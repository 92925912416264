import {FunctionComponent, useState, useCallback, useEffect, useRef, memo} from "react";
import KitchenMonitorCardDetailP from "./KitchenMonitorCardDetailP";
import PortalPopup from "./PortalPopup";
import MultipleKitchenButton from "./MultipleKitchenButton";
import KitchenMonitorDisplayCard from "./KitchenMonitorDisplayCard";
import {PosScreen, router} from "@/pos/PosRouter.ts";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {orderFoodList, setHeightContainer} from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";

import { mainScreen } from "@/data/PosSettingsSignal.ts";

const KitchenMonitorPlugin: FunctionComponent = () => {

  const [isKitchenMonitorCardDetailP7Open, setKitchenMonitorCardDetailP7Open] =
    useState(false);

  const openKitchenMonitorCardDetailP7 = useCallback(() => {
    setKitchenMonitorCardDetailP7Open(true);
  }, []);

  const closeKitchenMonitorCardDetailP7 = useCallback(() => {
    setKitchenMonitorCardDetailP7Open(false);
  }, []);

  const refContainer = useRef<any>(null)
  const refCardCol = useRef<any>(null)
  const [heightCardCol, setHeightCardCol] = useState<any>(null)

  useEffect(() => {
    if (refContainer.current) {
      setHeightContainer(refContainer.current.clientHeight)
    }
    if (refCardCol.current) {
      setHeightCardCol(refCardCol.current.clientHeight)
    }
  }, [])

  return (
    <>
      <div ref={refContainer}
           className="w-full h-full flex flex-col items-start justify-start relative bg-[url('/kitchen-monitor-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-smi text-black-solid-black-855-212121 font-mulish">
        <div
          className="self-stretch bg-gray-solid-gray-550-6b7178 h-[50px] flex flex-row items-center justify-between py-0 px-4 box-border z-[3] sm:h-[42px] Tablet_768:h-[42px] Tablet_768:pl-3 Tablet_768:pr-3 Tablet_768:box-border Tablet_600:gap-[12px] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
          <div className="flex flex-row items-start justify-start gap-[6px] sm:gap-[4px]">
            <MultipleKitchenButton/>
            <div
              className="rounded-31xl bg-blue-solid-blue-350-2196f3 shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] w-20 h-[34px] flex flex-row items-center justify-start py-0 px-2 box-border text-white-solid-white-100-ffffff sm:pl-1 sm:pr-1 sm:box-border Tablet_600:w-[68px]">
              <div className="flex-1 relative font-semibold sm:text-2xs">All Active</div>
            </div>
            <div
              className="rounded-31xl bg-white-solid-white-100-ffffff shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] h-[34px] flex flex-row items-center justify-start py-0 pr-2 pl-3 box-border gap-[4px] sm:pl-2 sm:pr-1 sm:box-border">
              <div className="relative font-semibold sm:text-2xs">New</div>
              <div
                className="w-6 h-6 flex flex-row items-center justify-center relative gap-[8px] text-gray-solid-gray-145-f9fafb">
                <div
                  className="absolute my-0 mx-[!important] top-[calc(50%_-_12px)] left-[calc(50%_-_12px)] rounded-[50%] bg-red-solid-red-150-ff5e71 w-6 h-6 z-[0]"/>
                <b className="flex-1 relative tracking-[-0.28px] z-[1] sm:text-2xs">
                  03
                </b>
              </div>
            </div>
            <div
              className="rounded-31xl bg-white-solid-white-100-ffffff shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] h-[34px] flex flex-row items-center justify-start py-0 px-2 box-border gap-[4px] sm:pl-1 sm:pr-1 sm:box-border">
              <div className="relative font-semibold sm:text-2xs">Preparing</div>
              <div
                className="w-6 h-6 flex flex-row items-center justify-center relative gap-[8px] text-gray-solid-gray-145-f9fafb">
                <div
                  className="absolute my-0 mx-[!important] top-[calc(50%_-_12px)] left-[calc(50%_-_12px)] rounded-[50%] bg-red-solid-red-150-ff5e71 w-6 h-6 z-[0]"/>
                <b className="flex-1 relative tracking-[-0.28px] z-[1] sm:text-2xs">
                  03
                </b>
              </div>
            </div>
            <div
              className="rounded-31xl bg-white-solid-white-100-ffffff shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] h-[34px] flex flex-row items-center justify-start py-0 px-4 box-border sm:pl-3 sm:pr-3 sm:box-border">
              <div className="relative font-semibold sm:text-2xs">Done</div>
            </div>
            <div
              className="rounded-31xl bg-white-solid-white-100-ffffff shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] h-[34px] flex flex-row items-center justify-start py-0 px-3 box-border gap-[4px] sm:pl-2 sm:pr-2 sm:box-border">
              <img
                className="relative w-6 h-6 object-cover"
                alt=""
                src="/iconzoom-in-icon@2x.png"
              />
              <div className="relative font-semibold sm:text-2xs">50%</div>
            </div>
          </div>
          <div
            className="rounded-[100px] bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-sm text-white-solid-white-100-ffffff Tablet_600:w-16 Tablet_600:min-w-[64px]"
            onClick={() => router.screen = mainScreen()}
            ref={makeRipple}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="relative leading-[17px] hidden">Button</b>
          </div>
        </div>
        <div
          className="my-0 mx-[!important] absolute right-[16px] bottom-[12px] rounded-26xl bg-gray-opacity-gray-50-d6d7d7 shadow-[0px_1px_4px_rgba(0,_0,_0,_0.25)] w-[45px] h-[45px] overflow-hidden shrink-0 flex flex-row items-center justify-center z-[9999]">
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/icongeneralwhite-double-chevron-icon@2x.png"
          />
        </div>
        <div
          className="my-0 mx-[!important] absolute bottom-[12px] left-[16px] rounded-26xl bg-gray-opacity-gray-50-d6d7d7 shadow-[0px_1px_4px_rgba(0,_0,_0,_0.25)] w-[45px] h-[45px] overflow-hidden shrink-0 flex flex-row items-center justify-center z-[9999]">
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/icongeneralwhite-double-chevron-icon1@2x.png"
          />
        </div>
        <div
          className="self-stretch h-full flex-1 overflow-x-auto no-scrollbar flex flex-row items-start justify-start p-2 gap-[10px] z-[0] text-mini text-black-solid-black-1000-000000">
            <div ref={refCardCol} className="self-stretch flex flex-col flex-wrap items-start justify-start gap-[8px]">
          {orderFoodList.map((order, index) => {
            return (
              <KitchenMonitorDisplayCard
                key={index}
                timeline={order.timeline}
                orderType={order.orderType}
                orderTime={order.orderTime}
                orderID={order.orderId}
                staff={order.orderStaff}
                orderStatus={order.orderStatus}
                orderFoods={order.orderFoods}
              />
          )})}
            </div>

        </div>
      </div>
      {isKitchenMonitorCardDetailP7Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeKitchenMonitorCardDetailP7}
        >
          <KitchenMonitorCardDetailP
            onClose={closeKitchenMonitorCardDetailP7}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(KitchenMonitorPlugin);
