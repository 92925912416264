import KeyboardUrl from "@/assets/keyboard.png";
import { Container } from "@/react/core/Box";
import Input, { InputController, inputController0, InputProps } from "@/react/core/Input";
import InputKeyboard from "@/react/core/InputKeyboard";
import { Accessor, effect, Setter, useSignal } from "@/react/core/reactive";
import { XContainer } from "@/react/core/XBox";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { memo, JSX } from "react";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";

export interface TeleportRef {
	show: Accessor<boolean>,
	setShow: Setter<boolean>
}

export type InputKeyboardPopUpRef = TeleportRef & {
	inputController: InputController
}

interface InputKeyboardPopUpProps extends Omit<InputProps, "children"> {
	onSave?: (str: string) => void;
	onOpen?: () => void;
	content?: (setShow: Setter<boolean>, show?: Accessor<boolean>) => JSX.Element;
	editUrl?: string;
	activatorHeight?: number;
	label?: string;
	noInput?: boolean;
	children?: (show: Accessor<boolean>, setShow: Setter<boolean>) => JSX.Element;
	ref0?: Setter<InputKeyboardPopUpRef>;
}


//todo: mutate value, don't lose focus
const InputKeyboardPopUp = memo((props: InputKeyboardPopUpProps) => {
	const [inputControllers, setInputControllers] = useSignal<Array<InputController>>([]);

	const [show, setShow] = useSignal(false);
	const content = <>
		{props.children && props.children?.(show, setShow)}
		<Dialog.Root open={show()} onOpenChange={setShow}>
			{!props.children && (
				<Dialog.Trigger asChild>
					<div className="h-full w-[50px] mr-[2px] items-end justify-center flex flex-col"
						onPointerDown={() => {
							setShow(v => !v);
							const dispose = effect(() => {
								if (inputControllers()[0]) {
									inputControllers()[0]?.setKeys(props.value?.split("")!);
									setTimeout(() => {
										inputControllers()[0]?.setFocus(true);
										inputControllers()[0]?.moveCaretToEnd();
										dispose();
									}, 200);
								}
							})
							props.onOpen?.();
						}}
					>
						<img src={props.editUrl || KeyboardUrl}
							style={{
								height: (props.activatorHeight || "13") + "px",
								"marginRight": "5px",
								...!props.editUrl && {
									filter: "brightness(0) saturate(100%) invert(27%) sepia(98%) saturate(2554%) hue-rotate(203deg) brightness(97%) contrast(88%)"
								}
							}} />
					</div>
				</Dialog.Trigger>
			)}
			<Dialog.Portal>
				<Dialog.Overlay />
				<Dialog.DialogContent>
					<div className={clsx(
						"w-[600px] py-4 px-5 z-1 bg-[#5d5d5d] rounded-[6px] gap-5",
						`fixed z-50 left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] backdrop-blur-[10px]`,
						`duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
						data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2
						data-[state=open]:slide-in-from-top-[48%]`
					)}
						onPointerDown={e => e.stopPropagation()}
					>
						<Container height={40} gap={10}>
							<XContainer justifyContent={"space-between"}>
								<Container width={70} justifyContent={"center"} style={{ color: "white" }}>
									{props.label || "Id"}
								</Container>
								<Input className="flex-grow"
									refInputController={i => {
										return setInputControllers([i]);
									}} />
							</XContainer>
						</Container>
						{props.content?.(setShow, show)}
						<Container height={240} width={"100%"}>
              {/*<InputKeyboard inputController={inputController0}*/}
              {/*               onEnter={() => {*/}
              {/*                 props.onChange?.(inputControllers()[0].keys().join(""));*/}
              {/*                 setShow(false);*/}
              {/*               }} />*/}
							<TableManualKeyboard inputController={inputController0}
								onEnter={() => {
									props.onChange?.(inputControllers()[0].keys().join(""));
									setShow(false);
								}} />
						</Container>
					</div>
				</Dialog.DialogContent>
			</Dialog.Portal>
		</Dialog.Root>
	</>;
	props.ref0?.({ show, setShow, inputController: inputControllers()[0] });
	if (props.noInput) return content;
	return <Input value={props.value} onChange={props.onChange} className={props.className} >
		{content}
	</Input>;
});

export default InputKeyboardPopUp;
