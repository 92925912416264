import { Order } from "@/data/Order.ts";
import { createOrder } from "@/pos/logic/order-reactive.ts";
import formatFactory from "format-number";
import _ from "lodash";
import { OrderItem, TseMethod } from "@/pos/OrderType.ts";
import { formatCurrency } from "@/shared/utils.ts";

export function getTaxTotal(order: Order) {
  const f = formatFactory({ round: 2, padRight: 2 });
  const taxes = order.vTaxSum;
  const tax_1_1 = _.get(taxes, '19.gross', 0) as number;
  const tax_1_2 = _.get(taxes, '16.gross', 0) as number;
  const tax_1 = tax_1_1 + tax_1_2;
  const tax_2_1 = _.get(taxes, '5.gross', 0) as number;
  const tax_2_2 = _.get(taxes, '7.gross', 0) as number;
  const tax_2 = tax_2_1 + tax_2_2;
  const taxArr = [formatCurrency(tax_1), formatCurrency(tax_2), '0.00', '0.00', formatCurrency(taxes![0]?.tax || 0)];
  return taxArr.join('_');
}

export function getTotal(order: Order) {
  // @ts-ignore
  if (order.$_id) {
    return order.vSum;
  }
  return createOrder(order).vSum;
}

export function filterPassthroughItem(order: Order) {
  const _order = _.cloneDeep(order);
  _order.items = _order.items.reduce<OrderItem[]>((l, i) => {
    if (i.tseMethod === TseMethod.applyPart) {
      i.tseMethod = TseMethod.passthrough;
      i.quantity = 1;
      if (i.quantity > 0) l.push(i);
    } else if (i.tseMethod === 'passthrough') {
      l.push(i);
    }
    return l;
  }, []);
  return _order;
}

export function filterApplyItems(order: Order) {
  const _order = _.cloneDeep(order);
  [_order.items, _order.cancellationItems] = getApplyItems(order);
  order.commits = [];
  return _order;
}

function getApplyItems(order: Order) {
  let items = _.cloneDeep(order.items).filter(i => i.tseMethod !== 'passthrough');
  items.forEach(i => {
    if (i.tseMethod === TseMethod.applyPart && i.quantity > 1) {
      i.quantity = 1;
    } else if (i.tseMethod === TseMethod.applyPart && i.quantity <= -1) {
      i.quantity = -1;
    }
  })
  let cancellationItems = _.cloneDeep(order.cancellationItems || []).filter(i => i.tseMethod === 'passthrough');
  return [
    items,
    cancellationItems
  ]
}

export function getTseMethod(order: Order, reProcess?: boolean) {
  let tseMethod = order.tseMethod;
  if (order.tseMethod === 'auto' || reProcess) {
    if (order.items.filter(i => i.tseMethod === 'passthrough').length === order.items.length) {
      tseMethod = TseMethod.passthrough;
    } else if (_.find(order.items, { tseMethod: 'passthrough' }) || order.items.find(i => i.tseMethod === TseMethod.applyPart && i.quantity > 1)) {
      tseMethod = TseMethod.applyPart;
    } else {
      tseMethod = TseMethod.apply;
    }
  }
  return tseMethod;
}

export function isDrink(item: OrderItem) {
  return item.groupPrinter === 'Bar' || item.groupPrinter === 'Getränk'
    || item.groupPrinter === 'drink';
}

export function isFood(item: OrderItem) {
  return item.groupPrinter !== 'Bar' && item.groupPrinter !== 'Getränk' &&
    item.groupPrinter !== 'drink';
}

export enum TseType {
  TseServer = 'TseServer',
  TseServerTestGermany = 'TseServerTestGermany',
  TseServerTest = 'TseServerTest',
  TseServerMock = 'TseServerMock',
  TseLocal = 'TseLocal',
  TseLocalMock = 'TseLocalMock',
}

export const ServerTypeGroup = [TseType.TseServer, TseType.TseServerTest, TseType.TseServerMock, TseType.TseServerTestGermany];