import { type FunctionComponent, memo } from 'react'
import CategoryLeft from "@order-view/CategoryLeft";
import CategoryTop from "@order-view/CategoryTop";
import ItemList from "@order-view/ItemList";
import {
  isSelectedCategory,
  layoutCategories0,
  menuDirection,
  orderLayout0
} from '@/react/OrderView/OrderView.tsx';
import PaymentSplitPopup from '@order-view/PaymentSplitPopup.tsx';
import MoveTablePopup from "@order-view/MoveTablePopup.tsx";
import { screenConfig } from "@/data/screenConfig.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { MenuDirection } from "@/data/MenuDirection.ts";

const OrderScreenLeft: FunctionComponent = memo(() => {

  return (
    <div className="relative self-stretch flex-1 overflow-hidden flex flex-row items-start justify-start text-center text-sm text-white font-mulish">
      {screenConfig().menuDirection === MenuDirection.HORIZONTAL &&
      <CategoryLeft />
      }
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start pt-0.5 px-0 pb-0">
        {screenConfig().menuDirection === MenuDirection.VERTICAL &&
        <CategoryTop />
        }
        <div className="w-full flex-1 relative">
          <div className={'absolute w-full h-full overflow-y-auto p-[5px]'}>
            {layoutCategories0().map((category, index) => (
              <ItemList category={category} index={index} key={index}/>
          ))}
          </div>
        </div>
      </div>
      <PaymentSplitPopup />
      <MoveTablePopup />
    </div>
  );
});

export default OrderScreenLeft;
