import { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";

export type DeleteConfirmationPopupType = {
  className?: string;
  onClose?: () => void;
  onComplete?: () => void;
};

const DeleteConfirmationPopup: FunctionComponent<
  DeleteConfirmationPopupType
> = ({ className = "", onClose, onComplete }) => {
  return (
    <div
      className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-8 min-w-[360] h-full text-center text-xl text-firebrick font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-8 hover:items-center hover:justify-center hover:rounded hover:pt-8 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[360] ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-1">
        <b className="self-stretch relative leading-[28px]">
          {LL0().masterMachine.deleteConfirmation()}
        </b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-black-solid-black-880-1d1d26">
          {LL0().masterMachine.doYouWantToDelete()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-3 max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff border-blue-solid-blue-420-2979ff border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer"
          onClick={onClose}
        >
          <b className="relative uppercase">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
          onClick={onComplete}
        >
          <b className="relative uppercase">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;