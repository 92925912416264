import type { RxCollection, RxDatabase } from 'rxdb'

import { Room, ROOM_OBJECTS_COLLECTION_NAME, RoomObject, roomObjectSchema, ROOMS_COLLECTION_NAME, roomSchema } from './Room'
import { createCollection } from './utils/migration'

export const createRoomCollection = <T>(database: RxDatabase<T>): Promise<RxCollection<Room>> =>
  createCollection({
    database,
    collection: Room,
    collectionName: ROOMS_COLLECTION_NAME,
    version: roomSchema.version,
    schema: roomSchema,
  })
export const createRoomObjectCollection = <T>(database: RxDatabase<T>): Promise<RxCollection<RoomObject>> =>
  createCollection({
    database,
    collection: RoomObject,
    collectionName: ROOM_OBJECTS_COLLECTION_NAME,
    version: roomObjectSchema.version,
    schema: roomObjectSchema,
  })
