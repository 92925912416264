import {type FunctionComponent, useState, useCallback, memo} from "react";
import {FormControlLabel, Switch} from "@mui/material";
import OnlineProviderSettingAddEd from "./OnlineProviderSettingAddEd";
import PortalPopup from "./PortalPopup";
import OnlineProviderSettingRow from "@online-provider-setting/OnlineProviderSettingRow.tsx";
import {router} from "@/pos/PosRouter.ts";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {LL0} from "@/react/core/I18nService.tsx";
import {HANDLERS, isUseSingleProvider, onlineProviders} from "@/react/OnlineOrderSettingView/OnlineProviderSettingView.logic.tsx";
import OnlineProviderSettingsOpen from "./OnlineProviderSettingsOpen";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import { mainScreen } from "@/data/PosSettingsSignal.ts";

const OnlineProviderSettingGrid: FunctionComponent = () => {
  const [isOnlineProviderSettingAddEdOpen, setOnlineProviderSettingAddEdOpen] =
    useState(false);
  const [
    isOnlineProviderSettingAddEd1Open,
    setOnlineProviderSettingAddEd1Open,
  ] = useState(false);

  const [isOnlineProviderSettingOpenDOpen, setOnlineProviderSettingOpenDOpen] =
    useState(false);

  const openOnlineProviderSettingAddEd = useCallback(() => {
    HANDLERS.onCreate()
    setOnlineProviderSettingAddEdOpen(true);
  }, []);

  const closeOnlineProviderSettingAddEd = useCallback(() => {
    setOnlineProviderSettingAddEdOpen(false);
  }, []);

  const openOnlineProviderSettingAddEd1 = useCallback(() => {
    setOnlineProviderSettingAddEd1Open(true);
  }, []);

  const closeOnlineProviderSettingAddEd1 = useCallback(() => {
    setOnlineProviderSettingAddEd1Open(false);
  }, []);

  const openOnlineProviderSettingOpenD = useCallback(() => {
    setOnlineProviderSettingOpenDOpen(true);
  }, []);

  const closeOnlineProviderSettingOpenD = useCallback(() => {
    setOnlineProviderSettingOpenDOpen(false);
  }, []);

  return (
    <>
      <div
        className={`online-provider-setting w-full h-full bg-white-solid-white-100-ffffff max-w-full overflow-hidden flex flex-col items-start justify-start text-left text-mini text-gray-solid-gray-850-767676 font-mulish-button-name-bold`}
      >
        <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-0 px-6 box-border sm:h-[42px] Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
          <b className="relative Tablet_600:text-smi Mobile_480:text-smi mq1440:text-[17px] height_414:text-smi">
            {LL0().onlineProdiverSetting.onlineProviderSetting()}
          </b>
          <div className="flex flex-row items-center justify-end gap-[10px] text-black-solid-black-1000-000000">
            <div
              className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 pr-3 pl-2 box-border gap-[3px] cursor-pointer Mobile_480:h-9 mq320:h-8"
              onClick={openOnlineProviderSettingOpenD}
              ref={makeRipple}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/icononline-provider-settingsopendelivery-hours-icon@2x.png"
              />
              <b
                className="flex-1 relative leading-[17px] Mobile_480:text-mini mq320:text-sm">{LL0().onlineProdiverSetting.openDeliveryHours()}</b>
            </div>
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-cyan-solid-cyan-280-80e0ff h-10 flex flex-row items-center justify-start py-0 px-2.5 box-border cursor-pointer sm:h-[30px] Tablet_600:h-[26px]"
              onClick={openOnlineProviderSettingAddEd}
              ref={makeRipple}
            >
              <div className="relative sm:text-sm Tablet_600:text-smi">
                +{LL0().onlineProdiverSetting.addNewProvider()}
              </div>
            </div>
            <div
              className="w-[76px] rounded-11xl bg-blue-solid-blue-450-1271ff h-10 flex flex-row items-center justify-center sm:h-8 Tablet_600:w-[68px] Mobile_480:w-[68px] height_414:w-[68px]"
              onClick={() => router.screen = mainScreen()}
              ref={makeRipple}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/iconback-icon@2x.png"
              />
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-3 px-6 pb-0 gap-[12px] text-sm text-black-solid-black-880-1d1d26 Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
          <div className="flex flex-row items-center justify-start gap-[12px]">
            <div className="w-10 h-6 flex flex-row items-center justify-start">
              <FormControlLabel
                label=''
                className="relative"
                control={<Switch color="primary" size="small" checked={isUseSingleProvider()}
                                 onChange={(event, checked) => {
                                   HANDLERS.toggleUseSingleProvider()
                                 }}/>}
              />
            </div>
            <label className="cursor-pointer relative mq1440:text-base">{LL0().onlineProdiverSetting.useOneMenuForAll()}</label>
          </div>
          <div className="no-scrollbar self-stretch relative rounded box-border h-[336px] overflow-y-auto shrink-0 grid grid-rows-[repeat(6,56px)] grid-cols-[repeat(3,1fr)_138_178] [grid-row-gap:0px] [grid-column-gap:0px] text-grey-grey-darken-3 border-r-[1px] border-solid border-gray-solid-gray-500-888f97 border-b-[1px] border-l-[1px]">
            <div className="bg-gray-solid-gray-500-888f97 flex flex-row items-center justify-between py-3 pr-2 pl-3 relative text-base text-white-solid-white-100-ffffff Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:relative">
              <div className="flex-1 relative font-semibold sm:text-mini Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-lg mq1440:text-lg">
                {LL0().onlineProdiverSetting.provider()}
              </div>
            </div>
            <div className="bg-gray-solid-gray-500-888f97 flex flex-row items-center justify-start py-3 px-2 relative text-center text-base text-white-solid-white-100-ffffff Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:relative">
              <div className="relative leading-[17px] font-semibold sm:text-mini Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-lg mq1440:text-lg">
                {LL0().settings.userName()}
              </div>
            </div>
            <div className="bg-gray-solid-gray-500-888f97 flex flex-row items-center justify-start py-3 px-2 relative text-center text-base text-white-solid-white-100-ffffff Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:relative">
              <div className="relative leading-[17px] font-semibold sm:text-mini Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-lg mq1440:text-lg">
                {LL0().onlineProdiverSetting.providerStoreID()}
              </div>
            </div>
            <div className="bg-gray-solid-gray-500-888f97 flex flex-row items-center justify-start py-3 px-2 relative text-center text-base text-white-solid-white-100-ffffff Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:relative">
              <div className="relative leading-[17px] font-semibold sm:text-mini Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-lg mq1440:text-lg">
                {LL0().onlineProdiverSetting.lastDateSync()}
              </div>
            </div>
            <div className="bg-gray-solid-gray-500-888f97 flex flex-row items-center justify-center py-3 px-2 relative text-center text-base text-white-solid-white-100-ffffff Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:relative">
              <div className="relative leading-[17px] font-semibold sm:text-mini Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-lg mq1440:text-lg">
                {LL0().onlineProdiverSetting.enable()}
              </div>
            </div>
            <div className="bg-gray-solid-gray-500-888f97 relative col-[6/6] row-[1/1] Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:box-border Tablet_768:relative Tablet_768:col-[6/6] Tablet_768:row-[1/1]" />
              {onlineProviders().map(value=> (
                <OnlineProviderSettingRow key={value._id} {...value} onEnableChanged={isEnable => HANDLERS.setEnable(value, isEnable)}/>
              ))}
          </div>
        </div>
      </div>
      {isOnlineProviderSettingAddEdOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOnlineProviderSettingAddEd}
        >
          <OnlineProviderSettingAddEd
            onClose={closeOnlineProviderSettingAddEd}
          />
        </PortalPopup>
      )}
      {isOnlineProviderSettingAddEd1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOnlineProviderSettingAddEd1}
        >
          <OnlineProviderSettingAddEd
            onClose={closeOnlineProviderSettingAddEd1}
          />
        </PortalPopup>
      )}
      {isOnlineProviderSettingOpenDOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOnlineProviderSettingOpenD}
        >
          <OnlineProviderSettingsOpen
            onClose={closeOnlineProviderSettingOpenD}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(OnlineProviderSettingGrid);