import { type FunctionComponent, useCallback, useState } from "react";
import PortalPopup from "@online-provider-setting/PortalPopup.tsx";
import OnlineProviderSettingAddEd from "@online-provider-setting/OnlineProviderSettingAddEd.tsx";
import TaxAndPaymentDeleteConfig from "@online-provider-setting/TaxAndPaymentDeleteConfig.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { HANDLERS } from "@/react/OnlineOrderSettingView/OnlineProviderSettingView.logic.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { Switch, FormControlLabel } from "@mui/material";
import OnlineProviderSettingsOpen from "@online-provider-setting/OnlineProviderSettingsOpen.tsx";
import DeleteConfirmationPopup from "@online-provider-setting/DeleteConfirmationPopup.tsx";


type  OnlineProviderSettingRowType = {
  _id?: string,
  name?: string,
  apiKey?: string,
  storeId?: string,
  username?: string,
  password?: string,
  lastSyncAt?: number,
  providerID?: string,
  isRegistered?: boolean,
  isEnabled?: boolean,
  onEnableChanged: (isEnabled: boolean) => void
}

const OnlineProviderSettingRow: FunctionComponent<OnlineProviderSettingRowType> = ({
  _id,
  name,
  apiKey,
  storeId,
  password,
  username,
  lastSyncAt,
  providerID,
  isRegistered,
  isEnabled,
  onEnableChanged
}) => {
  const [isDeleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [
    isOnlineProviderSettingAddEd1Open,
    setOnlineProviderSettingAddEd1Open,
  ] = useState(false);

  const openOnlineProviderSettingAddEd = useCallback(() => {
    HANDLERS.onEdit(_id)
    setOnlineProviderSettingAddEd1Open(true);
  }, []);
  const openDeleteConfirmDialog = useCallback(() => {
    HANDLERS.onDelete(_id)
    setDeleteConfirmDialogOpen(true);
  }, []);
  const closeOnlineProviderSettingAddEd = useCallback(() => {
    setOnlineProviderSettingAddEd1Open(false);
  }, []);

  const closeDeleteConfirmDialog = useCallback(() => {
    setDeleteConfirmDialogOpen(false);
  }, []);

  const onDeleteProvider = useCallback(() => {
    closeDeleteConfirmDialog()
    HANDLERS.onDeleteConfirmed()
  }, [])

  const openOnlineProviderSettingOpenD = useCallback(() => {
    setOnlineProviderSettingOpenDOpen(true);
  }, []);

  const closeOnlineProviderSettingOpenD = useCallback(() => {
    setOnlineProviderSettingOpenDOpen(false);
  }, []);

  return (
    <>
      <div
        className="flex flex-row items-center justify-start py-3 pr-2 pl-3 relative border-b-[1px] border-solid border-gray-solid-gray-500-888f97">
        <div className="relative font-semibold Tablet_768:text-xs Mobile_480:text-2xs mq1440:text-base">
          {name}
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-start py-3 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-500-888f97">
        <div className="relative font-semibold Tablet_768:text-xs mq1440:text-base">
          {username}
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-start py-3 pr-2 pl-3 relative border-b-[1px] border-solid border-gray-solid-gray-500-888f97">
        <div className="relative font-semibold Tablet_768:text-xs Mobile_480:text-2xs mq1440:text-base">
          {storeId}
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-start py-3 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-500-888f97">
        <div className="relative font-semibold Tablet_768:text-xs mq1440:text-base">
          -
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-center relative border-b-[1px] border-solid border-gray-solid-gray-500-888f97 Tablet_768:gap-[6px] Tablet_768:relative">
        <div className="w-10 relative h-6">
          <FormControlLabel
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
            control={
              <Switch color="primary" checked={isEnabled} onChange={(e, checked: boolean) => onEnableChanged(checked)}/>
            }
          />
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-center py-0 pr-2 pl-0 gap-[6px] relative text-center text-white-solid-white-100-ffffff border-b-[1px] border-solid border-gray-solid-gray-500-888f97 Tablet_768:relative">
        <div className="flex flex-row items-start justify-start">

          {isRegistered && <div
            className="rounded-11xl bg-blue-solid-blue-350-2196f3 w-[94px] h-8 flex flex-row items-center justify-center p-2.5 box-border Tablet_768:w-[60px] Tablet_768:h-6"
            ref={makeRipple}
          >
            <div className="relative font-semibold sm:text-sm Tablet_768:text-xs">
              {LL0().onlineProdiverSetting.register()}
            </div>
          </div>
          }

          {!isRegistered && <div
            className="rounded-11xl bg-mediumseagreen w-[94px] h-8 flex flex-row items-center justify-center py-2.5 pr-1 pl-2 box-border gap-[1px] text-xs Tablet_768:w-[60px] Tablet_768:h-6">
            <div
              className="relative font-semibold opacity-[0.8] sm:text-sm Tablet_768:text-xs Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden">
              {LL0().onlineProdiverSetting.registered()}
            </div>
            <img
              className="relative w-5 h-5 object-cover opacity-[0.94]"
              alt=""
              src="/checkmark-registered-1@2x.png"
            />
          </div>}
        </div>
        <div ref={makeRipple} onClick={openOnlineProviderSettingAddEd}>
          <img
            className="relative w-8 h-8 object-cover cursor-pointer sm:w-[26px] sm:h-[26px] sm:[transform:rotate(0deg)] Tablet_768:w-6 Tablet_768:h-6"
            alt=""
            src="/yellow-edit-icon@2x.png"
          />
        </div>
        <div onClick={openDeleteConfirmDialog} ref={makeRipple}>
          <img
            className="w-[30px] relative h-[30px] object-cover"
            alt=""
            src="/iconbig-red-bin-icon@2x.png"
          />
        </div>
      </div>

      {isOnlineProviderSettingAddEd1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOnlineProviderSettingAddEd}
        >
          <OnlineProviderSettingAddEd
            onClose={closeOnlineProviderSettingAddEd}
          />
        </PortalPopup>
      )}
      {isDeleteConfirmDialogOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmDialog}
        >
          <TaxAndPaymentDeleteConfig
            onClose={closeDeleteConfirmDialog}
            onConfirm={onDeleteProvider}/>
        </PortalPopup>
      )}
    </>
  )
}

export default OnlineProviderSettingRow