import { FunctionComponent } from 'react'
import { type Dayjs } from 'dayjs'
import _ from 'lodash'
import { MobileDateTimePicker } from '@mui/x-date-pickers'

import StaffReportTotalRevenue from './StaffReportTotalRevenue'
import StaffReportPaymentDetail from './StaffReportPaymentDetail'
import StaffReportTimeClock from './StaffReportTimeClock'
import StaffReportTotalCashlessSales from './StaffReportTotalCashlessSales'
import StaffReportSoldItems from './StaffReportSoldItems'
import StaffReportReturnedTotal from './StaffReportReturnedTotal'
import StaffReportCancellationHistory from './StaffReportCancellationHistory'
import { startDate, endDate, setStartDate, setEndDate, selectedUserId, staffReportData } from '@/react/StaffReportView/StaffReportView'
import { LL0 } from "@/react/core/I18nService.tsx";
import { isInSrmTrainingMode } from '@/srm/signals'
import { companyInfo0, generalSetting0, isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { FormControlLabel, Switch } from "@mui/material";
import { setShowTrainingMode, showTrainingMode } from "@/pos/trainingMode.ts";

const CustomDateTimePickerField: FunctionComponent<{ value?: Dayjs | null; onClick?: () => void }> = ({ value, onClick }) => (
  <button
    className="inline"
    type="button"
    onClick={onClick}
  >
    {value?.format('MMM D, HH:mm') ?? '-'}
  </button>
)

const StaffReportContent: FunctionComponent = () => {
  return (
    <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-4 px-7 gap-[16px] bg-[url('/staff-report--content@3x.png')] bg-cover bg-no-repeat bg-[top] text-left text-base text-white-solid-white-100-ffffff font-mulish Tablet_768:gap-[12px] Tablet_768:py-4 Tablet_768:px-5 Tablet_768:box-border Tablet_600:gap-[12px] Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border Mobile_480:gap-[12px] Mobile_480:pl-1 Mobile_480:pt-4 Mobile_480:pr-1 Mobile_480:box-border">
      {
        isInSrmTrainingMode() &&
          <div className="font-bold text-2xl text-white self-center">{LL0().settings.inTrainingMode().toUpperCase()}</div>
      }
      {selectedUserId() && (
        <>
          <div className="self-stretch flex flex-row items-center justify-start py-0 px-[18px] gap-[12px]">
            <div className="relative rounded-981xl w-9 h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_768:[transform:rotate(0deg)] Tablet_600:w-[26px] Tablet_600:h-[26px]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/iconcalendar-icon@2x.png"
              />
            </div>
            <div className="flex-1 relative [text-decoration:underline] font-semibold Tablet_768:text-sm Tablet_600:text-smi">
              <MobileDateTimePicker
                ampm={companyInfo0()?.country !== 'de'}
                value={startDate()}
                slots={{ field: CustomDateTimePickerField }}
                onChange={setStartDate}
              />
              {' - '}
              <MobileDateTimePicker
                value={endDate()}
                ampm={companyInfo0()?.country !== 'de'}
                slots={{ field: CustomDateTimePickerField }}
                onChange={setEndDate}
              />
            </div>
            <FormControlLabel
              className="self-stretch"
              label={LL0().monthlyReport.showTrainingMode()}
              control={<Switch color="primary"
                               checked={showTrainingMode() || false}
                               onChange={(e) => setShowTrainingMode(e.target.checked)}
              />}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[1px] text-mini text-gray">
            <StaffReportTotalRevenue totalRevenuePrice={staffReportData().totalRevenue} />
            <StaffReportPaymentDetail
              paymentDetailCash={staffReportData().totalPaymentInCash}
              paymentDetailCredit={staffReportData().totalPaymentInCredit}
              paymentDetailDebitor={staffReportData().totalPaymentInDebitor}
            />
            <StaffReportTimeClock />
            <StaffReportTotalCashlessSales
              totalCashlessSales={staffReportData().totalCashlessSales}
              totalCashlessSalesExcludingTip={staffReportData().totalCashlessSalesExcludingTip}
              totalTip={staffReportData().totalTip}
            />
            <StaffReportSoldItems
              totalItemSold={staffReportData().totalItemSold}
              usedDiscount={staffReportData().usedDiscount}
              totalVoucherSoldCount={staffReportData().totalVoucherSoldCount}
              totalVoucherSoldValue={staffReportData().totalVoucherSoldValue}
              totalVoucherUsedCount={staffReportData().totalVoucherUsedCount}
              totalVoucherUsedValue={staffReportData().totalVoucherUsedValue}
            />
            <StaffReportReturnedTotal returnedTotalPrice={staffReportData().returnedTotalPrice} />
            <StaffReportCancellationHistory data={staffReportData().cancellationHistory} title = {LL0().staffReport.cancellationHistory()}/>
            {isQuebecSrmEnabled() && (<StaffReportCancellationHistory data={staffReportData().refundHistory} title={LL0().monthlyReport.refundHistory()} />)}
          </div>
        </>
      )}
    </div>
  )
}

export default StaffReportContent
