import type { FunctionComponent } from 'react'
import {
  findProduct,
  keyboardMatchProducts,
  keyboardProductQuantity,
  onProductClick
} from '@/react/OrderView/OrderView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from "@/react/core/I18nService.tsx";

export type OrderChooseItemFromSameIDsPopType = {
  className?: string;
  onClose?: () => void;
};

const OrderChooseItemFromSameIDsPop: FunctionComponent<
  OrderChooseItemFromSameIDsPopType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-full relative rounded bg-white overflow-hidden flex flex-col items-start justify-start py-8 px-6 box-border gap-[24px] min-w-[520px] max-h-[240px] h-full max-w-full text-left text-smi text-blue-solid-blue-500-0051c1 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[20px] z-[0]">
        <b className="self-stretch relative text-base">
          {LL0().order.selectItem()}:
        </b>
        <div className="flex flex-row items-start justify-start gap-[3px] text-black-solid-black-1000-000000">
          <div className="relative">{LL0().order.thereWere()}</div>
          <div className="relative [text-decoration:underline] font-semibold text-red-solid-red-600-d40000">
            {keyboardMatchProducts().length}
          </div>
          <div className="relative">{LL0().order.itemsWithTheSameID()}</div>
          <div className="relative [text-decoration:underline] font-semibold text-red-solid-red-600-d40000">
            {findProduct(keyboardMatchProducts()?.[0])?.id}
          </div>
          <div className="relative">{LL0().order.found()}</div>
        </div>
        <div className="no-scrollbar overflow-y-auto h-[100px] self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-black-solid-black-880-1d1d26">
          {keyboardMatchProducts().map((productLayout, index) => {
            const product = findProduct(productLayout);

            return (
              <div className="rounded-sm w-[100.3px] h-[50.7px] flex flex-row items-center justify-start py-0 px-1.5 box-border relative"
                   key={index}
                   style={{
                     backgroundColor: productLayout?.color
                   }}
                   onClick={() => {
                     setTimeout(() => onProductClick(productLayout, keyboardProductQuantity()), 5);
                     onClose?.();
                   }}
                   ref={makeRipple}
              >
                <div className="self-stretch flex-1 relative tracking-[-0.12px] leading-[13px] font-semibold flex items-center z-[1]">
                  {product?.id}. {product?.name}
                </div>
                { product?.activePopupModifierGroup &&
                <img
                  className="!block absolute my-0 mx-[!important] top-[4px] right-[4.3px] w-2 h-2 object-cover hidden z-[2]"
                  alt=""
              src="/iconpopup-icon30@2x.png"
                />
                }
              </div>)
          })}
        </div>
      </div>
      <div
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 cursor-pointer z-[1]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/icong-close-popup-icon--grey12@2x.png"
        />
      </div>
    </div>
  );
};

export default OrderChooseItemFromSameIDsPop;
