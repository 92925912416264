import { signal } from "@/react/core/reactive.ts";
import uuid from "time-uuid";

type DashboardNotificationType = "info" | "error";

type DashboardNotificationOptions = {
  autoClose?: number;
  type?: DashboardNotificationType;
  extraType?: string
}

type DashboardNotification = {
  _id: string,
  text: string;
  options: DashboardNotificationOptions;
}

export const [dashboardNotifications, setDashboardNotifications] = signal<DashboardNotification[]>([]);

export const notificationToast = (text: string, options: DashboardNotificationOptions): string => {
  const newNotification = {
    text, options,
    _id: uuid()
  }
  setDashboardNotifications(prev => [...prev, newNotification])
  if (!options.autoClose) return newNotification._id;
  setTimeout(() => {
    setDashboardNotifications(prev => prev.filter(notification => notification._id !== newNotification._id))
  },options.autoClose)
  return newNotification._id;
}

export const clearNotificationToast = (_id: string) => {
  setDashboardNotifications(prev => prev.filter(notification => notification._id !== _id))
}