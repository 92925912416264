import { AgoraRtmTransporter } from "@syncit/transporter";
import {
  applyMirrorAction,
  createEmbedControlService,
  createEmbedService,
  type MirrorPayload,
  RemoteControlActions,
  SourceBuffer,
  type TransportAckRecordEvent,
  TransporterEvents,
  type TransportRemoteControlEvent
} from '@syncit/core';
import type { eventWithTime } from "@rrweb/types";
import { record } from "rrweb";
import { toast } from "react-toastify";
import { getDeviceId } from '@/shared/getDeviceId'
import SurrealClient from "@/shared/SurrealClient.ts";
import { captureException } from "@sentry/react";
import {
  requestLoginId,
  setRequestLoginId,
  setSyncitApi,
  syncitApi,
  setIsStop,
  generateRandomId
} from "@/pos/logic/rrweb-share.ts";
import { liveSurrealHook } from "@/react/utils/hooks.ts";
import { dialogShowId } from "@/shared/dialogShowId.tsx";
import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";

export const SyncitFactory = () => {
  const uid = generateRandomId();
  const transporter = new AgoraRtmTransporter({
    role: 'embed',
    uid,
    agoraAppId: '94fc8fb69da544dcb393c01bcaea67d3'
  });

  const buffer = new SourceBuffer<eventWithTime>({
    onTimeout(record) {
      transporter.sendRecord(record);
    }
  });

  const service = createEmbedService({
    transporter,
    record,
    stopRecordFn: null,
    buffer
  });

  let current = service.state;

  const controlService = createEmbedControlService({
    record
  });

  let controlCurrent = controlService.state;

  function login(manual: boolean = false) {
    transporter.login().then(() => {
      console.log('login success ', uid);
      setIsStop(true);
      if (!manual) sendUid(uid).then()
      setTimeout(() => {
        startSyncit()
      }, 1000);
    });
    return uid
  }

  function startSyncit() {
    service.start();
    service.subscribe((state) => {
      current = state;
    });
    service.send('START')
    controlService.start();
    controlService.subscribe((state) => {
      controlCurrent = state;
      if (state.value === 'requesting') {
        controlService.send('ACCEPT');
      }
    });
    transporter.on(TransporterEvents.MirrorReady, () => {
      transporter.sendSourceReady();
    });
    transporter.on(TransporterEvents.Start, () => {
      service.send('CONNECT');
    });
    transporter.on(TransporterEvents.AckRecord, ({ payload }) => {
      buffer.delete(payload as TransportAckRecordEvent['payload']);
    });
    transporter.on(TransporterEvents.RemoteControl, ({ payload }) => {
      switch ((payload as TransportRemoteControlEvent['payload']).action) {
        case RemoteControlActions.Request:
          controlService.send('REQUEST');
          break;
        case RemoteControlActions.Stop:
          controlService.send('STOP');
          break;
        default:
          // @ts-ignore
          applyMirrorAction(record.mirror, payload as MirrorPayload);
          break;
      }
    });
  }

  function stopSyncit() {
    try {
      service.send('STOP')
    } catch (e) {
      toast.error('Error: ' + e)
    }
  }

  return {
    login,
    stopSyncit,
    uid
  }
}

export function startSyncit(manual: boolean = false) {
  const api = SyncitFactory()
  setSyncitApi(api)
  const uid = api.login(manual)
  if (uid && manual) dialogShowId(uid).then()
}

export function stopSyncit() {
  if (!syncitApi()) return
  syncitApi()?.stopSyncit()
}

export const handleLoginSyncit = async () => {
  try {
    liveSurrealHook.on('request', async ([action, result]) => {
      const checkDeviceId = (result?.deviceId === getDeviceId()) || (result?.deviceId === (getDeviceId() + posSync0()?.id).toString())
      if (!checkDeviceId || result?.storeId !== posSync0()?.id) return
      if (action === "CREATE") {
        if (result?.mode === 'login-syncit') {
          setRequestLoginId(result?.id as string)
          startSyncit()
        }
        if (result?.mode === 'close-syncit') {
          stopSyncit()
        }
      }
    })
    await posSyncLock.acquireAsync();
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    await db.live("Request", (action, result) => liveSurrealHook.emit('request', [action, result]))
  } catch (e: any) {
    console.log(' ', e.message)
  }
}

export async function sendUid(uid: string) {
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db || !requestLoginId()) {
      captureException(new Error('Cannot response login uid'))
    }
    const [record] = await db.merge(requestLoginId()!, { response: uid })
  } catch (e: any) {
    captureException(new Error('Cannot response login uid' + e?.message))
  }
}

