import type {FunctionComponent} from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import clsx from "clsx";
import {
  handleFilterOrderHistory,
  setTypeOrderFilter,
  typeOrderFilter
} from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {useSignal} from "@/react/core/reactive.ts";

type TypeChoosingPopupType = {
  onClose?: () => void;
};

const TypeChoosingPopup: FunctionComponent<TypeChoosingPopupType> = (
  {
    onClose,
  }) => {

  const [type, setType] = useSignal<{type: string, custom: string}>(typeOrderFilter())

  return (
    <div
      className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[340px] h-full max-w-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish-button-name-bold">
      <div
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/order-history-close-popup-icon--grey@2x.png"
        />
      </div>
      <div
        className="self-stretch overflow-hidden flex flex-col items-start justify-start pt-0 px-0 box-border gap-[24px] w-full h-full z-[1] Mobile_480:w-full">
        <div
          className="self-stretch flex flex-col items-start justify-start pt-0 px-0 gap-[16px] border-b-[0px] border-solid border-gray-solid-gray-350-ced1d7">
          <b className="relative">{LL0().orderHistory.orderTypeFilterDialog.typeSelection()}:</b>
          <div
            className="self-stretch gap-[12px] grid grid-cols-[repeat(auto-fit,minmax(98px,auto))] [grid-row-gap:8px] [grid-column-gap:8px] text-center text-sm text-blue-solid-blue-420-2979ff Tablet_600:grid Tablet_600:grid-cols-[repeat(3,1fr)] Tablet_600:[grid-row-gap:8px] Tablet_600:[grid-column-gap:8px] Mobile_480:grid Mobile_480:grid-cols-[repeat(2,1fr)] Mobile_480:[grid-row-gap:8px] Mobile_480:[grid-column-gap:8px]">
            <div
              className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",
                type()?.type === "" && "text-white-solid-white-100-ffffff"
              )}
              onClick={() => setType(prev => ({...prev, type: ""}))}
            >
              {type()?.type === "" && <div
                  className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}
              <b className="relative z-[1]">{LL0().common.all().toUpperCase()}</b>
            </div>
            {/*<div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",*/}
            {/*    type()?.type === "dineIn" && "text-white-solid-white-100-ffffff"*/}
            {/*  )}*/}
            {/*  onClick={() => setType(prev => ({...prev, type: "dineIn"}))}*/}
            {/*>*/}
            {/*  {type()?.type === "dineIn" && <div*/}
            {/*    className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}*/}
            {/*  <b className="relative z-[1]">{LL0().report.dineIn().toUpperCase()}</b>*/}
            {/*</div>*/}
            <div
              className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",
                type()?.type === "takeAway" && "text-white-solid-white-100-ffffff"
              )}
              onClick={() => setType(prev => ({...prev, type: "takeAway"}))}
            >
              {type()?.type === "takeAway" && <div
                  className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}
              <b className="relative z-[1]">{LL0().report.takeAway().toUpperCase()}</b>
            </div>
            {/*<div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",*/}
            {/*    type()?.type === "pickUp" && "text-white-solid-white-100-ffffff"*/}
            {/*  )}*/}
            {/*  onClick={() => setType(prev => ({...prev, type: "pickUp"}))}*/}
            {/*>*/}
            {/*  {type()?.type === "pickUp" && <div*/}
            {/*    className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}*/}
            {/*  <b className="relative z-[1]">{LL0().delivery.methods.pickup().toUpperCase()}</b>*/}
            {/*</div>*/}
            {/*<div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",*/}
            {/*    type()?.type === "delivery" && "text-white-solid-white-100-ffffff"*/}
            {/*  )}*/}
            {/*  onClick={() => setType(prev => ({...prev, type: "delivery"}))}*/}
            {/*>*/}
            {/*  {type()?.type === "delivery" && <div*/}
            {/*    className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}*/}
            {/*  <b className="relative z-[1]">{LL0().delivery.methods.delivery().toUpperCase()}</b>*/}
            {/*</div>*/}
          </div>
        </div>
        {/*<div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-gray-solid-gray-900-616161">*/}
        {/*  <div className="relative">{LL0().orderHistory.orderTypeFilterDialog.yourCustomersCameFrom()}:</div>*/}
        {/*  <div className="self-stretch gap-[12px] grid grid-cols-[repeat(auto-fit,minmax(116px,130px))] [grid-row-gap:8px] [grid-column-gap:8px] text-center text-sm">*/}
        {/*    <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-gray-solid-gray-900-616161",*/}
        {/*        type()?.custom === "telephone" ? "text-white-solid-white-100-ffffff" : "bg-white-solid-white-100-ffffff"*/}
        {/*      )}*/}
        {/*        onClick={() => setType(prev => ({...prev, custom: "telephone"}))}*/}
        {/*    >*/}
        {/*      {type()?.custom === "telephone" && <div*/}
        {/*        className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-md bg-gray-solid-gray-900-616161 box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}*/}
        {/*      <b className="relative z-[1]">{LL0().settings.tel().toUpperCase()}</b>*/}
        {/*    </div>*/}
        {/*    <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-gray-solid-gray-900-616161",*/}
        {/*        type()?.custom === "website" ? "text-white-solid-white-100-ffffff" : "bg-white-solid-white-100-ffffff"*/}
        {/*      )}*/}
        {/*        onClick={() => setType(prev => ({...prev, custom: "website"}))}*/}
        {/*    >*/}
        {/*      {type()?.custom === "website" && <div*/}
        {/*        className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-md bg-gray-solid-gray-900-616161 box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}*/}
        {/*      <b className="relative z-[1]">WEBSITE</b>*/}
        {/*    </div>*/}
        {/*    <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-gray-solid-gray-900-616161",*/}
        {/*        type()?.custom === "uber" ? "text-white-solid-white-100-ffffff" : "bg-white-solid-white-100-ffffff"*/}
        {/*      )}*/}
        {/*        onClick={() => setType(prev => ({...prev, custom: "uber"}))}*/}
        {/*    >*/}
        {/*      {type()?.custom === "uber" && <div*/}
        {/*        className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-md bg-gray-solid-gray-900-616161 box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}*/}
        {/*      <b className="relative z-[1]">UBER</b>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div
        className="self-stretch flex flex-row items-center justify-end gap-[12px] z-[2] text-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
          ref={makeRipple}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 text-white-solid-white-100-ffffff"
          ref={makeRipple}
          onClick={() => {
            setTypeOrderFilter(type)
            handleFilterOrderHistory()
            onClose?.()
          }}
        >
          <b className="relative">{LL0().ui.ok().toUpperCase()}</b>
        </div>
      </div>
    </div>
  );
};

export default TypeChoosingPopup;