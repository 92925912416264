import { memo, type FunctionComponent } from 'react'

import { makeImagesAvailable } from '@/react/VirtualPrinterView/VitualPrinterLogic'

import VirtualPrinterContent from './VirtualPrinterContent'
import VirtualPrinterTopNav from './VirtualPrinterTopNav'

const VirtualPrinterPlugin: FunctionComponent = () => {
  makeImagesAvailable()
  return (
    <div className="virtual-printer-screen w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[8px] bg-[url('/virtual-printer-plugin@3x.png')] bg-cover bg-no-repeat bg-[top]">
      <VirtualPrinterTopNav />
      <VirtualPrinterContent />
    </div>
  )
}

export default memo(VirtualPrinterPlugin)
