import React, { Fragment, type FunctionComponent, useCallback, useEffect, useState } from 'react'
import PaymentMethod from '@order-history/PaymentMethod'
import {
  getUserActionsOrder,
  order0,
  setShowUserAction,
  showUserAction,
  userActions0
} from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import OrderHistoryListItem from '@order-history/OrderHistoryListItem.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { OrderStatus } from '@/pos/OrderType.ts'
import { useServiceFeeBeforeTax } from '@/shared/order/order-config'
import { clsx } from "clsx";

import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { isRefundOrder } from '@/pos/logic/order-utils'

const OrderInformation: FunctionComponent = () => {

  const vTaxSumArray = Object.entries(order0()?.vTaxSum || {}).map(([taxPercent, value]) => ({
    taxPercent,
    tax: value.tax,
    net: value.net,
    gross: value.gross
  }));

  const [isNewDeliveryAddEditCustomerOpen, setNewDeliveryAddEditCustomerOpen] =
    useState(false);

  const openNewDeliveryAddEditCustomer = useCallback(() => {
    setNewDeliveryAddEditCustomerOpen(true);
  }, []);

  const closeNewDeliveryAddEditCustomer = useCallback(() => {
    setNewDeliveryAddEditCustomerOpen(false);
  }, []);

  useEffect(()=>{
    if (!order0?.()) return
    getUserActionsOrder(order0()).then()
  },[order0()?._id])

  return (
    <>
    <div
      className="no-scrollbar overflow-y-auto self-stretch flex-1 flex flex-col items-center justify-start p-2 gap-[9px] text-left text-xs text-gray-solid-gray-600-979797 font-mulish-button-name-bold">
      {order0()?.status === OrderStatus.REFUNDED && (
        <div className="text-red-solid-red-660-8f181d font-bold text-lg mb-2">
          {/*{*/}
          {/*  //replace with isPartial*/}
          {/*  true ?*/}
          {/*    `${LL0().pendingOrder.orderIsPartiallyRefunded()}`*/}
          {/*    : `${LL0().pendingOrder.orderIsFullyRefunded()}`*/}
          {/*}*/}
           {LL0().orderHistory.orderRefunded()}
        </div>)
      }
      {
        order0()?.refOrderId &&
          <div className="text-center text-red-solid-red-660-8f181d font-bold text-lg mb-2">
            {LL0().orderHistory.cancellingOrder({id: order0()?.refOrderId})}
          </div>
      }
      <div
        className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
        {order0()?.users?.[0] && (
          <div
            className="shrink-0 flex flex-row items-center justify-start gap-[7px] sm:flex-col sm:gap-[2px] sm:items-start sm:justify-start sm:pl-0 sm:box-border">
            <div className="relative leading-[9px] font-semibold mix-blend-normal">
              {LL0().order.createdBy()}:
            </div>
            <div className="relative text-black-solid-black-900-1e1e23">
              {order0()?.users?.[0]}
            </div>
          </div>
        )}
        {order0()?.users?.[0] && (
          <div
            className="shrink-0 flex flex-row items-center justify-start gap-[7px] sm:flex-col sm:gap-[2px] sm:items-start sm:justify-start">
            <div className="relative leading-[9px] font-semibold mix-blend-normal">
              {LL0().printing.cashier()}:
            </div>
            <div className="relative text-black-solid-black-900-1e1e23 text-right">
              {order0()?.users?.[0]}
            </div>
          </div>)}
      </div>
      {order0()?.customer && (
      <div className="self-stretch flex flex-row items-start justify-end pt-0 px-0 pb-2 gap-[8px] text-blue-solid-blue-400-0091ff border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch flex flex-row items-start justify-start gap-[4px] sm:flex-col sm:gap-[2px]">
            <b className="relative tracking-[-0.08px] leading-[15px] sm:text-xs">
              {LL0().onlineOrder.customer()}:
            </b>
            <div
              className="flex-1 relative leading-[15px] text-text-color-black-1e1e23-400 sm:text-xs sm:flex-[unset] sm:self-stretch">
              {order0()?.customerRaw?.company ? order0()?.customerRaw?.company + ', ' : ''} {order0()?.customerRaw?.name ? order0()?.customerRaw?.name + ', ' : ''} {order0()?.customerRaw?.phone}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[12px] sm:flex-col sm:gap-[2px]">
            <b className="relative tracking-[-0.08px] sm:text-xs">{LL0().onlineOrder.address()}:</b>
            <div
              className="flex-1 relative leading-[16px] text-text-color-black-1e1e23-400 sm:text-xs sm:flex-[unset] sm:self-stretch">
              <p className="m-0">{order0()?.customerRaw?.address}</p>
            </div>
          </div>
          <div className="w-[232px] hidden flex-row items-start justify-start sm:flex-col sm:gap-[2px_4px]">
            <div className="flex-1 relative leading-[16px] sm:text-xs sm:flex-[unset] sm:self-stretch">
              <b>
                <span>Note:</span>
              </b>
              <span className="text-black-solid-black-880-1d1d26">
                  <b>{` `}</b>
                  <span>
                    Allergic to cereal grains and do not eat spicy food
                  </span>
                </span>
            </div>
          </div>
          {/*<div*/}
          {/*  className="self-stretch flex flex-row items-start justify-center pt-1.5 px-0 pb-0 cursor-pointer"*/}
          {/*  onClick={openNewDeliveryAddEditCustomer}*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="w-6 relative h-6 object-cover"*/}
          {/*    alt=""*/}
          {/*    src="/iconnew-deliveryedit-customer-icon@2x.png"*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
          <img
            className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
            alt=""
            src="/iconshift-icon@2x.png"
          />
        </div>)}
      <div
        className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 text-black-solid-black-900-1e1e23 border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
        {/*filter empty items*/}
        {order0()?.items?.filter(item => item.quantity !== 0)?.map((item, index) => (
          <OrderHistoryListItem orderItem={item} _id={index.toString()} key={index}/>
        ))}
        {
          !!order0()?.cancellationItems?.length &&
            <div className="mt-2 font-bold text-sm">{LL0().pendingOrder.cancelledItems()}:</div>
        }
        <div className="mt-3 opacity-60 w-full">
          {order0()?.cancellationItems?.filter(item => item.quantity > 0)?.map((item, index) => (
            <OrderHistoryListItem orderItem={item} _id={index.toString()} key={index}/>
          ))}
        </div>
      </div>
      <div
        className="self-stretch rounded flex flex-col items-start justify-start gap-[4px_0px] text-gray-solid-gray-800-777777">
        {!isRefundOrder(order0()) &&  !!order0()?.vDiscount &&
        <div
          className="self-stretch flex flex-row items-end justify-between pt-0 px-0 pb-2 text-blue-solid-blue-400-0091ff border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
          <b className="relative">{LL0().orderHistory.promotionApplied()}</b>
          <div className="relative text-base text-black-solid-black-900-1e1e23 text-center">
              {LL3().format.currency(order0()?.vDiscount || 0)}
          </div>
        </div>
        }
        {!!order0()?.serviceFee && useServiceFeeBeforeTax() &&
        <div className="self-stretch flex flex-row items-start justify-between">
          <div className="relative">{LL0().payment.serviceFee()}:</div>
          <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right">
            {LL3().format.currency(order0()?.serviceFee || 0)}
          </div>
        </div>
        }
        {vTaxSumArray.map((tax, index) => (
          <Fragment key={index}>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative">{LL0().order.subtotal()}:</div>
              <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right">
                {LL3().format.currency(tax.net || 0)}
              </div>
            </div>
            <div
              className="self-stretch flex flex-row items-end justify-between pt-0 px-0 pb-2 border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
              <div className="relative">{LL0().order.tax()}({tax.taxPercent}%):</div>
              <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right">
                {LL3().format.currency(tax.tax || 0)}
              </div>
            </div>
          </Fragment>
        ))}

        <div className="self-stretch flex flex-row items-center justify-between text-blue-solid-blue-400-0091ff">
          <b className="relative">{LL0().common.total()}</b>
          <b className="relative text-lg text-blue-solid-blue-420-2979ff text-right sm:text-sm">
            {LL3().format.currency(order0()?.vTotal || 0)}
          </b>
        </div>
        {order0()?.payments?.length ? (
          <div
            className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-2 text-blue-solid-blue-400-0091ff border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
            <b className="relative">{LL0().order.payment()}:</b>
            <PaymentMethod payments={order0()?.payments}/>
          </div>) : null}
        <div className="self-stretch flex flex-row items-start justify-between">
          <b className="relative">{LL0().payment.tip()}:</b>
          <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right">
            {LL3().format.currency(order0()?.tip || 0)}
          </div>
        </div>
        {!!order0()?.serviceFee && !useServiceFeeBeforeTax() &&
        <div className="self-stretch flex flex-row items-start justify-between">
          <div className="relative">{LL0().payment.serviceFee()}:</div>
          <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right">
            {LL3().format.currency(order0()?.serviceFee || 0)}
          </div>
        </div>
        }
        <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
          <b className="relative">{LL0().payment.change()}:</b>
          <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right">
            {LL3().format.currency(order0()?.cashback || 0)}
          </div>
        </div>
      </div>
      <div
        className="self-stretch flex flex-row items-start">
        <img className={clsx("relative w-4 h-4 duration-300", showUserAction() && "-rotate-90 ")} alt=""
             src={'/ArrowCyanColor.png'} />
        <div
          onClick={() => {
            if (!order0?.()) return
            getUserActionsOrder(order0()).then()
            setShowUserAction(!showUserAction())
          }}
          className="self-stretch flex flex-row items-start justify-between text-cyan-solid-cyan-400-4ad4cd text-[12px]">
          <b className="relative">{showUserAction() ? `${LL0().monthlyReport.shortened()}` : `${LL0().settings.generalSetting.logUserActions()}`}</b>
        </div>
      </div>
      {showUserAction() && <div className="self-stretch flex flex-col items-start justify-between">
        {userActions0().map((action, index) => (
          <div key={index} className="self-stretch flex flex-row items-start gap-1">
            <div
              className="self-stretch flex flex-col items-start justify-between text-cyan-solid-cyan-400-4ad4cd text-[12px] font-thin">
              <b className="relative">{action}</b>
            </div>
          </div>
        ))}
      </div>}
    </div>
    </>
  );
};

export default OrderInformation;
