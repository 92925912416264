export type Server = {
  manageServer: {
    url: string
  }
  replicateServer: {
    couchdb: string
    password: string
    management: string
  }
  surrealDbCloud: {
    url: string
    username?: string
    password?: string
  }
  o2: {
    url: string
    metrics: string
    authToken: string
    logToken: string
    logSite: string
  }
  nats: {
    url: string
  }
}

export const CANADA_SERVER_1 = 'canada1'
export const CANADA_SANDBOX_SERVER = 'canada_sandbox'
export const GERMANY_SERVER_1 = 'germany1'
export const GERMANY_SERVER_2 = 'germany2'
export const ASIA_SERVER_1 = 'asia1'

export const servers: Record<string, Server> = {
  [CANADA_SERVER_1]: {
    manageServer: {
      url: 'https://manage.vegapos.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb-pika.gigasource.io',
      password: 'mBkrkOm11j9fR5rQcJwK',
      management: 'https://couchdb-pika.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal-pika.gigasource.io',
    },
    o2: {
      url: 'https://o2-pika.gigasource.io/api/default/traces',
      metrics: 'https://o2-pika.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206N3ppdzFucEhqQlZFVkpqWg==',
      logToken: 'rum2kAAdMNoyLa5qFsY',
      logSite: 'o2-pika.gigasource.io',
    },
    nats: {
      url: 'wss://nats-pika.gigasource.io',
    },
  },
  [CANADA_SANDBOX_SERVER]: {
    manageServer: {
      url: 'https://sandbox.manage.vegapos.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb-pika.gigasource.io',
      password: 'mBkrkOm11j9fR5rQcJwK',
      management: 'https://couchdb-pika.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal-ca.gigasource.io',
    },
    o2: {
      url: 'https://o2-pika.gigasource.io/api/default/traces',
      metrics: 'https://o2-pika.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206N3ppdzFucEhqQlZFVkpqWg==',
      logToken: 'rum2kAAdMNoyLa5qFsY',
      logSite: 'o2-pika.gigasource.io',
    },
    nats: {
      url: 'wss://nats-pika.gigasource.io',
    },
  },
  [GERMANY_SERVER_1]: {
    manageServer: {
      url: 'https://manage.wave-pos.de',
    },
    replicateServer: {
      couchdb: 'https://couchdb-moritz-de.gigasource.io/',
      password: 'hvDJ3wNNaqouvw9z000V',
      management: 'https://couchdb-moritz-de.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal.wave-pos.de',
    },
    o2: {
      url: 'https://o2-de-1.gigasource.io/api/default/traces',
      metrics: 'https://o2-de-1.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206SmRhbjdqREQyOVRPYU1HWg==',
      logToken: 'rum5bXjz46Vjy1vTRBT',
      logSite: 'o2-de-1.gigasource.io',
    },
    nats: {
      url: 'wss://nats-ger-1.gigasource.io',
    },
  },
  [GERMANY_SERVER_2]: {
    manageServer: {
      url: 'https://manage-de.gigasource.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb.gigasource.io',
      password: 'd8kpvWAhfQyJMDq65kpn',
      management: 'https://couchdb.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal-de.gigasource.io/',
      password: 'po0xuuGj08OY65z1',
    },
    o2: {
      url: 'https://o2-de-2.gigasource.io/api/default/traces',
      metrics: 'https://o2-de-2.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206c1BsblltT1U3R0w2NFh5eg==',
      logToken: 'rumj5UJv7lYRbsQ9Y0w',
      logSite: 'o2-de-2.gigasource.io',
    },
    nats: {
      url: 'wss://nats-ger-1.gigasource.io',
    },
  },
  [ASIA_SERVER_1]: {
    manageServer: {
      url: 'https://pos-manage.gigasource.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb-pika-sg.gigasource.io',
      password: 'ZXufGvfKmnsWqKkUy10R',
      management: 'https://couchdb-pika-sg.gigasource.io/_utils',
    },
    surrealDbCloud: {
      url: 'wss://ovh-db.gigasource.io',
    },
    o2: {
      url: 'https://o2-sg.gigasource.io/api/default/traces',
      metrics: 'https://o2-sg.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206NnR4ZmhmY1cwdTd3Y0J6TQ==',
      logToken: 'rumMgT6JZWgxCwp6LIW',
      logSite: 'o2-sg.gigasource.io',
    },
    nats: {
      url: 'wss://nats-sg.gigasource.io',
    },
  },
}
