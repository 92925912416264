import { FunctionComponent, useState } from "react";
import { CustomDish, getDrinkClassname, getFoodClassname } from "@/react/EditMenuView/EditMenuView.tsx";
import { kitchenGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { clsx } from "clsx";
import _ from "lodash";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { dataCustomDish } from "@/react/OrderView/OrderView.tsx";

export type EditMenuCustomDishPopuType = {
  className?: string;
  onClose?: () => void;
};

const EditMenuCustomDishPopu: FunctionComponent<EditMenuCustomDishPopuType> = ({
  className = "",
  onClose,
}) => {
  const [selectedDish, setSelectedDish] = useState<CustomDish | null>(CustomDish.FOOD); // Sử dụng enum để lưu trạng thái của món được chọn

  return (
    <div
      className={`w-full bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start gap-[16px] min-w-[520px] h-full max-w-full max-h-full text-left text-sm text-gray-solid-gray-600-979797 font-mulish ${className}`}
    >
      <div
        className="self-stretch bg-white-solid-white-400-f2f2f2 flex flex-row items-start justify-start pt-4 px-6 pb-0 gap-[4px]">
        <div
          className={`flex flex-col items-center justify-start gap-[5px] `}
          onClick={() => setSelectedDish(CustomDish.FOOD)}>
          <div className="flex flex-row items-center justify-center py-0 px-2">
            <div className="relative font-medium">FOOD</div>
          </div>
          <div
            className={`self-stretch relative bg-black-solid-black-1000-000000 h-px ${selectedDish === CustomDish.DRINK ? 'hidden' : ''}`} />
        </div>
        <div
          className={`flex flex-col items-center justify-start gap-[5px] `}
          onClick={() => setSelectedDish(CustomDish.DRINK)}>
          <div className="flex flex-row items-center justify-center py-0 px-2">
            <div className="relative font-medium">DRINK</div>
          </div>
          <div
            className={`self-stretch relative bg-black-solid-black-1000-000000 h-px ${selectedDish === CustomDish.FOOD ? 'hidden' : ''}`} />
        </div>
      </div>
      {
        selectedDish == CustomDish.DRINK && (
          <div
            className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-6 pb-6 gap-[16px] text-base text-black-solid-black-900-1e1e23">
            <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
              <div className="relative font-semibold md:text-xs">Printers:</div>
              <div className="flex flex-wrap items-center justify-start gap-[16px]">
                {kitchenGroupPrinters0().map((groupPrinter, index) => (
                  <div key={index}
                       className="flex items-center justify-start text-mini text-black-solid-black-880-1d1d26">
                    <div
                      onClick={() => {
                        if (!posSetting0()?.divArticleSettings?.drink || !posSetting0()?.divArticleSettings) {
                          const drink = {
                            drink: {
                              groupPrinter: groupPrinter._id,
                              tax: dataCustomDish()?.DivArticleSettings?.[0]?.drink.tax
                            }
                          };
                          _.assign(posSetting0(), { divArticleSettings: { ...posSetting0()?.divArticleSettings, ...drink } });
                        } else _.assign(posSetting0()?.divArticleSettings?.drink, { groupPrinter: groupPrinter._id });
                      }}
                      className={clsx('shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1', getDrinkClassname(groupPrinter))}
                    >
                      <div
                        className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                      <div className="relative z-[1]">{groupPrinter.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        )
      }
      {
        selectedDish == CustomDish.FOOD && (
          <div
            className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-6 pb-6 gap-[16px] text-base text-black-solid-black-900-1e1e23">
            <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
              <div className="relative font-semibold md:text-xs">Printers:</div>
              <div className="flex flex-wrap items-center justify-start gap-[16px]">
                {kitchenGroupPrinters0().map((groupPrinter, index) => (
                  <div key={index}
                       className="flex items-center justify-start text-mini text-black-solid-black-880-1d1d26">
                    <div
                      onClick={() => {
                        if (!posSetting0()?.divArticleSettings?.food || !posSetting0()?.divArticleSettings) {
                          const food = {
                            food: {
                              groupPrinter: groupPrinter._id,
                              tax: dataCustomDish()?.DivArticleSettings?.[0]?.food.tax
                            }
                          };
                          _.assign(posSetting0(), { divArticleSettings: { ...posSetting0()?.divArticleSettings, ...food } });
                        } else _.assign(posSetting0()?.divArticleSettings?.food, { groupPrinter: groupPrinter._id });
                      }}
                      className={clsx('shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1', getFoodClassname(groupPrinter))}
                    >
                      <div
                        className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                      <div className="relative z-[1]">{groupPrinter.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }


    </div>
  );
};

export default EditMenuCustomDishPopu;
