import { CloudOff, ErrorSharp, InfoOutlined, PrintOutlined } from '@mui/icons-material'
import { Chip, IconButton, Tooltip } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import type { Paths } from 'rxdb'

import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { LL0 } from '@/react/core/I18nService.tsx'
import { useRxQuery } from '@/react/utils/useRxQuery'

import { PRINT_MODES_DISPLAY, TRANS_TYPES_DISPLAY } from '../lib/constants'
import { OperationModes, PrintModes, PrintOptions } from '../lib/enum'
import { reformatDate } from '../lib/timezone'
import type { TransactionData } from '../lib/types'
import { reformatCurrency } from '../lib/utils'
import { isPrintDuplicateAllowed } from '../lib/utils.transaction'
import { srmLogic } from '../logic'
import { srmTransactionLogic } from '../transaction.logic'
import { transactionLogSelector } from './srm.logic'

interface Column<T> {
  id: Paths<T>
  label: string
  minWidth?: number
  align?: 'right'
  format?: (v: unknown, row: T) => string | JSX.Element
}
type SrmHistoryColum = Column<SrmTransactionLog>

const COL_WIDTHS = { amount: 100, badge: 70 } as const
const columns: readonly SrmHistoryColum[] = [
  {
    id: 'data.noTrans',
    label: 'no',
    minWidth: 140,
    format: (val, row) => (
      <span>
        <code>{val as string}</code>{' '}
        {!!row.response?.listErr?.length && (
          <Tooltip
            disableFocusListener
            enterTouchDelay={50}
            title={LL0().srm.srmView.transactionFailToCreated()}
          >
            <IconButton
              size="small"
              onClick={() => srmLogic.showTransactionError(row)}
            >
              <ErrorSharp
                color="error"
                fontSize="inherit"
              />
            </IconButton>
          </Tooltip>
        )}
        {!row.response && (
          <Tooltip
            disableFocusListener
            enterTouchDelay={50}
            title={LL0().srm.srmView.transactionCreatedInOfflineMode()}
          >
            <CloudOff
              fontSize="small"
              color="info"
            />
          </Tooltip>
        )}
      </span>
    ),
  },
  { id: 'data.nomUtil', label: 'user', minWidth: 120 },
  { id: 'data.sectActi.noTabl', label: 'table', minWidth: 80, format: v => (typeof v === 'string' ? v.replaceAll('=', '') : '-') },
  { id: 'data.typTrans', label: 'type', minWidth: 120, format: v => TRANS_TYPES_DISPLAY[v as keyof typeof TRANS_TYPES_DISPLAY] },
  {
    id: 'data.modTrans',
    label: 'mode',
    minWidth: COL_WIDTHS.badge,
    format: v => (
      <Chip
        size="small"
        label={v as string}
        variant={v === OperationModes.operating ? 'outlined' : 'filled'}
        color={v === OperationModes.operating ? 'default' : 'warning'}
      />
    ),
  },
  { id: 'data.datTrans', label: 'date', minWidth: 100, format: v => reformatDate(v as string) },
  {
    id: 'data.modImpr',
    label: 'print',
    minWidth: COL_WIDTHS.badge,
    format: (val, row) => (
      <Tooltip
        disableFocusListener
        enterTouchDelay={50}
        title={row.data.formImpr === PrintOptions.notPrinted ? '<Not Printed>' : 'Printed to ' + PRINT_MODES_DISPLAY[val as keyof typeof PRINT_MODES_DISPLAY]}
      >
        <Chip
          variant={row.data.formImpr === PrintOptions.notPrinted ? 'outlined' : 'filled'}
          size="small"
          label={val as string}
          color={
            val === PrintModes.cancellation
              ? 'warning'
              : val === PrintModes.failureToPay
              ? 'error'
              : val === PrintModes.reproduction
              ? 'default'
              : val === PrintModes.duplicate
              ? 'secondary'
              : val === PrintModes.bill
              ? 'primary'
              : undefined
          }
          disabled={row.data.formImpr === PrintOptions.notPrinted}
          onClick={() => srmLogic.displayPrintedTransaction(row)}
        />
      </Tooltip>
    ),
  },
  { id: 'data.mont.avantTax', align: 'right', label: 'net', minWidth: COL_WIDTHS.amount, format: v => reformatCurrency(v as string) },
  {
    id: 'data.mont.TPS',
    align: 'right',
    label: 'gstQst',
    minWidth: COL_WIDTHS.amount,
    format: (__, row) => <TaxCell data={row.data} />,
  },
  { id: 'data.mont.apresTax', align: 'right', label: 'total', minWidth: COL_WIDTHS.amount, format: v => reformatCurrency(v as string) },
  // { id: 'data.mont.versAnt', align: 'right', label: 'Before Adj', minWidth: 100, format: reformatCurrency },
]

function TaxCell(props: { data: TransactionData }): string | JSX.Element {
  return (
    <div>
      <p>{reformatCurrency(props.data.mont.TPS)}</p>
      <p>{reformatCurrency(props.data.mont.TVQ)}</p>
    </div>
  )
}

export default function TransactionsTable() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const pageData = useRxQuery(
    () =>
      SrmTransactionLog.find({ selector: transactionLogSelector() })
        .sort({ date: 'desc' })
        .skip(page * rowsPerPage)
        .limit(rowsPerPage),
    [page, rowsPerPage, transactionLogSelector()]
  )
  const totalCount = useRxQuery(() => SrmTransactionLog.count({ selector: transactionLogSelector() }), [transactionLogSelector()])

  const handleChangePage = useCallback((_: unknown, newPage: number) => {
    setPage(newPage)
  }, [])

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }, [])

  return !totalCount ? (
    <h1>{LL0().srm.srmView.noTransaction()}</h1>
  ) : (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table
          stickyHeader
          size="small"
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  padding="normal"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {_.get(LL0().srm.srmView.table, column.label)?.()}
                </TableCell>
              ))}
              {/* Row actions */}
              <TableCell style={{ minWidth: 60 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData?.map(row => {
              const allowPrintDuplicate = isPrintDuplicateAllowed(row)
              return (
                <TableRow
                  tabIndex={-1}
                  key={row._id}
                  data-sent={row.sent}
                  className="data-[sent=false]:bg-slate-100"
                  onDoubleClick={() => console.log('ℹ️ Transaction', row.toJSON())}
                >
                  {columns.map(column => {
                    const value = _.get(row, column.id)
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                      >
                        {column.format ? column.format(value, row) : typeof value !== 'string' ? <TableCell key={column.id} /> : value}
                      </TableCell>
                    )
                  })}
                  {/* Row actions */}
                  <TableCell>
                    <div className="flex gap-1">
                      <IconButton
                        disabled={!allowPrintDuplicate}
                        color="primary"
                        onClick={() => srmTransactionLogic.printDuplicate(row._id)}
                      >
                        <PrintOutlined fontSize="inherit" />
                      </IconButton>
                      <IconButton onClick={() => srmLogic.showTransactionDetails(row)}>
                        <InfoOutlined fontSize="inherit" />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={LL0().srm.srmView.table.rowsPerPage()}
      />
    </Paper>
  )
}
