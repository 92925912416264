import { menus0 } from '@/data/MenuHub'
import { onlineProviders0 } from '@/data/OnlineProviderHub'
import { computed, signal } from '@/react/core/reactive'

/*******************[ States ]*********************/
const [selectedMenuId, setSelectedMenuId] = signal<string | undefined>()

/*******************[ Derived States ]*********************/
/** Enabled Menus - Having at least one active & enabled OnlineProvider */
const menus = computed(() => menus0().filter(a => a.providers?.some(a => onlineProviders0().find(b => b._id === a)?.isEnabled)))

/*******************[ Exported States ]*********************/
export { selectedMenuId, menus }

/*******************[ Handlers ]*********************/
export const HANDLERS = {
  selectMenu: (id: string) => setSelectedMenuId(id),
}
