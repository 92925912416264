import { type FunctionComponent, useEffect } from "react";
import {signal, useEffectOn, useSignal} from "@/react/core/reactive.ts";
import Input, { type InputController, inputController0 } from "@/react/core/Input.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { currentItem, onEditItem } from "@/react/OrderView/OrderView.tsx";
import {LL0} from "@/react/core/I18nService.tsx";
import {locale} from "@/react/core/I18nBackend.tsx";

export type EditItemFormType = {
  showEditItemForm?: boolean;
};

export const [inputControllers, setInputControllers] = signal<Array<InputController>>([]);

const EditItemForm: FunctionComponent<EditItemFormType> = ({
  showEditItemForm,
}) => {
  const [editItem, setEditItem] = useSignal<{name: string, price: string}>({name: "", price: ""})

  useEffect(() => {
    setEditItem({
      name: currentItem()?.name || "",
      price: currentItem()?.price.toString()
    })
    inputControllers().forEach(inputController => inputController.moveCaretToEnd())
  }, []);
  // useEffectOn([editItem], () => {
  //     setEditItem({
  //       name: currentItem().name || "",
  //       price: currentItem().price.toString()
  //     })
  //     inputControllers().forEach(inputController => inputController.moveCaretToEnd())
  // })

  return (
    showEditItemForm && (
      <div className="edit-item-popup !flex w-[602px] flex-1 hidden flex-col items-end justify-between pt-4 pb-6 pr-5 pl-1 box-border z-[0] text-left text-smi text-black-solid-black-1000-000000 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start pt-0 pb-6 pr-0 pl-2 gap-[16px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
            <div className="w-[52px] relative flex items-center shrink-0">
              {LL0().settings.name()}:
            </div>
            <div className="flex-1 flex flex-row items-center justify-start">
              <Input
                className="w-full font-mulish italic text-smi text-gray-400"
                refInputController={i => {
                  i.setFocus(true);
                  i.moveCaretToEnd();
                  return setInputControllers?.([i])
                }}
                value={editItem().name}
                onChange={value => setEditItem(prev => ({...prev, name: value}))}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-4">
            <div className="w-[52px] relative flex items-center shrink-0">
              {LL0().fnBtn.price()}:
            </div>
            <div className="flex-1 flex flex-row items-center justify-start">
              <Input
                className="w-full font-mulish italic text-smi text-gray-400"
                value={editItem().price}
                refInputController={i => {
                  i.moveCaretToEnd();
                  return setInputControllers?.([i])
                }}
                onChange={value => setEditItem(prev => ({...prev, price: value}))}
              />
            </div>
          </div>
        </div>
        {/*<div className="w-[121px] rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 box-border text-center text-sm text-white">*/}
        {/*  <b className="relative">Save</b>*/}
        {/*</div>*/}
        <TableManualKeyboard
          value={''}
          inputController={inputController0}
          onEnter={() => onEditItem(editItem().name, Number(editItem().price))}
        />
      </div>
    )
  );
};

export default EditItemForm;