import { type FunctionComponent, useCallback, useState } from "react";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import PortalPopup from "./PortalPopup";
import TableManualKeyboard from "@/react/core/TableManualKeyboard";
import { LL0 } from "@/react/core/I18nService.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import {
  CashbookTransactionsAction,
  handleAddNewType, handleChangeType, handleDeleteType,
  newTypeValue, setDeleteTypeIndex,
  setNewType
} from "@/react/CashbookView/CashbookView.tsx";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";

export type CashbookEditTypePopupType = {
  className?: string;
  onClose?: () => void;
  type?: CashbookTransactionsAction;
};

const CashbookEditTypePopup: FunctionComponent<CashbookEditTypePopupType> = ({
  className = "",
  onClose,
  type,
}) => {
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback((typeIndex: number) => {
    setDeleteTypeIndex(typeIndex)
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteTypeIndex(undefined)
    setDeleteConfirmationPopupOpen(false);
  }, []);

  const handleCompletePopup = useCallback(() => {
    handleDeleteType(type)
    closeDeleteConfirmationPopup()
  }, []);

  return (
    <>
      <div
        className={`w-full h-full relative rounded-md bg-white-solid-white-100-ffffff flex flex-col items-start justify-start py-[34px] px-0 box-border gap-5 min-w-[520] max-h-[480] text-left text-base text-black-solid-black-900-1e1e23 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-5 hover:items-start hover:justify-start hover:rounded-md hover:py-[34px] hover:px-0 hover:box-border hover:min-w-[520] hover:max-h-[480] ${className}`}
      >
        <div className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-center justify-start gap-5 z-[0]">
          <div className="self-stretch flex flex-col items-start justify-center gap-1.5">
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
              <div className="relative font-semibold md:text-xs">
                {LL0().cashbook.addNewType()}:
              </div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-start py-0 px-6 gap-3 text-center text-sm text-white-solid-white-100-ffffff">
              <Input
                className='w-full'
                value={newTypeValue()}
                onChange={(value) => setNewType(
                  value)}
              />
              <div
                className="px-1 rounded-10xs bg-blue-solid-blue-420-2979ff h-[37px] overflow-hidden shrink-0 flex flex-row items-center justify-center height_414:h-[30px]"
                ref={makeRipple}
                onClick={() => handleAddNewType(newTypeValue(), type)}
              >
                <b className="relative flex items-center justify-center h-8 shrink-0">
                  {LL0().ui.add()}
                </b>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-1.5">
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
              <div className="relative font-semibold md:text-xs">
                {LL0().cashbook.editAvailableType()}:
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-1.5">
              {type === CashbookTransactionsAction.INCOMING ?
                posSetting0()?.cashbook?.incomingTypes?.map((typeValue, index) => (
                  <div
                    key={index}
                    className="self-stretch bg-gray-solid-gray-130-f4f4f4 flex flex-col items-start justify-start py-2.5 px-0">
                    <div className="self-stretch flex flex-row items-center justify-start py-0 px-6 gap-3">
                      <Input
                        className='w-full'
                        value={typeValue}
                        onChange={(value) => {
                          handleChangeType(value, index, type)
                        }}
                      />
                      <div
                        className="w-12 rounded-10xs bg-red-solid-red-240-eb5458 h-[37px] flex flex-row items-center justify-center cursor-pointer"
                        onClick={() => openDeleteConfirmationPopup(index)}
                      >
                        <img
                          className="w-8 relative h-8 object-cover"
                          alt=""
                          src="/iconbin-icon--white@2x.png"
                        />
                      </div>
                    </div>
                  </div>))
                :
                posSetting0()?.cashbook?.outgoingTypes?.map((typeValue, index) => (
                  <div
                    key={index}
                    className="self-stretch bg-gray-solid-gray-130-f4f4f4 flex flex-col items-start justify-start py-2.5 px-0">
                    <div className="self-stretch flex flex-row items-center justify-start py-0 px-6 gap-3">
                      <Input
                        className='w-full'
                        value={typeValue}
                        onChange={(value) => {
                          handleChangeType(value, index, type)
                        }
                        }
                      />
                      <div
                        className="w-12 rounded-10xs bg-red-solid-red-240-eb5458 h-[37px] flex flex-row items-center justify-center cursor-pointer"
                        onClick={() => openDeleteConfirmationPopup(index)}
                      >
                        <img
                          className="w-8 relative h-8 object-cover"
                          alt=""
                          src="/iconbin-icon--white@2x.png"
                        />
                      </div>
                    </div>
                  </div>))
              }
            </div>
          </div>
        </div>
        <img
          className="w-6 absolute !m-[0] top-[10px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[1]"
          alt=""
          src="/close-popup-icon--grey@2x.png"
          onClick={onClose}
        />
        <TableManualKeyboard
          inputController={inputController0}
        />
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onComplete={handleCompletePopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default CashbookEditTypePopup;