import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { convertToBase64Png, createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import uuid from "time-uuid";
import { currentPrinter } from "@/react/Printer/PrinterSettingView.tsx";
import { toast } from "react-toastify";
import { captureException } from "@sentry/react";
import EscPrinter from "@/shared/printer/node-thermal-printer";

export async function printNetwork(data: { payload: string; uuid: string }){
  try {
    await rnHost.printTcp(data)
  }
  catch (e) {
    notificationToast("Connection failed", {
      type: "error",
      autoClose: 1000 * 60
    })
  }
}

export const testNetworkPrinter = async () => {
  const ip = currentPrinter()?.ip;
  if (!ip) {
    toast.error("missing ip")
    return
  }
  const _uuid = uuid();
  const printer = createPrinter();
  await printer.println("TEST");
  const raster: Raster = (await printer.print())!;
  const printer2 = new EscPrinter({ ip, printerType: 'ip', dropConnection: currentPrinter()?.dropConnection});
  await printer2.printRaster(raster);

  try {
    await printer2.print();
  }
  catch (e: any) {
    captureException(new Error(`print to ip: ${ip} error ${e.message}`), { tags: { type: 'print' } });
    notificationToast(`Error: ${e.message}`, {
      type: "error",
      autoClose: 1000 * 60
    })
  }
}