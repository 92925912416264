import { signal } from "@/react/core/reactive.ts";
import { RecordId } from "surrealdb.js";
import rdiff from "recursive-diff";

export const [syncitApi, setSyncitApi] = signal<any>()
export const [requestLoginId, setRequestLoginId] = signal<string | undefined>()
export const [isStop, setIsStop] = signal<boolean>(true)
export const [vRecord, setVRecord] = signal<number>()


export interface FirstDomSnapshot {
  id: RecordId<'FirstDomSnapshot'>,
  data: string,
  storeId: number,
  screen: string,
  date: number,
  outDate: boolean
}

export const screensMap = [
  { screenName: 'Passcode', class: "passcode-screen" },
  { screenName: 'EOD', class: "eod-screen" },
  { screenName: 'EditMenu', class: "edit-menu-screen" },
  { screenName: 'EditFloorPlan', class: "edit-floor-plan-screen" },
  { screenName: 'EditUser', class: "edit-user-screen" },
  { screenName: 'Payment', class: "payment-screen" }, //
  { screenName: 'OrderHistory', class: "order-history-screen" },
  { screenName: 'CustomerInfo', class: "customer-info-screen" },
  { screenName: 'PrinterSetting', class: "printer-setting-screen" },
  { screenName: 'VirtualPrinter', class: "virtual-printer-screen" },
  { screenName: 'PendingOrders', class: "pending-orders-screen" },
  { screenName: 'PaymentSetting', class: "payment-setting-screen" },
  { screenName: 'CompanyInformation', class: "company-information-screen" },
  { screenName: 'MonthlyReport', class: "monthly-report-screen" },
  { screenName: 'HappyHour', class: "happy-hour-screen" },
  { screenName: 'Reservation', class: "reservation-screen" },
  { screenName: 'GeneralSetting', class: "general-setting-screen" },
  { screenName: 'StaffReport', class: "staff-report-screen" },
  { screenName: 'EditOnlineMenu', class: "edit-online-menu-screen" },
  { screenName: 'MasterMachine', class: "master-machine-screen" },
  { screenName: 'ModifierListView', class: "modifier-list-screen" },
  { screenName: 'ModifierEditingView', class: "modifier-editing-screen" },
  { screenName: 'Inventory', class: "inventory-screen" },
  { screenName: 'Cashbook', class: "cashbook-screen" },
  { screenName: 'OnlineOrderSetting', class: "online-order-setting-screen" },
  { screenName: 'OnlineProvider', class: "online-provider-setting" },
  { screenName: 'ManualTable', class: "manual-table-screen" },
  { screenName: 'NewDelivery', class: "new-delivery-screen" },
  { screenName: 'Terminal', class: "terminal-screen" },
  { screenName: 'EditProduct', class: "edit-product-screen" }, //online menu
  { screenName: 'PSplitBillPayment', class: "split-bill-payment" },
  { screenName: 'PSplitBillOrder', class: "split-bill-order" },
  { screenName: 'PSplitItem', class: "split-item-popup" },
  { screenName: 'PEditModifier', class: "edit-modifier-popup" },
  { screenName: 'PDiscount', class: "discount-popup" },
  { screenName: 'PEditItem', class: "edit-item-popup" },
  { screenName: 'PVoucherOrder', class: "voucher-popup-order" },
  { screenName: 'PVoucherPayment', class: "voucher-popup-payment" },
  { screenName: 'PInput', class: "input-popup" },
  { screenName: 'PAddLabelOrder', class: "add-label-order" },
  { screenName: 'PAddLabelPayment', class: "add-label-payment" },
  { screenName: 'PNewPayment', class: "new-payment-popup" },
  { screenName: 'PNewTerminal', class: "new-terminal-popup" },
  { screenName: 'PNewTax', class: "new-tax-popup" },
  { screenName: 'PXReport', class: "x-report-popup" },
  { screenName: 'PZReport', class: "z-report-popup" },
  { screenName: 'PPaymentOrdHistory', class: "payment-ord-history" },
  { screenName: 'PRefund', class: "refund-popup" },
  { screenName: 'CategoryListRow', class: "category-list-row" },
  { screenName: 'PaymentButtonMore', class: "payment-button-more" },
  { screenName: 'OrderButtonMore', class: "order-button-more" },
  { screenName: 'PopupReport', class: "report-cashbook-popup" },
  { screenName: 'PopupDashboard', class: "dashboard-setting-popup" },
  { screenName: 'ReservationPopup', class: "new-reservation-popup" },
];

export const iconMap = [
  { screenName: 'Icon_1', class: "iconaddseat-icon--%123" },
  { screenName: 'Icon_2', class: "select-item-icon--%123" },
  { screenName: 'Icon_3', class: "iconsplit-icon--%123" },
  { screenName: 'Icon_3_payment', class: "iconsplit-icon-payment--%123" },
  { screenName: 'Icon_3_order', class: "iconsplit-icon-order--%123" },
  { screenName: 'Icon_4', class: "iconprinter-icon-31--%123" },
  { screenName: 'Icon_5', class: "iconpay-icon--%123" },
  { screenName: 'Icon_6', class: "plusvector1--%123" },
  { screenName: 'Icon_7', class: "minusvector-red--%123" },
  { screenName: 'Icon_8', class: "table-clock-icon--%123" }
]
export const iconClassRegex = () => {
  return new RegExp(iconMap.map(screen => screen.class).join("|"));
}
export const screenClassRegex = () => {
  return new RegExp(screensMap.map(screen => screen.class).join("|"));
}
const words: { [k: string]: string } = {
  'data': 'd',
  'texts': 't',
  'id': 'i0',
  'parentId': 'd1',
  'value': 'v',
  'attributes': 'a0',
  'removes': 'r',
  'isShadow': 'iS0',
  'node': 'n',
  'isStyle': 'iS1',
  'rootId': 'r1',
  'adds': 'a1',
  'isSVG': 'iS2',
  'isCustom': 'iC',
  'timestamp': 't1',
  'nextId': 'n0'
};

const ops = {
  'update': 'u',
  'delete': 'd',
  'add': 'a'
};

export function patch2(originStr: string, event: any) {
  const diffs = rdiff.getDiff(JSON.parse(originStr), event);
  const diffs2 = diffs.map(d => {
    const op = ops[d.op];
    const items = [op, d?.val ? JSON.stringify(d.val) : '_u', ...d.path.map(p => {
      if (typeof p === 'string') {
        if (words[p]) return words[p];
        return p;
      }
      return p;
    })];
    return items.join('^');
  });
  return diffs2.join('|');
}

export function generateRandomId() {
  const min = 100000;
  const max = 999999;
  return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
}