import { clearStaffStatusOnAllTables } from '@/data/TableStaffStatusHub'
import { onExitScreens, PosScreen } from '@/pos/PosRouter'
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";

export function effectClearTableStaffStatus() {
  onExitScreens([PosScreen.ORDER, PosScreen.PAYMENT], async () => {
    if (isQuebecSrmEnabled()) {
      // Since one device is only used by one staff, we can clear all tables here
      // TODO: Check if conflict with updating the status
      await clearStaffStatusOnAllTables()
    }
  })
}
