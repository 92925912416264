import { EodCache } from "@/data/EodCache.ts";
import { convertDocuments } from "@/data/data-utils.ts";
import { Eod, type EodDocument } from "@/data/Eod.ts";
import { Buffer } from "buffer";
import pako from "pako";
import _ from "lodash";
import { getInflateReport } from "@/tse/dsfinv/dsfinv-convert.ts";
import { clearEodCache } from "@/pos/logic/order-utils.ts";
import delay from "delay";
import { toast } from "react-toastify";
import { getVDate } from "@/pos/orderUtils";
import dayjs from "dayjs";
import { PaidOrder } from "@/data/Order.ts";
import { getEodReportPeriod } from "@/pos/logic/eod-calendar-utils";
import { now } from "@/pos/logic/time-provider.ts";


function flattenObject(obj) {
  return _.flatMap(_.values(obj), value =>
    _.isObject(value) ? _.values(value) : value
  );
}


export async function addTaxGroupEod() {
  return {
    getCompleteEod,
    solveCompleteOrder,
  }

  async function getCompleteEod() {
    const _eods = await Eod.find({selector: {complete: true}}).exec();
    const eods = convertDocuments(_eods, true);

    for (const eod of eods) {
      eod.complete = false;
    }
  }

  async function solveCompleteOrder() {
    let reports;

    const completeEods = await Eod.find({selector: {complete: true}}).exec();
    const cEods = convertDocuments(completeEods, true);

    const rawReport = await getReportData();
    if (rawReport) {
      reports = flattenObject(rawReport)
    }

    if (reports && completeEods) {
      for (const eod of completeEods) {
        const cEod = cEods.find(e => e.vDate === eod.vDate);
        const inflateEodReport = getInflateReport(cEod!);
        if (inflateEodReport && !inflateEodReport?.hasOwnProperty('taxGroup')) {
          const foundTaxGroupEod = reports.find(r => r.from === inflateEodReport.from && r.to === inflateEodReport.to)?.taxGroup;
          if (foundTaxGroupEod) {
            _.assign(inflateEodReport, {taxGroup: foundTaxGroupEod});
            const finalReport = Buffer.from(pako.deflate(JSON.stringify(inflateEodReport))).toString('base64');
            await eod.incrementalPatch(
              {
                report: finalReport
              } as unknown as EodDocument // Temp fix for type mismatch (the `report` field)
            )
          }

        }
      }
    }

    toast.success('Loading tax group', {autoClose: 600})
  }

  async function getReportData() {
    await clearEodCache();
    const earliestPaidOrder = await PaidOrder
      .findOne()
      .sort({ vDate: 'asc' })
      .exec();

    if (earliestPaidOrder) {
      const from = getVDate(dayjs(earliestPaidOrder.vDate).subtract(1, 'day').valueOf())
      const to = getVDate(dayjs().endOf('day').add(1, 'day').valueOf())

      const report = await getEodReportPeriod(from, to)
      return report
    }
  }

}

async function fixTaxGroup() {
  const api = await addTaxGroupEod();
  await api.solveCompleteOrder();

  //fixme: manual fix
  // await api.getCompleteEod()
  // await clearEodCache();
}


// @ts-ignore
window.fix6 = fixTaxGroup;