import _ from "lodash";
import dayjs from "dayjs";
import type { Eod } from "@/data/Eod.ts";
import {masterDeviceSetting} from "@/data/DeviceSettingHub.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";

const Stamm_Agenturen = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse",
    default: '1'
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses",
    mapping: 'end',
    format: d => dayjs(d).toISOString()
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses",
    mapping: 'z'
  },
  AGENTUR_ID: {
    type: "number",
    places: 0,
    hint: "ID der Agentur"
  },
  AGENTUR_NAME: {
    type: "string",
    maxLength: 60,
    hint: "Name des Auftraggebers"
  },
  AGENTUR_STRASSE: {
    type: "string",
    maxLength: 60,
    hint: "Straße"
  },
  AGENTUR_PLZ: {
    type: "string",
    maxLength: 10,
    hint: "Postleitzahl"
  },
  AGENTUR_ORT: {
    type: "string",
    maxLength: 62,
    hint: "Ort"
  },
  AGENTUR_LAND: {
    type: "string",
    maxLength: 3,
    hint: "Land"
  },
  AGENTUR_STNR: {
    type: "string",
    maxLength: 20,
    hint: "Steuernummer des Auftraggebers"
  },
  AGENTUR_USTID: {
    type: "string",
    maxLength: 15,
    hint: "USTID des Auftraggebers"
  }
}

export function Stamm_AgenturenFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG() ,
      Z_NR: Z_NR(),
      AGENTUR_ID: AGENTUR_ID(),
      AGENTUR_NAME: AGENTUR_NAME(),
      AGENTUR_STRASSE: AGENTUR_STRASSE(),
      AGENTUR_PLZ: AGENTUR_PLZ(),
      AGENTUR_ORT: AGENTUR_ORT(),
      AGENTUR_LAND: AGENTUR_LAND(),
      AGENTUR_STNR: AGENTUR_STNR(),
      AGENTUR_USTID: AGENTUR_USTID()
    }
  }

  function Z_KASSE_ID() {
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return eod.z;
  }

  function AGENTUR_ID() {
    return 1;
  }


  function AGENTUR_NAME() {

  }

  function AGENTUR_STRASSE() {

  }

  function AGENTUR_PLZ() {

  }

  function AGENTUR_ORT() {

  }

  function AGENTUR_LAND() {

  }

  function AGENTUR_STNR() {

  }

  function AGENTUR_USTID() {

  }

}