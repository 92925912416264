import type {FunctionComponent} from "react";
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import {LL0} from "@/react/core/I18nService.tsx";
import Input, {type InputController, inputController0} from "@/react/core/Input.tsx";
import {useSignal} from "@/react/core/reactive.ts";
import {
  filterInventoryValues,
  setFilterInventoryValues
} from '@/data/InventoryHub.ts'

type InventoryStockFilterPopType = {
  onClose?: () => void;
};

const InventoryStockFilterPop: FunctionComponent<
  InventoryStockFilterPopType
> = ({ onClose }) => {
  const [inputControllers, setInputControllers] = useSignal<Array<InputController>>([]);

  const [stock, setStock] =
    useSignal<{min: number, max: number}>({min: filterInventoryValues().stock?.min || 0, max: filterInventoryValues().stock?.max || 0})

  return (
    <div className="relative rounded-md bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[520] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[24px] hover:items-start hover:justify-start hover:rounded-md hover:pt-8 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[520]">
      <div className="self-stretch flex flex-col items-start justify-center gap-[10px] z-[0]">
        <div className="relative font-medium md:text-xs">{LL0().inventory.stock()}:</div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[4px] text-black-solid-black-600-424242">
          <Input
            className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
            label={LL0().inventory.filter.min()}
            refInputController={i => setInputControllers(() => [i])}
            value={stock()?.min.toString() || ''}
            onChange={value => setStock(prev => ({...prev, min: Number(value)}))}
          />
          <div className="relative md:text-xs">-</div>
          <Input
            className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
            label={LL0().inventory.filter.max()}
            refInputController={i => setInputControllers(() => [i])}
            value={stock()?.max.toString() || ''}
            onChange={value => setStock(prev => ({...prev, max: Number(value)}))}
          />
        </div>
      </div>
      <TableManualKeyboard
        inputController={inputController0}
        value={''}
        onEnter={() => {
          setFilterInventoryValues(prev => ({...prev, stock: stock()}))
          onClose?.()
        }}
      />
      <img
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icongeneralclose-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default InventoryStockFilterPop;
