import { FunctionComponent, useCallback, useState } from 'react'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import HappyHourWeekdayItem from '@happy-hour/HappyHourWeekdayItem'
import { discountTypeLabels, getHappyHourWeekdays, happyHour0 } from '@/react/HappyHourView/HappyHourView.tsx'
import dayjs from 'dayjs'
import _ from 'lodash'
import HappyHourDeleteConfirmati from '@happy-hour/HappyHourDeleteConfirmati.tsx'
import PortalPopup from '@happy-hour/PortalPopup.tsx'
import HappyHourDiscountTypeItem from '@happy-hour/HappyHourDiscountTypeItem.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import TextField from '@/react/core/TextField.tsx'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

const HappyHourRightPath: FunctionComponent = () => {
  const timePickerProps = (type: 'from' | 'to') => ({
    value: dayjs(happyHour0()?.[type] || '00:00', 'HH:mm'),
    onChange: (newValue: any) => _.assign(happyHour0(), { [type]: newValue.format('HH:mm') })
  })

  const [fromTimeInputValue, setFromTimeInputValue] = useState<string | null>(
    null
  )
  const [toTimeInputValue, setToTimeInputValue] = useState<string | null>(null)
  const [isHappyHourDeleteConfirmatiOpen, setHappyHourDeleteConfirmatiOpen] =
    useState(false)

  const openHappyHourDeleteConfirmati = useCallback(() => {
    setHappyHourDeleteConfirmatiOpen(true)
  }, [])

  const closeHappyHourDeleteConfirmati = useCallback(() => {
    setHappyHourDeleteConfirmatiOpen(false)
  }, [])

  const discountType = useCallback(() => {
    if (happyHour0()?.discountType === 'amount') {
      return `- ${LL3().common.currency()}`
    } else if (happyHour0()?.discountType === 'fixed') {
      return `= ${LL3().common.currency()}`
    } else return '- %'
  }, [happyHour0()?.discountType])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className="self-stretch flex-1 overflow-y-auto flex flex-col items-end justify-between py-[26px] px-[35px] text-left text-sm text-black font-mulish Tablet_768:pl-6 Tablet_768:pr-6 Tablet_768:box-border">
        <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            <b className="relative Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeeks()}</b>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[11px]">
              {getHappyHourWeekdays().map((weekDay, index) =>
                <HappyHourWeekdayItem weekday={weekDay} isSelected key={index} weekdayIndex={index} />)}
            </div>
          </div>
          <div
            className="self-stretch gap-[20px] grid grid-cols-[repeat(1,minmax(0px,1fr))_repeat(1,minmax(0px,1fr))] [grid-row-gap:20px] [grid-column-gap:20px] sm:grid sm:grid-cols-[repeat(1,minmax(0px,1fr))_repeat(1,minmax(0px,1fr))] sm:[grid-row-gap:20px] sm:[grid-column-gap:20px]">
            <div className="flex flex-col items-start justify-center gap-[11px] relative">
              <b className="relative Tablet_768:text-xs">{LL0().onlineOrder.from()}</b>
              <div className="self-stretch">
                <TimePicker
                  // label={LL0().settings.happyHour.from()}
                  ampm={false}
                  {...timePickerProps('from')}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      size: 'small',
                      fullWidth: true,
                      color: 'primary'
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-center gap-[11px] relative">
              <b className="relative Tablet_768:text-xs">{LL0().onlineOrder.to()}</b>
              <div className="self-stretch">
                <TimePicker
                  // label={LL0().settings.happyHour.to()}
                  ampm={false}
                  {...timePickerProps('to')}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      size: 'small',
                      fullWidth: true,
                      color: 'primary'
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
            <b className="relative Tablet_768:text-xs">{LL0().settings.happyHour.discount()}</b>
            <div className="flex flex-row flex-wrap items-end justify-start gap-[11px]">
              {_.entries(discountTypeLabels).map(([value, labels]) =>
                <HappyHourDiscountTypeItem typeLabel={labels.itemLabel} isSelected value={value} key={value} />)}
            </div>
            <div
              className="w-full flex-1 rounded bg-white box-border h-[39px] flex flex-row items-center justify-start relative text-smi text-black-solid-black-1000-000000 border-[1px] border-solid border-silver-100">
              <div
                className="self-stretch rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs bg-gray-solid-gray-130-f4f4f4-200 box-border w-[40.2px] flex flex-row items-center justify-center relative gap-[0px_8px] z-[1] border-r-[0.4px] border-solid border-silver-100">
                <div
                  className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs bg-cyan-solid-cyan-170-ceffff box-border hidden z-[0] border-r-[0.2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                <div
                  className="relative font-semibold z-[1]">{discountType()}</div>
              </div>
              <TextField
                className="text-sm font-mulish italic text-smi text-gray-400 w-full border-0"
                value={happyHour0()?.discountValue || ''}
                onChange={e => _.assign(happyHour0(), { discountValue: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div
          className="bg-basic-color-white-solid-white-100-ffffff shadow-[0px_4px_6px_rgba(0,_0,_0,_0.2)] w-44 h-10 shrink-0 flex flex-row items-center justify-center gap-[9px] cursor-pointer text-smi text-black-solid-black-880-1d1d26"
          onClick={openHappyHourDeleteConfirmati}
        >
          <img
            className="relative w-4 h-[17.4px] object-cover"
            alt=""
            src="/red-bin-icon@2x.png"
          />
          <b className="relative Tablet_768:text-2xs">{LL0().settings.happyHour.deleteHappyHour()}</b>
        </div>
      </div>

      {isHappyHourDeleteConfirmatiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeHappyHourDeleteConfirmati}
        >
          <HappyHourDeleteConfirmati
            onClose={closeHappyHourDeleteConfirmati}
          />
        </PortalPopup>
      )}
    </LocalizationProvider>
  )
}

export default HappyHourRightPath
