import { Order, PaidOrder } from "@/data/Order";
import { computed, effectOn, signal } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";
import { OrderItem, OrderStatus, OrderStrip, TseMethod } from "@/pos/OrderType.ts";
import { debugTse, getItemsFromTransaction } from "@/tse/tse-init.ts";
import { maxId0 } from "@/data/MaxIdHub.ts";
import { Subscription } from "rxjs";
import { TseTransaction, VorgangArt } from "@/data/TseTransaction.ts";
import _ from 'lodash';
import { renderPivotTable } from "@/pos/logic/pivot.js";
import rdiff from "recursive-diff";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { processTypes } from "@/tse/dsfinv/dsfinvModel.ts";
import { captureException } from "@sentry/react";

export const [orders1, setOrders1] = signal<Array<OrderStrip>>([]);
export const [orders2, setOrders2] = signal<Array<OrderStrip>>([]);
export const [tseTransactions0, setTseTransactions0] = signal<Array<TseTransaction>>([]);
const orderTseTransactions = computed(() => tseTransactions0().filter(t => t.TSE_TA_VORGANGSART === VorgangArt.BestellungV1))
const invoiceTseTransactions = computed(() => tseTransactions0().filter(t => t.TSE_TA_VORGANGSART === VorgangArt.KassenbelegV1))
export const orders0 = computed(() => [...orders1(), ...orders2()]);
export const [orderV, setOrderV] = signal(0);
//fixme: choose 0 or 1 base on tse enable
export const [orderZ, setOrderZ] = signal(0);

let rxSub1: Subscription,
  rxSub2: Subscription,
  rxSub3: Subscription;

effectOn([orderV, maxId0], async () => {
  await dataLock.acquireAsync()
  if (!tseConfig0()?.tseEnable) return;
  if (!maxId0()) return;
  if (rxSub1) {
    rxSub1.unsubscribe();
    rxSub2.unsubscribe();
    rxSub3.unsubscribe();
  }
  //fixme: wait for unused
  // const rxQuery2 = PaidOrder.find({ selector: { z: maxId0().z } });
  // rxSub2 = rxQuery2.$.subscribe((change) => {
  //   setOrders2(change.map(o => o.toMutableJSON()));
  // })
}, { defer: true });

effectOn([orders2], _.debounce(async () => {
  if (orders0().length === 0) return;
  await dataLock.acquireAsync();

  function getIds() {
    const ids = orders0().map(o => o._id);
    const ids2 = _.uniq(_.compact(orders0().map(o => o.splitId)));
    ids.push(...ids2);
    return ids;
  }

  const _transactions = await TseTransaction.find({ selector: { order: { $in: getIds() } } }).exec();
  setTseTransactions0(_transactions.map(t => t.toMutableJSON()));
  //todo: query transaction base on orders0 , use $in
  const items0 = orders0().reduce<OrderItem[]>((list, order) => {
    return [...list, ...order.items!];
  }, []);
  const cancellationItems0 = orders0().reduce<OrderItem[]>((list, order) => {
    return [...list, ...(order.cancellationItems || [])];
  }, []);

  const passthroughItems0 = items0.reduce<OrderItem[]>((list, item) => {
    if (item.tseMethod === TseMethod.passthrough) list.push(item);
    if (item.tseMethod === TseMethod.applyPart && item.quantity > 1) {
      const _item = _.cloneDeep(item);
      _item.quantity--;
      _item.tseMethod = TseMethod.passthrough;
      list.push(_item);
    }
    return list;
  }, []);

  const applyItems0 = items0.reduce<OrderItem[]>((list, item) => {
    if (item.tseMethod === TseMethod.apply) list.push(item);
    if (item.tseMethod === TseMethod.applyPart) {
      const _item = _.cloneDeep(item);
      _item.tseMethod = TseMethod.apply;
      if (_item.quantity > 0) {
        _item.quantity = 1;
      } else {
        _item.quantity = -1;
      }
      list.push(_item);
    }
    return list;
  }, []);

  const report: {
    reason: string[], total?: number,
    realTotal?: any, hasErr?: boolean,
    items?: any,
    orderItems?: any,
    groups?: any
    orders?: any
    transactionGroups?: any
    passthroughItems?: any
    applyItems?: any
  } = {
    reason: [],
    passthroughItems: _.sumBy(passthroughItems0, 'quantity'),
    applyItems: _.sumBy(applyItems0, 'quantity')
  }
  const transactions = tseTransactions0();
  const transactionGroups = _.groupBy(transactions, 'TSE_TA_VORGANGSART');
  //fixme: only hotfix for order._id
  let groups = _.groupBy(transactions, t => t.order ? t.order : 'noGroup');
  const items: OrderItem[] = [];
  for (const transaction of transactionGroups['Bestellung-V1'] || []) {
    items.push(...getItemsFromTransaction(transaction));
  }
  //multi index:
  //1. total
  //2. base on items
  //3. check numbers of orders
  //base idea: eod -> calculate report
  function getTotalFromTransaction() {
    const transactions = transactionGroups[processTypes.KassenbelegV1];
    const total = _.sumBy(transactions, t => {
      return _.sumBy(t.TSE_TA_VORGANGSDATEN!.split('^')[1].split('_').map(n => parseFloat(n)));
    })
    return total;
  }
  report.total = getTotalFromTransaction();
  report.realTotal = renderPivotTable({ reducers: ['@sum[2]:vSum'] }, orders0()) || 0;
  if (report.total !== report.realTotal) {
    report.hasErr = true;
    report.reason.push('total !== realTotal');
  }

  //items
  report.items = renderPivotTable({ rows: ['name'], reducers: ['@sum[2]:quantity'] }, items);
  const orderItems = orders0().reduce((l, o) => l.concat(o.items!), []);
  report.orderItems = renderPivotTable({ rows: ['name'], reducers: ['@sum[2]:quantity'] }, orderItems);
  let _diff = rdiff.getDiff(report.items, report.orderItems, true);
  _diff = _diff.filter(diff => {
    if (diff.op === 'delete' && diff.oldVal === 0) return false;
    return true;
  })
  if (_diff.length > 0) {
    report.hasErr = true;
    report.reason.push('items diffs');
    report.reason.push(report.items);
    report.reason.push(report.orderItems);
    report.reason.push(JSON.stringify(_diff));
  }

  //todo: check transaction
  const tseOrders = []
  if (!transactionGroups['Kassenbeleg-V1']) transactionGroups['Kassenbeleg-V1'] = []
  const alreadyPaidFilter = (o: Order) => !['inProgress', 'cancelledBeforePaid'].includes(o.status);
  if (transactionGroups['Kassenbeleg-V1'].length !== orders0().filter(alreadyPaidFilter as any).length) {
    report.hasErr = true;
    report.reason.push('number of orders is different ');
    report.reason.push(`tse: ${transactionGroups['Kassenbeleg-V1'].length}`);
    report.reason.push(`real: ${orders0().filter(alreadyPaidFilter as any).length}`);
    if (transactionGroups['Kassenbeleg-V1'].length < orders0().filter(alreadyPaidFilter as any).length) {
      const mapTse = transactionGroups['Kassenbeleg-V1'].map(t => t.order);
      const mapOrder = orders0().filter(alreadyPaidFilter as any).map(e => e._id);
      for (const _id of mapOrder) {
        if (!mapTse.includes(_id)) {
          report.reason.push(orders0().filter(alreadyPaidFilter as any).find(o => o._id === _id)?.id?.toString() || '');
        }
      }
    }
  }
  for (const transaction of transactionGroups['Kassenbeleg-V1']) {
    // TSE_TA_VORGANGSDATEN2: is real total
    const TSE_TA_VORGANGSDATEN = transaction['TSE_TA_VORGANGSDATEN2'] || transaction['TSE_TA_VORGANGSDATEN'];
    let payments = TSE_TA_VORGANGSDATEN!.split('^')[2].split('_');
    const _payments = payments.map(payment => ({ value: parseFloat(payment.split(':')[0].replace(',', '.')) }))
    //compare only _id + vSum
    const total = _.sumBy(_payments, 'value');
    const order = orders0().find(o => o._id === transaction.order);
    if (order && order!.status === OrderStatus.CANCELLED_BEFORE_PAID) continue;
    if (order) {
      if (order.vSum !== total) {
        report.hasErr = true;
        report.reason.push(`tse: ${order.id} : total is different`);
      }
    } else {
      report.reason.push(`not found order for transaction: `);
    }
  }
  //await check();
  [report.groups, report.orders, report.transactionGroups] = [groups, _.cloneDeep(orders0()),  _.cloneDeep(transactionGroups)];

  if (report.hasErr) {
    captureException(new Error(JSON.stringify(report)));
  }
  debugTse('report: ', report);
  //todo:
}, 100, {trailing: true, leading: false}));

effectOn([orders0], () => {
  console.log('passthrough: ', percent0());
}, { defer: true })

export function getItemPassthroughQuantity(item: OrderItem) {
  if (item.tseMethod === TseMethod.passthrough) return item.quantity;
  if (item.tseMethod === TseMethod.applyPart) return 1;
  return 0;
}

export const passthroughSum0 = computed(() => {
  const orders = orders0();
  const result = orders.reduce((acc, order) => {
    return acc + order.items!.reduce((acc, item) => {
      const quantity = getItemPassthroughQuantity(item);
      return acc + item.vTotal! * quantity;
    }, 0);
  }, 0)
  return result;
})

export const percent0 = computed(() => {
  const orders = orders0();
  const sum = orders.reduce((acc, order) => {
    return acc + order.vSum!;
  }, 0)
  const percent = sum === 0 ? 0 : (passthroughSum0() / sum) * 100;
  debugTse('percent0: ', percent);
  return percent;
})