import {type FunctionComponent, memo, useState} from "react";
import PopupDeleteConfirmation from "./Popup/PopupDeleteConfirmation";
import PortalPopup from "./Popup/PortalPopup";
import EditOnlineMenuTopNav1 from "./EditOnlineMenuTopNav1";
import ModifierListToolbar from "./ModifierListView/ModifierListToolbar";
import ModifierListCard from "./ModifierListView/ModifierListCard";
import ModifierListCardSingleDemo from "./ModifierListView/ModifierListCardSingleDemo";
import ModifierListCardSelectDemo from "./ModifierListView/ModifierListCardDemo";
import { STATES, VIEW__MODIFIER } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";

const ModifierListView: FunctionComponent = () => {
  const [isEditItemDeleteConfirmPoOpen, setEditItemDeleteConfirmPoOpen] =
    useState(false);
  return (
    <div className="modifier-list-screen bg-gray-solid-gray-120-f8f9ff w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[0px] hover:bg-gray-solid-gray-120-f8f9ff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start">
      <EditOnlineMenuTopNav1 />
      {/*<EditOnlineMenuTopNav />*/}
      <ModifierListToolbar />
      <section className="pb-6 self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-0 px-5 gap-[12px] lg:pl-5 lg:pr-5 lg:box-border md:pl-4 md:pr-4 md:box-border sm:gap-[8px] sm:pl-3.5 sm:pr-3.5 sm:box-border mq768:gap-[6px] mq768:pl-3 mq768:pr-3 mq768:box-border mq600:gap-[4px] mq600:pl-2.5 mq600:pr-2.5 mq600:box-border mq480:pl-2.5 mq480:pr-2.5 mq480:box-border">
        {STATES.modifiers().map(m => (
        <ModifierListCard
          key={m._id}
          model={m}
          onDelete={() => VIEW__MODIFIER.onDelete(m._id)}
          onEdit={() => VIEW__MODIFIER.onEdit(m._id)}
        />
        ))}
        {/*
        <ModifierListCardSingleDemo isQuantity="Multiple" />
        <ModifierListCardSingleDemo showQuantity isQuantity="Quantity" />
        <ModifierListCardSelectDemo />
        */}
      </section>
    </div>
  );
};

export default memo(ModifierListView);
