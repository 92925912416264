import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import type { MigrationStrategies, MigrationStrategy } from "rxdb";

export const DateType = {type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ** 10}

export const keepOld: MigrationStrategy = async function (oldDoc: any) {
  return oldDoc;
};

export const keepOldGenerator = <T = unknown>(version: number, extraStrategy: { [toVersion:number]: MigrationStrategy<T> }= {}) => {
  const result: MigrationStrategies = {}
  for (let i = 1; i <= version; i++) {
    if (extraStrategy[i]) {
      result[i] = extraStrategy[i]
    } else {
      result[i] = keepOld
    }
  }
  return result;
}


export const migrateProxy = (obj: {[k: string | symbol]: (doc: any) => void }) => {
  return new Proxy(obj, {
    get: function (target, p, receiver) {
      if (obj[p]) return obj[p];
      return Reflect.get(target, p, receiver);
    }
  });
}

export const genKeepOldGenerator = (version: number, opts: Object = {}) => {
  const proxy = new Proxy(opts, {
    get: function (target, prop) {
      return keepOld
    }
  });
}

export const dataLock = new MultiAwaitLock(true)
