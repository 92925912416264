import _ from "lodash";
import { deleteMany } from "./data-utils";

import { Order, PaidOrder } from "@/data/Order.ts";
import { OnlineOrder } from "@/data/OnlineOrder.ts";
import { Eod } from "@/data/Eod.ts";
import { EodCache } from "@/data/EodCache.ts";
import { MaxId } from "@/data/MaxId.ts";
import { TxLogCollection } from "@/data/TxLog.ts";
import { TxRefundLogCollection } from "@/data/TxRefundLog.ts";
import { TseTransaction } from "@/data/TseTransaction.ts";
import { OrderTseTemp } from "@/data/OrderTseTemp.ts";
import { PrintImage } from "@/data/PrintImage.ts";
import { TseConfig } from "@/data/TseConfig.ts";
import { Product } from "@/data/Product.ts";
import { User } from "@/data/User.ts";
import { OrderLayout } from "@/data/OrderLayout.ts";
import { ProductLayout } from "@/data/ProductLayout.ts";
import { Room, RoomObject } from "@/data/Room.ts";
import { Payment } from "@/data/Payment.ts";
import { GroupPrinter } from "@/data/GroupPrinter.ts";
import { Modifier } from "@/data/Modifier.ts";
import { TaxCategory } from "@/data/TaxCategory.ts";
import { PosSetting } from "@/data/PosSetting.ts";
import { DeviceSetting } from "@/data/DeviceSetting.ts";
import { PosSync } from "@/data/PosSync.ts";
import { Terminal } from "@/data/Terminal.ts";
import { HappyHour } from "@/data/HappyHour.ts";
import { TseCertificate } from "@/data/TseCertificate.ts";
import { Category } from "@/data/Category.ts";
import { Menu } from "@/data/Menu.ts";
import {OnlineProvider} from "@/data/OnlineProvider.ts";
import { ReservationCollection } from "@/data/Reservation.ts";
import { ProductSync } from "@/data/ProductSync.ts";
import { Inventory } from "@/data/Inventory.ts";
import { InventoryAction } from "@/data/InventoryAction.ts";
import { Customer } from "@/data/Customer.ts";
import { Call, MissedCall } from "@/data/Call.ts";
import { Voucher } from "@/data/Voucher.ts";
import { SrmEventLog } from "@/data/SrmEventLog.ts";
import { SrmTransactionLog } from "@/data/SrmTransactionLog.ts";
import { SrmDocumentLog } from "@/data/SrmDocumentLog.ts";
import { ModifierSync } from "@/data/ModifierSync.ts";
import { ZvtLog } from "@/data/ZvtLog.ts";
import { TimeClock } from "@/data/TimeClock.ts";

// This function cannot sit inside `data-utils.ts` since some data also imported
// that file, causing circular import!
export async function clearAllCollections() {
  await deleteMany(Product, {}, true);
  await deleteMany(User, {}, true);
  await deleteMany(Order, {}, true);
  await deleteMany(OnlineOrder, {}, true);
  await deleteMany(PaidOrder, {}, true);
  await deleteMany(OrderLayout, {}, true);
  await deleteMany(ProductLayout, {}, true);
  await deleteMany(Room, {}, true);
  await deleteMany(RoomObject, {}, true);
  await deleteMany(Payment, {}, true);
  await deleteMany(Eod, {}, true);
  await deleteMany(EodCache, {}, true);
  await deleteMany(GroupPrinter, {}, true);
  await deleteMany(Modifier, {}, true);
  await deleteMany(TaxCategory, {}, true);
  await deleteMany(PosSetting, {}, true);
  await deleteMany(PrintImage, {}, true);
  // await deleteMany(DeviceSetting, {}, true);
  // await deleteMany(PosSync, {}, true);
  await deleteMany(Terminal, {}, true);
  await deleteMany(MaxId, {}, true);
  await deleteMany(HappyHour, {}, true);
  await deleteMany(TxLogCollection, {}, true);
  await deleteMany(TxRefundLogCollection, {}, true);
  await deleteMany(HappyHour, {}, true);
  await deleteMany(TseCertificate, {}, true);
  await deleteMany(TseConfig, {}, true);
  await deleteMany(TseTransaction, {}, true);
  await deleteMany(OrderTseTemp, {}, true);
  await deleteMany(Category, {}, true);
  await deleteMany(Menu, {}, true);
  await deleteMany(OnlineProvider, {}, true);
  await deleteMany(Image, {}, true);

  await deleteMany(ReservationCollection, {}, true);
  await deleteMany(ProductSync, {}, true);
  await deleteMany(Inventory, {}, true);
  await deleteMany(InventoryAction, {}, true);
  await deleteMany(Customer, {}, true);
  await deleteMany(Call, {}, true);
  await deleteMany(MissedCall, {}, true);
  await deleteMany(Voucher, {}, true);
  await deleteMany(SrmEventLog, {}, true);
  await deleteMany(SrmTransactionLog, {}, true);
  await deleteMany(SrmDocumentLog, {}, true);
  await deleteMany(ModifierSync, {}, true);
  await deleteMany(ZvtLog, {}, true);
  await deleteMany(TimeClock, {}, true);
}