import { signal, signalSyncedWithLocalStorage } from '../core/reactive'

export const IS_SECOND_DISPLAY_KEY = 'IS_SECOND_DISPLAY'
export const MAIN_DISPLAY_IP_KEY = 'MAIN_DISPLAY_IP'
export const IS_MAIN_DISPLAY_IP_VALID_KEY = 'IS_MAIN_DISPLAY_IP_VALID'
export const SHOULD_AUTO_SWITCH_KEY = 'SHOULD_AUTO_SWITCH'

export const [isSecondDisplay, setIsSecondDisplay] = signalSyncedWithLocalStorage(IS_SECOND_DISPLAY_KEY, false)
export const [mainDisplayIp, setMainDisplayIp] = signalSyncedWithLocalStorage(MAIN_DISPLAY_IP_KEY, '')
export const [isMainDisplayIpValid, setIsMainDisplayIpValid] = signalSyncedWithLocalStorage(IS_MAIN_DISPLAY_IP_VALID_KEY, false)
export const [validatingMainDisplayIp, setValidatingMainDisplayIp] = signal(false)
export const [mainDisplayIpError, setMainDisplayIpError] = signal('')
export const [shouldAutoSwitch, setShouldAutoSwitch] = signalSyncedWithLocalStorage(SHOULD_AUTO_SWITCH_KEY, false)
