import { FunctionComponent } from 'react';
import { LL0 } from '@/react/core/I18nService.tsx';
import { dayReport0, onPrintX, showProductSold } from '@/react/EndOfDayView/EndOfDayView.tsx'
import _ from 'lodash';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { cashPaymentName0 } from "@/data/PaymentHub.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type EndOfDayXReportPopupType = {
  onClose?: () => void;
};

const EndOfDayXReportPopup: FunctionComponent<EndOfDayXReportPopupType> = ({
  onClose,
}) => {
  const handleClickPrintX = async () => {
    await onPrintX();
    onClose?.();
  }

  return (
    <div className="x-report-popup bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[24px] min-w-[440] max-h-[489] text-center text-xl text-black-solid-black-880-1d1d26 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[24px] hover:items-start hover:justify-start hover:min-w-[440] hover:max-h-[489]">
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-center justify-start pt-8 px-6 pb-0 box-border gap-[32px] h-full w-auto hover:flex hover:self-stretch hover:w-auto hover:flex-1 hover:flex-col hover:gap-[32px] hover:items-center hover:justify-start hover:pt-8 hover:px-6 hover:pb-0 hover:box-border hover:h-full">
        <b className="self-stretch relative">{LL0().report.xReport()}</b>
        <div
          className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[24px] text-left text-sm">
          <div className="self-stretch flex flex-col items-center justify-start gap-[4px]">
            <div
              className="self-stretch box-border h-7 flex flex-col items-start justify-between pt-0 px-0 pb-2 text-base text-blue-solid-blue-500-0051c1 border-b-[1px] border-dashed border-black-solid-black-1000-000000">
              <b className="self-stretch relative uppercase">{LL0().report.sales()}</b>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">{LL0().report.dineInQuantity()}:</div>
              <div className="relative leading-[25px] text-right">{dayReport0()?.dineInQuantity}</div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">{LL0().report.takeAwayQuantity()}:</div>
              <div className="relative leading-[25px] text-right">{dayReport0()?.takeAwayQuantity}</div>
            </div>
            {/*<div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">*/}
            {/*  <div className="flex-1 relative">{LL0().report.totalOrders()}</div>*/}
            {/*  <div className="relative leading-[25px] text-right">1</div>*/}
            {/*</div>*/}
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">{LL0().report.cash()}:</div>
              <div
                className="relative leading-[25px] text-right">{LL3().format.currency(dayReport0()?.paymentReport?.[cashPaymentName0()]?.total || 0)}</div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">{LL0().report.totalCashlessSales()}:</div>
              <div
                className="relative leading-[25px] text-right">{LL3().format.currency(dayReport0()?.totalCashlessSales || 0)}</div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">{LL0().report.totalTip()}:</div>
              <div
                className="relative leading-[25px] text-right">{LL3().format.currency(dayReport0()?.totalTip || 0)}</div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">
                {LL0().report.totalCashlessSalesExcludingTip()}:
              </div>
              <div
                className="relative leading-[25px] text-right">{LL3().format.currency(dayReport0()?.totalCashlessSalesExcludingTip || 0)}</div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">
                {LL0().report.totalCashSalesExcludingCashback()}:
              </div>
              <div
                className="relative leading-[25px] text-right">{LL3().format.currency(dayReport0()?.totalCashSalesExcludingCashback || 0)}</div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 Tablet_600:pl-4 Tablet_600:box-border">
              <div className="flex-1 relative">{LL0().payment.serviceFee()}:</div>
              <div
                className="relative leading-[25px] text-right">{LL3().format.currency(dayReport0()?.totalServiceFee || 0)}</div>
            </div>
            <div className="self-stretch flex flex-row items-end justify-between text-red-solid-red-450-c0272d">
              <div className="flex-1 relative font-extrabold">{LL0().common.total()}</div>
              <div
                className="relative text-base [text-decoration:underline] font-extrabold text-right flex hover:font-extrabold hover:font-mulish hover:text-base hover:text-right hover:text-red-solid-red-450-c0272d hover:flex">
                {LL3().format.currency(dayReport0()?.report.vSum || 0)}
              </div>
            </div>
          </div>
          <div className="w-full">
            <div
              className="self-stretch box-border h-7 flex flex-col items-start justify-between pt-0 px-0 pb-2 text-base text-blue-solid-blue-500-0051c1 border-b-[1px] border-dashed border-black-solid-black-1000-000000">
              <b className="self-stretch relative uppercase">Refunds</b>
            </div>
            <div className="pt-2 self-stretch flex flex-row items-end justify-between text-red-solid-red-450-c0272d">
              <div className="flex-1 relative font-extrabold">{LL0().common.total()}</div>
              <div
                className="relative text-base [text-decoration:underline] font-extrabold text-right flex hover:font-extrabold hover:font-mulish hover:text-base hover:text-right hover:text-red-solid-red-450-c0272d hover:flex">
                {LL3().format.currency(dayReport0()?.totalRefund || 0)}
              </div>
            </div>
          </div>
          {showProductSold() &&
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                  <div
                      className="self-stretch box-border h-7 flex flex-col items-start justify-between pt-0 px-0 pb-2 text-base text-blue-solid-blue-500-0051c1 border-b-[1px] border-dashed border-black-solid-black-1000-000000">
                      <b className="self-stretch relative uppercase">{LL0().report.productSold()}</b>
                  </div>
                {_.entries(dayReport0()?.groupItemsByCategory).map(([category, items], index) =>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]" key={index}>
                    <div className="self-stretch flex flex-row items-start justify-between">
                      <div className="relative font-extrabold">{category}:</div>
                      <div className="relative font-extrabold text-right">
                        {LL3().format.currency(dayReport0()?.sumByCategory[category] || 0)}
                      </div>
                    </div>
                    <div
                      className="self-stretch flex flex-col items-start justify-start py-0 pr-0 pl-6 gap-[8px] Tablet_600:pl-4 Tablet_600:box-border">
                      {_.entries(items).map(([itemName, value], index) =>
                        <div className="self-stretch relative" key={index}>
                          {value.quantity} x {itemName} ({LL3().format.currency(value.vSum)})
                        </div>)}
                    </div>
                  </div>)
                }
              </div>
          }
        </div>
      </div>
      <div
        className="self-stretch bg-black-opacity-black-10-384352 shadow-[0px_0px_2px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-end py-3 px-4 gap-[16px] text-sm text-black-solid-black-900-1e1e23">
        <div
          className="rounded-81xl [background:linear-gradient(180deg,_#ffda68,_#e3ac00)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] cursor-pointer"
          onClick={onClose}
          ref={makeRipple}
        >
          <img
            className="relative w-[30px] h-[30px] object-cover"
            alt=""
            src="/iconbin-icon--red@2x.png"
          />
          <b className="relative leading-[17px]">{LL0().orderHistory.cancel()}</b>
        </div>
        <div className="rounded-81xl [background:linear-gradient(180deg,_#ff8366,_#e44e2c)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
             ref={makeRipple}
             onClick={handleClickPrintX}
        >
          <img
            className="relative w-[30px] h-[30px] object-cover"
            alt=""
            src="/iconprint-icon@2x.png"
          />
          <b className="relative leading-[17px]">{LL0().order.receipt.print()}</b>
        </div>
      </div>
    </div>
  );
};

export default EndOfDayXReportPopup;
