import { type FunctionComponent, useContext, useRef } from 'react';
import SeatBill from "./SeatBill";
import EditMenuForm from "./EditMenuForm";
import EmptyListIndicator from "./EmptyListIndicator";
import ItemListContainer from "./ItemListContainer";
import { ScreenMode, screenMode } from '@/react/OrderView/OrderView.tsx';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import { useComputed } from '@/react/core/reactive.ts';
import clsx from 'clsx';

const OrderScreenRightContainer: FunctionComponent = () => {
  const { order0, splitEnable, containerClassName } = useContext(ItemFactoryContext);

  const isSeatMode = useComputed(() => order0?.().seatMode && !splitEnable?.())

  return (
    <div
      className={clsx("!w-full no-scrollbar text-black-solid-black-720-32383d self-stretch flex-1 bg-white overflow-y-auto flex flex-col items-center justify-start py-1.5 px-1 gap-[16px] z-[0] text-left text-base font-mulish md:gap-[12px] sm:pt-2 sm:pb-2 sm:box-border Tablet_768:pt-2 Tablet_768:pb-2 Tablet_768:box-border Tablet_600:pt-2 Tablet_600:pb-2 Tablet_600:box-border height_414:pt-2 height_414:pb-2 height_414:box-border",
        containerClassName)}
    >
      <EditMenuForm isEditScreen={screenMode() === ScreenMode.EDIT} />
      {screenMode() === ScreenMode.ORDER &&
        <>
          <SeatBill isSeatSplit={isSeatMode()} />
          {!isSeatMode() &&
            <>
              {
                order0?.().items.length === 0 ?
                  <EmptyListIndicator isEmptyList />
                  :
                  <ItemListContainer isListItemOnly />
              }
            </>
          }
        </>
      }
    </div>
  );
};

export default OrderScreenRightContainer;
