import type { RxCollection, RxJsonSchema } from 'rxdb'

export const ONLINE_PROVIDER_COLLECTION_NAME = 'online-providers'

export const SERVICE_TYPES = {
  order: 'Sync Online Order',
  menu: 'Sync Online Menu',
  delivery: 'Delivery Status',
} as const

export interface OnlineProvider {
  _id: string
  /** Types of providers */
  name?: string
  /** Online Store's ID - should get this value from our partner */
  storeId?: string
  /** Secret key to connect to partner's endpoint */
  apiKey?: string
  /** [RESTABLO] Credential to login  */
  username?: string
  /** [RESTABLO] Credential to login  */
  password?: string
  /** <Not used yet> */
  serviceTypes?: string[]
  /** <Not used yet> */
  lastSyncAt?: number
  /** Turn on/of the provider and its associate menu. */
  isEnabled?: boolean
}

export const onlineProviderSchema: RxJsonSchema<OnlineProvider> = {
  title: 'online provider schema',
  version: 7, // update this when schema change
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 64 },
    storeId: { type: 'string' },
    apiKey: { type: 'string' },
    username: { type: 'string' },
    password: { type: 'string' },
    serviceTypes: { type: 'array', items: { type: 'string' } },
    lastSyncAt: { type: 'number' },
    isEnabled: { type: 'boolean' },
  },
  required: ['_id'],
}

export const OnlineProvider = {} as RxCollection<OnlineProvider>

Object.assign(window, { OnlineProvider }) // Make available global
