import type { JsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'

import { createCollection } from '@/data/utils/migration.ts'
import type { CurrentTransactionResponseData, SignedTransactionData } from '@/srm/lib/types'

export const SRM_TRANSACTION_LOG_COLLECTION_NAME = 'srm_transaction_log'

export interface SrmTransactionLog {
  readonly _id: string
  readonly date: number
  /**
   * Identifier of the device on which the SRS is installed or of the terminal used to access the SRS.
   * The identifier is generated and returned by the WEB-SRM with the first "certificates" request.
   */
  readonly deviceId: string
  readonly data: SignedTransactionData
  readonly user: string
  /** Indicator to mark if this transaction is made in training mode */
  readonly training: boolean

  /** Reference data, typical storing order._id */
  readonly ref: string
  /** Amount of money of the transaction. Take from `data.mont.avantTax` field for quickly summarize */
  readonly total: number
  /** Indicator to mark if this transaction is sent to WEB-SRM */
  sent: boolean
  /** Response from WEB-SRM */
  response?: CurrentTransactionResponseData
}

export const VERSION = 22 // Increase this whenever schema changed
const schema: RxJsonSchema<SrmTransactionLog> = {
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    date: { type: 'number', final: true, minimum: 0, maximum: 8.64e15, multipleOf: 1 },
    total: { type: 'number', final: true, minimum: -100000, maximum: 100000, multipleOf: 0.01 },
    training: { type: 'boolean', default: false },
    deviceId: { type: 'string', final: true, maxLength: 24 },
    sent: { type: 'boolean' },
    data: <JsonSchema<SignedTransactionData>>{
      type: 'object',
      final: true,
    },
    response: <JsonSchema<CurrentTransactionResponseData>>{ type: 'object' },
    ref: { type: 'string', final: true, maxLength: 24 },
    user: { type: 'string', final: true, maxLength: 24 },
  },
  required: ['_id', 'deviceId', 'data', 'date', 'sent', 'ref', 'training', 'total', 'user'],
  indexes: [
    ['date'], // Usage: User report
    ['date', 'training'], // Usage: Sales report, all user
    ['date', 'training', 'user'], // Usage: Sales report, one user
    // 'deviceId', // Usage: Transaction History page
    'user', // Usage: Transaction History page
    'ref', // Usage: find origin transaction
    ['deviceId', 'sent'], // Usage: find pending transactions
  ],
}

export const SrmTransactionLog = {} as RxCollection<SrmTransactionLog>

declare global {
  interface Window {
    SrmTransactionLog: RxCollection<SrmTransactionLog>
  }
}

window.SrmTransactionLog = SrmTransactionLog

export const createSrmTransactionLogCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: SrmTransactionLog,
    collectionName: SRM_TRANSACTION_LOG_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
    extraStrategy: {
      22: async (doc) => {
        doc.training = doc.training ?? false
        return doc
      }
    }
  })
}
