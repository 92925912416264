import type { RxCollection, RxJsonSchema } from 'rxdb'

export const PRODUCT_LAYOUT_COLLECTION_NAME = 'product_layouts'

export interface ProductLayout {
  _id: string
  product?: string
  top: number
  left: number
  color?: string
  type: 'Text' | 'Article' | 'Extra' | 'NonFixed'
  text?: string
  icon?: {
    iconSet: number
    iconIndex: number
    source: string
  }
  category: string
}

export const productLayoutSchema: RxJsonSchema<ProductLayout> = {
  title: 'productLayout schema',
  version: 1, // Increment this if schema changes
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    product: { type: 'string', maxLength: 24 },
    top: { type: 'number' },
    left: { type: 'number' },
    color: { type: 'string' },
    type: { type: 'string' },
    text: { type: 'string' },
    icon: {
      type: 'object',
      properties: {
        iconSet: { type: 'number' },
        iconIndex: { type: 'number' },
        source: { type: 'string' },
      },
    },
    category: { type: 'string', maxLength: 24 },
  },
  indexes: [],
}

export const ProductLayout = {} as RxCollection<ProductLayout>

Object.assign(window, { ProductLayout }) // Make available in global scope for debugging
