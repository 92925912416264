/**
 * Supported payment methods:
 *
 * - ARG: Argent comptant (cash)
 * - CHQ : Chèque (cheque)
 * - COU : Coupon (coupon)
 * - CRE : Carte de crédit (credit card)
 * - DEB : Carte de débit (debit card)
 * - CPR: Certificat-cadeau (prepaid card or gift card)
 * - FID: Programme de fidélisation (loyalty program)
 * - CRY: Cryptomonnaie (cryptocurrency) (e.g. Bitcoins)
 * - MVO: Portefeuille électronique (digital wallet) (e.g. Paypal)
 * - TFD: Transfert de fonds (transfer of funds)
 * - MIX: Paiement mixte (mixed payment)
 * - PAC: Porté au compte (charge to account)
 * - AUT: Autre (other)
 * - INC: Inconnu (unknown)
 * - AUC: Aucun paiement (no payment)
 * - SOB: Sans objet (n/a)
 */

export enum PaymentMethods {
  cash = 'ARG',
  /** @deprecated Not used in out app */
  cheque = 'CHQ',
  /** @deprecated Not used in out app */
  coupon = 'COU',
  /** @deprecated Not used in out app */
  creditCard = 'CRE',
  debitCard = 'DEB',
  /** @deprecated Not used in out app */
  prepaidCard = 'CPR',
  /** @deprecated Not used in out app */
  loyaltyProgram = 'FID',
  /** @deprecated Not used in out app */
  cryptocurrency = 'CRY',
  /** @deprecated Not used in out app */
  digitalWallet = 'MVO',
  /** @deprecated Not used in out app */
  bankTransfer = 'TFD',
  /** @deprecated Not used in out app */
  mixed = 'MIX',
  /** @deprecated Not used in out app */
  chargeToAccount = 'PAC',
  /** @deprecated Not used in out app */
  other = 'AUT',
  /** @deprecated Not used in out app */
  unknown = 'INC',
  noPayment = 'AUC',
  notAvailable = 'SOB',
}

export enum ActivitySectors {
  /** Restaurants, bars and food trucks */
  restaurant = 'RBC',
  /**
   * @deprecated Not used in out app
   *
   * Remunerated passenger transportation
   */
  transportation = 'TRP',
}

/**
 * Service type
 *
 * Possible values:
 * - CMP (counter service)
 * - LVR (delivery by the mandatary)
 * - LVT (order through a digital platform)
 * - TBL (table service)
 */

export enum ServiceTypes {
  counterService = 'CMP',
  tableService = 'TBL',
  delivery = 'LVR',
  digitalPlatform = 'LVT',
}
/**
 * Document print option
 *
 * Possible values:
 *
 * PAP: Papier (paper)
 * ELE: Électronique (electronic)
 * PEL: Papier et électronique (combined paper and electronic)
 * NON: Non imprimé (not printed)
 */
export enum PrintOptions {
  paper = 'PAP',
  notPrinted = 'NON',

  /** @deprecated Not used in out app */
  electronic = 'ELE',
  /** @deprecated Not used in out app */
  combined = 'PEL',
}
export enum TransactionTypes {
  temporaryBill = 'ADDI',
  closingReceipt = 'RFER',

  /** @deprecated Not used in out app */
  estimate = 'ESTM',
  /** @deprecated Not used in out app */
  quote = 'SOUM',
  /** @deprecated Not used in out app */
  occasionalThirdParty = 'TIER',

  notAvailable = 'SOB',
}
/**
 * - **`ANN`**: Annulation (cancellation)
 * - **`RPR`**: Reproduction
 * - **`DUP`**: Duplicata (duplicate)
 * - **`PSP`**: Parti sans payer (failure to pay)
 * - **`FAC`**: Facture ou tout autre document non inclus dans cette liste (bill or any other document not included in this list)
 */

export enum PrintModes {
  cancellation = 'ANN',
  reproduction = 'RPR',
  duplicate = 'DUP',
  failureToPay = 'PSP',
  bill = 'FAC',
}
/**
 * Abbreviation of the item's activity subsector (see section 4.4.1.1.6).
 *
 * Possible Values:
 * - `RES`: Restaurant
 * - `BAR`: Bar
 * - `CDR`: Food truck
 * - `HAB`: Frequent third party
 * - `NON`: Not subject to the measures
 * - `SOB`: N/A
 *
 * > **NOTE**: If the prix field is absent or has the value "$0.00",
 * the value of the acti field must be "SOB" ("N/A").
 */

export enum ActivitySubSectors {
  restaurant = 'RES',
  bar = 'BAR',
  none = 'NON',
  notAvailable = 'SOB',

  /** @deprecated Not used in out app */
  foodTruck = 'CDR',
  /** @deprecated Not used in out app */
  frequentThirdParty = 'HAB',
}
/**
 * Transaction mode
 *
 * Possible values:
 * - `OPE`: Opérationnel (operating)
 * - `FOR`: Formation (training)
 *
 * > **NOTE**: When Training mode is activated, it must be clearly indicated on the SRS screen.
 */

export enum OperationModes {
  operating = 'OPE',
  training = 'FOR',
}

/**
 * Indication that the sales correspond to a single user account or to all user accounts of the establishment.
 *
 * - If the sales correspond to a single user, enter "Unique".
 * - If the sales correspond to all users of the establishment, for example for the
 * madatary and his employees, enter “Tous” (All).
 */
export enum UserReportTypes {
  All = 'Tous',
  Unique = 'Unique',
}

/**
 * Field identifying the type of document sent.
 *
 * Possible values:
 * - `RUT`: Rapport de l'utlisateur (user report)
 * - `HAB`: Tiers habituel (frequent third party)
 */
export enum DocumentTypes {
  userReport = 'RUT',
  frequentThirdParty = 'HAB',
}
