import { signal } from '../core/reactive'

export type DailyShift = {
  shiftNumber: number
  startTime: string
  endTime: string | null
  clockIn: boolean
  clockOut: boolean
  totalHours: string
  isPlus: boolean
}
type DailySchedule = {
  dayName: string
  shifts: DailyShift[]
}
type WeeklySchedule = DailySchedule[]

export const WeeklySchedule: WeeklySchedule = [
  {
    dayName: 'Mon',
    shifts: [
      {
        shiftNumber: 1,
        startTime: '08:00',
        endTime: '12:00',
        totalHours: '4:00',
        clockIn: false,
        clockOut: false,
        isPlus: false,
      },
      {
        shiftNumber: 2,
        startTime: '01:00',
        endTime: '05:00',
        totalHours: '4:00',
        clockIn: false,
        clockOut: false,
        isPlus: false,
      },
    ],
  },
  {
    dayName: 'Tue',
    shifts: [
      {
        shiftNumber: 1,
        startTime: '04:00',
        endTime: '08:00',
        totalHours: '4:00',
        clockIn: false,
        clockOut: false,
        isPlus: false,
      },
      {
        shiftNumber: 2,
        startTime: '',
        endTime: '',
        totalHours: '-',
        clockIn: true,
        clockOut: true,
        isPlus: true,
      },
    ],
  },
]

export const [selectedUserId, setSelectedUserId] = signal<string | undefined>()
