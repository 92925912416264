import { SrmFeatures } from './SrmFeatures'
import SrmSettings from './SrmSettings'
import SrmTestcase from './SrmTestcase'
import { SrmUtilities } from './SrmUtilities'

export default function DeveloperSrmTab() {
  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-6">
        <div className="flex flex-col gap-8">
          <div>
            <h6 className={'text-lg font-bold mb-4'}>Pos Settings</h6>
            <SrmSettings />
          </div>
          <div>
            <h6 className={'text-lg font-bold mb-4'}>Utilities</h6>
            <SrmUtilities />
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div>
            <h6 className={'text-lg font-bold mb-2'}>Beta Features</h6>
            <p className='mb-4'>Only effective while SRM mode is turned on.</p>
            <SrmFeatures />
          </div>

          {import.meta.env.DEV && (
            <div>
              <h6 className={'text-lg font-bold mb-4'}>Testcase</h6>
              <SrmTestcase />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
