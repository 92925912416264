import { type FunctionComponent } from 'react'
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material'
import { LL0 } from '@/react/core/I18nService.tsx'
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import Input, { inputController0 } from '@/react/core/Input.tsx'
import { createNewDiscount, setInputControllers } from '@/react/OrderView/OrderView.tsx'
import { useSignal } from '@/react/core/reactive.ts'
import { setOrderDiscount } from "@/react/PaymentView/PaymentView.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";


type EditDiscountLabelOtherType = {
  onClose?: () => void;
};

const EditDiscountLabelOther: FunctionComponent<EditDiscountLabelOtherType> = (
  {
    onClose
  }) => {

  const [discountLabel, setDiscountLabel] = useSignal<{
    label: string,
    type: 'percent' | 'amount',
    value: number
  }>({ label: '', type: 'percent', value: 0 })

  const handleAddDiscountLabel = async () => {
    if (!discountLabel().label || !discountLabel().value) return
    await createNewDiscount(discountLabel())
    setOrderDiscount(discountLabel())
    onClose?.()
  }

  return (
    <div
      className="relative rounded-md bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-center justify-start gap-[8px] min-w-[438px] max-w-full max-h-full text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish">
      <div
        className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-between pt-3.5 px-3.5 pb-2 border-b-[1px] border-solid border-gray-solid-gray-150-eeeeee">
        <b className="flex-1 relative">{LL0().order.setupDiscountLabel()}</b>
        <div
          className="relative w-6 h-6 overflow-hidden shrink-0 cursor-pointer"
          onClick={onClose}
        >
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/payment-iconclose-popup-icon--grey@2x.png"
          />
        </div>
      </div>
      <div
        className="self-stretch flex-1 flex flex-col items-center justify-start py-2 px-4 gap-[12px] text-base text-materialize-grey-darken-3">
        <div className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-6 gap-[12px]">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="relative font-extrabold inline-block min-w-[160px]">
              {LL0().order.discountName()}:
            </div>
            <Input
              refInputController={i => {
                i.setFocus(true);
                return setInputControllers?.([i])
              }}
              className="font-mulish italic text-smi text-gray-400 w-full"
              value={discountLabel().label}
              onChange={value => setDiscountLabel(prev => ({ ...prev, label: value }))}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="relative font-extrabold inline-block min-w-[160px]">
              {LL0().order.chooseType()}:
            </div>
            <FormControl className="flex-1" variant="outlined" size="small">
              <Select color="primary" size="small"
                      value={discountLabel().type}
                      onChange={e => setDiscountLabel(prev => ({
                        ...prev,
                        type: e.target.value as 'amount' | 'percent'
                      }))}
              >
                <MenuItem value="amount">{LL0().discount.amount()}</MenuItem>
                <MenuItem value="percent">{LL0().discount.percent()}</MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="relative font-extrabold inline-block min-w-[160px]">
              {LL0().fnBtn.value()}:
            </div>
            <div
              className="flex-1 rounded bg-white box-border h-[39px] flex flex-row items-center justify-start relative text-smi text-black-solid-black-1000-000000 border-[1px] border-solid border-silver-200">
              <div
                className="absolute !m-[0] h-full w-full top-[-1px] right-[1px] bottom-[1px] left-[-1px] rounded-10xs box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-250-8aeaff" />
              <div
                className="self-stretch rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs bg-[#f4f4f4] box-border w-[40.2px] flex flex-row items-center justify-center relative gap-[8px] z-[1] border-r-[0.4px] border-solid border-silver-100">
                <div
                  className="absolute !m-[0] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-r-[0.2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                <div
                  className="relative font-semibold z-[1]">{discountLabel().type === 'amount' ? LL3().common.currency() : '%'}</div>
              </div>
              <Input
                refInputController={i => {
                  return setInputControllers?.([i])
                }}
                className="font-mulish italic text-smi text-gray-400 w-full border-0"
                value={discountLabel().value.toString()}
                onChange={value => {
                  if (isNaN(Number(value))) return
                  setDiscountLabel(prev => ({ ...prev, value: Number(value) }))
                }}
              />
            </div>
          </div>
        </div>
        <TableManualKeyboard inputController={inputController0} onEnter={handleAddDiscountLabel} />
      </div>
    </div>
  )
}

export default EditDiscountLabelOther