import { User } from '@/data/User'
import { LL0 } from '@/react/core/I18nService'
import { handleError, progressToast } from '@/shared/decorators'

import { WebSrmUserError } from './errors'
import { getSignableSdkWithHeadersAndTaxNum } from './getSdkWithHeadersAndTaxNum'
import { handleSrmError } from './lib/decorators'
import type { UserRequestBody } from './lib/types'

export interface SrmUserModel {
  name?: string
  passcode?: string
  /** GST number */
  noTPS?: string
  /** QST number */
  noTVQ?: string
}

export class SrmUserLogic {
  @progressToast(u => LL0().srm.messages.creatingUser({ name: u.name ?? '' }))
  @handleSrmError()
  @handleError()
  async createUser(model: SrmUserModel) {
    const { sdk, headers } = getSignableSdkWithHeadersAndTaxNum()
    if (!model.name) throw new Error(LL0().srm.errors.invalidUserName({ name: '<empty>' }))
    if (!model.passcode) throw new Error(LL0().srm.errors.invalidPasscode())
    const existingUsers = await User.find({
      selector: {
        $or: [
          { name: model.name },
          { passcode: model.passcode },
        ],
      }
    }).exec()

    if(existingUsers?.length > 1) throw new Error(LL0().srm.errors.userAlreadyExists())

    const body: UserRequestBody = {
      reqUtil: {
        modif: 'AJO',
        nomUtil: model.name,
        noTax: { noTPS: model.noTPS ?? '', noTVQ: model.noTVQ ?? '' },
      },
    }
    const res = await sdk.user(headers, body)

    const errors: string[] = []
    if (res?.retourUtil.noTax.noTPS === 'INV') errors.push(LL0().srm.errors.invalidGstNumber())
    if (res?.retourUtil.noTax.noTVQ === 'INV') errors.push(LL0().srm.errors.invalidQstNumber())
    await User.find({ selector: { name: model.name } }).update({
      $set: {
        srmSynced: !errors.length,
        srmSyncingError: errors.join(', '),
      },
    })
  }

  @progressToast(u => LL0().srm.messages.deletingUser({ name: u }))
  @handleSrmError()
  @handleError()
  async deleteUser(userName: string) {
    const { sdk, headers, gstNumber, qstNumber } = getSignableSdkWithHeadersAndTaxNum()
    const body: UserRequestBody = {
      reqUtil: {
        modif: 'SUP',
        nomUtil: userName,
        noTax: { noTPS: gstNumber, noTVQ: qstNumber },
      },
    }
    const res = await sdk.user(headers, body)
    if (res.retourUtil.listErr?.length) throw new WebSrmUserError(res)
  }
}

export const srmUserLogic = new SrmUserLogic()
