import _ from 'lodash';
import {useState} from 'react';
import {LL0} from "@/react/core/I18nService.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

type Props = {
  onClose: Function,
  vSum: number,
  defaultTip: number,
}

const CustomTipDialog = ({
  onClose,
  vSum,
  defaultTip
}: Props) => {

  const [val, setVal] = useState(defaultTip.toString())
  const keys = '7894561230,<'.split('')

  const onKeyPress = (key: string) => {
    if (key !== '<')
      setVal(val => val + key)
    else
      setVal(val => val.substring(0, val.length - 1))
  }

  const onSetDefaultTip = (v: number) => {
    setVal(_.round(vSum * v / 100, 2).toString())
  }

  const cancel = () => onClose({action: 'cancel'})
  const ok = () => onClose({action: 'ok', tip: +(val || '0')})

  const styles = {
    input: {
      border: '1px solid #ddd',
      borderRadius: '4px'
    },
    panel: {
      backgroundColor: "#fff",
      margin: "0 auto",
      padding: '20px',
      borderRadius: '8px'
    },
    keyboards: {
      "display": "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "4px"
    },
    keyboard: {
      cursor: 'pointer',
      width: '100px',
      height: '40px',
      lineHeight: '40px',
      textAlign: 'center',
      background: '#eeee',
      borderRadius: '4px'
    },
    buttons: {
      cursor: 'pointer',
      height: '40px',
      lineHeight: '40px',
      borderRadius: '4px',
      textAlign: 'center',
    },
    cancel: {
      width: '80px',
      background: '#eee',
    },
    ok: {
      width: '180px',
      background: '#1271ff',
      color: '#fff'
    }
  }

  return <div className="flex flex-col gap-4" style={styles.panel}>
    <p><b>{LL0().payment.setTip()}</b></p>
    <div className="px-2 py-2 h-[40px]" style={styles.input}>{val}</div>

    <p><b>{LL0().payment.defaultTip()}</b></p>
    <section data-name="default-tips" className="flex flex-row flex-wrap gap-2">
      {generalSetting0()?.defaultTips?.map((tip) => <div
        key={tip}
        style={styles.keyboard}
        onClick={() => onSetDefaultTip(tip)}
      >
        {tip}%
      </div>)}
    </section>

    <p><b>{LL0().payment.customTip()}</b></p>
    <div style={styles.keyboards}>
      {keys.map(key => <div key={key} style={styles.keyboard} onClick={() => onKeyPress(key)}>
        {key}
      </div>)}
    </div>

    <div className="flex flex-row items-center justify-end gap-2">
      <div style={{...styles.buttons, ...styles.cancel}} onClick={cancel}>
        {LL0().ui.cancel()}
      </div>
      <div style={{...styles.buttons, ...styles.ok}} onClick={ok}>
        {val ? LL0().ui.ok() : LL0().payment.continueWithoutTip()}
      </div>
    </div>
  </div>
}

export default CustomTipDialog