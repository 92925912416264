import { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { DateType } from "@/data/DataUtils.ts";
import { createCollection } from "@/data/utils/migration.ts";

export const ORDER_TSE_TEMP_COLLECTION_NAME = 'order_tse_temps'

export const VERSION = 2
const orderTseTempSchema = {
  title: 'orderTseTemp schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    date: DateType,
    orderNumber: {type: 'number'},
    sum: {type: 'number'},
    passthroughSum: {type: 'number'},
    onlyFoodHoldNumber: {type: 'number'},
  },
  indexes: ['date']
} as const;

export interface OrderTseTemp {
  _id: string;
  date?: number;
  orderNumber?: number;
  sum?: number;
  passthroughSum?: number;
  onlyFoodHoldNumber: number;
}

export type OrderTseTempDocument = RxDocument<OrderTseTemp>;
export type OrderTseTempCollection = RxCollection<OrderTseTemp>;

export const OrderTseTempCollection: OrderTseTempCollection = {} as OrderTseTempCollection;
export const OrderTseTemp = OrderTseTempCollection;

export const createOrderTseTempCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: OrderTseTemp,
    collectionName: ORDER_TSE_TEMP_COLLECTION_NAME,
    version: VERSION,
    schema: orderTseTempSchema,
  });
}
