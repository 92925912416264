import debug from 'debug'

const log = debug('app:assert')

/**
 * Make an assertion, error will be thrown if `condition` does not have truthy value.
 * 
 * Also logs the message using `debug` module.
 */
export function assert(condition: unknown, message: string): asserts condition {
  if (!condition) {
    log('Assertion failed:', message)
    throw new Error(message)
  }
}
