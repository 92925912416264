import {signal} from "@/react/core/reactive.ts";
import uuid from 'time-uuid';
import {getIndex} from "./layer.ts";
import React, { Fragment } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import {CircularProgress} from "@mui/material";
import PortalPopup from '@passcode/PortalPopup.tsx'
import {LL0} from "@/react/core/I18nService.tsx";

type OnDialogClose<T> = (returnedValue?: T) => void

type DialogState<T> = {
  dlgId: string,
  attrs: Record<string, any>,
  bind: Record<string, any>,
  show: boolean,
  close: OnDialogClose<T>;
  component?: (props: {onClose: OnDialogClose<T>}) => any;
  zIndex: number,
  isLocofyPopup?: boolean
}

export const [dialogState, setDialogState] = signal<Array<DialogState<any>>>([])

// show and return value & close dialog base on handle of child component
export function show<T>(dlgState: Partial<DialogState<T>>) {
  const {component, attrs, bind, isLocofyPopup} = dlgState
  return new Promise<T>(resolve => {
    const dlgId = uuid()
    const newDialog = {
      resolve,
      close: (returnedValue?: T) => {
        setDialogState((prevState) => prevState.filter(dialog => dialog.dlgId !== dlgId))
        // @ts-ignore
        newDialog.resolve?.(returnedValue)
      },
      dlgId,
      attrs: attrs || {},
      bind: bind || {},
      show: true,
      component,
      zIndex: getIndex(),
      isLocofyPopup
    }
    // @ts-ignore
    setDialogState(prev => [...prev, newDialog])
  })
}

// show & return close fn
export function progress(dlgState: Partial<DialogState<any>> & {title: string}) {
  const {attrs, bind, title} = dlgState
  const dlgId = uuid()
  const newDialog = {
    close: (returnedValue?: any) => {},
    dlgId,
    attrs: attrs || {},
    bind: bind || {},
    show: true,
    component: function ({onClose}: {onClose: OnDialogClose<any>}) {
      return <BaseDialog title={title} data-testid='progressDialog'>
        <div className="flex flex-row items-center gap-[12px] min-w-[300px] mb-3">
          <CircularProgress style={{width: '20px', height: '20px'}} color="info" thickness={2} variant="indeterminate"/>
          <span>{LL0().onlineOrder.pleaseWait()}</span>
        </div>
        <div className="flex flex-row justify-end">
          <div className="cursor-pointer rounded bg-royalblue-200 text-white py-2 px-4" onClick={() => onClose()}>
            {LL0().ui.close()}
          </div>
        </div>
      </BaseDialog>
    },
    zIndex: getIndex(),
  }
  // @ts-ignore
  setDialogState(prev => [...prev, newDialog])
  return () => setDialogState((prevState) => prevState.filter(dialog => dialog.dlgId !== dlgId))
}

export function render() {
  return dialogState().map(dialog => (
    <Fragment key={dialog.dlgId}>
      {
        !dialog.isLocofyPopup ?
          <div
            className='dialog-service fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-[#000000af]'
            style={{ zIndex: dialog.zIndex }} {...dialog.attrs}>
            <dialog.component className="dlg-component"  onClose={dialog.close} {...dialog.bind}></dialog.component>
          </div>
          :
          <PortalPopup
            {...dialog.attrs}
            overlayColor="rgba(0, 0, 0, 0.2)"
            placement="Centered"
            onOutsideClick={dialog.close}
          >
            <dialog.component className="dlg-component" onClose={dialog.close} {...dialog.bind}></dialog.component>
          </PortalPopup>
      }
    </Fragment>
    )
  )
}

function BaseDialog(props: any) {
  return <div
    className='bg-basic-color-white no-scrollbar rounded overflow-y-scroll pt-4 px-3 pb-3 box-border gap-[24px] text-left text-sm text-gray font-mulish'>
    <div className='flex flex-row justify-between w-full mb-2'>
      <span className='font-bold'>{props.title || ''}</span>
      {props.onClose && <CloseIcon className="cursor-pointer" onClick={() => props.onClose()}/>}
    </div>
    <div {...props}></div>
  </div>
}

export default {
  BaseDialog,
  show,
  progress,
  render
}