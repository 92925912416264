import {FunctionComponent, memo} from "react";
import EmailReportHeader from "./EmailReportHeader";
import EmailReportBody from "./EmailReportBody";

const EmailReportPlugin: FunctionComponent = () => {
  return (
    <div className="bg-white w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[8px] hover:bg-white hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[8px] hover:items-start hover:justify-start">
      <EmailReportHeader />
      <EmailReportBody />
    </div>
  );
};

export default memo(EmailReportPlugin);
