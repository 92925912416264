import { Fragment, type FunctionComponent } from 'react';
import MonthtlyReportItem from "./MonthtlyReportItem";
import MonthlyReportStatiscal from "./MonthlyReportStatiscal";
import MonthtlyReportItemList from "./MonthtlyReportItemList";
import _ from 'lodash';
import {
  currentMonth,
  monthlyReport,
  onChangeMonth,
  reportConfigs
} from '@/react/MonthlyReportView/MonthlyReportView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { OrderStatus } from '@/pos/OrderType.ts'
import { formatTotalHours } from "@/react/StaffReportView/StaffReportView.tsx";
import { isInSrmTrainingMode } from '@/srm/signals';
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { clsx } from "clsx";

const SalesListItem = ({label, value}: {label: string; value: string | number}) => {
  return <div className="self-stretch flex flex-row items-center justify-between">
    <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
      {label}:
    </div>
    <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
      {LL3().format.currency(Number(value))}
    </div>
  </div>
}

const MonthlyReportLeft: FunctionComponent = () => {
  const month = () => {
    const monthMap: Record<string, string> = {
      January: '_1',
      February: '_2',
      March: '_3',
      April: '_4',
      May: '_5',
      June: '_6',
      July: '_7',
      August: '_8',
      September: '_9',
      October: '_10',
      November: '_11',
      December: '_12'
    };

    const currentMonthName:string = currentMonth().format('MMMM');
    // @ts-ignore
    return LL0().dateTime.monthFull[monthMap[currentMonthName]]();
  };

  const paymentString = Object.keys(monthlyReport()?.paymentReport || {})?.map(paymentName => {
    return ['cash', 'card'].includes(paymentName)
      ? _.get(LL0().payment, paymentName)?.()
      : _.startCase(paymentName);
  }).join(' + ');

  return (
    <div
      className="self-stretch flex-1 flex flex-col items-center justify-start py-0 px-16 bg-[url('/monthly-report-left@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-xl text-white font-mulish md:gap-[0px] md:pl-14 md:pr-14 md:box-border sm:gap-[0px] sm:pl-8 sm:pr-8 sm:box-border Tablet_768:gap-[14px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:gap-[12px] Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border Mobile_480:pl-1.5 Mobile_480:pr-1.5 Mobile_480:box-border">
      <div
        className="self-stretch flex flex-row items-center justify-center py-5 px-0 gap-[24px] sticky md:gap-[24px] md:pt-[18px] md:pb-[18px] md:box-border sm:pt-4 sm:pb-4 sm:box-border">
        <div
          className="relative w-6 h-6 hover:[filter:invert(0.5)_sepia(1)_saturate(5)_hue-rotate(175deg)] sm:w-5 sm:h-5"
          onClick={() => onChangeMonth("PREV")}
        >
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/left-arrow-icon.svg"
          />
        </div>
        <b className="relative leading-[17px] lg:text-xl lg:leading-[17px] md:text-lg sm:text-base">
          {`${month()} ${currentMonth().format('YYYY')}`}
        </b>
        <div
          className="relative w-6 h-6 hover:[filter:invert(0.5)_sepia(1)_saturate(5)_hue-rotate(175deg)] sm:w-5 sm:h-5"
          onClick={() => onChangeMonth("NEXT")}
        >
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/right-arrow-icon.svg"
          />
        </div>
      </div>
      <div
        className="no-scrollbar self-stretch flex-1 bg-white overflow-y-auto flex flex-col items-start justify-start p-3.5 gap-[20px] text-left text-sm text-gray sm:gap-[18px_20px] Tablet_768:p-4 Tablet_768:box-border Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          {
            isInSrmTrainingMode() &&
            <div className="font-bold text-2xl text-tomato-200">{LL0().settings.inTrainingMode().toUpperCase()}</div>
          }
          <div
            className="self-stretch h-7 flex flex-col items-start justify-between text-base text-blue-solid-blue-500-0051c1">
            <b
              className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
              {LL0().common.sales()}
            </b>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4 gap-[8px]">
            {_.entries(monthlyReport()?.paymentReport).map(([paymentName, report], index) =>
              <SalesListItem
                label={['cash', 'card'].includes(paymentName) ? _.get(LL0().payment, paymentName)?.() : _.startCase(paymentName)}
                value={report?.excludeCashback || 0} key={index} />
            )}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          <div
            className="self-stretch h-7 flex flex-col items-start justify-between text-base text-royalblue-200">
            <b
              className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
              {LL0().report.totalTip()}
            </b>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4 text-sm text-gray">
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                {LL0().report.totalTip()}:
              </div>
              <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                {LL3().format.currency(monthlyReport()?.totalTip || 0)}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
            <div className="self-stretch h-7 flex flex-col items-start justify-between text-base text-blue-solid-blue-500-0051c1">
              <b
                className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
                {LL0().common.total()}
              </b>
              <div className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
            </div>
            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4 gap-[8px]">
              <SalesListItem label={LL0().monthlyReport.totalNonCash()}
                             value={monthlyReport()?.totalCashlessSalesExcludingTip} />
              <SalesListItem label={LL0().monthlyReport.totalCashSales()}
                             value={monthlyReport()?.totalCashSalesExcludingCashback} />
              <div className="self-stretch flex flex-row items-center justify-between text-firebrick">
                <div className="flex-1 relative font-extrabold sm:text-xs Tablet_768:text-smi Tablet_600:text-smi">
                  {LL0().common.total()}
                </div>
                <div
                  className="relative text-base [text-decoration:underline] font-extrabold text-right md:text-mini sm:text-smi Tablet_768:text-smi Tablet_600:text-sm">
                  {LL3().format.currency(monthlyReport()?.totalCashlessSalesExcludingTip + monthlyReport()?.totalCashSalesExcludingCashback || 0)}
                </div>
              </div>
              <div className='text-gray-400 italic text-[13px]'>
                {`(${LL0().common.total()} = ${paymentString} - ${LL0().report.totalTip()})`}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          <div className="self-stretch h-7 flex flex-col items-start justify-between text-base text-royalblue-200">
            <b
              className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
              {LL0().monthlyReport.totalCashBack()}
            </b>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4 text-sm text-gray">
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                {LL0().monthlyReport.totalCashBack()}:
              </div>
              <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                {LL3().format.currency(monthlyReport()?.totalCashback || 0)}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          <div
            className="self-stretch h-7 flex flex-col items-start justify-between text-base text-blue-solid-blue-500-0051c1">
            <b
              className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
              {LL0().monthlyReport.taxes()}
            </b>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4">
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                {LL0().common.total()}:
              </div>
              <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                {LL3().format.currency(monthlyReport()?.report?.vTaxSum?.tax || 0)}
              </div>
            </div>
          </div>
          {Object.entries(monthlyReport()?.report?.vTaxSum?.vTaxSum || {})?.map(([key, value]) => (
          <div key={key} className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-8">
              <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                {LL0().common.tax()} ({key?.replace(',','.')}%):
              </div>
              <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                {LL3().format.currency(value.tax || 0)}
              </div>
            </div>
          </div>
          ))}
        </div>
        <div className="self-stretch h-7 flex flex-col items-start justify-between text-base text-royalblue-200">
          <b
            className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
            {LL0().onlineOrder.refund()}
          </b>
          <div className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
        </div>
        {/*<>fixme: total refund</>*/}
        {isQuebecSrmEnabled() && (
          <>
            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4">
              <div className="self-stretch flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[6px]">
                  <div className="w-2 relative rounded-[50%] bg-blue-solid-blue-500-0051c1 h-2" />
                  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                <span>
                  {LL0().monthlyReport.refundItems()}:
                </span>
                  </div>
                </div>
                <b className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                  {LL3().format.currency(Number(monthlyReport()?.refundItemReport || 0))}
                </b>
              </div>
            </div>

            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-[60px] pl-8">
              {monthlyReport()?.refundItems && monthlyReport()?.refundItems?.map((item, index) =>
                <div key={index} className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                    {item.quantity.toString().replace('-', '')} x {item.name}
                  </div>
                  <div className="flex-1 relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                    {LL3().format.currency(item.vSum)}
                  </div>
                </div>
              )}
            </div>

            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4">
              <div className="self-stretch flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[6px]">
                  <div className="w-2 relative rounded-[50%] bg-blue-solid-blue-500-0051c1 h-2" />
                  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                <span>
                  {LL0().monthlyReport.refundAmount()}:
                </span>
                  </div>
                </div>
                <b className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                  {LL3().format.currency(Number(monthlyReport()?.totalRefund || 0) - Number(monthlyReport()?.refundItemReport || 0))}
                </b>
              </div>
            </div>
          </>
        )}

        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          <div
            className={clsx("self-stretch flex flex-col items-start justify-center py-0 pr-0 text-sm", isQuebecSrmEnabled() ? 'font-extrabold text-firebrick' : 'text-gray pl-4')}>
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                {LL0().common.total()}
              </div>
              <div
                className={clsx("relative text-right Tablet_768:text-smi Tablet_600:text-xs", isQuebecSrmEnabled() ? '[text-decoration:underline]' : '')}>
                {LL3().format.currency(monthlyReport()?.totalRefund || 0)}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          <div
            className="self-stretch h-7 flex flex-col items-start justify-between text-base text-blue-solid-blue-500-0051c1">
            <b
              className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
              {LL0().orderHistory.canceledItems()}
            </b>
            <div
              className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-solid-black-1000-000000" />
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4">
              <div className="self-stretch flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[6px]">
                  <div className="w-2 relative rounded-[50%] bg-blue-solid-blue-500-0051c1 h-2" />
                  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                    <span>
                      {LL0().orderHistory.cancelledBeforePaid()}:
                    </span>
                  </div>
                </div>
                <b className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                  {LL3().format.currency(Number(monthlyReport()?.cancelledReport?.cancelledBeforePaid || 0) + Number(monthlyReport()?.cancelledReport?.paid || 0))}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-[60px] pl-8">
              {monthlyReport()?.cancelledItems && monthlyReport()?.cancelledItems.filter(item => item.status === OrderStatus.CANCELLED_BEFORE_PAID || item.status === OrderStatus.PAID).map((item, index) =>
                <div className="self-stretch flex flex-row items-center justify-between" key={index}>
                  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                    {item.quantity.toString()} x {item.name}
                  </div>
                  <div className="flex-1 relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                    {LL3().format.currency(item.vSum)}
                  </div>
                </div>)
              }
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-0 pl-4">
              <div className="self-stretch flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[6px]">
                  <div className="w-2 relative rounded-[50%] bg-blue-solid-blue-500-0051c1 h-2" />
                  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                    <span>
                      {LL0().orderHistory.cancelledAfterPaid()}:
                    </span>
                  </div>
                </div>
                <b className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                  {LL3().format.currency(Number(monthlyReport()?.cancelledReport?.cancelled || 0))}
                </b>
              </div>
            </div>

            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-[60px] pl-8">
              {monthlyReport()?.cancelledItems && monthlyReport()?.cancelledItems.filter(item => item.status === OrderStatus.CANCELLED).map((item, index) =>
                <div key={index} className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                    {item.quantity.toString().replace('-', '')} x {item.name}
                  </div>
                  <div className="flex-1 relative text-right Tablet_768:text-smi Tablet_600:text-xs">
                    {LL3().format.currency(item.vSum)}
                  </div>
                </div>)
              }
            </div>
            <div className="self-stretch flex flex-row items-center justify-between text-firebrick">
              <div className="flex-1 relative font-extrabold sm:text-xs Tablet_768:text-smi Tablet_600:text-smi">
                {LL0().common.total()}
              </div>
              <div
                className="relative text-base [text-decoration:underline] font-extrabold text-right md:text-mini sm:text-smi Tablet_768:text-smi Tablet_600:text-sm">
                {LL3().format.currency(monthlyReport()?.cancelledItemReport || 0)}
              </div>
            </div>
          </div>
        </div>
        <MonthlyReportStatiscal />
        {reportConfigs().showMoreZNumber &&
          <div
            className="self-stretch flex flex-col items-center justify-start gap-[8px] text-base text-royalblue-200">
            <div className="self-stretch h-7 flex flex-col items-start justify-between">
              <b
                className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
                {LL0().report.ZNumber()}
              </b>
              <div
                className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
            </div>
            {_.entries(monthlyReport()?.zNumbers).map(([date, dateValue], index) =>
              <Fragment key={index}>
                {_.entries(dateValue).map(([zIndex, zValue], index) =>
                  <div
                    className="self-stretch flex flex-row items-center justify-between py-0 px-4 text-right text-sm text-gray"
                    key={index}>
                    <div className="flex-1 relative text-left Tablet_768:text-smi Tablet_600:text-xs">
                      {LL0().report.ZNumber()} {zIndex}:
                    </div>
                    <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                      {LL3().format.currency(zValue)}
                    </div>
                    <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
                      {LL0().common.date()}: {date}
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        }
        {reportConfigs().showEmployeeTimeClock &&
          <div
            className="self-stretch flex flex-col items-start justify-start gap-[8px] text-base text-royalblue-200">
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <b
                className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
                {LL0().monthlyReport.timeClock()}
              </b>
              <div
                className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
            </div>
            {_.entries(monthlyReport()?.timeClocksByUser).map(([username, { timeClocksByDate, totalHours }], index) =>
              <MonthtlyReportItemList
                staff={`${_.startCase(username)}:`}
                username={`${username}`}
                //TODO: Check this
                // staffInfo={`${timeClocks.length} shifts in 20 days`}
                staffInfo={`${_.values(timeClocksByDate).reduce((acc, curr) => acc + curr.length, 0)} ${LL0().monthlyReport.shifts().toLowerCase()}`}
                time={`${formatTotalHours(totalHours)}`}
                userRoleLabel={`${_.startCase(username)}:`}
                showViewDetailButton
                showViewDetail={false}
                timeClocksByDate={timeClocksByDate}
                showDefault
                key={index}
              />)}
          </div>
        }
        {reportConfigs().showProductSold &&
          <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
            <div
              className="self-stretch h-7 flex flex-col items-start justify-between text-base text-royalblue-200">
              <b
                className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
                {LL0().monthlyReport.productSold()}
              </b>
              <div
                className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-100" />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              {_.entries(monthlyReport()?.groupItemsByCategory).map(([category, items], index) =>
                <Fragment key={index}>
                  <div className="self-stretch flex flex-row items-center justify-between">
                    <div className="relative font-extrabold sm:text-smi Tablet_768:text-smi Tablet_600:text-smi">
                      {category === "Other" ? LL0().dialogs.other() : category}:
                    </div>
                    <div
                      className="relative font-extrabold text-right sm:text-smi Tablet_768:text-smi Tablet_600:text-smi">
                      {LL3().format.currency(monthlyReport()?.sumByCategory[category] || 0)}
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start py-0 pr-0 pl-4 gap-[8px]">
                    {_.entries(items).map(([itemName, value], index) =>
                      <MonthtlyReportItem
                        itemQuantity={value.quantity?.toString()}
                        itemName={itemName}
                        itemPrice={LL3().format.currency(value.vSum)}
                        key={index}
                      />)}
                  </div>
                </Fragment>)
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default MonthlyReportLeft;