import { getApiUrl } from '@/shared/utils'
import { rnHost } from '@/shared/webview/rnwebview'
import { QuebecSDK } from '@/srm/lib/sdk'
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

export function getSignableSdk() {
  const { authCode, env } = posSetting0()?.srm ?? {}
  if (!authCode) throw new Error('Missing authCode!')

  const { cert, privateKey, certPSI } = deviceSetting0()?.srm ?? {}
  if (!cert) throw new Error('Missing cert!')
  if (!privateKey) throw new Error('Missing privateKey!')
  if (!certPSI) throw new Error('Missing certPSI!')

  return new QuebecSDK({
    relayFn: rnHost.relay,
    relayUrl: getApiUrl() + '/api/quebec-srm/relay',
    cert: cert,
    key: privateKey,
    authCode: authCode,
    certWebSRM: certPSI,
    debug: import.meta.env.MODE !== 'production',
    isProd: env === 'PROD',
  })
}
