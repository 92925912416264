import type { Product } from '@/data/Product'
import defaultTaxesByCity from '@/react/PaymentSettingView/defaultTaxesByCity.json'
import { getUrlParam } from '@/shared/utils2'

/**
 * Generate mapper function to apply default taxes for Product
 */
export function applyDefaultTaxByCountry(countryCode?: string): (product: Product) => Product {
  countryCode = countryCode || getUrlParam('cn') || import.meta.env.VITE_COUNTRY
  // only 1 tax
  if (countryCode === 'ca') {
    const defaultTax = defaultTaxesByCity['Canada-Quebec'][0]
    const defaultTaxValue = defaultTax.components.reduce((acc, curr) => acc + curr.value, 0)
    return product => ({
      ...product,
      tax: defaultTaxValue,
      tax2: defaultTaxValue,
      taxCategory: defaultTax.name,
      taxCategory2: defaultTax.name,
      taxComponents: defaultTax.components,
      taxComponents2: defaultTax.components,
    })
  } else if (countryCode === 'de') {
    const defaultTaxes = defaultTaxesByCity['Germany-All Cities']
    const dineInTaxes = defaultTaxes.filter(tax => tax.type.split(':')[1] === 'dineIn')
    const takeAwayTaxes = defaultTaxes.filter(tax => tax.type.split(':')[1] === 'takeAway')

    return product => {
      const foundTax = dineInTaxes.find(tax => tax.value === product.tax) || dineInTaxes[0]
      const foundTax2 = takeAwayTaxes.find(tax => tax.value === product.tax2) || takeAwayTaxes[0]
      return {
        ...product,
        tax: foundTax.value,
        tax2: foundTax2.value,
        taxCategory: foundTax.name,
        taxCategory2: foundTax2.name,
      }
    }
  }
  return p => p // NOOP
}
