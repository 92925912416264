import { FunctionComponent, useState, useCallback } from "react";
import PopupOrderDetailsPlugin from "./PopupOrderDetailsPlugin";
import PortalPopup from "./PortalPopup";

type PendingOrderOrderCardType = {
  noticeText?: string;
  orderID?: string;
  customerSName?: string;
  customerSPhoneNumber?: string;
  customerSAddress?: string;
  orderTime?: string;
  showEditIcon?: boolean;
  showOrderIsOnTheWay?: boolean;
  showTakeawayIcon?: boolean;
  showDeliverIcon?: boolean;
  showOrderWasDelivered?: boolean;
  showOrderIsRuningOutOfTime?: boolean;
  showSkipDishIcon?: boolean;
  showUberEatsIcon?: boolean;
  showOrderIsReady?: boolean;
  showNotice?: boolean;
  orderIsOnTheWay?: boolean;
  orderWasDelivered?: boolean;
  showDoordashIcon?: boolean;
  showVegaIcon?: boolean;
  showPikaIcon?: boolean;
  takeawayIconVisible?: boolean;
  showNoticeOrderIsReady?: boolean;
  showModifier?: boolean;
};

const PendingOrderOrderCard: FunctionComponent<PendingOrderOrderCardType> = ({
  noticeText = "Order is running out of time",
  orderID = "#121 ",
  customerSName = "Claudia C Braun",
  customerSPhoneNumber = "098765432",
  customerSAddress = "Kurfuerstendamm 29, München 8001 hgahghgxjhx",
  orderTime = "10:00",
  showEditIcon = true,
  showOrderIsOnTheWay = false,
  showTakeawayIcon = true,
  showDeliverIcon = true,
  showOrderWasDelivered = false,
  showOrderIsRuningOutOfTime = true,
  showSkipDishIcon = true,
  showUberEatsIcon = true,
  showOrderIsReady = false,
  showNotice = true,
  orderIsOnTheWay,
  orderWasDelivered,
  showDoordashIcon,
  showVegaIcon,
  showPikaIcon,
  takeawayIconVisible,
  showNoticeOrderIsReady,
  showModifier,
}) => {
  const [isPopupOrderDetailsPluginOpen, setPopupOrderDetailsPluginOpen] =
    useState(false);

  const openPopupOrderDetailsPlugin = useCallback(() => {
    setPopupOrderDetailsPluginOpen(true);
  }, []);

  const closePopupOrderDetailsPlugin = useCallback(() => {
    setPopupOrderDetailsPluginOpen(false);
  }, []);

  return (
    <>
      <div className="rounded flex flex-col items-start justify-start py-3 px-4 box-border relative gap-[6px] text-left text-sm text-white-solid-white-100-ffffff font-mulish self-stretch">
        {showOrderIsRuningOutOfTime && (
          <div className="!block absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-white-solid-white-100-ffffff shadow-[-2px_-2px_6px_rgba(255,_0,_0,_0.32)_inset,_3px_2px_6px_rgba(255,_0,_0,_0.32)_inset] hidden z-[0]" />
        )}
        {showOrderIsReady && (
          <div className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-white-solid-white-100-ffffff shadow-[-2px_-2px_6px_rgba(31,_178,_94,_0.55)_inset,_3px_2px_6px_rgba(31,_178,_94,_0.55)_inset] z-[1]" />
        )}
        {showOrderIsOnTheWay && (
          <div className="!block absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-white-solid-white-100-ffffff shadow-[-2px_-2px_6px_rgba(255,_138,_0,_0.55)_inset,_3px_2px_6px_rgba(255,_138,_0,_0.55)_inset] hidden z-[2]" />
        )}
        {showOrderWasDelivered && (
          <div className="!block absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0%] bottom-[0px] left-[0%] rounded bg-white-solid-white-100-ffffff shadow-[-2px_-2px_6px_rgba(144,_75,_255,_0.55)_inset,_3px_2px_6px_rgba(144,_75,_255,_0.55)_inset] hidden z-[3]" />
        )}
        {showNotice && (
          <div className="rounded-sm bg-red-solid-red-200-ff5252 overflow-hidden flex flex-row items-center justify-start py-1 px-1.5 relative gap-[4px] z-[4] text-smi">
            {showNoticeOrderIsReady && (
              <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-green-solid-green-650-1fb25e z-[0]" />
            )}
            {!orderIsOnTheWay && (
              <div className="!block absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-orange-solid-orange-500-ff8a00 hidden z-[1]" />
            )}
            {!orderWasDelivered && (
              <div className="!block absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-purple-solid-purple-600-904bff hidden z-[2]" />
            )}
            <b className="relative z-[3] Tablet_768:text-xs mq1440:text-sm">
              {noticeText}
            </b>
          </div>
        )}
        <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-1 gap-[4px] z-[5] text-blue-solid-blue-420-2979ff">
          {showSkipDishIcon && (
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconskip-the-dishes@2x.png"
            />
          )}
          {showDoordashIcon && (
            <img
              className="relative w-[23px] h-6 object-cover"
              alt=""
              src="/doordash-icon@2x.png"
            />
          )}
          {showVegaIcon && (
            <img
              className="relative w-[23px] h-6 object-cover"
              alt=""
              src="/vega-icon@2x.png"
            />
          )}
          {showPikaIcon && (
            <img
              className="relative w-[30px] h-6 object-cover"
              alt=""
              src="/pika-icon@2x.png"
            />
          )}
          {showUberEatsIcon && (
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconuber-eats-icon@2x.png"
            />
          )}
          {showTakeawayIcon && (
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/self-delivery@2x.png"
            />
          )}
          <b className="relative leading-[24px] mq1440:text-base">{orderID}</b>
          <div className="flex-1 flex flex-row items-center justify-start gap-[2px] text-xs text-black-solid-black-900-1e1e23">
            <div className="relative leading-[15px] mq1440:text-sm">
              {customerSName}
            </div>
            <div className="relative leading-[15px] mq1440:text-sm">-</div>
            <div className="relative leading-[15px] mq1440:text-sm">
              {customerSPhoneNumber}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] z-[6] text-smi text-gray-solid-gray-800-777777">
          <div className="relative w-5 h-5 overflow-hidden shrink-0 mq1440:w-6 mq1440:h-6">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/iconpin-icon@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 relative leading-[117.4%] mq1440:text-mini">
            {customerSAddress}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] z-[7] text-red-solid-red-660-8f181d">
          <div className="relative w-5 h-5 overflow-hidden shrink-0 mq1440:w-6 mq1440:h-6">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/iconfood-icon@2x.png"
            />
          </div>
          <div className="flex-1 flex flex-row flex-wrap items-start justify-start">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="flex flex-row items-start justify-start">
                <b className="relative leading-[145.6%] mq1440:text-base">3x</b>
                <div className="relative leading-[145.6%] text-black-solid-black-900-1e1e23 mq1440:text-base">
                  Chicken so
                </div>
              </div>
              <i className="relative leading-[145.6%] text-gray-solid-gray-800-777777 mq1440:text-base">
                (khong hanh, them thit)
              </i>
            </div>
          </div>
        </div>
        <div className="my-0 mx-[!important] absolute top-[7px] right-[7px] flex flex-row items-center justify-end gap-[10px] z-[8] text-right text-black-solid-black-900-1e1e23">
          {showEditIcon && (
            <img
              className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
              alt=""
              src="/iconshift-icon@2x.png"
            />
          )}
          <div
            className="rounded-sm bg-gray-solid-gray-150-eeeeee flex flex-row items-center justify-start py-1 px-2 gap-[4px] cursor-pointer border-[1px] border-solid border-gray-solid-gray-350-ced1d7 Tablet_768:gap-[2px]"
            onClick={openPopupOrderDetailsPlugin}
          >
            {showDeliverIcon && (
              <div className="relative w-6 h-6 overflow-hidden shrink-0 Tablet_768:w-[22px] Tablet_768:h-[22px] mq1440:w-7 mq1440:h-7">
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/icondeliver-icon@2x.png"
                />
              </div>
            )}
            {takeawayIconVisible && (
              <div className="relative w-6 h-6 Tablet_768:w-[22px] Tablet_768:h-[22px] mq1440:w-7 mq1440:h-7">
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/icontakeaway-icon1@2x.png"
                />
              </div>
            )}
            <b className="relative leading-[15px] inline-block w-[38px] shrink-0 Tablet_768:text-xs">
              {orderTime}
            </b>
          </div>
        </div>
      </div>
      {isPopupOrderDetailsPluginOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePopupOrderDetailsPlugin}
        >
          <PopupOrderDetailsPlugin onClose={closePopupOrderDetailsPlugin} />
        </PortalPopup>
      )}
    </>
  );
};

export default PendingOrderOrderCard;
