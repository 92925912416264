import { type FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { signal } from '@/react/core/reactive.ts'

type PaymentScreenPopupType = {
  onClose?: (ok?: boolean) => void;
  content?: string;
  stillWantToCompleteThePaymentProcess?: boolean;
};

const PaymentScreenPopup: FunctionComponent<PaymentScreenPopupType> = ({
  onClose,
  content,
  stillWantToCompleteThePaymentProcess,
}) => {
  return (
    <div className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[24px] h-full max-w-full max-h-full text-center text-lg text-red-solid-red-550-cf2222 font-mulish">
      <img
        className="absolute !m-[0] top-[8px] right-[8px] w-6 h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[0]"
        alt=""
        src="/payment-close-popup-icon--grey@2x.png"
        onClick={() => onClose?.(false)}
      />
      <div className="self-stretch flex flex-col items-start justify-start gap-[23px] z-[1]">
        {!content ?
          (!stillWantToCompleteThePaymentProcess && <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
            <b className="self-stretch relative">
              {LL0().payment.theAmountEnteredInsufficient()}
            </b>
            <div className="self-stretch relative text-base text-black-solid-black-900-1e1e23">
              {LL0().payment.pleaseComebackAndCheckAgain()}
            </div>
          </div>)
          :
          (!stillWantToCompleteThePaymentProcess && <div className="!flex self-stretch hidden flex-col items-center justify-start gap-[12px]">
            <b className="self-stretch relative">
              {LL0().payment.theAmountEnteredInsufficient()}
            </b>
            <div className="self-stretch flex flex-row items-center justify-center gap-[4px] text-base text-black-solid-black-900-1e1e23">
              <div className="relative">{LL0().payment.pleaseCheckOn1()}:</div>
              <i className="relative font-bold">{content}</i>
            </div>
          </div>)
        }
        {stillWantToCompleteThePaymentProcess && <div className="self-stretch flex-col items-center justify-start gap-[12px]">
          <b className="self-stretch relative">
            {LL0().payment.stillWantToCompleteThePaymentProcess()}
          </b>
          <div className="self-stretch relative text-base text-black-solid-black-900-1e1e23">{LL0().payment.stillHaveUnpaidSeats()}</div>
        </div>}
      </div>
      <div className="flex flex-row items-center justify-center gap-[8px] z-[2] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="rounded-md bg-white-solid-white-100-ffffff shrink-0 flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer text-mini border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={() => onClose?.(false)}
        >
          <div className="relative font-semibold">{LL0().orderHistory.comeBack()}</div>
        </div>
        <div
          className="self-stretch w-[78px] rounded-md bg-white-solid-white-100-ffffff box-border overflow-hidden shrink-0 hidden flex-row items-center justify-center py-[9px] px-6 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={() => onClose?.(false)}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        {stillWantToCompleteThePaymentProcess &&
          <div className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex-row items-center justify-center py-[9px] px-8 text-white-solid-white-100-ffffff"
               onClick={() => onClose?.(true)}>
            <b className="relative uppercase">{LL0().ui.ok()}</b>
          </div>}
      </div>
    </div>
  );
};

export default PaymentScreenPopup;
