import { GroupPrinter } from '@/data/GroupPrinter.ts';
import { invoiceGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

// to get mapped printer from deviceSetting if there are multiple printers
export const getMappedPrinter = (groupPrinter: GroupPrinter) => {
  const mappedPrinterMapItem = deviceSetting0()!.printerMapping?.find(mapItem => mapItem.groupPrinter === groupPrinter._id);
  // if not multiple positions or have no mapped printer
  if (!groupPrinter.hasMultiplePositions || !mappedPrinterMapItem)
    return groupPrinter.printers[0];
  // else get mapped one
  const mappedPrinterId = mappedPrinterMapItem?.position;
  return groupPrinter.printers.find(printer => printer._id === mappedPrinterId)
}

export function getInvoicePrinter() {
  const groupPrinter = invoiceGroupPrinters0()[0];
  const invoicePrinter = getMappedPrinter(groupPrinter);
  return invoicePrinter;
}