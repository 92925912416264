import type { RxCollection } from 'rxdb'

import type { TOrder } from '@/pos/OrderType'

export const ONLINE_ORDER_COLLECTION_NAME = 'online_orders'
export const OnlineOrder = {} as RxCollection<TOrder>

const clearOnlineOrder = async () => {
  const removedOrders = await OnlineOrder.find().remove()
  console.log(`Removed ${removedOrders.length} online order(s):`, removedOrders)
}

Object.assign(window, { OnlineOrder, clearOnlineOrder }) // Make available global
