import { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

export type AutoIntergratingPopupType = {
  className?: string;
  onClose?: () => void;
};

const AutoIntergratingPopup: FunctionComponent<AutoIntergratingPopupType> = ({
  className = "",
  onClose,
}) => {
  return (
    <div
      className={`w-full h-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center p-6 box-border gap-[24px] min-w-[320px] max-w-full max-h-full text-left text-xl text-black-solid-black-900-1e1e23 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-2 gap-[24px]">
        <div className="flex flex-col items-center justify-center gap-[12px]">
          <img
            className="w-[72px] relative h-[72px] object-cover"
            alt=""
            src="/payment-iconchecked-icon@2x.png"
          />
          <div className="relative font-semibold">{LL0().payment.approved()}</div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-center gap-[8px] text-mini">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative font-semibold">{LL0().onlineOrder.refundDialog.totalAmount()}:</div>
            <div className="relative font-semibold">{LL3().format.currency(64)}</div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative font-semibold">{LL0().payment.tip()}:</div>
            <div className="relative font-semibold text-green-solid-green-600-00c37c">
              {LL3().format.currency(64)}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff h-9 flex flex-row items-center justify-center text-center text-mini text-white-solid-white-100-ffffff">
        <div className="relative font-semibold">{LL0().ui.ok()}</div>
      </div>
    </div>
  );
};

export default AutoIntergratingPopup;