import {FunctionComponent, memo, useCallback, useState} from "react";
import PaymentVerticalAddPayment from "./PaymentVerticalAddPayment";
import PortalPopup from "./PortalPopup";
import PaymentVerticalMoreButton from "./PaymentVerticalMoreButton";
import PaymentVerticalPrintButto from "./PaymentVerticalPrintButto";
import PaymentVerticalInvoice from "./PaymentVerticalInvoice";
import {makeRipple} from '@/react/core/ripple-utils.ts';
import {onPaymentComplete, order0, PaymentScreenMode, setPaymentScreenMode} from '@/react/PaymentView/PaymentView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

type PaymentVerticalPluginType = {
  onClose?: () => void;
};

const PaymentVerticalPlugin: FunctionComponent<PaymentVerticalPluginType> = ({
  onClose,
}) => {
  const [isPaymentVerticalAddPaymentOpen, setPaymentVerticalAddPaymentOpen] =
    useState(false);

  const openPaymentVerticalAddPayment = useCallback(() => {
    setPaymentVerticalAddPaymentOpen(true);
  }, []);

  const closePaymentVerticalAddPayment = useCallback(() => {
    setPaymentVerticalAddPaymentOpen(false);
  }, []);

  return (
    <>
      <div className="bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-center justify-start text-center text-mini text-white-solid-white-100-ffffff font-mulish-button-name-bold">
        <div className="self-stretch bg-black-solid-black-500-59636f shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] h-16 flex flex-row items-center justify-between py-0 px-3 box-border z-[1] Mobile_480:h-14 Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border mq320:h-12 mq320:pl-1.5 mq320:pr-1.5 mq320:box-border">
          <div className="!items-center h-[42px] flex flex-row items-start justify-start gap-[8px] Mobile_480:h-9 Mobile_480:gap-[4px] mq320:h-8 mq320:gap-[2px] mq320:pl-0 mq320:box-border">
            <PaymentVerticalMoreButton />
            <div
              className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#a259ff] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-1 px-3 gap-[0px_4px] cursor-pointer Mobile_480:pl-2.5 Mobile_480:pr-2.5 Mobile_480:box-border mq320:pl-1.5 mq320:pr-1.5 mq320:box-border"
              onClick={openPaymentVerticalAddPayment}
              ref={makeRipple}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:hidden Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:hidden mq320:w-6 mq320:h-6"
                alt=""
                src="/payment-vertical--complete-icon@2x.png"
              />
              <b className="relative leading-[17px] Mobile_480:text-smi mq320:text-xs">
                {LL0().paymentVertical.appTip()}
              </b>
            </div>
            { !order0().seatMode &&
            <PaymentVerticalPrintButto isExpanded={false} />
            }
          </div>
          <div className="h-[42px] flex flex-row items-start justify-end gap-[0px_8px] Mobile_480:h-9 Mobile_480:gap-[0px_8px] mq320:h-8 mq320:gap-[0px_8px]">
          <div className="self-stretch rounded-81xl [background:linear-gradient(188.59deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ed1d00] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-1 px-3 gap-[0px_4px] Mobile_480:pl-2.5 Mobile_480:pr-2.5 Mobile_480:box-border mq320:pl-1.5 mq320:pr-1.5 mq320:box-border"
               onClick={onPaymentComplete}
          >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:hidden Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:hidden mq320:w-6 mq320:h-6"
                alt=""
                src="/payment-vertical--complete-icon1@2x.png"
              />
              <b className="relative leading-[17px] Mobile_480:text-smi mq320:text-xs">
                {LL0().ui.complete()}
              </b>
            </div>
          <div className="self-stretch w-16 rounded-[30px] [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ed7900] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden shrink-0 flex flex-col items-center justify-center Mobile_480:w-12 mq320:w-10"
               ref={makeRipple}
               onClick={() => setPaymentScreenMode(PaymentScreenMode.PAYMENT)}
          >
              <img
                className="w-8 relative h-8 object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                alt=""
                src="/payment-vertical--back-icon@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start py-10 px-4 gap-[30px_0px] z-[0] text-xl text-black-solid-black-1000-000000 font-verdana Mobile_480:py-6 Mobile_480:px-3 Mobile_480:box-border mq320:py-4 mq320:px-2.5 mq320:box-border">
          {/*TODO: Replace w real address later*/}
          <div className="self-stretch flex flex-col items-center justify-start gap-[8px_0px]">
            <b className="self-stretch relative Mobile_480:text-lg mq320:text-base">
              {companyInfo0().name}
            </b>
            <div className="self-stretch relative text-xs Mobile_480:text-2xs mq320:text-2xs">
              <p className="m-0">
                {companyInfo0().address}
              </p>
              <p className="m-0">{LL0().printing.tel()}: {companyInfo0().telephone} VAT Reg No: {companyInfo0().taxNumber}</p>
            </div>
          </div>
          {order0().seatMode ?
            order0().seatMap?.map(
            (_order, index) => (
              <PaymentVerticalInvoice
                key={index}
                tableNumber={order0().table}
                seat={`${LL0().payment.seat()} ${index + 1}`}
                subtotalPrice="196.62"
                hST="25.56"
                total="222.18"
                showTitleWrapper
                orderData={_order}
              />
            )
          )
          :
            <PaymentVerticalInvoice
              tableNumber={order0().table}
              seat={`${LL0().payment.seat()} 1`}
              subtotalPrice="196.62"
              hST="25.56"
              total="222.18"
              showTitleWrapper
              isSplit={false}
              orderData={order0()}
            />
          }
          <b className="self-stretch relative Mobile_480:text-lg mq320:text-base">
            {LL0().paymentVertical.thankYou()}
          </b>
        </div>
      </div>
      {isPaymentVerticalAddPaymentOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentVerticalAddPayment}
        >
          <PaymentVerticalAddPayment onClose={closePaymentVerticalAddPayment} />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(PaymentVerticalPlugin);
