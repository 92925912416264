import { type FunctionComponent, useState } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  fromDate,
  reportConfigs,
  setFromDate,
  setToDate,
  toDate
} from '@/react/MonthlyReportView/MonthlyReportView.tsx';
import dayjs from 'dayjs';
import _ from 'lodash';
import {LL0} from "@/react/core/I18nService.tsx";
import { setShowTrainingMode, showTrainingMode } from '@/pos/trainingMode.ts'
import { LL2 } from "@/react/core/I18nBackend.tsx";

const MonthlyReportRight: FunctionComponent = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="self-stretch bg-whitesmoke-100 shadow-[0px_0px_4px_rgba(0,_0,_0,_0.4)_inset] w-[243px] overflow-y-auto shrink-0 flex flex-col items-start justify-start p-4 box-border gap-[25px] h-full text-left text-black Tablet_768:w-[230px] Tablet_600:h-full Mobile_480:h-full">
        <div className="self-stretch">
          <DatePicker
            format={LL2().dates.dateFormat()}
            label={`${LL0().report.from()}:`}
            value={dayjs.unix(fromDate())}
            onChange={(newValue) => {
              newValue && setFromDate(newValue.unix()); // Do not set if newValue is null
            }}
            sx={{}}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                fullWidth: true,
                required: false,
                autoFocus: false,
                error: false,
                color: "primary",
                placeholder: "From:",
              },
              openPickerIcon: {
                component: () => <></>,
              },
            }}
          />
        </div>
        <div className="self-stretch">
          <DatePicker
            format={LL2().dates.dateFormat()}
            label={`${LL0().report.to()}:`}
            value={dayjs.unix(toDate())}
            onChange={(newValue) => {
              newValue && setToDate(newValue.unix()); // Do not set toDate if newValue is null
            }}
            sx={{}}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                fullWidth: true,
                required: false,
                autoFocus: false,
                error: false,
                color: "primary",
                placeholder: "To:",
              },
              openPickerIcon: {
                component: () => <></>,
              },
            }}
          />
        </div>
        <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[12px]">
          <FormControlLabel
            className="self-stretch"
            label={LL0().report.showProductSold()}
            control={<Switch color="primary"
                             checked={reportConfigs().showProductSold || false}
                             onChange={(e) => _.assign(reportConfigs(), { showProductSold: e.target.checked })}
            />}
          />
          <FormControlLabel
            className="self-stretch"
            label={LL0().monthlyReport.showMoreZNumber()}
            control={<Switch color="primary"
                             checked={reportConfigs().showMoreZNumber || false}
                             onChange={(e) => _.assign(reportConfigs(), { showMoreZNumber: e.target.checked })}
            />}
          />
          <FormControlLabel
            className="self-stretch"
            label={LL0().monthlyReport.showEmployeeTimeClock()}
            control={<Switch color="primary"
                             checked={reportConfigs().showEmployeeTimeClock || false}
                             onChange={(e) => _.assign(reportConfigs(), { showEmployeeTimeClock: e.target.checked })}
            />}
          />
          <FormControlLabel
            className="self-stretch"
            label={LL0().monthlyReport.showTrainingMode()}
            control={<Switch color="primary"
                             checked={showTrainingMode() || false}
                             onChange={(e) => setShowTrainingMode(e.target.checked)}
            />}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default MonthlyReportRight;
