import Queue from "queue";
import AwaitLock from "await-lock";

export default class MultiAwaitLock {
	_acquired = false;
	queue = new Queue({ concurrency: 1 });

	constructor(acquired: boolean = false) {
		if (acquired) {
			this.tryAcquire();
		}
	}

	get acquired() {
		return this._acquired;
	}

	tryAcquire() {
		if (!this._acquired) {
			this._acquired = true;
			return true;
		}

		return false;
	}

	async acquireAsync(timeout?: number) {
		if (!this._acquired) return;
		const lock = new AwaitLock();
		lock.tryAcquire();
		this.queue.push(async (cb) => {
			lock.acquired && lock.release();
			cb?.();
		});
		timeout && setTimeout(() => {
			lock.acquired && lock.release();
		}, timeout);
		await lock.acquireAsync();
	}

	async release() {
		if (!this._acquired) return;
    this._acquired = false;
		await new Promise((resolve: Function, reject: Function) => {
			this.queue.start(e => {
				if (e) reject(e);
				resolve();
			});
		});
	}
}
