import { FunctionComponent } from "react";
import { Slider, Box } from "@mui/material";
import { objectBorderRadiusConstructor } from '@/react/FloorPlanView/RenderRoom.tsx';
import { roomObject0 } from '@/react/EditRoomView/EditRoomView.tsx';
import _ from 'lodash';
import { useEffectOn, useSignal } from '@/react/core/reactive.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { RoomObject } from '@/data/Room.ts';
import {LL0} from "@/react/core/I18nService.tsx";

type CustomTablePopupType = {
  onClose?: () => void;
};


const CustomTablePopup: FunctionComponent<CustomTablePopupType> = ({
  onClose,
}) => {
  const [cloneRadiusObject, setCloneRadiusObject] = useSignal<RoomObject['borderRadius']>();
  useEffectOn([roomObject0], () => setCloneRadiusObject(roomObject0()?.borderRadius))

  const onComplete = () => {
    _.assign(roomObject0(), { borderRadius: cloneRadiusObject()});
    onClose && onClose()
  }

  const borderSliderPropsConstructor = (type: keyof RoomObject['borderRadius']) => ({
    onChange:(_e: any, number: number) => {
      setCloneRadiusObject(prev => ({...prev, [type]: number }))
    },
    value: cloneRadiusObject()?.[type] || 0,
    valueLabelDisplay: "auto"
  })


  return (
    <div className="min-w-[60vw] bg-basic-color-white w-full h-full overflow-hidden flex flex-col items-end justify-start p-5 box-border gap-[10px] max-w-full max-h-full text-left text-base text-royalblue-300 font-mulish lg:gap-[0px] lg:p-10 lg:box-border md:p-[30px] md:box-border sm:p-[26px] sm:box-border Tablet_768:gap-[0px] Tablet_768:p-[26px] Tablet_768:box-border Tablet_600:gap-[0px] Tablet_600:py-[22px] Tablet_600:px-5 Tablet_600:box-border Mobile_480:gap-[0px] Mobile_480:py-[22px] Mobile_480:px-5 Mobile_480:box-border">
      <div className="self-stretch flex flex-row items-center justify-between">
        <b className="flex-1 relative sm:text-mini Tablet_768:text-mini Tablet_600:text-mini Mobile_480:text-sm">
          {LL0().editTablePlan.customizeYourTable()}:
        </b>
      </div>
      <div className="self-stretch flex-1 flex flex-row items-center justify-center py-[26px] px-[25px] gap-[48px] text-smi text-black lg:gap-[60px] sm:gap-[48px] Tablet_768:gap-[46px] Tablet_600:gap-[30px] Tablet_600:p-2.5 Tablet_600:box-border Mobile_480:gap-[6px] Mobile_480:p-2 Mobile_480:box-border">
        <div className="h-full flex-1 flex flex-col items-center justify-between py-5 pr-2.5 pl-0 box-border min-w-[200px] max-h-[460px] sm:max-h-[400px] Tablet_768:max-h-[340px] Tablet_600:min-w-[260px] Tablet_600:max-h-[240px] Mobile_480:min-w-[100px] Mobile_480:max-h-[240px]">
          <div className="self-stretch flex flex-row items-center justify-start py-5 px-0 gap-[8px] md:pt-[18px] md:pb-[18px] md:box-border sm:pt-4 sm:pb-4 sm:box-border Tablet_768:pt-4 Tablet_768:pb-4 Tablet_768:box-border Tablet_600:pt-2.5 Tablet_600:pb-2.5 Tablet_600:box-border Mobile_480:pt-2.5 Mobile_480:pb-2.5 Mobile_480:box-border">
            <div className="flex flex-row items-center justify-center gap-[8px]">
              <img
                className="relative w-[16.5px] h-[16.5px]"
                alt=""
                src="/top-left-vector.svg"
              />
              <div className="relative font-semibold flex items-center w-[86px] shrink-0 lg:text-smi md:text-smi sm:text-smi Tablet_768:text-xs Tablet_600:text-xs Mobile_480:text-3xs">
                {LL0().editTablePlan.topLeft()}:
              </div>
            </div>
            <Box className="flex-1">
              <Slider color="primary" orientation="horizontal"
                      {...borderSliderPropsConstructor('topLeft')}
              />
            </Box>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-5 px-0 gap-[8px] md:pt-[18px] md:pb-[18px] md:box-border sm:pt-4 sm:pb-4 sm:box-border Tablet_768:pt-4 Tablet_768:pb-4 Tablet_768:box-border Tablet_600:pt-2.5 Tablet_600:pb-2.5 Tablet_600:box-border Mobile_480:pt-2.5 Mobile_480:pb-2.5 Mobile_480:box-border">
            <div className="flex flex-row items-center justify-center gap-[8px]">
              <img
                className="relative w-[16.5px] h-[16.5px]"
                alt=""
                src="/top-right-vector.svg"
              />
              <div className="relative font-semibold flex items-center w-[86px] shrink-0 Tablet_768:text-xs Tablet_600:text-xs Mobile_480:text-3xs">
                {LL0().editTablePlan.topRight()}:
              </div>
            </div>
            <Box className="flex-1">
              <Slider color="primary"
                      {...borderSliderPropsConstructor('topRight')}
              />
            </Box>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-5 px-0 gap-[8px] md:pt-[18px] md:pb-[18px] md:box-border sm:pt-4 sm:pb-4 sm:box-border Tablet_768:pt-4 Tablet_768:pb-4 Tablet_768:box-border Tablet_600:pt-2.5 Tablet_600:pb-2.5 Tablet_600:box-border Mobile_480:pt-2.5 Mobile_480:pb-2.5 Mobile_480:box-border">
            <div className="flex flex-row items-center justify-center gap-[8px]">
              <img
                className="relative w-[16.5px] h-[16.5px]"
                alt=""
                src="/bottom-left-vector.svg"
              />
              <div className="relative font-semibold flex items-center w-[86px] shrink-0 Tablet_768:text-xs Tablet_600:text-xs Mobile_480:text-3xs">
                {LL0().editTablePlan.bottomLeft()}:
              </div>
            </div>
            <Box className="flex-1">
              <Slider color="primary"
                      {...borderSliderPropsConstructor('bottomLeft')}
                      valueLabelDisplay="auto"
              />
            </Box>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-5 px-0 gap-[8px] md:pt-[18px] md:pb-[18px] md:box-border sm:pt-4 sm:pb-4 sm:box-border Tablet_768:pt-4 Tablet_768:pb-4 Tablet_768:box-border Tablet_600:pt-2.5 Tablet_600:pb-2.5 Tablet_600:box-border Mobile_480:pt-2.5 Mobile_480:pb-2.5 Mobile_480:box-border">
            <div className="flex flex-row items-center justify-center gap-[8px]">
              <img
                className="relative w-[16.5px] h-[16.5px]"
                alt=""
                src="/bottom-right-vector.svg"
              />
              <div className="relative font-semibold flex items-center w-[86px] shrink-0 Tablet_768:text-xs Tablet_600:text-xs Mobile_480:text-3xs">
                {LL0().editTablePlan.bottomRight()}:
              </div>
            </div>
            <Box className="flex-1">
              <Slider color="primary"
                      {...borderSliderPropsConstructor('bottomRight')}
              />
            </Box>
          </div>
        </div>
        <div className="flex-1 flex flex-row items-center justify-center relative min-w-[60px] max-w-[660px] aspect-[1] min-h-[0] lg:max-w-[700px] md:max-w-[700px] sm:max-w-[700px] Tablet_768:max-w-[700px] Tablet_600:max-w-[700px] Mobile_480:max-w-[700px]">
          <div className="self-stretch flex-1 relative bg-lightsteelblue overflow-hidden min-h-[61px] z-[0]"
               //TODO: Fix this: zoom and defaultZoom
              style={objectBorderRadiusConstructor({...roomObject0(), borderRadius: cloneRadiusObject()}, 1, 1)}
          />
          <div className="absolute my-0 mx-[!important] right-[-5px] bottom-[-5.5px] rounded-981xl bg-tomato-100 w-2.5 h-2.5 overflow-hidden shrink-0 z-[1]" />
          <div className="absolute my-0 mx-[!important] bottom-[-5.5px] left-[-5px] rounded-981xl bg-tomato-100 w-2.5 h-2.5 overflow-hidden shrink-0 z-[2]" />
          <div className="absolute my-0 mx-[!important] top-[-5px] right-[-5px] rounded-981xl bg-tomato-100 w-2.5 h-2.5 overflow-hidden shrink-0 z-[3]" />
          <div className="absolute my-0 mx-[!important] top-[-5px] left-[-5px] rounded-981xl bg-tomato-100 w-2.5 h-2.5 overflow-hidden shrink-0 z-[4]" />
        </div>
      </div>
      <div className="flex flex-row items-end justify-end gap-[12px] text-center text-mini">
        <div
          className="rounded-md box-border w-[121px] h-9 flex flex-row items-center justify-center cursor-pointer border-[1px] border-solid border-royalblue-100"
          ref={makeRipple}
          onClick={onClose}
        >
          <div className="relative font-semibold">{LL0().ui.cancel()}</div>
        </div>
        <div className="rounded-md bg-royalblue-100 w-[121px] h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center text-basic-color-white"
            ref={makeRipple}
            onPointerDown={onComplete}
        >
          <div className="relative font-semibold uppercase">{LL0().ui.ok()}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomTablePopup;
