import { FunctionComponent } from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";

export type MissingPopupType = {
  className?: string;
  onClose?: () => void;
  missingText?: string;
};

const MissingPopup: FunctionComponent<
  MissingPopupType
> = ({ className = "", onClose, missingText }) => {
  return (
    <div
      className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-8 min-w-[360px] h-full max-w-full max-h-full text-center text-xl text-red-solid-red-550-cf2222 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-1">
        <b className="self-stretch relative leading-[28px]">
          {LL0().voucher.missing()} {missingText?.toLowerCase()}
        </b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-black-solid-black-880-1d1d26">
          {LL0().voucher.fillValid()} {missingText?.toLowerCase()}
        </div>
      </div>
      <div
        className="w-full flex flex-row items-center justify-center max-w-[312px] text-sm text-white-solid-white-100-ffffff">
        <div
          className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
          ref={makeRipple}
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default MissingPopup;
