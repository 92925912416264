import PairingSetup from "@sign-in/PairingSetup.tsx";
import PairingDefault from "@sign-in/PairingDefault.tsx";
import {useSignal} from "@/react/core/reactive.ts";

type PairingState = 'default' | 'setup'

const PairingWrapper = () => {
  const [pairingState, setParingState] = useSignal<PairingState>('setup')
  return (
    <>
      {
        pairingState() === 'default'
          ?
          <PairingDefault/> : <PairingSetup/>
      }
    </>
  )
}

export default PairingWrapper