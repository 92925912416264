import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { makeRipple } from '@/react/core/ripple-utils.ts';

type DashboardPopupTabButtonType = {
  settingIconUrl?: string;
  buttonText?: string;
  noticeNumber?: string;
  showChip?: boolean;
  showInfoIcon?: boolean;

  onClick?: () => void;
};

const DashboardPopupTabButton: FunctionComponent<
  DashboardPopupTabButtonType
> = ({
  settingIconUrl,
  buttonText = "General Settings",
  noticeNumber = "20",
  showChip = false,
  showInfoIcon = true,
  onClick
}) => {

  return (
    <div
      className="w-[136px] !h-[136px] rounded-md [background:linear-gradient(180deg,_#fff,_#f8f7f7)] shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] h-[136px] flex flex-col items-center justify-center py-4 px-3 box-border relative gap-[8px] text-center text-base text-materialize-grey-darken-3 font-mulish Tablet_768:w-[110px] Tablet_768:h-[110px] Tablet_768:gap-[4px] Tablet_600:w-[100px] Tablet_600:h-[100px] Tablet_600:gap-[2px] Tablet_600:p-2 Tablet_600:box-border"
      onClick={onClick}
      ref={makeRipple}
    >
      <img
        className="w-16 relative h-16 object-cover z-[0] sm:w-[60px] sm:h-[60px] Tablet_768:w-14 Tablet_768:h-14 Tablet_600:w-[50px] Tablet_600:h-[50px]"
        alt=""
        src={settingIconUrl}
      />
      <b className="text-[#424242] self-stretch relative flex items-center justify-center h-10 shrink-0 z-[1] sm:text-mini sm:h-[38px] Tablet_768:text-smi Tablet_768:h-8 Tablet_600:text-xs Tablet_600:h-[30px]">
        {buttonText}
      </b>
      {showInfoIcon && (
        <img
          className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 object-cover z-[2]"
          alt=""
          src="/icon-info-icon@2x.png"
        />
      )}
      {showChip && (
        <div className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 hidden z-[3] text-sm text-gray-solid-gray-145-f9fafb">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-red-solid-red-320-e63950 w-6 h-6" />
          <b className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">
            {noticeNumber}
          </b>
        </div>
      )}
    </div>
  );
};

export default DashboardPopupTabButton;
