import { FunctionComponent, useCallback, useState } from 'react';
import SelectPayment from "@payment/SelectPayment";
import { payments0 } from "@/data/PaymentHub.ts";
import { Payment } from "@/data/Payment.ts";
import {
  blockMutateOrderButton,
  isActivePaymentId,
  multiPayment0,
  onPaymentClick
} from "@/react/PaymentView/PaymentView.tsx";
import AutoIntergratingPopup from '@payment/AutoIntergratingPopup.tsx';
import PortalPopup from '@payment/PortalPopup.tsx';
import { clsx } from "clsx";
import _ from "lodash";
import { toast } from "react-toastify";
import { LL0 } from '@/react/core/I18nService';

const PaymentSelectItems: FunctionComponent = () => {
  const [isAutoIntergratingPopupOpen, setAutoIntergratingPopupOpen] =
    useState(false);

  const openAutoIntergratingPopup = useCallback(() => {
    setAutoIntergratingPopupOpen(true);
  }, []);

  const closeAutoIntergratingPopup = useCallback(() => {
    setAutoIntergratingPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className={clsx(
          "self-stretch gap-[8px] min-w-[240px] grid grid-cols-[repeat(2,1fr)] [grid-row-gap:8px] [grid-column-gap:8px] text-left text-lg text-white-solid-white-100-ffffff font-mulish height_414:w-auto height_414:h-auto height_414:grid height_414:grid-cols-[repeat(2,1fr)] height_414:[grid-row-gap:8px] height_414:[grid-column-gap:8px]",
          {
            'opacity-70': blockMutateOrderButton()
          }
        )}
      >        {payments0().map((payment, index) =>
        <SelectPayment
          transactionCode={payment.icon}
          paymentMethod={payment.name}
          isSelected={isActivePaymentId(payment.name!)}
          onClick={async() => {
            if (multiPayment0()) {
              if (payment.type === 'debitor' ||_.lowerCase(payment.name).includes('debitor')) {
                toast(`${LL0().payment.unsupportDebitor()}`, {type: 'info', autoClose: 500})
                return
              }
            }
            await onPaymentClick(payment)
          }}
          key={index}
        />
      )}
      </div>
      {isAutoIntergratingPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeAutoIntergratingPopup}
        >
          <AutoIntergratingPopup onClose={closeAutoIntergratingPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default PaymentSelectItems;