import debug from 'debug'
import { ws } from './init-ws'
import { secondDisplayLock, setDeviceIdentifier, wsLock } from './ws-state'
import type { WsServerMessage } from './ws-types'

const log = debug('data:ws')

export enum Identify {
  POS = 'pos',
  SECOND_DISPLAY = 'second-display',
}

export function wsRegister() {
  setDeviceIdentifier(Identify.POS)
  ws.addEventListener('message', e => {
    try {
      const msg: WsServerMessage = JSON.parse(e.data)
      if (msg.ns !== 'register' && msg.ns !== 'second-display') return
      switch (msg.type) {
        case 'registered':
          log('⚡️ Registered, releasing wsLock...')
          wsLock.release().then()
          break
        case 'ack':
          if (secondDisplayLock.acquired) {
            log('⚡️ Acknowledged, releasing wsLock...')
            secondDisplayLock.release().then()
          }
          break
        default:
          log('⚠️ Unknown message', msg)
      }
    } catch (e) {
      console.log('⚠️ Error handling message:', e)
      // Ignore json parse error
    }
  })

  // async function onOpen() {
  //   await wsOpenLock.acquireAsync()
  //   console.log('⚡️ Socket connected, registering...')
  //   const msg: WsMessage = { ns: 'register', type: Identify.POS, deviceId: getDeviceId() }
  //   ws.send(JSON.stringify(msg))
  // }
  //
  // onOpen().then()
}

