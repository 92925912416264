import { signal } from "@/react/core/reactive.ts";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import { dataLock } from "@/data/DataUtils.ts";
import axios from "axios";
import { captureException } from "@sentry/react";

export const mockTime =
  // dayjs('02.01.2021', 'DD.MM.YYYY').hour(10).toISOString()
  undefined

dayjs.extend(customParseFormat);

export const [_now, setNow] = signal(mockTime);

const nowLock = new MultiAwaitLock(false);

export const now = () => {
  if (import.meta.env.MODE === 'production') {
    if (mockTime && Math.abs(deltaMock.v || 0) > 5) {
      let unix = dayjs(new Date()).unix();
      unix -= deltaMock.v || 0;
      return dayjs.unix(unix).toDate();
    }
    if (Math.abs(deltaInternet.v || 0) > 5) {
      let unix = dayjs(new Date()).unix();
      unix -= deltaInternet.v || 0;
      return dayjs.unix(unix).toDate();
    }
    if (Math.abs(deltaRn.v || 0) > 5) {
      let unix = dayjs(new Date()).unix();
      unix -= deltaRn.v || 0;
      return dayjs.unix(unix).toDate();
    }
  }
  return new Date();
};

// @ts-ignore
window.now = now;

export function getUnixDate(count: number = 1) {
  const result = dayjs(now()).unix();
  if (count >= 2) return -1;
  if (result < 1577811600) {
    return getUnixDate(count + 1);
  }
  //fixme: get last order date to compare
  return result;
}

//write function get last order date base on PaidOrder collection
export async function getLastOrderDate() {
  await dataLock.acquireAsync();
  const PaidOrder = (await import('@/data/Order.ts')).PaidOrder;
  const order = await PaidOrder.findOne().sort({ date: 'desc' }).exec();
  return order?.date;
}

const deltaInternet = { v: undefined as undefined | number };
const deltaRn = { v: undefined as undefined | number };
const deltaMock = { v: undefined as undefined | number };

//write function to calculate deltaMock
export async function calculateDeltaMock() {
  const _mockTime = dayjs(mockTime).unix();
  const _now = dayjs(new Date()).unix();
  deltaMock.v = _now - _mockTime;
}

function timeServiceCheck() {
  //run interval 1 minute
  //check time correct
  //if time incorrect, fallback to rn time
  //if rn time incorrect, fallback to internet time
  const checkTime = async () => {
    try {
      const internetDate = await getInternetTime();
      const _now = dayjs(new Date()).unix();
      // @ts-ignore
      deltaInternet.v = Math.abs(_now - internetDate);
      if (Math.abs(deltaInternet.v) > 5) {
        captureException("Time incorrect, fallback to internet time");
      }
      // return;
    } catch (e) {
    }

    try {
      // @ts-ignore
      const rnDate = dayjs(await window.rnHost?.getDate()).unix();
      const _now = dayjs(new Date()).unix();
      deltaRn.v = _now - rnDate;
      if (Math.abs(deltaRn.v) > 5) {
        captureException("Time incorrect, fallback to rn time");
      }

      const lastOrderDate = await getLastOrderDate();
      if (lastOrderDate && rnDate && rnDate < lastOrderDate) {
        captureException("Rn time incorrect , need manual handling");
        // toast('System time is not correct , please restart the device');
      }
    } catch (e) {
    }

  };
  setTimeout(checkTime, 5000);
  setInterval(checkTime, 1000 * 60 * 5);
}

timeServiceCheck();

async function getInternetTime() {
  const response = await axios.get('https://worldtimeapi.org/api/timezone/Asia/Ho_Chi_Minh');
  const unix = response.data.unixtime;
  return unix;
}

// @ts-ignore
window.getLastOrderDate = getLastOrderDate;


/*
  Can tinh nang kieu nhu delta
  Can tinh nang check time sai fallback -> rn -> internet
 */