import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import type { EodReportItem } from "@/pos/logic/ReportType.ts";
import { invoiceGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { GroupPrinter } from "@/data/GroupPrinter.ts";
import { showMoreInfo, showProductSold } from "@/react/EndOfDayView/EndOfDayView.tsx";
import Debug from 'debug';
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { orders1 } from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import { OrderStatus } from "@/pos/OrderType.ts";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import type { ScriptedRaster } from "@/shared/printer/types"

const debug = Debug("printer:z-report")

export async function printEod(eodReport: EodReportItem | undefined, z: number, date: Dayjs): Promise<ScriptedRaster | undefined> {
  if (!eodReport) {
    debug("eodReport is not defined");
    return;
  }

  const LL = LL2()
  const {
    // reportDate,
    // firstOrderDateString,
    // lastOrderDateString,
    totalDiscount: discount,
    totalCashlessSalesExcludingTip,
    totalCashSalesExcludingCashback,
    cancelledItemReport,
    voucherReport,
    dineInReport,
    takeAwayReport,
    groupItemsByCategory,
    sumByCategory,
    // showProductSold,
    // showMoreInfo,
    // country,
    sumByPayment,
    dineInQuantity,
    takeAwayQuantity,
    totalDiscountByLabel,
    avgItemPrice,
    avgItemInOrder,
    avgOrderPrice,
    avgDineInSale,
    avgTakeAwaySale,
    reportByHours,
    totalServiceFee,
    report,
    totalTip,
    totalDebitorAmount,
    paymentReport
  } = eodReport;
  const companyName = companyInfo0()?.name;
  const companyAddress = companyInfo0()?.address;
  const companyTel = companyInfo0()?.telephone;
  const companyVatNumber = companyInfo0()?.taxNumber;
  const country = companyInfo0()?.country;

  const vTaxSum = report.vTaxSum;
  const reportDate = date.format(LL.dates.dateFormat());
  const firstOrderDateString = dayjs.unix(report.from).format(LL.dates.dateFormat());
  const lastOrderDateString = dayjs.unix(report.to).format(LL.dates.dateFormat());
  const groupPrinter = invoiceGroupPrinters0()[0] as GroupPrinter;
  const printer = new VPrinter({...invoiceGroupPrinters0()[0].printers[0]});
  const cancelledOrders = orders1().filter(order => order.status === OrderStatus.CANCELLED).length

  await printer.alignCenter();
  await printer.bold(true);
  await printer.println(companyName!);
  await printer.bold(false);
  await printer.setTextNormal();
  await printer.println(companyAddress!);
  if (companyTel) await printer.println(`${LL.printing.tel()}: ${companyTel}`);
  if (companyVatNumber) await printer.println(`${LL.printing.vatRegNo()}: ${companyVatNumber}`);

  await printer.newLine();
  await printer.setTextDoubleHeight();
  await printer.bold(true);
  await printer.println(LL.printing.zReport());

  await printer.newLine();
  await printer.bold(false);
  await printer.setTextNormal();
  await printer.alignLeft();
  await printer.println(`${LL.printing.reportDate()}: ${reportDate}`);
  await printer.println(`${LL.printing.zNumber()}: ${z}`);
  await printer.println(`${LL.printing.firstOrder()}: ${firstOrderDateString}`);
  await printer.println(`${LL.printing.lastOrder()}: ${lastOrderDateString}`);
  await printer.println(`${LL.printing.printDate()}: ${dayjs().format(LL.dates.dateFormat())}`);

  await printer.bold(true);
  await printer.drawLine();

  await printer.println(LL.printing.totalSale());
  await printer.bold(false);
  await printer.leftRight(LL.printing.total(), LL.format.currency(vTaxSum.gross || 0));
  await printer.leftRight(LL.printing.subTotal(), LL.format.currency(vTaxSum.net || 0));
  if (totalServiceFee)
    await printer.leftRight(LL.printing.serviceFee(), `${LL.format.currency(totalServiceFee || 0)}`);
  await printer.leftRight(LL.printing.tax(), LL.format.currency(vTaxSum.tax || 0));
  await printer.bold(true);
  await printer.drawLine();

  await printer.bold(false);

  const groupTypes = Object.keys(vTaxSum.vTaxSum || {});
  for (let i = 0; i < groupTypes.length; i++) {
    const groupType = groupTypes[i];
    await printer.println(`${LL.printing.tax()} (${groupType}%)`);
    await printer.leftRight(LL.printing.total(), LL.format.currency(vTaxSum.vTaxSum![groupType]['gross']));
    await printer.leftRight(LL.printing.subTotal(), LL.format.currency(vTaxSum.vTaxSum![groupType]['net']));
    await printer.leftRight(LL.printing.tax(), LL.format.currency(vTaxSum.vTaxSum![groupType]['tax']));
    await printer.newLine();
  }

  await printer.bold(false);
  await printer.leftRight(LL.printing.voucherUsed(), `${LL.format.currency(voucherReport.redeemedTotal)}`);
  await printer.leftRight(LL.printing.voucherSold(), `${LL.format.currency(voucherReport.soldTotal)}`);

  if (showMoreInfo()) {
    await printer.drawLine()
    await printer.println(LL.printing.discount())
    for (const discountLabel of Object.keys(totalDiscountByLabel)) {
      await printer.leftRight(discountLabel, `${totalDiscountByLabel[discountLabel]}`)
    }
  } else {
    await printer.leftRight(LL.printing.discount(), `${LL.format.currency(discount || 0)}`);
  }
  await printer.bold(false);

  //region payment
  await printer.drawLine();

  for (const paymentMethod of Object.keys(paymentReport)) {
    // @ts-expect-error TODO: fix typings
    await printer.leftRight(_.capitalize(LL.payment[paymentMethod]() || paymentMethod), `${LL.format.currency(paymentReport?.[paymentMethod]?.excludeCashback || 0)}`)
  }
  if (companyInfo0()?.country !== 'de') {
    await printer.leftRight(LL.report.totalCashSalesExcludingCashback(), `${LL.format.currency(totalCashSalesExcludingCashback || 0)}`);
    await printer.leftRight(LL.report.totalCashlessSalesExcludingTip(), `${LL.format.currency(totalCashlessSalesExcludingTip || 0)}`);
    await printer.leftRight('Total debitor', LL.format.currency(totalDebitorAmount || 0))
  }

  await printer.leftRight(LL.printing.totalTip(), `${LL.format.currency(totalTip || 0)}`);
  //endregion

  //cancellation:
  await printer.leftRight(`${LL.printing.cancelledTotal()}:`, `${LL.format.currency(cancelledItemReport)}`);
  await printer.leftRight(`${LL.printing.cancelledOrders()}:`, `${cancelledOrders}`);
  await printer.drawLine();

  //dineIn, takeAway:
  if (showMoreInfo()) {
    await printer.leftRight(LL.printing.dineIn(), `${dineInQuantity}`)
  } else {
    await printer.println(LL.printing.dineIn());
  }
  await printer.leftRight(LL.printing.total(), LL.format.currency(dineInReport ? dineInReport['gross'] : 0));
  await printer.leftRight(LL.printing.subTotal(), LL.format.currency(dineInReport ? dineInReport['net'] : 0));
  await printer.leftRight(LL.printing.tax(), LL.format.currency(dineInReport ? dineInReport['tax'] : 0));

  await printer.newLine();
  if (showMoreInfo()) {
    await printer.leftRight(LL.printing.takeAway(), `${takeAwayQuantity}`)
  } else {
    await printer.println(LL.printing.takeAway());
  }
  await printer.leftRight(LL.printing.total(), LL.format.currency(takeAwayReport ? takeAwayReport['gross'] : 0));
  await printer.leftRight(LL.printing.subTotal(), LL.format.currency(takeAwayReport ? takeAwayReport['net'] : 0));
  await printer.leftRight(LL.printing.tax(), LL.format.currency(takeAwayReport ? takeAwayReport['tax'] : 0));

  await printer.drawLine();
  await printer.bold(false)

  //
  if (showMoreInfo()) {
    await printer.println(LL.report.averagesBreakdown())
    await printer.leftRight(LL.report.avgItemPrice(), `${avgItemPrice}`)
    await printer.leftRight(LL.report.avgItemsPerOrder(), `${avgItemInOrder}`)
    await printer.leftRight(LL.report.avgOrderPrice(), `${avgOrderPrice}`)
    await printer.leftRight(LL.report.dineInSales(), `${_.get(dineInReport, 'gross', 0)}`)
    await printer.leftRight(LL.report.avgDineInSale(), `${avgDineInSale}`)
    await printer.leftRight(LL.report.takeAwaySales(), `${_.get(takeAwayReport, 'gross', 0)}`)
    await printer.leftRight(LL.report.avgTakeAwaySale(), `${avgTakeAwaySale}`)
    await printer.drawLine()
    const reportByHoursTableData = Object.keys(reportByHours).map(period => (
      [{ text: period }, { text: `${reportByHours[period].orderCnt}` }, { text: `${LL.format.currency(reportByHours[period].total)}` }]))
    await printer.advancedTableCustom({
      metaData: {
        colMetaData: [
          { align: 'LEFT' },
          { align: 'LEFT', priority: 'HIGH', padding: 0.2 },
          { align: 'LEFT', priority: 'HIGH' }],
        rowMetaData: []
      },
      data: reportByHoursTableData
    }, true)
    await printer.drawLine()
  }

  await printer.leftRight(LL.orderHistory.totalRefund(), LL.format.currency(eodReport.totalRefund));
  if (showProductSold()) {
    await printer.drawLine();
    for (const category of Object.keys(groupItemsByCategory)) {
      await printer.println(`${category || LL.printing.noCategory} (${LL.format.currency(sumByCategory[category] || 0)}):`)
      for (const itemName of Object.keys(groupItemsByCategory[category])) {
        const item = {
          name: itemName,
          ...groupItemsByCategory[category][itemName]
        }
        if (item.quantity && item.name) await printer.println(`  ${item.quantity} x ${item.name} (${LL.format.currency(item.vSum)})`)
      }
      await printer.newLine()
    }
  }

  const raster = await printer.getRaster()
  if (!raster) throw new Error('Failed to get raster from printer for EOD report!')
  return raster
}
