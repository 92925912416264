import { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type PopupOrderDetailsPluginType = {
  onClose?: () => void;
};

const PopupOrderDetailsPlugin: FunctionComponent<
  PopupOrderDetailsPluginType
> = ({ onClose }) => {
  return (
    <div className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-4 px-6 pb-6 box-border gap-[24px] min-w-[350px] h-full max-w-full max-h-full text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
      <div className="self-stretch relative text-[22px] font-semibold Tablet_600:text-lg">
        {LL0().onlineOrder.orderDetails()}
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-2 text-blue-solid-blue-420-2979ff">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <div className="relative w-6 h-6">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/icontakeaway-icon@2x.png"
              />
            </div>
            <div className="relative font-semibold">#1</div>
            <div className="relative text-black-solid-black-900-1e1e23">
              123 - 0+8413121
            </div>
          </div>
          <div className="relative text-[20px] font-extrabold text-black-solid-black-900-1e1e23 text-right Tablet_600:text-base">
            11:34 PM
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <b className="relative flex items-center w-5 shrink-0 Tablet_600:text-smi">
              1x
            </b>
            <div className="relative Tablet_600:text-smi">EDAMAME</div>
          </div>
          <div className="relative Tablet_600:text-smi">{LL3().format.currency(6)}</div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <b className="relative flex items-center w-5 shrink-0 Tablet_600:text-smi">
              1x
            </b>
            <div className="relative Tablet_600:text-smi">
              SPICY TUNA RICE CAKES
            </div>
          </div>
          <div className="relative Tablet_600:text-smi">{LL3().format.currency(15)}</div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <div className="relative Tablet_600:text-smi">{LL0().common.total()}</div>
            <b className="relative Tablet_600:text-smi">2</b>
            <div className="relative Tablet_600:text-smi">{LL0().onlineOrder.items()}</div>
          </div>
          <div className="relative Tablet_600:text-smi">{LL3().format.currency(6)}</div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="relative Tablet_600:text-smi">{LL0().common.tax()} (19%):</div>
          <div className="relative Tablet_600:text-smi">{LL3().format.currency(3.35)}</div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-between">
        <div className="relative font-extrabold Tablet_600:text-smi">{LL0().common.total()}</div>
        <div className="flex flex-row items-start justify-start py-0 pr-0 pl-2 text-right">
          <div className="relative font-extrabold Tablet_600:text-smi">
            {LL3().format.currency(21)}
          </div>
        </div>
      </div>
      <div className="self-stretch relative font-extrabold Tablet_600:text-smi">
        {LL0().order.payment()}
      </div>
      <div className="self-stretch gap-[8px] grid grid-cols-[repeat(3,auto)] [grid-row-gap:8px] [grid-column-gap:8px] text-center text-lg text-white-solid-white-100-ffffff Tablet_600:grid Tablet_600:grid-cols-[repeat(1,auto)] Tablet_600:[grid-row-gap:8px] Tablet_600:[grid-column-gap:8px]">
        <div className="rounded bg-red-solid-red-100-e57373 flex flex-row items-center justify-center p-4 relative Tablet_600:pt-3 Tablet_600:pb-3 Tablet_600:relative">
          <div className="relative font-semibold Tablet_600:text-sm">
            {LL0().orderHistory.cancelOrder()}
          </div>
        </div>
        <div className="rounded bg-green-solid-green-480-73c664 flex flex-row items-center justify-center p-4 relative Tablet_600:pt-3 Tablet_600:pr-4 Tablet_600:pb-3 Tablet_600:relative">
          <div className="relative font-semibold Tablet_600:text-sm">
            {LL0().pendingOrder.notifyOrderIsReady()}
          </div>
        </div>
        <div className="rounded bg-blue-solid-blue-450-1271ff flex flex-row items-center justify-center p-4 relative">
          <div className="relative font-semibold Tablet_600:text-sm">
            {LL0().onlineOrder.completeOrder()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupOrderDetailsPlugin;
