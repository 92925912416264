import { FunctionComponent, useMemo, type CSSProperties, useCallback } from 'react';

type EditRoomColorSelectType = {
  isSelected?: boolean;
  onClick?: () => void;

  /** Style props */
  editRoomColorSelectBackgroundColor?: CSSProperties["backgroundColor"];
};

const EditRoomColorSelect: FunctionComponent<EditRoomColorSelectType> = ({
  isSelected = true,
  editRoomColorSelectBackgroundColor,
  onClick
}) => {
  const editRoomColorSelectStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: editRoomColorSelectBackgroundColor,
    };
  }, [editRoomColorSelectBackgroundColor]);

  return (
    <div
      className="rounded-981xl bg-basic-color-white flex flex-row flex-wrap items-start justify-start relative"
      style={editRoomColorSelectStyle}
      onPointerDown={onClick}
    >
      <div className="relative rounded-981xl box-border w-[18.9px] h-[18.9px] z-[0] border-[1px] border-solid border-lightgray" />
      {isSelected && (
        <div className="absolute my-0 mx-[!important] top-[calc(50%_-_9.55px)] left-[calc(50%_-_9.45px)] rounded-981xl box-border w-[18.9px] h-[18.9px] z-[1] border-[1px] border-solid border-royalblue-200" />
      )}
      {isSelected && (
        <img
          className="absolute my-0 mx-[!important] top-[0px] right-[-2.2px] w-[9px] h-[9px] object-cover z-[2]"
          alt=""
          src="/iconselected-color-icon@2x.png"
        />
      )}
    </div>
  );
};

export default EditRoomColorSelect;
