import { Switch } from '@mui/material'

import { erudaEnabled, setErudaEnabled } from '@/react/Developer/Eruda.logic.ts'

const DeveloperErudaTab = () => (
  <div>
    <label className="flex items-center gap-2">
      <Switch
        checked={erudaEnabled()}
        onChange={(_, checked) => setErudaEnabled(checked)}
      />
      <p>Enable Eruda</p>
    </label>
  </div>
)

export default DeveloperErudaTab
