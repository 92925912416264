import { FunctionComponent } from "react";

const SaleReportTabContent: FunctionComponent = () => {
  return (
    <div className="self-stretch flex-1 overflow-y-auto shrink-0 flex flex-col items-end justify-start gap-[10px] text-left text-base text-black-solid-black-1000-000000 font-mulish">
      <div className="self-stretch rounded-md box-border h-[133px] overflow-hidden shrink-0 flex flex-col items-start justify-start border-[2px] border-solid border-gray-solid-gray-164-e1e1e1">
        <div className="self-stretch bg-white-solid-white-100-ffffff h-11 flex flex-row items-center justify-center">
          <b className="relative Tablet_768:text-sm">Drink</b>
        </div>
        <div className="self-stretch flex-1 relative overflow-hidden grid grid-rows-[43px_1fr] grid-cols-[1fr_1fr_1fr] [grid-row-gap:0px] [grid-column-gap:0px] text-mini text-blue-solid-blue-450-1271ff">
          <div className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-3 px-2 relative col-[1/1] row-[1/1]">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              Product
            </div>
          </div>
          <div className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-3 px-2 relative col-[2/2] row-[1/1]">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              Quantity
            </div>
          </div>
          <div className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-3 px-2 relative col-[3/3] row-[1/1]">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              Used Ingredient
            </div>
          </div>
          <div className="bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 relative col-[1/1] row-[2/2] text-sm text-grey-grey-darken-3">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              thi bo
            </div>
          </div>
          <div className="bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 gap-[2px] relative col-[2/2] row-[2/2] text-sm text-grey-grey-darken-3">
            <div className="relative font-semibold Tablet_768:text-smi">
              200
            </div>
            <div className="relative font-semibold hidden Tablet_768:text-smi">
              g
            </div>
          </div>
          <div className="bg-white-solid-white-100-ffffff flex flex-row flex-wrap items-start justify-start p-2 gap-[8px] relative col-[3/3] row-[2/2] text-sm text-black-solid-black-1000-000000">
            <div className="rounded-81xl bg-gray-solid-gray-167-e0e0e0 h-7 flex flex-row items-center justify-center p-2 box-border gap-[2px]">
              <div className="flex flex-row items-center justify-center">
                <div className="relative font-semibold Tablet_768:text-smi">
                  2
                </div>
                <div className="relative font-semibold Tablet_768:text-smi">
                  g
                </div>
              </div>
              <div className="relative font-semibold Tablet_768:text-smi">
                Thit Bo
              </div>
            </div>
            <div className="rounded-81xl bg-gray-solid-gray-167-e0e0e0 h-7 hidden flex-row items-center justify-center p-2 box-border gap-[2px]">
              <div className="relative font-semibold Tablet_768:text-smi">
                2
              </div>
              <div className="relative font-semibold Tablet_768:text-smi">
                x
              </div>
              <div className="relative font-semibold Tablet_768:text-smi">
                Samosa
              </div>
            </div>
            <div className="rounded-10xs bg-blue-solid-blue-450-1271ff hidden flex-row items-center justify-center py-1.5 px-2 text-white-solid-white-100-ffffff">
              <div className="relative font-semibold">Assign</div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch rounded-md overflow-hidden shrink-0 flex flex-col items-start justify-start border-[2px] border-solid border-gray-solid-gray-164-e1e1e1">
        <div className="self-stretch bg-white-solid-white-100-ffffff h-11 flex flex-row items-center justify-center">
          <b className="relative Tablet_768:text-sm">
            Sold Products With No Materials Assigned
          </b>
        </div>
        <div className="self-stretch relative h-[89px] overflow-hidden shrink-0 grid grid-rows-[43px_1fr] grid-cols-[1fr_1fr_1fr] [grid-row-gap:0px] [grid-column-gap:0px] text-mini text-blue-solid-blue-450-1271ff">
          <div className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-3 px-2 relative col-[1/1] row-[1/1]">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              Product
            </div>
          </div>
          <div className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-3 px-2 relative col-[2/2] row-[1/1]">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              Quantity
            </div>
          </div>
          <div className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-3 px-2 relative col-[3/3] row-[1/1]">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              Used Ingredient
            </div>
          </div>
          <div className="bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 relative col-[1/1] row-[2/2] text-sm text-grey-grey-darken-3">
            <div className="flex-1 relative font-semibold Tablet_768:text-smi">
              thi bo
            </div>
          </div>
          <div className="bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 gap-[2px] relative col-[2/2] row-[2/2] text-sm text-grey-grey-darken-3">
            <div className="relative font-semibold Tablet_768:text-smi">
              200
            </div>
            <div className="relative font-semibold Tablet_768:text-smi">g</div>
          </div>
          <div className="bg-white-solid-white-100-ffffff flex flex-row flex-wrap items-start justify-start p-2 gap-[8px] relative col-[3/3] row-[2/2] text-sm text-black-solid-black-1000-000000">
            <div className="rounded-81xl bg-gray-solid-gray-167-e0e0e0 h-7 hidden flex-row items-center justify-center p-2 box-border gap-[2px]">
              <div className="flex flex-row items-center justify-center">
                <div className="relative font-semibold Tablet_768:text-smi">
                  2
                </div>
                <div className="relative font-semibold Tablet_768:text-smi">
                  g
                </div>
              </div>
              <div className="relative font-semibold Tablet_768:text-smi">
                Thit Bo
              </div>
            </div>
            <div className="rounded-81xl bg-gray-solid-gray-167-e0e0e0 h-7 hidden flex-row items-center justify-center p-2 box-border gap-[2px]">
              <div className="relative font-semibold Tablet_768:text-smi">
                2
              </div>
              <div className="relative font-semibold Tablet_768:text-smi">
                x
              </div>
              <div className="relative font-semibold Tablet_768:text-smi">
                Samosa
              </div>
            </div>
            <div className="rounded-10xs bg-blue-solid-blue-450-1271ff flex flex-row items-center justify-center py-1.5 px-2 text-white-solid-white-100-ffffff">
              <div className="relative font-semibold">Assign</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleReportTabContent;
