import dayjs from 'dayjs'
import { clone } from 'json-fn'
import uuid from 'time-uuid'

import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import type { SignedTransactionData } from '@/srm/lib/types'

import { OperationModes } from './lib/enum'

export async function recordTransaction(t: SignedTransactionData, ref: string, user: string) {
  const { deviceId } = deviceSetting0()?.srm ?? {}
  if (!deviceId) throw new Error('Missing deviceId!')

  return await SrmTransactionLog.insert({
    _id: uuid(),
    deviceId,
    date: dayjs().unix(),
    data: clone(t),
    total: +t.mont.apresTax,
    ref,
    sent: false,
    training: t.modTrans === OperationModes.training,
    user,
  })
}
