import type { RxCollection, RxDatabase } from 'rxdb'

import { Payment, PAYMENT_COLLECTION_NAME, paymentSchema } from './Payment'
import { createCollection } from './utils/migration'

export const createPaymentCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<Payment>> => {
  return await createCollection({
    database,
    collection: Payment,
    collectionName: PAYMENT_COLLECTION_NAME,
    version: paymentSchema.version,
    schema: paymentSchema,
  })
}
