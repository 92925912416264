import type { RxCollection, RxJsonSchema } from 'rxdb'

import type { ProductLayout } from '@/data/ProductLayout'

export const ORDER_LAYOUT_COLLECTION_NAME = 'order_layouts'

export interface OrderLayout {
  _id: string
  rows: number
  columns: number
  type: string
  categories: Array<CategoryLayout>
}

export interface CategoryLayout {
  _id: string
  top: number
  left: number
  name: string
  rows: number
  columns: number
  color: string
  products?: Array<ProductLayout>
}

export const orderLayoutSchema: RxJsonSchema<OrderLayout> = {
  title: 'orderLayout schema',
  version: 1, // increase this if the schema change
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    columns: { type: 'number' },
    rows: { type: 'number' },
    type: { type: 'string' },
    categories: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          _id: { type: 'string', maxLength: 24 },
          top: { type: 'number' },
          left: { type: 'number' },
          name: { type: 'string' },
          rows: { type: 'number' },
          columns: { type: 'number' },
          color: { type: 'string' },
        },
      },
    },
  },
  indexes: [],
}

export const OrderLayout = {} as RxCollection<OrderLayout>

Object.assign(window, { OrderLayout })
