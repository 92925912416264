import type { JsonSchema, RxCollection, RxJsonSchema } from 'rxdb'

export const POS_SYNC_COLLECTION_NAME = 'pos_syncs'

export interface PosSync {
  _id: string
  id?: number
  name?: string
  server?: string
  replicationIdentifierPrefix?: number
  replicateServer?: {
    username: string
    password: string
  }
  syncProtocol: string
}

export const posSyncSchema: RxJsonSchema<PosSync> = {
  title: 'posSync schema',
  version: 8, // Increment this if schema changes
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    id: { type: 'number' },
    name: { type: 'string' },
    server: { type: 'string' },
    replicationIdentifierPrefix: { type: 'number' },
    replicateServer: <JsonSchema<PosSync['replicateServer']>>{
      type: 'object',
      properties: {
        username: { type: 'string' },
        password: { type: 'string' },
      },
    },
    syncProtocol: { type: 'string' },
  },
  indexes: [],
} as const

export const PosSync = {} as RxCollection<PosSync>

Object.assign(window, { PosSync }) // Make available in global scope for debugging
