import { RxCollection, RxDatabase, RxDocument } from "rxdb";
import uuid from "time-uuid";
import { createCollection } from "@/data/utils/migration.ts";

export const GROUP_PRINTERS_COLLECTION_NAME = 'group_printers'

export const VERSION = 13;
const groupPrinterSchema = {
	title: 'groupPrinter schema',
  version:VERSION,
	primaryKey: '_id',
	type: 'object',
	properties: {
		_id: {type: 'string', maxLength: 24},
		defaultDineInTax: {type: 'number'},
		defaultTakeAwayTax: {type: 'number'},
		name: {type: 'string'},
		type: {type: 'string'},
		hasMultiplePositions: {type: 'boolean'},
		productType: {type: 'string'},
		printers: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					_id: {type: 'string', maxLength: 24},
					name: {type: 'string'},
					hardware: {type: 'array', items: {type: 'string'}},
					printerType: {type: 'string'},
					ip: {type: 'string'},
					address: {type: 'string'},
          usb: {type: 'string'},
          serialPort: {type: 'string'},
          baudrate: {type: 'number'},
					sdk: {type: 'string'},
					escPOS: {type: 'boolean'},
					merge: {type: 'boolean'},
					fontSize: {type: 'number'},
					marginTop: {type: 'number'},
					canvasWidth: {type: 'number'},
          canvasHeight: {type: 'number'},
					numberOfCharactersPerLine: {type: 'number'},
					printTipLine: {type: 'boolean'},
					oneReceiptForOneArticle: {type: 'boolean'},
					dateTimeFormat: {type: 'boolean'},
					showProductPrice: {type: 'boolean'},
					invertColor: {type: 'boolean'},
					hideProductName: {type: 'boolean'},
					hidePrinterName: {type: 'boolean'},
					sound: {type: 'boolean'},
					tscPOS: {type: 'boolean'},
					dineInTax: {type: 'number'},
					takeAwayTax: {type: 'number'},
					includes: {type: 'array', items: {type: 'string'}},
					dropConnection: {type: 'boolean'},
					flashed: {type: 'boolean'},
					printBarcode: {type: 'boolean'},
          leftPadding: {type: 'number'},
          lineHeight: {type: 'number'},
          gap: {type: 'number'},
          spaceBetweenItems: {type: 'boolean'},
          boldItems: {type: 'boolean'},
				}
			}
		}
	},
	indexes: []
} as const;

export const PrinterDefault = {
	_id: uuid(),
	merge: true,
	canvasWidth: 560,
	numberOfCharactersPerLine: 48
}

export type ThermalPrinter = {
	_id: string,
	hardware?: string[],
	printerType?: string,
	ip?: string,
	address?: string,
  usb?: string,
  serialPort?: string
  baudrate?: number
	sdk?: "v2" | "v3",
	escPOS?: boolean,
	merge?: boolean,
	fontSize?: number,
	marginTop?: number,
	canvasWidth?: number
  canvasHeight?: number
	numberOfCharactersPerLine?: number
	printTipLine?: boolean
	oneReceiptForOneArticle?: boolean
	dateTimeFormat?: boolean
	showProductPrice?: boolean
	invertColor?: boolean
	hideProductName?: boolean
	hidePrinterName?: boolean
	hideUnitPrice?: boolean
	sound?: boolean
	tscPOS?: boolean
	name?: string
	printDelivery?: boolean
	printCustomerInfo?: boolean
	dineInTax?: number
	takeAwayTax?: number
	includes?: string[],
	dropConnection?: boolean;
	flashed?: boolean;
	printBarcode?: boolean;
  leftPadding?: number;
  lineHeight?: number;
  gap?: number;
  spaceBetweenItems?: boolean;
  boldItems?: boolean;
};

export interface GroupPrinter {
	_id?: string;
	defaultDineInTax?: number,
	defaultTakeAwayTax?: number,
	name: string,
	type: 'kitchen' | 'invoice' | 'recheck' | 'label',
	printers: Array<ThermalPrinter>;
	hasMultiplePositions?: boolean;
	productType?: string;
}

export type GroupPrinterDocument = RxDocument<GroupPrinter>;
export type GroupPrinterCollection = RxCollection<GroupPrinter>;

export const GroupPrinterCollection: GroupPrinterCollection = {} as GroupPrinterCollection;
export const GroupPrinter = GroupPrinterCollection;

// @ts-ignore
window.GroupPrinter = GroupPrinter;

export const createGroupPrinterCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: GroupPrinter,
    collectionName: GROUP_PRINTERS_COLLECTION_NAME,
    version: VERSION,
    schema: groupPrinterSchema,
  });
}
