import {type FunctionComponent, useState, useCallback, Fragment } from 'react';
import EditMenuMoreMenu from "./EditMenuMoreMenu";
import PortalDrawer from "./Popups/PortalDrawer.tsx";
import GridLayout from '@/react/core/GridLayout.tsx';
import {
  columnsCategoryArr,
  editorMode0,
  ITEM_MODE,
  orderLayout0,
  rowsCategoryArr, setShowKeyboardIngredients, showKeyboardIngredients
} from '@/react/EditMenuView/EditMenuView.tsx';
import RenderLayoutCategory from '@edit-menu/RenderLayoutCategory.tsx';
import RenderProducts from '@edit-menu/RenderProducts.tsx';
import {LL0} from "@/react/core/I18nService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import {inputController0} from "@/react/core/Input.tsx";

const EditMenuLeft: FunctionComponent = () => {
  const [isEditMenuMoreMenuOpen, setEditMenuMoreMenuOpen] = useState(false);

  const openEditMenuMoreMenu = useCallback(() => {
    setEditMenuMoreMenuOpen(true);
  }, []);

  const closeEditMenuMoreMenu = useCallback(() => {
    setEditMenuMoreMenuOpen(false);
  }, []);

  return (
    <>
    {!!orderLayout0() &&
      <div className="relative self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start pt-0.5 px-0 pb-0 relative gap-[4px] text-center text-mini text-black-solid-black-1000-000000 font-mulish">
        <div className="!h-min self-stretch h-[82px] shrink-0 flex flex-row items-start justify-center py-1 px-1.5 box-border gap-[4px] z-[0] sm:h-[76px] Tablet_768:h-16">
          <div
            className="self-stretch rounded bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(255,_255,_255,_0.4)] w-[50px] flex flex-col items-center justify-center gap-[4px] cursor-pointer"
            onClick={openEditMenuMoreMenu}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover sm:w-[22px] sm:h-[22px] Tablet_768:flex Tablet_768:w-5 Tablet_768:h-5"
              alt=""
              src="/burger-menu-icon--white@2x.png"
            />
            <b className="!hidden relative inline-block w-9 sm:text-sm Tablet_768:text-smi">
              {LL0().ui.more()}
            </b>
          </div>
          <GridLayout rows={orderLayout0()?.rows || 0} cols={orderLayout0()?.columns || 0} colGap={5} rowGap={5}
                      className="h-full flex-grow self-center"
                      style={{height: (orderLayout0()?.rows || 1) * 38 - 5 }}
          >
            {rowsCategoryArr().map((item: number, top: number) => (<Fragment key={top} >
              {columnsCategoryArr().map((_item: number, left: number) => (
                <RenderLayoutCategory top={top} left={left} key={`${top}/${left}`} />
              ))}
            </Fragment>))}
          </GridLayout>
        </div>
        <div className="flex-1 w-full h-full py-0.5 px-1.5">
          {orderLayout0()?.categories!.map((category, i) => (
            <RenderProducts category={category} index={i} key={i}/>
          ))}
        </div>
        {/*{generalSetting0()?.useVirtualKeyboard &&*/}
        {/*<div className="my-0 mx-[!important] absolute w-[calc(100%_-_3px)] right-[1.5px] bottom-[0px] left-[1.5px] flex flex-col items-center justify-start z-[2]">*/}
        {/*  <TableManualKeyboard*/}
        {/*  />*/}
        {/*</div>*/}
        {/*}*/}
      {editorMode0() === ITEM_MODE.INGREDIENT && showKeyboardIngredients() &&
          <div className='absolute inset-0 bg-[#6b6f82] bg-opacity-70 w-full h-full flex flex-col'>
              <div className='bg-[#6b6f82] bg-opacity-70 flex-1' onClick={(event)=> {
                setShowKeyboardIngredients(false)
                event.stopPropagation();
              }}></div>
              <div className={'z-50 absolute inset-x-0 bottom-0'} onClick={()=>console.log('click')}>
                <TableManualKeyboard inputController={inputController0}/>
              </div>
          </div>
      }
      </div>

    }

      {isEditMenuMoreMenuOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Left"
          onOutsideClick={closeEditMenuMoreMenu}
        >
          <EditMenuMoreMenu onClose={closeEditMenuMoreMenu} />
        </PortalDrawer>
      )}
    </>
  );
};

export default EditMenuLeft;
