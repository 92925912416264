import type { FunctionComponent } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";

import { addCustomDish, setInputControllers } from '@/react/OrderView/OrderView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { useComputed, useSignal } from "@/react/core/reactive.ts";
import { taxCategories0 } from "@/data/TaxCategoryHub.ts";
import type { SubTax } from "@/pos/OrderType.ts";
import { isQuebecSrmEnabled, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { DialogOrderCustomDish } from "@order-view/DialogOrderCustomDish.tsx";
import _ from "lodash";
import { toast } from "react-toastify";

type OrderSreenFoodPopupType = {
  onClose?: () => void;
  defaultName?: string;
  type?: string;
};

const OrderScreenFoodPopup: FunctionComponent<OrderSreenFoodPopupType> = (
  {
    onClose,
    defaultName = "Food",
    type
  }) => {

  const [food, setFood] = useSignal<{
    taxId?: string,
    name?: string,
    price: number,
    tax?: number,
    taxComponents: SubTax[] | undefined
  }>({
    taxId: "",
    name: "",
    price: 0,
    tax: taxCategories0()[0]?.value,
    taxComponents: taxCategories0()[0]?.components
  })


  const handleClickSave = async () => {
    if (!posSetting0()?.divArticleSettings?.drink?.groupPrinter || !posSetting0()?.divArticleSettings?.food?.groupPrinter) {
      onClose?.();
      await DialogOrderCustomDish(() => {
        if (food().name && food().tax) {
          addCustomDish(food().name, food().price, defaultName, food().tax, '', food()?.taxComponents)
          // onClose?.()
        }
      })
      return
    } else if (food().name && food().tax) {
      if (isQuebecSrmEnabled()) {
        if (food().name.length >= 3) {
          await addCustomDish(food().name, food().price, defaultName, food().tax, '', food()?.taxComponents)
          onClose?.()
        } else {
          toast.error(`${LL0().srm.errors.itemNameNeedGreaterThan3Characters()}`, {autoClose: 3000})
        }
      } else {
        await addCustomDish(food().name, food().price, defaultName, food().tax, '', food()?.taxComponents)
        onClose?.()
      }
    }
  }

  const filteredTaxes = useComputed(() => taxCategories0().filter(tax => tax.type.startsWith(type) || (!tax.type.startsWith("food") && !tax.type.startsWith("drink"))))

  return (
    <div
      className="overflow-y-scroll no-scrollbar Mobile_480:min-w-0 min-w-[438px] w-full h-full relative rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start pt-8 px-0 pb-0 box-border gap-[8px] max-w-full max-h-full text-left text-sm text-materialize-grey-darken-3 font-mulish">
      <div className="self-stretch flex-1 flex flex-col items-center justify-start pt-2 px-4 pb-4 gap-[24px] z-[0] Mobile_480:px-2">
        <div className="self-stretch flex flex-col items-start justify-center gap-[12px]">
          <div className="w-full flex-1 flex flex-col items-start justify-start gap-[8px]">
            <b className="relative">{LL0().article.name()}</b>
            <Input
              className={'w-full'}
              refInputController={i => {
                i.setFocus(true)
                return setInputControllers?.([i]);
              }}
              value={food().name}
              onChange={value => setFood(prev => ({ ...prev, name: value }))}
            />
          </div>
          <div className="w-full flex flex-1 gap-[12px] Mobile_480:flex-col">
            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
              <b className="relative">{LL0().article.price()}</b>
              <Input
                className={'w-full'}
                refInputController={i => {
                  return setInputControllers?.([i]);
                }}
                value={food().price.toString()}
                onChange={value => {
                  if (!isNaN(Number(value))) {
                    setFood(prev => ({ ...prev, price: Number(value) }));
                  }
                }}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
              <b className="relative">{LL0().article.tax()}:</b>
              <FormControl className="self-stretch" variant="outlined">
                <InputLabel color="primary" />
                <Select color="primary" size="small"
                        value={food().taxId}
                        onChange={event => {
                          const selectedTax = taxCategories0().find(tax => tax._id === event.target.value);
                          setFood(prev => ({
                            ...prev,
                            taxId: selectedTax?._id,
                            tax: selectedTax?.value,
                            taxComponents: selectedTax?.components
                          }))
                        }}
                >
                  {filteredTaxes().map((tax, index) =>
                    <MenuItem value={tax._id} key={index}>{LL0().format.taxName(tax.name || "")} {tax.value || _.sumBy(tax.components, 'value')}%</MenuItem>)}
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          </div>
        </div>
        <TableManualKeyboard
          inputController={inputController0}
          onEnter={handleClickSave}
        />
      </div>
      <img
        className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 object-cover cursor-pointer z-[1]"
        alt=""
        src="/close-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default OrderScreenFoodPopup;
