import axios from 'axios'
import dayjs from 'dayjs'
import debug from 'debug'
import { deepSignal } from 'deepsignal/react'
import { toast } from 'react-toastify'

import type { DeviceSrmSetting } from '@/data/DeviceSetting'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { LL0 } from '@/react/core/I18nService'
import { computed, signal } from '@/react/core/reactive'
import msgBox, { Buttons, Icons, Results } from '@/react/SystemService/msgBox'
import { bound, bypassConfirmation, ensureExclusive, handleError, progressToast, requireConfirmation } from '@/shared/decorators'
import { rnHost } from '@/shared/webview/rnwebview'

import { WebSrmCertificateError, WebSrmInvalidResponseError } from './errors'
import { getSdk } from './getSdk'
import { getSignableSdk } from './getSignableSdk'
import { CONSTANT_VALUES } from './lib/constants'
import { handleSrmError } from './lib/decorators'
import type { CertificateRequestBody, CertificateResponse, RequestHeaders, SrsCsrContent } from './lib/types'
import { generateCsrJsrsasign, genKeyPair, getCertificateInfo, getKeyFingerprint } from './lib/utils.cert'
import { isCertificateResponse } from './response-validators'

const log = debug('data:srm-document')

// #region States

const srmSetting = computed(() => posSetting0()?.srm)
const srmDeviceSetting = computed(() => deviceSetting0()?.srm)
const certSerialNumber = computed(() => getCertificateInfo(srmDeviceSetting()?.cert)?.serialNumber)
const privKeyThumb = computed(() =>
  getKeyFingerprint(srmDeviceSetting()?.publicKey)
    ?.split(/(?<=^.{6}).+(?=.{6}$)/) // Only show 6 chars at the begin and the end
    .join('...')
)
const isSrmCertificateValid = computed(() => {
  const result = srmCertLogic.checkCertificateValidity(srmDeviceSetting()?.cert)
  return result.status === 'ok'
})

const csrContent = deepSignal<Pick<SrsCsrContent, 'SN' | 'ST' | 'C' | 'L'>>({
  SN: 'Certificate01',
  ST: 'QC',
  C: 'CA',
  L: '-05:00',
})
const [isSubmitting, setIsSubmitting] = signal(false)
const LAST_CERT_RESPONSE_KEY = 'LAST_CERT_RESPONSE_KEY'

export { csrContent, certSerialNumber, srmSetting, srmDeviceSetting, privKeyThumb, isSubmitting, isSrmCertificateValid }

// #endregion

// #region Logic

interface ValidityCheckResult {
  status: 'missing' | 'expireSoon' | 'expired' | 'invalid' | 'ok'
  days?: number
}
enum PromptResult {
  noActionNeeded = 'noActionNeeded',
  enrolled = 'enrolled',
  renewed = 'renewed',
  refused = 'refused',
}

class SrmCertLogic {
  @ensureExclusive()
  private async _sendRequest(body: CertificateRequestBody, firstTime = false) {
    const sdk = firstTime ? getSdk() : getSignableSdk()
    const headers = getCertificateRequestHeaders()
    const res = await sdk.certificate(headers, body)
    log('» Certificate response.', res)
    if (!isCertificateResponse(res)) throw new WebSrmInvalidResponseError(res)
    // Save last response to localStorage
    localStorage.setItem(LAST_CERT_RESPONSE_KEY, JSON.stringify(res))
    if (res.retourCertif.listErr?.length) throw new WebSrmCertificateError(res)
    if (res.retourCertif.casEssai && res.retourCertif.casEssai !== CONSTANT_VALUES.CASESSAI_EMPTY) {
      log('🧪 Next testcase is', res.retourCertif.casEssai)
      toast.info(`🧪 Next testcase is ${res.retourCertif.casEssai}`)
    }
    return res
  }

  /**
   * Enroll a new certificate on device
   */
  @progressToast(() => LL0().srm.messages.requestingCertificate(), undefined, setIsSubmitting)
  @handleError()
  @handleSrmError()
  @bound()
  async createCert() {
    const body: CertificateRequestBody = { reqCertif: { modif: 'AJO', csr: await getCsr(csrContent) } }
    const res = await this._sendRequest(body, true)
    await saveCert(res)
    log('✅ Enrollment succeeded.', res.retourCertif)
  }

  /**
   * Replace saved certificate.
   * If no saved certificate are founded, an error message will be shown to the user.
   *
   * Require confirmation.
   */
  @requireConfirmation(() => ({
    title: LL0().srm.caption.replaceCertificate(),
    content: LL0().srm.messages.replaceCertificateConfirm(),
  }))
  @progressToast(() => LL0().srm.messages.replacingCertificate(), undefined, setIsSubmitting)
  @handleError()
  @handleSrmError()
  @bound()
  async replaceCert() {
    const cert = getSavedCertificate()
    const body: CertificateRequestBody = { reqCertif: { modif: 'REM', csr: await getCsr(csrContent), noSerie: cert.serialNumber } }
    const res = await this._sendRequest(body)
    await saveCert(res)
    log('✅ Replace cert succeeded.', res.retourCertif)
  }

  /**
   * Delete saved certificate.
   * If none are founded, an error message will be shown to the user.
   *
   * Require confirmation.
   */
  @requireConfirmation(() => ({
    title: LL0().srm.caption.deleteCertificate(),
    content: LL0().srm.messages.deleteCertificateConfirm(),
  }))
  @progressToast(() => LL0().srm.messages.deletingCertificate(), undefined, setIsSubmitting)
  @handleError()
  @handleSrmError()
  @bound()
  async deleteCert() {
    const cert = getSavedCertificate()
    const body: CertificateRequestBody = { reqCertif: { modif: 'SUP', noSerie: cert.serialNumber } }
    const res = await this._sendRequest(body)
    await deleteSavedCert()
    log('ℹ️ Certificate deleted', res.retourCertif)
  }

  /**
   * Verify the certificate's validity.
   *
   * If the certificate is missing, expired, or invalid, and `promptForAction` is `true`, the user will be prompted to take action.
   */
  @bound()
  checkCertificateValidity(cert: string | undefined): ValidityCheckResult {
    if (!cert) return { status: 'missing' }
    const info = getCertificateInfo(cert)
    if (!info) return { status: 'invalid' }
    const { validInDays, expireInDays, warningInDays } = parseCertValidityDate(info)

    if (validInDays > 0) return { status: 'invalid' }
    if (expireInDays < 0) return { status: 'expired', days: Math.abs(expireInDays) }
    if (warningInDays <= 0) return { status: 'expireSoon', days: expireInDays }
    return { status: 'ok' }
  }

  /**
   * Prompt the user to take action if the certificate is missing, expired, or invalid
   */
  @bound()
  async promptUserToUpdateCert(validity: ValidityCheckResult): Promise<PromptResult> {
    switch (validity.status) {
      case 'missing': {
        const prompt = {
          cap: LL0().srm.messages.doYouWantEnrollNewCert(),
          msg: LL0().srm.messages.enrollNewCertExplain(),
          btn: Buttons.YesNo,
        }
        const answer = await msgBox.show(prompt.cap, prompt.msg, prompt.btn, Icons.Warning)
        if (answer !== Results.yes) return PromptResult.refused
        await this.createCert()
        return PromptResult.enrolled
      }
      case 'expireSoon': {
        const prompt = {
          cap: LL0().srm.caption.renewExpiringCertificate(),
          msg: LL0().srm.messages.renewExpiringCertificateConfirm({ days: validity.days ?? 0 }),
          btn: Buttons.YesNo,
        }
        const answer = await msgBox.show(prompt.cap, prompt.msg, prompt.btn, Icons.Warning)
        if (answer !== Results.yes) return PromptResult.refused
        bypassConfirmation('once')
        await this.replaceCert()
        return PromptResult.renewed
      }
      case 'expired': {
        const prompt = {
          cap: LL0().srm.caption.renewExpiringCertificate(),
          msg: LL0().srm.messages.renewExpiredCertificateConfirm({ days: validity.days ?? 0 }),
          btn: Buttons.YesNo,
        }
        const answer = await msgBox.show(prompt.cap, prompt.msg, prompt.btn, Icons.Error)
        if (answer !== Results.yes) return PromptResult.refused
        bypassConfirmation('once')
        await this.replaceCert()
        return PromptResult.renewed
      }
      case 'invalid': {
        const prompt = {
          cap: LL0().srm.caption.invalidCertificate(),
          msg: LL0().srm.messages.recreateInvalidCertificateConfirm(),
          btn: Buttons.OKCancel,
        }
        const answer = await msgBox.show(prompt.cap, prompt.msg, prompt.btn, Icons.Error)
        if (answer !== Results.ok) return PromptResult.refused
        bypassConfirmation('once')
        await this.deleteCert()
        await this.createCert()
        return PromptResult.renewed
      }
      default:
        return PromptResult.noActionNeeded
    }
  }
}

export const srmCertLogic = new SrmCertLogic()

// #endregion

// #region Utils
function getCertificateRequestHeaders() {
  const { productId, productVersionId, partnerId, certificateCode, testcaseNumber, version, previousVersion, env } = posSetting0()?.srm ?? {}
  const { deviceId } = deviceSetting0()?.srm ?? {}
  const genericMsg = ' ' + LL0().srm.messages.checkConfig()

  if (!productId) throw new Error(LL0().srm.errors.missingProductCode() + genericMsg)
  if (!productVersionId) throw new Error(LL0().srm.errors.missingProductVersionCode() + genericMsg)
  if (!partnerId) throw new Error(LL0().srm.errors.missingPartnerCode() + genericMsg)
  if (!version) throw new Error(LL0().srm.errors.missingProductVersion() + genericMsg)
  if (!previousVersion) throw new Error(LL0().srm.errors.missingPreviousProductVersion() + genericMsg)

  const headers: RequestHeaders = {
    ENVIRN: env ?? 'DEV',
    CASESSAI: testcaseNumber || CONSTANT_VALUES.CASESSAI_EMPTY,
    APPRLINIT: 'SEV',
    IDAPPRL: deviceId || CONSTANT_VALUES.IDAPPRL_EMPTY,
    IDSEV: productId,
    IDVERSI: productVersionId,
    CODCERTIF: certificateCode || CONSTANT_VALUES.CODCERTIF_EMPTY,
    IDPARTN: partnerId,
    VERSI: version,
    VERSIPARN: previousVersion,
  }
  return headers
}

function getSavedCertificate() {
  const { cert } = deviceSetting0()?.srm ?? {}
  if (!cert) throw new Error(LL0().srm.errors.noSavedCertificate())

  const info = getCertificateInfo(cert)
  if (!info) throw new Error(LL0().srm.errors.failToParseSavedCertificate())

  log('ℹ️ Saved certificate info', info)
  return info
}

async function deleteSavedCert() {
  // Removing all srm data...
  const result = await deviceSetting0()?.doc?.incrementalUpdate({
    $unset: {
      'srm.cert': '',
      'srm.certPSI': '',
      // We keep device ID and keys when deleting cert
      // 'srm.deviceId': '',
      // 'srm.publicKey': '',
      // 'srm.privateKey': '',
    },
  })
  if (!result) throw new Error(LL0().srm.errors.failToUpdateConfig())

  // Delete from device, only if ws is connected
  log('ℹ️ Deleting cert...')
  const deleteResult = await rnHost.deleteCert()
  log('✅ Deleted', deleteResult)
  await rnHost.loadCert() // Reload cert
}

async function getCsr(csrContent: Partial<SrsCsrContent>) {
  const { identificationNumber, billingNumber, authCode, qstNumber } = posSetting0()?.srm ?? {}

  if (!identificationNumber) throw new Error(LL0().srm.errors.missingIdentificationNumber())
  if (!billingNumber) throw new Error(LL0().srm.errors.missingBillingNumber())
  if (!authCode) throw new Error(LL0().srm.errors.missingAuthorizationCode())
  if (!qstNumber) throw new Error(LL0().srm.errors.missingQstNumber())

  const [privateKey, publicKey] = await getOrGenerateAndSaveKeyPair()
  const content: SrsCsrContent = {
    SN: 'Certificate 01',
    CN: identificationNumber,
    GN: billingNumber,
    O: 'RBC-' + authCode,
    OU: qstNumber,
    // hard coded values
    L: '-05:00',
    ST: 'QC',
    C: 'CA',
    ...csrContent,
  }
  try {
    return generateCsrJsrsasign(content, privateKey, publicKey)
  } catch {
    throw new Error(LL0().srm.errors.failToGenerateCsr())
  }
}

const SRM_P12_PWD = 'hxyW9M2oC517'
const SRM_CONVERT_PEM_ENDPOINT = 'https://ytcyd2rlslilmg246pcwtereqm0vtwee.lambda-url.ca-central-1.on.aws/'
async function saveCert(res: CertificateResponse) {
  const ds = deviceSetting0()
  if (!ds) throw new Error('No Device setting found')
  const result = await deviceSetting0()?.doc?.incrementalUpdate({
    $set: {
      'srm.cert': res.retourCertif.certif,
      'srm.certPSI': res.retourCertif.certifPSI,
      ...(res.retourCertif.idApprl ? { 'srm.deviceId': res.retourCertif.idApprl } : {}),
    },
  })
  if (!result) throw new Error(LL0().srm.errors.failToUpdateConfig())

  // Save to device if ws is connected
  const [privateKey] = await getOrGenerateAndSaveKeyPair(true)
  console.groupCollapsed('ℹ️ Converting PEM to P12')
  log('ℹ️ cert', res.retourCertif.certif)
  log('ℹ️ key', privateKey)
  const {
    data: { base64p12 },
  } = await axios.post(SRM_CONVERT_PEM_ENDPOINT, {
    cert_content: res.retourCertif.certif,
    private_key_content: privateKey,
    password: SRM_P12_PWD,
  })
  log('✅ Converted', base64p12)
  console.groupEnd()

  await rnHost.deleteCert() // Delete cert, if any
  const installResult = await rnHost.installCert(base64p12, SRM_P12_PWD)
  log('✅ Saved cert to device', installResult)
  await rnHost.loadCert() // Reload cert
}

async function getOrGenerateAndSaveKeyPair(throwIfMissing = false) {
  const ds = deviceSetting0()
  if (!ds) throw new Error('Device setting not initialized!')
  if (!ds.srm) ds.srm = deepSignal<DeviceSrmSetting>({})

  let { privateKey, publicKey } = ds.srm
  if (!privateKey || !publicKey) {
    if (throwIfMissing) throw new Error(LL0().srm.errors.missingCryptoKeys())

    log('ℹ️ Generating new private key pair...')
    const { privateKey: nextPrivateKey, publicKey: nextPublicKey } = genKeyPair()

    ds.srm.privateKey = nextPrivateKey
    ds.srm.publicKey = nextPublicKey
    const result = await ds.doc?.incrementalUpdate({
      $set: {
        'srm.privateKey': nextPrivateKey,
        'srm.publicKey': nextPublicKey,
      },
    })
    if (!result) throw new Error(LL0().srm.errors.failToUpdateConfig())

    privateKey = nextPrivateKey
    publicKey = nextPublicKey
  }
  return [privateKey, publicKey] as const
}

function parseCertValidityDate(certInfo: { notbefore: string; notafter: string }) {
  const validDate = dayjs(certInfo.notbefore)
  const expireDate = dayjs(certInfo.notafter)
  const validInDays = validDate.diff(dayjs(), 'day')
  const expireInDays = expireDate.diff(dayjs(), 'day')
  const warningDate = validDate.add((expireInDays - validInDays) / 2, 'day')
  const warningInDays = warningDate.diff(dayjs(), 'day')

  // console.group('🔐 Certificate')
  // log('🕐 Valid at', validDate.toString(), '(in', validInDays, 'days)')
  // log('🕐 Expire at', expireDate.toString(), '(in', expireInDays, 'days)')
  // log('🕐 Warning date is', warningDate.toString(), '(in', warningInDays, 'days)')
  // console.groupEnd()

  return { validInDays, expireInDays, warningInDays }
}
// #endregion

// Expose to window for debugging
Object.assign(window, { srmCertLogic })
