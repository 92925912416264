import { dineInTaxCategories0, takeAwayTaxCategories0 } from "@/data/TaxCategoryHub.ts";
import type { GroupPrinter } from "@/data/GroupPrinter.ts";

//region calculate tax category and tax value for tax.type = 2
export function calculateTaxValues(groupPrinter: GroupPrinter) {
  const productType = groupPrinter?.productType || 'other';

  return {
    taxCategory: dineInTaxCategories0()?.find(tax => tax.type === `${productType}:dineIn`)?.name,
    taxCategory2: takeAwayTaxCategories0()?.find(tax => tax.type === `${productType}:takeAway`)?.name,
    tax: dineInTaxCategories0()?.find(tax => tax.type === `${productType}:dineIn`)?.value,
    tax2: takeAwayTaxCategories0()?.find(tax => tax.type === `${productType}:takeAway`)?.value,
  };
}
//endregion
