import { taxCategories0 } from "@/data/TaxCategoryHub.ts";
import { products0 } from "@/data/ProductHub.ts";
import { Product } from "@/data/Product.ts";
import { convertDocuments, deleteMany } from "@/data/data-utils.ts";
import { captureException, withScope } from "@sentry/react";
import { ProductLayout } from "@/data/ProductLayout.ts";
import { Room } from "@/data/Room.ts";
import { TaxCategory } from "@/data/TaxCategory.ts";
import { GroupPrinter } from "@/data/GroupPrinter.ts";

export function taxFixFactory() {
	return {
		fixProductTax,
    removeDemoDataProduct,
    removeDemoDataProductLayout,
    removeDemoDataRoom,
    removeTaxQuebec,
    changeTaxPrinter,
    fixProductTaxGerman
	}

	async function fixProductTax() {
		const [taxCategory] = taxCategories0()
		const _products = await Product.find().exec();
		const products = convertDocuments(_products, true);
		for (const product of products) {
			product.tax = taxCategory.value;
			product.tax2 = taxCategory.value;
			product.taxCategory = taxCategory.name;
			product.taxCategory2 = taxCategory.name;
			product.taxComponents = undefined;
			product.taxComponents2 = undefined;
		}
		console.log('finish', products.filter(p => p.taxComponents).length);
	}

  async function fixProductTaxGerman() {
    const taxCategories = taxCategories0()
    const _products = await Product.find().exec();
    const products = convertDocuments(_products, true);
    for (const product of products) {
      // const taxCategory = taxCategories.find(t => t.name === product.taxCategory);
      // const taxCategory2 = taxCategories.find(t => t.name === product.taxCategory2);
      // if (taxCategory && taxCategory2) {
      //   product.tax = taxCategory?.value;
      //   product.tax2 = taxCategory2?.value;
      // }
      if (product.tax === 7) {
        console.log('product.tax === 7', product);
      }
      // product.tax = taxCategory.value;
      // product.tax2 = taxCategory.value;
      // product.taxCategory = taxCategory.name;
      // product.taxCategory2 = taxCategory.name;
      // product.taxComponents = undefined;
      // product.taxComponents2 = undefined;
    }
    console.log('finish', products.filter(p => p.taxComponents).length);
  }

	function queryTax() {

	}

  async function removeDemoDataProduct() {
    const _products = await Product.find().where('_id').regex({ $regex: '^.{21,}$', $options: '' }).exec()
    // const products = convertDocuments(_products, true);
    for (const product of _products) {
      const productLayout = await ProductLayout.findOne({selector: {product: product._id} }).exec();
      if (productLayout) {
        await productLayout.remove();
      }
      await product.remove();
    }
  }

  async function removeDemoDataProductLayout() {
    const productLayouts = await ProductLayout.find().where('_id').regex({ $regex: '^.{21,}$', $options: '' }).exec()
    // const products = convertDocuments(_products, true);
    for (const productLayout of productLayouts) {
      await productLayout.remove();
    }
  }

  async function removeDemoDataRoom() {
    const rooms = await Room.find().where('_id').regex({ $regex: '^.{21,}$', $options: '' }).exec()
    for (const room of rooms) {
      await room.remove();
    }
  }
  async function removeTaxQuebec() {
    const taxCate =  await TaxCategory.find({selector: {name: 'Quebec'}}).exec();
    for (const tax of taxCate) {
      await tax.remove();
    }
  }
  async function changeTaxPrinter() {
    const _printers = await GroupPrinter.find().exec();
    const printers = convertDocuments(_printers, true);
    for (const printer of printers) {
      if (printer.name === 'Kitchen') {
        printer.defaultDineInTax = 13;
        printer.defaultTakeAwayTax = 13
      }
    }
  }
}

async function fix2() {
	const api = taxFixFactory();
	// await api.fixProductTaxGerman();
  await api.removeDemoDataProduct();
  await api.removeDemoDataProductLayout();
  await api.removeDemoDataRoom();
  // await api.removeTaxQuebec();
  // await api.changeTaxPrinter()
}

// @ts-ignore
window.fix2 = fix2;

// @ts-ignore
window.testPrint = async () => {
	withScope(scope => {
		scope.setTag('type', 'print');
		captureException(new Error('test print'));
	});
	captureException(new Error('test print 2'), { tags: { type: 'print2' } });
}