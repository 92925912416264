import { type FunctionComponent, useState, useCallback } from "react";
import DeleteConfirmationPopup from "../../DeleteConfirmationPopup";
import PortalPopup from "../PortalPopup";
import EditItemForm from "./EditItemForm";
import EditStockForm from "./EditStockForm";
import StockHistoryForm from "./StockHistoryForm";
import { signal, useSignal } from '@/react/core/reactive.ts'
import {
  createNewInventory,
  deleteCurrentInventory,
  inventory0,
  updateInventoryStock
} from '@/react/InventoryView/InventoryView.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import _ from 'lodash'
import { convertDocument } from '@/data/data-utils.ts'
import {LL0} from "@/react/core/I18nService.tsx";
import { loginUser } from "@/data/UserSignal.ts";

export enum EditInventoryMode {
  EDIT,
  ADD
}

export const [editInventoryMode, setEditInventoryMode] = signal<EditInventoryMode>(EditInventoryMode.ADD)

type InventoryAddEditItemPopuType = {
  onClose?: () => void;
};

enum EditInventoryTab {
  EDIT_ITEM,
  EDIT_STOCK,
  STOCK_HISTORY
}

const InventoryAddEditItemPopu: FunctionComponent<
  InventoryAddEditItemPopuType
> = ({ onClose }) => {

  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);
  const [editInventoryTab, setEditInventoryTab] = useSignal<EditInventoryTab>(EditInventoryTab.EDIT_ITEM);
  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);
  const editInventoryTabs = [
    {
      tab: EditInventoryTab.EDIT_ITEM,
      label: `${LL0().inventory.editItem()}`
    },
    {
      tab: EditInventoryTab.EDIT_STOCK,
      label: `${LL0().inventory.newStock()}`
    },
    {
      tab: EditInventoryTab.STOCK_HISTORY,
      label: `${LL0().inventory.stockHistory()}`
    },
  ]

  const handleEditItemSave = async () => {
    onClose?.();
    const _inventory = inventory0();
    if (!_inventory || editInventoryMode() === EditInventoryMode.EDIT) return;
    const newInventory = await createNewInventory(_inventory);
    updateInventoryStock(convertDocument(newInventory, true, ["product", "lastUpdated"]),
      0, `${LL0().inventory.initStockBy()} ${loginUser()?.name}`)
  }

  const handleEditStockSave = async (amount: number, reason: string) => {
    onClose?.();
    const _inventory = inventory0()
    if (!_inventory) return;
    if (editInventoryMode() === EditInventoryMode.ADD) {
      const newInventory = await createNewInventory(_inventory);
      updateInventoryStock(convertDocument(newInventory, true, ["product", "lastUpdated"]),
        amount, `${LL0().inventory.initStockBy()} ${loginUser()?.name}`)
      return;
    }
    updateInventoryStock(_inventory, amount, reason)
  }

  return (
    <>
      <div className="relative rounded-md bg-white-solid-white-100-ffffff w-full flex flex-col items-center justify-start min-w-[540px] h-full gap-[0px] text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start hover:rounded-md hover:min-w-[540]">
        <div className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-between py-3 px-3.5 border-b-[1px] border-solid border-gray-solid-gray-150-eeeeee">
          <b className="relative">{LL0().inventory.itemName()}</b>
          <div className="flex flex-row items-center justify-end gap-[15px] text-center text-sm text-black-solid-black-900-1e1e23">
            <div
              className="rounded-[1000px] bg-red-solid-red-70-ff7777 w-[37px] h-[37px] flex flex-row items-center justify-center cursor-pointer"
              onClick={openDeleteConfirmationPopup}
              ref={makeRipple}
            >
              <img
                className="relative w-[30px] h-[30px] object-cover"
                alt=""
                src="/iconbin-icon--white@2x.png"
              />
            </div>
            {/*<div className="rounded-xl bg-orange-solid-orange-450-ffaa06 shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] w-[72px] h-[37px] flex flex-row items-center justify-center py-0 px-2.5 box-border">*/}
            {/*  <b className="relative sm:text-smi Tablet_768:text-xs">Save</b>*/}
            {/*</div>*/}
            <img
              className="relative w-6 h-6 object-cover cursor-pointer"
              alt=""
              src="/iconclose-popup-icon@2x.png"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="self-stretch h-[418px] overflow-y-auto  flex flex-row items-start justify-start py-0 pr-2 pl-0 box-border gap-[8px] text-smi text-black-solid-black-1000-000000">
          <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 box-border w-[108px] min-w-[108px] flex flex-col items-start justify-start pt-3 px-0 pb-0 gap-[4px] border-r-[1px] border-solid border-gray-solid-gray-125-f1f1f1 border-l-[1px]">
            {editInventoryTabs.map(({tab, label}) => (
              <button
                className='disabled:text-[rgba(0,0,0,0.38)] relative self-stretch flex flex-col items-start justify-center'
                ref={makeRipple}
                onClick={() => setEditInventoryTab(tab)}
                key={tab}
                disabled={tab === EditInventoryTab.STOCK_HISTORY && editInventoryMode() === EditInventoryMode.ADD}
              >
                <div className='self-stretch flex flex-row items-center justify-start py-[15px] px-2'>
                  {editInventoryTab() === tab &&
                      <div
                          className='absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-blue-solid-blue-70-cfddfd box-border z-[0] border-l-[1.5px] border-solid border-blue-solid-blue-470-356ff6' />
                  }
                  <b className='relative z-[1]'>{label}</b>
                </div>
              </button>
            ))}
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
            <EditItemForm showEditItemForm={editInventoryTab() === EditInventoryTab.EDIT_ITEM} onClickSave={handleEditItemSave} />
            <EditStockForm showEditStockForm={editInventoryTab() === EditInventoryTab.EDIT_STOCK} onClickSave={handleEditStockSave}/>
            <StockHistoryForm showStockHistoryForm={editInventoryTab() === EditInventoryTab.STOCK_HISTORY} />
          </div>
        </div>
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={()=>setDeleteConfirmationPopupOpen(false)}
        >
          <DeleteConfirmationPopup onClose={()=>setDeleteConfirmationPopupOpen(false)}
                                   onClick={deleteCurrentInventory}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default InventoryAddEditItemPopu;
