import type {FormattersInitializer} from 'typesafe-i18n'
import type {Locales, Formatters} from './i18n-types'
import {number} from "typesafe-i18n/formatters";

export const initFormatters: FormattersInitializer<Locales, Formatters> = (locale: Locales) => {
  const currencyMap = {
    de: 'EUR',
    en: 'USD',
    vn: 'VND',
    fr: 'EUR',
    cn: 'CNY',
  }
  const taxNameMap = {
    en: {
      'food:dineIn': 'Dine In',
      'food:takeAway': 'Take Away',
      'drink:dineIn': 'Dine In',
      'drink:takeAway': 'Take Away',
    },
    de: {
      'food:dineIn': 'Im Haus',
      'food:takeAway': 'Mitnehmen',
      'drink:dineIn': 'Im Haus',
      'drink:takeAway': 'Mitnehmen',
    },
    vn: {
      'food:dineIn': 'Ăn tại chỗ',
      'food:takeAway': 'Mang đi',
      'drink:dineIn': 'Ăn tại chỗ',
      'drink:takeAway': 'Mang đi',
    },
  } as { [k: string]: { [k: string]: string } }
  const formatters: Formatters = {
    currency: v => {
      const format =  number(locale, { style: 'currency', currency: currencyMap[locale] });
      return format(v).replaceAll(String.fromCharCode(160)," ");
    },
    taxName: (tax: string) => {
      return taxNameMap[locale][tax];
    },
    number: v => {
      const format = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      return format.format(v).replaceAll(String.fromCharCode(160), " ");
    }
  }

  return formatters
}
