import type {FunctionComponent} from "react";
import NewDeliveryTopNav from "./NewDeliveryTopNav";
import NewDeliveryCustomerInfo from "./NewDeliveryCustomerInfo";
import OrderScreenRightContainer from "@order-view/OrderScreenRightContainer";

const NewDeliveryRight: FunctionComponent = () => {
  return (
    <div className="self-stretch w-[280px] bg-white-solid-white-100-ffffff overflow-hidden shrink-0 flex flex-col items-start justify-start text-center text-sm text-white-solid-white-100-ffffff font-mulish sm:w-[250px] Tablet_768:w-[230px] Tablet_600:w-[230px] height_414:w-[230px]">
      <NewDeliveryTopNav />
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start pt-2.5 px-2 pb-2 z-[0] text-left text-xs text-blue-solid-blue-370-0094ff">
        <NewDeliveryCustomerInfo />
        <OrderScreenRightContainer />
      </div>
    </div>
  );
};

export default NewDeliveryRight;
