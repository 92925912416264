import CategoryListView from '@edit-online-menu/CategoryListView.tsx'
import ModifierEditingView from '@edit-online-menu/ModifierEditingView.tsx'
import ModifierListView from '@edit-online-menu/ModifierListView.tsx'
import ProductEditingView from '@edit-online-menu/ProductEditingView.tsx'

import { makeCategoriesAvailable } from '@/data/CategoryHub'
import { makeModifiersAvailable } from '@/data/ModifierHub'
import { makeProductsAvailable } from '@/data/ProductHub'
import { makeRoomsAvailable } from '@/data/RoomHub'
import { onEnter, PosScreen } from '@/pos/PosRouter'

import { STATES, Tabs, VIEW__CATEGORY } from './EditOnlineMenuView.logic'

export default function EditOnlineMenuView() {
  makeCategoriesAvailable()
  makeProductsAvailable()
  makeModifiersAvailable()
  makeRoomsAvailable()

  const editingProduct = STATES.editingProduct()
  const editingModifier = STATES.editingModifierId()
  const selectedTab = STATES.selectedTab()

  onEnter(PosScreen.EDIT_ONLINE_MENU, () => {
    const c = STATES.categories()
    if (c.length) VIEW__CATEGORY.onExpand(c[0]._id)
  })

  return selectedTab === Tabs.MenuList ? (
    <>{editingProduct ? <ProductEditingView /> : <CategoryListView />}</>
  ) : selectedTab === Tabs.OptionList ? (
    <>{editingModifier ? <ModifierEditingView /> : <ModifierListView />}</>
  ) : (
    <></>
  )
}
