import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const LICENSE_COLLECTION_NAME = 'license'

export const VERSION = 4

export interface License {
  licenseFrom?: number
  licenseTo?: number
  _id: string
  fullTime?: boolean
}

const licenseCollectionSchema: RxJsonSchema<License> = {
  title: 'license schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    licenseFrom: { type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ** 10 },
    licenseTo: { type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ** 10 },
    fullTime: { type: 'boolean' },
    _id: { type: 'string', maxLength: 24 },
  },
  indexes: [],
} as const

export const License = {} as RxCollection<License>

export const createLicenseCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: License,
    collectionName: LICENSE_COLLECTION_NAME,
    version: VERSION,
    schema: licenseCollectionSchema,
  });
}
