import axios, {type AxiosProgressEvent} from 'axios';
import {getApiUrl} from "@/shared/utils.ts";

import { posSync0 } from "@/data/PosSyncState.ts";

const apiKey = "389215d4-be05-4f59-8eab-b1be1ff82658";

export async function uploadFile(folder: string, file: File | Blob, uploadProgressCallback: (progress: number) => void) {
  try {
    const filename = file instanceof File ? file.name: 'uploaded.png' + file.type
    console.log('[FileUploader] upload file', filename, 'to folder', folder)
    const baseUrl = getApiUrl()
    const posId = posSync0()?.id || 'no-store'
    const qry: Record<string, string> = {
      folder: `${posId}/${folder}`,
      filename: filename,
      mimeType: file.type,
      apiKey
    }
    const qryStr = Object.entries(qry).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&')
    const {data} = await axios.get(`${baseUrl}/api/storage/upload-form?${qryStr}`);
    console.log('[uploadFile] presigned post returned. upload begin')
    const {url, fields, imageUrl} = data;
    console.log('[uploadFile] presigned post data', data);
    const formData = new FormData()
    Object.keys(fields).forEach(key => formData.append(key, fields[key]));
    formData.append('file', file)
    function onUploadProgress(progress: AxiosProgressEvent) {
      const progressPercentage = Math.round(progress.loaded * 100 / progress.total!);
      uploadProgressCallback && uploadProgressCallback(progressPercentage);
    }
    const source = axios.CancelToken.source()
    await axios.post(url, formData, {
      cancelToken: source.token,
      headers: {"Content-Type": "multipart/form-data"},
      onUploadProgress
    })
    return imageUrl
  } catch (e) {
    console.error('[FileUploader] file to upload file', e)
    return ''
  }
}
