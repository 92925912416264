import {
  type ExtractDocumentTypeFromTypedRxJsonSchema,
  type RxCollection,
  type RxDatabase,
  type RxDocument,
  type RxJsonSchema,
  toTypedRxJsonSchema
} from 'rxdb'
import { deleteMany } from "@/data/data-utils.ts";
import { createCollection } from "@/data/utils/migration.ts";

export const EOD_CACHE_COLLECTION_NAME = 'eod_caches'

export const VERSION = 5
const eodCacheSchema = {
  title: 'eodCache schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    date: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    vSum: { type: 'number' },
    data: { type: 'object' },
  },
  indexes: ['date'],
  required: ['_id'],
} as const satisfies RxJsonSchema<any>

const schemaTyped = toTypedRxJsonSchema(eodCacheSchema)
export interface EodCache extends ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped> {}

export type EodCacheDocument = RxDocument<EodCache>
export type EodCacheCollection = RxCollection<EodCache>

export const EodCacheCollection: EodCacheCollection = {} as EodCacheCollection
export const EodCache = EodCacheCollection

window.EodCache = EodCache

export const createEodCacheCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: EodCache,
    collectionName: EOD_CACHE_COLLECTION_NAME,
    version: VERSION,
    schema: eodCacheSchema,
    extraStrategy: {
      4: doc => {
        return null
      }
    }
  });
}

const clearEodCache = async () => {
  await deleteMany(EodCache, {});
}

//@ts-ignore
window.clearEodCache = clearEodCache