import type { Eod } from "@/data/Eod.ts";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";

export function Stamm_KassenFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      KASSE_BRAND: KASSE_BRAND(),
      KASSE_MODELL: KASSE_MODELL(),
      KASSE_SERIENNR: KASSE_SERIENNR(),
      KASSE_SW_BRAND: KASSE_SW_BRAND(),
      KASSE_SW_VERSION: KASSE_SW_VERSION(),
      KASSE_BASISWAEH_CODE: KASSE_BASISWAEH_CODE(),
      KEINE_UST_ZUORDNUNG: KEINE_UST_ZUORDNUNG()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return eod.z;
  }

  //fixme: fill from surreal data
  //todo: tao 1 bang tren surreal: ghi thong tin KASSE_BRAND, KASSE_MODELL, KASSE_SW_BRAND
  //ten bang la CompanyInfo
  //CRUD, hoc cach tao user tren surreal
  function KASSE_BRAND() {
    // hint: "Marke der Kasse",
    return '';
  }

  function KASSE_MODELL() {
    // hint: "Modellbezeichnung",
    return '';
  }

  function KASSE_SERIENNR() {
    // hint: "Seriennummer der Kasse",
    return '';
  }

  function KASSE_SW_BRAND() {
    // hint: "Markenbezeichnung der Software",
    return '';
  }

  function KASSE_SW_VERSION() {
    // hint: "Version der Software",
    return '';
  }

  function KASSE_BASISWAEH_CODE() {
    // hint: "Basiswährung der Kasse",
    return 'EUR';
  }

  function KEINE_UST_ZUORDNUNG() {
    // hint: "UmsatzsteuerNichtErmittelbar",
    return '';
  }
}
