import { FunctionComponent } from "react";
import { orderLayout0 } from '@/react/OrderView/OrderView.tsx';
import { ColorDarken } from '@/react/core/ColorDarken.tsx';
import GridElement from '@/react/core/GridElement.tsx';
import GridLayout from '@/react/core/GridLayout.tsx';
import { setCategoryIndex } from '@/react/SyncMenuView/SyncMenuView.tsx';

const CategoryList: FunctionComponent = () => {
  return (
    <GridLayout rows={orderLayout0()?.rows || 0} cols={orderLayout0()?.columns || 0} colGap={5}
                rowGap={5} className="w-full self-center"
                style={{height: (orderLayout0()?.rows || 1) * 38 - 5}}
    >
      {orderLayout0()?.categories.map((category, index) => (
        <ColorDarken color={category.color} key={index}>
          {(color, setClicked) => (
            <GridElement x={category.left} y={category.top} cols={1} rows={1}
                         style={{ background: color }}
                         className={"rounded-[5px] flex center"}
                         onPointerDown={e => {
                           setCategoryIndex(index)
                           setClicked(true)
                         }}>
              <b className="text-ellipsis whitespace-nowrap overflow-hidden text-sm text-text-color-black-1e1e23 font-mulish">
                {category.name}
              </b>
            </GridElement>
          )}
        </ColorDarken>
      ))}
    </GridLayout>
  );
};

export default CategoryList;
