import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type PrinterLeftPanelItemType = {
  iconCode?: string;
  text?: string;
  showAddIcon?: boolean;
  isSelected?: boolean;

  /** Style props */
  rectangleLabelColor?: CSSProperties["color"];

  onClick?: () => void;
};

const PrinterLeftPanelItem: FunctionComponent<PrinterLeftPanelItemType> = ({
  iconCode,
  text = "Additional Settings",
  showAddIcon = true,
  isSelected = true,
  rectangleLabelColor,
  onClick
}) => {
  const rectangleLabel1Style: CSSProperties = useMemo(() => {
    return {
      color: isSelected ? "#FFFFFF": "#424242",
    };
  }, [isSelected]);

  return (
    <div className="h-10 flex flex-row items-center justify-start py-0 px-2 box-border relative gap-[0px_12px] text-left text-smi text-basic-color-white font-mulish self-stretch"
         onPointerDown={onClick}
    >
      {isSelected && (
        <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] [background:linear-gradient(45deg,_#3949ab,_#4fc3f7)] z-[0]" />
      )}
      <img
        className="w-6 relative h-6 object-cover z-[1]"
        alt=""
        src={iconCode}
      />
      <b
        className="flex-1 relative leading-[15px] z-[2] md:text-2xs"
        style={rectangleLabel1Style}
      >
        {text}
      </b>
      {showAddIcon && (
        <img
          className="w-6 relative h-6 overflow-hidden shrink-0 object-cover z-[3]"
          alt=""
          src="/iconadd-label-printer-icon@2x.png"
        />
      )}
    </div>
  );
};

export default PrinterLeftPanelItem;