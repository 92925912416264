import type { RxCollection, RxDatabase } from 'rxdb'

import { Menu, MENU_COLLECTION_NAME, menuSchema } from './Menu'
import { createCollection } from './utils/migration'

export const createMenuCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<Menu>> => {
  return await createCollection({
    database,
    collection: Menu,
    collectionName: MENU_COLLECTION_NAME,
    version: menuSchema.version,
    schema: menuSchema,
  })
}
