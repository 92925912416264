import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'

import type { DocumentResponseData, SignedDocumentData } from '@/srm/lib/types'
import { createCollection } from "@/data/utils/migration.ts";

export const SRM_DOCUMENT_LOG_COLLECTION_NAME = 'srm_document_log'

export interface SrmDocumentLog {
  readonly _id: string
  readonly date: number
  /**
   * Identifier of the device on which the SRS is installed or of the terminal used to access the SRS.
   * The identifier is generated and returned by the WEB-SRM with the first "certificates" request.
   */
  readonly deviceId: string
  readonly data: SignedDocumentData
  readonly user: string

  /** Indicator to mark if this transaction is sent to WEB-SRM */
  sent: boolean
  /** Response from WEB-SRM */
  response?: DocumentResponseData
}

export const VERSION = 1 // Increase this whenever schema changed
const schema: RxJsonSchema<SrmDocumentLog> = {
  version:VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    date: { type: 'number', final: true, minimum: 0, maximum: 8.64e15, multipleOf: 1 },
    deviceId: { type: 'string', final: true, maxLength: 24 },
    sent: { type: 'boolean' },
    data: { type: 'object', final: true },
    response: { type: 'object' },
    user: { type: 'string', final: true, maxLength: 24 },
  },
  required: ['_id', 'deviceId', 'data', 'date', 'sent'],
  indexes: [
    'date', // Usage: Sales report
    'deviceId', // Usage: Transaction History page
    ['date', 'deviceId', 'user'], // Usage: Sales report, per user
    ['deviceId', 'user'], // Usage: Transaction History page
    ['deviceId', 'sent'], // Usage: find pending transactions
  ],
}

export const SrmDocumentLog = {} as RxCollection<SrmDocumentLog>

Object.assign(window, { SrmDocumentLog }) // Make available global

export const createSrmDocumentLogCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: SrmDocumentLog,
    collectionName: SRM_DOCUMENT_LOG_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}
