import type { RxCollection, RxDatabase } from 'rxdb'

import { posSetting0, posSettingLock } from './PosSettingsSignal'
import { POS_SYNC_COLLECTION_NAME, PosSync, posSyncSchema } from './PosSync'
import { createCollection } from './utils/migration'

export const createPosSyncCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<PosSync>> =>
  await createCollection({
    database,
    collection: PosSync,
    collectionName: POS_SYNC_COLLECTION_NAME,
    version: posSyncSchema.version,
    schema: posSyncSchema,
    extraStrategy: {
      7: async doc => {
        await posSettingLock.acquireAsync()
        const server = posSetting0()?.server
        const newDoc = { ...doc }
        newDoc.server = server
        return newDoc
      },
    },
  })
