import dayjs from "dayjs";
import {signal} from '@/react/core/reactive';
import { LL2 } from "@/react/core/I18nBackend.tsx";

export const [now, setNow] = signal<Date>(new Date())
setInterval(() => {
    setNow(() => new Date())
}, 1000)

export function smartFormatShortDateTime(date: Date | string | undefined, format?: string): string {
    if (!date) return ''
    const fmt = dayjs(date)
    if (!fmt.isValid()) {
        return date + ""
    }
    try {
        format = format || "HH:mm"
        if (fmt.get('date') === dayjs().get('date'))
            return fmt.format(LL2().dates.timeFormat()) // TODO: i18n
        return fmt.format(LL2().dates.monthDayTimeFormat()) // TODO: i18n
    } catch (e) {
        console.log('smartFormatShortDateTime', 'failed to format date', e)
        return date + ""
    }
}