import TextField from "@/react/core/TextField.tsx";
import {
  dbNameDownload,
  dbNameUpload,
  menuNameDownload,
  menuNameUpload,
  onDownloadDatabase,
  onDownloadMenu,
  onUploadDatabase,
  onUploadMenu,
  setDbNameDownload,
  setDbNameUpload,
  setMenuNameDownload,
  setMenuNameUpload
} from "@/react/Developer/DataMenu.logic.ts";
import React from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";

const DeveloperImportExportTab = () => {
  return (
    <div className='h-full flex-col gap-1 pb-5'>
      <div className="h-full flex flex-col gap-4">
        <fieldset className='border boder-[#c3c3c3] p-4 max-w-[500px]'>
          <legend>Upload Menu:</legend>
          <div className="flex flex-col items-start gap-2">
            <p className='text-[#000] text-sm'>Menu name:</p>
            <TextField
              value={menuNameUpload()}
              className={'self-stretch'}
              onChange={e => setMenuNameUpload(e.target.value)}
            />
            <div className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
                 ref={makeRipple}
                 onClick={() => onUploadMenu()}
            >
              Upload Menu
            </div>
          </div>
        </fieldset>
        <fieldset className='border boder-[#c3c3c3] p-4 max-w-[500px]'>
          <legend>Download Menu</legend>
          <div className="flex flex-col items-start gap-2">
            <p className='text-[#000] text-sm'>Menu name:</p>
            <TextField
              value={menuNameDownload()}
              className={'self-stretch'}
              onChange={e => setMenuNameDownload(e.target.value)}
            />
            <div className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
                 ref={makeRipple}
                 onClick={() => onDownloadMenu()}
            >
              Download Menu
            </div>
          </div>
        </fieldset>
      </div>
      <div className="flex-1 justify-center my-5">
        <div className="h-[1px] bg-gray-400 w-80%"></div>
      </div>

      <div className="h-full flex flex-col gap-4 pb-4">
        <fieldset className='border boder-[#c3c3c3] p-4 max-w-[500px]'>
          <legend>Upload Database</legend>
          <div className="flex flex-col items-start gap-2">
            <p className='text-[#000] text-sm'>Database name:</p>
            <TextField
              value={dbNameUpload()}
              className={'self-stretch'}
              onChange={e => setDbNameUpload(e.target.value)}
            />
            <div className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
                 ref={makeRipple}
                 onClick={() => onUploadDatabase()}
            >
              Upload Database
            </div>
          </div>
        </fieldset>
        <fieldset className='border boder-[#c3c3c3] p-4 max-w-[500px]'>
          <legend>Download Database</legend>
          <div className="flex flex-col items-start gap-2">
            <p className='text-[#000] text-sm'>Database name:</p>
            <TextField
              value={dbNameDownload()}
              className={'self-stretch'}
              onChange={e => setDbNameDownload(e.target.value)}
            />
            <div className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
                 ref={makeRipple}
                 onClick={() => onDownloadDatabase()}
            >
              Download Database
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  )
}

export default DeveloperImportExportTab