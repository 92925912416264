import { FunctionComponent } from "react";

type PrinterSelectButtonType = {
  buttonNumber?: number;
  isSelected?: boolean;
  onClick:()=>void
};

const PrinterSelectButton: FunctionComponent<PrinterSelectButtonType> = ({
  buttonNumber = 1,
  isSelected = false,
    onClick
}) => {
  return (
    <div onClick={onClick} className="w-10 rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] text-left text-mini text-black-solid-black-880-1d1d26 font-mulish border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">
      {isSelected && (
        <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
      )}
      <div className="relative z-[1]">{buttonNumber}</div>
    </div>
  );
};

export default PrinterSelectButton;
