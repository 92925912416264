import {type FunctionComponent, useState, useCallback } from "react";
import NewDeliveryAddEditCustomer from "./NewDeliveryAddEditCustomer";
import PortalPopup from "./PortalPopup";
import NewDeliveryMissedCallPopup from "./Popups/NewDeliveryMissedCallPopup";
import { onEnterNewDeliveryWithCustomer } from "@/react/NewDeliveryView/NewDeliveryView";
import {LL0} from "@/react/core/I18nService.tsx";

export type NewDeliveryBurgerMenuButType = {
  showExpand?: boolean;
  showPickupStatus?: boolean;
  showMenuIcon?: boolean;
  showDeliveryStatus?: boolean;
  isSelected?: boolean;
  isDefault?: boolean;
  showButtonText?: boolean;
};

const NewDeliveryBurgerMenuBut: FunctionComponent<
  NewDeliveryBurgerMenuButType
> = ({
  showExpand = true,
  showPickupStatus = true,
  showMenuIcon = true,
  showDeliveryStatus = true,
  isSelected,
  isDefault,
  showButtonText,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  showExpand = isOpened;

  const toggleMenu = () => setIsOpened(prev => !prev)

  const [isNewDeliveryAddEditCustomerOpen, setNewDeliveryAddEditCustomerOpen] =
    useState(false);
  const [isNewDeliveryMissedCallPopupOpen, setNewDeliveryMissedCallPopupOpen] =
    useState(false);

  const openNewDeliveryAddEditCustomer = useCallback(() => {
    onEnterNewDeliveryWithCustomer();
    toggleMenu();
    // setNewDeliveryAddEditCustomerOpen(true);
  }, []);

  const closeNewDeliveryAddEditCustomer = useCallback(() => {
    setNewDeliveryAddEditCustomerOpen(false);
  }, []);

  const openNewDeliveryMissedCallPopup = useCallback(() => {
    toggleMenu();
    setNewDeliveryMissedCallPopupOpen(true);
  }, []);

  const closeNewDeliveryMissedCallPopup = useCallback(() => {
    setNewDeliveryMissedCallPopupOpen(false);
  }, []);

  return (
    <>
      {
        isOpened &&
          <div className="w-full h-full absolute top-0 left-0 opacity-0 z-[1]" onClick={() => toggleMenu()} />
      }
      <div className="w-11 h-11 flex flex-col items-center justify-center text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
        <div className="flex flex-col items-start justify-start relative gap-[2px_0px]">
          {showMenuIcon && (
            <img
              className="w-11 relative rounded-981xl h-11 object-cover z-[0] sm:w-9 sm:h-9 Tablet_768:w-9 Tablet_768:h-9 Tablet_600:w-9 Tablet_600:h-9 height_414:w-9 height_414:h-9"
              alt=""
              src="/orderburger-menu-icon@2x.png"
              onClick={toggleMenu}
            />
          )}
          {showDeliveryStatus && (
            <img
              className="w-11 relative rounded-981xl h-11 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-9 Tablet_768:h-9 Tablet_600:w-9 Tablet_600:h-9 Mobile_480:w-9 Mobile_480:h-9 height_414:w-9 height_414:h-9"
              alt=""
              src="/new-delivery-delivery-moto-icon@2x.png"
              onClick={toggleMenu}
            />
          )}
          {showPickupStatus && (
            <img
              className="w-11 relative rounded-981xl h-11 object-cover z-[2] sm:w-9 sm:h-9 Tablet_768:w-9 Tablet_768:h-9 Tablet_600:w-9 Tablet_600:h-9 Mobile_480:w-9 Mobile_480:h-9 height_414:w-9 height_414:h-9"
              alt=""
              src="/new-delivery-pickup-bag-icon@2x.png"
              onClick={toggleMenu}
            />
          )}
          {showExpand && (
            <div className="!m-[0] absolute top-[42px] left-[2px] flex flex-col items-start justify-start z-[3]">
              <div className="self-stretch flex flex-col items-start justify-end py-0 pr-0 pl-[15px] sm:pl-[11px] sm:box-border Tablet_768:pl-[11px] Tablet_768:box-border Tablet_600:pl-[11px] Tablet_600:box-border height_414:pl-[11px] height_414:box-border">
                <img
                  className="w-2.5 relative h-[9px] object-cover"
                  alt=""
                  src="/icontooltip-arrow-up-icon@2x.png"
                />
              </div>
              <div className="w-48 rounded bg-blue-solid-blue-40-edf5ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.3)] flex flex-col items-start justify-start py-3.5 px-2 box-border gap-[8px_0px]">
                <div
                  className="self-stretch h-[30px] rounded grid flex-row items-center justify-start relative gap-[0px_8px] grid-flow-col [column-gap:8px] cursor-pointer"
                  onClick={openNewDeliveryAddEditCustomer}
                >
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/iconnew-deliveryadd-customer-icon@2x.png"
                  />
                  <b className="w-[65px] relative leading-[17px] hidden z-[2]">
                    {LL0().onlineOrder.addCustomer()}
                  </b>
                  <b className="flex-1 relative leading-[17px] text-white-solid-white-100-ffffff z-[3]">
                    {LL0().onlineOrder.addCustomer()}
                  </b>
                </div>
                <div
                  className="self-stretch h-[30px] rounded grid flex-row items-center justify-start relative gap-[0px_8px] grid-flow-col [column-gap:8px] cursor-pointer"
                  onClick={openNewDeliveryMissedCallPopup}
                >
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/iconnew-delivery-missed-calls@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().delivery.toolbar.missedCalls()}
                  </b>
                  <b className="w-[65px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                    {LL0().delivery.toolbar.missedCalls()}
                  </b>
                </div>
                {/*<div className="self-stretch h-[30px] rounded grid flex-row items-center justify-start relative gap-[0px_8px] grid-flow-col [column-gap:8px]">*/}
                {/*  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 hidden z-[0]" />*/}
                {/*  <img*/}
                {/*    className="w-[30px] relative h-[30px] object-cover z-[1]"*/}
                {/*    alt=""*/}
                {/*    src="/orderfunctions-icon@2x.png"*/}
                {/*  />*/}
                {/*  <b className="flex-1 relative leading-[17px] z-[2]">*/}
                {/*    Settings*/}
                {/*  </b>*/}
                {/*  <b className="w-[65px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">*/}
                {/*    Settings*/}
                {/*  </b>*/}
                {/*</div>*/}
              </div>
            </div>
          )}
        </div>
      </div>
      {isNewDeliveryAddEditCustomerOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewDeliveryAddEditCustomer}
        >
          <NewDeliveryAddEditCustomer
            onClose={closeNewDeliveryAddEditCustomer}
          />
        </PortalPopup>
      )}
      {isNewDeliveryMissedCallPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewDeliveryMissedCallPopup}
        >
          <NewDeliveryMissedCallPopup
            onClose={closeNewDeliveryMissedCallPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default NewDeliveryBurgerMenuBut;
