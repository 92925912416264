import { type FunctionComponent, useContext } from 'react';
import OrderScreenTopNav from '@order-view/OrderScreenTopNav2';
import { handleAddSeat, isShowToolbar } from '@/react/OrderView/OrderView.tsx';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import { LL0 } from '@/react/core/I18nService.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import OrderScreenRightContainer from '@order-view/OrderScreenRightContainer.tsx';
import { isInSrmTrainingMode } from '@/srm/signals';
import { computed } from '@/react/core/reactive.ts'
import { clsx } from "clsx";
import _ from "lodash";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { order0 } from "@/react/OrderView/OrderViewShare";

const OrderScreenRight: FunctionComponent = () => {
  const { order0 } = useContext(ItemFactoryContext);
  const tableName = computed(() => {
    if (order0().table) {
      return order0().table?.length > 5 ? `${order0().table?.substring(0, 5)}...` : order0().table
    }
  })
  return (
    <>
      <div
        className={clsx("self-stretch bg-white-solid-white-100-ffffff flex flex-col items-start justify-start relative text-left text-sm text-black-solid-black-720-32383d font-mulish",
          deviceSetting0()?.screenConfig?.smallSidebar ? 'w-[210px]' : 'w-[280px] sm:w-[250px] Tablet_600:w-[230px] height_414:w-[230px]'
        )}>
        {!isShowToolbar() && order0?.().items.length === 0 && !order0?.().seatMode && <div
          className="my-0 mx-[!important] absolute right-[15.8px] bottom-[17.8px] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-cyan-solid-cyan-160-d2ecff overflow-hidden flex flex-row items-center justify-center py-[9px] pr-3.5 pl-[13px] gap-[0px_7px] cursor-pointer z-[2] text-center border-[0.4px] border-solid border-cyan-solid-cyan-180-b4e9ff"
          ref={makeRipple} onClick={() => {
          handleAddSeat()
        }}
        >
          <img
            className="w-2.5 relative h-2.5 object-cover"
            alt=""
            src="/iconaddseatplus-icon@2x.png"
          />
          <b className="relative leading-[17px] Tablet_768:text-xs">{LL0().order.addSeat()}</b>
        </div>}
        <OrderScreenTopNav />
        <div className="self-stretch flex flex-col items-center justify-center pt-1 px-1 pb-0 z-[3] text-blue-solid-blue-194480">
          <div className="text-[#063FAC] self-stretch rounded-10xs bg-cyan-solid-cyan-150-edeff1 flex flex-row items-center justify-start py-1 px-1.5 gap-[5px]">
            <div className="flex-1 relative font-semibold [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
              {_.startCase(order0().users?.[0])}
            </div>
            <div className="relative font-semibold text-right [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
              {LL0().orderHistory.tableNo()}: {tableName()}
            </div>
          </div>
        </div>
        {order0()?.note &&
          <div className="self-stretch flex flex-row items-start justify-start sm:flex-col sm:gap-[0px_4px] px-[10px] py-1">
            <div className="flex-1 relative leading-[16px] sm:text-xs sm:flex-[unset] sm:self-stretch">
              <b>
                <span className='text-[#063FAC] font-semibold'>{LL0().reservation.note()}:</span>
              </b>
              <span className="text-black-solid-black-880-1d1d26">
                <b> </b>
                <span>{order0()?.note}</span>
              </span>
            </div>
          </div>
        }
        {isInSrmTrainingMode() &&
          <div
            className="!mt-2 self-stretch h-10 flex flex-col items-center justify-center pt-2.5 px-1 pb-0 box-border z-[1] text-3xs text-grey-grey-darken-3">
            <div
              className="self-stretch rounded-10xs bg-cyan-solid-cyan-250-8aeaff flex flex-row items-center justify-start py-1 pr-1 pl-1.5 gap-[5px]">
              <img
                className="w-[22px] relative h-[22px] object-cover"
                alt=""
                src="/icontraining-mode-icon@2x.png"
              />
              <i
                className="flex-1 relative [display:-webkit-inline-box] font-medium overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
                {LL0().settings.inTrainingMode()}
              </i>
            </div>
            {/*<div className="self-stretch flex flex-col items-center justify-center pt-1 px-1 pb-0 z-[1] text-2xs text-blue-solid-blue-194480">*/}
            {/*  <div className="self-stretch rounded-10xs bg-cyan-solid-cyan-250-8aeaff flex flex-row items-center justify-start py-1 pr-1 pl-2">*/}
            {/*    <i className="flex-1 relative [display:-webkit-inline-box] font-semibold overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">{`>> Uber Eats`}</i>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        }
        <OrderScreenRightContainer />
      </div>

    </>
  )

}

export default OrderScreenRight;
