import { toast } from "react-toastify";
import axios from "axios";
import EscPrinter from "@/shared/printer/node-thermal-printer";
import { getInvoicePrinter } from "@/react/Printer/print-utils.ts";

let ip = 'localhost';

export const openCashDrawer = async (): Promise<void> => {
  const invoicePrinter = getInvoicePrinter();
  if (!invoicePrinter) return;
  toast('Open Cash Drawer ...', { autoClose: 200 });
  if (invoicePrinter.printerType === 'integrate') {
    await axios.post(`http://${ip}:5005/open-cash-drawer-sunmi`, {});
  } else {
    const printer = new EscPrinter(invoicePrinter);
    await printer.openCashDrawer();
    await printer.print();
  }
}