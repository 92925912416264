import { type FunctionComponent } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { LL0 } from "@/react/core/I18nService.tsx";
import { autoCancel, setAutoCancel } from "@/data/ReservationHub.ts"
import { makeRipple } from "@/react/core/ripple-utils.ts";

export type SettingHourPopupType = {
  className?: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const SettingHourPopup: FunctionComponent<SettingHourPopupType> = ({
  className = "",
  onClose,
  onConfirm,
}) => {
  return (
    <div
      className={`w-[508px] h-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-end justify-start pt-[42px] px-6 pb-4 box-border gap-[16px] max-w-full max-h-full text-left text-base text-black-solid-black-1000-000000 font-mulish ${className}`}
    >
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start gap-[10px] z-[0]">
        <div className="self-stretch relative font-semibold">
          {LL0().reservation.autoCancelAfterThisPeriod()}:
        </div>
        <div className="self-stretch relative h-10 shrink-0">
          <FormControl
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] font-mulish text-sm text-black-solid-black-880-1d1d26"
            variant="outlined"
            sx={{
              borderRadius: "0px 0px 0px 0px",
              width: "460px",
              height: "40px",
              m: 0,
              p: 0,
              "& .MuiInputBase-root": {
                m: 0,
                p: "14px",
                minHeight: "40px",
                justifyContent: "center",
                display: "inline-flex",
              },
              "& .MuiInputLabel-root": {
                m: 0,
                p: 0,
                minHeight: "40px",
                display: "inline-flex",
              },
              "& .MuiMenuItem-root": {
                m: 0,
                p: 0,
                height: "40px",
                display: "inline-flex",
              },
              "& .MuiSelect-select": {
                m: 0,
                p: 0,
                height: "40px",
                alignItems: "center",
                display: "inline-flex",
              },
              "& .MuiInput-input": { m: 0, p: 0 },
              "& .MuiInputBase-input": { textAlign: "left", p: "0 !important" },
            }}
          >
            <InputLabel color="primary" />
            <Select color="primary" value={autoCancel()} onChange={e => setAutoCancel(e.target.value)}>
              <MenuItem value="30min">30 {LL0().reservation.min()}</MenuItem>
              <MenuItem value="1hours">1 {LL0().reservation.hours()}</MenuItem>
              <MenuItem value="2hours">2 {LL0().reservation.hours()}</MenuItem>
              <MenuItem value="3hours">3 {LL0().reservation.hours()}</MenuItem>
              <MenuItem value="6hours">6 {LL0().reservation.hours()}</MenuItem>
              <MenuItem value="12hours">12 {LL0().reservation.hours()}</MenuItem>
            </Select>
            <FormHelperText/>
          </FormControl>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-end z-[1] text-center text-sm text-white-solid-white-100-ffffff">
        <div
          className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
          ref={makeRipple}
          onClick={() => {
            onClose?.()
            onConfirm?.()
          }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
      <img
        className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icongeneralclose-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default SettingHourPopup;
