import type { RxDocument, RxQuery } from 'rxdb'
import { BehaviorSubject, map, shareReplay, startWith } from 'rxjs'

import { dataLock } from '@/data/DataUtils'
import { DeviceSetting } from '@/data/DeviceSetting'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { effect, type Accessor } from '@/react/core/reactive'
import { getDeviceId } from '@/shared/getDeviceId'

import { shouldResendTrans } from './lib/utils.transaction'

/** Generate BehaviorSubject that hold RxQuery result data */
function genBehaviorSubjectFromRxQuery<T>(query: Accessor<RxQuery<T, RxDocument<T>[]>>) {
  const subject = new BehaviorSubject<RxDocument<T>[]>([])
  // Make sure to subscribe to new query, if the original changed (signal)
  effect(() => {
    const subscription = dataLock.acquireAsync().then(() => {
      const q = query()
      // Execute and subscribe to the query
      return q.$.subscribe(a => subject.next(a))
    })
    return () => subscription.then(a => a.unsubscribe())
  })
  return subject
}

const deviceSetting$ = genBehaviorSubjectFromRxQuery(() => DeviceSetting.find({ selector: { _id: getDeviceId() } })).pipe(map(a => (a.length > 0 ? a[0] : null)))

export const deviceSrmSettings$ = deviceSetting$.pipe(
  map(a => a?.srm),
  shareReplay(1)
)

export const pendingSrmTransactions$ = genBehaviorSubjectFromRxQuery(() =>
  SrmTransactionLog.find({
    selector: { sent: { $ne: true } },
    sort: [{ date: 'asc' }],
  })
).pipe(
  map(a => a.filter(shouldResendTrans)),
  startWith<RxDocument<SrmTransactionLog>[]>([]),
  shareReplay(1)
)
