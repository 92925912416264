import { type FunctionComponent, useState, useCallback, useEffect } from "react";
import ViewDetailsPopup from "./ViewDetailsPopup";
import PortalPopup from "./PortalPopup";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {LL0} from "@/react/core/I18nService.tsx";
import { virtualPrinterLogic } from "@/react/VirtualPrinterView/VitualPrinterLogic.ts";
import _ from "lodash"
import type { PrintScripts } from "@/data/PrintScripts"
import { CircularProgress } from "@mui/material"

type VirtualPrinterInvoiceCardType = {
  statusText?: string;
  isSuccess?: boolean;
  isRestaurant?: boolean;
  isCourse?: boolean;
  isPrintStatus?: boolean;
  isDelivery?: boolean;
  img?: PrintScripts
};

const VirtualPrinterInvoiceCard: FunctionComponent<
  VirtualPrinterInvoiceCardType
> = ({
  statusText = "Successful",
  isSuccess = true,
  isRestaurant = false,
  isCourse = false,
  isPrintStatus = true,
  isDelivery = false,
  img
}) => {
  const [isViewDetailsPopupOpen, setViewDetailsPopupOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openViewDetailsPopup = useCallback(() => {
    setViewDetailsPopupOpen(true);
  }, []);

  const closeViewDetailsPopup = useCallback(() => {
    setViewDetailsPopupOpen(false);
  }, []);

  useEffect(() => {
    if (img) {
      virtualPrinterLogic.renderImageFromScripts(img.scripts).then((base64) => setImageSrc(base64));
    } 
  }, [img]);
  return (
    <>
      <div className="!overflow-hidden !h-[240px] w-full rounded bg-white flex flex-col items-center justify-start pt-4 px-4 pb-[51px] box-border relative gap-[12px] text-center text-4xs text-black font-verdana overflow-y-hidden">
        {imageSrc ?
        <img src={imageSrc} alt="" className="self-stretch flex flex-col items-start justify-start gap-[12px] z-[0] text-left">
        </img>
        : 
        <div className="h-full flex flex-col items-center justify-center gap-1">
          <CircularProgress size="1em" />
          <p>Loading...</p>
        </div>
        }
        {isDelivery && (
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px] z-[0] text-left">
            <div className="self-stretch flex flex-row items-start justify-between text-sm">
              <div className="flex-1 flex flex-row items-start justify-start">
                <b className="relative">{LL0().printing.delivery()} #1</b>
              </div>
              <b className="relative">14:30</b>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <div className="self-stretch relative">Joe</div>
              <div className="self-stretch relative">Room 503, 4th Floor</div>
              <div className="self-stretch relative">10503</div>
              <div className="self-stretch relative">01622312433</div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-center">
              <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 border-b-[1px] border-dashed border-black-solid-black-900-1e1e23">
                <b className="flex-1 relative text-left">{LL0().printing.item()}</b>
                <b className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  {LL0().printing.quantity()}.
                </b>
                <b className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  {LL0().printing.unitPrice()}
                </b>
                <b className="flex-1 relative inline-block text-right min-w-[44px] max-w-[56px]">
                  {LL0().printing.sum()}
                </b>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">Cola</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">31. Tunna Pizza</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">32. BBQ Pizza</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">* De day</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">* Them thit</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">* Them cheese</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
            </div>
          </div>
        )}
        {isCourse && (
          <div className="self-stretch flex flex-col items-center justify-start gap-[23px] z-[1] text-sm">
            <b className="self-stretch relative">{LL0().virtualPrinter.course()} 1</b>
            <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left">
              <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 border-b-[1px] border-dashed border-black-solid-black-900-1e1e23">
                <b className="relative flex items-center w-[82.8px] shrink-0">
                  {LL0().printing.table()}: 05
                </b>
                <div className="relative text-xs text-right">{`16:44 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
                <b className="relative">4 x</b>
                <b className="relative">3. Garlic Bread</b>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-3 gap-[8px] border-b-[1px] border-dashed border-black-solid-black-900-1e1e23">
                <b className="relative">2 x</b>
                <b className="relative">5. Spring Rolls</b>
              </div>
            </div>
            <div className="self-stretch relative text-[12.12px]">
              Kitchen Printer - Admin
            </div>
          </div>
        )}
        {isRestaurant && (
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px] z-[2] text-[12.12px]">
            <div className="w-[260px] flex flex-col items-center justify-start gap-[12px]">
              <b className="self-stretch relative">Gigasource Restaurant</b>
              <div className="self-stretch relative text-[8.08px]">
                <p className="m-0">{LL0().onlineOrder.address()}</p>
                <p className="m-0">{LL0().printing.tel()}: 0462.813.977</p>
                <p className="m-0">HST# 123456789</p>
              </div>
              <b className="self-stretch relative text-[16.16px]">{LL0().order.receipt.invoice()}</b>
            </div>
            <div className="relative w-32 h-[54.6px] text-left text-[8.89px]">
              <div className="absolute top-[0px] left-[0px]">{`Date: 2023-01-30 03:40 PM `}</div>
              <div className="absolute top-[29.1px] left-[0px]">
                {LL0().article.type()}: Delivery
              </div>
              <div className="absolute top-[43.6px] left-[0px]">{LL0().order.cashier()}: Mary</div>
              <div className="absolute top-[14.5px] left-[0px]">
                {LL0().printing.invoiceNo()}: 098765
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-4xs">
              <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 border-b-[1px] border-dashed border-black-solid-black-900-1e1e23">
                <b className="flex-1 relative text-left">{LL0().printing.item()}</b>
                <b className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  {LL0().printing.quantity()}.
                </b>
                <b className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  {LL0().printing.unitPrice()}
                </b>
                <b className="flex-1 relative inline-block text-right min-w-[44px] max-w-[56px]">
                  {LL0().printing.sum()}
                </b>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">Cola</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">31. Tunna Pizza</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">32. BBQ Pizza</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">* De day</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">* Them thit</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex-1 relative text-left">* Them cheese</div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[40px]">
                  1
                </div>
                <div className="flex-1 relative inline-block min-w-[32px] max-w-[44px]">
                  15,49
                </div>
                <div className="flex-1 relative text-right inline-block min-w-[44px] max-w-[56px]">{`30,98 `}</div>
              </div>
            </div>
          </div>
        )}
        <div className="my-0 mx-[!important] absolute w-full right-[0px] bottom-[-0.4px] left-[0px] bg-blue-opacity-15-f3f9ff [backdrop-filter:blur(3px)] flex flex-col items-start justify-start pt-9 px-0 pb-0 box-border z-[3] text-mini text-white-solid-white-100-ffffff font-mulish">
          <div
            className="self-stretch rounded-t-none rounded-b bg-blue-solid-blue-350-2196f3 flex flex-row items-center justify-between py-2 px-0 relative cursor-pointer border-[1px] border-solid border-blue-solid-blue-350-2196f3"
            ref={makeRipple}
            onClick={openViewDetailsPopup}
          >
            <div className="flex-1 relative font-semibold z-[0]">
              {LL0().virtualPrinter.viewDetails()}
            </div>
            <div className="absolute my-0 mx-[!important] top-[calc(50%_-_12.5px)] right-[14px] w-6 h-6 overflow-hidden shrink-0 z-[1]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/iconright-arrow-icon-3@2x.png"
              />
            </div>
          </div>
        </div>
        {isPrintStatus && (
          <div className="my-0 mx-[!important] absolute top-[12px] right-[12px] rounded-11xl bg-red-solid-red-200-ff5252 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-row items-center justify-end py-[5px] px-2.5 gap-[8px] z-[4] text-left text-smi text-white-solid-white-100-ffffff font-mulish">
            {isSuccess && (
              <div className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] bg-green-solid-green-500-67af34 box-border hidden z-[0] border-[1px] border-solid border-green-solid-green-500-67af34" />
            )}
            <i className="relative font-semibold z-[1]">{_.get(LL0().settings, statusText.toLowerCase())?.()}</i>
          </div>
        )}
      </div>
      {isViewDetailsPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeViewDetailsPopup}
        >
          <ViewDetailsPopup onClose={closeViewDetailsPopup} imageSrc={imageSrc} metadata={img?.metadata} />
        </PortalPopup>
      )}
    </>
  );
};

export default VirtualPrinterInvoiceCard;
