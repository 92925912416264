import { computed } from '@/react/core/reactive'
import { CONSTANT_VALUES } from '@/srm/lib/constants'
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

export const lastTransactionSignature = computed(() => deviceSetting0()?.srm?.lastTransSig ?? CONSTANT_VALUES.emptySig)

export async function getLastTransactionSignature() {
  const { lastTransSig } = deviceSetting0()?.srm ?? {}
  return lastTransSig ?? CONSTANT_VALUES.emptySig
}

export async function saveLastTransactionSignature(signature?: string) {
  const ds = deviceSetting0()
  const result = await ds?.doc?.incrementalUpdate({
    $set: { 'srm.lastTransSig': signature },
  })
  const isOk = result?.srm?.lastTransSig === signature
  if (!isOk) console.warn('⚠️ Failed to save Transaction Signature! Further transactions will not able to sign!')
  return isOk
}
