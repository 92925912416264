import { getDeviceId } from '@/shared/getDeviceId'
import { deviceSettingLock } from '@/data/DeviceSettingHub.ts'
import SurrealClient from '@/shared/SurrealClient.ts'
import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";
import { posSettingLock } from "@/data/PosSettingsSignal.ts";

export async function sendDeviceVersion() {
  const id = getDeviceId()
  await posSyncLock.acquireAsync()
  await posSettingLock.acquireAsync()
  await deviceSettingLock.acquireAsync()
  if (!posSync0()?.id || !id) return

  const db = await SurrealClient.getSurrealClient('cloud')
  if (!db) return

  const version = localStorage.getItem('deviceVersion2')
  if (version && version === import.meta.env.VITE_APP_VERSION) return

  await db.query(`UPSERT Device:${id} SET version = '${import.meta.env.VITE_APP_VERSION}'`)
  localStorage.setItem('deviceVersion2', import.meta.env.VITE_APP_VERSION)
}
