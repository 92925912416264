import * as zip from '@zip.js/zip.js'

export async function zipTextToBlob(...files: Array<[fileName: string, content: string]>) {
  zip.configure({ chunkSize: 128, useWebWorkers: true })
  let zipFs = new zip.fs.FS()
  for (const [fileName, content] of files) {
    const BLOB = new Blob([content], { type: zip.getMimeType(fileName) })
    zipFs.addBlob(fileName, BLOB)
  }
  const zippedBlob = await zipFs.exportBlob()
  return zippedBlob
}
