import {
  cashbookHistoryList,
  CashbookViewList,
  dateRange,
  formatDate,
  getCashBookHistoryList,
  getCashBookHistoryStartDate,
  setCashbookHistoryList,
  setDateRange,
  setSelectedCashbookView,
  toEndOfDate,
  toStartOfDate,
  VIEW__HISTORY_DETAILS
} from "@/react/CashbookView/CashbookView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { type FunctionComponent, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

const CustomDateTimePickerField: FunctionComponent<{ value?: Dayjs | null; onClick?: () => void }> = ({ value, onClick }) => (
  <button
    className="inline"
    type="button"
    onClick={onClick}
  >
    {value?.format(LL2().dates.dateFormat()) ?? '-'}
  </button>
)

const CashbookHistoryPlugin: FunctionComponent = () => {

  const bgColorTableRow = (index: number, lenTable: number) => {
    if (lenTable === 1) return "bg-white-solid-white-100-ffffff"
    else {
      if (index % 2 === 0) return "bg-white-solid-white-100-ffffff"
      else return "bg-gray-solid-gray-180-ebecee"
    }
  }

  useEffect(() => {
    (async () => {
      const historyList = await getCashBookHistoryList(toStartOfDate(dateRange()[0]), toEndOfDate(dateRange()[1] || dateRange()[0]))
      console.log("historyList", historyList);
      setCashbookHistoryList(historyList.sort((a, b) => b.id - a.id))
    })()
  }, [dateRange()[0], dateRange()[1]])

  return (
    <div
      className="bg-black-solid-black-700-364150 w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[0px] text-left text-base text-white-solid-white-100-ffffff font-mulish hover:bg-black-solid-black-700-364150 hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start">
      <div
        className="self-stretch bg-black-solid-black-500-59636f h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[5px] px-2.5 box-border sm:h-[42px]">
        <div className="flex flex-row items-center justify-start gap-[10px]">
          <img
            className="relative w-9 h-9 object-cover sm:w-7 sm:h-7"
            alt=""
            src="/iconcalendar-icon@2x.png"
          />
          <div className="flex-1 relative [text-decoration:underline] font-semibold Tablet_768:text-sm Tablet_600:text-smi">
            <MobileDateTimePicker
              ampm={companyInfo0()?.country !== 'de'}
              value={dayjs(dateRange()[0])}
              slots={{ field: CustomDateTimePickerField }}
              onChange={v => setDateRange(prev => ([v, prev[1]]))}
            />
            {' - '}
            <MobileDateTimePicker
              ampm={companyInfo0()?.country !== 'de'}
              value={dayjs(dateRange()[1])}
              slots={{ field: CustomDateTimePickerField }}
              onChange={v => setDateRange(prev => ([prev[0], v]))}
            />
          </div>
        </div>
        <div
          className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-center text-sm sm:h-8"
          onClick={() => {
            setSelectedCashbookView(CashbookViewList.Cashbook)
            setDateRange([getCashBookHistoryStartDate(), dayjs().format(LL2().dates.dateFormat())])
          }}
          ref={makeRipple}
        >
          <img
            className="relative w-[30px] h-[30px] object-cover sm:w-6 sm:h-6"
            alt=""
            src="/iconback-icon@2x.png"
          />
          <b className="relative leading-[17px] sm:text-2xs">{LL0().posSetup.accountTab.back()}</b>
        </div>
      </div>
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start">
        <table className="self-stretch overflow-hidden shrink-0 border-spacing-[0]">
          <thead>
            <tr>
              <th className="relative pr-0 pb-0" colSpan={2}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center sm:text-xs mq1440:text-lg">
                    {LL0().cashbook.reportId()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center sm:text-xs mq1440:text-lg">
                    {LL0().common.date()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={2}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center sm:text-xs mq1440:text-lg">
                    {LL0().cashbook.cashStart()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={2}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center sm:text-xs mq1440:text-lg">
                    {LL0().cashbook.cashClosed()}
                  </b>
                </div>
              </th>
              <th className="relative pb-0">
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 box-border">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center sm:text-xs mq1440:text-lg">
                    {LL0().cashbook.seeDetails()}
                  </b>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {cashbookHistoryList().length > 0 && cashbookHistoryList().map((cashbookHistoryItem: any, index: number) =>
              <tr key={index} className={bgColorTableRow(index, cashbookHistoryList().length)}>
                <td className="relative pr-0" colSpan={2}>
                  <div
                    className="box-border w-full h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3.5 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000 border-b-[1px]">
                    <div
                      className="relative text-smi leading-[16px] font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {cashbookHistoryItem.id}
                    </div>
                  </div>
                </td>
                <td className="relative pr-0" colSpan={3}>
                  <div
                    className="box-border w-full h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3.5 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000 border-b-[1px]">
                    <div
                      className="relative text-smi leading-[16px] font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {formatDate(cashbookHistoryItem.firstTransactionTime, `${LL2().dates.dateFormat()} | ${LL2().dates.timeFormat()}`)} - {formatDate(cashbookHistoryItem.lastTransactionTime, LL2().dates.timeFormat())}
                    </div>
                  </div>
                </td>
                <td className="relative pr-0" colSpan={2}>
                  <div
                    className="box-border w-full h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3.5 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000 border-b-[1px]">
                    <div
                      className="relative text-smi leading-[16px] font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {LL3().format.currency(cashbookHistoryItem.startBalance)}
                    </div>
                  </div>
                </td>
                <td className="relative pr-0" colSpan={2}>
                  <div
                    className="box-border w-full h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3.5 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000 border-b-[1px]">
                    <div
                      className="relative text-smi leading-[16px] font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {LL3().format.currency(cashbookHistoryItem.closeBalance)}
                    </div>
                  </div>
                </td>
                <td className="relative">
                  <div
                    className="box-border w-full h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3.5 px-0 border-b-[1px] border-solid border-black-solid-black-1000-000000"
                    onClick={() => VIEW__HISTORY_DETAILS.onClick(cashbookHistoryItem.id)}
                  >
                    <div
                      className="relative text-smi [text-decoration:underline] font-mulish text-blue-solid-blue-400-0091ff text-center mq1440:text-mini">
                      {LL0().cashbook.enterHere()}
                    </div>
                  </div>
                </td>
              </tr>
            )}

          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CashbookHistoryPlugin;
