import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { getDeviceId } from '@/shared/getDeviceId'
import { formatCurrency } from "@/shared/utils.ts";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";
import { getZERSTELLUNG } from "./dsfinv-convert";

export function BonPosFactory(order: Order, item: OrderItem, isCancelled: boolean, eod: Eod) {
  return build();

  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      POS_ZEILE: POS_ZEILE(),
      GUTSCHEIN_NR: GUTSCHEIN_NR(),
      ARTIKELTEXT: ARTIKELTEXT(),
      POS_TERMINAL_ID: POS_TERMINAL_ID(),
      GV_TYP: GV_TYP(),
      GV_NAME: GV_NAME(),
      INHAUS: INHAUS(),
      P_STORNO: P_STORNO(),
      AGENTUR_ID: AGENTUR_ID(),
      ART_NR: ART_NR(),
      GTIN: GTIN(),
      WARENGR_ID: WARENGR_ID(),
      WARENGR: WARENGR(),
      MENGE: MENGE(),
      FAKTOR: FAKTOR(),
      EINHEIT: EINHEIT(),
      STK_BR: STK_BR()
    }
  }

  function Z_KASSE_ID() {
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // if (!eod?.date) return '';
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function POS_ZEILE() {
    if (isCancelled) {
      return order.items.indexOf(item) + 1;
    } else {
      return order.cancellationItems!.indexOf(item) + 1;
    }
  }

  function GUTSCHEIN_NR() {
    //fixme: implement
    return '';
  }

  function ARTIKELTEXT() {
    return item.name;
  }

  function POS_TERMINAL_ID() {
    return getDeviceId();
  }

  function GV_TYP() {
    //fixme: check again
    return 'Umsatz';
  }

  function GV_NAME() {
    //todo: research GV_NAME
    return '';
  }

  function INHAUS() {
    return !item.vTakeAway;
  }

  function P_STORNO() {
    return isCancelled;
  }

  function AGENTUR_ID() {
    return 0;
  }

  function ART_NR() {
    return item.id;
  }

  function GTIN() {
    return ''
  }

  function WARENGR_ID() {
    //Warengruppen-ID
    //fixme: get name of category
    return item.categories?.[0];
  }

  function WARENGR() {
    //Bezeichnung Warengruppe
    //fixme: check
    return item.categories?.[0];
  }

  function MENGE() {
    return item.quantity;
  }

  function FAKTOR() {
    //Faktor, z. B. Gebindegrößen
    return '';
  }

  function EINHEIT() {
    //Maßeinheit, z. B. kg, Liter oder Stück
    return '';
  }

  function STK_BR() {
    //Preis pro Einheit inkl. USt
    return formatCurrency(item.price);
  }
}