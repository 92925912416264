import type { RxDatabase, RxCollection } from "rxdb"
import { DeviceSetting, DEVICE_SETTING_COLLECTION_NAME, deviceSettingSchema } from "./DeviceSetting"
import { createCollection } from "./utils/migration"


export const createDeviceSettingCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<DeviceSetting>> => {
  return await createCollection({
    database,
    collection: DeviceSetting,
    collectionName: DEVICE_SETTING_COLLECTION_NAME,
    version: deviceSettingSchema.version,
    schema: deviceSettingSchema,
  })
}
