import type { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { MENU_COLLECTION_NAME } from './Menu'
import { createCollection } from "@/data/utils/migration.ts";

export const MODIFIER_COLLECTION_NAME = 'modifiers'

export const OPTION_DISPLAY_TYPES = {
  onlyOne: 'Only One',
  multiple: 'Multiple',
  quantity: 'Quantity',
  select: 'Select',
} as const

export enum ModifierType {
  VARIANT = 'variant',
  MODIFIER = 'modifier'
}

export const VERSION = 18

const modifierSchema = {
  title: 'modifier schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    products: {
      type: "array",
      items: {
        type: "string"
      }
    },
    selectOne: { type: 'boolean' },
    mandatory: { type: 'boolean' },
    groupPrinter: { type: 'string' },
    items: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          _id: { type: 'string', maxLength: 24 },
          name: { type: 'string', maxLength: 50 },
          tax: { type: 'number' },
          price: { type: 'number' },
          type: { type: 'string'},
          position: { type: 'number' },
          isEnabled: { type: 'boolean' },
          typeQuantity_itemCount: { type: 'number' },
          typeQuantity_selectLimit: { type: 'number' },
          typeSelect_group: { type: 'string' },
          menuPrices: {
            type: "array",
            items: {
              type: "object",
              properties: {
                menu: { type: "string" },
                value: { type: "integer" },
              }
            }
          },
          menuTaxes: {
            type: "array",
            items: {
              type: "object",
              properties: {
                menu: {type: "string"},
                value: { type: "integer" },
              }
            }
          },
        },
        required: ['_id'],
      },
    },
    itemGroups: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          _id: { type: 'string', maxLength: 24 },
          name: { type: 'string', maxLength: 24 },
        },
        required: ['_id'],
      },
    },
    position: { type: 'number' },
    type: { type: 'string' },
    useType: { type: 'string' },
    minSelected: { type: 'number' },
    freeSelected: { type: 'number' },
    menus: { type: 'array', ref: MENU_COLLECTION_NAME, items: { type: 'string' } },
  },
  indexes: [],
  required: ['_id'],
} as const satisfies RxJsonSchema<Record<string, unknown>>

export interface Modifier extends ExtractDocumentTypeFromTypedRxJsonSchema<typeof modifierSchema> {
  type?: keyof typeof OPTION_DISPLAY_TYPES,
  useType?: ModifierType,
}
export type ModifierItem = NonNullable<Modifier['items']>[number]
export type ModifierItemGroup = NonNullable<Modifier['itemGroups']>[number]
export type ModifierCollection = RxCollection<Modifier>

export const ModifierCollection: ModifierCollection = {} as ModifierCollection
export const Modifier = ModifierCollection

//@ts-ignore
window.Modifier = Modifier

export const createModifierCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Modifier,
    collectionName: MODIFIER_COLLECTION_NAME,
    version: VERSION,
    schema: modifierSchema,
    extraStrategy: {
      17: doc => {
        if (doc.product) doc.products = [doc.product]
        return doc
      }
    }
  });
}
