import type { CustomerInfo } from "@payment/PaymentRedBillInfoPopup.tsx";
import _, { omit } from "lodash";
import { toast } from "react-toastify";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  createNewCustomer,
  customers,
  getAllCustomer,
  initCustomer,
  setCustomers
} from "@/react/CustomerInfoView/CustomerInfoView.tsx";
import type { Customer } from "@/data/Customer.ts";

export async function updateCustomer() {
  const allCustomer = await getAllCustomer()
  if (allCustomer) {
    setCustomers(allCustomer)
  }
}

export const findCustomerByCompanyName = (name?: string) => {
  if (!name) return;
  return customers().find(c => c?.company === name)
}

export async function addNewCustomerInfo(info: CustomerInfo) {
  const customerRaw = {
    address: info.street,
    ...omit(info, ['street'])
  }

  let id: string

  const data = findCustomerByCompanyName(info?.company)

  if (!data) {
    id = (await createNewCustomer({
      ...initCustomer(),
      phoneNumber: customerRaw.phone,
      addresses: [{ street: customerRaw.address, zipcode: customerRaw.zipCode, home: '', city: customerRaw.city}],
      ...omit(customerRaw, ['address', 'zipCode', 'phone', 'city'])
    }))._id

    await updateCustomer()
    toast.success(`${LL0().customer.addNewCustomer()}`, { autoClose: 600 })
  } else {
    id = data._id
    const updateProps = getUpdatedProps(info, data);
    if (updateProps && updateProps.length > 0) {
      let dataUpdate: Partial<Customer> = {};
      if (updateProps.includes('street') || updateProps.includes('zipcode') || updateProps.includes('city')) {
        _.assign(dataUpdate, {addresses: data.addresses});
      }


      updateProps.forEach(prop => {
        if (prop === 'phoneNumber') {
          _.assign(dataUpdate, {phoneNumber: info.phone});
        } else if (prop === 'street') {
          _.assign(dataUpdate?.addresses?.[0], {street: info.street})
        } else if (prop === 'zipcode') {
          _.assign(dataUpdate?.addresses?.[0], {zipcode: info.zipCode});
        } else if (prop === 'city') {
          _.assign(dataUpdate?.addresses?.[0], {city: info.city});
        } else {
          dataUpdate[prop] = info[prop] || '';
        }
      });
      await data?.doc?.incrementalPatch(dataUpdate)
      await updateCustomer()
      toast.success(`${LL0().customer.updateInfo()}`, { autoClose: 600 })
    }
  }

  return {
    rawInfo: customerRaw,
    customerId: id
  }
}

function getUpdatedProps(infoA: CustomerInfo, infoB: Customer) {
  //infoA is info get from user's input, info B is from exist data

  let properties = ['name', 'email', 'taxNo', 'ustId', 'cardNo', 'note'];
  let updateProps = []
  properties.forEach(prop => {
    if (infoA[prop] && infoA[prop] !== infoB[prop]) {
      updateProps.push(prop)
    }
  });
  if (infoA.phone && infoA.phone !== infoB.phoneNumber) {
    updateProps.push('phoneNumber')
  }

  const existStreet = infoB?.addresses?.map(a => a.street)
  const existZipcode = infoB?.addresses?.map(a => a.zipcode)
  const existCity = infoB?.addresses?.map(a => a.city)



  if (existStreet && !existStreet?.includes(infoA.street)) {
    updateProps.push('street')
  }

  if (existZipcode && !existZipcode?.includes(infoA.zipCode)) {
    updateProps.push('zipcode')
  }

  if (existCity && !existCity?.includes(infoA.city)) {
    updateProps.push('city')
  }

  return updateProps
}



export const getCityName = (addressName: string) => {
  const parts = addressName.split(',').map(part => part.trim());

  if (parts.length > 1) {
    return parts[parts.length - 2];
  }

  return '';
};
