import { Switch } from '@mui/material'
import type { FunctionComponent } from 'react'

import {
  isDeliveryOrderFeatureEnabled,
  isMoveTableFeatureEnabled,
  isMultiplePaymentFeatureEnabled,
  isPendingOrderFeatureEnabled,
  isSplitBillFeatureEnabled,
  PosFeatures,
  toggleFeature,
} from '@/srm/features.logic'

export const SrmFeatures: FunctionComponent = () => (
  <div className="grid gap-2 items-center place-items-start">
    <label className="flex items-center gap-2">
      <Switch
        checked={isPendingOrderFeatureEnabled()}
        onChange={(_, checked) => toggleFeature(PosFeatures.PendingOrder, checked)}
      />
      <p>Pending Order</p>
    </label>

    <label className="flex items-center gap-2">
      <Switch
        checked={isDeliveryOrderFeatureEnabled()}
        onChange={(_, checked) => toggleFeature(PosFeatures.DeliveryOrder, checked)}
      />
      <p>Delivery Order</p>
    </label>

    <label className="flex items-center gap-2">
      <Switch
        checked={isSplitBillFeatureEnabled()}
        onChange={(_, checked) => toggleFeature(PosFeatures.SplitBill, checked)}
      />
      <p>Split Bill</p>
    </label>

    <label className="flex items-center gap-2">
      <Switch
        checked={isMoveTableFeatureEnabled()}
        onChange={(_, checked) => toggleFeature(PosFeatures.MoveTable, checked)}
      />
      <p>Move Table</p>
    </label>

    <label className="flex items-center gap-2">
      <Switch
        checked={isMultiplePaymentFeatureEnabled()}
        onChange={(_, checked) => toggleFeature(PosFeatures.MultiplePayment, checked)}
      />
      <p>Multiple Payment</p>
    </label>
  </div>
)
