import { cashValue, coinValue, setCashValue, setCoinValue } from "@/react/CashbookView/CashbookView";
import { makeRipple } from "@/react/core/ripple-utils";
import { type CSSProperties, type FunctionComponent, useCallback, useMemo } from "react";
import _ from "lodash"

export type OrderKeyboard1Type = {
  className?: string;

  /** Style props */
  styles: {
    orderKeyboardMargin?: CSSProperties["margin"];
    orderKeyboardPosition?: CSSProperties["position"];
    orderKeyboardRight?: CSSProperties["right"];
    orderKeyboardBottom?: CSSProperties["bottom"];
    orderKeyboardHeight?: CSSProperties["height"];
    orderKeyboardFlexShrink?: CSSProperties["flexShrink"];
    orderKeyboardZIndex?: CSSProperties["zIndex"];
  }
  isSelected: { coin: number | null, cash: number | null };
  setIsSelected: React.Dispatch<React.SetStateAction<{
    coin: number | null;
    cash: number | null;
  }>>
};

const OrderKeyboard1: FunctionComponent<OrderKeyboard1Type> = ({
  className,
  styles: {
    orderKeyboardMargin,
    orderKeyboardPosition,
    orderKeyboardRight,
    orderKeyboardBottom,
    orderKeyboardHeight,
    orderKeyboardFlexShrink,
    orderKeyboardZIndex,
  },
  isSelected,
  setIsSelected
}) => {
  const orderKeyboardStyle: CSSProperties = useMemo(() => {
    return {
      margin: orderKeyboardMargin,
      position: orderKeyboardPosition,
      right: orderKeyboardRight,
      bottom: orderKeyboardBottom,
      height: orderKeyboardHeight,
    };
  }, [
    orderKeyboardMargin,
    orderKeyboardPosition,
    orderKeyboardRight,
    orderKeyboardBottom,
    orderKeyboardHeight,
  ]);

  const value = useMemo(() => {
    if (isSelected?.coin !== null) {
      return coinValue()[isSelected?.coin].quantity
    }

    if (isSelected?.cash !== null) {
      return cashValue()[isSelected?.cash].quantity
    }

    return 0
  }, [isSelected?.coin, isSelected?.cash, coinValue(), cashValue()])

  const handleClickNumber = useCallback((e: any) => {
    if (isSelected?.coin === null && isSelected?.cash === null) return
    if (isSelected?.coin !== null) {
      const newArray = _.cloneDeep(coinValue());
      newArray[isSelected?.coin].quantity = coinValue()[isSelected?.coin].quantity === 0 ? e.target.textContent : Number(coinValue()[isSelected?.coin].quantity.toString() + e.target.textContent)
      setCoinValue(newArray)
      return
    }

    if (isSelected?.cash !== null) {
      const newArray = _.cloneDeep(cashValue());
      newArray[isSelected?.cash].quantity = cashValue()[isSelected?.cash].quantity === 0 ? e.target.textContent : Number(cashValue()[isSelected?.cash].quantity.toString() + e.target.textContent)
      setCashValue(newArray)
      return
    }
  }, [value, isSelected?.coin, isSelected?.cash])

  const handleRemoveNumber = useCallback(() => {
    if (value === 0) return

    if (isSelected?.coin !== null) {
      const newArray = [...coinValue()];
      newArray[isSelected?.coin].quantity = newArray[isSelected?.coin].quantity.toString().length === 1 ? 0 : Number(newArray[isSelected?.coin].quantity.toString().slice(0, -1))
      setCoinValue(newArray)
      return
    }

    if (isSelected?.cash !== null) {
      const newArray = [...cashValue()];
      newArray[isSelected?.cash].quantity = newArray[isSelected?.cash].quantity.toString().length === 1 ? 0 : Number(newArray[isSelected?.cash].quantity.toString().slice(0, -1))
      setCashValue(newArray)
      return
    }

  }, [value, isSelected?.coin, isSelected?.cash])

  const handleClickEnter = useCallback(() => {
    if (isSelected?.cash === null && isSelected?.coin === null) return

    if (isSelected?.cash !== null && isSelected?.cash < 5) {
      setIsSelected(prev => ({ ...prev, cash: prev.cash !== null ? prev.cash + 1 : null }))
      return
    }

    if (isSelected?.coin !== null && isSelected?.coin < 5) {
      setIsSelected(prev => ({ ...prev, coin: prev.coin !== null ? prev.coin + 1 : null }))
      return
    }

    setIsSelected({ coin: null, cash: null })
  }, [isSelected?.coin, isSelected?.cash, value])

  console.log("value", value);
  return (
    <div
      className={`w-[232px] flex flex-col items-end justify-start gap-2 text-center text-xl text-black-solid-black-860-1c1c1c font-mulish ${className}`}
      style={orderKeyboardStyle}
    >
      <div className="self-stretch rounded-10xs bg-gray-solid-gray-150-eeeeee border-gray-solid-gray-600-979797 border-[1px] border-solid flex flex-row items-center justify-end py-1 px-2">
        <div className="flex-1 flex flex-row items-center justify-between">
          <b className="relative">{value}</b>
          <div ref={makeRipple} onClick={handleRemoveNumber}>
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/icondelete-key-icon@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-1 text-lg text-black-solid-black-900-1e1e23">
        <div className="self-stretch flex flex-row items-center justify-center gap-[5px]">
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">7</b>
          </div>
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">8</b>
          </div>
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">9</b>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[5px]">
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">4</b>
          </div>
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">5</b>
          </div>
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">6</b>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[5px]">
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">1</b>
          </div>
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">2</b>
          </div>
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">3</b>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[5px]">
          <div className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickNumber}
            ref={makeRipple}
          >
            <b className="relative leading-[17px]">0</b>
          </div>
          <div className="w-[153px] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-[38px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-[9px]"
            onClick={handleClickEnter}
            ref={makeRipple}
          >
            <img
              className="w-[41.6px] relative h-[17.7px]"
              alt=""
              src="/group.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderKeyboard1;