import type {FunctionComponent} from "react";
import {LL0} from "@/react/core/I18nService.tsx";

export type PrinterTemplatePopupType = {
  className?: string;
  onClose?: () => void;
  title?: string;
  content?: string
};

const PrinterTemplatePopup: FunctionComponent<PrinterTemplatePopupType> = ({
  className = "",
  onClose,
  title,
  content
}) => {
  return (
    <div className="relative bg-basic-color-white w-[509px] overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] max-w-full max-h-full text-left text-base text-royalblue-200 font-mulish">
      <img
        className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[0]"
        alt=""
        src="/close-popup-icon--grey@2x.png"
        onClick={onClose}
      />
      <b className="self-stretch relative z-[1]">
        {title}:
      </b>
      <div className="text-[#1d1d26] self-stretch relative text-gray-400 z-[2]">
        {content}
      </div>
      <div className="self-stretch flex flex-row items-center justify-end z-[3] text-center text-sm text-basic-color-white">
        <div
          className="rounded-md bg-royalblue-100 overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 cursor-pointer"
          onClick={onClose}
        >
          <b className="relative uppercase">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default PrinterTemplatePopup;