import type { Eod } from "@/data/Eod.ts";
import {masterDeviceSetting} from "@/data/DeviceSettingHub.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";

export function Stamm_TerminalsFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      TERMINAL_ID: TERMINAL_ID(),
      TERMINAL_BRAND: TERMINAL_BRAND(),
      TERMINAL_MODELL: TERMINAL_MODELL(),
      TERMINAL_SERIENNR: TERMINAL_SERIENNR(),
      TERMINAL_SW_BRAND: TERMINAL_SW_BRAND(),
      TERMINAL_SW_VERSION: TERMINAL_SW_VERSION()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return eod.z;
  }

  //todo: đưa thông tin vào deviceSetting
  //làm 1 nơi quản lý cho lựa chọn thông tin , lấy template sẵn từ bên surreal db
  function TERMINAL_ID() {
    // hint: "ID des Terminals",
    return '';
  }

  function TERMINAL_BRAND() {
    // hint: "Marke der Terminals",
    return '';
  }

  function TERMINAL_MODELL() {
    // hint: "Modellbezeichnung des Terminals"
    return '';
  }

  function TERMINAL_SERIENNR() {
    // hint: "Seriennummer des Terminals"
    return '';
  }

  function TERMINAL_SW_BRAND() {
    // hint: "Markenbezeichnung der Software"
    return '';
  }

  function TERMINAL_SW_VERSION() {
    // hint: "Version der Software"
    return '';
  }
}
