import { memo } from 'react'
import { type OrderItem, OrderStatus } from '@/pos/OrderType.ts'
import { order0 } from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type OrderHistoryListItemType = {
  orderItem: OrderItem;
  _id: string;
};

const OrderHistoryListItem = memo(({orderItem, _id}: OrderHistoryListItemType) => {
  return (
    <div
      className="self-stretch bg-gray-opacity-gray-30-b3b3b3 flex flex-row items-center justify-start py-1.5 px-1 gap-[4px] text-left text-xs text-black-solid-black-900-1e1e23 font-mulish-button-name-bold">
      <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
        <b className="self-stretch relative sm:text-2xs">{orderItem.name}</b>
        {orderItem.modifiers.map((modifier,index)=> (
          <b key={index} className="self-stretch relative text-gray-solid-gray-900-616161">
            {modifier.name}
          </b>
        ))}
      </div>
      <b className="relative text-red-solid-red-660-8f181d text-center sm:text-2xs">
      x{orderItem.quantity}
      </b>
      <div className="relative text-smi text-right inline-block min-w-[58px] sm:text-xs sm:min-w-[54px]">
        {order0()?.status === OrderStatus.CANCELLATION_REFERENCE && '-'}{LL3().format.currency(orderItem.price)}
      </div>
    </div>
  )
});

export default OrderHistoryListItem;