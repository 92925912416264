import {
  onBack,
  onPrintStaffReport,
  printSaleReport,
  selectedUserId,
  staffReportData
} from '@/react/StaffReportView/StaffReportView'
import { makeRipple } from '@/react/core/ripple-utils'
import React, { type FunctionComponent, useCallback, useState } from 'react'
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import PortalPopup from "@staff-report/PortalPopup.tsx";
import PrintStaffReportConfirmationPopup from "@staff-report/PrintStaffReportConfirmPopUp.tsx";
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";

import FilterPrintStaffReportSaleSummaryPopUp from "@staff-report/FilterPrintStaffReportSaleSummaryPopUp.tsx";

const StaffReportBottomBar: FunctionComponent = () => {
  const [isPrintConfirmationPopupOpen, setIsPrintConfirmationPopupOpen] =
    useState(false);
  const openPrintConfirmationPopup = useCallback(() => {
    setIsPrintConfirmationPopupOpen(true);
  }, []);
  const [isPopupPrintSummary, setTogglePopupPrintSummary] = useState(false)

  const closePrintConfirmationPopup = useCallback(() => {
    setIsPrintConfirmationPopupOpen(false);
  }, []);

  return (
    <div className="self-stretch bg-dimgray flex flex-row items-start justify-between py-2 px-4 box-border w-auto h-auto gap-[338px] text-center text-sm text-white-solid-white-100-ffffff font-mulish hover:bg-dimgray hover:flex hover:self-stretch hover:w-auto hover:h-auto hover:flex-row hover:gap-[338px] hover:items-start hover:justify-between hover:py-2 hover:px-4 hover:box-border">
      <button
        className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
        ref={makeRipple}
        onClick={onBack}
        type="button"
      >
        <img
          className="relative w-[30px] h-[30px] object-cover"
          alt=""
          src="/iconback-icon@2x.png"
        />
        <b className="relative leading-[17px]">{LL0().onlineOrder.back()}</b>
      </button>
      <div className='flex gap-2 flex-row'>

        {isQuebecSrmEnabled() && <button
          className="rounded-81xl [background:linear-gradient(180deg,_#ff8366,_#e44e2c)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-black-solid-black-900-1e1e23"
          ref={makeRipple}
          type="button"
          // onClick={printSaleReport}
          onClick={() => setTogglePopupPrintSummary(!isPopupPrintSummary)}
        >
          <img
            className="relative w-[30px] h-[30px] object-cover"
            alt="Print Z-Report"
            src="/iconprint-icon@2x.png"
          />
          <b className="relative leading-[17px]">{LL0().protocol.printSaleSummary()}</b>
        </button>}
        <button
          className="rounded-81xl [background:linear-gradient(180deg,_#ff8366,_#e44e2c)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-black-solid-black-900-1e1e23"
          ref={makeRipple}
          type="button"
          onClick={ async() => {
            const userData = _.flatMap(_.values(staffReportData()?.timeClock?.timeClocksByDate)).filter(i => !i.clockOutTime)
            if (!userData || userData.length > 0) {
              openPrintConfirmationPopup()
            } else {
             await onPrintStaffReport()
            }
          }}
        >
          <img
            className="relative w-[30px] h-[30px] object-cover"
            alt="Print Z-Report"
            src="/iconprint-icon@2x.png"
          />
          <b className="relative leading-[17px]">{LL0().report.printReport()}</b>
        </button>
      </div>
      {isPrintConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePrintConfirmationPopup}
        >
          <PrintStaffReportConfirmationPopup onClose={closePrintConfirmationPopup}
                                             onComplete = {onPrintStaffReport}/>
        </PortalPopup>
      )}
      {isPopupPrintSummary && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setTogglePopupPrintSummary(false)}
        >
          <FilterPrintStaffReportSaleSummaryPopUp onClose={() => setTogglePopupPrintSummary(false)} />
        </PortalPopup>
      )}
    </div>
  )
}

export default StaffReportBottomBar
