// @ts-ignore
import { RxCollection, RxDatabase, RxDocument, } from "rxdb";
import { MarketPlaceProvider, type VTaxComponents } from "@/pos/OrderType.ts";
import { createCollection } from "@/data/utils/migration.ts";

export const RESERVATION_COLLECTION_NAME = 'reservations'

export const VERSION = 3
const schema = {
  title: 'Reservation schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    externalStoreId: {type: 'string'},
    externalId: {type: 'string'},
    noOfGuests: {type: 'number'},
    date: {type: 'string'},
    time: {type: 'string'},
    customer: {
      type: 'object',
      properties: {
        name: {type: 'string'},
        email: {type: 'string'},
        phone: {type: 'string'}
      }
    },
    note: {type: 'string'},
    status: {type: 'string'},
    provider: {type: 'string'},
  },
  indexes: []
} as const;

export type ReservationCustomer = {
  name: string,
  email: string,
  phone: string
}

export enum ReservationStatus {
  Pending = "pending",
  Declined = "declined",
  Completed = "completed"
}

export type Reservation = {
  _id: string,
  externalStoreId: string,
  externalId: string,
  provider: MarketPlaceProvider,
  payments?: Array<any>,
  status: "pending" | "declined" | "completed",
  discount?: number,
  discountDetails?: Array<any>,
  donation?: number,
  isPaid?: boolean,
  date: number,
  note: string,
  customer: ReservationCustomer,
  items?: Array<any>,
  taxTotal?: number,
  providerTotal?: number,
  vExtraTax?: number,
  vTotal?: number,
  vTaxComponents?: VTaxComponents,
  staff?: string,
  noOfGuests: number,
  isFake?: boolean,
  isMultipleStaffs?: boolean
}

export type ReservationDocument = RxDocument<Reservation>
export type ReservationCollection = RxCollection<Reservation>

export const ReservationCollection: ReservationCollection = {} as ReservationCollection;
export const createReservationCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: ReservationCollection,
    collectionName: RESERVATION_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}
