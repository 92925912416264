import debug from 'debug'
import { useLayoutEffect, useState, type FC, type PropsWithChildren } from 'react'

import { useI18nContext } from '@/i18n/i18n-react'
import type { Locales, TranslationFunctions } from '@/i18n/i18n-types'
import { signal, useAsyncEffect, useEffectOn } from '@/react/core/reactive'

import { wsSendLocale } from '../websocket/ws-send'
import { secondDisplayLock } from '../websocket/ws-state'

const log = debug('data:locate')

//-> holder
export const [LL0, setLL0] = signal<TranslationFunctions>()
export const [locale, setLocale] = signal<Locales | undefined>()

export function getLL(): () => TranslationFunctions {
  return LL0
}

const I18nService: FC<PropsWithChildren> = props => {
  const i18n = useI18nContext()
  const [shouldRender, setShouldRender] = useState<boolean>(false)
  useLayoutEffect(() => {
    log('🌐 Locate changed', i18n.locale)
    setLL0(() => i18n.LL)
    setShouldRender(true)
  }, [i18n.locale])

  // Allow signal to change locale
  useEffectOn([locale], () => {
    const l = locale()
    if (l) i18n.setLocale(l)
  })

  useAsyncEffect(async () => {
    await secondDisplayLock.acquireAsync()
    if (i18n.locale) wsSendLocale(i18n.locale)
  }, [i18n.locale])

  return shouldRender ? <>{props.children}</> : null
}

export default I18nService

Object.assign(window, { LL0 }) // Expose for debugging
