import UserSettingPlugin from '@user-setting/UserSettingPlugin.tsx'
import _ from 'lodash'
import { memo, useEffect, type ChangeEvent } from 'react'
import uuid from 'time-uuid'

import Avatar1 from '@/assets/avatar/avatar_1.png'
import Avatar2 from '@/assets/avatar/avatar_2.png'
import Avatar3 from '@/assets/avatar/avatar_3.png'
import Avatar4 from '@/assets/avatar/avatar_4.png'
import Avatar5 from '@/assets/avatar/avatar_5.png'
import Avatar6 from '@/assets/avatar/avatar_6.png'
import Avatar7 from '@/assets/avatar/avatar_7.png'
import Avatar8 from '@/assets/avatar/avatar_8.png'
import Avatar9 from '@/assets/avatar/avatar_9.png'
import Avatar10 from '@/assets/avatar/avatar_10.png'
import Avatar11 from '@/assets/avatar/avatar_11.png'
import Avatar12 from '@/assets/avatar/avatar_12.png'
import Avatar13 from '@/assets/avatar/avatar_13.png'
import Avatar14 from '@/assets/avatar/avatar_14.png'
import Avatar15 from '@/assets/avatar/avatar_15.png'
import Avatar16 from '@/assets/avatar/avatar_16.png'
import Avatar17 from '@/assets/avatar/avatar_17.png'
import Avatar18 from '@/assets/avatar/avatar_18.png'
import { dataLock } from '@/data/DataUtils.ts'
import { User, UserRole } from '@/data/User.ts'
import { makeUsersAvailable, setUserV } from '@/data/UserHub.ts'
import { onExit, PosScreen, router } from '@/pos/PosRouter'
import { LL0 } from '@/react/core/I18nService'
import { computed, signal, useAsyncEffect } from '@/react/core/reactive.ts'
import { srmUserLogic } from '@/srm/user.logic'

import msgBox, { Buttons, Icons } from '../SystemService/msgBox'
import { isQuebecSrmEnabled, mainScreen } from "@/data/PosSettingsSignal.ts";
import { loginUser, users0 } from "@/data/UserSignal.ts";

export const [selectedUserId, setSelectedUserId] = signal<string | undefined>()
export const selectedUser = computed(() => users0().find(u => u._id === selectedUserId()))
export const [loading, setLoading] = signal(false)

export const AVATAR_LIST: Array<string> = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar18,
]

export const createUser = async () => {
  const newUser = await User.insert({
    _id: uuid(),
    avatar: Avatar1,
    name: 'NewUser',
    passcode: '',
    role: UserRole.STAFF,
  })
  setUserV(v => v + 1)
  setSelectedUserId(newUser._id)
}
export const removeCurrentUser = async () => {
  const u = _.clone(selectedUser())
  setSelectedUserId(undefined)
  setUserV(v => v + 1)
  setLoading(true)
  try {
    if (u?._id === loginUser()?._id) throw new Error('You cannot delete yourself!')
    if (isQuebecSrmEnabled() && u?.name) await srmUserLogic.deleteUser(u?.name)
    await User.find({ selector: { _id: u?._id } }).remove()
  } catch (e) {
    if (e instanceof Error) {
      setSelectedUserId(u?._id)
      setUserV(v => v + 1)
      msgBox.show('Error', e.message, Buttons.Dismiss, Icons.Error)
    } else {
      console.error('🪲 Unknown error when deleting user', u, e)
    }
  } finally {
    setLoading(false)
  }
}
export const getInfoFor = (field: keyof User) => {
  const s = LL0().settings
  let title = _.get(s, field)?.()
  let content = _.get(s, `${field}Explain`)?.()

  if (field === 'allowTableTakeOver') {
    title = s.changeTableWaiter()
    content = s.changeTableWaiterExplain()
  }

  if (!content || !title) throw new Error(`Missing translation for "${field}"`)
  return [title, content] as const
}
// Lead me to this page
export const getPopupScreenName = (field: keyof User) => {
  switch (field) {
    case 'viewOrderHistory':
      return PosScreen.ORDER_HISTORY
    case 'viewOtherStaffReport':
      return PosScreen.STAFF_REPORT
    case 'viewOwnReport':
      return PosScreen.STAFF_REPORT
    case 'accessZReport':
      return PosScreen.EOD
    case 'viewMonthlyReport':
      return PosScreen.MONTHLY_REPORT
    case 'viewCustomerInfo':
      return PosScreen.CUSTOMER_INFO
    case 'manageInventory':
      return PosScreen.INVENTORY
    case 'editThePriceOfAMenuItem':
      return PosScreen.EDIT_MENU
    case 'editMenuLayout':
      return PosScreen.EDIT_MENU
    case 'editTablePlan':
      return PosScreen.EDIT_FLOOR_PLAN
    case 'editHappyHours':
      return PosScreen.HAPPY_HOUR
    case 'allowTableTakeOver':
      return PosScreen.FLOOR_PLAN
    case 'viewOrderDetail':
      return PosScreen.FLOOR_PLAN
    default:
      return null
  }
}

export const onSaveData = async () => {
  const u = selectedUser()
  if (u) await srmUserLogic.createUser(u)
}

export const goBack = () => (router.screen = mainScreen())

export const userSettingsSwitchProps = (prop: keyof User) => ({
  checked: Boolean(selectedUser()?.[prop] ?? false),
  onChange: (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => _.assign(selectedUser(), { [prop]: checked }),
})

const UserSettingView = () => {
  makeUsersAvailable()
  useAsyncEffect(async () => {
    await dataLock.acquireAsync()
  })

  // Select the first users in the list by default
  useEffect(() => {
    setSelectedUserId(users0()?.[0]?._id)
  }, [])

  return <UserSettingPlugin />
}

export default memo(UserSettingView)
