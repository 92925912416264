import { FunctionComponent } from "react";
import { TextField } from "@mui/material";
import {LL0} from "@/react/core/I18nService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, {inputController0} from "@/react/core/Input.tsx";
import {
  handleFilterOrderHistory,
  setStaffCustomerFilter,
  staffCustomerFilter
} from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import {useSignal} from "@/react/core/reactive.ts";

type StaffCustomerPopupType = {
  onClose?: () => void;
};

const StaffCustomerPopup: FunctionComponent<StaffCustomerPopupType> = ({
  onClose,
}) => {

  const [staffCustomer, setStaffCustomer] = useSignal<string>()

  return (
    <div className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[320px] h-full max-w-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish-button-name-bold">
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] z-[0]">
        <b className="self-stretch relative">
          {LL0().orderHistory.writeDownYourStaffNameOrYourCustomerInformation()}:
        </b>
        {/*<TextField*/}
        {/*  className="[border:none] bg-[transparent] self-stretch"*/}
        {/*  color="primary"*/}
        {/*  variant="outlined"*/}
        {/*/>*/}
        <Input
          className="w-full !h-[48px]"
          value={staffCustomer()}
          onChange={value => setStaffCustomer(value)}
          refInputController={i => i.setFocus(true)}
        />
      </div>
      {/*<div className="self-stretch flex flex-row items-center justify-end z-[1] text-center text-sm text-white-solid-white-100-ffffff">*/}
      {/*  <div className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8">*/}
      {/*    <b className="relative">{LL0().ui.ok().toUpperCase()}</b>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[2]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/order-history-close-popup-icon--grey@2x.png"
        />
      </div>
      <TableManualKeyboard
        inputController={inputController0}
        onEnter={() => {
          setStaffCustomerFilter(staffCustomer())
          handleFilterOrderHistory()
          onClose?.()
        }}
      />
    </div>
  );
};

export default StaffCustomerPopup;
