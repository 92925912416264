import { type FunctionComponent } from "react";

export type DateButtonIdenticalType = {
  className?: string;
  date?: string;
  isSelected?: boolean;
  onClick?: any;
};

const DateButtonIdentical: FunctionComponent<DateButtonIdenticalType> = ({
  className = "",
  date,
  isSelected,
  onClick
}) => {
  return (
    <div
      className={`cursor-pointer flex-1 rounded-md bg-white-solid-white-100-ffffff box-border h-9 overflow-hidden flex flex-row items-center justify-center py-1 px-3 relative gap-[3px] min-w-[116px] text-center text-sm text-blue-solid-blue-420-2979ff font-mulish border-[1px] border-solid border-blue-solid-blue-420-2979ff ${className}`}
      onClick={onClick}
    >
      {isSelected ? (
        <div className="w-full !m-[0] absolute h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-solid-blue-420-2979ff box-border flex flex-row items-center justify-center py-1 px-3 z-[1] text-white-solid-white-100-ffffff border-[1px] border-solid border-blue-solid-blue-420-2979ff">
          <b className="relative leading-[15px] uppercase">{date}</b>
        </div>
      ) : <b className="flex-1 relative leading-[15px] uppercase z-[0]">{date}</b>}
    </div>
  );
};

export default DateButtonIdentical;
