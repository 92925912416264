import { Fragment, type FunctionComponent, useCallback, useRef, useState } from 'react'
import CustomerInfoRow from '@customer-info/CustomerInfoRow.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import {
  customers0, filterCustomers,
  isCustomerReachingEnd,
  isValidatingCustomers,
  setCustomersPage, setFilterCustomers,
  setIsValidatingCustomers
} from '@/data/CustomerHub.ts'
import clsx from 'clsx'
import {
  isCurrentCustomer,
  setCustomer0
} from '@/react/CustomerInfoView/CustomerInfoView.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import InfiniteScroll from '@order-history/InfiniteScrollContainer.tsx'
import PortalPopup from './PortalPopup'
import InputPopup from '@/react/core/InputPopup.tsx'
import { useSignal } from '@/react/core/reactive.ts'
import _ from 'lodash'
import InputPopup2 from '@/react/core/InputPopup2.tsx'

enum FilterMode {
  NAME = 'name',
  PHONE_NUMBER = 'phoneNumber',
  ADDRESS = 'address'
}

const CustomerTableWrapper: FunctionComponent = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const onClickScrollToTop = useCallback(
    () => {
      containerRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      })
    },
    []
  )

  const showFilter = Object.entries(filterCustomers()).length > 0
  const [filter, setFilter] =
    useSignal<{ name?: string, phoneNumber?: string, address?: { street?: string, home?: string }, mode: FilterMode | '' }>({
      name: '',
      phoneNumber: '',
      address: { street: '', home: '' },
      mode: ''
    })

  return (
    <>
      <div ref={containerRef}
           className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start z-[0] text-center text-sm text-white-solid-white-100-ffffff font-mulish">
        <div
          className="!h-fit !grid-rows-none self-stretch h-[141px] relative shrink-0 grid grid-rows-[38px] grid-cols-[1fr_3fr_4fr_5fr_4fr_2fr_4fr] [grid-row-gap:0px] [grid-column-gap:0px]"
        >
          <div
            className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-0 relative col-[1/1] row-[1/1] border-r-[1px] border-solid border-white-solid-white-100-ffffff">
            <b
              className="self-stretch flex-1 relative sm:text-smi Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-xs">
              {LL0().customer.id()}
            </b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-0 gap-[2px] relative col-[2/2] row-[1/1] border-r-[1px] border-solid border-white-solid-white-100-ffffff"
            ref={makeRipple}
            onClick={() => setFilter(p => ({ ...p, mode: FilterMode.NAME }))}
          >
            <div className="overflow-hidden flex flex-row items-center justify-center sm:w-[15px] sm:h-[15px]">
              <img
                className="w-4 relative h-4 object-cover"
                alt=""
                src="/iconsearch-icon@2x.png"
              />
            </div>
            <b
              className="h-[18px] relative inline-block sm:text-smi Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-xs">
              {LL0().customer.name()}
            </b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-0 gap-[2px] relative col-[3/3] row-[1/1] border-r-[1px] border-solid border-white-solid-white-100-ffffff Tablet_600:relative Tablet_600:col-[3/3] Tablet_600:row-[1/1]"
            ref={makeRipple}
            onClick={() => setFilter(p => ({ ...p, mode: FilterMode.PHONE_NUMBER }))}
          >
            <div className="overflow-hidden flex flex-row items-center justify-center sm:w-[15px] sm:h-[15px]">
              <img
                className="w-4 relative h-4 object-cover"
                alt=""
                src="/iconsearch-icon1@2x.png"
              />
            </div>
            <b
              className="h-[18px] relative inline-block sm:text-smi Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-xs">
              {LL0().customer.phone()}
            </b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-0 gap-[2px] relative col-[4/4] row-[1/1] border-r-[1px] border-solid border-white-solid-white-100-ffffff"
            ref={makeRipple}
            onClick={() => setFilter(p => ({ ...p, mode: FilterMode.ADDRESS }))}
          >
            <div className="overflow-hidden flex flex-row items-center justify-center sm:w-[15px] sm:h-[15px]">
              <img
                className="w-4 relative h-4 object-cover"
                alt=""
                src="/iconsearch-icon@2x.png"
              />
            </div>
            <b
              className="h-[18px] relative inline-block sm:text-smi Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-xs">
              {LL0().customer.address()}
            </b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-0 relative col-[5/5] row-[1/1] border-r-[1px] border-solid border-white-solid-white-100-ffffff sm:relative sm:col-[5/5] sm:row-[1/1] Tablet_768:relative Tablet_768:col-[5/5] Tablet_768:row-[1/1] Tablet_600:relative Tablet_600:col-[5/5] Tablet_600:row-[1/1] Tablet_600:min-w-[88px] Mobile_480:relative Mobile_480:col-[5/5] Mobile_480:row-[1/1] Mobile_480:min-w-[88px]">
            <b
              className="self-stretch flex-1 relative sm:text-smi Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-xs">
              {LL0().customer.numberOfOrder()}
            </b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-0 relative col-[6/6] row-[1/1] border-r-[1px] border-solid border-white-solid-white-100-ffffff">
            <b
              className="self-stretch w-24 relative inline-block shrink-0 sm:text-smi Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-xs">
              {LL0().customer.spending()}
            </b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-0 relative col-[7/7] row-[1/1]">
            <b
              className="self-stretch flex-1 relative sm:text-smi Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-xs">
              {LL0().customer.notes()}
            </b>
          </div>
          {showFilter && <div
            className="bg-gray-solid-gray-310-d6d7d7 flex flex-row items-center justify-between py-[7px] px-2.5 relative col-[1/8] row-[2/2] text-left text-xs text-gray-solid-gray-750-696969">
            <div className="flex-1 flex flex-row flex-wrap items-center justify-start py-0 px-2.5 gap-[12px]">
              <b className="w-[34px] relative inline-block text-red-solid-red-660-8f181d shrink-0">
                {LL0().reservation.filter()}:
              </b>
              {Object.entries(filterCustomers()).map(([key, value]) => (
                <div
                  key={key}
                  className="rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-1 gap-[5px]">
                  <div className="flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative">{_.get(LL0().customer, key === 'phoneNumber' ? 'phone' : key)?.()}:</div>
                    <div className="relative">{key === "address" ? `${value.home} ${value.street}` : value}</div>
                  </div>
                  <img
                    className="w-4 relative h-4 object-cover"
                    alt=""
                    src="/icondelete-fillter-icon@2x.png"
                    onClick={() => {
                      setFilterCustomers(prev => {
                        const { [key]: _, ...rest } = prev
                        return rest
                      })
                    }}
                  />
                </div>
              ))}
            </div>
            <div
              className="rounded-81xl bg-white-solid-white-230-f3f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border gap-[3px] max-w-[110px] float-right mr-2.5 text-center text-sm text-black-solid-black-900-1e1e23"
              ref={makeRipple}
              onPointerDown={() => setFilterCustomers({})}
            >
              <div className="flex flex-row items-center justify-center">
                <img
                  className="w-[30px] relative h-[30px] object-cover"
                  alt=""
                  src="/iconbin-icon--red@2x.png"
                />
              </div>
              <b className="relative leading-[17px]">{LL0().customer.clear()}</b>
            </div>
          </div>}
          <InfiniteScroll
            data={customers0()}
            increaseSize={() => {
              setIsValidatingCustomers(true)
              // setCustomersPage((prev) => prev + 1)
            }}
            isReachingEnd={isCustomerReachingEnd()}
            isValidating={isValidatingCustomers()}
            isAutoInfinite={true}
            loadingIndicator={`${LL0().ui.loading()}...`}
            endingIndicator={customers0().length !== 0 ? '' : LL0().customer.noCustomerLeft()}
            indicatorWrapper={({ children }) => (
              <div className="text-white text-center text-mulish font-semibold p-2 col-span-full">
                {children}
              </div>
            )}
          >

            {(customer, index) =>
              (<Fragment key={customer?._id}>
                  <div
                    className={clsx('box-border col-span-full absolute inset-0 z-[10] m-0', isCurrentCustomer(customer) && 'border-[2px] border-[#FF3797]')}
                    style={{ gridRow: `${index + (showFilter ? 3 : 2)}/${index + (showFilter ? 3 : 2)}` }}
                    onClick={() => setCustomer0(customer)}
                  />
                  <CustomerInfoRow {...customer} key={customer.id} isEven={index % 2 !== 0} />
                </Fragment>
              )}
          </InfiniteScroll>

        </div>
        <div
          className="!z-10 rounded-full w-[45px] my-0 mx-[!important] absolute bottom-[58px] left-[8px] flex flex-row items-start justify-between z-[2]"
          ref={makeRipple}
          onClick={onClickScrollToTop}
        >
          <img
            className="w-[45px] relative h-[45px] object-cover"
            alt=""
            src="/icongo-to-top-float-icon@2x.png"
          />
        </div>
      </div>
      {filter()?.mode && filter()?.mode !== FilterMode.ADDRESS &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setFilter(prev => ({ mode: '' }))}
        >
          <InputPopup
            label={_.get(LL0().customer, filter().mode === FilterMode.PHONE_NUMBER ? 'phone' : filter().mode)?.()}
            value={_.get(filter(), filter().mode) as string}
            onChange={(value: string) => _.assign(filter(), { [filter().mode]: value })}
            onEnter={() => {
              if (_.get(filter(), filter().mode)?.trim() === '') return
              setFilterCustomers(prev => ({ ...prev, [filter().mode]: _.get(filter(), filter().mode) as string }))
              setFilter(prev => ({ mode: '' }))
            }}
            onClose={() => setFilter(prev => ({ mode: '' }))}
          />
        </PortalPopup>
      }
      {filter()?.mode && filter()?.mode == FilterMode.ADDRESS &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setFilter(prev => ({ mode: '' }))}
        >
          <InputPopup2
            inputMapper={[
              {
                focus: true,
                label: LL0().customer.street(),
                value: _.get(filter(), 'address.street') || '',
                onChange: (value: string) => _.assign(filter(), { "address.street": value })
              },
              {
                focus: false,
                label: LL0().customer.houseNo(),
                value: _.get(filter(), 'address.home') || '',
                onChange: (value: string) => _.assign(filter(), { "address.home": value })
              }
            ]}
            onEnter={() => {
              setFilterCustomers(prev => ({ ...prev, address: {street: _.get(filter(), 'address.street'), home: _.get(filter(), 'address.home') } }))
              setFilter(prev => ({ mode: '' }))
            }}
            onClose={() => setFilter(prev => ({ mode: '' }))}
          />
        </PortalPopup>
      }
    </>
  )
}

export default CustomerTableWrapper
