import { RxCollection, RxDatabase, RxDocument, } from 'rxdb';
import { createCollection } from "@/data/utils/migration.ts";

export const HAPPY_HOUR_COLLECTION_NAME = 'happy_hours'

export const VERSION = 1
const happyHourSchema = {
  title: 'happy hour schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    name: {type: 'string', maxLength: 50},
    days: {type: 'array', items: {type: 'number'}},
    from: {type: 'string'},
    to: {type: 'string'},
    discountValue: {type: 'string'},
    discountType: {type: 'string'}
  },
  indexes: []
} as const;

export interface HappyHour {
  _id: string;
  name?: string;
  days?: number[];
  from?: string;
  to?: string;
  discountValue?: string;
  discountType?: string;
}

export type HappyHourDocument = RxDocument<HappyHour>;
export type HappyHourCollection = RxCollection<HappyHour>;

export const HappyHourCollection: HappyHourCollection = {} as HappyHourCollection;
export const HappyHour = HappyHourCollection;

export const createHappyHourCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: HappyHour,
    collectionName: HAPPY_HOUR_COLLECTION_NAME,
    version: VERSION,
    schema: happyHourSchema,
  });
}
