import {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import {signal, useSignal} from "@/react/core/reactive.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

const HOUR_TIME_REFRESH_INTERVAL = 30 * 1000;

export const [currentHourUnix, setCurrentHourUnix] = signal<number>(dayjs().unix());
const CurrentTime = () => {
  // implement logic to rerender minuteTime every HOUR_TIME_REFRESH_INTERVAL milliseconds
  // HOUR_TIME_REFRESH_INTERVAL can be reduced when we need more accurate time (e.g. 15 * 1000 for 15 seconds interval)
  const [currentHourTime, setCurrentHourTime] = useSignal<string | any>(dayjs());
  const intervalRef = useRef<any>()

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentHourTime(dayjs());
      setCurrentHourUnix(dayjs().unix());
    }, HOUR_TIME_REFRESH_INTERVAL);

    return () => clearInterval(intervalRef.current)
  }, []);
  return (
    <div className="self-stretch relative text-sm Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
      {/*TODO: Might need to implement real-time timer here*/}
      {currentHourTime().format('HH:mm')}
    </div>
  )
}

export default CurrentTime