import CustomerInformationPlugin from '@customer-info/CustomerInformationPlugin.tsx'
import { memo } from 'react'
import { selector, signal } from '@/react/core/reactive.ts'
import { convertDocuments, type DocDeepSignal } from '@/data/data-utils.ts'
import uuid from 'time-uuid'
import { maxId0 } from '@/data/MaxIdHub.ts'
import {
  makeCustomersAvailable,
  makeCustomersPageAvailable,
  makeFilterCustomersAvailable,
  setCustomerV
} from '@/data/CustomerHub.ts'
import { Customer } from '@/data/Customer.ts'
import { onEnter, PosScreen } from '@/pos/PosRouter.ts'
import CustomerInfomationNewEditC from '@customer-info/CustomerInfomationNewEditC.tsx'
import { clone } from 'json-fn'

const initAddress = {
  street: "",
  home: "",
  zipcode: ""
}

export const initCustomer = (): Customer => ({
  _id: uuid(),
  id: maxId0()?.customerId,
  email: "",
  phoneNumber: "",
  name: "",
  note: "",
  defaultAddressIndex: 0,
  addresses: [initAddress],
})

export enum CustomerInfoScreenMode {
  CUSTOMER_LIST,
  EDIT_CUSTOMER
}
export const [customerInfoScreenMode, setCustomerInfoScreenMode] = signal<CustomerInfoScreenMode>(CustomerInfoScreenMode.CUSTOMER_LIST)

export const [customer0, setCustomer0] = signal<DocDeepSignal<Customer> | undefined>();

export const [customers, setCustomers] = signal<Array<DocDeepSignal<Customer>>>([]);

export const isCurrentCustomer = selector(customer0, (s1: Customer, s2?: Customer) => s1._id === s2?._id)

export const createNewCustomer = (customer: Customer) => {
  maxId0().customerId =  maxId0().customerId! + 1;
  console.log(customer)
  return Customer.insert(customer)
}

export const deleteCurrentCustomer = () => {
  return customer0()?.doc?.incrementalRemove();
}

export const getExistedCustomerId = async (withExistedCustomer?: boolean) => {
  const firstCustomer = await Customer.findOne().exec();
  if (firstCustomer != null && withExistedCustomer) return firstCustomer?._id;
  //else wise create another
  return (await createNewCustomer({...initCustomer(),
    phoneNumber: Math.floor((Math.random() * 1000000000)).toString() || "",
    name: "Mock customer",
  }))?._id
}

export const findCustomer = async (name?: string, phoneNumber?: string) => {
  return Customer.find({
    selector: {
      name: {
        $regex: name || ""
      },
      phoneNumber: {
        $regex: phoneNumber || ""
      }
    }}).exec()
}

export const addCustomerAddress = () => {
  const _customer = customer0();
  if (!_customer) return;
  _customer.addresses = [...(_customer.addresses || []), clone(initAddress)]
}

export const removeCustomerAddress = (index: number) => {
  const _customer = customer0();
  if (!_customer) return;
  _customer?.addresses?.splice(index, 1);
  _customer.defaultAddressIndex = 0;
}

export const getAllCustomer = async () => {
  const customers = await Customer.find().exec();
  if (customers) {
    return convertDocuments(customers, true)
  }
}

const CustomerInfoView = () => {

  makeCustomersPageAvailable();
  makeCustomersAvailable();
  makeFilterCustomersAvailable();

  onEnter(PosScreen.CUSTOMER_INFO, ()=> {
    setCustomerV(v=> v + 1)
  })

  return (
    <>
      {
        customerInfoScreenMode() === CustomerInfoScreenMode.CUSTOMER_LIST ?
          <CustomerInformationPlugin />
          :
          <CustomerInfomationNewEditC onClose={() => setCustomerInfoScreenMode(CustomerInfoScreenMode.CUSTOMER_LIST)} />
      }
    </>
  )
}

export default memo(CustomerInfoView)