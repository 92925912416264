import { context } from '@opentelemetry/api'
import { StringCodec } from 'nats.ws'

import { setMasterAvailable } from '@/data/data-utils.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { getNatsClient } from '@/lib/nats-client.ts'
import { createSpan, runWithCurrentContext } from '@/lib/open-telemetry.ts'
import { getRxdbClient } from '@/lib/rxdb-client.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'

export interface CallOptions {
  onlyOffline?: boolean
  onlyOnline?: boolean
}

export default class ClientCaller {
  static clientPingInterval: any = null

  static async callMasterCommand(method: string, args: any[], options: CallOptions = {}) {
    if (!posSync0()?.id!) return
    const { onlyOnline = false, onlyOffline = false } = options
    const span = createSpan('Calling master command', undefined, context.active())
    span?.setAttribute('storeId', `${posSync0()?.id!}`)
    span?.setAttribute('deviceId', getDeviceId())
    span?.setAttribute('onlyOnline', onlyOnline)
    return runWithCurrentContext(span, async () => {
      if (!posSync0()?.id!) {
        const err = new Error('No post id')
        span?.recordException(err)
        span?.end()
        return
      }
      if (!onlyOnline) {
        const rxdbClient = await getRxdbClient(null, '')
        if (!!rxdbClient) {
          try {
            const res = await rxdbClient.callMasterCommand({
              method: method,
              params: args,
              isMasterCall: true,
            })
            if (!!res?.error) {
              throw new Error(res.error)
            }
            return res
          } catch (err: any) {
            span?.recordException(err)
          }
        }
      }
      if (!onlyOffline) {
        try {
          const subject = `${posSync0()?.id}-master`
          const natsClient = getNatsClient()
          const response = await natsClient.getClient()?.request(
            subject,
            StringCodec().encode(
              JSON.stringify({
                method,
                params: args,
              })
            ),
            { timeout: 4000 }
          )
          if (response) {
            const res = JSON.parse(StringCodec().decode(response!.data))
            if (!!res?.error) {
              throw new Error(res.error)
            }
            return res
          }
          throw new Error('No response')
        } catch (err: any) {
          span?.recordException(err)
          span?.end()
          throw new Error('Can not connect to master')
        }
      }

      const err = new Error('No response')
      span?.recordException(err)
      span?.end()
      throw err
    })
  }

  static startClientPingInterval() {
    if (ClientCaller.clientPingInterval) return
    ClientCaller.clientPingInterval = setInterval(async () => {
      try {
        await ClientCaller.callMasterCommand('pingMaster', [], { onlyOffline: true })
        setMasterAvailable(true)
      } catch (e) {
        setMasterAvailable(false)
      }
    }, 5000)
  }

  static stopClientPingInterval() {
    if (!ClientCaller.clientPingInterval) return
    clearInterval(ClientCaller.clientPingInterval)
  }
}
