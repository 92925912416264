import { type FunctionComponent, useCallback } from 'react'

import { srmLogic } from '../logic'
import { LL0 } from "@/react/core/I18nService.tsx";

type WipeTransactionDataConfirmationPopupType = {
  onClose: () => void
}

const WipeTransactionDataConfirmationPopup: FunctionComponent<WipeTransactionDataConfirmationPopupType> = ({ onClose }) => {
  const onSubmit = useCallback(async () => {
    await srmLogic.clearTransactionData()
    onClose()
  }, [])
  return (
    <div className="rounded-10xs bg-white-solid-white-100-ffffff h-[155px] flex flex-col items-center justify-center pt-[19px] px-[45px] pb-[26px] box-border gap-[16px] max-w-full max-h-full overflow-auto text-center text-xl text-black-solid-black-880-1d1d26 font-mulish">
      <div className="flex flex-col items-center justify-center gap-[2px]">
        <div className="relative text-base leading-[28px] font-medium">{LL0().srm.srmView.doYouWantClearDataSRM()}</div>
      </div>
      <div className="w-[283px] flex flex-row items-center justify-center gap-[12px] text-mini text-gray-solid-gray-600-979797">
        <div
          className="rounded-md box-border w-32 h-9 flex flex-row items-center justify-center p-2.5 cursor-pointer border-[1px] border-solid border-gray-solid-gray-600-979797"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="cursor-pointer rounded-md box-border w-32 h-9 flex flex-row items-center justify-center p-2.5 text-blue-solid-blue-500-0051c1 border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onSubmit}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  )
}

export default WipeTransactionDataConfirmationPopup
