import type {FunctionComponent} from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import {LL0} from "@/react/core/I18nService.tsx";
import {STATES} from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic.ts";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import {inputController0} from "@/react/core/Input.tsx";
import {useSignal} from "@/react/core/reactive.ts";

type OnlineMenuPrinterTaxSetupType = {
  onClose?: () => void;
};

const OnlineMenuPrinterTaxSetup: FunctionComponent<
  OnlineMenuPrinterTaxSetupType
> = ({ onClose }) => {

  const [printer, setPrinter] = useSignal<string | undefined>("")
  const [tax, setTax] = useSignal<string | undefined>("")

  return (
    <div className="min-w-[520px] min-h-[120px] relative rounded bg-white-solid-white-100-ffffff w-full flex flex-col items-end justify-start py-3 px-[15px] box-border gap-[10px] h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish-button-name-bold hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[10px] hover:items-end hover:justify-start hover:rounded hover:py-3 hover:px-[15px] hover:box-border hover:min-w-[560] hover:min-h-[200]">
      <img
        className="relative w-[21px] h-[21px] object-cover cursor-pointer"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
      <div className="self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-3 gap-[16px]">
        <div className="flex-1 flex flex-col items-start justify-start gap-[9px]">
          <b className="self-stretch relative flex items-center h-[21px] shrink-0">
            {LL0().printing.tax()}
          </b>
          <FormControl className="self-stretch" variant="outlined">
            <InputLabel color="primary" />
            <Select color="primary" size="small" value={tax()} onChange={e => setTax(e.target.value)}>
              <MenuItem value="5">5%</MenuItem>
              <MenuItem value="7">7%</MenuItem>
              <MenuItem value="8">8%</MenuItem>
              <MenuItem value="10">10%</MenuItem>
              <MenuItem value="19">19%</MenuItem>
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[9px]">
          <b className="self-stretch relative flex items-center h-[21px] shrink-0">
            {LL0().editOnlineMenu.printers()}
          </b>
          <FormControl className="self-stretch" variant="outlined">
            <InputLabel color="primary" />
            <Select color="primary" size="small" value={printer()} onChange={e => setPrinter(e.target.value)}>
              {STATES.printers().map(p =>
                <MenuItem key={p._id} value={p._id}>{p.name}</MenuItem>
              )}
              {/*<MenuItem value="Kitchen 1">Kitchen 1</MenuItem>*/}
              {/*<MenuItem value="Kitchen 2">Kitchen 2</MenuItem>*/}
              {/*<MenuItem value="Bar 1">Bar 1</MenuItem>*/}
              {/*<MenuItem value="Bar 2">Bar 2</MenuItem>*/}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
      </div>
      <TableManualKeyboard
        value={""}
        inputController={inputController0}
        onEnter={() => onClose?.()}
      />
      {/*<div className="self-stretch rounded-10xs bg-gray-solid-gray-180-ebecee h-[258px] flex flex-col items-center justify-start w-auto gap-[0px] text-center text-3xl text-black-solid-black-1000-000000 hover:bg-gray-solid-gray-180-ebecee hover:flex hover:self-stretch hover:w-auto hover:h-[258px] hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start hover:rounded-10xs">*/}
      {/*  <div className="self-stretch flex-1 rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-center py-2 px-2.5 gap-[8px]">*/}
      {/*    <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] mq768:gap-[6px] mq600:gap-[6px] mq1440:gap-[4px]">*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">1</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">2</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">3</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">4</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">5</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">6</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">7</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">8</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">9</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">0</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] mq768:gap-[6px] mq600:gap-[6px] mq1440:gap-[4px]">*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">Q</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">W</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">E</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">R</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">T</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">Y</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">U</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">I</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">O</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">P</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-[33px] gap-[8px] mq768:gap-[6px] mq600:gap-[6px] mq1440:gap-[4px] mq1440:pl-[30px] mq1440:pr-[30px] mq1440:box-border">*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">A</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">S</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">D</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">F</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">G</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">H</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">J</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">K</div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*        <div className="relative">L</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[22px] mq768:gap-[16px] mq600:gap-[12px] mq1440:gap-[4px]">*/}
      {/*      <div className="self-stretch rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7 box-border w-auto h-auto gap-[10px] hover:bg-white-solid-white-120-fcfcfe hover:flex hover:w-auto hover:self-stretch hover:h-auto hover:flex-col hover:gap-[10px] hover:items-center hover:justify-center hover:rounded-8xs-6 hover:py-0 hover:px-7 hover:box-border hover:shadow-[0px_1px_0px_#898a8d]">*/}
      {/*        <img*/}
      {/*          className="relative w-[18.9px] h-[16.5px] object-cover"*/}
      {/*          alt=""*/}
      {/*          src="/shift-up-black@2x.png"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative">Z</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative">X</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative">C</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative">V</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative">B</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative">N</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative">M</div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="self-stretch rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7 box-border w-auto h-auto gap-[10px] hover:[background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] hover:flex hover:w-auto hover:self-stretch hover:h-auto hover:flex-col hover:gap-[10px] hover:items-center hover:justify-center hover:rounded-8xs-6 hover:py-0 hover:px-7 hover:box-border hover:shadow-[0px_1px_0px_#898a8d]">*/}
      {/*        <img*/}
      {/*          className="relative w-[22.7px] h-[17px] object-cover"*/}
      {/*          alt=""*/}
      {/*          src="/delete-button@2x.png"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="self-stretch flex-1 gap-[8px] grid grid-cols-[33%_34%_32%] [grid-row-gap:0px] [grid-column-gap:8px] text-base">*/}
      {/*      <div className="flex flex-row items-start justify-start py-0 pr-1 pl-0 box-border gap-[8px] min-w-[146px] flex-1 self-stretch h-auto relative hover:flex-1 hover:self-stretch hover:h-auto hover:relative hover:min-w-[146px]">*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">*/}
      {/*          <div className="relative tracking-[-0.32px] leading-[21px]">*/}
      {/*            @*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center text-3xl">*/}
      {/*          <div className="relative">,</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center text-lg">*/}
      {/*          <div className="relative">EN</div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center flex-1 self-stretch h-auto gap-[0px] relative hover:flex-1 hover:self-stretch hover:h-auto hover:gap-[0px] hover:relative">*/}
      {/*        <div className="relative tracking-[-0.32px] leading-[21px]">*/}
      {/*          space*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="flex flex-row items-start justify-start py-0 pr-0 pl-1 box-border gap-[8px] min-w-[120px] flex-1 self-stretch h-auto relative text-3xl hover:flex-1 hover:self-stretch hover:h-auto hover:relative hover:min-w-[120px]">*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[26px]">*/}
      {/*          <div className="relative">.</div>*/}
      {/*        </div>*/}
      {/*        <div className="self-stretch flex-1 rounded-8xs-6 bg-blue-solid-blue-350-2196f3 shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[42px] text-base text-white-solid-white-100-ffffff">*/}
      {/*          <div className="relative">OK</div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default OnlineMenuPrinterTaxSetup;
