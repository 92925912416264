import { Fragment, type FunctionComponent } from "react";
import DashboardPopupTabButton from "@dashboard/DashboardPopupTabButton.tsx";
import { PosScreen, router } from '@/pos/PosRouter.ts';
import {LL0} from "@/react/core/I18nService.tsx";
import { HANDLERS, menus } from "@/react/Dashboard.logic";
import { onlineProviders0 } from "@/data/OnlineProviderHub";

type OnlineMenuPopupType = {
  onClose?: () => void;
};

const OnlineMenuPopup: FunctionComponent<OnlineMenuPopupType> = ({
  onClose,
}) => {
  const LL = LL0()
  const onClickTab = (screen: PosScreen) => {
    router.screen = screen;
    onClose?.();
  }

  return (
    <div className="!min-w-0 relative rounded-md [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] [backdrop-filter:blur(100px)] w-full flex flex-col items-center justify-start py-9 px-8 box-border gap-[20px] h-full max-w-full max-h-full overflow-auto sm:h-full sm:w-full Tablet_600:pl-6 Tablet_600:pr-6 Tablet_600:box-border Mobile_480:pl-4 Mobile_480:pr-4 Mobile_480:box-border">
      <div className="self-stretch overflow-y-auto flex flex-row flex-wrap items-start justify-start py-0.5 px-0 gap-[12px] z-[0] Tablet_600:gap-[8px]">
        <DashboardPopupTabButton
          iconImageUrl="/iconsetup-providers-icon@2x.png"
          buttonText={LL0().syncMenu.setupProviders()}
          noticeNumber="20"
          showChip={false}
          showInfoIcon={false}
          onClick={() => onClickTab(PosScreen.WEB_MENU)}
        />
        {menus().map((o, _i, arr) => (<Fragment key={o._id}>
        <DashboardPopupTabButton
          iconImageUrl="/iconweb-menu-icon@2x.png"
          buttonText={<span>
            {arr.length === 1 ? <>{LL.dashboard.editOnlineMenu()}</> : <>{`${LL.dashboard.editMenu()} ${o.name}`}</>}
          </span>}
          noticeNumber="20"
          showChip={false}
          showInfoIcon={false}
          onClick={() => (HANDLERS.selectMenu(o._id), onClickTab(PosScreen.EDIT_ONLINE_MENU))}
        />
        </Fragment>))}
        {/*<DashboardPopupTabButton*/}
        {/*  iconImageUrl="/icondeliverect-menu-icon@2x.png"*/}
        {/*  buttonText={LL.dashboard.deliveryMenu()}*/}
        {/*  noticeNumber="20"*/}
        {/*  showChip={false}*/}
        {/*  showInfoIcon={false}*/}
        {/*/>*/}
        {/*<DashboardPopupTabButton*/}
        {/*  iconImageUrl="/icondoordash-menu-icon@2x.png"*/}
          {/*  buttonText="Doordash Menu"*/}
        {/*  noticeNumber="20"*/}
        {/*  showChip={false}*/}
        {/*  showInfoIcon={false}*/}
        {/*/>*/}
        {/*<DashboardPopupTabButton*/}
        {/*  iconImageUrl="/iconvega-menu-icon@2x.png"*/}
        {/*  buttonText="Vega Menu"*/}
        {/*  noticeNumber="20"*/}
        {/*  showChip={false}*/}
        {/*  showInfoIcon={false}*/}
        {/*/>*/}
        {/*<DashboardPopupTabButton*/}
        {/*  iconImageUrl="/iconpika-menu-icon@2x.png"*/}
        {/*  buttonText="Pika Menu"*/}
        {/*  noticeNumber="20"*/}
        {/*  showChip={false}*/}
        {/*  showInfoIcon={false}*/}
        {/*/>*/}
        {/*<DashboardPopupTabButton*/}
        {/*  iconImageUrl="/iconuber-eats-icon61@2x.png"*/}
        {/*  buttonText="Uber Eats Menu"*/}
        {/*  noticeNumber="20"*/}
        {/*  showChip={false}*/}
        {/*  showInfoIcon={false}*/}
        {/*/>*/}
      </div>
      <img
        className="w-[25px] absolute !m-[0] top-[7px] right-[7px] h-[25.4px] cursor-pointer z-[1]"
        alt=""
        src="/close-tab.svg"
        onClick={onClose}
      />
    </div>
  );
};

export default OnlineMenuPopup;
