import {FunctionComponent, useMemo, type CSSProperties} from "react";
import {currentPaymentName0, multiPayment0} from "@/react/PaymentView/PaymentView.tsx";
import _ from "lodash";
import { LL0 } from '@/react/core/I18nService.tsx'
import { payments0 } from "@/data/PaymentHub";
import { clsx } from "clsx";

type SelectPaymentType = {
  transactionCode?: string;
  paymentMethod?: string;
  isSelected?: boolean;

  /** Style props */
  paymentMethodColor?: CSSProperties["color"];
  onClick?: () => void;
};

const SelectPayment: FunctionComponent<SelectPaymentType> = (
  {
    transactionCode,
    paymentMethod = "Cash",
    isSelected = false,
    paymentMethodColor,
    onClick
  }) => {
  // isSelected = multiPayment0() && currentPaymentName0() === paymentMethod;
  paymentMethodColor = isSelected ? '#fff' : '#1d1d26';

  const payment = payments0().find(p => [paymentMethod, paymentMethod.toLowerCase()].includes(p?.name))

  const isDisableDebitor = (paymentMethod.toLowerCase().includes('debitor') || payment?.type?.includes('debitor')) && multiPayment0()

  const paymentMethodStyle: CSSProperties = useMemo(() => {
    return {
      color: paymentMethodColor,
    };
  }, [paymentMethodColor]);

  return (
    <div
      className={clsx("min-w-[100px] h-[48px] rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] flex flex-row items-center justify-center py-0 px-2 box-border relative gap-[4px] min-h-[48px] text-left text-lg text-black-solid-black-900-1e1e23 font-mulish Tablet_768:min-h-[40px]", isDisableDebitor && 'opacity-40')}
      onClick={onClick}>
      {isSelected && (
        <div
          className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-blue-solid-blue-350-2196f3 box-border z-[0] border-[1px] border-solid border-blue-solid-blue-350-2196f3"/>
      )}
      {
        multiPayment0() && currentPaymentName0() === paymentMethod &&
          <div className={'absolute inset-[-1px] rounded-10xs border border-[#e54949]'}/>
      }
      { transactionCode &&
          <img
              className="!object-contain relative w-10 h-10 object-cover z-[1] Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px] height_414:w-[26px] height_414:h-[26px]"
              alt=""
              src={transactionCode}
          />
      }
      <b
        className="flex-1 relative z-[2] Tablet_768:text-base Tablet_600:text-sm height_414:text-sm"
        style={paymentMethodStyle}
      >
        {_.get(LL0().payment, paymentMethod.toLowerCase())?.() ? _.get(LL0().payment, paymentMethod.toLowerCase())?.() : _.startCase(paymentMethod)}
      </b>
    </div>
  );
};

export default SelectPayment;