import TypesafeI18n from "@/i18n/i18n-react.tsx";
import { currencyLanguage } from "@/data/language.ts";
import I18nCurrency from "@/react/core/I18nCurrency.tsx";

interface I18nCurrencyWrapperProps {
}

const I18nCurrencyWrapper = (props: I18nCurrencyWrapperProps) => {
  return (
    <TypesafeI18n locale={currencyLanguage() as any} key={currencyLanguage() as any}>
      <I18nCurrency>
      </I18nCurrency>
    </TypesafeI18n>
  )
}

export default I18nCurrencyWrapper
