import {FunctionComponent, memo} from "react";
import EditOnlineMenuTopNav1 from "./EditOnlineMenuTopNav1";
import ModifierEditingToolbar from "./ModifierEditingView/ModifierEditingToolbar";
import ModifierEditingFormDemo from "./ModifierEditingView/ModifierEditingFormDemo";
import ModifierEditingForm from "./ModifierEditingView/ModifierEditingForm";

const ModifierEditingView: FunctionComponent = () => {
  return (
    <div className="modifier-editing-screen bg-gray-solid-gray-120-f8f9ff w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[0px] hover:bg-gray-solid-gray-120-f8f9ff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start">
      <EditOnlineMenuTopNav1 isSelected />
      {/*<EditOnlineMenuTopNav />*/}
      <ModifierEditingToolbar />
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start py-0 pr-5 pl-4 md:pr-4 md:box-border sm:pr-3.5 sm:box-border mq768:pr-3 mq768:box-border mq600:pr-2.5 mq600:box-border mq480:pr-2.5 mq480:box-border">
        {/*
        <ModifierEditingFormDemo />
        */}
        <ModifierEditingForm />
      </div>
    </div>
  );
};

export default memo(ModifierEditingView);
