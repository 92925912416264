import type { FunctionComponent } from "react";
import VoucherComponent from "./VoucherComponent";
import { useSignal } from "@/react/core/reactive.ts";
import { clsx } from "clsx";
import { vouchers0 } from "@/data/VoucherHub.ts";
import {
  checkExpireDate,
  searchText,
  selectedVoucherId,
  setSearchText,
  setSelectedVoucherId
} from "@/react/VoucherView/VoucherView.tsx";
import dayjs from "dayjs";
import { VoucherStatus } from "@/data/Voucher.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { LL2 } from "@/react/core/I18nBackend.tsx";

export type VoucherLeftContentType = {
  className?: string;
};

const VoucherLeftContent: FunctionComponent<VoucherLeftContentType> = ({
  className = "",
}) => {

  const [openSelect, setOpenSelect] = useSignal<boolean>(false)

  const [filterStatus, setFilterStatus] = useSignal<'all' | 'available' | 'used'>('all')
  const _now = dayjs().unix()
  return (
    <div
      className={`self-stretch flex-1 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.25)] bg-white-solid-white-100-ffffff flex flex-col items-center justify-start max-w-[224px] z-[1] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish sm:max-w-[192px] ${className}`}
    >
      {openSelect() &&
        <div className="z-[1] w-screen h-screen fixed opacity-0 top-0 left-0" onClick={() => setOpenSelect(false)} />
      }
      <div className="self-stretch flex flex-col items-start justify-start p-2 relative gap-[8px] z-[2] border-b-[1px] border-solid border-gray-solid-gray-380-cdcdcd">
        <div className="self-stretch flex flex-col items-start justify-start relative z-[0]">
          <div className="self-stretch rounded bg-white-solid-white-100-ffffff box-border h-9 flex flex-row items-center justify-start py-3 px-2 gap-[8px] z-[0] border-[1px] border-solid border-darkgray"
               onClick={() => setOpenSelect(pre => !pre)}
          >
            <div className="flex-1 relative font-semibold">{_.get(LL0().voucher, filterStatus())?.()}</div>
            <img
              className={clsx("w-6 relative h-6 object-cover duration-100", openSelect() && 'rotate-180')}
              alt=""
              src="/icongeneralarrow-down-icon--grey@2x.png"
            />
          </div>
          {openSelect() &&
            <div className="w-full !m-[0] absolute top-[40px] right-[0px] left-[0px] shadow-[0px_4px_8px_rgba(0,_0,_0,_0.25)] rounded bg-whitesmoke overflow-hidden flex-col items-start justify-start z-[1] text-black-solid-black-1000-000000">
              <div className="self-stretch bg-whitesmoke box-border h-[34px] flex flex-row items-center justify-start py-1 px-2 relative gap-[8px] border-b-[1px] border-solid border-gainsboro"
                   onClick={() => {
                     setFilterStatus('all')
                     setOpenSelect(false)
                   }}
              >
                {filterStatus() === 'all' && <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-solid-blue-70-cfddfd z-[0]"/>}
                <div className="relative font-semibold z-[1]">{LL0().voucher.all()}</div>
              </div>
              <div className="self-stretch bg-whitesmoke box-border h-[34px] flex flex-row items-center justify-start py-1 px-2 relative gap-[8px] border-b-[1px] border-solid border-gainsboro"
                   onClick={() => {
                     setFilterStatus('available')
                     setOpenSelect(false)
                   }}
              >
                {filterStatus() === 'available' && <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-solid-blue-70-cfddfd z-[0]"/>}
                <div className="relative font-semibold z-[1]">{LL0().voucher.available()}</div>
              </div>
              <div className="self-stretch bg-whitesmoke box-border h-[34px] flex flex-row items-center justify-start py-1 px-2 relative gap-[8px] border-b-[1px] border-solid border-gainsboro"
                   onClick={() => {
                     setFilterStatus('used')
                     setOpenSelect(false)
                   }}
              >
                {filterStatus() === 'used' && <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-solid-blue-70-cfddfd z-[0]"/>}
                <div className="relative font-semibold z-[1]">{LL0().voucher.used()}</div>
              </div>
            </div>
          }
        </div>
        <div className="w-52 !m-[0] absolute top-[38px] left-[8px] shadow-[2px_2px_6px_rgba(0,_0,_0,_0.12)] rounded bg-white-solid-white-100-ffffff box-border overflow-y-auto hidden flex-col items-start justify-start py-1 px-0 z-[1] border-[1px] border-solid border-gray-solid-gray-310-d6d7d7">
          <div className="self-stretch flex flex-col items-start justify-start gap-[2px]">
            <div className="self-stretch bg-blue-solid-blue-30-e3f2fd h-10 overflow-hidden shrink-0 flex flex-row items-center justify-start py-1 px-2 box-border">
              <div className="flex-1 relative font-medium">{LL0().voucher.all()}</div>
            </div>
            <div className="self-stretch h-10 overflow-hidden shrink-0 flex flex-row items-center justify-start py-1 px-2 box-border">
              <div className="flex-1 relative font-medium">{LL0().voucher.available()}</div>
            </div>
            <div className="self-stretch h-10 overflow-hidden shrink-0 flex flex-row items-center justify-start py-1 px-2 box-border">
              <div className="flex-1 relative font-medium">{LL0().voucher.used()}</div>
            </div>
          </div>
        </div>
      </div>
      {searchText() && <div
        className="self-stretch bg-gray-opacity-gray-50-f2f2f2 flex flex-row items-center justify-start p-2 gap-[12px] z-[1] text-center text-blue-solid-blue-400-0091ff border-b-[1px] border-solid border-gray-solid-gray-380-cdcdcd"
        onClick={() => setSearchText('')}
      >
        <img
          className="w-5 relative h-5 object-cover"
          alt=""
          src="/turnoff@2x.png"
        />
        <b className="relative [text-decoration:underline] Tablet_768:text-xs">
          {LL0().voucher.turnOffSearchResults()}
        </b>
      </div>}
      <div className="no-scrollbar self-stretch bg-gray-solid-gray-300-dbdbdb overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-1 gap-[4px] z-[0] text-blue-solid-blue-0057ff">
        {/*<VoucherComponent*/}
        {/*  isAvalable*/}
        {/*  voucherCode="VCH29022"*/}
        {/*  creatorName="New Voucher"*/}
        {/*  expDate="--"*/}
        {/*  showPercentDecrease={false}*/}
        {/*  showIsSelected={false}*/}
        {/*  voucherName="VIP Member"*/}
        {/*  percentDecrease="- 20%"*/}
        {/*/>*/}
        {/*<VoucherComponent*/}
        {/*  isAvalable*/}
        {/*  voucherCode="VCH29021"*/}
        {/*  creatorName="Carolyn Campbell"*/}
        {/*  expDate="10-20-24, 10:00"*/}
        {/*  showPercentDecrease*/}
        {/*  showIsSelected*/}
        {/*  voucherName="VIP Member"*/}
        {/*  percentDecrease="- 20%"*/}
        {/*/>*/}
        {
          vouchers0()
            .filter(v => {
              const search = searchText()?.toLowerCase();
              return search
                ? (v?.code?.toLowerCase().includes(search) || v?.name?.toLowerCase().includes(search))
                : true;
            })
            .filter(v => filterStatus() === 'all' ? true : (filterStatus() === 'available' ? v.status === 'created' : v.status === 'redeemed'))
            .map(voucher => (
              <VoucherComponent
                key={voucher._id}
                isAvalable={voucher.status === VoucherStatus.CREATED && !checkExpireDate(voucher?.expiredAt)}
                voucherCode={voucher.code}
                creatorName={voucher.createdBy}
                expDate={voucher.expiredAt ? dayjs.unix(voucher.expiredAt).format(`${LL2().dates.dateFormat()} ${LL2().dates.timeFormat()}`) : '--'}
                showPercentDecrease={false}
                showIsSelected={selectedVoucherId() === voucher._id}
                voucherName={voucher.name}
                percentDecrease="- 20%"
                onClick={() => setSelectedVoucherId(voucher._id)}
              />
            ))
        }
      </div>
    </div>
  );
};

export default VoucherLeftContent;
