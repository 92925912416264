import { type ChangeEvent, type FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import CustomTablePopup from '@edit-room/CustomTablePopup';
import PortalPopup from '@edit-room/PortalPopup';
import DeleteConfirmationPopup from '@edit-room/DeleteConfirmationPopup';
import {
  copyRoomObject,
  deleteRoomObject,
  EditorMode,
  updateObjectName,
  isSelectedMode,
  roomObject0,
  TABLE_COLORS
} from '@/react/EditRoomView/EditRoomView.tsx';
import EditRoomColorSelect from '@edit-room/EditRoomColorSelect.tsx';
import _ from 'lodash';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import TextField from '@/react/core/TextField.tsx';

type EditTableFormType = {
  isTableEdit?: boolean;
};

type TableColorsType = Array<string>;

const EditTableForm: FunctionComponent<EditTableFormType> = ({
  isTableEdit = false,
}) => {
  const [nameInput, setNameInput] = useState<string>("")
  const [isNameError, setIsNameError] = useState<boolean>(false)
  // effectOn([roomObject0()?._id], () => setNameInput(roomObject0()?.name || ""));
  useEffect(() => {
    setNameInput(roomObject0()?.name || "")
  }, [roomObject0()?._id]);

  isTableEdit = isSelectedMode(EditorMode.Table)
  const shapeRadioInputProps = (shape: 'circle' | 'rectangle') =>( {
    checked: roomObject0()?.shape === shape || false,
    onChange: (e: ChangeEvent<HTMLInputElement>) => _.assign(roomObject0(), {shape})
  })

  const [isCustomTablePopupOpen, setCustomTablePopupOpen] = useState(false);
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openCustomTablePopup = useCallback(() => {
    setCustomTablePopupOpen(true);
  }, []);

  const closeCustomTablePopup = useCallback(() => {
    setCustomTablePopupOpen(false);
  }, []);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  return (
    <>
      {isTableEdit && (
        <div className="!flex !h-auto self-stretch rounded bg-[#fbfbfc] shadow-[0px_0px_8px_rgba(75,_74,_95,_0.3)] h-[336.8px] hidden flex-col items-start justify-center py-3.5 px-[11px] box-border gap-[10px] text-left text-sm text-royalblue-300 font-mulish">
          <b className="self-stretch relative md:text-xs">{LL0().restaurant.tableName()}:</b>
          <div className="self-stretch flex flex-col items-start justify-start py-0 px-2.5">
            <TextField
              className="self-stretch"
              color="primary"
              name="tableName"
              size="small"
              // label={LL0().restaurant.tableName()}
              placeholder={LL0().restaurant.tableName()}
              fullWidth={true}
              variant="outlined"
              onChange={(e) => {
                const targetValue = e.target.value;
                setNameInput(targetValue);
                setIsNameError(!updateObjectName(targetValue));
              }}
              value={nameInput}
            />
          </div>
          { isNameError &&
            <div className="text-red-500 font-medium">{LL0().restaurant.tableExists({nameInput:nameInput})}</div>
          }
          <div className="self-stretch flex flex-row items-center justify-start py-[3px] px-0 relative">
            <b className="relative z-[0] md:text-xs">{LL0().restaurant.takeAway()}:</b>
            <FormControlLabel
              className="absolute my-0 mx-[!important] h-full top-[0px] right-[-11px] bottom-[0px] z-[1]"
              control={<Switch color="primary" size="small"
              onChange={e => _.assign(roomObject0(), {takeAway: e.target.checked})}
              checked={roomObject0()?.takeAway || false}
              />}
              label=''
            />
          </div>
          <b className="self-stretch relative md:text-xs">{LL0().restaurant.color()}:</b>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 px-2.5 gap-[8px]" >
            {TABLE_COLORS.map((color, index) => <EditRoomColorSelect key={index} isSelected={roomObject0()?.bgColor === color} editRoomColorSelectBackgroundColor={color} onClick={() => _.assign(roomObject0(), {bgColor: color})} />)}
          </div>
          <b className="self-stretch relative md:text-xs">{LL0().restaurant.shape()}:</b>
          <div className="self-stretch flex-1 relative grid grid-cols-[1fr_1fr] [grid-row-gap:6px] [grid-column-gap:6px] text-smi text-darkslategray-100 md:grid md:grid-cols-[minmax(auto,50%)] md:[grid-row-gap:6px] md:[grid-column-gap:6px]">
            <div
              className="h-[15px] flex flex-row items-center justify-start gap-[7px] cursor-pointer relative col-[1/1] row-[2/2] md:row-[3/3]"
              onClick={openCustomTablePopup}
            >
              <img
                className="relative w-3.5 h-3.5"
                alt=""
                src="/custom-icon.svg"
              />
              <b className="relative leading-[15px] inline-block w-[50px] shrink-0 md:text-2xs">
                {LL0().restaurant.custom()}
              </b>
            </div>
            <div className="w-[88px] h-[15px] flex flex-row items-center justify-start gap-[7px]">
              <input
                className="cursor-pointer rounded-981xl bg-basic-color-white box-border w-[15px] h-[15px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-[3px] border-[1px] border-solid border-royalblue-100"
                type="radio"
                name="tableShape"
                {...shapeRadioInputProps('rectangle')}
            />
              <b className="relative leading-[15px] inline-block w-[66px] shrink-0 md:text-2xs">
                {LL0().restaurant.rectangle()}
              </b>
            </div>
            <div className="w-[57px] h-[15px] flex flex-row items-center justify-start gap-[7px]">
              <input
                className="cursor-pointer rounded-981xl bg-basic-color-white box-border w-[15px] h-[15px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-[3px] border-[1px] border-solid border-royalblue-100"
                type="radio"
                name="tableShape"
                {...shapeRadioInputProps('circle')}
              />
              <b className="relative leading-[15px] inline-block w-[35px] shrink-0 md:text-2xs">
                {LL0().restaurant.circle()}
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-smi text-black">
            <div className="self-stretch flex-1 rounded-md flex flex-row items-center justify-center py-2.5 px-3 gap-[6px] border-[1px] border-solid border-black"
                 onPointerDown={copyRoomObject}
                 ref={makeRipple}
            >
              <img
                className="relative w-3.5 h-4"
                alt=""
                src="/duplicate-icon1.svg"
              />
              <b className="relative leading-[15px] md:text-2xs">{LL0().restaurant.duplicate()}</b>
            </div>
            <div
              className="flex-1 rounded-md flex flex-row items-center justify-center py-[9.5px] px-3 gap-[8px] cursor-pointer text-tomato-200 border-[1px] border-solid border-tomato-100"
              onClick={openDeleteConfirmationPopup}
            >
              <img
                className="relative w-[17px] h-[17px] object-cover"
                alt=""
                src="/iconbin-icon--red11@2x.png"
              />
              <b className="relative leading-[15px] inline-block w-[42.8px] shrink-0 md:hidden">
                {LL0().ui.delete()}
              </b>
            </div>
          </div>
        </div>
      )}
      {isCustomTablePopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCustomTablePopup}
        >
          <CustomTablePopup onClose={closeCustomTablePopup} />
        </PortalPopup>
      )}
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onComplete={deleteRoomObject} />
        </PortalPopup>
      )}
    </>
  );
};

export default EditTableForm;
