import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

export type TaxAndPaymentDeleteConfiType = {
  onClose?: () => void;
  onComplete?: () => void;
};

const TaxAndPaymentDeleteConfi: FunctionComponent<
  TaxAndPaymentDeleteConfiType
> = ({ onClose, onComplete }) => {
  const onConfirmComplete = () => {
    onClose && onClose()
    onComplete && onComplete()
  }
  return (
    <div className="w-full rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px_0px] min-w-[360px] h-full max-w-full max-h-full text-center text-[20px] text-firebrick font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <b className="self-stretch relative leading-[28px]">
          {LL0().masterMachine.deleteConfirmation()}
        </b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-gray-300">
          {LL0().masterMachine.doYouWantToDelete()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[0px_12px] max-w-[312px] text-sm text-royalblue-100">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 text-white-solid-white-100-ffffff"
             onClick={onConfirmComplete}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default TaxAndPaymentDeleteConfi;