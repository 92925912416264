import type { FunctionComponent } from 'react';
import { useContext, useEffect, useRef } from 'react';
import OrderItem from '@order-view/OrderItem';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import { effect } from '@/react/core/reactive.ts';
import clsx from 'clsx';

type ItemListContainerType = {
  isListItemOnly?: boolean;
};

const ItemListContainer: FunctionComponent<ItemListContainerType> = ({
  isListItemOnly,
}) => {
  const divContainerRef = useRef<HTMLDivElement | null>(null);
  const { order0, splitEnable, moveEnable, isPayment, containerClassName } = useContext(ItemFactoryContext);

  useEffect(() => {
    divContainerRef.current?.scrollTo({
      top: divContainerRef.current?.scrollHeight || 0,
      behavior: "smooth"
    });
  }, [divContainerRef.current?.scrollHeight]);

  return (
    isListItemOnly && (
      <div className={clsx("!flex no-scrollbar self-stretch flex-1 rounded-md bg-white overflow-y-auto shrink-0 hidden flex-col items-center justify-start p-px border-[1px] border-solid border-gray-solid-gray-400-c9cbcc",
        containerClassName)}
           ref={divContainerRef}
      >
        {order0?.().items.map((item, index) =>
          <OrderItem
          orderItem={item}
          key={index}
          showAddMinusIcon={!splitEnable?.() && !moveEnable?.() && !isPayment}
          order={order0()}
        />)}
      </div>
    )
  );
};

export default ItemListContainer;
