import type { TOrder } from "./OrderType";

export enum OrderAction {
  ACKNOWLEDGE = 'act_ack',
  REJECT = 'act_reject',
  ACCEPT = 'act_accept',
  CANCEL = 'act_cancel',
  READY = 'act_ready',
  COMPLETE = 'act_complete'
}

export type UpdateOnlineOrderStatusRequestBody = {
  order: TOrder,
  action: OrderAction
}
