import {type FunctionComponent, useCallback, useState} from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import UserSettingUserPermissionPopupInfo from './UserSettingUserPermissionPopupInfo'
import PortalPopup from './PortalPopup'
import {
  getInfoFor,
  getPopupScreenName,
  userSettingsSwitchProps
} from '@/react/UserSettingView/UserSettingView'
import { User } from '@/data/User'
import { LL0 } from "@/react/core/I18nService.tsx";
import { PosScreen } from "@/pos/PosRouter.ts";

const RightUserPermission: FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  const [popupTitle, setPopupTitle] = useState('')
  const [popupContent, setPopupContent] = useState('')
  const [popupScreen, setPopupScreen] = useState<PosScreen | null>(null)
  const closePopup = useCallback(() => setOpen(false), [])
  const openPopupFor = useCallback(
    (field: keyof User) => () => {
      const [title, content] = getInfoFor(field)
      setPopupTitle(title)
      setPopupContent(content)
      setPopupScreen(getPopupScreenName(field));
      setOpen(true)
    },
    []
  )

  return (
    <>
      <div className="flex-1 flex flex-col items-start justify-center gap-[10px]">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('editHighSecuritySetting')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.editHighSecuritySetting()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('editHighSecuritySetting')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('editMenuLayout')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().dashboard.editMenu()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('editMenuLayout')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('editTablePlan')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.editTablePlan()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('editTablePlan')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('editHappyHours')}
                    value={true}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.editHappyHours()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('editHappyHours')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('allowTableTakeOver')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.changeTableWaiter()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('allowTableTakeOver')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('viewOrderDetail')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.viewOrderDetail()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('viewOrderDetail')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('discount')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.discount()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('discount')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('moveItem')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.moveItem()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('moveItem')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('openCashDrawer')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.openCashDrawer()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('openCashDrawer')}
          />
        </div>
      </div>
      {open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePopup}
        >
          <UserSettingUserPermissionPopupInfo
            onClose={closePopup}
            title={popupTitle}
            content={popupContent}
            screen={popupScreen}
          />
        </PortalPopup>
      )}
    </>
  )
}

export default RightUserPermission
