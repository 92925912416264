import { convertDocument, convertDocuments } from "@/data/data-utils.ts";
import { OrderLayout } from "@/data/OrderLayout.ts";
import _ from "lodash";
import { ProductLayout } from "@/data/ProductLayout.ts";
import { Product } from "@/data/Product.ts";
import { products0 } from "@/data/ProductHub.ts";
import delay from "delay";


function assignPositions(array, row, column) {
  let top = row;
  let left = 0;

  for (let i = 0; i < array.length; i++) {
    const category = array[i];
    category.top = top;
    category.left = left;
    left++;
    if (left >= column) {
      left = 0;
      top++;
    }
  }
  return array;
}

export function categoryLayoutFixFactory() {
  return {
    fixLayoutCategories,
    clearUnLayoutProduct,
    clearNoNameProduct
  }

  async function fixLayoutCategories() {
    const _orderLayout = await OrderLayout.findOne().exec();
    const orderLayout = convertDocument(_orderLayout!, true);

    const orderLayoutColumn = orderLayout.columns;
    const orderLayoutRow = orderLayout.rows;

    let duplitcatePosition: any[] = [];

    const group = _.groupBy(orderLayout.categories, c => `${c.top}/${c.left}`);

    const groupArray = Object.values(group);

    for (const smallGroup of groupArray) {
      if (smallGroup.length > 1) {
        const duplicate = smallGroup.filter((_, index) => index > 0);
        duplitcatePosition = duplitcatePosition.concat(duplicate);
      }
    }

    if (!duplitcatePosition || duplitcatePosition?.length === 0) {
      return
    } else {
      const duplitcateId = duplitcatePosition?.map(i => i._id);

      const result = assignPositions(duplitcatePosition, orderLayoutRow , orderLayoutColumn)

      const lastItem = _.last(result)
      const lastTop = lastItem?.top + 1
      orderLayout.rows = lastTop

      for (const id of duplitcateId) {
        const index = orderLayout.categories.findIndex(i => i._id === id)
        const newValue = result.find(i => i._id === id)
        if (index !== undefined && index !== -1) {
          orderLayout.categories[index!]!.top = newValue.top;
          orderLayout.categories[index!]!.left = newValue.left;
        }
      }
      const categories = orderLayout.categories.filter(c => {
        if (!c.name) return false;
        return true;
      });
      orderLayout.categories = categories;
    }
    await delay(1000);
  }

  async function clearUnLayoutProduct() {
    const _productLayouts = await ProductLayout.find().exec();
    const _products = await Product.find().exec();

    const productLayouts = convertDocuments(_productLayouts!, true);
    const product = convertDocuments(_products!, true);

    const productLayoutsId = productLayouts?.map(p => p.product);
    const unLayoutProducts = _products.filter(p => !productLayoutsId?.includes(p._id))

    if (unLayoutProducts) {
      for (const product of unLayoutProducts) {
        await product.remove()
      }
    }
    console.log('unLayoutProducts', unLayoutProducts)
    await delay(1000);
  }


  async function clearNoNameProduct() {
    const _products = await Product.find().exec();
    // const noNameProducts = _products.filter(p => !p.name || p.name === '')
    const products = convertDocuments(_products!, true);
    const ids = products?.map(p => p._id)
    const _productLayouts = await ProductLayout.find().exec();

    const noLayout = _productLayouts?.filter(p => !ids.includes(p.product) && p.type !== 'Text')
    for (const layout of noLayout) {
      await layout.remove()
    }
    await delay(1000)

    // for (const product of noNameProducts) {
    //     const productLayout = _productLayouts.find(p => p.product === product._id);
    //     if (productLayout) {
    //       await productLayout.remove();
    //     }
    //     await product.remove()
    // }
  }
}

async function fix4() {
  let api = categoryLayoutFixFactory();
  await api.fixLayoutCategories()
  await api.clearUnLayoutProduct()
  await api.clearNoNameProduct()
  // await api.fixRoomObjects();
}

// @ts-ignore
window.fix4 = fix4;
