import type { RxCollection, RxDatabase } from "rxdb";
import { createCollection } from "@/data/utils/migration.ts";

export const ZVT_LOG_COLLECTION_NAME = 'zvt_log'

export const VERSION = 1
const Schema = {
  title: 'Zvt schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    ssid: { type: 'string' },
    data: {type: 'string' },
    ts: { type: 'number' },
  },
  indexes: []
} as const;

export type ZvtLog = {
  _id: string;
  ssid: string;
  data: string;
  ts: number; // timestamp
}

export const ZvtLog = {} as RxCollection<ZvtLog>

export const createZvtLogCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: ZvtLog,
    collectionName: ZVT_LOG_COLLECTION_NAME,
    version: VERSION,
    schema: Schema,
  });
}