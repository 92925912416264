import { FunctionComponent, useCallback, useState } from 'react'
import AnnouncePopup from '@eod/AnnouncePopup'
import PortalPopup from '@eod/PortalPopup'
import { date0, dateIso0, dayReport0, getZContent, needZKey, onPrintZ } from '@/react/EndOfDayView/EndOfDayView.tsx'
import dayjs from 'dayjs'
import { LL0 } from '@/react/core/I18nService.tsx'
import { LoadingButton } from '@mui/lab'
import { Order } from '@/data/Order.ts'
import { getBeginHourAsMinutes } from '@/pos/orderUtils.ts';
import { twMerge } from "tailwind-merge";
import { activePS, eodMode0, setEodMode0 } from "@/tse/tse-variable.ts";
import { captureException } from "@sentry/react";
import { useSignal } from "@/react/core/reactive.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import DeveloperOnlyVerifyPopu from "@dashboard/DeveloperOnlyVerifyPopu.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";


type EndOfDayPopupConfirmType = {
  onClose?: () => void;
};

const EndOfDayPopupConfirm: FunctionComponent<EndOfDayPopupConfirmType> = ({ onClose }) => {
  const [isAnnouncePopupOpen, setAnnouncePopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenPopupVerified, setIsOpenPopupVerified] = useSignal<boolean>(false);
  const [countNumber, setCountNumber] = useSignal<number>(0);
  const password = tseConfig0()?.tsePassword

  const openAnnouncePopup = useCallback(() => {
    setAnnouncePopupOpen(true);
  }, []);

  const closeAnnouncePopup = useCallback(() => {
    setAnnouncePopupOpen(false);
    onClose?.();
  }, []);


  const onClickPrintZ = async () => {
    //if date0 === today and there is still a Unpaid Order -> open popup
    const remainOrders = await Order.find().exec();
    // const remainOrders = orders1();
    if (date0() === dayjs().subtract(getBeginHourAsMinutes(), 'minute').startOf("day")
        .format("YYYY-MM-DD") && remainOrders.filter(o => o.table).length > 0) {
      openAnnouncePopup();
      return;
    }
    if (remainOrders.filter(o => !o.table)) {
      captureException(new Error('There are still unpaid orders'));
    }

    setIsLoading(true);
    await onPrintZ(() => {
      setIsLoading(false);
      onClose?.();
    })
  }

  const handleOpenPopupVerify = () => {
    setCountNumber(v => v + 1)
    if (countNumber() === 5) {
      setIsOpenPopupVerified(true)
      setCountNumber(0)
    }
  };
  return (
    <>
      <div className="z-report-popup relative bg-basic-color-white w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[16px] min-w-[280px] h-full max-w-full max-h-full text-left text-sm text-black font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 gap-[8px] border-b-[1px] border-solid border-black">
            <b className="relative">{LL0().report.ZNumber()}: {getZContent(needZKey())}</b>
            <div className="relative">{dayjs(dateIso0() || '').format(LL2().dates.dateFormat())}</div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 gap-[8px] border-b-[1px] border-solid border-black">
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative font-semibold">{LL0().report.totalCashSales()}</div>
              <div className="flex flex-row items-center justify-end py-0 pr-0 pl-2">
                <div className="relative font-semibold">{LL3().format.currency(dayReport0()?.totalCashSalesExcludingCashback)}</div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative font-semibold">{LL0().report.totalCashlessSales()}</div>
              <div className="flex flex-row items-center justify-end py-0 pr-0 pl-2">
                <div className="relative font-semibold">{LL3().format.currency(dayReport0()?.totalCashlessSalesExcludingTip)}</div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative font-semibold">{LL0().report.vouchersUsed()}</div>
              <div className="flex flex-row items-center justify-end py-0 pr-0 pl-2">
                <div className="relative font-semibold">{LL3().format.currency(dayReport0()?.voucherReport.redeemedTotal)}</div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 relative font-semibold">{LL0().report.vouchersSold()}</div>
              <div className="flex flex-row items-center justify-end py-0 pr-0 pl-2">
                <div className="relative font-semibold">{LL3().format.currency(dayReport0()?.voucherReport.soldTotal)}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <b className="flex-1 relative" onClick={handleOpenPopupVerify}>{LL0().common.total()}</b>
            <div className="flex flex-row items-center justify-end py-0 pr-0 pl-2">
              <div className="relative font-semibold">{LL3().format.currency(dayReport0()?.report.vSum)}</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-end gap-[8px] text-base">
          <div className="rounded box-border flex flex-row items-center justify-center py-2 px-2 cursor-pointer border-[1px] border-solid border-button-stroke-gray" onClick={onClose}>
            <div className="relative font-semibold">{LL0().orderHistory.cancel()}</div>
          </div>
          <LoadingButton
            loading={isLoading}
            onClick={onClickPrintZ}
            className={twMerge(
              "!h-full !rounded !bg-indianred box-border !w-[70px] !text-basic-color-white border-[1px] !border-solid !border-indianred", activePS() && '!bg-blue-500', eodMode0() === 'reduce' && '!bg-green-solid-green-460-61dc00 box-border')}>
            {LL0().ui.ok()}
          </LoadingButton>
        </div>
      </div>
      {isAnnouncePopupOpen && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={closeAnnouncePopup}>
          <AnnouncePopup onClose={closeAnnouncePopup} />
        </PortalPopup>
      )}
      {isOpenPopupVerified() && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setIsOpenPopupVerified(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setIsOpenPopupVerified(false)}
            password={password}
            onComplete={() => {
              setEodMode0("reduce")
              setIsOpenPopupVerified(false)
            }}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default EndOfDayPopupConfirm;
