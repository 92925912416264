interface UsroboticsDataWsMessage {
  event: 'usrobotics-usb-data'
  data: string
}
interface UsroboticsRingData {
  RING?: string
  DATE?: string
  TIME?: string
  /** This will be the caller's phone number (`0912345678`) */
  NMBR?: string
}

export function isUsroboticsDataWsMessage(msg: unknown): msg is UsroboticsDataWsMessage {
  const temp = msg as UsroboticsDataWsMessage
  return temp.event === 'usrobotics-usb-data' && typeof temp.data === 'string'
}

export function getRingMsg(msg: UsroboticsDataWsMessage): UsroboticsRingData {
  // The incoming message's data will be something like this:
  // "\r\nRING\r\n\r\nDATE=0229\r\nTIME=1136\r\nNMBR=0912345678\r\n"
  return Object.fromEntries(
    msg.data
      .split('\r\n')
      .filter(a => !!a)
      .map(a => a.split('='))
  )
}
