import {
  CashbookViewList,
  cashValue,
  cashValueList,
  coinValue,
  coinValueList,
  setCashValue,
  setCoinValue,
  setCounted,
  setSelectedCashbookView
} from "@/react/CashbookView/CashbookView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import OrderKeyboard1 from "@cashbook/OrderKeyboard1.tsx";
import { type FunctionComponent, useCallback, useMemo, useState } from "react";
import CashBookInput from "./CashBookInput";
import { batch } from "@/react/core/reactive.ts"
import _ from "lodash"
import { LL3 } from "@/react/core/I18nCurrency.tsx";

const CashCounting: FunctionComponent = () => {
  const [isSelected, setIsSelected] = useState<{ coin: number | null, cash: number | null }>({ coin: null, cash: null })
  const [openKeyboard, setOpenKeyboard] = useState(true)

  const handleCashChange = useCallback((index: number, quantity: string) => {
    const newArray = _.cloneDeep(cashValue());
    newArray[index].quantity = Number(quantity) || 0;
    setCashValue(newArray);
  }, [cashValue()]);

  const handleCoinChange = useCallback((index: number, quantity: string) => {
    const newArray = _.cloneDeep(coinValue());
    newArray[index].quantity = Number(quantity) || 0;
    setCoinValue(newArray);
  }, [coinValue()]);

  const totalCashValue = useMemo(() => {
    return cashValue().reduce((total, cash) => (total += cash.value * cash.quantity), 0)
  }, [cashValue()])

  const totalCoinValue = useMemo(() => {
    return coinValue().reduce((total, cash) => (total += cash.value * cash.quantity), 0)
  }, [coinValue()])

  return (
    <div className="bg-black-solid-black-700-364150 w-full h-full flex flex-col items-start justify-start gap-[0px] text-left text-base text-gray-solid-gray-380-cdcdcd font-mulish bg-black-solid-black-700-364150 flex w-full h-full flex-col gap-[0px] items-start justify-start">
      <div className="self-stretch bg-black-solid-black-500-59636f h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[5px] px-2.5 box-border sm:h-[42px] sm:pt-[5px] sm:pb-[5px] sm:box-border Tablet_768:h-[42px] Tablet_600:h-[42px] mq1440:h-[50px]">
        <div className="flex flex-row items-center justify-center gap-[15px]">
          <div className="relative w-6 h-6 overflow-hidden shrink-0">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/icondouble-chevron-icon@2x.png"
            />
          </div>
          <b className="relative tracking-[-0.28px] sm:text-base Tablet_768:text-mini Tablet_600:text-sm mq1440:text-lg">
            {LL0().cashbook.cashCounting()}
          </b>
        </div>
        <div className="flex flex-row items-center justify-start Tablet_600:gap-[4px] height_414:gap-[4px]"
          onClick={() => {
            batch(() => {
              setCoinValue(coinValueList)
              setCashValue(cashValueList)
              setSelectedCashbookView(CashbookViewList.Cashbook)
            })
          }}
          ref={makeRipple}
        >
          <div className="rounded-11xl bg-blue-solid-blue-450-1271ff w-[76px] h-10 flex flex-row items-center justify-center p-2.5 box-border sm:h-8 Tablet_768:h-8 Tablet_600:h-8 mq1440:h-10">
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-between pt-2.5 px-5 pb-5 relative text-center text-lg text-white-solid-white-100-ffffff md:pb-5 md:box-border Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:pb-5 Tablet_600:box-border mq1440:gap-[24px] mq1440:p-5 mq1440:box-border">
        <div className="self-stretch flex flex-col items-center justify-start gap-[10px] z-[0]">
          <div className="self-stretch rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] flex flex-row items-center justify-center p-3 box-border w-auto h-auto gap-[0px] [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] flex self-stretch w-auto h-auto flex-row gap-[0px] items-center justify-center rounded-md p-3 box-border Tablet_768:gap-[8px] Tablet_768:p-2 Tablet_768:box-border Tablet_768:p-2 Tablet_768:box-border Tablet_600:p-2 Tablet_600:box-border Tablet_600:p-2 Tablet_600:box-border Mobile_480:p-1.5 Mobile_480:box-border Mobile_480:p-1.5 Mobile_480:box-border mq1440:gap-[0px_8px] mq1440:items-center mq1440:justify-start mq1440:p-5 mq1440:box-border mq1440:p-5 mq1440:box-border mq1440:p-5 mq1440:box-border mq1440:p-5 mq1440:box-border mq320:p-1 mq320:box-border mq320:p-1 mq320:box-border">
            <div className="flex-1 relative grid grid-rows-[repeat(2,1fr)] grid-cols-[repeat(3,1fr)] [grid-row-gap:16px] [grid-column-gap:16px] lg:gap-[20px] lg:grid lg:grid-rows-[repeat(2,1fr)] lg:grid-cols-[repeat(3,1fr)] lg:[grid-row-gap:16px] lg:[grid-column-gap:16px] sm:grid Tablet_768:gap-[10px] Tablet_768:grid Tablet_600:gap-[10px] Tablet_600:grid Mobile_480:gap-[8px] Mobile_480:grid mq1440:gap-[20px] mq1440:grid mq1440:max-w-[1200px] mq1440:gap-[20px] mq1440:grid mq1440:max-w-[1200px] mq320:gap-[8px] mq320:grid">
              {cashValue().map((cash, index) => (
                <CashBookInput
                  key={index}
                  textLeftValue={LL3().format.currency(cash.value)}
                  isCash
                  isSelected={index === isSelected.cash}
                  onSelect={() => setIsSelected({ coin: null, cash: index })}
                  quantity={cash.quantity.toString()}
                  onEnterQuantity={(e) => handleCashChange(index, e.target.value)}
                />
              ))}
            </div>
            <div className="self-stretch w-[260px] flex flex-col items-center justify-center py-0 px-3.5 box-border gap-[4px] h-auto text-11xl text-global-white hover:flex hover:w-[260px] hover:self-stretch hover:h-auto hover:flex-col hover:gap-[4px] hover:items-center hover:justify-center hover:py-0 hover:px-3.5 hover:box-border sm:w-[215px] sm:flex-col Tablet_768:w-[170px] Tablet_768:flex-col Tablet_600:w-[120px] Tablet_600:flex-col Mobile_480:flex-col mq1440:flex-1 mq1440:min-w-[250px] mq1440:max-w-[400px] mq320:flex-col">
              <img
                className="w-20 relative h-20 object-cover sm:w-[60px] sm:h-[60px] Tablet_768:w-14 Tablet_768:h-14 Tablet_600:w-[50px] Tablet_600:h-[50px] Mobile_480:w-[46px] Mobile_480:h-[46px] mq1440:w-20 mq1440:h-20 mq320:w-10 mq320:h-10"
                alt=""
                src="/cashbookcash-icon@2x.png"
              />
              <div className="h-[38px] relative flex [text-shadow:0px_1.5px_3px_rgba(0,_0,_0,_0.2)] [-webkit-text-stroke:0px_#979797] hover:font-mulish font-mulish text-16xl text-center text-white-solid-white-100-ffffff sm:text-7xl Tablet_768:text-lg Tablet_600:text-base Mobile_480:text-xl mq1440:text-16xl mq320:text-xl">
                {LL3().format.currency(totalCashValue)}
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] flex flex-row items-center justify-center p-3 box-border w-auto h-auto gap-[0px] [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] flex self-stretch w-auto h-auto flex-row gap-[0px] items-center justify-center rounded-md p-3 box-border Tablet_768:gap-[8px] Tablet_768:p-2 Tablet_768:box-border Tablet_768:p-2 Tablet_768:box-border Tablet_600:p-2 Tablet_600:box-border Tablet_600:p-2 Tablet_600:box-border Mobile_480:p-1.5 Mobile_480:box-border Mobile_480:p-1.5 Mobile_480:box-border mq1440:gap-[8px] mq1440:items-center mq1440:justify-start mq1440:p-5 mq1440:box-border mq1440:p-5 mq1440:box-border mq1440:p-5 mq1440:box-border mq1440:p-5 mq1440:box-border mq320:p-1 mq320:box-border mq320:p-1 mq320:box-border">
            <div className="flex-1 relative h-[120px] grid grid-rows-[repeat(2,1fr)] grid-cols-[repeat(3,1fr)] [grid-row-gap:16px] [grid-column-gap:16px] lg:gap-[20px] lg:grid lg:grid-rows-[repeat(2,1fr)] lg:grid-cols-[repeat(3,1fr)] lg:[grid-row-gap:16px] lg:[grid-column-gap:16px] sm:grid Tablet_768:gap-[10px] Tablet_768:grid Tablet_600:gap-[10px] Tablet_600:grid Mobile_480:gap-[8px] Mobile_480:grid mq1440:gap-[20px] mq1440:grid mq1440:max-w-[1200px] mq1440:gap-[20px] mq1440:grid mq320:gap-[8px] mq320:grid">
              {coinValue().map((coin, index) => (
                <CashBookInput
                  key={index}
                  textLeftValue={LL3().format.currency(coin.value)}
                  isCoin
                  isSelected={index === isSelected.coin}
                  onSelect={() => setIsSelected({ coin: index, cash: null })}
                  quantity={coin.quantity.toString()}
                  onEnterQuantity={(e) => handleCoinChange(index, e.target.value)}
                />
              ))}
            </div>
            <div className="self-stretch w-[260px] flex flex-col items-center justify-center py-0 px-3.5 box-border gap-[4px] h-auto text-11xl text-global-white hover:flex hover:w-[260px] self-stretch h-auto flex-col gap-[4px] items-center justify-center py-0 px-3.5 box-border sm:w-[215px] sm:flex-col Tablet_768:w-[170px] Tablet_768:flex-col Tablet_600:w-[120px] Tablet_600:flex-col Mobile_480:flex-col mq1440:flex-1 mq1440:min-w-[250px] mq1440:max-w-[400px] mq320:flex-col">
              <img
                className="relative w-20 h-20 object-cover sm:w-[60px] sm:h-[60px] Tablet_768:w-14 Tablet_768:h-14 Tablet_600:w-[50px] Tablet_600:h-[50px] Mobile_480:w-[46px] Mobile_480:h-[46px] mq320:w-10 mq320:h-10"
                alt=""
                src="/cashbookcash-icon1@2x.png"
              />
              <div className="h-[38px] relative flex [text-shadow:0px_1.5px_3px_rgba(0,_0,_0,_0.2)] [-webkit-text-stroke:0px_#979797] font-mulish text-11xl text-center text-white-solid-white-100-ffffff flex [-webkit-text-stroke:0px_#979797] [text-shadow:0px_1.5px_3px_rgba(0,_0,_0,_0.2)] sm:text-7xl Tablet_768:text-lg Tablet_600:text-base Mobile_480:text-xl mq1440:text-16xl mq320:text-xl">
                {LL3().format.currency(totalCoinValue)}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-end justify-start py-0 pr-0 pl-16 text-4xl sm:gap-[20px] sm:pl-6 sm:box-border Tablet_768:pl-6 Tablet_768:box-border Tablet_600:gap-[16px] Tablet_600:pl-3 Tablet_600:box-border mq1440:pl-16 mq1440:box-border height_414:hidden">
          <div className="flex flex-row items-center justify-start gap-[28px] sm:gap-[24px] Tablet_768:gap-[16px] Tablet_600:gap-[12px]">
            <div className="rounded-md [background:linear-gradient(180deg,_#c6f38e,_#6a9a33)] w-[488px] h-[53px] flex flex-row items-center justify-center py-2.5 px-0 box-border sm:w-[450px] sm:h-[50px] Tablet_768:w-[340px] Tablet_768:h-12 Tablet_600:w-[220px] Tablet_600:h-10 mq1440:w-[488px] mq1440:h-14 height_414:h-10 height_414:items-center height_414:justify-center"
              onClick={() => {
                setSelectedCashbookView(CashbookViewList.CashbookCountingNext)
                setCounted(totalCashValue + totalCoinValue)
                setCoinValue(coinValueList)
                setCashValue(cashValueList)
              }}
              ref={makeRipple}
            >
              <div className="uppercase relative md:text-4xl sm:text-xl Tablet_768:text-lg Tablet_600:text-lg mq1440:text-6xl height_414:text-lg">
                {LL0().cashbook.next()}
              </div>
            </div>

            <img
              className="relative w-14 h-14 object-cover sm:h-[50px] Tablet_600:h-10"
              alt=""
              src="/icongeneralkeyboard-2-icon@2x.png"
              onClick={() => setOpenKeyboard(!openKeyboard)}
            />
          </div>
        </div>
        {openKeyboard && <OrderKeyboard1
          styles={{
            orderKeyboardMargin: "0 !important",
            orderKeyboardPosition: "absolute",
            orderKeyboardRight: "20px",
            orderKeyboardBottom: "28px",
            orderKeyboardHeight: "205px",
            orderKeyboardFlexShrink: "0",
            orderKeyboardZIndex: "2",
          }}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
        />}
      </div>
    </div>
  );
};

export default CashCounting;
