import {convertDocuments, type DocDeepSignal} from "@/data/data-utils";
import { signal, computed, effectOn, useAsyncEffect } from "@/react/core/reactive";
import {dataLock} from "@/data/DataUtils.ts";
import _ from 'lodash';
import uuid from 'time-uuid';
import { OpenHour, type TOpenHour } from "./OpenHour";

export const [openHours0, setOpenHours0] = signal<Array<DocDeepSignal<OpenHour>>>([]);
export const [openHoursV, setOpenHoursV] = signal(0);
effectOn([openHoursV], async () => {
  await dataLock.acquireAsync();
  const _openHours = await OpenHour.find().exec();
  const openHours = convertDocuments(_openHours, false);
  setOpenHours0(openHours);
})

export async function addOpenHour() {
  await OpenHour.insert({
    _id: uuid(),
    dayInWeeks: [true, true, true, true, true, true, true],
    openTime: "06:00",
    closeTime: "23:00",
    deliveryStart: "06:00",
    deliveryEnd: "23:00"
  })
  setOpenHoursV(v => v + 1);
}

export async function updateOpenHours(openHour: TOpenHour, change: any) {
  console.log('updateOpenHours', change)
  const oh = await OpenHour.findOne({selector: {_id: openHour._id}}).exec()
  await oh?.incrementalPatch(change);
  setOpenHoursV(v => v + 1);
}

export async function removeOpenHour(openHour: TOpenHour) {
  const oh = await OpenHour.findOne({selector: {_id: openHour._id}}).exec()
  console.log('removeOpenHour', oh);
  await oh?.remove();
  setOpenHoursV(v => v + 1);
}


export const makeOpenHoursAvailable = () => {
  useAsyncEffect(async () => {
    if (openHoursV() === 0) {
      await dataLock.acquireAsync();
      OpenHour.$.subscribe(change => setOpenHoursV(v => v+1))
      setOpenHoursV(1)
    }
  })
}