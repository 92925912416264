import type { RxCollection, RxDatabase } from 'rxdb'

import { PRODUCT_LAYOUT_COLLECTION_NAME, ProductLayout, productLayoutSchema } from './ProductLayout'
import { createCollection } from './utils/migration'

export async function createProductLayoutCollection<T>(database: RxDatabase<T>): Promise<RxCollection<ProductLayout>> {
  return await createCollection({
    database: database,
    collection: ProductLayout,
    collectionName: PRODUCT_LAYOUT_COLLECTION_NAME,
    version: productLayoutSchema.version,
    schema: productLayoutSchema,
  })
}
