import {FunctionComponent, memo} from 'react'
import StaffReportMainContent from './StaffReportMainContent'
import StaffReportBottomBar from './StaffReportBottomBar'

const StaffReportPlugin: FunctionComponent = () => {
  return (
    <div className="staff-report-screen bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[0px] hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start">
      <StaffReportMainContent />
      <StaffReportBottomBar />
    </div>
  )
}

export default memo(StaffReportPlugin)
