import { TextField } from "@mui/material";
import { type FunctionComponent, useMemo, type CSSProperties } from "react";

type CashBookInputType = {
  textLeftValue?: string;
  quantity?: string;
  onEnterQuantity?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isCoin?: boolean;
  isCash?: boolean;
  currencySymbol?: string;
  isSelected?: boolean;
  onSelect?: () => void
};

const CashBookInput: FunctionComponent<CashBookInputType> = (
  {
    textLeftValue = "100,000",
    quantity = "0",
    onEnterQuantity,
    isCoin,
    isCash,
    isSelected,
    onSelect
  }) => {
  return (
    <div className="rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] w-full flex flex-row items-center justify-start relative gap-[0px] text-center text-lg text-black-solid-black-700-364150 font-mulish hover:[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] hover:flex hover:w-full hover:h-[52px] hover:flex-row hover:gap-[0px] hover:items-center hover:justify-start hover:rounded-md sm:min-w-[160px] Tablet_768:min-w-[160px] Tablet_600:min-w-[140px] Mobile_480:min-w-[100px] mq1440:min-w-[360px] mq1440:max-w-[400px] mq1440:min-w-[400px] mq1440:max-w-[600px] mq320:min-w-[80px]"
         onClick={onSelect}
    >
      <div className="self-stretch flex flex-row items-center justify-center py-0 px-2 box-border relative gap-[2px] opacity-[0.9] min-w-[60px] z-[0] Tablet_768:min-w-[30px] Tablet_600:min-w-[30px] Mobile_480:min-w-[30px] mq320:min-w-[30px]">
        {isCash && (
          <div className="w-full !m-[0] absolute h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md [background:linear-gradient(180deg,_#c0ed83,_#89d81f)] overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border z-[0]">
            <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] z-[0]" />
          </div>
        )}
        {isCoin && (
          <div className="w-full !m-[0] absolute h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md [background:linear-gradient(180deg,_#ffda68,_#e3ac00)] overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border z-[1]">
            <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] z-[0]" />
          </div>
        )}
        {isSelected && (
          <div className="w-full !m-[0] absolute h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md [background:linear-gradient(180deg,_#81eaea_0.01%,_#36a9c2)] overflow-hidden flex flex-row items-center justify-center z-[2]">
            <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] z-[0]" />
          </div>
        )}
        <div
          className="flex-1 pr-2 relative leading-[18px] inline-block min-w-[100px] z-[4] Tablet_600:min-w-[80px] Tablet_768:text-base Tablet_600:text-mini Mobile_480:text-sm mq1440:text-xl mq1440:text-xl mq320:text-smi">
          {textLeftValue}
        </div>
      </div>
      <TextField
        className="w-full h-full bg-transparent border-none outline-0"
        value={quantity}
        onChange={onEnterQuantity}
      />
    </div>
  );
};

export default CashBookInput;