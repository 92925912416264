import { FunctionComponent } from 'react'
import LeftUserPermission from './LeftUserPermission'
import RightUserPermission from './RightUserPermission'
import {LL0} from "@/react/core/I18nService.tsx";

const UserPermissionsForm: FunctionComponent = () => {
  return (
    <div className="self-stretch overflow-hidden shrink-0 flex flex-col items-start justify-start py-0 px-6 gap-[16px_0px] text-left text-base text-black-solid-black-880-1d1d26 font-mulish sm:gap-[6px_0px] sm:pl-4 sm:pr-4 sm:box-border Tablet_768:gap-[4px_0px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:gap-[4px_0px] Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:pl-2.5 mq320:pr-2.5 mq320:box-border">
      <b className="relative sm:text-smi">{LL0().settings.userPermissions()}:</b>
      <div className="self-stretch flex flex-row items-start justify-start gap-[0px_44px] sm:gap-[0px_36px] Tablet_768:gap-[0px_30px] Tablet_600:gap-[0px_28px] Mobile_480:gap-[0px_20px] mq320:gap-[0px_16px]">
        <LeftUserPermission />
        <RightUserPermission />
      </div>
    </div>
  )
}

export default UserPermissionsForm
