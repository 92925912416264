import type { FunctionComponent } from "react";
import OrderItem from "@payment/OrderItem";
import ButtonGroup from "@payment/ButtonGroup";
import { order0, paymentContext0 } from "@/react/PaymentView/PaymentView.tsx";
import { useComputed } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

const SplitBill: FunctionComponent = () => {
  const {currentSeat, onNext} = paymentContext0;
  const activeSeatOrder = useComputed(() => {
    return order0()?.seatMap?.[currentSeat()];
  });
  const items = activeSeatOrder()?.items;
  let showNotice = items?.length === 0;
  let showButtonGroup = false;

  return (
    <div
      className="split-bill-payment !bg-[#EFEFEF] self-stretch flex-1 rounded-md bg-whitesmoke-500 box-border overflow-hidden flex flex-col items-start justify-between pt-3 px-0 pb-0 max-w-[350px] text-left text-mini text-gray-400 font-mulish border-[3px] border-solid border-palette-blue-style">
      <div className="self-stretch flex-1 flex flex-col items-start justify-start pt-0 px-2 pb-2 gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-between py-0 px-3">
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
            <b className="relative leading-[15px] inline-block w-[43px]">
              {LL0().payment.total()}:
            </b>
            <b className="relative text-smi text-tomato">{LL3().format.currency(activeSeatOrder()?.vSum || 0)}</b>
          </div>
          <div
            className="rounded-81xl bg-blue-solid-blue-300-3faaff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-center text-sm text-basic-color-white"
            onClick={onNext}>
            <img
                  className="relative w-[22px] h-[22px] object-cover hidden"
              alt=""
                  src="/payment-minusvector3.svg"
            />
            <b className="relative leading-[17px]">{LL0().posSetup.next()}</b>
          </div>
        </div>
        {!showNotice && activeSeatOrder() ?
        <div
          className="self-stretch rounded-md bg-white flex flex-col items-start justify-start p-px border-[1px] border-solid border-lightgray-200">
          {items?.map((item, index) => (
            item && <OrderItem key={index} item={item} order={activeSeatOrder()} itemName={item.name}
                               itemQuantity={item.quantity} seat={-1}
                               movedQuantity={item.movedQuantity} seatEnable={false}/>
          ))}
        </div>
          :
          <div
            className="self-stretch flex-1 rounded-md bg-basic-color-white flex flex-row items-start justify-start p-2 text-sm text-darkslategray-200 border-[1px] border-solid border-lightgray-100">
            <b className="flex-1 relative">{LL0().order.selectItemsFromTheRight()}</b>
          </div>}
      </div>
      {showButtonGroup &&
          <ButtonGroup/>
      }
    </div>
  );
};

export default SplitBill;
