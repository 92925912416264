import { FunctionComponent } from "react";
import PendingOrdersListItem from "./PendingOrdersListItem";
import {TOrder} from "../../../../src/pos/OrderType";
import _ from 'lodash';

type OrderListProps = TOrder & {
  showPrice?: boolean,
  showIcon?: boolean
  onOpenProductMatchDialog?: Function
}

const OrderList: FunctionComponent<OrderListProps> = (props) => {

  return (
    <div className="self-stretch flex flex-col items-center justify-start gap-[5px] z-[3] text-left text-sm text-black font-mulish">
       {_.map(props.items, (item, index) => <PendingOrdersListItem
          key={index}
          quantity={item.quantity}
          itemSName={item.name}
          variation={item.variation}
          itemModifier={_.map(item.modifiers, m => `x${m.quantity} ${m.name}`).join(', ')}
          itemNote={item.note}
          showPrice={props.showPrice}
          showIcon={props.showIcon}
          price={item.vSubTotal}
          taxes={item.taxes}
          groupPrinter={item.groupPrinter}
          groupPrinter2={item.groupPrinter2}
          labelPrinter={item.labelPrinter}
          onOpenProductMatchDialog={() => props.onOpenProductMatchDialog?.(index)}
       />)}
    </div>
  );
};

export default OrderList;
