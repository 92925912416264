import type { RxCollection, RxJsonSchema } from 'rxdb'

import { CUSTOMER_COLLECTION_NAME } from '@/data/Customer.ts'

export const CALL_COLLECTION_NAME = 'calls'
export const MISSED_CALL_COLLECTION_NAME = 'missed_calls'

export const VERSION = 2

export interface Call {
  _id?: string
  customerId?: string
  date?: number
  phoneNumber?: string
}

export const callSchema: RxJsonSchema<Call> = {
  title: 'call schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    customerId: { type: 'string', ref: CUSTOMER_COLLECTION_NAME, maxLength: 24 },
    date: { type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ** 10 },
    phoneNumber: { type: 'string', maxLength: 24 },
  },
  indexes: [],
}

export const Call = {} as RxCollection<Call>
export const MissedCall = {} as RxCollection<Call>

Object.assign(window, { Call, MissedCall }) // Make available globally
