import { captureException } from '@sentry/react'
import { toast } from 'react-toastify'

import { isRunningTestcase } from '@/data/PosSettingsSignal'

import { WebSrmCertificateError, WebSrmDocumentError, WebSrmInvalidResponseError, WebSrmTransactionError, WebSrmUserError, type WebSrmError } from '../errors'
import type { ResponseError } from './types'

export function logAndNotifySrmError(e: WebSrmError) {
  const srmErrors: ResponseError[] = []
  if (e instanceof WebSrmUserError && e.errors) srmErrors.push(...e.errors)
  if (e instanceof WebSrmCertificateError && e.errors) srmErrors.push(...e.errors)
  if (e instanceof WebSrmDocumentError && e.errors) srmErrors.push(...e.errors)
  if (e instanceof WebSrmTransactionError && e.errors) srmErrors.push(...e.errors)
  if (e instanceof WebSrmInvalidResponseError) {
    console.error('🛑 Srm Unknown Response Error', e)
    toast.error('Unknown response from WEB-SRM!')
  }

  if (srmErrors.length) {
    for (const err of srmErrors) {
      console.error('🛑 Srm Response Error', err)
      // Some error is missing return code `codeRetour`
      toast.error(`[${[err.codRetour, err.id].filter(Boolean).join('-')}] ${err.mess}`, { autoClose: false })
    }

    captureException(e, { data: srmErrors })
    if (isRunningTestcase()) throw e // Rethrow if running testcase
  }
}
