import {type FunctionComponent, useCallback, useMemo} from "react";
import {useSignal} from "@/react/core/reactive.ts";
import {type InputKeyboardProps, ShiftState, type SpecialKey} from "@/react/core/InputKeyboard.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import _ from "lodash";

type KeyboardWCloseIconType = {

};

const KeyboardWCloseIcon: FunctionComponent<InputKeyboardProps> = (props) => {

  let backspaceInterval: ReturnType<typeof setInterval>;
  const [shiftState, setShiftState] = useSignal<ShiftState>(ShiftState.NONE);

  const convertChar = (char: string) => {
    if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
      return char.toUpperCase();
    }
    return char.toLowerCase();
  };

  const downShift = () => {
    if (shiftState() === ShiftState.SHIFT) {
      setShiftState(ShiftState.NONE);
      return;
    }
  };

  const convertWithShift = (char: string) => {
    if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
      downShift();
      return char.toUpperCase();
    }
    return char.toLowerCase();
  };
  let shiftTimeout: ReturnType<typeof setTimeout>;
  const specialChars: Array<SpecialKey> = [{
    id: "shift",
    down() {
      console.log('shift');
      if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
        setShiftState(ShiftState.NONE);
      } else {
        setShiftState(ShiftState.SHIFT);
      }
      shiftTimeout = setTimeout(() => {
        setShiftState(ShiftState.CAPSLOCK);
      }, 500);
    },
    up() {
      console.log('up');
      clearTimeout(shiftTimeout);
    }
  }, {
    id: "special"
  }, {
    id: "EN"
  }, {
    id: "space",
    down: () => {
      handleButton(" ");
    }
  }, {
    id: "enter",
    down: () => {
      props.onEnter?.();
    }
  }, {
    id: "backspace",
    down: () => {
      props.inputController?.()?.setFocus(true);
      props.inputController?.()?.setFocusLock(true);
      props.inputController?.()?.backspaceHandle();
      backspaceInterval = setInterval(() => {
        props.inputController?.()?.setFocus(true);
        props.inputController?.()?.setFocusLock(true);
        props.inputController?.()?.backspaceHandle();
      }, 200);
    },
    up() {
      clearInterval(backspaceInterval);
    }
  }, {
    id: ",",
    down: () => {
      handleButton(",");
    }
  }, {
    id: ".",
    down: () => {
      handleButton(".");
    }
  }];
  const handleButton = (char: string) => {
    setTimeout(() => {
      props.inputController?.()?.setFocus(true);
      props.inputController?.()?.setFocusLock(true);
      props.inputController?.()?.addKey(convertWithShift(char));
    }, 20);
  };
  const map = useMemo(() => new WeakMap<HTMLDivElement, boolean>(), []);
  const refHandler = useCallback((ref: HTMLDivElement, char?: string) => {
    if (ref && !map.has(ref)) {
      makeRipple(ref);
      if (!char) char = ref.children[0]?.textContent!;
      ref.addEventListener('pointerdown', () => {
        if (char === 'shift') {
          _.find(specialChars, { id: 'shift' })?.down?.();
        } else if (char === 'backspace') {
          _.find(specialChars, { id: 'backspace' })?.down?.();
        } else if (char === 'space') {
          _.find(specialChars, { id: 'space' })?.down?.();
        } else if (char === 'enter') {
          _.find(specialChars, { id: 'enter' })?.down?.();
        } else {
          if (char){
            handleButton(char);
          }
        }
      })
      ref.addEventListener('pointerup', () => {
        if (char === 'shift') {
          _.find(specialChars, { id: 'shift' })?.up?.();
        } else if (char === 'backspace') {
          _.find(specialChars, { id: 'backspace' })?.up?.();
        } else if (char === 'space') {
          _.find(specialChars, { id: 'space' })?.up?.();
        }
      });
      map.set(ref, true);
    }
  }, [])

  return (
    <div className="flex flex-col items-start justify-start relative text-center text-3xl text-black-solid-black-1000-000000 font-mulish self-stretch">
      <div className="self-stretch rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-center py-2 px-2.5 gap-[8px] z-[0]">
        <div className="self-stretch flex flex-row items-center justify-center gap-[8px]">
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">1</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">2</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">3</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">4</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">5</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">6</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">7</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">8</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">9</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">0</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[8px]">
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('Q')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('W')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('E')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('R')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('T')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('Y')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('Y')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('I')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('O')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('P')}</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center py-0 px-[33px] gap-[8px]">
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('A')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('S')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('D')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('F')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('G')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('H')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('J')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('K')}</div>
          </div>
          <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
            <div className="relative">{convertChar('L')}</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[20px]">
          <div ref={r => refHandler(r!, 'shift')} className="self-stretch flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-3 px-[29px] box-border max-w-[80px]">
            <img
              className="relative w-[18.9px] h-[16.5px]"
              alt=""
              src='/shift.svg'
            />
          </div>
          <div className="flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">
            <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
              <div className="relative">{convertChar('Z')}</div>
            </div>
            <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
              <div className="relative">{convertChar('X')}</div>
            </div>
            <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
              <div className="relative">{convertChar('C')}</div>
            </div>
            <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
              <div className="relative">{convertChar('V')}</div>
            </div>
            <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
              <div className="relative">{convertChar('B')}</div>
            </div>
            <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
              <div className="relative">{convertChar('N')}</div>
            </div>
            <div ref={refHandler} className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-col items-center justify-center">
              <div className="relative">{convertChar('M')}</div>
            </div>
          </div>
          <div ref={r => refHandler(r!, 'backspace')} className="flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-3 px-7 box-border max-w-[80px]">
            <img
              className="relative w-[22.7px] h-[17px]"
              alt=""
              src='delete-button.svg'
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[8px] text-base">
          <div ref={refHandler}
               className="flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-row items-center justify-center p-2.5 box-border max-w-[150px]">
            <div className="relative tracking-[-0.32px] leading-[21px]">@</div>
          </div>
          <div ref={r => refHandler(r!, 'space')}
               className="flex-1 rounded-8xs-6 bg-gray-solid-gray-200-fcfcfe shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-row items-center justify-center p-2.5 box-border">
            <div className="relative tracking-[-0.32px] leading-[21px]">
              Space
            </div>
          </div>
          <div ref={refHandler}
               className="flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-row items-center justify-center p-2.5 box-border max-w-[150px]">
            <div className="relative tracking-[-0.32px] leading-[21px]">.</div>
          </div>
          <div ref={r => refHandler(r!, 'enter')}
               className="flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] h-[42px] flex flex-row items-center justify-center max-w-[150px]">
            <div className="relative tracking-[-0.32px] leading-[21px]">
              Save
            </div>
          </div>
        </div>
      </div>
      {/*{showCloseIcon && (*/}
      {/*  <img*/}
      {/*    className="absolute my-0 mx-[!important] top-[-17px] left-[642px] w-[25px] h-[25.4px] z-[1]"*/}
      {/*    alt=""*/}
      {/*    src='/close-icon.svg'*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default KeyboardWCloseIcon;
