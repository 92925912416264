import { makeRipple } from "@/react/core/ripple-utils.ts";
import { type FunctionComponent, useMemo, type CSSProperties } from "react";

export type VoucherKeyboardButtonKeybType = {
  keyNumber?: string;
  onClick?: () => void;

  /** Style props */
  voucherKeyboardButtonKeybMinWidth?: CSSProperties["minWidth"];
  voucherKeyboardButtonKeybMinHeight?: CSSProperties["minHeight"];
};

const VoucherKeyboardButtonKeyb: FunctionComponent<
  VoucherKeyboardButtonKeybType
> = ({
  keyNumber = "7",
  onClick,
  voucherKeyboardButtonKeybMinWidth,
  voucherKeyboardButtonKeybMinHeight,
}) => {
  const voucherKeyboardButtonKeybStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: voucherKeyboardButtonKeybMinWidth,
      minHeight: voucherKeyboardButtonKeybMinHeight,
    };
  }, [voucherKeyboardButtonKeybMinWidth, voucherKeyboardButtonKeybMinHeight]);

  return (
    <div
      className="rounded-10xs bg-white-solid-white-100-ffffff box-border overflow-hidden flex flex-row items-center justify-center min-w-[32px] min-h-[32px] text-center text-lg text-black-solid-black-900-1e1e23 font-mulish self-stretch flex-1 border-[1px] border-solid border-gray-solid-gray-600-979797"
      style={voucherKeyboardButtonKeybStyle} ref={makeRipple} onClick={onClick}
    >
      <b className="flex-1 relative leading-[17px]">{keyNumber}</b>
    </div>
  );
};

export default VoucherKeyboardButtonKeyb;