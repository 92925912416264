import { getApiUrl } from '@/shared/utils'
import { rnHost } from '@/shared/webview/rnwebview'
import { QuebecSDK } from '@/srm/lib/sdk'
import { posSetting0 } from "@/data/PosSettingsSignal.ts";

export function getSdk() {
  const { authCode, env } = posSetting0()?.srm ?? {}
  if (!authCode) throw new Error('Missing authCode!')

  const sdk = new QuebecSDK({
    relayFn: rnHost.relay,
    relayUrl: getApiUrl() + '/api/quebec-srm/relay',
    authCode,
    debug: import.meta.env.MODE !== 'production',
    isProd: env === 'PROD',
  })
  return sdk
}
