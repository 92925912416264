import { paymentHook } from "@/react/utils/hooks.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { lockCondition, runMasterHandler } from "@/data/OrderHub.ts";
import { MasterHandlerCommand, TseMethod } from "@/pos/OrderType.ts";
import { order0 } from "@/react/PaymentView/PaymentView.tsx";
import { onPrintTse } from "@/tse/tse-init.ts";

export function registerTseHook() {
  //1. tse condition
  lockCondition.v = () => {
    return tseConfig0().tseEnable || false;
  }

  paymentHook.on("preSplitHandler", async (tseMethod: TseMethod = TseMethod.apply) => {
    await runMasterHandler(order0(), [MasterHandlerCommand.preSplitHandler, tseMethod], true);
  })

  paymentHook.on("paySplitHandler", (cmds) => {
    if (tseConfig0().tseEnable) cmds.push(MasterHandlerCommand.paySplitHandler, TseMethod.apply);
  })

  paymentHook.on("payOrderHandler", (cmds) => {
    if (tseConfig0().tseEnable) cmds.push(MasterHandlerCommand.payOrderHandler);
  });

  //2. payment hook
  paymentHook.on('postKitchenPrint', async (order) => {
    await onPrintTse(order);
  })
}