import {convertDocuments, DocDeepSignal} from "@/data/data-utils";
import {GroupPrinter} from "@/data/GroupPrinter";
import { computed, effectOn, signal, useAsyncEffect } from "@/react/core/reactive";
import {dataLock} from "@/data/DataUtils.ts";

export const [groupPrinters0, setGroupPrinters0] = signal<Array<DocDeepSignal<GroupPrinter>>>([]);
export const [groupPrinterV, setGroupPrinterV] = signal(0);
effectOn([groupPrinterV], async () => {
  await dataLock.acquireAsync();
  const _groupPrinters = await GroupPrinter.find().exec();
  const groupPrinters = convertDocuments<GroupPrinter>(_groupPrinters, true, [], {debounce: 200});
  setGroupPrinters0(groupPrinters);
}, { defer: true })

export const makeGroupPrintersAvailable = () => {
  useAsyncEffect(async () => {
    if (groupPrinterV() === 0) {
      await dataLock.acquireAsync();
      GroupPrinter.$.subscribe((change) => {
        setGroupPrinterV(v => v + 1);
      })
      setGroupPrinterV(1);
    }
  }, [groupPrinterV()]);
};

export const kitchenGroupPrinters0 = computed(() => groupPrinters0().filter(g => g.type === "kitchen"));
export const invoiceGroupPrinters0 = computed(() => groupPrinters0().filter(g => g.type === "invoice"));
export const recheckGroupPrinters0 = computed(() => groupPrinters0().filter(g => g.type === "recheck"));
export const recheckInvoiceGroupPrinters0 = computed(() => {
  return recheckGroupPrinters0().filter(g => g.printers[0]?.includes?.includes(invoiceGroupPrinters0()?.[0]?._id!));
});
export const labelPrinters0 = computed(() => groupPrinters0().filter(g => g.type === "label")[0]);

//used for quick select printer
export const multiplePositionGroupPrinters = computed(() => groupPrinters0().filter(groupPrinter => groupPrinter.hasMultiplePositions))

// @ts-ignore
window.groupPrinters0 = groupPrinters0;

// @ts-ignore
window.recheckGroupPrinters0 = recheckGroupPrinters0;

// @ts-ignore
window.recheckInvoiceGroupPrinters0 = recheckInvoiceGroupPrinters0;