import { STATES, VIEW__MODIFIER } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils";
import { OPTION_DISPLAY_TYPES } from "@/data/Modifier";
import _ from "lodash";
import {onlineProviders0} from "@/data/OnlineProviderHub.ts";
import {MarketPlaceProvider} from "@/pos/OrderType.ts";

const ModifierTypeFieldset: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-lg text-gray-solid-gray-900-2e2e48-200 font-mulish-button-name-bold height_414:gap-[2px]">
      <div className="relative font-semibold lg:text-mid md:text-base height_414:text-smi">
        {LL0().editOnlineMenu.optionDisplayType()}
      </div>
      <div className="self-stretch rounded-10xs bg-light-blue shadow-[0px_2px_4px_rgba(0,_0,_0,_0.1)_inset,_0px_-1px_4px_rgba(0,_0,_0,_0.1)_inset] flex flex-row items-center justify-center py-1 px-2 gap-[4px] text-center text-lgi text-blue-solid-blue-300-0f56b3 sm:gap-[2px] sm:pl-1 sm:pr-1 sm:box-border">
        {Object.entries(OPTION_DISPLAY_TYPES).map(([key, val]) => (
        <button key={key} ref={makeRipple} onClick={() => VIEW__MODIFIER.onChanged('type', key)}
                disabled={key === "select" && !!onlineProviders0().find(p => p.name === MarketPlaceProvider.PIKAPOINT)}
                className="disabled:opacity-60 flex-1 rounded-3xs shrink-0 flex flex-row items-center justify-center py-[3px] px-2 relative gap-[6px] text-blue-solid-blue-900-081a51 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          {STATES.editingModifier()?.type === key && (
          <div className="absolute my-0 mx-[!important] h-full w-[calc(100%_+_0.1px)] top-[0px] right-[-0.1px] bottom-[0px] left-[0px] rounded-3xs bg-white-solid-white-100-ffffff shadow-[0px_1px_2px_rgba(15,_86,_179,_0.2),_0px_2px_4px_rgba(15,_86,_179,_0.2)] z-[0]" />
          )}
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium z-[1] md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            {_.get(LL0().editOnlineMenu, key)?.()}
          </div>
        </button>
        ))}
        {/*
        <div className="self-stretch flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            {LL0().editOnlineMenu.multiple()}
          </div>
        </div>
        <div className="self-stretch flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            {LL0().product.quantity()}
          </div>
        </div>
        <div className="self-stretch flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            {LL0().fnBtn.select()}
          </div>
        </div>
        */}
      </div>
    </div>
  );
};

export default ModifierTypeFieldset;
