import { secondsToHoursMinutes } from '@monthly-report/MonthtlyReportItemList.tsx'
import dayjs from 'dayjs'
import Debug from 'debug'
import _ from 'lodash'

import { dataLock } from '@/data/DataUtils.ts'
import { invoiceGroupPrinters0 } from '@/data/GroupPrinterHub.ts'
import { companyInfo0 } from '@/data/PosSettingsSignal.ts'
import type { TimeClock } from '@/data/TimeClock'
import { getEodReportPeriodAggregate } from '@/pos/logic/eod-calendar-utils.ts'
import type { MonthlyReport } from '@/pos/logic/ReportType.ts'
import { LL2 } from '@/react/core/I18nBackend.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { VPrinter } from '@/react/Printer/VPrinter.ts'
import { endDate, formatTotalHours, startDate } from '@/react/StaffReportView/StaffReportView.tsx'
import type { ScriptedRaster } from '@/shared/printer/types'

const debug = Debug('printer:staff-report')

export const printStaffReport = async (monthlyReport?: MonthlyReport, staffName?: string): Promise<ScriptedRaster | undefined> => {
  if (!monthlyReport || !staffName) {
    debug('monthlyReport or staffName is not defined')
    return
  }

  const { userSales, groupByPayment, totalCancelled, cashSalesByUserExcludingCashBack, cashlessSalesByUser, cashlessSalesByUserExcludingTip, totalTipByUser, voucherReportByUser } =
    monthlyReport.staffReport

  const { timeClocksByUser } = monthlyReport

  const { from, to } = monthlyReport.report

  const printer = new VPrinter({ ...invoiceGroupPrinters0()[0].printers[0] })

  const LL = LL2()

  function formatDate(val: number) {
    return val ? dayjs.unix(val).format(LL.dates.dateFormat()) : ''
  }

  if (monthlyReport.trainingMode) {
    await printer.bold(true)
    await printer.println(`**${LL0().settings.inTrainingMode().toUpperCase()}**`)
  }

  await printer.bold(true)
  await printer.alignLeft()
  await printer.println(`${LL.printing.staffName()}: ${staffName}`)

  if (userSales[staffName]) {
    await printer.println(`${LL.printing.reportDate()}: ${formatDate(from)}`)
    await printer.bold(false)
    // await printer.println(`${LL.printing.firstOrder()}: ${formatDate(userSales[staffName].from)}`);
    // await printer.println(`${LL.printing.lastOrder()}: ${formatDate(userSales[staffName].to)}`);
    await printer.println(`${LL.printing.firstOrder()}: ${dayjs.unix(from).format(`${LL.dates.shortDate()} HH:mm`)}`)
    await printer.println(`${LL.printing.lastOrder()}: ${dayjs.unix(to).format(`${LL.dates.shortDate()} HH:mm`)}`)
  }

  await printer.bold(true)
  await printer.drawLine()
  await printer.println(LL.printing.sales())

  if (userSales[staffName] && userSales[staffName].vTaxSum) {
    await printer.bold(false)
    await printer.leftRight(LL.printing.total(), LL.format.currency(userSales[staffName].vTaxSum.gross))
    await printer.leftRight(LL.printing.subTotal(), LL.format.currency(userSales[staffName].vTaxSum.net))
    await printer.leftRight(LL.printing.tax(), LL.format.currency(userSales[staffName].vTaxSum.tax))
  }

  await printer.drawLine()

  const groupByTax = userSales[staffName] && userSales[staffName].vTaxSum && userSales[staffName].vTaxSum.vTaxSum
  if (groupByTax) {
    await printer.bold(false)

    const taxGroups = Object.keys(groupByTax)
    for (let i = 0; i < taxGroups.length; i++) {
      const taxGroup = taxGroups[i]
      const { gross, net, tax } = groupByTax[taxGroup]

      await printer.println(`${LL.printing.tax()} ${taxGroup}%:`)
      await printer.leftRight(LL.printing.total(), LL.format.currency(gross))
      await printer.leftRight(LL.printing.subTotal(), LL.format.currency(net))
      await printer.leftRight(LL.printing.tax(), LL.format.currency(tax))
      await printer.newLine()
    }
  }

  //todo: voucherSold, voucherUsed
  if (voucherReportByUser[staffName]) {
    await printer.leftRight(LL.printing.voucherSold(), LL.format.currency(_.get(voucherReportByUser[staffName], 'soldTotal', 0)))
    for (const voucher of _.get(voucherReportByUser[staffName], 'soldVouchers')) {
      await printer.leftRight('-  ' + (voucher.code || ' '), LL.format.currency(voucher.price))
    }
    await printer.leftRight(LL.printing.voucherUsed(), LL.format.currency(_.get(voucherReportByUser[staffName], 'redeemedTotal', 0)))
    for (const voucher of _.get(voucherReportByUser[staffName], 'soldVouchers')) {
      await printer.leftRight('-  ' + (voucher.code || ' '), LL.format.currency(voucher.price))
    }
  }
  await printer.leftRight(LL.printing.discount(), LL.format.currency(_.get(userSales[staffName], 'vDiscount', 0)))
  await printer.drawLine()
  await printer.leftRight('Total refund', LL.format.currency(_.get(userSales[staffName], 'totalRefund', 0)))
  await printer.drawLine()

  const groupByPaymentStaff = groupByPayment[staffName]
  if (groupByPaymentStaff) {
    const paymentTypes = Object.keys(groupByPaymentStaff)
    for (let i = 0; i < paymentTypes.length; i++) {
      const paymentType = paymentTypes[i]
      if (paymentType) {
        const saleAmount = groupByPaymentStaff[paymentType]?.totalExcludeCashback
        // @ts-expect-error TODO: fix this typing
        await printer.leftRight(`${_.capitalize(LL.payment[paymentType]() || paymentType)}`, `${LL.format.currency(saleAmount || 0)}`)
      } else {
        console.warn(`paymentType is empty`)
      }
    }
    await printer.leftRight(`${LL.report.totalCashlessSales()}`, `${LL.format.currency(cashlessSalesByUser[staffName] || 0)}`)
    await printer.leftRight(`${LL.printing.totalTip()}`, `${LL.format.currency(totalTipByUser[staffName] || 0)}`)
    if (companyInfo0()?.country !== 'de') {
      await printer.println(`${LL.report.totalCashlessSalesExcludingTip()}: ${LL.format.currency(cashlessSalesByUserExcludingTip[staffName] || 0)}`)
      await printer.println(`${LL.report.totalCashSalesExcludingCashback()}: ${LL.format.currency(cashSalesByUserExcludingCashBack[staffName] || 0)}`)
    }

    await printer.println(`${LL.printing.returnedTotal()}: ${LL.format.currency(totalCancelled[staffName] || 0)}`)
    await printer.drawLine()
  }

  let totalHours: number
  let timeClocks: TimeClock[]

  if (!timeClocksByUser) {
    await dataLock.acquireAsync()
    const data = await getEodReportPeriodAggregate(startDate().unix(), endDate().unix())
    const userData = data?.timeClocksByUser?.[staffName] || { timeClocksByDate: [], totalHours: 0 }
    totalHours = userData.totalHours
    timeClocks = _.map(_.flatMap(_.values(userData.timeClocksByDate)), ({ clockInTime, clockOutTime }) => ({ clockInTime, clockOutTime }))
  } else {
    const userData = _.get(timeClocksByUser, staffName, { timeClocksByDate: [], totalHours: 0 })
    totalHours = userData.totalHours
    timeClocks = _.map(_.flatMap(_.values(userData.timeClocksByDate)), ({ clockInTime, clockOutTime }) => ({ clockInTime, clockOutTime }))
  }

  await printer.leftRight(`Total hours: `, `${formatTotalHours(totalHours)}`)
  await printer.tableCustom([{ text: '', width: 0.05, bold: false, align: 'LEFT' }])
  if (timeClocks?.length > 0) {
    for (const timeClock of timeClocks) {
      if (timeClock.clockOutTime) {
        await printer.leftRight('Shift', `${secondsToHoursMinutes(timeClock?.clockOutTime - (timeClock?.clockInTime ?? 0))}`)
        await printer.tableCustom([{ text: '', width: 0.05, bold: false, align: 'LEFT' }])
        await printer.tableCustom([
          {
            text: `   ${dayjs.unix(timeClock.clockInTime || 0).format(LL.staffReport.dateTimeFormat())}`,
            width: 0.95,
            bold: false,
            align: 'LEFT',
          },
        ])
        await printer.tableCustom([{ text: '', width: 0.05, bold: false, align: 'LEFT' }])
        await printer.tableCustom([
          {
            text: `   ${dayjs.unix(timeClock.clockOutTime).format(LL.staffReport.dateTimeFormat())}`,
            width: 0.95,
            bold: false,
            align: 'LEFT',
          },
        ])
        await printer.tableCustom([{ text: '', width: 0.05, bold: false, align: 'LEFT' }])
      }
    }
  }

  // const raster: Raster = (await printer.print())!;
  const raster = await printer.getRaster()
  return raster
}
