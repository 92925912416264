import _ from 'lodash'
import {convertDocuments, type DocDeepSignal} from "@/data/data-utils";
import {Payment} from "@/data/Payment";
import {computed, effectOn, signal, useAsyncEffect} from "@/react/core/reactive";
import {dataLock} from "@/data/DataUtils.ts";
import {useEffect} from "react";
import {terminals0} from "@/data/TerminalHub.ts";

export const [payments0, setPayments0] = signal<Array<DocDeepSignal<Payment>>>([]);
// @ts-ignore
window.payments0 = payments0;
export const [paymentV, setPaymentV] = signal(0);
export const paymentsMap = computed(() => {
  const lackEnableTip = payments0().filter(p => !p.hasOwnProperty('enableTip'));
  if (lackEnableTip?.length > 0) {
    payments0().forEach(p => {
      if (!p.hasOwnProperty('enableTip')) {
        _.assign(p, {enableTip: true});
      }
    })
  }
  const payments = payments0();
  const map = new Map<string, DocDeepSignal<Payment>>();
  for (const payment of payments) {
    map.set(payment.name!, payment);
  }
  return map;
});
effectOn([paymentV], async () => {
  await dataLock.acquireAsync();
  const _payments = await Payment.find().exec();
  const payments = convertDocuments<Payment>(_payments, true, [], {debounce: 200});
  setPayments0(payments);
}, { defer: true })


export const paymentsWithTerminal = computed(() => {
  return payments0().filter(pm => pm.terminalIds && pm.terminalIds.length).map(pm => ({
    ...pm,
    terminals: _.map(pm.terminalIds, id => terminals0().find(terminal => terminal._id === id))
  }))
})
export const makePaymentsAvailable = () => {
  useAsyncEffect(async () => {
    if (paymentV() === 0) {
      await dataLock.acquireAsync();
      Payment.$.subscribe((change) => {
        setPaymentV(v => v + 1);
      })
      setPaymentV(1);
    }
  }, [paymentV()]);
};

export function getPaymentIcon(payment: {type: string}) {
  return paymentsMap().get(payment.type!)?.icon;
}

export const cashPaymentName0 = computed(() => {
  return payments0().find(p => {
    if (p.type === "cash") return true;
    if (p.name === "Bar" || p.name === "bar") return true;
    if (p.name === "cash" || p.name === "Cash") return true;
  })?.name || 'cash';
})


export const debitorPayment0 = computed(() => {
  return payments0().find(p => {
    if (p.type === "debitor") return true;
    if (p.name === "Debitor" || p.name === "Debitor") return true;
    // if (p.name === "cash" || p.name === "Cash") return true;
  })?.name || 'debitor';
})

// export const kitchenPayments0 = computed(() => payments0().filter(g => g.type === "kitchen"));
// export const invoicePayments0 = computed(() => payments0().filter(g => g.type === "invoice"));
