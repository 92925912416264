import { FunctionComponent } from "react";
import { payment0 } from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import _ from 'lodash';
import { LL0 } from '@/react/core/I18nService.tsx'

export type TaxPaymentPaymentIconItemType = {
  paymentIconLabel?: string;
  iconImageUrl?: string;
  isSelected?: boolean;
  type?: string;
};

const TaxPaymentPaymentIconItem: FunctionComponent<
  TaxPaymentPaymentIconItemType
> = ({ paymentIconLabel = "Cash", iconImageUrl, isSelected = true, type = "none" }) => {
  return (
    <div className="h-10 flex flex-row items-center justify-between text-left text-lg text-black font-mulish self-stretch">
      <div className="flex flex-row items-center justify-start gap-[0px_12px]">
        <input
          className="cursor-pointer relative w-5 h-5"
          type="radio"
          name="icon"
          checked={payment0()?.icon === iconImageUrl}
          onChange={e => e.target.checked && _.assign(payment0(), { icon: iconImageUrl, name: paymentIconLabel, type: type })}
        />
        <div className="relative">{_.get(LL0().payment, paymentIconLabel.toLowerCase())?.() || paymentIconLabel}</div>
      </div>
      <img
        className="relative w-8 h-8 object-contain"
        alt=""
        src={iconImageUrl}
      />
    </div>
  );
};

export default TaxPaymentPaymentIconItem;
