import { currentPrinter } from "@/react/Printer/PrinterSettingView.tsx";
import { VPrinter } from "@/react/Printer/VPrinter.ts";


export const testSerialPortPrinter = async () => {
  const printer = new VPrinter(currentPrinter());
  await printer.println("TEST");
  await printer.print();
}

//@ts-ignore
window.testSerialPortPrinter = testSerialPortPrinter;