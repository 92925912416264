import { PosScreen } from '@/pos/PosRouter.ts';

import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { loginUser } from "@/data/UserSignal.ts";

export enum AccessPort {
  DISCOUNT = "Discount",
  DELETE_PRINTED_ITEM = "DeletePrintedItem",
  EDIT_ITEM_PRICE = "EditItemPrice",
  EDIT_HIGH_SECURITY_SETTING = "HighSecuritySetting",
  OPEN_CASH_DRAWER_ANY_TIME = "OpenCashDrawer"
}

export const getUserSettingAccess = (accessPort: PosScreen | AccessPort) => {
  if (loginUser()?.name === "admin") return true;

  // w user setting
  switch (accessPort) {
    case PosScreen.ORDER_HISTORY:
      return loginUser()?.viewOrderHistory;
    case PosScreen.EDIT_MENU:
      return loginUser()?.editMenuLayout;
    case PosScreen.STAFF_REPORT:
      return loginUser()?.viewOtherStaffReport || loginUser()?.viewOwnReport;
    case PosScreen.EDIT_FLOOR_PLAN:
      return loginUser()?.editTablePlan;
    case PosScreen.MONTHLY_REPORT:
      return loginUser()?.viewMonthlyReport;
    case PosScreen.CUSTOMER_INFO:
      return loginUser()?.viewCustomerInfo;
    case AccessPort.DISCOUNT:
      return loginUser()?.discount;
    case AccessPort.DELETE_PRINTED_ITEM:
      return loginUser()?.deletePrintedItem;
    case PosScreen.EOD:
      return loginUser()?.accessZReport;
    case PosScreen.INVENTORY:
      return loginUser()?.manageInventory;
    case AccessPort.EDIT_ITEM_PRICE:
      return loginUser()?.editThePriceOfAMenuItem;
    case PosScreen.HAPPY_HOUR:
      return loginUser()?.editHappyHours;
    case PosScreen.VIRTUAL_PRINTER:
      return true; // Allowed for all user
    case AccessPort.EDIT_HIGH_SECURITY_SETTING:
      return loginUser()?.editHighSecuritySetting;
    case AccessPort.OPEN_CASH_DRAWER_ANY_TIME:
      return loginUser()?.openCashDrawer;
  }

  return false;
}

export const getGeneralSettingAccess = (accessPort: PosScreen | AccessPort) => {
  switch (accessPort) {
    case PosScreen.VIRTUAL_PRINTER:
      return generalSetting0()?.useVirtualPrinter;
    case PosScreen.INVENTORY:
      return generalSetting0()?.runInventory;
    case PosScreen.CASHBOOK:
      return generalSetting0()?.cashbookEnable;
  }

  return true;
}

export const getAccessibility = (accessPort: PosScreen | AccessPort) => {
  return getGeneralSettingAccess(accessPort) && getUserSettingAccess(accessPort)
}