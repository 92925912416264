import { type FunctionComponent, useEffect, useState } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import type { OrderStrip } from "@/pos/OrderType.ts";
import { mergeTable } from "@/react/FloorPlanView/floor-utils.ts";
import { clsx } from "clsx";
import { makeRipple } from "../core/ripple-utils";
import { setChangeActiveV } from "@/react/FloorPlanView/FloorPlanView.tsx";
import { useSignal } from "@/react/core/reactive.ts";


export type MergeTablePopupType = {
  className?: string;
  onClose?: () => void;
  order?:  OrderStrip;
  currentOrder?: OrderStrip;
};

const MergeTablePopup: FunctionComponent<MergeTablePopupType> = ({
  className = "",
  onClose,
  order,
  currentOrder
}) => {
  const [isRemainSeat, setIsRemainSeat] = useSignal<boolean>(true);

  return (
    <div
      className={`w-full font-medium py-[24px] gap-[24px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center px-8 box-border min-w-[300px] max-w-[674px] h-full max-h-full text-center text-base font-mulish ${className}`}
    >
        <div className="text-[16px] text-[#02519A] whitespace-normal self-stretch relative leading-[28px]"
             dangerouslySetInnerHTML={{ __html: LL0().dialogs.moveOrder.moveEntire({B: order?.table, A: currentOrder?.table})}}>
        </div>
      {!order?.seatMode && (
        <div
        className="text-[#424242] pb-3 gap-[16px] self-stretch flex flex-col items-center justify-center">
        <div
          className={clsx("w-full text-[16px] relative drop-shadow-sm rounded-md border-[#C9CBCC] border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer", isRemainSeat() ? "bg-[#CEFFFF]" : "bg-[#F4F4F4]")}
          onClick={() => {
            setIsRemainSeat(true)
          }}
          ref={makeRipple}
        >
          {LL0().dialogs.moveOrder.split()}
        </div>
        <div
          className={clsx("w-full text-[16px] relative drop-shadow-sm rounded-md border-[#C9CBCC] border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer", isRemainSeat() ? "bg-[#F4F4F4]" : "bg-[#CEFFFF]")}
          onClick={() => {
            setIsRemainSeat(false)
          }}
          ref={makeRipple}
        >
          {LL0().dialogs.moveOrder.notSplit()}
        </div>
      </div>
      )}
      {order?.seatMode && (
        <div className={'font-normal text-[#424242] self-stretch item-center gap-y-[24px] italic'}>
          {LL0().dialogs.moveOrder.note()}
        </div>
      )}
      <div
        className="pb-3 gap-[20px] flex flex-row items-center justify-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="w-full rounded-md bg-white-solid-white-100-ffffff border-[#979797] border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer"
          onClick={() => {
            onClose?.();
            setChangeActiveV(v => v + 1);
          }}
          ref={makeRipple}
        >
          <b className="text-[16px] text-[#979797] relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="w-full rounded-md bg-[#2979FF] border-blue-solid-blue-420-2979ff border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer"
          onClick={async() => {
            if (isRemainSeat()) {
              await mergeTable(order, currentOrder, true)
            } else {
              await mergeTable(order, currentOrder, false)
            }
            onClose?.()
          }}
          ref={makeRipple}
        >
          <b className="text-[16px] text-white relative">{LL0().ui.confirm()}</b>
        </div>
      </div>
    </div>
  );
};

export default MergeTablePopup;
