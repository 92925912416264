import dayjs from 'dayjs'
import debug from 'debug'
import { clone } from 'json-fn'
import _ from 'lodash'
import pTimeout, { TimeoutError } from 'p-timeout'
import { toast } from 'react-toastify'
import uuid from 'time-uuid'

import { convertDocuments, type DocDeepSignal } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils.ts'
import { maxId0 } from '@/data/MaxIdHub.ts'
import { Order, type OrderDocMethods, PaidOrder } from '@/data/Order'
import { rePrintInvoice, runMasterHandlerAfterPaid } from '@/data/OrderHub.ts'
import { isQuebecSrmEnabled } from '@/data/PosSettingsSignal.ts'
import { shouldWarnBecauseOffline } from '@/data/ReplicateEffect.ts'
import { tseConfig0 } from '@/data/TseConfigHub.ts'
import { TxLogCollection } from '@/data/TxLog'
import { type TxRefundLog, TxRefundLogCollection } from '@/data/TxRefundLog'
import { isValidSrmUser } from '@/data/UserHub.ts'
import { loginUser } from '@/data/UserSignal.ts'
import { createOrder, stripOrder } from '@/pos/logic/order-reactive.ts'
import { isCashPayment, makeCancelOrderNoTse, patchOrder, remakeReactiveOrder } from '@/pos/logic/order-utils.ts'
import { now } from '@/pos/logic/time-provider.ts'
import { InvoiceTypes, MasterHandlerCommand, OrderStatus, type OrderStrip, Reason } from '@/pos/OrderType'
import { getVDateDayjs } from '@/pos/orderUtils.ts'
import { createCashSale } from '@/react/CashbookView/cashbook-logic.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { computed, deepSignal, effectOn, selector, signal, useAsyncEffect } from '@/react/core/reactive'
import { preventInsufficientPayment } from '@/react/PaymentView/payment-check.ts'
import {
  assignZId,
  cloneNewOrder,
  makeRefundOrder,
  makeRefundOrderAmount,
  makeRefundOrderRaw
} from '@/react/PaymentView/payment-utils.ts'
import {
  order0 as paymentOrder,
  setCurrentPaymentName0,
  setOrder0 as setPaymentOrder,
  setValue0
} from '@/react/PaymentView/PaymentView.tsx'
import { bound, handleError, type MethodDecorator, progressDialog } from '@/shared/decorators'
import { srmTransactionLogic } from '@/srm/transaction.logic'

import dialogService from '../SystemService/dialogService'
import msgBox from '../SystemService/msgBox'
import { refundTx } from '../Terminal'
import { recordUserActionHistory, UserAction, UserActionHistory } from "@/data/UserActionHistory.ts";
import { generateUserActionMessages } from "@/shared/user-action-share.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";

const log = debug('data:order-history')

export const [order0, setOrder0] = signal<OrderStrip>(createOrder())

Object.assign(window, { orderH0: order0 }) // Debug

/**
 * use by order history
 */
const ORDERS_PER_PAGE = 100

export const [orders1, setOrders1] = signal<Array<DocDeepSignal<OrderStrip, OrderDocMethods>>>([])
export const [ordersPage, setOrdersPage] = signal<number>(0)
export const [isValidatingOrders, setIsValidatingOrders] = signal<boolean>(true)
export const [isReachingEnd, setIsReachingEnd] = signal<boolean>(false)
export const [orderV1, setOrderV1] = signal<number>(0)
export const isCurrentOrder = selector<OrderStrip, string | undefined>(order0, (p1: any, p2: {
  _id: any
}) => p2?._id === p1)

export const [filterOrderHistory, setFilterOrderHistory] = signal<any>({})
export const [typePaymentFilter, setTypePaymentFilter] = signal<string>('')
export const [datePickerFilter, setDatePickerFilter] = signal<{ from: number; to: number; choose?: number }>()
export const [typeOrderFilter, setTypeOrderFilter] = signal<{ type: string; custom: string }>()
export const [staffCustomerFilter, setStaffCustomerFilter] = signal<string>('')

export const [isFirstRender, setIsFirstRender] = signal<boolean>(true)
export const [isPrinted, setIsPrinted] = signal<boolean>(false)
export const [showUserAction, setShowUserAction] = signal<boolean>(true)
export const [isOpenRefundItemsPopup, setIsOpenRefundItemsPopup] = signal(false)
export const [isOpenRefundReasonPopup, setIsOpenRefundReasonPopup] = signal(false)
// export { isOpenRefundItemsPopup, isOpenRefundReasonPopup }
export const [userActions0, setUserActions0] = signal<string[]>([])
const disabledStatuses = [OrderStatus.REFUNDED, OrderStatus.CANCELLED, OrderStatus.CANCELLED_BEFORE_PAID, OrderStatus.CANCELLATION_REFERENCE]
export const isDisabled = computed(() => !order0() || disabledStatuses.includes(order0().status))
export const shouldAllowRePrint = computed(() => {
  const status = order0()?.status
  const blackList = [OrderStatus.CANCELLATION_REFERENCE, OrderStatus.CANCELLED, OrderStatus.CANCELLED_BEFORE_PAID]
  return !!status && !blackList.includes(status)
})

export const getUserActionsOrder = async (order: OrderStrip) => {
  const _userActions = await UserActionHistory.find({
    selector: { orderId: order._id },
    sort: [{ date: 'asc' }]
  }).exec()
  const userActions = _userActions.map(a => a.toMutableJSON())
  setUserActions0(generateUserActionMessages(userActions))
}

export const checkDatePrinted = (date?: number) => {
  if (!maxId0()?.lastEodCompleted || (date && maxId0()?.lastEodCompleted! < date)) {
    setIsPrinted(false)
  } else {
    setIsPrinted(true)
  }
}
export const handleFilterOrderHistory = () => {
  if (typePaymentFilter()) {
    setFilterOrderHistory(p => ({ ...p, payments: { $elemMatch: { type: typePaymentFilter() } } }))
  } else {
    setFilterOrderHistory(prev => {
      if (!prev?.payments) return prev
      const { payments, ...rest } = prev
      return rest
    })
  }
  if (datePickerFilter()?.from && datePickerFilter()?.to) {
    setFilterOrderHistory(p => ({
      ...p,
      date: { $gt: datePickerFilter().from / 1000, $lt: datePickerFilter().to / 1000 }
    }))
  } else {
    setFilterOrderHistory(prev => {
      if (!prev?.date) return prev
      const { date, ...rest } = prev
      return rest
    })
  }
  if (staffCustomerFilter()) {
    setFilterOrderHistory(p => ({ ...p, users: { $eq: staffCustomerFilter() } }))
  } else {
    setFilterOrderHistory(prev => {
      if (!prev?.users) return prev
      const { users, ...rest } = prev
      return rest
    })
  }
  if (typeOrderFilter()?.type || typeOrderFilter()?.custom || filterOrderHistory()?.takeAway) {
    if (typeOrderFilter()?.type === 'takeAway') {
      setFilterOrderHistory(p => ({ ...p, takeAway: true }))
    } else {
      setFilterOrderHistory(prev => {
        if (!prev?.takeAway) return prev
        const { takeAway, ...rest } = prev
        return rest
      })
    }
  } else {
    setFilterOrderHistory(prev => {
      if (!prev?.takeAway) return prev
      const { takeAway, ...rest } = prev
      return rest
    })
  }
  setOrdersPage(0)
  setOrderV1(p => p + 1)
}
// refresh table data when change user login
effectOn([loginUser], () => {
  setOrdersPage(0)
  setOrderV1(p => p + 1)
})

const loadingLock = new MultiAwaitLock(true);
effectOn(
  [ordersPage, orderV1, loginUser],
  async () => {
    const selector = {
      status: { $in: [OrderStatus.PAID, OrderStatus.CANCELLED, OrderStatus.CANCELLED_BEFORE_PAID, OrderStatus.REFUNDED, OrderStatus.CANCELLATION_REFERENCE, OrderStatus.DEBITOR] },
      ...filterOrderHistory(),
    }

    if (loginUser()?.role === 'user') {
      selector.users = {
        $elemMatch: { $eq: loginUser()?.name },
      }
    }
    await dataLock.acquireAsync() // Ensure data is initialized
    const _orders = await PaidOrder.find({
      selector,
      limit: ORDERS_PER_PAGE,
      skip: ORDERS_PER_PAGE * (ordersPage() - 1),
      sort: [
        {
          date: 'desc',
        },
      ],
    }).exec()
    const orders = convertDocuments<OrderStrip, OrderDocMethods>(_orders, true)
    // validate end on page change
    setIsReachingEnd(orders.length === 0)

    if (ordersPage() === 1) {
      setOrders1([...orders])
      if (isFirstRender()) {
        setOrder0(orders[0] as Order)
        setIsFirstRender(false)
      }
    } else {
      setOrders1(prev => _.uniqBy([...prev, ...orders], '_id'))
    }

    setIsValidatingOrders(false)
    console.log('trigger page change, page', ordersPage())
    loadingLock.release().then();
  },
  { defer: true }
)

export const handleRePrintOrder = async (invoiceType: InvoiceTypes) => {
  if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
  if (tseConfig0()?.tseEnable) {
    return await runMasterHandlerAfterPaid(order0() as Order, [MasterHandlerCommand.rePrintInvoice, invoiceType.toString()])
  }
  if (isQuebecSrmEnabled()) {
    // TODO: handle logic for difference invoiceType
    return await srmTransactionLogic.reproduceBill(order0())
  }
  return rePrintInvoice(order0() as Order, invoiceType)
}

export const handleCancelOrder = async () => {
  if (tseConfig0()?.tseEnable) {
    const closeDialog = dialogService.progress({ title: `${LL0().orderHistory.cancelling()}...` })
    try {
      await pTimeout(runMasterHandlerAfterPaid(order0() as Order, [MasterHandlerCommand.doCancelOrder]), { milliseconds: 5000 }) // 5 seconds
      _.assign(order0(), { status: OrderStatus.CANCELLED })
      closeDialog?.()
    } catch (error) {
      if (error instanceof TimeoutError) {
        closeDialog?.()
        toast(LL0().orderHistory.masterDeviceOffline(), { type: 'error' })
        return
      } else {
        throw error
      }
    }
  } else if (isQuebecSrmEnabled()) {
    throw new Error('You cannot cancel Order in srm mode!')
  } else {
    if (isCashPayment(order0())) {
      const _order = await makeCancelOrderNoTse(order0())
      await createCashSale(_order)
    }
    await patchOrder(order0() as Order, { status: OrderStatus.CANCELLED, reason: Reason.CANCELLED })
    _.assign(order0(), { status: OrderStatus.CANCELLED, reason: Reason.CANCELLED })
  }
  // await doCancelOrder(order0() as Order)
}

export const makeOrdersPageAvailable = () => {
  useAsyncEffect(async () => {
    if (ordersPage() === 0) {
      await dataLock.acquireAsync()

      //comment this when multiple generate?
      PaidOrder.$.subscribe(change => {
        setIsValidatingOrders(true)
        setOrdersPage(1)
      })
      setIsValidatingOrders(true)
      setOrdersPage(1)
    }
  }, [ordersPage()])
}

//handle refund

//-> equal order0 but reactive, use for changePayment, Refund
export const [order1, setOrder1] = signal<Order>()
export const [currentOrderInitialSum, setCurrentOrderInitialSum] = signal<number>(0)
export const [fullRefund, setFullRefund] = signal<boolean>(false)
export const [refundReason, setRefundReason] = signal<string>('')

export enum RefundMode {
  AMOUNT = 'amount',
  ITEMS = 'items',
}

//todo: binding refundMode
export const [refundMode, setRefundMode] = signal<RefundMode>(RefundMode.ITEMS)

//todo: binding refundAmount
export const [refundAmount, setRefundAmount] = signal<number>(0)

export const makeSelectedOrderReactive = (removeCancellationItems: boolean = true) => {
  const order = remakeReactiveOrder(order0(), removeCancellationItems)
  setOrder1(order)
  setCurrentOrderInitialSum(order.vSum ?? 0)
}

export const mapRefundItems = deepSignal<{ [key: string]: number }>({})

export function extractMapItems() {
  const items = order1()?.items || []
  for (const { _id = '', vPrice } of items) {
    mapRefundItems[_id] = vPrice
  }
}

export const [order2, setOrder2] = signal<Order>()

export const selectedRefundAmount = computed(() => {
  if (fullRefund()) return order1()?.vSum || 0
  return order2()?.vSum || 0
})
export const [isPaymentVerticalAddPaymentOpen, setPaymentVerticalAddPaymentOpen] = signal(false)

// Make global to easier debug
Object.assign(window, {
  order1: order1,
  order2: order2,
  mapRefundItems,
})

async function handleAfterCreateNegativeOrder(negativeOrder: Order) {
  _.assign(order0(), { status: OrderStatus.REFUNDED, ...(refundReason() && { refundReason: refundReason() }) })
  setFullRefund(false)
  setRefundReason('')

  if (tseConfig0()?.tseEnable) {
    // TODO: handle TSE here
  } else if (isQuebecSrmEnabled()) {
    if (!negativeOrder.refOrder) throw new Error('Origin order not found!')
    await srmTransactionLogic.recordRefund(negativeOrder, { print: true })
  }
  const refundAmount = Math.abs(negativeOrder.vSum!)
  await orderHistoryLogic.handleRefundTx(negativeOrder.refOrder!, refundAmount)

  setOrderV1(p => p + 1)
  return true
}

export async function changeStatusAfterRefund() {
  const _originalOrder = await PaidOrder.findOne({selector: {_id: order0()._id}}).exec();
  await _originalOrder?.incrementalPatch({status: OrderStatus.REFUNDED})
}


@bound()
class OrderHistoryLogic {
  @progressDialog(() => ({ title: `${LL0().onlineOrder.refund()}...` }))
  @handleError()
  async handleRefund() {
    if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

    if (refundMode() === RefundMode.AMOUNT) {
      const amount = refundAmount()
      if (!amount || amount < 0) throw new Error(`Invalid amount [${amount}]`)
      const negativeOrder = await makeRefundOrderAmount(order1(), amount)
      //fixme: handle cashbook in QuebecSRM
      if (!isQuebecSrmEnabled() && isCashPayment(negativeOrder)) {
        await createCashSale(negativeOrder)
      }
      await changeStatusAfterRefund()
      await handleAfterCreateNegativeOrder(negativeOrder);

    } else {
      const negativeOrder = await makeRefundOrder(order1(), fullRefund(), mapRefundItems, currentOrderInitialSum())
      if (!isQuebecSrmEnabled() && isCashPayment(negativeOrder)) {
        await createCashSale(negativeOrder)
      }
      await changeStatusAfterRefund()
      await handleAfterCreateNegativeOrder(negativeOrder)
    }
    setIsOpenRefundItemsPopup(false)
    setIsOpenRefundReasonPopup(false)
    await getUserActionsOrder(order1())
  }

  //#region Payment

  @handleError()
  async changePaymentClick() {
    if (!isQuebecSrmEnabled()) {
      setPaymentOrder(createOrder(clone(order0())))
    } else {
      //todo: create negative order
      const _order = await cloneNewOrder(order0() as Order)
      setOrder1(_order)
      setPaymentOrder(_order)
      setCurrentOrderInitialSum(_order.vSum ?? 0)
    }
    const firstPayment = order0().payments[0]
    setValue0(firstPayment.value.toString())
    setCurrentPaymentName0(firstPayment.type)
  }

  @progressDialog(() => ({ title: `${LL0().onlineOrder.changingPaymentMethod()}...` }))
  @handleError()
  async completePaymentChange(): Promise<boolean | undefined> {
    const paymentCheckResult = preventInsufficientPayment(order0() as Order)
    if (!paymentCheckResult) return false
    const { payments, tip, serviceFee, cashback, vTotal, cashTip, manualTip } = stripOrder(paymentOrder())
    if (!isQuebecSrmEnabled()) {
      _.assign(order0(), { payments, tip, serviceFee, cashback, vTotal, cashTip, manualTip})
    } else {
      await srmTransactionLogic.changePaymentMethod(order0() as Order, order1(), async () => {
        await assignZId(getVDateDayjs(dayjs(now())), order1())
      })
    }
    loadingLock.tryAcquire();
    setOrderV1(p => p + 1)
    await loadingLock.acquireAsync();
    setOrder0(orders1().find(o => o._id === order0()._id) as any);
    return true
  }

  //#endregion Payment

  @handleTxError()
  async handleRefundTx(orderId: string, amount: number) {
    console.log('[onRefund] order', orderId)
    if (!amount) throw new InsufficientAmountError()

    console.log('[onRefund] searching refund result...')
    const txRefund = await TxRefundLogCollection.findOne({ selector: { orderId } }).exec()
    if (txRefund) throw new OrderAlreadyRefundedError(txRefund._data)

    console.log('[onRefund] order is not refund yet, searching transaction...')
    const tx = await TxLogCollection.findOne({ selector: { orderId } }).exec()
    if (!tx) return

    console.log('[onRefund] tx', tx)
    console.log('[onRefund] transaction found, refund')
    const closeProgress = dialogService.progress({ title: 'Refunding...' })
    // @ts-ignore
    const { error, response } = await refundTx(tx.terminalId, { refId: tx.metadata?.payment.id, amount })
    console.log('[onRefund]', { error, response })
    closeProgress()

    if (error) throw new TransactionRefundFailedError(error)

    console.log('[onRefund] transaction refund completed', response)
    await TxRefundLogCollection.upsert({
      _id: uuid(),
      type: tx.type,
      orderId: tx.orderId,
      metadata: response,
    })
    await msgBox.show(LL0().onlineOrder.refund(), LL0().onlineOrder.transactionRefundedCompleted(), msgBox.Buttons.OK, msgBox.Icons.Information)
  }

  async handleCancelRefund() {
    setIsOpenRefundItemsPopup(false)
    setIsOpenRefundReasonPopup(false)
    if (refundMode() === RefundMode.AMOUNT) {
      await recordUserActionHistory(order1(), UserAction.CANCEL_REFUND)
    }
    if (tseConfig0()?.tseEnable) {
      // TODO: handle TSE here
    }
    if (isQuebecSrmEnabled() && isValidSrmUser()) {
      log('⚡️ Refund cancelling detected, recording to SRM...')
      // Record cancellation as Transaction
      await srmTransactionLogic.recordRefundCancellation(order1())
      await getUserActionsOrder(order1())
    }
  }

  async handleBackToRefundItemPopup() {
    setIsOpenRefundItemsPopup(true)
    setIsOpenRefundReasonPopup(false)
    await recordUserActionHistory(order1(), UserAction.CANCEL_REFUND)
  }

  openRefundDialog() {
    if (order0().status === OrderStatus.CANCELLATION_REFERENCE) return
    setIsOpenRefundItemsPopup(true)
  }

  @handleError()
  async proceedToSelectRefundReason() {
    const _order = await makeRefundOrderRaw(order1(), fullRefund(), mapRefundItems, currentOrderInitialSum())
    setOrder2(_order)
    setIsOpenRefundReasonPopup(true)
    for (const item of order2().items) {
      await recordUserActionHistory(order1(), UserAction.REFUND_ITEM, {
        productRef: {
          name: item.name,
          price: item.price
        },
        quantity: Math.abs(Number(item.quantity)),
      })
    }
  }
}

export const orderHistoryLogic = new OrderHistoryLogic()
export const { handleRefund, changePaymentClick, completePaymentChange } = orderHistoryLogic

/** Decorator for async method - will capture error and show dialog message */
function handleTxError<T, A extends unknown[], R>(): MethodDecorator<T, A, Promise<R | void>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      try {
        return await originMethod.apply(this, args)
      } catch (e) {
        if (e instanceof OrderAlreadyRefundedError) {
          console.log('[onRefund] transaction already refunded', e.data)
          await msgBox.show(LL0().onlineOrder.refund(), LL0().onlineOrder.transactionAlreadyRefunded(), msgBox.Buttons.OK, msgBox.Icons.Information)
        } else if (e instanceof TransactionRefundFailedError) {
          const errorMsg = `Transaction refund failed with error ${e.message}`
          console.log('[onRefund]', errorMsg)
          await msgBox.show(LL0().onlineOrder.refund(), errorMsg, msgBox.Buttons.OK, msgBox.Icons.Error)
        } else if (e instanceof Error) {
          await msgBox.show(`Unknown Error`, e.message, msgBox.Buttons.OK, msgBox.Icons.Error)
        } else if (e instanceof InsufficientAmountError) {
          console.log('[onRefund] InsufficientAmountError')
          await msgBox.show(LL0().onlineOrder.refund(), LL0().onlineOrder.insufficientAmount(), msgBox.Buttons.OK, msgBox.Icons.Error)
        } else {
          throw e
        }
      }
    }
}

class InsufficientAmountError extends Error {
}

class OrderAlreadyRefundedError extends Error {
  constructor(public data: TxRefundLog) {
    super()
  }
}

class TransactionRefundFailedError extends Error {
}
