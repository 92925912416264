import type { RxCollection, RxDatabase, RxDocument } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const TAX_CATEGORY_COLLECTION_NAME = 'tax_categories'

export const VERSION = 6

const taxCategorySchema = {
  title: 'taxCategory schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    type: { type: 'string' },
    value: { type: 'number' },
    printLabel: { type: 'string' },
    components: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string' },
          value: { type: 'number' },
          printLabel: { type: 'string' },
          note: { type: 'string' },
        },
      },
    },
  },
} as const

export type SubTax = {
  name?: string
  value?: number
  printLabel?: string
  note?: 'F' | 'P' | 'S'
}

export interface TaxCategory {
  _id?: string
  name?: string
  value?: number
  printLabel?: string
  type: 'food:dineIn' | 'food:takeAway' | 'drink:dineIn' | 'drink:takeAway' | 'HST' | 'combo'
  components?: SubTax[]
}

export type TaxCategoryDocument = RxDocument<TaxCategory>
export type TaxCategoryCollection = RxCollection<TaxCategory>

export const TaxCategoryCollection: TaxCategoryCollection = {} as TaxCategoryCollection
export const TaxCategory = TaxCategoryCollection

export const createTaxCategoryCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TaxCategory,
    collectionName: TAX_CATEGORY_COLLECTION_NAME,
    version: VERSION,
    schema: taxCategorySchema,
  });
}
