/** Add a performance mark. Key will be postfix with `-started` */
export const markStarted = (key: string): PerformanceMark => performance.mark(`${key}-started`)

/** Add a performance mark. Key will be postfix with `-finished` */
export const markFinished = (key: string): PerformanceMark => performance.mark(`${key}-finished`)

/** Measure duration between two performance marks that done with `markStarted` and `markFinished` */
export const measure = (key: string): PerformanceMeasure => performance.measure(key, { start: `${key}-started`, end: `${key}-finished` })

/** Print measured duration between two performance marks that done with `markStarted` and `markFinished` */
export const printDuration = (key: string): void => console.log('⏱️', key, `${measure(key).duration}ms`)
