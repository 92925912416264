// @ts-ignore
import uuid from "time-uuid";
import React, { memo, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from "react";
import { Accessor, useSignalEffect, useComputed, signal, Setter, useSignal } from "@/react/core/reactive";
import { batch } from "solid-js";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";


export function customParseFloat(value: string) {
  if (value.endsWith('.') && !isNaN(parseFloat(value.slice(0, -1)))) {
    return value;
  }
  const parsedValue = parseFloat(value);
	return isNaN(parsedValue) ? 0 : parsedValue;
}

export type InputController = {
	keys: Accessor<Array<string>>,
	setKeys: Setter<Array<string>>,
	focus: Accessor<boolean>,
	setFocus: Setter<boolean>,
	focusLock: Accessor<boolean>,
	setFocusLock: Setter<boolean>,
	caretIndex: Accessor<number>,
	setCaretIndex: Setter<number>,
	addKey: (char: string) => void,
	backspaceHandle: () => void,
	moveCaretToEnd: () => void
	setOnChange: Setter<OnChange>
	id: string
};

type OnChange = (value: string) => void;

export interface InputProps extends PropsWithChildren {
	label?: string;
	placeholder?: string;
	value: string,
	onChange?: OnChange
	onFocus?: () => void
	onBlur?: () => void
	refInputController?: (i: InputController) => void
	className?: string
	headless?: boolean
	disabled?: boolean
	// todo: refactor props textClass & caretClass
	textClass?: string,
	caretClass?: string,
  /** For testing only */
  'data-testid'?: string,
	fullWidth?: boolean,
}

export const [inputController0, setInputController0] = signal<InputController>();

const RenderChar = memo(({
	isCaretHere,
	index,
	focus,
	key0,
	isSelected,
	onCharMove,
	onCharPointerdown,
	onCharPointerup,
	textClass
}: {
	isCaretHere: boolean,
	index: number,
	focus: boolean,
	key0: string,
	isSelected: (index: number) => boolean,
	onCharMove: (index: number) => void,
	onCharPointerdown: (index: number) => void,
	onCharPointerup: (index: number) => void,
	textClass?: string
}) => {
	return <>
		{isCaretHere && focus && (
			<div className="w-0 h-[14px] relative">
				<div
					className={clsx(`w-[1px] h-[14px] bg-black absolute animate-blink left-[0] blink-caret`)}
				/>
			</div>
		)}
		<div
			style={{ ...(key0 === " " ? { width: "6px", flexShrink: 0 } : {}) }}
			className={`${isSelected(index) ? "bg-[#3487e7]" : "bg-[transparent]"} text-black select-none ${textClass || ''}`}
			onPointerOver={() => onCharMove(index)}
			onPointerDown={() => onCharPointerdown(index)}
			onPointerUp={() => onCharPointerup(index)}
		>
			{key0}
		</div>
	</>;
});

const Input = (props: InputProps) => {
	// @ts-ignore
	const document: Document = useMemo(() => self?.via?.document || window.document, []);
	// @ts-ignore
	const get = useMemo(() => self?.get?.bind?.(self) || (a => a), []);
	// const [texture, setTexture] = useSignal();
	//key event x
	//caret x
	//focus x
	//mouse select x
	const [keys, setKeys] = useSignal<Array<string>>(props.value?.split("") || []);
	const [caretIndex, setCaretIndex] = useSignal<number>(0);
	let selectingInitValue = { start: -1, end: -1, selecting: false };
	const [selecting, setSelecting] = useSignal<{
		start: number,
		end: number,
		selecting: boolean
	}>(selectingInitValue);
	const selected = useComputed(() => selecting().start !== selecting().end);
	const [focus, setFocus] = useSignal<boolean>(false);
	const [focusV, setFocusV] = useSignal<number>(0);
	const [focusLock, setFocusLock] = useSignal<boolean>(false);
	const id = useMemo(() => uuid(), []);
	const [onChange, setOnChange] = useSignal<OnChange>(undefined as any);
	const [onChangeLock, setOnChangeLock] = useSignal<boolean>(false);
	const [compositionStartIndex, setCompositionStartIndex] = useSignal<number>(-1);
	const [compositionLength, setCompositionLength] = useSignal<number>(0);
	const [useComposition, setUseComposition] = useSignal<boolean>(false);
	const [ctrlDown, setCtrlDown] = useSignal<boolean>(false);
	const [needBlur, setNeedBlur] = useSignal<boolean>(false);

	//todo: defer options
	useEffect(() => {
		if (!onChangeLock()) {
			props.onChange?.(keys().join(""));
			onChange()?.(keys().join(""));
		}
	}, [keys()]);

	useEffect(() => {
		//keys()?.join("") bc keys() is initialized via useSignal, which go after this effect
		if (props.hasOwnProperty("value") && props.value !== keys()?.join("")) {
			setKeys(props.value?.split("") || []);
			moveCaretToEnd();
			setOnChangeLock(true);
			setTimeout(() => setOnChangeLock(false), 10);
		}
	}, [props.value])

	const addKey = useCallback((char: string) => {
		setKeys((_keys: string[]) => {
			if (selected()) {
				const left = _keys.splice(0, Math.min(selecting().start, selecting().end));
				left.push(char);
				_keys.splice(0, Math.max(selecting().start, selecting().end) - Math.min(selecting().start, selecting().end) + 1);
				setCaretIndex(left.length);
				setSelecting(selectingInitValue);
				return [...left, ..._keys];
			}
			const left = _keys.splice(0, caretIndex());
			const right = [..._keys];
			left.push(...char?.split(""));
			setCaretIndex(left.length);
			// debugger
			return [...left, ...right];
		});
	}, []);

	const addCompositionKeys = useCallback((chars: string) => {
		setKeys((_keys: string[]) => {
			const left = _keys.splice(0, compositionStartIndex());
			_keys.splice(0, compositionLength());
			const right = [..._keys];
			left.push(...chars?.split(""));
			setCaretIndex(left.length);
			// debugger
			return [...left, ...right];
		});
	}, []);

	const backspaceHandle = useCallback(() => {
		setKeys((_keys: string[]) => {
			if (selected()) {
				const left = _keys.splice(0, Math.min(selecting().start, selecting().end));
				_keys.splice(0, Math.max(selecting().start, selecting().end) - Math.min(selecting().start, selecting().end) + 1);
				setCaretIndex(left.length);
				setSelecting(selectingInitValue);
				return [...left, ..._keys];
			}
			const left = _keys.splice(0, caretIndex());
			const right = [..._keys];
			left.pop();
			setCaretIndex(left.length);
			return [...left, ...right];
		});
	}, []);

	const moveCaretToEnd = useCallback(() => {
		setCaretIndex(keys()?.length);
	}, []);

	const inputController = useMemo(() => {
		const inputController = {
			keys, setKeys, focus, setFocus, focusLock,
			setFocusLock, caretIndex, setCaretIndex, addKey,
			backspaceHandle, id, moveCaretToEnd, setOnChange
		};

		props.refInputController?.(inputController);

		return inputController;
	}, []);

	useSignalEffect(() => {
		if (focus() && inputController0()?.id !== id) {
			inputController0()?.setFocus(false);
			setInputController0(inputController);
		}
	});

	useEffect(() => {
		focusV();
		if (focus()) {
			props.onFocus?.();
			setNeedBlur(true);
			// const inputElement = document.querySelector<HTMLInputElement>("#input");
			// setTimeout(() => {
			// 	inputElement?.focus();
			// 	if (inputElement) inputElement.value = "";
			// 	setCompositionLength(0);
			// }, 10);
			setTimeout(() => {
				if (inputRef.current) inputRef.current.value = "";
				setCompositionLength(0);
			}, 10);
		} else if (needBlur() && !focus()) {
			props.onBlur?.();
			setNeedBlur(false);
		}
	}, [focusV(), focus()]);

	const [lockBackspace, setLockBackspace] = useSignal(false);
	//todo: clean up
	useEffect(() => {
		const onCompositionEnd = async (event: CompositionEvent) => {
			if (!focus()) return;
			await get(event.data);
			// console.log('compositionend')
			setCompositionStartIndex(-1);
		};
		document.addEventListener("compositionend", onCompositionEnd);

		const onCompositionStart = () => {
			if (!focus()) return;
			// console.log('compositionstart')
			setCompositionStartIndex(caretIndex());
		};
		document.addEventListener("compositionstart", onCompositionStart);

		const onCompositionUpdate = async (event: CompositionEvent) => {
			if (focus()) {
				setUseComposition(true);
				let data = await get(event.data);
				data = data.replace(" ", "");
				// console.log('compositionupdate: ',data);
				addCompositionKeys(data);
				setCompositionLength(data.length);
			}
		};
		document.addEventListener("compositionupdate", onCompositionUpdate);

		const onKeyDown = async (event: KeyboardEvent) => {
			if (!focus()) return;
			setUseComposition(false);
			const key = await get(event.key);
			const keyCode = await get(event.keyCode);
			if (ctrlDown() && key === "c") {
				// await navigator.clipboard.writeText();
			} else if (ctrlDown() && key === "v") {
				const text = await navigator.clipboard.readText();
				addKey(text);
			} else if (keyCode == 17 || keyCode == 91) {
				setCtrlDown(true);
			} else if (key === "Backspace") {
				console.log("backspaceHandle");
				if (lockBackspace()) return;
				setLockBackspace(true);
				setTimeout(() => setLockBackspace(false), 1);
				backspaceHandle();
				// setCaretIndex(keys().length);
			} else if (key.length === 1) {
				if (key === " ") addKey(key);
				setTimeout(() => {
					if (!useComposition() && key !== " ") {
						addKey(key);
					}
				}, 1);
				// addKey(key);
			} else if (key === "ArrowLeft") {
				setCaretIndex((i: number) => i > 0 ? i - 1 : i);
				event.preventDefault();
			} else if (key === "ArrowRight") {
				setCaretIndex((i: number) => i < keys().length ? i + 1 : i);
				event.preventDefault();
			}

			if (key === " ") event.preventDefault();
		};
		document.addEventListener("keydown", onKeyDown, false);

		const onKeyUp = async (event: KeyboardEvent) => {
			if (!focus()) return;
			const keyCode = await get(event.keyCode);
			if (keyCode == 17 || keyCode == 91) {
				setCtrlDown(false);
			}
		};
		document.addEventListener("keyup", onKeyUp);

		const onPointerDown = () => {
			if (focusLock()) return;
			setFocus(false);
		};
		// document.addEventListener("pointerdown", onPointerDown);
		return () => {
			document.removeEventListener("compositionend", onCompositionEnd);
			document.removeEventListener("compositionstart", onCompositionStart);
			document.removeEventListener("compositionupdate", onCompositionUpdate);
			document.removeEventListener("keydown", onKeyDown, false);
			document.removeEventListener("keyup", onKeyUp);
			document.removeEventListener("pointerdown", onPointerDown);
		}
	}, []);

	const onPointerDown = useCallback(() => {
		if (props.disabled) return;
		inputRef.current?.focus();
		batch(() => {
			setFocus(true);
			setFocusLock(true);
			setFocusV((v: number) => v + 1);
			setTimeout(() => setFocusLock(false), 100);
		})
	}, [props.disabled])

	const onCharPointerdown = useCallback((index: number) => {
		setCaretIndex(index);
		setFocusV((v: number) => v + 1);
		setSelecting({ start: index, end: index, selecting: true });
	}, [])

	const onCharMove = useCallback((index: number) => {
		if (selecting().selecting) {
			setSelecting((v: any) => ({ ...v, end: index }));
		}
	}, []);

	const onCharPointerup = useCallback((index: number) => {
		if (selecting().selecting) {
			setSelecting((v: any) => ({ ...v, end: index, selecting: false }));
			if (selecting().start !== selecting().end) {
				setCaretIndex(Math.max(selecting().start, selecting().end) + 1);
			}
		}
	}, []);

	const isSelected = useCallback((index: number) => {
		if (selecting().start === selecting().end) return false;
		if (selecting().start <= index && selecting().end >= index) {
			return true;
		} else if (selecting().start >= index && selecting().end <= index) {
			return true;
		}
		return false;
	}, [])

	const inputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		inputRef.current?.addEventListener("blur", () => {
			if (!focusLock()) {
				setFocus(false);
				setFocusV(v => v + 1);
			}
		});
	}, []);

	const inputWrapper = (children: React.ReactNode) => {
		// console.log('props', props)
		return (
			<>
				{props.label ? (
					<fieldset className={twMerge(`relative cursor-text h-[50px] pl-[14px] py-[1px] pr-[1px] inline-flex items-center rounded-[4px] bg-white border border-[#C2C2C2]`, props.className,
						props.headless? 'h-full w-full border-none pb-[5px]': '',
						props.fullWidth ? 'w-[100%]' : '',
						focus() && 'border-[#1976D2] border-[2px] pl-[13px] py-[0px] pr-[0px]',
						props.disabled ? 'opacity-40' : ''
					)}>
						<legend className='px-1 text-left text-[12px]'>{props.label || 'label'}</legend>
						<div
							className={twMerge(`absolute top-[-7px] inset-x-0 cursor-text h-[37px] w-full pl-[14px] py-[1px] pr-[1px] inline-flex items-center rounded-[4px]`, props.className,
								props.headless ? 'h-full w-full border-none pb-[5px]' : ''
							)}
							onPointerDown={e => {
								e.stopPropagation();
								setTimeout(() => onPointerDown(), 10);
							}}
              data-testid={props["data-testid"]}
						>
							<input className={'w-[0px]'} ref={r => inputRef.current = r!} disabled readOnly/>
							{children}
						</div>
					</fieldset>
				) : <>
					<div
						className={twMerge(`relative cursor-text h-[37px] pl-[14px] py-[1px] pr-[1px] inline-flex items-center rounded-[4px] bg-white border border-[#C2C2C2]`, props.className,
							props.headless ? 'h-full w-full border-none pb-[5px]' : '',
							props.fullWidth ? 'w-[100%]' : '',
							focus() && 'border-[#1976D2] border-[2px] pl-[13px] py-[0px] pr-[0px]',
							props.disabled ? 'opacity-40' : ''
						)}
						onPointerDown={e => {
							e.stopPropagation();
							setTimeout(() => onPointerDown(), 10);
						}}
            data-testid={props["data-testid"]}
					>
					<input className={'w-[0px]'} ref={r => inputRef.current = r!} readOnly/>
					{children}
				</div>
			</>}
		</>
		)
	}

	return <>
		{inputWrapper(<>
			<div className="overflow-auto flex-grow h-full items-center flex flex-row no-scrollbar">
				{keys().map((key: string, index: number) => (
					<RenderChar isCaretHere={caretIndex() === index} index={index} focus={focus()} key={index} key0={key}
						isSelected={isSelected}
						onCharMove={onCharMove} onCharPointerdown={onCharPointerdown} onCharPointerup={onCharPointerup}
						textClass={props.textClass}
					/>
				))}
				{caretIndex() === keys().length && focus() && (
					<div
						className={`w-[1px] h-[14px] flex-shrink-0 bg-[#000000] animate-blink blink-caret ${props.caretClass || ''}`}
					/>
				)}
				<div
					className="flex-grow min-w-[20px] h-full"
					onPointerDown={() => {
						setCaretIndex(keys().length);
					}}
				/>
			</div>
			<div className={'h-full'}>
				{props.children}
			</div>
		</>)}
	</>
};
export default Input;
