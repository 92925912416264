//@ts-ignore
import Papa from 'papaparse';
import { collectionsMap } from "@/data/CollectionsMap.ts";
import { toast } from "react-toastify";
import uuid from 'time-uuid';
import { VoucherStatus } from "@/data/Voucher.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { dsfinvTables } from "@/tse/dsfinv/dsfinv-convert.ts";
import { getUrlParam } from "@/shared/utils2.ts";
import JSZip from 'jszip';
import { uploadFile } from "@/shared/FileUploader.ts";
import dialogService from "@/react/SystemService/dialogService.tsx";
import { sendDsfinvS3 } from "@/shared/EmailSender.ts";
import dayjs from "dayjs";

const devMode = import.meta.env.MODE === 'development';
// const devMode = false;

export const exportToCsv = async (data: any, s3: boolean, email?: string) => {
  const zip = new JSZip();
  const isBrowser = !getUrlParam('os')
  const dataExport: { name: string; data: any; }[] = []
  for (const { name, table } of dsfinvTables) {
    const tableData = data[table];
    if (!tableData || tableData.length === 0) {
      console.log(`skip table ${table}`)
      continue
    }
    const csvContent = Papa.unparse(tableData);

    if (devMode && !s3) {
      await rnHost.exportCsv(csvContent, name)
      continue
    }

    if (isBrowser || s3) {
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      zip.file(`${name}.csv`, blob);
    } else {
      dataExport.push({
        name: name,
        data: csvContent
      })
    }
  }
  const zipBlob = await zip.generateAsync({ type: 'blob' })

  if (s3) {
    await exportS3()
    return
  }

  await exportZip()

  async function exportZip () {
    if (!devMode && isBrowser) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.setAttribute('download', `dsfinv.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (dataExport.length > 0) {
      await rnHost.exportCsv2(dataExport)
    }
    toast.success(`Export successful!`);
  }

  async function exportS3 () {
    const closeDialog = dialogService.progress({ title: 'Exporting...' })
    const url = await uploadFile('dsfinv', zipBlob, progress => {})
    await sendDsfinvS3(email!, `Exported data ${dayjs().format()}`, `You can download the file and unzip at: ${url}`)
    toast.success(`Export successful, please check your email!`);
    closeDialog()
  }
};


export function importDataFromCsv() {
  let data: any
  return {
    data,
    readFile,
    importData,
  }

  async function readFile() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";

    const fileReadPromise = new Promise<void>((resolve, reject) => {
      input.addEventListener("change", (event) => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
          Papa.parse(file, {
            header: true,
            complete: (results: any) => {
              data = results.data;
              resolve();
            },
            error: (error: any) => {
              console.error("Error when read csv file:", error);
              reject(error);
            },
          });
        } else {
          console.error('File not found');
          reject(new Error('File not found'));
        }
      });
    });

    input.click();
    await fileReadPromise;
    console.log('Done reading');
  }

  async function importData(collectionName: string) {
    let t
    try {
      if (!data) await readFile()
      t = toast.info(`Importing data ${collectionName}...`, { autoClose: false })
      const collectionItem = collectionsMap().find((collection) => collection.name === collectionName)
      if (!collectionItem) {
        toast.warning(`Cannot find collection ${collectionName}`)
        return
      }
      const data2 = data
        .map((item: any) => {
          item = {
            ...item,
            status: item?.usedValue ? item.usedValue : VoucherStatus.CREATED,
            usedValue: item?.usedValue ? item.usedValue : 0,
            activated: item?.activated ? item.activated : true
          }
          if (typeof item === 'object' && !item.hasOwnProperty('_id')) {
            return {
              ...item,
              _id: uuid(),
            };
          }
          return item
        })
        .filter((item: any) => {
          if (collectionName === 'voucher') {
            if (!item.hasOwnProperty('code') || !item.hasOwnProperty('totalValue') || !item?.code || !item.totalValue) {
              console.log('Removing item:', item);
              return false;
            }
          }
          return true;
        });

      console.log('import: ', data2)
      await collectionItem.collection.bulkInsert(data2)
      toast.done(t)
      toast.success(`Import ${collectionName} success!`)
    } catch (e) {
      if (t) toast.done(t)
    }
  }

  async function checkCodeVoucher() {
    // todo: check voucher code exists
  }
}