import type { FunctionComponent } from "react";
import OrdersButtonCategory from "@order-view/OrdersButtonCategory";
import { layoutCategories0, orderLayout0 } from '@/react/OrderView/OrderView.tsx';

import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const CategoryLeft: FunctionComponent = () => {
  return (
    <div className="h-full no-scrollbar overflow-y-scroll min-w-[80px] bg-white-opacity-white-20-ffffff [backdrop-filter:blur(6px)] overflow-y-auto flex flex-col items-center justify-start py-[3px] px-1 box-border gap-[6px]"
      style={{width: deviceSetting0()?.screenConfig?.categoryWidth}}
    >
      {layoutCategories0().map((category, index) => (
        <OrdersButtonCategory
          categoryText={category.name}
          ordersButtonCategoryBackgroundColor={category.color}
          ordersButtonCategoryAlignSelf="stretch"
          categoryTextLineHeight="unset"
          categoryTextDisplay="-webkit-inline-box"
          categoryTextWhiteSpace="unset"
          categoryTextFlex="1"
          categoryTextWebkitLineClamp="unset"
          categoryTextWebkitBoxOrient="unset"
          key={index}
          index={index}
        />
      ))}
    </div>
  );
};

export default CategoryLeft;
