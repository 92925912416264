import { map, type Subscription } from 'rxjs'
import type { RxDocument, RxQuery } from 'rxdb'
import { type Accessor, effect, signal } from './reactive'
import { convertDocuments } from '@/data/data-utils'
import dayjs from 'dayjs'

export declare interface Layout {
  x?: number
  y?: number
  width?: number
  height?: number
}

export const numberToString = (n: number | undefined) => (typeof n !== 'undefined' && !isNaN(n) ? String(n) : '')
export const numberFromString = (s: string) => {
  if (!s || s === '') return 0
  const value = s.toString()
  if (value.endsWith('.') && !isNaN(parseFloat(value.slice(0, -1)))) return value
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? 0 : parsedValue;
}
export const dateFromUnix = (n: number | undefined) => {
  try {
    return typeof n !== 'undefined' && !isNaN(n) ? dayjs.unix(n) : undefined
  } catch {
    return undefined
  }
}
export const dateToUnix = (d: Date | null | undefined) => {
  try {
    return typeof d === 'undefined' || d === null ? undefined : dayjs(d).unix()
  } catch {
    return undefined
  }
}

/**
 * Convert Promise to a Signal.
 */
export const computedFromAsync = <T>(fn: () => Promise<T>, defaultVal: T) => {
  const [val, setVal] = signal<T>(defaultVal)

  effect(() => {
    fn().then(setVal)
  })

  return val
}

/**
 * Convert RxQuery to a Signal, by subscribing to its Obserable (`$`) and set the value to underlying Signal.
 *
 * @returns An `Accessor` holding query value, and a `Setter` to enable loading the data
 *
 * @example
 *
 * Make a computed query:
 *
 * ```ts
 * import { db } from '@/data/DataSchema'
 *
 * const [categories, makeCategoriesAvailable] = computedFromRxQuery(() => db()?.categories.find(), [])
 * ```
 *
 * Use it in component:
 *
 * ```ts
 * // Raise the flag so data will be load on first render
 * useEffect(() => {
 *   VIEW__CATEGORY.makeCategoriesAvailable(true)
 *
 *   // Cleanup: Turn of the flag here so the data will be unload (and unsubscribe)
 *   return () => {
 *     VIEW__CATEGORY.makeCategoriesAvailable(false)
 *   }
 * }, [])
 * ```
 */
export const computedFromRxQuery = <T, V extends RxDocument<T>[]>(accessor: Accessor<RxQuery<T, V> | undefined>, defaultVal: V) => {
  const defaultValC = convertDocuments(defaultVal, true)
  const [val, setVal] = signal(defaultValC)
  const [flag, setFlag] = signal(false)

  let lastSubsciption: Subscription | undefined
  effect(() => {
    lastSubsciption?.unsubscribe()

    // Unless the flag is true, do not load data
    if (!flag()) {
      setVal(defaultValC)
      return
    }

    const q = accessor()
    lastSubsciption = q?.$.pipe(map(a => convertDocuments(a, true))).subscribe(setVal)
  })

  return [val, setFlag] as const
}

export const toDataUrl = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', () => resolve(reader.result as string))
    reader.addEventListener('error', () => reject(reader.error))
    reader.readAsDataURL(file)
  })
