import { type FunctionComponent, useCallback, useState } from "react";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import PortalPopup from "./PortalPopup";
import InfoSection from "./InfoSection";
import ServiceSection from "./ServiceSection";
import TableManualKeyboard from "./TableManualKeyboard";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  checkInCurrentReservation,
  onSaveCurrentReservation,
  removeCurrentReservation,
  reservationDetailMode,
  ReservationDetailMode
} from "@/data/ReservationHub.ts";
import { LL0 } from "@/react/core/I18nService.tsx";

export type ReservationNewEditPluginType = {
  className?: string;
  onClose?: Function;
};

const ReservationNewEditPlugin: FunctionComponent<
  ReservationNewEditPluginType
> = ({ className = "", onClose }) => {
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className={`w-[100vw] h-[100vh] bg-white-solid-white-400-f2f2f2 max-w-full overflow-hidden flex flex-col items-start justify-start relative gap-[16px] text-center text-sm text-black-solid-black-900-1e1e23 font-mulish ${className}`}
      >
        <div
          className="self-stretch rounded-12xs bg-gray-solid-gray-570-5a6370 h-[50px] flex flex-row items-center justify-between py-2 px-4 box-border z-[0] mq768:h-11 mq600:h-10">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-orange-solid-orange-ffa216-700 h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-6 box-border min-w-[60px] sm:pl-5 sm:pr-5 sm:box-border mq768:h-9 mq768:pl-4 mq768:pr-4 mq768:box-border mq600:h-8 mq600:pl-3 mq600:pr-3 mq600:box-border h.414:pl-3 h.414:pr-3 h.414:box-border"
              ref={makeRipple}
              onClick={async () => {
                await onSaveCurrentReservation()
                onClose?.()
              }}
            >
              <b className="relative leading-[17px] mq768:text-xs mq768:flex">
                {LL0().ui.save()}
              </b>
            </div>
            {reservationDetailMode() !== ReservationDetailMode.NEW &&
              <div
                className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-red-solid-red-150-ff5e71 h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-6 box-border cursor-pointer mq768:h-9 mq768:pl-3 mq768:pr-3 mq768:box-border mq600:h-8"
                onClick={openDeleteConfirmationPopup}
                ref={makeRipple}
              >
                <b className="relative leading-[17px] mq768:text-xs mq768:text-center mq768:flex h.414:flex">
                  {LL0().ui.delete()}
                </b>
              </div>
            }
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-[#ddd] h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-6 box-border cursor-pointer mq768:h-9 mq768:pl-3 mq768:pr-3 mq768:box-border mq600:h-8"
              onClick={() => onClose?.()}
              ref={makeRipple}
            >
              <b className="relative leading-[17px] mq768:text-xs mq768:text-center mq768:flex h.414:flex">
                {LL0().ui.cancel()}
              </b>
            </div>
          </div>
          { reservationDetailMode() !== ReservationDetailMode.NEW &&
            <div className="flex flex-col items-center justify-start text-smi text-white-solid-white-100-ffffff">
              <div
                className="h-10 rounded-81xl bg-blue-solid-blue-470-356ff6 flex flex-row items-center justify-center py-0.5 pr-3 pl-2 box-border gap-[4px] mq768:h-9 mq600:h-8"
                ref={makeRipple}
                onClick={async () => {
                  await checkInCurrentReservation()
                  onClose?.()
                }}
              >
                <img
                  className="w-[26px] relative h-[26px] object-cover mq768:w-[22px] mq768:h-[22px] mq600:w-5 mq600:h-5"
                  alt=""
                  src="/button-icon@2x.png"
                />
                <b
                  className="flex-1 relative leading-[17px] inline-block w-full mq768:text-xs mq768:flex mq768:flex-1 h.414:flex">
                  {LL0().reservation.checkIn()}
                </b>
              </div>
            </div>
          }
        </div>
        <div
          className="h-full self-stretch overflow-y-auto flex flex-col items-start justify-start py-0 px-4 gap-[12px] z-[1] mq320:pl-2 mq320:pr-2 mq320:box-border">
          <InfoSection/>
          <ServiceSection/>
        </div>
        <TableManualKeyboard
          tableManualKeyboardIden={false}
          tableManualKeyboardIdenWidth="1024px"
          tableManualKeyboardIdenMargin="0 !important"
          tableManualKeyboardIdenPosition="absolute"
          tableManualKeyboardIdenBottom="0px"
          tableManualKeyboardIdenLeft="0px"
          tableManualKeyboardIdenAlignSelf="unset"
          deleteButtonBackground="linear-gradient(180deg, #e3f0ff, #aeb7c1)"
          aCellBackground="linear-gradient(180deg, #e3f0ff, #aeb7c1)"
          tableManualKeyboardIdenMinWidth="414px"
          tableManualKeyboardIdenRight="unset"
        />
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup
            onClose={closeDeleteConfirmationPopup}
            onConfirm={async () => {
              await removeCurrentReservation()
              onClose?.()
            }}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default ReservationNewEditPlugin;
