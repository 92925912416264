import type {FunctionComponent} from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import {LL0} from "@/react/core/I18nService.tsx";

import { mainScreen } from "@/data/PosSettingsSignal.ts";

const VirtualPrinterTopNav: FunctionComponent = () => {
  return (
    <div className="self-stretch bg-gray-opacity-gray-50-9e9e9e flex flex-row items-center justify-between py-2 px-4 text-left text-smi text-black-solid-black-900-1e1e23 font-mulish">
      <div className="flex flex-row items-start justify-start gap-[8px]">
        <div className="rounded-981xl bg-blue-solid-blue-450-1271ff flex flex-col items-center justify-center py-1.5 px-5 text-white-solid-white-100-ffffff">
          <b className="relative">{LL0().common.all()}</b>
        </div>
        <div className="rounded-981xl bg-white-solid-white-100-ffffff flex flex-col items-center justify-center py-1.5 px-5">
          <div className="relative font-medium">Bon</div>
        </div>
        <div className="rounded-981xl bg-white-solid-white-100-ffffff flex flex-col items-center justify-center py-1.5 px-4">
          <div className="relative font-medium">{LL0().printing.invoice()}</div>
        </div>
        <div className="rounded-981xl bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-0.5 px-3 gap-[2px]">
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/iconzoom-in-icon@2x.png"
          />
          <div className="relative font-medium">50%</div>
        </div>
      </div>
      <div className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-center text-sm text-white-solid-white-100-ffffff"
           ref={makeRipple} onClick={() => {router.screen = mainScreen()}}
      >
        <img
          className="relative w-[30px] h-[30px] object-cover"
          alt=""
          src="/back-icon@2x.png"
        />
      </div>
    </div>
  );
};

export default VirtualPrinterTopNav;
