import type { RxCollection, RxDatabase } from 'rxdb'

import { createCollection } from './utils/migration'
import { YEARLY_REPORTS_COLLECTION_NAME, YearlyReports, yearlyReportSchema } from './YearlyReports'

export async function createYearlyReportsCollection<T>(database: RxDatabase<T>): Promise<RxCollection<YearlyReports>> {
  return await createCollection({
    database: database,
    collection: YearlyReports,
    collectionName: YEARLY_REPORTS_COLLECTION_NAME,
    version: yearlyReportSchema.version,
    schema: yearlyReportSchema,
  })
}
