import axios from 'axios'

import { getApiUrl } from '@/shared/utils.ts'

const apiKey = '389215d4-be05-4f59-8eab-b1be1ff82658';

export async function sendEmail(targetEmail: string, title: string, content: string) {
  try {
    await axios.post(`${getApiUrl()}/api/sendEmail`, {
      targetEmail,
      title,
      content,
      apiKey
    }, {
      timeout: 30000
    })
  } catch (e) {
    console.error('[EmailSender] failed to send email', e)
    return ''
  }
}

export async function sendDsfinvS3(targetEmail: string, title: string, content: string) {
  try {
    await axios.post(`${getApiUrl()}/api/sendDsfinvS3`, {
      targetEmail,
      title,
      content,
      apiKey
    }, {
      timeout: 30000
    })
  } catch (e) {
    console.error('[EmailSender] failed to send email', e)
    return ''
  }
}
