import type { FunctionComponent } from "react";
import TextField from "@/react/core/TextField.tsx";
import {
  showUpcomingReservation,
  upcomingReservationsCount,
  showOldBookingReservation,
  oldBookingReservationsCount,
  showCancelledReservation,
  cancelledReservationsCount,
  isUpcomingStatusFilterSelected,
  isOldBookingStatusFilterSelected,
  isCancelledStatusFilterSelected,
  searchFilter,
  setSearchFilter
} from "@/data/ReservationHub.ts"
import { LL0 } from "@/react/core/I18nService.tsx";


export type TabToolbarType = {
  className?: string;
};

const TabToolbar: FunctionComponent<TabToolbarType> = ({ className = "" }) => {
  const activeBtnCls1 = 'cursor-pointer flex-1 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-sm bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-[5px] pr-3 pl-3.5 gap-[6px] text-blue-solid-blue-420-2979ff mq480:gap-[4px] h.414:gap-[2px] mq320:gap-[2px]'
  const passiveBtnCls1 = 'cursor-pointer flex-1 rounded-sm flex flex-row items-center justify-center py-[5px] pr-3 pl-3.5 gap-[6px] border-[1px] border-solid border-gray-solid-gray-400-c9cbcc mq480:gap-[4px] h.414:gap-[2px] mq320:gap-[2px]'

  const activeBtnCls2 = 'cursor-pointer rounded-sm flex flex-row items-center justify-center py-[5px] pr-3 pl-3.5 gap-[6px] text-blue-solid-blue-420-2979ff border-[1px] border-solid border-gray-solid-gray-400-c9cbcc mq480:gap-[4px] h.414:gap-[2px] mq320:gap-[2px]'
  const passiveBtnCls2 = 'cursor-pointer shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-sm bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-[5px] pr-3 pl-3.5 gap-[6px] mq480:gap-[4px] h.414:gap-[2px] mq320:gap-[2px]'

  const activeBtnCls3 = 'cursor-pointer rounded-sm flex flex-row items-center justify-center py-[5px] pr-3 pl-3.5 gap-[6px] text-blue-solid-blue-420-2979ff border-[1px] border-solid border-gray-solid-gray-400-c9cbcc mq480:gap-[4px] h.414:gap-[2px] mq320:gap-[2px]'
  const passiveBtnCls3 = 'cursor-pointer rounded-sm flex flex-row items-center justify-center py-[5px] pr-3 pl-3.5 gap-[6px] border-[1px] border-solid border-gray-solid-gray-400-c9cbcc mq480:gap-[4px] h.414:gap-[2px] mq320:gap-[2px]'

  return (
    <div
      className={`self-stretch flex flex-row items-center justify-start gap-[12px] text-center text-base text-black-solid-black-500-59636f font-mulish sm:flex-col sm:gap-[12px] ${className}`}
    >
      <div className="flex-1 flex flex-col items-start justify-start gap-[2px] sm:flex-[unset] sm:self-stretch">
        <div className="self-stretch shadow-[0px_4px_8px_rgba(38,_61,_88,_0.06)] rounded bg-gray-solid-gray-180-ebecee box-border h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-1 gap-[4px] border-[1px] border-solid border-gray-solid-gray-480-b1b1b1 sm:self-stretch sm:w-auto">
          <div onClick={showUpcomingReservation} className={isUpcomingStatusFilterSelected() ? activeBtnCls1 : passiveBtnCls1}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.upcoming()}
            </div>
            <div className="rounded-80xl bg-green-solid-green-600-00c37c flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {upcomingReservationsCount()}
              </b>
            </div>
          </div>
          <div onClick={showOldBookingReservation} className={isOldBookingStatusFilterSelected() ? activeBtnCls1 : passiveBtnCls1}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.arrived()}
            </div>
            <div className="rounded-80xl bg-yellow-solid-yellow-600-ff9f00 flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {oldBookingReservationsCount()}
              </b>
            </div>
          </div>
          <div onClick={showCancelledReservation} className={isCancelledStatusFilterSelected() ? activeBtnCls1 : passiveBtnCls1}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.cancelled()}
            </div>
            <div className="rounded-80xl bg-red-solid-red-150-ff5e71 flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {cancelledReservationsCount()}
              </b>
            </div>
          </div>
        </div>
        <div className="w-[490px] shadow-[0px_4px_8px_rgba(38,_61,_88,_0.06)] rounded bg-gray-solid-gray-180-ebecee box-border h-10 overflow-hidden shrink-0 hidden flex-row items-center justify-center p-1 gap-[4px] border-[1px] border-solid border-gray-solid-gray-480-b1b1b1 sm:self-stretch sm:w-auto">
          <div onClick={showUpcomingReservation} className={isUpcomingStatusFilterSelected() ? activeBtnCls2 : passiveBtnCls2 }>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.upcoming()}
            </div>
            <div className="rounded-80xl bg-green-solid-green-600-00c37c flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {upcomingReservationsCount()}
              </b>
            </div>
          </div>
          <div onClick={showOldBookingReservation} className={isOldBookingStatusFilterSelected() ? activeBtnCls2: passiveBtnCls2}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.arrived()}
            </div>
            <div className="rounded-80xl bg-yellow-solid-yellow-600-ff9f00 flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {oldBookingReservationsCount()}
              </b>
            </div>
          </div>
          <div onClick={showCancelledReservation} className={isCancelledStatusFilterSelected() ? activeBtnCls2: passiveBtnCls2}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.cancelled()}
            </div>
            <div className="rounded-80xl bg-red-solid-red-150-ff5e71 flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {cancelledReservationsCount()}
              </b>
            </div>
          </div>
        </div>
        <div className="w-[490px] shadow-[0px_4px_8px_rgba(38,_61,_88,_0.06)] rounded bg-gray-solid-gray-180-ebecee box-border h-10 overflow-hidden shrink-0 hidden flex-row items-center justify-center p-1 gap-[4px] border-[1px] border-solid border-gray-solid-gray-480-b1b1b1 sm:self-stretch sm:w-auto">
          <div onClick={showUpcomingReservation} className={isUpcomingStatusFilterSelected() ? activeBtnCls3: passiveBtnCls3}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.upcoming()}
            </div>
            <div className="rounded-80xl bg-green-solid-green-600-00c37c flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {upcomingReservationsCount()}
              </b>
            </div>
          </div>
          <div onClick={showOldBookingReservation} className={isOldBookingStatusFilterSelected() ? activeBtnCls3 : passiveBtnCls3}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.oldBooking()}
            </div>
            <div className="rounded-80xl bg-yellow-solid-yellow-600-ff9f00 flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {oldBookingReservationsCount()}
              </b>
            </div>
          </div>
          <div onClick={showCancelledReservation} className={isCancelledStatusFilterSelected() ? activeBtnCls3 : passiveBtnCls3}>
            <div className="w-[94px] relative leading-[20px] font-semibold inline-block shrink-0 mq600:text-sm mq480:text-sm mq480:w-[84px] h.414:text-xs h.414:w-[74px] mq320:text-xs">
              {LL0().reservation.cancelled()}
            </div>
            <div className="rounded-9981xl bg-red-solid-red-150-ff5e71 flex flex-row items-center justify-center py-1 px-2 text-xs text-white-solid-white-100-ffffff mq480:hidden h.414:hidden">
              <b className="relative leading-[14px] mq600:text-xs h.414:text-xs mq320:text-3xs">
                {cancelledReservationsCount()}
              </b>
            </div>
          </div>
        </div>
      </div>
      <TextField
        className="flex-1 font-mulish text-sm text-black-solid-black-500-59636f sm:flex-[unset] sm:self-stretch"
        color="primary"
        fullWidth={true}
        variant="outlined"
        sx={{ "& .MuiInputBase-root": { height: "40px" } }}
        value={searchFilter()}
        onChange={e => setSearchFilter(e.target.value)}
      />
    </div>
  );
};

export default TabToolbar;
