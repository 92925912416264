import { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { createCollection } from "@/data/utils/migration.ts";

export const TIME_CLOCK_COLLECTION_NAME = 'time_clocks'

// {
//   username,
//     clockInTime: new Date(clockInTime),
//   clockOutTime: new Date(clockOutTime)
// }
export const VERSION = 2
const timeClockSchema = {
  title: 'timeClock schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    username: {type: 'string'},
    clockInTime: {type: 'number'},
    clockOutTime: {type: 'number'},
    vDate: {type: 'number'},
    status:  {type: 'string'},
  },
  indexes: []
} as const;

export enum ShiftStatus {
  CLOCK_IN = 'clockIn',
  CLOCK_OUT = 'clockOut',
}

export enum ResponseStatus {
  OK = 1,
  NOT_OK = 0
}

export enum ClockFailReasons {
  ALREADY_CLOCK_IN = 'alreadyClockIn',
  NOT_CLOCK_IN_YET = 'notClockInYet',
  CAN_NOT_CONNECT_TO_MASTER = 'canNotConnectToMaster'
}

export enum UpdateShiftFailReason {
  OVERLAP = 'overlap',
  CAN_NOT_CONNECT_TO_MASTER = 'canNotConnectToMaster'
}

export interface TimeClock {
  _id?: string;
  username?: string
  clockInTime?: number
  clockOutTime?: number
  vDate?: number
  status?: ShiftStatus
}

export type TimeClockDocument = RxDocument<TimeClock>;
export type TimeClockCollection = RxCollection<TimeClock>;

export const TimeClockCollection: TimeClockCollection = {} as TimeClockCollection;
export const TimeClock = TimeClockCollection;

export const createTimeClockCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TimeClock,
    collectionName: TIME_CLOCK_COLLECTION_NAME,
    version: VERSION,
    schema: timeClockSchema,
  });
}
