import { signal } from "@/react/core/reactive.ts";
import { dataLock } from "@/data/DataUtils.ts";
import { Voucher } from "@/data/Voucher.ts";
import { convertDocuments, type DocDeepSignal } from "@/data/data-utils.ts";
import { useEffect } from "react";
import { map, Subscription, tap } from "rxjs";


export const [vouchers0, setVouchers0] = signal<Array<DocDeepSignal<Voucher>>>([])
export const [voucherV, setVoucherV] = signal<number>(0)

export const makeVouchersAvailable = () => {
  useEffect(() => {
    let sub = new Subscription()
    dataLock.acquireAsync().then(() => {
      sub.add(
        Voucher.find({ sort: [{ createdAt: 'desc' }] })
          .$.pipe(
          map(a => convertDocuments(a, true)),
          tap(a => setVouchers0(a))
        ).subscribe()
      )
    })
    return () => sub.unsubscribe()
  }, [])
}

// @ts-ignore
window.vouchers0 = vouchers0