import { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { TseType } from "@/tse/tse-utils.ts";
import { createCollection } from "@/data/utils/migration.ts";

export const TSE_CONFIG_COLLECTION_NAME = 'tse_configs'

export const VERSION = 5;
const tseConfigSchema = {
  title: 'tseConfig schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    version: {type: 'number'},
    tseEnable: {type: 'boolean'},
    certificateExpirationDate: {type: 'string'},
    delayAssignQr: {type: 'number'},
    inited: {type: 'boolean'},
    pdEncoding: {type: 'string'},
    serialNumber: {type: 'string'},
    signatureAlgorithm: {type: 'string'},
    tsePublicKey: {type: 'string'},
    tseTimeFormat: {type: 'string'},
    percent: {type: 'number'},
    passthroughEnable: {type: 'boolean'},
    mockTSE: {type: 'boolean'},
    username: {type: 'string'},
    password: {type: 'string'},
    tsePassword: {type: 'string'},
    type: {type: 'string'},
    initialized: {type: 'boolean'},
    hideTseQrCode: {type: 'boolean'}
  },
  indexes: []
} as const;

export interface TseConfig {
  _id: string;
  version?: number;
  tseEnable?: boolean;
  certificateExpirationDate?: string;
  delayAssignQr?: number;
  inited?: boolean;
  pdEncoding?: string;
  serialNumber?: string;
  signatureAlgorithm?: string;
  tsePublicKey?: string;
  tseTimeFormat?: string;
  percent?: number;
  passthroughEnable?: boolean;
  mockTSE?: boolean;
  username?: string;
  password?: string;
  tsePassword?: string;
  type?: TseType;
  initialized?: boolean;
  hideTseQrCode?: boolean;
}

export type TseConfigDocument = RxDocument<TseConfig>;
export type TseConfigCollection = RxCollection<TseConfig>;

export const TseConfigCollection: TseConfigCollection = {} as TseConfigCollection;
export const TseConfig = TseConfigCollection;

export const createTseConfigCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TseConfig,
    collectionName: TSE_CONFIG_COLLECTION_NAME,
    version: VERSION,
    schema: tseConfigSchema,
  });
}

// @ts-ignore
window.TseConfig = TseConfig;
