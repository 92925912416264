import type { SelectChangeEvent } from '@mui/material/Select'
import axios from 'axios'
import debug from 'debug'
import type { DeepSignal } from 'deepsignal/react'
import _ from 'lodash'
import uuid from 'time-uuid'

import { Menu, MenuTypes } from '@/data/Menu'
import { OnlineProvider } from '@/data/OnlineProvider.ts'
import { onlineProviders0 } from '@/data/OnlineProviderHub.ts'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { MarketPlaceProvider } from '@/pos/OrderType'
import { computed, deepSignal, signal } from '@/react/core/reactive.ts'
import { bound, handleError, progressDialog, progressToast, requireConfirmation, throwIf } from '@/shared/decorators'
import { getApiUrl, readableError } from '@/shared/utils'
import { rnHost } from '@/shared/webview/rnwebview.ts'

import msgBox from '../SystemService/msgBox'
import { ensureArray } from '../utils/ensureArray'

const log = debug('data:online-providers')

/*******************[ States ]*********************/
const [deletingItemId, setDeletingItemId] = signal<string | undefined>()
const [editingOnlineProvider, setEditingOnlineProvider] = signal<DeepSignal<OnlineProvider | undefined>>()

/*******************[ Derived States ]*********************/
const isUseSingleProvider = computed(() => posSetting0()?.useSingleOnlineMenu ?? false)
const onlineProviders = computed(() => onlineProviders0())
const hasUberEatsProvider = computed(() => !!onlineProviders().find(op => op.name === MarketPlaceProvider.UBER_EATS))

/*******************[ Exported States ]*********************/
export {
  // States
  isUseSingleProvider,
  onlineProviders,
  editingOnlineProvider,
  deletingItemId,
  hasUberEatsProvider,
}

/*******************[ Handlers ]*********************/
function isFieldAvailable(field: keyof OnlineProvider) {
  const model = editingOnlineProvider()
  if (!model) return false
  switch (model.name) {
    case MarketPlaceProvider.UBER_EATS:
      return false
    case MarketPlaceProvider.PIKAPOINT:
      return ['storeId'].includes(field)
    case MarketPlaceProvider.DELIVERECT:
      return ['storeId'].includes(field)
    case MarketPlaceProvider.LIEFERANDO:
      return ['storeId'].includes(field)
    case MarketPlaceProvider.RESTABLO:
      return ['username', 'password'].includes(field)
    case MarketPlaceProvider.RESTAURANT_PLUS:
      return ['storeId'].includes(field)
    case MarketPlaceProvider.SKIP_THE_DISH:
      return true
    case MarketPlaceProvider.DOOR_DASH:
      return true
    default:
      return true
  }
}

@bound()
class OnlineProviderSettingViewHandlers {
  @requireConfirmation(() => ({ title: 'Use Single Menu', content: 'Enable this option will REMOVE any extra Menu configured but the first one. Do you want to continue?' }))
  @progressToast(() => 'Enable single menu...')
  @handleError()
  async enableSingleMenuForAllProvider() {
    const p = posSetting0()
    if (!p) return

    const [menu, ...menus] = await Menu.find({ sort: [{ position: 'desc' }] }).exec()
    const allProviderIds = await OnlineProvider.find()
      .exec()
      .then(p => p.map(a => a._id))
    if (menu) {
      await menu.incrementalUpdate({ $set: { providers: allProviderIds } })

      if (menus?.length) for (const m of menus) await m.remove()
    } else if (allProviderIds.length > 0) {
      log('No menu available, creating one...')
      await Menu.insert({
        _id: uuid(),
        name: 'Default Menu',
        providers: allProviderIds,
        position: 1,
        type: MenuTypes.ONLINE,
      })
    }
    p.useSingleOnlineMenu = true
  }

  @progressToast(() => 'Disable single menu...')
  @handleError()
  async disableSingleMenuForAllProvider() {
    const p = posSetting0()
    if (!p) return
    const providers = await OnlineProvider.find().exec()
    if (providers.length > 1) throw new Error('This option cannot be turn off while you have more than one provider. Please remove extra provider first!')
    p.useSingleOnlineMenu = false
  }

  async toggleUseSingleProvider() {
    if (isUseSingleProvider()) this.disableSingleMenuForAllProvider()
    else this.enableSingleMenuForAllProvider()
  }
  onDelete = (id: string) => setDeletingItemId(id)
  onEdit(id: string) {
    const model = onlineProviders()
      ?.find(a => a._id === id)
      ?.doc?.toMutableJSON()
    if (!model) return
    setEditingOnlineProvider(deepSignal(model))
  }
  async onCreate() {
    const newProvider: OnlineProvider = {
      _id: uuid(),
      name: MarketPlaceProvider.PIKAPOINT,
      isEnabled: true,
    }
    setEditingOnlineProvider(deepSignal(newProvider))
  }

  @progressToast(() => 'Unregister provider...')
  @handleError()
  async onDeleteConfirmed() {
    const item = onlineProviders0().find(a => a._id === deletingItemId())
    if (!item) {
      log('🚫 No provider found to delete')
      return
    }
    console.log('🗑️ Deleting provider:', item)

    const transformErr = (e: unknown) => {
      throw new Error(`Failed to unregister provider due to ${readableError(e)}`)
    }
    const api = axios.create({ baseURL: getApiUrl() })
    const params = { posId: posSync0()?.id, storeId: item.storeId }

    if (item.name === MarketPlaceProvider.UBER_EATS) {
      await api.post(`/api/uber-eats/unregister`, params).catch(transformErr)
    } else if (item.name === MarketPlaceProvider.DELIVERECT) {
      await api.post(`/api/deliverect/unregister`, params).catch(transformErr)
    } else if (item.name === MarketPlaceProvider.LIEFERANDO) {
      await api.post(`/api/lieferando/unregister`, params).catch(transformErr)
    } else if (item.name === MarketPlaceProvider.PIKAPOINT) {
      await api.post(`/api/pika/unregister`, params).catch(transformErr)
    } else if (item.name === MarketPlaceProvider.RESTABLO) {
      await api.post(`/api/restablo/unregister`, params).catch(transformErr)
    } else if (item.name === MarketPlaceProvider.RESTAURANT_PLUS) {
      await api.post(`/api/rplus/unregister`, params).catch(transformErr)
    }

    // Remove the provider
    await OnlineProvider.findOne({ selector: { _id: item._id } }).remove()

    // Remove related Menu.
    const menu = await Menu.findOne({ selector: { providers: { $in: [item._id] } } }).exec()
    // Only remove the menu if it has only one provider
    if (menu)
      if (menu.providers?.length === 1) await menu.remove()
      else log('🚫 Skip removing menu because it has more than one provider', menu)
    else log('🚫 No menu associated with this provider', item)
  }

  @throwIf(([m]) => isFieldAvailable('storeId') && _.isEmpty(m?.storeId), 'Missing StoreId!')
  @progressDialog(([model, posId]) => {
    switch (model.name) {
      case MarketPlaceProvider.LIEFERANDO:
        return { title: `Register Lieferando's ID "${model.storeId}" with Pos ID: ${posId}` }
      case MarketPlaceProvider.RESTABLO:
        return { title: `Register Restablo with Pos ID: ${posId}` }
      case MarketPlaceProvider.RESTAURANT_PLUS:
        return { title: `Register RestaurantPlus's ID "${model.storeId}" with Pos ID: ${posId}` }
      case MarketPlaceProvider.PIKAPOINT:
        return { title: `Register Pika's ID "${model.storeId}" with Pos ID: ${posId}` }
      case MarketPlaceProvider.UBER_EATS:
        return { title: 'Getting authorization url...' }
      default:
        throw new Error('Invalid model!')
    }
  })
  private async registerProvider(model: OnlineProvider, posId: number) {
    const { name, storeId, username, password } = model
    const api = axios.create({ baseURL: getApiUrl() })
    switch (name) {
      case MarketPlaceProvider.LIEFERANDO: {
        try {
          const { data } = await api.post(`/api/lieferando/register`, { posId, storeId })
          log('✅ Register completed', data)
          await msgBox.show('Info', 'Lieferando registered', msgBox.Buttons.OK, msgBox.Icons.Information)
        } catch (e) {
          log('🛑 Failed to register lieferando', e)
          throw new Error('Lieferando failed with error: ' + readableError(e))
        }
        break
      }
      case MarketPlaceProvider.RESTABLO: {
        try {
          if (_.isEmpty(username)) throw new Error('Username is empty')
          if (_.isEmpty(password)) throw new Error('Password is empty')
          const { data } = await api.post(`/api/restablo/register`, { posId, metadata: { username, password } })
          log('✅ Register completed', data)
          await msgBox.show('Info', 'Restablo registered', msgBox.Buttons.OK, msgBox.Icons.Information)
        } catch (e) {
          log('🛑 Failed to register Restablo', e)
          throw new Error('Restablo failed with error: ' + readableError(e))
        }
        break
      }
      case MarketPlaceProvider.RESTAURANT_PLUS: {
        try {
          const { data } = await api.post(`/api/rplus/register`, { posId, storeId })
          log('✅ Register completed', data)
          await msgBox.show('Info', 'Restaurant Plus registered', msgBox.Buttons.OK, msgBox.Icons.Information)
          return
        } catch (e) {
          log('🛑 Failed to register Restaurant Plus', e)
          throw new Error('Restaurant Plus failed with error: ' + readableError(e))
        }
      }
      case MarketPlaceProvider.PIKAPOINT: {
        try {
          const { data } = await api.post(`/api/pika/register`, { posId, storeId })
          log('✅ Register completed', data)
          await msgBox.show('Info', 'Pika registered', msgBox.Buttons.OK, msgBox.Icons.Information)
        } catch (e) {
          log('🛑 Failed to register Pika', e)
          throw new Error('Pika failed with error: ' + readableError(e))
        }
        break
      }
      case MarketPlaceProvider.UBER_EATS: {
        try {
          const { data } = await api.post(`/api/uber-eats/authorization-url`, {
            posId,
            href: location.href,
          })
          const url = data.authorization_url
          console.log('open authorization_url', url)
          await rnHost.openUrl(url)
          return // Exit and skip saving Provider. Our backend will create it and sync back to this device later
        } catch (e) {
          throw new Error(`Failed to gathering authorization url due to ${readableError(e)}`)
        }
      }
      default:
        throw new Error('This provider is unsupported!')
    }

    log('⚡️ Saving online provider...')
    return await OnlineProvider.incrementalUpsert(model)
  }

  private async ensureMenuForProvider(p: OnlineProvider) {
    const menu = await Menu.findOne({ selector: { providers: { $in: [p._id] } } }).exec()
    if (!menu) {
      log('⚡️ No menu for this provider founded, creating one...')
      const inserted = await Menu.insert({
        _id: uuid(),
        providers: [p._id],
        name: p.name,
        type: MenuTypes.ONLINE,
      })
      log('💾 New Menu created', inserted)
    } else {
      // Update the menu's name based on Provider
      await menu.incrementalUpdate({ $set: { name: p.name } })
    }
  }

  @handleError()
  async onSave() {
    const model = editingOnlineProvider()
    if (!model) throw new Error('No provider is editing')
    const posId = posSync0()?.id
    if (!posId) throw new Error('PosId is missing. Please Register POS store first. Visit Settings > Developer Only')

    const provider = await this.registerProvider(model, posId)
    if (provider) this.ensureMenuForProvider(provider)
    log('💾 Online Provider saved', provider)
  }
  private async onChanged<T extends keyof OnlineProvider>(prop: T, val: OnlineProvider[T]) {
    const p = editingOnlineProvider()
    if (p && val !== p[prop]) p[prop] = val
  }
  // Forms
  isFieldAvailable = isFieldAvailable
  onNameChanged = (e: SelectChangeEvent) => this.onChanged('name', e.target.value)
  onStoreIdChanged = (value: string) => this.onChanged('storeId', value)
  onUsernameChanged = (value: string) => this.onChanged('username', value)
  onPasswordChanged = (value: string) => this.onChanged('password', value)
  onApiKeyChanged = (value: string) => this.onChanged('apiKey', value)
  onServiceTypesChanged = ({ target: { value } }: SelectChangeEvent<string[]>) => this.onChanged('serviceTypes', ensureArray(value))

  @progressDialog(([, isEnabled]) => ({ title: `Update store status to "${isEnabled ? 'ONLINE' : 'OFFLINE'}"` }))
  @handleError()
  @bound()
  async setEnable(provider: OnlineProvider, isEnabled: boolean) {
    console.log('⚡️ Toggle store status...', provider, isEnabled)

    const api = axios.create({ baseURL: getApiUrl() })
    const status = isEnabled ? 'ONLINE' : 'OFFLINE'
    const payload = {
      posId: posSync0()?.id,
      storeId: provider.storeId,
      status,
      reason: 'Temporary close',
    }

    const errorTransform = (e: unknown) => {
      throw new Error(`Failed to change store ${status} status due to ${(e as Error).message}`)
    }

    if (provider.name === MarketPlaceProvider.UBER_EATS) {
      await api.post(`/api/uber-eats/set-store-status`, payload).catch(errorTransform)
    } else if (provider.name === MarketPlaceProvider.PIKAPOINT) {
      await api.post(`/api/pika/set-store-status`, payload).catch(errorTransform)
    }

    await OnlineProvider.findOne({ selector: { _id: provider._id } }).update({ $set: { isEnabled } })
  }
}

export const HANDLERS = new OnlineProviderSettingViewHandlers()

/*******************[ Debug ]*********************/
// effect(() => log('🪲 onlineProviders', onlineProviders()))
