import { FunctionComponent } from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl
} from '@mui/material'
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { materialCategories } from '@/data/CategoryHub.ts'
import { useSignal } from '@/react/core/reactive.ts'
import { filterInventoryValues, setFilterInventoryValues } from '@/data/InventoryHub.ts'

type InventoryCategoryFilterType = {
  onClose?: () => void;
};

const InventoryCategoryFilter: FunctionComponent<
  InventoryCategoryFilterType
> = ({ onClose }) => {

  const [category, setCategory] =
    useSignal<{ name: string | undefined, id: string }>({ name: '', id: filterInventoryValues().category?.id || '' })

  return (
    <div
      className="relative rounded-md bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[414] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[24px] hover:items-start hover:justify-start hover:rounded-md hover:pt-8 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[414]">
      <div className="self-stretch flex flex-col items-start justify-center gap-[8px] z-[0]">
        <div className="relative font-medium md:text-xs">{LL0().sidebar.category()}</div>
        <FormControl
          className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
          variant="outlined"
        >
          <InputLabel color="primary" />
          <Select
            color="primary" size="small"
            value={category().id}
            onChange={e => setCategory( prev => ({ ...prev, id: e.target.value }) )}>
            {materialCategories().map((category, index) => (
              <MenuItem
                key={index}
                value={category._id}
                onClick={() => setCategory( prev => ({ ...prev, name: category.name }) )}
              >{category.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <TableManualKeyboard
        value={''}
        onEnter={() => {
          setFilterInventoryValues(prev => ({ ...prev, category: category() }))
          onClose?.()
        }}
      />
      <img
        onClick={onClose}
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 object-cover z-[2]"
        alt=""
        src="/icongeneralclose-popup-icon--grey@2x.png"
      />
    </div>
  )
}

export default InventoryCategoryFilter
