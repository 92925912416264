import {memo} from "react";
import SignInPlugin from "@sign-in/SignInPlugin.tsx";
import {signal} from "@/react/core/reactive.ts";
import type {InputController} from "@/react/core/Input.tsx";


export const [showKeyboardSignIn, setShowKeyboardSignIn] = signal<boolean>(false)

export const [inputControllers, setInputControllers] = signal<Array<InputController>>([]);
export const handleShowKeyboardSignIn = (event) => {
  event.stopPropagation();
  setShowKeyboardSignIn(true)

};

const SignInView = () => {
  return (
    <SignInPlugin/>
  )
}

export default memo(SignInView)