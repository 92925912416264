import { LL0 } from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";
import { setTerminal0, terminal0 } from "@/react/PaymentSettingView/PaymentSettingView.tsx";
import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import {Language, ZvtEncoding} from "zvt-js";
import _ from "lodash";

export default function ZvtTerminalConfiguration() {
  // TODO: i18n

  const languages = {
    English: Language.English,
    German: Language.German,
  }

  const encodings = {
    CodePage437: ZvtEncoding.CodePage437,
    UTF8: ZvtEncoding.UTF8,
    ISO_8859_1: ZvtEncoding.ISO_8859_1,
    ISO_8859_2: ZvtEncoding.ISO_8859_2,
    ISO_8859_15: ZvtEncoding.ISO_8859_15
  }

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().settings.ipAddress()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata.ipAddr || "192.168.95.92"}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.ipAddr = e.target.value;
            return prev;
          })}
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().tax.port()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          type="number"
          value={terminal0()?.metadata.port || 20007}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.port = +e.target.value;
            return prev;
          })}
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().onlineProdiverSetting.password()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata.password || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.password = e.target.value;
            return prev;
          })}
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().tax.language()}</div>
        <FormControl className="self-stretch" variant="outlined">
          <InputLabel color="primary" />
          <Select color="primary" size="small" value={terminal0()?.metadata.language || Language.English}
                  onChange={e => {
                    console.log('Language', e)
                    setTerminal0(prev => {
                      if (!prev) return;
                      prev.metadata.language = e.target.value;
                      return prev;
                    })
                  }}
          >
            {_.entries(languages).map(([key, value], index) =>
              <MenuItem value={value} key={index}>{key}</MenuItem>)}
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().tax.encoding()}</div>
        <FormControl className="self-stretch" variant="outlined">
          <InputLabel color="primary" />
          <Select color="primary" size="small" value={terminal0()?.metadata.encoding || ZvtEncoding.CodePage437}
                  onChange={e => {
                    console.log('Encoding', e)
                    setTerminal0(prev => {
                      if (!prev) return;
                      prev.metadata.encoding = e.target.value;
                      return prev;
                    })
                  }}
          >
            {_.entries(encodings).map(([key, value], index) =>
              <MenuItem value={value} key={index}>{key}</MenuItem>)}
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().tax.commandCompletionTimeout()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          type="number"
          value={terminal0()?.metadata.commandCompletionTimeout || 5}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.commandCompletionTimeout = +e.target.value;
            return prev;
          })}
        />
      </div>
      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.receiptPrintoutForPayment()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.receiptPrintoutForPaymentFunctionsViaPaymentTerminal || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.receiptPrintoutForPaymentFunctionsViaPaymentTerminal = checked;
              return prev;
            })}
          />}
      />
      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.receiptPrintoutForAdministration()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.receiptPrintoutForAdministrationFunctionsViaPaymentTerminal || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.receiptPrintoutForAdministrationFunctionsViaPaymentTerminal = checked;
              return prev;
            })}
          />}
      />
      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.allowStartPaymentViaPaymentTerminal()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.allowStartPaymentViaPaymentTerminal || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.allowStartPaymentViaPaymentTerminal = checked;
              return prev;
            })}
          />}
      />
      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.allowAdministrationViaPaymentTerminal()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.allowAdministrationViaPaymentTerminal || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.allowAdministrationViaPaymentTerminal = checked;
              return prev;
            })}
          />}
      />
      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.receiptPrintoutGeneratedViaPaymentTerminal()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.receiptPrintoutGeneratedViaPaymentTerminal || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.receiptPrintoutGeneratedViaPaymentTerminal = checked;
              return prev;
            })}
          />}
      />

      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.serviceMenuIsDisabledOnTheFunctionKeyOfThePaymentTerminal()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.serviceMenuIsDisabledOnTheFunctionKeyOfThePaymentTerminal || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.serviceMenuIsDisabledOnTheFunctionKeyOfThePaymentTerminal = checked;
              return prev;
            })}
          />}
      />

      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.activateTlvSupport()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.activateTlvSupport || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.activateTlvSupport = checked;
              return prev;
            })}
          />}
      />

      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.skipRegistration()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.skipRegistration || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.skipRegistration = checked;
              return prev;
            })}
          />}
      />

      <FormControlLabel
        className="self-stretch"
        label={LL0().tax.logging()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.logging || false}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.logging = checked;
              return prev;
            })}
          />}
      />
    </>
  )
}