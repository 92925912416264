import PaymentProcessingPopUp from '@payment/PaymentProcessingPopUp.tsx'
import PaymentScreenPlugin, { isMasterMachineNotTurnedOn, setIsMasterMachineNotTurnedOn } from '@payment/PaymentScreenPlugin.tsx'
import PaymentScreenTurnOnMasterDev from '@payment/PaymentScreenTurnOnMasterDev.tsx'
import PortalPopup from '@payment/PortalPopup.tsx'
import { captureException } from '@sentry/react'
import PaymentVerticalPlugin from '@vertical-payment/PaymentVerticalPlugin.tsx'
import SplitBillVerticalPlugin from '@vertical-payment/SplitBillVerticalPlugin.tsx'
import debug from 'debug'
import delay from 'delay'
import _ from 'lodash'
import { memo } from 'react'
import { toast } from 'react-toastify'
import uuid from 'time-uuid'

import { getDeviceId } from '@/shared/getDeviceId.ts'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { inventories0 } from '@/data/InventoryHub.ts'
import { Order } from '@/data/Order.ts'
import { removeOrder, runMasterHandler, runMasterHandlerPaidOrder, updateInProgressOrder, updateOrder } from '@/data/OrderHub.ts'
import { Payment } from '@/data/Payment'
import { makePaymentsAvailable, payments0 } from '@/data/PaymentHub.ts'
import { isQuebecSrmEnabled, mainScreen, posSetting0, printerGeneralSetting0 } from '@/data/PosSettingsSignal.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { shouldWarnBecauseOffline } from '@/data/ReplicateEffect.ts'
import { TerminalTypes } from '@/data/Terminal.ts'
import { makeTerminalsAvailable, terminals0 } from '@/data/TerminalHub.ts'
import { TxLogCollection } from '@/data/TxLog.ts'
import { isValidSrmUser } from '@/data/UserHub.ts'
import { Voucher, VoucherStatus } from '@/data/Voucher.ts'
import { setVoucherV, vouchers0 } from '@/data/VoucherHub.ts'
import { openCashDrawer } from '@/pos/cashdrawer.ts'
import { createOrder, getTotalItems } from '@/pos/logic/order-reactive'
import { CommitAction, InvoiceTypes, MasterHandlerCommand, OrderStatus, TseMethod, type ItemCommitAddOrderDiscount, type MasterHandler, type TOrder } from '@/pos/OrderType'
import { onEnter, params, PosScreen, router } from '@/pos/PosRouter'
import { computed, effect, effectOn, signal } from '@/react/core/reactive'
import { updateInventoryStock } from '@/react/InventoryView/InventoryView.tsx'
import {
  genVoucherCode,
  orderMode0,
  setVoucherStatus,
  type Discount,
  itemContext, isFirstTimeGoToPayment, setIsFirstTimeGoToPayment, snapshot
} from '@/react/OrderView/OrderView.tsx'
import { OrderMode } from '@/react/OrderView/OrderViewShare.ts'
import { ItemFactoryContext, useItemFactory } from '@/react/PaymentView/OrderItemsFactory2'
import { checkOrderPayment, checkSingleOrderPayments, PaymentStatus, preventInsufficientPayment } from '@/react/PaymentView/payment-check.ts'
import { printBody0, printPayment0, PrintStack } from '@/react/PaymentView/PrintStack.ts'
import { printInvoice, printInvoiceFromRaster } from '@/react/Printer/print-invoice.ts'
import { handlePrintLabel } from '@/react/Printer/print-label.ts'
import { printVoucher } from '@/react/Printer/print-voucher.ts'
import DialogService from '@/react/SystemService/dialogService.tsx'
import dialogService from '@/react/SystemService/dialogService.tsx'
import MsgBox from '@/react/SystemService/msgBox.tsx'
import { cancel, pay } from '@/react/Terminal'
import { paymentHook } from '@/react/utils/hooks.ts'
import { createVoucher } from '@/react/VoucherView/VoucherView.tsx'
import { roundNumber, useServiceFeeBeforeTax } from '@/shared/order/order-config'
import { getServer } from '@/shared/utils.ts'
import { rnHost } from '@/shared/webview/rnwebview.ts'
import { srmTransactionLogic } from '@/srm/transaction.logic'

import { LL0 } from '../core/I18nService.tsx'
import { useOrderEditLocking } from '../hooks/useOrderEditLocking'
import CustomTipDialog from './CustomTipDialog.tsx'
import { setPaymentRedBillInfoPopupOpen } from "@payment/PaymentScreenMoreOption.tsx";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { isCashPayment } from "@/pos/logic/order-utils.ts";
import { createCashSale } from '../CashbookView/cashbook-logic.ts'
import { recordUserActionHistory, revertUserActions, UserAction } from "@/data/UserActionHistory.ts";

const log = debug('view:payment')
let oldPayment: string | undefined

//todo: add payments
//todo: assign payment
//todo: multi payment
//todo: show table of payment

export enum PaymentScreenMode {
  BILL,
  PAYMENT,
}

export const [paymentScreenMode, setPaymentScreenMode] = signal<PaymentScreenMode>(PaymentScreenMode.PAYMENT)
export const [orderDiscount, setOrderDiscount] = signal<{
  value: number | string
  type: 'percent' | 'amount'
  label: string
}>({ value: '', type: 'percent', label: '' })

export const [order0, setOrder0] = signal<TOrder>(createOrder())

export const [isAddCustomer, setIsAddCustomer] = signal<boolean>(false)

// @ts-ignore
window.order1 = order0
// @ts-ignore
window.cloneDeep = _.cloneDeep.bind(_)

export const isPaymentsIncludeDebitor = computed(() => {
  const debitorPayments = scopeOrder0()?.payments?.filter(p => p?.extraType === 'debitor' || _.lowerCase(p?.type) === 'debitor')
  if (debitorPayments && debitorPayments?.length > 0) return true
  return false
})

//region utils
const orderPaymentsJSON = computed(() => {
  return JSON.stringify(order0().payments)
})
//endregion

effectOn([orderPaymentsJSON], async () => {
  if (order0().payments.length < 1) return
  await printPayment0()
})

export const handleChangeTip = (value: string) => {
  changeTip(parseFloat(value))
}

export const callbackPayment = {
  fn: undefined,
} as { fn: (() => Promise<TOrder>) | undefined }

export const paymentContext0 = useItemFactory(order0, setOrder0, () => null)

const { seat, addSeat } = paymentContext0

const paymentsMap = computed<{ [key: string]: Payment[] }>(() => {
  return _.groupBy(payments0(), p => p.name)
})
export const paymentChunks0 = computed<Array<Array<Payment>>>(() => {
  return _.chunk(payments0(), 2)
})
export const serviceFee0 = computed<number>(() => {
  return getActiveOrder()?.serviceFee || 0
})
export const tip0 = computed<number>(() => {
  return getActiveOrder()?.tip || 0
})
export const scopeOrder0 = computed<TOrder>(() => {
  order0().seatMap
  if (order0().seatMode) {
    const val = order0().seatMap![seat()]
    if (val) {
      return val
    } else {
      const o: TOrder = {
        _id: '',
        multiplePriceMenu: '',
        payments: [],
        lack: 0,
        vSum: 0,
        tip: 0,
        cashback: 0,
        discountLabel: '',
        items: [],
        status: OrderStatus.IN_PROGRESS,
      }
      return o
    }
  }
  return order0()
})

Object.assign(window, { scopeOrder0 }) // Make global

export const [value0, setValue0] = signal<string>('0')
export const [firstClick0, setFirstClick0] = signal<boolean>(false)
export const [multiPayment0, setMultiPayment0] = signal<boolean>(false)
export const canSwitchMultiPayment = computed<boolean>(() => {
  if (_.isEmpty(scopeOrder0().payments)) return true
  const cardProcessedPm = scopeOrder0().payments.find(p => p.by != undefined)
  return !cardProcessedPm
})

const _whiteList = [PosScreen.ORDER_HISTORY]
export const blockMutateOrderButton = computed<boolean>(() => order0().status === OrderStatus.PAID && !_whiteList.includes(router.screen))
export const hasPaidSeatOrder = computed(() => {
  if (order0().seatMode) {
    return order0().seatMap?.some(order => order.status === OrderStatus.PAID)
  }
})
export function onPaymentKeyClick(s: string) {
  if (blockMutateOrderButton())
    return toast('You have already printed the invoice!', {
      type: 'info',
      autoClose: 500,
    })
  console.log('add')
  if (s === '<-') {
    if (value0().slice(0, value0.length - 1).length === 0) {
      setValue0('0')
    } else {
      setValue0(value0().slice(0, value0.length - 1))
    }
  } else {
    let _s = s
    if (_s === ',') _s = '.'
    if (firstClick0()) {
      setValue0(_s)
      setFirstClick0(false)
    } else {
      if (value0() === '0') {
        setValue0(_s)
      } else {
        setValue0(value0() + _s)
      }
    }
  }
}

// @ts-ignore
window.value0 = value0

effectOn(
  [isPaymentsIncludeDebitor],
  () => {
    if (isPaymentsIncludeDebitor()) {
      if (serviceFee0()) addServiceFee(0);
      if (tip0()) changeTip(0);
    }
  },
)

effectOn(
  [multiPayment0],
  () => {
    order0().commits!.push({
      action: CommitAction.CLEAR_PAYMENTS,
      ...addSeat(),
    })
    setCurrentPaymentName0(undefined as any)
  },
  { defer: true }
)

//effect from keyboard
effectOn([value0], () => {
  const p = scopeOrder0().payments.find(p => p.type === currentPaymentName0())
  if (p && value0() !== '0') {
    console.log('change payment', value0())
    order0().commits!.push({
      action: CommitAction.CHANGE_PAYMENT,
      type: p.type,
      extraType: currentPayment0()?.type,
      value: parseFloat(value0()),
      ...addSeat(),
      by: p.by,
    })
    // p.value = parseFloat(value())

    const value = isFirstTimeGoToPayment() ? 0 : p.value
    setIsFirstTimeGoToPayment(false)
    recordPaymentAction(value, parseFloat(value0()), p.type)

  } else if (p && value0() === '0') {
    order0().commits!.push({
      action: CommitAction.CHANGE_PAYMENT,
      type: p.type,
      extraType: currentPayment0()?.type,
      value: 0,
      ...addSeat(),
      by: p.by,
    })

    recordPaymentAction(p.value, 0, p.type)
    // p.value = 0;
  }
})

const recordPaymentAction = _.debounce(
  (valueFrom: number, valueTo: number, paymentName: string) => {
    recordUserActionHistory(order0(), UserAction.CHANGE_PAYMENT, {
      valueFrom,
      valueTo,
      paymentName,
      oldPayment: oldPayment || paymentName,
    }).then();
  },
  500, {leading: true, trailing: true}
);

effectOn(
  [seat],
  () => {
    if (scopeOrder0().payments.length > 0) {
      const payment = _.last(scopeOrder0().payments)
      setValue0(payment!.value.toString())
      setCurrentPaymentName0(payment!.type)
      return
    }
    setValue0('0')
    setCurrentPaymentName0(undefined as any)
  },
  { defer: true }
)

export const [currentPaymentName0, setCurrentPaymentName0] = signal<string>()
export const currentPayment0 = computed<Payment | undefined>(() => payments0().find(p => p.name === currentPaymentName0()))
export const isActivePaymentId = (type: string) => {
  if (_.find(scopeOrder0().payments, { type })) return true
  return false
}

export const onBack = async () => {
  if (blockMutateOrderButton() || hasPaidSeatOrder())
    return toast('You have already printed the invoice!', {
      type: 'info',
      autoClose: 500,
    })
  if (callbackPayment.fn) {
    router.screen = PosScreen.PENDING_ORDERS
    return
  }
  router.screen = PosScreen.ORDER
  revertUserActions(order0(), snapshot)
  if (params()?.orderMode === OrderMode.FAST_CHECKOUT || !posSetting0()?.generalSetting?.hasTablePlan) {
    const _order = order0()
    log('⚡️ Payment cancelling detected')
    if (isQuebecSrmEnabled()) {
      // Record cancelled Transaction
      // TODO: check if cancellation has item nor not
      await srmTransactionLogic.recordCancellation(_order, { makingPayment: true })
    }
    // _order.commits?.push({ action: CommitAction.CANCEL_BEFORE_PRINT, })
    // await updateOrder(_order, true);
    //fixme: handle cancel order immediately
    await removeOrder(_order)
  }

  setMultiPayment0(false)
}

export const canShowQrCode = (order: Order) => {
  if (!order.seatMode) {
    return order?.status === OrderStatus.PAID
  }
  if (order?.commits) {
    const foundCommit = order.commits.find(commit => commit.action === CommitAction.PAY)
    if (foundCommit) return true
  }
}

export const getLinkInvoice = (order: Order, seat?: number) => {
  if (!order.seatMode) {
    return `${getServer().manageServer.url}/invoice/${posSync0()?.id}/${order._id}`
  }
  if (seat !== undefined) {
    return `${getServer().manageServer.url}/invoice/${posSync0()?.id}/${order?.seatMap?.[seat]?._id}`
  }
}

export const [dialogProcessTerminalPaymentState, setProcessPaymentDialog] = signal({
  show: false,
  processing: false,
  response: undefined,
  error: undefined,
  status: '',
  onComplete: _.noop,
})

export function closeDialogProcessTerminalPayment() {
  setProcessPaymentDialog(prev => ({
    ...prev,
    status: '',
    show: false,
    response: undefined,
    error: undefined,
  }))
}

function findTerminalProfileById(terminalId: string) {
  return terminals0().find(item => item._id === terminalId)
}

async function selectCardReader(payment?: Payment): Promise<string | undefined> {
  if (_.isEmpty(payment?.terminalIds)) {
    await MsgBox.show('Action cancelled', "This payment method doesn't support card reader", MsgBox.Buttons.OK, MsgBox.Icons.Error)
    return
  }

  if (payment?.terminalIds!.length === 1) return payment?.terminalIds![0]

  console.log('[selectCardReader] more than 2 terminals. find default terminal for this device')
  const deviceId = getDeviceId()
  for (let _id of payment?.terminalIds!) {
    const terminal = findTerminalProfileById(_id)
    if (terminal?.useAsDefaultForPosDevices.includes(deviceId)) return terminal._id
  }

  console.log('[selectCardReader] no default terminal, show select card reader dialog')
  return DialogService.show<string | undefined>({
    component: function ({ onClose }) {
      return (
        <DialogService.BaseDialog
          title="Select terminal"
          onClose={onClose}
        >
          <p className="mb-2">Please select which terminal you want to use</p>
          <div className="flex row-flex gap-4">
            {payment?.terminalIds!.map(terminalId => (
              <div
                className="cursor-pointer self-stretch rounded-md bg-blue-solid-blue-420-2979ff w-28 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff"
                key={terminalId}
                onClick={() => onClose(terminalId)}
              >
                {terminals0().find(item => item._id === terminalId)?.name}
              </div>
            ))}
          </div>
        </DialogService.BaseDialog>
      )
    },
  })
}

export function addSinglePayment(paymentName: string, value: number, extraInfo?: Record<string, unknown>) {
  console.log(`⚡️ Adding payment [${paymentName}]: ${value}`)
  order0().commits!.push({
    action: order0()?.isGroupBill ? CommitAction.ADD_PAYMENT_ALL_SEATS : CommitAction.ADD_PAYMENT,
    type: paymentName,
    value,
    ...addSeat(),
    ...(extraInfo || {}),
  })
  setValue0(value.toString())
}

let terminalId: string | undefined
function _shouldShowInAppTipDialog(terminalProfile: any): boolean {
  const types = [TerminalTypes.Zvt, TerminalTypes.Simulator]
  return types.includes(terminalProfile.type)
}

async function processPaymentUseCardReader(payment: Payment, {customTip, defaultTip} : {customTip: boolean, defaultTip: number}, onCurrentPaymentComplete: (pmName: string, value: number, extraInfo?: Record<string, unknown>) => void) {
  terminalId = await selectCardReader(payment)
  if (!terminalId) {
    await MsgBox.show('Action cancelled', 'You must select card reader to continue proceed', MsgBox.Buttons.OK, MsgBox.Icons.Error)
    return
  }
  let tip = scopeOrder0().tip || 0
  if (customTip) {
    const terminalProfile = findTerminalProfileById(terminalId)
    if (_shouldShowInAppTipDialog(terminalProfile)) {
      const resp = await dialogService.show<{ action: string; tip: number }>({
        component: CustomTipDialog,
        bind: {
          vSum: scopeOrder0().vSum,
          defaultTip: defaultTip || 0
        },
        isLocofyPopup: true,
      })
      if (resp.action === 'ok') {
        tip = resp.tip
      } else {
        return
      }
    }
  }
  setProcessPaymentDialog(prev => ({ ...prev, show: true, processing: true, onComplete: _.noop }))
  const payTxPayload = {
    amount: scopeOrder0().vSum || 0,
    tip, // this may not correct for multiple payment
    tableNumber: scopeOrder0().table || '',
    invoiceNumber: '',
  }
  console.log('payTxPayload', payTxPayload)
  const onPayProgress = (status: string) => setProcessPaymentDialog(prev => Object.assign(prev, { status }))
  // @ts-ignore
  const { response, error } = await pay(terminalId, payTxPayload, onPayProgress)
  console.log('response', response)
  if (error) {
    // @ts-ignore
    setProcessPaymentDialog(prev => ({ ...prev, processing: false, error, onComplete: _.noop }))
    return
  }
  const onComplete = async () => {
    closeDialogProcessTerminalPayment()
    const { type } = response
    const { amount, tipAmount } = response.payment
    const paidValue = _.round(Number(amount) / 100 + Number(tipAmount) / 100, 2)
    const txLogDoc = {
      _id: uuid(),
      type,
      orderId: scopeOrder0()._id,
      terminalId,
      metadata: JSON.parse(JSON.stringify(response)), /*remove no-json-like data*/
    }
    // console.log('txLogDoc', txLogDoc)
    await TxLogCollection.upsert(txLogDoc)
    if (onCurrentPaymentComplete) {
      // TODO: (thinh) 'card' or specified type for example 'clover', 'zvt'
      // these info will be show in Order History, but specified type 'clover', 'zvt
      // is not display in receipt (bug)
      onCurrentPaymentComplete(payment.name!, paidValue, { by: type })
    }
    if (checkOrderPayment(order0())) {
      await onPaymentComplete({ needPrintInvoice: true })
    }
  }
  // @ts-ignore
  setProcessPaymentDialog(prev => ({ ...prev, processing: false, response, onComplete }))
}

export async function cancelProcessingPayment() {
  closeDialogProcessTerminalPayment()
  if (!terminalId) return
  cancel(terminalId!, {}, _.noop).catch(captureException)
}

export async function openPaymentProcessingPopUp() {
  const payment = currentPayment0()!
  const _payment = scopeOrder0().payments.find(p => p.type === payment.name)
  if (_payment && _payment.by) {
    await MsgBox.show('Action cancelled', 'This payment has been processed', MsgBox.Buttons.OK, MsgBox.Icons.Error)
    return
  }
  return processPaymentUseCardReader(currentPayment0()!, {customTip: false, defaultTip: 0}, (paymentName: string, value: number, extra?: Record<string, unknown>) => {
    console.log('extra', extra)
    order0().commits!.push({
      action: CommitAction.CHANGE_PAYMENT,
      type: paymentName,
      value: value,
      extraType: currentPayment0()?.type,
      ...addSeat(),
      ...(extra || {}),
    })
    setValue0(value.toString())
  })
}

export function clearPayment() {
  order0().commits!.push({
    action: CommitAction.CLEAR_PAYMENTS,
    ...addSeat(),
  })
}

export async function onPaymentClick(payment: Payment) {
  const _scopeOrder = scopeOrder0()
  const currentTip = _scopeOrder.cashTip || _scopeOrder.tip;
  _.assign(_scopeOrder, { cashTip: 0 })
  if (blockMutateOrderButton())
    return toast('You have already printed the invoice!', {
      type: 'info',
      autoClose: 500,
    })
  setFirstClick0(true)
  const _payment = _scopeOrder.payments.find(p => p.type === payment.name)

  const isDebitorPayment = payment.type === 'debitor' || _.lowerCase(payment.name) === 'debitor'

  //fixme: handle if customer choose debitor and order don't have customerRaw
  if (isDebitorPayment && !scopeOrder0().customerRaw) {
    setIsAddCustomer(true)
    setPaymentRedBillInfoPopupOpen(true)
  }
  setCurrentPaymentName0(payment.name!)
  if (_payment) {
    setValue0(_payment.value.toString())
    return
  }
  if (multiPayment0()) {
    addSinglePayment(payment.name!, 0)
  } else {
    const debitVal = roundNumber(_scopeOrder.vItemTotal ?? 0, 2)
    const nextVal = roundNumber((_scopeOrder.vSum ?? 0) + (_scopeOrder.tip ?? 0), 2)
    setValue0('0')
    oldPayment = _.last(order0().payments)?.type
    order0().commits!.push({
      action: CommitAction.CLEAR_PAYMENTS,
      ...addSeat(),
    })
    if (payment.isAutoApplyTerminal && !_.isEmpty(payment.terminalIds)) {
      await processPaymentUseCardReader(payment, {customTip: true, defaultTip: currentTip}, addSinglePayment)
    } else {
      addSinglePayment(payment.name!, isDebitorPayment ? debitVal : nextVal)
    }
  }
}

//check if order/seatOrder completed

export async function onPrintInvoiceComplete({ invoiceType = InvoiceTypes.INVOICE }: { invoiceType?: InvoiceTypes }) {
  if (order0().status !== OrderStatus.PAID) {
    // console.log('[print] onPrintInvoiceComplete.order0', order0())
    let preSplitHandlerNeeded = order0().status === OrderStatus.IN_PROGRESS
    if (!(await preventInsufficientPayment(order0() as Order))) return
    //create pay action
    //fixme: tao qua nhieu commits
    if (order0().status !== OrderStatus.PAID) {
      order0().commits!.push({
        action: CommitAction.PAY,
      })
    }

    _.assign(order0(), { printInvoice: true })
    await handlePrintLabel(order0())
    if (order0().seatMode) {
      //todo:
      for (const seatOrder of order0().seatMap!) {
        _.assign(seatOrder, { printInvoice: true })
      }
      if (preSplitHandlerNeeded) {
        await paymentHook.emit('preSplitHandler', undefined)
      }
      if (isQuebecSrmEnabled() && order0().isGroupBill) {
        await srmTransactionLogic.recordClosingReceipt(order0(), { print: true })
        for (const seatOrder of order0().seatMap ?? []) {
          seatOrder.commits?.push({ action: CommitAction.PRINT })
          order0().commits?.push({ action: CommitAction.PAY, seat: seatOrder.seat })
        }
        await runMasterHandlerPaidOrder(order0(), [])
        updateInventoryPostPay(order0())
        await updateVouchersPostPay(order0())
      } else
        for (const seatOrder of order0().seatMap!) {
          if (seatOrder._id !== scopeOrder0()._id) continue
          if (seatOrder.status === OrderStatus.PAID) continue

          if (checkSingleOrderPayments(seatOrder).status !== PaymentStatus.OK) return
          //fixme: bo print o day di
          seatOrder.commits?.push({ action: CommitAction.PRINT })
          order0().commits!.push({
            action: CommitAction.PAY,
            seat: seatOrder.seat,
          })
          await updateInProgressOrder(order0())
          if (getTotalItems(seatOrder) === 0) {
            //fixme: why updateOrder here ??
            await updateOrder(seatOrder)
            continue
          }
          const cmds: unknown[] = []
          paymentHook.emit('paySplitHandler', cmds).then()

          if (isQuebecSrmEnabled()) {
            log('✅ Payment completed, recording closing receipt...')
            await srmTransactionLogic.recordClosingReceipt(seatOrder, { print: true })
          } else cmds.push('printInvoice', invoiceType.toString())
          await runMasterHandlerPaidOrder(seatOrder, cmds as MasterHandler['cmd'])
        }

      //For fast checkout case
    } else {
      const cmds: any[] = []
      if (!order0().table && !order0().provider) {
        order0().commits?.push({ action: CommitAction.PRINT })
        cmds.push('onKitchenPrintFastCheckout')
        // await runMasterHandler(order0(), ['onKitchenPrintFastCheckout'], true);
      }
      await paymentHook.emit('payOrderHandler', cmds)
      if (isQuebecSrmEnabled()) {
        log('✅ Payment completed, recording closing receipt...')
        await srmTransactionLogic.recordClosingReceipt(order0(), { print: true })
      } else cmds.push('printInvoice', invoiceType.toString())
      await runMasterHandlerPaidOrder(order0(), [MasterHandlerCommand.singleComplete, cmds.join(',')])
      updateInventoryPostPay(order0())
      await updateVouchersPostPay(order0())
    }
  }

  //region utils
  async function handleCaseSingleOrderComplete() {
    const cmds: any[] = []
    await paymentHook.emit('payOrderHandler', cmds)
    cmds.push('printInvoice', invoiceType.toString())
    await runMasterHandlerPaidOrder(order0(), cmds as MasterHandler['cmd'])
    //todo: use hook here
    updateInventoryPostPay()
  }

  //endregion
}

function back() {
  //check if in PaymentScreen, used for Order History simulate payment change case
  if (router.screen !== PosScreen.PAYMENT) return

  if (orderMode0() === OrderMode.FAST_CHECKOUT) {
    router.screen = PosScreen.ORDER
    return
  }
  router.screen = mainScreen()
}

export async function onPaymentComplete({
  needPrintInvoice = false,
  moveToPaidOrder = true,
  invoiceType = InvoiceTypes.INVOICE,
}: {
  needPrintInvoice?: boolean
  moveToPaidOrder?: boolean
  invoiceType?: InvoiceTypes
}) {
  if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

  needPrintInvoice = needPrintInvoice || printerGeneralSetting0()?.autoPrintInvoiceBeforePaying || false

  if (order0().status !== OrderStatus.PAID && order0().status !== OrderStatus.DEBITOR) {
    let preSplitHandlerNeeded = order0().status === OrderStatus.IN_PROGRESS
    //fixme: check sufficient payment
    if (!(await preventInsufficientPayment(order0() as Order))) return
    await handlePrintLabel(order0())
    //create pay action
    //fixme: tao qua nhieu commits

    if (needPrintInvoice) {
      _.assign(order0(), { printInvoice: true })
    }

    if (callbackPayment.fn) {
      //fixme: handle sau
      const order = await callbackPayment.fn?.()
      order0().commits!.push({ action: CommitAction.PAY })

      order.tseMethod = TseMethod.passthrough
      await paymentHook.emit('payOrderHandler', [])
      await runMasterHandlerPaidOrder(order, [])

      return
    } else if (order0().seatMode) {
      // await preSplitHandler(order0());
      back()
      if (preSplitHandlerNeeded) {
        await paymentHook.emit('preSplitHandler', order0().tseMethod! === TseMethod.apply ? TseMethod.apply : TseMethod.auto)
      }

      let shouldRemoveOrder = true

      const currentOrder = order0()
      if (isQuebecSrmEnabled() && currentOrder.isGroupBill) {
        await srmTransactionLogic.recordClosingReceipt(currentOrder, { print: true })
        for (const seatOrder of currentOrder.seatMap ?? []) {
          seatOrder.commits?.push({ action: CommitAction.PRINT })
          currentOrder.commits?.push({ action: CommitAction.PAY, seat: seatOrder.seat })
        }
        await runMasterHandlerPaidOrder(currentOrder, [])
        updateInventoryPostPay(currentOrder)
        await updateVouchersPostPay(currentOrder)
      } else
        for (const seatOrder of currentOrder.seatMap!) {
          // skip empty seats on payment
          if (seatOrder.status === OrderStatus.PAID) continue

          if (checkSingleOrderPayments(seatOrder).status !== PaymentStatus.OK) {
            shouldRemoveOrder = false
            break
          }
          seatOrder.commits?.push({ action: CommitAction.PRINT })
          currentOrder.commits!.push({
            action: CommitAction.PAY,
            seat: seatOrder.seat,
          })
          if (getTotalItems(seatOrder) === 0) {
            //fixme: why updateOrder here ??
            await updateOrder(seatOrder)
          } else {
            const cmds: unknown[] = []
            paymentHook.emit('paySplitHandler', cmds).then()
            if (isQuebecSrmEnabled()) {
              await srmTransactionLogic.recordClosingReceipt(seatOrder, { print: true })
            } else {
              if (needPrintInvoice) cmds.push(MasterHandlerCommand.printInvoice, invoiceType.toString())
            }
            await runMasterHandlerPaidOrder(seatOrder, cmds as MasterHandler['cmd'])
            updateInventoryPostPay(seatOrder)
            await updateVouchersPostPay(seatOrder)
            //fixme: handle for Quebec
            if (!tseConfig0()?.tseEnable && !isQuebecSrmEnabled()) {
              //paySplitHandler won't be push in cmds if tse isn't enable
              if (isCashPayment(seatOrder)) {
                await createCashSale(seatOrder)
              }
            }
          }
          // await preSplitSeatHandler(seatOrder, currentOrder.tseMethod!);
          // await payOrderHandler(seatOrder);
        }
      //todo: check if all seat paid -> remove , if not -> update
      if (shouldRemoveOrder) {
        await removeOrder(currentOrder)
      } else {
        await runMasterHandler(currentOrder, [], false)
      }
      if (deviceSetting0()?.autoOpenCashDrawer) {
        await openCashDrawer()
      }
    } else {
      // Fast checkout mode
      order0().commits?.push({ action: CommitAction.PRINT })
      order0().commits!.push({ action: CommitAction.PAY })
      //todo: print invoice, mark as completed
      back()
      const cmds: any[] = []
      if (!order0().table) {
        cmds.push('onKitchenPrintFastCheckout')
        // await runMasterHandler(order0(), ['onKitchenPrintFastCheckout'], true);
      }
      await paymentHook.emit('payOrderHandler', cmds)
      if (isQuebecSrmEnabled()) {
        log('✅ Payment completed, recording closing receipt...')
        await srmTransactionLogic.recordClosingReceipt(order0(), { print: true })
      } else if (needPrintInvoice) {
        // console.log('needPrintInvoice', 'push command printInvoice')
        cmds.push('printInvoice', invoiceType.toString()) // Only print invoice if not in SRM mode
      }
      await runMasterHandlerPaidOrder(order0(), [MasterHandlerCommand.singleComplete, cmds.join(',')])
      updateInventoryPostPay(order0())
      await updateVouchersPostPay(order0())
      // await payOrderHandler(order0());
      if (deviceSetting0()?.autoOpenCashDrawer) {
        await openCashDrawer()
      }
    }
  } else {
    if (callbackPayment.fn) {
      //fixme: handle sau
      await callbackPayment.fn?.()
    } else {
      back()
    }
    order0().commits!.push({ action: CommitAction.PAY })

    if (isQuebecSrmEnabled()) {
      const order = order0()
      console.log('ℹ️ Order already paid and printed, skipping...', order)
    }
  }
  setMultiPayment0(false)
  // problem -> order history
}

export function addVoucher({ price, code, _id }: { price: number | string; code?: string; _id?: string }) {
  const voucherItem = {
    name: 'Voucher',
    price: +price,
    isVoucher: true,
    tax: 0,
    taxes: [0, 0],
    code: code || genVoucherCode(),
    _id: _id || uuid(),
  }
  order0().commits?.push({
    action: CommitAction.ADD_PRODUCT,
    ref: voucherItem!._id,
    _id: uuid(),
    productRef: voucherItem,
    ...addSeat(),
  })
}

export async function redeemVoucher(redeemCode: string) {
  const isVoucherAlready =
    order0().items.some(item => item.code === redeemCode && item.quantity > 0) ||
    (
      await Voucher.find({
        selector: {
          code: redeemCode,
          status: 'redeemed',
        },
      }).exec()
    ).length > 0

  const foundVoucher = await Voucher.findOne({
    selector: {
      code: redeemCode,
      status: {
        $not: 'redeemed',
      },
    },
  }).exec()

  if (foundVoucher && !isVoucherAlready) {
    const { price, code, _id } = foundVoucher
    addVoucher({
      price: -(price || 0),
      code,
      _id,
    })
    setVoucherStatus('valid')
  } else {
    if (!foundVoucher) setVoucherStatus('notFound')
    if (isVoucherAlready) setVoucherStatus('alreadyUsed')
  }
}

export const getActiveOrder = () => {
  return scopeOrder0()?.items.length > 0 ? scopeOrder0() : order0()
}

export function updateValue0() {
  const payments = getActiveOrder().payments
  const currentPayment = getActiveOrder().payments.find(p => p.type === currentPayment0()?.name)

  if (!multiPayment0()) {
    setValue0(getActiveOrder().vSum!.toString())
    changeTip(0)
  } else {
    if (currentPayment) {
      changeTip(0)
      let paymentNotIncludeCurrentPayment = _.filter(payments, p => p.type !== currentPayment.type)
      const notCurrentValue = _.sumBy(paymentNotIncludeCurrentPayment || [], 'value')
      const value = getActiveOrder().vSum! - notCurrentValue > 0 ? getActiveOrder().vSum! - notCurrentValue : 0
      setValue0(roundNumber(value, 2).toString())
    }
  }
}

export function addServiceFee(value: number) {
  const api = addServiceFeeFactory(order0())
  return api.addServiceFee(value)
}

export function addServiceFeeFactory(order: Order) {
  return {
    addServiceFee,
  }

  function addServiceFee(value: number) {
    oldPayment = _.last(order0().payments)?.type
    console.group(`💵 Adding service fee ${value}%...`)
    if (blockMutateOrderButton())
      return toast('You have already printed the invoice!', {
        type: 'info',
        autoClose: 500,
      })
    const valueFrom = order.serviceFee
    order.commits?.push({
      action: CommitAction.SET_SERVICE_FEE,
      value,
      ...addSeat(),
    })

    recordUserActionHistory(order, UserAction.CHANGE_SERVICE_FEE, {
      valueFrom,
      valueTo: order.serviceFee
    }).then()

    const activeOrder = getActiveOrder()
    let nextVal: number = roundNumber(activeOrder.vTotal!, 2)

    console.log(`» New order total 💵${nextVal}...`)
    setValue0(nextVal.toString())
    console.groupEnd()
  }
}

/** @deprecated use `changeTip` instead */
export function addTip(value: number) {
  if (blockMutateOrderButton())
    return toast('You have already printed the invoice!', {
      type: 'info',
      autoClose: 500,
    })
  order0().commits?.push({
    action: CommitAction.SET_TIP,
    value,
  })
}

export function changeTip(value: number) {
  const valueFrom = order0().tip
  oldPayment = _.last(order0().payments)?.type
  const payments = getActiveOrder().payments
  const currentPayment = getActiveOrder().payments.find(p => p.type === currentPayment0()?.name)
  let newValue0: string = '0'
  if (currentPayment?.extraType === 'cash') {
    if (multiPayment0()) {
      order0().commits?.push({
        action: CommitAction.CHANGE_CASH_TIP,
        value,
      })
      if (parseFloat(value0()) < getActiveOrder().vSum! + value) {
        newValue0 = roundNumber(getActiveOrder().vSum! + value, 2).toString()
      }
    } else {
      order0().commits?.push({
        action: CommitAction.CHANGE_CASH_TIP,
        value,
      })
      // newValue0 = roundNumber(parseFloat(value0()) + value, 2).toString()
      newValue0 = roundNumber(getActiveOrder().vSum! + value, 2).toString()
    }
    if (valueFrom !== order0().tip) {
      recordUserActionHistory(order0(), UserAction.CHANGE_TIP, {
        valueFrom,
        valueTo: order0().tip
      }).then()
    }
    setValue0(newValue0)
    return
  }

  const cashlessPayments = _.filter(payments, p => p.extraType !== 'cash')
  if (_.isEmpty(cashlessPayments)) {
    console.log('no cashless payments')
    return
  }

  if (multiPayment0()) {
    if (checkOrderPayment(getActiveOrder()) !== true) {
      // toast(isPaymentSufficent(scopeOrder0()));
    } else {
      newValue0 = roundNumber((currentPayment?.value || 0) + value, 2).toString()
    }
  } else {
    newValue0 = roundNumber(getActiveOrder().vSum! + value, 2).toString()
  }
  if (valueFrom !== order0().tip) {
    recordUserActionHistory(order0(), UserAction.CHANGE_TIP, {
      valueFrom,
      valueTo: order0().tip
    }).then()
  }
  setValue0(newValue0)
}

export function handleOrderDiscount(discount: Discount, order?: TOrder) {
  const _order = order ? order : order0();
  const valueFrom = _order.discount || 0
  const commit: ItemCommitAddOrderDiscount = {
    action: CommitAction.ADD_ORDER_DISCOUNT,
    type: discount.type ?? 'percent',
    value: (discount.value ?? 0) + '',
    label: discount.label ?? '',
  }

  if (_order?.seatMode && discount?.type === 'amount') {
    if (typeof _order?.discount === 'string' && (_order?.discount as string)?.includes('%')) {
      _order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER })
    }
    commit.seat = paymentContext0.seat()
    _order.commits?.push(commit)
  } else {
    _order.commits?.push(commit)
    setValue0(roundNumber(_order.vTotal!, 2).toString())
  }

  recordUserActionHistory(_order, UserAction.CHANGE_DISCOUNT_ORDER, {
    valueFrom,
    valueTo: discount.type === 'percent' ? `${discount.value}%` : `${discount.value}$`
  }).then();
}

export function handleClearDiscountOrder(order?: TOrder) {
  const _order = order ? order : order0()
  const valueFrom = order0().discount!
  const seat = paymentContext0.seat()
  if (typeof seat !== 'undefined' && (_order?.discountSeat?.length ?? 0) > 0) {
    const isExistDiscountSeatAmount = _order?.discountSeat?.find(d => d.seat === seat)
    if (isExistDiscountSeatAmount) {
      _order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER, seat: seat })
    }
  } else {
    _order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER })
    const actionData = (valueFrom && valueFrom !== '0') ? {
      valueFrom,
    } : undefined
    recordUserActionHistory(_order, UserAction.CLEAR_DISCOUNT_ORDER, actionData).then();
  }
}

//for rear display screen
effect(async () => {
  order0().discount
  order0().items.length
  order0().items.map(item => item.quantity)
  const o = scopeOrder0()
  const sentPaymentsMap = o.payments.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: payments0().find(payment => payment.name === curr.type)?.icon,
    }),
    {}
  )
  // if (o && !secondDisplayLock.acquired && router.screen === PosScreen.PAYMENT) wsSendOrder(o);
  await rnHost.sendOrderToSD(o)
})

// ingredient handlers

export const updateInventoryPostPay = (order?: Order) => {
  order?.items?.forEach(item => {
    item.ingredients?.forEach(ingredient => {
      const foundInventory = inventories0().find(inventory => inventory._id === ingredient.inventoryId)
      if (!foundInventory) return
      updateInventoryStock(foundInventory, -ingredient.amount * item.quantity, `sale: ${item.quantity} ${item.name}`)
    })
  })
}

//voucher handlers
const updateVouchersPostPay = async (order: Order) => {
  let newVouchers: Voucher[] = []

  for (const item of order.items || []) {
    if (item.quantity === 0 || !item.isVoucher) continue
    //new voucher
    if (item.price >= 0) {
      const newVoucher = await createVoucher({
        code: item.code,
        customerFirstName: item.voucher?.firstName,
        customerLastName: item.voucher?.lastName,
        isEqualPrice: item.price === item.voucher?.amount,
        price: item.price === item.voucher?.amount ? item.voucher.amount : item.price,
        amount: item.voucher?.amount,
      })
      newVouchers.push(newVoucher)
      setVoucherV(v => v + 1)
      continue
    }
    //redeem

    // const voucher = await Voucher.findOne({ selector: { code: item.code } }).exec();
    //
    // await Voucher.upsert({
    //   _id: voucher?._id,
    //   status: VoucherStatus.REDEEMED,
    // })

    const voucher = vouchers0().find(v => v.code === item.code)
    if (voucher) {
      if (voucher.totalValue > voucher.usedValue + Math.abs(item.price)) {
        _.assign(voucher, {
          usedValue: (voucher?.usedValue || 0) + Math.abs(item.price),
          customer: {
            firstName: item.voucher?.firstName || voucher.customerFirstName,
            lastName: item.voucher?.lastName || voucher.customerLastName,
          },
        })
      } else {
        _.assign(voucher, {
          status: VoucherStatus.REDEEMED,
          usedValue: (voucher?.usedValue || 0) + Math.abs(item.price),
          customer: {
            firstName: item.voucher?.firstName || voucher?.customerFirstName,
            lastName: item.voucher?.lastName || voucher?.customerLastName,
          },
        })
      }
    }
    setVoucherV(v => v + 1)
  }
  for (const raster of await printVoucher(newVouchers)) {
    await printInvoiceFromRaster(raster, { metadata: { orderId: order._id } })
  }
}

export function handleChangeServiceFee(amount: number, serviceFee: number) {
  if (serviceFee0() === amount) {
    let value;
    if (currentPayment0()?.type !== 'cash' || ['bar', 'cash'].includes(_.lowerCase(currentPayment0()?.name))) {
      value = getActiveOrder().payments?.find(p => p.type === currentPaymentName0())?.value;
    }
    addServiceFee(0)
    if (value) {
      let targetValue;
      if (useServiceFeeBeforeTax()) {
        const serviceFeeIncludeTax = roundNumber((getActiveOrder().vItemTotal ?? 0) * serviceFee / 100, 2)
        targetValue = roundNumber(Number(value || 0) - serviceFeeIncludeTax)
      } else {
        targetValue = roundNumber(Number(value || 0) - Number(amount || 0))
      }
      setValue0(String(targetValue))
    }
  } else
    addServiceFee(serviceFee)
}

const PaymentView = () => {
  //<editor-fold desc="onEnter">
  onEnter(PosScreen.PAYMENT, async () => {
    // let order1 = createOrder(demoOnlineorder0);
    // const _order = _.cloneDeep(order1);
    // const _order = clone(stripOrder(_order0()));
    // let order1 = createOrder(_order);
    // console.log('after create order', order1);
    // setOrder0(order1);
    // onPaymentClick({name: 'cash'} as Payment);
    oldPayment = undefined
    setPaymentScreenMode(PaymentScreenMode.PAYMENT)
    await delay(100)
    await printBody0()
    PrintStack._id = order0()._id
    if (order0().seatMode) {
      paymentContext0.setSeat(order0().seatMap!.findIndex(o => o.status !== OrderStatus.PAID))
    }
    if (order0()?.reactive) {
      updateValue0()
    }
  })
  //</editor-fold>
  makePaymentsAvailable()
  makeTerminalsAvailable()
  useOrderEditLocking(order0()._id, PosScreen.PAYMENT)

  return (
    <ItemFactoryContext.Provider value={{ ...paymentContext0, isPayment: true }}>
      <div className="flex-1 overflow-x-hidden no-scrollbar">
        {paymentScreenMode() === PaymentScreenMode.PAYMENT ? <PaymentScreenPlugin /> : <>{paymentContext0.splitEnable() ? <SplitBillVerticalPlugin /> : <PaymentVerticalPlugin />}</>}
      </div>
      {/*TODO: Change this to dialogService*/}
      {dialogProcessTerminalPaymentState().show && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
        >
          <PaymentProcessingPopUp />
        </PortalPopup>
      )}
      {isMasterMachineNotTurnedOn() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsMasterMachineNotTurnedOn(false)}
        >
          <PaymentScreenTurnOnMasterDev onClose={() => setIsMasterMachineNotTurnedOn(false)} />
        </PortalPopup>
      )}
    </ItemFactoryContext.Provider>
  )
}

export default memo(PaymentView)