import Bitmap from "./bitmap";
import JPEG from "jpeg-js";
import PNG_LIB from "pngjs";
import * as text from "./text";
import * as uint32 from "./uint32";
import NAMED_COLORS from "./named_colors";

const PNG = PNG_LIB.PNG;
/**
 * Create a new bitmap image
 *
 * @param {number} w       Image width
 * @param {number} h       Image height
 * @param {object} options Options to be passed to {@link Bitmap}
 *
 * @returns {Bitmap}
 */
export const make = function(w, h, options) {
	return new Bitmap(w, h, options);
};

/**
 * Encode the PNG image to output stream
 *
 * @param {Bitmap} bitmap    An instance of {@link Bitmap} to be encoded to PNG, `bitmap.data` must be a buffer of raw PNG data
 * @param {Stream} outstream The stream to write the PNG file to
 *
 * @returns {Promise<void>}
 */
export const encodePNGToStream = function(bitmap, outstream) {
	return new Promise((res, rej) => {
		if (!bitmap.hasOwnProperty("data") || !bitmap.hasOwnProperty("width") || !bitmap.hasOwnProperty("height")) {
			rej(new TypeError("Invalid bitmap image provided"));
		}
		var png = new PNG({
			width: bitmap.width,
			height: bitmap.height
		});
		for (var i = 0; i < bitmap.width; i++) {
			for (var j = 0; j < bitmap.height; j++) {
				var rgba = bitmap.getPixelRGBA(i, j);
				var n = (j * bitmap.width + i) * 4;
				var bytes = rgba === NAMED_COLORS.white ? [255, 255, 255, 255] : [0, 0, 0, 255];
				for (var k = 0; k < 4; k++) {
					png.data[n + k] = bytes[k];
				}
			}
		}

		png
			.on("error", (err) => {
				rej(err);
			})
			.pack()
			.pipe(outstream)
			.on("finish", () => {
				res();
			})
			.on("error", (err) => {
				rej(err);
			});
	});
};

/**
 * Encode JPEG To Stream
 *
 * Encode the JPEG image to output stream
 *
 * @param {Bitmap} img       An instance of {@link Bitmap} to be encoded to JPEG, `img.data` must be a buffer of raw JPEG data
 * @param {Stream} outstream The stream to write the raw JPEG buffer to
 * @param {Int} Number between 0 and 100 setting the JPEG quality
 * @returns {Promise<void>}
 */
export function encodeJPEGToStream(img, outstream, quality) {
	quality = quality || 90;
	return new Promise((res, rej) => {
		if (!img.hasOwnProperty("data") || !img.hasOwnProperty("width") || !img.hasOwnProperty("height")) {
			rej(new TypeError("Invalid bitmap image provided"));
		}
		var data = {
			data: img.data,
			width: img.width,
			height: img.height
		};
		outstream.on("error", (err) => rej(err));
		outstream.write(JPEG.encode(data, quality).data, () => {
			outstream.end();
			res();
		});
	});
}

/**
 * Decode JPEG From Stream
 *
 * Decode a JPEG image from an incoming stream of data
 *
 * @param {Stream} data A readable stream to decode JPEG data from
 *
 * @returns {Promise<Bitmap>}
 */
export function decodeJPEGFromStream(data) {
	return new Promise((res, rej) => {
		try {
			var chunks = [];
			data.on("data", chunk => chunks.push(chunk));
			data.on("end", () => {
				var buf = Buffer.concat(chunks);
				var rawImageData = JPEG.decode(buf);
				var bitmap = new Bitmap(rawImageData.width, rawImageData.height);
				for (var x_axis = 0; x_axis < rawImageData.width; x_axis++) {
					for (var y_axis = 0; y_axis < rawImageData.height; y_axis++) {
						var n = (y_axis * rawImageData.width + x_axis) * 4;
						bitmap.setPixelRGBA_i(x_axis, y_axis,
							rawImageData.data[n + 0],
							rawImageData.data[n + 1],
							rawImageData.data[n + 2],
							rawImageData.data[n + 3]
						);
					}
				}
				res(bitmap);
			});
			data.on("error", (err) => {
				rej(err);
			});
		} catch (e) {
			console.log(e);
			rej(e);
		}
	});
}

/**
 * Decode PNG From Stream
 *
 * Decode a PNG file from an incoming readable stream
 *
 * @param {Stream} instream A readable stream containing raw PNG data
 *
 * @returns {Promise<Bitmap>}
 */
export function decodePNGFromStream(instream, options = { bgColor: { r: 255, g: 255, b: 255 } }) {
	return new Promise((res, rej) => {
		instream.pipe(new PNG())
			.on("parsed", function() {
				var bitmap = new Bitmap(this.width, this.height);
				for (var i = 0; i < this.data.length; i += 4) {
					const r = this.data[i + 3] === 0 ? options.bgColor.r : this.data[i];
					const g = this.data[i + 3] === 0 ? options.bgColor.g : this.data[i + 1];
					const b = this.data[i + 3] === 0 ? options.bgColor.b : this.data[i + 2];
					const rgbaHex = uint32.fromBytesBigEndian(r, g, b, this.data[i + 3]);
					bitmap.setPixelRGBA_index(i / 4, rgbaHex);
				}
				res(bitmap);
			}).on("error", function(err) {
			rej(err);
		});
	});
}

/**@ignore */
export const registerFont = text.registerFont;
