import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import TextField from '@/react/core/TextField.tsx'

type DataExportPopupType = {
  onClose?: () => void;
};

const DataExportPopup: FunctionComponent<DataExportPopupType> = ({
  onClose,
}) => {
  return (
    <div className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start pt-8 px-4 pb-6 box-border gap-[16px] min-w-[560px] h-full max-w-full max-h-full text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
      <b className="self-stretch relative text-base z-[0]">
        GDPdU/GoBD {LL0().order.dataExporters()}
      </b>
      <img
        className="w-[21px] absolute !m-[0] top-[12px] right-[12px] h-[21px] object-cover cursor-pointer z-[1]"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] z-[2] text-black-solid-black-880-1d1d26">
        <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
          <b className="relative sm:text-smi Tablet_768:text-smi">{LL0().orderHistory.type()}</b>
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-white-solid-white-100-ffffff">
            <div className="flex-1 rounded-10xs bg-blue-solid-blue-400-0091ff overflow-hidden flex flex-row items-center justify-center py-2 px-3 relative gap-[8px]">
              <div className="w-full absolute my-0 mx-[!important] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-palette-blue-style box-border hidden z-[0] border-[1px] border-solid border-palette-blue-style" />
              <div className="relative font-semibold z-[1]">GoDB</div>
            </div>
            <div className="flex-1 rounded-10xs bg-gray-solid-gray-135-efefef overflow-hidden flex flex-row items-center justify-center py-2 px-3 relative gap-[8px] text-black-solid-black-900-1e1e23">
              <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-palette-blue-style box-border hidden z-[0] border-[1px] border-solid border-palette-blue-style" />
              <div className="relative font-semibold z-[1]">DSFINV</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-end gap-[16px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
            <b className="relative sm:text-smi Tablet_768:text-smi">{LL0().report.from()}</b>
            <TextField
              className="self-stretch shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)] font-mulish text-sm text-black-solid-black-880-1d1d26"
              color="primary"
              size="small"
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "40px" } }}
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
            <b className="relative sm:text-smi Tablet_768:text-smi">{LL0().report.to()}</b>
            <TextField
              className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
              color="primary"
              size="small"
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "40px" } }}
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <b className="relative sm:text-smi Tablet_768:text-smi">
            {LL0().order.exportTo()}:
          </b>
          <div className="relative sm:text-smi Tablet_768:text-smi">
            {LL0().order.notUSBFound()}
          </div>
        </div>
      </div>
      <div className="self-stretch h-9 flex flex-row items-start justify-between z-[3] text-center text-white">
        <div className="flex flex-row items-start justify-start gap-[8px]">
          <div className="rounded-md bg-purple-solid-purple-500-586cf5 overflow-hidden flex flex-row items-center justify-center py-[9px] px-4">
            <b className="relative">{LL0().order.refresh()}</b>
          </div>
          <div className="rounded-md bg-green-solid-green-415-7ab76f overflow-hidden flex flex-row items-center justify-center py-[9px] px-4">
            <b className="relative">{LL0().order.leave()}</b>
          </div>
        </div>
        <div className="rounded-md bg-green-solid-green-415-7ab76f overflow-hidden flex flex-row items-center justify-center py-[9px] px-4">
          <b className="relative">Tse {LL0().orderHistory.info()}</b>
        </div>
      </div>
    </div>
  );
};

export default DataExportPopup;
