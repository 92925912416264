import { FunctionComponent, useState } from 'react';
import { createNewGroupPrinter, defaultPrinterTaxes } from '@/react/Printer/PrinterSettingView.tsx';
import _ from 'lodash';
import { LL0 } from '@/react/core/I18nService.tsx';
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

export type PrinterSettingSelectTaxCatType = {
  className?: string;
  onClose?: () => void;
};

const PrinterSettingSelectTaxCat: FunctionComponent<
  PrinterSettingSelectTaxCatType
> = ({ onClose }) => {
  const [productType, setProductType] = useState<string>("other")

  const handleSave = async () => {
    onClose?.();
    const foundDefaultTaxes = defaultPrinterTaxes()[productType]
    await createNewGroupPrinter('kitchen', { defaultDineInTax: foundDefaultTaxes?.dineIn,
      defaultTakeAwayTax: foundDefaultTaxes?.takeAway, productType });
  }

  return (
    <div className="relative rounded bg-white-solid-white-100-ffffff flex flex-col items-center justify-start pt-[22px] px-[22px] pb-5 box-border gap-[24px_0px] min-w-[414px] h-full w-full max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-900-1e1e23 font-mulish">
      <div className="self-stretch relative font-semibold">
        Select the tax category below for this printer:
      </div>
      <div className="self-stretch flex flex-col items-start justify-center gap-[16px] text-mini text-gray-solid-gray-920-4e4e4e">
        { _.entries(defaultPrinterTaxes()).filter(([name]) => ["food", "drink"].includes(name))
          ?.map(([name, {dineIn, takeAway}], index) => (
          <div className="flex flex-row items-center justify-start gap-[0px_16px]" key={index}>
            <input
            className="cursor-pointer m-0 w-5 relative h-5"
              type="radio"
              name="productType"
              checked={productType === name}
              onChange={_ => setProductType(name)}
            />
            <div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-3 relative gap-[14px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc">
              { productType === name &&
              <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff z-[0]" />
              }
              <i className="relative font-medium z-[1]">{LL0().printerSetting[name as "food" | "drink"]()}</i>
              { generalSetting0()?.taxType === "two" ?
              <>
              <b className="relative z-[2]">{LL0().printing.dineIn()}: {dineIn}%</b>
              <b className="relative z-[3]">{LL0().printing.takeAway()}: {takeAway}%</b>
              </>
              :
                <b className="relative z-[3]">{takeAway || dineIn}%</b>
              }
            </div>
          </div>
        ))}
        <div className="flex flex-row items-center justify-start gap-[0px_16px]">
          <input
            className="cursor-pointer m-0 w-5 relative h-5"
            type="radio"
            name="productType"
            checked={productType === "other"}
            onChange={_ => setProductType("other")}
          />
          <div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-3 relative gap-[14px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc">
            { productType === "other" &&
            <div className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff hidden z-[0]" />
            }
            <i className="relative font-medium z-[1]">{LL0().printerSetting.none()}</i>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-end gap-[12px] text-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="self-stretch w-[101px] rounded-md bg-white-solid-white-100-ffffff box-border overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="self-stretch w-[100px] rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff"
             onClick={handleSave}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default PrinterSettingSelectTaxCat;
