import { useSignalEffect, useSignal, useEffectOn } from '@/react/core/reactive';
import {
	CSSProperties,
	PointerEventHandler,
	PropsWithChildren,
	ReactElement,
	PointerEvent as RPointerEvent,
	useCallback, useRef, useEffect
} from 'react';
import _ from 'lodash';

interface DraggableResizableRectangleProps {
	width: number;
	height: number;
	top: number;
	left: number;
	setWidth: (value: number) => void;
	setHeight: (value: number) => void;
	setTop: (value: number) => void;
	setLeft: (value: number) => void;
	style?: CSSProperties;
	active: boolean;
	setActive: (value: boolean) => void;
	resizeIcon?: (onResizeMouseDown: (e: RPointerEvent<HTMLImageElement>) => void,
								onResizeTouchStart: (e: TouchEvent<HTMLImageElement>) => void,
	) => ReactElement;
	className?: string
}

export default function DraggableResizableRectangle(
	props: DraggableResizableRectangleProps & PropsWithChildren
) {
	const rectRef = useRef<HTMLDivElement | null>(null);

	const [width, setWidth] = useSignal(props.width);
	const [height, setHeight] = useSignal(props.height);
	const [posX, setPosX] = useSignal(props.left);
	const [posY, setPosY] = useSignal(props.top);

	useEffect(() => {
		rectRef.current?.addEventListener("touchstart", onTouchStart, {passive: false})
	}, []);

	const onMouseDown = (e: RPointerEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (!props.active) {
			props.setActive(true);
			// do not return so that user can immediately drag and drop instead of click then drag
		}
		const initialX = e.clientX - posX();
		const initialY = e.clientY - posY();

		const onMouseMove = (e: PointerEvent) => {
			const newX = e.clientX - initialX;
			const newY = e.clientY - initialY;
			const snappedX = Math.round(newX / 2) * 2;
			const snappedY = Math.round(newY / 2) * 2;

			setPosX(snappedX);
			setPosY(snappedY);
		};

		const onPointerUp = () => {
			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("mouseup", onPointerUp);
		};

		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onPointerUp);
	};

	const onTouchStart = (e: TouchEvent) => {
		e.preventDefault();
		e.stopPropagation();

		if (!props.active) {
			props.setActive(true);
			// do not return so that user can immediately drag and drop instead of click then drag
		}
		const initialX = e.touches[0].clientX - posX();
		const initialY = e.touches[0].clientY - posY();

		const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
			const newX = e.touches[0].clientX - initialX;
			const newY = e.touches[0].clientY - initialY;
			const snappedX = Math.round(newX / 2) * 2;
			const snappedY = Math.round(newY / 2) * 2;

			setPosX(snappedX);
			setPosY(snappedY);
		};

		const onTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
			document.removeEventListener("touchmove", onTouchMove);
			document.removeEventListener("touchend", onTouchEnd);
		};

		document.addEventListener("touchmove", onTouchMove, {passive: false});
		document.addEventListener("touchend", onTouchEnd, {passive: false});
	};

	const onResizeMouseDown = (e: RPointerEvent<HTMLImageElement>) => {
		if (!props.active) return;
		e.stopPropagation();
		const initialWidth = width();
		const initialHeight = height();
		const initialX = e.clientX;
		const initialY = e.clientY;

		const onMouseMove = (e: PointerEvent) => {
			const newWidth = initialWidth + (e.clientX - initialX);
			const newHeight = initialHeight + (e.clientY - initialY);
			const snappedWidth = Math.max(5, Math.round(newWidth / 5) * 5);
			const snappedHeight = Math.max(5, Math.round(newHeight / 5) * 5);

			setWidth(snappedWidth);
			setHeight(snappedHeight);
		};

		const onPointerUp = () => {
			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("mouseup", onPointerUp);
		};

		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onPointerUp);
	};

	const onResizeTouchStart = (e: TouchEvent<HTMLImageElement>) => {
		if (!props.active) return;
		e.stopPropagation();
		const initialWidth = width();
		const initialHeight = height();
		const initialX = e.touches[0].clientX;
		const initialY = e.touches[0].clientY;

		const onTouchMove = (e: TouchEvent) => {
			const newWidth = initialWidth + (e.touches[0].clientX - initialX);
			const newHeight = initialHeight + (e.touches[0].clientY - initialY);
			const snappedWidth = Math.max(5, Math.round(newWidth / 5) * 5);
			const snappedHeight = Math.max(5, Math.round(newHeight / 5) * 5);

			setWidth(snappedWidth);
			setHeight(snappedHeight);
		};

		const onTouchEnd = () => {
			document.removeEventListener("touchmove", onTouchMove);
			document.removeEventListener("touchend", onTouchEnd);
		};

		document.addEventListener("touchmove", onTouchMove, {passive: false});
		document.addEventListener("touchend", onTouchEnd, {passive: false});
	};

	const setPropertiesDebounce = useCallback(_.debounce(() => {
		props.setWidth(width());
		props.setHeight(height());
		props.setLeft(posX());
		props.setTop(posY());
	}, 1000), [])

	useEffectOn([width, height, posX, posY], setPropertiesDebounce);

	return (
		<div
			className={`absolute cursor-move ${props.className}`}
			style={{
				width: `${width()}px`,
				height: `${height()}px`,
				left: `${posX()}px`,
				top: `${posY()}px`,
				...props.style
			}}
			ref={rectRef}
			onMouseDown={onMouseDown}
		>
			{props.children}
			{props.active && props.resizeIcon?.(onResizeMouseDown, onResizeTouchStart)}
		</div>
	);
}
