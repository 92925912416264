import PortalPopup from '@eod/PortalPopup.tsx'
import { createTheme, Tab, Tabs, ThemeProvider } from '@mui/material'
import _ from 'lodash'
import React, { memo, useState } from 'react'

import { Button } from '@/components/ui/button.tsx'
import { posSettingV, setPosSettingV } from '@/data/PosSettingHub.ts'
import { companyInfo0, mainScreen } from '@/data/PosSettingsSignal.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { router } from '@/pos/PosRouter.ts'
import { deepSignal, onMount } from '@/react/core/reactive.ts'
import { processing } from '@/react/Developer/DataMenu.logic.ts'
import DeleteAllPopup from '@/react/Developer/DeleteAllPopup.tsx'
import DeveloperDataTab from '@/react/Developer/DeveloperDataTab.tsx'
import DeveloperErudaTab from '@/react/Developer/DeveloperErudaTab.tsx'
import DeveloperImportExportTab from '@/react/Developer/DeveloperImportExportTab.tsx'
import DeveloperOtherTab from '@/react/Developer/DeveloperOtherTab.tsx'
import DeveloperTSETab from '@/react/Developer/DeveloperTSETab.tsx'
import RegisterStore from '@/react/Developer/RegisterStore.tsx'
import UpdateNowPopup from '@/react/Developer/UpdateNowPopup.tsx'
import DeveloperSrmTab from '@/srm/components/developer/DeveloperSrmTab'
import { deregisterTse } from '@/tse/tse-communicate.ts'

const muiTheme = createTheme()

export function TabPanel(props: { children: React.ReactNode; value: string; index: string }) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <div className="p-4 overflow-y-auto no-scrollbar">{children}</div>}
    </div>
  )
}

export function setReplicateServerUserNamePassword(storeId: number, password: string) {
  if (posSync0() && !posSync0()?.replicateServer) {
    posSync0()!.replicateServer = deepSignal({} as { username: string; password: string })
  }
  _.assign(posSync0()?.replicateServer, { username: `user_n${storeId}`, password })
}

export async function deregisterTSE() {
  await deregisterTse()
}

const Developer = () => {
  const [currentTab, setCurrentTab] = useState(localStorage.getItem('srm_runningTestcase') ? '1' : '0')

  onMount(() => {
    if (posSettingV() === 0) {
      setPosSettingV(1)
    }
  })

  const handleChangeTab = (_: unknown, newValue: string) => setCurrentTab(newValue)

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={'w-full h-full no-scrollbar p-[10px] overflow-y-auto'}>
        <div className="relative border-b border-b-slate-200 grid grid-cols-[1fr_max-content] gap-2 items-center">
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons={false}
          >
            <Tab
              value="0"
              label="General"
            />
            {companyInfo0()?.country !== 'de' && (
              <Tab
                value="1"
                label="Quebec SRM"
                data-testid="developer.tabs.srm"
              />
            )}
            {server() !== 'canada1' && (
              <Tab
                value="2"
                label="TSE"
              />
            )}
            <Tab
              value="3"
              label="Data"
            />
            <Tab
              value="4"
              label="Import/ Export"
            />
            <Tab
              value="5"
              label="Eruda"
            />
            <Tab
              value="6"
              label="Others"
            />
          </Tabs>
          <Button
            data-testid={'developer.back'}
            onClick={() => (router.screen = mainScreen())}
          >
            Back
          </Button>
        </div>
        <TabPanel
          value={currentTab}
          index="0"
        >
          <RegisterStore type={'Developer'} />
        </TabPanel>

        <TabPanel
          value={currentTab}
          index="1"
        >
          <DeveloperSrmTab />
        </TabPanel>

        <TabPanel
          value={currentTab}
          index={'2'}
        >
          <DeveloperTSETab />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index="3"
        >
          <DeveloperDataTab />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index="4"
        >
          <DeveloperImportExportTab />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index="5"
        >
          <DeveloperErudaTab />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index="6"
        >
          <DeveloperOtherTab />
        </TabPanel>
      </div>
      {processing() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
        >
          <button
            type="button"
            className="bg-gray-solid-gray-750-696969 text-white p-4 rounded-xl ..."
            disabled
          >
            Processing...
          </button>
        </PortalPopup>
      )}
      <UpdateNowPopup />
      <DeleteAllPopup />
    </ThemeProvider>
  )
}

export default memo(Developer)
