import { Fragment, type FunctionComponent, useCallback, useRef, useState } from 'react'
import DateChoosingPopup, { FilterDate } from '@order-history/DateChoosingPopup.tsx'
import PortalPopup from '@order-history/PortalPopup'
import TypeChoosingPopup from '@order-history/TypeChoosingPopup'
import PaymentMethodPopup from '@order-history/PaymentMethodPopup'
import OrderHistoryTableRow from '@order-history/OrderHistoryTableRow.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import {
  datePickerFilter, filterOrderHistory,
  handleFilterOrderHistory,
  isCurrentOrder,
  isReachingEnd,
  isValidatingOrders,
  orders1,
  setDatePickerFilter,
  setIsValidatingOrders,
  setOrdersPage, setStaffCustomerFilter, setTypeOrderFilter,
  setTypePaymentFilter, staffCustomerFilter, typeOrderFilter,
  typePaymentFilter
} from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import InfiniteScroll from '@order-history/InfiniteScrollContainer.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import StaffCustomerPopup from '@order-history/StaffCustomerPopup'
import dayjs from 'dayjs'
import _ from 'lodash'
import { LL2 } from "@/react/core/I18nBackend.tsx";

const HistoryLeftPath: FunctionComponent = () => {
  const [isDateChoosingPopupOpen, setDateChoosingPopupOpen] = useState(false)
  const [isTypeChoosingPopupOpen, setTypeChoosingPopupOpen] = useState(false)
  const [isPaymentMethodPopupOpen, setPaymentMethodPopupOpen] = useState(false)
  const [isStaffCustomerPopupOpen, setStaffCustomerPopupOpen] = useState(false)
  const leftPathRef = useRef<HTMLDivElement>(null)

  const openDateChoosingPopup = useCallback(() => {
    setDateChoosingPopupOpen(true)
  }, [])

  const closeDateChoosingPopup = useCallback(() => {
    setDateChoosingPopupOpen(false)
  }, [])

  const openTypeChoosingPopup = useCallback(() => {
    setTypeChoosingPopupOpen(true)
  }, [])

  const closeTypeChoosingPopup = useCallback(() => {
    setTypeChoosingPopupOpen(false)
  }, [])

  const openPaymentMethodPopup = useCallback(() => {
    setPaymentMethodPopupOpen(true)
  }, [])

  const closePaymentMethodPopup = useCallback(() => {
    setPaymentMethodPopupOpen(false)
  }, [])

  const openStaffCustomerPopup = useCallback(() => {
    setStaffCustomerPopupOpen(true)
  }, [])

  const closeStaffCustomerPopup = useCallback(() => {
    setStaffCustomerPopupOpen(false)
  }, [])
  const onClickScrollToTop = useCallback(
    () =>
      leftPathRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      }),
    []
  )

  const isFiltering = Object.entries(filterOrderHistory()).length > 0

  return (
    <>
      <div ref={leftPathRef}
           className="no-scrollbar self-stretch overflow-y-auto flex-1 flex flex-col items-start justify-start bg-[url('/history-left-path-BG@3x.png')] bg-cover bg-no-repeat bg-[top] z-[0] text-center text-sm text-white-solid-white-100-ffffff font-mulish-button-name-bold">
        <div
          className="self-stretch relative shrink-0 grid grid-cols-[53px_2fr_2fr_2fr_1fr_2fr_3fr] [grid-row-gap:0px] [grid-column-gap:0px] Tablet_768:grid Tablet_768:grid-cols-[53px_2fr_2fr_2fr_1fr_1fr] Tablet_768:[grid-row-gap:0px] Tablet_768:[grid-column-gap:0px] Tablet_600:grid Tablet_600:grid-cols-[53px_2fr_2fr_2fr_1fr_1fr] Tablet_600:[grid-row-gap:0px] Tablet_600:[grid-column-gap:0px] Mobile_480:grid Mobile_480:grid-cols-[53px_1fr_1fr_2fr_1fr] Mobile_480:[grid-row-gap:0px] Mobile_480:[grid-column-gap:0px] mq320:grid mq320:grid-cols-[53px_1fr_1fr_2fr_1fr] mq320:[grid-row-gap:0px] mq320:[grid-column-gap:0px]"
          style={{ gridTemplateRows: `38px 44px repeat(${orders1().length}, 1fr) 1fr` }}
        >
          {/*<div className="self-stretch flex-1 flex flex-col items-start justify-start bg-[url('/history-left-path@3x.png')] bg-cover bg-no-repeat bg-[top] z-[0] text-center text-sm text-white-solid-white-100-ffffff font-mulish-button-name-bold">*/}
          {/*  <div className="self-stretch relative h-fit overflow-y-auto shrink-0 grid grid-cols-[53px_2fr_2fr_1fr_1fr_3fr] [grid-row-gap:0px] [grid-column-gap:0px] sm:grid sm:grid-cols-[53px_2fr_2fr_1fr_1fr_3fr] sm:[grid-row-gap:0px] sm:[grid-column-gap:0px] Tablet_768:grid Tablet_768:grid-rows-[repeat(0,1fr)_44px_1fr] Tablet_768:grid-cols-[53px_2fr_2fr_1fr_1fr] Tablet_768:[grid-row-gap:0px] Tablet_768:[grid-column-gap:0px]"*/}
          {/*    style={{gridTemplateRows: `38px 44px repeat(${orders1().length}, 1fr) 1fr`}}*/}
          {/*  >*/}
          <div
            className="bg-black-solid-black-700-364150 h-[38px] flex flex-row items-center justify-center py-2.5 px-1.5 box-border gap-[2px] min-w-[40px] relative sm:relative">
            <img
              className="relative w-4 h-4 object-cover hidden"
              alt=""
              src="/order-history-iconsearch-icon1@2x.png"
            />
            <b className="flex-1 relative Tablet_768:text-xs">{LL0().orderHistory.orderNo()}</b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 box-border h-[38px] flex flex-row items-center justify-center py-2.5 px-1.5 gap-[2px] cursor-pointer relative border-l-[1px] border-solid border-white-solid-white-100-ffffff sm:relative"
            onClick={openDateChoosingPopup}
            ref={makeRipple}
          >
            <img
              className="w-4 relative h-4 object-cover sm:hidden"
              alt=""
              src="/order-history-iconsearch-icon1@2x.png"
            />
            <b className="relative Tablet_768:text-xs">{LL0().orderHistory.date()}</b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 box-border h-[38px] flex flex-row items-center justify-center py-2.5 px-1.5 gap-[2px] cursor-pointer relative border-l-[1px] border-solid border-white-solid-white-100-ffffff sm:relative"
          >
            <b className="relative Tablet_768:text-xs">Provider</b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 box-border h-[38px] flex flex-row items-center justify-center py-2.5 px-1.5 gap-[2px] cursor-pointer relative border-l-[1px] border-solid border-white-solid-white-100-ffffff sm:relative"
            onClick={openTypeChoosingPopup}
            ref={makeRipple}
          >
            <img
              className="w-4 relative h-4 object-cover"
              alt=""
              src="/order-history-iconsearch-icon11@2x.png"
            />
            <b className="relative Tablet_768:text-xs">{LL0().orderHistory.type()}</b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 box-border h-[38px] flex flex-row items-center justify-center py-2.5 px-1.5 gap-[2px] border-l-[1px] border-solid border-white-solid-white-100-ffffff Tablet_600:relative Mobile_480:hidden Mobile_480:relative mq320:hidden mq320:relative">
            <img
              className="w-4 relative h-4 object-cover hidden"
              alt=""
              src="/order-history-iconsearch-icon1@2x.png"
            />
            <b className="flex-1 relative Tablet_768:text-xs">{LL0().orderHistory.amount()}</b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 box-border h-[38px] flex flex-row items-center justify-center py-2.5 px-1.5 gap-[2px] cursor-pointer relative border-l-[1px] border-solid border-white-solid-white-100-ffffff"
            onClick={openPaymentMethodPopup}
            ref={makeRipple}
          >
            <img
              className="w-4 relative h-4 object-cover sm:hidden"
              alt=""
              src="/order-history-iconsearch-icon1@2x.png"
            />
            <b className="relative Tablet_768:text-xs">{LL0().orderHistory.changePayment()}</b>
          </div>
          <div
            className="bg-black-solid-black-700-364150 box-border h-[38px] flex flex-row items-center justify-center py-2.5 px-1.5 gap-[2px] cursor-pointer relative border-l-[1px] border-solid border-white-solid-white-100-ffffff sm:relative Tablet_768:hidden Tablet_768:relative Tablet_600:hidden Tablet_600:relative Mobile_480:hidden Mobile_480:relative mq320:hidden mq320:relative"
            onClick={openStaffCustomerPopup}
            ref={makeRipple}
          >
            <img
              className="w-4 relative h-4 object-cover sm:hidden"
              alt=""
              src="/order-history-iconsearch-icon1@2x.png"
            />
            <b className="relative Tablet_768:text-xs">{LL0().orderHistory.staffCustomer()}</b>
          </div>

          {isFiltering && <div
            className="bg-gray-solid-gray-300-dbdbdb flex flex-row items-center justify-between py-[7px] px-2.5 relative col-span-full text-left text-xs text-red-solid-red-660-8f181d Tablet_768:relative">
            <div
              className="bg-gray-solid-gray-300-dbdbdb flex flex-row items-center justify-between py-[7px] px-2.5 relative col-[1/7] row-[2/2] text-left text-xs text-red-solid-red-660-8f181d Tablet_768:py-1 Tablet_768:px-1.5 Tablet_768:relative Tablet_768:col-[1/6] Tablet_768:row-[2/2] Tablet_600:relative Tablet_600:col-[1/6] Tablet_600:row-[2/2]">
              <div
                className="flex-1 shrink-0 flex flex-row flex-wrap items-center justify-start py-0 px-2.5 gap-[12px]">
                <b className="relative inline-block shrink-0 Tablet_768:text-3xs">{LL0().orderHistory.filter()}:</b>
                {!!(datePickerFilter()?.from && datePickerFilter()?.to) && <div
                  className="rounded bg-white-solid-white-100-ffffff shrink-0 flex flex-row items-center justify-start p-1 gap-[5px] text-gray-solid-gray-900-616161">
                  <div className="shrink-0 flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative Tablet_768:text-3xs">{LL0().orderHistory.date()}:</div>
                    <div className="relative Tablet_768:text-3xs">
                      {datePickerFilter()?.choose === FilterDate.TODAY ? LL0().onlineOrder.today() :
                        datePickerFilter()?.choose === FilterDate.YESTERDAY ? LL0().onlineOrder.yesterday() :
                          datePickerFilter()?.choose === FilterDate.THIS_WEEK ? LL0().onlineOrder.thisWeek() :
                            datePickerFilter()?.choose === FilterDate.THIS_MONTH ? LL0().onlineOrder.thisMonth() :
                              `${dayjs(datePickerFilter()?.from).format(LL2().dates.dateFormat())} - ${dayjs(datePickerFilter()?.to).format(LL2().dates.dateFormat())}`
                      }
                    </div>
                  </div>
                  <div className="relative w-4 h-4 overflow-hidden shrink-0"
                       onClick={() => {
                         setDatePickerFilter({ from: 0, to: 0 })
                         handleFilterOrderHistory()
                       }}
                  >
                    <img
                      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                      alt=""
                      src="/icondelete-fillter-icon@2x.png"
                    />
                  </div>
                </div>}
                {typePaymentFilter() &&
                  <div
                    className="rounded bg-white-solid-white-100-ffffff shrink-0 flex flex-row items-center justify-start p-1 gap-[5px] text-gray-solid-gray-900-616161">
                    <div className="shrink-0 flex flex-row items-start justify-start gap-[2px]">
                      <div className="relative Tablet_768:text-3xs">{LL0().payment.paymentMethod()}:</div>
                      <div
                        className="relative Tablet_768:text-3xs">{_.get(LL0().payment, typePaymentFilter())?.() || typePaymentFilter()}</div>
                    </div>
                    <div className="relative w-4 h-4 overflow-hidden shrink-0" onClick={() => {
                      setTypePaymentFilter('')
                      handleFilterOrderHistory()
                    }}>
                      <img
                        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                        alt=""
                        src="/icondelete-fillter-icon@2x.png"
                      />
                    </div>
                  </div>}
                {staffCustomerFilter() && <div
                  className="rounded bg-white-solid-white-100-ffffff shrink-0 flex flex-row items-center justify-start p-1 gap-[5px] text-gray-solid-gray-900-616161">
                  <div className="shrink-0 flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative Tablet_768:text-3xs">{LL0().monthlyReport.staff()}:</div>
                    <div
                      className="relative Tablet_768:text-3xs">{staffCustomerFilter()}</div>
                  </div>
                  <div className="relative w-4 h-4 overflow-hidden shrink-0" onClick={() => {
                    setStaffCustomerFilter('')
                    handleFilterOrderHistory()
                  }}>
                    <img
                      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                      alt=""
                      src="/icondelete-fillter-icon@2x.png"
                    />
                  </div>
                </div>}
                {(typeOrderFilter()?.type || typeOrderFilter()?.custom) && <div
                  className="rounded bg-white-solid-white-100-ffffff shrink-0 flex flex-row items-center justify-start p-1 gap-[5px] text-gray-solid-gray-900-616161">
                  <div className="shrink-0 flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative Tablet_768:text-3xs">{LL0().article.type()}:</div>
                    <div className="relative Tablet_768:text-3xs">
                      {filterOrderHistory()?.takeAway && LL0().order.takeAway()}
                    </div>
                  </div>
                  <div className="relative w-4 h-4 overflow-hidden shrink-0" onClick={() => {
                    setTypeOrderFilter({ type: '', custom: '' })
                    handleFilterOrderHistory()
                  }}>
                    <img
                      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                      alt=""
                      src="/icondelete-fillter-icon@2x.png"
                    />
                  </div>
                </div>}

              </div>
            </div>
          </div>}
          <InfiniteScroll
            data={orders1()}
            increaseSize={() => {
              setIsValidatingOrders(true)
              setOrdersPage((prev) => prev + 1)
            }}
            isReachingEnd={isReachingEnd()}
            isAutoInfinite={true}
            isValidating={isValidatingOrders()}
            loadingIndicator={`${LL0().ui.loading()}...`}
            endingIndicator={LL0().orderHistory.noOrdersLeft()}
            indicatorWrapper={({ children }) => (

              <div className="text-white text-center text-mulish font-semibold p-2 col-span-full">
                {children}
              </div>
            )}>
            {/* <div className={clsx('text-gray-200 odd:bg-white bg-whitesmoke-200 border', isCurrentOrder(order._id) ? 'border-[2px] border-[#FF3797]' : 'border-[#1D1D26]')} onClick={onClick}>*/}
            {(order, index) =>
              (<Fragment key={order?._id}>
                {isCurrentOrder(order._id) && <div
                  className={'border-[2px] border-[#FF3797] col-span-full absolute inset-0 z-[10] m-0'}
                  style={{ gridRow: `${index + (isFiltering ? 3 : 2)}/${index + (isFiltering ? 3 : 2)}` }}
                  onDoubleClick={() => console.log('🪲 order', _.cloneDeep(order))}
                />
                }
                <OrderHistoryTableRow order={order} key={order?._id} _id={order?._id} isEven={index % 2 !== 0} />
              </Fragment>)
            }
          </InfiniteScroll>
        </div>
        <div className="absolute my-0 mx-[!important] bottom-[8px] left-[8px] w-[45px] h-[45px] z-[2] rounded-full"
             onClick={onClickScrollToTop} ref={makeRipple}>
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt="" src="/icongo-to-top-float-icon@2x.png" />
        </div>
      </div>
      {isDateChoosingPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDateChoosingPopup}
        >
          <DateChoosingPopup onClose={closeDateChoosingPopup} />
        </PortalPopup>
      )}
      {isTypeChoosingPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeTypeChoosingPopup}
        >
          <TypeChoosingPopup onClose={closeTypeChoosingPopup} />
        </PortalPopup>
      )}
      {isPaymentMethodPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentMethodPopup}
        >
          <PaymentMethodPopup onClose={closePaymentMethodPopup} />
        </PortalPopup>
      )}
      {isStaffCustomerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeStaffCustomerPopup}
        >
          <StaffCustomerPopup onClose={closeStaffCustomerPopup} />
        </PortalPopup>
      )}
    </>
  )
}

export default HistoryLeftPath
