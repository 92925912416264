import { CashbookTransactions } from "@/data/Cashbook.ts";
import type { Order } from "@/data/Order.ts";
import {
  type CashbookHistoryReport,
  CashbookTransactionsAction,
  CashbookTransactionsType
} from "@/react/CashbookView/CashbookView.tsx";
import uuid from "time-uuid";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { invoiceGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import dayjs, { type Dayjs } from "dayjs";
import { printInvoiceFromRaster } from "@/react/Printer/print-invoice.ts";
import _ from "lodash";
import { isCashPayment } from "@/pos/logic/order-utils.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { loginUser } from "@/data/UserSignal.ts";

export async function createCashSale(order: Order) {
  //todo: add cashbook
  //todo: split tax
  const cashPay = isCashPayment(order);
  if (!cashPay) return;
  const isMultiple =  cashPay?.isMultiple

  if (!isMultiple) {
    //handle single cash payment
    for (const tax of Object.keys(order.vTaxSum!)) {
      await CashbookTransactions.insert({
        _id: uuid(),
        date: order.date!,
        vDate: order.vDate!,
        action: CashbookTransactionsAction.INCOMING,
        transactionType: CashbookTransactionsType.CASHSALE,
        amount: order.vTaxSum![tax].gross,
        user: loginUser()?.name,
        tax: parseFloat(tax),
        orderId: order._id,
      })
    }
  } else {
    //handle multi payment
    const cashPayment = cashPay?.cashPayment?.length === 1 ? cashPay?.cashPayment?.[0]?.value : _.sumBy(Object.values(cashPay?.cashPayment), 'value');
    const cashRevenue = cashPayment - (order?.cashback || 0);
    if (!order?.vTaxSum) return;
    const totalGross = _.sumBy(Object.values(order?.vTaxSum), 'gross');

    for (const tax of Object.keys(order.vTaxSum!)) {
      const taxRatio = (order.vTaxSum![tax].gross / totalGross)
      await CashbookTransactions.insert({
        _id: uuid(),
        date: order.date!,
        vDate: order.vDate!,
        action: CashbookTransactionsAction.INCOMING,
        transactionType: CashbookTransactionsType.CASHSALE,
        amount: taxRatio * cashRevenue,
        user: loginUser()?.name,
        tax: parseFloat(tax),
        orderId: order._id,
      })
    }
  }
}

export async function printCashbook (date: Dayjs, reports: CashbookHistoryReport[]) {
  const LL = LL2()
  const { name, address, telephone, taxNumber, country } = companyInfo0()
  const reportDate = date?.format(LL.dates.dateFormat());

  const printer = new VPrinter({...invoiceGroupPrinters0()[0].printers[0]});
  //print header
  await printer.alignCenter();
  await printer.setTextDoubleHeight();
  await printer.bold(true);
  await printer.println(name!);

  await printer.bold(false);
  await printer.setTextNormal();
  await printer.println(address!);
  if (telephone) await printer.println(`${LL.printing.tel()}: ${telephone}`);
  if (taxNumber) await printer.println(`${LL.printing.vatRegNo()}: ${taxNumber}`);

  await printer.newLine();
  await printer.setTextDoubleHeight();
  await printer.bold(true);
  await printer.println(LL.cashbook.cashbook());

  await printer.newLine();
  await printer.setTextNormal();
  await printer.alignLeft();
  await printer.bold(true);
  await printer.println(`${LL.printing.reportDate()}: ${reportDate}`);
  await printer.newLine();


  for (const report of reports) {
    await printer.leftRight(LL.printing.date(), dayjs.unix(report.vDate).format(LL.dates.dateFormat()))
    await printer.newLine();
    await printer.bold(false);
    await printer.leftRight(LL.cashbook.cashStart(), LL.format.currency(report.startBalance));
    await printer.leftRight(LL.cashbook.cashClosed(), LL.format.currency(report.closeBalance));

    await printer.newLine();
    await printer.leftRight(LL.cashbook.incomingCash(), LL.format.currency(report.incomingBalance));
    await printer.leftRight(LL.cashbook.outgoingCash(), LL.format.currency(report.outgoingBalance));

    await printer.newLine();

    await printer.bold(true);
    await printer.println(LL.cashbook.incoming() + ' : ');
    await printer.bold(false);

    const incomingItems = report.transactions.filter(t => t.action === CashbookTransactionsAction.INCOMING).map(t => {
      const date = dayjs.unix(t.date).format(LL.dates.timeFormat());
      const amount = LL.format.currency(t.amount!);
      return [
        {text: date},
        {text: amount},
        {text: t.tax!.toString()},
        {text: t.transactionType}
      ]
    })

    const outgoingItems = report.transactions.filter(t => t.action === CashbookTransactionsAction.OUTGOING).map(t => {
      const date = dayjs.unix(t.date).format(LL.dates.timeFormat());
      const amount = LL.format.currency(t.amount!);
      return [
        {text: t.transactionType},
        {text: date},
        {text: amount},
        {text: t.tax!.toString()},
      ]
    })

    const headers = ['Type', 'Date', 'Amount', 'Tax'].map(t => ({text: _.get(LL.cashbook, t.toLowerCase())?.()}));

    await printer.advancedTableCustom({
      metaData: {
        colMetaData: [
          { align: 'LEFT'},
          { align: 'RIGHT',priority: 'HIGH', padding: 0.03},
          { align: 'RIGHT',priority: 'HIGH', padding: 0.03},
          { align: 'RIGHT',priority: 'HIGH'}],
        rowMetaData: []
      },
      data: [headers, ...incomingItems as any]
    }, true)

    await printer.newLine();
    await printer.bold(true);
    await printer.println(LL.cashbook.outgoing() + ' : ');
    await printer.bold(false);

    await printer.advancedTableCustom({
      metaData: {
        colMetaData: [
          { align: 'LEFT'},
          { align: 'RIGHT',priority: 'HIGH', padding: 0.03},
          { align: 'RIGHT',priority: 'HIGH', padding: 0.03},
          { align: 'RIGHT',priority: 'HIGH'}],
        rowMetaData: []
      },
      data: [headers, ...outgoingItems as any]
    }, true)


    await printer.newLine();
    await printer.drawLine();
    await printer.newLine();
    await printer.bold(true);
  }

  const raster = await printer.getRaster();
  await printInvoiceFromRaster(raster, { metadata: { date: dayjs().unix(), type: 'cashbook' } });
}