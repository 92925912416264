import {type FunctionComponent, useCallback, useState } from 'react'
import EditMenuAddIngredientP from './Popups/EditMenuAddIngredientP.tsx'
import PortalPopup from './Popups/PortalPopup.tsx'
import {
  ITEM_MODE,
  product0,
  setEditorMode0,
  setInputControllers,
  setShowKeyboardIngredients
} from '@/react/EditMenuView/EditMenuView.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { inventories0, mappedInventories } from '@/data/InventoryHub.ts'
import { Checkbox, FormControlLabel } from '@mui/material'
import _ from 'lodash'
import Input from "@/react/core/Input.tsx";
import {EditInventoryMode, setEditInventoryMode} from "@inventory/Popups/EditItemPopup/InventoryAddEditItemPopu.tsx";
import {initInventoryItem, setInventory0} from "@/react/InventoryView/InventoryView.tsx";
import {deepSignal, useSignal} from "@/react/core/reactive.ts";
import {clone} from "json-fn";
import {makeRipple} from "@/react/core/ripple-utils.ts";

const EditIngredientForm: FunctionComponent = () => {
  const [isEditMenuAddIngredientPOpen, setEditMenuAddIngredientPOpen] =
    useState(false);
  const [isEditMenuAddIngredientP1Open, setEditMenuAddIngredientP1Open] =
    useState(false);

  const openEditMenuAddIngredientP = useCallback(() => {
    setEditInventoryMode(EditInventoryMode.ADD);
    setInventory0(deepSignal(clone(initInventoryItem())));
    setEditMenuAddIngredientPOpen(true);
  }, []);

  const closeEditMenuAddIngredientP = useCallback(() => {
    setEditMenuAddIngredientPOpen(false);
  }, []);

  const openEditMenuAddIngredientP1 = useCallback(() => {
    setEditMenuAddIngredientP1Open(true);
  }, []);

  const closeEditMenuAddIngredientP1 = useCallback(() => {
    setEditMenuAddIngredientP1Open(false);
  }, []);
  const [filterName, setFilterName] = useSignal<string>('')

  return (
    <>
      <div className="!flex self-stretch bg-white-solid-white-100-ffffff w-[310px] overflow-hidden shrink-0 hidden flex-col items-start justify-start z-[0] text-left text-sm text-black-solid-black-1000-000000 font-mulish sm:w-[260px] Tablet_600:w-60">
        <div className="self-stretch bg-gray-solid-gray-170-e3e4e5 h-[50px] flex flex-row items-center justify-between py-0 px-2.5 box-border z-[1] sm:h-[42px] sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-1 Tablet_600:pr-1 Tablet_600:box-border">
          <div className="flex flex-row items-center justify-start gap-[8px] sm:gap-[4px] Tablet_600:gap-[2px]">
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-2 box-border cursor-pointer Tablet_600:h-8"
              onClick={openEditMenuAddIngredientP}
              ref={makeRipple}
            >
              <b className="relative sm:text-xs Tablet_768:text-smi Tablet_600:text-2xs">
                {LL0().product.newIngredient()}
              </b>
            </div>
            <div
              className="opacity-50 rounded-11xl bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-center py-0 px-2 box-border cursor-pointer Tablet_600:h-8"
              // onClick={openEditMenuAddIngredientP1}
            >
              <b className="relative sm:text-xs Tablet_768:text-smi Tablet_600:text-2xs">
                {LL0().editMenu.checkList()}
              </b>
            </div>
          </div>
          <div className="rounded-11xl bg-blue-500 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-center py-0 px-2 box-border text-white-solid-white-100-ffffff Tablet_600:h-8"
               onClick={() => setEditorMode0(ITEM_MODE.ITEM)}
               ref={makeRipple}
          >
            <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().ui.close()}</b>
          </div>
        </div>
        <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-4 px-3 gap-[8px] z-[0] text-black-solid-black-600-424242">
          {/*<div className="self-stretch rounded-sm bg-white-solid-white-100-ffffff box-border h-[37px] shrink-0 flex flex-row items-center justify-start py-0 px-2.5 gap-[4px] text-gray-solid-gray-600-979797 border-[1px] border-solid border-gainsboro">*/}
          {/*  <img*/}
          {/*    className="relative w-6 h-6 object-cover"*/}
          {/*    alt=""*/}
          {/*    src="/iconsearch-icon--gray@2x.png"*/}
          {/*  />*/}
          {/*  <div className="relative md:text-xs">{LL0().editMenu.searchIngredient()}</div>*/}
          {/*</div>*/}
          <div className='w-full' onClick={()=> setShowKeyboardIngredients(true)}>
            <Input
                       className={'w-full'}
                       label={LL0().editMenu.searchIngredient()}
                       value={filterName()}
                       onChange={(value)=> setFilterName(value)}
            />
          </div>
          { mappedInventories().filter(inventory=>  inventory?.product?.name?.includes(filterName())).map(inventory => {
            const foundIngredient = product0().ingredients?.find(ingredient => ingredient.inventoryId === inventory._id)

            return (
              <div
                className="self-stretch rounded-sm bg-gray-solid-gray-180-ebecee flex flex-row items-start justify-start border-[1px] border-solid border-gainsboro"
                key={inventory._id}
              >
                <div className="flex-1 h-[37px] flex flex-row items-center justify-between py-0 pr-1 pl-2 box-border">
                  <FormControlLabel
                    className="flex-1"
                    label={inventory?.product?.name}
                    control={<Checkbox color="primary" checked={!!foundIngredient}
                                       onChange={e => {
                                         if (!foundIngredient) {
                                           _.assign(product0(), {ingredients: [...(product0().ingredients || []), {
                                                inventoryId: inventory._id,
                                                amount: '0'
                                             }]})
                                           return;
                                         }
                                         const foundIngredientIndex = product0().ingredients?.findIndex(ingredient => ingredient.inventoryId === inventory._id);
                                         if(typeof foundIngredientIndex !== 'undefined') product0()?.ingredients?.splice(foundIngredientIndex, 1);
                                       }}
                    />}
                  />
                  <div className="relative md:text-xs">({inventory.unit})</div>
                </div>
                <div
                  className="flex-1 box-border h-[37px] flex flex-row items-center justify-start py-0 px-0 max-w-[60px] border-l-[1px] border-solid border-gainsboro"
                  onClick={()=> !!foundIngredient && setShowKeyboardIngredients(true)}
                >
                  {/*<div className="flex-1 relative md:text-xs">{foundIngredient?.amount || ""}</div>*/}
                  <Input className='bg-transparent border-none !pl-1 flex-1 max-w-full'
                         disabled={!foundIngredient} value={foundIngredient?.amount?.toString() || '0'}
                         onChange={(value)=> _.assign(foundIngredient, {amount: value})}
                         refInputController={i => setInputControllers(() => [i])}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {isEditMenuAddIngredientPOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuAddIngredientP}
        >
          <EditMenuAddIngredientP onClose={closeEditMenuAddIngredientP} />
        </PortalPopup>
      )}
      {isEditMenuAddIngredientP1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuAddIngredientP1}
        >
          <EditMenuAddIngredientP onClose={closeEditMenuAddIngredientP1} />
        </PortalPopup>
      )}
    </>
  );
};

export default EditIngredientForm;
