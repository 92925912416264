import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { ToastContainer } from 'react-toastify'

import 'intl-pluralrules'
import 'react-toastify/dist/ReactToastify.css'

import ManuelTablePlugin from '@dashboard/ManuelTablePlugin.tsx'
import OrderHistoryPlugin from '@order-history/OrderHistoryPlugin.tsx'
import MoveItemTablePlugin from '@order-view/MoveItemTable/MoveItemTablePlugin.tsx'
import PendingOrdersPlugin from '@pending-order/PendingOrdersPlugin.tsx'
import VirtualPrinterPlugin from '@virtual-printer/VirtualPrinterPlugin.tsx'

import { frontendLanguage } from '@/data/language.ts'
import { login, logOut } from '@/data/UserHub.ts'
import TypesafeI18n from '@/i18n/i18n-react'
import { PosScreen, router } from '@/pos/PosRouter'
import CashbookView from '@/react/CashbookView/CashbookView.tsx'
import CompanyInformationView from '@/react/CompanyInformationView/CompanyInformationView.tsx'
import I18nBackendWrapper from '@/react/core/I18nBackendWrapper.tsx'
import I18nCurrencyWrapper from '@/react/core/I18nCurrencyWrapper.tsx'
import I18nService from '@/react/core/I18nService'
import LazyRender from '@/react/core/LazyRender.tsx'
import { onMount } from '@/react/core/reactive'
import { styleVisible } from '@/react/core/tw'
import CustomerInfoView from '@/react/CustomerInfoView/CustomerInfoView.tsx'
import DateTimePickerPopup from '@/react/Developer/DateTimePickerPopup.tsx'
import Developer from '@/react/Developer/Developer.tsx'
import Installation from '@/react/Developer/Installation.tsx'
import EditMenuView from '@/react/EditMenuView/EditMenuView.tsx'
import EditOnlineMenuView from '@/react/EditOnlineMenuView/EditOnlineMenuView.tsx'
import EditRoomView from '@/react/EditRoomView/EditRoomView.tsx'
import EmailReportView from '@/react/EmailReportView/EmailReportView.tsx'
import EndOfDayView from '@/react/EndOfDayView/EndOfDayView.tsx'
import FloorPlanView from '@/react/FloorPlanView/FloorPlanView'
import GeneralSettingsView from '@/react/GeneralSettingsView/GeneralSettingsView.tsx'
import HappyHourView from '@/react/HappyHourView/HappyHourView.tsx'
import Init from '@/react/Init'
import InventoryView from '@/react/InventoryView/InventoryView.tsx'
import KitchenMonitorView from '@/react/KitchenMonitorView/KitchenMonitorView.tsx'
import MasterMachineView from '@/react/MasterMachineView/MasterMachineView.tsx'
import MonthlyReportView from '@/react/MonthlyReportView/MonthlyReportView.tsx'
import NewDeliveryView, { supportMockCall } from '@/react/NewDeliveryView/NewDeliveryView.tsx'
import OnlineOrderSettingView from '@/react/OnlineOrderSettingView/OnlineOrderSettingView.tsx'
import OrderView from '@/react/OrderView/OrderView.tsx'
import PasscodeView from '@/react/PasscodeView/PasscodeView.tsx'
import PaymentSettingView from '@/react/PaymentSettingView/PaymentSettingView.tsx'
import PaymentView from '@/react/PaymentView/PaymentView.tsx'
import PrinterSettingView from '@/react/Printer/PrinterSettingView.tsx'
import ReservationView from '@/react/ReservationView2/ReservationView.tsx'
import ShippingStatusView from '@/react/ShippingStatusView/ShippingStatusView.tsx'
import SignInView from '@/react/SignInView/SignInView.tsx'
import SrmView from '@/react/SrmView/SrmView.tsx'
import StaffReportView from '@/react/StaffReportView/StaffReportView.tsx'
import SyncMenuView from '@/react/SyncMenuView/SyncMenuView.tsx'
import DialogService from '@/react/SystemService/dialogService.tsx'
import MessageBoxService from '@/react/SystemService/msgBox.tsx'
import TerminalView from '@/react/TerminalView/TerminalView.tsx'
import TimeManagement from '@/react/TimeManagementView/TimeManagementView.tsx'
import TutorialView from '@/react/TutorialView/TutorialView.tsx'
import UserSettingView from '@/react/UserSettingView/UserSettingView.tsx'
import VoucherView from '@/react/VoucherView/VoucherView.tsx'
import WebMenuView from '@/react/WebMenuView/WebMenuView.tsx'
import { rnHost } from '@/shared/webview/rnwebview'

import MockTSEPopup from './Developer/MockTSEPopup'
import { wsRegister } from './websocket/ws-register'

import '@/srm/onWakeup'
import '@/data/OnlineProviderRestablo.ts'
import '../pos/logic/rrweb-utils.ts'
import { generalSetting0, isQuebecSrmEnabled, mainScreen } from "@/data/PosSettingsSignal.ts";

Error.stackTraceLimit = Infinity

// import { tseServerSignup } from "@/tse/tse-server-signup.ts";

export default { title: 'pixi' }
localStorage.debug = 'render:*,Tse,data:*,ws,ws-java,view:*,dev:*,printer:*,sync:*'

//for dayjs formatting & weekdays
dayjs.extend(customParseFormat)
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(timezone)

//@ts-ignore
window._ = _
//@ts-ignore
window.dayjs = dayjs

export const FullApp3 = () => {
  onMount(async () => {
    if (import.meta.env.MODE === 'production') {
      router.screen = PosScreen.PASSCODE as PosScreen

      // login onMount
      if (dayjs().unix() > Number(localStorage.getItem('lastPingUnix')) + 10) {
        await logOut()
      } else {
        await login(localStorage.getItem('loggedPasscode') || '')
      }

      // Load certss so we can communitate with the WEB-SRS
      if (isQuebecSrmEnabled()) await rnHost.loadCert()
    } else {
      router.screen = mainScreen() as PosScreen
      // for supporting create mock calls
      supportMockCall()
    }

    console.log(
      `🤖 You are running %c${import.meta.env.VITE_APP_VERSION}%c version in %c${import.meta.env.MODE}%c mode`,
      'color:fuchsia;font-weight:bold',
      'color:initial;font-weight:initial',
      'color:red;font-weight:bold',
      'color:initial;font-weight:initial'
    )
    wsRegister()
  })
  //todo: lazy render
  // log out user handlers

  let idleTime = useRef<number>(0)
  const idleIntervalRef = useRef<NodeJS.Timeout>()

  const timerIncrement = useCallback(async () => {
    idleTime.current = idleTime.current + 10 * 1000
    if (idleTime.current > (generalSetting0()?.autoLogOutPeriod || 20 * 1000) && generalSetting0()?.autoLogOutAfterInactivity) {
      // 20 seconds
      await logOut()
    }
    // set localStorage ping event
    localStorage.setItem('lastPingUnix', dayjs().unix().toString())
  }, [generalSetting0()?.autoLogOutPeriod, generalSetting0()?.autoLogOutAfterInactivity])

  useEffect(() => {
    idleIntervalRef.current = setInterval(timerIncrement, 10 * 1000) // 5 seconds
    return () => clearInterval(idleIntervalRef.current)
  }, [])

  return (
    <>
      <ToastContainer />
      <div
        className={'w-full h-full relative select-none'}
        onMouseMove={() => {
          idleTime.current = 0
        }}
        onKeyDown={() => (idleTime.current = 0)}
      >
        <Init>
          <I18nCurrencyWrapper />
          <I18nBackendWrapper />
          <TypesafeI18n locale={frontendLanguage() as any} key={frontendLanguage() as any}>
            <I18nService>
              <div
                {...styleVisible(router.screen === PosScreen.FLOOR_PLAN)}
                className={'absolute inset-0'}
                data-testid={PosScreen.FLOOR_PLAN}
              >
                <FloorPlanView />
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.ORDER)}
                className={'absolute inset-0'}
                data-testid={PosScreen.ORDER}
              >
                {/*<LazyRender show={router.screen === PosScreen.ORDER}>*/}
                <OrderView />
                {/*</LazyRender>*/}
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.DEVELOPER)}
                className={'absolute inset-0'}
                data-testid={PosScreen.DEVELOPER}
              >
                <LazyRender show={router.screen === PosScreen.DEVELOPER}>
                  <Developer />
                  {/*<DeveloperOnlyView />*/}
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.INSTALLATION)}
                className={'absolute inset-0'}
                data-testid={PosScreen.INSTALLATION}
              >
                <LazyRender show={router.screen === PosScreen.INSTALLATION}>
                  <Installation />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.EDIT_MENU)}
                className={'absolute inset-0'}
                data-testid={PosScreen.EDIT_MENU}
              >
                <LazyRender show={router.screen === PosScreen.EDIT_MENU}>
                  <EditMenuView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.EDIT_ONLINE_MENU)}
                className={'absolute inset-0'}
                data-testid={PosScreen.EDIT_ONLINE_MENU}
              >
                <LazyRender show={router.screen === PosScreen.EDIT_ONLINE_MENU}>
                  <EditOnlineMenuView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.PASSCODE)}
                className={'absolute inset-0'}
                data-testid={PosScreen.PASSCODE}
              >
                <LazyRender show={router.screen === PosScreen.PASSCODE}>
                  <PasscodeView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.VIRTUAL_PRINTER)}
                className={'absolute inset-0'}
                data-testid={PosScreen.VIRTUAL_PRINTER}
              >
                <LazyRender show={router.screen === PosScreen.VIRTUAL_PRINTER}>
                  <VirtualPrinterPlugin />
                </LazyRender>
              </div>
              {/*<div {...styleVisible(router.screen === PosScreen.PAIRING)} className={"absolute inset-0"} data-testid={PosScreen.PAIRING}>*/}
              {/*	<LazyRender show={router.screen === PosScreen.PAIRING}>*/}
              {/*		<Pairing/>*/}
              {/*	</LazyRender>*/}
              {/*</div>*/}
              <div
                {...styleVisible(router.screen === PosScreen.PENDING_ORDERS)}
                className={'absolute inset-0'}
                data-testid={PosScreen.PENDING_ORDERS}
              >
                <LazyRender show={router.screen === PosScreen.PENDING_ORDERS}>
                  <PendingOrdersPlugin />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.PAYMENT)}
                className={'absolute inset-0'}
                data-testid={PosScreen.PAYMENT}
              >
                <LazyRender show={router.screen === PosScreen.PAYMENT}>
                  <PaymentView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.EDIT_FLOOR_PLAN)}
                className={'absolute inset-0'}
                data-testid={PosScreen.EDIT_FLOOR_PLAN}
              >
                <LazyRender show={router.screen === PosScreen.EDIT_FLOOR_PLAN}>
                  <EditRoomView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.EOD)}
                className={'absolute inset-0'}
                data-testid={PosScreen.EOD}
              >
                <LazyRender show={router.screen === PosScreen.EOD}>
                  <EndOfDayView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.ORDER_HISTORY)}
                className={'absolute inset-0'}
                data-testid={PosScreen.ORDER_HISTORY}
              >
                <LazyRender show={router.screen === PosScreen.ORDER_HISTORY}>
                  <OrderHistoryPlugin />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.EDIT_USER)}
                className={'absolute inset-0'}
                data-testid={PosScreen.EDIT_USER}
              >
                <LazyRender show={router.screen === PosScreen.EDIT_USER}>
                  <UserSettingView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.GENERAL_SETTING)}
                className={'absolute inset-0'}
                data-testid={PosScreen.GENERAL_SETTING}
              >
                <LazyRender show={router.screen === PosScreen.GENERAL_SETTING}>
                  <GeneralSettingsView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.HAPPY_HOUR)}
                className={'absolute inset-0'}
                data-testid={PosScreen.HAPPY_HOUR}
              >
                <LazyRender show={router.screen === PosScreen.HAPPY_HOUR}>
                  <HappyHourView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.STAFF_REPORT)}
                className={'absolute inset-0'}
                data-testid={PosScreen.STAFF_REPORT}
              >
                <LazyRender show={router.screen === PosScreen.STAFF_REPORT}>
                  <StaffReportView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.MONTHLY_REPORT)}
                className={'absolute inset-0'}
                data-testid={PosScreen.MONTHLY_REPORT}
              >
                <LazyRender show={router.screen === PosScreen.MONTHLY_REPORT}>
                  <MonthlyReportView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.TIME_MANAGEMENT)}
                className={'absolute inset-0'}
                data-testid={PosScreen.TIME_MANAGEMENT}
              >
                <LazyRender show={router.screen === PosScreen.TIME_MANAGEMENT}>
                  <TimeManagement />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.MASTER_MACHINE)}
                className={'absolute inset-0'}
                data-testid={PosScreen.MASTER_MACHINE}
              >
                <LazyRender show={router.screen === PosScreen.MASTER_MACHINE}>
                  <MasterMachineView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.RESERVATION)}
                className={'absolute inset-0'}
                data-testid={PosScreen.RESERVATION}
              >
                <LazyRender show={router.screen === PosScreen.RESERVATION}>
                  <ReservationView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.SHIPPING_STATUS)}
                className={'absolute inset-0'}
                data-testid={PosScreen.SHIPPING_STATUS}
              >
                <LazyRender show={router.screen === PosScreen.SHIPPING_STATUS}>
                  <ShippingStatusView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.INVENTORY)}
                className={'absolute inset-0'}
                data-testid={PosScreen.INVENTORY}
              >
                <LazyRender show={router.screen === PosScreen.INVENTORY}>
                  <InventoryView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.SYNC_MENU)}
                className={'absolute inset-0'}
                data-testid={PosScreen.SYNC_MENU}
              >
                <LazyRender show={router.screen === PosScreen.SYNC_MENU}>
                  <SyncMenuView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.CUSTOMER_INFO)}
                className={'absolute inset-0'}
                data-testid={PosScreen.CUSTOMER_INFO}
              >
                <LazyRender show={router.screen === PosScreen.CUSTOMER_INFO}>
                  <CustomerInfoView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.EMAIL_REPORT)}
                className={'absolute inset-0'}
                data-testid={PosScreen.EMAIL_REPORT}
              >
                <LazyRender show={router.screen === PosScreen.EMAIL_REPORT}>
                  <EmailReportView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.COMPANY_INFORMATION)}
                className={'absolute inset-0'}
                data-testid={PosScreen.COMPANY_INFORMATION}
              >
                <LazyRender show={router.screen === PosScreen.COMPANY_INFORMATION}>
                  <CompanyInformationView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.WEB_MENU)}
                className={'absolute inset-0'}
                data-testid={PosScreen.WEB_MENU}
              >
                <LazyRender show={router.screen === PosScreen.WEB_MENU}>
                  <WebMenuView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.CASHBOOK)}
                className={'absolute inset-0'}
                data-testid={PosScreen.CASHBOOK}
              >
                <LazyRender show={router.screen === PosScreen.CASHBOOK}>
                  <CashbookView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.KITCHEN_MONITOR)}
                className={'absolute inset-0'}
                data-testid={PosScreen.KITCHEN_MONITOR}
              >
                <LazyRender show={router.screen === PosScreen.KITCHEN_MONITOR}>
                  <KitchenMonitorView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.TUTORIAL)}
                className={'absolute inset-0'}
                data-testid={PosScreen.TUTORIAL}
              >
                <LazyRender show={router.screen === PosScreen.TUTORIAL}>
                  <TutorialView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.MOVE_ITEM_TABLE)}
                className={'absolute inset-0'}
                data-testid={PosScreen.MOVE_ITEM_TABLE}
              >
                <LazyRender show={router.screen === PosScreen.MOVE_ITEM_TABLE}>
                  <MoveItemTablePlugin />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.MANUAL_TABLE)}
                className={'absolute inset-0'}
                data-testid={PosScreen.MANUAL_TABLE}
              >
                <LazyRender show={router.screen === PosScreen.MANUAL_TABLE}>
                  <ManuelTablePlugin />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.SIGNIN)}
                className={'absolute inset-0'}
                data-testid={PosScreen.SIGNIN}
              >
                <LazyRender show={router.screen === PosScreen.SIGNIN}>
                  <SignInView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.NEW_DELIVERY)}
                className={'absolute inset-0'}
                data-testid={PosScreen.NEW_DELIVERY}
              >
                <LazyRender show={router.screen === PosScreen.NEW_DELIVERY}>
                  <NewDeliveryView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.TERMINAL)}
                className={'absolute inset-0'}
                data-testid={PosScreen.TERMINAL}
              >
                <LazyRender show={router.screen === PosScreen.TERMINAL}>
                  <TerminalView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.SRM)}
                className={'absolute inset-0'}
                data-testid={PosScreen.SRM}
              >
                <LazyRender show={router.screen === PosScreen.SRM}>
                  <SrmView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.PRINTER_SETTING)}
                className={'absolute inset-0'}
                data-testid={PosScreen.PRINTER_SETTING}
              >
                <LazyRender show={router.screen === PosScreen.PRINTER_SETTING}>
                  <PrinterSettingView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.PAYMENT_SETTING)}
                className={'absolute inset-0'}
                data-testid={PosScreen.PAYMENT_SETTING}
              >
                <LazyRender show={router.screen === PosScreen.PAYMENT_SETTING}>
                  <PaymentSettingView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.ONLINE_ORDER_SETTING)}
                className={'absolute inset-0'}
                data-testid={PosScreen.ONLINE_ORDER_SETTING}
              >
                <LazyRender show={router.screen === PosScreen.ONLINE_ORDER_SETTING}>
                  <OnlineOrderSettingView />
                </LazyRender>
              </div>
              <div
                {...styleVisible(router.screen === PosScreen.VOUCHER)}
                className={'absolute inset-0'}
              >
                <LazyRender show={router.screen === PosScreen.VOUCHER}>
                  <VoucherView />
                </LazyRender>
              </div>
              {DialogService.render()}
              {MessageBoxService.render()}
            </I18nService>
          </TypesafeI18n>
        </Init>
      </div>
      <DateTimePickerPopup />
      <MockTSEPopup />
    </>
  )
}
