import { type CSSProperties, type FunctionComponent, useMemo } from 'react'
import { useSignal, useSignalEffect } from '@/react/core/reactive.ts'
import {
  blockMutateOrderButton, hasPaidSeatOrder,
  onBack,
  order0,
  paymentContext0,
  scopeOrder0
} from '@/react/PaymentView/PaymentView.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import PaymentScreenMoreOption from '@payment/PaymentScreenMoreOption.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { handlePrintInvoice, printInvoice } from '@/react/Printer/print-invoice.ts'
import { clsx } from "clsx";
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";

type PaymentRightPathTopNavType = {
  showReViewBill?: boolean;
  showCancel?: boolean;
  showAllDone?: boolean;
  showSplitViewBill?: boolean;
  showButtonIcon?: boolean;
  showButtonText?: boolean;

  /** Style props */
  paymentRightPathTopNavWidth?: CSSProperties["width"];
};

enum Type {
  Default = 'Default',
  Cancel = 'Cancel',
  Redivision = 'Redivision',
  AllDone = 'AllDone',
}

const PaymentRightPathTopNav: FunctionComponent<PaymentRightPathTopNavType> = (
  {
    showReViewBill = false,
    showCancel = false,
    showAllDone = false,
    showSplitViewBill = false,
    paymentRightPathTopNavWidth,
    showButtonIcon,
    showButtonText,
  }) => {
  const paymentRightPathTopNavStyle: CSSProperties = useMemo(() => {
    return {
      width: paymentRightPathTopNavWidth,
    };
  }, [paymentRightPathTopNavWidth]);

  const [type, setType] = useSignal<Type>(Type.Default);
  useSignalEffect(() => {
    if (!paymentContext0.splitEnable() && type() === Type.Cancel) {
      setType(Type.Redivision);
    }
  })

  // if (type() === Type.Default) showSplitViewBill = true;
  // if (type() === Type.Cancel) showCancel = true;
  if (type() === Type.Redivision) showReViewBill = true;
  if (type() === Type.AllDone) showAllDone = true;

  showSplitViewBill = !paymentContext0.splitEnable();
  showCancel = paymentContext0.splitEnable();

  return (
    <div
      className="z-[2] flex flex-col items-center justify-start gap-[16px] text-left text-sm text-text-color-black-1e1e23 font-mulish"
      style={paymentRightPathTopNavStyle}
    >
      {showSplitViewBill && (
        <div className="self-stretch flex flex-row items-center justify-between py-0 px-2 z-[1] sm:pl-1 sm:pr-1 sm:box-border">
          <PaymentScreenMoreOption />
          <div className="self-stretch flex flex-row items-center justify-end gap-[8px] text-center text-white-solid-white-100-ffffff sm:gap-[8px] Tablet_768:gap-[4px]">
            <div className="self-stretch bg-white-opacity-white-opacity-0-ffffff w-0.5 overflow-hidden shrink-0" />
            <ButtonWrapper onClick={()=> handlePrintInvoice(scopeOrder0())} isDisabled={blockMutateOrderButton()}>
              <div
                className={clsx(
                  "rounded-81xl px-3 bg-orange-solid-orange-800-f25936 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 box-border gap-[3px]",
                )}
                // ref={makeRipple}
                // onClick={async () => {
                //   await handlePrintInvoice(scopeOrder0());
                // }}
              >
                <img
                  className="relative w-[30px] h-[30px] object-cover sm:hidden Tablet_768:w-[26px] Tablet_768:h-[26px]"
                  alt=""
                  src="/payment-iconprinter-icon-3@2x.png"
                />
                <b className="relative leading-[17px] sm:text-xs Tablet_768:text-xs Tablet_600:text-xs height_414:text-xs">
                  {LL0().fnBtn.paymentFunctions.bill()}
                </b>
              </div>
            </ButtonWrapper>
            <div
              className={clsx(
                "self-stretch h-[34px] rounded-81xl bg-palette-blue-style shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px]",
                {
                  'opacity-70': blockMutateOrderButton() || hasPaidSeatOrder()
                }
              )}
              onClick={onBack}
            >
              <img
                className="relative w-[30px] h-[30px] object-cover"
                alt=""
                src="/payment-iconback-icon@2x.png"
              />
              <b className="relative leading-[17px] hidden">{LL0().ui.back()}</b>
            </div>
          </div>
        </div>
      )}
      {showCancel && (
        <div
          className="rounded-81xl [background:linear-gradient(180deg,_#ffda68,_#e3ac00)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] z-[0] text-center text-white-solid-white-100-ffffff"
          onClick={() => paymentContext0.onSplitCancel()}
        >
          <img
            className="relative w-[30px] h-[30px] object-cover hidden"
            alt=""
            src="/payment-ordervoucher-icon1@2x.png"
          />
          <b className="relative leading-[17px] sm:text-xs Tablet_768:text-xs Tablet_600:text-xs height_414:text-xs">{LL0().ui.cancel().toUpperCase()}</b>
        </div>
      )}
    </div>
  );
};

export default PaymentRightPathTopNav;
