import { type FunctionComponent, useState, useCallback } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateButtonIdentical from "./DateButtonIdentical";
import {
  dateFilter,
  DATE_FILTERS,
  filterToday,
  filterYesterday,
  filterThisWeek,
  filterThisMonth,
  filterCustomDateRange,
  setStartDate,
  setEndDate
} from "@/data/ReservationHub.ts"
import { useComputed, useSignal } from "@/react/core/reactive";
import dayjs from 'dayjs';
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { LL2 } from "@/react/core/I18nBackend.tsx";

export type DateChoosingPopupType = {
  className?: string;
  onClose?: () => void;
};

const DateChoosingPopup: FunctionComponent<DateChoosingPopupType> = ({
  className = "",
  onClose,
}) => {
  const [inputDateTimePicker1Value, setInputDateTimePicker1Value] = useState(null);
  const [inputDateTimePicker2Value, setInputDateTimePicker2Value] = useState(null);

  const [mode, setMode] = useSignal(dateFilter());
  const isTodaySelected = useComputed<boolean>(() => mode() === DATE_FILTERS.today)
  const isYesterdaySelected = useComputed<boolean>(() => mode() === DATE_FILTERS.yesterday)
  const isThisWeekSelected = useComputed<boolean>(() => mode() === DATE_FILTERS.thisWeek)
  const isThisMonthSelected = useComputed<boolean>(() => mode() === DATE_FILTERS.thisMonth)
  const isCustomDateSelected = useComputed<boolean>(() => mode() === DATE_FILTERS.custom)

  function applyChange() {
    switch (mode()) {
      case DATE_FILTERS.today:
        filterToday()
        break;
      case DATE_FILTERS.yesterday:
        filterYesterday()
        break;
      case DATE_FILTERS.thisWeek:
        filterThisWeek()
        break;
      case DATE_FILTERS.thisMonth:
        filterThisMonth()
        break;
      case DATE_FILTERS.custom:
        filterCustomDateRange(inputDateTimePicker1Value, inputDateTimePicker2Value);
        break;
    }
    onClose?.()
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`w-[664px] h-full relative rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-11 px-6 pb-6 box-border gap-[24px] max-w-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish ${className}`}
      >
        <div className="self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[24px] z-[0]">
          <div
            className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[12px] border-b-[1px] border-solid border-gray-solid-gray-350-ced1d7">
            <b className="relative">{LL0().orderHistory.quickSelection()}:</b>
            <div
              className="self-stretch grid flex-row flex-wrap items-start justify-start gap-[8px] grid-cols-[repeat(auto-fill,_minmax(116px,_1fr))]">
              <DateButtonIdentical date={(LL0().orderHistory.dateFilterDialog.today())}
                                   isSelected={isTodaySelected()} onClick={() => setMode(DATE_FILTERS.today)}/>
              <DateButtonIdentical date={(LL0().orderHistory.dateFilterDialog.yesterday())}
                                   isSelected={isYesterdaySelected()}
                                   onClick={() => setMode(DATE_FILTERS.yesterday)}/>
              <DateButtonIdentical date={(LL0().orderHistory.dateFilterDialog.thisWeek())}
                                   isSelected={isThisWeekSelected()}
                                   onClick={() => setMode(DATE_FILTERS.thisWeek)}/>
              <DateButtonIdentical date={(LL0().orderHistory.dateFilterDialog.thisMonth())}
                                   isSelected={isThisMonthSelected()}
                                   onClick={() => setMode(DATE_FILTERS.thisMonth)}/>
              <DateButtonIdentical date={(LL0().orderHistory.dateFilterDialog.custom())}
                                   isSelected={isCustomDateSelected()}
                                   onClick={() => setMode(DATE_FILTERS.custom)}/>
            </div>
          </div>
          {isCustomDateSelected() ? <div
            className="self-stretch flex flex-col items-start justify-start gap-[12px] text-gray-solid-gray-800-777777">
            <b className="relative text-blue-solid-blue-500-0051c1">
              {LL0().reservation.customYourDate()}:
            </b>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
              <div className="w-[83px] relative flex items-center shrink-0">
                {(LL0().orderHistory.dateFilterDialog.startDate())}:
              </div>
              <div className="w-[250px] max-w-[250px]">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  value={inputDateTimePicker1Value}
                  onChange={(newValue: any) => {
                    console.log('setInputDateTimePicker1Value', newValue)
                    setInputDateTimePicker1Value(newValue);
                  }}
                  sx={{}}
                  slotProps={{
                    textField: {
                      name: "",
                      id: "",
                      size: "small",
                      fullWidth: true,
                      required: false,
                      autoFocus: false,
                      error: false,
                      color: "primary",
                    },

                  }}
                />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
              <div className="w-[83px] relative flex items-center shrink-0">
                {(LL0().orderHistory.dateFilterDialog.endDate())}:
              </div>
              <div className="w-[250px] max-w-[250px]">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  value={inputDateTimePicker2Value}
                  onChange={(newValue: any) => {
                    console.log('setInputDateTimePicker2Value', newValue)
                    setInputDateTimePicker2Value(newValue);
                  }}
                  sx={{}}
                  slotProps={{
                    textField: {
                      name: "",
                      id: "",
                      size: "small",
                      fullWidth: true,
                      required: false,
                      autoFocus: false,
                      error: false,
                      color: "primary",
                    },

                  }}
                />
              </div>
            </div>
          </div> : null}
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-end gap-[8px] z-[1] text-center text-sm text-blue-solid-blue-420-2979ff">
          <div
            className="cursor-pointer rounded-md box-border h-[37px] flex flex-row items-center justify-center py-1 px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={onClose}
          >
            <b className="relative">{LL0().ui.cancel()}</b>
          </div>
          <div
            className="cursor-pointer rounded-md bg-blue-solid-blue-420-2979ff h-9 flex flex-row items-center justify-center py-1 px-8 box-border text-white-solid-white-100-ffffff"
            onClick={applyChange}>
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
        <img
          className="w-6 absolute !m-[0] top-[20px] right-[20px] h-6 object-cover cursor-pointer z-[2]"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
          onClick={onClose}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DateChoosingPopup;
