import { FunctionComponent, useState, useCallback } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import PortalPopup from "@tax-payment/PortalPopup";
import { DocDeepSignal } from '@/data/data-utils.ts';
import { Payment } from '@/data/Payment.ts';
import {
  deletePaymentMethod,
  setPayment0
} from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import { setPaymentV } from '@/data/PaymentHub.ts';
import _ from 'lodash';
import TaxAndPaymentDeleteConfi from '@tax-payment/Popups/TaxAndPaymentDeleteConfi.tsx';
import NewEditPaymentMethodPopu from '@tax-payment/Popups/NewEditPaymentMethodPopup/NewEditPaymentMethodPopu.tsx';
import { LL0 } from '@/react/core/I18nService.tsx'

export type TaxPaymentPaymentMethodRowType = {
  paymentMethodName?: string;
  transactionIconUrl?: string;
  showLinkIcon?: boolean;
  showToggleSwitch?: boolean;
  paymentData: DocDeepSignal<Payment>
};

const TaxPaymentPaymentMethodRow: FunctionComponent<
  TaxPaymentPaymentMethodRowType
> = ({
  paymentMethodName = "Cash",
  transactionIconUrl,
  showLinkIcon = true,
  showToggleSwitch,
  paymentData
}) => {
  paymentMethodName = _.get(LL0().payment, paymentData?.name?.toLowerCase())?.() || paymentData?.name || "";
  transactionIconUrl = paymentData?.icon;
  showLinkIcon = false;
  // showToggleSwitch stands for auto apply terminal (just when terminals are applied)
  showToggleSwitch = paymentData?.terminalIds && paymentData?.terminalIds?.length !== 0

  const [isTaxAndPaymentDeleteConfiOpen, setTaxAndPaymentDeleteConfiOpen] =
    useState(false);


  const [isNewEditPaymentMethodPopuOpen, setNewEditPaymentMethodPopuOpen] =
    useState(false);

  const openTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(true);
  }, []);

  const closeTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(false);
  }, []);

  const openNewEditPaymentMethodPopu = useCallback(() => {
    setPayment0(paymentData);
    setNewEditPaymentMethodPopuOpen(true);
  }, [paymentData]);

  const closeNewEditPaymentMethodPopu = useCallback(() => {
    setPaymentV(v => v + 1);
    setNewEditPaymentMethodPopuOpen(false);
  }, []);

  return (
    <>
      <div
        className='even:bg-white min-h-[40px] self-stretch bg-gray-table-row flex flex-row items-center justify-start p-2 gap-2 text-left text-sm text-grey-grey-darken-3 font-mulish'
      >
        <img
          className="w-5 relative h-5 object-cover Tablet_600:w-[18px] Tablet_600:h-[18px]"
          alt=""
          src="/icondrag-and-drop-icon@2x.png"
        />
        <div className="flex-1 flex flex-row items-center justify-start py-0 px-1 box-border gap-1 min-w-[112px]">
          {transactionIconUrl && <img
            className="h-8 w-8 relative object-contain sm:w-8 sm:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"
            alt=""
            src={transactionIconUrl}
          />}
          <div className="relative font-semibold Tablet_768:text-smi">{paymentMethodName}</div>
          {showLinkIcon && (
            <img
              className="relative w-5 h-5 object-cover Tablet_600:w-[18px] Tablet_600:h-[18px]"
              alt=""
              src="/iconlink-icon1@2x.png"
            />
          )}
        </div>
        {showToggleSwitch && (
          <div className="flex-row items-center justify-center py-0 px-0.5">
            <FormControlLabel
              control={<Switch color="primary" size="small"
                               checked={paymentData?.isAutoApplyTerminal || false}
                               onChange={e => _.assign(paymentData, { isAutoApplyTerminal: e.target.checked })}
              />}
              label=""
            />
          </div>
        )}

        <div className="ml-3 flex flex-row items-center justify-center py-0 px-0.5">
          <FormControlLabel label ="" control={
            <Switch color="primary" size="small"
                    checked={paymentData?.enableTip !== undefined ? paymentData?.enableTip : true}
                    onChange={e => _.assign(paymentData, { enableTip: e.target.checked })}
            />
          } />
        </div>
        <div className="flex flex-row items-center justify-center py-0 px-1 gap-2">
          <img
            className="w-7 relative h-7 object-cover cursor-pointer"
            alt=""
            src="/big-red-bin-icon@2x.png"
            onClick={openTaxAndPaymentDeleteConfi}
          />
          <img
            className="w-7 relative h-7 object-cover cursor-pointer sm:w-[26px] sm:h-[26px] Tablet_600:w-[22px] Tablet_600:h-[22px]"
            alt=""
            src="/yellow-edit-icon1@2x.png"
            onClick={openNewEditPaymentMethodPopu}
          />
        </div>
      </div>
      {isTaxAndPaymentDeleteConfiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTaxAndPaymentDeleteConfi}
        >
          <TaxAndPaymentDeleteConfi onClose={closeTaxAndPaymentDeleteConfi} onComplete={() => deletePaymentMethod(paymentData)} />
        </PortalPopup>
      )}
      {isNewEditPaymentMethodPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewEditPaymentMethodPopu}
        >
          <NewEditPaymentMethodPopu onClose={closeNewEditPaymentMethodPopu} onComplete={closeNewEditPaymentMethodPopu} />
        </PortalPopup>
      )}
    </>
  );
};

export default TaxPaymentPaymentMethodRow;