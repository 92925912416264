import { type FunctionComponent, useState } from "react";
import { TextField, Icon } from "@mui/material";
import { LocalizationProvider, DatePicker, type DatePickerSlotsComponentsProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { STATES, VIEW__PRODUCT } from '@/react/EditOnlineMenuView/EditOnlineMenuView.logic'
import { dateFromUnix, dateToUnix } from '@/react/core/utils'
import { useComputed } from '@/react/core/reactive'
import {LL0} from "@/react/core/I18nService.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";

const formTimePickerSlotProps: DatePickerSlotsComponentsProps<Date> = {
  textField: { variant: 'outlined', hiddenLabel: true, fullWidth: true, color: 'primary', className: 'bg-white' },
}

const ValidityPeriodFieldset: FunctionComponent = () => {
  const [datePickerDateTimePickerValue, setDatePickerDateTimePickerValue] =
  useState<string | null>(null);
  const [datePickerDateTimePicker1Value, setDatePickerDateTimePicker1Value] =
  useState<string | null>(null);

  const from = useComputed(() => dateFromUnix(STATES.editingProduct()?.validity_periodFrom))
  const to = useComputed(() => dateFromUnix(STATES.editingProduct()?.validity_periodTo))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="self-stretch h-[77px] flex flex-row items-start justify-start gap-[20px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold sm:gap-[16px] mq768:gap-[12px]">
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().settings.licenseSetting.startDate()}
          </div>
          <div className="self-stretch">
            <DatePicker
              format={LL2().dates.dateFormat()}
              value={from() ?? null}
              onChange={v => VIEW__PRODUCT.onChanged('validity_periodFrom', dateToUnix(v))}
              slotProps={formTimePickerSlotProps}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().settings.licenseSetting.endDate()}
          </div>
          <div className="self-stretch">
            <DatePicker
              format={LL2().dates.dateFormat()}
              value={to() ?? null}
              onChange={v => VIEW__PRODUCT.onChanged('validity_periodTo', dateToUnix(v))}
              slotProps={formTimePickerSlotProps}
            />
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default ValidityPeriodFieldset;
