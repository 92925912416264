import type { RxCollection, RxDatabase } from 'rxdb'

import { User, USER_COLLECTION_NAME, userSchema } from './User'
import { createCollection } from './utils/migration'

export async function createUserCollection<T>(database: RxDatabase<T>): Promise<RxCollection<User>> {
  return await createCollection({
    database: database,
    collection: User,
    collectionName: USER_COLLECTION_NAME,
    version: userSchema.version,
    schema: userSchema,
    extraStrategy: {
      10: doc => {
        if (doc.role === 'admin') {
          doc.editHighSecuritySetting = true;
          doc.editHappyHours = true;
          doc.openCashDrawer = true
        }
        return doc
      }
    }
  })
}
