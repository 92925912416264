import type {FunctionComponent} from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import {addCustomDish, currentNonFixedProduct} from "@/react/OrderView/OrderView.tsx";
import _ from "lodash";
import Input, {inputController0} from "@/react/core/Input.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";

type OrderScreenAdjustPricePopupType = {
  onClose?: () => void;
};

const OrderScreenAdjustPricePopup: FunctionComponent<
  OrderScreenAdjustPricePopupType
> = ({ onClose }) => {
  const handleClickAdd = () => {
    const _product = currentNonFixedProduct();
    addCustomDish(_product?.name, _product?.price, '', _product?.tax, _product?.id, _product?.taxComponents)
    onClose?.();
  }


  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start p-5 box-border gap-[24px] min-w-[550px] h-full max-w-full max-h-full text-left text-base text-grey-grey-darken-3 font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="relative font-extrabold inline-block min-w-[66px]">
            {LL0().article.name()}:
          </div>
          <Input
            className="w-full"
            refInputController={i => {
              i.setFocus(true)
              i.moveCaretToEnd()
            }}
            value={currentNonFixedProduct()?.name || ""}
            onChange={ value => _.assign(currentNonFixedProduct(), {name: value})}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="relative font-extrabold inline-block min-w-[66px]">
            {LL0().article.price()}:
          </div>
          <Input
            className="w-full"
            value={currentNonFixedProduct()?.price ? currentNonFixedProduct()?.price?.toString() || "" : ""}
            onChange={ value => _.assign(currentNonFixedProduct(), {price: parseFloat(value || "0")})}
          />
        </div>
      </div>
      <TableManualKeyboard
        value={''}
        onEnter={handleClickAdd}
        inputController={inputController0}
      />
    </div>
  );
};

export default OrderScreenAdjustPricePopup;
