import { type FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import DottedLine from "@pending-order/DottedLine.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import _ from "lodash";

type PendingOrdersListItemType = {
  quantity: number;
  itemSName?: string;
  variation?: string;
  itemModifier?: string;
  itemNote?: string;
  price?: number;
  showPrice?: boolean;
  taxes?: Array<number>;
  showIcon?: boolean;
  groupPrinter?: string;
  groupPrinter2?: string;
  labelPrinter?: string;
  onOpenProductMatchDialog?: Function
};

const PendingOrdersListItem: FunctionComponent<PendingOrdersListItemType> = ({
  quantity,
  itemSName,
  variation,
  itemModifier,
  itemNote,
  price,
  showPrice,
  taxes,
  showIcon,
  groupPrinter,
  groupPrinter2,
  labelPrinter,
  onOpenProductMatchDialog
}) => {
  const missingPrinterInfo = (!groupPrinter && !groupPrinter2 && !labelPrinter)
  const missingTaxesInfo = _.isEmpty(taxes);
  const showMatchProduct =  missingTaxesInfo || missingTaxesInfo;

  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-between text-left text-sm text-black font-mulish self-stretch">
        <div className="flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-start text-xs text-brown">
            <b className="relative text-sm flex items-center min-w-[20px] shrink-0">
              {quantity}x
            </b>
            <div className="relative text-black">{itemSName} {variation || ""}</div>
          </div>
        </div>
        {showMatchProduct && <div className="ml-[4px] bg-red-500 px-1 py-1 rounded text-white cursor-pointer" onClick={onOpenProductMatchDialog}>
          {LL0().onlineOrder.assignProduct()}
        </div>}
        {showPrice && <>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(price!)}</div>
        </>}
      </div>
      {itemModifier && <div className="ml-[20px]"><i className="text-xs text-[#777]">- {itemModifier}</i></div>}
      {itemNote && <div className="text-xs text-[#777] ml-[20px]">- {itemNote}</div>}
    </div>
  );
};

export default PendingOrdersListItem;
