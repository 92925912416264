import { clone } from 'json-fn'
import uuid from 'time-uuid'

import { SrmDocumentLog } from '@/data/SrmDocumentLog'
import type { SignedDocumentData } from '@/srm/lib/types'
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

export async function recordDocument(t: SignedDocumentData, user: string) {
  const { deviceId } = deviceSetting0()?.srm ?? {}
  if (!deviceId) throw new Error('Missing deviceId!')

  return await SrmDocumentLog.insert({
    _id: uuid(),
    deviceId,
    date: Date.now(),
    data: clone(t),
    sent: false,
    user,
  })
}
