import { convertDocuments, type DocDeepSignal } from "@/data/data-utils";
import { DATA_INITED_2 } from './data-const'
import { MaxId } from "@/data/MaxId";
import { effectOn, signal } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";
import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { Eod } from "@/data/Eod.ts";
import { getVDate, getVDateDayjs } from "@/pos/orderUtils.ts";
import uuid from "time-uuid";
import { now } from "@/pos/logic/time-provider.ts";
import { captureException } from "@sentry/react";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

export const [maxId0, setMaxId0] = signal<DocDeepSignal<MaxId>>();
export const [maxIdV, setMaxIdV] = signal(0);

// @ts-ignore
window.maxId0 = maxId0;

effectOn([maxIdV], async () => {
  await dataLock.acquireAsync();
  let _maxIds = await MaxId.find().exec();
  if (_maxIds.length === 0 && !localStorage.getItem(DATA_INITED_2)) {
    await MaxId.upsert({
      _id: "max_id",
      eodId: -1,
      z: 0,
      orderId: 1,
      transactionId: 1,
      inventoryId: 1,
      customerId: 1,
    });
    _maxIds = await MaxId.find().exec();
  }
  const maxIds = convertDocuments<MaxId>(_maxIds, true, [], { debounce: 200 });
  setMaxId0(maxIds[0]);
}, { defer: true })

export const makeMaxIdsAvailable = async () => {
  if (maxIdV() === 0) {
    await dataLock.acquireAsync();
    MaxId.$.subscribe((change) => {
      setMaxIdV(v => v + 1);
    })
    setMaxIdV(1);
  }
};


export async function validateEods() {
  await dataLock.acquireAsync();
  // when to trigger it
  Eod.$.subscribe(async (change) => {
    const firstDayOfCurrentMonth = dayjs().startOf('month').unix();

    const _eods = await Eod.find({
      selector: {vDate: {$gte: firstDayOfCurrentMonth}},
      sort: [{date: 'asc'}, {z: 'asc'}]
    }).exec();

    const eods = convertDocuments(_eods, true);
    const eodsByDate = _.groupBy(_eods, e => e.vDate);

    const sortedZ = eods.map(doc => doc.z);

    for (let i = 1; i < sortedZ.length; i++) {
      if (sortedZ[i] > sortedZ[i - 1] + 1) {
        // Check for non-continuous z values
        const eod = eods.find(eod => eod.z === sortedZ[i]);
        captureException(new Error(`Non-incremental z values on date ${dayjs.unix(eod!.vDate!).format('DD.MM.YYYY')}`), {tags: { type: 'eodZ' } });
      }
    }
    for (const vDate in eodsByDate) {
      const _eodByDate = eodsByDate[vDate];

      // Check for multiple incomplete z values
      const incompleteCount = _eodByDate.filter(doc => !doc.complete).length;
      if (incompleteCount >= 2) {
        // console.error(`Error: Multiple incomplete z values on date ${dayjs.unix(parseInt(vDate)).format('DD.MM.YYYY')}`);
        captureException(new Error(`Multiple incomplete z values on date ${dayjs.unix(parseInt(vDate)).format('DD.MM.YYYY')}`), {tags: { type: 'eodZ' } });
      }
    }
    // todo: validate eods: if two eods are not complete in one day -> throw Error
    // captureException(new Error('There are 2 EODs not complete in one day'));
    // check incremental eod z
  })
}
validateEods().then();

/**
 * vDate is beginning of date, in normal case is order.vDate, not order.date
 * @param vDate
 * @param getOnlyZ
 */
export const getMaxIdZ = async (vDate: Dayjs, getOnlyZ: boolean = false) => {
  //todo: prevent case 10 -> 12 no idea why
  const _date = vDate.unix();
  let id = maxId0().orderId!;
  let transactionId = maxId0().transactionId!;
  let z = maxId0().z!;
  let lastEodDate = maxId0().lastEodDate!;
  let mutate = !getOnlyZ;

  //add validate: if vDate is not today -> send error to sentry
  if (await shouldIncreaseZ(_date, z, vDate)) {
    //create EOD Record
    //increase eodId
    z++;
    await Eod.upsert({ _id: uuid(), vDate: _date, z: z, complete: false, date: _date });
    lastEodDate = _date;
    mutate = true;
    if (generalSetting0().resetMaxIdPerDay) {
      // id = 1;
      transactionId = 1;
    }

  }
  if (mutate) {
    await maxId0().doc?.incrementalPatch({
      orderId: getOnlyZ ? id : id + 1,
      transactionId: getOnlyZ ? transactionId : transactionId + 1,
      z, lastEodDate
    })
  }

  return { id, z, transactionId };
}

async function shouldIncreaseZ(_date: number, z: number, vDate: Dayjs) {
  if (getVDateDayjs(dayjs(now())).unix() !== vDate.unix()) {
    captureException(new Error(`vDate: ${vDate.format('DD.MM.YYYY')} but today is: ${dayjs(now()).format('DD.MM.YYYY')}`));
    return false;
  }
  if (_date !== maxId0().lastEodDate || z === maxId0().eodId) {
    const _vDate = getVDate(dayjs(now()).unix());
    const eods = await Eod.find({ selector: { vDate: _vDate } }).exec();
    if (eods.length === 0) return true;
    if (eods.find(e => !e.complete)) return false;
    return true;
  }
  return false;
}

export const getTransactionId = async (date: Dayjs) => {
  const _date = getVDate(date.unix());
  let transactionId = maxId0().transactionId!;
  let z = maxId0().z!;
  let lastEodDate = maxId0().lastEodDate!;
  if (await shouldIncreaseZ(_date, z, dayjs.unix(_date))) {
    //create EOD Record
    //increase eodId
    z++;
    await Eod.upsert({ _id: uuid(), vDate: _date, z: z, complete: false, date: _date });
    lastEodDate = _date;
    if (generalSetting0().resetMaxIdPerDay) {
      // id = 1;
      transactionId = 1;
    }

  }
  _.assign(maxId0(), {
    transactionId: transactionId + 1,
    z, lastEodDate
  });
  return { transactionId };
}

makeMaxIdsAvailable().then();