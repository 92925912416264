import { useCallback, useLayoutEffect, useMemo, useState, type MouseEvent } from 'react'
import { toast } from 'react-toastify'

import { Button } from '@/components/ui/button.tsx'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { computed } from '@/react/core/reactive'
import { runTestcase001 } from '@/srm/testcase/001'
import { runTestcase002 } from '@/srm/testcase/002'
import { runTestcase003 } from '@/srm/testcase/003'
import { runTestcase004 } from '@/srm/testcase/004'
import { runTestcase005 } from '@/srm/testcase/005'
import { runTestcase006 } from '@/srm/testcase/006'
import { runTestcase007 } from '@/srm/testcase/007'
import { runTestcase008 } from '@/srm/testcase/008'
import { runTestcase009 } from '@/srm/testcase/009'
import { runTestcase010 } from '@/srm/testcase/010'
import { runTestcase011 } from '@/srm/testcase/011'
import { runTestcase019 } from '@/srm/testcase/019'
import { runTestcase020 } from '@/srm/testcase/020'
import { runTestcase021 } from '@/srm/testcase/021'
import { runTestcase022 } from '@/srm/testcase/022'
import { runTestcase024 } from '@/srm/testcase/024'
import { runTestcase026 } from '@/srm/testcase/026'
import { runTestcase029 } from '@/srm/testcase/029'
import { runTestcase030 } from '@/srm/testcase/030'
import { runTestcase031 } from '@/srm/testcase/031'
import { runTestcase036 } from '@/srm/testcase/036'
import { runTestcase037 } from '@/srm/testcase/037'
import { runTestcase103 } from '@/srm/testcase/103'
import { TESTCASES } from '@/srm/testcase/constants'
import { loadTestcaseSummary, saveTestcaseSummary } from '@/srm/testcase/libs'

const isProd = computed(() => posSetting0()?.srm?.env === 'PROD')

export default function SrmTestcase() {
  const [running, setRunning] = useState(false)
  const [results, setResults] = useState<Record<string, boolean>>(loadTestcaseSummary())
  const cases = useMemo(() => Object.keys(TESTCASES), []).sort()

  useLayoutEffect(() => saveTestcaseSummary(results), [results])

  const run = useCallback(async function (num: string) {
    setRunning(true)
    localStorage.setItem('srm_runningTestcase', num)
    try {
      // prettier-ignore
      switch (num) {
        case '001': await runTestcase001(); break
        case '002': await runTestcase002(); break
        case '003': await runTestcase003(); break
        case '004': await runTestcase004(); break
        case '005': await runTestcase005(); break
        case '006': await runTestcase006(); break
        case '007': await runTestcase007(); break
        case '008': await runTestcase008(); break
        case '009': await runTestcase009(); break
        case '010': await runTestcase010(); break
        case '011': await runTestcase011(); break
        case '019': await runTestcase019(); break
        case '020': await runTestcase020(); break
        case '021': await runTestcase021(); break
        case '022': await runTestcase022(); break
        case '024': await runTestcase024(); break
        case '026': await runTestcase026(); break
        case '029': await runTestcase029(); break
        case '030': await runTestcase030(); break
        case '031': await runTestcase031(); break
        case '036': await runTestcase036(); break
        case '037': await runTestcase037(); break
        case '103': await runTestcase103(); break
        default: throw new Error('Unimplemented')
      }
      setResults(a => ({ ...a, [num]: true }))
      toast.success(`Testcase ${num} ended successfully!`)
      localStorage.removeItem('srm_runningTestcase')
      return true
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message)
        setResults(a => ({ ...a, [num]: false }))
      }
      return false
    } finally {
      setRunning(false)
    }
  }, [])

  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.preventDefault()
      const num = e.currentTarget.dataset['num'] ?? ''
      if (cases.includes(num)) await run(num)
    },
    [cases]
  )
  const handleRunAll = useCallback(
    async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.preventDefault()
      for (const num of cases) if (!(await run(num))) break
    },
    [cases]
  )
  const handleRunAllFormLastSucceeded = useCallback(
    async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.preventDefault()
      for (const num of cases) {
        if (results[num] == true) continue
        if (!(await run(num))) break
      }
    },
    [cases, results]
  )

  return (
    <div className="grid grid-cols-4 gap-1">
      {cases.map(n => (
        <Button
          key={n}
          disabled={running || isProd()}
          variant={results[n] == true ? 'successive' : results[n] === false ? 'destructive' : 'outline'}
          size="sm"
          onClick={onClick}
          data-num={n}
        >
          🧪 {n}
        </Button>
      ))}
      <Button
        disabled={running || isProd()}
        variant="default"
        onClick={handleRunAll}
        className="col-span-4"
      >
        ▶️ ALL (from start)
      </Button>
      <Button
        disabled={running || isProd()}
        variant="default"
        onClick={handleRunAllFormLastSucceeded}
        className="col-span-4"
      >
        ▶️ ALL (skip succeeded)
      </Button>
    </div>
  )
}
