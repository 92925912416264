import { captureException } from '@sentry/react'
import axios from 'axios'
import debug from 'debug'
import delay from 'delay'
import _ from 'lodash'
import { toast } from 'react-toastify'
import uuid from 'time-uuid'

import bellMp3 from '@/assets/sounds/bell.mp3'
import { type DocDeepSignal } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils'
import { OnlineOrder } from '@/data/OnlineOrder'
import { OnlineOrderProductMappingCollection } from '@/data/OnlineOrderProductMapping.ts'
import { runMasterHandlerPaidOrderFactory } from '@/data/OrderHub.ts'
import { Payment } from '@/data/Payment.ts'
import { payments0 } from '@/data/PaymentHub.ts'
import { isQuebecSrmEnabled } from '@/data/PosSettingsSignal.ts'
import type { Product } from '@/data/Product.ts'
import { StaffStatus } from '@/data/TableStaffStatus'
import { isTableHasStaffEditing, recordTableStaffStatus } from '@/data/TableStaffStatusHub'
import { isValidSrmUser } from '@/data/UserHub'
import { createOrder, stripOrder } from '@/pos/logic/order-reactive'
import { OrderAction as Action } from '@/pos/OnlineOrderType'
import {
  CommitAction,
  InvoiceTypes,
  MarketPlaceProvider,
  MasterHandlerCommand,
  OrderStatus,
  type TOrder
} from '@/pos/OrderType'
import { PosScreen, router } from '@/pos/PosRouter.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { effectOn, signal } from '@/react/core/reactive'
import {
  callbackPayment,
  onPaymentClick,
  order0 as paymentOrder0,
  setOrder0 as _setOrder0
} from '@/react/PaymentView/PaymentView.tsx'
import msgBox from '@/react/SystemService/msgBox.tsx'
import { unwrapProxy } from '@/react/utils/common.ts'
import { paymentHook } from '@/react/utils/hooks.ts'
import { getApiUrl } from '@/shared/utils.ts'
import { srmTransactionLogic } from '@/srm/transaction.logic'

import { applyQuebecTaxComponents, checkPaymentConsistency, recalculateDiscount } from './srm-fix'
import { ensureValidItemNameLength, nameLengthRegex } from "@/srm/lib/utils.ts";
import { getTicketNumber } from "@/react/Printer/print-label.ts";

const log = debug('dev:PendingOrderLogic')

export const [onlineOrdersV, setOnlineOrdersV] = signal<number>(0)
export const [pendingOrders0, setPendingOrders0] = signal<Array<DocDeepSignal<TOrder>>>([])
export const [kitchenOrders0, setKitchenOrders0] = signal<Array<DocDeepSignal<TOrder>>>([])

export const [totalOrder, setTotalOrder] = signal<number>(0)
export const [totalPendingOrder, setTotalPendingOrder] = signal<number>(0)
export const [totalKitchenOrder, setTotalKitchenOrder] = signal<number>(0)

export const [popupOrder, setPopupOrder] = signal<TOrder>()

const repeat = () => true
const playSound = () => true

let isPlaying = false
let shouldStopAtNextTurn = false

const bell = new Audio(bellMp3)

function playBell() {
  if (import.meta.env.MODE === 'development') return

  if (!playSound()) {
    console.log('play sound is disabled. skip')
    return
  }

  if (isPlaying) {
    console.log('bell is playing. skip')
    return
  }

  shouldStopAtNextTurn = false
  bell.play().then()
}

export function stopBell() {
  shouldStopAtNextTurn = true
}

let bellInitialized = false

function initBell() {
  if (bellInitialized) return
  bellInitialized = true
  isPlaying = false
  shouldStopAtNextTurn = false
  bell.addEventListener('play', () => (isPlaying = true))
  bell.addEventListener('ended', () => {
    isPlaying = false
    const isPendingOrdersNotEmpty = totalPendingOrder() > 0
    if (repeat() && !shouldStopAtNextTurn && isPendingOrdersNotEmpty) {
      playBell()
    }
  })
}

const axiosConfig = {
  // bypass 3xx redirect
  // TODO: check if 2xx, 4xx, 5xx
  validateStatus: (status: number) => status < 400,
}

function getExternalProductKey(provider: MarketPlaceProvider, item: any) {
  switch (provider) {
    case MarketPlaceProvider.RESTABLO:
      return `${item.name}__${item.sku || ''}`
    case MarketPlaceProvider.LIEFERANDO:
      return item.id;
    default:
      return item._id;
  }
}

export async function mapProduct(order: TOrder, itemIdx: number, posProduct?: Product) {
  const items = unwrapProxy(order.items)
  if (itemIdx >= items.length) {
    console.warn('[mapProduct] bad index')
    return
  }
  posProduct = unwrapProxy(posProduct)
  const selectedItem = items[itemIdx]
  const externalProductKey = getExternalProductKey(order.provider!, selectedItem);
  const itemsWithTheSameKey = items.filter((item: any) => getExternalProductKey(order.provider!, item) === externalProductKey);

  for (const item of itemsWithTheSameKey) {
    // mapped
    item._id = posProduct?._id

    // printer
    item.labelPrinter = posProduct?.labelPrinter
    item.groupPrinter = posProduct?.groupPrinter
    item.groupPrinter2 = posProduct?.groupPrinter2

    // tax
    item.tax = posProduct?.tax
    item.tax2 = posProduct?.tax2
    item.taxCategory = posProduct?.taxCategory
    item.taxCategory2 = posProduct?.taxCategory2
    item.taxComponents = posProduct?.taxComponents
    item.taxComponents2 = posProduct?.taxComponents2
    item.taxes = [posProduct?.tax, posProduct?.tax2]
    // add more if needed
  }

  try {
    console.log('[mapProduct] update OnlineOrderCollection')
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    await rawOrder?.incrementalPatch({ items: items })
    console.log('[mapProduct] OnlineOrderCollection updated')
  } catch (e) {
    console.error('[mapProduct] failed to update items', e)
  }

  try {
    console.log('[mapProduct] posProduct', posProduct)
    const change = {
      _id: uuid(),
      externalProductKey,
      productId: posProduct?._id,
      provider: order.provider,
    }
    console.log('[mapProduct] saving OnlineOrderProductMappingCollection', externalProductKey, change)
    await OnlineOrderProductMappingCollection.upsert(change)
    console.log('[mapProduct] OnlineOrderProductMappingCollection saved')
  } catch (e) {
    console.error('[mapProduct] failed to save OnlineOrderProductMappingCollection')
  }
}

function isOrderByPhone(order: any) {
  return order.provider === MarketPlaceProvider.PHONE
}

export async function declineOrder(order: TOrder, reason: string) {
  const LL = LL0()
  try {
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    await rawOrder?.incrementalPatch({ status: OrderStatus.REJECTING, responseMessage: reason })
    if (!rawOrder?.isFake)
      await axios.post(
        `${getApiUrl()}/api/order/update-status`,
        {
          order: Object.assign(order, { responseMessage: reason }),
          action: Action.REJECT,
        },
        axiosConfig
      )
    await delay(100)
    await rawOrder?.incrementalPatch({ status: OrderStatus.REJECTED })
  } catch (e: any) {
    console.error('error', e)
    captureException(new Error('declineOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToDecline()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

export async function cancelOrder(order: TOrder) {
  const LL = LL0()
  try {
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    await rawOrder?.incrementalPatch({ status: OrderStatus.CANCELLING })
    if (!rawOrder?.isFake && !isOrderByPhone(order)) await axios.post(`${getApiUrl()}/api/order/update-status`, { order, action: Action.CANCEL }, axiosConfig)
    await delay(100)
    await rawOrder?.incrementalPatch({ status: OrderStatus.CANCELLED })
  } catch (e: any) {
    captureException(new Error('cancelOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToCancel()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

export type OnAcceptOption = { pickupDate?: string; dropOffDate?: string }

//fixme: print over call master api
export async function acceptOrder(order: TOrder, storeExpectedDate: OnAcceptOption) {
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())
  const LL = LL0()
  try {
    toast.info(LL.pendingOrder.updateOrderStatus(), { autoClose: 1000 })
    if (!order?.isFake)
      await axios.post(
        `${getApiUrl()}/api/order/update-status`,
        {
          order: Object.assign({}, order, storeExpectedDate),
          action: Action.ACCEPT,
        },
        axiosConfig
      )
    await delay(100)
    getTicketNumber(order)
    order.status = OrderStatus.ACCEPTED
    order.pickupDate = storeExpectedDate.pickupDate
    order.dropOffDate = storeExpectedDate.dropOffDate

    // order.users = loginUsers();
    // printKitchen(order, false).catch(e => captureException(new Error('acceptOrder', { cause: e }), { tags: { type: 'print' } }))
    // await handlePrintLabel(order)
    // await assignZ(order);
    // order.id = maxId0().orderId!;
    // await onPrintTse(order);
    if (!ensureValidItemNameLength(order.items)) {
      const validateNameLength = name => {
        return name.padEnd(2, '_').substring(0, 128)
      };

      order.items.forEach(item => {
        if (!nameLengthRegex.test(item?.name)) {
          item.name = validateNameLength(item?.name || '');
        }

        if (item?.modifiers?.length) {
          item.modifiers.forEach(modifier => {
            if (!nameLengthRegex.test(modifier.name))
            modifier.name = validateNameLength(modifier?.name || '');
          });
        }
      });
    }
    if (isQuebecSrmEnabled()) {
      await srmTransactionLogic.recordTemporaryBill(order);
    }
    order.commits?.push({ action: CommitAction.PRINT })
    const api = runMasterHandlerPaidOrderFactory(stripOrder(order), [MasterHandlerCommand.onKitchenAndLabelPrint])
    await api.runFull(true, OnlineOrder);
    console.log('acceptOrder finish')
    // await OnlineOrderCollection.upsert(stripOrder(order));
  } catch (e) {
    console.error('error', e)
    captureException(new Error('acceptOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToAccept()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

export async function readyOrder(order: TOrder) {
  const LL = LL0()
  try {
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    if (!rawOrder?.isFake && !isOrderByPhone(order)) await axios.post(`${getApiUrl()}/api/order/update-status`, { order, action: Action.READY }, axiosConfig)
    await delay(100)
    await rawOrder?.incrementalPatch({ status: OrderStatus.READY })
  } catch (e: any) {
    captureException(new Error('readyOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToReady()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

//fixme: print over call master api
export async function completeOrder(order: TOrder) {
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())
  const LL = LL0()
  const _update = async (order: TOrder) => {
    try {
      const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
      if (!order.isFake && !isOrderByPhone(order)) await axios.post(`${getApiUrl()}/api/order/update-status`, { order, action: Action.COMPLETE }, axiosConfig)
      await delay(100)
      await rawOrder?.incrementalPatch({ status: OrderStatus.COMPLETED })

      // When user click print invoice in the payment screen, the order is considered as paid.
      if (order.status === OrderStatus.PAID) return

      const cmds: any[] = []
      await paymentHook.emit('payOrderHandler', cmds)
      if (isQuebecSrmEnabled()) {
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
      } else {
        cmds.push('printInvoice', InvoiceTypes.INVOICE.toString())
      }
      const api = runMasterHandlerPaidOrderFactory(Object.assign(order, { status: OrderStatus.PAID }), [MasterHandlerCommand.singleComplete, cmds.join(',')])
      await api.runFull()
      //todo handle tse case
    } catch (e) {
      console.error('error', e)
      captureException(new Error('completeOrder', { cause: e }), { tags: { type: 'oo' } })
      await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToComplete()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
    }
  }

  const orderPayments = _.map(order.payments, pm => _.toUpper(pm.extraType))
  const isPaid = orderPayments.length && !orderPayments.includes('CASH')
  if (isPaid) {
    await _update(order)
  } else {
    if (isQuebecSrmEnabled() && order.table && (await isTableHasStaffEditing(order.table))) {
      return // TODO: check if this flow is valid ?
    }
    router.screen = PosScreen.PAYMENT
    if (isQuebecSrmEnabled() && order.table) await recordTableStaffStatus(order.table, StaffStatus.PROCESSING_PAYMENT, order._id)

    // set default payment to cash
    const cashPayment = payments0().find(item => _.toUpper(item.type) === 'CASH')
    let order1 = createOrder(order)
    order1.payments.splice(0, order1.payments.length)
    _setOrder0(order1)
    onPaymentClick(cashPayment || ({ name: 'cash' } as Payment))

    callbackPayment.fn = async () => {
      delete callbackPayment.fn
      router.screen = PosScreen.PENDING_ORDERS
      const order = paymentOrder0()
      await _update(order)
      return order
    }
  }
}

const pendingOrderStatus = [OrderStatus.IN_PROGRESS, OrderStatus.REJECTING, OrderStatus.ACCEPTING]
const kitchenOrderStatus = [OrderStatus.ACCEPTED, OrderStatus.CANCELLING, OrderStatus.READY]

const fetchOrders = _.debounce(async () => {
  await dataLock.acquireAsync()
  const orders = await OnlineOrder.find({
    selector: {
      status: { $in: [...pendingOrderStatus, ...kitchenOrderStatus] },
    },
  }).exec()
  const pendingOrders = []
  const kitchenOrders = []

  for (const o of orders) {
    const { status } = o
    if (!pendingOrderStatus.includes(status) && !kitchenOrderStatus.includes(status)) continue
    const order = o.toMutableJSON()

    log('⚡️ Got new online order...', _.cloneDeep(order))
    // FIXME: This is a temporary fix for Quebec SRM to avoid missing tax components in receiving order's items.
    //        Should be handled for all other cases properly.
    if (isQuebecSrmEnabled()) {
      applyQuebecTaxComponents(order)

      if (order.provider === MarketPlaceProvider.PIKAPOINT) recalculateDiscount(order)

      log('⚡️ Fixed new online order', _.cloneDeep(order))
    }

    const recreatedOrder = createOrder(order)

    // FIXME: This is a temporary fix for Quebec SRM to avoid missing tax components in receiving order's items.
    //        Should be handled for all other cases properly.
    if (order.provider === MarketPlaceProvider.PIKAPOINT && isQuebecSrmEnabled()) {
      checkPaymentConsistency(recreatedOrder)
    }
    log('⚡️ Recreated online order', recreatedOrder)

    if (pendingOrderStatus.includes(status)) {
      pendingOrders.push(recreatedOrder)
    } else if (kitchenOrderStatus.includes(status)) {
      kitchenOrders.push(recreatedOrder)
    } else {
      // ...
    }
  }

  const shouldPlayBell = pendingOrders0().length < pendingOrders.length

  setTotalOrder(pendingOrders.length + kitchenOrders.length)

  setTotalPendingOrder(pendingOrders.length)
  // @ts-ignore
  setPendingOrders0(pendingOrders)

  setTotalKitchenOrder(kitchenOrders.length)
  // @ts-ignore
  setKitchenOrders0(_.sortBy(kitchenOrders, order => order.pickupDate))

  if (shouldPlayBell) {
    playBell()
    const currentScreenIsPendingOrderScreen: boolean = PosScreen.PENDING_ORDERS === router.screen
    const autoClose: any = currentScreenIsPendingOrderScreen ? 1000 : false
    toast(LL0().onlineOrder.youHaveNewOnlineOrder(), {
      type: 'info',
      autoClose,
      style: {
        fontSize: '20px',
        padding: '20px',
        backgroundColor: '#77f077',
        color: '#fff',
      },
      onClick: () => {
        if (currentScreenIsPendingOrderScreen) return
        router.screen = PosScreen.PENDING_ORDERS
      },
    })
  }
}, 100)

effectOn([onlineOrdersV], fetchOrders)

dataLock.acquireAsync().then(() => {
  OnlineOrder.$.subscribe(() => setOnlineOrdersV(v => v + 1))
  initBell()
})
