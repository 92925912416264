import React, { type FunctionComponent, useCallback, useEffect } from 'react'
import { createNewDiscount, deleteDiscount, isDiscountOrder, itemContext } from '@/react/OrderView/OrderView.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { InputAdornment, TextField } from '@mui/material'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { discountAmounts0, discountLabels0, discountPercents0, discounts0 } from '@/data/DiscountHub.ts'
import type { Discount } from '@/data/Discount.ts'
import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Setter } from "solid-js";
import { clsx } from "clsx";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { paymentContext0 } from "@/react/PaymentView/PaymentView.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type DiscountFormType = {
  showDiscountForm?: boolean
  type: string
  order?: Order | OrderItem
  discount: Discount
  setDiscount: Setter<Discount>
  isEdit?: boolean
  onApply?: (discount: Discount) => void
  onClear?: () => void
  onClose?: () => void
};

const DiscountForm: FunctionComponent<DiscountFormType> = (props) => {

  const setInputValue = (newValue: string | ((prev: string) => string)) => {
    if (props.isEdit) return;
    if (typeof newValue === 'string') {
      if (newValue === '.' && !props.discount.value.toString().includes('.')) {
        props.setDiscount(prev => ({ ...prev, value: prev?.value.toString() + '.' }) as any)
      } else {
        props.setDiscount(prev => ({ ...prev, value: (newValue) }) as any)
      }
    } else {
      props.setDiscount(prev => ({ ...prev, value: (newValue(props.discount.value.toString() || '')) }) as any)
    }
  }

  const checkApplyDiscount = useCallback((d: Discount) => {
    return props.discount.label === d.label && props.discount.type === d.type && props.discount.value.toString() === d.value.toString()
  }, [props.discount.label, props.discount.type, props.discount.value])

  const handleChangeDiscount = ({ value, type, label }: Omit<Discount, '_id'>) => {
    if (!props.isEdit) {
      props.setDiscount({ type, label, value } as any)
      setInputValue(value.toString())
    }
  }

  const handleApplyDiscount = async () => {
    if (props.isEdit || (props.type === 'item' && isDiscountOrder())) return;
    const isExistsDiscount = discounts0().some(d => checkApplyDiscount(d))
    let isCreateNew = false
    if (!isExistsDiscount && props.discount.label === "") {
      await createNewDiscount(props.discount as Discount)
      isCreateNew = true
    }
    if (!props.discount.value) {
      props.onClear?.()
    } else if (isCreateNew || isExistsDiscount) {
      isCreateNew = false
      props.onApply?.(props.discount)
    }
    props.onClose?.()
  }

  const handleClearDiscount = () => {
    if (props.isEdit || (props.type === 'item' && isDiscountOrder())) return;
    props.onClear?.()
    props.onClose?.()
  }

  const handleDeleteButton = async (e: React.MouseEvent<HTMLImageElement, MouseEvent>, discount: Discount) => {
    e.stopPropagation()
    await deleteDiscount(discount)
  }

  // //init data
  useEffect(() => {
    props.setDiscount({ value: '', type: 'percent', label: '' })
    const currentSeat = (router.screen === PosScreen.ORDER) ? itemContext?.seat() : paymentContext0?.seat();
    const isOrder = props.order?.hasOwnProperty('seatMode') && props.order?.hasOwnProperty('discountSeat')
    const isSeatDiscountAmount = isOrder ? (props.order as Order)?.seatMode && currentSeat !== undefined && ((props?.order as Order)?.discountSeat?.filter(d => d?.seat === currentSeat) ?? [])?.length > 0 : null
    const currentDiscount = isSeatDiscountAmount ? (props?.order as Order)?.discountSeat?.find(d => d.seat === currentSeat)?.discount : props.order?.discount
    // const currentDiscount = props.order?.discount
    if (!currentDiscount) return
    const [value, percent] = currentDiscount.toString().split('%')
    props.setDiscount({
      value: Number(value),
      type: percent !== undefined ? 'percent' : 'amount',
      label: props.order?.discountLabel
    } as any)
  }, [])

  return (
    props.showDiscountForm && (
      <div
        className="discount-popup !flex self-stretch flex-1 hidden flex-col items-start justify-between pt-4 pb-1 pr-2 pl-0 z-[2] text-left text-smi text-black-solid-black-1000-000000 font-mulish Mobile_480:pt-3 Mobile_480:pr-0 Mobile_480:box-border">
        <div
          className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-2 pb-6 gap-[16px_0px]">
          {(props.type === 'item' && isDiscountOrder()) &&
            <p className="relative text-sm text-red-600">{LL0().order.discountAlert()}</p>
          }
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px]">
            <div className="flex flex-row items-center justify-start gap-[0px_8px]">
              <img
                className="w-5 relative h-5 hidden"
                alt=""
                src="/uncheck1.svg"
              />
              {!props.isEdit && <input
                className="cursor-pointer relative w-5 h-5"
                type="radio"
                name="Discount Popup"
                checked={props.discount.label !== ''}
                onChange={() => {
                }}
              />}
              <div className="relative">{LL0().order.discountLabel()}</div>
            </div>
            <div
              className="gap-3 self-stretch flex flex-row flex-wrap items-start justify-start text-center text-sm text-black-solid-black-880-1d1d26">
              {discountLabels0().map((d, index) => {
                return <div
                  className="rounded-10xs bg-gray-solid-gray-130-f4f4f4-200 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-[34px] flex flex-row items-center justify-center py-0 px-5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
                  key={index}
                  onClick={() => handleChangeDiscount(d)}
                >
                  {checkApplyDiscount(d) &&
                    <div
                      className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                  }
                  <span className="z-[1]">{d.label} | {d.value} {d.type === 'amount' ? LL3().common.currency() : '%'}</span>
                  {props.isEdit && <img
                    className="!flex w-6 absolute !m-[0] top-[-8px] right-[-10px] h-6 hidden z-[4]"
                    alt=""
                    src="/delete-discount-label.svg"
                    onClick={(e) => handleDeleteButton(e, d)}
                  />}
                </div>
              })}
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <div className="flex flex-row items-center justify-start gap-[8px]">
                {!props.isEdit && <input
                  id="discount-percent"
                  className="cursor-pointer m-0 w-5 relative h-5"
                  type="radio"
                  name="Discount Popup"
                  checked={props.discount.label === '' && props.discount.type === 'percent'}
                  onChange={e => e.target.checked && props.setDiscount({ label: '', value: "", type: 'percent' })}
                />}
                {/*<img*/}
                {/*  className="w-5 relative h-5 hidden"*/}
                {/*  alt=""*/}
                {/*  src="/order-view-delete-discount-label.svg"*/}
                {/*/>*/}
                <label className="relative" htmlFor="discount-percent">
                  <span>{LL0().dialogs.discountBy()}</span>
                  <b> % ({LL0().discount.percent().toLowerCase()})</b>
                </label>
              </div>
              <div
                className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[20px] text-center text-sm text-black-solid-black-880-1d1d26 Tablet_600:gap-[10px] Mobile_480:gap-[8px] mq320:gap-[6px]">
                {discountPercents0().map((d, index) => {
                    return <div
                      className="rounded-10xs bg-gray-solid-gray-130-f4f4f4-200 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-[34px] flex flex-row items-center justify-center py-0 px-5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
                      key={index}
                      onClick={() => handleChangeDiscount(d)}
                    >
                      {checkApplyDiscount(d) &&
                        <div
                          className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />}
                      <div className="relative z-[3]">{d.value}%</div>
                      {props.isEdit && <img
                        className="!flex w-6 absolute !m-[0] top-[-8px] right-[-10px] h-6 hidden z-[4]"
                        alt=""
                        src="/delete-discount-label.svg"
                        onClick={(e) => handleDeleteButton(e, d)}
                      />}
                    </div>
                  }
                )}

              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="flex flex-row items-center justify-start gap-[8px]">
                  {!props.isEdit && <input
                    id="discount-amount"
                    className="cursor-pointer m-0 relative w-5 h-5"
                    type="radio"
                    name="Discount Popup"
                    checked={props.discount.label === '' && props.discount.type === 'amount'}
                    onChange={e => e.target.checked && props.setDiscount({ label: '', value: "", type: 'amount' })}
                  />}
                  <label className="relative" htmlFor="discount-amount">
                    <span>{LL0().dialogs.discountBy()}</span>
                    <b> {LL3().common.currency()} ({LL0().discount.amount().toLowerCase()})</b>
                  </label>
                  {/*<img*/}
                  {/*  className="relative w-5 h-5 hidden"*/}
                  {/*  alt=""*/}
                  {/*  src="/check1.svg"*/}
                  {/*/>*/}
                </div>
                <div
                  className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[20px] text-center text-sm text-black-solid-black-880-1d1d26 Tablet_600:gap-[10px] Mobile_480:gap-[8px] mq320:gap-[6px]">
                  {discountAmounts0().map((d, index) => {
                      return <div
                        className="rounded-10xs bg-gray-solid-gray-130-f4f4f4-200 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-[34px] flex flex-row items-center justify-center py-0 px-5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
                        key={index}
                        onClick={() => handleChangeDiscount(d)}
                      >
                        {checkApplyDiscount(d) && <div
                          className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />}
                        <div className="relative z-[3]">{d.value}</div>
                        {props.isEdit && <img
                          className="!flex w-6 absolute !m-[0] top-[-8px] right-[-10px] h-6 hidden z-[4]"
                          alt=""
                          src="/delete-discount-label.svg"
                          onClick={(e) => handleDeleteButton(e, d)}
                        />}
                      </div>
                    }
                  )}
                </div>
              </div>
              <div
                className="self-stretch rounded bg-gray-solid-gray-180-ebecee [backdrop-filter:blur(30px)] h-[86px] flex flex-row items-center justify-center p-1 box-border gap-[4px] text-center text-xl Mobile_480:gap-[4px] Mobile_480:p-0.5 Mobile_480:box-border">
                <div
                  className="flex-1 relative h-[76px] grid grid-rows-[repeat(2,1fr)] grid-cols-[repeat(10,1fr)] [grid-row-gap:4px] [grid-column-gap:4px] Mobile_480:grid Mobile_480:grid-cols-[repeat(10,1fr)] Mobile_480:[grid-row-gap:px] Mobile_480:[grid-column-gap:px]">
                  <div className="relative col-[1/9] row-[1/1]">
                    <TextField
                      className="col-[1/9] row-[1/1] !m-0 bg-white-solid-white-100-ffffff"
                      type="string"
                      sx={{ m: 1, width: '100%' }}
                      InputProps={{
                        startAdornment: <InputAdornment
                          position="start">{props.discount.type === 'amount' ? LL3().common.currency() : '%'}</InputAdornment>,
                        readOnly: true
                      }}
                      size={'small'}
                      value={props.discount.value.toString() || ''}
                      onChange={e => setInputValue(e.target.value)}
                      focused={!props.discount.label}
                      disabled={props.isEdit}
                    />
                  </div>
                  <div
                    className="rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] h-9 overflow-hidden flex flex-row items-center justify-center relative text-base text-black-solid-black-900-1e1e23"
                    ref={makeRipple}
                    onClick={() => setInputValue(prev => {
                      const _prev = prev.toString()
                      if (_prev.includes('.')) return _prev
                      return _prev + '.'
                    })}
                  >
                    <div className="relative leading-[17px] font-medium">.</div>
                  </div>
                  <div
                    className="rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] h-9 overflow-hidden flex flex-row items-center justify-center relative"
                    ref={makeRipple}
                    onClick={() => {
                      setInputValue(prev => {
                        return prev.toString().slice(0, -1)
                      })
                    }}
                  >
                    <img
                      className="relative w-[30px] h-[30px] object-cover"
                      alt=""
                      src="/order-view-delete-keyboard@2x.png"
                    />
                  </div>
                  {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((number, index) => (
                    <div
                      key={index}
                      className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] h-9 flex flex-col items-center justify-center relative"
                      ref={makeRipple}
                      onClick={() => setInputValue(prev => prev + number)}
                    >
                      <div className="relative">{number}</div>
                    </div>
                  ))}
                </div>
                <div
                  className="rounded-8xs-6 bg-blue-solid-blue-350-2196f3 shadow-[0px_1px_0px_#898a8d] hidden flex-col items-center justify-center min-w-[52px] relative col-[2/2] text-base"
                  ref={makeRipple}
                >
                  <div className="relative Mobile_480:text-smi mq320:text-xs">
                    {LL0().ui.save()}
                  </div>
                </div>
                <div
                  className="text-white h-[76px] flex flex-col items-start justify-start gap-[4px] relative col-[3/3] text-sm">
                  <div
                    className={clsx(
                      "self-stretch flex-1 rounded-10xs bg-orange-solid-orange-450-ffaa06 flex flex-row items-center justify-center py-2 px-1",
                      { 'opacity-60': (props.type === 'item' && isDiscountOrder()) }
                    )}
                    onClick={handleApplyDiscount}
                    ref={makeRipple}
                  >
                    <div
                      className="relative leading-[17px] font-semibold Tablet_600:text-2xs Mobile_480:text-2xs mq320:text-3xs">
                      {LL0().order.applyNow()}
                    </div>
                  </div>
                  {/*<div*/}
                  {/*  className="self-stretch flex-1 rounded-10xs bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center py-2 px-1"*/}
                  {/*  onClick={handleClearDiscount}*/}
                  {/*  ref={makeRipple}*/}
                  {/*>*/}
                  {/*  <div*/}
                  {/*    className="relative leading-[17px] font-semibold Tablet_600:text-2xs Mobile_480:text-2xs mq320:text-3xs">*/}
                  {/*    {LL0().customer.clear()}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div
                    className={clsx(
                      "!bg-red-600 self-stretch flex-1 rounded-10xs bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center py-2 px-1",
                      { 'opacity-60': (props.type === 'item' && isDiscountOrder()) }
                    )}
                    onClick={handleClearDiscount}
                    ref={makeRipple}
                  >
                    <div
                      className="relative leading-[17px] font-semibold Tablet_600:text-2xs Mobile_480:text-2xs mq320:text-3xs">
                      {LL0().customer.clear()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default DiscountForm
