import { effectOn, signal, useAsyncEffect } from '@/react/core/reactive.ts'
import { dataLock } from '@/data/DataUtils.ts'
import { Discount } from '@/data/Discount.ts'
import { map } from 'rxjs'
import { convertDocuments, type DocDeepSignal } from '@/data/data-utils.ts'
import { tap } from 'rxjs/operators'
import uuid from 'time-uuid'
import debug from 'debug'

const log = debug('data:discount')
export const [discounts0, setDiscounts0] = signal<Array<DocDeepSignal<Discount>>>([]);

export const [discountLabels0, setDiscountLabels0] = signal<Array<DocDeepSignal<Discount>>>([]);
export const [discountPercents0, setDiscountPercents0] = signal<Array<DocDeepSignal<Discount>>>([]);
export const [discountAmounts0, setDiscountAmounts0] = signal<Array<DocDeepSignal<Discount>>>([]);


let dataLoaded = false

export const makeDiscountsAvailable = () => {
  useAsyncEffect(async () => {
    log('⚡️ Loading discount data...')
    if (dataLoaded) return;
    await dataLock.acquireAsync();
    const sub = Discount.find({sort: [{value: 'asc'}]})
      .$.pipe(
        map(a => convertDocuments(a, true)),
        tap(a => setDiscounts0(a)),
        // tap(a => log('⚡️ Discount data loaded', a)),
      )
      .subscribe();
    dataLoaded = true;
    return () => sub.unsubscribe();
  })
}

effectOn([discounts0],(() => {
  setDiscountLabels0(discounts0().filter(d => d.label))
  setDiscountAmounts0(discounts0().filter(d => !d.label && d.type === 'amount'))
  setDiscountPercents0(discounts0().filter(d => !d.label && d.type === 'percent'))
}))