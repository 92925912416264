import {type FunctionComponent, useMemo, type CSSProperties, useCallback, useRef, useEffect } from 'react'
import { setSelectedUserId } from '@/react/UserSettingView/UserSettingView.tsx'

export type UserTabType = {
  userId?: string
  profileImageId?: string
  userName?: string
  showSelected?: boolean

  /** Style props */
  userTabAlignSelf?: CSSProperties['alignSelf']
}

const UserTab: FunctionComponent<UserTabType> = ({ profileImageId, userName = 'Carolyn Campbell', showSelected = true, userTabAlignSelf, userId }) => {
  const onClick = useCallback(() => setSelectedUserId(userId), [])
  const userTabStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: userTabAlignSelf,
    }
  }, [userTabAlignSelf])

  // Scroll to the item when selected, or when newly added (we auto-select newly added item)
  const item = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (showSelected) item.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [showSelected])

  return (
    <div
      ref={item}
      className="bg-white-solid-white-230-f3f4f4 box-border h-[68px] flex flex-row items-center justify-start py-3.5 px-4 relative gap-[0px_12px] text-left text-smi text-gray-solid-gray-910-4d4d4e font-mulish border-b-[1px] border-solid border-gray-solid-gray-360-d6d5d5 Tablet_768:py-2.5 Tablet_768:px-3 Tablet_768:box-border Tablet_600:gap-[0px_8px] Tablet_600:py-2.5 Tablet_600:px-3 Tablet_600:box-border"
      style={userTabStyle}
      onClick={onClick}
    >
      {showSelected && (
        <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-white-solid-white-100-ffffff box-border z-[0] border-r-[4px] border-solid border-blue-solid-blue-460-1471ff" />
      )}
      <img
        className="w-10 relative rounded-981xl h-10 z-[1] Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"
        alt=""
        src={profileImageId}
      />
      <div
        data-selected={showSelected}
        className="relative z-[2] data-[selected=true]:text-blue-solid-blue-460-1471ff Tablet_600:text-xs"
      >
        {userName}
      </div>
    </div>
  )
}

export default UserTab
