import { type FunctionComponent } from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";

export type DeleteConfirmationPopupType = {
  className?: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const DeleteConfirmationPopup: FunctionComponent<
  DeleteConfirmationPopupType
> = ({ className = "", onClose, onConfirm }) => {
  return (
    <div
      className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px] min-w-[280px] h-full max-w-full max-h-full text-center text-[20px] text-red-solid-red-550-cf2222 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <b className="self-stretch relative leading-[28px]">
          {LL0().masterMachine.deleteConfirmation()}
        </b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-black-solid-black-880-1d1d26">
          {LL0().masterMachine.doYouWantToDelete()}
        </div>
      </div>
      <div
        className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
          ref={makeRipple}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 box-border max-w-[100px] text-white-solid-white-100-ffffff"
          ref={makeRipple}
          onClick={() => {
            onConfirm?.()
            onClose?.()
          }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
