import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type TableManualKeyboardType = {
  buttonText?: string;

  /** Style props */
  tableManualKeyboardMinWidth?: CSSProperties["minWidth"];
  tableManualKeyboardMinHeight?: CSSProperties["minHeight"];
  tableManualKeyboardHeight?: CSSProperties["height"];
  keyboardFlex?: CSSProperties["flex"];
  keyboardPadding?: CSSProperties["padding"];
  keyboardGap?: CSSProperties["gap"];
  lineDivFlex?: CSSProperties["flex"];
  lineDivHeight?: CSSProperties["height"];
  lineDivFlex1?: CSSProperties["flex"];
  lineDivHeight1?: CSSProperties["height"];
  lineDivFlex2?: CSSProperties["flex"];
  lineDivHeight2?: CSSProperties["height"];
  lineDivFlex3?: CSSProperties["flex"];
  lineDivHeight3?: CSSProperties["height"];
  lineDivFlex4?: CSSProperties["flex"];
  lineDivHeight4?: CSSProperties["height"];
};

const TableManualKeyboard: FunctionComponent<TableManualKeyboardType> = ({
  buttonText,
  tableManualKeyboardMinWidth,
  tableManualKeyboardMinHeight,
  tableManualKeyboardHeight,
  keyboardFlex,
  keyboardPadding,
  keyboardGap,
  lineDivFlex,
  lineDivHeight,
  lineDivFlex1,
  lineDivHeight1,
  lineDivFlex2,
  lineDivHeight2,
  lineDivFlex3,
  lineDivHeight3,
  lineDivFlex4,
  lineDivHeight4,
}) => {
  const tableManualKeyboardStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: tableManualKeyboardMinWidth,
      minHeight: tableManualKeyboardMinHeight,
      height: tableManualKeyboardHeight,
    };
  }, [
    tableManualKeyboardMinWidth,
    tableManualKeyboardMinHeight,
    tableManualKeyboardHeight,
  ]);

  const keyboardStyle: CSSProperties = useMemo(() => {
    return {
      flex: keyboardFlex,
      padding: keyboardPadding,
      gap: keyboardGap,
    };
  }, [keyboardFlex, keyboardPadding, keyboardGap]);

  const lineDivStyle: CSSProperties = useMemo(() => {
    return {
      flex: lineDivFlex,
      height: lineDivHeight,
    };
  }, [lineDivFlex, lineDivHeight]);

  const lineDiv1Style: CSSProperties = useMemo(() => {
    return {
      flex: lineDivFlex1,
      height: lineDivHeight1,
    };
  }, [lineDivFlex1, lineDivHeight1]);

  const lineDiv2Style: CSSProperties = useMemo(() => {
    return {
      flex: lineDivFlex2,
      height: lineDivHeight2,
    };
  }, [lineDivFlex2, lineDivHeight2]);

  const lineDiv3Style: CSSProperties = useMemo(() => {
    return {
      flex: lineDivFlex3,
      height: lineDivHeight3,
    };
  }, [lineDivFlex3, lineDivHeight3]);

  const lineDiv4Style: CSSProperties = useMemo(() => {
    return {
      flex: lineDivFlex4,
      height: lineDivHeight4,
    };
  }, [lineDivFlex4, lineDivHeight4]);

  return (
    <div
      className="h-[258px] rounded-10xs bg-gray-solid-gray-180-ebecee flex flex-col items-start justify-start min-w-[414px] min-h-[200px] text-center text-3xl text-black-solid-black-1000-000000 font-mulish self-stretch"
      style={tableManualKeyboardStyle}
    >
      <div
        className="self-stretch flex-1 rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-center py-2 px-2.5 gap-[8px_0px]"
        style={keyboardStyle}
      >
        <div
          className="self-stretch flex-1 flex flex-row items-center justify-center gap-[0px_8px] Tablet_768:gap-[0px_6px] Tablet_600:gap-[0px_6px] Mobile_480:gap-[0px_4px]"
          style={lineDivStyle}
        >
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">1</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">2</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">3</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">4</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">5</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">6</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">7</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">8</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">9</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">0</div>
          </div>
        </div>
        <div
          className="self-stretch flex-1 flex flex-row items-center justify-center gap-[0px_8px] Tablet_768:gap-[0px_6px] Tablet_600:gap-[0px_6px] Mobile_480:gap-[0px_4px]"
          style={lineDiv1Style}
        >
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">Q</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">W</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">E</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">R</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">T</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">Y</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">U</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">I</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">O</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">P</div>
          </div>
        </div>
        <div
          className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-[33px] gap-[0px_8px] Tablet_768:gap-[0px_6px] Tablet_600:gap-[0px_6px] Mobile_480:gap-[0px_4px] Mobile_480:pl-[30px] Mobile_480:pr-[30px] Mobile_480:box-border"
          style={lineDiv2Style}
        >
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">A</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">S</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">D</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">F</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">G</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">H</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">J</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">K</div>
          </div>
          <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">L</div>
          </div>
        </div>
        <div
          className="self-stretch flex-1 flex flex-row items-center justify-center gap-[0px_22px] Tablet_768:gap-[0px_16px] Tablet_600:gap-[0px_12px] Mobile_480:gap-[0px_4px]"
          style={lineDiv3Style}
        >
          <div className="self-stretch rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7">
            <img
              className="w-[18.9px] relative h-[16.5px]"
              alt=""
              src="/shift.svg"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[0px_6px]">
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">Z</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">X</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">C</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">V</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">B</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">N</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">M</div>
            </div>
          </div>
          <div className="self-stretch rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7">
            <img
              className="w-[22.7px] relative h-[17px]"
              alt=""
              src="/delete-button1.svg"
            />
          </div>
        </div>
        <div
          className="self-stretch flex-1 gap-[0px_8px] grid grid-cols-[33%_34%_32%] [grid-row-gap:0px] [grid-column-gap:8px] text-base"
          style={lineDiv4Style}
        >
          <div className="flex flex-row items-start justify-start py-0 pr-1 pl-0 box-border gap-[0px_8px] min-w-[146px] relative Tablet_768:relative">
            <div className="self-stretch flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative tracking-[-0.32px] leading-[21px]">
                @
              </div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center text-3xl">
              <div className="relative">,</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center text-lg">
              <div className="relative">EN</div>
            </div>
          </div>
          <div className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative">
            <div className="relative tracking-[-0.32px] leading-[21px]">
              space
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 pr-0 pl-1 box-border gap-[0px_8px] min-w-[120px] relative text-3xl">
            <div className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[26px]">
              <div className="relative">.</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-blue-solid-blue-350-2196f3 shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[42px] text-base text-white-solid-white-100-ffffff">
              <div className="relative">{buttonText}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableManualKeyboard;
