const DEFAULT_CANVAS_WIDTH = 560
const DEFAULT_NUMBER_OF_CHARACTERS_PER_LINE = 48
const MAX_NO_ENTIRE_RECEIPT = 4
const DEFAULT_FONT_SIZE = 4
const ProductTypes = {
  FOOD: 'food',
  DRINK: 'drink',
  OTHER: 'other'
}
export {
  DEFAULT_CANVAS_WIDTH,
  DEFAULT_NUMBER_OF_CHARACTERS_PER_LINE,
  MAX_NO_ENTIRE_RECEIPT,
  DEFAULT_FONT_SIZE,
  ProductTypes
};
