import { deleteMany } from "@/data/data-utils";
import { DATA_INITED_2 } from './data-const'
import { Order, PaidOrder } from "@/data/Order";
import {
  InvoiceTypes,
  type MasterHandler, MasterHandlerCommand,
  type OrderStrip,
  TseMethod,
  OrderStatus
} from "@/pos/OrderType";
import { createOrder, resetOrder, stripOrder, stripPaidOrder } from "@/pos/logic/order-reactive.ts";
import { getMaxIdZ } from "@/data/MaxIdHub.ts";
import dayjs from "dayjs";
import { OnlineOrder } from "@/data/OnlineOrder.ts";
import { Eod } from "@/data/Eod.ts";
import { EodCache } from "@/data/EodCache.ts";
import { MaxId } from "@/data/MaxId.ts";
import { TxLogCollection } from "@/data/TxLog.ts";
import { TxRefundLogCollection } from "@/data/TxRefundLog.ts";
import { TxVoidLogCollection } from "@/data/TxVoidLog.ts";
import { TseTransaction } from "@/data/TseTransaction.ts";
import { OrderTseTemp } from "@/data/OrderTseTemp.ts";
import { PrintImage } from "@/data/PrintImage.ts";
import { printInvoice } from "@/react/Printer/print-invoice.ts";
import { doCancelOrder, payOrderHandler, paySplitHandler, preSplitHandler, rePrintInvoiceTse } from "@/tse/tse-init.ts";
import { dataLock } from "@/data/DataUtils.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import _ from "lodash";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { printKitchen } from "@/react/Printer/print-kitchen.ts";
import { now } from "@/pos/logic/time-provider.ts";

import type { RxChangeEvent } from "rxdb";
import delay from "delay";
import Queue from "queue";
import { paymentHook } from "@/react/utils/hooks.ts";
import {
  markPrintInvoice,
  mergeRasters,
  printInvoiceFromRaster2,
  printInvoiceStackStep1,
  printInvoiceStackStep2
} from "@/react/PaymentView/PrintStack.ts";
import uuid from "time-uuid";
import { loginUsers } from "./UserHub";
import { SrmTransactionLog } from "@/data/SrmTransactionLog.ts";
import { isMaster } from "@/lib/fetch-master.ts";
import { Voucher } from "@/data/Voucher.ts";
import { allConfig } from "@/extensions/firebase/useFirebase.ts";
import {
  CashbookHistories,
  CashbookTransactions
} from "@/data/Cashbook.ts";
import { createCashSale } from "@/react/CashbookView/cashbook-logic.ts";
import { isCashPayment } from "@/pos/logic/order-utils.ts";
import { getVDate } from "@/pos/orderUtils.ts";
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { OrderHandler } from "@/data/OrderHandler.ts";
import { printImageToConsole } from "@/shared/printImageToConsole"
import { handlePrintLabel } from "@/react/Printer/print-label.ts";

/**
 * use by order history
 */

export async function removeOrder(order: Order) {
  const _order = await Order.findOne({ selector: { _id: order._id } }).exec();
  await _order?.remove();
}

/**
 * Clone, cleanup and add to PaidOrder. Also recorded the order's commits
 */
export async function updateOrder(order: Order, getOnlyZ: boolean = false) {
  // await recordOrderCommits(order)
  //todo: upsert new order to PaidOrder Collection
  const stripOrder0 = stripPaidOrder(order);
  await removeOrder(order);
  // await Order.upsert(stripOrder0);
  //get maxId
  const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!), getOnlyZ);
  if (!getOnlyZ) stripOrder0.id = id;
  stripOrder0.z = z;
  await PaidOrder.upsert(stripOrder0);
  return stripOrder0;
  // await OrderZ.upsert({ _id: order._id, z: -1, vDate: order.vDate });
}

export async function assignZ(order: OrderStrip) {
  //todo: upsert new order to PaidOrder Collection
  const { z } = await getMaxIdZ(dayjs.unix(order.vDate!), true);
  order.z = z;
  // await OrderZ.upsert({ _id: order._id, z: -1, vDate: order.vDate });
}

export async function clearAllOrder(needReload: boolean = true) {
  await deleteMany(PaidOrder, {});
  await deleteMany(Order, {});
  await deleteMany(OnlineOrder, {});
  await deleteMany(Eod, {});
  await deleteMany(EodCache, {});
  await deleteMany(MaxId, {});
  await deleteMany(TxLogCollection, {});
  await deleteMany(TxRefundLogCollection, {});
  await deleteMany(TxVoidLogCollection, {});
  await deleteMany(TseTransaction, {});
  await deleteMany(OrderTseTemp, {});
  await deleteMany(PrintImage, {});
  await deleteMany(Voucher, {});
  await deleteMany(SrmTransactionLog, {});
  await deleteMany(CashbookTransactions, {});
  await deleteMany(CashbookHistories, {});
  // await deleteMany(TseConfig, {});
  localStorage.removeItem(DATA_INITED_2);
  if (needReload) location.reload();
}

//@ts-expect-error debug only
window.clearAllOrder = clearAllOrder;

export async function rePrintInvoice(order: Order, type: InvoiceTypes = InvoiceTypes.INVOICE) {
  //todo: print
  await printInvoice(order, type);
  await rePrintInvoiceTse(order);
}

interface OrderLocks {
  [k: string]: MultiAwaitLock
}

export const orderLocks: OrderLocks = {}

export async function onKitchenPrint(order: Order, postProcess: boolean = true, printed = true) {
  printKitchen(order, printed).then();
  if (postProcess) {
    await paymentHook.emit('postKitchenPrint', order);
  }
}


const processingOrders: { [k: string]: string } = {};

const queue = new Queue({ concurrency: 1, autostart: true });

function getCmdArgsCount(cmd?: MasterHandlerCommand) {
  switch (cmd) {
    case MasterHandlerCommand.preSplitHandler:
    case MasterHandlerCommand.paySplitHandler:
    case MasterHandlerCommand.rePrintInvoice:
    case MasterHandlerCommand.printInvoice:
    case MasterHandlerCommand.singleComplete:
    case MasterHandlerCommand.srmCancelRefund:
      return 1
    case MasterHandlerCommand.srmRecordClosingReceipt:
      return 2
    default:
      return 0
  }
}

async function getRestOfMasterHandlers(order: OrderStrip) {
  let processedIndex = 0;
  const orderHandler = await OrderHandler.findOne({selector: {_id: order._id}}).exec();
  if (orderHandler && !order.masterHandlers?.reset) {
    processedIndex = orderHandler.toMutableJSON().processedIndex || 0;
  }

  const cmds = order.masterHandlers!.cmd.slice(processedIndex) as MasterHandlerCommand[];
  const cmdUuids = order.masterHandlers!.cmdUuids!.slice(processedIndex);
  const firstCmdLength = 1 + getCmdArgsCount(cmds[0]!);
  processingOrders[`${order._id}`] = cmds[0]!;

  const restCmds = cmds.slice(firstCmdLength);
  const processedCmdUuids = cmdUuids.slice(0, firstCmdLength);
  const restCmdUuids = cmdUuids.slice(firstCmdLength);
  //old code: restCmds.length === 0 ? 'completed' : 'starting'
  const status = cmds.length > 0 ? 'starting' : 'completed';
  const afterStatus = restCmds.length === 0 ? 'completed' : 'starting';

  processedIndex += firstCmdLength;
  async function saveOrderHandler() {
    await OrderHandler.incrementalUpsert({_id: order._id, processedIndex, status: afterStatus});
  }
  const masterHandlers = { cmd: restCmds, status, cmdUuids: restCmdUuids } as MasterHandler;
  return { masterHandlers, processedCmdUuids, cmds, status, saveOrderHandler, cmdUuids, afterStatus };
}

const globalCmdUuids: string[] = [];

async function masterHandler(): Promise<void> {
  await dataLock.acquireAsync();
  OrderHandler.$.subscribe((data) => {
    if (data.documentData.status === 'completed') {
      orderLocks[data.documentData._id]?.release();
    }
  })

  const handler = async function (change: RxChangeEvent<OrderStrip>): Promise<void> {
    if (change.operation === 'DELETE') return;
    queue.push(async () => {
      const order = _.omitBy(_.omit(_.cloneDeep(change.documentData) as OrderStrip, ['_rev', '_meta']), _.isNil) as unknown as OrderStrip;
      const isPaidOrder = change.collectionName === 'paid_orders';
      const Collection = isPaidOrder ? PaidOrder : Order;
      const doc = await Collection.findOne({ selector: { _id: change.documentId } }).exec();
      if (order.masterHandlers && order.masterHandlers.cmd) {
        // if (getVDate(order.updatedAt) !== getVDateDayjs(dayjs(now())).unix()) return;
        const { masterHandlers, processedCmdUuids, status , afterStatus, saveOrderHandler, cmds, cmdUuids} = await getRestOfMasterHandlers(order as OrderStrip);
        /*if (status === 'completed') {
          orderLocks[order._id]?.release();
        } else */
        if (isMaster() && status === 'starting') {
          //handle here
          if (cmdUuids!.find((id: string) => globalCmdUuids.includes(id))) {
            return;
          }
          globalCmdUuids.push(...processedCmdUuids);
          let mutate = true;
          // await doc?.incrementalPatch({ masterHandlers: { cmd: masterHandlers.cmd, status: 'processing' } });
          let _order = createOrder(order);
          if (cmds[0] === MasterHandlerCommand.cmd1) {
            await delay(500);
          } else if (cmds[0] === MasterHandlerCommand.cmd2) {
            await delay(500);
          } else if (cmds[0] === MasterHandlerCommand.onKitchenPrint) {
            _order = order.commits ? createOrder(resetOrder(order)) : createOrder(order);
            await onKitchenPrint(_order)
            //luu ra 1 collection rieng mapping cua tse, commit them vao, khi nao xong thi merge vao ?
            if (!tseConfig0().tseEnable) {
              mutate = false;
            }
          } else if (cmds[0] === MasterHandlerCommand.onKitchenAndLabelPrint) {
            _order = order.commits ? createOrder(resetOrder(order)) : createOrder(order);
            await onKitchenPrint(_order)
            //luu ra 1 collection rieng mapping cua tse, commit them vao, khi nao xong thi merge vao ?
            if (!tseConfig0().tseEnable) {
              mutate = false;
            }
            await handlePrintLabel(order)
          } else if (cmds[0] === MasterHandlerCommand.onKitchenPrintFastCheckout) {
            _order = order.commits ? createOrder(resetOrder(order)) : createOrder(order);
            await onKitchenPrint(_order, false)
            if (!tseConfig0().tseEnable) {
              mutate = false;
            }
          } else if (cmds[0] === MasterHandlerCommand.printInvoice) {
            console.time('printInvoice');
            await printInvoice(_order, parseInt(cmds[1] ?? '') as InvoiceTypes);
            console.timeEnd('printInvoice');
          } else if (cmds[0] === MasterHandlerCommand.preSplitHandler) {
            _order = createOrder(resetOrder(order));
            await preSplitHandler(_order, cmds[1] as unknown as TseMethod);
          } else if (cmds[0] === MasterHandlerCommand.payOrderHandler) {
            await payOrderHandler(_order);
          } else if (cmds[0] === MasterHandlerCommand.paySplitHandler) {
            await paySplitHandler(_order, cmds[1] as unknown as TseMethod);
            if (isCashPayment(_order)) {
              await createCashSale(_order);
            }
          } else if (cmds[0] === MasterHandlerCommand.doCancelOrder) {
            delete order.masterHandlers;
            const cancelOrder = await doCancelOrder(order as Order);
            mutate = false;
            if (isCashPayment(cancelOrder)) {
                await createCashSale(cancelOrder);
            }
            return await doc?.incrementalPatch({ masterHandlers });
          } else if (cmds[0] === MasterHandlerCommand.rePrintInvoice) {
            const type = parseInt(cmds[1] ?? '') as InvoiceTypes;
            delete order.masterHandlers;
            await rePrintInvoice(order as Order, type);
            mutate = false;
          } else if (cmds[0] === MasterHandlerCommand.singleComplete) {
            //todo: kitchen print
            const _cmds = (cmds[1] ?? '').split(',');
            _order = order.commits ? createOrder(resetOrder(order as any)) : _order;
            const lock = new MultiAwaitLock(true);
            await Promise.all([(async () => {
              if (_cmds.includes(MasterHandlerCommand.payOrderHandler)) {
                await payOrderHandler(_order);
              }
              await lock.release();
            })(), (async () => {
              if (_cmds.includes(MasterHandlerCommand.onKitchenPrintFastCheckout)) {
                await onKitchenPrint(_order, false)
              }

              const invoiceType = getInvoiceType();
              const raster1 = await printInvoiceStackStep1(_order, invoiceType);
              // await printInvoiceFromRaster2(raster1, { cut: false });
              await lock.acquireAsync();

              if (_cmds.includes(MasterHandlerCommand.printInvoice)) {
                const raster2 = await printInvoiceStackStep2(_order, invoiceType);
                const r = mergeRasters([raster1, raster2]);
                await printInvoiceFromRaster2(r, {
                  cut: true,
                  openCashDrawer: allConfig['auto_open_cashdrawer']?.asBoolean(),
                  prependRaster: raster1,
                  virtualPrinter: true
                },
                { orderId: _order._id, invoiceType: invoiceType });
                //todo: package printInvoice
                // await printInvoice(_order, parseInt(order.masterHandlers!.cmd[1]) as InvoiceTypes);
                await printImageToConsole(`Order #${order.id} (${order._id})`, r)
              }

              markPrintInvoice(_order!, invoiceType);

              if (isCashPayment(_order)) {
                await createCashSale(_order);
              }

              function getInvoiceType() {
                if (!_cmds.includes(MasterHandlerCommand.printInvoice)) return InvoiceTypes.NONE;
                const invoiceType = parseInt(_cmds[_cmds.indexOf(MasterHandlerCommand.printInvoice) + 1]) as InvoiceTypes;
                return invoiceType;
              }
            })()])
          }
          _order.masterHandlers!.status = afterStatus;
          if (mutate) {
            const tripOrder = isPaidOrder ? stripPaidOrder(_order) : stripOrder(_order);
            try {
              await doc?.patch(tripOrder);
            } catch (e) {
              const _doc = await Collection.findOne({ selector: { _id: change.documentId } }).exec();
              await _doc?.incrementalPatch(tripOrder);
            }
          }

          if (masterHandlers?.status === 'completed') {
            delete processingOrders[`${order._id}`];
          }
          await saveOrderHandler();
        }
      }
    })
  }

  OnlineOrder.$.subscribe(handler);
  Order.$.subscribe(handler);
  PaidOrder.$.subscribe(handler);
}

masterHandler().then();

export async function updateInProgressOrder(order: Order) {
  const stripOrder0 = stripOrder(order);
  await Order.upsert(stripOrder0);
}

export const lockCondition: { v: () => boolean } = { v: () => false };

function getCmdUuids(cmd: string[]) {
  return cmd.map(() => uuid());
}

export async function runMasterHandler(order: Order, cmd: MasterHandler['cmd'], forceMasterHandler: boolean = false) {
  // Save current user to order, before forward to master. TODO: redesign this schema
  order.modifiedBy = loginUser()?.name
  const stripOrder0 = stripOrder(order);
  stripOrder0.masterHandlers = _.defaults(stripOrder0.masterHandlers, { cmd: [], cmdUuids: [], status: 'completed' });
  if (cmd.length > 0 && (!forceMasterHandler && tseConfig0().tseEnable || forceMasterHandler)) {
    stripOrder0.masterHandlers.cmd.push(...cmd);
    stripOrder0.masterHandlers.cmdUuids!.push(...getCmdUuids(cmd));
    stripOrder0.masterHandlers.status = 'starting';
  }
  await Order.upsert(stripOrder0);
  if (lockCondition.v()) {
    orderLocks[stripOrder0._id] = new MultiAwaitLock(true);
    await orderLocks[stripOrder0._id].acquireAsync();
    delete orderLocks[stripOrder0._id];
  }
}

type OrderType = typeof PaidOrder | typeof Order | typeof OnlineOrder;

export function runMasterHandlerPaidOrderFactory(order: Order, cmd: MasterHandler['cmd']) {
  let stripOrder0: OrderStrip;
  let Collection: OrderType = PaidOrder;

  return {
    assignInfoToOrder,
    _removeOrder,
    processCommandOverMaster,
    upsertToPaidOrder,
    waitForMasterProcess,
    runFull,
    assignUserToOrder,
    runOnlineOrder
  }

  function useCollection(col: OrderType) {
    Collection = col;
  }

  // use for kitchen print
  async function runOnlineOrder(col: OrderType = PaidOrder) {
    useCollection(col);
    await assignUserToOrder();
    await processCommandOverMaster();
    await upsertToPaidOrder();
    await waitForMasterProcess();
  }

  async function runFull(getOnlyZ: boolean = false, col: OrderType = PaidOrder) {
    useCollection(col);
    await assignInfoToOrder(getOnlyZ);
    await processCommandOverMaster();
    await upsertToPaidOrder();
    await _removeOrder();
    await waitForMasterProcess();
  }

  async function assignUserToOrder() {
    order.modifiedBy = loginUser()?.name
  }

  async function assignInfoToOrder(getOnlyZ: boolean = false) {
    // Save current user to order, before forward to master. TODO: redesign this schema
    order.modifiedBy = loginUser()?.name
    order.users = loginUsers();

    order.date = dayjs(now()).unix();
    order.vDate = getVDate(order.date);
    stripOrder0 = stripPaidOrder(order);

    const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!), getOnlyZ);
    if (!getOnlyZ) stripOrder0.id = id;
    stripOrder0.z = z;
    const eod = await Eod.findOne({ selector: { z: z } }).exec();
    stripOrder0.eod = eod?._id;
  }

  async function _removeOrder() {
    await removeOrder(order);
  }

  async function processCommandOverMaster() {
    stripOrder0.masterHandlers = _.defaults(stripOrder0.masterHandlers, { cmd: [], cmdUuids: [], status: 'completed' });
    if (cmd.length > 0) {
      stripOrder0.masterHandlers.cmd.push(...cmd);
      stripOrder0.masterHandlers.cmdUuids?.push(...getCmdUuids(cmd));
      stripOrder0.masterHandlers.status = 'starting';
    }
  }

  async function upsertToPaidOrder() {
    await Collection.upsert({
      ...stripOrder0,
      // Since we inserting to Paid Order, convert "In Progress", status to "Cancelled"
      status: stripOrder0.status === OrderStatus.IN_PROGRESS ? OrderStatus.CANCELLED_BEFORE_PAID : stripOrder0.status,
    })
  }

  async function waitForMasterProcess() {
    if (lockCondition.v()) {
      orderLocks[stripOrder0._id] = new MultiAwaitLock(true);
      await orderLocks[stripOrder0._id].acquireAsync();
      delete orderLocks[stripOrder0._id];
    }
  }
}

export async function runMasterHandlerPaidOrder(order: Order, cmd: MasterHandler['cmd']) {
  // Save current user to order, before forward to master. TODO: redesign this schema
  order.modifiedBy = loginUser()?.name

  order.date = dayjs(now()).unix();
  order.vDate = getVDate(order.date);
  
  // await recordOrderCommits(order)
  const stripOrder0 = stripPaidOrder(order);
  await removeOrder(order);
  const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!));
  stripOrder0.id = id;
  stripOrder0.z = z;
  const eod = await Eod.findOne({ selector: { z: z } }).exec();
  stripOrder0.eod = eod?._id;

  stripOrder0.masterHandlers = _.defaults(stripOrder0.masterHandlers, { cmd: [], cmdUuids: [], status: 'completed' });
  if (cmd.length > 0) {
    stripOrder0.masterHandlers.cmd.push(...cmd);
    stripOrder0.masterHandlers.cmdUuids!.push(...getCmdUuids(cmd));
    stripOrder0.masterHandlers.status = 'starting';
  }

  await PaidOrder.upsert(stripOrder0);
  if (lockCondition.v()) {
    orderLocks[stripOrder0._id] = new MultiAwaitLock(true);
    await orderLocks[stripOrder0._id].acquireAsync();
    delete orderLocks[stripOrder0._id];
  }

}

export async function runMasterHandlerAfterPaid(order: Order, cmd: MasterHandler['cmd']) {
  // Save current user to order, before forward to master. TODO: redesign this schema
  order.modifiedBy = loginUser()?.name

  if (tseConfig0().tseEnable || isQuebecSrmEnabled()) {
    order.masterHandlers = {
      cmd, status: 'starting', cmdUuids: getCmdUuids(cmd), reset: true
    }
  }
  // await recordOrderCommits(order)
  await PaidOrder.upsert(stripPaidOrder(order));

  if (lockCondition.v()) {
    orderLocks[order._id] = new MultiAwaitLock(true);
    await orderLocks[order._id].acquireAsync();
    delete orderLocks[order._id];
  }
}
