import {type FunctionComponent, memo} from 'react'
import UserSettingLeft from './UserSettingLeft'
import UserSettingRight from './UserSettingRight'

const UserSettingPlugin: FunctionComponent = () => {
  return (
    <div className="edit-user-screen w-full h-full relative bg-white-solid-white-100-ffffff flex flex-row items-start justify-start">
      <UserSettingLeft />
      <UserSettingRight />
    </div>
  )
}

export default memo(UserSettingPlugin)
