import { FunctionComponent, useState } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment, Autocomplete, TextField,
} from "@mui/material";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { inputController0 } from "@/react/core/Input.tsx";
import {
  convertCityNameToCountryCity,
  convertCountryCityToCityName,
  onAutofillTaxByRegion
} from "@/react/PaymentSettingView/PaymentSettingView.tsx";
import defaultTaxesByCity from "@/react/PaymentSettingView/defaultTaxesByCity.json"
import _ from "lodash";

export type AutofillTaxPopupType = {
  onClose?: () => void;
};



const AutofillTaxPopup: FunctionComponent<AutofillTaxPopupType> = ({
  onClose,
}) => {
  const [cityOption, setCityOption] = useState<{
    country: string;
    city: string;
  } | null>(null)
  return (
    <div className="w-full relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-9 px-3 pb-0 box-border gap-[24px_0px] min-w-[580px] h-full max-w-full max-h-full text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish height_414:h-auto">
      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-3 gap-[0px_17px] z-[0]">
        <b className="relative">Select Area:</b>
        <FormControl
          className="flex-1"
          variant="outlined"
        >
          <InputLabel color="primary" />
          <Autocomplete
            getOptionKey={convertCountryCityToCityName}
            isOptionEqualToValue={(option, value) => convertCountryCityToCityName(option) === convertCountryCityToCityName(value)}
            options={_.keys(defaultTaxesByCity).map(convertCityNameToCountryCity)}
            value={cityOption}
            onChange={(_e, newValue) => setCityOption(newValue)}
            getOptionLabel={(option) => `${option?.city}, ${option?.country}`}
            groupBy={(option) => option.country}
            renderInput={(params) => <TextField
              {...params}
              sx={{ "& .MuiInputBase-root": { height: "37px" } }}
              size={"small"}
            />}
          />
          <FormHelperText />
        </FormControl>
      </div>
      <TableManualKeyboard
        value={""}
        inputController={inputController0}
        onEnter={() => {
          if (!cityOption) return;
          onAutofillTaxByRegion(convertCountryCityToCityName(cityOption) as keyof typeof defaultTaxesByCity).then();
          onClose?.();
        }}
        // for refreshing keyboard since we used useCallback() for onPointerDown
        key={convertCountryCityToCityName(cityOption)}
      />
      <img
        className="w-[21px] absolute !m-[0] top-[10px] right-[10px] h-[21px] object-cover cursor-pointer z-[2]"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default AutofillTaxPopup;
