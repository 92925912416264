import {computed} from "@/react/core/reactive.ts";
import _ from "lodash";
import { manualOrders0 } from '@/react/FloorPlanView/FloorPlanView.tsx'

export const manualTables = computed(() =>  {
  return _(manualOrders0()).filter(o => o.manual).map(o => o.table).uniq().value()
})

export function tableExists({tableNameInput}: {tableNameInput: string}) {
  return manualTables().includes(_.trim(tableNameInput));
}