import { convertDocuments, DocDeepSignal } from "@/data/data-utils";
import { TaxCategory } from "@/data/TaxCategory";
import { dataLock } from "@/data/DataUtils.ts";
import { computed, signal, useAsyncEffect } from "@/react/core/reactive.ts";
import _ from 'lodash';
import { map, tap } from "rxjs";
import debug from "debug";

const log = debug('data:taxCategory')

export const [taxCategories0, setTaxCategories0] = signal<Array<DocDeepSignal<TaxCategory>>>([]);
export const taxCategoriesVM = computed(() => {
  return taxCategories0();
})

window.taxCategories0 = taxCategories0;

let dataLoaded = false

export const makeTaxCategoriesAvailable = () => {
  useAsyncEffect(async () => {
    log('⚡️ Loading taxCategory data...')

    if (dataLoaded) return
    await dataLock.acquireAsync()
    const sub = TaxCategory.find()
      .$.pipe(
        map(a => convertDocuments<TaxCategory>(a, true, [], {
          preSaved: taxCategory => {
            if (taxCategory.type === 'combo') {
              return {
                ...taxCategory,
                value: _.sumBy(taxCategory.components, comp => +(comp.value || 0))
              }
            }
            return taxCategory;
          }
        })),
        tap(a => setTaxCategories0(a)),
        tap(a => log('⚡️ TaxCategory data loaded', a)),
      )
      .subscribe()
    dataLoaded = true

    return () => sub.unsubscribe()
  })
};

//used to prevent duplicating taxes in edit menu, so that this won't affect edit flow
export const dineInTaxCategories0 = computed(() => taxCategories0().filter(tax => tax.type === 'food:dineIn' || tax.type === "drink:dineIn"));
export const takeAwayTaxCategories0 = computed(() => taxCategories0().filter(tax => tax.type === 'drink:takeAway' || tax.type === 'food:takeAway'));

export const getTotalTaxFromComponents = (components: TaxCategory['components']) => {
  return _.sumBy(components, comp => +(comp.value || 0))
}

