import { VIEW__MODIFIER } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import { makeRipple } from "@/react/core/ripple-utils";
import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

const ModifierListToolbar: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-2.5 px-5 text-center text-mini text-white-solid-white-100-ffffff font-mulish-button-name-bold lg:pl-5 lg:pr-5 lg:box-border md:pl-4 md:pr-4 md:box-border sm:py-2 sm:px-3.5 sm:box-border mq768:py-2 mq768:px-3 mq768:box-border mq600:py-2 mq600:px-2.5 mq600:box-border mq480:py-2 mq480:px-2.5 mq480:box-border">
      <div className="h-10 flex flex-row items-start justify-start sm:h-9 mq768:h-8 mq768:gap-[6px] mq600:h-7 mq600:gap-[4px] mq480:h-7">
        <button ref={makeRipple} onClick={VIEW__MODIFIER.onCreate} className="self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-2.5">
          <b className="relative sm:text-sm mq768:text-smi mq600:text-smi mq480:text-xs">
            + {LL0().editOnlineMenu.addNewOption()}
          </b>
        </button>
      </div>
    </section>
  );
};

export default ModifierListToolbar;
