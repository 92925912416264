import dayjs from "dayjs";
import { formatCurrency } from "@/shared/utils";
import type { Order } from "@/data/Order.ts";
import type { OrderItem, OrderPayment } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { Bonpos } from "@/tse/dsfinv/dsfinvModel2.ts";
import { getPayment } from "@/tse/makeProcessDataLib.ts";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";

const Bonkopf_Zahlarten = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse",
    default: Bonpos.Z_KASSE_ID.default
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses",
    mapping: Bonpos.Z_ERSTELLUNG.mapping.replace('parent.', ''),
    format: d => dayjs(d).toISOString()
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses",
    mapping: Bonpos.Z_NR.mapping.replace('parent.', '')
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID",
    mapping: Bonpos.BON_ID.mapping.replace('parent.', '')
  },
  ZAHLART_TYP: {
    type: "string",
    maxLength: 25,
    hint: "Typ der Zahlart",
    //todo: check again
    mapping: function (e) {
      if (e.paymentOption === 'cash') return 'Bar';
      if (e.paymentOption === 'card') return 'Unbar';
      return 'Unbar'
    }
  },
  ZAHLART_NAME: {
    type: "string",
    maxLength: 60,
    hint: "Name der Zahlart"
  },
  ZAHLWAEH_CODE: {
    type: "string",
    maxLength: 3,
    hint: "Währungscode",
    regex: "^[A-Z]{3}$",
    default: 'EUR'
  },
  ZAHLWAEH_BETRAG: {
    type: "number",
    places: 2,
    hint: "Betrag in Fremdwährung",
    default: 0,
    format: (n: number) => formatCurrency(n)
  },
  BASISWAEH_BETRAG: {
    type: "number",
    places: 2,
    hint: "Betrag in Basiswährung (i.d.R. EUR)",
    format: (n: number) => formatCurrency(n)
    //todo fill brutto sum here
  }
}
export function Bonkopf_ZahlartenFactory(order: Order, eod: Eod){
  let payment: OrderPayment;
  let voucherItem: OrderItem;
  let voucherProcess: boolean = false;

  return build();
  function build() {
    const rows = []
    for (const _payment of order.payments) {
      payment = _payment;
      rows.push(buildSingle());
    }
    voucherProcess = true;
    for (const _item of order.items) {
      if (!_item.isVoucher) continue;
      voucherItem = _item;
      rows.push(buildSingle());
    }
    return rows;
  }

  function buildSingle(){
    return{
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      ZAHLART_TYP: ZAHLART_TYP(),
      ZAHLART_NAME: ZAHLART_NAME(),
      ZAHLWAEH_CODE: ZAHLWAEH_CODE(),
      ZAHLWAEH_BETRAG: ZAHLWAEH_BETRAG(),
      BASISWAEH_BETRAG: BASISWAEH_BETRAG()
    }
  }
  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function ZAHLART_TYP() {
    // hint: "Typ der Zahlart"
    if (voucherProcess) return 'Unbar';
    return getPayment(payment);
  }

  function ZAHLART_NAME() {
    // hint: "Name der Zahlart"
    return voucherProcess ? 'Voucher' : payment.type;
  }

  function ZAHLWAEH_CODE() {
    // hint: "Währungscode"
    return 'EUR';
  }

  function ZAHLWAEH_BETRAG() {
    // hint: "Betrag in Fremdwährung"
    return '';
  }

  function BASISWAEH_BETRAG() {
    // hint: "Betrag in Basiswährung (i.d.R. EUR)"
    return voucherProcess ? voucherItem.vSum! : payment.value!;
  }
}