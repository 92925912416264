import { type FunctionComponent, useState, useCallback } from "react";
import InventoryDateChoosingPo from "../InventoryDateChoosingPo";
import PortalPopup from "../PortalPopup";
import {
  filterDateStockHistory, inventoryActions,
  inventoryActions0, inventoryActionsPage, isReachingEnd, isValidatingInventoryActions, setInventoryActionsPage,
  setIsValidatingInventoryActions
} from '@/data/InventoryActionHub.ts'
import { inventory0 } from '@/react/InventoryView/InventoryView.tsx'
import dayjs from 'dayjs'
import {LL0} from "@/react/core/I18nService.tsx";
import _ from 'lodash'
import InfiniteScroll from '@order-history/InfiniteScrollContainer.tsx'
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

type StockHistoryFormType = {
  showStockHistoryForm?: boolean;
};

const StockHistoryForm: FunctionComponent<StockHistoryFormType> = ({
  showStockHistoryForm,
}) => {
  const [isInventoryDateChoosingPoOpen, setInventoryDateChoosingPoOpen] =
    useState(false);

  const openInventoryDateChoosingPo = useCallback(() => {
    setInventoryDateChoosingPoOpen(true);
  }, []);

  const closeInventoryDateChoosingPo = useCallback(() => {
    setInventoryDateChoosingPoOpen(false);
  }, []);

  return (
    <>
      {showStockHistoryForm && (
        <div className="self-stretch flex-1 overflow-hidden flex flex-col items-center justify-center py-2 px-0 gap-[8px] text-left text-sm text-black-solid-black-1000-000000 font-mulish">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <div className="self-stretch relative text-mini">
              <b>{inventory0()?.product?.name}</b>
              <span className="font-extrabold">{` `}</span>
              <span className="font-semibold">({LL0().product.unit()}: {inventory0()?.unit})</span>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-2 gap-[4px]">
              <div className="relative">{LL0().inventory.currentStock()}:</div>
              <div className="relative text-mini font-semibold">{inventory0()?.stock}</div>
            </div>
            <div
              className="flex flex-row items-center justify-start gap-[4px] cursor-pointer"
              onClick={openInventoryDateChoosingPo}
            >
              <img
                className="relative w-8 h-8 object-cover sm:w-[34px] sm:h-[34px] Tablet_768:w-[30px] Tablet_768:h-[30px]"
                alt=""
                src="/iconcalendar-icon@2x.png"
              />
              <div className="relative [text-decoration:underline] font-semibold Tablet_768:text-smi Tablet_600:text-smi">
                {/*April 03, 2022*/}
                {filterDateStockHistory() ?
                  filterDateStockHistory()?.type ? _.get(LL0().onlineOrder, filterDateStockHistory()?.type || 'today')?.()
                    : `${dayjs(filterDateStockHistory()?.from).format('MMMM D, YYYY')} - ${dayjs(filterDateStockHistory()?.to).format('MMMM D, YYYY')}`
                  : dayjs().format('MMMM D, YYYY')
                }

              </div>
            </div>
          </div>
          <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-3">
            <table className="self-stretch rounded-md shrink-0 border-spacing-[0] border-[2px] border-solid border-gray-solid-gray-164-e1e1e1">
              <thead>
              <tr className="bg-lightslategray">
                <th className="relative pr-0 pb-0">
                  <div className="w-full flex flex-row items-center justify-between py-[11px] px-2 box-border min-w-[128px] max-w-[157px] h-full">
                      <div className="flex-1 relative text-mini font-semibold font-mulish text-white-solid-white-100-ffffff text-left Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-smi Tablet_600:hover:text-smi Mobile_480:text-smi Mobile_480:hover:text-smi height_414:text-smi height_414:hover:text-smi">
                        {LL0().orderHistory.dateFilterDialog.thisMonth()}
                      </div>
                    </div>
                  </th>
                  <th className="relative pr-0 pb-0">
                    <div className="w-full flex flex-row items-center justify-between py-[11px] px-2 box-border max-w-[92px] h-full">
                      <div className="flex-1 relative text-mini font-semibold font-mulish text-white-solid-white-100-ffffff text-left Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-smi Tablet_600:hover:text-smi Mobile_480:text-smi Mobile_480:hover:text-smi height_414:text-smi height_414:hover:text-smi">{`Added `}</div>
                    </div>
                  </th>
                  <th className="relative pr-0 pb-0">
                    <div className="w-full flex flex-row items-center justify-between py-[11px] px-2 box-border max-w-[92px] h-full">
                      <div className="flex-1 relative text-mini font-semibold font-mulish text-white-solid-white-100-ffffff text-left flex hover:font-semibold hover:font-mulish hover:text-mini hover:text-left hover:text-white-solid-white-100-ffffff hover:flex hover:flex-1 Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-smi Tablet_600:hover:text-smi Mobile_480:text-smi Mobile_480:hover:text-smi height_414:text-smi height_414:hover:text-smi">
                        {LL0().common.removed()}
                      </div>
                    </div>
                  </th>
                  <th className="relative pb-0">
                    <div className="flex flex-row items-center justify-between py-[11px] px-2 box-border w-full h-full">
                      <div className="flex-1 relative text-mini font-semibold font-mulish text-white-solid-white-100-ffffff text-left flex hover:font-semibold hover:font-mulish hover:text-mini hover:text-left hover:text-white-solid-white-100-ffffff hover:flex hover:flex-1 Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-smi Tablet_600:hover:text-smi Mobile_480:text-smi Mobile_480:hover:text-smi height_414:text-smi height_414:hover:text-smi">
                        {LL0().inventory.reason()}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
              {/*{inventoryActions0()*/}
              {/*  .filter(inventoryAction => inventoryAction.inventoryId === inventory0()?._id)*/}
              {/*  .filter(inventoryAction => filterDateStockHistory() ? filterDateStockHistory().from / 1000 <= inventoryAction.date && filterDateStockHistory().to / 1000 >= inventoryAction.date : true)*/}
              {/*  .map(({amount, reason, date, _id}) => (*/}
              {/*    <tr className="bg-gray-solid-gray-180-ebecee odd:bg-white"*/}
              {/*        key={_id}*/}
              {/*    >*/}
              {/*      <td className="relative pr-0 pb-0">*/}
              {/*        <div*/}
              {/*          className="w-full flex flex-row items-start justify-between py-2.5 pr-2 pl-0 box-border min-w-[128px] max-w-[157px] h-full">*/}
              {/*          <div*/}
              {/*            className="flex-1 relative text-sm font-semibold font-mulish text-black-solid-black-600-424242 whitespace-pre-wrap text-left Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-smi Tablet_600:hover:text-smi Mobile_480:text-smi Mobile_480:hover:text-smi height_414:text-smi height_414:hover:text-smi">*/}
              {/*            {dayjs.unix(date).format("DD/MM/YYYY HH:mm")}*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*      <td className="relative pr-0 pb-0">*/}
              {/*        <div*/}
              {/*          className="w-full flex flex-row items-start justify-between py-2.5 pr-2 pl-0 box-border max-w-[92px] h-full">*/}
              {/*          <div*/}
              {/*            className="flex-1 relative text-base font-semibold font-mulish text-green-solid-green-800-4e8700 text-left Mobile_480:text-sm Mobile_480:hover:text-sm">*/}
              {/*            {amount >= 0 ? `+${ amount }` : ""}*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*      <td className="relative pr-0 pb-0">*/}
              {/*        <div*/}
              {/*          className="w-full flex flex-row items-start justify-between py-2.5 pr-2 pl-0 box-border max-w-[92px] h-full">*/}
              {/*          <div*/}
              {/*            className="flex-1 relative text-base font-semibold font-mulish text-orange-solid-orange-900-bb6d00 text-left Mobile_480:text-sm Mobile_480:hover:text-sm" >*/}
              {/*            {amount < 0 ? amount : ""}*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*      <td className="relative pb-0">*/}
              {/*        <div className="flex flex-row items-start justify-between py-2.5 px-0 box-border w-full h-full">*/}
              {/*          <i*/}
              {/*            className="flex-1 relative text-sm [display:-webkit-inline-box] font-mulish text-black-solid-black-600-424242 text-left overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical] Mobile_480:text-smi Mobile_480:hover:text-smi">*/}
              {/*            {reason}*/}
              {/*          </i>*/}
              {/*        </div>*/}
              {/*      </td>*/}
              {/*    </tr>*/}
              {/*  ))}*/}
              <InfiniteScroll
                data={inventoryActions()}
                increaseSize={() => {
                  setIsValidatingInventoryActions(true)
                  setInventoryActionsPage(p => p + 1)
                }}
                isReachingEnd={isReachingEnd()}
                isAutoInfinite={true}
                isValidating={isValidatingInventoryActions()}
                loadingIndicator={`${LL0().ui.loading()}...`}
                endingIndicator={LL0().inventory.noStockHistory()}
                indicatorWrapper={({children}) => (
                  <tr>
                    <td colSpan={4} className="text-center text-mulish font-semibold relative pr-0 pb-0">
                      {children}
                    </td>
                  </tr>
                )}
              >
                {({ amount, reason, date, _id }) => (
                  <tr className="odd:bg-gray-solid-gray-180-ebecee bg-white"
                      key={_id}
                  >
                    <td className="relative pr-0 pb-0">
                      <div
                        className="w-full flex flex-row items-start justify-between py-2.5 pr-2 pl-0 box-border min-w-[128px] max-w-[157px] h-full">
                        <div
                          className="flex-1 relative text-sm font-semibold font-mulish text-black-solid-black-600-424242 whitespace-pre-wrap text-left Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-smi Tablet_600:hover:text-smi Mobile_480:text-smi Mobile_480:hover:text-smi height_414:text-smi height_414:hover:text-smi">
                          {dayjs.unix(date).format(`${LL2().dates.dateFormat()} ${LL2().dates.timeFormat()}`)}
                        </div>
                      </div>
                    </td>
                    <td className="relative pr-0 pb-0">
                      <div
                        className="w-full flex flex-row items-start justify-between py-2.5 pr-2 pl-0 box-border max-w-[92px] h-full">
                        <div
                          className="flex-1 relative text-base font-semibold font-mulish text-green-solid-green-800-4e8700 text-left Mobile_480:text-sm Mobile_480:hover:text-sm">
                          {amount >= 0 ? `+${amount}` : ''}
                        </div>
                      </div>
                    </td>
                    <td className="relative pr-0 pb-0">
                      <div
                        className="w-full flex flex-row items-start justify-between py-2.5 pr-2 pl-0 box-border max-w-[92px] h-full">
                        <div
                          className="flex-1 relative text-base font-semibold font-mulish text-orange-solid-orange-900-bb6d00 text-left Mobile_480:text-sm Mobile_480:hover:text-sm">
                          {amount < 0 ? amount : ''}
                        </div>
                      </div>
                    </td>
                    <td className="relative pb-0">
                      <div className="flex flex-row items-start justify-between py-2.5 px-0 box-border w-full h-full">
                        <i
                          className="flex-1 relative text-sm [display:-webkit-inline-box] font-mulish text-black-solid-black-600-424242 text-left overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical] Mobile_480:text-smi Mobile_480:hover:text-smi">
                          {reason}
                        </i>
                      </div>
                    </td>
                  </tr>
                )}
              </InfiniteScroll>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {isInventoryDateChoosingPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryDateChoosingPo}
        >
          <InventoryDateChoosingPo onClose={closeInventoryDateChoosingPo} />
        </PortalPopup>
      )}
    </>
  );
};

export default StockHistoryForm;
