import {convertDocuments, type DocDeepSignal} from "@/data/data-utils";
import { Modifier, ModifierType } from "@/data/Modifier";
import {type Accessor} from "solid-js/types/reactive/signal";
import {Product} from "@/data/Product";
import { signal, computed, effectOn } from "@/react/core/reactive";
import _ from 'lodash';
import { generateSignalDataAccess } from "@/react/utils/generateSignalDataAccess";
import { product0 } from "@/react/EditMenuView/EditMenuView.tsx";
import { migrateVariants } from "@/data/fix/fixVariants.ts";

import { posSettingLock } from "@/data/PosSettingsSignal.ts";

export const [modifiers0, makeModifiersAvailable] = generateSignalDataAccess(Modifier, { convertOpts: { autoSave: true } })
export const dineInModifiers = computed(() => modifiers0().filter(m => (_.isEmpty(m.menus) && (!m?.useType || m.useType === ModifierType.MODIFIER))))
export const [showAllVariants, setShowAllVariants] = signal<boolean>(false)
export const variantModifiers = computed(() => {
  if (showAllVariants())
    return modifiers0().filter(m => (_.isEmpty(m.menus) && m.useType === ModifierType.VARIANT))
  return modifiers0().filter(m => (_.isEmpty(m.menus)
    && m.useType === ModifierType.VARIANT &&
    (m?.products && m?.products?.includes((product0()?._id || '')) || product0()?.activePopupModifierGroup?.includes(m._id)))
  )
})

export const addModifierFactory = (product0: Accessor<Product | undefined>, modifier: DocDeepSignal<Modifier>) => {
  return (val: boolean) => {
    if (!product0()) return;
    if (!product0()!.activePopupModifierGroup) product0()!.activePopupModifierGroup = [];
    const activePopupModifierGroup = product0()!.activePopupModifierGroup!;
    if (val && !activePopupModifierGroup.includes(modifier._id!)) {
      activePopupModifierGroup.push(modifier._id!);
      console.log('push modifier');
      if (modifier?.useType === ModifierType.VARIANT) {
        if (!modifier?.products) modifier.products = [product0()!._id]
        else if (!modifier.products.includes(product0()!._id)) modifier.products.push(product0()!._id)
      }
    }
    if (!val && activePopupModifierGroup.includes(modifier._id!)) {
      activePopupModifierGroup.splice(activePopupModifierGroup.indexOf(modifier._id!), 1);
    }
  }
}