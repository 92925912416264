import { useEffect, useRef, useState, type DependencyList } from 'react'
import type { RxQuery } from 'rxdb'
import type { Subscription } from 'rxjs'

import { dataLock } from '@/data/DataUtils'

export function useRxQuery<T, V>(queryGetter: () => RxQuery<T, V>, deps?: DependencyList) {
  const [data, setData] = useState<V>()
  const sub = useRef<Subscription | undefined>()

  useEffect(() => {
    dataLock.acquireAsync().then(() => {
      const q = queryGetter()
      sub.current?.unsubscribe()
      sub.current = q.$.subscribe(setData)
    })
  }, deps)

  return data
}
