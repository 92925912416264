import { type CSSProperties, FunctionComponent, useMemo } from 'react';
import { LL0 } from '@/react/core/I18nService.tsx';
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type ManuelTableTableItemType = {
  price?: number;
  tableNumber?: string;
  remainingTime?: string;
  isBooked?: boolean;
  isMyTable?: boolean;

  /** Style props */
  manuelTableTableItemWidth?: CSSProperties["width"];

  topRightRoundedPercent: number;
};

const ManuelTableTableItem: FunctionComponent<ManuelTableTableItemType> = ({
  price = 0,
  tableNumber = "8",
  remainingTime = "23",
  isBooked ,
  isMyTable,
  manuelTableTableItemWidth,
  topRightRoundedPercent
}) => {
  const LL = LL0()
  const manuelTableTableItemStyle: CSSProperties = useMemo(() => {
    return {
      width: manuelTableTableItemWidth,
    };
  }, [manuelTableTableItemWidth]);
  return (
    <div
      className="rounded w-full h-full overflow-hidden flex flex-col items-center justify-center relative gap-[6px] text-center text-2xs text-black-solid-black-880-1d1d26 font-mulish"
      style={manuelTableTableItemStyle}
    >
      {isBooked && (
        <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-red-solid-red-50-ff8787" />
      )}
      {isMyTable && (
        <img
          className="pointer-events-none absolute my-0 mx-[!important] -top-[2px] -right-[2px] min-w-[21.5px] min-h-[21.5px] max-w-[100%] max-h-[100%]"
          style={{
            width: `${topRightRoundedPercent}%`,
            height: `${topRightRoundedPercent}%`
          }}
          alt=""
          src="/is-my-table-indicator.svg"
        />
      )}
      {isBooked && <b className="relative leading-[15px]">{LL3().format.currency(price)}</b>}
      <b className="relative text-xl leading-[16px]">{tableNumber}</b>
      {isBooked && (
        <div className="flex flex-row items-center justify-center gap-[3px] text-3xs">
          <img
            className="table-clock-icon--%123 relative w-[9.8px] h-[9.1px]"
            alt=""
            src="/table-clock-icon.svg"
          />
          <b className="relative tracking-[-0.2px]">{remainingTime}{LL.common.mins()}</b>
        </div>
      )}
    </div>
  );
};

export default ManuelTableTableItem;
