import Queue from 'queue';
import {rnHost} from "@/shared/webview/rnwebview";
import uuid from "time-uuid";
import { captureException } from "@sentry/react";
import debug from 'debug';

const log = debug('printer:usbManager');

const queue = new Queue({autostart: true, concurrency: 1});

export const printUsb = (task: { data: Buffer, path?: string }) => {
  queue.push(async () => {
    let count = 0;
    while (count < 10) {
      try {
        const payload = task.data.toString('base64');
        await rnHost.printUsb({payload, path : task.path});
        break;
      } catch (e: any) {
        captureException(new Error(`print to usb: error ${e.message}`), { tags: { type: 'print' } });
        log(`print to usb: error ${e.message}`);
        console.error(`print to usb: error`, e);
        //todo: emit to virtual printer
        if (e.message?.includes('queueing USB request') || e.message?.includes('claim USB interface')) {
          break;
        }
      } finally {
        count++;
      }
    }
  })
}
