import I18nBackend from "@/react/core/I18nBackend.tsx";
import TypesafeI18n from "@/i18n/i18n-react.tsx";
import { backendLanguage } from "@/data/language.ts";

interface I18nBackendWrapperProps {
}

const I18nBackendWrapper = (props: I18nBackendWrapperProps) => {
  return (
    <TypesafeI18n locale={backendLanguage() as any} key={backendLanguage() as any}>
      <I18nBackend>
      </I18nBackend>
    </TypesafeI18n>
  )
}

export default I18nBackendWrapper
