import * as Comlink from 'comlink'
import { proxyMarker, type ProxyMarked, type Remote } from 'comlink'
import uuid from 'time-uuid'
import { EventEmitter } from 'tsee'
import type { Class } from 'utility-types'

import type { Order } from '@/data/Order.ts'
import { ws } from '@/react/websocket/init-ws.ts'
import nodeEndpoint, { type NodeEndpoint } from '@/shared/comlink/node-adapter'

export function rnPostMessage(s: string) {
  console.log('POST MESSAGE: ', s.length)
  // @ts-ignore
  // window.ReactNativeWebView?.postMessage(s);

  ws.send(JSON.stringify({ ns: 'frontend', data: JSON.parse(s) }))
  // ws.send(JSON.stringify(JSON.parse(s)));
}

interface SrmRnHost {
  /**
   * Install certificate to secure store.
   *
   * @param {string} p12 Base64 encoded .p12 file
   * @param {string} password password used for .p12
   */
  installCert(p12: string, password: string): Promise<void>
  /** Load installed cert. Need to run this function whenever start the app or after installing cert */
  loadCert(): Promise<void>
  /** Delete certificate from secure store. */
  deleteCert(): Promise<void>
  /** Make HTTPS request, with specific TLS cert  */
  relay(data: { url: string; headers: Record<string, string>; body: Record<string, unknown> }): Promise<unknown>
  /** Turn on/off OfflineMode, where all axios request will error */
  switchOfflineMode(mode: 'error' | 'timeout' | 'off'): void
}

type DefaultEE = {
  message(event: any): void
}

export type BluetoothPrinterData = {
  address: string
  name?: string
}

export type RnHost = {
  add(a: number, b: number): number
  testCb(cb: (text: string) => void, cb2: (text: string) => void): void
  openTcp(uuid: string, ip: string, closeCallback?: Function): void
  printTcp(data: { payload: string; uuid: string; dropConnection?: boolean }): void
  closeTcp(uuid: string): void
  printUsb(data: { payload: string; path: string }): void
  printStar(payload: string, width: number, ip: string): void
  listUsbDevices(): string[]
  checkUpdate(mode: 'store' | 'codepush' | 's3'): Promise<string | null>
  updateNow(mode: string, onProgress: (p: number) => void): Promise<boolean>
  sendOrderToSD(data?: Order): string
  sendBackendLanguageToSD(locale: string): string
  scanDevices(): Promise<{
    found: BluetoothPrinterData[]
    paired: BluetoothPrinterData[]
  }>
  connect(address: string): Promise<boolean>
  printBluetooth(address: string, data: string, width: number): Promise<boolean>
  getConnectedDevices(): Promise<BluetoothPrinterData[]>
  openTcpConnection(ip: string, port: number, onData: Function, onError: Function, onClose: Function): Promise<void>
  sendDataTcpConnection(ip: string, port: number, data: number[]): Promise<void>
  closeTcpConnection(ip: string, port: number): Promise<void>
  getDate(): Promise<string>
  startStream(deviceId: string): void
  stopStream(): void
  sendDeviceName(): Promise<string>
  sendDeviceModel(): Promise<string>
  sendStoreId(storeId: string): string
  sendVersion(version: string): string
  sendBackgroundToSD(url: string): string
  sendLogoToSD(url: string): string
  printTsc(address: string, options: Object): void
  openUrl(url: string): Promise<void>
  openSecondDisplay(usb: string): void
  exportCsv(csvData: any, fileName: string): void
  exportCsv2(csvData: any): void
  chooseImage(): Promise<string>
  findSerialPortPrinter(): Promise<any>
  printCom(data: any, path: string, baudRate: number): Promise<any>
} & SrmRnHost

interface UsbDevice {
  productId: number
  vendorId: number
  deviceId: number
}

export declare class TSerialPort {
  constructor(deviceId: number)

  hello(): string

  init(): void

  send(str: string): void

  close(): void

  static list(): UsbDevice[]
}

// const endpoint: NodeEndpoint<DefaultEE> = {
//   postMessage(s: string) {
//     console.log('POST MESSAGE: ', s.length);
//     // @ts-ignore
//     // window.ReactNativeWebView?.postMessage(s);
//
//     ws.send(JSON.stringify({ ns: 'frontend', data: JSON.parse(s) }));
//     // ws.send(JSON.stringify(JSON.parse(s)));
//   },
//   emit() {
//
//   },
//   ee: new EventEmitter()
// }

export interface ComlinkMessage {
  ns: string
  data: any
}

export const endpoints: { [p: string]: NodeEndpoint<DefaultEE> & { emit: (obj: ComlinkMessage) => void } } = {}

export const endpointFactory = (ns: string) => {
  const ee = new EventEmitter()
  const result = {
    postMessage(str: string) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ ns, data: JSON.parse(str) }))
      } else {
        ws.send(JSON.stringify({ ns, data: JSON.parse(str) }))
      }
    },
    emit(msg: ComlinkMessage) {
      if (ns === msg.ns) {
        if (msg.data.name === 'proxy') {
          msg.data.value = { [proxyMarker]: true, ...msg.data.value }
        }
        ee.emit('message', { data: msg.data })
      }
    },
    ee,
  }
  endpoints[ns] = result
  return result
}

const endpoint = endpointFactory('frontend')
const endpoint2 = endpointFactory('serialport')

/**
 * handle only com-link message
 */
ws.addEventListener('message', e => {
  try {
    const obj = JSON.parse(e.data) as { ns: string; data: unknown }
    if (!obj.ns) return
    if (obj.ns !== 'frontend' && obj.ns !== 'serialport' && !obj.ns.includes('callback')) return
    // console.log('on-message: ', obj);
    //todo switch
    endpoints[obj.ns].emit(obj)
  } catch (e) {
    console.log('Failed to parse ws message:', e)
  }
})

function onComlink(data: { ns: string; data: unknown }) {
  try {
    const obj = data
    if (obj.ns !== 'frontend' && obj.ns !== 'serialport' && !obj.ns.includes('callback')) return
    // console.log('on-message: ', obj);
    //todo switch
    endpoints[obj.ns].emit(obj)
  } catch (e) {
    console.error(e)
  }
}

export type SerialPort = Class<Remote<TSerialPort>> & {
  list: () => Promise<UsbDevice[]>
  tryRequestPermission: (deviceId: number) => Promise<void>
}

export const rnHost = Comlink.wrap<RnHost>(nodeEndpoint(endpoint))
export const SerialPort = Comlink.wrap<TSerialPort>(nodeEndpoint(endpoint2)) as unknown as SerialPort

Comlink.transferHandlers.set('proxy', {
  canHandle: (val): val is ProxyMarked => typeof val === 'object' && (val as any)['id'],
  serialize(obj) {
    console.log('serialize: ', obj)
    //expo to new port ??
    return [
      {
        id: '1234',
      },
      [],
    ]
  },
  deserialize(port: { _instance: string }) {
    console.log('deserialize: ', port)
    //create new port to send message
    const port2 = Comlink.wrap<any>(nodeEndpoint(endpointFactory(port._instance)))
    return port2
  },
})

Comlink.transferHandlers.set('callback', {
  canHandle: (val): val is ProxyMarked => typeof val === 'function' && (val as any)[proxyMarker],
  serialize(obj) {
    const ns = `callback-${uuid()}`
    Comlink.expose(obj, nodeEndpoint(endpointFactory(ns)))
    return [{ endpoint: ns }, []]
  },
  deserialize(port: any) {},
})

export async function testComlink() {
  console.log('testComlink')
  // const result = await rnHost.add(1, 2);
  const deviceList = await SerialPort.list()
  const device = deviceList.find(d => d.vendorId === 1659)
  if (device) {
    await SerialPort.tryRequestPermission(device.deviceId)
    const sp = await new SerialPort(device.deviceId)
    await sp.init()
    await sp.send('test string')
    await sp.close()
    const sp2 = await new SerialPort(device.deviceId)
    await sp2.hello()
  }

  console.log('result: ')
}

export async function testComlink2() {
  const cb = (text: string) => {
    console.log(text)
  }
  await rnHost.testCb(
    Comlink.proxy(cb),
    Comlink.proxy((text: string) => {
      console.log('2. ' + text)
    })
  )
}

Object.assign(window, { rnHost, SerialPort, onComlink, testComlink2 }) // Expose for debugging
