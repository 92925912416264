import type { RxCollection, RxDatabase } from 'rxdb'

import { ONLINE_PROVIDER_COLLECTION_NAME, OnlineProvider, onlineProviderSchema } from './OnlineProvider'
import { createCollection } from './utils/migration'

export const createOnlineProviderCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<OnlineProvider>> => {
  return await createCollection({
    database,
    collection: OnlineProvider,
    collectionName: ONLINE_PROVIDER_COLLECTION_NAME,
    version: onlineProviderSchema.version,
    schema: onlineProviderSchema,
  })
}
