import { deepSignal } from 'deepsignal/react'

import { deviceSetting0 } from '@/data/DeviceSettingSignal'
import { isQuebecSrmEnabled } from '@/data/PosSettingsSignal.ts'
import { computed } from '@/react/core/reactive'

export enum PosFeatures {
  /** The Cancel button in the OrderHistory screen */
  CancelCompletedOrder,
  /** The Void button in the dropdown menu in OrderHistory screen  */
  VoidOrder,
  PendingOrder,
  DeliveryOrder,
  SplitBill,
  Discount,
  RedBill,
  /** Move Table button in FastCheckout screen */
  MoveTable,
  MultiplePayment,
  ChangeTipWhenSwitchPaymentMethod,
  CustomDish,
  Reactivate,
}

export const isPendingOrderFeatureEnabled = computed(() => deviceSetting0()?.srm?.features?.[PosFeatures.PendingOrder] ?? false)
export const isDeliveryOrderFeatureEnabled = computed(() => deviceSetting0()?.srm?.features?.[PosFeatures.DeliveryOrder] ?? false)
export const isSplitBillFeatureEnabled = computed(() => deviceSetting0()?.srm?.features?.[PosFeatures.SplitBill] ?? false)
export const isMoveTableFeatureEnabled = computed(() => deviceSetting0()?.srm?.features?.[PosFeatures.MoveTable] ?? false)
export const isMultiplePaymentFeatureEnabled = computed(() => deviceSetting0()?.srm?.features?.[PosFeatures.MultiplePayment] ?? false)

/** Check for feature that we should disable */
export function isFeatureEnabled(feat: PosFeatures): boolean {
  if (!isQuebecSrmEnabled()) return true

  const features = deviceSetting0()?.srm?.features || ({} as Record<PosFeatures, boolean>)
  return features[feat] ?? false
}

export function toggleFeature(feat: PosFeatures, enabled: boolean) {
  const ds = deviceSetting0()
  if (!ds) return
  let srm = ds.srm
  if (!srm) srm = ds.srm = deepSignal({})
  let features = srm.features
  if (!features) features = srm.features = deepSignal({} as Record<PosFeatures, boolean>)
  features[feat] = enabled
}
