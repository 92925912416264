import {Fragment, type FunctionComponent} from 'react';
import {LL0} from "@/react/core/I18nService.tsx";
import PasscodeUserItem from '@passcode/PasscodeUserItem.tsx';
import _ from "lodash";
import {clsx} from "clsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { users0 } from "@/data/UserSignal.ts";

const PasscodeLeft: FunctionComponent = () => {
  return (
    <div data-testid='passcode.users' className="self-stretch flex-1 flex flex-col items-center justify-start gap-[20px] h-full text-center text-3xl text-white-solid-white-100-ffffff font-mulish">
      <b className="self-stretch h-7 relative inline-block sm:text-2xl sm:hidden mq768:text-lg mq768:hidden mq600:hidden mq480:hidden height_414:hidden height_598:hidden">
        {LL0().login.activeUsers().toUpperCase()}
      </b>
      <div className="self-stretch flex-1 rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] overflow-hidden flex flex-col items-center justify-start py-3 px-4 box-border max-h-[540px] text-left text-sm lg:max-h-[440px] mq768:max-h-[440px] height_340:max-h-[278px]">
        <div className="max-h-full min-h-full self-stretch flex-1 rounded flex flex-col items-center justify-between">
          <div className="max-h-full min-h-full self-stretch flex-1 flex flex-col items-center justify-start gap-[8px]">
            <div className="!h-full min-h-full no-scrollbar self-stretch h-[209.6px] overflow-y-auto shrink-0 flex flex-col items-center justify-start gap-[12px] max-h-[calc(100vh_-_310px)] lg:h-[200px] md:h-[220px] mq1440:h-[250px] mq768:h-[230px] mq600:h-[260px] mq480:h-[260px] height_414:flex-1 height_414:max-h-[calc(100vh_-_150px)] height_340:flex height_340:flex-1 height_340:max-h-[calc(100vh_-_150px)]">
              {
                users0().map((user, index)=> (
                  <Fragment key={index}>
                    <PasscodeUserItem userData={user} />
                    {
                      user.passcode === "0000" && (
                        <div
                          className={clsx("self-stretch rounded bg-blue-opacity-blue-60-002256 [backdrop-filter:blur(5px)] h-[42px] flex flex-row items-center justify-start py-2.5 px-3 box-border gap-[10px]",
                            !generalSetting0()?.isDisplayPasscodeTutorial && 'hidden'
                          )}>
                          <img
                            className="w-5 relative h-5 object-cover"
                            alt=""
                            src="/iconpasscodenote-icon@2x.png"
                          />
                          <div
                            className="flex-1 relative leading-[17px] font-medium mq1440:text-xl mq768:text-sm mq600:text-smi mq480:text-xs">
                            <span>{LL0().login.enter()}</span>
                            <span className="text-smi">{` `}</span>
                            <span className="text-lg">0000</span>
                            <span className="text-smi">{` `}</span>
                            <span>{LL0().login.asPasscode()}.</span>
                          </div>
                          <img
                            className="w-[25px] relative h-[25.5px] object-cover md:w-[23px] md:h-[23px] mq768:w-[22px] mq768:h-[22px] mq600:w-[22px] mq600:h-[22px] mq480:w-[22px] mq480:h-[22px]"
                            alt=""
                            src="/iconpasscodescreenclose-icon@2x.png"
                            onPointerDown={() => _.assign(generalSetting0(), {isDisplayPasscodeTutorial: false})}
                          />
                        </div>
                      )
                    }
                  </Fragment>
                ))
              }
            </div>
          </div>
          <div className="!hidden self-stretch flex flex-col items-center justify-end pt-1 px-6 pb-0 gap-[8px] height_414:hidden height_340:hidden">
            <img
              className="self-stretch max-w-full overflow-hidden h-[115px] shrink-0 object-cover"
              alt=""
              src="/ads@2x.png"
            />
            <img
              className="w-[22px] relative h-[5.4px]"
              alt=""
              src="/iconpasscodeswitch-bar-icon.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasscodeLeft;
