import {FunctionComponent, memo, useState} from "react";
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import PendingOrderOrderCard from "./PendingOrderOrderCard";
import {LL0} from "@/react/core/I18nService.tsx";

import { mainScreen } from "@/data/PosSettingsSignal.ts";

const ShippingStatusPlugin: FunctionComponent = () => {
  const [isPopupOrderDetailsPluginOpen, setPopupOrderDetailsPluginOpen] =
    useState(false);
  const [isPopupOrderDetailsPlugin1Open, setPopupOrderDetailsPlugin1Open] =
    useState(false);
  const [isPopupOrderDetailsPlugin2Open, setPopupOrderDetailsPlugin2Open] =
    useState(false);
  const [isPopupOrderDetailsPlugin3Open, setPopupOrderDetailsPlugin3Open] =
    useState(false);
  const [isPopupOrderDetailsPlugin4Open, setPopupOrderDetailsPlugin4Open] =
    useState(false);
  const [isPopupOrderDetailsPlugin5Open, setPopupOrderDetailsPlugin5Open] =
    useState(false);
  const [isPopupOrderDetailsPlugin6Open, setPopupOrderDetailsPlugin6Open] =
    useState(false);
  return (
    <div className="w-full h-full overflow-hidden flex flex-row items-start justify-start pt-2 px-9 pb-6 box-border gap-[40px] bg-[url('/shipping-status--plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-left text-mini text-white-solid-white-100-ffffff font-mulish sm:pl-6 sm:pr-6 sm:box-border Tablet_768:gap-[24px] Tablet_768:pl-5 Tablet_768:pr-5 Tablet_768:box-border Tablet_600:gap-[16px] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border Tablet_600:h-full Tablet_600:[widows:100%] Mobile_480:gap-[24px] Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border">
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-start justify-start pt-2 px-0 pb-0 gap-[8px] Mobile_480:grid">
        <div className="self-stretch h-[26px] flex flex-col items-start justify-start gap-[4px] Tablet_768:flex">
          <div className="self-stretch flex flex-row items-center justify-start">
            <div className="flex flex-row items-center justify-start gap-[8px] Tablet_600:flex-row">
              <b className="relative leading-[16px] sm:text-sm Tablet_768:text-smi mq1440:text-mid">
                {LL0().shippingStatus.onTheWay()}
              </b>
              <div className="rounded-81xl bg-orange-solid-orange-500-ff8a00 w-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-px px-0 box-border text-center text-xs mq1440:w-[34px] mq1440:pt-1 mq1440:pb-1 mq1440:box-border">
                <div className="flex-1 relative font-semibold mq1440:text-sm">
                  3
                </div>
              </div>
            </div>
            <div className="rounded-md bg-white-solid-white-100-ffffff overflow-hidden hidden flex-row items-center justify-start py-1 px-2 gap-[5px] text-sm text-color-mau-dark border-[1px] border-solid border-gainsboro">
              <img
                className="relative w-5 h-5 object-cover"
                alt=""
                src="/iconnew-delivery-icon@2x.png"
              />
              <b className="relative sm:text-smi Tablet_768:text-xs Tablet_600:text-smi">
                {LL0().onlineOrder.newDelivery()}
              </b>
            </div>
          </div>
          <div className="relative text-sm leading-[17px] font-medium text-tomato hidden">
            {LL0().onlineOrder.callSystem.modemNotConnected()}
          </div>
        </div>
        <div className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[8px]">
          <PendingOrderOrderCard
            noticeText={LL0().shippingStatus.orderIsOnTheWay()}
            orderID="#121 "
            customerSName="Claudia C Braun"
            customerSPhoneNumber="098765432"
            customerSAddress="Kurfuerstendamm 29, München 8001 hgahghgxjhx"
            orderTime="10:00"
            showEditIcon={false}
            showOrderIsOnTheWay
            showTakeawayIcon={false}
            showDeliverIcon
            showOrderWasDelivered={false}
            showOrderIsRuningOutOfTime={false}
            showSkipDishIcon={false}
            showUberEatsIcon={false}
            showOrderIsReady={false}
            showNotice
            orderIsOnTheWay
            orderWasDelivered={false}
            showDoordashIcon={false}
            showVegaIcon={false}
            showPikaIcon={false}
            takeawayIconVisible={false}
            showNoticeOrderIsReady={false}
            showModifier
          />
          <PendingOrderOrderCard
            noticeText={LL0().shippingStatus.orderIsOnTheWay()}
            orderID="#121 "
            customerSName="Claudia C Braun"
            customerSPhoneNumber="098765432"
            customerSAddress="Kurfuerstendamm 29, München 8001 hgahghgxjhx"
            orderTime="10:00"
            showEditIcon={false}
            showOrderIsOnTheWay
            showTakeawayIcon={false}
            showDeliverIcon
            showOrderWasDelivered={false}
            showOrderIsRuningOutOfTime={false}
            showSkipDishIcon={false}
            showUberEatsIcon={false}
            showOrderIsReady={false}
            showNotice
            orderIsOnTheWay
            orderWasDelivered={false}
            showDoordashIcon={false}
            showVegaIcon={false}
            showPikaIcon={false}
            takeawayIconVisible={false}
            showNoticeOrderIsReady={false}
            showModifier
          />
          <PendingOrderOrderCard
            noticeText={LL0().shippingStatus.orderIsOnTheWay()}
            orderID="#121 "
            customerSName="Claudia C Braun"
            customerSPhoneNumber="098765432"
            customerSAddress="Kurfuerstendamm 29, München 8001 hgahghgxjhx"
            orderTime="10:00"
            showEditIcon={false}
            showOrderIsOnTheWay
            showTakeawayIcon={false}
            showDeliverIcon
            showOrderWasDelivered={false}
            showOrderIsRuningOutOfTime={false}
            showSkipDishIcon={false}
            showUberEatsIcon={false}
            showOrderIsReady={false}
            showNotice
            orderIsOnTheWay
            orderWasDelivered={false}
            showDoordashIcon={false}
            showVegaIcon={false}
            showPikaIcon={false}
            takeawayIconVisible={false}
            showNoticeOrderIsReady={false}
            showModifier
          />
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-[12px]">
            <b className="relative leading-[16px] sm:text-sm Tablet_768:text-smi mq1440:text-mid">
              {LL0().shippingStatus.delivered()}
            </b>
            <div className="rounded-81xl bg-purple-solid-purple-600-904bff w-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-px px-0 box-border text-center text-xs mq1440:w-[34px] mq1440:pt-1 mq1440:pb-1 mq1440:box-border">
              <div className="flex-1 relative font-semibold mq1440:text-sm">
                4
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start text-center text-sm sm:gap-[4px] Tablet_600:gap-[8px]">
            <div className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
                 ref={makeRipple}
                 onClick={() => router.screen = mainScreen()}
            >
              <img
                className="relative w-[30px] h-[30px] object-cover"
                alt=""
                src="/iconback-icon@2x.png"
              />
              <b className="relative leading-[17px] hidden">Button</b>
            </div>
          </div>
        </div>
        <div className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[8px]">
          <PendingOrderOrderCard
            noticeText={LL0().shippingStatus.orderIsOnTheWay()}
            orderID="#121 "
            customerSName="Claudia C Braun"
            customerSPhoneNumber="098765432"
            customerSAddress="Kurfuerstendamm 29, München 8001 hgahghgxjhx"
            orderTime="10:00"
            showEditIcon={false}
            showOrderIsOnTheWay={false}
            showTakeawayIcon={false}
            showDeliverIcon
            showOrderWasDelivered
            showOrderIsRuningOutOfTime={false}
            showSkipDishIcon={false}
            showUberEatsIcon={false}
            showOrderIsReady={false}
            showNotice
            orderIsOnTheWay={false}
            orderWasDelivered
            showDoordashIcon={false}
            showVegaIcon
            showPikaIcon={false}
            takeawayIconVisible={false}
            showNoticeOrderIsReady={false}
            showModifier
          />
          <PendingOrderOrderCard
            noticeText={LL0().shippingStatus.orderWasDelivered()}
            orderID="#121 "
            customerSName="Claudia C Braun"
            customerSPhoneNumber="098765432"
            customerSAddress="Kurfuerstendamm 29, München 8001 hgahghgxjhx"
            orderTime="10:00"
            showEditIcon={false}
            showOrderIsOnTheWay={false}
            showTakeawayIcon={false}
            showDeliverIcon
            showOrderWasDelivered
            showOrderIsRuningOutOfTime={false}
            showSkipDishIcon={false}
            showUberEatsIcon
            showOrderIsReady={false}
            showNotice
            orderIsOnTheWay={false}
            orderWasDelivered
            showDoordashIcon={false}
            showVegaIcon={false}
            showPikaIcon={false}
            takeawayIconVisible={false}
            showNoticeOrderIsReady={false}
            showModifier
          />
          <PendingOrderOrderCard
            noticeText={LL0().shippingStatus.orderWasDelivered()}
            orderID="#121 "
            customerSName="Claudia C Braun"
            customerSPhoneNumber="098765432"
            customerSAddress="Kurfuerstendamm 29, München 8001 hgahghgxjhx"
            orderTime="10:00"
            showEditIcon={false}
            showOrderIsOnTheWay={false}
            showTakeawayIcon={false}
            showDeliverIcon
            showOrderWasDelivered
            showOrderIsRuningOutOfTime={false}
            showSkipDishIcon={false}
            showUberEatsIcon
            showOrderIsReady={false}
            showNotice
            orderIsOnTheWay={false}
            orderWasDelivered
            showDoordashIcon
            showVegaIcon={false}
            showPikaIcon={false}
            takeawayIconVisible={false}
            showNoticeOrderIsReady={false}
            showModifier
          />
          <PendingOrderOrderCard
            noticeText={LL0().shippingStatus.orderWasDelivered()}
            orderID="#121 "
            customerSName="Claudia C Braun"
            customerSPhoneNumber="098765432"
            customerSAddress="Kurfuerstendamm 29, München 8001 hgahghgxjhx"
            orderTime="10:00"
            showEditIcon={false}
            showOrderIsOnTheWay={false}
            showTakeawayIcon={false}
            showDeliverIcon
            showOrderWasDelivered
            showOrderIsRuningOutOfTime={false}
            showSkipDishIcon={false}
            showUberEatsIcon
            showOrderIsReady={false}
            showNotice
            orderIsOnTheWay={false}
            orderWasDelivered
            showDoordashIcon={false}
            showVegaIcon={false}
            showPikaIcon
            takeawayIconVisible={false}
            showNoticeOrderIsReady={false}
            showModifier
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ShippingStatusPlugin);
