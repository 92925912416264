import { FunctionComponent } from "react";
import VoucherKeyboardButtonKeyb from "./VoucherKeyboardButtonKeyb";
import {
  currentPayment0,
  onPaymentKeyClick,
  scopeOrder0,
  setFirstClick0,
  setValue0,
  value0
} from "@/react/PaymentView/PaymentView.tsx";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";

const SmallHeightKeyBoard: FunctionComponent = () => {
  return (
    <div className="w-[336px] h-[130px] hidden flex-col items-center justify-end gap-[2px] text-center text-sm text-black-solid-black-900-1e1e23 font-mulish Tablet_768:hidden Tablet_768:gap-[2px] Tablet_600:gap-[2px] height_414:flex height_414:max-h-[130px]">
      <div className="self-stretch rounded-10xs bg-gray-solid-gray-150-eeeeee flex flex-row items-center justify-between py-1 px-[5px] border-[1px] border-solid border-gray-solid-gray-600-979797">
        <div className="rounded-10xs bg-darkslategray shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] shrink-0 flex flex-row items-center justify-center py-0 px-3.5 height_414:pl-2.5 height_414:pr-2.5 height_414:box-border">
          {currentPayment0()?.icon && <img
            className="!object-contain relative w-[26px] h-[26px] object-cover Tablet_768:w-[26px] Tablet_768:h-[26px] height_414:w-[26px] height_414:h-[26px]"
            alt=""
            src={currentPayment0()?.icon}
          />}
        </div>
        <div className="flex flex-row items-center justify-end gap-[10px]">
          <b className="relative sm:text-lg Tablet_768:text-base height_414:text-base">
            {value0()}
          </b>
          <div className="w-[26px] relative h-[26px] Tablet_768:w-[26px] Tablet_768:h-[26px] height_414:w-[26px] height_414:h-[26px]">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/payment-button-close-icon3@2x.png"
              onClick={() => {
                setFirstClick0(true);
                setValue0(scopeOrder0()?.vSum?.toString() || '');
              }}
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[2px] Tablet_768:gap-[2px] Tablet_600:gap-[2px]">
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[2px] Tablet_768:gap-[2px] Tablet_600:gap-[2px]">
          <VoucherKeyboardButtonKeyb
            keyNumber="7"
            onClick={() => onPaymentKeyClick('7')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber="8"
            onClick={() => onPaymentKeyClick('8')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber="9"
            onClick={() => onPaymentKeyClick('9')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber="0"
            onClick={() => onPaymentKeyClick('0')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[2px] Tablet_768:gap-[2px] Tablet_600:gap-[2px]">
          <VoucherKeyboardButtonKeyb
            keyNumber="4"
            onClick={() => onPaymentKeyClick('4')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber="5"
            onClick={() => onPaymentKeyClick('5')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber="6"
            onClick={() => onPaymentKeyClick('6')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber=","
            onClick={() => onPaymentKeyClick(',')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[2px] Tablet_768:gap-[2px] Tablet_600:gap-[2px]">
          <VoucherKeyboardButtonKeyb
            keyNumber="1"
            onClick={() => onPaymentKeyClick('1')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber="2"
            onClick={() => onPaymentKeyClick('2')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <VoucherKeyboardButtonKeyb
            keyNumber="3"
            onClick={() => onPaymentKeyClick('3')}
            voucherKeyboardButtonKeybMinWidth="26px"
            voucherKeyboardButtonKeybMinHeight="26px"
          />
          <div className="self-stretch flex-1 rounded-10xs bg-white-solid-white-100-ffffff box-border overflow-hidden flex flex-row items-center justify-center min-w-[26px] min-h-[26px] border-[1px] border-solid border-gray-solid-gray-600-979797"
               onClick={() => onPaymentKeyClick('<-')} ref={makeRipple}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/payment-delete-icon2@2x.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallHeightKeyBoard;