/** [noTabl] Format: `XXXXX` (enter on the left using equal signs, if necessary) */
export function formatTableNumber(n?: number | string): string | undefined {
  if (typeof n === 'undefined') return
  if (typeof n === 'number') n = n.toString()
  return n.slice(-5).padStart(5, '=')
}

/** [nbClint] Format: `999` (enter on the left, using zeros if necessary) */
export function formatCustomerNumber(n?: number) {
  if (n !== undefined && !isNaN(n)) return n.toString().padStart(3, '0').substring(0, 3)
  return '000'
}

/** [noTrans] Must comprise 1 to 10 characters. */
export function formatTransactionNumber(n?: number) {
  if (n !== undefined && !isNaN(n)) return n.toString().padStart(10, '0')
  return ''.padStart(10, '0')
}

const formatQuantityIntl = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  minimumIntegerDigits: 5,
  useGrouping: false,
  signDisplay: 'always',
})
/** [qte] Format: `+/-00001.00` */
export function formatQuantity(n?: number) {
  if (n !== undefined && !isNaN(n)) return formatQuantityIntl.format(n)
  return formatQuantityIntl.format(0)
}

const formatMoneyIntl = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  minimumIntegerDigits: 9,
  useGrouping: false,
  signDisplay: 'always',
})
/** [prix] Format: `+/-999999999.99` */
export function formatMoney(n?: number) {
  if (n !== undefined && !isNaN(n)) return formatMoneyIntl.format(n)
  return formatMoneyIntl.format(0)
}
