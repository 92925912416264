import ripple from "ripple-effects";

export const makeRipple = (ref: HTMLElement | null, duration: number = 0.7, background: string = "#ff854f") => {
	if (ref) {
		ripple(ref, {
			zIndex: 0,
			duration: duration * 1000,
			background: background,
			opacity: 0.7
		});
	}
};
