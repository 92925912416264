import type {FunctionComponent} from "react";

const ButtonGroup: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row items-end justify-center">
      <div
        className="self-stretch flex-1 bg-blue-solid-blue-500-0051c1 flex flex-row items-center justify-center py-1 px-4">
        <img
          className="w-8 relative h-8 object-cover"
          alt=""
          src="/payment-cash-icon2@2x.png"
        />
      </div>
      <div
        className="self-stretch flex-1 bg-green-solid-green-700-1c974d flex flex-row items-center justify-center py-1 px-4">
        <img
          className="w-8 relative h-8 overflow-hidden shrink-0 object-cover"
          alt=""
          src="/payment-mastercard-icon2@2x.png"
        />
      </div>
      <div
        className="self-stretch flex-1 bg-brown-solid-brown-600-5f4f4b flex flex-row items-center justify-center py-1 px-4">
        <img
          className="w-8 relative h-8 overflow-hidden shrink-0 object-cover"
          alt=""
          src="/payment-cash-and-card-icon@2x.png"
        />
      </div>
    </div>
  );
};

export default ButtonGroup;
