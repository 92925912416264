import { type FunctionComponent, useState } from "react";
import { LocalizationProvider, TimePicker, type TimePickerSlotsComponentsProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateFromUnix, dateToUnix } from "@/react/core/utils";
import { STATES, VIEW__PRODUCT } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import { useComputed } from "@/react/core/reactive";
import {LL0} from "@/react/core/I18nService.tsx";

import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

const formTimePickerSlotProps: TimePickerSlotsComponentsProps<Date> = {
  textField: { variant: 'outlined', hiddenLabel: true, fullWidth: true, color: 'primary', className: 'bg-white', placeholder: 'From' },
}

const PickupTimeFieldset: FunctionComponent = () => {
  const [datePickerDateTimePickerValue, setDatePickerDateTimePickerValue] =
  useState<string | null>(null);
  const [datePickerDateTimePicker1Value, setDatePickerDateTimePicker1Value] =
  useState<string | null>(null);

  const from = useComputed(() => dateFromUnix(STATES.editingProduct()?.orderRestriction_pickupTimeFrom))
  const to = useComputed(() => dateFromUnix(STATES.editingProduct()?.orderRestriction_pickupTimeTo))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold height_414:gap-[2px]">
        <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
          {LL0().editOnlineMenu.timeAvailable()}
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[20px] sm:gap-[16px] mq768:gap-[12px]">
          <div className="flex-1">
            <TimePicker
              label={LL0().report.from()}
              ampm={companyInfo0()?.backendLanguage !== 'de'}
              value={from() ?? null}
              onChange={v => VIEW__PRODUCT.onChanged('orderRestriction_pickupTimeFrom', dateToUnix(v))}
              slotProps={formTimePickerSlotProps}
            />
          </div>
          <div className="flex-1">
            <TimePicker
              label={LL0().report.to()}
              ampm={companyInfo0()?.backendLanguage !== 'de'}
              value={to() ?? null}
              onChange={v => VIEW__PRODUCT.onChanged('orderRestriction_pickupTimeTo', dateToUnix(v))}
              slotProps={formTimePickerSlotProps}
            />
          </div>
        </div>
        <div className="self-stretch relative text-sm height_414:text-xs">
          {LL0().editOnlineMenu.seeOrderingTimeMenu()}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default PickupTimeFieldset;
