import type {FunctionComponent} from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import {
  Select,
  InputLabel,
  FormHelperText,
  FormControl, MenuItem,
} from "@mui/material";
import {
  handleFilterOrderHistory,
  setTypePaymentFilter
} from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {useSignal} from "@/react/core/reactive.ts";
import {payments0} from "@/data/PaymentHub.ts";
import _ from "lodash";

type PaymentMethodPopupType = {
  onClose?: () => void;
};

const PaymentMethodPopup: FunctionComponent<PaymentMethodPopupType> = (
  {
    onClose,
  }) => {

  const [type, setType] = useSignal<string>("")

  return (
    <div
      className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[320px] h-full max-w-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish-button-name-bold">
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] z-[0]">
        <b className="self-stretch relative">{LL0().payment.paymentMethod()}:</b>
        <FormControl
          size="small"
          className="self-stretch font-mulish-button-name-bold font-bold text-smi text-black-solid-black-900-1e1e23"
          variant="outlined"
        >
          <InputLabel color="primary">{LL0().payment.paymentMethod()}</InputLabel>
          <Select color="primary" size="small" label={LL0().payment.paymentMethod()}
                  value={type()} onChange={e => setType(e.target.value)}
          >
            {payments0().map((payment, index) => (
              <MenuItem key={index} value={payment.name}>{_.get(LL0().payment, payment.name || '')?.() || payment.name}</MenuItem>
              ))}
          </Select>
          <FormHelperText/>
        </FormControl>
      </div>
      <div
        className="self-stretch flex flex-row items-center justify-end z-[1] text-center text-sm text-white-solid-white-100-ffffff">
        <div
          className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
          onClick={() => {
            setTypePaymentFilter(type)
            handleFilterOrderHistory()
            onClose?.()
          }}
          ref={makeRipple}
        >
          <b className="relative">{LL0().ui.ok().toUpperCase()}</b>
        </div>
      </div>
      <div
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[2]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/order-history-close-popup-icon--grey@2x.png"
        />
      </div>
    </div>
  );
};

export default PaymentMethodPopup;
