import PureImagePrinter from './pure-image-printer';
import {applyWorkerPool} from './print-worker-thread/worker-pool';

export default new Proxy(PureImagePrinter, {
  construct(target, argArray) {
    const width = argArray[0];
    const height = argArray[1];
    const opts = argArray[2] || {};

    const newObj = new target(width, height, opts);
    applyWorkerPool(newObj, Reflect.ownKeys(newObj.__proto__));
    return newObj;
  }
});
