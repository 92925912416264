import React, { type FunctionComponent, useState, useCallback, Fragment } from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl
} from '@mui/material'
import RefundAmountConfirmationP from '../RefundAmountConfirmationP'
import PortalPopup from '../PortalPopup'
import { fullRefund, selectedRefundAmount, order2, refundReason, setRefundReason, orderHistoryLogic } from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

export type OrderHistoryRestaurantPOS1Type = {
  onClose?: () => void;
};

const OrderHistoryRestaurantPOS1: FunctionComponent<
  OrderHistoryRestaurantPOS1Type
> = ({ onClose }) => {
  const [isRefundAmountConfirmationPOpen, setRefundAmountConfirmationPOpen] =
    useState(false)

  const openRefundAmountConfirmationP = useCallback(() => {
    setRefundAmountConfirmationPOpen(true)
  }, [])

  const closeRefundAmountConfirmationP = useCallback(() => {
    setRefundAmountConfirmationPOpen(false)
  }, [])

  return (
    <>
      <div
        className="min-h-[240px] !w-full w-[800px] h-full relative rounded-lg bg-white-solid-white-100-ffffff flex flex-col items-start justify-start gap-[10px] max-w-full max-h-full text-left text-base text-black-solid-black-880-1d1d26 font-mulish-button-name-bold">
        <div
          className="self-stretch flex flex-row items-center justify-start py-2 px-3 gap-[14px] border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1">
          <b className="flex-1 relative leading-[24px]">{LL0().orderHistory.refundProduct()}</b>
          <div className="flex flex-row items-center justify-start gap-[4px] text-sm">
            <div className="relative leading-[18px] Mobile_480:text-xs">
              {LL0().orderHistory.totalRefundAmount()}:
            </div>
            <b className="relative text-mini leading-[18px] text-blue-solid-blue-0057ff Mobile_480:text-smi">
              {LL3().format.currency(selectedRefundAmount() || 0)}
            </b>
          </div>
        </div>
        <div
          className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-0 px-3 gap-[12px]">
          {fullRefund() && <div className="self-stretch flex flex-row items-center justify-center text-blue-solid-blue-0057ff">
            <div className="flex-1 flex flex-row items-center justify-start">
              <div className="flex-1 relative font-semibold">{LL0().orderHistory.fullRefund()}</div>
            </div>
          </div>}
          {!fullRefund() && <div
            className="self-stretch rounded overflow-y-auto shrink-0 flex flex-col items-start justify-start border-[1px] border-solid border-gray-solid-gray-320-d2d2d2">
            <div
              className="!overflow-auto no-scrollbar self-stretch relative rounded h-[184px] overflow-hidden shrink-0 grid grid-rows-[40px_repeat(3,1fr)] grid-cols-[1fr_3fr_repeat(2,2fr)] [grid-row-gap:0px] [grid-column-gap:0px]">
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-start p-2 relative col-[1/1] row-[1/1] text-white-solid-white-100-ffffff">
                <b className="flex-1 relative leading-[18px] Mobile_480:text-sm">
                  No
                </b>
              </div>
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-start p-2 relative col-[2/2] row-[1/1] text-white-solid-white-100-ffffff">
                <div className="flex-1 flex flex-col items-start justify-center">
                  <b
                    className="self-stretch relative leading-[18px] overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {LL0().article.product()}
                  </b>
                </div>
              </div>
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-start p-2 relative col-[3/3] row-[1/1] text-white-solid-white-100-ffffff">
                <div className="flex-1 flex flex-col items-start justify-center">
                  <b
                    className="self-stretch relative leading-[18px] overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {LL0().order.quantity()}
                  </b>
                </div>
              </div>
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-center p-2 relative col-[4/4] row-[1/1] text-right text-white-solid-white-100-ffffff">
                <b className="flex-1 relative leading-[18px] Mobile_480:text-sm">
                  {LL0().orderHistory.refundPrice()}
                </b>
              </div>
              {order2()?.items?.map((item, index) => <Fragment key={index}>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-start p-2 relative col-auto row-auto border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <b
                    className="flex-1 relative leading-[150%] overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {item.id}
                  </b>
                </div>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-start p-2 relative col-auto row-auto border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <div
                    className="flex-1 relative leading-[150%] font-semibold overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {item.name}
                  </div>
                </div>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center p-2 relative col-auto row-auto text-mini border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <div className="flex-1 relative leading-[30px] Mobile_480:text-sm">
                    {item.quantity}
                  </div>
                </div>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-start p-2 relative col-auto row-auto text-right text-blue-solid-blue-420-2979ff border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <div className="flex-1 relative leading-[150%] Mobile_480:text-sm">
                    -{LL3().format.currency(item.vTotal ?? 0)}
                  </div>
                </div>

              </Fragment>)}
            </div>
          </div>}
          <div className="self-stretch flex flex-row items-start justify-start text-sm">
            <div className="self-stretch flex-1 relative Tablet_600:flex-1 Tablet_600:gap-[6px]">
              <div className="absolute top-[0px] left-[0px] leading-[18px] mix-blend-normal">
                {LL0().inventory.reason()}:
              </div>
              <FormControl
                className="absolute w-full top-[22px] right-[0%] left-[0%] font-mulish-button-name-bold text-sm text-black-solid-black-880-1d1d26"
                variant="outlined"
                sx={{
                  borderRadius: '0px 0px 0px 0px',
                  width: '776px',
                  height: '40px',
                  m: 0,
                  p: 0,
                  '& .MuiInputBase-root': {
                    m: 0,
                    p: '14px',
                    minHeight: '40px',
                    justifyContent: 'center',
                    display: 'inline-flex'
                  },
                  '& .MuiInputLabel-root': {
                    m: 0,
                    p: 0,
                    minHeight: '40px',
                    display: 'inline-flex'
                  },
                  '& .MuiMenuItem-root': {
                    m: 0,
                    p: 0,
                    height: '40px',
                    display: 'inline-flex'
                  },
                  '& .MuiSelect-select': {
                    m: 0,
                    p: 0,
                    height: '40px',
                    alignItems: 'center',
                    display: 'inline-flex'
                  },
                  '& .MuiInput-input': { m: 0, p: 0 },
                  '& .MuiInputBase-input': {
                    textAlign: 'left',
                    p: '0 !important'
                  }
                }}
              >
                <InputLabel color="primary" />
                <Select
                  color="primary"
                  size="small"
                  displayEmpty
                  value={refundReason()}
                  onChange={e => setRefundReason(e.target.value)}
                >
                  <MenuItem value="Fradulent charge">{LL0().orderHistory.fradulentCharge()}</MenuItem>
                  <MenuItem value="Cancelled order">{LL0().orderHistory.cancelledOrder()}</MenuItem>
                  <MenuItem value="Store's return policy">
                    {LL0().orderHistory.storesReturnPolicy()}
                  </MenuItem>
                  <MenuItem value="Returned goods">{LL0().orderHistory.returnedGoods()}</MenuItem>
                  <MenuItem value="Product doesn't meet requirements">
                    {LL0().orderHistory.productDoesntMeetRequirements()}
                  </MenuItem>
                  <MenuItem value="Not satisfied with the product">
                    {LL0().orderHistory.notSatisfiedWithTheProduct()}
                  </MenuItem>
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          </div>
        </div>
        <div
          className="self-stretch bg-gray-solid-gray-166-ededee flex flex-row items-center justify-between py-2 px-3 text-center text-sm text-white-solid-white-100-ffffff">
          {/*<div*/}
          {/*  className="self-stretch w-[60px] rounded-81xl bg-palette-blue-style shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] sm:pl-2 sm:pr-2 sm:box-border Tablet_768:pl-2 Tablet_768:pr-2 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border height_414:pl-2 height_414:pr-2 height_414:box-border"*/}
          {/*  ref={makeRipple}*/}
          {/*  onClick={orderHistoryLogic.handleBackToRefundItemPopup}*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="w-[30px] relative h-[30px] object-cover Tablet_768:w-[26px] Tablet_768:h-[26px]"*/}
          {/*    alt=""*/}
          {/*    src="/order-history-iconback-icon@2x.png"*/}
          {/*  />*/}
          {/*  <b className="w-[45px] relative leading-[17px] hidden">Button</b>*/}
          {/*</div>*/}
          <div className="flex flex-row items-center justify-end gap-[8px] text-left text-black-solid-black-880-1d1d26">
            <div
              className="w-[120px] rounded-80xl box-border flex flex-row items-center justify-center py-2 px-3 border-[1px] border-solid border-black-solid-black-880-1d1d26"
              onClick={onClose}
              ref={makeRipple}
            >
              <b className="relative leading-[20px]">{LL0().ui.cancel()}</b>
            </div>
            <div
              className="w-[120px] rounded-80xl bg-gray-solid-gray-720-8d8d8d flex flex-row items-center justify-center py-2 px-3 box-border relative gap-[8px] cursor-pointer text-white-solid-white-100-ffffff"
              onClick={openRefundAmountConfirmationP}
              ref={makeRipple}
            >
              <div
                className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-80xl [background:linear-gradient(180deg,_#2196f3,_#1f8be0)] z-[0]" />
              <b className="relative leading-[20px] z-[1]">{LL0().onlineOrder.refund()}</b>
            </div>
          </div>
        </div>
      </div>
      {isRefundAmountConfirmationPOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeRefundAmountConfirmationP}
        >
          <RefundAmountConfirmationP onClose={closeRefundAmountConfirmationP} />
        </PortalPopup>
      )}
    </>
  )
}

export default OrderHistoryRestaurantPOS1
