export function maskCardNumber(cardNumber: string) {
  // Ensure the input is a string
  cardNumber = String(cardNumber);

  // Get the length of the card number
  const length = cardNumber.length;

  // Create the masked part with asterisks
  const maskedPart = '*'.repeat(length - 4);

  // Get the last four characters of the card number
  const lastFour = cardNumber.slice(-4);

  // Combine the masked part with the last four characters
  return maskedPart + lastFour;
}