import { useSignalEffect } from '@preact/signals-react'
import type { Eruda } from 'eruda'

import { signalSyncedWithLocalStorage } from '@/react/core/reactive.ts'

let eruda: Eruda | undefined

export const [erudaEnabled, setErudaEnabled] = signalSyncedWithLocalStorage('enable-eruda', false)

/** React hook. Enable Eruda (https://github.com/liriliri/eruda) */
export function useEruda() {
  useSignalEffect(() => {
    if (erudaEnabled()) initializeEruda()
    else destroyEruda()
  })
}

export async function initializeEruda() {
  eruda = await import('eruda').then(m => m.default)
  eruda?.init()
}

export const destroyEruda = (): void => eruda?.destroy()
