import { LL0 } from '@/react/core/I18nService'
import { FunctionComponent } from 'react'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type StaffReportSoldItemsType = {
  totalItemSold?: number
  usedDiscount?: number
  totalVoucherSoldCount?: number
  totalVoucherSoldValue?: number
  totalVoucherUsedCount?: number
  totalVoucherUsedValue?: number
}

const StaffReportSoldItems: FunctionComponent<StaffReportSoldItemsType> = ({
  totalItemSold,
  usedDiscount,
  totalVoucherSoldCount,
  totalVoucherSoldValue,
  totalVoucherUsedCount,
  totalVoucherUsedValue,
}) => {
  const LL = LL0()
  return (
    <div className="self-stretch bg-gray-solid-gray-180-ebecee flex flex-col items-start justify-start py-2 px-6 gap-[8px] text-left text-mini text-gray font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
      <div className="self-stretch flex flex-row items-center justify-between">
        <b className="relative Tablet_768:text-sm Tablet_600:text-smi">{LL.staffReport.soldItem()}</b>
        <div className="relative text-base text-right Tablet_768:text-mini Tablet_600:text-sm">{totalItemSold ?? '-'}</div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between">
        <b className="relative Tablet_768:text-sm Tablet_600:text-smi">{LL.staffReport.usedDiscount()}</b>
        <div className="relative text-base text-right Tablet_768:text-mini Tablet_600:text-sm">{usedDiscount ? LL3().format.currency(usedDiscount) : '-'}</div>
      </div>
      <b className="self-stretch relative Tablet_768:text-sm Tablet_600:text-smi">{LL.staffReport.voucherGiftCard()}:</b>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 text-sm">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">{LL.staffReport.sold()} {totalVoucherSoldCount ? `(${totalVoucherSoldCount})` : ''}</div>
        <div className="relative text-base text-right Tablet_768:text-smi Tablet_600:text-xs">{totalVoucherSoldValue ? LL3().format.currency(totalVoucherSoldValue) : '-'}</div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6 text-sm">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">{LL.staffReport.used()} {totalVoucherUsedCount ? `(${totalVoucherUsedCount})` : ''}</div>
        <div className="relative text-base text-right Tablet_768:text-smi Tablet_600:text-xs">{totalVoucherUsedValue ? LL3().format.currency(totalVoucherUsedValue) : '-'}</div>
      </div>
    </div>
  )
}

export default StaffReportSoldItems
