import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'

import { createCollection } from '@/data/utils/migration.ts'

export const SRM_EVENT_LOG_COLLECTION_NAME = 'srm_event_log'

export enum SrmEvents {
  ENTER_OFFLINE = 'enterOffline',
  BACK_ONLINE = 'backOnline',
  EXPORT_DATA = 'exportData',
  WIPE_DATA = 'wipeData',
  TRAINING_MODE_ON = 'trainingModeOn',
  TRAINING_MODE_OFF = 'trainingModeOff',
  // TODO: add more event here
}

export interface SrmEventLog {
  readonly _id: string
  readonly date: number
  readonly deviceId: string
  readonly user: string
  readonly type: SrmEvents
}

export const VERSION = 7 // Increase this whenever schema changed
const schema: RxJsonSchema<SrmEventLog> = {
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    date: { type: 'number', final: true, minimum: 0, maximum: 8.64e15, multipleOf: 1 },
    deviceId: { type: 'string', final: true, maxLength: 24 },
    type: { type: 'string', final: true, maxLength: 24 },
    user: { type: 'string', final: true, maxLength: 24 },
  },
  required: ['_id', 'deviceId', 'date'],
  indexes: ['user'],
}

export const SrmEventLog = {} as RxCollection<SrmEventLog>

Object.assign(window, { SrmEventLog })

export const createSrmEventLogCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: SrmEventLog,
    collectionName: SRM_EVENT_LOG_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
    extraStrategy: {
      5: row => ({
        ...row,
        type:
          `${row.type}` === '0'
            ? SrmEvents.ENTER_OFFLINE
            : `${row.type}` === '1'
            ? SrmEvents.BACK_ONLINE
            : `${row.type}` === '2'
            ? SrmEvents.EXPORT_DATA
            : `${row.type}` === '3'
            ? SrmEvents.WIPE_DATA
            : SrmEvents.ENTER_OFFLINE,
      }),
    },
  })
}
