import ManuelTablePlugin from '@dashboard/ManuelTablePlugin.tsx'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'

import type { OrderStrip, OrderType } from '@/pos/OrderType'
import { computed, effect, mutable, signal } from '@/react/core/reactive'
import type { OrderMode } from '@/react/OrderView/OrderViewShare.ts'

export const enum PosScreen {
  BLANK = 'Blank',
  ORDER = 'OrderView',
  FLOOR_PLAN = 'FloorPlanView',
  MAIN_VIEW = 'MainView',
  VIRTUAL_PRINTER_VIEW = 'VirtualPrinterView',
  VIRTUAL_PRINTER = 'VirtualPrinter',
  EDIT_MENU = 'EditMenu',
  EDIT_FLOOR_PLAN = 'EditFloorPlan',
  EDIT_USER = 'EditUser',
  TIME_MANAGEMENT = 'TimeManagement',
  PAYMENT = 'Payment',
  EOD = 'EOD',
  ORDER_HISTORY = 'OrderHistory',
  CUSTOMER_INFO = 'CustomerInfo',
  PRINTER_SETTING = 'PrinterSetting',
  PENDING_ORDERS = 'PendingOrders',
  PAYMENT_SETTING = 'PaymentSetting',
  COMPANY_INFORMATION = 'CompanyInformation',
  MONTHLY_REPORT = 'MonthlyReport',
  LOGIN = 'Login',
  HAPPY_HOUR = 'HappyHour',
  RESERVATION = 'Reservation',
  GENERAL_SETTINGS = 'GeneralSettings',
  PASSCODE = 'Passcode',
  GENERAL_SETTING = 'GeneralSetting',
  STAFF_REPORT = 'StaffReport',
  BILL = 'Bill',
  DEVELOPER = 'Developer',
  EDIT_ONLINE_MENU = 'EditOnlineMenu',
  PAIRING = 'Pairing',
  WEB_MENU = 'WebMenu',
  MASTER_MACHINE = 'MasterMachine',
  SHIPPING_STATUS = 'ShippingStatus',
  INVENTORY = 'Inventory',
  CASHBOOK = 'Cashbook',
  SYNC_MENU = 'SyncMenu',
  KITCHEN_MONITOR = 'KitchenMonitor',
  EMAIL_REPORT = 'EmailReport',
  ONLINE_ORDER_SETTING = 'OnlineOrderSetting',
  TUTORIAL = 'Tutorial',
  MOVE_ITEM_TABLE = 'MoveTableItem',
  MANUAL_TABLE = 'ManualTable',
  SIGNIN = 'SignIn',
  NEW_DELIVERY = 'NewDelivery',
  TERMINAL = 'Terminal',
  SRM = 'Srm',
  INSTALLATION = 'Installation',
  VOUCHER = 'Voucher',
}

/** **React Signal** to store the current screen */
export const router = mutable<{ screen: PosScreen }>({ screen: PosScreen.BLANK })
const routerHistory: string[] = []

// Rcord route history
effect(() => {
  routerHistory.push(router.screen)
})

/** Extra navigation params */
interface ParamType {
  table?: string
  order?: OrderStrip
  orderMode?: OrderMode
  isMoveTable?: boolean
  takeAway?: boolean
  // new delivery
  orderType?: OrderType
}

export const [params, setParams] = signal<ParamType>()

/** **React Hook** to run a function when a screen is exited */
export const onExit = (screen: string, fn: () => any) => {
  const isScreen = useMemo(() => computed(() => router.screen === screen), [])
  useEffect(() => {
    // TODO: check this
    // if (!isScreen() && _.last(routerHistory) === screen) fn();
    if (!isScreen()) fn()
  }, [isScreen()])
}

/** **React Signal's Effect** to run when a screen is exited */
export const onExitScreens = (screens: PosScreen[], fn: () => any) => {
  const isScreen = computed(() => screens.includes(router.screen))
  effect(() => {
    // TODO: check this
    // if (!isScreen() && _.last(routerHistory) === screen) fn();
    if (!isScreen()) fn()
  })
}

/** **React Hook** to run a function when a screen is entered */
export const onEnter = (screen: string, fn: () => any) => {
  const isScreen = useMemo(() => computed(() => router.screen === screen), [])
  useEffect(() => {
    if (isScreen()) fn()
  }, [isScreen()])
}

/** **React Signal's Effect** to run when a screen is entered */
export const onEnterScreen = (screen: string, fn: () => any) => {
  const isScreen = computed(() => router.screen === screen)
  effect(() => {
    if (isScreen()) fn()
  })
}

/** **React Signal's Effect** to run when a screen is entered */
export const onEnterScreens = (screens: string[], fn: () => any) => {
  const isScreen = computed(() => screens.includes(router.screen))
  effect(() => {
    if (isScreen()) fn()
  })
}

/** **React Signal's Effect** to run when a screen is entered for the first time */
export const onMountScreen = (screen: string, fn: () => any) => {
  const isScreen = computed(() => router.screen === screen)
  let isFirstTime = true

  effect(() => {
    if (isScreen() && isFirstTime) {
      isFirstTime = false
      fn()
    }
  })
}
