import { type FunctionComponent, useCallback, useState } from "react";
import TextField from "@/react/core/TextField.tsx";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import TextFieldArea from "@/react/core/TextFieldArea.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  customerEmail,
  customerName,
  customerPhone,
  onSaveCurrentReservation,
  removeCurrentReservation,
  ReservationDetailMode,
  reservationDetailMode,
  rsvDate,
  rsvGuest,
  rsvNote, setCustomerEmail,
  setCustomerName,
  setCustomerPhone,
  setRsvDate,
  setRsvGuest,
  setRsvNote,
  setRsvTime
} from "@/data/ReservationHub.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import PortalPopup from "@reservation2/PortalPopup.tsx";
import DeleteConfirmationPopup from "@reservation2/DeleteConfirmationPopup.tsx";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

export type MakeReservationPopupType = {
  onClose?: () => void;
  onDelete?: () => void;
  onSave?: () => void;
};
const MakeReservation: FunctionComponent<MakeReservationPopupType> = ({ onDelete, onSave, onClose }) => {

  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);


  return (
    <>
      <div className='!w-full overflow-hidden relative rounded bg-white-solid-white-100-ffffff flex flex-col items-start justify-start box-border gap-[10px] h-full max-w-full max-h-full overflow-auto text-left text-sm text-black-solid-black-900-1e1e23 font-mulish'>
        <b className='self-stretch relative z-[1] p-2 text-[16px] font-bold'>{LL0().dashboard.reservation()}</b>
        <div className='no-scrollbar overflow-y-auto flex flex-col gap-3 self-stretch px-4'>
          <div className='self-stretch flex flex-row items-start justify-start gap-3'>
            <div className='flex-1 flex flex-col gap-[2px]'>
              <b className='self-stretch relative z-[1] font-bold'>
                {LL0().ui.name()}:
                <b className='text-[#D40000] text-[14px]'>*</b>
              </b>
              <TextField
                className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset] font-mulish text-sm text-black-solid-black-900-1e1e23 z-[1]"
                value={customerName()}
                onChange={e => setCustomerName(e.target.value)}
              />
            </div>
            <div className='flex-1 flex flex-col gap-[2px] items-center'>
              <b className='self-stretch relative z-[1] font-bold first-letter:uppercase'>
                {LL0().onlineOrder.newReservationDialog.guests()}:
                <b className='text-[#D40000] text-[14px]'>*</b>
              </b>
              <div className='flex flex-row gap-3 self-stretch items-center'>
                <div className='w-[30px] h-[30px] rounded-full' ref={makeRipple}
                     onClick={() => {
                       if (rsvGuest() <= 1) return;
                       setRsvGuest(v => v - 1)
                     }}>
                  <img className='w-[30px] h-[30px] object-cover shrink-0 cursor-pointer z-1'
                       alt=''
                       src='/MinusBlueIcon.png'

                  />
                </div>
                <b className='w-4 text-[17px] font-bold'>{rsvGuest()}</b>
                <div className='w-[30px] h-[30px] rounded-full'
                     ref={makeRipple}
                     onClick={() => setRsvGuest(v => v + 1)}
                >
                  <img className='w-[30px] h-[30px] object-cover shrink-0 cursor-pointer z-1'
                       alt=''
                       src='/PlusBlueIcon.png'
                  />
                </div>
              </div>
            </div>

          </div>
          <div className='self-stretch flex flex-row items-start justify-start gap-3'>
            <div className='flex-1 flex flex-col gap-[2px]'>
              <b className='self-stretch relative z-[1] font-bold'>
                {LL0().posSetup.accountTab.email()}:
                <b className='text-[#D40000] text-[14px]'>*</b>
              </b>
              <TextField
                className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset] font-mulish text-sm text-black-solid-black-900-1e1e23 z-[1]"
                value={customerEmail()}
                onChange={e => setCustomerEmail(e.target.value)}
              />
            </div>
            <div className='flex-1 flex flex-col gap-[2px]'>
              <b className='self-stretch relative z-[1] font-bold'>
                {LL0().settings.tel()}:
                <b className='text-[#D40000] text-[14px]'>*</b>
              </b>
              <TextField
                className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset] font-mulish text-sm text-black-solid-black-900-1e1e23 z-[1]"
                value={customerPhone()}
                onChange={e => setCustomerPhone(e.target.value)}
              />
            </div>

          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='self-stretch flex flex-row items-start justify-start gap-3'>
              <div className='flex-1 flex flex-col gap-[2px]'>
                <b className='self-stretch relative z-[1] font-bold'>
                  {LL0().common.date()}:
                  <b className='text-[#D40000] text-[14px]'>*</b>
                </b>
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  value={dayjs.unix(rsvDate())}
                  onChange={(newValue: any) => setRsvDate(newValue)}
                  sx={{}}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      required: false,
                      autoFocus: false,
                      error: false,
                      color: "primary",
                    },
                  }}
                />
              </div>
              <div className='flex-1 flex flex-col gap-[2px]'>
                <b className='self-stretch relative z-[1] font-bold'>
                  {LL0().payment.time()}
                </b>
                <TimePicker
                  value={dayjs.unix(rsvDate())}
                  onChange={(newValue: any) => setRsvTime(newValue)}
                  ampm={companyInfo0()?.country !== 'de'}
                  sx={{}}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      required: false,
                      autoFocus: false,
                      error: false,
                      color: "primary",
                    },
                  }}
                />
              </div>
            </div>
          </LocalizationProvider>
          <div className='self-stretch flex flex-row items-start justify-start gap-3'>
            <div className='flex-1 flex flex-col gap-[2px]'>
              <b className='self-stretch relative z-[1] font-bold'>
                {LL0().article.note()}:
              </b>
              <TextFieldArea
                // className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset] font-mulish italic font-medium text-sm text-gray-solid-gray-500-888f97"
                color="primary"
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { height: "40px" } }}
                value={rsvNote()}
                onChange={e => setRsvNote(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-1 flex-row gap-3 border-t-[1px] border-[#C9CBCC] px-[10px] pt-[10px] pb-4'>
          {reservationDetailMode() !== ReservationDetailMode.NEW &&
            <div className='h-[36px] flex-1 self-stretch flex items-center justify-center bg-[#eb5458] rounded-[6px]'
               ref={makeRipple}
               onClick={openDeleteConfirmationPopup}
          >
            <b className='text-white text-[14px]'>{LL0().ui.delete()}</b>
          </div>
          }
          <div className='h-[36px] flex-1 self-stretch flex items-center justify-center bg-[#2979ff] rounded-[6px]'
               ref={makeRipple}
               onClick={async () => {
                 await onSaveCurrentReservation()
                 onClose?.()
               }}
          >
            <b className='text-white text-[14px]'>{LL0().ui.save()}</b>
          </div>
        </div>

        <img className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[10]"
             alt=""
             src="/icongeneralclose-popup-icon--grey@2x.png"
             onClick={onClose}
        />
        {/*<TableManualKeyboard inputController={inputController0}/>*/}
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup
            onClose={closeDeleteConfirmationPopup}
            onConfirm={async () => {
              await removeCurrentReservation()
              onClose?.()
            }}
          />
        </PortalPopup>
      )}
    </>
  )
}

export default MakeReservation;