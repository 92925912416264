import { convertDocuments, type DocDeepSignal } from '@/data/data-utils'
import { effectOn, signal, useAsyncEffect } from '@/react/core/reactive'
import { dataLock } from '@/data/DataUtils.ts'
import { Inventory } from '@/data/Inventory.ts'
import { map, tap } from 'rxjs'
import debug from 'debug'
import { products0 } from '@/data/ProductHub.ts'
import { inventoryActions0 } from '@/data/InventoryActionHub.ts'

const log = debug('data:inventory')
export const [inventories0, setInventories0] = signal<Array<DocDeepSignal<Inventory>>>([]);
export const [mappedInventories, setMappedInventories] = signal<DocDeepSignal<Inventory[]>>([])
export const [filterInventoryValues, setFilterInventoryValues] = signal<any>({})

let dataLoaded = false

export const makeInventoriesAvailable = () => {
  useAsyncEffect(async () => {
    log('⚡️ Loading inventory data...')

    if (dataLoaded) return
    await dataLock.acquireAsync()
    const sub = Inventory.find({sort: [{id: 'desc'}]})
      .$.pipe(
        map(a => convertDocuments(a, true, ["product", "lastUpdated"])),
        tap(a => setInventories0(a)),
        tap(a => log('⚡️ Inventory data loaded', a)),
      )
      .subscribe()
    dataLoaded = true

    return () => sub.unsubscribe()
  })
}

effectOn([inventories0, products0, inventoryActions0], async() => {
  await dataLock.acquireAsync();
  setMappedInventories(inventories0().map(inventory => {
    inventory.product = products0().find(product => product._id === inventory.productId);
    inventory.lastUpdated = inventoryActions0().find(inventoryAction => inventoryAction.inventoryId === inventory._id)?.date;
    return inventory;
  }));
})
