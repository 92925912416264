import { FunctionComponent } from "react";

export type PaymentScreenTurnOnMasterDevType = {
  className?: string;
  onClose?: () => void;
};

const PaymentScreenTurnOnMasterDev: FunctionComponent<
  PaymentScreenTurnOnMasterDevType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[24px] h-full max-w-full max-h-full text-center text-sm text-blue-solid-blue-420-2979ff font-mulish ${className}`}
    >
      <div
        className="w-6 absolute !m-[0] top-[8px] right-[12px] h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/payment-iconclose-popup-icon--grey@2x.png"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[23px] z-[1] text-lg text-red-solid-red-550-cf2222">
        <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
          <b className="self-stretch relative">
            The master device is not turned on !
          </b>
          <div className="self-stretch relative text-base text-black-solid-black-900-1e1e23">
            Please turn on the master device if you want to continue creating
            payment(s) for this order.
          </div>
        </div>
        <div className="w-[581px] hidden flex-col items-center justify-start gap-[12px]">
          <b className="self-stretch relative">
            The amount you entered is insufficient.
          </b>
          <div className="self-stretch flex flex-row items-center justify-center gap-[4px] text-base text-black-solid-black-900-1e1e23">
            <div className="relative">Please check on:</div>
            <i className="relative font-bold">Seat 2</i>
            <div className="relative">;</div>
            <i className="relative font-bold">Seat 4</i>
          </div>
        </div>
        <div className="w-[581px] hidden flex-col items-center justify-start gap-[12px]">
          <b className="self-stretch relative">
            Still want to complete the payment process?
          </b>
          <div className="self-stretch relative text-base text-black-solid-black-900-1e1e23">{`Notice that you still have unpaid seats. `}</div>
        </div>
      </div>
      <div
        className="rounded-md bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-2 px-8 cursor-pointer z-[2] text-mini border-[1px] border-solid border-blue-solid-blue-420-2979ff"
        onClick={onClose}
      >
        <div className="relative font-semibold">OK</div>
      </div>
      <div
        className="w-[78px] rounded-md bg-white-solid-white-100-ffffff box-border h-[38px] overflow-hidden shrink-0 hidden flex-row items-center justify-center py-[9px] px-6 cursor-pointer z-[3] border-[1px] border-solid border-blue-solid-blue-420-2979ff"
        onClick={onClose}
      >
        <b className="relative">Cancel</b>
      </div>
      <div className="rounded-md bg-blue-solid-blue-420-2979ff h-[37px] overflow-hidden shrink-0 hidden flex-row items-center justify-center py-[9px] px-8 box-border z-[4] text-white-solid-white-100-ffffff">
        <b className="relative">OK</b>
      </div>
    </div>
  );
};

export default PaymentScreenTurnOnMasterDev;
