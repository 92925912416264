import { getOsFromParam } from '@/shared/utils2.ts'

/** Static variables using for tests (extract from doc SW-77-V) */
export const TESTCASE_VARS = {
  /** [Testcase Only] Mandatary Name */
  name: 'Michel Untel enr.',
  /** [Testcase Only] Mandatary Address */
  street_address: '123-456 rue Unetelle',
  /** [Testcase Only] Mandatary City */
  city: 'Montréal',
  /** [Testcase Only] Mandatary Province */
  province: 'Québec',
  /** [Testcase Only] Mandatary zip code */
  zipCode: 'H2Y 1C6',
  /** [Testcase Only] Mandatary QST Number, used in `noTVQ` field */
  qstNumber: '5678912340TQ0001',
  /** [Testcase Only] Mandatary GST Number, used in `noTPS` field */
  gstNumber: '567891234RT0001',
  /** [Testcase Only] Mandatary Billing Number, used in `noDossFO` field */
  billingNumber: 'ER0001',
  /** [Testcase Only] Mandatary Identification Number, used in Certificate request header */
  identificationNumber: '5678912340',
  /** [Testcase Only] Authentication Code, used in Certificate request header */
  authCode: 'D8T8-W8W8',
  /** [Testcase Only] Certificate Code, used in `codCertif` field */
  certificateCode: 'FOB201999999',
  /** [Testcase Only] Staff Name */
  userName: 'Michel Untel',
  /** [Testcase Only] Timezone */
  timezone: '-05:00',
} as const

export const enum TESTCASE_USERS {
  Michel = 'Michel Untel',
  John = 'John Smith',
  Joe = 'Joe Bleau',
}

export const SRM_VERSION: '1.0' | '1.1' = getOsFromParam() === 'android' ? '1.0' : '1.1'

const testCasesV0: Record<string, string[]> = {
  '001': ['001', '002', '003', '004', '005', '008'],
  '002': ['001', '002', '003', '004'],
  '003': ['001', '002', '003', '004', '005', '006', '007', '008', '009', '010', '017', '018', '019', '020'],
  '004': ['001', '002', '003', '004', '008'],
  '005': ['001', '002', '003', '004', '005', '006'],
  '006': ['001', '002', '003', '004', '005', '006', '007', '008'],
  '007': ['002', '003'],
  '008': ['001', '002', '003', '004', '005', '006'],
  '009': ['001', '005', '006', '007'],
  '010': ['008', '009', '010', '011'],
  '011': ['001', '002'],
  '026': ['001', '002'],
  '029': ['001'],
  '030': ['001', '002', '003'],
  '031': ['001', '002', '003'],
  '037': ['001', '002', '003', '004'],
  '103': ['006'],
}

const testCasesV1: Record<string, string[]> = {
  ...testCasesV0,
  '003': [...testCasesV0['003'], '011', '012', '013', '014', '015', '016'],
  '004': [...testCasesV0['004'], '007', '009', '010'],
  '009': [...testCasesV0['009'], '009'],
  '010': [...testCasesV0['010'], '016', '017', '018', '019'],
  '019': ['001', '002', '003', '004', '005'],
  '020': ['001', '002', '003', '004'],
  '021': ['001', '002', '003', '004', '005', '006'],
  '022': ['001', '002', '003', '004', '005', '006'],
  '024': ['001', '002', '003'],
  '036': ['001'],
}

/** Required testcase, extracted from Quebec's Dashboard */
export const TESTCASES = SRM_VERSION === '1.0' ? testCasesV0 : testCasesV1

export const TESTCASES_REQUIRE_DOWNLOAD = [
  '003.002',
  '003.005',
  '003.008',
  '003.009',
  '003.010',
  '004.004',
  '005.003',
  '006.002',
  '008.001',
  '009.001',
  '009.005',
  '011.001',
  '011.002',
  '019.002',
  '019.003',
  '020.003',
  '020.004',
  '021.003',
  '021.004',
  '022.003',
  '022.004',
  '026.001',
  '030.001',
  '030.002',
  '031.001',
  '031.002',
  '031.003',
  '036.001',
  '103.006',
]
