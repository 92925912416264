import { type DocDeepSignal } from '@/data/data-utils'
import { effectOn, signal, useAsyncEffect } from '@/react/core/reactive'
import { dataLock } from '@/data/DataUtils.ts'
import { InventoryAction } from '@/data/InventoryAction.ts'
import { tap } from 'rxjs'
import debug from 'debug'

const log = debug('data:inventory')

const INVENTORY_ACTIONS_PER_PAGE = 10;
export const [filterDateStockHistory, setFilterDateStockHistory] = signal<{ from: number, to: number, type?: string } | undefined>();
export const [inventoryActions0, setInventoryActions0] = signal<Array<DocDeepSignal<InventoryAction>>>([]);
export const [inventoryActions, setInventoryActions] = signal<Array<DocDeepSignal<InventoryAction>>>([]);
export const [inventoryActionsPage, setInventoryActionsPage] = signal<number>(0);
export const [isReachingEnd, setIsReachingEnd] = signal<boolean>(false);
export const [isValidatingInventoryActions, setIsValidatingInventoryActions] = signal<boolean>(true);
export const [inventoryActionsV, setInventoryActionsV] = signal<number>(0);

export const [inventoryActionsId, setInventoryActionsId] = signal<string | undefined>();

let dataLoaded = false
export const makeInventoryActionsAvailable = () => {
  useAsyncEffect(async () => {
    log('⚡️ Loading inventory action data...')

    if (dataLoaded) return;
    await dataLock.acquireAsync()
    //  since inventory actions is immutable, we do not need to "convertDocuments"
    // sort by date, from latest to oldest
    const sub = InventoryAction.find()
      .$.pipe(
        tap(a => setInventoryActions0(a.sort((item1, item2) => item2.date - item1.date))),
        tap(a => log('⚡️ Inventory action data loaded', a))
      )
      .subscribe()
    dataLoaded = true

    return () => sub.unsubscribe()
  })
}

effectOn([inventoryActionsV, inventoryActionsPage], async () => {

  await dataLock.acquireAsync();
  const _inventoryActions = await InventoryAction.find({
    sort: [{ date: 'desc' }],
    selector: {
      inventoryId: inventoryActionsId() ? inventoryActionsId() : { $exists: true },
      date: filterDateStockHistory() ? { $gt: filterDateStockHistory()?.from / 1000, $lt: filterDateStockHistory()?.to / 1000 } : {$exists: true}
    },
    limit: INVENTORY_ACTIONS_PER_PAGE,
    skip: (inventoryActionsPage() - 1) * INVENTORY_ACTIONS_PER_PAGE
  }).exec()
  setIsReachingEnd(_inventoryActions.length === 0)

  if (inventoryActionsPage() === 1) {
    setInventoryActions([..._inventoryActions])
  } else {
    setInventoryActions(prev => [...prev, ..._inventoryActions])
  }
  setIsValidatingInventoryActions(false)

  InventoryAction.$.subscribe(c => {
    setInventoryActionsPage(0)
  })

}, { defer: true })

export const makeInventoryActionsPageAvailable = () => {
  useAsyncEffect(async () => {
    if (inventoryActionsPage() === 0) {
      await dataLock.acquireAsync()
      setInventoryActionsPage(1)
    }
  }, [inventoryActionsPage(), filterDateStockHistory()])
}
export const makeInventoryActionsFilterAvailable = () => {
  useAsyncEffect(async () => {
    setInventoryActionsV(v => v + 1)
    setInventoryActionsPage(0)
    setIsValidatingInventoryActions(true)
  }, [inventoryActionsId(), filterDateStockHistory()])
}

