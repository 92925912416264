import type { RxDatabase } from 'rxdb'

import { ONLINE_ORDER_COLLECTION_NAME, OnlineOrder } from './OnlineOrder'
import { createOrderSchema } from './Order'
import { createCollection } from './utils/migration'

export const createOnlineOrderCollection = async <T>(database: RxDatabase<T>) => {
  const onlineOrderSchema = createOrderSchema('online order schema')
  return await createCollection({
    database,
    collection: OnlineOrder,
    collectionName: ONLINE_ORDER_COLLECTION_NAME,
    version: onlineOrderSchema.version,
    schema: onlineOrderSchema,
  })
}
