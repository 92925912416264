import { FunctionComponent, memo, useCallback, useState } from 'react'
import EndOfDayXReportPopup from '@eod/EndOfDayXReportPopup'
import PortalPopup from '@eod/PortalPopup'
import EndOfDayPopupConfirm from '@eod/EndOfDayPopupConfirm'
import EndOfDayLeft from '@eod/EndOfDayLeft'
import EndOfDayRight from '@eod/EndOfDayRight'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { date0, needReprint, needZKey, onBack, onReprintZ } from '@/react/EndOfDayView/EndOfDayView.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import PrintIconUrl from '../../public/iconprint-icon@2x.png'
import { confirmRePrintZ } from "@/react/EndOfDayView/eod-dialog.tsx";
import dayjs from "dayjs";
import { LL2 } from "@/react/core/I18nBackend.tsx";


const EndOfDay: FunctionComponent = () => {
  const [isEndOfDayXReportPopupOpen, setEndOfDayXReportPopupOpen] = useState(false)
  const [isEndOfDayPopupConfirmOpen, setEndOfDayPopupConfirmOpen] = useState(false)

  const openEndOfDayXReportPopup = useCallback(() => {
    setEndOfDayXReportPopupOpen(true)
  }, [])

  const closeEndOfDayXReportPopup = useCallback(() => {
    setEndOfDayXReportPopupOpen(false)
  }, [])

  const openEndOfDayPopupConfirm = useCallback(() => {
    setEndOfDayPopupConfirmOpen(true)
  }, [])

  const closeEndOfDayPopupConfirm = useCallback(() => {
    setEndOfDayPopupConfirmOpen(false)
  }, [])

  return (
    <>
      <div
        className="eod-screen bg-basic-color-white w-full h-full overflow-hidden flex flex-col items-start justify-start text-center text-sm text-basic-color-white font-mulish">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start">
          <EndOfDayLeft />
          <EndOfDayRight />
        </div>
        <div className="self-stretch bg-dimgray flex flex-row items-start justify-between py-2 px-4">
          <div className="h-[34px] flex flex-row items-center justify-start" ref={makeRipple} onClick={onBack}>
            <div
              className="rounded-81xl bg-royalblue-200 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[87px]">
              <img className="relative w-[30px] h-[30px] object-cover" alt="" src="/iconback-icon@2x.png" />
              <b className="flex-1 relative leading-[17px]">{LL0().onlineOrder.back()}</b>
            </div>
          </div>
          <div
            className="flex flex-row items-center justify-end gap-[12px] text-text-color-black-1e1e23 Tablet_768:gap-[6px] Tablet_600:gap-[4px]">
            {/*<div*/}
            {/*  className="rounded-81xl [background:linear-gradient(180deg,_#ffc7e4,_#fa80be)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"*/}
            {/*  ref={makeRipple}>*/}
            {/*  <img className="relative w-[30px] h-[30px] object-cover Tablet_600:hidden" alt="" src="/icongrocery-icon@2x.png" />*/}
            {/*  <b className="relative leading-[17px] Tablet_768:text-smi">{LL0().report.ingredientReport()}</b>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className="rounded-81xl [background:linear-gradient(180deg,_#c0ed83,_#8ad81f)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"*/}
            {/*  ref={makeRipple}>*/}
            {/*  <img className="relative w-[30px] h-[30px] object-cover Tablet_600:hidden" alt="" src="/iconemail-icon@2x.png" />*/}
            {/*  <b className="relative leading-[17px] Tablet_768:text-smi">{LL0().delivery.customer.email()}</b>*/}
            {/*</div>*/}
            {needZKey() &&
              <>
                <div
                  className="rounded-81xl [background:linear-gradient(180deg,_#daffff,_#83dede)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] cursor-pointer"
                  onClick={openEndOfDayXReportPopup}
                  ref={makeRipple}>
                  <img className="relative w-[30px] h-[30px] object-cover Tablet_600:hidden" alt=""
                       src="/iconreport-icon@2x.png" />
                  <b className="relative leading-[17px] Tablet_768:text-smi">{LL0().report.xReport()}</b>
                </div>
                <div
                  className="rounded-81xl [background:linear-gradient(180deg,_#ff8266,_#e44e2c)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] cursor-pointer"
                  onClick={openEndOfDayPopupConfirm}
                  ref={makeRipple}>
                  <img className="relative w-[30px] h-[30px] object-cover Tablet_600:hidden" alt=""
                       src={PrintIconUrl} />
                  <b className="relative leading-[17px] Tablet_768:text-smi">{LL0().report.zReport()}</b>
                </div>
              </>
            }
            {(needReprint() && !needZKey()) &&
              <>
                <div
                  className="rounded-81xl [background:linear-gradient(180deg,_#c0ed83,_#89d81f)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] cursor-pointer"
                  onClick={async ()=>{
                      await confirmRePrintZ(dayjs(date0()).format(LL2().dates.dateFormat()), onReprintZ)
                  }}
                  ref={makeRipple}>
                  <img className="relative w-[30px] h-[30px] object-cover Tablet_600:hidden" alt=""
                       src={PrintIconUrl} />
                  <b className="relative leading-[17px] Tablet_768:text-smi">{LL0().report.zReport()}</b>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      {isEndOfDayXReportPopupOpen && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={closeEndOfDayXReportPopup}>
          <EndOfDayXReportPopup onClose={closeEndOfDayXReportPopup} />
        </PortalPopup>
      )}
      {isEndOfDayPopupConfirmOpen && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={closeEndOfDayPopupConfirm}>
          <EndOfDayPopupConfirm onClose={closeEndOfDayPopupConfirm} />
        </PortalPopup>
      )}
    </>
  )
}

export default memo(EndOfDay)
