import type { MangoQuerySelector } from 'rxdb'

import type { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { UserRole } from '@/data/User'
import { loginUser } from '@/data/UserSignal.ts'
import { computed } from '@/react/core/reactive'

import { isBackupSucceeded } from '../logic'

export const transactionLogSelector = computed<MangoQuerySelector<SrmTransactionLog>>(() => ({
  ...(loginUser()?.name && loginUser()?.role === UserRole.STAFF ? { user: { $eq: loginUser()?.name } } : {}),
}))
export const isManager = computed(() => [UserRole.MANAGER, UserRole.ADMIN].includes(loginUser()?.role ?? UserRole.STAFF))
export const dataClearable = computed(() => isManager() && isBackupSucceeded())
