import dayjs from 'dayjs'
import uuid from 'time-uuid'

import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { SrmEventLog, SrmEvents } from '@/data/SrmEventLog'

export async function recordSrmEvent(type: SrmEvents, user: string) {
  const { deviceId } = deviceSetting0()?.srm ?? {}
  if (!deviceId) throw new Error('Missing deviceId!')

  return await SrmEventLog.insert({
    _id: uuid(),
    deviceId,
    date: dayjs().unix(),
    type,
    user,
  })
}

export async function isCurrentlyOffline() {
  const latestOfflineEvent = await SrmEventLog.findOne({ selector: { type: SrmEvents.ENTER_OFFLINE }, sort: [{ date: 'desc' }] }).exec()
  const latestOnlineEvent = await SrmEventLog.findOne({ selector: { type: SrmEvents.BACK_ONLINE }, sort: [{ date: 'desc' }] }).exec()
  if (!latestOfflineEvent) return false
  if (!latestOnlineEvent) return true
  if (latestOfflineEvent.date > latestOnlineEvent.date) return true
  return false
}
