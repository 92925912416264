import { type FunctionComponent, useState, useCallback } from 'react'
import { Switch, FormControlLabel } from '@mui/material'
import UserSettingSelectUserAvatarModal from './UserSettingSelectUserAvatarModal'
import PortalPopup from './PortalPopup'
import DeleteConfirmationPopup from './DeleteConfirmationPopup'
import UserPermissionsForm from './UserPermissionsForm'
import { loading, onSaveData, removeCurrentUser, selectedUser, userSettingsSwitchProps } from '@/react/UserSettingView/UserSettingView'
import _ from 'lodash'
import TextField from '@/react/core/TextField'
import { makeRipple } from '@/react/core/ripple-utils'
import {LL0} from "@/react/core/I18nService.tsx";
import SetRole from "@user-setting/SetRole.tsx";

import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";

const UserSettingRight: FunctionComponent = () => {
  const [isUserSettingSelectUserAvataOpen, setUserSettingSelectUserAvataOpen] = useState(false)
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] = useState(false)

  const openUserSettingSelectUserAvata = useCallback(() => {
    setUserSettingSelectUserAvataOpen(true)
  }, [])

  const closeUserSettingSelectUserAvata = useCallback(() => {
    setUserSettingSelectUserAvataOpen(false)
  }, [])

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true)
  }, [])

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false)
  }, [])

  const handleDeleteConfirmed = useCallback(async () => {
    closeDeleteConfirmationPopup()
    await removeCurrentUser()
  }, [])

  const handleAvatarSelected = useCallback(async (avatar: string | undefined) => {
    _.assign(selectedUser(), { avatar })
    closeUserSettingSelectUserAvata()
  }, [])

  return (
    <>
      {selectedUser() && (
        <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-6 px-0 box-border gap-[20px_0px] h-full text-left text-base text-black-solid-black-880-1d1d26 font-mulish sm:gap-[16px_0px] Tablet_768:gap-[12px_0px] Tablet_600:h-full">
          <div className="self-stretch flex flex-col items-start justify-start py-0 px-6 gap-[10px_0px] text-xs sm:pl-4 sm:pr-4 sm:box-border Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:pl-2.5 mq320:pr-2.5 mq320:box-border">
            <div className="self-stretch flex flex-row items-start justify-between text-base">
              <b className="relative sm:text-smi">{LL0().settings.editUser()}</b>
              <SetRole />
            </div>
            <div className="flex flex-row items-start justify-start gap-[0px_4px] text-center text-sm text-blue-solid-blue-900-1c1f37">
              {isQuebecSrmEnabled() && (!selectedUser()?.srmSynced && !selectedUser()?.srmSyncingError ?
              <div className="!bg-[#d9d9ff] rounded bg-purple-solid-purple-130-d9d9ff shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] h-10 flex flex-row items-center justify-center py-0 px-2.5 box-border">
                <div className="relative leading-[12px] font-medium sm:text-smi Tablet_768:text-smi Tablet_600:text-smi Mobile_480:text-xs mq320:text-xs">
                  {LL0().settings.userSettings.srmStatusWaitingToBeSent()}
                </div>
              </div>
              :
              <>
               {!selectedUser()?.srmSyncingError ?
                <div className="!bg-[#bef9c0] rounded bg-green-solid-green-90-bef9c0 shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] h-10 flex flex-row items-center justify-center py-0 px-2.5 box-border">
                  <div className="relative leading-[12px] font-medium sm:text-smi Tablet_768:text-smi Tablet_600:text-smi Mobile_480:text-xs mq320:text-xs">
                    {LL0().settings.userSettings.srmStatusValid()}
                  </div>
                </div>
                :
                <div className="!bg-[#ffdbdb] rounded bg-red-solid-red-90-ffdada shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] h-10 flex flex-row items-center justify-center py-0 px-2.5 box-border">
                  <div className="relative leading-[12px] font-medium sm:text-smi Tablet_768:text-smi Tablet_600:text-smi Mobile_480:text-xs mq320:text-xs">
                    {LL0().settings.userSettings.srmStatusInvalid()} {selectedUser()?.srmSyncingError}
                  </div>
                </div>
               }
              </>
              )}
            </div>
            <div className="self-stretch flex flex-row items-end justify-start gap-[0px_44px] sm:gap-[0px_36px] Tablet_768:gap-[0px_30px] Tablet_600:h-auto Tablet_600:gap-[0px_28px] Tablet_600:items-end Tablet_600:justify-start Tablet_600:min-w-[460px] Mobile_480:gap-[0px_20px] mq320:gap-[0px_16px]">
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[5px_0px] Tablet_600:flex-1 Tablet_600:gap-[6px_0px]">
                <div className="relative leading-[16px] mix-blend-normal">{LL0().settings.name()}</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  variant="outlined"
                  value={selectedUser()?.name}
                  onChange={e => _.assign(selectedUser(), { name: e.target.value })}
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[12px_0px] text-sm Tablet_600:flex-1 Tablet_600:self-stretch Tablet_600:h-auto">
                <div className="relative">{LL0().settings.userAvatar()}</div>
                <div
                  className="flex flex-row items-center justify-start gap-[0px_18px] cursor-pointer text-right text-blue-solid-blue-460-1471ff"
                  onClick={openUserSettingSelectUserAvata}
                >
                  <img
                    className="w-10 relative rounded-981xl h-10 object-cover"
                    alt={selectedUser()?.name}
                    src={selectedUser()?.avatar}
                  />
                  <b
                    className="relative cursor-pointer"
                    onClick={openUserSettingSelectUserAvata}
                  >
                    {LL0().ui.change()}
                  </b>
                </div>
              </div>
            </div>
            <div
              className="self-stretch flex flex-row items-end justify-start gap-[0px_44px] sm:gap-[0px_36px] Tablet_768:gap-[0px_30px] Tablet_600:gap-[0px_28px] Tablet_600:min-w-[460px] Mobile_480:gap-[0px_20px] mq320:gap-[0px_16px]">
              <div className="flex-1 flex flex-col items-start justify-center gap-[5px_0px]">
                <div className="relative leading-[16px] mix-blend-normal">{LL0().settings.passcode()}</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  variant="outlined"
                  value={selectedUser()?.passcode}
                  onChange={e => _.assign(selectedUser(), { passcode: e.target.value })}
                />
              </div>
              <div
                className="flex-1 flex flex-row items-start justify-start gap-[16px] text-smi text-black-solid-black-1000-000000 sm:gap-[16px_10px] Tablet_768:gap-[16px_10px] Tablet_600:gap-[16px_8px] Mobile_480:gap-[16px_6px] mq320:gap-[16px_6px]">
                <div
                  className="cursor-pointer rounded bg-gray-solid-gray-145-f9fafb shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] h-10 overflow-hidden flex flex-row items-center justify-center py-0 pr-[9px] pl-1 box-border gap-[0px_2px]"
                  ref={makeRipple}
                  onClick={onSaveData}
                >
                  <img
                    className="w-[30px] relative h-[30px] object-cover"
                    alt=""
                    src="/iconusersettingsave-data-icon@2x.png"
                  />
                  <b
                    className="relative sm:text-xs Tablet_768:text-xs Tablet_600:text-3xs Mobile_480:text-3xs height_414:text-smi mq320:text-5xs">
                    {LL0().settings.userSettings.saveData()}
                  </b>
                </div>
                <button
                  className="rounded bg-gray-solid-gray-145-f9fafb shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] h-10 overflow-hidden flex flex-row items-center justify-center py-0 pr-1.5 pl-0.5 box-border cursor-pointer"
                  onClick={openDeleteConfirmationPopup}
                  ref={makeRipple}
                  type="button"
                >
                  <img
                    className="w-[30px] relative h-[30px] object-cover"
                    alt=""
                    src="/iconusersettingdelete-icon@2x.png"
                  />
                  <b
                    className="relative sm:text-xs Tablet_768:text-xs Tablet_600:text-3xs Mobile_480:text-3xs mq320:text-5xs">
                    {LL0().msgBox.deleteUser.title()}
                  </b>
                </button>
              </div>
            </div>
          </div>
          <div
            className="self-stretch relative box-border h-px shrink-0 border-t-[1px] border-solid border-gray-solid-gray-360-d6d5d5" />
          <UserPermissionsForm />
          <div
            className="self-stretch relative box-border h-px shrink-0 border-t-[1px] border-solid border-gray-solid-gray-360-d6d5d5" />
          <div
            className="self-stretch overflow-hidden shrink-0 flex flex-col items-start justify-start py-0 px-6 gap-[16px_0px] sm:gap-[8px_0px] sm:pl-4 sm:pr-4 sm:box-border Tablet_768:gap-[6px_0px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:gap-[4px_0px] Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:pl-2.5 mq320:pr-2.5 mq320:box-border">
            <b className="relative sm:text-smi">{LL0().settings.permissionsForOnlineOrder()}:</b>
            <div
              className="self-stretch flex flex-row items-start justify-start gap-[0px_44px] sm:gap-[0px_36px] Tablet_768:gap-[0px_30px] Tablet_600:gap-[0px_28px] Mobile_480:gap-[0px_20px] mq320:gap-[0px_16px]">
              <div className="flex-1 flex flex-col items-start justify-center gap-[10px_0px]">
                <div className="self-stretch overflow-hidden flex flex-row items-center justify-start gap-[4px]">
                  <div className="w-12 relative h-6">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                      control={
                        <Switch
                          color="primary"
                          size="medium"
                          {...userSettingsSwitchProps('viewOnlineOrderDashboard')}
                        />
                      }
                    />
                  </div>
                  <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
                    {LL0().settings.orderDashboard()}
                  </div>
                </div>
                <div className="self-stretch overflow-hidden flex flex-row items-center justify-start gap-[4px]">
                  <div className="w-12 relative h-6">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                      control={
                        <Switch
                          color="primary"
                          size="medium"
                          {...userSettingsSwitchProps('viewOnlineOrderMenu')}
                        />
                      }
                    />
                  </div>
                  <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
                    {LL0().settings.onlineOrderMenu()}
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-center gap-[10px_0px]">
                <div className="self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px]">
                  <div className="w-12 relative h-6">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                      control={
                        <Switch
                          color="primary"
                          size="medium"
                          {...userSettingsSwitchProps('viewOrder')}
                        />
                      }
                    />
                  </div>
                  <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
                    {LL0().settings.viewCompletedDeclinedOrders()}
                  </div>
                </div>
                <div className="self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px]">
                  <div className="w-12 relative h-6">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                      control={
                        <Switch
                          color="primary"
                          size="medium"
                          {...userSettingsSwitchProps('viewReservation')}
                        />
                      }
                    />
                  </div>
                  <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
                    {LL0().sidebar.reservation()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="self-stretch relative box-border h-px shrink-0 border-t-[1px] border-solid border-gray-solid-gray-360-d6d5d5" />
          {isQuebecSrmEnabled() &&
            <div
              className="self-stretch overflow-hidden shrink-0 flex flex-col items-start justify-start py-0 px-6 gap-[14px] sm:gap-[8px_14px] sm:pl-4 sm:pr-4 sm:box-border Tablet_768:gap-[6px_14px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:gap-[4px_14px] Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:pl-2.5 mq320:pr-2.5 mq320:box-border">
              <b className="relative sm:text-smi">
                {LL0().settings.userSettings.registrationNumber()}
              </b>
              <div
                className="self-stretch flex flex-col items-start justify-end gap-[14px] text-xs sm:gap-[80px_14px] Tablet_768:gap-[40px_14px] Tablet_600:h-auto Tablet_600:gap-[20px_14px] Tablet_600:items-end Tablet_600:justify-start Tablet_600:min-w-[460px]">
                <div
                  className="self-stretch h-[61px] flex flex-col items-start justify-center gap-[5px] Tablet_600:self-stretch Tablet_600:w-auto Tablet_600:gap-[6px_5px]">
                  <div className="relative leading-[16px] mix-blend-normal">
                    {LL0().settings.userSettings.tpsNumber()}
                  </div>
                  <TextField
                    className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
                    color="primary"
                    size="small"
                    placeholder={LL0().settings.userSettings.tpsNumber()}
                    variant="outlined"
                    sx={{ "& .MuiInputBase-root": { height: "40px" } }}
                    value={selectedUser()?.noTPS}
                    onChange={e => _.assign(selectedUser(), { noTPS: e.target.value })}
                  />
                </div>
                <div
                  className="self-stretch h-[61px] flex flex-col items-start justify-center gap-[5px] Tablet_600:self-stretch Tablet_600:w-auto Tablet_600:gap-[6px_5px]">
                  <div className="relative leading-[16px] mix-blend-normal">
                    {LL0().settings.userSettings.tvqNumber()}
                  </div>
                  <TextField
                    className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
                    color="primary"
                    size="small"
                    placeholder={LL0().settings.userSettings.tvqNumber()}
                    variant="outlined"
                    sx={{ "& .MuiInputBase-root": { height: "40px" } }}
                    value={selectedUser()?.noTVQ}
                    onChange={e => _.assign(selectedUser(), { noTVQ: e.target.value })}
                  />
                </div>
              </div>
            </div>}
        </div>
      )}
      {isUserSettingSelectUserAvataOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeUserSettingSelectUserAvata}
        >
          <UserSettingSelectUserAvatarModal
            onClose={closeUserSettingSelectUserAvata}
            selected={selectedUser()?.avatar}
            onOk={handleAvatarSelected}
          />
        </PortalPopup>
      )}
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup
            onClose={closeDeleteConfirmationPopup}
            onConfirmed={handleDeleteConfirmed}
            message={LL0().msgBox.deleteUser.content({username: selectedUser()?.name})}
            loading={loading()}
          />
        </PortalPopup>
      )}
    </>
  )
}

export default UserSettingRight
