import type { FunctionComponent } from 'react'
import { inventory0 } from '@/react/InventoryView/InventoryView.tsx'
import _ from 'lodash'
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import Input, { inputController0 } from '@/react/core/Input.tsx'
import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { materialCategories } from '@/data/CategoryHub.ts'
import { editInventoryMode, EditInventoryMode } from '@inventory/Popups/EditItemPopup/InventoryAddEditItemPopu.tsx'
import {LL0} from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";

type EditItemFormType = {
  showEditItemForm?: boolean;
  onClickSave?: () => void;
};

const EditItemForm: FunctionComponent<EditItemFormType> = ({
  showEditItemForm,
  onClickSave
}) => {
  return (
    showEditItemForm && (
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-center justify-start py-2 px-0 gap-[8px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
        <div className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch flex flex-row items-start justify-start gap-[8px] Tablet_600:flex-row Tablet_600:gap-[8px]">
            <div className="flex-1 flex flex-col items-start justify-center gap-[4px] w-1/2">
              <div className="relative font-medium md:text-xs">{LL0().article.name()}:</div>
              <Input
                className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
                refInputController={i => {
                  i.setFocus(true);
                  i.moveCaretToEnd();
                }}
                value={inventory0()?.product?.name || ""}
                onChange={(v) => _.assign(inventory0()?.product, {name: v})}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[4px] w-1/2">
              <div className="relative font-medium md:text-xs">{LL0().inventory.price()}:</div>
              <Input
                className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
                value={(inventory0()?.product?.price ?? 0) + ''}
                onChange={(v) => _.assign(inventory0()?.product, {price: parseFloat(v || '0')})}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
            <div className="flex-1 flex flex-col items-start justify-center gap-[4px]">
              <div className="relative font-medium md:text-xs">{LL0().article.unit()}:</div>
                <FormControl
                  className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
                  variant="outlined"
                >
                  <InputLabel color="primary" />
                  <Select color="primary" size="small"
                          value={inventory0()?.unit || "piece"}
                          onChange={e => _.assign(inventory0(), {unit: e.target.value})}
                  >
                    <MenuItem value="piece">{LL0().inventory.units.piece()} (pc)</MenuItem>
                  <MenuItem value="g">{LL0().inventory.units.g()}</MenuItem>
                  <MenuItem value="kg">{LL0().inventory.units.kg()}</MenuItem>
                  <MenuItem value="ml">{LL0().inventory.units.ml()}</MenuItem>
                  <MenuItem value="l">{LL0().inventory.units.l()}</MenuItem>
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[4px]">
              <div className="relative font-medium md:text-xs">{LL0().inventory.threshold()}:</div>
              <Input
                className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
                  value={inventory0()?.threshold?.toString() ?? ""}
                  onChange={v => {
                    const convertedNumber = parseFloat(v.replace(",", "."));
                    if (isNaN(convertedNumber)) return;
                    _.assign(inventory0(), { threshold: convertedNumber})
                }}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-center gap-[4px]">
            <div className="relative font-medium md:text-xs">
              <span>{LL0().productViewSetting.category()}</span>
              <span className="text-red">*</span>
              <span>:</span>
            </div>
            <FormControl
              className="self-stretch font-mulish font-medium text-sm text-black-solid-black-1000-000000"
              variant="outlined"
            >
              <InputLabel color="primary" />
              <Select color="primary" size="small"
                      multiple
                      value={inventory0()?.product?.categories || []}
                      onChange={e => _.assign(inventory0()?.product, {categories: e.target.value})}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={materialCategories().find(category => category._id === value)?.name} />
                          ))}
                        </Box>
                      )}
              >
                { materialCategories().map(({_id, name}) =>
                  <MenuItem value={_id} key={_id}>{name}</MenuItem>
                ) }
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
        </div>
        <TableManualKeyboard
          value=''
          className={'Tablet_600:min-w-[416px]'}
          inputController={inputController0}
          onEnter={onClickSave}
        />
      </div>
    )
  );
};

export default EditItemForm;
