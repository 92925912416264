import type {FunctionComponent} from "react";
import {
  InputAdornment,
  Icon,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ModifierTypeFieldset from "./ModifierTypeFieldset";
import TextField from "@/react/core/TextField";
import { VIEW__MODIFIER_FORM } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import {LL0} from "@/react/core/I18nService.tsx";

const ModifierMainFieldset: FunctionComponent = () => {
  return (
    <div className="flex-1 rounded-2xl bg-gray-solid-gray-80-fafafa box-border shrink-0 flex flex-row items-start justify-center p-4 gap-[16px] h-auto text-left text-lg text-gray-solid-gray-900-2e2e48-200 font-mulish-button-name-bold border-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 hover:bg-gray-solid-gray-80-fafafa hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[16px] hover:items-start hover:justify-center hover:rounded-2xl hover:p-4 hover:box-border hover:border-[1px] hover:border-solid hover:border-gray-solid-gray-300-e1e1e1-200 lg:gap-[16px] md:gap-[16px] sm:gap-[16px] mq768:gap-[16px] mq600:gap-[16px] mq480:gap-[16px]">
      <div className="flex-1 flex flex-col items-center justify-start gap-[22px] lg:gap-[20px] sm:gap-[16px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold lg:text-mid md:text-base height_414:text-smi">
            {LL0().editOnlineMenu.optionName()}
          </div>
          <TextField
            className="self-stretch bg-white"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            value={VIEW__MODIFIER_FORM.nameValue()}
            onChange={VIEW__MODIFIER_FORM.nameChanged}
          />
        </div>
        <ModifierTypeFieldset />
      </div>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[22px] lg:gap-[20px] sm:gap-[16px]">
        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-3 gap-[18px] mq768:gap-[8px] height_414:gap-[10px]">
          <div className="relative font-semibold lg:text-mid md:text-base height_414:text-smi">
            {LL0().editOnlineMenu.optionRequired()}
          </div>
          <div className="relative w-10 h-5">
            <FormControlLabel
              label=""
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
              control={<Switch checked={!!VIEW__MODIFIER_FORM.mandatoryValue()} onChange={VIEW__MODIFIER_FORM.mandatoryChanged} color="primary" />}
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold lg:text-mid md:text-base height_414:text-smi">
            {LL0().editOnlineMenu.minSelected()}
          </div>
          <TextField
            className="self-stretch bg-white"
            color="primary"
            size="small"
            placeholder="0"
            fullWidth={true}
            variant="outlined"
            value={VIEW__MODIFIER_FORM.minSelectedValue()}
            onChange={VIEW__MODIFIER_FORM.minSelectedChanged}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold lg:text-mid md:text-base height_414:text-smi">
            {LL0().editOnlineMenu.freeSelected()}
          </div>
          <TextField
            className="self-stretch bg-white"
            color="primary"
            size="small"
            placeholder="0"
            fullWidth={true}
            variant="outlined"
            value={VIEW__MODIFIER_FORM.freeSelectedValue()}
            onChange={VIEW__MODIFIER_FORM.freeSelectedChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default ModifierMainFieldset;
