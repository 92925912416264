import { convertDocuments, DocDeepSignal } from '@/data/data-utils'
import { Room } from '@/data/Room'
import { effectOn, signal, useAsyncEffect } from '@/react/core/reactive'
import { dataLock } from '@/data/DataUtils.ts'

export const [rooms0, setRooms0] = signal<Array<DocDeepSignal<Room>>>([])
export const [roomV, setRoomV] = signal(0)
effectOn(
  [roomV],
  async () => {
    await dataLock.acquireAsync()
    const _rooms = await Room.find().exec()
    const rooms = convertDocuments<Room>(_rooms, true)
    setRooms0(rooms)
  },
  { defer: true }
)

export const makeRoomsAvailable = () => {
  useAsyncEffect(async () => {
    if (roomV() === 0) {
      await dataLock.acquireAsync()
      Room.$.subscribe(change => {
        setRoomV(v => v + 1)
      })
      setRoomV(1)
    }
  }, [roomV()])
}
