import { rnHost } from "@/shared/webview/rnwebview.ts";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { convertToBase64Png, createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import { type Raster } from "@/shared/printer/pure-image-printer-parallel";
import { currentPrinter } from "@/react/Printer/PrinterSettingView.tsx";
import { captureException } from "@sentry/react";

export async function printBluetooth(address: string, base64: string) {
  try {
    await rnHost.printBluetooth(address, base64, 560);
  } catch (e: any) {
    captureException(new Error(`print to bluetooth: error ${e.message}`), { tags: { type: 'print' } });
    notificationToast("Connection failed : " + (e as Error).message, {
      type: "error",
      autoClose: 1000 * 60
    })
    captureException(e);
  }
}

export const testBluetoothPrinter = async () => {
  const address = currentPrinter()?.address?.split("/")[0];
  if (!address) return;
  const printer = createPrinter();
  await printer.println("TEST");
  const raster: Raster = (await printer.print())!;
  const base64 = await convertToBase64Png(raster, false);

  await printBluetooth(address, base64);
}