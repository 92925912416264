import { Fragment, type FunctionComponent, useCallback, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import PopupDeleteConfirmation from '../Popup/PopupDeleteConfirmation';
import PortalPopup from '../Popup/PortalPopup';
import type { Menu } from '@/data/Menu.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import type { Product } from '@/data/Product.ts';
import { STATES, VIEW__CATEGORY, VIEW__PRODUCT } from '@/react/EditOnlineMenuView/EditOnlineMenuView.logic.ts';
import TextField from '@/react/core/TextField.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { useComputed } from '@/react/core/reactive.ts';
import clsx from 'clsx';
import RxAttachmentImage from '@/react/RxAttachmentImage.tsx';
import OnlineMenuPrinterTaxSetup from "../OnlineMenuPrinterTaxSetup";
import { LL3 } from '@/react/core/I18nCurrency';

type CategoryListRowType = {
  categoryName?: string;
  tax?: string;
  price?: string;
  showModifiers?: boolean;
  itemDescription?: string;
  itemName?: string;
  isHaveNoItem?: boolean;
  isAnItem?: boolean;
  iD?: string;
  isExpanded?: boolean;

  model: Menu;
  onToggleEdit: () => void;
  onDelete: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onToggleExpand: () => void;
  onCreateItem: () => void;
  isEditing?: boolean;
  onNameChanged: (value: string) => void;
};

const CategoryListRow: FunctionComponent<CategoryListRowType> = ({
                                                                   model,
                                                                   onToggleEdit,
                                                                   onDelete,
                                                                   onMoveUp,
                                                                   onMoveDown,
                                                                   onToggleExpand,
                                                                   onCreateItem,
                                                                   isEditing,
                                                                   onNameChanged,
                                                                    isExpanded
                                                                 }) => {
  const LL = LL0()
  const [stagingProduct, setStagingProduct] = useState<Product | undefined>()
  const items = useComputed(() => STATES.products().filter(({categories}) => model._id && categories?.includes(model._id)))

  const isAnItem = !!items().length;
  const isHaveNoItem = !items().length;

  const [isMoreButtonExpanded, setIsMoreButtonExpanded] = useState<boolean>(false);
  const [isEditItemDeleteConfirmPoOpen, setEditItemDeleteConfirmPoOpen] =
    useState(false);
  const [isOnlineMenuPrinterTaxSetupOpen, setOnlineMenuPrinterTaxSetupOpen] =
    useState(false);
  const [isEditItemDeleteConfirmPo1Open, setEditItemDeleteConfirmPo1Open] =
    useState(false);

  const openEditItemDeleteConfirmPo = useCallback(() => {
    setEditItemDeleteConfirmPoOpen(true);
  }, []);

  const closeEditItemDeleteConfirmPo = useCallback(() => {
    setEditItemDeleteConfirmPoOpen(false);
  }, []);

  const openOnlineMenuPrinterTaxSetup = useCallback(() => {
    setOnlineMenuPrinterTaxSetupOpen(true);
  }, []);

  const closeOnlineMenuPrinterTaxSetup = useCallback(() => {
    setOnlineMenuPrinterTaxSetupOpen(false);
  }, []);

  const openEditItemDeleteConfirmPo1 = useCallback(() => {
    setEditItemDeleteConfirmPo1Open(true);
  }, []);

  const closeEditItemDeleteConfirmPo1 = useCallback(() => {
    setEditItemDeleteConfirmPo1Open(false);
  }, []);

  const inspectRow = () => console.log('🪲 Category', model)

  return (
    <>
      <div
        className={clsx("category-list-row self-stretch flex flex-col items-center justify-start py-0 pr-5 pl-[39px] box-border w-full h-auto gap-[0px] text-left text-xl text-blue-solid-blue-900-1c1f37 font-mulish-button-name-bold hover:flex hover:self-stretch hover:w-full hover:h-auto hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start hover:py-0 hover:pr-5 hover:pl-[39px] hover:box-border mq768:pl-[26px] mq768:pr-3 mq768:box-border mq768:hover:pl-[26px] mq768:hover:pr-3 mq768:hover:box-border mq600:pl-[26px] mq600:pr-2.5 mq600:box-border mq600:hover:pr-2.5 mq600:hover:box-border mq480:pl-[26px] mq480:pr-2.5 mq480:box-border",
          isMoreButtonExpanded && "z-[2]")}>
        <div
          className="self-stretch rounded-lg [background:linear-gradient(180deg,_#e6f2fe,_#c1e0ff)] shadow-[0px_5px_10px_#f1f2fa] flex flex-row items-center justify-between py-1.5 pr-[11px] pl-[35px] relative z-[1] border-[1px] border-solid border-white-solid-white-100-ffffff sm:py-1 sm:pr-2.5 sm:pl-[30px] sm:box-border mq768:pl-[26px] mq768:pt-0.5 mq768:pb-0.5 mq768:box-border mq600:pl-[26px] mq600:pt-0.5 mq600:pb-0.5 mq600:box-border">
          <div className="shrink-0 flex flex-row items-center justify-start gap-[8px] z-[0]">
            {isEditing ? (
              <TextField className="bg-white" value={model.name} onChange={e => onNameChanged(e.target.value)}/>) : (
              <b className="relative lg:text-lg md:text-mini sm:text-sm mq768:text-smi mq600:text-xs">
                {model.name}
              </b>
            )}
            <div onClick={onToggleEdit}
                 className="relative w-[30px] h-[30px] sm:w-[26px] sm:h-[26px] mq768:w-6 mq768:h-6 mq600:w-[22px] mq600:h-[22px] mq480:w-[22px] mq480:h-[22px]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/iconmenuedit-icon2@2x.png"
              />
            </div>
          </div>
          <div
            className="!z-[0] shrink-0 flex flex-row items-center justify-start gap-[12px] z-[1] text-center text-mini text-white-solid-white-100-ffffff lg:gap-[12px] md:gap-[10px] sm:gap-[8px] sm:pt-[5px] sm:pb-[5px] sm:box-border mq768:gap-[4px]">
            <div
              className="rounded-3xs [background:linear-gradient(135deg,_#698ff0,_#033ac9)] shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] h-10 flex flex-row items-center justify-center py-0 px-4 box-border md:h-9 md:pt-2 md:pb-2 md:box-border sm:h-8 sm:py-1.5 sm:px-3 sm:box-border mq768:h-7 mq768:pl-2.5 mq768:pr-2.5 mq768:box-border mq600:h-7 mq600:pl-2 mq600:pr-2 mq600:box-border"
              ref={makeRipple} onClick={onCreateItem}
            >
              <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-smi mq600:text-xs">
                +{LL0().editOnlineMenu.newItem()}
              </b>
            </div>
            <div
              className="rounded-3xs bg-pink-solid-pink-200-ffe9f5 shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] w-12 h-10 flex flex-row items-center justify-center cursor-pointer md:w-11 md:h-9 md:py-2 md:px-4 md:box-border sm:w-10 sm:h-8 sm:py-1.5 sm:px-3 sm:box-border mq768:w-8 mq768:h-7"
              onClick={openEditItemDeleteConfirmPo}
            >
              <img
                className="relative w-6 h-6 object-cover sm:w-5 sm:h-5"
                alt=""
                src="/iconmenubin-icon@2x.png"
              />
            </div>

            <div
              className="!z-[3] rounded-981xl shadow-[0px_4px_4px_rgba(0,_0,_0,_0.08)] shrink-0 flex flex-col items-center justify-center relative gap-[2px] text-base text-black-solid-black-900-1e1e23">
              <div
                ref={makeRipple}
                className="rounded-full"
              >
                <img
                  className="relative w-10 h-10 object-cover z-[0] md:w-9 md:h-9 sm:w-8 sm:h-8 mq768:w-7 mq768:h-7"
                  alt=""
                  src="/imagemenumore-button@2x.png"
                  onClick={() => setIsMoreButtonExpanded(prev => !prev)}
                />
              </div>
              {isMoreButtonExpanded &&
                <div className="z-[3] fixed top-0 left-0 w-screen h-screen opacity-0"
                     onClick={() => setIsMoreButtonExpanded(false)}/>
              }
              {isMoreButtonExpanded &&
                <div
                  className="!z-[3] my-0 mx-[!important] absolute top-[40px] left-[calc(50%_-_146px)] w-[200px] h-[143.3px] flex flex-col items-center justify-start py-1 px-0.5 box-border max-w-[none] max-h-[none] gap-[0px] z-[1] hover:flex hover:w-[200px] hover:h-[143.3px] hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start hover:py-1 hover:px-0.5 hover:box-border hover:max-w-[none] hover:max-h-[none]">
                  <div
                    className="self-stretch flex flex-row items-center justify-end py-0 pr-[45px] pl-0 mq1440:pl-[42px] mq1440:box-border">
                    <img
                      className="relative w-[13px] h-[11.3px] object-cover"
                      alt=""
                      src="/polygon-up-white@2x.png"
                    />
                  </div>
                  <div
                    className="rounded-sm bg-black-opacity-black-30-000000 shadow-[1px_0px_4px_#525f71,_10px_-10px_14px_rgba(149,_149,_149,_0.1)_inset,_-10px_10px_14px_rgba(255,_255,_255,_0.1)_inset] [backdrop-filter:blur(40px)] w-[196px] overflow-hidden flex flex-col items-center justify-start p-3 box-border gap-[16px]">
                    <div
                      className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[8px] mq1440:h-9"
                      ref={makeRipple}
                      onClick={async () => {
                        setIsMoreButtonExpanded(false);
                        await VIEW__CATEGORY.onImport(model._id);
                      }}
                    >
                      <img
                        className="relative w-[30px] h-[30px] object-cover mq1440:w-[26px] mq1440:h-[26px]"
                        alt=""
                        src="/iconmenuimport-item@2x.png.png"
                      />
                      <b className="flex-1 relative leading-[17px] mq1440:text-mini">
                        {LL0().editOnlineMenu.importItems()}
                      </b>
                    </div>
                    <div
                      className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[8px] mq1440:h-9"
                      ref={makeRipple}
                      onClick={() => {
                        openOnlineMenuPrinterTaxSetup()
                        setIsMoreButtonExpanded(false)

                      }}
                    >
                      <img
                        className="relative w-[30px] h-[30px] object-cover"
                        alt=""
                        src="/icononlinemenusetup-icon@2x.png"
                      />
                      <b className="relative leading-[17px] mq1440:text-mini">{LL0().editOnlineMenu.printerAndTax()}</b>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div
              className="rounded-3xs bg-white-solid-white-100-ffffff shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] w-12 h-10 flex flex-row items-center justify-center md:w-11 md:h-9 md:pt-2 md:pb-2 md:box-border sm:w-10 sm:h-8 sm:pt-1.5 sm:pb-1.5 sm:box-border mq768:w-8 mq768:h-7"
              ref={makeRipple} onClick={onMoveUp}
            >
              <img
                className="relative w-6 h-6 object-cover sm:w-5 sm:h-5"
                alt=""
                src="/iconmenuarrow-up-icon@2x.png"
              />
            </div>
            <div
              className="rounded-3xs bg-white-solid-white-100-ffffff shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] w-12 h-10 flex flex-row items-center justify-center md:w-11 md:h-9 md:pt-2 md:pb-2 md:box-border sm:w-10 sm:h-8 sm:pt-1.5 sm:pb-1.5 sm:box-border mq768:w-8 mq768:h-7"
              ref={makeRipple} onClick={onMoveDown}
            >
              <img
                className="relative w-6 h-6 object-cover sm:w-5 sm:h-5"
                alt=""
                src="/iconmenuarrow-down-icon@2x.png"
              />
            </div>
          </div>
          <div className="!z-[0] absolute my-0 mx-[!important] h-full top-[0px] bottom-[0px] left-[-19px] w-10 z-[2]">
            <div
              className="data-[expanded=true]:rotate-90 transition-all absolute h-[71.35%] w-full top-[14.23%] right-[0%] bottom-[14.42%] left-[0%] lg:w-10 lg:h-10 md:w-[38px] md:h-[38px] sm:w-[34px] sm:h-[34px]"
              onClick={onToggleExpand} data-expanded={isExpanded}
            >
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/iconcollapse-expanded-icon@2x.png"
              />
            </div>
          </div>
        </div>
        {isExpanded &&
          <div
            className="self-stretch flex flex-col items-center justify-start gap-[10px] z-[0] text-center text-sm text-gray-opacity-gray-60-2e2e48-300">
            {isAnItem && (
              <div
                className="self-stretch relative h-full grid w-auto gap-[8px] py-2 px-0 box-border grid-rows-[35px_1fr] grid-cols-[32px_8fr_repeat(6,1fr)] [grid-row-gap:16px] [grid-column-gap:0px] sm:h-full"
                onClick={inspectRow}
                >
                <div
                  className="flex flex-row gap-[0px] items-center justify-start py-2 px-[5px] relative col-[1/1] row-[1/1] hover:flex hover:flex-row hover:gap-[0px] hover:items-center hover:justify-start hover:py-2 hover:px-[5px] hover:box-border hover:relative hover:col-[1/1] hover:row-[1/1] md:pt-2 md:pb-2"/>
                <div
                  className="flex flex-row items-center justify-start py-2 pr-0 pl-3 gap-[0px] relative col-[2/2] row-[1/1] text-left hover:gap-[0px] hover:relative hover:col-[2/2] hover:row-[1/1] md:pt-2 md:pb-2 mq768:col-[2/5] mq768:hover:col-[2/5]">
                  <div className="relative font-semibold md:text-smi sm:text-xs">
                    {LL0().editOnlineMenu.itemInfo()}
                  </div>
                </div>
                <div
                  className="flex flex-row items-center justify-center py-2 px-0 box-border max-w-[70] gap-[0px] relative col-[3/3] row-[1/1] hover:gap-[0px] hover:relative hover:col-[3/3] hover:row-[1/1] hover:max-w-[70] mq600:hidden mq480:hidden">
                  <div className="relative font-semibold md:text-smi sm:text-xs mq768:flex">
                    {LL0().product.price()}
                  </div>
                </div>
                <div
                  className="flex flex-row items-center justify-center py-2 px-0 box-border max-w-[70] gap-[0px] relative col-[4/4] row-[1/1] hover:gap-[0px] hover:relative hover:col-[4/4] hover:row-[1/1] hover:max-w-[70] mq600:hidden mq480:hidden">
                  <div className="relative font-semibold md:text-smi sm:text-xs mq768:flex">
                    {LL0().order.tax()}
                  </div>
                </div>
                <div
                  className="flex flex-row items-center justify-center py-2 px-0 gap-[0px] relative col-[6/6] row-[1/1] hover:gap-[0px] hover:relative hover:col-[6/6] hover:row-[1/1]">
                  <div className="relative font-semibold md:text-smi sm:text-xs mq768:flex">
                    {LL0().editOnlineMenu.sync()}
                  </div>
                </div>
                <div
                  className="flex flex-row items-center justify-center py-2 px-0 gap-[0px] relative col-[5/5] row-[1/1] hover:gap-[0px] hover:relative hover:col-[5/5] hover:row-[1/1]">
                  <div className="relative font-semibold md:text-smi sm:text-xs mq768:flex">
                    {LL0().onlineOrder.status()}
                  </div>
                </div>
                <div
                  className="flex flex-row items-center justify-center py-2 px-0 gap-[0px] relative col-[7/7] row-[1/1] hover:gap-[0px] hover:relative hover:col-[7/7] hover:row-[1/1]">
                  <div className="relative font-semibold md:text-smi sm:text-xs">
                    {LL0().ui.edit()}
                  </div>
                </div>
                <div
                  className="flex flex-row items-center justify-center py-2 pr-3 pl-0 gap-[0px] relative col-[8/8] row-[1/1] hover:gap-[0px] hover:relative hover:col-[8/8] hover:row-[1/1]">
                  <div className="relative font-semibold md:text-smi sm:text-xs">
                    {LL0().ui.delete()}
                  </div>
                </div>
                {items().map((item, index) => {
                  const modifiers = STATES.modifiers().filter(a => item.modifiers?.includes(a._id))
                  const onItemEdit = () => VIEW__PRODUCT.onEdit(item._id, model._id)
                  const onItemDelete = () => (setStagingProduct(item), openEditItemDeleteConfirmPo1())
                  const inspectItem = () => console.log('🪲 Product', item)

                  return (<Fragment key={item._id}>
                    <div
                      className="flex flex-col items-start justify-between py-5 pr-2 pl-0 [align-self:unset] gap-[58px] relative col-[1/1] row-[2/2] hover:[align-self:unset] hover:gap-[58px] hover:relative hover:col-[1/1] hover:row-[2/2]"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                    >
                      <img
                        className="relative w-6 h-6 object-cover sm:w-5 sm:h-5 mq768:w-5 mq768:h-5 mq600:w-[18px] mq600:h-[18px] mq480:w-4 mq480:h-4"
                        alt=""
                        src="/iconmenuarrow-up-icon@2x.png"
                        onClick={() => VIEW__CATEGORY.onMoveProductUp(model._id, item._id)}
                      />
                      <img
                        className="relative w-6 h-6 object-cover sm:w-5 sm:h-5 mq768:w-5 mq768:h-5 mq600:w-[18px] mq600:h-[18px] mq480:w-4 mq480:h-4"
                        alt=""
                        src="/iconmenuarrow-down-icon@2x.png"
                        onClick={() => VIEW__CATEGORY.onMoveProductDown(model._id, item._id)}
                      />
                    </div>
                    <div
                      className="rounded-tl-2xl rounded-tr-none rounded-br-none rounded-bl-2xl bg-gray-solid-gray-80-fafafa-100 flex flex-row items-start justify-start py-3.5 pr-0 pl-3 gap-[14px] relative col-[2/2] row-[2/2] text-left text-base text-blue-solid-blue-900-1c1f37 border-t-[1.4px] border-solid border-gray-solid-gray-350-d4dbeb border-b-[1.4px] border-l-[1.4px] hover:relative hover:col-[2/2] hover:row-[2/2] mq600:gap-[8px] mq600:col-[2/5] mq600:hover:col-[2/5] mq480:flex-col mq480:gap-[8px] mq480:col-[2/5] mq480:hover:col-[2/5]"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                      onClick={inspectItem}
                    >
                      <RxAttachmentImage
                        className="cursor-pointer rounded w-[109px] h-[109px] object-cover lg:w-[109px] lg:h-[109px] md:w-[100px] md:h-[100px] sm:w-[88px] sm:h-[88px] mq768:w-[70px] mq768:h-[70px] mq600:w-[70px] mq600:h-[70px] mq480:w-[70px] mq480:h-[70px]"
                        src="/default-product-img.png"
                        doc={item.doc}
                        id={item.image}
                        imgProp={{onClick: onItemEdit}}
                      />
                      <div
                        className="flex-1 flex flex-col items-start justify-start py-0 pr-2 pl-0 gap-[12px] mq768:gap-[8px] mq480:flex-[unset] mq480:self-stretch">
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px]"
                             onClick={onItemEdit}
                        >
                          <div className="shrink-0 flex flex-row items-center justify-start gap-[2px]">
                            <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs mq1440:text-xl">
                              #
                            </b>
                            <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs mq1440:text-xl">
                              {item.id}
                            </b>
                          </div>
                          <b
                            className="flex-1 relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs mq1440:text-xl">
                            {item.name}
                          </b>
                        </div>
                        <i
                          className="self-stretch relative text-smi [display:-webkit-inline-box] font-semibold overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical] md:text-xs mq1440:text-base">
                          {item.description}
                        </i>
                        {!!modifiers.length && (
                          <div
                            className="self-stretch flex flex-col items-start justify-start text-xs text-gray-opacity-gray-60-2e2e48-300">
                            <div
                              className="self-stretch flex flex-row items-start justify-start gap-[20px] mq768:flex-col mq768:gap-[12px]">
                              <div className="relative font-semibold mq1440:text-sm">
                                {LL0().editOnlineMenu.options()}:
                              </div>
                              <div
                                className="flex-1 shrink-0 flex flex-row flex-wrap items-start justify-start gap-[10px] text-blue-solid-blue-200-017efa mq768:flex-[unset] mq768:self-stretch">
                                {modifiers.map(m => (
                                  <div key={m._id}
                                       className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_rgba(228,_230,_242,_0.5)] shrink-0 flex flex-row items-center justify-center py-[5px] px-2 box-border w-auto [align-self:unset] h-auto gap-[16px] hover:bg-blue-solid-blue-100-e6f2fe hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-row hover:gap-[16px] hover:items-center hover:justify-center hover:rounded-10xs hover:py-[5px] hover:px-2 hover:box-border hover:shadow-[0px_5px_10px_rgba(228,_230,_242,_0.5)]">
                                    <b className="relative lg:text-2xs md:text-2xs">
                                      {m.name}
                                    </b>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="bg-gray-solid-gray-80-fafafa-100 flex flex-row items-center justify-center gap-[8px] relative col-[3/3] row-[2/2] text-left text-base text-orange-solid-orange-200-ef9400 border-t-[1.4px] border-solid border-gray-solid-gray-350-d4dbeb border-b-[1.4px] hover:gap-[8px] hover:relative hover:col-[3/3] hover:row-[2/2] mq600:hidden mq480:hidden"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                    >
                      <b className="relative lg:text-sm md:text-smi sm:text-xs mq768:text-2xs">
                        {item.price ? LL3().format.currency(item.price) : '-'}
                      </b>
                    </div>
                    <div
                      className="bg-gray-solid-gray-80-fafafa-100 flex flex-row items-center justify-center gap-[8px] relative col-[4/4] row-[2/2] text-base text-blue-solid-blue-260-304eee border-t-[1.4px] border-solid border-gray-solid-gray-350-d4dbeb border-b-[1.4px] hover:gap-[8px] hover:relative hover:col-[4/4] hover:row-[2/2] mq600:hidden mq480:hidden"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                    >
                      <b className="relative lg:text-sm md:text-smi sm:text-xs mq768:text-2xs">
                        {item.tax2 ? `${item.tax2}%` : '-'}
                      </b>
                    </div>
                    <div
                      className="bg-gray-solid-gray-80-fafafa-100 flex flex-col items-center justify-center py-0 px-2 gap-[8px] relative col-[6/6] row-[2/2] border-t-[1.4px] border-solid border-gray-solid-gray-350-d4dbeb border-b-[1.4px] hover:gap-[8px] hover:relative hover:col-[6/6] hover:row-[2/2]"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                    >
                      <div className="relative w-10 h-5">
                        <FormControlLabel
                          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                          control={<Switch color="primary" checked={!!item.syncingHash} disabled/>}
                          label=""
                        />
                      </div>
                    </div>
                    <div
                      className="bg-gray-solid-gray-80-fafafa-100 flex flex-col items-center justify-center py-0 px-2 gap-[8px] relative col-[5/5] row-[2/2] border-t-[1.4px] border-solid border-gray-solid-gray-350-d4dbeb border-b-[1.4px] hover:gap-[8px] hover:relative hover:col-[5/5] hover:row-[2/2]"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                    >
                      <div className="relative w-10 h-5">
                        <FormControlLabel
                          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                          control={<Switch color="primary" size="medium"
                                           checked={!!item.isEnabled}
                                           onChange={(_, checked) => VIEW__PRODUCT.onSetProductEnabled(item._id, checked)}/>}
                          label=""
                        />
                      </div>
                    </div>
                    <div
                      className="bg-gray-solid-gray-80-fafafa-100 flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[7/7] row-[2/2] border-t-[1.4px] border-solid border-gray-solid-gray-350-d4dbeb border-b-[1.4px] hover:gap-[8px] hover:relative hover:col-[7/7] hover:row-[2/2]"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                    >
                      <div
                        className="relative w-10 h-10 md:w-9 md:h-9 sm:w-8 sm:h-8 mq768:w-[26px] mq768:h-[26px] mq600:w-6 mq600:h-6"
                        ref={makeRipple} onClick={onItemEdit}
                      >
                        <img
                          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                          alt=""
                          src="/iconmenuedit-icon3@2x.png"
                        />
                      </div>
                    </div>
                    <div
                      className="rounded-tl-none rounded-tr-2xl rounded-br-2xl rounded-bl-none bg-gray-solid-gray-80-fafafa-100 flex flex-row items-center justify-center py-0 px-2.5 gap-[8px] relative col-[8/8] row-[2/2] border-t-[1.4px] border-solid border-gray-solid-gray-350-d4dbeb border-r-[1.4px] border-b-[1.4px] hover:gap-[8px] hover:relative hover:col-[8/8] hover:row-[2/2]"
                      style={{
                        gridRow: `${index + 2}/${index + 2}`
                      }}
                    >
                      <div
                        className="relative w-10 h-10 cursor-pointer md:w-9 md:h-9 sm:w-8 sm:h-8 mq768:w-[26px] mq768:h-[26px] mq600:w-6 mq600:h-6"
                        ref={makeRipple} onClick={onItemDelete}
                      >
                        <img
                          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                          alt=""
                          src="/iconcell-bin-icon@2x.png"
                        />
                      </div>
                    </div>
                  </Fragment>)
                })}
              </div>
            )}
            {isHaveNoItem && (
              <div
                className="self-stretch flex flex-col items-center justify-start py-4 px-3 text-xl text-blue-solid-blue-900-364150">
                <div
                  className="self-stretch rounded-2xl bg-gray-solid-gray-80-fafafa-100 shadow-[0px_5px_10px_rgba(228,_230,_242,_0.5)] box-border h-[89px] flex flex-col items-center justify-center py-5 px-3 gap-[4px] border-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200">
                  <b className="relative lg:text-lg md:text-base sm:text-mini mq768:text-sm mq600:text-smi">
                    {LL0().editOnlineMenu.noItemsYet()}
                  </b>
                  <div
                    className="relative text-base font-semibold text-gray-opacity-gray-60-2e2e48-300 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                    {LL0().editOnlineMenu.clickAddNewItemCreateItemMenuCategory()}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
      {isEditItemDeleteConfirmPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo}
        >
          <PopupDeleteConfirmation
            onClose={closeEditItemDeleteConfirmPo}
            onConfirmed={() => (closeEditItemDeleteConfirmPo(), VIEW__CATEGORY.onRemove(model?._id))}
            message={`${LL0().editOnlineMenu.doYouWantToDelete()} "${model?.name}"?`}
          />
        </PortalPopup>
      )}
      {isOnlineMenuPrinterTaxSetupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOnlineMenuPrinterTaxSetup}
        >
          <OnlineMenuPrinterTaxSetup onClose={closeOnlineMenuPrinterTaxSetup} />
        </PortalPopup>
      )}
      {isEditItemDeleteConfirmPo1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo1}
        >
          <PopupDeleteConfirmation
            onClose={closeEditItemDeleteConfirmPo1}
            onConfirmed={() => (closeEditItemDeleteConfirmPo1(), VIEW__PRODUCT.onDelete(stagingProduct?._id))}
            message={`${LL0().editOnlineMenu.doYouWantToDelete()} "${stagingProduct?.name}"?`}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default CategoryListRow;
