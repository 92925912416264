import { type FunctionComponent, useMemo } from "react";
import CashbookPriceBox from "./CashbookPriceBox";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  CashbookViewList,
  counted,
  currentBalance,
  setCounted,
  setSelectedCashbookView
} from "@/react/CashbookView/CashbookView.tsx";
import { batch } from "@/react/core/reactive.ts";
import { LL0 } from '@/react/core/I18nService.tsx'

const CashBalancingNextRight: FunctionComponent = () => {
  const different = useMemo(() => counted() - currentBalance(), [counted(), currentBalance()])
  return (
    <div
      className="w-full h-full flex flex-col items-center justify-start gap-[0px] text-left text-base text-gray-solid-gray-380-cdcdcd font-mulish hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start mq1440:w-full mq1440:h-full">
      <div
        className="self-stretch bg-black-solid-black-500-59636f h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[5px] px-2.5 box-border sm:h-[42px] sm:pt-[5px] sm:pb-[5px] sm:box-border Tablet_768:h-[42px] Tablet_600:h-[42px]">
        <div className="flex flex-row items-center justify-center gap-[15px] sm:gap-[8px]">
          <img
            className="relative w-5 h-5 object-cover"
            alt=""
            src="/icondouble-chevron-icon1@2x.png"
          />
          <b
            className="relative tracking-[-0.28px] sm:text-base Tablet_768:text-smi Tablet_600:text-smi mq1440:text-lg">
            {LL0().cashbook.cashBalance()}
          </b>
        </div>
        <div
          className="rounded-81xl [background:linear-gradient(180deg,_#c6f38e,_#6a9a33)] h-8 flex flex-row items-center justify-center py-0 px-5 box-border text-center text-sm text-white-solid-white-100-ffffff lg:hidden lg:items-center lg:justify-center sm:hidden sm:h-10 Tablet_768:flex Tablet_768:h-8 Tablet_600:flex Tablet_600:h-8 mq1440:hidden height_414:flex height_414:h-8 height_414:items-center height_414:justify-center"
          onClick={() => {
            batch(() => {
              setSelectedCashbookView(CashbookViewList.Cashbook)
              setCounted(0)
            })
          }}
          ref={makeRipple}
        >
          <div
            className="relative leading-[23px] sm:text-lg Tablet_768:text-base Tablet_600:text-base height_414:text-sm">
            {LL0().cashbook.done()}
          </div>
        </div>
      </div>
      <div
        className="self-stretch flex-1 bg-black-solid-black-700-364150 flex flex-col items-center justify-start pt-[30px] px-0 pb-2.5 text-center text-gray-solid-gray-450-b6b6b6 lg:pt-12 lg:pb-12 lg:box-border md:pt-8 md:pb-8 md:box-border Tablet_600:pt-4 Tablet_600:pb-4 Tablet_600:box-border mq1440:pt-20 mq1440:pb-20 mq1440:box-border height_414:pt-5 height_414:pb-0 height_414:box-border">
        <div
          className="w-[680px] flex flex-col items-center justify-start py-0 px-6 box-border gap-[25px] lg:w-[700px] lg:gap-[36px] lg:pl-6 lg:pr-6 lg:box-border md:w-[680px] md:gap-[25px] sm:w-[620px] sm:gap-[26px] sm:pl-4 sm:pr-4 sm:box-border Tablet_768:w-[540px] Tablet_768:gap-[26px] Tablet_768:pl-0 Tablet_768:pr-0 Tablet_768:box-border Tablet_600:w-[480px] Tablet_600:gap-[24px] Tablet_600:pl-0 Tablet_600:pr-0 Tablet_600:box-border mq1440:w-[800px] height_414:gap-[15px]">
          <div className="self-stretch flex flex-col items-center justify-start">
            <div className="self-stretch flex flex-row items-start justify-between">
              <CashbookPriceBox
                icon="/cashbookcalculator-icon@2x.png"
                title={LL0().cashbook.counted()}
                cashNumber={counted()}
                isShowIcon
                isDefault
                widthStyle={200}
                showDateCash={false}
              />
              <CashbookPriceBox
                icon="/cashbooktarget-icon@2x.png"
                title={LL0().cashbook.target()}
                cashNumber={currentBalance()}
                isShowIcon
                isDefault
                widthStyle={200}
                showDateCash={false}
              />
            </div>
            <div
              className="self-stretch h-[140px] flex flex-row items-start justify-center lg:h-[180px] sm:h-[158px] Tablet_600:h-[130px] mq1440:h-[180px] height_414:h-[140px] height_414:pt-0 height_414:box-border">
              <div
                className="w-[119px] h-[100px] flex flex-row items-end justify-start relative sm:w-[89px] sm:h-[100px] Tablet_600:w-[70px] mq1440:w-[142px] mq1440:h-[140px]">
                <div
                  className="self-stretch relative box-border w-px z-[0] border-r-[1px] border-dashed border-blue-opacity-blue-80-e3f0ff" />
                <div
                  className="flex-1 relative box-border h-px z-[1] border-t-[1px] border-dashed border-blue-opacity-blue-80-e3f0ff" />
                <div
                  className="absolute my-0 mx-[!important] top-[calc(50%_-_8px)] left-[-12px] w-6 h-6 overflow-hidden shrink-0 z-[2]">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/icontriangle-bottom@2x.png"
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-center justify-end">
                <CashbookPriceBox
                  title={LL0().cashbook.difference()}
                  isShowIcon={false}
                  showDateCash={false}
                  isMatching={different === 0}
                  isMismatch={different !== 0}
                  widthStyle={200}
                  cashNumber={different}
                />
              </div>
              <div
                className="w-[119px] h-[100px] flex flex-col items-end justify-start relative sm:w-[89px] sm:h-[100px] Tablet_600:w-[70px] mq1440:w-[142px] mq1440:h-[140px]">
                <div
                  className="flex-1 relative box-border w-px z-[0] border-r-[1px] border-dashed border-blue-opacity-blue-80-e3f0ff" />
                <div
                  className="self-stretch relative box-border h-px z-[1] border-t-[1px] border-dashed border-blue-solid-blue-20-f3f9ff" />
                <div
                  className="absolute my-0 mx-[!important] top-[calc(50%_-_8px)] right-[-12px] w-6 h-6 overflow-hidden shrink-0 z-[2]">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/icontriangle-top@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-[10px]">
            <div
              className="relative flex items-center justify-center w-[455px] h-[21px] shrink-0 sm:text-base Tablet_768:text-sm Tablet_600:text-smi mq1440:text-base">
              {LL0().cashbook.match()}
            </div>
            <img
              className="relative w-8 h-8 object-cover"
              alt=""
              src={different === 0 ? "/iconchecked-light-blue-linear@2x.png" : "/cancel-light-orange-linear-icon@2x.png"}
              rel=""
            />
          </div>
          <div
            className="rounded-md [background:linear-gradient(180deg,_#c6f38e,_#6a9a33)] w-[480px] h-12 flex flex-row items-center justify-center text-[21px] text-white-solid-white-100-ffffff lg:items-center lg:justify-center sm:h-11 Tablet_768:hidden Tablet_768:h-10 Tablet_600:hidden Tablet_600:h-[38px] mq1440:h-[55px] height_414:hidden height_414:h-12 height_414:items-center height_414:justify-center"
            onClick={() => {
              batch(() => {
                setSelectedCashbookView(CashbookViewList.Cashbook)
                setCounted(0)
              })
            }}
            ref={makeRipple}
          >
            <div
              className="relative leading-[23px] sm:text-lg Tablet_768:text-base Tablet_600:text-base mq1440:text-4xl height_414:text-lg">
              {LL0().cashbook.done()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashBalancingNextRight;