import LoadingButton from '@mui/lab/LoadingButton'
import { Button, FormControlLabel, Switch, Typography } from '@mui/material'

import InputKeyboardPopUp from '../core/InputKeyboardPopUp'
import {
  handleIpChange,
  handleSwitchChange,
  handleSwitchMode,
  handleValidate,
} from './SecondDisplayConfig.logic'
import {
  isMainDisplayIpValid,
  isSecondDisplay,
  mainDisplayIp,
  mainDisplayIpError,
  validatingMainDisplayIp
} from './SecondDisplayConfig.state'

const SecondDisplayConfig = () => (
  <div>
    <FormControlLabel
      control={
        <Switch
          checked={isSecondDisplay()}
          onChange={handleSwitchChange}
          color="primary"
        />
      }
      label="Is this a second display?"
    />

    {isSecondDisplay() && (
      <div className="flex flex-col gap-2">
        <InputKeyboardPopUp
          fullWidth
          label="Main Display IP"
          value={mainDisplayIp()}
          onChange={handleIpChange}
          disabled={validatingMainDisplayIp()}
        />
        {mainDisplayIpError() && <Typography color="error">{mainDisplayIpError()}</Typography>}
        <LoadingButton
          variant="contained"
          loading={validatingMainDisplayIp()}
          fullWidth
          color="secondary"
          onClick={handleValidate}
        >
          Validate
        </LoadingButton>
        <Button
          variant="contained"
          disabled={!isMainDisplayIpValid()}
          onClick={handleSwitchMode}
          color="primary"
          fullWidth
        >
          Switch To Second Display Mode
        </Button>
      </div>
    )}
  </div>
)

export default SecondDisplayConfig
