import { ArrayBuffertohex, hextoArrayBuffer, hextob64, KEYUTIL, KJUR, pemtohex, RSAKey, X509, zulutodate } from 'jsrsasign'

import type { SrsCsrContent } from './types'

export function genKeyPair() {
  const { prvKeyObj, pubKeyObj } = KEYUTIL.generateKeypair('EC', 'secp256r1')
  const publicKey = KEYUTIL.getPEM(pubKeyObj)
  const privateKey = KEYUTIL.getPEM(prvKeyObj, 'PKCS8PRV')

  return { privateKey, publicKey }
}

/**
 * Indicates that a certificate can be used to bind the hash of an object to a time from a trusted time source.
 * <https://www.alvestrand.no/objectid/1.3.6.1.5.5.7.3.8.html>
 */
const id_kp_timeStamping = '1.3.6.1.5.5.7.3.8'

/**
 *  Generate CSR
 *
 * > **Quebe's Spec**: When creating a pair of keys, you must specify that the
 * > private key is to be used to produce a **signature** and that the public key
 * > must be used to ensure **non-repudiation**. In addition, the public and private
 * > key length must be 256 bits using an **ECDSA P-256** algorithm (e.g. to get
 * > a 256-bit key length with ECDSA P-256, use CngAlgorithm.ECDsaP256 in C#
 * > or secp256r1 in Java [Android]).
 */
export function generateCsrJsrsasign(data: SrsCsrContent, privateKey: string, publicKey: string) {
  const csr = new KJUR.asn1.csr.CertificationRequest({
    sigalg: 'SHA256withECDSA',
    sbjpubkey: publicKey,
    sbjprvkey: privateKey,
    // @ts-expect-error We're using non-standard fields here
    subject: data,
    extreq: [
      {
        extname: 'extKeyUsage',
        array: [{ oid: id_kp_timeStamping }],
      },
      {
        extname: 'keyUsage',
        // @ts-expect-error We're using non-standard fields here
        names: ['digitalSignature', 'nonRepudiation'],
      },
      {
        extname: 'basicConstraints',
        // @ts-expect-error We're using non-standard fields here
        cA: false,
        pathLen: 0,
        critical: false,
      },
    ],
  })
  const result = csr.getPEM()
  const fixed = fixCSR(result)

  return fixed
}

/**
 * Fix the CSR format to fit with Quebec's Spec
 */
export function fixCSR(csrPem: string) {
  const fixed = csrPem
    .replace(/\r\n/g, '')
    .replace('-----BEGIN CERTIFICATE REQUEST-----', '-----BEGIN CERTIFICATE REQUEST-----\n')
    .replace('-----END CERTIFICATE REQUEST-----', '\n-----END CERTIFICATE REQUEST-----')
    .trimEnd()

  return fixed
}

export async function signWithPrivateKey(keyPem: string, content: string) {
  const key = await importPrivateKey(keyPem)
  const data = new TextEncoder().encode(content)
  const signature = await crypto.subtle.sign({ name: 'ECDSA', hash: 'SHA-256' }, key, data)
  const result = hextob64(ArrayBuffertohex(signature))

  // TODO: inspect why the jsrsasign code below does not work
  // const key = KEYUTIL.getKey(keyPem, null, "pkcs8prv");
  // const sig = new KJUR.crypto.Signature({ alg: "SHA256withEC" });
  // sig.init(key);
  // sig.updateHex(content);
  // const signature = sig.sign();
  // const result = hextob64(signature);

  return result
}

export async function encryptWithCertificate(certPem: string, content: string) {
  const c = new X509()
  c.readCertPEM(certPem)
  const publicKey = c.getPublicKey()
  if (!(publicKey instanceof RSAKey)) throw new Error('Invalid certificate!')
  const encrypted = KJUR.crypto.Cipher.encrypt(content, publicKey, 'RSA')
  const result = hextob64(encrypted)

  return result
}

export function getCertificateInfo(pem: string | undefined) {
  if (!pem) return
  const c = new X509()
  c.readCertPEM(pem)
  const subjects = c.getSubjectString().split('/')
  const info = {
    subject: <Partial<SrsCsrContent>>{
      C: subjects.find(a => a.match(/^C=/))?.replace(/^C=/, ''),
      ST: subjects.find(a => a.match(/^ST=/))?.replace(/^ST=/, ''),
      L: subjects.find(a => a.match(/^L=/))?.replace(/^L=/, ''),
      OU: subjects.find(a => a.match(/^OU=/))?.replace(/^OU=/, ''),
      O: subjects.find(a => a.match(/^O=/))?.replace(/^O=/, ''),
      GN: subjects.find(a => a.match(/^GN=/))?.replace(/^GN=/, ''),
      CN: subjects.find(a => a.match(/^CN=/))?.replace(/^CN=/, ''),
      SN: subjects.find(a => a.match(/^SN=/))?.replace(/^SN=/, ''),
    },
    issuer: c.getIssuerString(),
    serialNumber: c.getSerialNumberHex(),
    notbefore: zulutodate(c.getNotBefore()).toISOString(),
    notafter: zulutodate(c.getNotAfter()).toISOString(),
    thumb: KJUR.crypto.Util.hashHex(c.hex, 'sha1'),
  }

  return info
}

export function getKeyFingerprint(pem: string | undefined) {
  if (!pem) return
  const hex = pemtohex(pem)
  return KJUR.crypto.Util.hashHex(hex, 'sha1')
}

export function getCertificateThumb(pem?: string) {
  if (!pem) return
  const c = new X509()
  c.readCertPEM(pem)
  return KJUR.crypto.Util.hashHex(c.hex, 'sha1')
}

/**
 * Import a PEM encoded private key
 */
async function importPrivateKey(pem: string) {
  return crypto.subtle.importKey(
    'pkcs8',
    hextoArrayBuffer(pemtohex(pem)),
    {
      name: 'ECDSA',
      namedCurve: 'P-256',
      hash: 'SHA-256',
    },
    true,
    ['sign']
  )
}
