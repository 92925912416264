import { context } from '@opentelemetry/api'
import _ from 'lodash'
import { addRxPlugin, createRxDatabase, type RxDocument, type RxReactivityFactory } from 'rxdb'
import { wrappedLoggerStorage } from 'rxdb-premium/plugins/logger'
import { disableVersionCheck } from 'rxdb-premium/plugins/shared'
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
import { getLocalstorageMetaOptimizerRxStorage } from 'rxdb-premium/plugins/storage-localstorage-meta-optimizer'
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments'
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup'
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
import { RxDBUpdatePlugin } from 'rxdb/plugins/update'
import type { Observable } from 'rxjs'
import type { Accessor } from 'solid-js'

import { createCategoryCollection } from '@/data/Category'
import { createCheckPointCollection } from '@/data/CheckPoint.ts'
import { createCustomerCollection } from '@/data/Customer.ts'
import { clearAllCollections } from '@/data/data-utils-dev.ts'
import { convertDocument, convertDocuments, database, database2, database3, setDbReady } from '@/data/data-utils.ts'
import { dataLock } from '@/data/DataUtils.ts'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { createDiscountCollection } from '@/data/Discount.ts'
import { createEodCollection } from '@/data/Eod'
import { createEodCacheCollection } from '@/data/EodCache'
import { createGroupPrinterCollection } from '@/data/GroupPrinter'
import { createHappyHourCollection } from '@/data/HappyHour.ts'
import { createImageCollection } from '@/data/Image.ts'
import { createInventoryCollection } from '@/data/Inventory.ts'
import { createInventoryActionCollection } from '@/data/InventoryAction.ts'
import { createLicenseCollection } from '@/data/License'
import { createMaxIdCollection } from '@/data/MaxId.ts'
import { createModifierCollection } from '@/data/Modifier'
import { createModifierSyncCollection } from '@/data/ModifierSync.ts'
import { createOnlineOrderPrinterGroupMappingCollection } from '@/data/OnlineOrderPrinterGroupMapping.ts'
import { createOnlineOrderProductMappingCollection } from '@/data/OnlineOrderProductMapping.ts'
import { createOpenHourCollection } from '@/data/OpenHour.ts'
import { createOrderCommitsCollection } from '@/data/OrderCommits.ts'
import { createOrderHandlerCollection } from '@/data/OrderHandler.ts'
import { createOrderTseTempCollection } from '@/data/OrderTseTemp.ts'
import { createPosSettingCollection } from '@/data/PosSetting'
import { posSync0 } from '@/data/PosSyncState.ts'
import { createPrintImageCollection } from '@/data/PrintImage'
import { createProductSyncCollection } from '@/data/ProductSync'
import { createReservationCollection } from '@/data/Reservation.ts'
import { createSrmDocumentLogCollection } from '@/data/SrmDocumentLog'
import { createSrmEventLogCollection } from '@/data/SrmEventLog'
import { createSrmTransactionLogCollection } from '@/data/SrmTransactionLog'
import { createTableStaffStatusCollection } from '@/data/TableStaffStatus'
import { createTaxCategoryCollection } from '@/data/TaxCategory'
import { createTerminalCollection } from '@/data/Terminal.ts'
import { createTimeClockCollection } from '@/data/TimeClock.ts'
import { createTseCertificateCollection } from '@/data/TseCertificate.ts'
import { createTseConfigCollection } from '@/data/TseConfig.ts'
import { createTseTransactionCollection } from '@/data/TseTransaction.ts'
import { createTxCollection } from '@/data/TxLog.ts'
import { createTxRefundCollection } from '@/data/TxRefundLog.ts'
import { createTxVoidCollection } from '@/data/TxVoidLog.ts'
import { createUserActionHistoryCollection } from '@/data/UserActionHistory.ts'
import { UpdatedAtPlugin } from '@/data/utils/updateAt.plugin'
import { createVoucherCollection } from '@/data/Voucher.ts'
import { createZvtLogCollection } from '@/data/ZvtLog.ts'
import { createSpan, LogOperation } from '@/lib/open-telemetry.ts'
import { signal } from '@/react/core/reactive'
import { getDeviceId } from '@/shared/getDeviceId'

import { createCallCollection, createMissedCallCollection } from './Call.create'
import { createCashbookHistoriesCollection, createCashbookTransactionsCollection } from './Cashbook.create'
import { createDeviceSettingCollection } from './DeviceSetting.create'
import { createMasterActionCollection } from './MasterAction.create'
import { createMenuCollection } from './Menu.create'
import { createOnlineOrderCollection } from './OnlineOrder.create'
import { createOnlineProviderCollection } from './OnlineProvider.create'
import { createOrderCollection, createPaidOrderCollection } from './Order.create'
import { createOrderLayoutCollection } from './OrderLayout.create'
import { createPaymentCollection } from './Payment.create'
import { createPosSyncCollection } from './PosSync.create'
import { createPrintScriptsCollection } from './PrintScripts.create'
import { createProductCollection } from './Product.create'
import { createProductLayoutCollection } from './ProductLayout.create'
import { createRoomCollection, createRoomObjectCollection } from './Room.create'
import type { PosDatabase2Collection, PosDatabase3Collection, PosDatabaseCollection } from './types'
import { createUserCollection } from './User.create'
import { createYearlyReportsCollection } from './YearlyReports.create'

import '@/data/ReplicateEffect.ts'

disableVersionCheck()

function createReactivityFactory<T = unknown>(): RxReactivityFactory<Accessor<T>> {
  return {
    fromObservable<Data, InitData>(observable$: Observable<Data>, initialValue: InitData): Accessor<T> {
      const [data, setData] = signal(initialValue as unknown as T)
      type V = Parameters<typeof setData>[0]
      observable$.subscribe(_data => {
        if (_.isArray(_data)) return setData(convertDocuments(_data as RxDocument[], true) as V)
        return setData(convertDocument(_data as RxDocument, true) as V)
      })
      return data
    },
  }
}

addRxPlugin(RxDBMigrationPlugin)
addRxPlugin(RxDBQueryBuilderPlugin)
addRxPlugin(RxDBUpdatePlugin)
addRxPlugin(RxDBAttachmentsPlugin)
addRxPlugin(RxDBLeaderElectionPlugin)
addRxPlugin(RxDBCleanupPlugin)
addRxPlugin(RxDBJsonDumpPlugin)
addRxPlugin(UpdatedAtPlugin)
// addRxPlugin(RxDBcrdtPlugin)
if (import.meta.env.MODE !== 'production') {
  addRxPlugin(RxDBDevModePlugin)
}

export const clearDatabases = async () => {
  // await database.v?.remove();
  // await database2.v?.remove();
  // initDatabase(false).then();
  // await removeRxDatabase('pos', optimizedRxStorage)
  // await removeRxDatabase('pos2', optimizedRxStorage)
  await clearAllCollections()
}

export const removeDatabases = async () => {
  await database.v?.remove()
  await database2.v?.remove()
  await database3.v?.remove()
}
export const exportDatabases = async () => [await database.v?.exportJSON(), await database2.v?.exportJSON(), await database3.v?.exportJSON()] as const
export const importDatabases = async ([d1, d2, d3]: Awaited<ReturnType<typeof exportDatabases>>) => {
  if (d1) await database.v?.importJSON(d1)
  if (d2) await database2.v?.importJSON(d2)
  if (d3) await database3.v?.importJSON(d3)
}

// Make available globally
Object.assign(window, {
  clear: clearDatabases,
  exportDatabases,
  importDatabases,
  database,
  database2,
  database3,
})

export const initDatabase: (needSync?: boolean) => Promise<void> = async (_needSync = true) => {
  // await removeRxDatabase('pos', getRxStorageDexie())
  // const storage = getRxStorageWorker(
  // 	{
  // 		statics: RxStorageOPFSStatics,
  // 		workerInput: workerUrl
  // 	}
  // )
  const storage = wrappedLoggerStorage({
    storage: getRxStorageIndexedDB({
      batchSize: 300,
    }),
    onOperationStart: (operationsName, logId, args) => {
      if (LogOperation.includes(operationsName)) {
        const span = createSpan(`onOperationStart ${operationsName}`, undefined, context.active())
        span?.setAttribute('storeId', `${posSync0()?.id}`)
        span?.setAttribute('args', JSON.stringify(args))
        span?.setAttribute('deviceId', getDeviceId())
        span?.setAttribute('logId', logId)
        span?.setAttribute('deviceName', deviceSetting0()?.name ?? '')
        span?.end()
      }
    },
    onOperationEnd: (_operationsName, _logId, _args) => {},
    onOperationError: (_operationsName, _logId, _args, _error) => {},
    //@ts-expect-error TODO: add typing for this
    settings: {
      times: false,
    },
  })

  const optimizedRxStorage = getLocalstorageMetaOptimizerRxStorage({
    storage: storage,
  })

  try {
    database.v = await createRxDatabase<PosDatabaseCollection>({
      name: 'pos',
      storage: optimizedRxStorage,
      ignoreDuplicate: true,
      reactivity: createReactivityFactory(),
    })

    //use for order
    database2.v = await createRxDatabase<PosDatabase2Collection>({
      name: 'pos2',
      storage: storage,
      ignoreDuplicate: true,
      reactivity: createReactivityFactory(),
    })

    database3.v = await createRxDatabase<PosDatabase3Collection>({
      name: 'pos3',
      storage: optimizedRxStorage,
      ignoreDuplicate: true,
      reactivity: createReactivityFactory(),
    })

    /**
     * database -> for all collection, which is like config or dedicated data,
     *   but not generated by time like order
     * database2 -> for order, which is generated by time
     * database3 -> for device settings & pos sync
     */
    await Promise.all([
      //#region Database 1
      createProductCollection(database.v),
      createUserCollection(database.v),
      createOrderLayoutCollection(database.v),
      createProductLayoutCollection(database.v),
      createRoomCollection(database.v),
      createRoomObjectCollection(database.v),
      createPaymentCollection(database.v),
      createGroupPrinterCollection(database.v),
      createModifierCollection(database.v),
      createTaxCategoryCollection(database.v),
      createPosSettingCollection(database.v),
      createTerminalCollection(database.v),
      createMaxIdCollection(database.v),
      createHappyHourCollection(database.v),
      createTimeClockCollection(database.v),
      createTseCertificateCollection(database.v),
      createTseConfigCollection(database.v),
      createCategoryCollection(database.v),
      createMenuCollection(database.v),
      createProductSyncCollection(database.v),
      createModifierSyncCollection(database.v),
      createOnlineProviderCollection(database.v),
      createInventoryCollection(database.v),
      //#endregion

      //#region Database 2
      createOrderCollection(database2.v),
      createOnlineOrderCollection(database2.v),
      createPaidOrderCollection(database2.v),
      createEodCollection(database2.v),
      createEodCacheCollection(database2.v),
      createPrintImageCollection(database2.v),
      createZvtLogCollection(database2.v),
      createTseTransactionCollection(database2.v),
      createOrderTseTempCollection(database2.v),
      createTimeClockCollection(database2.v),
      createTxCollection(database2.v),
      createTxRefundCollection(database2.v),
      createTxVoidCollection(database2.v),
      createReservationCollection(database2.v),
      createCheckPointCollection(database2.v),
      createInventoryActionCollection(database2.v),
      createCustomerCollection(database2.v),
      createCallCollection(database2.v),
      createMissedCallCollection(database2.v),
      createDiscountCollection(database2.v),
      createSrmTransactionLogCollection(database2.v),
      createSrmDocumentLogCollection(database2.v),
      createSrmEventLogCollection(database2.v),
      createVoucherCollection(database2.v),
      createLicenseCollection(database2.v),
      createCashbookTransactionsCollection(database2.v),
      createCashbookHistoriesCollection(database2.v),
      createImageCollection(database2.v),
      createOpenHourCollection(database2.v),
      createOnlineOrderPrinterGroupMappingCollection(database2.v) /*Obsolete*/,
      createOnlineOrderProductMappingCollection(database2.v),
      createOrderCommitsCollection(database2.v),
      createTableStaffStatusCollection(database2.v),
      createOrderHandlerCollection(database2.v),
      createUserActionHistoryCollection(database2.v),
      createYearlyReportsCollection(database2.v),
      createPrintScriptsCollection(database2.v),
      createMasterActionCollection(database2.v),
      //#endregion

      //#region Database 3
      createDeviceSettingCollection(database3.v),
      createPosSyncCollection(database3.v),
      //#endregion
    ])

    //todo: clean up, when weekly

    // Order.storageInstance.cleanup(0).then();
    // startCleanupForRxCollection(Order).then();

    if (dataLock.acquired) {
      dataLock.release().then()
    }
    setDbReady(true)
    console.log('release')

    // if (needSync) sync([database, database2, database3]).then()
  } catch (e) {
    console.error(e)
    // await window.clear()
    // await initDatabase();
  }
}
