import type { FunctionComponent } from "react";
import ModifierMainFieldset from "./Form/ModifierMainFieldset";
import ModifierTypeSingleFieldset from "./Form/ModifierTypeSingleFieldset";
import ModifierTypeQuantityFieldset from "./Form/ModifierTypeQuantityFieldset";
import ModifierTypeSelectFieldset from "./Form/ModifierTypeSelectFieldset";
import { STATES } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";

const ModifierEditingForm: FunctionComponent = () => {
  return (
    <div className="w-full overflow-y-auto flex flex-col items-start justify-start gap-[12px]">
      <div className="self-stretch flex flex-row items-center justify-center py-0 pr-0 pl-8 box-border w-auto h-auto gap-[8px] hover:flex hover:self-stretch hover:w-auto hover:h-auto hover:flex-row hover:gap-[8px] hover:items-center hover:justify-center hover:py-0 hover:pr-0 hover:pl-8 hover:box-border">
        <ModifierMainFieldset />
      </div>
      {(STATES.editingModifierType() === 'onlyOne' || STATES.editingModifierType() === 'multiple') ? (
      <ModifierTypeSingleFieldset />
      ) : STATES.editingModifierType() === 'quantity' ? (
      <ModifierTypeQuantityFieldset />
      ) : STATES.editingModifierType() === 'select' ? (
      <ModifierTypeSelectFieldset />
      ) : (
        <div className='w-full p-6 text-lg text-stone-500 text-center'>Loading...</div>
      )}
      </div>
  );
};

export default ModifierEditingForm;
