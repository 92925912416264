import React, { type FunctionComponent, useEffect, useState } from 'react';
import ButtonPayment from "./ButtonPayment";
import { LL0 } from '@/react/core/I18nService';
import {
  addServiceFee,
  changeTip,
  getActiveOrder,
  handleChangeServiceFee,
  handleChangeTip,
  isActivePaymentId,
  multiPayment0,
  onPaymentClick,
  onPaymentKeyClick,
  scopeOrder0, serviceFee0,
  setFirstClick0,
  setMultiPayment0,
  setValue0,
  updateValue0,
  value0
} from '@/react/PaymentView/PaymentView.tsx';
import { payments0, paymentsMap } from '@/data/PaymentHub.ts';
import _ from 'lodash';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { clsx } from "clsx";
import { printInvoice } from '@/react/Printer/print-invoice.ts';
import { InvoiceTypes } from "@/pos/OrderType.ts";
import { openCashDrawer } from '@/pos/cashdrawer.ts'
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import PortalPopup from "@payment/PortalPopup.tsx";
import InputPopup from "@/react/core/InputPopup.tsx";
import { setTip, tip } from "@payment/PaymentTipFee.tsx";
import { PosFeatures, isFeatureEnabled } from '@/srm/features.logic';
import { roundNumber, useServiceFeeBeforeTax } from "@/shared/order/order-config.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { toast } from "react-toastify";
import { PosScreen, router } from "@/pos/PosRouter.ts";

type PaymentVerticalAddPaymentType = {
  onClose?: () => void;
  onComplete?: () => Promise<boolean>;
};

const PaymentVerticalAddPayment: FunctionComponent<
  PaymentVerticalAddPaymentType
> = ({ onClose, onComplete }) => {

  const [showTip, setShowTip] = useState(false)
  const [showFee, setShowFee] = useState(false)

  const [isPopupCashTip, setIsPopupCashTip] = useSignal<boolean>(false)

  useEffect(() => {
    if (router.screen === PosScreen.ORDER_HISTORY) {
      _.assign(scopeOrder0(), {manualTip: 0})
    }
  }, []);


  return (
    <>
      {(showTip || showFee) && <div className="z-[2] fixed top-0 left-0 w-screen h-screen" onClick={() => {
        setShowFee(false);
        setShowTip(false);
      }} />}
      <div
        className="height_414:pt-[40px] no-scrollbar rounded [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.98),_rgba(232,_238,_241,_0.8)),_rgba(0,_0,_0,_0.6)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)_inset] w-full flex flex-col items-start justify-start pt-[45px] px-4 pb-4 box-border relative gap-[8px] max-w-full max-h-full overflow-auto text-left text-base text-white-solid-white-100-ffffff font-mulish-button-name-bold Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:gap-[8px] mq320:pl-2 mq320:pr-2 mq320:pb-3 mq320:box-border min-w-[539px]">
        <img
          className="w-[25px] absolute my-0 mx-[!important] top-[12px] right-[12px] h-[25.4px] cursor-pointer z-[3]"
          alt=""
          src="/payment-vertical-close-tab.svg"
          onClick={onClose}
        />
        <div
          className="self-stretch gap-[4px] grid grid-cols-[repeat(4,1fr)] [grid-row-gap:0px] [grid-column-gap:4px] z-[2]">
          <div className="flex flex-col items-center justify-start relative gap-[2px]">
            <div
              className="self-stretch rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[5px] px-1.5 relative gap-[4px] z-[0] mq320:gap-[2px] mq320:p-1 mq320:box-border">
              <div className="flex flex-row items-start justify-start gap-[4px] z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  {LL0().common.total()}
                </div>
              </div>
              <b
                className="self-stretch relative text-xl text-orange-solid-orange-200-ffc163 z-[1] Mobile_480:text-xs mq320:text-2xs">
                {LL3().format.currency(scopeOrder0().vSum || 0)}
              </b>
              <img
                className="absolute my-0 mx-[!important] top-[7px] right-[5.8px] w-[21px] h-[21px] object-cover hidden z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow@2x.png"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-start relative gap-[2px]">
            <div
              className="self-stretch rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[5px] px-1.5 relative gap-[4px] z-[0] mq320:gap-[2px] mq320:p-1 mq320:box-border"
              onClick={() => {
                if (isFeatureEnabled(PosFeatures.ChangeTipWhenSwitchPaymentMethod))
                  if (scopeOrder0()?.vSum <= 0) {
                    toast.warn('Order with total value less than 0 will not have tip applied!')
                    return
                  }
                  setIsPopupCashTip(v => !v)
              }}
            >
              <div className="flex flex-row items-start justify-start gap-[4px] z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  {LL0().payment.tip()}
                </div>
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  {LL3().format.currency(scopeOrder0().tip || 0)}
                </div>
              </div>
              <b
                className="self-stretch relative text-xl text-green-solid-green-250-5effa3 z-[1] Mobile_480:text-xs mq320:text-2xs">
                {LL3().format.currency(scopeOrder0().tip || 0)}
              </b>
              {isFeatureEnabled(PosFeatures.ChangeTipWhenSwitchPaymentMethod) &&
              <img
                className="absolute my-0 mx-[!important] top-[7px] right-[5.8px] w-[21px] h-[21px] object-cover z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow1@2x.png"
              />
              }
            </div>
            {showTip &&
              <div
                className="!z-[2] my-0 mx-[!important] absolute top-[104%] right-[-0.4%] left-[-0.4%] rounded-md [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.98),_rgba(232,_238,_241,_0.8)),_rgba(0,_0,_0,_0.6)] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] [backdrop-filter:blur(100px)] box-border flex flex-col items-start justify-start pt-2.5 px-2.5 pb-[11px] gap-[8px] z-[1] text-sm text-black-solid-black-880-1d1d26 border-[1px] border-solid border-blue-solid-blue-250-559ad1">
                {generalSetting0()?.defaultTips?.map((tip, index) => (
                  <div key={index} onClick={() => {
                    setShowTip(false);
                    changeTip(tip);
                    console.log(tip)
                  }}
                       className="rounded-md w-full h-6 flex flex-row items-center justify-center py-px px-0 box-border relative gap-[10px]">
                    <div
                      className={clsx("absolute my-0 mx-[!important] top-[0%] right-[0%] bottom-[0%] left-[0%] hidden rounded-md bg-lightskyblue z-[0]",
                        (scopeOrder0().tip === tip && scopeOrder0().tip !== 0)  && "!flex"
                      )} />
                    <b
                      className="relative z-[1]">{tip} %</b>
                  </div>))}
              </div>
            }
          </div>
          <div className="flex flex-col items-center justify-start relative gap-[2px]">
            <div
              className="self-stretch rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[5px] px-1.5 relative gap-[4px] z-[0] mq320:gap-[2px] mq320:p-1 mq320:box-border"
              onClick={() => {
                if (scopeOrder0()?.vSum <= 0) {
                  toast.warn('Order with total value less than 0 will not have a service fee applied!')
                  return
                }
                setShowFee(!showFee)
              }}
            >
              <div className="flex flex-row items-start justify-start gap-[4px] z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  {LL0().payment.fees()}
                </div>
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  {LL3().format.currency(scopeOrder0().serviceFee || 0)}
                </div>
              </div>
              <b
                className="self-stretch relative text-xl text-cyan-solid-cyan-280-80e0ff z-[1] Mobile_480:text-xs mq320:text-2xs">
                {LL3().format.currency(scopeOrder0().serviceFee || 0)}
              </b>
              <img
                className="absolute my-0 mx-[!important] top-[7px] right-[5.8px] w-[21px] h-[21px] object-cover z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow2@2x.png"
              />
            </div>
            {showFee &&
              <div
                className="!z-[2] my-0 mx-[!important] absolute top-[104%] right-[-0.4%] left-[-0.4%] rounded-md [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.98),_rgba(232,_238,_241,_0.8)),_rgba(0,_0,_0,_0.6)] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] [backdrop-filter:blur(100px)] box-border flex flex-col items-start justify-start pt-2.5 px-2.5 pb-[11px] gap-[8px] z-[1] text-sm text-black-solid-black-880-1d1d26 border-[1px] border-solid border-blue-solid-blue-250-559ad1">
                {generalSetting0()?.serviceCharges?.map((serviceFee, index) => {
                  const amount = useServiceFeeBeforeTax() ?
                    roundNumber((getActiveOrder().getNet?.() ?? 0) * serviceFee / 100, 2) :
                    roundNumber((getActiveOrder().vItemTotal ?? 0) * serviceFee / 100, 2)
                  return (
                  <div key={index} onClick={() => {
                    console.log('⚡️ Toggle ServiceFee', serviceFee, amount)
                    setShowFee(false)
                    handleChangeServiceFee(amount, serviceFee)
                  }}
                       className="rounded-md w-full h-6 flex flex-row items-center justify-center py-px px-0 box-border relative gap-[10px]">
                    <div
                      className={clsx("absolute my-0 mx-[!important] top-[0%] right-[0%] bottom-[0%] left-[0%] hidden rounded-md bg-lightskyblue z-[0]",
                        (serviceFee0() == amount && serviceFee0() !== 0) && '!flex'
                      )} />
                    <b
                      className="relative z-[1]">{serviceFee} %</b>
                  </div>
                  )}
                )}
              </div>
            }
          </div>
          <div className="flex flex-col items-center justify-start relative gap-[2px]">
            <div
              className="self-stretch rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[5px] px-1.5 relative gap-[4px] z-[0] mq320:gap-[2px] mq320:p-1 mq320:box-border">
              <div className="flex flex-row items-start justify-start gap-[4px] z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  {LL0().payment.change()}
                </div>
                <div className="relative font-semibold hidden Mobile_480:text-2xs mq320:text-3xs">
                  10%
                </div>
              </div>
              <b
                className="self-stretch relative text-xl text-pink-solid-pink-150-ffa8ff z-[1] Mobile_480:text-xs mq320:text-2xs">
                {LL3().format.currency(scopeOrder0().cashback || 0)}
              </b>
              <img
                className="absolute my-0 mx-[!important] top-[7px] right-[5.8px] w-[21px] h-[21px] object-cover hidden z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow3@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start min-h-[54px] z-[1]">
          <div
            className="no-scrollbar self-stretch overflow-y-auto gap-[4px] grid grid-rows-[repeat(auto,1fr)] grid-cols-[repeat(4,1fr)] [grid-row-gap:4px] [grid-column-gap:4px] Mobile_480:h-[140px] Mobile_480:grid Mobile_480:grid-rows-[repeat(2,1fr)] Mobile_480:grid-cols-[repeat(2,1fr)] Mobile_480:[grid-row-gap:4px] Mobile_480:[grid-column-gap:4px] mq320:h-[140px] mq320:grid mq320:grid-rows-[repeat(2,1fr)] mq320:grid-cols-[repeat(2,1fr)] mq320:[grid-row-gap:4px] mq320:[grid-column-gap:4px]">
            {payments0().map((payment, index) =>
              <ButtonPayment
                itemCode={payment.icon}
                buttonText={_.startCase(payment.name)}
                isAddTip={false}
                showPaymentIcon
                isSelected={isActivePaymentId(payment.name!)}
                onClick={() => onPaymentClick(payment)}
                key={index}
              />
            )}
          </div>
        </div>
        <div
          className="self-stretch flex flex-col items-center justify-start gap-[4px] z-[0] text-right text-green-solid-green-700-1c974d font-verdana">
          <div
            className="self-stretch h-[22px] overflow-hidden shrink-0 flex flex-row items-center justify-end gap-[8px]">
            {scopeOrder0().payments.map((payment, index) => (
              <div className="flex flex-row items-center justify-start gap-[8px]" key={index}>
                <img
                  className="!object-contain relative w-10 h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-7 mq320:h-7"
                  alt=""
                  src={paymentsMap().get(payment.type)?.icon}
                />
                <b className="relative Mobile_480:text-sm mq320:text-sm">{LL3().format.currency(payment.value)}</b>
              </div>
            ))}
          </div>
        </div>
        <div
          className="self-stretch flex flex-col items-start justify-start gap-[3px] text-left text-xl text-black-solid-black-900-1e1e23 font-mulish-button-name-bold">
          <div
            className="self-stretch rounded-10xs bg-gray-solid-gray-350-ced1d7 shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] h-[50px] flex flex-row items-center justify-start py-0 px-3 box-border relative gap-[8px] Mobile_480:h-11">
            <div
              className="absolute my-0 mx-[!important] h-[calc(100%_+_1.5px)] w-[calc(100%_+_1.5px)] top-[-0.7px] right-[-0.8px] bottom-[-0.8px] left-[-0.7px] rounded-10xs box-border z-[0] border-[1.5px] border-solid border-cyan-solid-cyan-250-8aeaff" />
            <div className="flex-1 relative font-semibold z-[1] mq320:text-mini">
              {value0()}
            </div>
            <div className="flex flex-row items-center justify-end gap-[0px_20px] z-[2]">
              <img
                className="!object-contain relative w-[22px] h-[22px] object-cover mq320:w-[18px] mq320:h-[18px]"
                alt=""
                src="/icon-vertical-lclose-popup-icon--grey1@2x.png"
                onClick={() => {
                  setFirstClick0(true);
                  updateValue0();
                }}
              />
              {isFeatureEnabled(PosFeatures.MultiplePayment) &&
              <div className="w-10 h-8 flex flex-col items-start justify-start relative"
                   onPointerDown={() => {
                     setMultiPayment0(prev => !prev);
                   }}
              >
                {!multiPayment0() ?
                  <img
                    className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover z-[0]"
                    alt=""
                    src="/iconpaymentverticalmultiple-yellow@2x.png"
                  />
                  :
                  <img
                    className="relative w-[40.7px] h-[33px] object-cover z-[1]"
                    alt=""
                    src="/iconpaymentverticalmultiple-red@2x.png"
                  />
                }
              </div>
              }
            </div>
          </div>
          <div
            className="self-stretch flex-1 relative grid grid-rows-[repeat(5,1fr)] grid-cols-[repeat(3,1fr)] [grid-row-gap:3px] [grid-column-gap:3px] text-center text-4xl">
            {[7, 8, 9, 4, 5, 6, 1, 2, 3, 0, ','].map((keyNumber, index) =>
              <div
                className="rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] flex flex-row items-center justify-center relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-700-898a8d"
                ref={makeRipple}
                onClick={() => onPaymentKeyClick(keyNumber.toString())}
                key={index}
              >
                <div
                  className="absolute my-0 mx-[!important] h-[calc(100%_+_0.5px)] w-[calc(100%_+_0.3px)] top-[-0.2px] right-[-0.1px] bottom-[-0.3px] left-[-0.2px] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border hidden z-[0] border-[0.5px] border-solid border-gray-solid-gray-700-898a8d" />
                <b className="flex-1 relative z-[1] Mobile_480:text-xl mq320:text-lg">
                  {keyNumber}
                </b>
              </div>
            )}
            <div
              className="rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] flex flex-row items-center justify-center relative gap-[10px] col-[3/3] row-[4/4] border-[0.5px] border-solid border-gray-solid-gray-700-898a8d"
              ref={makeRipple}
              onClick={() => onPaymentKeyClick('<-')}
            >
              <div
                className="absolute my-0 mx-[!important] h-full w-[calc(100%_-_0.2px)] top-[-0.2px] right-[0.4px] bottom-[0.2px] left-[-0.2px] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border z-[0] border-[0.5px] border-solid border-gray-solid-gray-700-898a8d" />
              <div className="relative w-9 h-9 z-[1]">
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/delete-keyboard@2x.png"
                />
              </div>
            </div>
            {isFeatureEnabled(PosFeatures.RedBill) && <>
            <button className="rounded-10xs flex flex-row items-center justify-center relative gap-[0px_10px] col-[1/1] row-[5/5] text-base text-basic-color-white Mobile_480:col-[1/1] Mobile_480:row-[5/5] mq320:col-[1/1] mq320:row-[5/5]"
              ref={makeRipple}
              onClick={() => openCashDrawer()}
            >
              <div className="w-[calc(100%_+_1px)] absolute my-0 mx-[!important] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.8px] bottom-[-0.2px] left-[-0.2px] rounded-10xs [background:linear-gradient(175.65deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#10e095] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border z-[0] border-[0.5px] border-solid border-button-stroke-gray" />
              <div className="w-[calc(100%_+_1px)] absolute my-0 mx-[!important] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.8px] bottom-[-0.2px] left-[-0.2px] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border hidden z-[1] border-[0.5px] border-solid border-purple-linear-purple-linear-4000" />
              <div className="relative font-semibold z-[2] Mobile_480:text-sm mq320:text-mini">
                {LL0().payment.drawer()}
              </div>
            </button>
            <ButtonWrapper onClick={() => {
              printInvoice(scopeOrder0());
            }} className={'h-full !rounded-10xs flex flex-row items-center justify-center relative gap-[0px_10px] col-[2/2] row-[5/5] text-base text-basic-color-white Mobile_480:col-[2/2] Mobile_480:row-[5/5] mq320:col-[2/2] mq320:row-[5/5]'}>
              <div className="h-full w-full rounded-10xs flex flex-row items-center justify-center relative gap-[0px_10px] text-base text-basic-color-white "
              >
                <div className="w-[calc(100%_+_1px)] absolute my-0 mx-[!important] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.8px] bottom-[-0.2px] left-[-0.2px] rounded-10xs [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border z-[0] border-[0.5px] border-solid border-button-stroke-gray" />
                <div className="w-[calc(100%_+_1px)] absolute my-0 mx-[!important] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.8px] bottom-[-0.2px] left-[-0.2px] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border hidden z-[1] border-[0.5px] border-solid border-purple-linear-purple-linear-4000" />
                <div className="relative font-semibold z-[2] Mobile_480:text-sm mq320:text-mini">
                  {LL0().ui.print()}
                </div>
              </div>
            </ButtonWrapper>
            <button className="disabled:opacity-40 rounded-10xs [background:linear-gradient(182.19deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#df0000] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] flex flex-row items-center justify-center relative col-[3/3] row-[5/5] text-base text-basic-color-white border-[0.5px] border-solid border-button-stroke-gray Mobile_480:relative Mobile_480:col-[3/3] Mobile_480:row-[5/5] mq320:relative mq320:col-[3/3] mq320:row-[5/5]"
              ref={makeRipple}
              onClick={async () => {
                await printInvoice(scopeOrder0(), InvoiceTypes.RED_INVOICE);
              }}
            >
              <div className="relative font-semibold Mobile_480:text-sm mq320:text-mini">
                {LL0().fnBtn.paymentFunctions.redBill()}
              </div>
            </button>
            </>}
          </div>
          <div className="self-stretch h-[45px] rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border flex flex-row items-center justify-center text-base text-basic-color-white border-[0.5px] border-solid border-button-stroke-gray Mobile_480:h-12 mq320:h-[37px]"
             onClick={async () => {
               if((await onComplete?.()) === false) return;
               onClose?.();
             }}
             ref={makeRipple}
          >
            <div className="relative font-semibold Mobile_480:text-sm mq320:text-mini">
              {LL0().ui.confirm()}
            </div>
          </div>
        </div>
      </div>
      {isPopupCashTip() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsPopupCashTip(false)}>
          <InputPopup
            label={'Tip'}
            value={tip()}
            modeKeyboard={1}
            onEnter={() => {
              const isValid =
                !tip().includes('..') &&
                !tip().startsWith('.') &&
                !tip().endsWith('.') &&
                /^[0-9.]*$/.test(tip());
              if (!isValid) {
                toast.warning("Please enter a number!")
              } else {
                if (scopeOrder0().manualTip && router.screen === PosScreen.ORDER_HISTORY) {
                  _.assign(scopeOrder0(), {manualTip: 0})
                  _.assign(scopeOrder0(), {cashTip: 0})
                }
// decrease value0 before change tip
                const newValue = Number(value0() || 0) - Number(scopeOrder0()?.tip);
                setValue0(String(newValue))

                handleChangeTip(tip())
                setIsPopupCashTip(false)
                setTip('')
              }
            }}
            onChange={value => setTip(value)} />
        </PortalPopup>
      )}
    </>
  );
};

export default PaymentVerticalAddPayment;
