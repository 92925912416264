import type {Accessor, EffectFunction, Signal, Setter} from "solid-js";
import {
  batch as batch0,
  computed as computed0,
  effect as effect0,
  signal as signal0, untracked,
  useComputed as useComputed0,
  useSignal as useSignal0,
  useSignalEffect as useSignalEffect0,
} from "@preact/signals-react";
import {useCallback, useEffect, useMemo as useMemo0} from "react";
import signalStruct from "@/react/core/signal-struct";
import {useDeepCompareEffect, useFirstMountState} from "@react-hookz/web";
import {deepSignal, useDeepSignal} from "deepsignal/react";

export {deepSignal, useDeepSignal};

export type {Accessor, EffectFunction, Signal, Setter};

export const useSignalEffect = (fn: (() => void), options: { defer?: boolean } = {}) => {
	const isFirstRender = options.defer ? useFirstMountState() : false;
	useSignalEffect0(() => {
		if (isFirstRender && options?.defer) return;
		fn();
	});
};

export const useAsyncEffect = (fn: Function, dependencies: any[] = [], options: { defer?: boolean, deep?: boolean } = {}) => {
	const isFirstRender = options.defer ? useFirstMountState() : false;
	if (options?.deep) {
		useDeepCompareEffect(() => {
			if (isFirstRender && options?.defer) return;
			fn();
		}, dependencies);
	} else {
		useEffect(() => {
			if (isFirstRender && options?.defer) return;
			fn();
		}, dependencies);
	}
}

const effect = effect0;

export const effectOn = (dependencies: Accessor<any>[], fn: Function, options: { defer?: boolean } = {}) => {
	let isFirstTime = true;
	const arr = dependencies.map(dep => computed(dep));
	return effect(() => {
		arr.forEach(dep => dep());
		if (isFirstTime && options.defer) {
			isFirstTime = false;
			return;
		}
    untracked(fn as any);
		// setTimeout(fn, 0);
	})
}

export const useEffectOn = (dependencies: Accessor<any>[], fn: Function, options: { defer?: boolean } = {}) => {
	let isFirstTime = true;
	const arr = dependencies.map(dep => useComputed(dep));
	useSignalEffect0(() => {
		arr.forEach(dep => dep());
		if (isFirstTime && options.defer) {
			isFirstTime = false;
			return;
		}
		setTimeout(fn, 0);
	})
}

export const onMount = (fn: () => void) => {
	useEffect(() => {
		fn();
	}, []);
};

export const useComputed = <T, >(fn: () => T): Accessor<T> => {
	const result = useComputed0(fn);
	const getter = useCallback(() => result.value, [result]);
	return getter;
};

export const computed = <T, >(fn: () => T): Accessor<T> => {
	const result = computed0(fn);
	const getter = () => result.value;
	return getter;
};

export function useLiveSignal<T = any>(value: T): Signal<T> {
	const [v, setV] = useSignal<T>(value);
	if ((v as any)(true) !== value) setV(value as any);
	return [v, setV];
}

export const useSignal = <T, >(value?: T): Signal<T> => {
	const v = useSignal0<T>(value as any);
	const setter: any = useCallback((val: any) => {
		if (typeof val === "function") {
			v.value = val(v.peek());
			return;
		}
		v.value = val;
	}, []);
	const getter = useCallback((raw?: boolean) => {
		if (raw) return v.peek();
		return v.value;
	}, []);
	return [getter, setter];
};

export const signal = <T, >(value?: T): Signal<T> => {
	const v = signal0<T>(value as any);
	const setter: any = (val: any) => {
		if (typeof val === "function") {
			v.value = val(v.peek());
			return;
		}
		v.value = val;
	};
	const getter = () => v.value;
	return [getter, setter];
};

export const useMutable = <T extends object, >(value: T): T => {
	const v = useMemo0(() => signalStruct<T>(value), []);
	return v;
};

export const mutable = signalStruct;

type SetActive = (value: (((prevState: boolean) => boolean) | boolean)) => void;

export const makeActiveFactory: () => (setActive: SetActive) => void = () => {
	let currentSetActive: Function;
	const makeActive = (setActive: SetActive) => {
		currentSetActive?.(false);
		currentSetActive = setActive;
		setActive(true);
	};
	return makeActive;
};

export const selector = <T, V = T>(s: Accessor<T>, fn?: Function) => {
	const isSelected = (_s: V | T) => {
		const [isActive, setIsActive] = signal(false);
		effect(() => {
			setIsActive(fn?.(_s, s()) || s() === _s);
		})
		return isActive();
	}
	return isSelected;
};

/**
 * do not use in map -> use makeActiveFactory or useSelectorRaw
 * @param s
 * @param fn
 */
export const useSelector = <T, V = T>(s: Accessor<T>, fn?: (p1: T, p2: V) => boolean, deps?: any[]) => {
	const [v, setV] = useSignal<V>();
	const [isActive, setIsActive] = useSignal(false);
	useEffect(() => {
		effect(() => {
			setIsActive(fn?.(s(), v()) || s() === (v() as any));
		})
	}, []);
	const isSelected = (_s: V) => {
		useEffect(() => {
			setV(() => _s);
		}, deps);
		return isActive();
	};
	return isSelected;
};

export const useSelectorRaw = <T, V = T>(s: Accessor<T>, fn?: Function) => {
	const isSelected = (_s: V | T) => {
		return fn?.(_s, s()) || s() === _s;
	};
	return isSelected;
}

export {
	effect,
	batch0 as batch
};

//problem : createMutable

/**
 * Creates a signal that is tied to a value in local storage.
 */
export function signalSyncedWithLocalStorage<T>(
  /** The key to store the value under in local storage. */
  key: string,
  /** Default value of the signal, used in case the value is not found in local storage. */
  defaultValue: T,
  /** An options object that can be used to customize the behavior of the signal. */
  options?: {
    /**
     * A function that takes a string and returns the value to be used for the signal.
     * Defaults to JSON.parse if not provided.
     */
    parse: (value: string | null) => T | undefined
    /**
     * A function that takes the value of the signal and returns the string to be stored in local storage.
     * Defaults to JSON.stringify if not provided.
     */
    stringify: (value: T) => string | null
  }
): Signal<T> {
  if (!options)
    options = {
      parse: a => (a === null ? defaultValue : JSON.parse(a)),
      stringify: a => (typeof a === 'undefined' ? null : JSON.stringify(a)),
    }
  const savedVal = options.parse(localStorage.getItem(key))
  const v = signal0(savedVal ?? defaultValue)
  const setter: any = (val: any) => {
    const nextVal: T = typeof val === 'function' ? val(v.peek()) : val
    v.value = nextVal

    // Save the value to local storage, or clear it if it's `null`.
    const formatted = options.stringify(nextVal)
    if (formatted === null) localStorage.removeItem(key)
    else localStorage.setItem(key, formatted)
  }
  const getter = () => v.value
  return [getter, setter] as const
}
