import { FunctionComponent, useCallback, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import DeleteConfirmationPopup from '@edit-room/DeleteConfirmationPopup';
import PortalPopup from '@edit-room/PortalPopup';
import EditRoomColorSelect from '@edit-room/EditRoomColorSelect';
import {
  copyRoomObject,
  deleteRoomObject,
  EditorMode,
  isSelectedMode,
  WALL_COLORS
} from '@/react/EditRoomView/EditRoomView.tsx';
import {
   roomObject0,
} from '@/react/EditRoomView/EditRoomView.tsx';
import _ from 'lodash';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import {LL0} from "@/react/core/I18nService.tsx";
import TextField from '@/react/core/TextField.tsx';

type EditWallFormType = {
  isWallEdit?: boolean;
};

const EditWallForm: FunctionComponent<EditWallFormType> = ({
  isWallEdit = true,
}) => {
  isWallEdit = isSelectedMode(EditorMode.Wall)

  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  return (
    <>
      {isWallEdit && (
        <div className="self-stretch rounded bg-[#fbfbfc] shadow-[0px_0px_8px_rgba(75,_74,_95,_0.3)] flex flex-col items-start justify-center py-3.5 px-[11px] gap-[10px] text-left text-sm text-royalblue-300 font-mulish">
          <b className="self-stretch relative md:text-xs">{LL0().restaurant.wallName()}:</b>
          <div className="self-stretch flex flex-col items-start justify-start py-0 px-2.5">
            <TextField
              className="self-stretch"
              color="primary"
              name="wallNote"
              size="small"
              label={LL0().restaurant.wallName()}
              placeholder={LL0().restaurant.wallName()}
              fullWidth={true}
              variant="outlined"
              onChange={e => _.assign(roomObject0(), {name: e.target.value})}
              value={roomObject0()?.name || ''}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-[3px] px-0 relative">
            <b className="relative z-[0] md:text-xs">{LL0().restaurant.radius()}:</b>
            <FormControlLabel
              className="absolute my-0 mx-[!important] h-full top-[0px] right-[-11px] bottom-[0px] z-[1]"
              control={<Switch
                color="primary" size="small"
                onChange={e => _.assign(roomObject0(), {shape: e.target.checked ? 'circle' : 'rectangle'})}
                checked={roomObject0()?.shape === 'circle' || false}
              />}
              label=''
            />
          </div>
          <b className="self-stretch relative md:text-xs">{LL0().restaurant.color()}:</b>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 px-2.5 gap-[8px]">
            {WALL_COLORS.map((item, index) => (
              <EditRoomColorSelect
                key={index}
                isSelected={roomObject0()?.bgColor === item}
                editRoomColorSelectBackgroundColor={item}
                onClick={() => _.assign(roomObject0(), {bgColor: item})}
              />
            ))}
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-[3px] px-0 relative">
            <b className="relative z-[0] md:text-xs">{LL0().restaurant.transparent()}:</b>
            <FormControlLabel
              className="absolute my-0 mx-[!important] h-full top-[0px] right-[-11px] bottom-[0px] z-[1]"
              control={<Switch color="primary" size="small"
              checked={roomObject0()?.isTransparent || false}
              onChange={(e) => _.assign(roomObject0(), {isTransparent: e.target.checked})}/>}
              label=''
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-smi text-black">
            <div className="self-stretch flex-1 rounded-md flex flex-row items-center justify-center py-2.5 px-3 gap-[6px] border-[1px] border-solid border-black"
              onPointerDown={copyRoomObject}
                 ref={makeRipple}
            >
              <img
                className="relative w-3.5 h-4"
                alt=""
                src="/duplicate-icon1.svg"
              />
              <b className="relative leading-[15px] md:text-2xs">{LL0().restaurant.duplicate()}</b>
            </div>
            <div
              className="flex-1 rounded-md flex flex-row items-center justify-center py-[9.5px] px-3 gap-[8px] cursor-pointer text-tomato-200 border-[1px] border-solid border-tomato-100"
              onClick={openDeleteConfirmationPopup}
            >
              <img
                className="relative w-[17px] h-[17px] object-cover"
                alt=""
                src="/iconbin-icon--red21@2x.png"
              />
              <b className="relative leading-[15px] inline-block w-[42.8px] shrink-0 md:hidden">
                {LL0().ui.delete()}
              </b>
            </div>
          </div>
        </div>
      )}
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onComplete={deleteRoomObject} />
        </PortalPopup>
      )}
    </>
  );
};

export default EditWallForm;
