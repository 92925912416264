import GeneralSettingsPlugin from '@general-settings/GeneralSettingsPlugin.tsx'
import { captureException } from '@sentry/react'
import _ from 'lodash'
import { memo } from 'react'

import { Image } from '@/data/Image.ts'
import { generalSetting0 } from '@/data/PosSettingsSignal.ts'
import { signal } from '@/react/core/reactive.ts'
import { fileToBase64, imageUrlToBase64 } from '@/react/shared/utils.ts'
import { srmLogic } from '@/srm/logic'
import { isInSrmTrainingMode } from '@/srm/signals'
import { srmTransactionLogic } from '@/srm/transaction.logic'

// value represented by ms (milliseconds)
export const AUTO_LOGOUT_PERIODS = [
  {
    label: '15 mins',
    value: 15 * 60 * 1000,
  },
  {
    label: '30 mins',
    value: 30 * 60 * 1000,
  },
  {
    label: '1 hour',
    value: 60 * 60 * 1000,
  },
  {
    label: '2 hours',
    value: 2 * 60 * 60 * 1000,
  },
  {
    label: '3 hours',
    value: 3 * 60 * 60 * 1000,
  },
]

export const mergeSimilarItemSwitch = () => ({
  checked: !!generalSetting0()?.mergeSimilarItem ?? true,
  onChange: (e: any) => {
    _.assign(generalSetting0(), { mergeSimilarItem: e.target.checked })
  },
})

export const [imageChosen, setImageChosen] = signal<string>()
export const [loadingImage, setLoadingImage] = signal<boolean>()
export const onToggleTrainingMode = async (): Promise<void> => {
  await srmTransactionLogic.sendOfflineTransactionsUnattended()
  await srmLogic.setSrmTrainingMode(!isInSrmTrainingMode())
}
export const onSetSrmEnabled = async (flag: boolean) => {
  await srmTransactionLogic.sendOfflineTransactionsUnattended()
  _.assign(generalSetting0(), { quebecSrmEnable: flag })
}

export const handleChooseBackground = async (file: File | undefined, background?: string) => {
  if (file) {
    fileToBase64(file)
      .then(async base64String => {
        await updateBackground(base64String as string)
      })
      .catch(error => {
        console.log('Error:', error)
        captureException(new Error('Error converting file to base64:', error))
      })
  } else if (background) {
    await updateBackground(background)
  }
}

async function updateBackground(base64: string) {
  setImageChosen(base64 as string)
  await Image.upsert({
    _id: 'background',
    type: 'SecondDisplayBackground',
    data: base64 as string,
  })
}

export const handleUrlBackground = async (url: string | undefined) => {
  if (!url) return
  imageUrlToBase64(url)
    .then(async base64String => {
      await updateBackground(base64String as string)
    })
    .catch(error => {
      console.log('Error:', error)
      captureException(new Error('Error converting url to base64:', error))
    })
}

const GeneralSettingsView = () => {
  return <GeneralSettingsPlugin />
}

export default memo(GeneralSettingsView)
