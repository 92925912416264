import debug from 'debug'
import { sortBy } from 'lodash'
import { map, tap } from 'rxjs'

import { Category } from '@/data/Category'
import { convertDocuments, type DocDeepSignal } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils.ts'
import { computed, signal, useAsyncEffect } from '@/react/core/reactive'

const log = debug('data:category')

export const [categories0, setCategories0] = signal<Array<DocDeepSignal<Category>>>([])

let dataLoaded = false
export const makeCategoriesAvailable = () => {
  useAsyncEffect(async () => {
    log('⚡️ Loading category data...')

    if (dataLoaded) return
    await dataLock.acquireAsync()
    const sub = Category.find()
      .$.pipe(
        map(a => convertDocuments(a, true)),
        tap(a => setCategories0(a)),
        tap(a => log('⚡️ Category data loaded', a))
      )
      .subscribe()
    dataLoaded = true

    return () => sub.unsubscribe()
  })
}

export const materialCategories = computed(() =>
  sortBy(
    categories0().filter(category => !category.menus),
    a => a.position ?? 0
  )
)
