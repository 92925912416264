import { UserAction, UserActionHistory } from "@/data/UserActionHistory.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";

export function generateUserActionMessages(userActions: UserActionHistory[]): string[] {
  const payments = (data1: string) => _.get(LL0().payment, data1)?.() || data1
  return userActions.map((action) => {
    const { kind, data } = action;
    let message = ``;

    switch (kind) {
      case UserAction.ADD_ITEM:
        message += `${LL0().srm.userAction.addItem({nameProduct:data?.productRef?.name || '', priceProduct: LL3().format.currency(data?.productRef?.price || 0)})} `;
        break;

      case UserAction.CANCEL_ADD_ITEM:
        message += `${LL0().srm.userAction.cancelAddItem({nameProduct:data?.productRef?.name || ''})} `
        break;

      case UserAction.DELETE_ITEM:
        message += `${LL0().srm.userAction.deleteItem({nameProduct:data?.productRef?.name || ''})} `;
        break;

      case UserAction.CANCEL_DELETE_ITEM:
        message += `${LL0().srm.userAction.cancelDeleteItem({nameProduct:data?.productRef?.name || ''})} `;
        break;

      case UserAction.INCREASE_QUANTITY:
        message += `${LL0().srm.userAction.increaseQuantity({nameProduct:data?.productRef?.name || '', quantity: data?.quantity || 0})} `;
        break;

      case UserAction.DECREASE_QUANTITY:
        message += `${LL0().srm.userAction.decreaseQuantity({nameProduct:data?.productRef?.name || '', quantity: data?.quantity || 0})} `;
        break;

      case UserAction.ADD_MODIFIER:
        message += `${LL0().srm.userAction.addModifier({modifierProduct: data?.productRef?.modifierName ,modifierPrice: LL3().format.currency(data?.productRef?.modifierPrice || 0),nameProduct:data?.productRef?.name || ''})} `;
        break;

      case UserAction.CHANGE_DISCOUNT_ORDER:
        message += `${LL0().srm.userAction.changeDiscountOrder({valueFrom:data?.valueFrom, valueTo:data?.valueTo})} `;
        break;

      case UserAction.CLEAR_DISCOUNT_ORDER:
        message += `${LL0().srm.userAction.clearDiscountOrder()} `;
        break;
      case UserAction.CANCEL_CHANGE_DISCOUNT_ORDER:
        message += `${LL0().srm.userAction.cancelChangeDiscountOrder({valueFrom:data?.valueFrom ? `${LL0().onlineOrder.from()} ${data.valueFrom}` : '', valueTo:data?.valueTo ? `${LL0().onlineOrder.to()} ${data.valueTo}` : ''})} `;
        break;

      case UserAction.CHANGE_DISCOUNT_ITEM:
        message += `${LL0().srm.userAction.changeDiscountItem({nameProduct:data?.productRef?.name || '', valueFrom:data?.valueFrom, valueTo:data?.valueTo})} `;
        break;

      case UserAction.CANCEL_CHANGE_DISCOUNT_ITEM:
        message += `${LL0().srm.userAction.cancelChangeDiscountItem({nameProduct:data?.productRef?.name || '', valueFrom:data?.valueFrom, valueTo:data?.valueTo})} `;
        break;

      case UserAction.CHANGE_ITEM_INFO:
        message += `${LL0().srm.userAction.changeItemInfo({productOldName:data?.productRef?.oldName || '', productOldPrice: LL3().format.currency(data?.productRef?.oldPrice || 0), nameProduct: data?.productRef?.name || LL0().srm.userAction.newItem() ,productNewPrice: LL3().format.currency(data?.productRef?.price || 0),})} `;
        break;

      case UserAction.CANCEL_CHANGE_ITEM_INFO:
        message += `${LL0().srm.userAction.cancelChangeItemInfo({nameProduct:data?.productRef?.name || '', productPrice: LL3().format.currency(data?.productRef?.price || 0)})} `;
        break;

      case UserAction.CHANGE_PAYMENT:
        message += `${LL0().srm.userAction.changePayment({paymentName: payments(data?.paymentName || "") || LL0().srm.userAction.unknownPayment(), valueFrom: LL3().format.currency(Number(data?.valueFrom!)), valueTo:LL3().format.currency(Number(data?.valueTo!)), oldPayment: payments(data?.oldPayment || "") ? `(${payments(data?.oldPayment || "")})` : ``})} `;
        break;

      case UserAction.CHANGE_TIP:
        message += `${LL0().srm.userAction.changeTip({valueFrom:LL3().format.currency(Number(data?.valueFrom!)), valueTo:LL3().format.currency(Number(data?.valueTo!))})} `;
        break;

      case UserAction.CHANGE_SERVICE_FEE:
        message += `${LL0().srm.userAction.changeServiceFee({valueFrom:LL3().format.currency(Number(data?.valueFrom!)), valueTo:LL3().format.currency(Number(data?.valueTo!))})} `;
        break;

      case UserAction.REFUND_ITEM:
        message += `${LL0().srm.userAction.refundItem({nameProduct:data?.productRef?.name || '', productPrice: LL3().format.currency(data?.productRef?.price || 0)})} `;
        break;

      case UserAction.REFUND_AMOUNT:
        message += `${LL0().srm.userAction.refundAmount({valueTo:data?.valueTo || 0})} `;
        break;

      case UserAction.CANCEL_REFUND:
        message += `${LL0().srm.userAction.cancelRefund()} `;
        break;

      case UserAction.CANCEL_PAYMENT:
        message += `${LL0().srm.userAction.cancelPayment()} `;
        break;

      case UserAction.CANCEL_TIP:
        message += `${LL0().srm.userAction.cancelTip()} `;
        break;

      case UserAction.CANCEL_SERVICE_FEE:
        message += `${LL0().srm.userAction.cancelServiceFee()} `;
        break;

      default:
        message += `${LL0().srm.userAction.defaultMess()} `;
    }
    if (action?.transNo) message += `${LL0().srm.userAction.messageTransaction({transId:action?.transNo})} `;;
    return message;
  });
}