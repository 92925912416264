import { forwardRef, type FunctionComponent, useEffect, useState} from "react";
import VirtualPrinterInvoiceCard from "./VirtualPrinterInvoiceCard";
import { VirtuosoGrid, type VirtuosoGridProps } from 'react-virtuoso'
import _ from "lodash"
import { CircularProgress } from "@mui/material"
import { images0, isLastPage, virtualPrinterLogic, IMAGE_HEIGHT } from "@/react/VirtualPrinterView/VitualPrinterLogic.ts";
import type { PrintScripts } from "@/data/PrintScripts"
import { LL0 } from "@/react/core/I18nService"

const components: VirtuosoGridProps<PrintScripts>['components'] = ({
  List: forwardRef(({ children, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className="p-2 gap-4 grid grid-cols-3 xl:grid-cols-4"
    >
      {children}
    </div>
  )),
  Item: forwardRef(({ children, ...props }, ref) => (
    <div 
      ref={ref}
      {...props}
      className="h-[240px] flex items-center justify-center rounded overflow-hidden"
     >
      {children}
    </div>
  )),
  Footer: () => (
    <div className="xl:col-span-4 col-span-3 flex items-center gap-1 justify-center p-4">
      {isLastPage() ? 
        <div className="text-white">{LL0().ui.noItemLeft()}</div>
        :
        <>
          <CircularProgress size="1em" />
          <div className="text-white">{LL0().ui.loadMore()}...</div>
        </>
      }
    </div>
  )
})

const VirtualPrinterContent: FunctionComponent = () => {
  return (
    <div className="h-full no-scrollbar self-stretch overflow-y-auto p-2">
      <VirtuosoGrid
        data={images0()}
        increaseViewportBy={IMAGE_HEIGHT * 2}
        endReached={virtualPrinterLogic.handleEndReached}
        itemContent={(_index, item) => (<VirtualPrinterInvoiceCard key={item._id} img={item} />)}
        components={components}
      />
    </div>
  );
};

export default VirtualPrinterContent;
