import type { SrmTransactionLog } from '@/data/SrmTransactionLog'
import type { OrderSrmTransaction } from '@/pos/OrderType'

/**
 * Convert SRM Transaction Log into OrderSrmTransaction
 */
export function transLog2orderLog(t: SrmTransactionLog): OrderSrmTransaction {
  return {
    _id: t._id,
    date: t.date,
    total: t.total,
    ...t.data,
  }
}
