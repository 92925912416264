import {type FunctionComponent, memo} from "react";
import CustomerTableWrapper from "./CustomerTableWrapper";
import BottomBar from "./BottomBar";

const CustomerInformationPlugin: FunctionComponent = () => {
  return (
    <div className="customer-info-screen w-full h-full bg-black-solid-black-700-364150 shadow-[2px_0px_20px_rgba(0,_0,_0,_0.14)] overflow-hidden flex flex-col items-start justify-between relative">
      <CustomerTableWrapper />
      <BottomBar />
    </div>
  );
};

export default memo(CustomerInformationPlugin);
