import { useMemo } from "react"
import uuid from "time-uuid"

export function getDeviceId() {
  const id = localStorage.getItem('deviceId')
  if (id) {
    return id
  } else {
    const _id = uuid()
    localStorage.setItem('deviceId', _id)
    return _id
  }
}

export const useDeviceId = () => {
  const deviceId = useMemo(() => {
    return getDeviceId()
  }, [])
  return deviceId
}
