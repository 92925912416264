import { Buffer } from 'buffer/'
import delay from 'delay'
import Dexie from 'dexie'
import IDBExportImport from 'indexeddb-export-import'
import { md5 } from 'js-md5'
import pako from 'pako'
import { toast } from 'react-toastify'

import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'
import { posSync0 } from "@/data/PosSyncState.ts";
import SurrealClient from "@/shared/SurrealClient.ts";

type Item = {
  id?: string
  data: string
  pos: number
  dbName: string
  storeId: string
  hash: string
}

export async function exportMeta() {
  const surreal = await SurrealClient.getSurrealClient('cloud')
  for (const dbName of ['pos', 'pos2', 'pos3']) {
    await surreal?.query('DELETE FROM indexdb_metadata WHERE (storeId = $storeId AND dbName = $dbName)', {
      storeId: posSync0().id?.toString() || '43',
      dbName: dbName,
    })
    const db = new Dexie(dbName)
    await db.open()
    const idbDatabase = db.backendDB() // get native IDBDatabase object from Dexie wrapper

    // export to JSON, clear database, and import from JSON
    const lock = new MultiAwaitLock(true)
    IDBExportImport.exportToJsonString(idbDatabase, async function (err, jsonString: string) {
      // const arr = chunkString(jsonString, 1000000);
      const data = pako.deflate(jsonString)
      const arr = chunkUint8Array(data, 500000)
      let i = 0
      for (const element of arr) {
        const data0 = Buffer.from(element).toString('base64')
        const item: Item = {
          storeId: posSync0().id?.toString() || '43',
          dbName: dbName,
          pos: i,
          data: data0,
          hash: md5(data0),
        }
        await surreal?.insert('indexdb_metadata', item)
        i++
        console.log(`Exported ${dbName} ${i}/${arr.length}`)
        toast(`Exported ${dbName} ${i}/${arr.length}`, { autoClose: 200 })
      }

      console.log('Exported as JSON: ')
      lock.release().then()
      // if (err) {
      //   console.error(err);
      // } else {
      //   console.log('Exported as JSON: ' + jsonString);
      //   IDBExportImport.clearDatabase(idbDatabase, function(err) {
      //     if (!err) { // cleared data successfully
      //       IDBExportImport.importFromJsonString(idbDatabase, jsonString, function(err) {
      //         if (!err) {
      //           console.log('Imported data successfully');
      //         }
      //       });
      //     }
      //   });
      // }
    })
    await lock.acquireAsync()
  }
}

export async function importMeta(storeId: string = '43') {
  const surreal = await SurrealClient.getSurrealClient('cloud')
  const toastId = toast('begin... ', { autoClose: false })
  for (const dbName of ['pos', 'pos2']) {
    const db = new Dexie(dbName)
    await db.open()
    const idbDatabase = db.backendDB() // get native IDBDatabase object from Dexie wrapper

    // export to JSON, clear database, and import from JSON
    let offset = 0
    const data: Uint8Array[] = []
    while (true) {
      const result = await surreal?.query('SELECT * FROM indexdb_metadata WHERE (storeId = $storeId AND dbName = $dbName) ORDER BY pos LIMIT 5 START $offset', {
        storeId: storeId,
        dbName: dbName,
        offset: offset,
      })
      //@ts-ignore
      if (!result?.[0] || result?.[0].length === 0) break
      //@ts-ignore
      for (const item of result[0]) {
        if (item.hash !== md5(item.data)) {
          toast('Hash not match', { type: 'error' })
          console.error('Hash not match')
        }
        const data0 = Buffer.from(item.data, 'base64')
        data.push(data0)
        console.log('Importing current: ', item.pos)
      }
      // data.push(...result?.[0].result.map(i => {
      //   return i.data;
      // }));
      offset += 5
      toast.update(toastId, {
        render: `Importing ${dbName} ${offset}`,
      })
    }
    const jsonString = pako.inflate(Buffer.concat(data), { to: 'string' })
    const lock = new MultiAwaitLock(true)
    IDBExportImport.clearDatabase(idbDatabase, function (err: Error) {
      if (!err) {
        // cleared data successfully
        return IDBExportImport.importFromJsonString(idbDatabase, jsonString, function (err: Error) {
          if (!err) {
            toast.update(toastId, {
              render: 'Imported data successfully',
            })
          }
          lock.release().then()
        })
      }
      lock.release().then()
    })
    await lock.acquireAsync()
  }
  toast.update(toastId, {
    render: 'Imported data successfully',
    autoClose: 2000,
  })
  await delay(1000)
  location.reload()
}

function chunkUint8Array(arr: Uint8Array, length: number) {
  const chunks = []
  for (let i = 0; i < arr.length; i += length) {
    chunks.push(arr.slice(i, i + length))
  }
  return chunks
}

//@ts-ignore
window.testExport = exportMeta

//@ts-ignore
window.testImport = importMeta

