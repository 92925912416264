import { map } from 'rxjs'

import { computed } from '@/react/core/reactive'
import { signalFromObs } from '@/shared/signalFromObs'

import { deviceSrmSettings$, pendingSrmTransactions$ } from './subjects'

export const [deviceSrmSettings] = signalFromObs(deviceSrmSettings$)
export const [pendingSrmTransactions] = signalFromObs(pendingSrmTransactions$)
export const hasPendingSrmTransactions = computed(() => pendingSrmTransactions().length > 0)
export const [isInSrmTrainingMode] = signalFromObs(deviceSrmSettings$.pipe(map(srm => !!srm?.trainingMode)))
