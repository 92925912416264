import TextField from "@/react/core/TextField.tsx";
import { useCallback, useState, type FunctionComponent } from "react";
import { LL0 } from '@/react/core/I18nService.tsx'

type CashbookExportPDFPopupType = {
  onClose: () => void;
};

const CashbookExportPDFPopup: FunctionComponent<CashbookExportPDFPopupType> = ({
  onClose,
}) => {
  const [email, setEmail] = useState<string>("")
  const onSubmit = useCallback(async () => {
    onClose()
  }, [])
  return (
    <div className="rounded-10xs bg-white-solid-white-100-ffffff h-[155px] flex flex-col items-center justify-center pt-[19px] px-[45px] pb-[26px] box-border gap-[16px] max-w-full max-h-full overflow-auto text-center text-base text-black-solid-black-880-1d1d26 font-mulish">
      <div className="flex flex-col items-center justify-center gap-[2px] w-full">
        <div className="self-stretch flex flex-row justify-center items-center gap-[10px] ">
          <i
            className="relative flex sm:text-xs mq1440:text-base">
            {LL0().posSetup.accountTab.email()}
          </i>
          <TextField
            className="bg-white self-stretch rounded-[4px] w-full"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            label={LL0().posSetup.accountTab.email()}
            disabledLabel
          />
        </div>
      </div>
      <div className="w-[283px] justify-end mt-5 flex text-mini text-gray-solid-gray-600-979797">
        <button className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff w-28 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff"
          onClick={onSubmit}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </button>
      </div>
    </div>
  );
};

export default CashbookExportPDFPopup;
