import QRcode from 'qrcode'

import { invoiceGroupPrinters0 } from '@/data/GroupPrinterHub'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { loginUser } from '@/data/UserSignal.ts'
import { VPrinter } from '@/react/Printer/VPrinter'
import type { ScriptedRaster } from '@/shared/printer/types'
import type { SignedDocumentData } from '@/srm/lib/types'

import { DOCUMENT_NOTES } from './lib/constants'
import { reformatDate, reformatDateInLocalTz } from './lib/timezone'
import { reformatCurrency } from './lib/utils'

type Options = {
  /** Change the user that making transaction. If not specified, default to current login user */
  impersonate?: string
  /** QR Code content */
  qrcodeContent: string
}

/**
 * Generate raster bitmap for user report (RUT)
 */
export async function generateUserReport(d: SignedDocumentData, o: Options): Promise<ScriptedRaster> {
  if (d.type !== 'RUT') throw new Error('Not supported!')

  const p = new VPrinter({ ...invoiceGroupPrinters0()[0].printers[0] })

  //=======< Title >=======//
  await p.newLine(8)
  await p.alignCenter()
  await p.setFontSize(24)
  await p.bold(true)
  await p.println(`RAPPORT DE L’UTILISATEUR`)
  await p.bold(false)
  await p.setFontSize(22)

  //=======< Mandatary >=======//
  const { name: companyName, address, address2, city, province, zipCode } = posSetting0()?.companyInfo ?? {}
  const userName = o?.impersonate ?? loginUser()?.name

  if (!userName) throw new Error('Missing user name!')
  if (!companyName) throw new Error('Missing company name!')
  if (!address) throw new Error('Missing company address!')

  await p.newLine(8)
  await p.bold(true)
  await p.println(companyName)
  await p.bold(false)
  await p.println(`TPS : ${d.RT}`)
  await p.println(`TVQ : ${d.TQ}`)
  await p.println(userName)
  await p.newLine(4)
  const addr = [address, address2, city, province, zipCode].filter((a): a is string => !!a).join(', ')
  if (addr) await p.println(addr)

  //=======< Content >=======//
  await p.newLine(8)
  await p.setFontSize(24)
  await p.bold(true)
  await p.println('DERNIER DOCUMENT')
  await p.bold(false)
  await p.setFontSize(22)
  await p.println(`#${d.NO}`)
  await p.println(reformatCurrency(d.MT))
  await p.println('Papier')
  await p.println(`SEV : ${reformatDate(d.DF)}`)
  await p.println(`MEV-WEB : ${d.psiDatTrans ? reformatDateInLocalTz(d.psiDatTrans) : DOCUMENT_NOTES.communicationProblem}`)
  if (d.psiNoTrans) await p.println(d.psiNoTrans)

  //=======< Device Info >=======//
  await p.newLine(8)
  await p.setFontSize(24)
  await p.bold(true)
  await p.println('APPAREIL')
  await p.bold(false)
  await p.setFontSize(22)
  await p.println(`ID : ${d.IA}`)
  await p.println(`SEV : ${d.IS}`)
  await p.println(`VERSION : ${d.VR}`)

  //=======< Dates >=======//
  await p.newLine(8)
  await p.setFontSize(24)
  await p.bold(true)
  await p.println('DATES')
  await p.bold(false)
  await p.setFontSize(22)
  await p.println(`Conn. : ${reformatDateInLocalTz(d.DC)}`)
  await p.println(`Rapp. : ${reformatDateInLocalTz(d.DR)}`)

  //=======< Qrcode >=======//
  await p.newLine(8)
  const qrcode = await QRcode.toDataURL(o.qrcodeContent, { errorCorrectionLevel: 'H' })
  await p.printImage(qrcode.slice(22), 'base64', 1)
  await p.newLine(8)

  //=======< Result >=======//
  const result = await p.getRaster()
  if (!result || !result.data.length) throw new Error('Failed to print: page is empty')
  return result
}
