import { SerialPort, TSerialPort } from "@/shared/webview/rnwebview.ts";
import { signal } from "@/react/core/reactive.ts";
import { type Remote } from "comlink";
import { type OrderItem } from "@/pos/OrderType.ts";
import { getMessageForVFD, pre1, pre2 } from "@/shared/vfd/vfd-utils.ts";
import { wsLock } from "@/react/websocket/ws-state.ts";

const [vfd,setVfd] = signal<Remote<TSerialPort>>();

export async function initVFD() {
  await wsLock.acquireAsync();
  const deviceList = await SerialPort.list();
  const device = deviceList.find(d => d.vendorId === 1659);
  if (device) {
    await SerialPort.tryRequestPermission(device.deviceId);
    const sp = await new SerialPort(device.deviceId);
    await sp.init();
    setVfd(() => sp);
  }
}

export async function clearVFD() {
  await vfd().send(`${pre2}${pre2}`);
}

let clearCustomerDisplayTimeout: any;

export async function testVFD() {
  await vfd().send(`${pre1}Line 1${pre2}Line 2`);
}

export async function displayVFD({ discount, item, sum }: { discount: string, item: OrderItem, sum: string }) {
  if (!vfd()) return;
  if (sum && !item) {
      clearCustomerDisplayTimeout = setTimeout(clearVFD, 2 * 60 * 1000)
  } else {
    if (clearCustomerDisplayTimeout) {
      clearTimeout(clearCustomerDisplayTimeout);
      clearCustomerDisplayTimeout = null;
    }
  }

  const msg = getMessageForVFD({ discount, item, sum });
  await vfd().send(msg);
}