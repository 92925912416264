import { type CSSProperties, type FunctionComponent, useMemo } from 'react'
import { LL0 } from '@/react/core/I18nService.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { currentPayment0, currentPaymentName0, getActiveOrder } from '@/react/PaymentView/PaymentView.tsx'
import { clsx } from 'clsx'
import _ from "lodash";
import { cashPaymentName0, debitorPayment0 } from '@/data/PaymentHub'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

export type PaymentScreenFeeType = {
  className?: string;
  feeName?: string;
  feeValue?: string;
  tip?: string;
  price?: number;
  showTip?: boolean;
  showFeesInfo?: boolean;
  showShowAll?: boolean;
  showFees?: boolean;
  isSelected?: boolean;
  tipCash?: boolean

  /** Style props */
  paymentScreenFeeHeight?: CSSProperties['height'];

  onClick?: () => void;
};

const PaymentScreenFee: FunctionComponent<PaymentScreenFeeType> = (
  {
    feeName = 'SF:',
    feeValue = '3%',
    tip = 'Tip:',
    tipCash = false,
    price = 6.74,
    isSelected,
    showTip = true,
    showFeesInfo = true,
    showShowAll = true,
    showFees = true,
    paymentScreenFeeHeight,
    onClick
  }) => {
  const paymentScreenFeeStyle: CSSProperties = useMemo(() => {
    return {
      height: paymentScreenFeeHeight
    }
  }, [paymentScreenFeeHeight])

  return (
    <button
      className={clsx(
        `w-full min-h-[52px] rounded-sm bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] flex flex-col items-center justify-center relative text-center text-base text-dark-text font-mulish`,
        (!currentPaymentName0() || currentPaymentName0() === cashPaymentName0() || currentPayment0()?.type === 'cash'  || _.lowerCase(currentPayment0()?.name) === _.lowerCase(debitorPayment0()) || getActiveOrder()?.vSum <= 0/*|| isPaymentsIncludeDebitor()*/ ) && 'disabled:opacity-40',
        currentPayment0()?.enableTip === false ? '' : 'self-stretch'
      )}
      ref={makeRipple}
      disabled={(showTip && !tipCash && (currentPaymentName0() === cashPaymentName0() || currentPayment0()?.type === 'cash')) || _.lowerCase(currentPayment0()?.name) === _.lowerCase(debitorPayment0()) || getActiveOrder()?.vSum <= 0 /*|| isPaymentsIncludeDebitor()*/}
      onClick={onClick}
    >
      {
        isSelected && (
          <div
            className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-blue-solid-blue-350-2196f3 z-[0]" />
        )
      }
      {showFeesInfo && (
        <div className="self-stretch flex flex-col items-center justify-center gap-[2px] z-[1] height_414:gap-[2px]">
          {showFees && (
            <div className="self-stretch flex flex-row items-center justify-center gap-[2px]">
              <div className="relative font-semibold Tablet_768:text-sm Tablet_600:text-smi height_414:text-smi">
                {feeName}
              </div>
              <div
                className="relative font-semibold text-black-solid-black-900-1e1e23 Tablet_768:text-sm Tablet_600:text-smi height_414:text-smi">
                {feeValue}
              </div>
            </div>
          )}
          {showTip && (
            <div
              className="self-stretch relative font-semibold text-black-solid-black-900-1e1e23 Tablet_768:text-sm Tablet_600:text-smi height_414:text-smi">
              {tip}
            </div>
          )}
          <div
            className="self-stretch relative font-semibold text-orange-solid-orange-500-ff8a00 Tablet_768:text-sm Tablet_600:text-smi height_414:text-smi">
            {LL3().format.currency(price)}
          </div>
        </div>
      )}
      {showShowAll && (
        <div
          className="self-stretch relative font-semibold text-black-solid-black-900-1e1e23 z-[2] Tablet_768:text-sm Tablet_600:text-smi height_414:text-smi">
          {LL0().payment.showAll()}
        </div>
      )}
    </button>
  )
}

export default PaymentScreenFee;