import { computed, signal } from "@/react/core/reactive.ts";
import { PosSync } from "@/data/PosSync.ts";
import type { DocDeepSignal } from "@/data/data-utils.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import debug from "debug";

const log = debug('data:posSync')

export const posSyncs0Signal = signal<Array<DocDeepSignal<PosSync>>>([]);
export const posSyncs0 = posSyncs0Signal[0];
export const setPosSyncs0 = posSyncs0Signal[1];
// check if exists
export const posSyncLockReady = new MultiAwaitLock(true);
export const posSyncLock = new MultiAwaitLock(true);
export const posSync0 = computed<PosSync>(() => posSyncs0()[0]);

export const reSyncDb: string[] = JSON.parse(localStorage.getItem('RE_SYNC_DB') ?? '[]')

export function completeReSync(collectionName: string) {
  log(`Done re-pulling ${collectionName}`)
  const foundIndex = reSyncDb.indexOf(collectionName)
  if (foundIndex !== -1) {
    reSyncDb.splice(foundIndex, 1)
  }
  localStorage.setItem('RE_SYNC_DB', JSON.stringify(reSyncDb))
}