import dayjs from 'dayjs'

import { masterDeviceSetting } from '@/data/DeviceSettingHub.ts'
import type { Eod } from '@/data/Eod.ts'
import { getInflateReport, getZERSTELLUNG } from '@/tse/dsfinv/dsfinv-convert.ts'

const Z_Waehrungen = {
  Z_KASSE_ID: {
    type: 'string',
    maxLength: 50,
    hint: 'ID der (Abschluss-) Kasse',
    default: '1',
  },
  Z_ERSTELLUNG: {
    type: 'datetime',
    hint: 'Zeitpunkt des Kassenabschlusses',
    mapping: 'end',
    format: d => dayjs(d).toISOString(),
  },
  Z_NR: {
    type: 'number',
    places: 0,
    hint: 'Nr. des Kassenabschlusses',
    mapping: 'z',
  },
  ZAHLART_WAEH: {
    type: 'string',
    maxLength: 3,
    hint: 'Währung',
    regex: '^[A-Z]{3}$',
    default: 'EUR',
  },
  ZAHLART_BETRAG_WAEH: {
    type: 'number',
    places: 2,
    hint: 'Betrag',
    mapping: 'zahlungen',
  },
}

export function Z_WaehrungenFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      ZAHLART_WAEH: ZAHLART_WAEH(),
      ZAHLART_BETRAG_WAEH: ZAHLART_BETRAG_WAEH(),
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses"
    return eod.z
  }

  function ZAHLART_WAEH() {
    // hint: "Währung"
    return 'EUR'
  }

  function ZAHLART_BETRAG_WAEH() {
    // hint: "Betrag"
    const inflateReport = getInflateReport(eod)
    return inflateReport?.totalCashSalesExcludingCashback || 0
  }
}
