import { FunctionComponent } from "react";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { searchText, setSearchText } from "@/react/VoucherView/VoucherView.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";

export type SearchVoucherPopupType = {
  className?: string;
  onClose?: () => void;
};

const SearchVoucherPopup: FunctionComponent<SearchVoucherPopupType> = ({
  className = "",
  onClose,
}) => {

  return (
    <div
      className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-12 px-6 pb-5 box-border gap-[24px] min-w-[320px] h-full max-w-full max-h-full text-center text-sm text-white-solid-white-100-ffffff font-mulish ${className}`}
    >
      <Input
        className="self-stretch font-mulish font-medium text-base text-dimgray z-[0]"
        value={searchText() || ''}
        onChange={value => setSearchText(value)}
      />
      {/*<div className="self-stretch flex flex-row items-center justify-end z-[1]">*/}
      {/*  <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8">*/}
      {/*    <b className="relative">{LL0().ui.ok()}</b>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <TableManualKeyboard
        inputController={inputController0}
        onEnter={() => {
          onClose?.()
        }}
      />
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[3]"
        alt=""
        src="/close-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default SearchVoucherPopup;
