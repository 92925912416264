import { type FunctionComponent, useState, useCallback } from "react";
import { MenuItem, Select } from "@mui/material";
import PopupModifierEdit from "./PopupModifierEdit";
import PortalPopup from "../../Popup/PortalPopup";
import { STATES, VIEW__MODIFIER, VIEW__PRODUCT } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import {LL0} from "@/react/core/I18nService.tsx";

const ModifierSelectFieldset: FunctionComponent = () => {
  const [isEditAddOptionPopupOpen, setEditAddOptionPopupOpen] = useState(false);

  const openEditAddOptionPopup = useCallback(async () => {
    await VIEW__MODIFIER.onCreate()
    setEditAddOptionPopupOpen(true);
  }, []);

  const closeEditAddOptionPopup = useCallback(() => {
    VIEW__MODIFIER.onEndEditing()
    setEditAddOptionPopupOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold height_414:gap-[2px]">
        <div className="self-stretch flex flex-row items-center justify-between py-0 pr-1.5 pl-0">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().editOnlineMenu.options()}
          </div>
          <div
            className="relative [text-decoration:underline] font-medium text-green-solid-green-500-1f8b24 text-right cursor-pointer lg:text-base md:text-mini sm:text-sm mq768:text-smi height_414:text-smi"
            onClick={openEditAddOptionPopup}
          >
            + {LL0().editOnlineMenu.addNew()}
          </div>
        </div>
        <Select
          color="primary" size="small"
          className="w-full bg-white"
          multiple
          value={STATES.editingProduct()?.modifiers ?? []}
          onChange={({target:{value}}) => VIEW__PRODUCT.onChanged('modifiers', Array.isArray(value) ? value : [value])}
        >
          {STATES.modifiers()?.map(c => (
            <MenuItem key={c._id} value={c._id}>{c.name}</MenuItem>
          ))}
        </Select>
        {/*
        <div className="self-stretch rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] flex flex-row items-start justify-between py-[5px] pr-2 pl-3 text-blue-solid-blue-200-017efa border-[1px] border-solid border-blue-solid-blue-100-e6f2fe">
          <div className="flex-1 shrink-0 flex flex-row flex-wrap items-center justify-start gap-[8px]">
            <div className="rounded-xl bg-blue-solid-blue-100-e6f2fe h-9 flex flex-row items-center justify-start py-1.5 px-2 box-border gap-[4px]">
              <div className="relative font-semibold md:text-base sm:text-sm mq768:text-smi">
                Selected Option 1
              </div>
              <div className="relative font-semibold text-green-solid-green-300-3cbc81 md:text-base sm:text-sm mq768:text-smi">
                (8)
              </div>
              <img
                className="relative w-5 h-5 object-cover mq768:w-[18px] mq768:h-[18px]"
                alt=""
                src="/iconmenudelete-icon@2x.png"
              />
            </div>
            <div className="rounded-xl bg-blue-solid-blue-100-e6f2fe h-9 flex flex-row items-center justify-start py-1.5 px-2 box-border gap-[4px]">
              <div className="relative font-semibold md:text-base sm:text-sm mq768:text-smi">
                Selected Option 2
              </div>
              <div className="relative font-semibold text-green-solid-green-300-3cbc81 md:text-base sm:text-sm mq768:text-smi">
                (4)
              </div>
              <img
                className="relative w-5 h-5 object-cover mq768:w-[18px] mq768:h-[18px]"
                alt=""
                src="/iconmenudelete-icon@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch w-6 flex flex-row items-start justify-between pt-2 px-0 pb-0 box-border">
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconmenuarrow-dropdown@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_4px_14px_rgba(0,_0,_0,_0.1)] flex flex-col items-center justify-start py-4 pr-[30px] pl-4 relative gap-[32px] text-black-solid-black-100-292d32 lg:gap-[24px] md:gap-[20px] sm:gap-[16px] mq768:gap-[12px]">
          <div className="self-stretch flex flex-row items-center justify-between z-[0]">
            <div className="flex-1 shrink-0 flex flex-row items-end justify-start gap-[8px]">
              <div className="relative w-6 h-6">
                <FormControlLabel
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                  label=""
                  control={<Checkbox color="primary" defaultChecked />}
                />
              </div>
              <div className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                Selected option 1
              </div>
            </div>
            <div className="relative text-base [text-decoration:underline] font-medium text-green-solid-green-500-1f8b24 text-right lg:text-base md:text-mini sm:text-sm mq768:text-smi">
              Edit
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between z-[1]">
            <div className="flex-1 shrink-0 flex flex-row items-end justify-start gap-[8px]">
              <div className="relative w-6 h-6">
                <FormControlLabel
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                  label=""
                  control={<Checkbox color="primary" defaultChecked />}
                />
              </div>
              <div className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                Selected option 2
              </div>
            </div>
            <div className="relative text-base [text-decoration:underline] font-medium text-green-solid-green-500-1f8b24 text-right lg:text-base md:text-mini sm:text-sm mq768:text-smi">
              Edit
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between z-[2]">
            <div className="flex-1 shrink-0 flex flex-row items-end justify-start gap-[8px]">
              <div className="relative w-6 h-6">
                <FormControlLabel
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                  label=""
                  control={<Checkbox color="primary" defaultChecked />}
                />
              </div>
              <div className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                Selected option 3
              </div>
            </div>
            <div className="relative text-base [text-decoration:underline] font-medium text-green-solid-green-500-1f8b24 text-right lg:text-base md:text-mini sm:text-sm mq768:text-smi">
              Edit
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between z-[3]">
            <div className="flex-1 shrink-0 flex flex-row items-end justify-start gap-[8px]">
              <div className="relative w-6 h-6">
                <FormControlLabel
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                  label=""
                  control={<Checkbox color="primary" defaultChecked />}
                />
              </div>
              <div className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                Selected option 4
              </div>
            </div>
            <div className="relative text-base [text-decoration:underline] font-medium text-green-solid-green-500-1f8b24 text-right lg:text-base md:text-mini sm:text-sm mq768:text-smi">
              Edit
            </div>
          </div>
          <div className="absolute my-0 mx-[!important] top-[8px] right-[4px] rounded-lg bg-gray-200 w-2 h-[121.5px] z-[4]" />
        </div>
        */}
      </div>
      {isEditAddOptionPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditAddOptionPopup}
        >
          <PopupModifierEdit onClose={closeEditAddOptionPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default ModifierSelectFieldset;
