import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'

import type { BaseDocumentType } from '@/data/types'
import { createCollection } from '@/data/utils/migration.ts'

export const TABLE_STAFF_STATUS_COLLECTION_NAME = 'table_staff_status'

export enum StaffStatus {
  NORMAL = 'normal',
  MAKING_ORDER = 'making_order',
  PROCESSING_PAYMENT = 'processing_payment',
}

export interface TableStaffStatus extends BaseDocumentType {
  _id: string
  /** The table number (`order.table`) */
  table: string
  /** The status of the table */
  status: StaffStatus
  /** Order ID */
  orderId?: string
}

export const VERSION = 6
const schema: RxJsonSchema<TableStaffStatus> = {
  title: 'table_staff_status schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    table: { type: 'string', maxLength: 64 },
    status: { type: 'enum', enum: Object.values(StaffStatus) },
    orderId: { type: 'string', maxLength: 24 },
    updatedAt: { type: 'number' },
    updatedOn: { type: 'string' },
  },
  required: ['_id', 'table', 'status', 'updatedAt', 'updatedOn'],
}

export const TableStaffStatus = {} as RxCollection<TableStaffStatus>

export const createTableStaffStatusCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TableStaffStatus,
    collectionName: TABLE_STAFF_STATUS_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  })
}

Object.assign(window, { TableStaffStatus }) // Make available global
