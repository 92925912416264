import _ from 'lodash'
import type { JsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'

import { createCollection } from '@/data/utils/migration.ts'
import type { ItemCommit } from '@/pos/OrderType.ts'

export const ORDER_COMMITS_COLLECTION_NAME = 'order_commits'
export const VERSION = 8

/** @deprecated use `src/data/UserActionHistory.ts` instead */
export interface OrderCommits {
  orderId: string
  date: number
  commits: ItemCommit[]
}

const orderCommitsSchema: RxJsonSchema<OrderCommits> = {
  title: 'orderCommits schema',
  version: VERSION,
  primaryKey: 'orderId',
  type: 'object',
  properties: {
    orderId: { type: 'string', maxLength: 100 },
    date: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    commits: {
      type: 'array',
      items: <JsonSchema<ItemCommit>>{
        type: 'object',
        properties: {
          _id: { type: 'string' },
          action: { type: 'integer' },
          date: { type: 'number' },
          processed: { type: 'boolean' },
          seat: { type: 'number' },
        },
      },
    },
  },
  required: ['orderId', 'commits', 'date'],
} as const

/** @deprecated use `src/data/UserActionHistory.ts` instead */
export const OrderCommits: RxCollection<OrderCommits> = {} as RxCollection<OrderCommits>

export const createOrderCommitsCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: OrderCommits,
    collectionName: ORDER_COMMITS_COLLECTION_NAME,
    version: VERSION,
    schema: orderCommitsSchema,
    extraStrategy: { 8: () => null }, // Remove all data
  })
}

// Expose to global
Object.assign(window, { OrderCommits })
