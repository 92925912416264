import { computed, effect } from "@/react/core/reactive.ts";
import _ from "lodash";
import { wsLock } from "@/react/websocket/ws-state.ts";
import { rnHost } from '@/shared/webview/rnwebview.ts'
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const CountryLanguageMaps: {
  [key: string]: string | undefined
} = {
  'us': 'en',
  'ca': 'en',
  'de': 'de',
  'vn': 'vn',
}
export enum ChangeLanguage {
  EN = "en",
  FR = "fr",
  CN = "cn",
  VN = "vn",
  DE = "de"
}
const frontendLanguageRaw = computed<string | undefined>(() => {
  if (deviceSetting0()?.frontendLanguage) return deviceSetting0()!.frontendLanguage!;
  if (posSetting0()?.companyInfo?.backendLanguage) return posSetting0()!.companyInfo!.backendLanguage!;
  return CountryLanguageMaps[posSetting0()?.companyInfo?.country || ''];
})

export const frontendLanguage = computed<string>(() => {
  if (frontendLanguageRaw()) {
    if (localStorage.getItem('frontendLanguage') !== frontendLanguageRaw()) {
      localStorage.setItem('frontendLanguage', frontendLanguageRaw()!);
    }
    return frontendLanguageRaw()!;
  }
  if (localStorage.getItem('frontendLanguage')) return localStorage.getItem('frontendLanguage')!;
  return 'en';
})

const backendLanguageRaw = computed<string | undefined>(() => {
  if (posSetting0()?.companyInfo?.backendLanguage) return posSetting0()!.companyInfo!.backendLanguage!;
  return CountryLanguageMaps[posSetting0()?.companyInfo?.country || ''];
});

export function setFrontendLanguage(lang: string) {
  if (frontendLanguage() === lang) return;
  _.assign(deviceSetting0(), { frontendLanguage: lang });
}

export const backendLanguage = computed<string>(() => {
  if (backendLanguageRaw()) {
    if (localStorage.getItem('backendLanguage') !== backendLanguageRaw()) {
      localStorage.setItem('backendLanguage', backendLanguageRaw()!);
    }
    return backendLanguageRaw()!;
  }
  if (localStorage.getItem('backendLanguage')) {
    return localStorage.getItem('backendLanguage')!;
  }
  return 'en';
})

//@ts-ignore
window.backendLanguage = backendLanguage

export function setBackendLanguage(lang: string) {
  if (backendLanguage() === lang) return;
  _.assign(posSetting0()?.companyInfo, { backendLanguage: lang });
}

const currencyLanguageRaw = computed<string | undefined>(() => {
  return CountryLanguageMaps[posSetting0()?.companyInfo?.country || ''];
});

export const currencyLanguage = computed<string>(() => {
  if (currencyLanguageRaw()) {
    if (localStorage.getItem('currencyLanguage') !== currencyLanguageRaw()) {
      localStorage.setItem('currencyLanguage', currencyLanguageRaw()!);
    }
    return currencyLanguageRaw()!;
  }
  if (localStorage.getItem('currencyLanguage')) {
    return localStorage.getItem('currencyLanguage')!;
  }
  return 'en';
});

effect(async () => {
  backendLanguage();
  //wait for connect to react native
  await wsLock.acquireAsync();
  //send backendLanguage with rnHost.setBackendLanguage
  await rnHost.sendBackendLanguageToSD(backendLanguage());
})