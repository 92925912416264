import type { RxCollection, RxDatabase, RxDocument } from 'rxdb'
import type { Product } from '@/data/Product.ts'
import { createCollection } from "@/data/utils/migration.ts";

export const INVENTORY_COLLECTION_NAME = 'inventories'

export const VERSION = 2

const inventorySchema = {
  title: 'inventory schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    productId: {type: 'string'},
    unit: {type: 'string'},
    id: {type: 'string'},
    stock: {type: 'number'},
    threshold: {type: 'number'},
  },
  indexes: []
} as const;

export interface Inventory {
  _id: string;
  productId?: string;
  unit: "g" | "kg" | "piece" | "ml" | "l";
  id: number;
  stock: number;
  threshold: number;

  //for mapping data, not exist in db
  product?: Product;
  lastUpdated?: number;
}

export type InventoryDocument = RxDocument<Inventory>;
export type InventoryCollection = RxCollection<Inventory>;

export const InventoryCollection: InventoryCollection = {} as InventoryCollection;
export const Inventory = InventoryCollection;

export const createInventoryCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Inventory,
    collectionName: INVENTORY_COLLECTION_NAME,
    version: VERSION,
    schema: inventorySchema,
  });
}
