import { map, Subscription, tap } from 'rxjs';
import { dataLock } from '@/data/DataUtils.ts';
import { computed, effectOn, signal } from '@/react/core/reactive';
import { Image } from "@/data/Image.ts";
import { useEffect } from "react";
import { convertDocuments, type DocDeepSignal } from "@/data/data-utils.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { setImageChosen } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";

export const [images0, setImages0] = signal<DocDeepSignal<Image, {}>[]>([])
export const [imageV, setImageV] = signal<number>(0)
export const logo0 = computed(() => images0().find((img) => img.type === 'SecondDisplayLogo'))

export const makeImagesAvailable = () => {
  useEffect(() => {
    let sub = new Subscription()
    dataLock.acquireAsync().then(() => {
      sub.add(
        Image.find().$.pipe(
          map(a => convertDocuments(a, true, [])),
          tap(a => {
            setImages0(a)
            setImageV((prev) => prev + 1)
          }),
        ).subscribe()
      )
    })
    return () => sub.unsubscribe()
  }, [])
}

effectOn([imageV], async () => {
  if (!imageV() || !images0()) return
  for (const img of images0()) {
    await sendImageToSD(img.type!, img)
    if (img.type === 'SecondDisplayBackground') setImageChosen(img.data!)
  }
})

async function sendImageToSD(key: string, _data: any) {
  const check = localStorage.getItem(key)
  const updatedAt = JSON.stringify(_data?.updatedAt || '')
  if (!!check && check === updatedAt) return
  localStorage.setItem(key, updatedAt)
  try {
    if (key === 'SecondDisplayBackground') {
      await rnHost.sendBackgroundToSD(_data.data)
    } else {
      await rnHost.sendLogoToSD(_data.data)
    }
  } catch (e) {
  }
}