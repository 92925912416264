import {type FunctionComponent, useState} from 'react';
import {makeRipple} from '@/react/core/ripple-utils.ts';
import {getLL} from '@/react/core/I18nService.tsx';
import _ from 'lodash';
import {useI18nContext} from '@/i18n/i18n-react.tsx';
import {setFrontendLanguage} from "@/data/language.ts";

type ButtonWDropdownType = {
  showLanguageChoicesDropBox?: boolean;
  languageChoicesDropBoxVisible?: boolean;
};

const localeMapper = {
  de: "german",
  en: "english",
  vn: "vietnam",
  fr: "french",
  cn: "china",
}

const ButtonWDropdown: FunctionComponent<ButtonWDropdownType> = ({}) => {
  const LL = getLL();
  const {locale, setLocale} = useI18nContext();

  const [languageChoicesDropBoxVisible, setLanguageChoicesDropBoxVisible] = useState<boolean>(false);

  return (
    <>
      {languageChoicesDropBoxVisible &&
          <div className="fixed w-screen h-screen opacity-0 top-0 left-0 bg-white"
               onClick={() => setLanguageChoicesDropBoxVisible(prev => !prev)}/>
      }
      <div
        className="!w-fit w-[117px] h-[38px] flex flex-col items-center justify-start gap-[6px] text-center text-lg text-white-solid-white-100-ffffff font-mulish [&_.language-choices-drop-box1]:active:flex height_340:h-[38px]">
        <div
          className="rounded-81xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[38px] overflow-hidden shrink-0 flex flex-row items-center justify-end py-[11px] pr-3 pl-4 box-border gap-[8px]"
          onClick={() => setLanguageChoicesDropBoxVisible(prev => !prev)}
          ref={makeRipple}
        >
          <div
            className="relative font-semibold md:text-base">{_.get(LL().login, localeMapper[locale || "en"])?.()}</div>
          <img
            className="w-5 relative h-5 overflow-hidden shrink-0"
            alt=""
            src="/iconpasscodearrow-down-icon-white--polygon.svg"
          />
        </div>
        {languageChoicesDropBoxVisible && (
          <div className="z-40 flex flex-row items-start justify-start text-left text-mini">
            <div
              className="language-choices-drop-box1 rounded bg-[#f3f9ff] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] flex flex-col items-start justify-center py-[9px] px-0 text-left text-mini text-black-solid-black-400-424242 border-[1px] border-solid border-black-solid-black-400-424242 hover:flex">
              {(Object.keys(localeMapper) as Array<keyof typeof localeMapper>).map((localeString, index) => (
                <div className="self-stretch flex flex-row items-start justify-start py-1.5 px-5" key={index}
                     onClick={() => {
                       setFrontendLanguage(localeString);
                       setLocale?.(localeString);
                       setLanguageChoicesDropBoxVisible(false);
                     }}
                >
                  <div className="relative [text-decoration:underline] font-semibold">
                    {_.get(LL().login, localeMapper[localeString])?.()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ButtonWDropdown;
