import type {FunctionComponent} from "react";

import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import {LL0} from "@/react/core/I18nService.tsx";
import Input, {type InputController, inputController0} from "@/react/core/Input.tsx";
import {useSignal} from "@/react/core/reactive.ts";
import { filterInventoryValues, setFilterInventoryValues } from '@/data/InventoryHub.ts'

type InventoryNameFilterPopuType = {
  onClose?: () => void;
};

const InventoryNameFilterPopu: FunctionComponent<
  InventoryNameFilterPopuType
> = ({ onClose }) => {
  const [inputControllers, setInputControllers] = useSignal<Array<InputController>>([]);

  const [name, setName] = useSignal<string>(filterInventoryValues().name)

  return (
    <div className="relative rounded-md bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[520] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[24px] hover:items-start hover:justify-start hover:rounded-md hover:pt-8 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[520]">
      <div className="self-stretch flex flex-col items-start justify-center gap-[8px] z-[0]">
        <div className="relative font-medium md:text-xs">{LL0().inventory.name()}</div>
        <Input
          className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
          refInputController={i => setInputControllers(() => [i])}
          value={name()}
          onChange={value => setName(value)}
        />
      </div>
      <TableManualKeyboard
        inputController={inputController0}
        value={''}
        onEnter={() => {
          setFilterInventoryValues(prev => ({...prev, name: name()}))
          onClose?.()
        }}
      />
      <div
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[2]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
        />
      </div>
    </div>
  );
};

export default InventoryNameFilterPopu;
