import CloverTerminal from './CloverTerminal'
import SimulatorTerminal from './SimulatorTerminal.ts';
import ZvtTerminal from "./ZvtTerminal.ts";
import CCVA920Terminal from "./CCVA920Terminal.ts";
import { terminals0 } from '@/data/TerminalHub.ts'
import { TerminalTypes } from "@/data/Terminal.ts";

const profiles = [
  {type: TerminalTypes.Clover, ctor: CloverTerminal},
  {type: TerminalTypes.Simulator, ctor: SimulatorTerminal},
  {type: TerminalTypes.Zvt, ctor: ZvtTerminal},
  {type: TerminalTypes.CCVA920, ctor: CCVA920Terminal}
]

function resolveTerminalProfile(profileType: TerminalTypes) {
  const profile = profiles.find(profile => profile.type === profileType)
  return profile?.ctor
}

async function createTerminalInstanceById(terminalProfileId: string) {
  console.log('createTerminalInstanceById', terminalProfileId, terminals0())
  const terminalProfileInfo = terminals0().find(terminal => terminal._id === terminalProfileId)
  if (!terminalProfileInfo)
      throw "Not found terminalProfileInfo"
  const ctor = resolveTerminalProfile(terminalProfileInfo.type)
  if (!ctor)
      throw "Not found ctor"
  // @ts-ignore
  return new ctor(terminalProfileInfo.metadata)
}

export async function getProfiles() {
  console.log(`terminal:get-profiles`)
  return terminals0()
}

export async function getProfileTypes() {
  console.log('terminal:get-profile-types')
  return profiles.map(profile => profile.type);
}

export async function isTerminalOnline(terminalId: string) {
  const terminal = await createTerminalInstanceById(terminalId);
  return terminal.isOnline();
}

export async function test(terminalId: string, onProgress?: Function) {
  console.log('terminal:test')
  const terminal = await createTerminalInstanceById(terminalId);
  return terminal.test(onProgress)
}

export async function pay(terminalId: string, payload: any, onProgress?: Function) {
  console.log('terminal:pay')
  const terminal = await createTerminalInstanceById(terminalId);
  return terminal.payTx(payload, onProgress);
}

export async function refundTx(terminalId: string, payload: any, onProgress?: Function) {
  // await delay(8000)
  // return {error: null, response: {} }
  console.log('terminal:refund-tx')
  const terminal = await createTerminalInstanceById(terminalId);
  return terminal.refundTx(payload, onProgress)
}

export async function voidTx(terminalId: string, payload: any, onProgress?: Function) {
  // await delay(8000)
  // return {error: null, response: {} }
  console.log('terminal:refund-tx')
  const terminal = await createTerminalInstanceById(terminalId);
  return terminal.voidTx(payload, onProgress)
}

export async function cancel(terminalId: string, payload: any, onProgress?: Function) {
  console.log('terminal:cancel')
  const terminal = await createTerminalInstanceById(terminalId);
  return terminal.cancel(payload, onProgress)
}

export async function endOfDay(terminalId: string) {
  console.log('terminal:endOfDay')
  const terminal = await createTerminalInstanceById(terminalId);
  return terminal.eod()
}