import { signal } from "@/react/core/reactive.ts";
import { TseType } from "@/tse/tse-utils.ts";

export const [activePS, setActivePS] = signal<boolean>(false);

//handle case voucher , discount
//handle case discount split

//test case eod
type EodMode = 'keep' | 'reduce';
export const [eodMode0, setEodMode0] = signal<EodMode>("keep")
export const TseServerMap : {[k: string]: string} = {
  [TseType.TseServerTest]: 'wss://surreal-pika-sg.gigasource.io',
  [TseType.TseServerMock]: 'wss://surreal-pika-sg.gigasource.io',
  [TseType.TseServer]: 'wss://tse-germany1.gigasource.io',
  [TseType.TseServerTestGermany]: 'wss://tse-germany1.gigasource.io',
}
export const TSE_NOT_REACHABLE = 'TSE ist nicht erreichbar';