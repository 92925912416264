import {onlineProviders0} from './OnlineProviderHub.ts'
import {effectOn} from "@/react/core/reactive.ts";
import {getApiUrl} from "@/shared/utils.ts";
import {MarketPlaceProvider} from "@/pos/OrderType.ts";
import axios from "axios";
import { isMaster } from "@/lib/fetch-master.ts";
import { posSync0 } from "@/data/PosSyncState.ts";

let fetchInterval: any;
effectOn([onlineProviders0, isMaster, posSync0], () => {
  if (!isMaster()) {
    console.log('Restablo: Not master -> quit')
    return
  }

  if (!posSync0()) {
    console.log('Restablo: Not PosSync -> quit')
    return
  }
  const posId = posSync0()?.id
  if (!posId) {
    console.log('Restablo: Not have PosId -> quit')
    return
  }

  console.log('onlineProviders0()', onlineProviders0())
  const restabloProvider = onlineProviders0().find(provider => provider.name === MarketPlaceProvider.RESTABLO)
  if (!restabloProvider) {
    console.log('no restablo')
    return;
  }

  if (!restabloProvider.isEnabled) {
    console.log('restablo is not enabled')
    return;
  }

  if (!fetchInterval) {
    fetchInterval = setInterval(triggerGetOrder, 50000)
    triggerGetOrder()
  }
})

function triggerGetOrder() {
  console.log('triggerGetOrder')

  if (!isMaster()) {
    clearInterval(fetchInterval);
    fetchInterval = undefined;
    return;
  }

  // TODO: early alert
  const posId = posSync0()?.id
  console.log('trigger restablo get order webhook for', posId)
  axios.post(`${getApiUrl()}/api/restablo/web-hook/get-orders?timestamp=${Date.now()}&posId=${posId}`, {})
    .then(rs => console.log('rs', rs.data))
    .catch(e => console.error('Failed to trigger restablo', e))
}