import EscPrinter from "@/shared/printer/node-thermal-printer";
import Printer, { type Raster } from "@/shared/printer/pure-image-printer-parallel";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import QRcode from "qrcode";
import { printImageToConsole } from "@/shared/printImageToConsole.ts";
import debug from "debug"
import type { PrintingScripts } from "@/data/PrintScripts"
import type { PrinterAddress, ScriptedRaster } from "@/shared/printer/types"

const log = debug('printer:VPrinter')

export enum PrinterMode {
  ESC = 'esc',
  IMAGE = 'image'
}

export type ColItemMetaData = {
  align?: 'LEFT' | 'RIGHT' | 'CENTER'
  padding?: number
  priority?: 'HIGH' | 'LOW'
};

export type TableColumnData = {
  text: string,
  align?: 'LEFT' | 'RIGHT' | 'CENTER',
  width?: number
  bold?: boolean
};

type Align = 'LEFT' | 'RIGHT' | 'CENTER'
//proxy
//VPrinter.print:
//

const IGNORE_LIST = [
  'getRaster',
  'getRasterFromSavedScript'
]
const PRINTER_METHODS = [
  'println', 'printImage', 'printBarcode',
  'alignCenter', 'alignLeft', 'alignRight', 'advancedTableCustom', 'setTextDoubleHeight', 'setTextDoubleWidth',
  'setTextQuadArea', 'bold', 'italic', 'underline', 'underlineThick',
  'marginTop', 'newLine', 'setFontSize', 'setTextNormal',
  'drawLine', 'invert', 'leftRight'
]

interface VPrinterConfig {
  scripts?: PrintingScripts
}

export class VPrinter implements Omit<Printer, 'print' | 'getDefaultFontSize' | 'getDefaultNewLineFontSize' | '_reset' | 'cleanup'> {
  mode: PrinterMode
  escPrinter?: EscPrinter
  imagePrinter: Printer
  raster: Raster | undefined
  scripts: PrintingScripts = []

  private shouldRecord = true;

  constructor(public address?: PrinterAddress, public initConfig: VPrinterConfig = {}) {
    this.mode = address?.escPOS ? PrinterMode.ESC : PrinterMode.IMAGE
    
    this.imagePrinter = createPrinter();
    this.escPrinter = new EscPrinter(address || {}, initConfig)
    if (initConfig.scripts) this.scripts = initConfig.scripts

    return new Proxy(this, {
      get(target, p, receiver) {
        const original = Reflect.get(target, p, receiver)
        if (typeof p === 'symbol') return original // Skip symbols
        if (typeof original !== 'function') return original // Skip non-functions props
        if (IGNORE_LIST.includes(p)) return original // Skip ignored methods
        if (PRINTER_METHODS.includes(p)) {
          return function (...args: unknown[]) {
            registerScript(p, [...args]);
            const printers = target.mode === PrinterMode.IMAGE ? [target.imagePrinter] : [target.imagePrinter, target.escPrinter]
            const results: unknown[] = []
            for (const printer of printers) {
              if (!printer) continue
              const method = printer[p as keyof typeof printer] as (...args: unknown[]) => unknown
              if(typeof method !== 'function') {
                log(`⚠️ Method ${p} not found in printer`)
                continue
              }
              results.push(method.apply(printer, args))
            }
            return results
          }
        }

        return function (...args: unknown[]) {
          registerScript(p, [...args]);
          return original.apply(target, args)
        }

        function registerScript(fn: string, args: unknown[]) {
          if (target.shouldRecord) target.scripts.push({ fn, args })
        }
      }
    })
  }

  get printer() {
    return this.mode === PrinterMode.ESC ? this.escPrinter : this.imagePrinter;
  }

  async println(text: string) {
    return await this.printer?.println(text);
  }

  async printRaster(raster: Raster) {
    if (this.mode === PrinterMode.ESC) {
      this.escPrinter?.append(raster.esc);
    } else {
      await this.escPrinter?.printRaster(raster);
    }
  }

  async openCashDrawer() {
    this.escPrinter?.openCashDrawer();
  }

  async getRaster(hybrid: boolean = true): Promise<ScriptedRaster> {
    if (!this.raster) {
      this.raster = await this.imagePrinter?.print();
      if (!this.raster) throw new Error('Failed to get print image using image printer');
      if (this.escPrinter)
        this.raster.esc = await this.escPrinter.buffer as Buffer
    }
    return { ...this.raster, scripts: this.scripts }
  }

  async print(cut = true, escClearBuffer = false, printRaster = true): Promise<void> {
    if (this.mode === PrinterMode.ESC) {
      await this.escPrinter?.print(cut);
    } else {
      if (escClearBuffer) {
        this.escPrinter?.clear();
      }
      if (printRaster) {
        const raster = await this.getRaster();
        await this.escPrinter?.printRaster(raster);
      }
      return await this.escPrinter?.print(cut);
    }
  }

  async printImage(imageInput: string, inputType: 'path' | 'base64', ratio: number) {
    await this.imagePrinter.printImage(imageInput, inputType, ratio)
    await this.escPrinter?.printImage(imageInput, inputType, ratio);
  }

  async printQrCode(text: string, ratio: number) {
    const lastAlign = this.escPrinter?.align;
    this.escPrinter?.alignCenter();
    this.escPrinter?.printQrCode(text, ratio);
    if (lastAlign === 'left') {
      this.escPrinter?.alignLeft();
    } else if (lastAlign === 'right') {
      this.escPrinter?.alignRight();
    }
    const qrcode = await QRcode.toDataURL(text, { errorCorrectionLevel: 'H' })
    await this.imagePrinter.printImage(qrcode.slice(22), 'base64', 0.8)
  }

  async printBarcode(text: string, opts: { height?: number, width?: number, displayValue?: boolean } = {}) {
    return await this.printer?.printBarcode(text, opts)
  }

  async alignCenter() {
    return await this.printer?.alignCenter()
  }

  async alignLeft() {
    return await this.printer?.alignLeft()
  }

  async alignRight() {
    return await this.printer?.alignRight()
  }

  async advancedTableCustom(tableData: {
    metaData: {
      colMetaData: Array<ColItemMetaData>
      rowMetaData: Array<{
        borderBottom?: boolean
      }>
    }
    data: Array<Array<{
      text: string
    }>>
  }, autoAdjustWidth: boolean) {
    return await this.printer?.advancedTableCustom(tableData, autoAdjustWidth)
  }

  async tableCustom(columns: {
      text: string,
      align: Align,
      width: number,
      bold?: boolean,
      fontSize?: number
    }[],
    autoAdjustWidth?: boolean,
    data?: { text?: string, width?: number, bold?: boolean, align?: Align }[],
    options?: {
      textDoubleWith?: boolean
    }) {
    this.escPrinter?.tableCustom(columns, options);
    await this.imagePrinter?.tableCustom(columns, autoAdjustWidth);
  }

  async setTextDoubleHeight() {
    return await this.printer?.setTextDoubleHeight()
  }

  async setTextDoubleWidth() {
    return await this.printer?.setTextDoubleWidth()
  }

  async setTextQuadArea() {
    return await this.printer?.setTextQuadArea()
  }

  async bold(isBold: boolean = false) {
    return await this.printer?.bold(isBold)
  }

  async italic(isItalic: boolean = false) {
    if (this.mode === PrinterMode.IMAGE)
      return this.imagePrinter?.italic(isItalic);
  }

  async underline(enabled: boolean) {
    return this.escPrinter?.underline(enabled);
  }

  async underlineThick(enabled: boolean) {
    return this.escPrinter?.underline(enabled);
  }

  async upsideDown(enabled: boolean) {
    return this.escPrinter?.underlineThick(enabled);
  }

  async marginTop(x: number) { //x(cm)
    return await this.printer?.marginTop(x)
  }

  async newLine(customNewLineFontSize?: number) {
    return await this.printer?.newLine(customNewLineFontSize)
  }

  async setFontSize(fontSize: number) {
    return await this.printer?.setFontSize(fontSize)
  }

  async setTextNormal() {
    return await this.printer?.setTextNormal()
  }

  async drawLine() {
    return await this.printer?.drawLine()
  }

  async invert(enabled: boolean) {
    return await this.printer?.invert(enabled)
  }

  async leftRight(leftText: string, rightText: string) {
    return await this.printer?.leftRight(leftText, rightText)
  }

  async getRasterFromSavedScript(): Promise<ScriptedRaster> {
    this.shouldRecord = false
    try {
      const results = []
      for (const { fn, args } of this.scripts) {
        const func = this[fn as unknown as keyof typeof this]
        if (typeof func !== 'function') {
          log(`⚠️ Function ${fn} not found or not a function`)
          continue
        }
        const res = await func.apply(this, args)
        if (res) results.push(res)
      }
      return this.getRaster()
    } finally {
      this.shouldRecord = true
    }
  }
}

// function createColMetaData() {
//   const res: ColItemMetaData[] = [{ align: 'LEFT' }]
//   if (true) res.push({ align: 'RIGHT', priority: 'HIGH', padding:  0.05 })
//   res.push({ align: 'RIGHT', priority: 'HIGH' })
//   return res
// }
// function createOrderDetailTableHeader(name: string, quantity: string, unitPrice: string, totalPrice: string): TableColumnData[] {
//   const res: TableColumnData[] = [{ text: name, bold: true }]
//   if (true) res.push({ text: quantity, bold: true })
//   // if (!hideUnitPrice) res.push({ text: unitPrice, bold: true })
//   res.push({ text: totalPrice, bold: true })
//   return res
// }

async function testVPrinter() {

  // const metaData = {
  //   colMetaData: createColMetaData(),
  //   rowMetaData: [
  //     { borderBottom: true }
  //   ]
  // }
  // const data: TableColumnData[][] = [createOrderDetailTableHeader('product', 'quantity', 'price', 'sum')]

  const groupPrinters = groupPrinters0();
  const printer = groupPrinters0()[0].printers[0];
  const vPrinter = new VPrinter(printer);
  // const vPrinter = createPrinter();
  // const mode = vPrinter.mode
  await vPrinter.alignCenter()
  // await vPrinter.alignLeft()
  // await vPrinter.alignRight()
  await vPrinter.setTextQuadArea()
  // await vPrinter.bold(true)
  // await vPrinter.newLine()
  await vPrinter.setFontSize(35)
  await vPrinter.println(`test QR `);
  // await vPrinter.invert(true)
  await vPrinter.setTextNormal()
  await vPrinter.italic(true)
  await vPrinter.println('print some texts');
  // await vPrinter.advancedTableCustom({ metaData, data }, true)
  await vPrinter.tableCustom([{ text: '12345678', align: 'LEFT', width: 0.5, bold: true, fontSize: 40 }], true, [{
    text: '12345',
    width: 0.4,
    bold: true
  }], { textDoubleWith: true })

  // await vPrinter.tableCustom({text: 'line1', width: 0.05, bold: false, align: "LEFT"})
  // await vPrinter.tableCustom({text: 'line2', width: 0.05, bold: false, align: "LEFT"})
  // await vPrinter.drawLine();
  // await vPrinter.printImage(takeAwayIcon, 'base64', 1/6.3)
  // await vPrinter.printQrCode('test qr', 0.5);
  // await vPrinter.printBarcode('821937122')
  // await vPrinter.print();

  // await vPrinter.print();
  const raster = await vPrinter.getRaster();
  await printImageToConsole('test', raster);
  console.log('Print success');

  const raster2 = await vPrinter.getRasterFromSavedScript();
  await printImageToConsole('printed from script', raster2);
}

Object.assign(window, { testVPrinter })