import { signal, computed, Signal, useComputed, useSignal } from "@preact/signals-react";
// import { signal, computed } from 'usignal/sync'
// import { signal, computed } from '@webreflection/signal'
// @ts-ignore
import sube, { observable } from "./sube.ts";

const isSignal = (v: Signal) => v && v.peek;
const isStruct = (v: any) => v && v[_struct];
const _struct = Symbol("signal-struct");

signalStruct.isStruct = isStruct;

let _untrack = false;
let untrackList: string[] = [];
export const untrack = (fn: Function, whitelist?: string[]) => {
	untrackList = whitelist || [];
	_untrack = true;
	fn();
	_untrack = false;
};

const assignProp = (state: any, key: string, fn: Function) => {
	Object.defineProperty(state, key, {
		get() {
			return fn;
		},
		enumerable: false,
		configurable: true
	})
}

export default function signalStruct<T,>(values: T, proto?: Object): T {
	if (isStruct(values) && !proto) return values;

	// define signal accessors - creates signals for all object props

	if (isObject(values)) {
		const
			state = Object.create(proto || Object.getPrototypeOf(values)),
			signals: any = {},
			descs = Object.getOwnPropertyDescriptors(values);

		// define signal accessors for exported object
		for (let key in descs) {
			let desc = descs[key];

			// getter turns into computed
			if (desc.get) {
				let s = signals[key] = computed(desc.get.bind(state));
				Object.defineProperty(state, key, {
					get() {
						return s.value;
					},
					set: desc.set?.bind(state),
					configurable: false,
					enumerable: true
				});
			}
			// regular value creates signal accessor
			else {
				let value = desc.value;

				let isObservable = observable(value),
					s = signals[key] = isSignal(value) ? value :
						// if initial value is an object - we turn it into sealed struct
						signal(
							isObservable ? undefined :
								isObject(value) ? Object.seal(signalStruct(value)) :
									Array.isArray(value) ? signalStruct(value) :
										value
						);

				// observables handle
				if (isObservable) sube(value, (v: any) => s.value = v);

				// define property accessor on struct
				// if ( key === 'items') debugger

				Object.defineProperty(state, key, {
					get() {
						if (_untrack && untrackList.length === 0) {
							return s.peek();
						} else if (_untrack && !untrackList.includes(key)) {
							return s.peek();
						}
						return s.value;
					},
					set(v) {
						if (isObject(v)) {
							// new object can have another schema than the new one
							// so if it throws due to new props access then we fall back to creating new struct
							if (isObject(s.value)) try {
								Object.assign(s.value, v);
								return;
							} catch (e) {
							}
							s.value = Object.seal(signalStruct(v));
						} else if (Array.isArray(v)) {
							s.value = signalStruct(v);
						} else s.value = v;
					},
					enumerable: true,
					configurable: false
				});

				if (Array.isArray(value)) {
					const fn = () => {
						assignProp(state[key], 'push', (...args: any[]) => {
							const v = s.peek();
							s.value = [...v, ...args];
							fn();
							spliceFn();
						})
					};
					const spliceFn = () => {
						assignProp(state[key], 'splice', (...args: any[]) => {
							const v = [...s.peek()];
							// @ts-ignore
							v.splice(...args);
							s.value = v;
							fn();
							spliceFn();
						})
					};
					fn();
					spliceFn();
				}
			}
		}

		Object.defineProperty(state, _struct, { configurable: false, enumerable: false, value: true });

		return state;
	}

	// for arrays we turn internals to signal structs
	if (Array.isArray(values)) {
		for (let i = 0; i < values.length; i++) {
			if (!isStruct(values[i])) values[i] = signalStruct(values[i]);
		}
	}

	return values;
}

export function useSignalStruct<T,>(values: T, proto?: Object): T {
	if (isStruct(values) && !proto) return values;

	// define signal accessors - creates signals for all object props

	if (isObject(values)) {
		const
			state = Object.create(proto || Object.getPrototypeOf(values)),
			signals: any = {},
			descs = Object.getOwnPropertyDescriptors(values);

		// define signal accessors for exported object
		for (let key in descs) {
			let desc = descs[key];

			// getter turns into computed
			if (desc.get) {
				let s = signals[key] = useComputed(desc.get.bind(state));
				Object.defineProperty(state, key, {
					get() {
						return s.value;
					},
					set: desc.set?.bind(state),
					configurable: false,
					enumerable: true
				});
			}
			// regular value creates signal accessor
			else {
				let value = desc.value;

				let isObservable = observable(value),
					s = signals[key] = isSignal(value) ? value :
						// if initial value is an object - we turn it into sealed struct
						useSignal(
							isObservable ? undefined :
								isObject(value) ? Object.seal(signalStruct(value)) :
									Array.isArray(value) ? signalStruct(value) :
										value
						);

				// observables handle
				if (isObservable) sube(value, (v: any) => s.value = v);

				// define property accessor on struct
				// if ( key === 'items') debugger

				Object.defineProperty(state, key, {
					get() {
						if (_untrack && untrackList.length === 0) {
							return s.peek();
						} else if (_untrack && !untrackList.includes(key)) {
							return s.peek();
						}
						return s.value;
					},
					set(v) {
						if (isObject(v)) {
							// new object can have another schema than the new one
							// so if it throws due to new props access then we fall back to creating new struct
							if (isObject(s.value)) try {
								Object.assign(s.value, v);
								return;
							} catch (e) {
							}
							s.value = Object.seal(signalStruct(v));
						} else if (Array.isArray(v)) {
							s.value = signalStruct(v);
						} else s.value = v;
					},
					enumerable: true,
					configurable: false
				});

				if (Array.isArray(value)) {
					const fn = () => {
						state[key].push = (...args: any[]) => {
							const v = s.peek();
							s.value = [...v, ...args];
							fn();
							spliceFn();
						};
					};
					const spliceFn = () => {
						state[key].splice = (...args: any[]) => {
							const v = [...s.peek()];
							// @ts-ignore
							v.splice(...args);
							s.value = v;
							fn();
							spliceFn();
						};
					};
					fn();
					spliceFn();
				}
			}
		}

		Object.defineProperty(state, _struct, { configurable: false, enumerable: false, value: true });

		return state;
	}

	// for arrays we turn internals to signal structs
	if (Array.isArray(values)) {
		for (let i = 0; i < values.length; i++) {
			if (!isStruct(values[i])) values[i] = signalStruct(values[i]);
		}
	}

	return values;
}

function isObject(v: any) {
	return (v && v.constructor === Object);
}
