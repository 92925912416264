import Dexie from 'dexie'
// @ts-ignore
import IDBExportImport from 'indexeddb-export-import'
import { toast } from 'react-toastify'

import { signal } from '@/react/core/reactive.ts'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'

export const [openDeleteAllPopup, setOpenDeleteAllPopup] = signal<boolean>(false)

export const handleDeleteAll = async () => {
  toast(`Clearing all data`)
  for (const dbName of ['pos', 'pos2', 'pos3']) {
    const db = new Dexie(dbName)
    await db.open()
    const idbDatabase = db.backendDB()
    const lock = new MultiAwaitLock(true)
    IDBExportImport.clearDatabase(idbDatabase, function (err: Error) {
      lock.release().then()
    })
    await lock.acquireAsync()
  }
  localStorage.clear()
  location.reload()
}
