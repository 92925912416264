import { getUrlParam } from "@/shared/utils2.ts";
import { getServersFromFlavor } from "@/shared/utils.ts";
import { computed, signal } from "@/react/core/reactive.ts";
import { GERMANY_SERVER_1 } from "@/lib/servers.ts";

const isHanseatic = getUrlParam('flavor') === 'hanseatic'
export const [isLimitGermany, setIsLimitGermany] = signal(isHanseatic)

const serverFilter: string = getUrlParam('serverFilter') || import.meta.env.VITE_SERVER_FILTER || ''

export const availableServers = computed(() => {
  const servers = Object.keys(getServersFromFlavor());

  if (serverFilter) {
    const filteredServers = serverFilter
      .split(',')
      .filter(s => servers.includes(s));

    if (filteredServers.length > 0) return filteredServers;
  }

  if (isLimitGermany()) {
    return servers.filter(server => server === GERMANY_SERVER_1);
  }

  return servers;
});
