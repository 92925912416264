import TimeManagementPlugin from '@time-management/TimeManagementPlugin.tsx'
import { memo } from 'react'

import { users0 } from '@/data/UserSignal.ts'
import { onEnter, PosScreen } from '@/pos/PosRouter.ts'

import { setSelectedUserId } from './TimeManagementView.logic'

const TimeManagementView = () => {
  onEnter(PosScreen.TIME_MANAGEMENT, () => setSelectedUserId(users0()?.[0]?._id))
  return <TimeManagementPlugin />
}

export default memo(TimeManagementView)
