import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type StartSplittingBillVertical1Type = {
  className?: string;
  seatNumber?: string;
  seatLabel?: string;
  isDefault?: boolean;
  isSelected?: boolean;
  showSeat?: boolean;

  /** Style props */
  startSplittingBillVerticaFlexShrink?: CSSProperties["flexShrink"];

  onClick?: () => void;
};

const StartSplittingBillVertical1: FunctionComponent<
  StartSplittingBillVertical1Type
> = ({
  className = "",
  seatNumber = "SEAT 1",
  seatLabel,
  isDefault = true,
  isSelected = true,
  showSeat,
  startSplittingBillVerticaFlexShrink,
  onClick
}) => {
  const startSplittingBillVerticalStyle: CSSProperties = useMemo(() => {
    return {
      flexShrink: startSplittingBillVerticaFlexShrink,
    };
  }, [startSplittingBillVerticaFlexShrink]);

  return (
    <div
      className={`shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] rounded bg-basic-color-white overflow-hidden flex flex-row items-center justify-center py-0 px-3 box-border relative gap-[8px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish-button-name-bold self-stretch mq320:pl-2.5 mq320:pr-2.5 mq320:box-border ${className}`}
      style={startSplittingBillVerticalStyle}
      onClick={onClick}
    >
      {isSelected && (
        <div className="absolute !m-[0] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] rounded [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ed7900] box-border z-[0] border-[1px] border-solid border-orange-linear-orange-linear-4000" />
      )}
      {isDefault && (
        <b className="relative z-[1] mq320:text-smi">{seatNumber}</b>
      )}
      {showSeat && (
        <b className="relative text-basic-color-white z-[2] mq320:text-smi">
          {seatLabel}
        </b>
      )}
    </div>
  );
};

export default StartSplittingBillVertical1;