import type { FunctionComponent } from "react";
import { STATES, VIEW__MODIFIER } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import TextField from "@/react/core/TextField";
import {LL0} from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils";

type PopupModifierGroupEditType = {
  onClose?: () => void;
};

const PopupModifierGroupEdit: FunctionComponent<PopupModifierGroupEditType> = ({
  onClose,
}) => {
  return (
    <div className="rounded-3xs bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-end justify-start pt-5 px-6 pb-6 box-border gap-[18px] min-w-[600] h-full text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish-button-name-bold hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[18px] hover:items-end hover:justify-start hover:rounded-3xs hover:pt-5 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[600]">
      <img
        className="relative w-6 h-6 object-cover cursor-pointer"
        alt=""
        src="/icongeneralclose-popup-icon--grey@2x.png"
        onClick={onClose}
      />
      <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
        <b className="relative md:text-xs">{LL0().ui.name()}</b>
        <TextField
          className="flex-1 font-mulish-button-name-bold text-sm text-darkslategray-100"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={STATES.editingModifierGroup()?.name}
          onChange={VIEW__MODIFIER.onItemGroupNameChanged}
        />
      </div>
      <div className="self-stretch flex flex-row items-center justify-end gap-[12px] text-center text-red-solid-red-300-ff4836">
        <button ref={makeRipple} onClick={() => (onClose?.(),VIEW__MODIFIER.onCurrentItemGroupDelete())} className="rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 gap-[3px] border-[1px] border-solid border-red-solid-red-300-ff4836">
          <img
            className="relative w-[17px] h-[17px] object-cover"
            alt=""
            src="/iconbin-icon--red@2x.png"
          />
          <b className="relative">{LL0().ui.delete()}</b>
        </button>
        <button ref={makeRipple} onClick={onClose} className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-[37px] text-white-solid-white-100-ffffff">
          <b className="relative">{LL0().ui.ok()}</b>
        </button>
      </div>
    </div>
  );
};

export default PopupModifierGroupEdit;
