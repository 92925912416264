import type { RxCollection, RxDatabase, RxDocument, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const VOUCHER = 'voucher'

export const VERSION = 3;

export interface Voucher {
  _id: string
  code?: string
  createdAt?: number
  price?: number
  createdBy?: string
  status?: VoucherStatus
  activated?: boolean
  expiredAt?: number
  name?: string
  type?: VoucherType
  usedValue?: number
  totalValue?: number | string
  customerLastName?: string
  customerFirstName?: string
  isEqualPrice?: boolean
}

export interface VoucherInfo {
  amount?: number,
  firstName?: string,
  lastName?: string
}

export enum VoucherStatus {
  CREATED = "created",
  REDEEMED = "redeemed"
}

export enum VoucherType {
  AMOUNT = 'amount',
  PERCENT = 'percent'
}

const voucherSchema: RxJsonSchema<Voucher> = {
  title: 'voucher schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    code: { type: 'string' },
    createdAt: { type: 'number' },
    price: { type: 'number' },
    createdBy: { type: 'string' },
    status: { type: 'string' },
    activated: { type: 'boolean' },
    expiredAt: { type: 'number' },
    name: { type: 'string' },
    type: { type: 'string' },
    usedValue: { type: 'number' },
    totalValue: { type: 'number' },
    customerLastName: { type: 'string' },
    customerFirstName: { type: 'string' },
    isEqualPrice: { type: 'boolean' },
  },
  indexes: []
} as const;

// export type Voucher = ExtractDocumentTypeFromTypedRxJsonSchema<typeof voucherSchema> & {
//   status: "created" | "redeemed",
//   type: "amount" | "percent",
//
// }


export type VoucherDocument = RxDocument<Voucher>;
export type VoucherCollection = RxCollection<Voucher>;
export const VoucherCollection: VoucherCollection = {} as VoucherCollection;
export const Voucher = VoucherCollection;

//@ts-expect-error debug only
window.Voucher = Voucher

export const createVoucherCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: VoucherCollection,
    collectionName: VOUCHER,
    version: VERSION,
    schema: voucherSchema,
  });
}
