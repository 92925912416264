import { type Order, PaidOrder } from "@/data/Order.ts";
import { d3 } from "@/data/data-utils.ts";
import { BonPosFactory } from "@/tse/dsfinv/BonPos.ts";
import { Eod } from "@/data/Eod.ts";
import { type ItemModifier, type OrderItem, OrderStatus } from "@/pos/OrderType.ts";
import { Bonpos_UStFactory } from "@/tse/dsfinv/Bonpos_UStFactory.ts";
import { Bonpos_ZusatzinfoFactory } from "@/tse/dsfinv/Bonpos_Zusatzinfo.ts";
import { Bonpos_PreisfindungFactory } from "@/tse/dsfinv/Bonpos_Preisfindung.ts";
import { BonkopfFactory } from "@/tse/dsfinv/Bonkopf.ts";
import { Bonkopf_UStFactory } from "@/tse/dsfinv/Bonkopf_USt.ts";
import { Bonkopf_AbrKreisFactory } from "@/tse/dsfinv/Bonkopf_AbrKreis.ts";
import { Bonkopf_ZahlartenFactory } from "@/tse/dsfinv/Bonkopf_Zahlarten.ts";
import { Bon_ReferenzenFactory } from "@/tse/dsfinv/Bon_Referenzen.ts";
import { Z_GV_TypFactory } from "@/tse/dsfinv/Z_GV_Typ.ts";

import { Stamm_AbschlussFactory } from "@/tse/dsfinv/Stamm_Abschluss.ts";
import { Stamm_OrteFactory } from "@/tse/dsfinv/Stamm_Orte.ts";
import { Stamm_KassenFactory } from "@/tse/dsfinv/Stamm_Kassen.ts";
import { Stamm_TerminalsFactory } from "@/tse/dsfinv/Stamm_Terminals.ts";
import { Stamm_AgenturenFactory } from "@/tse/dsfinv/Stamm_Agenturen.ts";
import { Stamm_UStFactory } from "@/tse/dsfinv/Stamm_USt.ts";
import { Stamm_TSEFactory } from "@/tse/dsfinv/Stamm_TSE.ts";
import { Z_ZahlartFactory } from "@/tse/dsfinv/Z_Zahlart.ts";
import { Z_WaehrungenFactory } from "@/tse/dsfinv/Z_Waehrungen.ts";
import { TSE_TransaktionenFactory } from "@/tse/dsfinv/TSE_Transaktionen.ts";
import { TseTransaction } from "@/data/TseTransaction.ts";
import { exportToCsv } from "@/shared/csv-utils.ts";
import { Buffer } from "buffer";
import pako from "pako";
import dayjs from "dayjs";
import { users0 } from "@/data/UserSignal.ts";

let eods: Eod[] = [];


//todo: EOD -> date -> which time to click print

async function order2Dsfinv(order: Order) {
  const BonPos_Table: ReturnType<typeof BonPosFactory>[] = []
  const BonPosUst_Table: any[] = []
  const BonPosZusatzinfo_Table: any[] = []
  const Bonpos_PreisfindungTable: any[] = [];
  const BonkopfTable: any[] = [];
  const Bonkopf_UStTable: any[] = []
  const Bonkopf_AbrKreisTable: any[] = []
  const Bonkopf_ZahlartenTable: any[] = []
  const Bon_ReferenzenTable: ReturnType<typeof Bon_ReferenzenFactory>[] = []
  const Z_GV_TypTable: any[] = [];

  const eod = eods.find(e => e.z === order.z);

  let refOrder: Order | undefined;
  if (order.refOrder && order.status === OrderStatus.CANCELLATION_REFERENCE) {
    refOrder = (await PaidOrder.findOne({ selector: { _id: order.refOrder } }).exec()) as Order;
  }

  function handleItem(item: OrderItem, isCancelled: boolean, refOrder?: Order) {
    if (item.quantity === 0) return;

    const BonPosItem = BonPosFactory(order, item, isCancelled, eod!);
    BonPos_Table.push(BonPosItem);

    const BonPosUStItem = Bonpos_UStFactory(order, item, isCancelled, eod!);
    BonPosUst_Table.push(BonPosUStItem);

    if (item.vDiscount) {
      Bonpos_PreisfindungTable.push(...Bonpos_PreisfindungFactory(order, item, isCancelled, eod!));
    }

    if (refOrder) {
      Bon_ReferenzenTable.push(Bon_ReferenzenFactory(order, refOrder, item, isCancelled, eod!));
    }
  }

  function handleModifier(item: OrderItem, modifier: ItemModifier) {
    //todo: test
    BonPosZusatzinfo_Table.push(Bonpos_ZusatzinfoFactory(order, item, modifier, eod!));
  }

  for (const item of order.items) {
    handleItem(item, false, refOrder);

    for (const modifier of item.modifiers) {
      handleModifier(item, modifier);
    }
  }

  for (const item of order.cancellationItems || []) {
    handleItem(item, true, refOrder);
  }

  function getUser(order: Order) {
    const user = users0().find(u => u.name === order.users?.[0]);
    return user;
  }

  BonkopfTable.push(BonkopfFactory(order, eod!, getUser(order)!));
  Bonkopf_UStTable.push(...Bonkopf_UStFactory(order, eod!));
  Bonkopf_AbrKreisTable.push(Bonkopf_AbrKreisFactory(order, eod!));
  Bonkopf_ZahlartenTable.push(...Bonkopf_ZahlartenFactory(order, eod!));
  Z_GV_TypTable.push(...Z_GV_TypFactory(order, eod!));

  return {
    BonkopfTable,
    Bonkopf_UStTable,
    Bonkopf_AbrKreisTable,
    Bonkopf_ZahlartenTable,
    Z_GV_TypTable,
    BonPos_Table,
    BonPosUst_Table,
    BonPosZusatzinfo_Table,
    Bonpos_PreisfindungTable,
    Bon_ReferenzenTable
  };
}


async function createTseTransactionTable(tseTransactions: TseTransaction[]) {
  const table: Awaited<ReturnType<typeof TSE_TransaktionenFactory>>[]  = [];
  for (const tseTransaction of tseTransactions) {
    table.push(await TSE_TransaktionenFactory(tseTransaction))
  }
  return table;
  //const TSE_TransaktionenTable = []
}

export const dsfinvTables = [
  { name: 'lines', table: 'BonPos_Table' },
  { name: 'lines_vat', table: 'BonPosUst_Table' },
  { name: 'itemamounts', table: 'Bonpos_PreisfindungTable' },
  { name: 'subitems', table: 'BonPosZusatzinfo_Table' },
  { name: 'transactions', table: 'BonkopfTable' },
  { name: 'transactions_vat', table: 'Bonkopf_UStTable' },
  { name: 'allocation_groups', table: 'Bonkopf_AbrKreisTable' },
  { name: 'datapayment', table: 'Bonkopf_ZahlartenTable' },
  { name: 'references', table: 'Bon_ReferenzenTable' },
  { name: 'businesscases', table: 'Z_GV_TypTable' },
  { name: 'cashpointclosing', table: 'Stamm_AbschlussTable' },
  { name: 'location', table: 'Stamm_OrteTable' },
  { name: 'cashregister', table: 'Stamm_KassenTable' },
  { name: 'slaves', table: 'Stamm_TerminalsTable' },
  { name: 'pa', table: 'Stamm_AgenturenTable' },
  { name: 'vat', table: 'Stamm_UStTable' },
  { name: 'tse', table: 'Stamm_TSETable' },
  { name: 'payment', table: 'Z_ZahlartTable' },
  { name: 'cash_per_currency', table: 'Z_WaehrungenTable' },
  { name: 'transactions_tse', table: 'TSE_TransaktionenTable' },
];

const getDateRangeSelector = (from: number, to: number) => ({
  vDate: { $gte: from, $lte: to }
});

export async function exportDSFINV(from: number, to: number, s3: boolean, email?: string) {
  const BonkopfTable: any[] = [];
  const Bonkopf_UStTable = []
  const Bonkopf_AbrKreisTable = []
  const Bonkopf_ZahlartenTable = []
  const Z_GV_TypTable = [];
  const BonPos_Table: ReturnType<typeof BonPosFactory>[] = []
  const BonPosUst_Table: any[] = []
  const Bon_ReferenzenTable: ReturnType<typeof Bon_ReferenzenFactory>[] = []
  const BonPosZusatzinfo_Table: any[] = []
  const Bonpos_PreisfindungTable: any[] = [];

  const selector = getDateRangeSelector(from, to);

  eods = d3(await Eod.find({ selector: selector }).exec());
  const orders = d3(await PaidOrder.find({ selector: selector }).exec());

  for (const order of orders) {
    if (order.status !== 'paid' && order.status !== OrderStatus.CANCELLATION_REFERENCE) continue;
    const mapping = await order2Dsfinv(order as Order);
    Bonkopf_UStTable.push(...mapping.Bonkopf_UStTable);
    Z_GV_TypTable.push(...mapping.Z_GV_TypTable);
    BonkopfTable.push(...mapping.BonkopfTable);
    Bonkopf_ZahlartenTable.push(...mapping.Bonkopf_ZahlartenTable);
    Bonkopf_AbrKreisTable.push(...mapping.Bonkopf_AbrKreisTable);
    BonPos_Table.push(...mapping.BonPos_Table);
    BonPosUst_Table.push(...mapping.BonPosUst_Table);
    BonPosZusatzinfo_Table.push(...mapping.BonPosZusatzinfo_Table);
    Bonpos_PreisfindungTable.push(...mapping.Bonpos_PreisfindungTable);
    Bon_ReferenzenTable.push(...mapping.Bon_ReferenzenTable);
  }

  //get all tables from createStammData
  const {
    Stamm_AbschlussTable,
    Stamm_OrteTable,
    Stamm_KassenTable,
    Stamm_TerminalsTable,
    Stamm_AgenturenTable,
    Stamm_UStTable,
    Stamm_TSETable,
    Z_ZahlartTable,
    Z_WaehrungenTable
  } = await createStammData(eods);

  let tseTransactions = await TseTransaction.find({
    selector: {
      TSE_TA_ENDE: { $gte: from, $lte: to }
    }
  }).exec();
  // tseTransactions = _.filter(tseTransactions, transaction => transaction.order && transaction.z);
  const TSE_TransaktionenTable = await createTseTransactionTable(tseTransactions);

  await exportToCsv({
    Bonkopf_UStTable,
    Z_GV_TypTable,
    BonkopfTable,
    Bonkopf_ZahlartenTable,
    Bonkopf_AbrKreisTable,
    BonPos_Table,
    BonPosUst_Table,
    BonPosZusatzinfo_Table,
    Bonpos_PreisfindungTable,
    Bon_ReferenzenTable,
    Stamm_AbschlussTable,
    Stamm_OrteTable,
    Stamm_KassenTable,
    Stamm_TerminalsTable,
    Stamm_AgenturenTable,
    Stamm_UStTable,
    Stamm_TSETable,
    Z_ZahlartTable,
    Z_WaehrungenTable,
    TSE_TransaktionenTable
  }, s3, email)

  console.log('exportDSFINV')
}

async function createStammData(eods: Eod[]) {
  const Stamm_AbschlussTable = []
  const Stamm_OrteTable = [];
  const Stamm_KassenTable = [];
  const Stamm_TerminalsTable = []
  const Stamm_AgenturenTable = []
  const Stamm_UStTable = []
  const Stamm_TSETable: Awaited<ReturnType<typeof Stamm_TSEFactory>>[] = [];
  const Z_ZahlartTable = []
  const Z_WaehrungenTable = []


  for (const eod of eods) {
    Stamm_AbschlussTable.push(Stamm_AbschlussFactory(eod));
    Stamm_OrteTable.push(Stamm_OrteFactory(eod));
    Stamm_KassenTable.push(Stamm_KassenFactory(eod));
    Stamm_TerminalsTable.push(Stamm_TerminalsFactory(eod));
    Stamm_AgenturenTable.push(Stamm_AgenturenFactory(eod));
    Stamm_UStTable.push(...Stamm_UStFactory(eod));
    Stamm_TSETable.push(...await Stamm_TSEFactory(eod));
    Z_ZahlartTable.push(...Z_ZahlartFactory(eod));
    Z_WaehrungenTable.push(Z_WaehrungenFactory(eod));
  }

  return {
    Stamm_AbschlussTable,
    Stamm_OrteTable,
    Stamm_KassenTable,
    Stamm_TerminalsTable,
    Stamm_AgenturenTable,
    Stamm_UStTable,
    Stamm_TSETable,
    Z_ZahlartTable,
    Z_WaehrungenTable
  }
}


export function getInflateReport(eod: Eod) {
  if (typeof eod?.report === 'string') {
    const dataBase64 = Buffer.from(eod?.report as string, 'base64')
    return JSON.parse(pako.inflate(dataBase64, { to: 'string' }))
  } else {
    return eod?.report
  }
}

export function getZERSTELLUNG(eod: Eod) {
  if (dayjs.unix(eod?.date).isValid()){
    return dayjs.unix(eod?.date).toISOString();
  } else {
    return '';
  }
}

// @ts-ignore
window.exportDSFINV = exportDSFINV