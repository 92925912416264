import { Customer } from '@/data/Customer'
import { convertDocuments, type DocDeepSignal } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils.ts'
import { effectOn, signal, useAsyncEffect } from '@/react/core/reactive'
import { setCustomer0 } from '@/react/CustomerInfoView/CustomerInfoView.tsx'
import { OnlineOrder } from '@/data/OnlineOrder.ts';
import { PaidOrder } from "@/data/Order.ts";
import _ from "lodash";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

const CUSTOMER_PER_PAGE = 100
export const [customers0, setCustomers0] = signal<Array<DocDeepSignal<Customer>>>([])
export const [customersPage, setCustomersPage] = signal<number>(0)
export const [isValidatingCustomers, setIsValidatingCustomers] = signal<boolean>(true)

export const [isCustomerReachingEnd, setIsCustomerReachingEnd] = signal<boolean>(false)
export const [customerV, setCustomerV] = signal<number>(0)
export const [isFirstRenderCustomers, setIsFirstRenderCustomers] = signal<boolean>(true)
export const [sortBy, setSortBy] = signal<{sortBy: string, sort: 'asc' | 'desc'}>()

export const [filterCustomers, setFilterCustomers] = signal<{
  name?: string
  phoneNumber?: string
  address?: {
    street?: string
    home?: string
  }
}>({})

export const makeCustomersAvailable = () => {
  useAsyncEffect(async () => {
    if (customersPage() === 0) {
      await dataLock.acquireAsync()

      //comment this when multiple generate?
      Customer.$.subscribe((change) => {
        setIsValidatingCustomers(true)
        setCustomersPage(1)
      })
      setIsValidatingCustomers(true)
      setCustomersPage(1)
    }
  }, [customersPage()])
}

export const makeCustomersPageAvailable = () => {
  useAsyncEffect(async () => {
    if (customersPage() === 0) {
      await dataLock.acquireAsync()
      setCustomersPage(1)
    }

  }, [customersPage()])
}

export const makeFilterCustomersAvailable = () => {
  useAsyncEffect(async () => {
    setCustomersPage(0)
    setIsValidatingCustomers(true)
  }, [filterCustomers(), sortBy()])
}

effectOn(
  [customersPage, customerV],
  async () => {
    const filter = filterCustomers();
    const {name: filterByName, phoneNumber: filterByPhoneNr, address} = filter || {};
    // must have name & phone number
    const selector : any = {}
    if (filterByName) {
      selector.name = { $regex: filterByName };
    }
    if (filterByPhoneNr) {
      selector.phoneNumber = { $regex: filterByPhoneNr };
    }
    if (!_.isEmpty(address)) {
      const {home, street} = address;
      if (home || street) {
        selector.addresses = { $elemMatch: {} };
        if (home) selector.addresses.$elemMatch.home = { $regex: home };
        if (street) selector.addresses.$elemMatch.street = { $regex: street };
      }
    }
    const _customers = await Customer.find({
      selector,
      sort: [
        sortBy()?.sortBy ? { [sortBy().sortBy]: sortBy().sort } : {id: 'desc'}
      ],
      limit: CUSTOMER_PER_PAGE,
      skip: CUSTOMER_PER_PAGE * (customersPage() - 1)
    }).exec()

    const customers = convertDocuments<Customer>(_customers, true, ["numberOfOrders", "spending"])

    for (const customer of customers) {
      const foundOnlineOrders = await OnlineOrder.find({
        selector: {
          customer: customer._id
        }
      }).exec()

      const foundPaidOrder = await PaidOrder.find({
        selector: {
          customer: customer._id
        }
      }).exec()

      if (companyInfo0()?.country !== 'de') {
        customer.spending = foundOnlineOrders.reduce((acc, curr) => acc + curr.vSum, 0);
        customer.numberOfOrders = foundOnlineOrders.length;
      } else {
        customer.spending = foundPaidOrder.reduce((acc, curr) => acc + curr.vSum, 0);
        customer.numberOfOrders = foundPaidOrder.length;
      }
    }
    setIsCustomerReachingEnd(customers.length === 0)

    if (customersPage() === 1) {
      setCustomers0([...customers])
      if (isFirstRenderCustomers()) {
        setCustomer0(customers[0])
        setIsFirstRenderCustomers(false)
      }
    } else {
      setCustomers0((prev) => [...prev, ...customers])
    }
    setIsValidatingCustomers(false)

    Customer.$.subscribe(c => {
      setCustomersPage(0);
    })

  }, { defer: true }
)

window.customers0 = customers0