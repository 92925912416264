import debug from 'debug'

import type { Order } from '@/data/Order'
import type { CompanyInfo } from '@/data/PosSetting'
import type { Locales } from '@/i18n/i18n-types'

import { ws } from './init-ws'
import { deviceIdentifier } from './ws-state'
import type { WsMessage } from './ws-types'

const log = debug('data:ws')

export function wsSendOrder(order?: Order) {
  const msg: WsMessage = { type: 'order', data: order, ns: 'second-display' }
  log('⚡️ Sending order to ws server...', msg)
  ws.send(JSON.stringify(msg))
}

export function wsSendInfo(info: CompanyInfo) {
  if (deviceIdentifier.v !== 'pos') return
  const msg: WsMessage = { type: 'info', data: info, ns: 'second-display' }
  log('⚡️ Sending company info to ws server...', msg)
  ws.send(JSON.stringify(msg))
}

// just send 2 props in generalSetting since we do not need the rest in second display
export function wsSendOrderConfig(info: any) {
  if (deviceIdentifier.v !== 'pos') return
  const msg: WsMessage = { type: 'order-config', data: info, ns: 'second-display' }
  log('⚡️ Sending order config to ws server...', msg)
  ws.send(JSON.stringify(msg))
}

export function wsSendLocale(locate: Locales) {
  if (deviceIdentifier.v !== 'pos') return
  const msg: WsMessage = { type: 'locale', data: locate, ns: 'second-display' }
  log('⚡️ Sending locale info to ws server...', msg)
  ws.send(JSON.stringify(msg))
}
