import type { FunctionComponent } from 'react';
import { Fragment, useContext } from 'react';
import OrderItem from '@order-view/OrderItem';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion-seats.tsx';
import { LL0 } from '@/react/core/I18nService.tsx';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import { getTotalItems } from '@/pos/logic/order-reactive.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { handleAddSeat, isShowToolbar } from '@/react/OrderView/OrderView.tsx'
import { OrderStatus } from "@/pos/OrderType.ts";
import { checkSingleOrderPayments, PaymentStatus } from "@/react/PaymentView/payment-check.ts";

type SeatBillType = {
  isSeatSplit?: boolean;
};

const SeatBill: FunctionComponent<SeatBillType> = ({ isSeatSplit }) => {
  const { seat, setSeat, order0, isPayment } = useContext(ItemFactoryContext);

  return (
    isSeatSplit && (
      <div className="!flex self-stretch overflow-y-auto shrink-0 hidden flex-col items-center justify-start gap-[8px]">
        <Accordion type="single" className={'w-full space-y-[8px]'}
                   collapsible
                   value={seat?.().toString()}
                   onValueChange={val => {
                     setSeat?.(parseInt(val));
                   }}>
          {order0?.().seatMap?.filter(o => o.status !== OrderStatus.PAID).map(
            (_order, index) => (
              <Fragment key={index}>
                {
                  (getTotalItems(_order) > 0 || !isPayment) &&
                  <AccordionItem key={_order.seat} value={(_order.seat!).toString()}>
                    <AccordionTrigger text={`${LL0().payment.seat()} ${_order.seat! + 1}`}
                                      isPaid={!!isPayment && checkSingleOrderPayments(_order).status === PaymentStatus.OK}
                                      price={_order.vSum}>
                    </AccordionTrigger>
                    <AccordionContent className={'mt-[8px]'}>
                      {_order.items.length > 0 ?
                        <>
                          {_order.items.map((item, index2) => (
                            <OrderItem orderItem={item} key={index2} order={order0?.()} showAddMinusIcon={!isPayment} />
                          ))}
                        </>
                        :
                        <b
                          className="self-stretch relative text-center py-2.5 px-2 text-[#9E9E9E]">{LL0().order.selectItemsFromTheLeftSide()}</b>
                      }
                    </AccordionContent>
                  </AccordionItem>
                }
              </Fragment>
            )
          )}
        </Accordion>
        { !isPayment && !isShowToolbar() &&
        <div
          className="self-stretch rounded bg-blue-solid-blue-100-c9e2ff flex flex-row items-center justify-between p-4 relative text-left text-base text-black-solid-black-900-1e1e23 height_414:pt-3 height_414:pb-3 height_414:box-border"
          ref={makeRipple}
          onPointerDown={handleAddSeat}
        >
          <div
            className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]" />
          <div className="flex-1 flex flex-row items-center justify-start gap-[8px] z-[1]">
            <img
              className="w-[19px] relative h-3 hidden"
              alt=""
              src="/menu-open-icon.svg"
            />
            <b className="relative height_414:text-sm">{LL0().order.addSeat().toUpperCase()}</b>
          </div>
          <div className='flex flex-row items-center justify-start z-[2]'>
            <img
              className='w-6 relative h-6 object-cover'
              alt=''
              src='/orderscreenaddseatplus-icon@2x.png'
            />
          </div>
        </div>
        }
      </div>
    )
  );
};

export default SeatBill;