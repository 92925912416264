import { type FunctionComponent, useCallback, useState } from "react";
import SetMasterMachineEditName from "./SetMasterMachineEditName";
import PortalPopup from "./PortalPopup";
import TaxAndPaymentDeleteConfi from "./TaxAndPaymentDeleteConfi";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { DeviceSetting } from "@/data/DeviceSetting.ts";
import { useDeviceId } from '@/shared/getDeviceId'
import type { DocDeepSignal } from "@/data/data-utils.ts";
import { lastSeen, removeDevice, setDeviceAsMaster } from "@/react/MasterMachineView/MasterMachineView.tsx";
import { posSync0 } from "@/data/PosSyncState.ts";

type SetMasterMachineItemType = {
  device?: string;
  devicePos?: number;
  deviceSetting?: DocDeepSignal<DeviceSetting>
  isMasterMachine?: boolean;
  isSetAsMasterDevice?: boolean;
};

const SetMasterMachineItem: FunctionComponent<SetMasterMachineItemType> = ({
  device,
  devicePos,
  isMasterMachine = true,
  isSetAsMasterDevice = true,
  deviceSetting
}) => {
  const [isSetMasterMachineEditNameOpen, setSetMasterMachineEditNameOpen] =
    useState(false);
  const [isTaxAndPaymentDeleteConfiOpen, setTaxAndPaymentDeleteConfiOpen] =
    useState(false);

  const openSetMasterMachineEditName = useCallback(() => {
    setSetMasterMachineEditNameOpen(true);
    setItemToEdit(deviceSetting);
  }, [device]);

  const [itemToEdit, setItemToEdit] = useState<DocDeepSignal<DeviceSetting> | undefined>(undefined)


  const closeSetMasterMachineEditName = useCallback(() => {
    setSetMasterMachineEditNameOpen(false);
  }, []);

  const openTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(true);
  }, []);

  const closeTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(false);
  }, []);

  const deviceId = useDeviceId();

  const handleRemoveDevice = useCallback(async () => {
    if (deviceSetting)
      await removeDevice(deviceSetting)
    closeTaxAndPaymentDeleteConfi();
  }, [device]);

  const handleSetDeviceAsMaster = useCallback(async () => {
    if (deviceSetting)
      await setDeviceAsMaster(deviceSetting)
  }, [device]);

  if (deviceSetting?.show === false && posSync0()?.id) {
    return
  }

  return (
    <>
      <div
        className="bg-whitesmoke flex flex-row items-start justify-between py-2 px-6 box-border text-left text-mini text-black-solid-black-880-1d1d26 font-mulish self-stretch sm:pl-6 sm:box-border Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
        <div
          className="shrink-0 flex flex-row items-center justify-start gap-[8px] cursor-pointer"
          onClick={openSetMasterMachineEditName}
        >
          <img
            className="relative w-8 h-8 object-cover sm:w-[26px] sm:h-[26px] sm:[transform:rotate(0deg)] Tablet_600:w-6 Tablet_600:h-6"
            alt=""
            src="/yellow-edit-icon@2x.png"
          />
          <div className='flex flex-col gap-1'>
            <div
              className="relative sm:text-sm">{(device ? device : 'Device') + ` ${devicePos ? `(${devicePos})` : ''}`}
              {deviceId === deviceSetting?._id ? ` ( ${LL0().masterMachine.thisDevice()} )` : ''}
            </div>
            <div className='text-[13px]'>{deviceSetting?._id}</div>
          </div>
        </div>
        <div
          className="shrink-0 flex flex-row items-center justify-start gap-[24px] text-center text-black-solid-black-1000-000000 sm:gap-[16px] Tablet_600:gap-[12px]">
          <div className="relative sm:text-sm">
            {deviceSetting?.date ? ` (${lastSeen(deviceSetting?.date)})` : ''}
          </div>
          {isSetAsMasterDevice && (
            <div
              className="rounded-md bg-purple-solid-purple-300-aec0ff h-8 flex flex-row items-center justify-center py-0 px-2 box-border sm:h-[30px] Tablet_600:h-[26px]"
              onClick={handleSetDeviceAsMaster}
              ref={makeRipple}>
              <div className="relative sm:text-sm Tablet_600:text-smi">
                {LL0().settings.deviceManager.setDeviceAsMaster()}
              </div>
            </div>
          )}
          {isMasterMachine && (
            <div
              className="rounded-md h-8 flex flex-row items-center justify-center py-0 px-3 box-border text-left text-red-solid-red-450-c0272d sm:h-[30px] Tablet_600:h-[26px]">
              <i className="relative sm:text-sm Tablet_600:text-smi">{LL0().dashboard.masterMachine().toUpperCase()}</i>
            </div>
          )}
          <div
            className="rounded-10xs bg-red-solid-red-220-ff4e54 w-8 h-8 flex flex-row items-center justify-center cursor-pointer sm:w-[26px] sm:h-[26px] Tablet_600:w-6 Tablet_600:h-6"
            onClick={openTaxAndPaymentDeleteConfi}
          >
            <img
              className="relative w-[37px] h-[37px] object-cover sm:w-[26px] sm:h-[26px] Tablet_600:w-6 Tablet_600:h-6"
              alt=""
              src="/iconbin-icon--white@2x.png"
            />
          </div>
        </div>
      </div>
      {isSetMasterMachineEditNameOpen && isMasterMachine && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeSetMasterMachineEditName}
        >
          <SetMasterMachineEditName onClose={closeSetMasterMachineEditName} deviceSetting={deviceSetting} />
        </PortalPopup>
      )}
      {isTaxAndPaymentDeleteConfiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTaxAndPaymentDeleteConfi}
        >
          <TaxAndPaymentDeleteConfi onClose={closeTaxAndPaymentDeleteConfi} onClick={handleRemoveDevice} />
        </PortalPopup>
      )}
    </>
  );
};

export default SetMasterMachineItem;
