import {type FunctionComponent, memo} from 'react';
import CategoryListToolBar from './CategoryListView/CategoryListToolBar';
import { STATES, VIEW__CATEGORY, VIEW__PRODUCT } from '@/react/EditOnlineMenuView/EditOnlineMenuView.logic';
import CategoryListRow from './CategoryListView/CategoryListRow';
import EditOnlineMenuTopNav from './EditOnlineMenuTopNav';
import EditOnlineMenuTopNav1 from "./EditOnlineMenuTopNav1";

const CategoryListView: FunctionComponent = () => {
  return (
    <div className="edit-online-menu-screen bg-gray-solid-gray-120-f8f9ff w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[0px] hover:bg-gray-solid-gray-120-f8f9ff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start">
      <EditOnlineMenuTopNav1 />
      {/*<EditOnlineMenuTopNav  />*/}
      <CategoryListToolBar />
      <div className="!pb-[140px] no-scrollbar pb-4 self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[12px]">
        {STATES.categories()?.map(c => (
          <CategoryListRow
            key={c._id}
            model={c}
            isExpanded={STATES.expandedCategoryIds().includes(c._id)}
            isEditing={STATES.editingCategoryId() === c._id}
            onDelete={() => VIEW__CATEGORY.onRemove(c._id)}
            onMoveUp={() => VIEW__CATEGORY.onMoveUp(c._id)}
            onMoveDown={() => VIEW__CATEGORY.onMoveDown(c._id)}
            onToggleEdit={() => (STATES.editingCategoryId() ? VIEW__CATEGORY.onEdit() : VIEW__CATEGORY.onEdit(c._id))}
            onToggleExpand={() => VIEW__CATEGORY.onToggleExpand(c._id)}
            onNameChanged={v => VIEW__CATEGORY.onChanged('name', v)}
            onCreateItem={() => VIEW__PRODUCT.onCreate(c._id)}
          />
        ))}
        {/*
        <CategoryListRow courseText="Main Course 1" />
        <CategoryListRow courseText="Main Course 2" />
        <CategoryListRow courseText="Main Course 3" />
        */}
      </div>
    </div>
  );
};

export default memo(CategoryListView);
