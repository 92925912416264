import type { RxCollection, RxDatabase } from 'rxdb'

import { MASTER_ACTION_COLLECTION_NAME, MasterAction, masterActionSchema } from './MasterAction'
import { createCollection } from './utils/migration'

export function createMasterActionCollection<T>(database: RxDatabase<T>): Promise<RxCollection<MasterAction>> {
  return createCollection({
    database,
    collection: MasterAction,
    collectionName: MASTER_ACTION_COLLECTION_NAME,
    version: masterActionSchema.version,
    schema: masterActionSchema,
  })
}
