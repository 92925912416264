import { LL0 } from '@/react/core/I18nService'

import type { CertificateResponse, DocumentResponseBody, ResponseError, TransactionResponseBody, UserResponseBody } from './lib/types'

export class SrmUserNotConfigured extends Error {
  constructor() {
    super(LL0().srm.errors.unauthorized())
  }
}
export class UserNotFound extends Error {
  constructor(public userName?: string) {
    super(userName ? `User "${userName}" not found` : 'Invalid User')
  }
}

abstract class WebSrmErrorBase<T> extends Error {
  constructor(public response: T, public errors?: ResponseError[]) {
    super(errors?.map(e => `[${e.id}] ${e.mess}`).join(', ') ?? 'Unknown Error')
  }
}
export class WebSrmCertificateError extends WebSrmErrorBase<CertificateResponse> {
  constructor(public response: CertificateResponse) {
    super(response, response.retourCertif.listErr)
  }
}
export class WebSrmUserError extends WebSrmErrorBase<UserResponseBody> {
  constructor(public response: UserResponseBody) {
    super(response, response.retourUtil.listErr)
  }
}
export class WebSrmTransactionError extends WebSrmErrorBase<TransactionResponseBody> {
  constructor(public response: TransactionResponseBody) {
    super(response, response.retourTrans.retourTransActu?.listErr ?? response.retourTrans.retourTransLot?.listErr)
  }
}
export class WebSrmDocumentError extends WebSrmErrorBase<DocumentResponseBody> {
  constructor(public response: DocumentResponseBody) {
    super(response, response.retourDoc.listErr)
  }
}
export class WebSrmInvalidResponseError extends WebSrmErrorBase<unknown> {}

export type WebSrmError = WebSrmCertificateError | WebSrmUserError | WebSrmTransactionError | WebSrmDocumentError | WebSrmInvalidResponseError
