import type {FunctionComponent, memo} from "react";
import DashboardLeftPanel from "@dashboard/DashboardLeftPanel";
import DashboardRight from "@dashboard/DashboardRight";

const ManuelTablePlugin: FunctionComponent = () => {
  return (
    <div className="w-full h-full overflow-hidden flex flex-row items-start justify-start !bg-[url('/order-screen-plugin@3x.png')] bg-cover bg-no-repeat bg-[top]">
      <DashboardLeftPanel />
      <DashboardRight />
    </div>
  );
};

export default ManuelTablePlugin;
