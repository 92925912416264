import dayjs from 'dayjs'
import { useRef } from 'react'
import { toast } from 'react-toastify'
import { filter, tap, type Subscription } from 'rxjs'

import { dataLock } from '@/data/DataUtils'
import { getDeviceId } from '@/shared/getDeviceId'
import { StaffStatus, TableStaffStatus } from '@/data/TableStaffStatus'
import { onEnter, onExit, PosScreen, router } from '@/pos/PosRouter'
import { mainScreen } from "@/data/PosSettingsSignal.ts";

/** Will exit to home screen if the current order is changed on other devices */
export function useOrderEditLocking(orderId: string, screen: PosScreen) {
  const sub = useRef<Subscription | null>(null)

  onEnter(screen, async () => {
    await dataLock.acquireAsync()
    sub.current?.unsubscribe()
    sub.current = TableStaffStatus.$.pipe(
      filter(o => o.operation !== 'DELETE'),
      filter(o => o.documentData.updatedOn !== getDeviceId()),
      filter(o => dayjs.unix(o.documentData.updatedAt).isAfter(dayjs().subtract(1, 'minutes'))),
      tap(async ({ documentData }) => {
        const currentTable = await TableStaffStatus.findOne({
          selector: {
            updatedOn: getDeviceId(),
            status: { $ne: StaffStatus.NORMAL },
          },
        })
          .exec()
          .then(a => a?.table)
        if (documentData.table !== currentTable) return

        const msg = '⚠️ Order is being process on other devices, going to home screen...'
        console.log(msg)
        toast.info(msg) // TODO: check if we need this
        router.screen = mainScreen()
      })
    ).subscribe()
  })
  onExit(screen, () => sub.current?.unsubscribe())
}
