import { OrderItem } from '@order-view/OrderItem'
import { TESTCASES_REQUIRE_DOWNLOAD } from '../testcase/constants'
import { CONSTANT_VALUES } from './constants'
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";

/** Check if the testcase should download the receipt. For local debug only */
export function shouldDownloadReceipt(testcaseNumber: string = CONSTANT_VALUES.CASESSAI_EMPTY): testcaseNumber is string {
  if (!import.meta.env.DEV) return false
  return TESTCASES_REQUIRE_DOWNLOAD.includes(testcaseNumber)
}

const priceFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

/** Reformat the price. Used in **Report** or **Bill** only */
export const reformatCurrency = (n: number | string) => priceFormatter.format(Number(n)) + ' $'

/** Trim zipcode and test if it in correct format (`A9A9A9`) */
export const getValidZipcode = (zipCode: string) => {
  const result = zipCode.trim().replaceAll(' ', '')
  if (/^\w\d\w\d\w\d$/.test(result)) return result
  throw new Error(`Invalid Zip Code ${result}`)
}

/** Get the street number from street address (Take "123-456" from "123-456 rue Unetelle") */
export const getStreetNumber = (streetAddress: string) => {
  return streetAddress.trim().split(/ |,/).shift() ?? ''
}

const asciiRegex = /[^a-zA-Z0-9 @:!#$%&'()*+,\-.=?_|~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜÝàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿ]/g
/**
 * [SW-73-V 4.1] Remove any character that not conform to the spec.
 *
 * Example: "phở gà" -> "ph gà"
 */
export const ensureValidAsciiStandard = (s: string) => s.replace(asciiRegex, '').trim()

export const nameLengthRegex = /^.{2,128}$/;


export const ensureValidItemNameLength = (items: OrderItem[]) => {
  if (!isQuebecSrmEnabled()) return true;
  const checkedItems = items?.filter(o => o.quantity !== 0)
  const nameLessThan2 = checkedItems?.filter(item => {
    if (!nameLengthRegex.test(item.name)) return true;
    return item.modifiers?.some(modifier => !nameLengthRegex.test(modifier.name));
  })?.length || 0;

  return nameLessThan2 < 1;
}
