import type { RxCollection, RxDatabase, RxDocument } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const DISCOUNT = 'discount'

export const VERSION = 2;

const discountSchema = {
  title: 'discount schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    label: {type: 'string'},
    value: {type: 'number'},
    type: {type: 'string'},
  },
  indexes: []
} as const;

export interface Discount {
  _id?: string;
  label?: string;
  value?: number;
  type?: "amount" | "percent";
}

export type DiscountDocument = RxDocument<Discount>;
export type DiscountCollection = RxCollection<Discount>;
export const DiscountCollection: DiscountCollection = {} as DiscountCollection;
export const Discount = DiscountCollection;

export const createDiscountCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Discount,
    collectionName: DISCOUNT,
    version: VERSION,
    schema: discountSchema,
  });
}
