import {FunctionComponent, memo} from "react";
import EditRoomFloorPlanAccordion from "@edit-room/EditRoomFloorPlanAccordion";
import EditRoomForm from "@edit-room/EditRoomForm";
import EditTableForm from "@edit-room/EditTableForm";
import EditWallForm from "@edit-room/EditWallForm";
import EditTreeForm from "@edit-room/EditTreeForm";
import AddWallTreeTableButtonGroup from "@edit-room/AddWallTreeTableButtonGroup";
import EditRoomRight from "@edit-room/EditRoomRight";
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { onBack } from '@/react/EditRoomView/EditRoomView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

const EditRoomPlugin: FunctionComponent = () => {
  return (
    <div className="edit-floor-plan-screen bg-darkslategray-200 w-full h-full flex flex-row items-center justify-start text-center text-sm text-basic-color-white font-mulish">
      <div className="self-stretch flex-1 bg-whitesmoke-100 flex flex-col items-center justify-start gap-[11px] min-w-[190px] max-w-[290px]">
        <EditRoomFloorPlanAccordion showFloorFlanAccordionClosed={false} />
        <div className="no-scrollbar self-stretch flex-1 bg-basic-color-white overflow-y-auto flex flex-col items-center justify-between pt-2 px-0 pb-3">
          <div className="self-stretch flex flex-col items-center justify-start py-0 px-0.5 gap-[10px]">
            <div className="self-stretch flex flex-col items-center justify-start gap-[5px]">
              <EditRoomForm isRoomEdit={false} />
              <EditTableForm isTableEdit={false} />
              <EditWallForm isWallEdit />
              <EditTreeForm isTreeEdit={false} />
            </div>
            <AddWallTreeTableButtonGroup />
          </div>
          <div className="rounded-81xl bg-royalblue-200 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
              ref={makeRipple}
               onClick={onBack}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="relative leading-[17px]">{LL0().ui.back()}</b>
          </div>
        </div>
      </div>
      <EditRoomRight />
    </div>
  );
};

export default memo(EditRoomPlugin);
