import type { RxCollection, RxJsonSchema } from 'rxdb'

export const CASHBOOK_TRANSACTIONS_COLLECTION_NAME = 'cashbook_transactions'
export const CASHBOOK_HISTORIES_COLLECTION_NAME = 'cashbook_histories'

export const VERSION = 4

export interface CashbookHistories {
  _id: string
  id: number
  vDate?: number
  startBalance?: number
  closeBalance?: number
  incomingBalance?: number
  outgoingBalance?: number
  firstTransactionTime?: number
  lastTransactionTime?: number
  isClosed: boolean
}

export interface CashbookTransactions {
  _id: string
  z?: number
  action?: string
  transactionType?: string
  amount?: number
  user?: string
  tax?: number
  date: number
  vDate: number
  note?: string
  orderId?: string
  receipt?: string
}

export const cashbookTransactionsSchema: RxJsonSchema<CashbookTransactions> = {
  title: 'cashbookTransactions schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    z: { type: 'integer', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    action: { type: 'string', maxLength: 8 },
    transactionType: { type: 'string', maxLength: 8 },
    amount: { type: 'number' },
    user: { type: 'string', maxLength: 64 },
    tax: { type: 'number' },
    date: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    vDate: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    note: { type: 'string' },
    orderId: { type: 'string' },
    receipt: { type: 'string' },
  },
  indexes: ['date', 'vDate'],
  required: ['_id', 'date', 'vDate'],
}

export const cashbookHistoriesSchema: RxJsonSchema<CashbookHistories> = {
  title: 'cashbookHistories schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    id: { type: 'integer', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    vDate: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    startBalance: { type: 'number' },
    closeBalance: { type: 'number' },
    incomingBalance: { type: 'number' },
    outgoingBalance: { type: 'number' },
    firstTransactionTime: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    lastTransactionTime: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    isClosed: { type: 'boolean' },
  },
  indexes: ['lastTransactionTime', 'vDate', 'id', 'isClosed'],
  required: ['_id', 'id', 'isClosed'],
}

export const CashbookHistories = {} as RxCollection<CashbookHistories>
export const CashbookTransactions = {} as RxCollection<CashbookTransactions>

Object.assign(window, { CashbookTransactions, CashbookHistories }) // Make available globally
