import { FunctionComponent } from "react";

type NoticeType = {
  showNotice?: boolean;
  noticeOrderIsReady?: boolean;
  deliveryIsOnTheWay?: boolean;
  delivered?: boolean;
  noticeText?: string;
};

const Notice: FunctionComponent<NoticeType> = ({
  showNotice,
  noticeOrderIsReady,
  deliveryIsOnTheWay,
  delivered,
  noticeText,
}) => {
  return (
    showNotice && (
      <div className="rounded-sm bg-tomato-200 overflow-hidden flex flex-row items-center justify-start py-1 px-1.5 relative gap-[4px] z-[4] text-left text-smi text-white font-mulish">
        {noticeOrderIsReady && (
           <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-mediumseagreen z-[0]" />
        )}
        {deliveryIsOnTheWay && (
            <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-darkorange hidden z-[1]" />
        )}
        {delivered && (
            <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-blueviolet hidden z-[2]" />
        )}
        <b className="relative leading-[117.4%] z-[3] Tablet_768:text-xs mq1440:text-sm">
          {noticeText}
        </b>
      </div>
    )
  );
};

export default Notice;
