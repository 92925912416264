import { Button } from '@mui/material'
import type { FunctionComponent } from 'react'

import { makeLicenseExpired } from '@/data/LicenseHub'
import { srmCertLogic } from '@/srm/cert.logic'
import { srmLogic } from '@/srm/logic'

export const SrmUtilities: FunctionComponent = () => (
  <div className="grid gap-2 items-center">
    <Button
      onClick={srmLogic.setupTestcase}
      data-testid="srmSettings.testcaseSetup"
    >
      Testcase Setup
    </Button>
    <Button
      onClick={srmCertLogic.createCert}
      data-testid="srmSettings.testUserSetup"
    >
      Enroll Certificate
    </Button>
    <Button
      onClick={srmLogic.setupTestUsers}
      data-testid="srmSettings.testUserSetup"
    >
      Setup Test Users
    </Button>
    <hr />
    <Button
      variant={'contained'}
      size={'medium'}
      onClick={makeLicenseExpired}
    >
      Make license expired
    </Button>
    <Button
      variant={'contained'}
      size={'medium'}
      color='secondary'
      onClick={srmLogic.resetSettingsToDefault}
    >
      Reset Settings to Default
    </Button>
  </div>
)
