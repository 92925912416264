import { type FunctionComponent, useCallback, useState } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { Order } from "@/data/Order.ts";
import { checkOngoingOrder } from "@general-settings/CheckOngoingOrder.tsx";

export type GeneralSettingsTrainingMode1Type = {
  className?: string;
  onClose?: () => void;
  onComplete?: () => void;
};

const GeneralSettingsTrainingMode1: FunctionComponent<
  GeneralSettingsTrainingMode1Type
> = ({ className = "", onClose, onComplete }) => {
  const onTrainingMode = async () => {
    const remainOrders = await Order.find().exec();
    if (remainOrders.filter(o => o.table).length > 0) {
      await checkOngoingOrder()
      return;
    }
    onComplete?.();
    onClose?.();
  }
  return (
    <>
      <div
        className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-[30px] px-6 pb-6 box-border gap-[24px] min-w-[280px] h-full max-w-full max-h-full  text-xl text-red-solid-red-550-cf2222 font-mulish ${className}`}
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px] ml-3">
          <div
            className="self-stretch relative items-start justify-start text-base leading-[28px] font-medium text-black-solid-black-880-1d1d26 mt-3 gap-[4px]">
            <b className="text-red-700">{LL0().settings.warning()}</b>
            <p>{LL0().settings.trainingModeOn()}</p>
            <ul className="list-disc font-bold ml-5 font-size" style={{ fontSize: '15px', lineHeight: '30px' }}>
              <li>{LL0().settings.transactions()}</li>
              <li>{LL0().settings.customer()}</li>
              <li>{LL0().settings.receipts()}</li>
              <li>{LL0().settings.ensure()}</li>
            </ul>
            <p>{LL0().settings.ifYou()}</p>
            <p>{LL0().settings.doYou()}</p>
          </div>
        </div>
        <div
          className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
          <div
            className="flex-1 rounded-md bg-white-solid-white-100-ffffff box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={onClose}
          >
            <b className="relative">{LL0().ui.cancel()}</b>
          </div>
          <div
            className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 box-border max-w-[100px] text-white-solid-white-100-ffffff"
            onClick={onTrainingMode}
          >
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettingsTrainingMode1;
