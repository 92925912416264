import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { makeRipple } from '@/react/core/ripple-utils'
import {LL0} from "@/react/core/I18nService.tsx";
import { FunctionComponent } from "react";
import PhotoPickerForm from "./PhotoPickerForm";
import {
  handleChooseBackground, handleUrlBackground,
  setLoadingImage
} from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";

type PopupImageSelectType = {
  onClose?: () => void;
};

const PopupImageSelect: FunctionComponent<PopupImageSelectType> = ({
  onClose,
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [url, setUrl] = useState<string | null>(null)
  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files?.item(0) ?? null), [])
  const onUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value), [])
  const onSubmit = async () => {
    onClose?.()
    setLoadingImage(true)
    if (file) {
      await handleChooseBackground(file)
    } else if (url) {
      await handleUrlBackground(url)
    }
    setLoadingImage(false)
  }

  return (
    <div className="rounded-2xl bg-white-opacity-white-30-ffffff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.1)] [backdrop-filter:blur(30px)] w-[1087px] overflow-hidden flex flex-col items-end justify-start py-10 px-6 box-border gap-[16px] max-w-full max-h-full text-center text-xl text-white-solid-white-100-ffffff font-mulish-button-name-bold">
      <button form="uploadImg" onClick={onSubmit} disabled={!file && !url} ref={makeRipple} className="rounded-3xs [background:linear-gradient(135deg,_#ffd998,_#e2a130)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-2.5 px-5 box-border min-w-[88px] md:py-2 md:px-4 md:box-border sm:py-1.5 sm:px-4 sm:box-border sm:min-w-[64px] mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2 mq600:pr-2 mq600:box-border">
        <b className="flex-1 relative lg:text-lg md:text-mini sm:text-sm mq768:text-smi mq600:text-smi">
          {LL0().ui.ok()}
        </b>
      </button>
      <PhotoPickerForm onClose={onClose} onFileChange={onFileChange} onUrlChange={onUrlChange} onSubmit={onSubmit} file={file} url={url}/>
      {/*
      <PhotoPickerFormDemo />
      */}
    </div>
  );
};

export default PopupImageSelect;
