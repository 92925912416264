import { currentPrinter } from "@/react/Printer/PrinterSettingView.tsx";
import { convertToBase64Png, createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import { printIntegrate } from "@/shared/printer/intergrateManager.ts";
import EscPrinter from "@/shared/printer/node-thermal-printer";
import uuid from "time-uuid";

  export const testIntegratePrint = async () => {
    const address = currentPrinter()?.address?.split("/")[0];
    if (!address) return;
    const _uuid = uuid();
    const escPrinter = new EscPrinter(address,{needWarmup: false, width: 384});
    const printer = createPrinter();
    await printer.println("TEST");
    const raster: Raster = (await printer.print())!;
    const base64 = await convertToBase64Png(raster, false);
    printIntegrate({id:escPrinter.printerConfig.id, printer:escPrinter, data:Buffer.from(base64, 'base64')});
  }