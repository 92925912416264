import dayjs from 'dayjs'
import delay from 'delay'
import _ from 'lodash'
import { useEffect, useState, type FC, type PropsWithChildren } from 'react'
import { toast } from 'react-toastify'

import { initDatabase } from '@/data/DataSchema'
import { deviceSettingLock } from '@/data/DeviceSettingHub.ts'
import { generalSetting0, posSetting0, posSettingLock } from '@/data/PosSettingsSignal.ts'
import { tseConfig0, tseConfigLock } from '@/data/TseConfigHub.ts'
import { allConfig, remoteConfigLock, useFirebase } from '@/extensions/firebase/useFirebase'
import { loadAllLocalesAsync } from '@/i18n/i18n-util.async'
import { onRecord } from '@/pos/logic/rrweb-utils.ts'
import { handleLoginSyncit } from '@/pos/logic/syncit-utils.ts'
import { now } from '@/pos/logic/time-provider.ts'
import { checkRequestUpdate, sendDeviceInfo, sendDeviceName } from '@/pos/SendDeviceInfo.ts'
import { sendDeviceVersion } from '@/pos/sendDeviceVersion.ts'
import { useAsyncEffect } from '@/react/core/reactive.ts'
import { clearPrintCache } from '@/react/Printer/print-invoice-api.ts'
import { initVFD } from '@/shared/vfd/vfd-init.ts'
import { initializeRuntimeTse } from '@/tse/tse-communicate.ts'
import { TseType } from '@/tse/tse-utils.ts'

import { usePageTitle } from './hooks/usePageTitle'

import '@/react/websocket/init-ws.ts'
import '@/react/ws-java/init-java-ws.ts'
import '@/tse/dsfinv/dsfinv-convert.ts'
import '@/react/utils/shortcut-init.ts'
import '@/react/Printer/VPrinter.ts'
import '@/data/validate/validate.ts'
import '@/data/fix/backup-indexeddb.ts'
import '@/lib/fetch-master.ts'
import { useEruda } from "@/react/Developer/Eruda.logic.ts";
import '@/shared/debug.ts'
import '@/lib/open-telemetry.ts'
import '@/lib/o2.ts'
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { useMasterActionProcessQueue } from '@/react/hooks/master-actions.hook'

// import '@/react/dev/test-surreal.ts';

if (import.meta.env.DEV) {
  import('@/react/dev/auto').then()
  import('@/react/dev/scenario/srm').then()
}

const Init: FC<PropsWithChildren> = ({ children }) => {
  const [render, setRender] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      await loadAllLocalesAsync()
      initDatabase().then()
      setRender(true)
      initializeRuntimeTse().then()
      initVFD().then()
      await sendDeviceVersion()
    })()
    const id = setInterval(sendDeviceInfo, 30000)
    return () => clearInterval(id)
  }, [])
  useAsyncEffect(async () => {
    handleLoginSyncit().then()
    checkRequestUpdate().then()

    if (localStorage.getItem('clear-print-cache')) {
      await posSettingLock.acquireAsync()
      clearPrintCache()
      localStorage.removeItem('clear-print-cache')
    }

    if (localStorage.getItem('change-master-device')) {
      await posSettingLock.acquireAsync()
      await deviceSettingLock.acquireAsync()
      await tseConfigLock.acquireAsync()
      if (posSetting0()) {
        _.assign(deviceSetting0(), { isSupportDevice: true, show: true })
        toast.success('Change master device...', { autoClose: 800 })
        posSetting0()!.masterDevice = deviceSetting0()?._id
        localStorage.removeItem('change-master-device')
        if (tseConfig0()?.initialized && tseConfig0().type !== TseType.TseLocalMock) {
          _.assign(tseConfig0(), { type: TseType.TseLocalMock })
          toast.info('Change tse -> TseLocalMock...')
          await delay(1500)
          location.reload()
        }
      }
    }

    if (import.meta.env.MODE === 'production') {
      await posSettingLock.acquireAsync()
      await remoteConfigLock.acquireAsync()
      if (!!generalSetting0()?.monitoring || !!allConfig['forceRecordScreen']?.asBoolean()) {
        await delay(3000)
        onRecord().then()
      }
    }

    await deviceSettingLock.acquireAsync()
    if (!deviceSetting0()?.isSupportDevice && !deviceSetting0()?.show) {
      _.assign(deviceSetting0(), { show: true })
    }

    _.assign(deviceSetting0(), { date: dayjs(now()).unix() })
    sendDeviceName().then()
  }, [])
  usePageTitle()
  useFirebase()
  useEruda()
  useMasterActionProcessQueue()

  return <>{render ? children : null}</>
}

export default Init
