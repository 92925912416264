import type { Order } from "@/data/Order.ts";
import {
  CommitAction,
  type ItemCommitAddDiscountOrderItem, type ItemCommitAddOrderDiscount,
  type ItemCommitClearDiscountOrderItem
} from "@/pos/OrderType.ts";
import { toast } from "react-toastify";

export function removeDiscountBeforeSplit(order: Order) {
  if (order.discount !== undefined) {
    order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER })
  }

  if (order.seatMode) {
    const discountOrderCommits = order.commits?.filter(c => c.action === CommitAction.ADD_ORDER_DISCOUNT && c.seat !== undefined) as ItemCommitAddOrderDiscount[];
    if (discountOrderCommits && discountOrderCommits.length > 0) {
      for (const discountOrderCommit of discountOrderCommits) {
        if (discountOrderCommit.seat !== undefined) {
          order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER, seat: discountOrderCommit.seat })
        }
      }
    }
  }

  const discountItems = order.items.filter(i => i.discount);
  if (discountItems?.length) {
    for (const discountItem of discountItems) {
      order.commits?.push({
        action: CommitAction.CLEAR_DISCOUNT_ORDER_ITEM,
        commitId: discountItem?.commitRefs![0],
      } as ItemCommitClearDiscountOrderItem)
    }
  }

  order.commits?.push({action: CommitAction.CLEAR_DISCOUNT_COMMIT_BEFORE_MOVE})
}

export function checkItemBeforeSplit(order: Order) {
  let check: boolean = true;
  const printItems = order.items.filter(i => i.printed);
  const unPrintItems = order.items.filter(i => !i.printed && i?.change);
  if (printItems?.length > 0 && unPrintItems.length > 0) {
    check = false;
  }
  return check;
}
