import paytx from '@/assets/data/clover/paytx.json'
import refundtx from '@/assets/data/clover/refundtx.json'
import delay from 'delay'
import type { IPayTxPayload, IPayTxResp, IXTerminal } from './IXTerminal'

export default class SimulatorTerminal implements IXTerminal {
  eod(): void {
  }

  async payTx({ amount, tip = 0 } : IPayTxPayload, onProgress?: Function): Promise<{error: string} | IPayTxResp> {
    await delay(2000)
    console.log('SimulatorTerminal', amount, tip);
    return {
      response: {
        type: 'simulator',
        payment: Object.assign({}, paytx.payment, {
          amount: Math.round(amount * 100),
          tipAmount: Math.round(tip * 100)
        })
      }
    }
  }

  // https://docs.clover.com/reference/refund
  async refundTx({refId, amount}: { refId: string, amount: number}, onProgress?: Function): Promise<any> {
    try {
      await delay(2000);
      refundtx.payment.amount = amount * 100;
      return { response: refundtx };
    } catch (e: any) {
      return {error: e?.message}
    }
  }

  /**
   * https://docs.clover.com/reference/void
   */
  async voidTx({refId}: {refId: string}, onProgress?: Function) : Promise<any> {
    try {
      await delay(2000)
      return { response: refundtx }
    } catch (e: any) {
      return {error: e?.message}
    }
  }

  async printTxReceipt(paymentId: string, method: string) : Promise<any> {
    return
  }

  async printRefundReceipt(refundId: string, method: string) : Promise<any> {
    return
  }

  async printVoidReceipt(paymentId: string, method: string) : Promise<any> {
    return
  }

  async isOnline() {
    return true
  }

  async cancel(metadata?: any) {
    return
  }

  async test(onProgress?: Function) {
    return true
  }
}
