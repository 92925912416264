import { taxCategories0 } from "@/data/TaxCategoryHub.ts";
import { products0 } from "@/data/ProductHub.ts";
import { Product } from "@/data/Product.ts";
import { convertDocument, convertDocuments } from "@/data/data-utils.ts";
import { captureException, withScope } from "@sentry/react";
import { OrderLayout } from "@/data/OrderLayout.ts";
import _ from "lodash";
import { TseTransaction } from "@/data/TseTransaction.ts";
import dayjs from "dayjs";
import { RoomObject } from "@/data/Room.ts";

export function categoryFixFactory() {
  return {
    fixLayoutCategories,
    queryTseTransaction,
    fixRoomObjects
  }

  async function fixRoomObjects() {
    const docs = await RoomObject.find().exec();
    for (const doc of docs) {
      if (doc.doc) {
        await doc.incrementalPatch({doc: undefined});
      }
    }
  }

  async function fixLayoutCategories() {
    const _orderLayout = await OrderLayout.findOne().exec();
    const orderLayout = convertDocument(_orderLayout, true);
    const group = _.groupBy(orderLayout.categories, c => `${c.top}/${c.left}`);
    console.log('orderLayout', orderLayout);
    const categories = orderLayout.categories.filter(c => {
      if (!c.name) return false;
      return true;
    });
    orderLayout.categories = categories;
  }

  async function queryTseTransaction() {
    const date = dayjs('05.06.2024', 'DD.MM.YYYY').startOf('d').unix();
    const endDate = dayjs('06.06.2024', 'DD.MM.YYYY').startOf('d').unix();
    const transactions = await TseTransaction.find({
      selector: {
        TSE_TA_START: {
          $gte: date,
          $lt: endDate
        }
      }
    }).exec();
    console.log('transactions', transactions);
  }
}

async function fix3() {
  const api = categoryFixFactory();
  await api.fixRoomObjects();
}

// @ts-ignore
window.fix3 = fix3;
