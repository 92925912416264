import { Button, FormControl } from '@mui/material'
import debug from 'debug'
import _ from 'lodash'
import { useEffect } from 'react'

import { isQuebecSrmEnabled, srmSetting0 } from '@/data/PosSettingsSignal.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import TextField from '@/react/core/TextField.tsx'
import { rnHost } from '@/shared/webview/rnwebview'

import { csrContent, isSubmitting, srmCertLogic, srmDeviceSetting } from '../cert.logic'
import CertificateInfoCell from './CertificateInfoCell'

const log = debug('data:srm')

export default function SettingsFieldset() {
  useEffect(() => {
    log('Loading cert...')
    rnHost.loadCert().then(r => log('>', r))
  }, [])
  return (
    <>
      {isQuebecSrmEnabled() && (
        <div className="p-4 self-stretch flex flex-col gap-2 border border-black border-opacity-25 rounded">
          <div className="self-stretch">{LL0().settings.quebecSrmSettings.identificationNumber()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={srmSetting0().identificationNumber ?? ''}
              onChange={e => _.assign(srmSetting0(), { identificationNumber: e.target.value })}
              placeholder="1234567890"
            />
          </FormControl>

          <div className="self-stretch">{LL0().settings.quebecSrmSettings.billingNumber()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={srmSetting0().billingNumber ?? ''}
              onChange={e => _.assign(srmSetting0(), { billingNumber: e.target.value })}
              placeholder="AA9999"
            />
          </FormControl>

          <div className="self-stretch">{LL0().settings.quebecSrmSettings.qstNumber()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={srmSetting0().qstNumber ?? ''}
              onChange={e => _.assign(srmSetting0(), { qstNumber: e.target.value })}
            />
          </FormControl>

          <div className="self-stretch">{LL0().settings.quebecSrmSettings.gstNumber()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={srmSetting0().gstNumber ?? ''}
              onChange={e => _.assign(srmSetting0(), { gstNumber: e.target.value })}
            />
          </FormControl>

          <div className="self-stretch">{LL0().settings.quebecSrmSettings.authCode()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={srmSetting0().authCode ?? ''}
              onChange={e => _.assign(srmSetting0(), { authCode: e.target.value })}
            />
          </FormControl>

          <div className="self-stretch">{LL0().settings.quebecSrmSettings.regionCode()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={csrContent.ST ?? ''}
              onChange={e => _.assign(csrContent, { ST: e.target.value })}
            />
          </FormControl>

          <div className="self-stretch">{LL0().settings.quebecSrmSettings.countryCode()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={csrContent.C ?? ''}
              onChange={e => _.assign(csrContent, { C: e.target.value })}
            />
          </FormControl>

          <div className="self-stretch">{LL0().settings.quebecSrmSettings.timezone()}:</div>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={csrContent.L ?? ''}
              onChange={e => _.assign(csrContent, { L: e.target.value })}
            />
          </FormControl>

          <hr />

          <p>{LL0().settings.userSettings.deviceID()}</p>

          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={srmDeviceSetting()?.deviceId ?? ''}
              onChange={e => {
                _.assign(srmDeviceSetting(), { deviceId: e.target.value })
              }}
            />
          </FormControl>

          <hr />

          <p>{'Certificate Nickname (SN)'}</p>
          <FormControl
            className="self-stretch"
            variant="outlined"
            size="small"
            disabled={isSubmitting()}
          >
            <TextField
              className="w-full"
              value={csrContent.SN ?? ''}
              data-testid="installation.srm.nicknameInput"
              onChange={e => _.assign(csrContent, { SN: e.target.value })}
            />
          </FormControl>

          <CertificateInfoCell
            cert={srmDeviceSetting()?.cert}
            download="cert.pem"
            onDelete={srmCertLogic.deleteCert}
          />

          <div className="grid grid-cols-2 gap-2">
            <Button
              disabled={!!srmDeviceSetting()?.cert || isSubmitting()}
              variant="contained"
              color="primary"
              onClick={srmCertLogic.createCert}
              data-testid="installation.srm.enrollBtn"
            >
              {LL0().settings.userSettings.enroll()}
            </Button>
            <Button
              disabled={!srmDeviceSetting()?.cert || isSubmitting()}
              variant="contained"
              color="secondary"
              onClick={srmCertLogic.replaceCert}
              data-testid="installation.srm.replaceBtn"
            >
              {LL0().settings.userSettings.replace()}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
