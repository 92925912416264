import { type FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";

export type ReservationCheckinPopupType = {
  className?: string;
  name?: string;
  phone?: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const ReservationCheckinPopup: FunctionComponent<
  ReservationCheckinPopupType
> = ({ className = "", name, phone, onClose, onConfirm }) => {
  return (
    <div
      className={`w-[448px] relative bg-white-solid-white-100-ffffff flex flex-col items-end justify-start pt-8 px-4 pb-5 box-border gap-[32px] max-w-full max-h-full overflow-auto text-center text-base text-red-solid-red-550-cf2222 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] z-[0]">
        <b className="self-stretch relative">{LL0().ui.confirmation()}</b>
        <div className="self-stretch flex flex-col items-center justify-start text-gray-solid-gray-470-33333c">
          <div className="relative font-medium">{LL0().reservation.doYouWantToCheckInThisAppointment()}</div>
          <div className="relative font-extrabold text-black-solid-black-1000-000000">
            <p className="m-0">{LL0().dashboard.customer()}: {`${name}`}</p>
            <p className="m-0">{LL0().reservation.phoneNumber()}: {phone}</p>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start z-[1] text-sm text-white-solid-white-100-ffffff">
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff h-9 flex flex-row items-center justify-center py-1 px-2.5 box-border"
            ref={makeRipple}
             onClick={() => {
               onConfirm?.()
               onClose?.()
             }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
      <img
        className="w-6 absolute !m-[0] top-[8px] left-[416px] h-6 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icongeneralclose-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default ReservationCheckinPopup;
