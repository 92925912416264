import {FunctionComponent, useState} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";

type KitchenMonitorItemType = {
  quantity?: number;
  itemName?: string;
  modifiers?: string;
  isChecked?: boolean;
  orderStatus?: "new" | "prepare" | "done"
};

const KitchenMonitorItem: FunctionComponent<KitchenMonitorItemType> = (
  {
    quantity = 3,
    itemName = "Chicken soup",
    modifiers = "(khong hanh, them thit)",
    orderStatus = "new",
    isChecked = false,
  }) => {

  const [checked, setChecked] = useState<boolean>(isChecked)

  return (
    <div
      className="flex w-full flex-row items-center justify-between py-1 px-2 box-border relative text-left text-sm text-red-solid-red-660-8f181d font-mulish flex-1">
      <div className={`absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] box-border z-[2] border-[1px] border-solid
        ${orderStatus === "new" ? "bg-orange-solid-orange-50-fff4ed border-solid border-orange-solid-orange-50-fff4ed"
        : orderStatus === "prepare" ? "bg-green-solid-green-50-f5fbec border-green-solid-green-50-f5fbec"
          : "bg-purple-solid-purple-50-f7f7ff border-solid border-purple-solid-purple-50-f7f7ff"}
      `}/>
      <div className="flex-1 flex flex-row items-start justify-start gap-[8px] z-[3]">
        <b className="relative leading-[18px] flex items-center w-[26px] shrink-0">
          {quantity}x
        </b>
        <div
          className="flex-1 flex flex-col items-start justify-start gap-[2px] text-xs text-black-solid-black-1000-000000">
          <div className={`self-stretch relative leading-[15px] font-semibold ${checked && "line-through"}`}>
            {itemName}
          </div>
          <i className={`self-stretch relative leading-[15px] text-black-solid-black-870-282828 ${checked && "line-through"}`}>
            {modifiers}
          </i>
        </div>
      </div>
      <div className="flex flex-row items-center justify-start gap-[4px] z-[4]">
        <div className="relative w-5 h-5">
          <FormControlLabel
            className="absolute h-[120%] w-[120%] top-[-10%] right-[-10%] bottom-[-10%] left-[-10%]"
            label=""
            control={<Checkbox color="primary" checked={checked} onChange={() => setChecked(!checked)}/>}
          />
        </div>
      </div>
    </div>
  );
};

export default KitchenMonitorItem;
