import { convertDocuments, type DocDeepSignal } from "@/data/data-utils";
import { DATA_INITED_2 } from './data-const'
import { OrderTseTemp } from "@/data/OrderTseTemp";
import { computed, effectOn, signal, useAsyncEffect } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";
import uuid from "time-uuid";

export const [orderTseTemps0, setOrderTseTemps0] = signal<Array<DocDeepSignal<OrderTseTemp>>>([]);
export const [orderTseTempV, setOrderTseTempV] = signal(1);
effectOn([orderTseTempV], async () => {
  await dataLock.acquireAsync();
  let _orderTseTemps = await OrderTseTemp.find().exec();
  if (_orderTseTemps.length === 0 && !localStorage.getItem(DATA_INITED_2)) {
    await OrderTseTemp.insert({
      _id: uuid(),
      onlyFoodHoldNumber: 0
    });
    _orderTseTemps = await OrderTseTemp.find().exec();
  }
  const orderTseTemps = convertDocuments<OrderTseTemp>(_orderTseTemps, true);
  setOrderTseTemps0(orderTseTemps);
}/*, { defer: true }*/);

export const makeOrderTseTempsAvailable = () => {
  useAsyncEffect(async () => {
    if (orderTseTempV() === 0) {
      await dataLock.acquireAsync();
      OrderTseTemp.$.subscribe((change) => {
        setOrderTseTempV(v => v + 1);
      })
      setOrderTseTempV(1);
    }
  }, [orderTseTempV()]);
};

export const orderTseTemp0 = computed<OrderTseTemp>(() => orderTseTemps0()[0]);