import { FunctionComponent, useCallback, useState } from 'react';
import DeleteConfirmationPopup from '@edit-room/DeleteConfirmationPopup';
import PortalPopup from '@edit-room/PortalPopup';
import { EditorMode, isSelectedMode } from '@/react/EditRoomView/EditRoomView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

type EditTreeFormType = {
  isTreeEdit?: boolean;
};

const EditTreeForm: FunctionComponent<EditTreeFormType> = ({
  isTreeEdit = false,
}) => {
  isTreeEdit = isSelectedMode(EditorMode.Tree)

  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  return (
    <>
      {isTreeEdit && (
        <div className="!flex self-stretch rounded bg-[#fbfbfc] shadow-[0px_0px_8px_rgba(75,_74,_95,_0.3)] hidden flex-col items-start justify-center py-3.5 px-[11px] text-left text-smi text-black font-mulish">
          <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
            <div className="self-stretch flex-1 rounded-md flex flex-row items-center justify-center py-2.5 px-3 gap-[6px] border-[1px] border-solid border-black">
              <img
                className="relative w-3.5 h-4"
                alt=""
                src="/duplicate-icon1.svg"
              />
              <b className="relative leading-[15px] md:text-2xs">{LL0().restaurant.duplicate()}</b>
            </div>
            <div
              className="flex-1 rounded-md flex flex-row items-center justify-center py-[9.5px] px-3 gap-[8px] cursor-pointer text-tomato-200 border-[1px] border-solid border-tomato-100"
              onClick={openDeleteConfirmationPopup}
            >
              <img
                className="relative w-[17px] h-[17px] object-cover"
                alt=""
                src="/iconbin-icon--red21@2x.png"
              />
              <b className="relative leading-[15px] inline-block w-[42.8px] shrink-0 md:hidden">
                {LL0().ui.delete()}
              </b>
            </div>
          </div>
        </div>
      )}
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default EditTreeForm;
