import {LL0} from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";
import {setTerminal0, terminal0} from "@/react/PaymentSettingView/PaymentSettingView.tsx";
import {FormControlLabel, Switch} from "@mui/material";

export default function CloverTerminalConfiguration() {
  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().taxPayment.terminalId()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata.terminalId || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.terminalId = e.target.value;
            return prev;
          })}
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">POS Id</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata.posId || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.posId = e.target.value;
            return prev;
          })}
        />
      </div>

      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().tax.merchantId()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata.merchantId || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.merchantId = e.target.value;
            return prev;
          })}
        />
      </div>

      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().tax.clientId()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata.clientId || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.clientId = e.target.value;
            return prev;
          })}
        />
      </div>

      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">{LL0().taxPayment.timeoutSeconds()}</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          type="number"
          value={terminal0()?.metadata.timeoutInSeconds || 0}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.timeoutInSeconds = e.target.value;
            return prev;
          })}
        />
      </div>
      {<FormControlLabel
        className="self-stretch"
        label={LL0().tax.enableManualTip()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.enableTip}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.enableTip = checked;
              return prev;
            })}
          />}
      />}

      {<FormControlLabel
        className="self-stretch"
        label={LL0().tax.enableSignature()}
        control={
          <Switch
            color="primary"
            size="medium"
            checked={terminal0()?.metadata?.enableSignature}
            onChange={(e, checked) => setTerminal0(prev => {
              if (!prev) return prev;
              prev.metadata.enableSignature = checked;
              return prev;
            })}
          />}
      />}

      <div className="self-stretch relative text-text-color-gray-979797 text-center">
        Clover Network, LLC
      </div>
    </>
  )
}