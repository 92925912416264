import Queue from 'queue';
import { rnHost } from "@/shared/webview/rnwebview";
import uuid from "time-uuid";
import { captureException } from "@sentry/react";
import debug from 'debug';
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import _ from "lodash";
import pTimeout from "p-timeout";
import delay from "delay";
import { allConfig } from "@/extensions/firebase/useFirebase.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";

const log = debug('printer:lanManager');

const queueManager: { [k: string]: {q: Queue, id: string, opened: boolean, timeoutId?: ReturnType<typeof setTimeout>} } = {}

const LIMIT = import.meta.env.MODE === 'production' ? 1000 : 10;

const lanLock = new MultiAwaitLock(false);

export const printLan = (task: { data: Buffer, dropConnection: boolean, printerIp: string }) => {
  log('print Lan ', task.printerIp);

  const notiErr = _.debounce((e: any) => {
    notificationToast(`print to ip: ${task.printerIp} error ${e.message}`, { autoClose: 30 * 1000 });
  }, 30000, { leading: true });

  queueManager[task.printerIp] = queueManager[task.printerIp] || {
    q: new Queue({ autostart: true, concurrency: 1 }),
    id: task.printerIp,
    opened: false,
  }

  queueManager[task.printerIp].q.push(async () => {
    let count = 0;
    while (count < LIMIT) {
      let openReady = false;
      try {
        const payload = task.data.toString('base64');
        const _uuid = queueManager[task.printerIp].id;
        try {
          await pTimeout(rnHost.add(1, 1), {
            milliseconds: 1000,
          })
        } catch (e) {
          throw new Error('Comlink fail');
        }
        // await lanLock.acquireAsync();
        await rnHost.openTcp(_uuid, task.printerIp);
        openReady = true;
        // const extra = {chunkSize: allConfig['print_ip_chunk_size']?.asNumber(), chunkDelay: allConfig['print_ip_chunk_delay']?.asNumber()}
        await rnHost.printTcp({ payload, uuid: _uuid, dropConnection: /*task.dropConnection*/ false});
        // if (task.dropConnection) {
        //   clearTimeout(queueManager[task.printerIp].timeoutId);
        //   queueManager[task.printerIp].timeoutId = setTimeout(async () => {
        //     lanLock.tryAcquire();
        //     await rnHost.closeTcp(task.printerIp);
        //     await delay(3000)
        //     await lanLock.release();
        //   }, 20000)
        // }
        await rnHost.closeTcp(task.printerIp);
        await delay(500);
        break;
      } catch (e: any) {
        notiErr(e);
        captureException(new Error(`print to ip: ${task.printerIp} error ${e.message}`), { tags: { type: 'print' } });
        log(`print to ip: ${task.printerIp} error ${e.message}`);
        console.error(`print to ip: ${task.printerIp} error`, e);
        //todo: emit to virtual printer
        if (e.message === undefined || e.message?.includes('Comlink fail') || openReady) {
          break;
        }
      } finally {
        count++;
      }
    }
  })
}
