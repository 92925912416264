import type { RxCollection, RxJsonSchema } from 'rxdb'

export const YEARLY_REPORTS_COLLECTION_NAME = 'yearly_reports'

/** Represents the information for the latest transaction. */
interface LatestTransactionInfo {
  noTrans: string
  apresTax: string
  datTrans: string
  psiDatTrans?: string
  psiNoTrans?: string
}

/**
 * Represents the data for a yearly report.
 */
export interface YearlyReportData {
  /** The year of the report. */
  year: number
  /** The total number of records for the year. */
  totalRecords: number
  /** The number of payment records for the year. */
  paymentRecords: number
  /** The net amount for the year. */
  net: number
  /** The GST (Goods and Services Tax) amount for the year. */
  gst: number
  /** The QST (Quebec Sales Tax) amount for the year. */
  qst: number
  /** The gross amount for the year. */
  gross: number
  /** The adjustment amount for the year. */
  adj: number
  /** The amount due for the year. */
  due: number
  /** The latest printed transaction info. */
  latestTrans?: LatestTransactionInfo
}

export interface YearlyReports {
  _id: string
  /** The year of the report. */
  year: number
  /** The user name of the report. */
  user: string
  /** Whether the report data is made in training mode or not. */
  training: boolean
  /** The report data for the year. */
  reportData: YearlyReportData
}

export const yearlyReportSchema: RxJsonSchema<YearlyReports> = {
  title: 'yearly report schema',
  version: 2, // increment this number if schema changes
  type: 'object',
  primaryKey: '_id',
  properties: {
    _id: { type: 'string', maxLength: 100 },
    year: {
      type: 'number',
      minimum: 2000,
      maximum: 2100,
      multipleOf: 1,
    },
    training: { type: 'boolean' },
    user: { type: 'string', maxLength: 100 },
    reportData: { type: 'object' },
  },
  required: ['_id', 'year', 'user', 'training', 'reportData'],
}

export const YearlyReports: RxCollection<YearlyReports> = {} as RxCollection<YearlyReports>

Object.assign(window, { YearlyReports }) // for easier debugging
