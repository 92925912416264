import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { signal } from '@/react/core/reactive.ts';
import { addVoucher, redeemVoucher } from '@/react/PaymentView/PaymentView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { setVoucherStatus, voucherStatus } from "@/react/OrderView/OrderView.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const [inputValue, setInputValue] = signal<string>("");

function onKeyTap(key: string) {
  if (key === '<-') {
    if (inputValue().length > 0) {
      setInputValue(inputValue().slice(0, inputValue().length - 1))
      if (voucherStatus() !== '') {
        setVoucherStatus('');
      }
    }
  } else {
    setInputValue((prev) => prev + key)
    if (voucherStatus() !== '') {
      setVoucherStatus('');
    }
  }
}

const VoucherKeyboard = ({ onClickSave }: { onClickSave?: () => void }) => {
  return (<div
    className="select-none bg-silver-200 grid grid-cols-3 grid-rows-4 w-full p-1 gap-[5px] text-center text-lg text-text-color-black-1e1e23">
    {["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"].map((key, index) => (
      <div
        className="self-stretch flex-1 rounded-10xs bg-white-solid-white-100-ffffff box-border overflow-hidden flex flex-row items-center justify-center min-w-[32px] min-h-[32px] border-[1px] border-solid border-gray-solid-gray-600-979797"
        key={index}
        onPointerDown={() => onKeyTap(key)}
        ref={makeRipple}
      >
        <b className="flex-1 relative leading-[17px]">{key}</b>
      </div>
    ))}
    <div
      className="self-stretch flex-1 flex flex-row items-center justify-center gap-[5px] text-sm text-white-solid-white-100-ffffff">
      <div
        className="self-stretch flex-1 rounded-10xs bg-gray-solid-gray-350-ced1d7 box-border overflow-hidden flex flex-row items-center justify-center min-w-[40px] min-h-[40px] border-[1px] border-solid border-gray-solid-gray-600-979797"
        onPointerDown={() => onKeyTap("<-")}
      >
        <div className="relative w-[30px] h-[30px] Mobile_480:w-5 Mobile_480:h-5">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/iconicon-delete-key3@2x.png"
          />
        </div>
      </div>
      <div
        className="self-stretch flex-1 rounded-10xs [background:linear-gradient(188.59deg,_rgba(255,_255,_255,_0.25),_rgba(0,_0,_0,_0.25)),_#3a89ff] overflow-hidden flex flex-row items-center justify-center min-w-[40px] min-h-[40px]"
        onPointerDown={onClickSave}
      >
        <div className="relative font-semibold Mobile_480:text-xs">
          {LL0().ui.save()}
        </div>
      </div>
    </div>

  </div>)
}


type VoucherPopupType = {
  onClose?: () => void;
};

const VoucherPopup: FunctionComponent<VoucherPopupType> = ({ onClose }) => {

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setInputValue("")
  }, [])

  return (
    <div
      className="relative rounded-md bg-white-solid-white-100-ffffff w-[620px] h-fit flex flex-col items-center justify-start gap-[8px] max-w-full max-h-full overflow-auto text-left text-2xs text-blue-solid-blue-470-356ff6 font-mulish-button-name-bold">
      <div
        className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-start justify-end pt-3.5 px-3.5 pb-2 border-b-[1px] border-solid border-gray-solid-gray-150-eeeeee">
        <div className="relative w-6 h-6 overflow-hidden shrink-0">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover cursor-pointer"
            alt=""
            src="/payment-vertical-close-popup-icon--grey@2x.png"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
        <div className="self-stretch flex flex-row items-start justify-start py-2 pr-2 pl-0 gap-[12px]">
          <div className="self-stretch w-fit flex flex-col items-start justify-start gap-[4px] min-w-[119px]">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider', width: "100%", flexGrow: 1, flexShrink: 0 }}
            >
              <Tab label={LL0().restaurant.createVoucher().toUpperCase()} {...a11yProps(0)} />
              <Tab label={LL0().restaurant.redeemVoucher().toUpperCase()} {...a11yProps(1)} />
            </Tabs>
          </div>
          <div
            className="flex-1 flex flex-col items-start justify-start gap-[16px] text-base text-black-solid-black-600-424242">
            <TabPanel value={value} index={0} className="w-full">
              <div
                className="flex-1 h-[294px] flex flex-col items-start justify-start gap-[16px] text-base text-black-solid-black-600-424242">
                <div className="relative font-extrabold">{LL0().restaurant.voucherValue()}</div>
                <input
                  className="[border:none] flex font-mulish-button-name-bold text-smi bg-white-solid-white-100-ffffff self-stretch rounded-sm shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] overflow-hidden flex-row items-center justify-start py-[9px] px-[17px]"
                  placeholder={LL0().restaurant.voucherValue()}
                  type="text"
                  value={inputValue()}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <VoucherKeyboard onClickSave={() => {
                  addVoucher({
                    price: inputValue()
                  })
                  onClose?.()
                }}/>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="w-full">
              <div
                className="flex-1 flex flex-col items-start justify-start gap-[16px] text-base text-black-solid-black-600-424242">
                <div className="relative font-extrabold font-mulish">{LL0().restaurant.voucherCode()}</div>
                <input
                  className="[border:none] flex font-mulish-button-name-bold text-smi bg-white-solid-white-100-ffffff self-stretch rounded-sm shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] overflow-hidden flex-row items-center justify-start py-[9px] px-[17px]"
                  placeholder={LL0().restaurant.voucherCode()}
                  type="text"
                  value={inputValue()}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                {inputValue() &&
                  <span className="text-red-500 text-sm">
                    {voucherStatus() === 'notFound' && LL0().voucher.voucherNotCreated()}
                    {voucherStatus() === 'alreadyUsed' && LL0().voucher.voucherAlreadyRedeemed()}
                  </span>}
                <VoucherKeyboard onClickSave={async () => {
                  await redeemVoucher(inputValue())
                  if (voucherStatus() === 'valid') {
                    onClose?.()
                    setVoucherStatus('')
                  }
                }}/>
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherPopup;
