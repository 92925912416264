import _ from 'lodash'
import Hooks from 'schemahandler/hooks/hooks'
const hooks = new Hooks();
import dayjs from 'dayjs';
import traverse from 'traverse';
import { toISOString } from "@/lib/utils";

const SEPARATOR = '+-*/'
hooks.on('mapCol', function (r) {
  if (_.startsWith(r, '@date')) {
    const _r = r.split(':')[1];
    const format = r.split(':')[0].slice(4);
    let _format = _.get(format.match(/\[(.*)]/), 1);
    this.ok = true;
    this.value = {
      label: r,
      fn: i => {
        const result = _.get(i, _r);
        if (result) {
          if (_format) return dayjs.unix(result).format(_format);
          return toISOString(result);
        }
      }
    }
  }
})

function reducerMap(r) {
  if (typeof r === 'string') {
    if (_.startsWith(r, '@sum')) {
      const prop = r.split(':')[1];
      const _round = r.split(':')[0].slice(4);
      let roundNumber = parseInt(_.get(_round.match(/\[(.*)]/), 1));
      return {
        fn: (sum, i) => sum + _.get(i, prop, 0),
        label: prop,
        ..._round.includes('[') && {
          format(n) {
            return _.round(n, roundNumber);
          }
        }
      }
    } else if (_.startsWith(r, '@objSum')) {
      const prop = r.split(':')[1];
      const _round = r.split(':')[0].slice(7);
      let roundNumber = parseInt(_.get(_round.match(/\[(.*)\]/), 1));
      let customHook = _.get(_round.match(/\((.*)\)/), 1);
      return {
        fn: (result, obj) => {
          const _obj = obj[prop] || {};
          for (const key of Object.keys(_obj)) {
            if (typeof _obj[key] === 'object') {
              if (!result[key]) result[key] = {};
              const current = result[key];
              traverse(_obj[key]).forEach(function (node) {
                if (this.isLeaf) {
                  if (typeof node === 'number') {
                    if (customHook) {
                      const result = hooks.emit(customHook, this, node, current);
                      if (result) return;
                    }
                    const currentValue = _.get(current, this.path.join('.'), 0);
                    let val = node + currentValue;
                    if (_round) val = _.round(val, roundNumber);
                    _.setWith(current, this.path.join('.'), val, Object);
                  }
                }
              });
              continue;
            }
            if (typeof _obj[key] === "number") {
              if (!result[key]) result[key] = 0;
              result[key] += _obj[key];
            }
          }
          return result;
        },
        label: prop,
        initValue: {},
        ..._round.includes('[') && {
          format(obj) {
            const _obj = {}
            for (const key of Object.keys(obj)) {
              if (typeof obj[key] === 'number') {
                _obj[key] = _.round(obj[key], roundNumber)
              } else {
                _obj[key] = obj[key]
              }
            }
            return _obj
          }
        }
      }
    } else if (_.startsWith(r, '@mergeArr')) {
      const prop = r.split(':')[1];
      return {
        fn: (arr, i) => {
          arr.push(...(i[prop] || []));
          return arr;
        },
        label: prop,
        initValue: [],
      }
    }
  }
  return r;
}

/**
 *
 * @param pivot
 * @param items
 * @returns {*}
 * @example
 * {
 *  rows: ["tax"],
 *  columns: [
 *    {
 *      label: "takeAway",
 *      fn: i => (i.takeAway ? "takeAway" : "dineIn")
 *    }
 *  ],
 *  reducers: [
 *      "@sum[2]:vSum",
 *      {
 *        label: "from",
 *        fn(_from, order) {
 *          if (_from === undefined) return order.date;
 *          if (_from > order.date) return order.date;
 *          return _from;
 *        },
 *        initValue: "undefined"
 *      }
 *    ]
 * }
 *
 * initValue default : 0
 *
 */
export function renderPivotTable(pivot, items) {
  const result = {data: items};

  const reducers = pivot.reducers.map(reducerMap);

  result.reducer = reducers[0];
  result.reducers = reducers;

  result.reducerType = pivot.reducers[0].resultType;

  const mapCol = r => {
    const {ok, value} = hooks.emit('mapCol', r);
    if (ok) return value;
    if (typeof r === 'string') {
      return {
        label: r,
        fn: i => _.get(i, r)
      }
    } else {
      return r;
    }
  }
  result.rowFields = (pivot.rows || []).map(mapCol);
  result.colFields = (pivot.columns || []).map(mapCol);
  if (pivot.filter) {
    result.filter = pivot.filter;
  }

  let fields = result.colFields.concat(result.rowFields);
  if (result.filter) fields = result.filter.concat(fields);

  let jsonData = _(items).groupBy(item => {
    const arr = [];
    for (const field of fields) {
      arr.push(field.fn(item));
    }
    return arr.join(SEPARATOR);
  }).mapValues(items => {
    const _result = result.reducers.reduce((obj, reducer) => {
      const initValue = _.cloneDeep(reducer.initValue ? eval(reducer.initValue) : 0);
      let reduceResult = _.reduce(items, reducer.fn, initValue);
      if (reducer.format) reduceResult = reducer.format(reduceResult)
      return _.assign(obj, {[reducer.label]: reduceResult});
    }, {});
    if (result.reducers.length === 1) return _result[result.reducers[0].label];
    return _result;
  }).thru(groups => {
    const res = {};
    for (const k of Object.keys(groups)) {
      if (result.reducerType === 'array' && groups[k].length === 0) continue;
      _.setWith(res, k.split(SEPARATOR).map(c => c.replaceAll('"', "'")), groups[k], Object);
    }
    return res;
  }).value();
  if (fields.length === 0) jsonData = jsonData[''];

  return jsonData;
}

export { hooks };

/*const items = [
  {name: 'A1', price: 10, quantity: 1, tax: 7, group: 'G1', takeAway: true},
  {name: 'A2', price: 10, quantity: 1, tax: 19, group: 'G2', takeAway: true},
  {name: 'A3', price: 10, quantity: 1, tax: 7, group: 'G1', takeAway: true},
  {name: 'A4', price: 10, quantity: 1, tax: 7, group: 'G2', takeAway: true},
  {name: 'A5', price: 10, quantity: 1, tax: 7, group: 'G1'},
  {name: 'A6', price: 10, quantity: 1, tax: 19, group: 'G2'},
  {name: 'A7', price: 10, quantity: 1, tax: 19, group: 'G1'},
  {name: 'A8', price: 10, quantity: 1, tax: 19, group: 'G2'},
  {name: 'A9', price: 10, quantity: 1, tax: 7, group: 'G1'}
]

const pivot = {
  rows: [{label: 'tax', getter: i => i.tax}],
  columns: [{label: 'group', getter: i => i.group}],
  reducers: [{fn: (sum, i) => sum + i.quantity, name: 'quantity'}]
}
const result = renderPivotTable(pivot, items);*/
