import { FunctionComponent } from "react";
import TextField from '@/react/core/TextField.tsx';
import _ from 'lodash';
import { categoryLayout0, orderLayout0, PRODUCT_COLORS } from '@/react/EditMenuView/EditMenuView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import {LL0} from "@/react/core/I18nService.tsx";

const EditCategoryForm: FunctionComponent = () => {
  return (
    <div className="!flex self-stretch hidden flex-col items-start justify-start py-4 px-3 gap-[16px] text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish Tablet_600:gap-[12px]">
      <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
        <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
          <span>{LL0().article.name()}</span>
          <span className="text-firebrick">*</span>
          <span>:</span>
        </b>
        <TextField
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={categoryLayout0()?.name}
          onChange={e => _.assign(categoryLayout0(), { name: e.target.value })}
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
        <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
          {LL0().restaurant.color()}:
        </b>
        <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[12px]">
          {PRODUCT_COLORS.map((color, index) =>
            <div className="rounded-981xl flex flex-row flex-wrap items-start justify-start relative"
                 key={index}
                 style={{backgroundColor: color}}
                 onClick={() => { _.assign(categoryLayout0(), { color }) }}
            >
              <div className="relative rounded-981xl box-border w-6 h-6 z-[0] border-[1px] border-solid border-gray-solid-gray-320-d2d2d2" />
              { categoryLayout0()?.color === color &&
                <>
                  <div className="absolute my-0 mx-[!important] top-[-0.1px] left-[0px] rounded-981xl box-border w-6 h-6 z-[1] border-[1px] border-solid border-blue-500" />
                  <img
                    className="absolute my-0 mx-[!important] top-[0.1px] right-[-4px] w-3 h-3 object-contain z-[2]"
                    alt=""
                    src="/iconchecked-icon1@2x.png"
                  />
                </>
              }
            </div>
          )}
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-center gap-[12px] text-black-solid-black-900-1e1e23">
        <b className="relative text-blue-solid-blue-500-0051c1 md:text-xs">
          {LL0().restaurant.menuEdit.setTheNumberOfMainCourse()}:
        </b>
        <b className="relative md:text-xs">{LL0().restaurant.menuEdit.rows()}:</b>
        <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-black-solid-black-600-424242 border-[1px] border-solid border-gainsboro">
          <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 if (orderLayout0()?.rows === 1) return;
                 _.assign(orderLayout0(), { rows: (orderLayout0()?.rows || 0) - 1 });
               }}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconsteper-minus@2x.png"
            />
          </div>
          <b className="relative md:text-xs">{orderLayout0()?.rows || 0}</b>
          <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 _.assign(orderLayout0(), { rows: (orderLayout0()?.rows || 0) + 1 });
               }}>
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconstepper-plus@2x.png"
            />
          </div>
        </div>
        <b className="relative md:text-xs">{LL0().restaurant.menuEdit.columns()}:</b>
        <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-black-solid-black-600-424242 border-[1px] border-solid border-gainsboro">
          <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 _.assign(orderLayout0(), { columns: (orderLayout0()?.columns || 0) - 1 });
               }}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconsteper-minus@2x.png"
            />
          </div>
          <b className="relative md:text-xs">{orderLayout0()?.columns || 0}</b>
          <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 _.assign(orderLayout0(), { columns: (orderLayout0()?.columns || 0) + 1 });
               }}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconstepper-plus@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-center gap-[12px] text-black-solid-black-900-1e1e23">
        <b className="relative text-blue-solid-blue-500-0051c1 md:text-xs">
          {LL0().restaurant.menuEdit.setTheNumberOfSubSections()}:
        </b>
        <b className="relative md:text-xs">{LL0().restaurant.menuEdit.rows()}:</b>
        <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-black-solid-black-600-424242 border-[1px] border-solid border-gainsboro">
          <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 if (categoryLayout0()?.rows === 1) return;
                 _.assign(categoryLayout0(), { rows: (categoryLayout0()?.rows || 0) - 1 });
               }}
          >
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconsteper-minus@2x.png"
            />
          </div>
          <b className="relative md:text-xs">{categoryLayout0()?.rows || 0}</b>
          <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 _.assign(categoryLayout0(), { rows: (categoryLayout0()?.rows || 0) + 1 });
               }}
          >
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconstepper-plus@2x.png"
            />
          </div>
        </div>
        <b className="relative md:text-xs">{LL0().restaurant.menuEdit.columns()}:</b>
        <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-black-solid-black-600-424242 border-[1px] border-solid border-gainsboro">
          <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 if (categoryLayout0()?.columns === 1) return;
                 _.assign(categoryLayout0(), { columns: (categoryLayout0()?.columns || 0) - 1 });
               }}
          >
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconsteper-minus@2x.png"
            />
          </div>
          <b className="relative md:text-xs">{categoryLayout0()?.columns || 0}</b>
          <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
               ref={makeRipple}
               onPointerDown={() => {
                 _.assign(categoryLayout0(), { columns: (categoryLayout0()?.columns || 0) + 1 });
               }}
          >
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconstepper-plus@2x.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategoryForm;
