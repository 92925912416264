import {type FunctionComponent, useEffect } from "react";
import NewDeliveryUpperBar from "./NewDeliveryUpperBar";
import NewDeliveryBottomBar from "./NewDeliveryBottomBar";

const NewDeliveryPlugin: FunctionComponent = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div
      className="new-delivery-screen !opacity-100 w-full h-full rounded-md overflow-hidden flex flex-col items-start justify-start bg-[url('/new-delivery-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0]"
      data-animate-on-scroll
    >
      <NewDeliveryUpperBar />
      <NewDeliveryBottomBar />
    </div>
  );
};

export default NewDeliveryPlugin;
