import type { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxDatabase, RxDocument, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const TX_LOG_COLLECTION_NAME = 'tx_log'

export const VERSION = 2
const txLogSchema = {
  title: 'txLog schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    type: { type: 'string', maxLength: 50 },
    orderId: { type: 'string' },
    terminalId: { type: 'string' },
    metadata: { type: 'object', properties: {} },
  },
  indexes: [],
  required: ['_id', 'type', 'orderId', 'terminalId'],
} as const satisfies RxJsonSchema<any>

export interface TxLog extends ExtractDocumentTypeFromTypedRxJsonSchema<typeof txLogSchema> {}

export type TxLogDocument = RxDocument<TxLog>
export const TxLogCollection: RxCollection<TxLog> = {} as RxCollection<TxLog>

export const createTxCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TxLogCollection,
    collectionName: TX_LOG_COLLECTION_NAME,
    version: VERSION,
    schema: txLogSchema,
  });
}

//@ts-ignore
window.TxLogCollection = TxLogCollection