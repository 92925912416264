import { FunctionComponent, useState, useCallback } from "react";
import {
  Switch,
  FormControlLabel,
} from "@mui/material";
import EmailReportEmailReportHi from "./EmailReportEmailReportHistory";
import PortalPopup from "./PortalPopup";
import {LL0} from "@/react/core/I18nService.tsx";
import TextField from '@/react/core/TextField.tsx'

const EmailReportBody: FunctionComponent = () => {
  const [isEmailReportEmailReportHiOpen, setEmailReportEmailReportHiOpen] =
    useState(false);

  const openEmailReportEmailReportHi = useCallback(() => {
    setEmailReportEmailReportHiOpen(true);
  }, []);

  const closeEmailReportEmailReportHi = useCallback(() => {
    setEmailReportEmailReportHiOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-6 pb-4 gap-[12px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
          <div className="relative font-semibold md:text-xs mq1440:text-mini">
            {LL0().settings.emailReport.emailAddresses()}
          </div>
          <TextField
            className="flex-1"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
          />
        </div>
        <FormControlLabel
          className="self-stretch"
          label={LL0().settings.emailReport.automatiReceiveDailyZReport()}
          control={<Switch color="primary" />}
        />
        <div className="self-stretch relative bg-gray-solid-gray-300-dbdbdb h-px shrink-0" />
        <FormControlLabel
          className="self-stretch"
          label={LL0().settings.emailReport.automaticReceiveWeeklyReport()}
          control={<Switch color="primary" />}
        />
        <FormControlLabel
          className="self-stretch"
          label={LL0().settings.emailReport.automaticReceiveBiweeklyReport()}
          control={<Switch color="primary" />}
        />
        <div className="self-stretch relative bg-gray-solid-gray-300-dbdbdb h-px shrink-0" />
        <FormControlLabel
          className="self-stretch"
          label={LL0().settings.emailReport.automaticReceiveMonthlyReport()}
          control={<Switch color="primary" />}
        />
        <div
          className="rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-3 box-border cursor-pointer text-center text-white height_414:h-[30px] mq1440:h-10"
          onClick={openEmailReportEmailReportHi}
        >
          <b className="relative mq1440:text-mini">{LL0().settings.emailReport.viewEmailHistory()}</b>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
          <b className="self-stretch relative mq1440:text-mini">{LL0().settings.emailReport.note()}:</b>
          <div className="self-stretch relative text-inherit font-inherit text-black-solid-black-1000-000000 mq1440:text-mini">
            <ul className="m-0 pl-[19px]">
              <li className="mb-0">
                {LL0().settings.emailReport.reportsAreSentToYourEmailsWhenAppLaunch()}
              </li>
              <li>{LL0().settings.emailReport.multipleEmailsAreSeparatedBy()}</li>
            </ul>
          </div>
        </div>
      </div>
      {isEmailReportEmailReportHiOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEmailReportEmailReportHi}
        >
          <EmailReportEmailReportHi onClose={closeEmailReportEmailReportHi} />
        </PortalPopup>
      )}
    </>
  );
};

export default EmailReportBody;
