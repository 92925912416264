import _ from "lodash";
import { CSSProperties, FC, HTMLAttributes, ReactElement, ReactNode, useMemo } from "react";

export interface GridElementProps {
	x: number,
	y: number,
	cols: number,
	rows: number,
	className?: string;
	style?: CSSProperties
	content?: (styles: CSSProperties) => ReactNode;
}

const GridElement: FC< Omit<HTMLAttributes<any>, 'content'> & GridElementProps> = (props) => {
	const styles = {
		"display": "flex",
		"gridColumnStart": props.x + 1,
		"gridColumnEnd": props.x + props.cols + 1,
		"gridRowStart": props.y + 1,
		"gridRowEnd": props.y + props.rows + 1
	};
	// removed useMemo for the case we need to adjust x, y, width, height (keyboard config)
	// TODO: Optimize this later?
	const styles1 = props.style ? _.merge({}, styles, props.style) : styles;
	if (props.content) {
		return <>{props.content(styles1)}</>;
	}
	return <div style={styles1} className={props.className}
							onPointerDown={props.onPointerDown}
							onPointerUp={props.onPointerUp}
							onClick={props.onClick}
	>{props.children}</div>;
};

export default GridElement;
