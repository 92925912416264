import debug from 'debug'

import type { TOrder } from '@/pos/OrderType'
import defaultTaxesByCity from '@/react/PaymentSettingView/defaultTaxesByCity.json'
import { roundNumber } from '@/shared/order/order-config'

const log = debug('dev:PendingOrderLogic')

/**
 * Fix discount.
 *
 * This will convert the discount value to back percentage, so it can be recalculated later.
 */
export function recalculateDiscount(order: TOrder) {
  if (order.discount) {
    const { discount = 0 } = order
    const total = Number(order.metadata?.subTotal) // subTotal is the total before tax & discount
    if (typeof discount === 'number' && discount > 0) {
      const discountPercent = roundNumber((discount / total) * 100)
      order.discount = `${discountPercent}%`
    } else {
      log('⚠️ cannot fix discount', discount)
    }
  }
}

/**
 * Fix tax. Add default tax components for Canada-Quebec. Will modify the order in place.
 */
export function applyQuebecTaxComponents(order: TOrder) {
  const defaultTax = defaultTaxesByCity['Canada-Quebec'][0]
  for (const item of order.items) {
    item.taxComponents = defaultTax.components
    item.taxComponents2 = defaultTax.components
  }
  delete order.taxTotal // remove taxTotal to recalculate later
}

/** Check and fix payment. If it differs by 0.01, then it's okay. */
export function checkPaymentConsistency(order: TOrder) {
  const payment = order.payments[0]
  const total = order.vSum
  if (payment && total) {
    if (Math.abs(payment.value - total) <= 0.01) {
      log('⚡️ Payment value differ by 0.01 or less', payment.value, total)
    } else {
      log('⚠️ Payment value not match', payment.value, total)
    }
  }
}
