import { MenuItem, Select } from '@mui/material'

import type { PosSrmSetting } from '@/data/PosSetting'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { computed, deepSignal } from '@/react/core/reactive'
import TextField from '@/react/core/TextField.tsx'

const srmSetting = computed(() => posSetting0()?.srm)

function onChanged(key: keyof PosSrmSetting, val: string) {
  const currentVal = srmSetting()?.[key]
  const posSetting = posSetting0()
  if (val !== currentVal && !!posSetting) {
    let srm = posSetting.srm
    if (!srm) srm = deepSignal({})

    if (key === 'env') {
      if (val === 'ESSAI' || val === 'PROD') srm[key] = val
      else srm[key] = 'DEV'
    } else srm[key] = val

    posSetting.srm = srm
  }
}

export default function SrmSettings() {
  return (
    <div
      className="grid grid-cols-2 gap-2 items-center"
      data-testid="srmSettings"
    >
      <label>Product Identifier</label>
      <TextField
        value={srmSetting()?.productId ?? ''}
        onChange={e => onChanged('productId', e.target.value)}
      />
      <label>Product Version Identifier</label>
      <TextField
        value={srmSetting()?.productVersionId ?? ''}
        onChange={e => onChanged('productVersionId', e.target.value)}
      />
      <label>Partner Identifier</label>
      <TextField
        value={srmSetting()?.partnerId ?? ''}
        onChange={e => onChanged('partnerId', e.target.value)}
      />
      <label>Certificate Code</label>
      <TextField
        value={srmSetting()?.certificateCode ?? ''}
        onChange={e => onChanged('certificateCode', e.target.value)}
      />
      <label>Version</label>
      <TextField
        value={srmSetting()?.version ?? ''}
        onChange={e => onChanged('version', e.target.value)}
      />
      <label>Previous Version</label>
      <TextField
        value={srmSetting()?.previousVersion ?? ''}
        onChange={e => onChanged('previousVersion', e.target.value)}
      />
      <hr className="col-span-2" />

      <label className="text-red-700">Testcase Number</label>
      <TextField
        value={srmSetting()?.testcaseNumber ?? ''}
        onChange={e => onChanged('testcaseNumber', e.target.value)}
      />
      <label className="text-red-700">Environment</label>
      <Select
        size="small"
        value={srmSetting()?.env ?? 'DEV'}
        onChange={e => onChanged('env', e.target.value)}
      >
        <MenuItem value="DEV">🪲 DEV</MenuItem>
        <MenuItem value="ESSAI">🧪 ESSAI</MenuItem>
        <MenuItem value="PROD">🔒 PROD</MenuItem>
      </Select>
    </div>
  )
}
