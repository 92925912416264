import dayjs from 'dayjs'
import debug from 'debug'
import { deepSignal, type DeepSignal } from 'deepsignal/react'
import { clone } from 'json-fn'
import _ from 'lodash'
import { isRxDocument, type MangoQuery, type MangoQuerySelector, type RxCollection, type RxDocument } from 'rxdb'

import { collectionsMap } from '@/data/CollectionsMap.ts'
import type { PosDatabase, PosDatabase2, PosDatabase3 } from '@/data/types.d.ts'
import { effectOn, signal } from '@/react/core/reactive'

import { SyncMode } from './data-enum'

const log = debug('data:auto-save')

export type DocMutable<T, V = {}> = T & {
  doc: RxDocument<T, V>
}

export type DocDeepSignal<T, V = {}> = DeepSignal<
  T & {
    get doc(): RxDocument<T, V>
  }
>

interface ConvertOptions<T> {
  debounce?: number
  preSaved?: (doc: T) => T
  onSaved?: Function
  collection?: RxCollection
}

export function convertDocuments<T, V = {}>(docs: Array<RxDocument<T, V>>, autoSave: boolean, omitList: string[] = [], options: ConvertOptions<T> = {}): Array<DocDeepSignal<T, V>> {
  const results = docs.map(doc => {
    // const result = mutable<DocMutable<T, V>>({
    // 	..._.defaults(doc.toMutableJSON(), patch ? clone(patch) : undefined), get doc() {
    // 		return doc;
    // 	}
    // });
    const result = deepSignal({
      ...(doc.toMutableJSON?.() || _.cloneDeep(doc)),
      get doc() {
        return doc
      },
    }) as DocDeepSignal<T, V>

    if (autoSave) {
      const save = _.debounce(
        async () => {
          log(`💾 Saving %c${doc.collection?.name || options.collection?.name}%c data...`, 'color:blue', 'color:initial')
          let _clone = clone(_.omit(result, ['doc', ...omitList]), false) as any
          if (options.preSaved) {
            _clone = options.preSaved(_clone)
          }
          _clone.doc = undefined
          if (isRxDocument(doc)) {
            await doc.incrementalPatch(_clone)
          } else {
            await options.collection?.incrementalUpsert(_clone)
          }
          options.onSaved && options.onSaved(_clone)
        },
        options.debounce || 0,
        { leading: true, trailing: true }
      )
      effectOn(
        [() => JSON.stringify(_.omit(result, ['doc', ...omitList]))],
        async () => {
          await save()
        },
        { defer: true }
      )
    }
    return result
  })
  return results
}

export const convertDocument = <T, V = {}>(doc: RxDocument<T, V>, autoSave: boolean, omitList: string[] = [], options: ConvertOptions<T> = {}) => {
  return convertDocuments([doc], autoSave, omitList, options)[0]
}

export const convertRawDocuments = <T extends { _id: string }>(docs: T[], collection: RxCollection<T>, omitList: string[] = [], options: ConvertOptions<T> = {}): Array<T> => {
  const results = docs.map(doc => {
    const result = deepSignal(doc) as DeepSignal<T>

    const save = _.debounce(
      async () => {
        log(`💾 Saving %c${collection.name}%c data...`, 'color:blue', 'color:initial')
        const _clone = clone(_.omit(result, [...omitList]) as unknown as T)
        const _doc = await collection.findOne({ selector: { _id: result._id } as MangoQuerySelector<T> }).exec()
        await _doc?.incrementalPatch(_clone)
      },
      options.debounce || 0,
      { leading: true, trailing: true }
    )

    effectOn(
      [() => JSON.stringify(_.omit(result, ['doc', ...omitList]))],
      async () => {
        await save()
      },
      { defer: true }
    )

    return doc
  })
  return results
}

export const convertRawDocument = <T extends Object & { _id: string }>(doc: T, collection: RxCollection, omitList: string[] = []) => {
  return convertRawDocuments([doc], collection, omitList)[0]
}

export async function deleteMany<T extends { _id: string }, V>(col: RxCollection<T, V>, query: MangoQuery<T>, needCleanUp = false) {
  const results = await col.find(query).exec()
  for (const result of results) {
    await result.remove()
  }
  // await col.bulkRemove(results.map(r => r._id));
  if (needCleanUp) {
    await col.cleanup(0)
    col._queryCache._map.clear()
    col._docCache.cacheItemByDocId.clear()
  }
}

Object.assign(window, { deleteMany }) // Expose for debugging

export async function updateMany(col: RxCollection, query: MangoQuery, patch: Object) {
  const docs = await col.find(query).exec()
  for (const doc of docs) {
    await doc.incrementalPatch(patch)
  }
}

export const database: { v?: PosDatabase } = {}
export const database2: { v?: PosDatabase2 } = {}
export const database3: { v?: PosDatabase3 } = {}

export const dbsList = [database, database2, database3]

export const [dbReady, setDbReady] = signal(false)

export const [configMode, setConfigMode] = signal<SyncMode>(SyncMode.mixed)

export const [masterAvailable, setMasterAvailable] = signal<boolean>(true)

Object.assign(window, { setConfigMode }) // Expose for debugging

export async function deleteCreatedMenuUser() {
  for (const collectionItem of collectionsMap()) {
    if (collectionItem.menuUserCreated) {
      await deleteMany(collectionItem.collection, {}, true)
    }
  }
}

export const d2 = function (date: number) {
  return dayjs.unix(date).format('DD.MM HH:mm')
}

export const d3 = function <T, M>(docs: RxDocument<T, M>[]) {
  return convertDocuments(docs, true)
}

Object.assign(window, { d2 })
Object.assign(window, { d3 })
