import {memo} from 'react';
import {ItemFactoryContext, useItemFactory} from '../PaymentView/OrderItemsFactory2';
import NewDeliveryPlugin from '@new-delivery/NewDeliveryPlugin.tsx';
import { onEnter, params, PosScreen, router, setParams } from '@/pos/PosRouter.ts';
import {createOrder, stripOrder} from '@/pos/logic/order-reactive.ts';
import {deepSignal, signal} from '@/react/core/reactive.ts';
import {
  createNewCustomer,
  findCustomer,
  getExistedCustomerId,
  initCustomer
} from '@/react/CustomerInfoView/CustomerInfoView.tsx';
import {clone} from 'json-fn';
import NewDeliveryAddEditCustomer from '@new-delivery/NewDeliveryAddEditCustomer.tsx';
import {convertDocument, type DocDeepSignal} from '@/data/data-utils.ts';
import NewDeliveryIncomingCall from '@new-delivery/NewDeliveryIncomingCall.tsx';
import {Call, MissedCall} from '@/data/Call.ts';
import uuid from 'time-uuid';
import dayjs from 'dayjs';
import Debug from 'debug';
import {calls0} from '@/data/CallHub.ts';
import {Customer} from '@/data/Customer.ts';
import {type RxDocument} from 'rxdb';
import {CommitAction, MarketPlaceProvider, OrderStatus, OrderType} from '@/pos/OrderType.ts';
import ReservationNewEditPlugin from "@reservation2/ReservationNewEditPlugin.tsx"
import {loginUsers} from '@/data/UserHub.ts';
import {printKitchen} from '@/react/Printer/print-kitchen.ts';
import {assignZ} from '@/data/OrderHub.ts';
import {maxId0} from '@/data/MaxIdHub.ts';
import {onPrintTse} from '@/tse/tse-init.ts';
import {OnlineOrder} from '@/data/OnlineOrder.ts';
import dialogService from "@/react/SystemService/dialogService.tsx";
import {order0, setOrder0} from "@/react/OrderView/OrderViewShare";
import _ from "lodash";
import { makeModifiersAvailable } from '@/data/ModifierHub';
import { isQuebecSrmEnabled, mainScreen } from "@/data/PosSettingsSignal.ts";
import { ensureValidItemNameLength } from "@/srm/lib/utils.ts";
import { toast } from "react-toastify";

const debug = Debug("calls")

export const newDeliveryContext = useItemFactory(order0, setOrder0, () => null);
export const [customer0, setCustomer0] = signal<DocDeepSignal<Customer> | undefined>();

export enum NewDeliveryScreenMode {
  CUSTOMER,
  ORDER,
}
export const [newDeliveryScreenMode, setNewDeliveryScreenMode] = signal<NewDeliveryScreenMode>(NewDeliveryScreenMode.CUSTOMER)

export const handleSaveCustomer = async () => {
  const _customer = customer0()
  if (!_customer) return
  // if not created
  if (!_customer.doc) {
    const newCustomer = await createNewCustomer(clone(_customer));
    setCustomer0(convertDocument(newCustomer, false));
  } else {
    //patch customer
    _customer.doc.incrementalPatch(_.omit(clone(_customer), ["doc"]));
  }
  setNewDeliveryScreenMode(NewDeliveryScreenMode.ORDER);
}

export const onEnterNewDelivery = () => {
  if (!customer0()?.doc) {
    // @ts-ignore
    setCustomer0(deepSignal(clone(initCustomer())))
  }
  setNewDeliveryScreenMode(NewDeliveryScreenMode.CUSTOMER);
  setOrder0(createOrder())
  order0().commits?.push({
    action: CommitAction.SET_ORDER_TYPE,
    orderType: params()?.orderType || OrderType.Delivery
  })
  order0().commits?.push({
    action: CommitAction.SET_PROVIDER_TYPE,
    provider: MarketPlaceProvider.PHONE
  })
}

// pass undefined customer to create new customer
export const onEnterNewDeliveryWithCustomer = (customer?: RxDocument<Customer, {}>, orderType?: OrderType) => {
  //to reset customer
  setCustomer0(customer ? convertDocument(customer, false) : undefined);
  setParams({ orderType });
  router.screen = PosScreen.NEW_DELIVERY;
  onEnterNewDelivery()
}

// call handlers

const CALL_TIMEOUT = 30 * 1000 // 30s
const [phoneCallMap, setPhoneCallMap] = signal<Record<string, any>>({})

export const handleIncomingCall = async (phoneNumber?: string, customerId?: string) => {
  if (!phoneNumber) {
    debug("null phone number");
    return Promise.reject("Phone cannot be null")
  }

  const foundCustomerId = customerId ||
    (await findCustomer(undefined, phoneNumber))?.[0]?._id ||
    (await createNewCustomer({...initCustomer(),
      phoneNumber,
      name: "",
    }))?._id
  const callId = uuid();
  const incomingCall = await Call.insert({
    _id: callId, date: dayjs().unix(),
    customerId: foundCustomerId, phoneNumber
  })

  const onAutoHide = (onClose?: () => void) => {
    //TODO: integrate w real call, setInterval?
    phoneCallMap()[callId] = setTimeout(() => {
      if (!phoneCallMap()[callId]) return;
      const missedCall = calls0().find(call => call._id === callId);
      if (!missedCall) return;
      missedCall?.incrementalRemove();
      MissedCall.insert({
        _id: missedCall?._id, date: missedCall?.date, customerId: missedCall?.customerId
      })
      onClose?.();
      debug('missed call', phoneNumber, callId)
    }, CALL_TIMEOUT);
  }

  if (router.screen === PosScreen.PENDING_ORDERS) {
    onAutoHide();
    return Promise.resolve("Displayed on screen")
  }
  dialogService.show({component: NewDeliveryIncomingCall, attrs: {
      className: "w-fit h-fit fixed bottom-5 right-5"
    }, bind:{
      onClickOrder: onAcceptOrderCall,
      onAutoHide,
      incomingCall
    }}).then()
  return Promise.resolve("Created new call")
}

export const onAcceptCall = (call: RxDocument<Call, {}>) => {
  const callId = call?._id;
  if (!callId) return;
  if (phoneCallMap()[callId]) {
    delete phoneCallMap()[callId];
  }
  call?.incrementalRemove();
}

export const onAcceptOrderCall = (call: RxDocument<Call, {}>, customer?: RxDocument<Customer, {}>, orderType?: OrderType) => {
  onAcceptCall(call);
  onEnterNewDeliveryWithCustomer(customer, orderType)
}

export const onAcceptReservationCall = async (call: RxDocument<Call, {}>, customer?: RxDocument<Customer, {}>) => {
  onAcceptCall(call);
  await dialogService.show({
    component: ReservationNewEditPlugin,
    isLocofyPopup: true,
  })
}

export const createMockCall = async (withExistedCustomer?: boolean) => {
  const phoneNumber = Math.floor((Math.random() * 1000000000)).toString();
  const customerId = await getExistedCustomerId(withExistedCustomer);
  await handleIncomingCall(phoneNumber, customerId);
}

export const createMockMissedCall = async () => {
  MissedCall.insert({
    _id: uuid(), date: dayjs().unix(),
    customerId: await getExistedCustomerId(),
  })
}

export const supportMockCall = () => {
  // @ts-ignore
  window.__dev = window.__dev || {};
  // @ts-ignore
  window.__dev.mockCall = createMockCall;
  // @ts-ignore
  window.__dev.mockMissedCall = createMockMissedCall;
  // @ts-ignore
  window.__dev.handleIncomingCall = handleIncomingCall;
}

export const onPrintDeliveryOrder = async () => {
  //TODO: Integrate this w date choosing popup
  const PREPARE_TIME_IN_MINUTES = 30;
  if (!ensureValidItemNameLength(order0()?.items)) return toast.error('Item with name or modifier name less than 2 digit is not allowed!!!')
  const isPickUpOrder = order0().type === OrderType.PickUp
  const customerExpectDt = (
    isPickUpOrder
      ? order0().pickupDate === 'asap' ? dayjs() : dayjs(order0().pickupDate)
      : order0().dropOffDate === 'asap' ? dayjs() : dayjs(order0().dropOffDate)
  );

  const _customer = customer0();

  if (!_customer) return;

  order0().note = _customer.note;
  order0().customer = _customer._id;
  order0().customerRaw = {
    name: _customer.name || "",
    phone: _customer.phoneNumber || "",
    address: _customer.addresses?.[_customer.defaultAddressIndex || 0].street,
    placeId: _customer.addresses?.[_customer.defaultAddressIndex || 0].placeId,
    note: _customer.note,
    email: _customer.email
  };

  const storeExpectDt = customerExpectDt.add(PREPARE_TIME_IN_MINUTES, 'minute')

  order0().status = OrderStatus.ACCEPTED;
  order0().users = loginUsers();
  printKitchen(order0(), false).then();
  await assignZ(order0());
  order0().id = maxId0().orderId!;
  await onPrintTse(order0());
  order0().commits?.push({ action: CommitAction.PRINT });
  await OnlineOrder.upsert(Object.assign(stripOrder(order0()),{
      pickupDate: isPickUpOrder ? storeExpectDt.toISOString() : dayjs(order0().pickupDate).toISOString(),
      dropOffDate: isPickUpOrder ? dayjs(order0().dropOffDate).toISOString() : storeExpectDt.toISOString()
    }
  ));

  //redirect to mainScreen()
  router.screen = mainScreen();
}

const NewDeliveryView = () => {
  onEnter(PosScreen.NEW_DELIVERY, () => {
    onEnterNewDelivery();
  })
  makeModifiersAvailable()

  return (
    <ItemFactoryContext.Provider value={{...newDeliveryContext, containerClassName: "!p-0 !border-0"}}>
      { newDeliveryScreenMode() === NewDeliveryScreenMode.ORDER ?
        <NewDeliveryPlugin />
        :
        <NewDeliveryAddEditCustomer />
      }
    </ItemFactoryContext.Provider>
  )
}

export default memo(NewDeliveryView)
