import { Chip } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import type { Paths } from 'rxdb'

import { SrmEventLog, SrmEvents } from '@/data/SrmEventLog'
import { makeSrmEventLogAvailable } from '@/data/SrmEventLogHub'
import { loginUser } from '@/data/UserSignal.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { computed } from '@/react/core/reactive'
import { useRxQuery } from '@/react/utils/useRxQuery'

import { isManager } from './srm.logic'

interface Column<T> {
  id: Paths<T>
  label: string
  minWidth?: number
  align?: 'right'
  format?: (v: string | number, row: T) => string | JSX.Element
}

const EventTypeMapper = {
  [SrmEvents.BACK_ONLINE]: 'Online',
  [SrmEvents.ENTER_OFFLINE]: 'Offline',
  [SrmEvents.EXPORT_DATA]: 'ExportData',
  [SrmEvents.WIPE_DATA]: 'WipeData',
  [SrmEvents.TRAINING_MODE_ON]: 'trainingModeOn',
  [SrmEvents.TRAINING_MODE_OFF]: 'trainingModeOff',
}

const columns: readonly Column<SrmEventLog>[] = [
  {
    id: 'type',
    label: 'type',
    minWidth: 120,
    format: (_, row) => (
      <Chip
        variant={'filled'}
        size="small"
        label={EventTypeMapper[row.type]}
        color={row.type === SrmEvents.BACK_ONLINE ? 'success' : row.type === SrmEvents.ENTER_OFFLINE ? 'warning' : 'default'}
      />
    ),
  },
  { id: 'date', label: 'date', minWidth: 100, format: v => dayjs.unix(+v).format('YYYY-MM-DD HH:mm:ss') },
  { id: 'user', label: 'user', minWidth: 120 },
]

const selector = computed(() =>
  isManager()
    ? {}
    : {
        selector: {
          user: { $eq: loginUser()?.name },
        },
      }
)

export default function EventTable() {
  makeSrmEventLogAvailable()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const pageData = useRxQuery(
    () =>
      SrmEventLog.find(selector())
        .sort({ date: 'desc' })
        .skip(page * rowsPerPage)
        .limit(rowsPerPage),
    [page, rowsPerPage, selector()]
  )
  const totalCount = useRxQuery(() => SrmEventLog.count(selector()), [selector()])

  const handleChangePage = useCallback((_: unknown, newPage: number) => {
    setPage(newPage)
  }, [])

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }, [])

  return !totalCount ? (
    <h1>{LL0().srm.srmView.noData()}</h1>
  ) : (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          size="small"
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  padding="normal"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {_.get(LL0().srm.srmView.table, column.label)?.()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData?.map(row => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={row._id}
                  onDoubleClick={() => console.log('ℹ️ Event', row.toJSON())}
                >
                  {columns.map(column => {
                    const value = _.get(row, column.id)
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                      >
                        {column.format ? column.format(value, row) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={LL0().srm.srmView.table.rowsPerPage()}
      />
    </Paper>
  )
}
