import type {FunctionComponent} from "react";
import { handleCancelOrder, order0 } from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import {LL0} from "@/react/core/I18nService.tsx";
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";

type OrderHistoryCancelConfirmatiType = {
  onClose?: () => void;
};

const OrderHistoryCancelConfirmati: FunctionComponent<
  OrderHistoryCancelConfirmatiType
> = ({ onClose }) => {

  const onCancelOrder = async () => {
    await handleCancelOrder();
    onClose?.()
  }

  return (
    <div className="relative rounded bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[24px] h-full max-w-full max-h-full text-center text-sm text-blue-solid-blue-420-2979ff font-mulish-button-name-bold">
      <div className="w-[420px] h-[23px] flex flex-row items-start justify-center gap-[2px] text-lg text-black-solid-black-900-1e1e23">
        <b className="relative">{LL0().orderHistory.doYouWantToCancelOrderNumber()}</b>
        <b className="relative text-red-solid-red-550-cf2222">{order0()?.id || "NA"}</b>
        <b className="relative">?</b>
      </div>
      <div className="flex flex-row items-center justify-center gap-[16px] text-mini">
        <div
          className="rounded-md bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-2 px-8 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
        >
          <div className="relative font-semibold">{LL0().ui.no()}</div>
        </div>
        <ButtonWrapper onClick={onCancelOrder} className={'rounded-md'}>
          <div
            className="rounded-md bg-blue-solid-blue-420-2979ff shrink-0 flex flex-row items-center justify-center py-2 px-8 cursor-pointer text-white-solid-white-100-ffffff border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          >
            <div className="relative font-semibold">{LL0().ui.yes()}</div>
          </div>
        </ButtonWrapper>
      </div>
      <div
        className="self-stretch rounded-md bg-white-solid-white-100-ffffff box-border h-[38px] overflow-hidden shrink-0 hidden flex-row items-center justify-center py-[9px] px-6 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
        onClick={onClose}
      >
        <b className="relative">Cancel</b>
      </div>
      <div className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff h-[37px] overflow-hidden shrink-0 hidden flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff">
        <b className="relative">OK</b>
      </div>
    </div>
  );
};

export default OrderHistoryCancelConfirmati;
