import type {FunctionComponent} from "react";
import { materialCategories } from '@/data/CategoryHub.ts'
import TextField from '@/react/core/TextField.tsx'
import { clsx } from 'clsx'
import { createNewCategory, deleteCategory, existCategoryMap } from '@/react/InventoryView/InventoryView.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import _ from 'lodash'
import {LL0} from "@/react/core/I18nService.tsx";

type InventoryAddEditCategoryType = {
  onClose?: () => void;
};

const InventoryAddEditCategory: FunctionComponent<
  InventoryAddEditCategoryType
> = ({ onClose }) => {
  return (
    <div className="relative rounded-md bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-end justify-start pt-8 px-3 pb-6 box-border gap-[13px] min-w-[414] text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[13px] hover:items-end hover:justify-start hover:rounded-md hover:pt-8 hover:px-3 hover:pb-6 hover:box-border hover:min-w-[414]">
      <b className="self-stretch relative z-[0]">{LL0().productViewSetting.categoryEditPopup.title()}</b>
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-start justify-between z-[1] text-xs text-black-solid-black-600-424242">
        <div className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start pt-2 px-0 pb-2 gap-[12px]">
          { materialCategories().map(category =>
            (<div className='self-stretch flex flex-row items-start justify-start gap-[4px]'
                  key={category._id}
            >
              <TextField
                type="string"
                className='flex-1 font-mulish text-sm text-black-solid-black-600-424242'
                color='primary'
                size='small'
                fullWidth={true}
                variant='outlined'
                value={category.name}
                onChange={e => _.assign(category, {name: e.target.value})}
              />
              <button
                className="bg-red-solid-red-240-eb5458 disabled:bg-gray-solid-gray-490-bdbdbd rounded-10xs w-[37px] h-[37px] flex flex-row items-center justify-center"
                disabled={existCategoryMap()[category._id]}
                onClick={async () => {
                  await deleteCategory(category)
                }}
                ref={makeRipple}
              >
                <img
                  className='relative w-[37px] h-[37px] object-cover'
                  alt=''
                  src='/iconbin-icon--white@2x.png'
                />
              </button>
            </div>)
          )}
        </div>
        <div className="self-stretch flex flex-row items-center justify-start pt-3 px-0 pb-0 gap-[8px]">
          <i className="flex-1 relative md:text-xs">
            *{LL0().inventory.onlyEmpty()}
          </i>
          <div className="flex flex-row items-center justify-start gap-[8px] text-center text-sm text-white-solid-white-100-ffffff">
            <div className="rounded-md bg-blue-solid-blue-420-2979ff w-[120px] h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center max-w-[120px] height_414:h-[30px]"
                 onPointerDown={createNewCategory}
                 ref={makeRipple}
            >
              <b className="relative">+ {LL0().inventory.filter.category()}</b>
            </div>
            <div
              className="rounded-md bg-green-solid-green-750-508c46 box-border w-[121px] h-[37px] overflow-hidden shrink-0 flex flex-row items-center justify-center max-w-[120px] cursor-pointer border-[1px] border-solid border-white-solid-white-100-ffffff height_414:h-[30px]"
              onClick={onClose}
              ref={makeRipple}
            >
              <b className="relative">{LL0().ui.save()}</b>
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-[21px] h-[21px] object-cover cursor-pointer z-[2]"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default InventoryAddEditCategory;
