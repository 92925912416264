import { type FunctionComponent, useCallback, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import TextField from '@/react/core/TextField.tsx';
import _ from 'lodash';
import {
  groupPrinters0,
  removeCurrentModifier,
  removeModifierItem,
  selectedItem,
  selectedModifier,
  setSelectedItem
} from '@/react/EditMenuView/EditMenuView.tsx';
import clsx from 'clsx';
import uuid from 'time-uuid';
import PortalPopup from '@edit-menu/Popups/PortalPopup.tsx';
import EditMenuDeleteConfirmatio from '@edit-menu/Popups/EditMenuDeleteConfirmatio.tsx';
import { LL0 } from '@/react/core/I18nService.tsx';
import {
  pricingLevelFactory,
} from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import { ModifierType } from "@/data/Modifier.ts";
import { customParseFloat } from "@/react/core/Input.tsx";


type EditMenuNewGroupPopupType = {
  type: ModifierType;
  onClose?: () => void;
};

const EditMenuNewGroupPopup: FunctionComponent<EditMenuNewGroupPopupType> = ({
  onClose,
  type
}) => {
  const [isEditMenuDeleteConfirmatioOpen, setEditMenuDeleteConfirmatioOpen] =
    useState(false);

  const openEditMenuDeleteConfirmatio = useCallback(() => {
    setEditMenuDeleteConfirmatioOpen(true);
  }, []);

  const closeEditMenuDeleteConfirmatio = useCallback(() => {
    setEditMenuDeleteConfirmatioOpen(false);
  }, []);

  const {
    computedModifierItemPrice,
    handleModifierItemPriceChange,
    } = pricingLevelFactory()

  const handleAddItem = (_name?: string, price?: number) => {
    const type = (selectedModifier()?.useType !== ModifierType.VARIANT) ? ModifierType.MODIFIER : ModifierType.VARIANT
    const name = _name || (type === ModifierType.MODIFIER ? 'Selection' : 'Variant')
    selectedModifier()?.items?.push({ name: name, price: price || 0, _id: uuid(), type: type })
    setSelectedItem(_.last(selectedModifier()?.items)!);
  }

    return (
    <>
      <div
        className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-end justify-start p-6 box-border gap-[12px] min-w-[520px] h-full max-w-full max-h-full text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish`}
      >
        <img
          className="w-6 relative h-6 object-cover cursor-pointer"
          alt=""
          src="/close-popup-icon--grey@2x.png"
          onClick={onClose}
        />
        <div className="self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[16px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
            <b className="relative md:text-xs">{LL0().printerSetting.groupPrinterName()}:</b>
            <TextField
              className="flex-1 font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              value={selectedModifier()?.name || ""}
              onChange={(e) => {
                _.assign(selectedModifier(), {name: e.target.value})
              }}
              sx={{ "& .MuiInputBase-root": { height: "37px" } }}
            />
          </div>
          <div
            className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-smi text-black-solid-black-1000-000000">
            {selectedModifier()?.items?.map((item, index) => (
              <div
                className={clsx("rounded bg-gray-solid-gray-150-eeeeee box-border h-10 overflow-hidden flex flex-row items-center justify-start py-0 pr-0 pl-1.5 relative gap-[8px] border-[1px] border-solid border-cornflowerblue",
                  selectedItem() === item && "[background:linear-gradient(180deg,_#27bbfb,_#3e69fe)]")}
                key={index}
                onPointerDown={() => {
                  setSelectedItem(item);
                }}
              >
                <b className="relative z-[1]">{item?.name}</b>
                <div
                  className="self-stretch bg-gray-solid-gray-150-eeeeee box-border w-10 flex flex-row items-center justify-center z-[2] border-l-[1px] border-solid border-cornflowerblue"
                  onPointerDown={() => removeModifierItem(selectedModifier(), index)}
                >
                  <img
                    className="relative w-[18px] h-[18px] object-cover"
                    alt=""
                  src="/icondelete-fillter-icon@2x.png"
                  />
                </div>
              </div>
            ))}
            <div
              className="rounded bg-gray-solid-gray-150-eeeeee box-border h-10 flex flex-row items-center justify-start py-0 px-2 text-sm text-blue-solid-blue-420-2979ff border-[1px] border-solid border-cornflowerblue"
              onPointerDown={(e) => {
                e.stopPropagation();
                handleAddItem()
              }}
            >
              <b className="relative">+ {type === ModifierType.MODIFIER ? LL0().productViewSetting.modifierEditorDialog.addSelection() : LL0().productViewSetting.modifierEditorDialog.addVariant()}</b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[57px]">
            <b className="relative md:text-xs">{LL0().ui.name()}:</b>
            <TextField
              className="flex-1 font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              value={selectedItem()?.name}
              onChange={(e) => {
                if (!selectedItem()) handleAddItem(e.target.value)
                _.assign(selectedItem(), {name: e.target.value})
              }}
              sx={{ "& .MuiInputBase-root": { height: "37px" } }}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[63px]">
            <b className="relative md:text-xs">{LL0().article.price()}:</b>
            <TextField
              className="flex-1 font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              value={computedModifierItemPrice()}
              onChange={(e) => {
                if (!selectedItem()) handleAddItem('', customParseFloat(e.target.value) as number)
                handleModifierItemPriceChange(e);
              }}
              sx={{ "& .MuiInputBase-root": { height: "37px" } }}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-1 px-0 gap-[51px]">
            <b className="relative md:text-xs">{LL0().productViewSetting.modifierEditorDialog.printer()}:</b>
            <div
              className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[8px] text-mini text-black-solid-black-880-1d1d26">
              {groupPrinters0().map((groupPrinter, index) => (
                <div
                  className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1',
                    selectedModifier()?.groupPrinter === groupPrinter._id && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                  key={index}
                  onClick={() => _.assign(selectedModifier(), {groupPrinter: groupPrinter._id})}
                >
                  {groupPrinter.name}
                </div>))}
            </div>
          </div>
          {type === ModifierType.MODIFIER &&
            <div className="self-stretch flex flex-row items-center justify-start py-1 px-0 gap-[8px]">
              <b className="relative md:text-xs">{LL0().productViewSetting.modifierEditorDialog.oneAndOnly()}:</b>
              <FormControlLabel
                label=""
                control={
                  <Switch color="primary"
                          checked={selectedModifier()?.selectOne || false}
                          onChange={e => _.assign(selectedModifier(), { selectOne: e.target.checked })}
                  />}
              />
            </div>}
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-end gap-[12px] text-center text-red-solid-red-300-ff4836">
          <div
            className="rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 gap-[3px] cursor-pointer border-[1px] border-solid border-red-solid-red-300-ff4836"
            onClick={openEditMenuDeleteConfirmatio}
          >
            <img
              className="w-[17px] relative h-[17px] object-cover"
              alt=""
              src="/iconbin-icon--red@2x.png"
            />
            <b className="relative">{LL0().ui.delete()}</b>
          </div>
          <div
            className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 text-white-solid-white-100-ffffff"
            onClick={onClose}
          >
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
      </div>
      {isEditMenuDeleteConfirmatioOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditMenuDeleteConfirmatio}
        >
          <EditMenuDeleteConfirmatio onClose={closeEditMenuDeleteConfirmatio} onComplete={async () => {
            await removeCurrentModifier();
            onClose?.();
          }}/>
        </PortalPopup>
      )}
    </>
  );
};

export default EditMenuNewGroupPopup;
