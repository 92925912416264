import { convertDocuments, type DocDeepSignal } from "@/data/data-utils";
import { SyncMode } from './data-enum'
import { DeviceSetting, type PrinterMappingItem, type ScreenConfig } from '@/data/DeviceSetting';
import { getDeviceId } from '@/shared/getDeviceId'
import { computed, effectOn, signal } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";
import { now } from "@/pos/logic/time-provider.ts";
import dayjs from "dayjs";
import _ from "lodash";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import { map, type Subscription, tap } from "rxjs";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { setUseNativeInput } from "@/react/core/NativeInput.ts";
import pTimeout from "p-timeout";
import { screenConfig } from "@/data/screenConfig.ts";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { ip } from "@/data/IpState.ts";
import { getUrlParam } from "@/shared/utils2.ts";
import {
  deviceSetting0,
  deviceSettings0,
  deviceSettingV,
  setDeviceSettings0,
  setDeviceSettingV
} from "@/data/DeviceSettingSignal.ts";
import { isLimitGermany } from "@/react/CompanyInformationView/companyInfomation.logic.ts";
import { frontendLanguage } from "@/data/language.ts";
import { MenuDirection } from "@/data/MenuDirection.ts";
import semver from "semver";

export const [syncMode0, setSyncMode0] = signal(/*deviceSetting0()?.syncMode ||*/ (() => {
  return localStorage.getItem('syncMode') as SyncMode || (!getUrlParam('os') ? 'online': 'mixed')
})());

effectOn([deviceSetting0], () => {
  if (deviceSetting0()?.syncMode && deviceSetting0()?.syncMode !== localStorage.getItem('syncMode')) {
    localStorage.setItem('syncMode', deviceSetting0()?.syncMode || SyncMode.mixed);
    if (deviceSetting0()?.syncMode !== syncMode0()) {
      setSyncMode0(deviceSetting0()?.syncMode || SyncMode.mixed);
    }
  }
})


// @ts-ignore
window.deviceSetting0 = deviceSetting0;

//todo: computed masterDeviceSetting
//todo: computed timezone
export const masterDeviceSetting = computed<DocDeepSignal<DeviceSetting> | undefined>(() => {
  if (posSetting0()?.masterDevice) {
    return deviceSettings0().find(d => d._id === posSetting0()?.masterDevice)!;
  }
  return deviceSetting0();
});

// @ts-ignore
window.masterDeviceSetting = masterDeviceSetting;

export const deviceSettingLock = new MultiAwaitLock(true)

// async function reloadIfChangeMaster() {
//   await posSettingLock.acquireAsync();
//   await deviceSettingLock.acquireAsync();
//   await delay(100);
//   let masterDevice = masterDeviceSetting();
//   effectOn([masterDeviceSetting, posSetting0], async () => {
//     if (!posSync0().id) return;
//     if (masterDeviceSetting()?._id && masterDevice?._id && masterDeviceSetting()?._id !== masterDevice?._id) {
//       await delay(1000);
//       // location.reload();
//     }
//   })
// }

// reloadIfChangeMaster().then();

export const getTimezoneMasterDevice = computed<string | undefined>(() => {
  if (masterDeviceSetting()?.timezone)
    return masterDeviceSetting()?.timezone;
  return dayjs.tz.guess();
});

// @ts-ignore
window.getTimezoneMasterDevice = getTimezoneMasterDevice;

export const callSystemLock = new MultiAwaitLock(true)

const isShowToolbar = computed(() => {
  return window.innerHeight > 410
})

export const isSmallSidebar = computed(()=> {
  return deviceSetting0()?.screenConfig?.smallSidebar
})

export const isVerticalMenu = computed(()=> {
  return deviceSetting0()?.screenConfig?.menuDirection === MenuDirection.VERTICAL
})

export const printerMapping0 = computed<PrinterMappingItem[]>(() => deviceSetting0()?.printerMapping ?? [])

let deviceSettings0Sub: Subscription | null = null

export const screenConfigDataBase: ScreenConfig = {
  itemCardHeight: 76,
  productFontSize: 14,
  textLineHeight: 17,
  oneLine: false,
  oneLineShorten: false,
  categoryHeight: 42,
  categoryWidth: 96,
  categoryFontSize: 14,
  categoryLineHeight: 18,
  hideTitleName: false,
  scrollableLayout: false,
  smallSidebar: false,
  menuDirection: MenuDirection.VERTICAL
}

effectOn([deviceSettingV, ip], async () => {
  await dataLock.acquireAsync();
  if (!localStorage.getItem('init-device-setting')) {
    localStorage.setItem('init-device-setting', 'true');
    await DeviceSetting.upsert({
      _id: getDeviceId(),
      isMaster: true,
      date: dayjs(now()).unix(),
      force: false,
      show: Boolean(getUrlParam('os')),
      isSupportDevice: !Boolean(getUrlParam('os')),
      //todo: create prop timezone use dayjs tz
      timezone: dayjs.tz.guess(),
      useVirtualKeyboard: !Boolean(getUrlParam('os')),
      showToolbar: isShowToolbar(),
      callSystem: {
        mode: 'none',
      },
      syncMode: SyncMode.mixed,
      screenConfig: screenConfigDataBase,
    });
  }

  deviceSettings0Sub?.unsubscribe()
  deviceSettings0Sub = DeviceSetting.find().$.pipe(
    map(a => convertDocuments(a, true)),
    tap(async a => {
      setDeviceSettings0(a);

      if (['56k-modem', 'artech'].includes(deviceSetting0()?.callSystem?.mode || '')) {
        await callSystemLock.release();
      }

      if (deviceSetting0()?.useVirtualKeyboard !== undefined) {
        setUseNativeInput(!!deviceSetting0()?.useVirtualKeyboard);
      }

      if (!deviceSetting0()?.publicIp) {
        fetch('https://ipinfo.io/json')
          .then(response => response.json())
          .then(data => {
            _.assign(deviceSetting0(), { publicIp: data.ip })
          })
      }


      if (!deviceSetting0()?.ip && ip() !== 'localhost') {
        _.assign(deviceSetting0(), { ip: ip() })
      }

      if (!deviceSetting0()?.name) {
        try {
          const deviceName = await pTimeout(rnHost.sendDeviceName(), { milliseconds: 1000 });
          _.assign(deviceSetting0(), { name: deviceName })
        } catch (e) {
          _.assign(deviceSetting0(), { name: 'Device' })
        }
      }

      if (deviceSetting0()?._id === masterDeviceSetting()?._id && !deviceSetting0()?.timezone || deviceSetting0()!.timezone !== dayjs.tz.guess()) {
        _.assign(deviceSetting0(), { timezone: dayjs.tz.guess() })
      }

      if (deviceSetting0()?.autoOpenCashDrawer === undefined) {
        _.assign(deviceSetting0(), {autoOpenCashDrawer: true})
      }
      if (!deviceSetting0()?.screenConfig) {
        _.assign(deviceSetting0(), {screenConfig: screenConfigDataBase})
      }
      if (isLimitGermany()) {
        if (frontendLanguage() === 'de') return;
        _.assign(deviceSetting0(), { frontendLanguage: 'de' })
      }

      if (deviceSetting0()?.secondDisplayType) {
        const previous = localStorage.getItem('open-second-display');
        const updatedAt = deviceSetting0()!.updatedAt?.toString();
        const appVersion = import.meta.env.VITE_APP_VERSION;

        const [previousUpdatedAt = '', previousAppVersion = ''] = _.split(previous, '/');

        if (deviceSetting0()!.updatedAt?.toString() !== previousUpdatedAt || appVersion !== previousAppVersion) {
          localStorage.setItem('open-second-display', updatedAt + '/' + appVersion);
          await rnHost.openSecondDisplay(deviceSetting0()!.secondDisplayType!);
        }
      }

      await deviceSettingLock.release();
    }),
  ).subscribe()

}/*, {defer: true}*/);

effectOn([deviceSettingV],()=> {
  screenConfig()
})

export const makeDeviceSettingsAvailable = async () => {
  if (deviceSettingV() === 0) {
    await dataLock.acquireAsync();
    DeviceSetting.$.subscribe(_.throttle((change) => {
      setDeviceSettingV(v => v + 1);
    }, 100, { leading: true, trailing: true }))
    setDeviceSettingV(1);
    const ds = DeviceSetting.find().$$;

    //@ts-ignore
    window.ds = ds;
  }
};

makeDeviceSettingsAvailable().then();
