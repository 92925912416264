import CompanyInformationPlugin from "@company-info/CompanyInformationPlugin.tsx";
import {memo} from "react";
import _ from 'lodash';
import type { UploadFile } from "@solid-primitives/upload";
import { convertBase64ToPngBase64, toBase64 } from "@/react/shared/utils.ts";
import { Image } from "@/data/Image.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

export const defaultGermanRedInvoiceContent = 'Angaben zum Nachweis der Höhe und der betrieblichen Veranlassung von Bewirtungs Aufwendungen (Par.4 Abs.5 Ziff.2 EStG)\n' +
  '--------------------------------------\n' +
  'Tag und Ort der Bewirtung:\n' +
  '\n' +
  '--------------------------------------\n' +
  'Bewirtete Person(en)\n' +
  '\n' +
  '\n' +
  '--------------------------------------\n' +
  'Anlaß der Bewirtung\n'

export async function initDefaultRedInvoiceContent() {
  _.assign(companyInfo0(), {redInvoiceContent: defaultGermanRedInvoiceContent})
}

//TODO: Implement this later
export async function initTaxLabelByCountry(country: string) {
  let taxLabel;
  switch (country) {
    case 'de': {
      taxLabel = 'St.-Nr'
      break;
    }
    case 'ca': {
      taxLabel = 'HST'
      break;
    }
    default: {
      taxLabel = 'VAT'
    }
  }
  _.assign(companyInfo0(), { taxLabel })
}

export const handleChangeLogo = async (file?: UploadFile, logo?: string) => {
  try {
    if (file) {
      const content = await toBase64(file.file);
      if (file.file.type !== 'image/png') {
        const base64 = await convertBase64ToPngBase64(content)
        await updateImage(base64 as string)
        return
      }
      await updateImage(content as string)
    }
    if (logo) {
      const base64 = await convertBase64ToPngBase64(logo) // check
      await updateImage(base64 as string)
    }
  } catch (e) {
    console.error(e);
  }

  async function updateImage(base64: string) {
    _.assign(companyInfo0(), { logo: base64 });
    await Image.upsert({
      _id: 'logo',
      type: 'SecondDisplayLogo',
      data: base64
    })
  }
}

const CompanyInformationView = () => {
  return (
      <CompanyInformationPlugin />
  )
}

export default memo(CompanyInformationView)