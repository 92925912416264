import * as Comlink from 'comlink'
import { toast } from 'react-toastify'

import { signal } from '@/react/core/reactive.ts'
import dialogService from '@/react/SystemService/dialogService.tsx'
import SurrealClient from '@/shared/SurrealClient'
import { rnHost } from '@/shared/webview/rnwebview.ts'

const percentFormat = Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format
export const [openUpdateNowPopup, setOpenUpdateNowPopup] = signal<boolean>(false)
const handleUpdate = async (mode: 'store' | 'codepush' | 's3' | 'electron') => {
  setOpenUpdateNowPopup(false)
  let closeDialog = dialogService.progress({ title: 'Updating...' })
  const toastId = toast.loading('Updating...', { progress: 0, isLoading: true })
  try {
    const success = await rnHost.updateNow(
      mode === 'electron' ? await getElectronUpdatePackageUrl() : mode,
      Comlink.proxy((p: number) => {
        toast.update(toastId, {
          render: `Downloading update package... ${percentFormat(p / 100)}`,
          progress: p / 100,
        })
      })
    )
    if (!success) {
      toast.error('Failed to update!')
      return
    }
    toast.success('Update new version successful!')
  } catch (e) {
    console.error('Failed to update:', e)
    if (e instanceof Error) toast.error('Failed to update! ' + e.message)
  } finally {
    toast.dismiss(toastId)
    closeDialog()
  }
}

interface AppRelease {
  id: string
  name: string
  version: string
  releaseDate: number
  downloadUrl: string
}
async function getElectronUpdatePackageUrl() {
  const client = await SurrealClient.getSurrealClient('cloud')
  const [release] = await client.query(`SELECT * FROM ONLY app_releases ORDER BY releaseDate DESC LIMIT 1;`)
  if (!release) throw new Error('Fail to fetch latest version! Please try again.')
  console.log('» Query result:', release)
  const { downloadUrl } = release as AppRelease
  console.log('» Download url:', downloadUrl)
  return downloadUrl
}

// Testing only
Object.assign(window, { getElectronUpdatePackageUrl })

export const handleUpdateAPK = async () => await handleUpdate('store')
export const handleUpdateCore = async () => await handleUpdate('codepush')
export const handleUpdateS3 = async () => await handleUpdate('s3')
export const handleUpdateElectron = async () => await handleUpdate('electron')
