import dialogService from "@/react/SystemService/dialogService.tsx";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { LL0 } from "@/react/core/I18nService.tsx";


export const checkOngoingOrder = async () => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className='w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center box-border gap-[24px] min-w-[280px] pb-4 h-full max-w-full max-h-full text-center text-xl text-red-solid-red-550-cf2222 font-mulish'
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative leading-[28px]">
            {LL0().settings.completed()}
          </b>
          <b className="self-stretch relative text-base leading-[18px] text-red-solid-red-550-cf2222">
            {LL0().settings.please()}
             </b>
        </div>
        <div
          className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
          <div
            className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 box-border max-w-[100px] text-white-solid-white-100-ffffff cursor-pointer"
            onClick={() => {
              router.screen = PosScreen.FLOOR_PLAN
              onClose()
            }}
          >
            <b className="relative">GO</b>
          </div>
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}


