import type { FunctionComponent } from "react";
import PasscodeHeader from "./PasscodeHeader";
import PasscodeLeft from "./PasscodeLeft";
import PasscodeRight from "./PasscodeRight";

const PasscodePlugin: FunctionComponent = () => {
  return (
    <div className="passcode-screen w-full h-full overflow-hidden flex flex-col items-center justify-start !bg-[url('/passcode--plugin@3x.png')] bg-cover bg-no-repeat bg-[top]">
      <PasscodeHeader />
      <div className="self-stretch flex-1 flex flex-row items-start justify-start pt-5 px-[70px] pb-16 gap-[64px] z-[0] mq768:gap-[64px_24px] mq768:pl-[34px] mq768:pt-4 mq768:pr-[34px] mq768:box-border mq600:gap-[64px_12px] mq600:pl-4 mq600:pt-4 mq600:pr-4 mq600:box-border mq480:gap-[64px_10px] mq480:pl-4 mq480:pr-4 mq480:box-border height_414:gap-[64px_10px] height_414:pt-3 height_414:pb-6 height_414:box-border height_340:gap-[64px_10px] height_340:pt-2.5 height_340:pb-6 height_340:box-border">
        <PasscodeLeft />
        <PasscodeRight />
      </div>
    </div>
  );
};

export default PasscodePlugin;
