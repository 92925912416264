import { type FunctionComponent, useState, useCallback } from "react";
import PopupNotificationError from "../Popup/PopupNotificationError";
import PortalPopup from "../Popup/PortalPopup";
import PopupNotificationSyncSuccess from "../Popup/PopupNotificationSyncSuccess";
import { VIEW__CATEGORY, STATES } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import {LL0} from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils";

const CategoryListToolBar: FunctionComponent = () => {
  const [isPopupNotificationErrorOpen, setPopupNotificationErrorOpen] =
    useState(false);
  const [isPopupNotificationSyncSuccOpen, setPopupNotificationSyncSuccOpen] =
    useState(false);

  const openPopupNotificationError = useCallback(() => {
    setPopupNotificationErrorOpen(true);
  }, []);

  const closePopupNotificationError = useCallback(() => {
    setPopupNotificationErrorOpen(false);
  }, []);

  const openPopupNotificationSyncSucc = useCallback(() => {
    setPopupNotificationSyncSuccOpen(true);
  }, []);

  const closePopupNotificationSyncSucc = useCallback(() => {
    setPopupNotificationSyncSuccOpen(false);
  }, []);

  return (
    <>
      <section className="self-stretch flex flex-row items-start justify-between py-2.5 px-5 text-center text-mini text-blue-solid-blue-900-081a51 font-mulish-button-name-bold lg:pl-5 lg:pr-5 lg:box-border md:pl-4 md:pr-4 md:box-border sm:py-2 sm:px-3.5 sm:box-border mq768:py-2 mq768:px-3 mq768:box-border mq600:py-2 mq600:px-2.5 mq600:box-border mq480:py-2 mq480:px-2.5 mq480:box-border">
        <div className="h-10 flex flex-row items-start justify-start gap-[10px] sm:h-9 mq768:h-8 mq768:gap-[6px] mq600:h-7 mq600:gap-[4px] mq480:h-7">
          <button ref={makeRipple} onClick={VIEW__CATEGORY.onCreate}  className="self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-2.5 text-white-solid-white-100-ffffff">
            <b className="relative leading-[12px] sm:text-sm mq768:text-smi mq600:text-smi mq480:text-xs">
              +{LL0().editOnlineMenu.addCategory()}
            </b>
          </button>
          {/*
          <div className="self-stretch rounded-3xs bg-pink-solid-pink-500-e5d5ff shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-2.5 text-blue-solid-blue-900-1c1f37">
            <b className="relative leading-[12px] sm:text-sm mq768:text-smi mq600:text-smi mq480:text-xs">
              Last sync: 03/08/2023
            </b>
          </div>
          <div
            className="self-stretch rounded-3xs bg-pink-solid-pink-250-ffe5e5 shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-2.5 gap-[12px] cursor-pointer"
            onClick={openPopupNotificationError}
          >
            <img
              className="relative w-6 h-6 object-cover mq768:w-5 mq768:h-5 mq600:w-[18px] mq600:h-[18px]"
              alt=""
              src="/iconerror-icon@2x.png"
            />
            <b className="relative mq768:text-sm mq600:text-smi mq480:text-smi">
              {LL0().payment.error()}
            </b>
          </div>
          <div
            className="self-stretch rounded-3xs bg-green-solid-green-100-cdffbc shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-2.5 gap-[12px] cursor-pointer"
            onClick={openPopupNotificationSyncSucc}
          >
            <img
              className="relative w-6 h-6 object-cover mq768:w-5 mq768:h-5 mq600:w-[18px] mq600:h-[18px]"
              alt=""
              src="/iconsuccess-icon11@2x.png"
            />
            <b className="relative mq768:text-sm mq600:text-sm mq480:text-smi">
              {LL0().editOnlineMenu.sync()}
            </b>
          </div>
          */}
        </div>
        <div className="h-10 flex flex-row items-start justify-start gap-[10px] text-white-solid-white-100-ffffff sm:h-9 mq768:h-8 mq768:gap-[6px] mq600:h-7 mq600:gap-[4px] mq480:h-7 mq480:gap-[4px]">
          <button className="disabled:opacity-30 self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-[13px]"
            disabled={!STATES.isSyncAvailable()}
            ref={makeRipple}
            onClick={VIEW__CATEGORY.onSync}>
            <b className="relative sm:text-sm mq768:text-smi mq600:text-smi mq480:text-xs">
              {LL0().editOnlineMenu.sync()}
            </b>
          </button>
          <button disabled className="opacity-30 self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-3">
            <b className="relative sm:text-sm mq768:text-smi mq600:text-smi mq480:text-xs">
              {LL0().settings.labelPrinters.preview()}
            </b>
          </button>
          <button disabled className="opacity-30 self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-[13px]">
            <b className="relative sm:text-sm mq768:text-smi mq600:text-smi mq480:text-xs">
              {LL0().syncMenu.import()}
            </b>
          </button>
          <button disabled className="opacity-30 self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] flex flex-row items-center justify-center py-0 px-[13px]">
            <b className="relative sm:text-sm mq768:text-smi mq600:text-smi mq480:text-xs">
              {LL0().editOnlineMenu.export()}
            </b>
          </button>
        </div>
      </section>
      {isPopupNotificationErrorOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePopupNotificationError}
        >
          <PopupNotificationError onClose={closePopupNotificationError} />
        </PortalPopup>
      )}
      {isPopupNotificationSyncSuccOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePopupNotificationSyncSucc}
        >
          <PopupNotificationSyncSuccess
            onClose={closePopupNotificationSyncSucc}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default CategoryListToolBar;
