import { computed, effect } from "@/react/core/reactive.ts";
import { screenConfigDataBase } from "@/data/DeviceSettingHub.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";


const screenConfigRaw = computed(() => {
  if (deviceSetting0()?.screenConfig) {
    return deviceSetting0()!.screenConfig
  }
  return screenConfigDataBase
})

export const screenConfig = computed(() => {
  const getScreenConfigFromLS = localStorage.getItem('screenConfigLS');
  const screenConfigFromLSValue = getScreenConfigFromLS ? JSON.parse(getScreenConfigFromLS) : null;
    if (screenConfigFromLSValue !== screenConfigRaw()) {
      localStorage.setItem('screenConfigLS', JSON.stringify(screenConfigRaw()))
      return screenConfigRaw()
    }
    return screenConfigFromLSValue

})

//@ts-ignore
window.screenConfig = screenConfig

