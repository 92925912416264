export default function normalizeString(str, supportedCharacters = []) {
  const germanySpecialCharacters = ['ä', 'ö', 'ü', 'ß', 'Ä', 'Ö', 'Ü', '€'];
  const mGermanySpecialCharacters = ['ae', 'oe', 'ue', 'ss', 'AE', 'OE', 'UE', 'EUR'];

  const vietnameseSpecialCharacters = ['o','ò', 'ó', 'ỏ', 'õ', 'á', 'à', 'ả', 'ã', 'ạ', 'ă', 'ằ', 'ắ', 'ẳ', 'ẵ', 'ặ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'è', 'é', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'ơ', 'ờ', 'ớ', 'ở', 'ỡ', 'ợ', 'ô', 'ồ', 'ố', 'ổ', 'ỗ', 'ộ', 'đ', 'ù', 'ú', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'ì', 'í', 'ỉ', 'ĩ', 'ị', 'ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ']
  const mVietnameseSpecialCharacters = ['o', 'o', 'o', 'o', 'o', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'd', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'i', 'i', 'i', 'i', 'i', 'y', 'y', 'y', 'y', 'y']

  const cVietnameseSpecialCharacters = ['O','Ò', 'Ó', 'Ỏ', 'Õ', 'Á', 'À', 'Ả', 'Ã', 'Ạ', 'Ă', 'Ằ', 'Ắ', 'Ẳ', 'Ẵ', 'Ặ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'È', 'É', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Ơ', 'Ờ', 'Ớ', 'Ở', 'Ỡ', 'Ợ', 'Ô', 'Ồ', 'Ố', 'Ổ', 'Ỗ', 'Ộ', 'Đ', 'Ù', 'Ú', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Ì', 'Í', 'Ỉ', 'Ĩ', 'Ị', 'Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ']
  const mCVietnameseSpecialCharacters = ['o', 'o', 'o', 'o', 'O', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'D', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'I', 'I', 'I', 'I', 'I', 'Y', 'Y', 'Y', 'Y', 'Y']

  const symbols = ['€', '₫']
  const mSymbols = ['EUR', 'VND']
  for (let i = 0; i < germanySpecialCharacters.length; i++) {
    str = str.replace(new RegExp(germanySpecialCharacters[i], 'g'), mGermanySpecialCharacters[i]);
  }
  for (let i = 0; i < vietnameseSpecialCharacters.length; i++) {
    str = str.replace(new RegExp(vietnameseSpecialCharacters[i], 'g'), mVietnameseSpecialCharacters[i]);
  }

  for (let i = 0; i < cVietnameseSpecialCharacters.length; i++) {
    str = str.replace(new RegExp(cVietnameseSpecialCharacters[i], 'g'), mCVietnameseSpecialCharacters[i]);
  }

  for (let i = 0; i < symbols.length; i++) {
    str = str.replace(new RegExp(symbols[i], 'g'), mSymbols[i]);
  }

  str = removeUnsupportedCharacters(str, supportedCharacters);

  return str;
}

function removeUnsupportedCharacters(str, supportedCharacters) {
  if (!str) return str;

  let res = '';

  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127 && !Object.keys(supportedCharacters).includes(str[i])) res += '_';
    else res += str[i];
  }

  return res;
}
