import { clsx, type ClassValue } from 'clsx'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toISOString(date: number) {
  return dayjs.unix(date).format('YYYY-MM-DDT00:00:00.000') + 'Z'
}

export function toHex(str: string) {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i).toString(16)
    if (c.length === 1) {
      c = '0' + c
    }
    result += c
  }
  return result
}

export function hex2a(hexx: string) {
  const hex = hexx.toString() //force conversion
  let str = ''
  for (let i = 0; i < hex.length; i += 2) {
    let c = String.fromCharCode(parseInt(hex.substr(i, 2), 16))
    str += c
  }
  return str
}

export function syncBooleanLocalstorage(value: boolean | undefined, key: string) {
  const val = localStorage.getItem(key)
  if (value === undefined && val) {
    localStorage.removeItem(key)
  } else if (value ? 'true' : 'false' !== val) {
    localStorage.setItem(key, value ? 'true' : 'false')
  }
}
export function usePemDownload(pem?: string) {
  const [link, setLink] = useState<string | undefined>()
  useEffect(() => {
    let l: string | undefined
    if (pem) {
      const blob = new Blob([pem], { type: 'application/x-pem-file' })
      l = URL.createObjectURL(blob)
    }
    setLink(l)

    return () => {
      // Cleanup
      if (l) URL.revokeObjectURL(l)
    }
  }, [pem])
  return link
}
