import React from "react";

export default function () {
  return <div className="relative w-6 h-6 overflow-hidden shrink-0 Tablet_768:w-[22px] Tablet_768:h-[22px] mq1440:w-7 mq1440:h-7">
    <img
      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
      alt=""
      src="/icondeliver-icon@2x.png"
    />
  </div>
}