import type { RxCollection, RxDatabase } from 'rxdb'

import { PRINT_SCRIPTS_COLLECTION_NAME, PrintScripts, printScriptsSchema } from './PrintScripts'
import { createCollection } from './utils/migration'

export function createPrintScriptsCollection<T>(database: RxDatabase<T>): Promise<RxCollection<PrintScripts>> {
  return createCollection({
    database,
    collection: PrintScripts,
    collectionName: PRINT_SCRIPTS_COLLECTION_NAME,
    version: printScriptsSchema.version,
    schema: printScriptsSchema,
  })
}
