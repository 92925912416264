import { type FunctionComponent } from 'react'
import { unix } from 'dayjs'
import { staffReportData } from '@/react/StaffReportView/StaffReportView'
import { LL0 } from "@/react/core/I18nService.tsx";

type TRow = ReturnType<typeof staffReportData>['cancellationHistory'][number]
type TProp = { data?: Array<TRow> , title: string}

const StaffReportCancellationHistory: FunctionComponent<TProp> = ({ data, title }) => {
  return (
    <div className="self-stretch bg-gray-solid-gray-180-ebecee flex flex-col items-start justify-start pt-2 px-6 pb-6 gap-[8px] text-left text-mini text-gray font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
      <div className="self-stretch flex flex-row items-center justify-between">
        <b
          className="flex-1 relative Tablet_768:text-sm Tablet_600:text-sm">{title}
        </b>
        {(!data || data?.length === 0) && (
          <div className="relative text-base text-right Tablet_768:text-mini Tablet_600:text-xs">
            -
          </div>
        )}
      </div>
      {(data && data?.length > 0 ) && (
        <table className="self-stretch rounded-md overflow-hidden border-spacing-[0]">
          <thead>
          <tr className="bg-[#888e97]">
            <th className="relative pr-0 pb-0">
              <div className="w-full flex flex-row items-center justify-start py-3 px-2 box-border h-full">
                <b className="flex-1 relative text-base font-mulish text-white-solid-white-100-ffffff text-left Tablet_768:text-sm Tablet_600:text-smi">{LL0().staffReport.table()}</b>
              </div>
            </th>
            <th className="relative pr-0 pb-0">
              <div className="w-full flex flex-row items-center justify-start py-3 px-2 box-border gap-[2px] h-full">
                <b className="relative text-base font-mulish text-white-solid-white-100-ffffff text-left Tablet_768:text-sm Tablet_600:text-smi">{LL0().staffReport.time()}</b>
                <img
                  className="relative w-4 h-4 Tablet_600:w-4 Tablet_600:h-4"
                  alt=""
                  src="/arrow-down-icon.svg"
                />
              </div>
            </th>
            <th className="relative pb-0">
              <div className="flex flex-row items-center justify-start py-3 px-2 box-border w-full h-full">
                <b className="relative text-base font-mulish text-white-solid-white-100-ffffff text-left Tablet_768:text-sm Tablet_600:text-smi">{LL0().staffReport.items()}</b>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row, i) => (
            <tr
              className="odd:bg-white even:bg-[#e3e3e3]"
              key={i}
            >
              <td className="relative pr-0 pb-0">
                <div className="w-full flex flex-row items-center justify-start py-3 px-2 box-border h-full">
                  <div className="flex-1 relative text-sm font-semibold font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-smi Tablet_600:text-xs">{row.table ? LL0().staffReport.table() : ''} {row.table}</div>
                </div>
              </td>
              <td className="relative pr-0 pb-0">
                <div className="w-full flex flex-row items-center justify-start py-3 px-2 box-border h-full">
                  <div className="flex-1 relative text-sm font-semibold font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-smi Tablet_600:text-xs">
                    {unix(row.date).format('MMM DD, HH:mm')}
                  </div>
                </div>
              </td>
              <td className="relative pb-0">
                <div className="flex flex-row items-center justify-start py-3 px-2 box-border w-full h-full">
                  <div className="flex-1 relative text-sm font-semibold font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-smi Tablet_600:text-xs">
                    {(Math.abs(row.count) > 1 ? `${Math.abs(row.count)} x ` : '') + row.name}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      )}
    </div>
  )
}

export default StaffReportCancellationHistory
