import pTimeout from 'p-timeout'

import { bound, handleError, progressToast } from '@/shared/decorators'

import msgBox, { Buttons, Results } from '../SystemService/msgBox'
import { mainDisplayIp, setIsMainDisplayIpValid, setIsSecondDisplay, setMainDisplayIp, setMainDisplayIpError, setShouldAutoSwitch, setValidatingMainDisplayIp } from './SecondDisplayConfig.state'

const SECOND_DISPLAY_URL = './index-2.html'

function ensureCorrectIpFormat(ip: string) {
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/
  if (!ipRegex.test(ip)) throw new Error(`Invalid IP address: ${ip}`)
}

async function ensureWssConnection(ip: string) {
  const url = `ws://${ip}:8080`
  const ws = new WebSocket(url)
  const promise = new Promise((resolve, reject) => {
    ws.addEventListener('open', () => {
      ws.close()
      console.log('Connected to main display at', ip)
      resolve(true)
    })
    ws.addEventListener('error', e => {
      console.log('Failed to connect to main display at', ip, e)
      reject(new Error('Failed to connect to main display at ' + ip + ' (connection refused)'))
    })
  })

  await pTimeout(promise, {
    milliseconds: 10 * 1000,
    message: `Failed to connect to main display at ${ip} (timeout after 10s)`,
  })
}

class SecondDisplayConfigLogic {
  handleSwitchChange(event: React.ChangeEvent, checked: boolean) {
    setIsSecondDisplay(checked)
    if (!checked) {
      setMainDisplayIp('')
      setMainDisplayIpError('')
      setIsMainDisplayIpValid(false)
    }
  }

  handleIpChange(val: string) {
    setMainDisplayIp(val)
    setMainDisplayIpError('')
    setIsMainDisplayIpValid(false)
  }

  @progressToast(() => 'Validating...', undefined, setValidatingMainDisplayIp)
  @handleError(e => (e instanceof Error ? setMainDisplayIpError(e.message) : setMainDisplayIpError('Unknown error')))
  async handleValidate() {
    const ip = mainDisplayIp()
    ensureCorrectIpFormat(ip)
    await ensureWssConnection(ip)
    setIsMainDisplayIpValid(true)
  }

  @bound()
  async handleSwitchMode() {
    const title = 'Switch to Second Display Mode'
    const msg = 'Do you want to switch to second display mode automatically next time?'
    const answer = await msgBox.show(title, msg, Buttons.YesNo)
    setShouldAutoSwitch(answer === Results.yes)
    this.switchToSecondDisplayMode()
  }

  switchToSecondDisplayMode() {
    const currentUrl = new URL(window.location.href)
    const secondDisplayUrl = new URL(SECOND_DISPLAY_URL, currentUrl)
    for (const [name, val] of currentUrl.searchParams.entries()) secondDisplayUrl.searchParams.set(name, val)
    window.location.assign(secondDisplayUrl)
  }
}

export const { handleSwitchChange, handleValidate, handleIpChange, handleSwitchMode, switchToSecondDisplayMode } = new SecondDisplayConfigLogic()
