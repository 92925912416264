import debug from 'debug'
import { connect, StringCodec, type NatsConnection, type Subscription } from 'nats.ws'

import { posSyncLock } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { servers } from '@/lib/servers.ts'
import { effectOn } from '@/react/core/reactive.ts'

const log = debug('data:nats')

class NatsClient {
  private client: NatsConnection | null
  private subjects: Record<string, any>
  private subObjects: Record<string, Subscription>
  public connected: boolean

  constructor() {
    this.client = null
    this.subjects = {}
    this.subObjects = {}
    this.connected = false
  }

  async connectToServer(serverUrl: string) {
    log('serverUrl', serverUrl)
    this.client = await connect({
      servers: [serverUrl],
      user: 'pos',
      pass: 'gigapos321123',
    })
    this.connected = true
    await Promise.all(
      Object.keys(this.subjects).map(subject => {
        return this.subscribeToSubject(subject, this.subjects[subject])
      })
    )
  }

  async subscribeToSubject(subject: string, handler: (data: any) => Promise<any>) {
    this.subjects[subject] = handler
    const sub = this.client?.subscribe(subject)
    const sc = StringCodec()
    ;(async (sub: Subscription) => {
      if (!sub) return
      console.log(`listening for ${sub.getSubject()} requests...`)
      for await (const m of sub) {
        const data = JSON.parse(sc.decode(m.data))
        const result = await handler(data)
        if (m.respond(sc.encode(JSON.stringify(result)))) {
          console.info(`[${subject}] handled #${sub.getProcessed()}`)
        } else {
        }
      }
      console.log(`subscription ${sub.getSubject()} drained.`)
    })(sub!).then(() => {})
    this.subObjects[subject] = sub!
  }

  unsubscribeToSubject(subject: string) {
    this.subObjects[subject]?.unsubscribe()
    delete this.subObjects[subject]
    delete this.subjects[subject]
  }

  drain() {
    this.connected = false
    return this.client?.drain()
  }

  isDraining() {
    return this.client?.isDraining()
  }

  getClient() {
    return this.client
  }
}

let natsClient: NatsClient
let cacheServer: string = ''

export function getNatsClient() {
  if (!natsClient) {
    natsClient = new NatsClient()
  }
  return natsClient
}

effectOn([server], async () => {
  await posSyncLock.acquireAsync()
  if (server() === cacheServer) return
  cacheServer = server()!
  const client = getNatsClient()
  if (server() && !client?.isDraining()) {
    if (client.connected) {
      await client.drain()
    }
    const serverName = server()
    await client.connectToServer(servers[serverName!].nats.url)
  }
})
