import { memo, useEffect, useMemo } from 'react';
import {
  isSelected,
  ITEM_MODE,
  orderLayout0,
  setCategoryLayout0,
  setCurrentCategory,
  setEditorMode0,
  setTempItem, setV,
  tempItem
} from '@/react/EditMenuView/EditMenuView.tsx';
import { batch, useAsyncEffect, useComputed, useDeepSignal, useSignal } from '@/react/core/reactive.ts';
import { CategoryLayout } from '@/data/OrderLayout.ts';
import uuid from 'time-uuid';
import _ from 'lodash';
import { clone } from 'rxdb';
import GridElement from '@/react/core/GridElement.tsx';
import type { Setter } from "solid-js";
import delay from "delay";
import { peek } from "deepsignal/react";
import { untracked } from "@preact/signals-react";

let categoryHub: any = {};

const RenderLayoutCategory = memo(({left, top, setVer}: { top: number, left: number, setVer: Setter<number>}) => {
  // const _orderLayout0 = untracked(() => orderLayout0());
  const categoryLayout = useComputed(() => {
    let found = orderLayout0()?.categories.find((c) => c.top === top && c.left === left);
    if (!found) {
      console.log('create new category');
      orderLayout0().categories.push({
        _id: uuid(),
        top,
        left,
        rows: 10,
        columns: 6,
        name: "",
        color: "#FFFFFF",
        products: []
      });
      found = orderLayout0().categories.find((c) => c.top === top && c.left === left)
    }
    return found;
  });

  useEffect(() => {
    // console.log(`Render CategoryLayout ${top} ${left} ${categoryLayout()?.name}`);
  }, []);

  //todo create new
  const index = useComputed(() => orderLayout0()?.categories.findIndex(category => category._id === categoryLayout()?._id) as number);
  const color = () => categoryLayout()?.color
  const active = isSelected(index());
  const border = () => {
    if (!active) return "solid 1px #FFFFFF";
    return active ? "solid 2px #FF0022" : "1px solid transparent";
  }

  if (!categoryLayout()) return <></>;

  categoryHub[categoryLayout()!._id] = {
    setVer,
    setActive() {
      batch(() => {
        setCurrentCategory(index());
        setCategoryLayout0(categoryLayout() as CategoryLayout);
        setEditorMode0(ITEM_MODE.CATEGORY);
      });
    }
  }

  return (
    <GridElement
      x={categoryLayout()!.left} y={categoryLayout()!.top} cols={1} rows={1}
      style={{
        background: color(),
        display: "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "borderRadius": "5px",
        "border": border(),
      }}
      onClick={async (e) => {
        const switchItem = tempItem();
        if (switchItem?.active && switchItem.type === "category") {
          const switchCategory = orderLayout0()?.categories.find(
            (p) => p._id === switchItem._id
          )!;
          const currentCategory = orderLayout0()?.categories.find(
            (p) => p._id === categoryLayout()!._id
          )!;
          if (!switchCategory) {
            setTempItem();
            return;
          }

          if (switchItem.mode === "swap") {
            batch(() => {
              switchCategory.left = peek(categoryLayout()!, 'left');
              switchCategory.top = peek(categoryLayout()!, 'top')
              currentCategory.left = peek(switchItem, 'left')!;
              currentCategory.top = peek(switchItem, 'top')!;
            })
          }

          setTempItem();

          // setV(v => v + 1);
          await delay(50);
          setVer(ver => ver + 1);
          categoryHub[switchCategory._id]?.setVer((v: number) => v + 1);
          await delay(50);
          categoryHub[switchCategory._id]?.setActive();
          // set(index)
          return;
        }
        batch(() => {
          setCurrentCategory(index());
          setCategoryLayout0(categoryLayout() as CategoryLayout);
          setEditorMode0(ITEM_MODE.CATEGORY);
        });
      }}
    >
      <span className="text-sm font-bold">{categoryLayout()?.name}</span>
    </GridElement>
  );
})

const RenderLayoutCategoryWrapper = ({left, top}: { top: number, left: number }) => {
  const [ver, setVer] = useSignal<number>(0);
  useEffect(() => {
    if (ver() % 2 === 1) setVer(ver() + 1);
  }, [ver()]);
  return ver() % 2 === 0 && <RenderLayoutCategory top={top} left={left}
                                                  setVer={setVer}/>;
}

export default RenderLayoutCategoryWrapper;