import React, { type FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';

import {
  currentItem,
  decreaseParts, handleEnter, handlePriceInput,
  increaseParts,
  numberOfParts, priceItems,
  setPriceItems
} from "@/react/OrderView/OrderView.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import _ from "lodash";
import { LL0 } from "@/react/core/I18nService.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";

type SplitItemFormType = {
  showSplitItemForm?: boolean;
  onClose?: () => void;
};



const SplitItemForm: FunctionComponent<SplitItemFormType> = ({
  showSplitItemForm,
  onClose
}) => {
  const { order0 } = useContext(ItemFactoryContext);

  const initialSeatValues = useMemo(() => Array(numberOfParts()).fill(currentItem()?.seat ?? 0), [numberOfParts()]);
  const [currentSeat, setCurrentSeat] = useState<number[]>(initialSeatValues);
  const [isChangeQuantity, setIsChangeQuantity] = useSignal<boolean>(true);


  useEffect(() => {
    if (currentItem()) {
      const newPrices = Array(numberOfParts()).fill({
        price: "",
        quantity: "",
        seat: currentItem().seat
      });
      setPriceItems(newPrices.map((item, index) => ({
        ...item,
        quantity: isChangeQuantity() ? _.round( currentItem().quantity / numberOfParts(), 2).toString() : '1',
        price: isChangeQuantity() ? currentItem().price.toString() : _.round((currentItem().price * currentItem().quantity) / numberOfParts(), 2).toString(),
        seat: currentSeat[index]
      })));
    }
    const newSeatValues = Array(numberOfParts()).fill(null).map((_, index) => {
      return index < currentSeat.length ? currentSeat[index] : currentItem()?.seat ?? 0;
    });
    if (!_.isEqual(currentSeat, newSeatValues)) {
      setCurrentSeat(newSeatValues);
    } else {
      return
    }
  }, [numberOfParts(), currentSeat, isChangeQuantity()]);

  return (
    showSplitItemForm && (
      <div
        className="split-item-popup self-stretch flex-1 flex flex-col items-end justify-between pt-4 px-2 pb-5 z-[4] pr-3 pl-1 mq432:p-0 text-left text-smi text-black-solid-black-1000-000000 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[20px] mq432:p-2">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <div className="relative">{LL0().order.splitTheItemInto()}</div>
            <div
              className="flex flex-row items-center justify-start gap-[3px] text-center text-base text-black-solid-black-880-1d1d26">
              <img
                className="w-[22px] relative h-[22px]"
                alt=""
                src="/minusvector.svg"
                onClick={decreaseParts}
              />
              <b className="w-[30px] relative inline-block shrink-0">{numberOfParts()}</b>
              <img
                className="w-[22px] relative h-[22px]"
                alt=""
                src="/plusvector.svg"
                onClick={increaseParts}
              />
            </div>
            <div className="relative">{LL0().payment.part()}.</div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-0 pr-1 pl-0 gap-[16px] Mobile_480:gap-[12px] height_414:gap-[16px] mq320:gap-[12px]">
            <div className="no-scrollbar w-full overflow-y-auto max-h-[200px]">
              {priceItems().map((p, index) => (
                <div className="flex flex-row items-center justify-start gap-[0px_16px] w-full mt-3" key={index}>
                  <div className="relative w-[64px]">{LL0().payment.part()} {index + 1}:</div>
                  {!isChangeQuantity() && (<Input
                    className="[border:none] bg-[transparent] flex-1 shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish font-semibold text-smi text-black-solid-black-1000-000000"
                    value={p.price}
                    onChange={(value) => handlePriceInput(value, index)}
                  />)}
                  {isChangeQuantity() && (<Input
                    className="[border:none] bg-[transparent] flex-1 shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish font-semibold text-smi text-black-solid-black-1000-000000"
                    value={p.quantity}
                    onChange={(value) => handlePriceInput(value, index, isChangeQuantity())}
                  />)}
                  {order0?.()?.seatMode && (
                    <div className="flex flex-col items-start justify-start">
                      <FormControl
                        className="w-[140px] shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] font-mulish text-sm text-black-solid-black-880-1d1d26"
                        variant="outlined"
                      >
                        <InputLabel>Select Seat</InputLabel>
                        <Select
                          size="small"
                          label="Select Seat"
                          value={currentSeat[index] !== undefined ? `Seat ${currentSeat[index] + 1}` : ``}
                          onChange={(event) => {
                            const value = event.target.value;
                            setCurrentSeat(prevState => {
                              const newState = [...prevState];
                              newState[index] = parseInt(value.split(' ')[1]) - 1;
                              return newState;
                            });
                          }}
                        >
                          {order0?.()?.seatMap?.map((_, seatIndex) => (
                            <MenuItem key={seatIndex}
                                      value={`Seat ${seatIndex + 1}`}>{`Seat ${seatIndex + 1}`}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText />
                      </FormControl>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <FormControlLabel
            className="z-[1]"
            label={LL0().order.keepTheOriginalPrice()}
            control={<Switch color="primary" size="medium" checked={!!isChangeQuantity()}
                             onChange={(e, checked: boolean) =>  setIsChangeQuantity(checked)}/>}
          />
        </div>
        <TableManualKeyboard
          mode={1}
          inputController={inputController0}
          onEnter={() => {
            onClose?.()
            handleEnter(isChangeQuantity())
          }}
        />
      </div>
    )
  );
};

export default SplitItemForm;