import { getDrinkClassname, getFoodClassname } from "@/react/EditMenuView/EditMenuView.tsx";
import { kitchenGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { clsx } from "clsx";
import _ from "lodash";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { dataCustomDish } from "@/react/OrderView/OrderView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import dialogService from "@/react/SystemService/dialogService.tsx";
import React from "react";


export const DialogOrderCustomDish = async (onConfirm: () => void) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className={`w-full bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start gap-[10px] min-w-[480px] h-full max-w-full max-h-full text-left text-sm text-gray-solid-gray-600-979797 font-mulish`}
      >
        {(!posSetting0()?.divArticleSettings?.drink?.groupPrinter || !posSetting0()?.divArticleSettings?.food?.groupPrinter ) &&
          <p className="relative text-sm text-red-600 px-6">{LL0().order.noPrinterAssigned()}</p>
        }
        <div
          className="self-stretch overflow-y-auto flex flex-col items-start justify-start px-6 gap-[16px] text-base text-black-solid-black-900-1e1e23">
          <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
            <div className="relative font-semibold md:text-xs">Food Printers:</div>
            <div className="flex flex-wrap items-center justify-start gap-[16px]">
              {kitchenGroupPrinters0().map((groupPrinter, index) => (
                <div key={index}
                     className="flex items-center justify-start text-mini text-black-solid-black-880-1d1d26">
                  <div
                    onClick={() => {
                      if (!posSetting0()?.divArticleSettings?.drink || !posSetting0()?.divArticleSettings) {
                        const drink = {
                          drink: {
                            groupPrinter: groupPrinter._id,
                            tax: dataCustomDish()?.DivArticleSettings?.[0]?.drink.tax
                          }
                        };
                        _.assign(posSetting0(), { divArticleSettings: { ...posSetting0()?.divArticleSettings, ...drink } });
                      } else _.assign(posSetting0()?.divArticleSettings?.drink, { groupPrinter: groupPrinter._id });
                    }}
                    className={clsx('shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1', getDrinkClassname(groupPrinter))}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                    <div className="relative z-[1]">{groupPrinter.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-6 px-6 gap-[16px] text-base text-black-solid-black-900-1e1e23">
          <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
            <div className="relative font-semibold md:text-xs">Drink Printers:</div>
            <div className="flex flex-wrap items-center justify-start gap-[16px]">
              {kitchenGroupPrinters0().map((groupPrinter, index) => (
                <div key={index}
                     className="flex items-center justify-start text-mini text-black-solid-black-880-1d1d26">
                  <div
                    onClick={() => {
                      if (!posSetting0()?.divArticleSettings?.food || !posSetting0()?.divArticleSettings) {
                        const food = {
                          food: {
                            groupPrinter: groupPrinter._id,
                            tax: dataCustomDish()?.DivArticleSettings?.[0]?.food.tax
                          }
                        };
                        _.assign(posSetting0(), { divArticleSettings: { ...posSetting0()?.divArticleSettings, ...food } });
                      } else _.assign(posSetting0()?.divArticleSettings?.food, { groupPrinter: groupPrinter._id });
                    }}
                    className={clsx('shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1', getFoodClassname(groupPrinter))}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                    <div className="relative z-[1]">{groupPrinter.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="self-stretch flex flex-col items-end justify-start z-[1]  px-6 pb-6 text-center text-sm text-white-solid-white-100-ffffff">
          <div
            className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
            onClick={async () => {
              if (!posSetting0()?.divArticleSettings?.drink?.groupPrinter || !posSetting0()?.divArticleSettings?.food?.groupPrinter ) return
                onConfirm()
              onClose?.()
            }}>
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}

