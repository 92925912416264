import { FunctionComponent, useEffect, useState } from 'react';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { Call } from '@/data/Call.ts';
import { Customer } from '@/data/Customer.ts';
import type { RxDocument } from 'rxdb';
import dayjs from 'dayjs';
import {LL0} from "@/react/core/I18nService.tsx";
import { companyInfo0, generalSetting0 } from "@/data/PosSettingsSignal.ts";

type NewDeliveryIncomingCallProps = {
  onClose?: () => void;
  onClickOrder?: (call: RxDocument<Call, {}>, customer: RxDocument<Customer>) => void;
  onAutoHide?: (onClose?: () => void) => void;
  incomingCall: RxDocument<Call, {}>;
}

const NewDeliveryIncomingCall: FunctionComponent =
  ({onClose, onClickOrder, onAutoHide, incomingCall}: NewDeliveryIncomingCallProps) => {
  const [customer, setCustomer] = useState<RxDocument<Customer> | undefined>();

  useEffect(() => {
    const fetchCustomer = async () => {
      setCustomer(await incomingCall.populate("customerId"))
    }
    onAutoHide?.(onClose);
    fetchCustomer();
  }, []);

  return (
    <div className="w-[334px] bg-white-solid-white-100-ffffff box-border h-[146px] flex flex-col items-start justify-center pt-[15px] pb-[17px] pr-[30px] pl-[17px] relative gap-[6px_0px] min-w-[330px] text-left text-sm text-gray-solid-gray-725-707070 font-mulish border-[4px] border-solid border-green-solid-green-460-61dc00">
      <i className="relative [text-decoration:underline] font-medium z-[0]">
        {LL0().onlineOrder.callSystem.incomingCall()}
      </i>
      <div className="!z-[0] flex flex-col items-start justify-start gap-[2px_0px] z-[1] text-black-solid-black-600-424242">
        <b className="w-[202px] relative inline-block h-[22px] shrink-0">
          {customer?.name} - {customer?.phoneNumber}
        </b>
        <div className="w-10 relative text-xs text-gray-solid-gray-725-707070 inline-block h-4 shrink-0">
          {companyInfo0()?.backendLanguage === 'de' ? dayjs.unix(incomingCall.date || 0).format("HH:mm") : dayjs.unix(incomingCall.date || 0).format("hh:mmA")}
        </div>
      </div>
      <div className="!z-[0] self-stretch flex flex-row items-center justify-center gap-[0px_12px] z-[2] text-center text-mini text-gray-solid-gray-600-979797">
        <div className="w-32 rounded-md box-border h-9 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-gray-solid-gray-600-979797"
             ref={makeRipple}
             onClick={onClose}
        >
          <b className="relative">{LL0().delivery.hide()}</b>
        </div>
        <div className="w-32 rounded-md box-border h-9 flex flex-row items-center justify-center p-2.5 text-blue-solid-blue-500-0051c1 border-[1px] border-solid border-blue-solid-blue-420-2979ff"
             ref={makeRipple}
             onClick={() => {
               onClose?.();
               if (!customer) return;
               onClickOrder?.(incomingCall, customer);
             }}
        >
          <b className="relative">{LL0().delivery.order()}</b>
        </div>
      </div>
      <img
        className="!z-[0] w-7 absolute !m-[0] top-[9px] right-[8px] h-7 overflow-hidden shrink-0 object-cover z-[3]"
        alt=""
        src="/new-delivery-call-icon@2x.png"
      />
    </div>
  );
};

export default NewDeliveryIncomingCall;
