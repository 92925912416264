import type { Eod } from "@/data/Eod.ts";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

export function Stamm_OrteFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      LOC_NAME: LOC_NAME(),
      LOC_STRASSE: LOC_STRASSE(),
      LOC_PLZ: LOC_PLZ(),
      LOC_ORT: LOC_ORT(),
      LOC_LAND: LOC_LAND(),
      LOC_USTID: LOC_USTID()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return eod.z;
  }

  function LOC_NAME() {
    // hint: "Name des Standortes",
    return companyInfo0().name;
  }

  function LOC_STRASSE() {
    // hint: "Straße",
    return companyInfo0().address;
  }

  function LOC_PLZ() {
    // hint: "Postleitzahl",
    return companyInfo0().zipCode;
  }

  function LOC_ORT() {
    // hint: "Ort",
    return companyInfo0().city;
  }

  function LOC_LAND() {
    // hint: "Land",
    return 'DEU';
  }

  function LOC_USTID() {
    // hint: "USTID",
    return companyInfo0().ustId;
  }
}
