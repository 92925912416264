import type { RxCollection, RxDatabase } from 'rxdb'

import { ORDER_LAYOUT_COLLECTION_NAME, OrderLayout, orderLayoutSchema } from './OrderLayout'
import { createCollection } from './utils/migration'

export async function createOrderLayoutCollection<T>(database: RxDatabase<T>): Promise<RxCollection<OrderLayout>> {
  return await createCollection({
    database: database,
    collection: OrderLayout,
    collectionName: ORDER_LAYOUT_COLLECTION_NAME,
    version: orderLayoutSchema.version,
    schema: orderLayoutSchema,
  })
}
