import axios, {AxiosError} from 'axios'
import uuid from "time-uuid"
import _ from 'lodash';
import type { IPayTxPayload, IPayTxResp, IXTerminal } from './IXTerminal';
import { captureException } from "@sentry/react";
import { getApiUrl } from "@/shared/utils.ts";

// https://docs.clover.com/docs/rest-pay-overview
const SANDBOX_URL = {
  PLATFORM_API: 'https://sandbox.dev.clover.com/connect',
}

const PRODUCTION_URL = {
  PLATFORM_API_US_CA: 'https://api.clover.com/connect',
}

export type CloverTerminalMetadata = {
  terminalId: string;
  posId: string;
  merchantId: string;
  clientId: string;
  timeoutInSeconds: number;
  enableTip?: boolean;
  enableSignature?: boolean;
}

interface ReceiptOptionResponse {
  deliveryOption: Array<{
    additionalData?: string,
    method: "NO_RECEIPT" | "PRINT" | "EMAIL" | "SMS"
  }>
}

export default class CloverTerminal implements IXTerminal {
  apiEndpoint?: string;
  requireHeaders?: Record<string, string>;
  timeoutMs: number;
  enableTip?: boolean;
  enableSignature?: boolean;
  merchantId: string;
  clientId: string;
  terminalId: string;
  posId: string;

  constructor(options: CloverTerminalMetadata) {
    console.log('options', options)
    const {
      merchantId,
      clientId,
      terminalId,
      posId,
      enableTip,
      enableSignature,
      timeoutInSeconds
    } = options
    this.merchantId = merchantId;
    this.clientId = clientId;
    this.terminalId = terminalId;
    this.posId = posId;
    this.enableTip = enableTip
    this.enableSignature = enableSignature
    this.timeoutMs = (timeoutInSeconds || 120) * 1000
  }

  eod(): void {

  }

  async _getAccessToken() {
    try {
      console.log('[clover] Getting access token...');
      const apiUrl = getApiUrl();
      const apiKey = '901f6c83-a227-4a22-8ca5-d78d2c182050'
      const {data} = await axios.get(`${apiUrl}/api/clover/get-token?merchant_id=${this.merchantId}&client_id=${this.clientId}&api_key=${apiKey}`);
      return data;
    } catch (e) {
      console.error('[clover] failed to get api access token', e)
    }
  }

  async _ensureRequireHeaders() {
    if (!this.requireHeaders) {
      console.log('[clover] requireHeaders is not initialized yet. initializing...')
      const {sandbox, accessToken} = await this._getAccessToken()
      this.apiEndpoint = sandbox ? SANDBOX_URL.PLATFORM_API : PRODUCTION_URL.PLATFORM_API_US_CA
      this.requireHeaders = {
        'Authorization': `Bearer ${accessToken}`,
        'X-Clover-Device-Id': this.terminalId,
        'X-POS-ID': this.posId,
      }
    }
  }

  async _post(apiPath: string, payload?: any, extraHeaders?: any) : Promise<any> {
    await this._ensureRequireHeaders();
    const apiUrl = `${this.apiEndpoint}${apiPath}`
    const config = {
      headers: Object.assign({}, this.requireHeaders, extraHeaders),
      timeout: this.timeoutMs
    }
    const {data} = await axios.post(apiUrl, payload, config)
    return data
  }

  async _readableError(e: AxiosError | Error) {
    console.error(_.get(e, 'response.data') || e.message)
    const errMsg = _.get(e, 'response.data.message') || _.get(e, 'response.data.error') || e.message
    return { error: errMsg }
  }

  async payTx({ amount, tip = 0 }: IPayTxPayload, onProgress?: Function) : Promise<{error: string} | IPayTxResp> {
    try {
      const amountInCents = Math.round(amount * 100)
      let tipAmountInCents;
      if (tip === 0) {
        if (this.enableTip) {
          const {response: customerTipInCents} = await this.readTip(amountInCents)
          tipAmountInCents = customerTipInCents;
        } else {
          tipAmountInCents = 0;
        }
      } else {
        tipAmountInCents = Math.round(tip * 100);
      }

      const payload = {
        amount: amountInCents, // cents
        tipAmount: tipAmountInCents, // cents
        final: true,
        externalPaymentId: new Date().getTime().toString()
      }
      const idempotencyKey = uuid()
      const extraHeader = { 'Idempotency-Key': idempotencyKey }
      console.log('Clover::transaction begin')
      const transaction = await this._post('/v1/payments', payload, extraHeader)
      console.log('Clover::transaction end', transaction)

      let signature;
      if (transaction.payment?.id) {
        if (this.enableSignature) {
          console.log('Clover::getASignature')
          signature = await this.getASignature()
          console.log('Clover::signature', signature)
        }

        console.log('Clover::receiptOptions')
        const options = await this.receiptOptions()
        console.log('Clover::receiptOptions', options)

        const method = options.deliveryOption[0].method
        console.log('Clover::printTxReceipt', method)
        if (method === "PRINT")
          await this.printTxReceipt(transaction.payment?.id, method)
      }
      this.welcome()
      return { response: Object.assign(transaction, signature, {type: 'clover'}) }
    } catch (e: any) {
      captureException(new Error('CloverTerminal: Failed to payTx', { cause: e}))
      console.log('Clover::transaction error', e)
      this.welcome()
      return this._readableError(e)
    }
  }

  // https://docs.clover.com/reference/refund
  async refundTx({refId, amount}: { refId: string, amount: number}, onProgress?: Function): Promise<any> {
     try {
       const idempotencyKey = uuid()
       const extraHeader = { 'Idempotency-Key': idempotencyKey }
       let amountInCents, fullRefund;
       if (amount) {
         amountInCents = amount * 100
         fullRefund = false;
       } else {
         fullRefund = true
       }
       const data = await this._post(`/v1/payments/${refId}/refunds`, {
         amount: amountInCents,
         fullRefund
       }, extraHeader)
       console.log('Clover::void receiptOptions')
       const options = await this.receiptOptions()
       console.log('Clover::void receiptOptions', options)

       const method = options.deliveryOption[0].method
       console.log('Clover::void printTxReceipt', method)
       if (method === "PRINT")
         await this.printRefundReceipt(data.refund.id, method)
       await this.displayMessage('The refund is complete.', true)
       this.welcome()
       return {
         response: {
           ...data,
           type: 'clover'
         }
       }
     } catch (e: any) {
       captureException(new Error('CloverTerminal: Failed to refundTx', { cause: e}))
       console.log('Clover::transaction error', e)
       this.welcome()
       return this._readableError(e)
     }
  }

  /**
   * https://docs.clover.com/reference/void
   */
  async voidTx({refId}: {refId: string}, onProgress?: Function) : Promise<any> {
    try {
      const idempotencyKey = uuid()
      const extraHeader = { 'Idempotency-Key': idempotencyKey }
      const data = await this._post(`/v1/payments/${refId}/void`, { voidReason: "USER_CANCEL" }, extraHeader)

      console.log('Clover::void', data)

      console.log('Clover::void receiptOptions')
      const options = await this.receiptOptions()
      console.log('Clover::void receiptOptions', options)

      const method = options.deliveryOption[0].method
      console.log('Clover::void printTxReceipt', method)
      if (method === "PRINT")
        await this.printVoidReceipt(data.paymentId, method)

      await this.displayMessage('The transaction was voided.', true)
      this.welcome()
      return { response: data }
    } catch (e: any) {
      captureException(new Error('CloverTerminal: Failed to voidTx', { cause: e}))
      console.log('Clover::void error', e)
      this.welcome()
      return this._readableError(e)
    }
  }

  async printTxReceipt(paymentId: string, method: string) : Promise<any> {
    try {
      return await this._post(`/v1/payments/${paymentId}/receipt`, {deliveryOption: {method}})
    } catch (e) {
      captureException(new Error('CloverTerminal: Failed to printTxReceipt', { cause: e}))
    }
  }

  async printRefundReceipt(refundId: string, method: string) : Promise<any> {
    try {
      return await this._post(`/v1/refunds/${refundId}/receipt`, {deliveryOption: {method}})
    } catch (e) {
      captureException(new Error('CloverTerminal: Failed to printRefundReceipt', { cause: e}))
    }
  }

  async printVoidReceipt(paymentId: string, method: string) : Promise<any> {
    try {
      return await this._post(`/v1/payments/${paymentId}/void/receipt`, {deliveryOption: {method}})
    } catch (e) {
      captureException(new Error('CloverTerminal: Failed to printVoidReceipt', { cause: e}))
    }
  }

  // https://docs.clover.com/reference/receipt_options
  async receiptOptions() : Promise<ReceiptOptionResponse> {
    const data = await this._post('/v1/device/receipt-options', {
      message: 'Please select addition receipt options'
    })
    return data;
  }

  async getASignature(): Promise<any> {
    const data = await this._post('/v1/device/read-signature', {
      gzip: false,
      signatureFormat: "JPG"
    })
    return data;
  }

  async isOnline() {
    try {
      const {data} = await this._post(`/v1/device/ping`)
      return data.connected
    } catch (e) {
      return false
    }
  }

  async cancel(metadata?: any, onProgress?: Function) {
    return this._post('/v1/device/cancel')
  }

  async test(onProgress?: Function) {
    return this.displayMessage('Hello...', true);
  }

  async readTip(baseAmount: number): Promise<any> {
    console.log('readTip', baseAmount)
    return this._post(`/v1/device/read-tip`, {baseAmount})
  }

  async printText(printDeviceId: string, text: string): Promise<any> {
    return this._post('/v1/device/print/text', {
      printDeviceId,
      text
    })
  }

  async printImage(printDeviceId: string, base64Img: string): Promise<any> {
    try {
      return await this._post('/v1/device/print/image', {
        printDeviceId,
        image: base64Img
      })
    } catch (e) {
      captureException(new Error('CloverTerminal: Failed to printImage', { cause: e}))
    }
  }

  async getPrinters(): Promise<any> {
    return this._post('/v1/device/printers')
  }

  async ping(): Promise<any> {
    return this._post('/v1/device/ping')
  }

  welcome(): void {
    this._post('/v1/device/welcome', {}).then(() => {
      console.log('Clover:Welcome completed')
    }).catch(e => {
      captureException(new Error('CloverTerminal: Failed to welcome', { cause: e}))
    })
  }

  async displayMessage(text: string, beep: boolean): Promise<any> {
    try {
      return await this._post('/v1/device/display', { text, beep })
    } catch (e) {
      captureException(new Error('CloverTerminal: Failed to displayMessage', { cause: e}))
    }
  }

  async reset(): Promise<any> {
    try {
      return await this._post('/v1/device/reset')
    } catch (e) {
      captureException(new Error('CloverTerminal: Failed to reset', { cause: e}))
    }
  }
}
