import type {FunctionComponent} from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import TextField from '@/react/core/TextField.tsx';
import _ from 'lodash';
import { productLayout0 } from '@/react/EditMenuView/EditMenuView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

export type EditTitleItemFormType = {
  className?: string;
};

const EditTitleItemForm: FunctionComponent<EditTitleItemFormType> = ({
  className = "",
}) => {
  return (
    <div className="!flex self-stretch hidden flex-col items-start justify-start py-4 px-3 gap-[16px] text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish">
      <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
        <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
          {LL0().article.type()}:
        </b>
        <FormControl
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
          variant="outlined"
        >
          <InputLabel color="primary" />
          <Select color="primary" size="small"
                  value={productLayout0()?.type || "Text"}
                  onChange={(e) => {
                    const _productLayout = productLayout0();
                    if (_productLayout) _productLayout.type = e.target.value as any;
                  }}
          >
            <MenuItem value="Text">{LL0().article.types.titleName()}</MenuItem>
            <MenuItem value="Article">{LL0().article.types.normalItem()}</MenuItem>
            <MenuItem value="NonFixed">{LL0().article.types.nonFixedItem()}</MenuItem>
            <MenuItem value="Extra">{LL0().article.types.extra()}</MenuItem>
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
        <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
          <span>{LL0().article.name()}</span>
          <span className="text-firebrick">*</span>
          <span>:</span>
        </b>
        <TextField
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={productLayout0()?.text}
          onChange={(e) => _.assign(productLayout0(), { text: e.target.value })}
        />
      </div>
      {/*<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">*/}
      {/*  <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">*/}
      {/*    <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">*/}
      {/*      Icon:*/}
      {/*    </b>*/}
      {/*    <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[8px] text-mini text-black-solid-black-880-1d1d26">*/}
      {/*      <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
      {/*        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
      {/*        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee box-border hidden z-[1] border-[2px] border-solid border-gray-solid-gray-150-eeeeee" />*/}
      {/*        <div className="relative z-[2]">Drink</div>*/}
      {/*      </div>*/}
      {/*      <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
      {/*        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
      {/*        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee box-border hidden z-[1] border-[2px] border-solid border-gray-solid-gray-150-eeeeee" />*/}
      {/*        <div className="relative z-[2]">Dessert</div>*/}
      {/*      </div>*/}
      {/*      <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
      {/*        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
      {/*        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee box-border hidden z-[1] border-[2px] border-solid border-gray-solid-gray-150-eeeeee" />*/}
      {/*        <div className="relative z-[2]">Bowl</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="self-stretch rounded-sm grid grid-cols-[repeat(auto-fit,minmax(48px,auto))] [grid-row-gap:0px] [grid-column-gap:0px]">*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon1@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon2@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon3@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon4@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon5@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon6@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon7@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon8@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon9@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon10@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon11@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon12@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon13@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon14@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon15@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon16@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon17@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon18@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon19@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon20@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon21@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon22@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon23@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon24@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="self-stretch rounded-sm grid grid-cols-[repeat(auto-fit,minmax(48px,auto))] [grid-row-gap:0px] [grid-column-gap:0px]">*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon25@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon26@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon27@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon28@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon29@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon30@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon31@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon32@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon33@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon34@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon35@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon36@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon37@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon13@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon38@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon39@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon40@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon41@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon42@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon43@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon44@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon45@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon46@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon47@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[102.56%] w-[102.08%] top-[-1.83%] right-[-0.35%] bottom-[-0.73%] left-[-1.73%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon48@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="self-stretch rounded-sm grid grid-cols-[repeat(auto-fit,minmax(48px,auto))] [grid-row-gap:0px] [grid-column-gap:0px]">*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon491@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon501@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon511@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon521@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon531@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon541@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon551@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon561@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon571@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon581@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon591@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon601@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon611@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon621@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon631@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon641@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon651@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon661@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon671@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon681@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon691@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon701@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon711@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon721@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-[8px] border-[0.6px] border-solid border-gainsboro">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-[103.7%] w-[103.15%] top-[-1.85%] right-[-1.4%] bottom-[-1.85%] left-[-1.75%] bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-100-ceffff" />*/}
      {/*      <img*/}
      {/*        className="relative w-10 h-10 object-cover z-[1] sm:w-9 sm:h-9 Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"*/}
      {/*        alt=""*/}
      {/*        src="/icon-cocktailscocktails-1-icon731@2x.png"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default EditTitleItemForm;
