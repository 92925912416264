import {type FunctionComponent} from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";

import { inventory0 } from '@/react/InventoryView/InventoryView.tsx'
import Input, {type InputController, inputController0} from '@/react/core/Input.tsx'
import {LL0} from "@/react/core/I18nService.tsx";
import {signal, useSignal} from "@/react/core/reactive.ts";
import InventoryKeyboard from "@inventory/InventoryKeyboard.tsx";
import { setInventoryActionsPage } from '@/data/InventoryActionHub.ts'
import { loginUser } from "@/data/UserSignal.ts";

type EditStockFormType = {
  showEditStockForm?: boolean;
  onClickSave?: (amount: number, reason: string) => void;
};
export const [password, setPassword] = signal<string>('');
const EditStockForm: FunctionComponent<EditStockFormType> = ({
  showEditStockForm,
  onClickSave
}) => {
  const [addStock, setAddStock] = useSignal<number | string>("");
  const [removeStock, setRemoveStock] = useSignal<number | string>("");
  const [reason, setReason] = useSignal<string>(LL0().inventory.expiredIngredient());
  const [inputControllers, setInputControllers] = useSignal<Array<InputController>>([]);

  return (
    showEditStockForm && (
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-center justify-center py-2 px-0 gap-[2px] text-left text-mini text-gray font-mulish">
        <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-2 gap-[8px]">
          <div className="self-stretch flex flex-row items-center justify-start pt-2 px-0 pb-1 gap-[12px]">
            <b className="relative flex items-center w-[133px] shrink-0">
              {LL0().inventory.currentStock()}:
            </b>
            <div className="relative font-semibold">{(inventory0()?.stock || 0) + Number(addStock()) - Number(removeStock())}</div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-blue-solid-blue-450-1271ff">
            <b className="relative flex items-center w-[123px] shrink-0">
              {LL0().inventory.addedStock()} (+)
            </b>
            <Input
              className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
              value={addStock().toString()}
              onChange={(value) => {
                const convertedNumber = parseFloat(value.replace(",", "."))
                if (isNaN(convertedNumber)) return;
                setAddStock(convertedNumber)
                setRemoveStock("")
              }}
                refInputController={i => setInputControllers(arr => [...arr, i])}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-red-solid-red-250-eb5558">
            <b className="relative">{LL0().inventory.removeStock()} (-)</b>
            <Input
              className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
              value={removeStock().toString()}
              onChange={(value) => {
                const convertedNumber = parseFloat(value.replace(",", "."))
                if (isNaN(convertedNumber)) return;
                setRemoveStock(convertedNumber)
                setAddStock("")
              }}
              refInputController={i => setInputControllers(arr => [...arr, i])}
            />
          </div>
          {!!removeStock() &&
            <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
              <b className='relative flex items-center w-[123px] shrink-0'>
                {LL0().inventory.removeReason()}:
              </b>
              <FormControl
                className='flex-1 font-mulish text-sm text-black-solid-black-600-424242'
                variant='outlined'
              >
                <InputLabel color='primary' />
                <Select color='primary' size='small'
                        value={reason() || LL0().inventory.expiredIngredient()}
                        onChange={e => setReason(e.target.value)}
                >
                  <MenuItem value={LL0().inventory.expiredIngredient()}>
                    {LL0().inventory.expiredIngredient()}
                  </MenuItem>
                  <MenuItem value={LL0().inventory.updateToMatch()}>
                    {LL0().inventory.updateToMatch()}
                  </MenuItem>
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          }
        </div>
        <InventoryKeyboard
          value=""
          inputController={inputController0}
          onEnter={() => {
            return onClickSave?.(Number(addStock()) - Number(removeStock()), addStock() ? `${LL0().inventory.updateBy()} ${loginUser().name}` : reason())
          }}
        />
      </div>
    )
  );
};

export default EditStockForm;
