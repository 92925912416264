// https://developer.myccv.eu/documentation/android_rest_beta_api/api_reference/api-reference/
import type { IPayTxPayload, IPayTxResp, IXTerminal } from './IXTerminal';
import axios from "axios";
import dayjs from "dayjs";


export type CCVMetadata = {
  host: string; // 127.0.0.1
  port: number; // 8080
}

export type Barcode = {
  type: string;
  nr: string;
  bitmap: string;
  bitmapHash: string;
}

export type Logo = {
  name: string;
  bitmap: string;
  bitmapHash: string;
}

export type PaymentReceipt = {
  formattedTextLines: Array<any>;
  signature: Array<any>;
  logo: Logo;
  barcode: Barcode;
  printingRequired: boolean;
}

export type PaymentResponse = {
  status: string; // SUCCESS, FAILURE
  failureReason: string; //
  // Success, Failure, PartialFailure, DeviceUnavailable,
  // DeviceConfigurationFailed, TimedOut, Aborted, FormatError,
  // ParsingError, ValidationError, MissingMandatoryData, Busy, Unknown, PcConfigurationFailed, NoActivePayment,
  // PaymentOnGoing, ReceiptCallFailed, PrintLastTicket, CommunicationError, AlreadyActivated
  amount: number;
  currency: string;
  stan: string;
  timestamp: string;
  requestId: string;
  cardBrand: string;
  cardPan: string;
  cardUID: string;
  customerReceipt: PaymentReceipt;
  merchantReceipt: PaymentReceipt,
  journalReceipt: PaymentReceipt,
  userAction: string; // DELIVERYBOX
  terminalId: string;
  ereceiptUrl: string;
  ejournal: string;
}

export type TextLine = {
  text: string;
  height: string; // DOUBLE || SINGLE
  width: string; // DOUBLE || SINGLE
  menuItem: string;
}

export type Violation = {
  field: string;
  message: string;
}

export type ViolationResponse = {
  status: string; // SUCCESS | FAILURE
  failureReason: string; //
  // Success, Failure, PartialFailure, DeviceUnavailable,
  // DeviceConfigurationFailed, TimedOut, Aborted, FormatError,
  // ParsingError, ValidationError, MissingMandatoryData,
  // Busy, Unknown, PcConfigurationFailed, NoActivePayment,
  // PaymentOnGoing, ReceiptCallFailed, PrintLastTicket,
  // CommunicationError, AlreadyActivated
  violations: Array<Violation>
}

export type EReceipt = {
  eReceiptTextPlacementFooter: Array<string>;
  eReceiptTextPlacementHeader: Array<string>;
  eReceiptTextPlacementSaleLine: Array<string>;
  additionalTextRequest?: Array<EReceiptAdditionalTextRequest>;
}

export type EReceiptAdditionalTextRequest = {
  geteReceiptTextPlacement: string; // HEADER | SALELINE | FOOTER
  text: Array<string>;
}

export type GermanEichrecht = {}

export type PaymentRequest = {
  supportMifareCards: boolean;
  ipAddress: string;
  socketMode: string; // SINGLE_SOCKET | DUAL_SOCKET
  terminalOperatingEnvironment: string; // ATTENDED | UNATTENDED
  terminalAccessProtocol: string; // OPI_NL | OPI_DE
  terminalLanguage: string
  shiftNumber: string;
  workstationId: string;
  port: number;
  compatibilityPort: number;
  terminalDisplayText: Array<TerminalDisplayText>;
  printerStatus: string; // AVAILABLE | AVAILABLE | PAPER_EMPTY | PAPER_LOW
  merchantEmailAddress: string;
  customerEmailAddress: string;
  amount: number;
  minimumCaptureAmount: number;
  currency: string;
  paymentType: string;
  // SALE, REFUND, PREAUTHORIZATION, EXTENDED_PREAUTHORIZATION, FINANCIAL_ADVICE, EXTENDED_FINANCIAL_ADVICE, CARD_CIRCUIT, VOID, RESERVATION, AUTHORISATION_BY_VOICE, AUTHORISE, CAPTURE, GIFT_CARD_BALANCE, ACTIVATE_RECHARGE_GIFT_CARD
  terminalEnvironment: string;
  requestId: string;
  referenceNumber: number;
  tokenType: string; // CARD, WEB, UNKNOWN
  authReference: string;
  authReferenceVersion: string;
  cardBrands: Array<string>;
  // GermanDebitELV, GermanDebitOLV, GermanDebitPOZ, GermanDebitEcCash, NationalDebit, girocardELV, girocardOLV, girocard, Geldkarte, EuroCard, MasterCard, Amex, VISA, VPAY, Diners, JCB, CUP, Maestro, Payback, ComfortCard, Yapital, Cash, Alipay, Bar, PBPAY, MyCard4u, PontosGiftCard, TeleCashGiftCard, EpayGiftCard, DKV, Novofleet, UTA, UTA Ful Select, Routex, LogPay, Roadrunner, viel, maes, ecmc, dc, GermanECcard, Geldkarte, Akzenta, Allcash, Allianzcard, BayWa, Boecker, BreuningerExtern, Breuninger, BSW, BWE, CardPayBonus, CardTech, CAST, CASTBonus, Conrad, Dankort, Douglas, DresdnerPlus, EAPS, euroShell CRT, euroShell Privat, EVoucher, Family dent, Fraenky-Noris, GDP, Hagebau, HappyDigit, HEM, Herkules, Hornbach, HornbachGutscheinkarte, HornbachProfiCard, HornbachProjektWeltkarte, IKEAFamilyPlus, IntercardCard, Polo, Praesentcard, RATENZ, RUEFACH, SGCard, ShoppingPlus, SOliver, TaxFree, VISAElektron, VISA electron, VWKundenclub, Westfalen, BankTransfer, GermanDebitELVFallback, girocardELVFallback, EuroCardFallback, MasterCardFallback, AmexFallback, VISAFallback, DinersFallback, JCBFallback, Unknown, Other
  germanEichrecht: GermanEichrecht,
  eReceipt: EReceipt
}

export type TerminalDisplayText = {}

export type TransactionInteraction = {

}

export type DeliveryBoxRequest = {
  goodsOrServicesDelivered: boolean;
}

export type TerminalRequest = {
  supportMifareCards: boolean;
  ipAddress: string;
  socketMode: string;
  terminalOperatingEnvironment: string;
  terminalAccessProtocol: string;
  terminalLanguage: string;
  shiftNumber: string;
  workstationId: string;
  port: number;
  compatibilityPort: number;
  terminalDisplayText: Array<TerminalDisplayText>;
  printerStatus: string; // AVAILABLE, UNAVAILABLE, PAPER_EMPTY, PAPER_LOW
  merchantEmailAddress: string;
  customerEmailAddress: string;
  paymentRequestId: string;
}

export type OpenPreAuthorisation = {

}

export type ReconciliationTotal = {
  amount: number;
  currency: string;
  paymentType: string;
  numberOfPayments: number;
}

export type TerminalResponse = {
  status: string;
  failureReason: string;
  dateOfShift: string;
  shiftNumber: number;
  transactionOverviewStatus: string; //
  clerkID: string;
  firstTransactionDate: string;
  lastTransactionDate: string;
  terminalTransactions: Array<TerminalTransactions>;
  cardUID: string;
  terminalId: string;
  terminalState: string;
  terminalAction: string;
  brands: Array<string>;
  requestTypes: Array<string>;
  reconciliation: Array<string>;
  customerReceipt: PaymentReceipt;
  merchantReceipt: PaymentReceipt;
  journalReceipt: PaymentReceipt;
  openPreAuthorisations: Array<OpenPreAuthorisation>;
  ejournal: string;
}

export type TerminalTransactions = {
  cardCircuit: string;
  // GermanDebitELV, GermanDebitOLV, GermanDebitPOZ, GermanDebitEcCash, NationalDebit, girocardELV, girocardOLV, girocard, Geldkarte, EuroCard, MasterCard, Amex, VISA, VPAY, Diners, JCB, CUP, Maestro, Payback, ComfortCard, Yapital, Cash, Alipay, Bar, PBPAY, MyCard4u, PontosGiftCard, TeleCashGiftCard, EpayGiftCard, DKV, Novofleet, UTA, UTA Ful Select, Routex, LogPay, Roadrunner, viel, maes, ecmc, dc, GermanECcard, Geldkarte, Akzenta, Allcash, Allianzcard, BayWa, Boecker, BreuningerExtern, Breuninger, BSW, BWE, CardPayBonus, CardTech, CAST, CASTBonus, Conrad, Dankort, Douglas, DresdnerPlus, EAPS, euroShell CRT, euroShell Privat, EVoucher, Family dent, Fraenky-Noris, GDP, Hagebau, HappyDigit, HEM, Herkules, Hornbach, HornbachGutscheinkarte, HornbachProfiCard, HornbachProjektWeltkarte, IKEAFamilyPlus, IntercardCard, Polo, Praesentcard, RATENZ, RUEFACH, SGCard, ShoppingPlus, SOliver, TaxFree, VISAElektron, VISA electron, VWKundenclub, Westfalen, BankTransfer, GermanDebitELVFallback, girocardELVFallback, EuroCardFallback, MasterCardFallback, AmexFallback, VISAFallback, DinersFallback, JCBFallback, Unknown, Other
  numberOfPayments: number;
  acquirer: string;
  amount: number;
  currency: string;
}

export type DisplayMessage = {
  text: string;
  textId: string;
  languageCode: string;
  displayText: string;
}

export type TokenResponse = {
  status: string;
  failureReason: string;
  token: string;
  cardUID: string;
  authReference: string;
  authReferenceVersion: string;
  journalReceipt: PaymentReceipt;
  customerReceipt: PaymentReceipt;
  currency: string;
  amount: number;
  ejournal: string;
}

export type TokenRequest = {
  supportMifareCards: boolean;
  ipAddress: string;
  socketMode: string;
  terminalOperatingEnvironment: string;
  terminalAccessProtocol: string;
  terminalLanguage: string;
  shiftNumber: string;
  workstationId: string;
  port: number;
  compatibilityPort: number;
  terminalDisplayText: Array<TerminalDisplayText>;
  printerStatus: string;
  merchantEmailAddress: string;
  customerEmailAddress: string;
  tokenType: string; // CARD, WEB, UNKNOWN
}

export default class CCVA920Terminal implements IXTerminal {
  cfg: any;
  apiEndpoint: string;
  requireHeaders: Record<string, string>;
  timeoutMs: number;

  constructor(props: any) {
    this.cfg = props;
    this.apiEndpoint = `http://${this.cfg.host}:${this.cfg.port}`;
    this.requireHeaders = {}
    this.timeoutMs = 7 * 60 * 1000; // 7 min
  }

  async _get(apiPath: string, extraHeaders?: any) : Promise<any> {
    const apiUrl = `${this.apiEndpoint}${apiPath}`
    const config = {
      headers: Object.assign({}, this.requireHeaders, extraHeaders),
      timeout: this.timeoutMs
    }
    const {data} = await axios.get(apiUrl, config)
    return data
  }

  async _post(apiPath: string, payload?: any, extraHeaders?: any) : Promise<any> {
    const apiUrl = `${this.apiEndpoint}${apiPath}`
    const config = {
      headers: Object.assign({}, this.requireHeaders, extraHeaders),
      timeout: this.timeoutMs
    }
    const {data} = await axios.post(apiUrl, payload, config)
    return data
  }

  /**
   * Cancel active transaction
   */
  _cancel() {
    return this._post('/terminal/payment/cancel', {})
  }

  // TERMINAL
  /**
   * Get available brands for your terminal
   */
  _getAvailableBrands() {
    return this._post('/terminal/brands', {
      "terminalOperatingEnvironment": "UNATTENDED",
      "terminalAccessProtocol": this.cfg.terminalAccessProtocol
    })
  }


  /**
   * Retrieve last ticket/receipt
   */
  _retrieveLastReceipt() {
    return this._post('/terminal/receipt/reprint', {
      "terminalOperatingEnvironment": "UNATTENDED",
      "terminalAccessProtocol": this.cfg.terminalAccessProtocol
    })
  }

  /**
   * Retrieve last display messages
   */
  _retrieveLastDisplayMessage() {
    return this._get('/terminal/lastDisplayMessage')
  }

  /**
   * Do a period closing
   */
  _periodClosing() {
    return this._post('/terminal/periodClosing', {
      "terminalOperatingEnvironment": "UNATTENDED",
      "terminalAccessProtocol": this.cfg.terminalAccessProtocol
    })
  }

  /**
   * Get terminal status
   */
  _terminalStatus() {
    return this._post('/terminal/status', {
      "terminalOperatingEnvironment": "UNATTENDED",
      "terminalAccessProtocol": this.cfg.terminalAccessProtocol
    })
  }

  cancel(payload: any, onProgress?: Function): Promise<any> {
    return this._cancel()
  }

  eod(): void {
    // TODO: (thinh) impl
  }

  isOnline(): Promise<boolean> {
    return this._terminalStatus()
  }

  async payTx(payload: IPayTxPayload, onProgress?: Function): Promise<{ error: string } | IPayTxResp> {
    const pl: Partial<PaymentRequest> = {
      amount: (payload.amount + (payload.tip || 0)), /*the amount send to CCV is dollar, not cent*/
      currency: "EUR",
      paymentType: "SALE",
    }
    const data = await this._post('/terminal/payment', pl);
    console.log('payTx.resp', data);
    const {status, result, failureReason} = data;
    // status = SUCCESS | FAILURE
    // TODO: (thinh) partial payment???
    if (status === "SUCCESS") {
      if (pl.amount === result.amount.value) {
        return {
          response: {
            type: "ccv",
            payment: {
              result: "SUCCESS",
              amount: payload.amount * 100 /*cents*/,
              taxAmount: 0,
              tipAmount: payload.tip * 100 /*cents*/,
              createdTime: dayjs().unix(),
              rawResult: result,
            }
          }
        }
      } else {
        return {
          response: {
            type: "ccv",
            payment: {
              result: "SUCCESS",
              amount: result.amount.value * 100 /*cents*/,
              taxAmount: 0,
              tipAmount: 0,
              createdTime: dayjs().unix(),
              rawResult: result,
            }
          }
        }
      }
    } else {
      return {error: `${failureReason}. ${result.errorText || ''}`}
    }
  }

  async refundTx(payload: any, onProgress?: Function): Promise<any> {
    console.log('[ccv] refundTx', payload)
    const rsp = await this._post('/terminal/refund', { amount: payload.amount, currency: "EUR" })
    console.log('[ccv] refundTx resp', rsp)
    const {status, result, failureReason} = rsp;
    if (status === "SUCCESS") {
      return {response: result}
    } else {
      return {error: `${failureReason}`}
    }
  }

  test(onProgress?: Function): Promise<any> {
    return this._terminalStatus();
  }

  voidTx(payload: any, onProgress?: Function): Promise<any> {
    return Promise.resolve(undefined);
  }

}