import { type FunctionComponent, useState, useCallback } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import SelectStaffPopup from "./SelectStaffPopup";
import PortalPopup from "./PortalPopup";
import SearchServicesPopup from "./SearchServicesPopup";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import SelectModifiersPopup from "@reservation2/SelectModifiersPopup.tsx";
import {
  addService, isFromWeb,
  findProductFromDisplayText, getServiceDisplayText,
  onConfirmModifiers,
  setCurrentProduct,
  setSearchServicePopupMode, setStaffForService
} from "@/data/ReservationHub.ts";
import { useComputed, useSignal } from "@/react/core/reactive.ts";

export type ReservationServiceFromType = {
  className?: string;
  service?: string;
  isAddStaff?: boolean;
  staffName?: string;
  displayText?: string;
  servicePrice?: string;
  note?: string;
  modifiers?: Array<any>;
  onRemove?: () => void;
  isMultipleStaffs?: boolean;
};

const ReservationServiceFrom: FunctionComponent<ReservationServiceFromType> = ({
  className = "",
  service,
  isAddStaff,
  staffName,
  displayText,
  servicePrice,
  note,
  modifiers,
  onRemove,
  isMultipleStaffs
}) => {
  const [isSelectStaffPopupOpen, setSelectStaffPopupOpen] = useState(false);
  const [isSearchServicesPopupOpen, setSearchServicesPopupOpen] =
    useState(false);
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const [isSelectModifiersPopupOpen, setSelectModifiersPopupOpen] =
    useState(false);

  const [selectedProduct, setSelectedProduct] = useSignal()
  const selectedProductDisplayText = useComputed(() => getServiceDisplayText(selectedProduct()))

  const openSelectModifiersPopup = useCallback(() => {
    setSelectModifiersPopupOpen(true);
  }, []);

  const closeSelectModifiersPopup = useCallback(() => {
    setSelectModifiersPopupOpen(false);
  }, []);

  const openSelectStaffPopup = useCallback(() => {
    setSelectStaffPopupOpen(true);
  }, []);

  const closeSelectStaffPopup = useCallback(() => {
    setSelectStaffPopupOpen(false);
  }, []);

  const openSearchServicesPopup = useCallback(() => {
    if (isFromWeb()) return
    console.log('openSearchServicesPopup', displayText);
    setCurrentProduct(findProductFromDisplayText(displayText));
    setSearchServicePopupMode("edit");
    setSearchServicesPopupOpen(true);
  }, [displayText])

  const closeSearchServicesPopup = useCallback(() => {
    setSearchServicesPopupOpen(false);
  }, []);

  const openDeleteConfirmationPopup = useCallback(() => {
    if (isFromWeb()) return
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  const setStaffForSingleService = useCallback((staff: string) => {
    console.log('setStaffForSingleService', displayText, staff);
    setStaffForService(displayText, staff);
  }, [displayText])

  return (
    <>
      <div
        className={`flex-1 rounded-lg bg-white-solid-white-400-f2f2f2 flex flex-row items-center justify-start text-left text-sm text-black-solid-black-900-1e1e23 font-mulish border-[1px] border-solid border-gray-solid-gray-350-ced1d7 ${className}`}
      >
        <div className="flex-1 flex flex-col items-start justify-start p-3 gap-[8px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
            <div className="flex-1 relative sm:text-smi mq768:text-xs h.414:text-xs">
              {service}
            </div>
            {(isMultipleStaffs && !isAddStaff) && (
              <b
                className="relative [text-decoration:underline] text-purple-solid-purple-9e5cfb cursor-pointer sm:text-smi mq768:text-xs h.414:text-xs"
                onClick={openSelectStaffPopup}
              >
                {staffName}
              </b>
            )}
            {(isMultipleStaffs && isAddStaff) && (
              <b
                className="relative [text-decoration:underline] text-blue-solid-blue-420-2979ff cursor-pointer sm:text-smi mq768:text-xs h.414:text-xs"
                onClick={openSelectStaffPopup}
              >
                + {LL0().reservation.addStaff()}
              </b>
            )}
          </div>
          <div
            className="self-stretch shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] rounded bg-white-solid-white-100-ffffff box-border h-[65px] flex flex-col items-start justify-center py-2 px-2.5 gap-[8px] cursor-pointer border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
            onClick={openSearchServicesPopup}
          >
            <div className="self-stretch flex-1 flex flex-row items-center justify-start gap-[4px]">
              <b className="relative sm:text-smi mq768:text-xs h.414:text-xs">
                {displayText}
              </b>
            </div>
            {modifiers?.length ? <div className="self-stretch flex flex-row flex-wrap items-start justify-start text-xs">
              {modifiers?.map(m => <div
                key={m.name}
                className="rounded bg-white-solid-white-400-f2f2f2 flex flex-row items-center justify-center p-1 gap-[4px] border-[1px] border-solid border-gray-solid-gray-175-dddddd">
                <div className="relative font-semibold">{m.name}</div>
              </div>)}
            </div> : null}
            {note ? <div className="self-stretch flex flex-row flex-wrap items-start justify-start text-xs">
              {note}
            </div> : null}
          </div>
        </div>
        <div
          className="self-stretch [background:linear-gradient(90deg,_#fff,_rgba(232,_238,_241,_0.35))] flex flex-row items-center justify-start py-0 px-2 cursor-pointer border-l-[1px] border-solid border-gray-solid-gray-350-ced1d7"
          onClick={openDeleteConfirmationPopup}
          ref={makeRipple}
        >
          <div className="w-6 relative rounded-81xl bg-white-solid-white-100-ffffff box-border h-6 cursor-pointer border-[1px] border-solid border-gray-solid-gray-480-b1b1b1">
            <img
              className="absolute top-[calc(50%_-_7px)] left-[calc(50%_-_7px)] w-3.5 h-3.5 object-cover"
              alt=""
              src="/iconred-close-icon@2x.png"
            />
          </div>
        </div>
      </div>
      {isSelectStaffPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSelectStaffPopup}
        >
          <SelectStaffPopup onClose={closeSelectStaffPopup} onConfirm={setStaffForSingleService}/>
        </PortalPopup>
      )}
      {isSearchServicesPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeSearchServicesPopup}
        >
          <SearchServicesPopup
            onClose={closeSearchServicesPopup}
            displayText={displayText}
            onConfirm={(product) => {
              if (product.activePopupModifierGroup) {
                setSelectedProduct(product)
                openSelectModifiersPopup()
              } else {
                addService(product)
              }
            }}

          />
        </PortalPopup>
      )}
      {isSelectModifiersPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSelectModifiersPopup}
        >
          <SelectModifiersPopup
            displayText={selectedProductDisplayText()}
            onClose={closeSelectModifiersPopup}
            onConfirm={(modifiers) => onConfirmModifiers(selectedProduct(), modifiers)}/>
        </PortalPopup>
      )}
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onConfirm={onRemove}/>
        </PortalPopup>
      )}
    </>
  );
};

export default ReservationServiceFrom;
