import type { Observable } from 'rxjs'

import { signal } from '@/react/core/reactive'

/**
 * Create a signal from Observable, by creating a new subscription.
 *
 * Return `[signal, subscription]`
 *
 * You can cleanup the subscription by calling `subscription.unsubscribe()`
 */
export function signalFromObs<T>(obs: Observable<T>) {
  const [val, setVal] = signal<T>()
  const sub = obs.subscribe(a => setVal(() => a))
  return [val, sub] as const
}
