import type { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { createCollection } from "@/data/utils/migration.ts";

const COLLECTION_NAME = 'check_points'

export const VERSION = 2
const checkPointSchema = {
  title: 'checkPoint schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    sequence: { type: 'string' },
    date: { type: 'number' },
  },
  indexes: []
} as const;

export interface CheckPoint {
  _id?: string;
  sequence?: string;
  date?: number;
}

export type CheckPointDocument = RxDocument<CheckPoint>;
export type CheckPointCollection = RxCollection<CheckPoint>;

export const CheckPointCollection: CheckPointCollection = {} as CheckPointCollection;
export const CheckPoint = CheckPointCollection;

export const createCheckPointCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: CheckPoint,
    collectionName: COLLECTION_NAME,
    version: VERSION,
    schema: checkPointSchema,
  });
}

//@ts-ignore
window.CheckPoint = CheckPoint;