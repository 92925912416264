import type { FunctionComponent } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LL0 } from '@/react/core/I18nService.tsx'
import { useSignal } from '@/react/core/reactive.ts'
import dayjs from 'dayjs'
import { filterInventoryValues, setFilterInventoryValues } from '@/data/InventoryHub.ts'
import {
  EditInventoryMode,
  editInventoryMode,
} from '@inventory/Popups/EditItemPopup/InventoryAddEditItemPopu.tsx'
import {
  filterDateStockHistory,
  setFilterDateStockHistory,
} from '@/data/InventoryActionHub.ts'
import { clsx } from 'clsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { LL2 } from "@/react/core/I18nBackend.tsx";

type InventoryDateChoosingPoType = {
  onClose?: () => void;
};

const InventoryDateChoosingPo: FunctionComponent<
  InventoryDateChoosingPoType
> = ({ onClose }) => {

  const startOfDay = dayjs().startOf('day').valueOf()
  const endOfDay = dayjs().endOf('day').valueOf()
  const yesterdayStart = dayjs().subtract(1, 'day').startOf('day').valueOf()
  const yesterdayEnd = dayjs().subtract(1, 'day').endOf('day').valueOf()
  const weekStart = dayjs().startOf('week').valueOf()
  const weekEnd = dayjs().endOf('week').valueOf()
  const monthStart = dayjs().startOf('month').valueOf()
  const monthEnd = dayjs().endOf('month').valueOf()

  const [date, setDate] =
    useSignal<{ from: number, to: number, type?: string }>({
      from: editInventoryMode() === EditInventoryMode.EDIT ? filterDateStockHistory()?.from : filterInventoryValues().date?.from || startOfDay,
      to: editInventoryMode() === EditInventoryMode.EDIT ? filterDateStockHistory()?.to : filterInventoryValues().date?.to || endOfDay,
      type: editInventoryMode() === EditInventoryMode.EDIT ? filterDateStockHistory()?.type : filterInventoryValues().date?.type,
    })

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className="relative rounded-md bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-10 px-6 pb-6 box-border gap-[24px] min-w-[340] h-full text-left text-base text-gray-solid-gray-750-696969 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[24px] hover:items-start hover:justify-start hover:rounded-md hover:pt-10 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[340] Mobile_480:h-full">
        <img
          className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 object-cover cursor-pointer z-[0]"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
          onClick={onClose}
        />
        <div
          className="self-stretch h-[148px] overflow-hidden shrink-0 flex flex-col items-start justify-start gap-[16px] w-auto z-[1] hover:flex hover:self-stretch hover:w-auto hover:h-[148px] hover:flex-col hover:gap-[16px] hover:items-start hover:justify-start">
          <div
            className="self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-4 gap-[16px] border-b-[1px] border-solid border-gray-solid-gray-300-dbdbdb">
            <div
              className="flex-1 h-16 flex flex-col items-start justify-center gap-[4px] Mobile_480:flex-col Mobile_480:gap-[8px] Mobile_480:items-start Mobile_480:justify-start">
              <div className="relative font-medium">{LL0().onlineOrder.from()}:</div>
              <div className="self-stretch">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  value={dayjs(date()?.from)}
                  onChange={(newValue: any) => {
                    const from = dayjs(newValue).valueOf()
                    setDate(prev => ({ ...prev, from, type: '' }))
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      size: 'small',
                      fullWidth: true,
                      color: 'primary'
                    }
                  }}
                />
              </div>
            </div>
            <div
              className="flex-1 h-16 flex flex-col items-start justify-center gap-[4px] Mobile_480:flex-col Mobile_480:gap-[8px] Mobile_480:items-start Mobile_480:justify-start">
              <div className="relative font-medium">{LL0().onlineOrder.to()}:</div>
              <div className="self-stretch">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  value={dayjs(date()?.to)}
                  onChange={(newValue: any) => {
                    const to = dayjs(newValue).valueOf()
                    setDate(prev => ({ ...prev, to, type: '' }))
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      size: 'small',
                      fullWidth: true,
                      color: 'primary'
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[12px] text-center text-sm text-black-solid-black-1000-000000">
            <div
              className="relative flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98] gap-[3px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0 hover:bg-gray-solid-gray-167-e0e0e0 hover:flex hover:flex-1 hover:h-[37px] hover:flex-row hover:gap-[3px] hover:items-center hover:justify-center hover:rounded-md hover:py-[9px] hover:px-4 hover:box-border hover:min-w-[98] hover:border-[1px] hover:border-solid hover:border-gray-solid-gray-167-e0e0e0"
              onClick={() => setDate({ from: startOfDay, to: endOfDay, type: 'today' })}
              ref={makeRipple}
            >
              {date().type === 'today' && <div
                className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff" />}
              <div className={clsx('relative font-medium', date().type === 'today' && "text-white")}>{LL0().onlineOrder.today()}</div>
            </div>
            <div
              className="relative flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98] gap-[3px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0 hover:bg-gray-solid-gray-167-e0e0e0 hover:flex hover:flex-1 hover:h-[37px] hover:flex-row hover:gap-[3px] hover:items-center hover:justify-center hover:rounded-md hover:py-[9px] hover:px-4 hover:box-border hover:min-w-[98] hover:border-[1px] hover:border-solid hover:border-gray-solid-gray-167-e0e0e0"
              onClick={() => setDate({ from: yesterdayStart, to: yesterdayEnd, type: 'yesterday' })}
              ref={makeRipple}
            >
              {date().type === 'yesterday' && <div
                className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff" />}
              <div className={clsx('relative font-medium', date().type === 'yesterday' && "text-white")}>{LL0().onlineOrder.yesterday()}</div>
            </div>
            <div
              className="relative flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98] gap-[3px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0 hover:bg-gray-solid-gray-167-e0e0e0 hover:flex hover:flex-1 hover:h-[37px] hover:flex-row hover:gap-[3px] hover:items-center hover:justify-center hover:rounded-md hover:py-[9px] hover:px-4 hover:box-border hover:min-w-[98] hover:border-[1px] hover:border-solid hover:border-gray-solid-gray-167-e0e0e0"
              onClick={() => setDate({ from: weekStart, to: weekEnd, type: 'thisWeek' })}
              ref={makeRipple}
            >
              {date().type === 'thisWeek' && <div
                className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff" />}
              <div className={clsx('relative font-medium', date().type === 'thisWeek' && "text-white")}>{LL0().onlineOrder.thisWeek()}</div>
            </div>
            <div
              className="relative flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98] gap-[3px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0 hover:bg-gray-solid-gray-167-e0e0e0 hover:flex hover:flex-1 hover:h-[37px] hover:flex-row hover:gap-[3px] hover:items-center hover:justify-center hover:rounded-md hover:py-[9px] hover:px-4 hover:box-border hover:min-w-[98] hover:border-[1px] hover:border-solid hover:border-gray-solid-gray-167-e0e0e0"
              onClick={() => setDate({ from: monthStart, to: monthEnd, type: 'thisMonth' })}
              ref={makeRipple}
            >
              {date().type === 'thisMonth' && <div
                className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff" />}
              <div className={clsx('relative font-medium', date().type === 'thisMonth' && "text-white")}>{LL0().onlineOrder.thisMonth()}</div>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-end pt-2 px-0 pb-0 z-[2] text-center text-sm text-white-solid-white-100-ffffff">
          <div
            className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
            onClick={() => {
              editInventoryMode() === EditInventoryMode.EDIT ?
                setFilterDateStockHistory(date())
                :
                setFilterInventoryValues(prev => ({ ...prev, date: date() }))
              onClose?.()
            }}
            ref={makeRipple}
          >
            <b className="relative">{LL0().inventory.apply()}</b>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default InventoryDateChoosingPo
