import React, { Fragment, type FunctionComponent, useCallback, useEffect, useState } from "react";
import AddNewTimeEntryPopup from "./AddNewTimeEntryPopup";
import PortalPopup from "./PortalPopup";
import EditTimeEntry from "./EditTimeEntry";
import { LL0 } from "@/react/core/I18nService.tsx";
import { endDate, formatTotalHours, staffReportData, startDate } from "@/react/StaffReportView/StaffReportView";
import _ from "lodash";
import dayjs from "dayjs";
import { signal, useSignal } from "@/react/core/reactive.ts";
import { toast } from "react-toastify";
import { loginUser } from "@/data/UserSignal.ts";

export const [isEditTimeEntryOpen, setEditTimeEntryOpen] = signal<boolean>(false);

const StaffReportTimeClock: FunctionComponent = () => {
  const [isAddNewTimeEntryPopupOpen, setAddNewTimeEntryPopupOpen] =
    useState(false);

  const [role, setRole] = useState(loginUser()?.role ?? 'user')
  const [shift, setShift] = useSignal<{id?: string, clockInTime?: number, clockOutTime?: number, vDate?: number}>({id: '', clockInTime: 0, clockOutTime: 0, vDate: 0})

  console.log(loginUser())

  useEffect(() => {
    const roleUser = loginUser()?.role
    setRole(roleUser as string)
  }, [loginUser()]);

  const openAddNewTimeEntryPopup = useCallback(() => {
    setAddNewTimeEntryPopupOpen(true);
  }, []);

  const closeAddNewTimeEntryPopup = useCallback(() => {
    setAddNewTimeEntryPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className="self-stretch bg-gray-solid-gray-180-ebecee flex flex-col items-start justify-start py-2 px-6 gap-[2px] text-left text-mini text-gray font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
        <div className="self-stretch flex flex-row items-center justify-between text-royalblue-200">
          <b className="relative leading-[30px] Tablet_768:text-sm Tablet_600:text-smi">
            {LL0().settings.generalSetting.useTimeClock()}
          </b>
          {
            role === 'manager' && (
              <div
                className="rounded flex flex-row items-center justify-center py-[3px] px-2 cursor-pointer text-sm text-chocolate-100 border-[1px] border-solid border-chocolate-200"
                onClick={() => {
                  openAddNewTimeEntryPopup()
                }}
              >
                <b className="relative Tablet_768:text-smi">+ {LL0().monthlyReport.newTimeEntry()}</b>
              </div>
            )
          }
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <b className="flex-1 relative Tablet_768:text-sm Tablet_600:text-smi">
            {LL0().timeClock.clockOutSuccessDialog.totalHours()}
          </b>
          <b
            className="relative text-base leading-[30px] text-firebrick text-right Tablet_768:text-sm Tablet_600:text-sm">
            {formatTotalHours(staffReportData()?.timeClock?.totalHours || 0)}
          </b>
        </div>
        {
          _.entries(staffReportData()?.timeClock?.timeClocksByDate)?.map(([date, timeClocks], index) => (
            <Fragment key={index}>
              {startDate().format('YYYY-DD-MM') !== endDate().format('YYYY-DD-MM') && (
                <div className="relative Tablet_768:text-smi Tablet_600:text-xs mb-2 mt-2">
                  {/*{dayjs.unix(timeClocks?.[0]!.vDate || 0).format('MMMM D')}*/}
                  {date}
                </div>
              )}
              {timeClocks.map(({
                _id,
                clockInTime,
                clockOutTime,
                vDate
              }, index) => (
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-0 gap-[8px] text-sm"
                     key={index}
                >
                    <img
                      className="relative w-5 h-5 object-cover cursor-pointer"
                      alt=""
                      src="/iconshift-icon@2x.png"
                      onClick={() => {
                        if (!role || role === 'user') return toast.error(`${LL0().timeClock.clockOutDialog.editShiftRight()}`)
                        setShift({id: _id, clockInTime: clockInTime, clockOutTime: clockOutTime, vDate: vDate})
                        if (!clockOutTime) return toast.info(`${LL0().timeClock.clockOutDialog.clockOutBeforeEditShift()}`)
                        setEditTimeEntryOpen(true)
                      }}
                    />
                  <div className="flex flex-col">
                    <div>
                      <div className="flex flex-row items-center justify-start gap-[2px]">
                        <div className="relative Tablet_768:text-smi Tablet_600:text-xs">
                          {LL0().monthlyReport.shift()} {index + 1}
                        </div>
                        <div className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs">
                          ({LL0().report.from()} {dayjs.unix(clockInTime || 0).format("HH:mm")} {LL0().report.to()} {clockOutTime ? dayjs.unix(clockOutTime).format("HH:mm") : "--:--"})
                        </div>
                      </div>
                    </div>
                    {!clockOutTime && (
                      <div
                        className="relative text-[#EB5458] Tablet_768:text-smi Tablet_600:text-xs font-mulish font-[300] italic mb-2 mt-2">
                        <b>{LL0().timeClock.clockOutDialog.notClockOut()}</b>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Fragment>
          ))
        }
      </div>
      {isAddNewTimeEntryPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeAddNewTimeEntryPopup}
        >
          <AddNewTimeEntryPopup onClose={closeAddNewTimeEntryPopup} />
        </PortalPopup>
      )}
      {isEditTimeEntryOpen() && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setEditTimeEntryOpen(false)}
        >
          <EditTimeEntry onClose={() => setEditTimeEntryOpen(false)} data = {shift()}/>
        </PortalPopup>
      )}
    </>
  );
};

export default StaffReportTimeClock;
