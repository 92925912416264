import { captureMessage } from '@sentry/react'
import dayjs from 'dayjs'
import debug from 'debug'
import uuid from 'time-uuid'

import { generateSignalDataAccess } from '@/react/utils/generateSignalDataAccess'

import { getDeviceId } from '@/shared/getDeviceId'
import { StaffStatus, TableStaffStatus } from './TableStaffStatus'

const log = debug('data:tableStaffStatus')

export const [tableStaffStatus0, makeTableStaffStatusAvailable] = generateSignalDataAccess(TableStaffStatus)

export async function recordTableStaffStatus(table: string, status: StaffStatus, orderId?: string) {
  const recordId =
    (await TableStaffStatus.findOne({ selector: { table } })
      .exec()
      .then(r => r?._id)) || uuid()

  const doc: TableStaffStatus = {
    _id: recordId,
    table,
    status,
    orderId,
    updatedAt: Date.now(),
    updatedOn: new Date().toISOString(),
  }
  const record = await TableStaffStatus.incrementalUpsert(doc)
  log(`⚡ Table ${table}: ${status}`)
  return record
}

export async function isTableHasStaffEditing(table?: string) {
  const records = await TableStaffStatus.find({ selector: { table } }).exec()
  if (records.length > 1) {
    const msg = `🔥 Found multiple status records for table ${table}`
    log(msg, records)
    captureMessage(msg, 'warning')
  }
  const record = records[0]
  return (
    !!record &&
    record.status !== StaffStatus.NORMAL &&
    // Only if the status is updated by other device
    record.updatedOn !== getDeviceId() &&
    // Only of the status is updated within 1 minutes
    dayjs.unix(record.updatedAt).isAfter(dayjs().subtract(1, 'minutes'))
  )
}

export async function clearStaffStatusOnAllTables() {
  const removedRecords = await TableStaffStatus.find({ selector: { updatedOn: getDeviceId() } }).incrementalRemove()
  if (removedRecords.length > 0) log('🧹 Cleared all table status', removedRecords)
}

Object.assign(window, { tableStaffStatus0 }) // Make available global
