import { rnHost } from "@/shared/webview/rnwebview.ts";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { convertToBase64Png, createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import { captureException } from "@sentry/react";
import EscPrinter from "@/shared/printer/node-thermal-printer";
import { currentPrinter } from "@/react/Printer/PrinterSettingView.tsx";

export async function printUsb(data: {payload:string}){
  try {
    await rnHost.printUsb(data)
  }catch (e) {
    notificationToast("Connection failed", {
      type: "error",
      autoClose: 1000 * 60
    })
  }
}

export const testUSBPrinter = async () => {
  const printer = createPrinter();
  await printer.println("TEST USB PRINTER");
  const raster: Raster = (await printer.print())!;
  const printer2 = new EscPrinter(currentPrinter());
  await printer2.printRaster(raster);
  await printer2.print();
}