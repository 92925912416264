import { type FunctionComponent, useState } from 'react';
import UserInfo from '@dashboard/UserInfo';
import Rooms from '@dashboard/Rooms';
import DashboardButtons from '@dashboard/DashboardButtons.tsx';
import clsx from 'clsx';
import { LL0 } from '@/react/core/I18nService.tsx';
import { dashboardNotifications, setDashboardNotifications } from "@/react/FloorPlanView/Noti.ts";
import { isInSrmTrainingMode } from '@/srm/signals';

const DashboardLeftPanel: FunctionComponent = () => {
  const LL = LL0()
  const [isRoomAccordionOpened, setIsRoomAccordionOpened] = useState<boolean>(true);

  return (
    <div
      className="no-scrollbar self-stretch bg-basic-color-white w-[16rem] overflow-y-auto shrink-0 flex flex-col items-start justify-start text-left text-base text-gray-200 font-mulish">
      <UserInfo/>
      {dashboardNotifications().length > 0 &&
          <div
              className="mx-1 mb-1 relative self-stretch rounded-10xs bg-yellow-solid-yellow-fdffa6-180 flex flex-col items-start justify-center py-1.5 pr-1 pl-1.5 p gap-[5px]">
            {dashboardNotifications().map(({options: {type}, text}, index) =>
              <div
                className={clsx('self-stretch relative [display:-webkit-inline-box] font-medium overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical] text-3xs',
                  type === "error" ? "text-[#EB5458]" : "text-[#424242]")}
                key={index}
              >
                {text && <i className='mt-1.5 w-full font-light text-3xs'>{text}</i>}
              </div>)
            }
              <img
                  className="rounded-full absolute my-0 mx-[!important] top-[2px] right-[1px] w-3.5 h-[13.1px] object-cover opacity-[0.9] z-[2]"
                  alt=""
                  src="/icondashboard-scrollclose-icon@2x.png"
                  onClick={() => setDashboardNotifications([])}
              />
          </div>}
      {isInSrmTrainingMode() && <div className="self-stretch flex flex-col items-center justify-center pt-0 px-1 pb-1">
        <div
          className="self-stretch rounded-10xs bg-cyan-solid-cyan-250-8aeaff flex flex-row items-center justify-start py-1 pr-1 pl-1.5 gap-[5px]">
          <img
            className="w-[22px] relative h-[22px] object-cover"
            alt=""
            src="/icontraining-mode-icon@2x.png"
          />
          <i
            className="!text-[10px] flex-1 relative [display:-webkit-inline-box] font-medium overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
            {LL0().settings.inTrainingMode()}
          </i>
        </div>
      </div>}
      <div
        className='self-stretch bg-gray-solid-gray-150-eeeeee shadow-[0px_0.2px_1.2px_rgba(0,_0,_0,_0.3)] flex flex-row items-center justify-between py-0.5 px-1 text-xs text-blue-solid-blue-400-0091ff'
        onPointerDown={() => setIsRoomAccordionOpened(prev => !prev)}
      >
        <div className='flex-1 flex flex-row items-center justify-start gap-[8px]'>
          <img
            className='relative w-[22px] h-6 object-cover'
            alt=''
            src='/iconstore-icon@2x.png'
          />
          <b className="flex-1 relative">{LL.restaurant.floorPlan()}</b>
        </div>
        <img
          className={clsx("relative w-[18px] h-[18px] object-cover transition-all",
            isRoomAccordionOpened && "rotate-180")}
          alt=""
          src="/iconarrow-down-icon-2--black@2x.png"
        />
      </div>
      {isRoomAccordionOpened &&
          <Rooms/>
      }
      <div className="self-stretch flex flex-col space-y-[10px] text-center text-sm text-materialize-grey-darken-3">
        <DashboardButtons/>
      </div>
    </div>
  );
};

export default DashboardLeftPanel;
