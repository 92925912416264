import TimeManagementClockTime from "@time-management/TimeManagementClockTime.tsx";
import TimeManagementTableItem from "@time-management/TimeManagementTableItem.tsx";
import type { DailyShift } from "@/react/TimeManagementView/TimeManagementView.logic.ts"

type TimeManagementRowProps = DailyShift & {
  date: string;
  isShowDate?: boolean;
}

const TimeManagementRow = ({
                             shiftNumber,
                             startTime,
                             endTime,
                             clockIn,
                             clockOut,
                             totalHours,
                             isPlus,
                             date,
                             isShowDate
                           }: TimeManagementRowProps) => {
  return <>
    <TimeManagementTableItem
      text={isShowDate ? date : ""}
      showPlusIcon={false}
      showText
    />
    <TimeManagementTableItem
      text={shiftNumber.toString()}
      showPlusIcon={isPlus}
      showText
    />
    <TimeManagementTableItem
      text={totalHours}
      showPlusIcon={false}
      showText
    />
    <TimeManagementClockTime
      time={startTime || ""}
      isClockIn={clockIn}
      isClockOut={false}
    />
    <TimeManagementTableItem
      text="-"
      showPlusIcon={false}
      showText
    />
    <TimeManagementClockTime
      time={endTime || ""}
      isClockIn={false}
      isClockOut={clockOut}
    />
  </>
}

export default TimeManagementRow;