import dayjs from "dayjs";
import { formatCurrency } from "@/shared/utils";
import type { Eod } from "@/data/Eod.ts";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";
import { getInflateReport, getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";

const Z_Zahlart = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse",
    default: '1'
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses",
    mapping: 'end',
    format: d => dayjs(d).toISOString()
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses",
    mapping: 'z'
  },
  ZAHLART_TYP: {
    type: "string",
    maxLength: 25,
    hint: "Typ der Zahlart"
  },
  ZAHLART_NAME: {
    type: "string",
    maxLength: 60,
    hint: "Name der Zahlart"
  },
  Z_ZAHLART_BETRAG: {
    type: "number",
    places: 2,
    hint: "Betrag",
    format: (n: number) => formatCurrency(n)
  }
}


export function Z_ZahlartFactory(eod: Eod) {
  let paymentType: string;
  let paymentAmount: number;
  let paymentReport;
  return build()

  function build() {
    const report = getInflateReport(eod);
    const paymentReport = report?.paymentReport;

    if (!paymentReport) return []
    const paymentTypes = Object.keys(paymentReport!);
    const rows = [];
    for (const _type of paymentTypes) {
      paymentType = _type;
      paymentAmount = paymentReport![paymentType].excludeTipValue;
      rows.push(buildSingle());
    }
    return rows;
  }
  function buildSingle() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      ZAHLART_TYP: ZAHLART_TYP(),
      ZAHLART_NAME: ZAHLART_NAME(),
      Z_ZAHLART_BETRAG: Z_ZAHLART_BETRAG()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses"
    return eod.z;
  }

  function ZAHLART_TYP() {
    // hint: "Typ der Zahlart"
    return paymentType;
  }

  function ZAHLART_NAME() {
    // hint: "Name der Zahlart"
    return '';
  }

  function Z_ZAHLART_BETRAG() {
    // hint: "Betrag"
    return paymentAmount;
  }
}
