import type { RxCollection, RxJsonSchema } from 'rxdb'

export const PAYMENT_COLLECTION_NAME = 'payments'

export enum PaymentType {
  None = 'none',
  Debit = 'debit',
  Credit = 'credit',
  Cash = 'cash',
  Debitor = 'debitor',
}

export interface Payment {
  _id: string
  name?: string
  icon?: string
  editable?: boolean
  terminalIds?: string[]
  isAutoApplyTerminal?: boolean
  type?: PaymentType
  enableTip: boolean
}
export const paymentSchema: RxJsonSchema<Payment> = {
  title: 'payment schema',
  version: 5, // increment this if schema changes
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    icon: { type: 'string', maxLength: 50 },
    editable: { type: 'boolean', maxLength: 50 },
    terminalIds: {
      type: 'array',
      items: {
        type: 'string',
        maxLength: 24,
      },
    },
    isAutoApplyTerminal: { type: 'boolean' },
    type: { type: 'string' },
    enableTip: { type: 'boolean' },
  },
  indexes: [],
} as const

export const Payment = {} as RxCollection<Payment>

Object.assign(window, { Payment }) // Make available globally
