import type { RxCollection, RxDatabase, RxDocument } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const INVENTORY_ACTION_COLLECTION_NAME = 'inventory_actions'

export const VERSION = 1

const inventoryActionSchema = {
  title: 'inventoryAction schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    inventoryId: { type: 'string' },
    type: { type: 'string' },
    amount: { type: 'number' },
    date: { type: 'number' },
    reason: { type: 'string' },
    orderId: { type: 'string' },
    productInfo: {
      type: 'object',
      properties: {
        productId: { type: 'string' },
        quantity: { type: 'number' },
      },
    }
  },
  indexes: []
} as const;

export interface InventoryAction {
  _id: string;
  inventoryId: string;
  type: 'add' | 'remove';
  amount: number;
  date: number;
  reason: string;
  orderId?: string;
  productInfo?: {
    productId: string;
    quantity: number;
  }
}

export type InventoryActionDocument = RxDocument<InventoryAction>;
export type InventoryActionCollection = RxCollection<InventoryAction>;

export const InventoryActionCollection: InventoryActionCollection = {} as InventoryActionCollection;
export const InventoryAction = InventoryActionCollection;

export const createInventoryActionCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: InventoryAction,
    collectionName: INVENTORY_ACTION_COLLECTION_NAME,
    version: VERSION,
    schema: inventoryActionSchema,
  });
}
