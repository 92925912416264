import dayjs from 'dayjs'
import _ from 'lodash'
import uuid from 'time-uuid'

import { invoiceGroupPrinters0 } from '@/data/GroupPrinterHub'
import { generalSetting0 } from '@/data/PosSettingsSignal'
import { PrintScripts } from '@/data/PrintScripts'
import { getMappedPrinter } from '@/react/Printer/print-utils'
import EscPrinter from '@/shared/printer/node-thermal-printer'
import type { ScriptedRaster } from '@/shared/printer/types'

export async function printRaster(raster: ScriptedRaster) {
  const groupPrinter = invoiceGroupPrinters0()[0]
  const invoicePrinter = getMappedPrinter(groupPrinter)
  if (invoicePrinter) {
    const printer = new EscPrinter(invoicePrinter)
    await printer.printRaster(raster)
    await printer.print()
  }

  if (!generalSetting0()?.virtualPrinter) {
    await PrintScripts.insert({
      _id: uuid(),
      date: dayjs().unix(),
      scripts: _.cloneDeep(raster.scripts),
    })
  }
}
