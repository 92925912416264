import {type FunctionComponent} from "react";
import {
  Select,
  InputLabel,
  FormHelperText,
  FormControl, MenuItem,
} from "@mui/material";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {useSignal} from "@/react/core/reactive.ts";
import {clsx} from "clsx";
import {setShowSignInfo} from "@sign-in/SignUpWrapper.tsx";
import Input from "@/react/core/Input.tsx";
import {handleShowKeyboardSignIn, setInputControllers} from "@/react/SignInView/SignInView.tsx";

const SignUpFull: FunctionComponent = () => {
  const [signUpShowFull, setSignUpShowFull] = useSignal<boolean>(false)
  return (
    <div
      className="self-stretch rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-start py-7 px-[27px] gap-[14px] text-left text-mini text-white-solid-white-100-ffffff font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[10px]"
           onClick={handleShowKeyboardSignIn}
      >
        <div className="relative font-medium">Your store’s name:</div>
        <Input
          className="h-10 bg-[transparent] self-stretch font-mulish italic text-smi text-gray"
          refInputController={i => setInputControllers(() => [i])}
          textClass='!text-white'
          caretClass='!bg-white'
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[10px]"
           onClick={handleShowKeyboardSignIn}
      >
        <div className="relative font-medium">Store’s address:</div>
        <Input
          className="h-10 bg-[transparent] self-stretch font-mulish italic text-smi text-gray"
          refInputController={i => setInputControllers(() => [i])}
          textClass='!text-white'
          caretClass='!bg-white'
        />
      </div>
      <div
        className={clsx("cursor-pointer rounded-81xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[11px] px-6 box-border gap-[4px] text-center text-lg",
          signUpShowFull() && 'hidden'
        )}
        ref={makeRipple}
        onClick={() => setSignUpShowFull(true)}
      >
        <div className="relative font-semibold">Show more</div>
        <img
          className="relative w-5 h-5 overflow-hidden shrink-0"
          alt=""
          src="/arrow-down-icon.png"
        />
      </div>

      <div className={clsx("self-stretch flex flex-col items-start justify-start gap-[10px]",
        !signUpShowFull() && 'hidden'
      )}
           onClick={handleShowKeyboardSignIn}
      >
        <div className="relative font-medium">Your phone number:</div>
        <Input
          className="h-10 bg-[transparent] self-stretch font-mulish italic text-smi text-gray"
          refInputController={i => setInputControllers(() => [i])}
          textClass='!text-white'
          caretClass='!bg-white'
        />
      </div>
      <div className={clsx("self-stretch flex flex-col items-start justify-start gap-[10px]",
        !signUpShowFull() && 'hidden'
      )}
           onClick={handleShowKeyboardSignIn}
      >
        <div className="relative font-medium">Country:</div>
        <FormControl
          className="self-stretch font-mulish text-lg text-white-solid-white-100-ffffff"
          variant="outlined"
        >
          <InputLabel color="primary"/>
          <Select color="primary" className='h-10' defaultValue={'vn'}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c2c2c2',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#1976d2'
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                    '.MuiInput-root': {
                      color: 'white',
                    },
                  }}
           >
            <MenuItem value={'vn'}>VietNam</MenuItem>
          </Select>
          <FormHelperText/>
        </FormControl>
      </div>
      <div
        className={clsx("cursor-pointer rounded-41xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] w-[162px] h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[11px] px-2.5 box-border text-center text-lg",
          !signUpShowFull() && 'hidden'
        )}
        onClick={() => setShowSignInfo(true)}
        ref={makeRipple}
      >
        <div className="flex-1 relative font-semibold">Confirm</div>
      </div>
    </div>
  );
};

export default SignUpFull;
