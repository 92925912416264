import React, { type FunctionComponent } from "react";
import {
  cancelProcessingPayment,
  closeDialogProcessTerminalPayment,
  dialogProcessTerminalPaymentState as dlgState,
} from "@/react/PaymentView/PaymentView.tsx";
import { useComputed } from "@/react/core/reactive.ts";
import _ from 'lodash';
import { CircularProgress } from "@mui/material";
import { LL0 } from "@/react/core/I18nService.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type PaymentProcessingPopUpType = {
};

const PaymentProcessingPopUp: FunctionComponent<PaymentProcessingPopUpType> = ({
}) => {
  const response = useComputed(() => _.get(dlgState(), 'response', { }))
  const error = useComputed(() => _.get(dlgState(), 'error'))
  const type = useComputed(() => _.get(response(), 'type', ''))
  const isSimulatorType = useComputed(() => type() === 'simulator')
  const isCloverType = useComputed(() => type() === 'clover')
  const isZvtType = useComputed(() => type() === 'zvt')
  const isCCV = useComputed(() => type() === 'ccv')
  const payment = useComputed(() => _.get(response(), 'payment', { result: '', amount: 0, tipAmount: 0 }))
  const approved = useComputed(() => payment().result === 'SUCCESS')
  const amount = useComputed(() => _.round((payment().amount || 0) / 100, 2))
  const tipAmount = useComputed(() => _.round((payment().tipAmount || 0) / 100, 2))
  const status = useComputed(() => _.get(dlgState(), 'status', ''))
  const processing = useComputed(() => _.get(dlgState(), 'processing', false))

  return (
    <div className="relative rounded-sm [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)_inset] w-[375px] flex flex-col items-center justify-start pt-[35px] px-0 box-border gap-[25px] max-w-full overflow-auto text-center text-lg font-mulish min-h-[250px]">
      {processing()
        ? <><div>
            <b className="relative z-[0]">{LL0().payment.processingPayment()}...</b>
            <p>{status()}</p>
            <div>
                <CircularProgress
                  className="mt-3"
                  style={{width: '97px', height: '97px'}}
                  color="primary"
                  thickness={2}
                  variant="indeterminate"/>
            </div>
          </div>
          <div className="flex-grow"></div>
          <div className="mt-2 w-full h-[50px]">
            <button className="w-full h-full bg-red-500 cursor-pointer text-white"
                    onClick={cancelProcessingPayment}>
              {LL0().ui.cancel()}
            </button>
          </div>
        </>
        : approved()
          ? <div className="w-full">
              <b className="relative z-[0] text-blue-500 mb-3">{LL0().payment.approved()}</b>
              <div className="mt-3 flex flex-row justify-center">
                <img className="relative w-[97px] h-[97px] object-cover z-[1]" alt="" src="/payment-check.svg"/>
              </div>

              {isSimulatorType() && <>
                <div className="mt-2 pl-4 pr-4">
                  <p className="flex flex-row justify-between">
                    <span className="fw-600">{LL0().orderHistory.amount()}:</span>
                    <span>{LL3().format.currency(amount())}</span>
                  </p>
                  <p className="flex flex-row justify-between">
                    <span className="fw-600">{LL0().payment.tipAmount()}:</span>
                    <span>{LL3().format.currency(tipAmount())}</span>
                  </p>
                </div>
              </>}

              {isCloverType() && <>
                <div className="mt-2 pl-4 pr-4">
                  <p className="flex flex-row justify-between">
                    <span className="fw-600">{LL0().orderHistory.amount()}:</span>
                    <span>{LL3().format.currency(amount())}</span>
                  </p>
                  <p className="flex flex-row justify-between">
                    <span className="fw-600">{LL0().payment.tipAmount()}:</span>
                    <span>{LL3().format.currency(tipAmount())}</span>
                  </p>
                </div>
              </>}

              {isZvtType() && <>
                <div className="mt-2 pl-4 pr-4">
                  <p className="flex flex-row justify-between">
                    <span className="fw-600">{LL0().orderHistory.amount()}:</span>
                    <span>{LL3().format.currency(amount())}</span>
                  </p>
                  <p className="flex flex-row justify-between">
                    <span className="fw-600">{LL0().payment.tipAmount()}:</span>
                    <span>{LL3().format.currency(tipAmount())}</span>
                  </p>
                </div>
              </>}

            {isCCV() && <>
              <div className="mt-2 pl-4 pr-4">
                <p className="flex flex-row justify-between">
                  <span className="fw-600">{LL0().orderHistory.amount()}:</span>
                  <span>{LL3().format.currency(amount())}</span>
                </p>
                <p className="flex flex-row justify-between">
                  <span className="fw-600">{LL0().payment.tipAmount()}:</span>
                  <span>{LL3().format.currency(tipAmount())}</span>
                </p>
              </div>
            </>}


            <div className="mt-2 w-full h-[50px]">
                <button className="w-full h-full bg-blue-500 cursor-pointer text-white"
                        onClick={dlgState().onComplete}>
                  {LL0().payment.complete()}
                </button>
              </div>
            </div>
          : <>
              <div>
                <b className="relative z-[0] text-red-500">{LL0().payment.error()}: {error()}</b>
              </div>
              <div className="flex-grow"></div>
              <div className="mt-2 w-full h-[50px]">
                <button className="w-full h-full bg-red-500 cursor-pointer text-white"
                        onClick={closeDialogProcessTerminalPayment}>
                  {LL0().ui.close()}
                </button>
              </div>
            </>
      }
    </div>
  );
};

export default PaymentProcessingPopUp;
