import { initializeApp } from 'firebase/app'
import { fetchAndActivate, getAll, getRemoteConfig, type RemoteConfig, type RemoteConfigSettings } from 'firebase/remote-config'
import { useEffect, useState } from 'react'
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";

const firebaseConfig = {
  apiKey: 'AIzaSyDNSGIqMmFjK8lTlMtlcqRWqfEreGRwfuU',
  authDomain: 'pos-rn-da210.firebaseapp.com',
  projectId: 'pos-rn-da210',
  storageBucket: 'pos-rn-da210.appspot.com',
  messagingSenderId: '1066292922307',
  appId: '1:1066292922307:web:1b84b3f6f5645923529f76',
  measurementId: 'G-CP01PE4M83',
}

const defaultSettings: Partial<RemoteConfigSettings> = {
  minimumFetchIntervalMillis: 300_000, // 5m. The default is 12h
}
// TODO: add more remote config here
const defaultConfig: RemoteConfig['defaultConfig'] = {
  COUNTRY: 'en',
}

export let remoteConfig: RemoteConfig | null
export let allConfig: ReturnType<typeof getAll> = {};
export const remoteConfigLock = new MultiAwaitLock(true);

/** Initialize Firebase & Remote Config */
export function useFirebase() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    ;(async () => {
      const app = initializeApp(firebaseConfig)
      remoteConfig = getRemoteConfig(app)

      Object.assign(remoteConfig.settings, defaultSettings)
      Object.assign(remoteConfig.defaultConfig, defaultConfig)

      await fetchAndActivate(remoteConfig)

      setLoaded(true)

      console.log('✅ RemoteConfig loaded')

      // Log all remote config
      // TODO: remove this?
      allConfig = getAll(remoteConfig)
      remoteConfigLock.release().then();
    })()
  }, [])

  return loaded
}
