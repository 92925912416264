import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

import { isRunningTestcase } from '@/data/PosSettingsSignal.ts'
import { User } from '@/data/User'
import { loginUser } from '@/data/UserSignal.ts'
import type { MethodDecorator } from '@/shared/decorators'
import { SrmUserNotConfigured, UserNotFound, WebSrmCertificateError, WebSrmDocumentError, WebSrmInvalidResponseError, WebSrmTransactionError, WebSrmUserError } from '@/srm/errors'
import type { ResponseError } from '@/srm/lib/types'
import { isCertificateResponse, isDocumentResponse, isTransactionResponse, isUserResponse } from '@/srm/response-validators'

/**
 * Decorator to check if current user is correctly configured to make SRM transaction(s)
 *
 * Will take consideration of `impersonate` options
 */
export function ensureSrmConfigured<T extends { options?: { impersonate?: string } }, A extends unknown[], R>(): MethodDecorator<T, A, Promise<R>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      if (this.options?.impersonate) return await originMethod.apply(this, args)
      const username = loginUser()?.name
      if (!username) throw new UserNotFound(username)
      const user = await User.findOne({ selector: { name: username } }).exec()
      if (!user) throw new UserNotFound(username)
      if (!user.srmSynced) throw new SrmUserNotConfigured()

      return await originMethod.apply(this, args)
    }
}

/**
 * Decorator for async method - Only catch SRM errors, and rethrow otherwise.
 *
 * Must applied to function with return type is `Promise<void>`
 */
export function handleSrmError<T, A extends unknown[], R>(): MethodDecorator<T, A, Promise<R | undefined>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      try {
        return await originMethod.apply(this, args)
      } catch (e) {
        const srmErrors: ResponseError[] = []
        if (e instanceof AxiosError) {
          const errors = collectSrmResponseError(e.response?.data)
          if (errors.length === 0) {
            console.error('🛑 Unknown Network Error', e)
            toast.error('Unknown Network Error:' + e.message)
          } else srmErrors.push(...errors)
        } else if (e instanceof WebSrmUserError) {
          srmErrors.push(...(e.errors ?? []))
        } else if (e instanceof WebSrmCertificateError) {
          srmErrors.push(...(e.errors ?? []))
        } else if (e instanceof WebSrmDocumentError) {
          srmErrors.push(...(e.errors ?? []))
        } else if (e instanceof WebSrmTransactionError) {
          srmErrors.push(...(e.errors ?? []))
        } else if (e instanceof WebSrmInvalidResponseError) {
          console.error('🛑 Srm Unknown Response Error', e)
          toast.error('Unknown response from WEB-SRM!')
        } else throw e

        // TODO: record those error
        if (srmErrors.length) {
          for (const err of srmErrors) {
            console.error('🛑 Srm Response Error', err)
            // Some error is missing return code `codeRetour`
            toast.error(`[${[err.codRetour, err.id].filter(Boolean).join('-')}] ${err.mess}`, { autoClose: false })
          }

          captureException(e, { data: srmErrors })
          if (isRunningTestcase()) throw e // Rethrow if running testcase
        }
      }
    }
}

function collectSrmResponseError(res: unknown) {
  const errors: ResponseError[] = []
  if (isUserResponse(res)) errors.push(...(res.retourUtil.listErr ?? []))
  if (isCertificateResponse(res)) errors.push(...(res.retourCertif.listErr ?? []))
  if (isDocumentResponse(res)) errors.push(...(res.retourDoc.listErr ?? []))
  if (isTransactionResponse(res)) {
    errors.push(...(res.retourTrans.retourTransActu?.listErr ?? []))
    errors.push(...(res.retourTrans.retourTransLot?.listErr ?? []))
  }
  return errors
}
