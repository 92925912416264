import type { RxCollection, RxJsonSchema } from 'rxdb'

export const PRINT_SCRIPTS_COLLECTION_NAME = 'print_scripts'
export type PrintingScripts = Array<{ fn: string; args: unknown[] }>

export interface PrintScripts {
  readonly _id: string
  readonly date: number
  readonly scripts: PrintingScripts
  metadata?: Record<string, unknown>
}

export const printScriptsSchema: RxJsonSchema<PrintScripts> = {
  version: 1, // Increase this whenever schema changed
  title: 'PrintScripts schema',
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    date: { type: 'number', final: true, minimum: 0, maximum: 8.64e15, multipleOf: 1 },
    scripts: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          fn: { type: 'string' },
          args: { type: 'array' },
        },
        required: ['fn', 'args'],
      },
    },
    metadata: { type: 'object' },
  },
  required: ['_id', 'scripts', 'date'],
  indexes: ['date'],
}

export const PrintScripts = {} as RxCollection<PrintScripts>

Object.assign(window, { PrintScripts }) // Make available global
