import { type FunctionComponent, useCallback, useEffect, useState } from 'react'
import OrderInformation from '@order-history/OrderInformation'
import OrderHistoryButtonPrint from '@order-history/OrderHistoryButtonPrint'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import OrderHistoryButtonMore from '@order-history/OrderHistoryButtonMore.tsx'
import { router } from '@/pos/PosRouter.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import {
  changePaymentClick,
  checkDatePrinted,
  completePaymentChange,
  isDisabled,
  isPaymentVerticalAddPaymentOpen,
  isPrinted,
  order0,
  setPaymentVerticalAddPaymentOpen
} from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import { OrderStatus } from '@/pos/OrderType.ts'
import PortalPopup from '@order-history/PortalPopup'
import OrderHistoryCancelConfirmati from '@order-history/OrderHistoryCancelConfirmati'
import OrderHistoryReactiveConfirma from '@order-history/OrderHistoryReactiveConfirma'
import clsx from 'clsx'
import PaymentVerticalAddPayment from '@vertical-payment/PaymentVerticalAddPayment.tsx'
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";
import { maxId0 } from "@/data/MaxIdHub.ts";
import { isFeatureEnabled, PosFeatures } from '@/srm/features.logic'
import { mainScreen } from "@/data/PosSettingsSignal.ts";

type HistoryRightPathType = {
  onClose?: () => void;
};

const HistoryRightPath: FunctionComponent<HistoryRightPathType> = ({
  onClose,
}) => {
  const [
    isOrderHistoryReactiveConfirmaPopupOpen,
    setOrderHistoryReactiveConfirmaPopupOpen,
  ] = useState(false);
  const [
    isOrderHistoryCancelConfirmatiPopupOpen,
    setOrderHistoryCancelConfirmatiPopupOpen,
  ] = useState(false);

  const openOrderHistoryReactiveConfirmaPopup = useCallback(() => {
    if (isDisabled()) return;
    setOrderHistoryReactiveConfirmaPopupOpen(true);
  }, []);

  const closeOrderHistoryReactiveConfirmaPopup = useCallback(async () => {
    setOrderHistoryReactiveConfirmaPopupOpen(false);
  }, []);

  const openPaymentVerticalAddPayment = useCallback(async () => {
    await changePaymentClick()
    setPaymentVerticalAddPaymentOpen(true);
  }, []);

  const closePaymentVerticalAddPayment = useCallback(() => {
    setPaymentVerticalAddPaymentOpen(false);
  }, []);

  const openOrderHistoryCancelConfirmatiPopup = useCallback(() => {
    if (isDisabled()) return;
    setOrderHistoryCancelConfirmatiPopupOpen(true);
  }, []);

  const closeOrderHistoryCancelConfirmatiPopup = useCallback(() => {
    setOrderHistoryCancelConfirmatiPopupOpen(false);
  }, []);

  const onCompletePaymentChange = async () => {
    return completePaymentChange();
  }
  useEffect(() => {
    checkDatePrinted(order0()?.date!)
  }, [order0(),maxId0()?.lastEodCompleted!])
  return (
    <>
      <div className="self-stretch bg-white-solid-white-100-ffffff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.4)_inset] w-[314px] flex flex-col items-center justify-start z-[1] text-center text-sm text-black-solid-black-900-1e1e23 font-mulish-button-name-bold md:w-[280px] sm:w-[230px] Tablet_768:w-[230px]">
        <div className="self-stretch rounded-12xs bg-black-solid-black-700-364150 h-[38px] flex flex-row items-center justify-end h-[38px] py-1 px-2 box-border gap-[4px]">
          {/*TODO: Enable this later*/}
          {isFeatureEnabled(PosFeatures.Reactivate) && <button
            className="disabled:opacity-50 flex-1 rounded-81xl [background:linear-gradient(180deg,_#c0ed83,_#89d81f)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 h-[34px] cursor-pointer sm:flex-1 sm:h-[34px] Tablet_768:flex-1"
            onClick={openOrderHistoryReactiveConfirmaPopup}
            ref={makeRipple}
            disabled={isDisabled() || isPrinted()}
          >
            <img
              className="w-7 relative h-7 object-cover sm:flex sm:w-6 sm:h-6 Tablet_768:flex Tablet_768:w-5 Tablet_768:h-5 Tablet_600:w-5 Tablet_600:h-5 Mobile_480:w-5 Mobile_480:h-5 height_414:w-5 height_414:h-5"
              alt=""
              src="/order-history-iconreactive-icon@2x.png"
            />
            <b className="relative leading-[17px] Tablet_768:text-smi Tablet_768:flex">
              {LL0().orderHistory.reactivate()}
            </b>
          </button>}
          {/* todo: changePayment */}
          <ButtonWrapper
            className={clsx("flex-1 h-[34px] rounded-81xl [background:linear-gradient(180deg,_#f5baff,_#c97fd5)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[3px] sm:flex-1 sm:h-[34px] Tablet_768:flex-1",
            )}

            onClick={openPaymentVerticalAddPayment}
            isDisabled={isPrinted() || isDisabled() || order0().status === OrderStatus.DEBITOR}
          >
            <img
              className="w-7 relative h-7 object-cover sm:flex sm:w-6 sm:h-6 Tablet_768:flex Tablet_768:w-5 Tablet_768:h-5 Tablet_600:w-5 Tablet_600:h-5 Mobile_480:w-5 Mobile_480:h-5 height_414:w-5 height_414:h-5"
              alt=""
              src="/order-history-iconpayment-icon@2x.png"
            />
            <b className="relative leading-[17px] Tablet_768:text-smi Tablet_768:flex">
              {LL0().orderHistory.changePayment()}
            </b>
          </ButtonWrapper>
          <div
            className="h-[34px] rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[3px] text-white-solid-white-100-ffffff sm:pl-2 sm:pr-2 sm:box-border Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border"
            ref={makeRipple}
            onClick={() => (router.screen = mainScreen())}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover Tablet_768:w-[26px] Tablet_768:h-[26px]"
              alt=""
              src="/order-history-iconback-icon@2x.png"
            />
            <b className="w-[45px] relative leading-[17px] hidden">Button</b>
          </div>
        </div>
        <div
          className="hidden self-stretch bg-cadetblue flex flex-col items-center justify-center p-2 gap-[2px] text-smi text-white-solid-white-100-ffffff">
          <div className="self-stretch h-4 relative font-medium inline-block Tablet_600:text-sm Mobile_480:text-xs">
            Order is partial refunded
          </div>
          <div className="w-[264px] h-4 relative font-medium hidden Tablet_600:text-sm Mobile_480:text-xs">
            Order is fully refunded
          </div>
        </div>
        <OrderInformation />
        <div
          className="z-[9999] self-stretch flex flex-col items-start justify-start sm:gap-[2px] sm:pl-0 sm:box-border Tablet_768:gap-[4px]">
          <div
            className="self-stretch rounded-12xs bg-black-solid-black-700-364150 flex flex-row items-start justify-start py-1 px-3 gap-[4px] Tablet_768:gap-[4px] Tablet_768:pl-3 Tablet_768:box-border">
            {isFeatureEnabled(PosFeatures.RedBill) &&
            <ButtonWrapper
              className={clsx("flex-1 rounded-81xl [background:linear-gradient(180deg,_#ffda68,_#e3ac00)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0.5 px-0 gap-[3px] cursor-pointer sm:h-[34px] Tablet_768:flex-1",
              )}
              onClick={openOrderHistoryCancelConfirmatiPopup}
              isDisabled={isPrinted() || isDisabled() || order0().status === OrderStatus.DEBITOR}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover sm:flex sm:w-[26px] sm:h-[26px] Tablet_768:flex Tablet_768:w-[26px] Tablet_768:h-[26px] Tablet_600:flex Tablet_600:w-[26px] Tablet_600:h-[26px] Mobile_480:w-[26px] Mobile_480:h-[26px] height_414:w-[26px] height_414:h-[26px]"
                alt=""
                src="/order-history-iconbin-icon--red@2x.png"
              />

              <b className="relative leading-[17px] Tablet_768:text-smi Tablet_768:flex">
                {LL0().orderHistory.cancel()}
              </b>
            </ButtonWrapper>
            }
            <OrderHistoryButtonPrint />
            <OrderHistoryButtonMore />
          </div>
        </div>
      </div>
      {isOrderHistoryReactiveConfirmaPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderHistoryReactiveConfirmaPopup}
        >
          <OrderHistoryReactiveConfirma
            onClose={closeOrderHistoryReactiveConfirmaPopup}
          />
        </PortalPopup>
      )}
      {isPaymentVerticalAddPaymentOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentVerticalAddPayment}
        >
          <PaymentVerticalAddPayment onClose={closePaymentVerticalAddPayment}
                                     onComplete={onCompletePaymentChange}
          />
        </PortalPopup>
      )}
      {isOrderHistoryCancelConfirmatiPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderHistoryCancelConfirmatiPopup}
        >
          <OrderHistoryCancelConfirmati
            onClose={closeOrderHistoryCancelConfirmatiPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default HistoryRightPath;