import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { dataLock } from '@/data/DataUtils.ts'
import { mainScreen } from '@/data/PosSettingsSignal.ts'
import { makeSrmEventLogAvailable } from '@/data/SrmEventLogHub.ts'
import { SrmTransactionLog } from '@/data/SrmTransactionLog.ts'
import { onEnter, PosScreen, router } from '@/pos/PosRouter'
import { LL0 } from '@/react/core/I18nService.tsx'
import InputPopup from '@/react/core/InputPopup.tsx'
import { rnHost } from '@/shared/webview/rnwebview'
import { CONSTANT_VALUES } from '@/srm/lib/constants'

import { lastTransactionSignature } from '../getLastTransactionSignature'
import { setIsBackupSucceeded, srmLogic } from '../logic'
import { hasPendingSrmTransactions } from '../signals'
import { srmTransactionLogic } from '../transaction.logic'
import EventTable from './EventTable'
import PortalPopup from './PortalPopup'
import { dataClearable, isManager } from './srm.logic'
import TransactionsTable from './TransactionsTable'
import WipeTransactionDataConfirmationPopup from './WipeTransactionDataConfirmationPopup'

function goBack() {
  router.screen = mainScreen()
}

export default function TransactionHistoryPage() {
  makeSrmEventLogAvailable()
  const [showExportModal, setShowExportModal] = useState(false)
  const [email, setEmail] = useState('')
  const [isOpenWipePopupConfirmation, setIsOpenWipePopupConfirmation] = useState<boolean>(false)
  const [expandSig, setExpandSig] = useState(false)

  onEnter(PosScreen.SRM, async () => {
    await dataLock.acquireAsync()
    rnHost.loadCert()
  })

  useEffect(() => {
    const subscription = SrmTransactionLog.$.subscribe(changeEvent => {
      if (changeEvent.operation === 'INSERT') {
        setIsBackupSucceeded(false)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const toggleExportModal = () => {
    setEmail('')
    setShowExportModal(v => !v)
  }

  const openPopupWipePopupConfirmation = useCallback(() => {
    setIsOpenWipePopupConfirmation(true)
  }, [])

  const closePopupWipePopupConfirmation = useCallback(() => {
    setIsOpenWipePopupConfirmation(false)
  }, [])

  return (
    <div className="p-6 w-full">
      <div className="flex justify-between mb-4">
        <h2 className="text-xl font-bold">{LL0().srm.srmView.transactionHistory()}</h2>
        <div className={'flex'}>
          <Button
            className={clsx(!dataClearable() && 'opacity-60', 'mr-4 bg-red-500')}
            size="sm"
            onClick={openPopupWipePopupConfirmation}
            disabled={!dataClearable()}
          >
            {LL0().srm.srmView.wipeTransactionData()}
          </Button>
          <Button
            className={clsx(!isManager() && 'opacity-60', 'mr-4')}
            size="sm"
            onClick={toggleExportModal}
            disabled={!isManager()}
          >
            {LL0().editOnlineMenu.export()}
          </Button>
          <Button
            size="sm"
            onClick={goBack}
          >
            {LL0().ui.back()}
          </Button>
        </div>
      </div>
      {showExportModal && (
        <InputPopup
          key={email}
          value={email}
          onChange={value => setEmail(value)}
          onClose={toggleExportModal}
          label={LL0().delivery.customer.email()}
          onEnter={async () => {
            setShowExportModal(false)
            await srmLogic.exportData(email)
          }}
        />
      )}

      {isOpenWipePopupConfirmation && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupWipePopupConfirmation}
        >
          <WipeTransactionDataConfirmationPopup onClose={closePopupWipePopupConfirmation} />
        </PortalPopup>
      )}
      <TransactionsTable />
      <p className="mt-2">
        <span>{LL0().srm.srmView.lastSignature()}: </span>
        {lastTransactionSignature() === CONSTANT_VALUES.emptySig ? (
          <code className="text-stone-500">N/A</code>
        ) : (
          <code
            className="text-stone-500"
            onClick={() => setExpandSig(a => !a)}
          >
            {expandSig
              ? lastTransactionSignature()
              : lastTransactionSignature()
                  .split(/(?<=^.{8}).+(?=.{8}$)/)
                  .join('...')}
          </code>
        )}
      </p>
      <Button
        className="mt-4 disabled:opacity-60"
        disabled={!hasPendingSrmTransactions()}
        size="sm"
        onClick={srmTransactionLogic.sendOfflineTransactions}
      >
        {LL0().srm.srmView.sendOfflineTransaction()}
      </Button>
      <h2 className="text-xl font-bold mt-6 mb-4">{LL0().srm.srmView.eventsHistory()}</h2>
      <EventTable />
    </div>
  )
}
