import { type FunctionComponent, useEffect } from 'react'
import ManuelTableTableItem from '@dashboard/ManuelTableTableItem'
import { isManual, manualOrders0, RoomComponent, rooms0 } from '@/react/FloorPlanView/FloorPlanView.tsx'
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import Input, { inputController0 } from '@/react/core/Input.tsx'
import { useSignal } from '@/react/core/reactive.ts'
import { PosScreen, router, setParams } from '@/pos/PosRouter.ts'
import clsx from 'clsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import dayjs from 'dayjs'
import { loginUsers } from '@/data/UserHub.ts'
import { tableExists } from '@/react/FloorPlanView/ManualTable.logic.ts'

const ManualTable: FunctionComponent = () => {
  const [name, setName] = useSignal<string>('')
  const [v, setV] = useSignal<number>(0)
  const [inputController, setInputController] = useSignal(inputController0())

  useEffect(() => {
    if (inputController()) {
      inputController()[0]?.setFocus(true)
    }
  }, [v()])
  return (
    <>
      <div
        className={clsx('manual-table-screen relative self-stretch flex-1 flex flex-col items-start justify-start',
          isManual() && 'h-full overflow-y-auto no-scrollbar'
        )}>
        {!isManual() && rooms0().map(room => <RoomComponent key={room._id} room={room} />)}
        {isManual() &&
          <div className="p-6 flex flex-wrap overflow-y-auto gap-4 lg:gap-5 md:gap-4 sm:gap-3 sm:p-4 Tablet_600:gap-6">
            {manualOrders0().map(order => {
                const tableName = order.table.length > 5 ? `${order.table.substring(0, 5)}...` : order.table
                return <div key={order._id} className="w-20 h-20" ref={makeRipple}
                            onClick={() => {
                              setParams({ order: order })
                              setTimeout(() => {
                                router.screen = PosScreen.ORDER
                              })
                            }}
                >
                  <ManuelTableTableItem
                    tableNumber={tableName}
                    topRightRoundedPercent={0}
                    price={order.vSum}
                    isBooked
                    remainingTime={order.date ? `${Math.floor((dayjs().unix() - order.date) / 60)} ` : ''}
                    isMyTable={!!loginUsers()?.[0] && loginUsers()?.[0] === order.users?.[0]}
                  />
                </div>
              }
            )}
          </div>}
      </div>
      {isManual() &&
        <div className="bg-white w-full p-2"
          onPointerDown={() => {
            if (!inputController()[0]?.focus()) setV(v => v + 1)
          }}
        >
          <Input
            className="w-full"
            refInputController={i => {
              setInputController([i])
            }}
            value={name()}
            onChange={value => setName(value)}
          />
          <TableManualKeyboard
            value=""
            inputController={inputController0}
            onEnter={() => {
              if (name().trim() === '' || tableExists({ tableNameInput: name() })) return
              setParams({ table: name() })
              setTimeout(() => {
                router.screen = PosScreen.ORDER
              }, 0)
              setName('')
            }}
          />
        </div>
      }
    </>
  )
}

export default ManualTable