import {type FunctionComponent, useState } from "react";
import MoreButton from "@passcode/MoreButton.tsx";
import { LL0 } from '@/react/core/I18nService.tsx';
import ButtonWDropdown from "@passcode/ButtonWDropdown.tsx";
import { posSync0 } from "@/data/PosSyncState.ts";

const PasscodeHeader: FunctionComponent = () => {
  const [isPasscodeScreenChooseServerOpen, setPasscodeScreenChooseServerOpen] =
    useState(false);
  return (
    <div className="relative !shrink-0 self-stretch bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] h-[52px] flex flex-row items-center justify-between py-1.5 px-5 box-border z-[1] text-left text-lg text-white-solid-white-100-ffffff font-mulish mq480:pl-4 mq480:pr-4 mq480:box-border height_340:h-[42px]">
      <div className="flex flex-row items-center justify-start gap-[26px]">
        <MoreButton/>
        <div className="flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-[22px] relative h-[18px] object-cover"
            alt=""
            src="/iconpasscode-internet-icon@2x.png"
          />
          <div className="relative font-semibold">{LL0().login.internet()}</div>
        </div>
        <div className="hidden flex-row items-center justify-start gap-[8px] text-red-solid-red-200-ff6666">
          <img
            className="w-[22px] relative h-[18px] object-cover"
            alt=""
            src="/iconpasscodeno-internet-icon@2x.png"
          />
          <div className="relative font-semibold">{LL0().login.noInternet()}</div>
        </div>
        { posSync0()?.id &&
        <div className="relative font-semibold">{`${LL0().onlineOrder.settings.storeName()}: ${posSync0()?.id} `}</div>
        }
      </div>
      <ButtonWDropdown />
    </div>
  );
};

export default PasscodeHeader;
