import { FunctionComponent } from "react";
import VoucherKeyboardButtonKeyb from "@payment/VoucherKeyboardButtonKeyb";
import {
  currentPayment0,
  scopeOrder0,
  onPaymentKeyClick,
  setFirstClick0,
  setValue0,
  value0, blockMutateOrderButton, updateValue0
} from "@/react/PaymentView/PaymentView.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import { clsx } from "clsx";

const PaymentKeyboard: FunctionComponent = () => {

  return (
    <div
      className={clsx(
        "self-stretch h-[214px] flex flex-col items-center justify-end gap-[8px] text-center text-xl text-black-solid-black-900-1e1e23 font-mulish Tablet_768:flex Tablet_768:gap-[4px] Tablet_768:pl-0 Tablet_768:box-border height_414:hidden",
        {
          'opacity-70': blockMutateOrderButton()
        }
      )}
    >
      <div className="self-stretch rounded-10xs bg-gray-solid-gray-150-eeeeee flex flex-row items-center justify-between py-1 px-[5px] border-[1px] border-solid border-gray-solid-gray-600-979797">
        <div className="rounded-10xs bg-gray-solid-gray-940-394453 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] shrink-0 flex flex-row items-center justify-center py-0 px-3.5">
          {currentPayment0()?.icon &&
          <img
            className="!object-contain relative w-8 h-8 object-cover Tablet_768:w-[26px] Tablet_768:h-[26px] height_414:w-[26px] height_414:h-[26px]"
            alt=""
            src={currentPayment0()?.icon}
          />
          }
        </div>
        <div className="shrink-0 flex flex-row items-center justify-end gap-[10px]">
          <b className="relative sm:text-lg Tablet_768:text-base height_414:text-base">
            {value0()}
          </b>
          <div className="relative w-[30px] h-[30px] Tablet_768:w-[26px] Tablet_768:h-[26px] height_414:w-[26px] height_414:h-[26px]">
            <img
              className="absolute object-cover h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/payment-button-close-icon2@2x.png"
              onClick={() => {
                setFirstClick0(true);
                updateValue0()
              }}
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[4px]">
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[5px] Tablet_600:gap-[2px]">
          <VoucherKeyboardButtonKeyb keyNumber="7" onClick={() => onPaymentKeyClick('7')}/>
          <VoucherKeyboardButtonKeyb keyNumber="8" onClick={() => onPaymentKeyClick('8')}/>
          <VoucherKeyboardButtonKeyb keyNumber="9" onClick={() => onPaymentKeyClick('9')}/>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[5px] Tablet_600:gap-[2px]">
          <VoucherKeyboardButtonKeyb keyNumber="4" onClick={() => onPaymentKeyClick('4')}/>
          <VoucherKeyboardButtonKeyb keyNumber="5" onClick={() => onPaymentKeyClick('5')}/>
          <VoucherKeyboardButtonKeyb keyNumber="6" onClick={() => onPaymentKeyClick('6')}/>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[5px] Tablet_600:gap-[2px]">
          <VoucherKeyboardButtonKeyb keyNumber="1" onClick={() => onPaymentKeyClick('1')}/>
          <VoucherKeyboardButtonKeyb keyNumber="2" onClick={() => onPaymentKeyClick('2')}/>
          <VoucherKeyboardButtonKeyb keyNumber="3" onClick={() => onPaymentKeyClick('3')}/>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[5px] Tablet_600:gap-[2px]">
          <VoucherKeyboardButtonKeyb keyNumber="0" onClick={() => onPaymentKeyClick('0')}/>
          <VoucherKeyboardButtonKeyb keyNumber="," onClick={() => onPaymentKeyClick(',')}/>
          <div className="self-stretch flex-1 rounded-10xs bg-basic-color-white box-border overflow-hidden flex flex-row items-center justify-center min-w-[40px] min-h-[40px] border-[1px] border-solid border-darkgray-300"
               onClick={() => onPaymentKeyClick('<-')} ref={makeRipple}>
            <div className="relative w-[30px] h-[30px]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/payment-delete-icon1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentKeyboard;
