import { RxCollection, RxDatabase } from "rxdb";
import { createCollection } from "@/data/utils/migration.ts";

export const ONLINE_ORDER_PRINTER_GROUP_MAPPING_COLLECTION_NAME = 'online_order_printer_group_mapping'

const VERSION = 0;

const schema = {
  title: 'online_order_printer_group_mapping schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    externalProductKey: { type: 'string', maxLength: 50 },
    printerGroups: {
      type: 'array',
      items: {
        type: 'string',
        maxLength: 50
      }
    }
  }
}

export interface OnlineOrderPrinterGroupMappingCollection {
  type?: keyof typeof ONLINE_ORDER_PRINTER_GROUP_MAPPING_COLLECTION_NAME,
  _id: string,
  externalProductKey: string,
  printerGroups: string[]
}

export type TOnlineOrderPrinterGroupMappingCollection = RxCollection<OnlineOrderPrinterGroupMappingCollection>

export const OnlineOrderPrinterGroupMappingCollection: TOnlineOrderPrinterGroupMappingCollection = {} as TOnlineOrderPrinterGroupMappingCollection;

export const createOnlineOrderPrinterGroupMappingCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: OnlineOrderPrinterGroupMappingCollection,
    collectionName: ONLINE_ORDER_PRINTER_GROUP_MAPPING_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}


// @ts-ignore
window.OnlineOrderPrinterGroupMapping = OnlineOrderPrinterGroupMappingCollection;