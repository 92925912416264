import { type FunctionComponent, memo, useCallback, useState } from 'react';
import MonthlyReportLeft from "@monthly-report/MonthlyReportLeft";
import MonthlyReportRight from "@monthly-report/MonthlyReportRight";
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { onBack, onPrintMonthlyReport } from '@/react/MonthlyReportView/MonthlyReportView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";
import PortalPopup from '@monthly-report/PortalPopup.tsx';
import MonthlyReportIngredientPo from '@monthly-report/Popups/IngredientReportPopup/MonthlyReportIngredientPo.tsx';

const MonthlyReportPlugin: FunctionComponent = () => {
  const [isMonthlyReportIngredientPoOpen, setMonthlyReportIngredientPoOpen] =
    useState(false);

  const closeMonthlyReportIngredientPo = useCallback(() => {
    setMonthlyReportIngredientPoOpen(false);
  }, []);

  return (
    <>
    <div className="monthly-report-screen bg-white w-full h-full overflow-hidden flex flex-col items-start justify-start text-center text-sm text-white font-mulish">
        <div className="self-stretch flex-1 overflow-y-auto flex flex-row items-start justify-start">
          <MonthlyReportLeft />
          <MonthlyReportRight />
        </div>
        <div className="self-stretch bg-dimgray flex flex-row items-start justify-between py-2 px-4">
          <div className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[83px] Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border Tablet_600:min-w-[74px]"
            ref={makeRipple}
             onClick={onBack}
        >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="flex-1 relative leading-[17px] Tablet_600:text-xs">
            {LL0().ui.back()}
            </b>
          </div>
        <div className="flex flex-row items-start justify-start gap-[8px] text-[#1e1e23]">
          {/*<div className="cursor-pointer rounded-81xl [background:linear-gradient(180deg,_#ffc7e3,_#fa80be)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"*/}
          {/*     ref={makeRipple}*/}
          {/*     onClick={openMonthlyReportIngredientPo}*/}
          {/*>*/}
          {/*    <img*/}
          {/*      className="relative w-[30px] h-[30px] object-cover"*/}
          {/*      alt=""*/}
          {/*      src="/icongrocery-icon@2x.png"*/}
          {/*    />*/}
          {/*  <b className="relative leading-[17px]">{LL0().report.ingredientReport()}</b>*/}
          {/*</div>*/}
          <div className="rounded-81xl [background:linear-gradient(180deg,_#ff8366,_#e44e2c)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
               ref={makeRipple}
               onClick={onPrintMonthlyReport}
          >
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/iconprint-icon@2x.png"
              />
            <b className="relative leading-[17px]">{LL0().protocol.printZReport()}</b>
            </div>
          </div>
        </div>
      </div>
      {isMonthlyReportIngredientPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeMonthlyReportIngredientPo}
        >
          <MonthlyReportIngredientPo onClose={closeMonthlyReportIngredientPo} />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(MonthlyReportPlugin);
