import type { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxDatabase, RxDocument, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const TX_REFUND_LOG_COLLECTION = 'tx_refund_log'

export const VERSION = 2
const txLogSchema = {
  title: 'txRefund schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    type: { type: 'string', maxLength: 50 },
    orderId: { type: 'string' },
    metadata: { type: 'object', properties: {} },
  },
  indexes: [],
  required: ['_id', 'type', 'orderId'],
} as const satisfies RxJsonSchema<any>

export interface TxRefundLog extends ExtractDocumentTypeFromTypedRxJsonSchema<typeof txLogSchema> {}

export type TxRefundLogDocument = RxDocument<TxRefundLog>
export const TxRefundLogCollection: RxCollection<TxRefundLog> = {} as RxCollection<TxRefundLog>

export const createTxRefundCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TxRefundLogCollection,
    collectionName: TX_REFUND_LOG_COLLECTION,
    version: VERSION,
    schema: txLogSchema
  });
}
