import { type FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useSignal } from "@/react/core/reactive";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

export type Props = {
  onClose: Function,
  date: any
}

export const TimeSelection: FunctionComponent<Props> = ({ onClose, date }) => {
  const LL = LL0();

  function adjustTime(e: any, value: any) {
    e.stopPropagation()
    e.preventDefault();
    onClose({action: 'adjust', value})
  }

  const [vDate, setVDate] = useSignal(date)
  function setDate() {
    onClose({action: 'set', value: vDate()})
  }

  const showAmPm = companyInfo0()?.country !== 'de' && companyInfo0()?.country !== 'vn' && companyInfo0()?.country !== 'vi'

  const estimatesKitchen = [5, 10, 15, 20, 30, 40,50, 60, 70, 80, 90, 100, 110, 120];
  return <div className="max-w-[600px] bg-white px-5 py-5" style={{margin: "0 auto"}}>
    <div className="flex flex-col gap-[20px]">
      <div className="flex items-center justify-between">
        <p>{LL.pendingOrder.adjustTimeBy()}:</p>
        <div className="px-2 py-2" onClick={() => onClose({action: 'cancel'})}>X</div>
      </div>

      <div className="flex flex-row flex-wrap gap-[10px]">
        {estimatesKitchen.map(item =>
          <div onClick={(e) => adjustTime(e, item)} key={item}
               className="flex items-center justify-center cursor-pointer"
               style={{
                 borderRadius: '3px',
                 border: '2px solid #B1B1B1',
                 background: '#F4F4F4',
                 boxShadow: "0.8px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                 width: 85,
                 height: 34,
               }}>
            {item} min
          </div>
        )}
      </div>

      <div>
        {LL.pendingOrder.orderSetSpecificDateTime()}
      </div>

      <div className="flex flex-row items-center gap-[10px]">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            format={LL2().dates.datetimeFormat()}
            ampm={showAmPm}
            value={dayjs(vDate())}
            onChange={(newValue) => newValue && setVDate(newValue)}/>
        </LocalizationProvider>
        <div className="px-[20px] cursor-pointer" onClick={setDate}>{LL.ui.save()}</div>
      </div>

      {/*<div className="flex flex-row items-center gap-[10px] height-[53px]">*/}
      {/*  <TextField*/}
      {/*    className="w-full h-full bg-transparent border-none outline-0"*/}
      {/*    placeholder="Custom time"*/}
      {/*    value={value}*/}
      {/*    onChange={v => setValue(v.target.value)}*/}
      {/*  />*/}
      {/*  <div className="cursor-pointer flex items-center justify-center px-[40px] height-[53px] bg-[#2979FF] text-white"*/}
      {/*       style={{lineHeight: '53px'}} onClick={save}>*/}
      {/*    Save*/}
      {/*  </div>*/}
      {/*</div>*/}

    </div>
  </div>
}