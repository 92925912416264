import {
  cashbookTransaction,
  type CashbookTransaction,
  CashbookTransactionsAction,
  CashbookTransactionsType,
  CashbookViewList,
  createCashbookTransaction, formatNumber,
  setCashbookTransaction,
  setSelectedCashbookView
} from "@/react/CashbookView/CashbookView.tsx";
import TextField from "@/react/core/TextField.tsx";
import { batch } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";
import { type ChangeEvent, type FunctionComponent, useCallback, useState } from "react";
import PrinterSelectButton from "./PrinterSelectButton";
import { LL0 } from '@/react/core/I18nService.tsx'
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { convertBase64ToPngBase64 } from "@/react/shared/utils.ts";
import { toast } from "react-toastify";
import CashbookEditTypePopup from "./CashbookEditTypePopup";
import PortalPopup from "./PortalPopup";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { getUrlParam } from "@/shared/utils2.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

const VAT = [
  { name: '0%', value: 0 },
  { name: '5%', value: 5 },
  { name: '7%', value: 7 },
  { name: '10%', value: 10 },
  { name: '19%', value: 19 },
]

export type CashbookCashPluginType = {
  type: CashbookTransactionsAction
}

const CashbookCashPlugin: FunctionComponent<CashbookCashPluginType> = ({ type }) => {

  const [isCashbookEditTypePopupOpen, setCashbookEditTypePopupOpen] =
    useState(false);

  const openCashbookEditTypePopup = useCallback(() => {
    setCashbookEditTypePopupOpen(true);
  }, []);

  const closeCashbookEditTypePopup = useCallback(() => {
    setCashbookEditTypePopupOpen(false);
  }, []);

  const handleFileInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result) {
          setCashbookTransaction(prev => ({ ...prev, receipt: reader.result } as CashbookTransaction))
        }
      };

      reader.readAsDataURL(file);
    }
  }, [])

  async function onChooseReceipt() {
    console.log('1')
    if (getUrlParam('os')) {
      console.log('2')
      const data = await rnHost.chooseImage()
      if (data) {
        const receipt = await convertBase64ToPngBase64(data)
        console.log(receipt.length)
        setCashbookTransaction(prev => ({ ...prev, receipt: receipt } as CashbookTransaction))
        toast.success('Success')
        return
      }
      toast.error('Failed to add receipt')
      return
    }
    console.log('3')
    document.getElementById("imageInput")?.click()
  }

  return (
    <>
      <div
        className="w-full h-full overflow-hidden flex flex-col items-center justify-start gap-[0px] text-left text-lg text-gray-solid-gray-380-cdcdcd font-mulish hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start">
        <div
          className="self-stretch bg-black-solid-black-500-59636f h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[5px] px-2.5 box-border sm:h-[42px]">
          <div className="overflow-hidden flex flex-row items-center justify-start gap-[16px] sm:gap-[12px]">
            <img
              className="relative w-6 h-6 object-cover sm:w-5 sm:h-5"
              alt=""
              src="/icondouble-chevron-icon@2x.png"
            />
            <b className="relative tracking-[-0.28px] sm:text-mini">
              {type === CashbookTransactionsAction.INCOMING ? LL0().cashbook.newIncoming() : LL0().cashbook.newOutgoing()}
            </b>
          </div>
          <div
            className="h-12 overflow-hidden flex flex-row items-center justify-start gap-[16px] text-center text-smi text-black-solid-black-1000-000000 Tablet_768:gap-[8px]">
            <div
              className="rounded-xl bg-purple-solid-purple-300-aec0ff w-[85px] h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center sm:h-8"
              onClick={async () => {
                await createCashbookTransaction(cashbookTransaction(), type)
                setSelectedCashbookView(CashbookViewList.Cashbook)
                setCashbookTransaction({
                  transactionType: CashbookTransactionsType.CASHSALE,
                  amount: "0",
                  tax: 0,
                  note: "",
                  receipt: ""
                })
              }}
            >
              <div className="relative font-semibold sm:text-2xs">{LL0().cashbook.save()}</div>
            </div>
            <div
              className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-sm text-white-solid-white-100-ffffff sm:h-8"
              onClick={() => {
                batch(() => {
                  setSelectedCashbookView(CashbookViewList.Cashbook)
                  setCashbookTransaction({
                    transactionType: CashbookTransactionsType.CASHSALE,
                    amount: "0",
                    tax: 0,
                    note: "",
                    receipt: ""
                  })
                })
              }}
              ref={makeRipple}
            >
              <img
                className="relative w-[30px] h-[30px] object-cover"
                alt=""
                src="/iconback-icon@2x.png"
              />
              <b className="relative leading-[17px] sm:text-2xs">{LL0().posSetup.accountTab.back()}</b>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex-1 bg-black-solid-black-700-364150 overflow-y-auto flex flex-row items-start justify-start py-4 px-5 gap-[42px] text-sm text-white-solid-white-100-ffffff Tablet_768:gap-[20px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
          <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-[20px]">
            <div className="self-stretch h-[63px] flex flex-col items-start justify-start gap-[5px]">
              <div className="self-stretch flex flex-row items-start justify-between">
                <i
                  className="relative flex italic w-auto [align-self:unset] h-auto hover:flex hover:italic hover:font-mulish hover:text-sm hover:text-left hover:text-white-solid-white-100-ffffff hover:w-auto hover:[align-self:unset] hover:h-auto sm:text-xs mq1440:text-base">
                  {LL0().cashbook.chooseType()}
                </i>
                <div
                  className="relative [text-decoration:underline] font-medium text-blue-solid-blue-310-00aaff cursor-pointer lg:text-base md:text-mini sm:text-sm Tablet_768:text-smi"
                  onClick={openCashbookEditTypePopup}
                >
                  + {LL0().cashbook.editType()}
                </div>
              </div>
              {type === CashbookTransactionsAction.INCOMING ?
                <FormControl className="self-stretch" variant="outlined">
                  <InputLabel color="primary"/>
                  <Select color="primary" size="small" className="bg-white"
                          value={cashbookTransaction().transactionType || ""}
                          onChange={e => setCashbookTransaction(prev =>
                            ({ ...prev, transactionType: e.target.value } as CashbookTransaction)
                          )}
                  >
                    {
                      posSetting0()?.cashbook?.incomingTypes.map((type, index) => (
                        <MenuItem value={type} key={index}>{type}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText/>
                </FormControl>
                :
                <FormControl className="self-stretch" variant="outlined">
                  <InputLabel color="primary"/>
                  <Select color="primary" size="small" className="bg-white"
                          value={cashbookTransaction().transactionType || ""}
                          onChange={e => setCashbookTransaction(prev =>
                            ({ ...prev, transactionType: e.target.value } as CashbookTransaction)
                          )}
                  >
                    {
                      posSetting0()?.cashbook?.outgoingTypes.map((type, index) => (
                        <MenuItem value={type} key={index}>{type}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText/>
                </FormControl>
              }
            </div>
            <div className="self-stretch flex flex-col items-start justify-center gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">
                {LL0().cashbook.amountInput()}
              </i>
              <TextField
                className="bg-white self-stretch rounded-[4px]"
                value={LL3().format.number(cashbookTransaction().amount) || ""}
                required
                onChange={e => setCashbookTransaction(prev =>
                  ({ ...prev, amount: formatNumber(e.target.value) > 0 ? formatNumber(e.target.value) : "" } as CashbookTransaction)
                )}
                label=''
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-center gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">
                {LL0().cashbook.noteInput()}
              </i>
              <TextField
                className="bg-white self-stretch rounded-[4px]"
                value={cashbookTransaction().note || ""}
                label=''
                onChange={e => setCashbookTransaction(prev =>
                  ({ ...prev, note: e.target.value } as CashbookTransaction)
                )}
              />
            </div>
          </div>
          <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-[20px]">
            <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">
                {LL0().cashbook.taxInput()}
              </i>
              <div
                className="overflow-hidden flex flex-row flex-wrap items-center justify-start gap-[20px] Tablet_768:gap-[8px] Tablet_768:[transform:rotate(0deg)]">
                {VAT.map((vat, index) => (
                  <PrinterSelectButton vatValue={vat.name} key={index}
                                       isSelected={cashbookTransaction().tax === vat.value}
                                       onSelect={() => setCashbookTransaction(prev => ({
                                         ...prev,
                                         tax: vat.value
                                       }) as CashbookTransaction)}/>
                ))}
              </div>
            </div>
            <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">
                {LL0().cashbook.addPhoto()}
              </i>
              <button
                className="relative self-stretch bg-gray-solid-gray-130-f4f4f4 h-10 overflow-hidden shrink-0 flex flex-row items-center justify-start p-2.5 box-border gap-[10px] text-center text-blue-solid-blue-400-0091ff"
                onClick={onChooseReceipt}
              >
                <img
                  className="relative w-6 h-6 object-cover z-[0]"
                  alt=""
                  src="/iconattachments@2x.png"
                />
                {cashbookTransaction().receipt &&
                  <div
                    className="my-0 mx-[!important] absolute top-[3px] left-[23px] rounded-[703.45px] bg-red-solid-red-180-f55349 w-[17px] h-[17px] flex flex-col items-center justify-center z-[1]">
                    <div
                      className="relative text-white font-semibold flex items-center justify-center w-[9.7px] h-[19.3px] shrink-0">
                      1
                    </div>
                  </div>}
                <div
                  className="uppercase flex-1 relative [text-decoration:underline] font-semibold sm:text-xs mq1440:text-base">
                  {LL0().cashbook.addReceipt()}
                </div>
                <input type="file" id="imageInput" className="hidden" accept="image/*"
                       onChange={handleFileInputChange}/>
              </button>
              <i className="relative text-3xs text-gray-solid-gray-450-b6b6b6 mq1440:text-xs">
                {LL0().cashbook.pleaseUsePhoto()}
              </i>
            {cashbookTransaction()?.receipt &&
              <div>
                <img alt={'d'} src={cashbookTransaction().receipt} className={'w-full h-auto'}/>
              </div>
            }</div>
          </div>
        </div>
      </div>
      {isCashbookEditTypePopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeCashbookEditTypePopup}
        >
          <CashbookEditTypePopup type={type} onClose={closeCashbookEditTypePopup}/>
        </PortalPopup>
      )}
    </>
  );
};

export default CashbookCashPlugin;
