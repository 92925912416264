import { type FunctionComponent, useState } from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl, FormControlLabel, Checkbox, OutlinedInput, Chip, Box, ListItemText
} from '@mui/material'
import {LL0} from "@/react/core/I18nService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import {editingOnlineProvider, HANDLERS} from "@/react/OnlineOrderSettingView/OnlineProviderSettingView.logic.tsx";
import {MarketPlaceProvider} from "@/pos/OrderType.ts";

export type OnlineProviderSettingAddEdType = {
  className?: string;
  onClose?: () => void;
};

export const services = [
  {name: 'Sync Online Order', value: 'syncOnlineOrder'},
  {name: 'Sync Online Menu', value: 'syncOnlineMenu'},
  {name: 'Delivery Status', value: 'deliveryStatus'},
]


const OnlineProviderSettingAddEd: FunctionComponent<
  OnlineProviderSettingAddEdType
> = ({ className = "", onClose }) => {
  const [servicesType, setServicesType] = useState<[]>([]);

  const handleComplete = async () => {
    HANDLERS.onSave()
    onClose && onClose()
  }
  return (
    <div className="!h-fit no-scrollbar relative rounded bg-white-solid-white-100-ffffff w-full h-[491px] flex flex-col items-center justify-start pt-[26px] px-3 pb-1 box-border gap-[8px] min-w-[580px] min-h-[390px] max-w-full max-h-full overflow-auto text-left text-smi text-blue-solid-blue-500-0051c1 font-mulish Tablet_768:h-full Tablet_768:w-full Tablet_768:min-w-[550px]">
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start gap-[6px] z-[0] Tablet_768:self-stretch Tablet_768:w-auto height_414:gap-[2px]">
        <div className="self-stretch flex flex-row items-start justify-center gap-[12px] height_414:gap-[6px]">
          <div className="flex-1 flex flex-col items-start justify-start height_414:gap-[4px] gap-2">
            <b className="relative sm:text-sm">{LL0().onlineProdiverSetting.providerName()}</b>
            <FormControl className="self-stretch" variant="outlined">
              <InputLabel color="primary" />
              <Select color="primary" size="small" value={editingOnlineProvider()?.name || MarketPlaceProvider.UBER_EATS} onChange={(event)=>HANDLERS.onNameChanged(event)}>
                <MenuItem value={MarketPlaceProvider.UBER_EATS}>Uber Eats</MenuItem>
                {/*<MenuItem value={MarketPlaceProvider.DOOR_DASH}>Doordash</MenuItem>*/}
                <MenuItem value={MarketPlaceProvider.RESTAURANT_PLUS}>Restaurant Plus</MenuItem>
                <MenuItem value={MarketPlaceProvider.PIKAPOINT}>Pika</MenuItem>
                <MenuItem value={MarketPlaceProvider.RESTABLO}>Restablo</MenuItem>
                <MenuItem value={MarketPlaceProvider.LIEFERANDO}>Lieferando</MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
          {HANDLERS.isFieldAvailable('storeId') && <div className="flex-1 flex flex-col items-start justify-start height_414:gap-[4px] gap-2">
            <b className="relative sm:text-sm">{LL0().onlineProdiverSetting.providerStoreID()}</b>
            <Input className={'self-stretch w-full'} value={editingOnlineProvider()?.storeId || ''}
                   onChange={HANDLERS.onStoreIdChanged}/>
          </div>}
        </div>
        <div className="self-stretch flex flex-row items-start justify-center gap-[12px] height_414:gap-[6px]">
          {HANDLERS.isFieldAvailable('username') && (
          <div className="flex-1 flex flex-col items-start justify-start height_414:gap-[4px] gap-2">
            <b className="relative sm:text-sm">{LL0().onlineProdiverSetting.userNameEmail()}</b>
            <Input className={'self-stretch w-full'} value={editingOnlineProvider()?.username || ''} onChange={HANDLERS.onUsernameChanged}/>
          </div>
          )}
          {HANDLERS.isFieldAvailable('password') && (
          <div className="flex-1 flex flex-col items-start justify-start height_414:gap-[4px] gap-2">
            <b className="relative sm:text-sm">{LL0().onlineProdiverSetting.password()}</b>
            <Input className={'self-stretch w-full'} value={editingOnlineProvider()?.password || ''} onChange={HANDLERS.onPasswordChanged}/>
          </div>
          )}
        </div>
        <div className="self-stretch flex flex-row items-start justify-center height_414:gap-[6px]">
          {HANDLERS.isFieldAvailable('apiKey') && (
          <div className="flex-1 flex flex-col items-start justify-start height_414:gap-[4px] gap-2">
            <b className="relative sm:text-sm">{LL0().onlineProdiverSetting.apiKey()}</b>
            <Input className={'self-stretch w-full'} value={editingOnlineProvider()?.apiKey || ''} onChange={HANDLERS.onApiKeyChanged}/>
          </div>
          )}
        </div>
        {/*<div className="self-stretch flex flex-col items-start justify-start height_414:gap-[4px] gap-2">*/}
        {/*  <b className="relative sm:text-sm">{LL0().dashboard.serviceType()}</b>*/}

        {/*  <FormControl sx={{ width: '100%' }}>*/}
        {/*    <InputLabel id="demo-multiple-chip-label"/>*/}
        {/*    <Select*/}
        {/*      labelId="demo-multiple-chip-label"*/}
        {/*      id="demo-multiple-chip"*/}
        {/*      multiple*/}
        {/*      size="small"*/}
        {/*      className="w-full"*/}
        {/*      value={servicesType}*/}
        {/*      onChange={e => setServicesType(e.target.value)}*/}
        {/*      input={<OutlinedInput className="flex-1" id="select-multiple-chip" label="" />}*/}
        {/*      renderValue={(selected) => (*/}
        {/*        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>*/}
        {/*          {selected.map((value, index) => (*/}
        {/*            <Chip key={index} label={value.name} />*/}
        {/*          ))}*/}
        {/*        </Box>*/}
        {/*      )}*/}
        {/*      MenuProps={{*/}
        {/*        PaperProps: {*/}
        {/*          style: {*/}
        {/*            maxHeight: 48 * 4.5 + 8,*/}
        {/*            width: 250,*/}
        {/*          },*/}
        {/*        },*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {services.map((service, index) => (*/}
        {/*        <MenuItem key={index} value={service}>*/}
        {/*          <Checkbox checked={servicesType.includes(service)} />*/}
        {/*          <ListItemText primary={_.get(LL0().dashboard, service.value)?.()} />*/}
        {/*        </MenuItem>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}

        {/*  /!*<div className="self-stretch flex flex-col items-start justify-start relative gap-[2px] text-sm text-black-solid-black-880-1d1d26">*!/*/}
        {/*  /!*  <div className="self-stretch rounded bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-start py-[9px] px-2.5 z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff">*!/*/}
        {/*  /!*    <div className="flex-1 flex flex-row flex-wrap items-center justify-start gap-[4px]">*!/*/}
        {/*  /!*      <div className="rounded-81xl bg-white-solid-white-100-ffffff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.2)] box-border h-[26px] flex flex-row items-center justify-center py-0 px-2 border-[0.5px] border-solid border-gray-solid-gray-380-cdcdcd">*!/*/}
        {/*  /!*        <div className="relative font-medium Tablet_768:text-smi">*!/*/}
        {/*  /!*          {LL0().dashboard.syncOnlineOrder()}*!/*/}
        {/*  /!*        </div>*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*      <div className="rounded-81xl bg-white-solid-white-100-ffffff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.2)] box-border h-[26px] flex flex-row items-center justify-center py-0 px-2 border-[0.5px] border-solid border-gray-solid-gray-380-cdcdcd">*!/*/}
        {/*  /!*        <div className="relative font-medium Tablet_768:text-smi">*!/*/}
        {/*  /!*          {LL0().dashboard.syncOnlineMenu()}*!/*/}
        {/*  /!*        </div>*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*      <div className="rounded-81xl bg-white-solid-white-100-ffffff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.2)] box-border h-[26px] flex flex-row items-center justify-center py-0 px-2 border-[0.5px] border-solid border-gray-solid-gray-380-cdcdcd">*!/*/}
        {/*  /!*        <div className="relative font-medium Tablet_768:text-smi">*!/*/}
        {/*  /!*          {LL0().dashboard.deliveryStatus()}*!/*/}
        {/*  /!*        </div>*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*    <div className="relative w-[18px] h-[18px] overflow-hidden shrink-0">*!/*/}
        {/*  /!*      <img*!/*/}
        {/*  /!*        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"*!/*/}
        {/*  /!*        alt=""*!/*/}
        {/*  /!*        src="/icongeneralarrow-down-icon--black@2x.png"*!/*/}
        {/*  /!*      />*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*  <div className="my-0 mx-[!important] absolute w-full right-[0px] bottom-[-138px] left-[0px] rounded bg-white-solid-white-100-ffffff shadow-[0px_2px_6px_rgba(0,_0,_0,_0.12)] box-border h-[136px] overflow-y-auto shrink-0 flex flex-col items-start justify-start py-1 pr-3 pl-0 gap-[4px] z-[999] text-black-solid-black-1000-000000 border-[1px] border-solid border-gray-solid-gray-320-d2d2d2">*!/*/}
        {/*  /!*    <div className="self-stretch h-10 shrink-0 flex flex-row items-center justify-start py-2 px-2.5 box-border gap-[8px]">*!/*/}
        {/*  /!*      <div className="relative w-5 h-5">*!/*/}
        {/*  /!*        <FormControlLabel*!/*/}
        {/*  /!*          className="absolute h-[120%] w-[120%] top-[-10%] right-[-10%] bottom-[-10%] left-[-10%]"*!/*/}
        {/*  /!*          label=""*!/*/}
        {/*  /!*          control={<Checkbox color="primary" defaultChecked />}*!/*/}
        {/*  /!*        />*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*      <div className="relative">{LL0().dashboard.syncOnlineOrder()}</div>*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*    <div className="self-stretch h-10 shrink-0 flex flex-row items-center justify-start py-2 px-2.5 box-border gap-[8px]">*!/*/}
        {/*  /!*      <div className="relative w-5 h-5">*!/*/}
        {/*  /!*        <FormControlLabel*!/*/}
        {/*  /!*          className="absolute h-[120%] w-[120%] top-[-10%] right-[-10%] bottom-[-10%] left-[-10%]"*!/*/}
        {/*  /!*          label=""*!/*/}
        {/*  /!*          control={<Checkbox color="primary" defaultChecked />}*!/*/}
        {/*  /!*        />*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*      <div className="relative">{LL0().dashboard.syncOnlineMenu()}</div>*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*    <div className="self-stretch h-10 shrink-0 flex flex-row items-center justify-start py-2 px-2.5 box-border gap-[8px]">*!/*/}
        {/*  /!*      <div className="relative w-5 h-5">*!/*/}
        {/*  /!*        <FormControlLabel*!/*/}
        {/*  /!*          className="absolute h-[120%] w-[120%] top-[-10%] right-[-10%] bottom-[-10%] left-[-10%]"*!/*/}
        {/*  /!*          label=""*!/*/}
        {/*  /!*          control={<Checkbox color="primary" defaultChecked />}*!/*/}
        {/*  /!*        />*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*      <div className="relative">{LL0().dashboard.deliveryStatus()}</div>*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</div>*/}
      </div>
      <div className="self-stretch flex-1 rounded-[3px] bg-gray-solid-gray-180-ebecee flex flex-col items-center justify-start max-h-[260px] z-[1] text-center text-3xl text-black-solid-black-1000-000000 height_414:h-full height_414:max-h-[160px]">
        <TableManualKeyboard
          value={''}
          inputController={inputController0}
          onEnter={handleComplete}
        />
      </div>
      <img
        className="w-[21px] absolute !m-[0] top-[11px] right-[13px] h-[21px] object-cover cursor-pointer z-[0]"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default OnlineProviderSettingAddEd;
