import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { LL0 } from '@/react/core/I18nService'
import { CONSTANT_VALUES } from '@/srm/lib/constants'
import type { RequestHeadersWithTax } from '@/srm/lib/types'

import { getSignableSdk } from './getSignableSdk'
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

export function getSignableSdkWithHeadersAndTaxNum() {
  const { productId, productVersionId, partnerId, qstNumber, gstNumber, testcaseNumber, version, previousVersion, certificateCode, env } = posSetting0()?.srm ?? {}
  const { deviceId } = deviceSetting0()?.srm ?? {}

  const genericMsg = ' ' + LL0().srm.messages.checkConfig()

  if (!productId) throw new Error(LL0().srm.errors.missingProductCode() + genericMsg)
  if (!qstNumber) throw new Error(LL0().srm.errors.missingQstNumber() + genericMsg)
  if (!gstNumber) throw new Error(LL0().srm.errors.missingGstNumber() + genericMsg)
  if (!deviceId) throw new Error(LL0().srm.errors.missingDeviceId() + genericMsg)
  if (!productVersionId) throw new Error(LL0().srm.errors.missingProductVersionCode() + genericMsg)
  if (!partnerId) throw new Error(LL0().srm.errors.missingPartnerCode() + genericMsg)
  if (!version) throw new Error(LL0().srm.errors.missingProductVersion() + genericMsg)
  if (!previousVersion) throw new Error(LL0().srm.errors.missingPreviousProductVersion() + genericMsg)
  if (!certificateCode) throw new Error(LL0().srm.errors.missingAuthorizationCode() + genericMsg)

  const sdk = getSignableSdk()
  const headers: RequestHeadersWithTax = {
    ENVIRN: env ?? 'DEV',
    CASESSAI: testcaseNumber ?? CONSTANT_VALUES.CASESSAI_EMPTY,
    NOTPS: gstNumber,
    NOTVQ: qstNumber,
    APPRLINIT: 'SEV',
    IDAPPRL: deviceId,
    IDSEV: productId,
    IDVERSI: productVersionId,
    CODCERTIF: certificateCode,
    IDPARTN: partnerId,
    VERSI: version,
    VERSIPARN: previousVersion,
  }
  return { sdk, headers, gstNumber, qstNumber, deviceId } as const
}
