import type {FunctionComponent} from "react";
import {LL0} from "@/react/core/I18nService.tsx";

export type GeneralSettingsTrainingModeType = {
  className?: string;
  onClose?: () => void;
  onComplete?: () => void;
};

const GeneralSettingsTrainingMode: FunctionComponent<
  GeneralSettingsTrainingModeType
> = ({ className = "", onClose, onComplete }) => {
  return (
    <div
      className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-[30px] px-6 pb-6 box-border gap-[24px] min-w-[280px] h-full max-w-full max-h-full text-center text-xl text-red-solid-red-550-cf2222 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <b className="self-stretch relative leading-[28px]">
          {LL0().settings.turnOffConfirm()}
        </b>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 box-border max-w-[100px] text-white-solid-white-100-ffffff"
             onClick={() => {
               onComplete?.();
               onClose?.();
             }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettingsTrainingMode;
