import {signal} from "@/react/core/reactive";

export const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = reject;
});

export const [now, setNow] = signal<Date>(new Date())
setInterval(() => setNow(() => new Date()), 1000)

export const convertBase64ToPngBase64 = (jpegBase64: any) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.src = jpegBase64;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const pngBase64 = canvas.toDataURL('image/png');
      resolve(pngBase64);
    };
    img.onerror = (error) => reject(error);
  });
};

export async function imageUrlToBase64(imageUrl: any) {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
export async function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}