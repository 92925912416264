import hotkeys from 'hotkeys-js'

import { autoLogin, logOut } from '@/data/UserHub.ts'
import { PosScreen, router } from '@/pos/PosRouter.ts'
import { signal } from '@/react/core/reactive.ts'
import { setOpenDateTimePickerPopup } from '@/react/Developer/DateTimePickerPopup.tsx'
import { setOpenMockTSEPopup, setOpenVerifyTSEPopup } from '@/react/Developer/MockTSEPopup.tsx'
import { mainScreen } from "@/data/PosSettingsSignal.ts";

export const [autoLoginShortcut, setAutoLoginShortcut] = signal<boolean>(false)

const handle = async (e: KeyboardEvent, handler: hotkeys.HandlerFunction) => {
  e.preventDefault()
  if (await autoLogin()) {
    setAutoLoginShortcut(true)
    router.screen = mainScreen()
  } else {
    await logOut()
  }
}

hotkeys('⌘+l,ctrl+l', handle)

const openPopupDatePicker = (e: KeyboardEvent, handler: hotkeys.HandlerFunction) => {
  // setOpenDateTimePickerPopup(prev => !prev)
  if (import.meta.env.MODE === 'production') {
    setOpenVerifyTSEPopup(true)
  } else {
    setOpenMockTSEPopup(true)
  }
  e.preventDefault()
}
hotkeys('⌘+i,ctrl+i', openPopupDatePicker)

const handleOpenMockTSEPopup = (e: KeyboardEvent, handler: hotkeys.HandlerFunction) => {
  setOpenMockTSEPopup(prev => !prev)
  e.preventDefault()
}

hotkeys('⌘+q,ctrl+q', handleOpenMockTSEPopup)

const openDeveloperScreen = (e: KeyboardEvent, handler: hotkeys.HandlerFunction) => {
  router.screen = PosScreen.DEVELOPER
  e.preventDefault()
}

hotkeys('⌘+d,ctrl+d', openDeveloperScreen)

const openTerminalScreen = (e: KeyboardEvent, handler: hotkeys.HandlerFunction) => {
  router.screen = PosScreen.TERMINAL
  e.preventDefault()
}
hotkeys('⌘+t,ctrl+t', openTerminalScreen)

const openEod = (e: KeyboardEvent, handler: hotkeys.HandlerFunction) => {
  router.screen = PosScreen.EOD
}

hotkeys('⌘+e,ctrl+e', openEod)

hotkeys('⌘+m,ctrl+m', () => {
  router.screen = PosScreen.EDIT_MENU
})

hotkeys('⌘+k,ctrl+k', () => {
  router.screen = PosScreen.MASTER_MACHINE
})

hotkeys('⌘+u,ctrl+u', () => {
  router.screen = PosScreen.EDIT_USER
})

hotkeys('⌘+u,ctrl+s', () => {
  router.screen = PosScreen.GENERAL_SETTING
})
