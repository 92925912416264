export default function hook() {
  const ehMap: Record<string, Function[]> = {}
  return {
    on(event: string, handler: Function) {
      if (typeof handler !== 'function')
        throw new Error("Invalid handler")
      if (!ehMap[event])
        ehMap[event] = []
      ehMap[event].push(handler)
    },
    off(event: string, handler?: Function) {
      if (!ehMap || !ehMap[event])
        return
      if (!handler)
        ehMap[event] = []
      else
        ehMap[event] = ehMap[event].filter(h => h != handler)
    },
    emit(event: string, ...data: any): void | Promise<any> {
      const ehs = ehMap[event]
      if (!ehs)
        return
      for (const eh of ehs) {
        const rs = eh(...data)
        const isPromise = rs && typeof rs.then === 'function'
        if (isPromise)
          rs.then().catch((e: any) => console.error(e.message, {fn: 'hook::emit', event, data}))
      }
    }
  }
}