import TseClient from "tse-client";
import { toast } from "react-toastify";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { TseServerMap } from "@/tse/tse-variable.ts";
import { TseType } from "@/tse/tse-utils.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const username0 = 'testa11';
const password0 = '123456'

const typeMap = {
  [TseType.TseServerMock]: 'test',
  [TseType.TseServerTest]: 'test',
  [TseType.TseServer]: 'production',
}

export function getTseServerUrl() {
  return TseServerMap[tseConfig0().type || TseType.TseServerMock];
}

export async function tseServerSignup(username: string = username0, password: string = password0) {
  console.log('url', getTseServerUrl());
  const tse = new TseClient();
  const deviceId = deviceSetting0()?._id;
  await tse.connect({
    ns: "cloud",
    db: "tse",
    url: getTseServerUrl(),
  });
  try {
    await tse.signup({
      scope: 'user',
      username: username,
      password: password
    })
    toast('TSE Server Signup Success');
  } catch (e: Error) {
    if (typeof e === 'string') e = new Error(e);
    toast('Signup: ' + e.message);
    // throw e;
  }
  try {
    // toast('TSE Server Signup Success');
    await tse.connectAndSignIn({
      ns: 'cloud',
      db: 'tse',
      url: getTseServerUrl(),
      scope: 'user',
      username: username,
      password: password,
      httpUrl: 'https://tse-01.horeca-24.de:8888'
    });
    const testFlag = tseConfig0().type === TseType.TseServerTestGermany;
    await tse.registerClient(testFlag);
    toast('TSE Server Register Success');
  } catch (err: Error) {
    if (typeof err === 'string') err = new Error(err);
    toast('Register: ' + err.message);
    throw err;
  }
}

export async function connectToServer(tse: TseClient) {
  const username0 = tseConfig0()?.username;
  const password0 = tseConfig0()?.password;
  await tse.connectAndSignIn({
    ns: 'cloud',
    db: 'tse',
    url: getTseServerUrl(),
    scope: 'user',
    username: username0,
    password: password0,
    httpUrl: 'https://tse-01.horeca-24.de:8888'
  });
}

export async function testTseServer1(username: string = username0, password: string = password0) {
  const tse = new TseClient();
  await tse.connectAndSignIn({
    ns: 'cloud',
    db: 'tse',
    url: getTseServerUrl(),
    scope: 'user',
    username: username0,
    password: password0
  });
  try {
    // await tse.registerClient();
    const info = await tse.getInfo();
    console.log('info', info);
    const res = await tse.startTransaction("ABCD", "TR-Process");
    console.log('res', res);
    const finishRes = await tse.finishTransaction(res.txNumber, "ABCD1", "TR-Process")
    console.log('wtf', finishRes);
  } catch (e) {
    console.log('error', e);
  }
}

export async function testTseServer2() {
  const username0 = tseConfig0()?.username;
  const password0 = tseConfig0()?.password;
  const tse = new TseClient();
  await tse.connectAndSignIn({
    ns: 'cloud',
    db: 'tse',
    url: getTseServerUrl(),
    scope: 'user',
    username: username0,
    password: password0
  });
  try {
    // await tse.registerClient();
    const info = await tse.getInfo();
    console.log('info', info);
    const res = await tse.startTransaction("ABCD", "TR-Process");
    console.log('res', res);
    const finishRes = await tse.finishTransaction(res.txNumber, "ABCD1", "TR-Process")
    console.log('wtf', finishRes);
  } catch (e) {
    console.log('error', e);
  }
}

export async function testRegisterTse() {
  try {
    const username0 = tseConfig0()?.username;
    const password0 = tseConfig0()?.password;
    const tse = new TseClient();
    // toast('TSE Server Signup Success');
    await tse.connectAndSignIn({
      ns: 'cloud',
      db: 'tse',
      url: getTseServerUrl(),
      scope: 'user',
      username: username0,
      password: password0
    });
    await tse.registerClient();
    toast('TSE Server Register Success');
  } catch (err: Error) {
    if (typeof err === 'string') err = new Error(err);
    toast('Register: ' + err.message);
    throw err;
  }
}

//@ts-ignore
window.tseServerSignup = tseServerSignup;
//@ts-ignore
window.testTseServer1 = testTseServer1;
//@ts-ignore
window.testTseServer2 = testTseServer2;
//@ts-ignore
window.testRegisterTse = testRegisterTse;