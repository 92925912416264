import {FunctionComponent} from "react";
import CategoryList from "./CategoryList";
import LeftItemGrid from "./LeftItemGrid";

const SyncMenuLeft: FunctionComponent = () => {
  return (
    <div className="bg-gray-solid-gray-165-f8f8f8 self-stretch flex-1 flex flex-col items-center justify-start pt-[5px] px-0 pb-0 text-center text-sm text-black-solid-black-900-1e1e23 font-mulish">
      <div className='absolute inset-0 z-0 bg-black-opacity-black-80-1b2533'>
        <img src="/out@2x.png" alt="" className='h-full w-full'/>
      </div>
      <div
        className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 px-[5px] pb-[5px] box-border">
        <CategoryList/>
      </div>
      <div
        className="self-stretch flex-1 flex flex-col items-center justify-start py-0.5 px-[5px] text-left text-smi text-black-opacity-black-70-1d1d26">
        <LeftItemGrid/>
      </div>
    </div>
  );
};

export default SyncMenuLeft;
