import {type FunctionComponent, useState, useCallback } from 'react'
import { Switch, FormControlLabel } from '@mui/material'
import UserSettingUserPermissionPopupInfo from './UserSettingUserPermissionPopupInfo'
import PortalPopup from './PortalPopup'
import {getPopupScreenName, getInfoFor, userSettingsSwitchProps} from '@/react/UserSettingView/UserSettingView'
import type { User } from '@/data/User'
import {LL0} from "@/react/core/I18nService.tsx";
import { PosScreen } from "@/pos/PosRouter.ts";

const LeftUserPermission: FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  const [popupTitle, setPopupTitle] = useState('')
  const [popupContent, setPopupContent] = useState('')
  const [popupScreen, setPopupScreen] = useState<PosScreen | null>(null)

  const closePopup = useCallback(() => setOpen(false), [])
  // const openPopupFor = useCallback(
  //   (field: keyof User,) => () => {
  //     const [title, content] = getInfoFor(field)
  //     setPopupTitle(title)
  //     setPopupContent(content)
  //     setOpen(true)
  //   },
  //   []
  // )

  const openPopupFor = useCallback(
    (field: keyof User) => () => {
      const [title, content] = getInfoFor(field)
      setPopupTitle(title)
      setPopupContent(content)
      setOpen(true)
      setPopupScreen(getPopupScreenName(field));
    },
    []
  )

  return (
    <>
      <div className="flex-1 flex flex-col items-start justify-center gap-[10px]">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('viewOrderHistory')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().dashboard.orderHistory()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('viewOrderHistory')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('viewOtherStaffReport')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().dashboard.staffReport()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('viewOtherStaffReport')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('viewOwnReport')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.viewOwnReport()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('viewOwnReport')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('accessZReport')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().report.runEndOfDay()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('accessZReport')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('viewMonthlyReport')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().dashboard.monthlyReport()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('viewMonthlyReport')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('viewCustomerInfo')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.viewCustomerInfo()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('viewCustomerInfo')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('manageInventory')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.manageInventory()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('manageInventory')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('editThePriceOfAMenuItem')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.editThePriceOfAMenuItem()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('editThePriceOfAMenuItem')}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-start gap-[4px]">
            <div className="w-12 relative h-6">
              <FormControlLabel
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    {...userSettingsSwitchProps('deletePrintedItem')}
                  />
                }
              />
            </div>
            <div className="flex-1 relative sm:text-smi Tablet_768:text-xs">
              {LL0().settings.deletePrintedItem()}
            </div>
          </div>
          <img
            className="w-11 relative h-11 object-cover cursor-pointer"
            alt=""
            src="/iconquestion-icon@2x.png"
            onClick={openPopupFor('deletePrintedItem')}
          />
        </div>
      </div>
      {open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePopup}
        >
          <UserSettingUserPermissionPopupInfo
            onClose={closePopup}
            title={popupTitle}
            content={popupContent}
            screen={popupScreen}
          />
        </PortalPopup>
      )}
    </>
  )
}

export default LeftUserPermission
