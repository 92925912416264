import type { Customer } from '@/data/Customer.ts'
import { clsx } from 'clsx'
import { LL0 } from '@/react/core/I18nService.tsx';
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type CustomerInfoRowProps = Customer & {
  isEven: boolean
}

const CustomerInfoRow = (
  {
    id,
    name,
    note,
    phoneNumber,
    email,
    addresses,
    isEven,
    spending,
    numberOfOrders,
    defaultAddressIndex
  }: CustomerInfoRowProps) => {
  return (
    <>
      <div
        className={clsx('!h-full !row-auto bg-gray-solid-gray-180-ebecee box-border h-[60px] flex flex-row items-start justify-center py-3 px-2 relative col-[1/1] row-[3/3] text-grey-grey-darken-3 border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px]', isEven && "bg-white")}>
        <div className="self-stretch flex-1 relative font-semibold sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
          {id}
        </div>
      </div>
      <div
        className={clsx('!h-full !row-auto bg-gray-solid-gray-180-ebecee box-border h-[60px] flex flex-row items-start justify-center py-3 px-2 relative col-[2/2] row-[3/3] text-left text-grey-grey-darken-3 border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px]', isEven && "bg-white")}>
        <div className="self-stretch flex-1 relative font-semibold sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
          {name}
        </div>
      </div>
      <div
        className={clsx('!h-full !row-auto bg-gray-solid-gray-180-ebecee box-border h-[60px] flex flex-row items-start justify-center py-3 px-2 relative col-[3/3] row-[3/3] text-grey-grey-darken-3 border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_600:relative Tablet_600:col-[3/3] Tablet_600:row-[3/3]', isEven && "bg-white")}>
        <div className="self-stretch flex-1 relative font-semibold sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
          {phoneNumber || email}
        </div>
      </div>
      <div
        className={clsx('!h-full !row-auto bg-gray-solid-gray-180-ebecee box-border h-[60px] flex flex-row items-start justify-center py-3 px-2 relative col-[4/4] row-[3/3] text-left text-grey-grey-darken-3 border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_600:relative Tablet_600:col-[4/4] Tablet_600:row-[3/3]', isEven && "bg-white")}>
        <div
          className="self-stretch flex-1 relative font-semibold [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
          {addresses?.[defaultAddressIndex]?.home} {addresses?.[defaultAddressIndex]?.street}
        </div>
      </div>
      <div
        className={clsx('!h-full !row-auto bg-gray-solid-gray-180-ebecee box-border h-[60px] flex flex-row items-start justify-center py-3 px-2 relative col-[5/5] row-[3/3] text-grey-grey-darken-3 border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px]', isEven && "bg-white")}>
        <div className="self-stretch flex-1 relative font-semibold sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
          {numberOfOrders}
        </div>
      </div>
      <div
        className={clsx('!h-full !row-auto bg-gray-solid-gray-180-ebecee box-border h-[60px] flex flex-row items-start justify-center py-3 px-2 relative col-[6/6] row-[3/3] text-grey-grey-darken-3 border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px]', isEven && "bg-white")}>
        <div className="self-stretch flex-1 relative font-semibold sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(spending || 0)}
        </div>
      </div>
      <div
        className={clsx('!h-full !row-auto bg-gray-solid-gray-180-ebecee box-border h-[60px] flex flex-row items-start justify-center py-3 px-2 relative col-[7/7] row-[3/3] text-grey-grey-darken-3 border-b-[1px] border-solid border-black-solid-black-700-364150', isEven && "bg-white")}>
        <div
          className="self-stretch flex-1 relative font-semibold [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
          {note}
        </div>
      </div>
    </>
  )
}
export default CustomerInfoRow