import type { FunctionComponent } from "react";
import { LL0 } from '@/react/core/I18nService.tsx'
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { handleRefund, order1, selectedRefundAmount } from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { UserAction, recordUserActionHistory } from "@/data/UserActionHistory.ts";

export type RefundAmountConfirmationPType = {
  onClose?: () => void;
};

const RefundAmountConfirmationP: FunctionComponent<
  RefundAmountConfirmationPType
> = ({ onClose }) => {

  return (
    <div className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px] min-w-[360px] h-full max-w-full max-h-full text-center text-xl text-black-solid-black-1000-000000 font-mulish-button-name-bold">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div className="self-stretch flex flex-row items-center justify-center gap-[4px]">
          <b className="relative leading-[28px]">{LL0().onlineOrder.refund()}</b>
          <b className="relative leading-[28px] text-red-solid-red-660-8f181d">
            {LL3().format.currency(selectedRefundAmount() || 0)}
          </b>
        </div>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-black-solid-black-880-1d1d26">
          {LL0().orderHistory.refundIrreversibleConfirm()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={async() => {
            onClose?.()
            await recordUserActionHistory(order1(), UserAction.CANCEL_REFUND)
          }}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
             ref={makeRipple}
             onClick={async () => {
               await handleRefund();
               onClose?.()
             }}
        >
          <b className="relative">{LL0().onlineOrder.refund()}</b>
        </div>
      </div>
    </div>
  );
};

export default RefundAmountConfirmationP;
