import dayjs from 'dayjs'
import debug from 'debug'
import _ from 'lodash'
import type { RxDocument } from 'rxdb'
import { concatMap, delay, distinctUntilKeyChanged, filter, firstValueFrom, from, Subscription } from 'rxjs'
import uuid from 'time-uuid'

import { MasterAction, MasterActionType, type MasterPrintAction } from '@/data/MasterAction'
import { printInvoiceFromRaster } from '@/react/Printer/print-invoice'
import { VPrinter } from '@/react/Printer/VPrinter'
import { consoleGroup, handleError, progressToast } from '@/shared/decorators'
import { getDeviceId } from '@/shared/getDeviceId'

const log = debug('dev:master-action-logic')

class MasterActionLogic {
  /** Registers the master action processing logic. */
  registerQueue(): Subscription {
    log('🔌 Registering master action processing queue...')
    const subscription = MasterAction.findOne({
      selector: { processed: { $ne: true } },
      sort: [{ createAt: 'asc' }], // Take the oldest action first
    })
      .$.pipe(
        filter(Boolean), // Skip null values
        distinctUntilKeyChanged('_id'), // Ensure action is not repeated
        // Ensures that each action is processed in sequence.
        // Even when new actions are added, they will wait for the previous
        // one to finish, and there will be a delay before the next one starts
        concatMap(action => from(this.processAction(action)).pipe(delay(500)))
      )
      .subscribe()
    return subscription
  }

  @consoleGroup(([a]) => `Processing action [${a.type}] from [${a.requester}]...`)
  @progressToast(a => `Processing action [${a.type}] from [${a.requester}]...`) // TODO: add i18n
  @handleError()
  private async processAction(action: RxDocument<MasterAction>): Promise<void> {
    try {
      if (action.type === MasterActionType.print) {
        const raster = await new VPrinter(action.payload.address, { scripts: action.payload.scripts }).getRasterFromSavedScript()
        await printInvoiceFromRaster(raster, action.payload)
      } else {
        throw new Error(`Unknown action type ${action.type}`)
      }
      log('🎉 Master action processed', action)
      await action.incrementalPatch({
        processed: true,
        processedAt: dayjs().unix(),
        // TODO: Add data to return to client
        result: { success: true, data: 'OK' },
      })
    } catch (error) {
      log('❌ Error processing action', error)
      const msg = error instanceof Error ? error.message : 'Unknown error:' + JSON.stringify(error)
      await action.incrementalPatch({
        processed: true,
        processedAt: dayjs().unix(),
        result: { success: false, error: msg },
      })
    }
  }

  /**
   * Executes the master print action.
   *
   * @param scripts - The printing scripts to execute.
   * @param address - The printer address to use.
   * @returns A promise that resolves when the action is processed.
   *
   * @remarks
   * This function inserts a new MasterAction document with the type set to `print` and the provided script(s) as the payload.
   * It waits for the action to be processed before resolving the promise with the result data.
   */
  @handleError()
  async runMasterPrintAction({ scripts, address, metadata }: MasterPrintAction['payload']): Promise<unknown> {
    log('⚡️ Running master print action', scripts)
    // Save the action to the database. Will be processed by the master machine.
    const record = await MasterAction.insert({
      _id: uuid(),
      createAt: dayjs().unix(),
      requester: getDeviceId(),
      type: MasterActionType.print,
      payload: {
        scripts: _.cloneDeep(scripts),
        address: _.cloneDeep(
          _.pick(address, [
            // Only take printer address related fields
            'escPOS',
            'numberOfCharactersPerLine',
            'printerType',
            'canvasWidth',
            'sound',
            'ip',
            'dropConnection',
            'address',
            'baudrate',
            'compress',
          ])
        ),
        ...(metadata ? { metadata: _.cloneDeep(metadata) } : {}),
      },
      processed: false,
    })
    // Wait for the action to be processed
    log('📝 Waiting master print action to be processed...', record)
    const { result } = await firstValueFrom(record.$.pipe(filter(doc => doc.processed === true)))
    if (!result) throw new Error('Failed to process action')
    if (!result.success) throw new Error(result.error)
    log('🎉 Master print action processed', result)
    return result.data
  }
}

export const masterActionLogic = new MasterActionLogic()
