import { LL2 } from "@/react/core/I18nBackend.tsx";
import type { Voucher } from "@/data/Voucher.ts";
import dayjs from "dayjs";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { invoiceGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import type { ScriptedRaster } from "@/shared/printer/types"

export const printVoucher = async (vouchers: Voucher[]): Promise<ScriptedRaster[]> => {
  const LL = LL2()
  
  const {name: companyName} = companyInfo0();
  
  const results: ScriptedRaster[] = []
  
  for (const voucher of vouchers) {
    const printer = new VPrinter({...invoiceGroupPrinters0()[0].printers[0]});
    const {price, createdAt, createdBy, code} = voucher
    await printer.alignCenter()
    await printer.setFontSize(35)
    await printer.bold(true)
    await printer.println(LL.printing.voucher())
    await printer.setFontSize(30)
    await printer.bold(false)
    await printer.println(companyName || "")
    await printer.newLine(10)
    await printer.setFontSize(120)
    await printer.bold(true)
    await printer.println(LL.format.currency(price || 0))
    await printer.newLine(10)
    await printer.alignLeft()
    await printer.setFontSize(30)
    await printer.bold(false)
    await printer.println(LL.printing.signature())
    await printer.drawLine()
    await printer.setFontSize(60)
    await printer.alignCenter()
    await printer.println(code || "")
    // await printer.printBarcode(code || "", {
    //   width: 540,
    //   height: 40
    // })
    await printer.setFontSize(30)
    await printer.println(dayjs.unix(createdAt || 0).format(LL.dates.datetimeFormat()))
    await printer.println(`(${createdBy})`)
    results.push(await printer.getRaster())
  }

  return results
}
