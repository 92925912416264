import {
  cashbookHistoryList,
  CashbookTransactionsAction,
  formatDate,
  getCashBookTransactions,
  getCurrentVdate,
  setTransactions,
  transactions,
  VIEW__HISTORY_DETAILS,
  VIEW__HISTORY_TRANSACTION_DETAILS
} from "@/react/CashbookView/CashbookView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { type FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import CashbookHistoryCloseDayPop from "./CashbookHistoryCloseDayPop";
import CashbookPriceBox from "./CashbookPriceBox";
import PortalPopup from "./PortalPopup";
import CashbookExportPDFPopup from "./CashbookExportPDFPopup";
import clsx from "clsx";
import type { CashbookHistories, CashbookTransactions } from "@/data/Cashbook";
import type { RxDocument } from "rxdb";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import CashbookReprintConfirm from "./CashbookReprintConfirm";
import { LL2 } from "@/react/core/I18nBackend.tsx";

const CashbookHistoryDetailsP: FunctionComponent = () => {
  const [isCashbookHistoryCloseDayPopOpen, setCashbookHistoryCloseDayPopOpen] =
    useState(false);
  const [isCashbookExportPDFOpen, setIsCashbookExportOpen] =
    useState(false);

  const [isTapHereOpen, setTapHereOpen] =
    useState(true);

  const [
    isCashbookReprintConfirmPopupOpen,
    setCashbookReprintConfirmPopupOpen,
  ] = useState(false);

  const openCashbookReprintConfirmPopup = useCallback(() => {
    setCashbookReprintConfirmPopupOpen(true);
  }, []);

  const closeCashbookReprintConfirmPopup = useCallback(() => {
    setCashbookReprintConfirmPopupOpen(false);
  }, []);

  const openCashbookExportPDFPopup = useCallback(() => {
    setIsCashbookExportOpen(true);
  }, []);

  const closeCashbookExportPDFPopup = useCallback(() => {
    setIsCashbookExportOpen(false);
  }, []);

  const openCashbookHistoryCloseDayPopup = useCallback(() => {
    setCashbookHistoryCloseDayPopOpen(true);
  }, []);

  const closeCashbookHistoryCloseDayPopup = useCallback(() => {
    setCashbookHistoryCloseDayPopOpen(false);
  }, []);

  const historyDetails = useMemo(() => cashbookHistoryList().find((item: RxDocument<CashbookHistories, {}>) => item.id === VIEW__HISTORY_DETAILS.historyDetailsId()), [cashbookHistoryList(), VIEW__HISTORY_DETAILS.historyDetailsId()])

  const isDisabledCloseDay = useMemo(() => historyDetails?.isClosed || historyDetails?.vDate === getCurrentVdate(), [historyDetails?.isClosed, historyDetails?.vDate, getCurrentVdate()])

  useEffect(() => {
    if (isDisabledCloseDay) return

    const timeoutId = setTimeout(() => {
      setTapHereOpen(false)
    }, 10000)
    return () => clearTimeout(timeoutId)
  }, []);


  useEffect(() => {
    (async () => {
      if (!historyDetails) return
      const transactions = await getCashBookTransactions(historyDetails.id, historyDetails.firstTransactionTime, historyDetails.lastTransactionTime)
      console.log("transactions", transactions);
      setTransactions(transactions.sort((a, b) => b.date - a.date))
    })()
  }, [])

  return (
    <>
      <div
        className="bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[0px] text-left text-smi text-black-solid-black-1000-000000 font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start">
        <div
          className="self-stretch bg-black-solid-black-500-59636f h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[5px] px-2.5 box-border sm:h-[42px]">
          <div className="flex flex-row items-center justify-start gap-2.5">
            <button
              className={clsx(!historyDetails?.isClosed && "opacity-[0.54]", "rounded-xl bg-cyan-solid-cyan-250-8aeaff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] w-[120px] h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center gap-[8px] sm:h-8")}
              onClick={openCashbookExportPDFPopup}
              disabled={!historyDetails?.isClosed}
            >
              <img
                className="relative w-5 h-5 object-cover"
                alt=""
                src="/iconexport@2x.png"
              />
              <div className="relative font-semibold sm:text-3xs">{LL0().cashbook.pdfExport()}</div>
            </button>
            <div
              className="w-[108px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] rounded-xl bg-burlywood h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center gap-2 cursor-pointer sm:h-8"
              onClick={openCashbookReprintConfirmPopup}
            >
              <img
                className="w-[23px] relative h-[23px] object-cover"
                alt=""
                src="/iconexport1@2x.png"
              />
              <div className="relative font-semibold sm:text-3xs">Reprint</div>
            </div>
          </div>
          <div
            className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-center text-sm text-white-solid-white-100-ffffff sm:h-8"
            onClick={() => VIEW__HISTORY_DETAILS.onClick(0)}
            ref={makeRipple}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover sm:w-6 sm:h-6"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="relative leading-[17px] sm:text-2xs">{LL0().posSetup.accountTab.back()}</b>
          </div>
        </div>
        <div
          className="self-stretch flex-1 bg-black-solid-black-700-364150 overflow-y-auto flex flex-col items-center justify-start text-6xl text-green-solid-green-410-88c057">
          <div
            className="w-[600px] h-[206px] shrink-0 flex flex-row items-center justify-between py-5 px-0 box-border relative sm:w-[580px] sm:items-center sm:justify-center Tablet_768:w-[480px] Tablet_768:items-center Tablet_768:justify-center Tablet_600:h-auto Tablet_600:items-center Tablet_600:justify-center">
            <CashbookPriceBox
              icon="/cashbookshuttle@2x.png"
              title={LL0().cashbook.start()}
              cashNumber={historyDetails?.startBalance || 0}
              dateCash={historyDetails?.firstTransactionTime}
              isShowIcon
              isDefault
              showDateCash
            />
            <div
              className="flex-1 overflow-hidden flex flex-col items-center justify-center h-auto gap-[0px] z-[1] hover:flex hover:flex-1 hover:h-auto hover:flex-col hover:gap-[0px] hover:items-center hover:justify-center">
              <div className="overflow-hidden flex flex-row items-center justify-start gap-[16px] Tablet_768:gap-[8px]">
                <img
                  className="relative w-[26px] h-[26px] object-cover Tablet_768:w-6 Tablet_768:h-6"
                  alt=""
                  src="/iconarrow-increace@2x.png"
                />
                <div
                  className="relative flex items-center w-24 h-[21px] shrink-0 sm:text-3xl Tablet_768:text-xl Tablet_600:text-lg">
                  {LL3().format.currency(historyDetails?.incomingBalance || 0)}
                </div>
              </div>
              <div className="self-stretch overflow-hidden flex flex-row items-center justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full"
                  alt=""
                  src="/divider.svg"
                />
                <img
                  className="relative w-5 h-5 object-cover"
                  alt=""
                  src="/icontriangle-right1@2x.png"
                />
              </div>
              <div
                className="overflow-hidden flex flex-row items-center justify-start gap-[16px] text-orange-solid-orange-700-f0603e Tablet_768:gap-[8px]">
                <img
                  className="relative w-[26px] h-[26px] object-cover Tablet_768:w-6 Tablet_768:h-6"
                  alt=""
                  src="/iconarrow-decrease@2x.png"
                />
                <div
                  className="relative flex items-center w-24 h-[21px] shrink-0 sm:text-3xl Tablet_768:text-xl Tablet_600:text-lg">
                  {LL3().format.currency(historyDetails?.outgoingBalance || 0)}
                </div>
              </div>
            </div>
            <div onClick={openCashbookHistoryCloseDayPopup} className="flex-1">
              <CashbookPriceBox
                icon="/iconsafety@2x.png"
                title={LL0().cashbook.closed()}
                cashNumber={historyDetails?.closeBalance || 0}
                dateCash={historyDetails?.lastTransactionTime}
                isShowIcon
                isDefault
                showDateCash
              />
            </div>
            {isTapHereOpen && !isDisabledCloseDay &&
              <div
                className="my-0 mx-[!important] absolute w-[74px] top-[calc(50%_-_23px)] right-[-11.33%] left-[99%] h-[44.3px] flex flex-row items-center justify-center gap-[0px] z-[3] text-center text-xs text-black-solid-black-700-364150 hover:flex hover:w-[74px] hover:h-[44.3px] hover:flex-row hover:gap-[0px] hover:items-center hover:justify-center Tablet_600:w-16">
                <img
                  className="relative w-[13px] h-4 object-cover"
                  alt=""
                  src="/icontriangle-left@2x.png"
                />
                <div
                  className="rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.8),_rgba(174,_183,_193,_0.8))] overflow-hidden flex flex-row items-center justify-center p-0.5">
                  <i className="relative Tablet_768:text-3xs">
                    <p className="m-0">{`Tap here `}</p>
                    <p className="m-0">{`to close `}</p>
                    <p className="m-0">the day.</p>
                  </i>
                </div>
              </div>}
          </div>
          <table className="self-stretch overflow-hidden shrink-0 border-spacing-[0]">
            <thead>
            <tr>
              <th className="relative pr-0 pb-0">
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row gap-[0px] items-center justify-center border-r-[1px] border-solid border-white-solid-white-100-ffffff"/>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().cashbook.reportId()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().common.date()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().orderHistory.amount()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b
                    className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().orderHistory.type()}
                  </b>
                </div>
              </th>
              <th className="relative pb-0" colSpan={5}>
                <div
                  className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 pr-3 pl-0 box-border">
                  <b className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center">
                    {LL0().sidebar.user()}
                  </b>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            {transactions().map((t: RxDocument<CashbookTransactions, {}>, i) => (
              <tr key={t._id} className="bg-gray-solid-gray-180-ebecee">
                <td className="relative pr-0">
                  <div
                    className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-2 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                    <img
                      className="relative w-6 h-6 object-cover"
                      alt=""
                      src={t.action === CashbookTransactionsAction.INCOMING ? "/iconarrow-increace@2x.png" : "/cashbookarrow-decrease@2x.png"}
                    />
                  </div>
                </td>
                <td className="relative pr-0" colSpan={3}>
                  <div
                    className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                    <div
                      className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {`${historyDetails?.id}-${transactions().length - i}`}
                    </div>
                  </div>
                </td>
                <td className="relative pr-0" colSpan={3}>
                  <div
                    className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                    <div
                      className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {formatDate(t.date, `${LL2().dates.dateFormat()} | ${LL2().dates.timeFormat()}`)}
                    </div>
                  </div>
                </td>
                <td className="relative pr-0" colSpan={3}>
                  <div
                    className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                    <div
                      className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {LL3().format.currency(t.amount || 0)}
                    </div>
                  </div>
                </td>
                <td className="relative pr-0" colSpan={3}>
                  <div
                    className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                    <div
                      className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {t.transactionType}
                    </div>
                  </div>
                </td>
                <td className="relative" colSpan={5}
                    onClick={() => VIEW__HISTORY_TRANSACTION_DETAILS.onClick({
                      id: `${historyDetails?.id}-${transactions().length - i}`,
                      _id: t._id
                    })}
                    ref={makeRipple}
                >
                  <div
                    className="w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-end p-3 box-border">
                    <div
                      className="flex-1 relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                      {t.user}
                    </div>
                    <img
                      className="relative w-4 h-4 object-cover mq1440:w-5 mq1440:h-5"
                      alt=""
                      src="/iconarrowrightblack@2x.png"
                    />
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      {isCashbookHistoryCloseDayPopOpen && !isDisabledCloseDay &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCashbookHistoryCloseDayPopup}
        >
          <CashbookHistoryCloseDayPop onClose={closeCashbookHistoryCloseDayPopup}/>
        </PortalPopup>
      }
      {isCashbookExportPDFOpen &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCashbookExportPDFPopup}
        >
          <CashbookExportPDFPopup onClose={closeCashbookExportPDFPopup}/>
        </PortalPopup>
      }
      {isCashbookReprintConfirmPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCashbookReprintConfirmPopup}
        >
          <CashbookReprintConfirm onClose={closeCashbookReprintConfirmPopup}/>
        </PortalPopup>
      )}
    </>

  );
};

export default CashbookHistoryDetailsP;
