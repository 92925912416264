import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { getLL2 } from '@/react/core/I18nBackend.tsx'
import { VPrinter } from '@/react/Printer/VPrinter.ts'
import type Printer from '@/shared/printer/pure-image-printer-parallel'
import type { PrinterAddress, ScriptedRaster } from '@/shared/printer/types'
import type { CurrentTransactionResponseData, SignedTransactionData } from '@/srm/lib/types'

import { DOCUMENT_NOTES } from './lib/constants'
import { OperationModes, PaymentMethods, PrintModes, PrintOptions, TransactionTypes } from './lib/enum'
import { formatDate, reformatDate, reformatDateInLocalTz } from './lib/timezone'
import { reformatCurrency } from './lib/utils'
import {
  countPrintedRefs,
  isAbandonedTransaction,
  isCancelledBill,
  isCancelledEstimate,
  isChargeToAccount,
  isCorrectedBill,
  isCorrectedCreditNote,
  isCreditNote,
  isEstimate,
  isFailureToPay,
  isInvalidCertificate,
  isOccasionalThirdParty,
  isOriginalBill,
  isPaymentReceived,
  isQuote,
  isRevisedBill,
  isRevisedEstimate,
  isRevisedQuote,
} from './lib/utils.transaction'
import type { TOrder } from "@/pos/OrderType.ts";

type Options = {
  deviceId: string
  qrcodeData: string
  transRes?: CurrentTransactionResponseData
  printer: PrinterAddress
  order?: TOrder
}
/**
 * Generate raster bitmap for bill
 */
export async function generateRasterInvoice(t: Readonly<SignedTransactionData>, o: Readonly<Options>): Promise<ScriptedRaster> {
  const p = new VPrinter(o.printer)

  await printCompanyInfo(p, t)
  await printTransactionInfo(p, t)
  await printItemsInfo(p, t)
  await printTaxNumbers(p, t)
  await printPaymentInfo(p, t)
  await printExtraOrderInfo(p, o)
  await printCustomerInfo(p, o)
  await printSeparator(p)

  await printTotalInfo(p, t)
  await printExtraNotes(p, t)
  await printQrCode(p, o.qrcodeData)
  await printQrCodeExtraNotes(p, t, o)
  await printSeparator(p)
  await p.newLine(8)
  const result = await p.getRaster()
  if (!result || !result.data.length) throw new Error('Failed to print: page is empty')
  return result
}

async function printCompanyInfo(p: VPrinter, t: Readonly<SignedTransactionData>) {
  const { telephone: companyTel, address, address2, city, province, zipCode } = posSetting0()?.companyInfo || {}

  await p.newLine(8)
  await p.alignCenter()
  await p.setFontSize(24)
  await p.bold(true)
  await p.println(t.nomMandt)
  await p.bold(false)
  await p.setFontSize(20)
  if (companyTel) await p.println(companyTel)
  const addr = [address, address2, city, province, zipCode].filter((a): a is string => !!a).join(', ')
  if (addr) await p.println(addr)
}

async function printTransactionInfo(p: VPrinter, t: Readonly<SignedTransactionData>) {
  await p.newLine(8)
  await p.println(reformatDate(t.datTrans))
  await p.bold(true)
  await p.println(`TRANSACTION #${t.noTrans}`)
  await p.bold(false)

  if (t.sectActi.abrvt === 'RBC' && t.sectActi.noTabl) await p.println(`TABLE #${t.sectActi.noTabl.replaceAll('=', '')} - ${+t.sectActi.nbClint} CLIENT${+t.sectActi.nbClint > 1 ? 'S' : ''}`)
}

async function printItemsInfo(p: VPrinter, t: Readonly<SignedTransactionData>) {
  await p.newLine(8)
  const table = [
    ...t.items.flatMap(item => [
      // Main item row
      [
        (+item.qte).toString(), // quantity
        item.descr,
        item.unitr ? reformatCurrency(item.unitr) : '',
        reformatCurrency(item.prix),
        item.tax,
      ],
      // Modifier rows
      ...(item.preci?.map(modifier => [
        modifier.unitr && modifier.qte ? (+modifier.qte).toString() : '',
        modifier.descr,
        modifier.unitr ? reformatCurrency(modifier.unitr) : '',
        modifier.prix ? reformatCurrency(modifier.prix) : '',
        modifier.tax ?? '',
        '1', // indicate that this is modifier item
      ]) ?? []),
    ]),
    ['', '', '', '', ''],
    ['', 'SOUS-TOTAL', '', reformatCurrency(t.mont.avantTax), ''],
  ]
  const separator = (w = 0.02): Parameters<Printer['tableCustom']>[0][0] => ({
    text: '',
    width: w,
    bold: false,
    align: 'LEFT',
  })

  for (const row of table) {
    const isModifier = !!row[5]
    const fontSize = isModifier ? 16 : 20
    await p.tableCustom([
      { text: row[0], width: 0.08, bold: false, align: 'RIGHT', fontSize },
      separator(),
      { text: row[1], width: 0.4, bold: false, align: 'LEFT', fontSize },
      { text: row[2], width: 0.2, bold: false, align: 'RIGHT', fontSize },
      { text: row[3], width: 0.2, bold: false, align: 'RIGHT', fontSize },
      separator(),
      { text: row[4], width: 0.08, bold: false, align: 'LEFT', fontSize },
    ])
  }
}
async function printTaxNumbers(p: VPrinter, t: Readonly<SignedTransactionData>) {
  await p.newLine(8)
  const table = [
    ['TPS', t.noTax.noTPS.split(/(?<=^\d{9})|(?=\d{4}$)/).join(' ')],
    ['TVQ', t.noTax.noTVQ.split(/(?<=^\d{10})|(?=\d{4}$)/).join(' ')],
  ]

  for (const row of table)
    await p.tableCustom([
      { text: '', width: 0.23, bold: false, align: 'LEFT' },
      { text: row[0], width: 0.1, bold: false, align: 'RIGHT' },
      { text: '', width: 0.04, bold: false, align: 'LEFT' },
      { text: row[1], width: 0.4, bold: false, align: 'RIGHT' },
      { text: '', width: 0.23, bold: false, align: 'LEFT' },
    ])
}
async function printPaymentInfo(p: VPrinter, t: Readonly<SignedTransactionData>) {
  await p.newLine(8)
  await p.alignCenter()
  if (t.typTrans === TransactionTypes.closingReceipt) {
    if ([PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr)) {
      if (t.modPai === PaymentMethods.cash) await p.println(DOCUMENT_NOTES.cash)
      if (t.modPai === PaymentMethods.creditCard) await p.println(DOCUMENT_NOTES.creditCard)
      if (t.modPai === PaymentMethods.debitCard) await p.println(DOCUMENT_NOTES.debitCard)
      if (t.modPai === PaymentMethods.noPayment) await p.println(DOCUMENT_NOTES.noPayment)

      if ([PaymentMethods.cash, PaymentMethods.creditCard, PaymentMethods.debitCard, PaymentMethods.noPayment].includes(t.modPai)) await p.newLine(8)
    } else await p.println(DOCUMENT_NOTES.noPayment)
  }
  if (await isCreditNote(t)) {
    await p.println(`Crédité à: ${t.clint?.nomClint ?? '_________'}`)
    await p.println(`Date de la remise : ${formatDate(new Date())}`)
    await p.newLine(8)
  }

  if (t.nomUtil) await p.println(`Vous avez été servi par: ${t.nomUtil}`)
}
async function printExtraOrderInfo(p: VPrinter, o: Readonly<Options>) {
  if (!o.order?.ticketNumber) return

  const LL = getLL2()
  await p.bold(true)
  await p.alignCenter()
  await p.newLine(8)
  await p.println(`${LL().printing.ticketNumber()}: ${o.order?.ticketNumber}`)
  await p.bold(false)
}
async function printCustomerInfo(p: VPrinter, o: Readonly<Options>) {
  const { phone, name } =  o.order?.customerRaw ?? {}
  if (!phone && !name) return

  const LL = getLL2()
  await p.newLine(8)
  if (phone) await p.println(`${LL().printing.customerPhone()}: ${phone}`)
  if (name )await p.println(`${LL().customer.name()}: ${name}`)
}

async function printSeparator(p: VPrinter) {
  await p.alignLeft()
  await p.newLine(8)
  await p.println(''.padStart(34, '='))
}
async function printTotalInfo(p: VPrinter, t: Readonly<SignedTransactionData>) {
  await p.newLine(8)
  const table = [
    ['', 'TPS', reformatCurrency(+t.mont.TPS), ''],
    ['', 'TVQ', reformatCurrency(+t.mont.TVQ), ''],
    ['', 'TOTAL', reformatCurrency(+t.mont.apresTax), ''],
    ...(t.mont.ajus && t.mont.mtdu && +t.mont.ajus > 0
      ? [
          ['', 'Ajustement', reformatCurrency(+t.mont.ajus), ''],
          ['', 'MONTANT DÛ', reformatCurrency(+t.mont.mtdu), ''],
        ]
      : []),
    ...(t.mont.pourb && +t.mont.pourb > 0 ? [['', 'POURBOIRE', reformatCurrency(+t.mont.pourb), '']] : []),
  ]
  const separator = (w = 0.02) => ({ text: '', width: w, bold: false, align: 'LEFT' as 'LEFT' | 'RIGHT' | 'CENTER' })
  const isBold = (name: string) => ['TOTAL', 'MONTANT DÛ'].includes(name)
  for (const row of table) {
    await p.tableCustom([
      { text: row[0], width: 0.1, bold: isBold(row[1]), align: 'RIGHT' },
      { text: row[1], width: 0.5, bold: isBold(row[1]), align: 'LEFT' },
      { text: row[2], width: 0.3, bold: isBold(row[1]), align: 'RIGHT' },
      separator(),
      { text: row[3], width: 0.08, bold: isBold(row[1]), align: 'LEFT' },
    ])
  }
}

async function printExtraNotes(p: VPrinter, t: Readonly<SignedTransactionData>) {
  await p.newLine(8)
  await p.alignCenter()
  if (t.modTrans === OperationModes.training) await p.println(DOCUMENT_NOTES.trainingMode)
  if ([PrintModes.duplicate, PrintModes.failureToPay, PrintModes.cancellation].includes(t.modImpr) || t.typTrans === TransactionTypes.occasionalThirdParty) await p.println(DOCUMENT_NOTES.merchantCopy)
  if (t.modImpr === PrintModes.reproduction) await p.println(DOCUMENT_NOTES.reproduction)
  if (t.modTrans === OperationModes.training || [PrintModes.duplicate, PrintModes.failureToPay, PrintModes.cancellation].includes(t.modImpr) || t.typTrans === TransactionTypes.occasionalThirdParty) {
    await p.bold(true)
    await p.println(DOCUMENT_NOTES.doNotGiveToClient)
    await p.bold(false)
  }
  if (await isOccasionalThirdParty(t)) await p.println(DOCUMENT_NOTES.occasionalThirdParty)
  if (await isQuote(t)) await p.println(DOCUMENT_NOTES.quote)
  if (await isRevisedQuote(t)) await p.println(DOCUMENT_NOTES.revisedQuote)
  if (await isEstimate(t)) await p.println(DOCUMENT_NOTES.estimate)
  if (await isRevisedEstimate(t)) await p.println(DOCUMENT_NOTES.revisedEstimate)
  if (await isCancelledEstimate(t)) await p.println(DOCUMENT_NOTES.cancelledEstimate)
  if (await isOriginalBill(t)) await p.println(DOCUMENT_NOTES.originalBill)
  if (await isRevisedBill(t)) await p.println(DOCUMENT_NOTES.revisedBill)
  if (await isCancelledBill(t)) await p.println(DOCUMENT_NOTES.cancelledBill)
  if (await isPaymentReceived(t)) await p.println(DOCUMENT_NOTES.paymentReceived)
  if (await isChargeToAccount(t)) await p.println(DOCUMENT_NOTES.chargeToAccount)
  if (await isFailureToPay(t)) await p.println(DOCUMENT_NOTES.failureToPay)
  if (await isCreditNote(t)) await p.println(DOCUMENT_NOTES.creditNote)
  if (await isAbandonedTransaction(t)) await p.println(DOCUMENT_NOTES.abadonedTransaction)
  if (await isCorrectedCreditNote(t)) await p.println(DOCUMENT_NOTES.correctedCreditNote)
  if (await isCorrectedBill(t)) await p.println(DOCUMENT_NOTES.correctedBill)

  if (await isRevisedQuote(t)) {
    const count = await countPrintedRefs(t, a => a.typTrans === TransactionTypes.quote)
    if (count > 0) await p.println(DOCUMENT_NOTES.replaceNQuote(count))
  }

  if (await isRevisedEstimate(t)) {
    const count = await countPrintedRefs(t, a => a.typTrans === TransactionTypes.estimate)
    if (count > 0) await p.println(DOCUMENT_NOTES.replaceNEstimate(count))
  }

  if (await isRevisedBill(t)) {
    const count = await countPrintedRefs(t, a => a.typTrans === TransactionTypes.temporaryBill)
    if (count > 0) await p.println(DOCUMENT_NOTES.replaceNBill(count))
  }
}
async function printQrCode(p: VPrinter, qrcodeData: string) {
  // const qrcode = await QRcode.toDataURL(qrcodeData, { errorCorrectionLevel: 'H' })
  await p.printQrCode(qrcodeData, 0.8)
  // await p.printImage(qrcode.slice(22), 'base64', 0.8)
}
async function printQrCodeExtraNotes(p: VPrinter, t: Readonly<SignedTransactionData>, o: Options) {
  await p.newLine(8)
  await p.alignCenter()

  if (t.formImpr === PrintOptions.combined) await p.println(DOCUMENT_NOTES.electronicCopy)
  if (t.formImpr === PrintOptions.combined || t.formImpr === PrintOptions.electronic) await p.println(DOCUMENT_NOTES.viewTheTransactionOnline)

  if (isInvalidCertificate(o.transRes)) {
    await p.println(DOCUMENT_NOTES.invalidCertificate)
    await p.bold(true)
    await p.println(DOCUMENT_NOTES.doNotGiveToClient)
    await p.bold(false)
  }
  if (!o.transRes?.psiNoTrans) p.println(DOCUMENT_NOTES.communicationProblem)
  else {
    await p.println(reformatDateInLocalTz(o.transRes.psiDatTrans))
    await p.println(o.transRes.psiNoTrans)
  }
  await p.println(o.deviceId)
}
