import { FunctionComponent, useCallback, useEffect, useState } from "react";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import PortalPopup from "./PortalPopup";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  addCustomerAddress,
  createNewCustomer,
  customer0,
  CustomerInfoScreenMode,
  removeCustomerAddress,
  setCustomer0,
  setCustomerInfoScreenMode
} from "@/react/CustomerInfoView/CustomerInfoView";
import { clone } from "json-fn";
import _ from "lodash";
import axios from "axios";
import type { GoogleMapAddress } from "@new-delivery/NewDeliveryAddEditCustomer.tsx";
import { getApiUrl } from "@/shared/utils.ts";
import Autocomplete from "@/react/core/Autocomplete.tsx";
import {LL0} from "@/react/core/I18nService.tsx";

import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

export type CustomerInfomationNewEditCType = {
  onClose?: () => void;
};

const CustomerInfomationNewEditC: FunctionComponent<
  CustomerInfomationNewEditCType
> = ({ onClose }) => {
  const [autoCompleteAddressOptions, setAutoCompleteAddressOptions] = useState<GoogleMapAddress[]>([]);
  const [addressChangeIndex, setAddressChangeIndex] = useState<number>(0);

  const fetchAddress = useCallback(_.debounce(async (searchPlace: string) => {
    //TODO: Integrate w google api key later?
    const { data: foundAddresses } = await axios.get<
      GoogleMapAddress[]>(getApiUrl() + "/api/map/google-places", {
      params: {
        searchPlace
      }
    })
    setAutoCompleteAddressOptions(foundAddresses || [])
  }, 500), []);

  useEffect(() => {
    const autoCompleteStreet = customer0()?.addresses?.[addressChangeIndex]?.street
    if (!autoCompleteStreet || (autoCompleteStreet?.length || 0) <= 3) return;
    fetchAddress(autoCompleteStreet)
  }, [JSON.stringify(customer0()?.addresses?.map(address => address?.street))]);

  const [currentRemoveIndex, setCurrentRemoveIndex] = useState<number>(0)

  const handleAddCustomer = () => {
    setCustomerInfoScreenMode(CustomerInfoScreenMode.CUSTOMER_LIST);
    if (!!customer0()?.doc) return;
    const _customer = customer0();
    if (!_customer) return;
    createNewCustomer(clone(_customer))
    setCustomer0()
  }

  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  return (
    <>
      <div className="!justify-between w-full rounded flex flex-col items-start justify-start min-w-[520px] h-full max-w-full max-h-full overflow-auto text-center text-mini text-white-solid-white-100-ffffff font-mulish">
        <div className="w-full">
          <div className="self-stretch rounded-t rounded-b-none bg-white-solid-white-400-f2f2f2 flex flex-row items-center justify-between pt-1 px-3 pb-[3px] z-[2] sm:h-[42px] sm:pl-4 sm:pr-4 sm:box-border">
            <div className="rounded-11xl [background:linear-gradient(180deg,_#82e1ff,_#2aacf5)] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3.5 box-border height_414:h-7"
                 ref={makeRipple}
                 onClick={addCustomerAddress}
            >
              <div className="relative leading-[16px] font-semibold">
                {LL0().delivery.addAddress()}
              </div>
            </div>
            <div className="flex flex-row items-center justify-end gap-[16px]">
              <div className="rounded-11xl [background:linear-gradient(180deg,_#82e1ff,_#2aacf5)] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-6 box-border height_414:h-7"
                   ref={makeRipple}
                   onClick={handleAddCustomer}
              >
                <div className="relative leading-[16px] font-semibold">{LL0().ui.save()}</div>
              </div>
              <img
                className="w-[22px] relative h-[22px] object-cover cursor-pointer"
                alt=""
                src="/icongeneralclose-popup-icon--grey@2x.png"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="!h-full !max-h-[calc(100vh-233px)] no-scrollbar self-stretch bg-white-solid-white-100-ffffff shadow-[0px_0px_8px_rgba(0,_0,_0,_0.12)_inset] box-border h-60 overflow-y-auto shrink-0 flex flex-col items-start justify-start py-1.5 px-3.5 gap-[6px] z-[1] text-left text-base text-blue-solid-blue-450-1271ff border-[1px] border-solid border-gray-solid-gray-200-e8e8e8 height_414:h-[148px]">
            <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[3]">
              <Input
                className="flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26-200"
                label={LL0().customerInfo.telephoneOrEmail()}
                value={customer0()?.phoneNumber || ''}
                onChange={value => _.assign(customer0(), {phoneNumber: value})}
              />
              <Input
                className="flex-1 font-mulish text-sm text-gray-100"
                label={LL0().customerInfo.customersName()}
                value={customer0()?.name || ''}
                onChange={value => _.assign(customer0(), {name: value})}
              />
            </div>
            {companyInfo0()?.country && companyInfo0()?.country === 'de' && (
              <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[3]">
                <Input
                  className="flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26-200"
                  label={LL0().settings.companyName()}
                  value={customer0()?.company || ''}
                  onChange={value => _.assign(customer0(), {company: value})}
                />
                <Input
                  className="flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26-200"
                  label={LL0().settings.taxNo()}
                  value={customer0()?.taxNo || ''}
                  onChange={value => _.assign(customer0(), {taxNo: value})}
                />
              </div>
            )}
            <div className="self-stretch flex flex-row items-start justify-center z-[2] height_414:hidden">
              <Input
                className="flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26-200"
                label={LL0().customerInfo.note()}
                value={customer0()?.note || ''}
                onChange={value => _.assign(customer0(), {note: value})}
              />
            </div>
            { customer0()?.addresses?.map((address, index) => (
              <div
                className="!z-auto self-stretch rounded bg-white-solid-white-100-ffffff shadow-[1px_1px_8px_rgba(0,_0,_0,_0.12)_inset] flex flex-col items-end justify-start py-1.5 px-2.5 gap-[6px] z-[1] border-r-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
                key={index}
              >
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                  <b className="h-5 flex-1 relative inline-block Tablet_768:text-mini Tablet_600:text-sm">
                    {LL0().onlineOrder.address()} {index + 1}
                  </b>
                  <div
                    className="rounded-md bg-red-opacity-red-10-ff4836 box-border h-[29px] overflow-hidden flex flex-row items-center justify-center py-0 px-2.5 gap-[3px] cursor-pointer text-center text-sm text-red-solid-red-300-ff4836 border-[1px] border-solid border-white-solid-white-100-ffffff"
                    onClick={() => {
                      openDeleteConfirmationPopup();
                      setCurrentRemoveIndex(index);
                    }}
                  >
                    <img
                      className="w-[17px] relative h-[17px] object-cover"
                      alt=""
                      src="/iconbin-icon--red1@2x.png"
                    />
                    <b className="relative">{LL0().ui.delete()}</b>
                  </div>
                </div>
                <Autocomplete
                  className="w-full z-[5]"
                  // not let mui do filter
                  filterOptions={(options) => {
                    return options
                  }}
                  value={address?.street || ''}
                  onChange={(_e, newValue) => {
                    const foundAddress = autoCompleteAddressOptions.find(address => address.name === newValue);
                    _.merge(address,
                      {
                        street: foundAddress?.name
                        , placeId: foundAddress?.placeId
                      })
                  }}
                  options={autoCompleteAddressOptions.map(address => address.name)}
                  inputProps={{
                    label: `${LL0().customerInfo.streetAddress()}`,
                    className: "w-full font-mulish text-sm text-gray-100",
                    onChange: value => {
                      setAddressChangeIndex(index);
                      _.merge(address, {street: value})
                    },
                    value: address?.street || ''
                  }}
                  dontUseNativeInput
                />
                <div className="self-stretch flex flex-row items-start justify-end gap-[10px]">
                  <Input
                    className="flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26-200"
                    label={LL0().customerInfo.houseNumber()}
                    value={address?.home || ''}
                    onChange={value => _.merge(address, {home: value})}
                  />
                  <Input
                    className="flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26-200"
                    label={LL0().settings.zipCode()}
                    value={address?.zipcode || ''}
                    onChange={value => _.merge(address, {zipcode: value})}
                  />
                </div>
              </div>
            )) }
          </div>
        </div>
        <TableManualKeyboard
          value=""
          inputController={inputController0}
          onEnter={handleAddCustomer}
        />
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onComplete={() => {
            removeCustomerAddress(currentRemoveIndex);
            setCurrentRemoveIndex(0);
            closeDeleteConfirmationPopup();
          }}/>
        </PortalPopup>
      )}
    </>
  );
};

export default CustomerInfomationNewEditC;
