import {FunctionComponent, memo} from "react";
import CompanyInformationLeftForm from "./CompanyInformationLeftForm";
import CompanyInformationRightForm from "./CompanyInformationRightForm";
import {router} from "@/pos/PosRouter.ts";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {LL0} from "@/react/core/I18nService.tsx";

import { mainScreen } from "@/data/PosSettingsSignal.ts";

const CompanyInformationPlugin: FunctionComponent = () => {

  return (
    <div className="company-information-screen w-full h-full bg-white-solid-white-100-ffffff max-w-full overflow-hidden flex flex-row items-start justify-start text-left text-mini text-gray-solid-gray-850-767676 font-mulish">
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[50px] flex flex-row items-center justify-start py-[15px] pr-0 pl-6 box-border Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
          <b className="relative">{LL0().dashboard.companyInfo().toUpperCase()}:</b>
        </div>
        <CompanyInformationLeftForm />
      </div>
      <div className="self-stretch w-[0.5px] relative box-border opacity-[0.8] border-r-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1" />
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[50px] flex flex-row items-center justify-between py-0 pr-2 pl-6 box-border Tablet_600:pl-4 Tablet_600:box-border">
          <b className="relative">{LL0().settings.deviceManager.devices().toUpperCase()}:</b>
          <div className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-center text-sm text-white-solid-white-100-ffffff"
          onClick={()=>router.screen=mainScreen()}
               ref={makeRipple}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="w-[45px] relative leading-[17px] hidden">Button</b>
          </div>
        </div>
        <CompanyInformationRightForm />
      </div>
    </div>
  );
};

export default memo(CompanyInformationPlugin);
