import {type FunctionComponent } from "react";
import Input from "@/react/core/Input.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {handleShowKeyboardSignIn, setInputControllers} from "@/react/SignInView/SignInView.tsx";

const PairingSetup: FunctionComponent = () => {
  return (
    <div className="self-stretch rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-start py-7 px-[27px] gap-[30px] text-left text-mini text-white-solid-white-100-ffffff font-mulish">
      <div className="self-stretch flex flex-col items-center justify-start gap-[20px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px]" onClick={handleShowKeyboardSignIn}>
          <div className="relative font-medium">Setup device’s name:</div>
          <Input
            className="h-10 bg-[transparent] self-stretch font-mulish italic text-smi text-gray"
            refInputController={i => setInputControllers(() => [i])}
            textClass='!text-white'
            caretClass='!bg-white'
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px]" onClick={handleShowKeyboardSignIn}>
          <div className="relative">
            <span className="font-medium">Setup admin password</span>
            <i>(only numbers allowed)</i>
            <span className="font-medium">:</span>
          </div>
          <Input
            className="h-10 bg-[transparent] self-stretch font-mulish italic text-smi text-gray"
            refInputController={i => setInputControllers(() => [i])}
            textClass='!text-white'
            caretClass='!bg-white'
          />
        </div>
      </div>
      <div className="cursor-pointer rounded-41xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] w-[162px] h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[11px] px-2.5 box-border text-center text-lg"
        ref={makeRipple}
      >
        <div className="relative font-semibold">Save</div>
      </div>
    </div>
  );
};

export default PairingSetup;
