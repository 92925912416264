import dayjs from 'dayjs'
import uuid from 'time-uuid'

import { now } from '@/pos/logic/time-provider'
import { computed, effectOn, signal, useAsyncEffect } from '@/react/core/reactive'
import MultiAwaitLock from '@/shared/MultiAwaitLock'

import { convertDocuments, type DocDeepSignal } from './data-utils'
import { DATA_INITED_2 } from './data-const'
import { dataLock } from './DataUtils'
import { License } from './License'

export const [licenses0, setLicenses0] = signal<Array<DocDeepSignal<License>>>([])
export const [licenseV, setLicenseV] = signal(0)

export const licenseConfigLock = new MultiAwaitLock(true)
effectOn(
  [licenseV],
  async () => {
    await dataLock.acquireAsync()
    let _license = await License.find().exec()
    if (_license.length === 0 && !localStorage.getItem(DATA_INITED_2)) {
      await License.insert({
        _id: uuid(),
        licenseFrom: dayjs(now()).unix(),
        licenseTo: dayjs().add(1, 'month').unix(),
        ...((!import.meta.env.VITE_NEED_LICENSE || import.meta.env.VITE_NEED_LICENSE === 'false') && { fullTime: true }),
      })
      _license = await License.find().exec()
    }
    const licenses = convertDocuments<License>(_license, true)
    setLicenses0(licenses)
    licenseConfigLock.release().then()
  }
)
export const makeLicenseAvailable = () => {
  useAsyncEffect(async () => {
    if (licenseV() === 0) {
      await dataLock.acquireAsync()
      License.$.subscribe(change => {
        setLicenseV(v => v + 1)
      })
      setLicenseV(1)
    }
  }, [licenseV()])
}
const license0 = computed(() => licenses0()[0])

// @ts-ignore
window.license0 = license0

export const isExpiredLicense = computed(() => {
  if (license0()?.fullTime) return false
  if (license0()) return license0().licenseTo! < dayjs(now()).unix()
  return false
})

export async function makeLicenseExpired() {
  if (licenses0().length <= 0) return
  licenses0()[0].isExpired = true
}

// @ts-ignore
window.makeLicenseExpired = makeLicenseExpired
