import { type CSSProperties, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import DiscountPopup from "./DiscountPopup";
import PortalPopup from "./PortalPopup";
import { LL0 } from "@/react/core/I18nService.tsx";
import { Order } from "@/data/Order";
import { paymentsMap } from '@/data/PaymentHub.ts';
import { paymentContext0, setMultiPayment0 } from '@/react/PaymentView/PaymentView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts'
import AddTipPopup from "./AddTipPopup";
import PaymentVerticalAddPayment from "./PaymentVerticalAddPayment";
import { useSignal } from "@/react/core/reactive.ts";
import VoucherPopup from "./VoucherPopup.tsx";
import { InvoiceTypes } from "@/pos/OrderType.ts";
import { printInvoice } from "@/react/Printer/print-invoice.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type PaymentVerticalInvoiceType = {
  tableNumber?: string;
  seat?: string;
  subtotalPrice?: string;
  hST?: string;
  total?: string;
  isCard?: boolean;
  isCash?: boolean;
  isAddPayment?: boolean;
  isSplit?: boolean;
  showTitleWrapper?: boolean;
  showCashPayment?: boolean;
  showCardPayment?: boolean;
  showOtherPayment?: boolean;

  /** Style props */
  paymentVerticalInvoiceAlignSelf?: CSSProperties["alignSelf"];
  paymentVerticalInvoiceWidth?: CSSProperties["width"];

  orderData: Order;
};

const PaymentVerticalInvoice: FunctionComponent<PaymentVerticalInvoiceType> = ({
  tableNumber = "5",
  seat,
  subtotalPrice = "196.62",
  hST = "25.56",
  total = "222.18",
  isCard = true,
  isCash = true,
  isAddPayment = true,
  isSplit = true,
  showTitleWrapper,
  showCashPayment,
  showCardPayment,
  showOtherPayment,
  paymentVerticalInvoiceAlignSelf,
  paymentVerticalInvoiceWidth,
  orderData
}) => {
  subtotalPrice = orderData.getNet?.().toString() || "";
  hST = orderData.getTax?.().toString() || "";
  total = orderData.vSum?.toString() || "";

  const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(false);
  const [isVoucherPopupOpen, setVoucherPopupOpen] = useState(false);
  const [isAddTipPopupOpen, setAddTipPopupOpen] = useState(false);
  const [isOpenMore, setIsOpenMore] = useSignal<boolean>(false)

  const [isPaymentVerticalAddPaymentOpen, setPaymentVerticalAddPaymentOpen] =
    useState(false);
  const paymentVerticalInvoiceStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: paymentVerticalInvoiceAlignSelf,
      width: paymentVerticalInvoiceWidth,
    };
  }, [paymentVerticalInvoiceAlignSelf, paymentVerticalInvoiceWidth]);

  const openDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(true);
  }, []);

  const closeDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(false);
  }, []);

  const openVoucherPopup = useCallback(() => {
    setVoucherPopupOpen(true);
  }, []);

  const closeVoucherPopup = useCallback(() => {
    setVoucherPopupOpen(false);
  }, []);

  const openAddTipPopup = useCallback(() => {
    setAddTipPopupOpen(true);
  }, []);

  const closeAddTipPopup = useCallback(() => {
    setAddTipPopupOpen(false);
  }, []);

  const openPaymentVerticalAddPayment = useCallback(() => {
    if (orderData.seat != null) {
      paymentContext0.setSeat(orderData.seat);
      setMultiPayment0(false);
    }
    setPaymentVerticalAddPaymentOpen(true);
  }, []);

  const closePaymentVerticalAddPayment = useCallback(() => {
    setPaymentVerticalAddPaymentOpen(false);
  }, []);

  const handleUnpaidBill = async() => {
    await printInvoice(orderData, InvoiceTypes.GUEST_CHECK)
  }
  const handlePaidBill = async() => {
    await printInvoice(orderData, InvoiceTypes.INVOICE)
  }
  const handleRedBill = async() => {
    await printInvoice(orderData, InvoiceTypes.RED_INVOICE)
  }

  const refClickOutside = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (refClickOutside.current && !refClickOutside.current.contains(event.target as Node)) {
        setIsOpenMore(false)
      }
    };
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [isOpenMore()]);

  return (
    <>
      <div
        className="flex flex-col items-center justify-start gap-[10px_0px] text-left text-sm text-black-solid-black-1000-000000 font-verdana self-stretch"
        style={paymentVerticalInvoiceStyle}
      >
        <div className="self-stretch flex flex-row items-end justify-between text-xl font-mulish-button-name-bold">
          <div className="flex flex-col items-start justify-start">
            {showTitleWrapper && (
              <div className="h-[42px] flex flex-row items-center justify-start gap-[0px_6px]">
                <b className="relative Mobile_480:text-lg mq320:text-base">
                  {LL0().orderHistory.tableNo()}:
                </b>
                <b className="relative Mobile_480:text-lg mq320:text-base">
                  {tableNumber}
                </b>
              </div>
            )}
            {/*{isSplit &&*/}
            {/*  <div*/}
            {/*    className="rounded [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] h-[42px] flex flex-row items-center justify-center py-0 px-[18px] box-border text-center text-base text-white-solid-white-100-ffffff Mobile_480:h-9 Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:h-8 mq320:pl-2.5 mq320:pr-2.5 mq320:box-border">*/}
            {/*    <b className="relative tracking-[-0.2px] Mobile_480:text-smi mq320:text-xs">*/}
            {/*      {seat}*/}
            {/*    </b>*/}
            {/*  </div>*/}
            {/*}*/}

            {isSplit && <div
              className="flex flex-col items-start justify-start gap-[8px] text-center text-mini text-black-solid-black-900-1e1e23"
              ref={makeRipple}
              onClick={() => {
                if (orderData.seat != null) {
                  paymentContext0.setSeat(orderData.seat);
                }
                setIsOpenMore(v => !v)
              }}
            >
              <div
                className="rounded-981xl bg-basic-color-white box-border h-8 hidden flex-row items-center justify-center py-0 px-3 gap-[4px] border-[1.5px] border-solid border-pink-linear-pink-linear-6000 sm:h-8 Mobile_480:h-9 Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:h-8 mq320:pl-2.5 mq320:pr-2.5 mq320:box-border">
                <img
                  className="w-6 relative h-6 object-cover"
                  alt=""
                  src="/iconpayment-vertical-more-menu-icon-ver1@2x.png"
                />

                  <div className="flex flex-row items-center justify-center gap-[4px]">
                    {/*<b*/}
                    {/*  className="h-[19px] relative tracking-[-0.2px] inline-block text-transparent !bg-clip-text [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] sm:text-sm Mobile_480:text-smi mq320:text-xs">*/}
                    {/*  Seat*/}
                    {/*</b>*/}
                    <b
                      className="h-[19px] relative tracking-[-0.2px] inline-block text-transparent !bg-clip-text [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] sm:text-sm Mobile_480:text-smi mq320:text-xs">
                      {seat}
                    </b>
                  </div>
              </div>
              <div className="flex flex-col items-start justify-start relative gap-[2px]">
                <div
                  className="rounded-981xl bg-pink-solid-pink-100-fbe4ec box-border h-8 flex flex-row items-center justify-center py-0 px-3 gap-[4px] z-[0] border-[1.5px] border-solid border-pink-linear-pink-linear-6000 sm:h-8 Mobile_480:h-9 Mobile_480:pl-3 Mobile_480:pr-3 Mobile_480:box-border mq320:h-8 mq320:pl-2.5 mq320:pr-2.5 mq320:box-border">
                  <img
                    className="w-6 relative h-6 object-cover"
                    alt=""
                    src="/iconpayment-vertical-more-menu-icon-ver1@2x.png"
                  />
                  <div className="flex flex-row items-center justify-center gap-[4px]">
                    {/*<b*/}
                    {/*  className="h-[19px] relative tracking-[-0.2px] inline-block text-transparent !bg-clip-text [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] sm:text-sm Mobile_480:text-smi mq320:text-xs">*/}
                    {/*  Seat*/}
                    {/*</b>*/}
                    <b
                      className="h-[19px] relative tracking-[-0.2px] inline-block text-transparent !bg-clip-text [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] sm:text-sm Mobile_480:text-smi mq320:text-xs">
                      {seat}
                    </b>
                  </div>
                </div>
                {isOpenMore() && <div
                  className="!m-[0] absolute top-[31.8px] left-0 right-0 flex flex-col items-start justify-start z-[1] text-left text-sm height_414:h-60"
                  ref={refClickOutside}
                >
                  <div
                    className="self-stretch flex flex-col items-start justify-center py-0 px-5 sm:pr-3 sm:box-border Tablet_768:pr-3 Tablet_768:box-border">
                    <img
                      className="w-5 relative h-[13px] object-cover"
                      alt=""
                      src="/icontooltip--1-arrow-up-icon@2x.png"
                    />
                  </div>
                  <div
                    className="w-[148px] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-blue-solid-blue-20-f3f9ff overflow-y-auto flex flex-col items-start justify-end p-2 box-border gap-[6px]">
                    <div
                      className="self-stretch h-[34px] rounded shrink-0 grid flex-row items-center justify-start py-0.5 px-0 box-border relative gap-[8px] grid-flow-col [column-gap:24px] cursor-pointer"
                      onClick={openDiscountPopup}
                      ref={makeRipple}
                    >
                      <div
                        className="hidden w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 z-[0]"/>
                      <img
                        className="w-[30px] relative h-[30px] object-cover z-[1]"
                        alt=""
                        src="/ordericon@2x.png"
                      />
                      <b className="flex-1 relative leading-[17px] z-[2]">
                        {LL0().order.discount()}
                      </b>
                      <b
                        className="!hidden flex-1 relative leading-[17px] [display:-webkit-inline-box] text-basic-color-white overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[3]">
                        {LL0().order.discount()}
                      </b>
                    </div>
                    <div
                      className="self-stretch h-[34px] rounded shrink-0 grid flex-row items-center justify-start py-0.5 px-0 box-border relative gap-[8px] grid-flow-col [column-gap:24px] cursor-pointer"
                      onClick={openVoucherPopup}
                      ref={makeRipple}
                    >
                      <div
                        className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 hidden z-[0]"/>
                      <img
                        className="w-[30px] relative h-[30px] object-cover z-[1]"
                        alt=""
                        src="/ordericon1@2x.png"
                      />
                      <b
                        className="flex-1 relative leading-[17px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[2]">
                        {LL0().order.voucher()}
                      </b>
                      <b className="w-[65px] relative leading-[17px] hidden text-basic-color-white z-[3]">
                        {LL0().order.voucher()}
                      </b>
                    </div>
                    <div
                      className="self-stretch h-[34px] rounded shrink-0 grid flex-row items-center justify-start py-0.5 px-0 box-border relative gap-[8px] grid-flow-col [column-gap:24px] cursor-pointer"
                      onClick={handleUnpaidBill}
                      ref={makeRipple}
                    >
                      <div
                        className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 hidden z-[0]"/>
                      <img
                        className="w-[30px] relative h-[30px] object-cover z-[1]"
                        alt=""
                        src="/ordericon2@2x.png"
                      />
                      <b
                        className="flex-1 relative leading-[17px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[2]">
                        {LL0().order.invoiceTypes.guestCheck()}
                      </b>
                      <b className="w-[65px] relative leading-[17px] hidden text-basic-color-white z-[3]">
                        {LL0().order.invoiceTypes.guestCheck()}
                      </b>
                    </div>
                    <div
                      className="self-stretch h-[34px] rounded shrink-0 grid flex-row items-center justify-start py-0.5 px-0 box-border relative gap-[8px] grid-flow-col [column-gap:24px] cursor-pointer"
                      onClick={handlePaidBill}
                      ref={makeRipple}
                    >
                      <div
                        className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 hidden z-[0]"/>
                      <img
                        className="w-[30px] relative h-[30px] object-cover z-[1]"
                        alt=""
                        src="/ordericon3@2x.png"
                      />
                      <b
                        className="flex-1 relative leading-[17px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[2]">
                        {LL0().order.invoiceTypes.bill()}
                      </b>
                      <b className="w-[65px] relative leading-[17px] hidden text-basic-color-white z-[3]">
                        {LL0().order.invoiceTypes.bill()}
                      </b>
                    </div>
                    <div
                      className="self-stretch h-[34px] rounded shrink-0 grid flex-row items-center justify-start py-0.5 px-0 box-border relative gap-[8px] grid-flow-col [column-gap:24px] cursor-pointer"
                      onClick={handleRedBill}
                      ref={makeRipple}
                    >
                      <div
                        className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3 hidden z-[0]"/>
                      <img
                        className="w-[30px] relative h-[30px] object-cover z-[1]"
                        alt=""
                        src="/ordericon4@2x.png"
                      />
                      <b
                        className="flex-1 relative leading-[17px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[2]">
                        {LL0().order.invoiceTypes.redBill()}
                      </b>
                      <b className="w-[65px] relative leading-[17px] hidden text-basic-color-white z-[3]">
                        {LL0().order.invoiceTypes.redBill()}
                      </b>
                    </div>
                  </div>
                </div>}
              </div>
            </div>}

          </div>
          <div
            className="w-[366.5px] flex flex-row items-center justify-end text-center text-mini text-basic-color-white">
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl [background:linear-gradient(175.65deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#10e095] h-[42px] flex flex-row items-center justify-center py-1 px-3 box-border gap-[4px] cursor-pointer Mobile_480:h-9 Mobile_480:gap-[4px] mq320:h-8 mq320:gap-[2px] mq320:pl-2.5 mq320:pr-2.5 mq320:box-border"
              onClick={openPaymentVerticalAddPayment}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:flex Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:flex mq320:w-6 mq320:h-6"
                alt=""
                src="/payment-vertical--complete-cash-icon2@2x.png"
              />
              <b className="relative leading-[17px] Mobile_480:text-smi mq320:text-xs">
                {LL0().order.payment()}
              </b>
            </div>
          </div>
        </div>
        <div
          className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-solid-black-600-424242"/>
        <div className="self-stretch flex flex-row items-center justify-start">
          <b
            className="w-16 relative flex items-center shrink-0 Mobile_480:text-smi Mobile_480:w-14 mq320:text-xs mq320:w-12">
            {LL0().printing.quantity()}
          </b>
          <div className="flex-1 flex flex-row items-start justify-start gap-[0px_8px]">
            <b className="flex-1 relative Mobile_480:text-smi mq320:text-xs">
              {LL0().payment.itemName()}
            </b>
            <b className="relative text-right Tablet_600:text-sm Mobile_480:text-smi mq320:text-xs">
              {LL0().payment.total()}
            </b>
          </div>
        </div>
        <div
          className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-solid-black-600-424242"/>
        {orderData.items.map((item, index) => (
          <div className="self-stretch flex flex-row items-center justify-start"
               key={index}
          >
            {item.quantity > 0 &&
              <>
                <div
                  className="relative flex items-center w-16 shrink-0 Mobile_480:text-smi Mobile_480:w-14 mq320:text-xs mq320:w-12">
                  {item.quantity}
                </div>
                <div className="flex-1 flex flex-row items-start justify-start gap-[8px]">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[2px_0px]">
                    <div className="self-stretch relative Mobile_480:text-smi mq320:text-xs">
                      {item.name} {item.isVoucher && ` - ${item.code}`}
                    </div>
                    {item.modifiers.length > 0 &&
                      <div className="self-stretch relative Mobile_480:text-smi mq320:text-xs">
                        ({item.modifiers.map((modifier, index) => (
                        <span key={index}>{modifier.name}{index < item.modifiers.length - 1 && ', '}</span>
                      ))})
                      </div>}
                  </div>
                  <div className="relative text-right Mobile_480:text-smi mq320:text-xs">
                    {item.vSum}
                  </div>
                </div>
              </>
            }
          </div>
        ))}
        <div
          className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-solid-black-600-424242"/>
        <div className="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
          <div className="flex-1 relative Mobile_480:text-smi mq320:text-xs">
            {LL0().printing.subTotal()}
          </div>
          <div className="relative text-right Mobile_480:text-smi mq320:text-xs">
            {subtotalPrice}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
          <div className="flex-1 relative Mobile_480:text-smi mq320:text-xs">
            {LL0().article.tax()}
          </div>
          <div className="relative text-right Mobile_480:text-smi mq320:text-xs">
            {hST}
          </div>
        </div>
        <div
          className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-solid-black-600-424242"/>
        <div className="self-stretch flex flex-row items-center justify-between text-blue-solid-blue-400-0091ff">
          <b className="flex-1 relative Mobile_480:text-smi mq320:text-xs">
            {LL0().common.total()}
          </b>
          <div className="relative text-black-solid-black-1000-000000"/>
          <b className="relative text-lg text-red-solid-red-550-cf2222 text-right Mobile_480:text-base mq320:text-mini">
            {total}
          </b>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between text-blue-solid-blue-400-0091ff">
          <b className="flex-1 relative Mobile_480:text-smi mq320:text-xs">
            {LL0().order.payment()}
          </b>
          <div className="relative text-black-solid-black-1000-000000"/>
          <div className="overflow-hidden flex flex-row items-center justify-start gap-[8px] text-right text-base">
            {orderData.payments.map((payment, index) =>
              <div className="flex flex-row items-center justify-start gap-[8px]" key={index}>
                <img
                  className="!object-contain relative w-10 h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-7 mq320:h-7"
                  alt=""
                  src={paymentsMap().get(payment.type)?.icon}
                />
                <b className="relative Mobile_480:text-mini mq320:text-sm">
                  {LL3().format.currency(payment.value)}
                </b>
              </div>
            )}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
          <b className="flex-1 relative Mobile_480:text-smi mq320:text-xs">
            {LL0().payment.tip()}
          </b>
          <b
            className="relative text-base text-green-solid-green-280-00deb6 text-right Mobile_480:text-smi mq320:text-xs">
            {orderData.tip}
          </b>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
          <b className="flex-1 relative Mobile_480:text-smi mq320:text-xs">
            {LL0().payment.cashBack()}
          </b>
          <b
            className="relative text-base text-pink-solid-pink-700-ff2fb8 text-right Mobile_480:text-smi mq320:text-xs">
            {orderData.cashback}
          </b>
        </div>
      </div>
      {isDiscountPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDiscountPopup}
        >
          <DiscountPopup onClose={closeDiscountPopup} order={orderData} />
        </PortalPopup>
      )}
      {isVoucherPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeVoucherPopup}
        >
          <VoucherPopup onClose={closeVoucherPopup}/>
        </PortalPopup>
      )}
      {isAddTipPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeAddTipPopup}
        >
          <AddTipPopup onClose={closeAddTipPopup}/>
        </PortalPopup>
      )}
      {isPaymentVerticalAddPaymentOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentVerticalAddPayment}
        >
          <PaymentVerticalAddPayment onClose={closePaymentVerticalAddPayment}/>
        </PortalPopup>
      )}
    </>
  );
};

export default PaymentVerticalInvoice;
