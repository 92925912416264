import { type FunctionComponent, useEffect, useState } from "react";
import { AccessPort, getAccessibility } from "@/react/DecentralizationPort.ts";
import { onEquallySplit } from "@/react/OrderView/OrderView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { EquallySplit } from "@/pos/OrderType.ts";
import { Switch, FormControlLabel } from "@mui/material";
import _ from "lodash";
import { signal } from "@/react/core/reactive.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";

export type SplitBillEquallyPopupType = {
  className?: string;
  onClose?: () => void;
};

const [isChangeQuantity, setIsChangeQuantity] = signal<boolean>(true);

const SplitBillEquallyPopup: FunctionComponent<SplitBillEquallyPopupType> = ({
  className = "",
  onClose,
}) => {
  const [seat, setSeat] = useState<number>(2);
  const [option, setOption] = useState<EquallySplit>(isChangeQuantity() ? EquallySplit.CHANGE_QUANTITY : EquallySplit.CHANGE_QUANTITY);
  const onClickPlus = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setSeat(seat + 1);
  }

  const onClickMinus = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (seat === 0) return
    setSeat(seat - 1);
  }

  const onSplit = async () => {
    await onEquallySplit(seat, option)
  }

  useEffect(() => {
    if (isChangeQuantity()) {
      setOption(EquallySplit.CHANGE_QUANTITY)
    } else {
      setOption(EquallySplit.CHANGE_PRICE)
    }
  }, [isChangeQuantity()])

  return (
    <div
      className={`w-full relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-5 max-h-[240px] h-full max-w-full text-left text-base font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-8 z-[0]">
        <b className="self-stretch relative text-blue-solid-blue-500-0051c1">{LL0().order.quickSplit()}</b>
        <div className="flex flex-row items-center justify-start gap-4 text-black-solid-black-1000-000000">
          <div className="relative">{LL0().order.divideEqually()}</div>
          <div className="flex flex-row items-center justify-start gap-1 text-center text-black-solid-black-880-1d1d26">
            <div className={'shrink-0'} ref={makeRipple} onClick={onClickMinus}>
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/iconminusblue@2x.png"
              />
            </div>

            <b className="w-[30px] relative inline-block shrink-0">{seat}</b>
            <div className={'shrink-0'} ref={makeRipple} onClick={onClickPlus}>
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/iconplus-blue@2x.png"
              />
            </div>

          </div>
          <div className="relative">{LL0().order.intoEqualPart()}</div>
        </div>
      </div>
      <FormControlLabel
        className="z-[1]"
        label={LL0().order.keepTheOriginalPrice()}
        control={<Switch color="primary" size="medium" checked={!!isChangeQuantity()}
                         onChange={(e, checked: boolean) => setIsChangeQuantity(checked)} />}
      />
      <div
        className="self-stretch flex flex-col items-end justify-start z-[1] text-center text-sm text-white-solid-white-100-ffffff">
        <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8" onClick={async () => {
          await onSplit();
          onClose?.()
        }}>
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
      <img
        className="w-6 absolute !m-[0] top-[18px] right-[24px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[3]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default SplitBillEquallyPopup;
