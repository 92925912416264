import debug from 'debug';
import { tap } from 'rxjs';

import { Call, MissedCall } from '@/data/Call';
import { dataLock } from '@/data/DataUtils.ts';
import { signal } from '@/react/core/reactive';
import type { RxDocument } from 'rxdb';

const log = debug('data:call')

export const [calls0, setCalls0] = signal<Array<RxDocument<Call, {}>>>([])
export const [missedCalls0, setMissedCalls0] = signal<Array<RxDocument<Call, {}>>>([])

let dataLoaded: boolean = false;

(async () => {
  log('⚡️ Loading call data...')

  if (dataLoaded) return
  await dataLock.acquireAsync()
  const subCall = Call.find()
    .$.pipe(
      tap(a => setCalls0(a)),
      tap(a => log('⚡️ Call data loaded', a))
    )
    .subscribe()

  const subMissedCall = MissedCall.find()
    .$.pipe(
      tap(a => setMissedCalls0(a)),
      tap(a => log('⚡️ Missed call data loaded', a))
    )
    .subscribe()
  dataLoaded = true

  return () => {
    subCall.unsubscribe();
    subMissedCall.unsubscribe();
  }
})()