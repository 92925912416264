import { isShowPrice, onPrintPay } from '@/react/OrderView/OrderView.tsx';
import { stop0 } from '@/shared/utils.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import { type FunctionComponent, useState, useCallback, useContext } from 'react';
import OrderScreenFastFoodRestaurant from "./OrderScreenFastFoodRestaurant";
import PortalPopup from "./PortalPopup";
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import { makeRipple } from "@/react/core/ripple-utils.ts";
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";
import { clsx } from "clsx";
import { isSmallSidebar } from "@/data/DeviceSettingHub.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type PayPrintButtonsType = {
  showPay?: boolean;
  showPrint?: boolean;
};

const PayPrintButtons: FunctionComponent<PayPrintButtonsType> = ({
  showPay,
  showPrint,
}) => {
  const { order0 } = useContext(ItemFactoryContext);
  showPay = order0?.().payable
  showPrint = !order0?.().payable

  const [
    isOrderScreenFastFoodRestaurantPopupOpen,
    setOrderScreenFastFoodRestaurantPopupOpen,
  ] = useState(false);

  const openOrderScreenFastFoodRestaurantPopup = useCallback(() => {
    setOrderScreenFastFoodRestaurantPopupOpen(true);
  }, []);

  const closeOrderScreenFastFoodRestaurantPopup = useCallback(() => {
    setOrderScreenFastFoodRestaurantPopupOpen(false);
  }, []);

  return (
    <>
      <ButtonWrapper onClick={onPrintPay}>
        <div className="rounded-81xl flex flex-row items-center justify-end gap-[0px_8px] text-center text-sm text-white font-mulish"
        >
          {showPay &&
            <div
              className="!flex rounded-81xl bg-blue-solid-blue-370-0094ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-9 overflow-hidden hidden flex-row items-center justify-center py-0.5 px-[5px] box-border gap-[0px_3px] cursor-pointer Tablet_768:pl-1.5 Tablet_768:pr-1.5 Tablet_768:box-border Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border Mobile_480:pl-1.5 Mobile_480:pr-1.5 Mobile_480:box-border height_414:pl-1.5 height_414:pr-1.5 height_414:box-border"
            >
              <img
                className={clsx("iconpay-icon--%123 relative object-cover Tablet_768:w-[26px] Tablet_768:h-[26px]",
                  isSmallSidebar() ? '!w-[24px] !h-[24px]' : 'h-[30px] w-[30px]'
                )}
                alt=""
                src="/iconpay-icon@2x.png"
              />
              <div className="flex flex-col items-center justify-center">
                {!isShowPrice() ?
                  <b className={clsx("!flex relative leading-[17px] hidden sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs",
                    isSmallSidebar() && '!text-[12px]'
                  )}>
                    {LL0().article.pay()}
                  </b>
                  :
                  <b className={clsx("relative sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs",
                    isSmallSidebar() && '!text-[12px]'
                  )}>
                    {LL3().format.currency(order0?.().vSum || 0)}</b>
                }
              </div>
            </div>
          }
          {showPrint &&
            <div className="rounded-81xl bg-orange-solid-orange-800-f25936 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-9 overflow-hidden flex flex-row items-center justify-center py-0.5 px-[5px] box-border gap-[3px] Tablet_768:pl-1.5 Tablet_768:pr-1.5 Tablet_768:box-border Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border Mobile_480:pl-1.5 Mobile_480:pr-1.5 Mobile_480:box-border height_414:pl-1.5 height_414:pr-1.5 height_414:box-border"
            >
              <img
                className={clsx("iconprinter-icon-31--%123 relative object-cover Tablet_768:w-[26px] Tablet_768:h-[26px]",
                  isSmallSidebar() ? '!w-[24px] !h-[24px]' : 'h-[30px] w-[30px]'
                )}
                alt=""
                src="/iconprinter-icon-31@2x.png"
              />
              <div className="flex flex-col items-center justify-center">
                {!isShowPrice() ?
                  <b className={clsx("!flex relative leading-[17px] hidden sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs",
                    isSmallSidebar() && '!text-[12px]'
                  )}>
                    {LL0().ui.print()}
                  </b>
                  :
                  <b className={clsx("relative sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs",
                    isSmallSidebar() && '!text-[12px]'
                  )}>
                    {LL3().format.currency(order0?.().vSum || 0)}
                  </b>
                }
              </div>
            </div>
          }
        </div>
      </ButtonWrapper>
      {isOrderScreenFastFoodRestaurantPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderScreenFastFoodRestaurantPopup}
        >
          <OrderScreenFastFoodRestaurant
            onClose={closeOrderScreenFastFoodRestaurantPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default PayPrintButtons;
