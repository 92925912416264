import { FunctionComponent } from "react";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {PosScreen, router} from "@/pos/PosRouter.ts";
import {LL0} from "@/react/core/I18nService.tsx";

import { mainScreen } from "@/data/PosSettingsSignal.ts";

const EmailReportHeader: FunctionComponent = () => {
  return (
    <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[50px] flex flex-row items-center justify-between py-0 pr-2 pl-6 box-border text-left text-mini text-gray-solid-gray-850-767676 font-mulish sm:h-[42px] Tablet_768:pl-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:box-border height_414:h-[42px]">
      <b className="relative uppercase sm:text-sm height_414:text-sm mq1440:text-lg">
        {LL0().settings.emailReport.emailReport()}
      </b>
      <div className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border min-w-[72px] sm:h-[30px] sm:min-w-[68px] Tablet_600:h-[26px] Tablet_600:pl-2.5 Tablet_600:box-border Tablet_600:min-w-[64px]"
       onClick={()=>router.screen = mainScreen()}
       ref={makeRipple}
      >
        <img
          className="relative w-[30px] h-[30px] object-cover"
          alt=""
          src="/iconback-icon@2x.png"
        />
      </div>
    </div>
  );
};

export default EmailReportHeader;
