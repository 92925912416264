import {convertDocuments, DocDeepSignal} from "@/data/data-utils";
import {computed, effectOn, signal, useAsyncEffect} from "@/react/core/reactive";
import {dataLock} from "@/data/DataUtils.ts";
import { HappyHour } from '@/data/HappyHour.ts';

export const [happyHours0, setHappyHours0] = signal<Array<DocDeepSignal<HappyHour>>>([]);
export const [happyHourV, setHappyHourV] = signal(0);

effectOn([happyHourV], async () => {
  await dataLock.acquireAsync();
  const _happyHours = await HappyHour.find().exec();
  const happyHours = convertDocuments<HappyHour>(_happyHours, true, [], {debounce: 200});
  setHappyHours0(happyHours);
}, { defer: true })

export const makeHappyHoursAvailable = () => {
  useAsyncEffect(async () => {
    if (happyHourV() === 0) {
      await dataLock.acquireAsync();
      HappyHour.$.subscribe((change) => {
        setHappyHourV(v => v + 1);
      })
      setHappyHourV(1);
    }
  }, [happyHourV()]);
};

