import type { FunctionComponent } from "react";
import UserTab from "./UserTab";

import { selectedUserId } from "@/react/TimeManagementView/TimeManagementView.logic.ts"
import { users0 } from "@/data/UserSignal.ts";


const StaffReportLeft: FunctionComponent = () => {

  return (
    <div className="self-stretch bg-white-solid-white-230-f3f4f4 shadow-[-1px_0px_6px_rgba(0,_0,_0,_0.25)] w-[239px] overflow-y-auto shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-[50px] box-border Tablet_768:w-[180px] Tablet_600:w-[180px] Mobile_480:w-40">
      {users0().map(user=>(
        <UserTab
        profileImageId={user.avatar}
        userName={user.name}
        isSelected={user._id === selectedUserId()}
        userId={user._id}
        key={user._id}
        />
      ))}
    </div>
  );
};

export default StaffReportLeft;
