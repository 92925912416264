import { Button } from "@/components/ui/button.tsx";
import React, { memo, useState } from "react";
import { router } from "@/pos/PosRouter.ts";
import RegisterStore from "@/react/Developer/RegisterStore.tsx";
import { createTheme, FormControlLabel, Switch, Tab, Tabs, ThemeProvider } from "@mui/material";
import { TabPanel } from "@/react/Developer/Developer.tsx";
import { onSetSrmEnabled } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import SettingsFieldset from "@/srm/components/SettingsFieldset.tsx";
import { companyInfo0, isQuebecSrmEnabled, mainScreen } from "@/data/PosSettingsSignal.ts";
import SecondDisplayConfig from "../Installation/SecondDisplayConfig"
import { processing } from "@/react/Developer/DataMenu.logic.ts";
import PortalPopup from "@eod/PortalPopup.tsx";
import UpdateNowPopup from "@/react/Developer/UpdateNowPopup.tsx";
import DeleteAllPopup from "@/react/Developer/DeleteAllPopup.tsx";
import TextField from "@/react/core/TextField.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { backupCollections } from "@/react/Developer/developer.logic.ts";


const muiTheme = createTheme();

const Installation = () => {
  const [currentTab, setCurrentTab] = useState('0')
  const [emailBackup, setEmailBackup] = useSignal('')
  const handleChangeTab = (_: unknown, newValue: string) => setCurrentTab(newValue);
  return (
    <ThemeProvider theme={muiTheme}>
      <div className={"w-full h-full no-scrollbar p-[10px]"}>
        <div className="relative border-b border-b-slate-200">
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
          >
            <Tab
              value="0"
              label="General"
            />
            <Tab
              value="1"
              label="Second Display"
            />
            {companyInfo0()?.country !== "de" &&
              <Tab
                value="2"
                label="SRM"
                data-testid='installation.srm'
              />
            }
            {companyInfo0()?.country !== "de" &&
              <Tab
                value="3"
                label="Backup"
                data-testid='Backup'
              />
            }
          </Tabs>
          <div className={"absolute top-2 right-2"}>
            <Button
              variant={"default"}
              size={"sm"}
              className={"h-[30px] float-right"}
              onClick={() => {
                router.screen = mainScreen();
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <TabPanel
          value={currentTab}
          index="0"
        >
          <RegisterStore type={'Installation'} />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index="1"
        >
          <SecondDisplayConfig />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index="2"
        >
          <div className='flex flex-col gap-4'>
            <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  label=''
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  control={<Switch color="primary" size="medium" checked={isQuebecSrmEnabled() ?? false}
                                   onChange={(e, checked: boolean) => onSetSrmEnabled(checked)} />}
                />
              </div>
              <div className="flex-1 relative">{LL0().settings.generalSetting.quebecSrmEnable()}</div>
            </div>
            <SettingsFieldset />
          </div>
        </TabPanel>
        <TabPanel
          value={currentTab}
          index="3"
        >
          <div className='flex flex-row gap-4'>
            <TextField
              value={emailBackup()}
              label={'Enter your email'}
              className={'self-stretch'}
              onChange={e => setEmailBackup(e.target.value)}
            />
            <button onClick={() => backupCollections(emailBackup())}>Backup data</button>
          </div>
        </TabPanel>
      </div>
      {processing() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
        >
          <button
            type="button"
            className="bg-gray-solid-gray-750-696969 text-white p-4 rounded-xl ..."
            disabled
          >
            Processing...
          </button>
        </PortalPopup>
      )}
      <UpdateNowPopup />
      <DeleteAllPopup />
    </ThemeProvider>
  )
}

export default memo(Installation)