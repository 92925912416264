import { convertDocuments, deleteMany, type DocDeepSignal } from "@/data/data-utils";
import { DATA_INITED_2 } from './data-const'
import { TseConfig } from "@/data/TseConfig";
import { computed, effectOn, signal } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";
import uuid from "time-uuid";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";

export const [tseConfigs0, setTseConfigs0] = signal<Array<DocDeepSignal<TseConfig>>>([]);
export const [tseConfigV, setTseConfigV] = signal(0);
export const tseConfigLock = new MultiAwaitLock(true);

export const TSE_PASSWORD = '5678'

effectOn([tseConfigV], async () => {
  await dataLock.acquireAsync();
  let _tseConfigs = await TseConfig.find().exec();
  if (_tseConfigs.length === 0 && !localStorage.getItem(DATA_INITED_2)) {
    const _id = uuid();
    await TseConfig.insert({
      _id: _id,
      version: 1,
      username: uuid(),
      password: _id,
      initialized: false,
      tsePassword: TSE_PASSWORD,
      // tseEnable: true,
      // passthroughEnable: true,
      // percent: 50,
      // // delayAssignQr: 1000
      // delayAssignQr: 30 * 60,
      // mockTSE: false
    });
    _tseConfigs = await TseConfig.find().exec();
  }
  const tseConfigs = convertDocuments<TseConfig>(_tseConfigs, true);
  setTseConfigs0(tseConfigs);
  tseConfigLock.release().then();
});

export const removeTseConfig = async () => {
  localStorage.removeItem(DATA_INITED_2);
  await deleteMany(TseConfig, {}, true);
}

// @ts-ignore
window.removeTseConfig = removeTseConfig;

(async () => {
  if (tseConfigV() === 0) {
    await dataLock.acquireAsync();
    TseConfig.$.subscribe((change) => {
      setTseConfigV(v => v + 1);
    })
  }
})()

export const tseConfig0 = computed<TseConfig>(() => tseConfigs0()[0]);

// @ts-ignore
window.tseConfig0 = tseConfig0;