import { STATES, VIEW__PRODUCT } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import { type FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { ITEM_VALIDITIES } from "@/data/Product";
import _ from "lodash";

const ValiditySelectFieldset: FunctionComponent = () => {
  return (
    <div className="self-stretch h-[72px] flex flex-col items-start justify-start gap-[8px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold height_414:gap-[2px]">
      <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
        {LL0().editOnlineMenu.validity()}
      </div>
      <div className="self-stretch rounded-10xs bg-light-blue shadow-[0px_2px_4px_rgba(0,_0,_0,_0.1)_inset,_0px_-1px_4px_rgba(0,_0,_0,_0.1)_inset] flex flex-row items-center justify-center py-1 px-2 gap-[4px] text-center text-lgi text-blue-solid-blue-300-0f56b3 sm:gap-[2px]">
        {Object.entries(ITEM_VALIDITIES).map(([key, val]) => (
        <div key={key} onClick={() => VIEW__PRODUCT.onChanged('validityMode', key)} className="flex-1 rounded-3xs shrink-0 flex flex-row items-center justify-center py-[3px] px-2 relative sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          {STATES.editingProduct()?.validityMode === key && (
            <div className="absolute my-0 mx-[!important] h-full w-[calc(100%_+_0.3px)] top-[0px] right-[-0.3px] bottom-[0px] left-[0px] rounded-3xs bg-basic-color-white shadow-[0px_1px_2px_rgba(15,_86,_179,_0.2),_0px_2px_4px_rgba(15,_86,_179,_0.2)] z-[0]" />
          )}
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            {_.get(LL0().editOnlineMenu, key)?.()}
          </div>
        </div>
        ))}
        {/*
        <div className="flex-1 rounded-3xs shrink-0 flex flex-row items-center justify-center py-[3px] px-2 relative gap-[6px] text-blue-solid-blue-900-081a51 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="absolute my-0 mx-[!important] h-full w-[calc(100%_+_0.3px)] top-[0px] right-[-0.3px] bottom-[0px] left-[0px] rounded-3xs bg-white-solid-white-100-ffffff shadow-[0px_1px_2px_rgba(15,_86,_179,_0.2),_0px_2px_4px_rgba(15,_86,_179,_0.2)] z-[0]" />
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium z-[1] md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Recurring
          </div>
        </div>
        <div className="flex-1 rounded-3xs shrink-0 flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Period
          </div>
        </div>
        */}
      </div>
    </div>
  );
};

export default ValiditySelectFieldset;
