import { FunctionComponent, useContext } from 'react';
import SeatSelectedButton from '@order-view/SeatSelectedButton';
import { LL0 } from '@/react/core/I18nService.tsx';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';

const SplitPopupSeatsArrow: FunctionComponent = () => {
  const {order0, isCurrentSeat, setCurrentSeat} = useContext(ItemFactoryContext);
  return (
    <div className="w-[123.46px] flex flex-col items-end justify-start text-left text-sm text-gray-400 font-mulish">
      <div className="flex flex-col items-end justify-start gap-[8px]">
        <div className="rounded bg-basic-color-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.25)] hidden flex-row items-center justify-center py-[15px] px-3">
          <b className="relative">{LL0().payment.seat().toUpperCase()} 1</b>
        </div>
        {order0?.()?.seatMap?.map((sOrder, index) => (
          <SeatSelectedButton key={index} selected={isCurrentSeat?.(index)} seatNumber={`${index + 1}`} onClick={() => setCurrentSeat?.(index)}/>
        ))}
      </div>
    </div>
  );
};

export default SplitPopupSeatsArrow;
