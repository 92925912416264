import SignUpDefault from "@sign-in/SignUpDefault.tsx";
import {signal, useSignal} from "@/react/core/reactive.ts";
import SignUpFull from "@sign-in/SignUpFull.tsx";
import SignUpConfirm from "@sign-in/SignUpConfirm.tsx";

export const [isShowSignInfo, setShowSignInfo] = signal<boolean>(false)
const SignUpWrapper = () => {
  return (
    <>
      {!isShowSignInfo() ?
        <SignUpFull />: <SignUpConfirm />
      }
      {/*<SignUpDefault />*/}
    </>
  )
}

export default SignUpWrapper