import type {FunctionComponent} from "react";
import Input from "@/react/core/Input.tsx";
import {handleShowKeyboardSignIn, setInputControllers} from "@/react/SignInView/SignInView.tsx";

const PairingDefault: FunctionComponent = () => {
  return (
    <div className="self-stretch rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-start py-7 px-[27px] gap-[20px] text-left text-mini text-white-solid-white-100-ffffff font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[10px]" onClick={handleShowKeyboardSignIn}>
        <div className="relative font-medium">Pairing Code:</div>
        <Input
          className="h-10 bg-[transparent] self-stretch font-mulish italic text-smi text-gray"
          refInputController={i => setInputControllers(() => [i])}
          textClass='!text-white'
          caretClass='!bg-white'
        />
      </div>
      <div className="self-stretch flex flex-row items-center justify-center gap-[20px] text-center text-lg">
        <div className="cursor-pointer flex-1 rounded-41xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-11 overflow-hidden flex flex-row items-center justify-center py-[11px] px-2.5 box-border">
          <div className="relative font-semibold">Connect</div>
        </div>
        <div className="cursor-pointer flex-1 rounded-41xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-11 overflow-hidden flex flex-row items-center justify-center py-[11px] px-2.5 box-border text-blue-solid-blue-200-2079ff">
          <div className="relative font-semibold">Skip to demo</div>
        </div>
      </div>
    </div>
  );
};

export default PairingDefault;
