import { Modifier, ModifierType } from "@/data/Modifier.ts";
import { convertDocuments } from "@/data/data-utils.ts";
import { Product } from "@/data/Product.ts";
import { toast } from "react-toastify";
import delay from "delay";

export const migrateVariants = async () => {
  console.log('start migrate variants')
  async function step1() {
    const check = await Modifier.find({
      selector: {
        useType: ModifierType.VARIANT,
        $or: [
          { product: { $exists: false } },
        ]
      }
    }).exec()
    console.log('checking1: ', check.length)
    if (!check) return
    const variants = convertDocuments(check, true)
    const variantIds = variants.map(variant => variant._id)

    const products = await Product.find({
      selector: { activePopupModifierGroup: { $in: variantIds } }
    })
      .exec()
    const products2 = convertDocuments(products, true)
    console.log('checking step2: ', products2.length)
    if (!products2 || products2.length < 1) return

    variants.forEach(variant => {
      products.forEach(product => {
        if (product?.activePopupModifierGroup?.includes(variant._id)) {
          variant.product = product._id;
        }
      });
    });
  }

  async function step2() {
    const _variants = await Modifier.find({
      selector: {
        useType: ModifierType.VARIANT,
      }
    }).exec()
    const variants = convertDocuments(_variants, true)
    console.log('checking step3: ', variants.length)
    for (const variant of variants) {
      //@ts-ignore
      const pp = variant.product
      if (pp && !variant?.products?.includes(pp)) {
        if (variant.products) variant.products.push(pp)
        else variant.products = [pp]
        delete variant.product
      }
    }
    toast.success('migrate variants success', { autoClose: 600 })
  }

  await step1()
  await delay(3000)
  await step2()
  localStorage.setItem('migrate-variants', 'true')
}