import type { FunctionComponent } from "react";
import ModifierEditingFormPopupDemo from "../../ModifierEditingView/ModifierEditingFormPopupDemo";
import ModifierEditingForm from "../../ModifierEditingView/ModifierEditingForm";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";

type PopupModifierEditType = {
  onClose?: () => void;
};

const PopupModifierEdit: FunctionComponent<PopupModifierEditType> = ({
  onClose,
}) => {
  return (
    <div className="rounded-2xl bg-white-opacity-white-30-ffffff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.1)] [backdrop-filter:blur(30px)] w-[1103px] overflow-hidden flex flex-col items-end justify-start py-8 pr-6 pl-4 box-border gap-[16px] max-w-full max-h-full text-center text-xl text-white-solid-white-100-ffffff font-mulish-button-name-bold md:pr-4 md:box-border">
      <div
        className="rounded-3xs [background:linear-gradient(135deg,_#ffd998,_#e2a130)] shadow-[0px_3px_20px_rgba(48,_48,_48,_0.2)] flex flex-row items-center justify-center py-2.5 px-5 box-border min-w-[88px] cursor-pointer md:py-2 md:px-4 md:box-border sm:py-1.5 sm:px-4 sm:box-border mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2 mq600:pr-2 mq600:box-border"
        onClick={onClose}
        ref={makeRipple}
      >
        <b className="flex-1 relative lg:text-lg md:text-mini sm:text-sm mq768:text-smi mq600:text-smi">
          {LL0().ui.ok()}
        </b>
      </div>
      {/*
      <ModifierEditingFormPopupDemo />
      */}
      <ModifierEditingForm />
    </div>
  );
};

export default PopupModifierEdit;
