import {type FunctionComponent, useState, useCallback } from 'react';
import PortalPopup from "@tax-payment/PortalPopup";
import TaxPaymentTipServiceTag from "@tax-payment/TaxPaymentTipServiceTag";
import {
  addDefaultTip,
  addServiceCharge, deleteDefaultTip,
  deleteServiceCharge
} from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";
import AddTipPopup from '@tax-payment/Popups/AddTipPopup.tsx';
import AddServiceChargePopup from '@tax-payment/Popups/AddServiceChargePopup.tsx';
import { FormControlLabel, Switch } from '@mui/material'
import { customParseFloat } from "@/react/core/Input.tsx";
import _ from "lodash";
import { generalSetting0, isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";

const TipServiceSection: FunctionComponent = () => {
  const [defaultTip, setDefaultTip] = useState<number | ''>('')
  const [serviceCharge, setServiceCharge] = useState<number | ''>("")

  const onClickAddTip = () => {
    if (defaultTip) {
      addDefaultTip(defaultTip);
      setDefaultTip("")
      return;
    }
    // openAddTipPopup();
  }

  const onClickAddServiceCharge = () => {
    if (serviceCharge) {
      addServiceCharge(serviceCharge);
      setServiceCharge("")
      return;
    }
    // openAddServiceChargePopup();
  }

  const [isAddTipPopupOpen, setAddTipPopupOpen] = useState(false);
  const [isAddServiceChargePopupOpen, setAddServiceChargePopupOpen] =
    useState(false);
  const [isTaxAndPaymentDeleteConfi2Open, setTaxAndPaymentDeleteConfi2Open] =
    useState(false);

  const openAddTipPopup = useCallback(() => {
    setAddTipPopupOpen(true);
  }, []);

  const closeAddTipPopup = useCallback(() => {
    setAddTipPopupOpen(false);
  }, []);

  const openAddServiceChargePopup = useCallback(() => {
    setAddServiceChargePopupOpen(true);
  }, []);

  const closeAddServiceChargePopup = useCallback(() => {
    setAddServiceChargePopupOpen(false);
  }, []);
  return (
    <>
      <div className="self-stretch flex flex-col items-center justify-start gap-[16px_0px] text-left text-sm text-gray-300 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-center gap-[8px_0px]">
          <b className="relative sm:text-smi Tablet_768:text-smi">
            {LL0().taxPayment.setDefaultTip()}:
          </b>
          <div className="self-stretch flex flex-row items-center justify-start gap-[0px_10px] text-center text-mini">
            <TextField
              className="bg-[transparent] flex-1"
              color="primary"
              size="small"
              variant="outlined"
              type="number"
              value={customParseFloat(defaultTip.toString()) || '0'}
              onChange={e => setDefaultTip(+e.target.value)}
            />
            <div
              className="rounded-sm bg-whitesmoke-100 box-border h-[37px] flex flex-row items-center justify-center py-0 px-2 cursor-pointer border-[0.5px] border-solid border-text-color-gray-979797"
              onClick={onClickAddTip}
            >
              <div className="relative Tablet_768:text-smi">+ {LL0().settings.addTip()}</div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap items-start justify-start gap-[4px]">
            {generalSetting0()?.defaultTips?.map((defaultTip, index) =>
              <TaxPaymentTipServiceTag tagLabel={`${defaultTip}%`} key={index} onClickDelete={() => deleteDefaultTip(index)}/>)}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-center gap-[8px_0px]">
          <b className="relative sm:text-smi Tablet_768:text-smi">
            {LL0().settings.serviceFees()}:
          </b>
          <div className="self-stretch flex flex-row items-center justify-start gap-[0px_10px] text-center text-mini">
            <TextField
              className="bg-[transparent] flex-1"
              color="primary"
              size="small"
              variant="outlined"
              type="number"
              value={customParseFloat(serviceCharge.toString()) || '0'}
              onChange={e => setServiceCharge(+e.target.value)}
            />
            <div
              className="rounded-sm bg-whitesmoke-100 box-border h-[37px] flex flex-row items-center justify-center py-0 px-2 min-w-[84px] cursor-pointer border-[0.5px] border-solid border-text-color-gray-979797"
              onClick={onClickAddServiceCharge}
            >
              <div className="flex-1 relative Tablet_768:text-smi">{LL0().ui.add()}</div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap items-start justify-start gap-[4px]">
            {generalSetting0()?.serviceCharges?.map((serviceCharge, index) =>
              <TaxPaymentTipServiceTag tagLabel={`${serviceCharge}%`} key={index} onClickDelete={() => deleteServiceCharge(index)} />)}
          </div>
        </div>
        { isQuebecSrmEnabled() &&
        <div className="self-stretch flex flex-row items-center justify-start Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
          <FormControlLabel
            className="flex-1"
            label="Set service fee in bill as an item (SRM)"
            control={<Switch color="primary" size="small" value={!!generalSetting0()?.serviceFeeAsItem}
              onChange={e => _.assign(generalSetting0(), {serviceFeeAsItem: e.target.checked})}/>}
          />
        </div>
        }
      </div>
      {isAddTipPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeAddTipPopup}
        >
          <AddTipPopup onClose={closeAddTipPopup}/>
        </PortalPopup>
      )}
      {isAddServiceChargePopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeAddServiceChargePopup}
        >
          <AddServiceChargePopup onClose={closeAddServiceChargePopup}/>
        </PortalPopup>
      )}
    </>
  );
};

export default TipServiceSection;