import { type FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";

export type SRMCertificateWarningPopupType = {
  onClose?: () => void;
  onConfirm?: () => void;
};

const SRMCertificateWarningPopup: FunctionComponent<
  SRMCertificateWarningPopupType
> = ({ onClose, onConfirm }) => {

  return (
    <div className="w-full max-w-[500px] !gap-4 relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-end justify-start pt-[18px] px-4 pb-3.5 box-border gap-[4px] min-w-[320px] h-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div className='w-full flex justify-start'>
        <b className='text-[18px] font-bold'>{LL0().srm.messages.doYouWantEnrollNewCert()}</b>
      </div>
      <div className='flex justify-start'>
        <div className='text-[16px] text-black'>{LL0().srm.messages.enrollNewCertExplain()}</div>
      </div>
      <div className="gap-3 self-stretch flex flex-row items-center justify-end text-center text-sm text-white-solid-white-100-ffffff">
        <div className="rounded-md overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 border-[1px] border-[#2979ff]"
             onClick={onClose}
        >
          <b className="relative text-[#2979ff]">{LL0().ui.cancel()}</b>
        </div>
        <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
             onClick={onConfirm}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default SRMCertificateWarningPopup;