import {
  CashbookTransactionsAction,
  formatDate,
  transactions,
  VATData,
  VIEW__HISTORY_TRANSACTION_DETAILS
} from "@/react/CashbookView/CashbookView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { useMemo, type FunctionComponent, useCallback, useState } from "react";
import PrinterSelectButton from "./PrinterSelectButton";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";

const TransactionDetailsPlugin: FunctionComponent = () => {
  const transaction = useMemo(() => transactions().find(t => t._id === VIEW__HISTORY_TRANSACTION_DETAILS.historyTransactionDetailsId()._id), [transactions(), VIEW__HISTORY_TRANSACTION_DETAILS.historyTransactionDetailsId()])
  const [isViewReceipt, setOpenPopupViewReceipt] = useState(false)

  const openPopupViewReceipt = useCallback(() => {
    setOpenPopupViewReceipt(!isViewReceipt)
  }, [])

  if (!transaction) return

  return (
    <div className="bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-center justify-start gap-[0px] text-left text-lg text-gray-solid-gray-380-cdcdcd font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start">
      <div className="self-stretch bg-black-solid-black-500-59636f h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[5px] px-2.5 box-border sm:h-[42px]">
        <div className="overflow-hidden flex flex-row items-center justify-start gap-[16px] sm:gap-[12px]">
          <img
            className="relative w-6 h-6 object-cover sm:w-5 sm:h-5"
            alt=""
            src="/icondouble-chevron-icon@2x.png"
          />
          <b className="relative tracking-[-0.28px] sm:text-mini">
            {LL0().cashbook.transactionDetails()}
          </b>
        </div>
        <div className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-center text-sm text-white-solid-white-100-ffffff sm:h-8"
          onClick={() => VIEW__HISTORY_TRANSACTION_DETAILS.onClick({ id: "", _id: "" })}
          ref={makeRipple}
        >
          <img
            className="relative w-[30px] h-[30px] object-cover"
            alt=""
            src="/iconback-icon@2x.png"
          />
          <b className="relative leading-[17px]">{LL0().posSetup.accountTab.back()}</b>
        </div>
      </div>
      <div className="self-stretch flex-1 bg-black-solid-black-700-364150 overflow-y-auto flex flex-col items-start justify-start gap-[10px] text-sm text-white-solid-white-100-ffffff">
        <table className="self-stretch bg-black-solid-black-700-364150 overflow-hidden shrink-0 [empty-cells:hide] border-spacing-[0] border-none border-collapse">
          <thead>
            <tr>
              <th className="relative pr-0 pb-0">
                <div className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border h-[43px] overflow-hidden border-r-[1px] border-solid border-white-solid-white-100-ffffff" />
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().cashbook.reportId()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().common.date()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().orderHistory.amount()}
                  </b>
                </div>
              </th>
              <th className="relative pr-0 pb-0" colSpan={3}>
                <div className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] box-border w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-white-solid-white-100-ffffff">
                  <b className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().orderHistory.type()}
                  </b>
                </div>
              </th>
              <th className="relative pb-0" colSpan={5}>
                <div className="[background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] w-full h-[43px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 box-border">
                  <b className="relative text-mini font-mulish text-white-solid-white-100-ffffff text-center mq1440:text-lg">
                    {LL0().sidebar.user()}
                  </b>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white-solid-white-100-ffffff">
              <td className="relative pr-0">
                <div className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-2 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                  <img
                    className="relative w-6 h-6 object-cover"
                    alt=""
                    src={transaction?.action === CashbookTransactionsAction.INCOMING ? "/iconarrow-increace@2x.png" : "/cashbookarrow-decrease@2x.png"}
                  />
                </div>
              </td>
              <td className="relative pr-0" colSpan={3}>
                <div className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                  <div className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                    {VIEW__HISTORY_TRANSACTION_DETAILS.historyTransactionDetailsId().id}
                  </div>
                </div>
              </td>
              <td className="relative pr-0" colSpan={3}>
                <div className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                  <div className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                    {formatDate(transaction?.date, `${LL2().dates.dateFormat()} | ${LL2().dates.timeFormat()}`)}
                  </div>
                </div>
              </td>
              <td className="relative pr-0" colSpan={3}>
                <div className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                  <div className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                    {LL3().format.currency(transaction?.amount || 0)}
                  </div>
                </div>
              </td>
              <td className="relative pr-0" colSpan={3}>
                <div className="box-border w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 border-r-[1px] border-solid border-black-solid-black-1000-000000">
                  <div className="relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                    {transaction?.transactionType}
                  </div>
                </div>
              </td>
              <td className="relative" colSpan={3}>
                <div className="w-full h-10 overflow-hidden shrink-0 flex flex-row items-center justify-end p-3 box-border">
                  <div className="flex-1 relative text-smi font-mulish text-black-solid-black-880-1d1d26 text-center mq1440:text-mini">
                    {transaction?.user}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="self-stretch overflow-hidden shrink-0 flex flex-row items-start justify-start p-2.5 gap-[42px] Tablet_768:gap-[20px]">
          <div className="flex-1 overflow-hidden flex flex-col items-start justify-center gap-[20px]">
            <div className="self-stretch flex flex-col items-start justify-center gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">
                {LL0().orderHistory.amount()}
              </i>
              <TextField
                className="bg-white self-stretch"
                color="primary"
                size="small"
                variant="outlined"
                value={transaction?.amount?.toString()}
                label={LL0().orderHistory.amount()}
                disabled
                disabledLabel
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-center gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">
                {LL0().order.Note()}
              </i>
              <TextField
                className="bg-white self-stretch"
                color="primary"
                size="small"
                variant="outlined"
                value={transaction?.note}
                label={LL0().order.Note()}
                disabled
                disabledLabel
              />
            </div>
          </div>
          <div className="flex-1 overflow-hidden flex flex-col items-start justify-center gap-[20px]">
            <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">VAT</i>
              <div className="overflow-hidden flex flex-row flex-wrap items-start justify-start gap-[20px] Tablet_768:gap-[8px]">
                {VATData.map((vat, index) => (
                  <PrinterSelectButton vatValue={vat.name} key={index} isSelected={transaction?.tax === vat.value}
                  />
                ))}
              </div>
            </div>
            <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[5px]">
              <i className="relative sm:text-xs mq1440:text-base">{LL0().printing.invoice()}</i>
              <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 h-[39px] overflow-hidden shrink-0 flex flex-row items-center justify-start py-0 pr-2.5 pl-0 box-border gap-[10px] text-center text-2xs font-inter">
                <div className="self-stretch w-10 overflow-hidden shrink-0 flex flex-row items-center justify-center relative">
                  <img
                    className="relative w-6 h-6 object-cover z-[0]"
                    alt=""
                    src="/iconattachments@2x.png"
                  />
                  <div className="my-0 mx-[!important] absolute top-[3px] left-[23px] rounded-[703.45px] bg-red-solid-red-180-f55349 w-[17px] h-[17px] flex flex-col items-center justify-center z-[1]">
                    {transaction?.receipt &&
                      <div className="relative font-semibold flex items-center justify-center w-[9.7px] h-[19.3px] shrink-0">
                        1
                      </div>}
                  </div>
                </div>
                <div onClick={openPopupViewReceipt} className="flex-1 relative text-sm [text-decoration:underline] font-semibold font-mulish text-blue-solid-blue-400-0091ff sm:text-xs">
                  {LL0().restaurant.viewReceipt()}
                </div>
              </div>
              <i className="relative text-3xs text-gray-solid-gray-480-b1b1b1 mq1440:text-xs">
                {LL0().cashbook.pleaseUsePhoto()}
              </i>
              {(isViewReceipt && transaction?.receipt) &&
                <div>
                  <img alt={'d'} src={transaction.receipt} className={'w-full h-auto'} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetailsPlugin;
