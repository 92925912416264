import { FunctionComponent } from "react";

type PopupNotificationErrorType = {
  onClose?: () => void;
  message?: string;
};

const PopupNotificationError: FunctionComponent<PopupNotificationErrorType> = ({
  onClose,
  message,
}) => {
  return (
    <div className="rounded-md bg-pink-solid-pink-250-ffe5e5 shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] flex flex-row items-center justify-center py-2.5 px-5 box-border gap-[16px] max-w-full max-h-full overflow-auto text-center text-xl text-blue-solid-blue-900-1c1f37 font-mulish-button-name-bold">
      <img
        className="relative w-[22px] h-[22px] object-cover"
        alt=""
        src="/iconmenu-warning-1@2x.png"
      />
      <b className="relative lg:text-lg md:text-base sm:text-sm mq768:text-smi mq600:text-smi">
        {message}
      </b>
      <img
        className="relative w-[25px] h-[25.4px] cursor-pointer"
        alt=""
        src="/close-tab.svg"
        onClick={onClose}
      />
    </div>
  );
};

export default PopupNotificationError;
