import { DateTime } from 'luxon'

const QUEBEC_TIMEZONE = 'America/Toronto' // Note: 'Canada/Eastern' is actually an alias of 'America/Toronto'

/**
 * Format the Quebec's date to to 'YYYY-MM-DD HH:mm:ss'.
 *
 * Should used for display date in **Report** or **Bill**
 */
export const formatDate = (d: Date) => DateTime.fromJSDate(d).toFormat('yyyy-MM-dd HH:mm:ss')

/**
 * Reformat the formatted Quebec's date from 'YYYYMMDDHHmmss' to 'YYYY-MM-DD HH:mm:ss' format.
 *
 * Should used for display date in **Report** or **Bill**
 */

export const reformatDate = (s: string) => DateTime.fromFormat(s, 'yyyyMMddHHmmss').toFormat('yyyy-MM-dd HH:mm:ss')

/**
 * Reformat the formatted Quebec's date.
 *
 * Should used for display date in **Report** or **Bill**
 *
 * This will parse the time string in 'yyyyMMddHHmmss' using Quebec timezone
 * and reformat it to `yyyy-MM-dd HH:mm:ss` in local time zone.
 */
export function reformatDateInLocalTz(s: string) {
  const dt = DateTime.fromFormat(s, 'yyyyMMddHHmmss', { zone: QUEBEC_TIMEZONE }).setLocale('fr')
  return DateTime.local().offset === dt.offset
    ? dt.toFormat('yyyy-MM-dd HH:mm:ss')
    : // If time zone is different with Quebec Time Zone, must show the timezone info
      dt.setZone().toFormat(`yyyy-MM-dd HH:mm:ss 'UTC${getUtcInfo(undefined, false)}'`)
}

/** Format date to format `yyyyMMddHHmmss`. Should used for contruct JSON data */
export const formatDateForJson = (d: Date) => DateTime.fromJSDate(d).toFormat('yyyyMMddHHmmss')

/** Format date to format `yyyyMMddHHmmss`, in Quebec timezone. Should used for contruct JSON data */
export const formatDateForJsonInQuebecTz = (d: Date) => {
  const dt = DateTime.fromJSDate(d).setZone(QUEBEC_TIMEZONE)
  return dt.setLocale('fr').toFormat('yyyyMMddHHmmss')
}

/** Get the UTC offset info for Quebec timezone. Example value: `"-05:00A"` */
export const getQuebecUtcInfo = () => getUtcInfo(QUEBEC_TIMEZONE)

/** Get the UTC offset info. Example value: `"+07:00N"` */
export function getUtcInfo(zone?: string, withDST = true) {
  const dt = DateTime.fromJSDate(new Date(), { zone })
  // If in DST, offset is 1 hour forward, so we need to revert it for Quebec WEB-SRM
  const offset = dt.isOffsetFixed || !dt.isInDST ? dt.offset : dt.offset - 60
  const sign = offset >= 0 ? '+' : '-'
  const hours = Math.abs(offset / 60)
    .toString()
    .padStart(2, '0')
  const minutes = Math.abs(offset % 60)
    .toString()
    .padStart(2, '0')
  return `${sign}${hours}:${minutes}${!withDST ? '' : dt.isInDST ? 'A' : 'N'}`
}

Object.assign(window, {
  reformatDateInLocalTz,
  formatDateForJsonInQuebecTz,
  getQuebecUtcInfo,
  getUtcInfo,
  DateTime,
})
