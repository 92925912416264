import type {FunctionComponent} from "react";
import UserTab from './UserTab'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { createUser, goBack, selectedUser } from '@/react/UserSettingView/UserSettingView.tsx'
import {LL0} from "@/react/core/I18nService.tsx";
import { users0 } from "@/data/UserSignal.ts";

const UserSettingLeft: FunctionComponent = () => {
  return (
    <div className="self-stretch bg-white-solid-white-230-f3f4f4 overflow-y-auto flex flex-col items-start justify-between h-full text-left text-smi text-red-solid-red-230-ff505d font-mulish Tablet_600:h-full">
      <div className="no-scrollbar flex-1 bg-white-solid-white-230-f3f4f4 w-[246px] overflow-y-auto shrink-0 flex flex-col items-start justify-start Tablet_768:w-[180px] Tablet_600:w-[180px] Mobile_480:w-40">
        {users0().map(user => (
          <UserTab
            profileImageId={user.avatar}
            userName={user.name}
            showSelected={user._id === selectedUser()?._id}
            userTabAlignSelf="stretch"
            key={user._id}
            userId={user._id}
          />
        ))}
      </div>
      <div className="self-stretch bg-white-solid-white-100-ffffff shadow-[0px_-4px_6px_rgba(0,_0,_0,_0.08)] flex flex-col items-center justify-start pt-3 px-0 pb-2 gap-[16px_0px]">
        <div
          className="self-stretch h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-3 box-border gap-[0px_8px]"
          ref={makeRipple}
          onPointerDown={createUser}
        >
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/icongeneralplus-icon-grey@2x.png"
          />
          <b className="relative leading-[24px]">{LL0().settings.addUser()}</b>
        </div>
        <button
          className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[0px_3px] text-center text-sm text-white-solid-white-100-ffffff"
          onClick={goBack}
          ref={makeRipple}
          type="button"
        >
          <img
            className="w-[30px] relative h-[30px] object-cover"
            alt=""
            src="/iconback-icon@2x.png"
          />
          <b className="relative leading-[17px]">{LL0().ui.back()}</b>
        </button>
      </div>
    </div>
  )
}

export default UserSettingLeft
