import type { FunctionComponent } from 'react'
import {LL0} from "@/react/core/I18nService.tsx";
import { useContext } from 'react';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import clsx from 'clsx';

type EmptyListIndicatorType = {
  isEmptyList?: boolean;
};

const EmptyListIndicator: FunctionComponent<EmptyListIndicatorType> = ({
  isEmptyList,
}) => {
  const { containerClassName } = useContext(ItemFactoryContext);

  return (
    isEmptyList && (
      <div className={clsx("!flex self-stretch flex-1 rounded-md bg-white hidden flex-col items-center justify-center p-2 gap-[16px] text-center text-sm text-gray-solid-gray-650-9e9e9e font-mulish border-[1px] border-solid border-gray-solid-gray-370-d8d5d5",
        containerClassName)}>
        <img
          className="select-item-icon--%123 w-16 relative h-16 overflow-hidden shrink-0 object-cover"
          alt=""
          src="/select-item-icon@2x.png"
        />
        <b className="self-stretch relative">{LL0().order.selectItemsFromTheLeftSide()}</b>
      </div>
    )
  );
};

export default EmptyListIndicator;