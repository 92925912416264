export interface UsbDevice {
  devicePath: string
  deviceManufacturerName: string
  deviceProductName: string
  deviceVID: number
  devicePID: number
}

interface UsbDeviceWsMessage {
  event: 'usrobotics-list-usb-devices' | 'artech-list-usb-devices'
  data: { deviceList: UsbDevice[] }
}

interface UsbDeviceStatusWsMessage {
  event: 'EVENT_SEND_STATUS'
  data: { status: string; deviceID: string }
}

export function isUsbDeviceWsMessage(msg: unknown): msg is UsbDeviceWsMessage {
  const temp = msg as UsbDeviceWsMessage
  return (temp.event === 'usrobotics-list-usb-devices' || temp.event === 'artech-list-usb-devices') && Array.isArray(temp.data.deviceList)
}

export function isUsbDeviceStatusWsMessage(msg: unknown): msg is UsbDeviceStatusWsMessage {
  const temp = msg as UsbDeviceStatusWsMessage
  return (
    temp.event === 'EVENT_SEND_STATUS' &&
    // Check if correct types
    typeof temp.data === 'object' &&
    typeof temp.data.status === 'string' &&
    typeof temp.data.deviceID === 'string'
  )
}
