import InventoryPlugin from '@inventory/InventoryPlugin.tsx'
import { memo } from 'react'
import { inventories0, makeInventoriesAvailable, mappedInventories } from '@/data/InventoryHub.ts'
import { computed, effect, signal } from '@/react/core/reactive.ts'
import { type DocDeepSignal } from '@/data/data-utils.ts'
import { makeProductsAvailable, products0 } from '@/data/ProductHub.ts';
import { makeCategoriesAvailable, materialCategories } from '@/data/CategoryHub.ts'
import { Category } from '@/data/Category.ts'
import uuid from 'time-uuid'
import { Product } from '@/data/Product.ts'
import { clone } from 'json-fn'
import { Inventory } from '@/data/Inventory.ts'
import Debug from 'debug'
import { maxId0 } from '@/data/MaxIdHub.ts'
import {
  makeInventoryActionsAvailable,
  makeInventoryActionsFilterAvailable,
  makeInventoryActionsPageAvailable
} from '@/data/InventoryActionHub.ts'
import { InventoryAction } from '@/data/InventoryAction.ts'
import dayjs from 'dayjs'

const log = Debug("data:inventory")

export const initInventoryItem = (): Inventory => ({
  _id: uuid(),
  unit: "piece",
  product: {
    _id: uuid(),
    name: "",
    id: '',
    price: 0,
  },
  threshold: 100,
  stock: 0,
  id: maxId0()?.inventoryId || 1,
})

// for displaying inventories
export const [inventory0, setInventory0] = signal<DocDeepSignal<Inventory> | undefined>()

//inventory handlers

// create flow going to like this: create product -> link to inventory -> create inventory -> update stock for that inventory
export const createNewInventory = async (inventory: Inventory) => {
  const { product, ...rest } = inventory
  if (!product) return;
  const insertProduct: DocDeepSignal<Product> = product
  insertProduct!.doc = await Product.insert(clone(insertProduct || {}))
  maxId0().inventoryId =  maxId0().inventoryId! + 1;
  return Inventory.insert({...rest, productId: insertProduct?._id})
}

export const updateInventoryStock = (inventory: DocDeepSignal<Inventory>, amount: number, reason: string) => {
  inventory.stock += amount;
  InventoryAction.insert({_id: uuid(),
    inventoryId: inventory._id,
    type: amount >= 0 ? 'add' : 'remove',
    amount,
    date: dayjs().unix(),
    reason})
}

export const deleteCurrentInventory = () => {
  inventory0()?.doc?.incrementalRemove();
  products0().forEach(product => {
    if (!product.ingredients?.find(ingredient => ingredient === inventory0()?._id)) {
      return;
    }
    product.ingredients = product.ingredients.filter(ingredient => ingredient !== inventory0()?._id);
  })
}

//category handlers
export const existCategoryMap = computed(() => {
  let map : Record<string, boolean> = {};
  materialCategories().forEach(category => {
    products0().forEach(product => {
      if (product?.categories?.includes(category?._id)) {
        map[category._id] = true;
      }
    })
  })
  return map;
})

export const createNewCategory = () => {
  Category.insert({
    _id: uuid(),
    name: "",
    position: materialCategories().length + 1,
  })
}

export const deleteCategory = async (category: DocDeepSignal<Category>) => {
  //search for products that have this category
  products0().forEach(product => {
    if (!product.categories?.find(productCategory => productCategory === category._id)) {
      return;
    }
    product.categories = product.categories.filter(productCategory => productCategory !== category._id);
  })
  await category.doc?.incrementalRemove();
}

effect(() => log("mappedInventories: ", inventories0()))
const InventoryView = () => {
  makeInventoriesAvailable();
  makeInventoryActionsAvailable();
  makeInventoryActionsPageAvailable();
  makeInventoryActionsFilterAvailable();
  makeProductsAvailable();
  makeCategoriesAvailable();

  return <InventoryPlugin />
}

export default memo(InventoryView);