import { type FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils";

type ChoosePaymentPopUp = {
  onClose?: () => void;
};

const ChoosePaymentPopUp: FunctionComponent<
  ChoosePaymentPopUp
> = ({ onClose }) => {

  return (
    <div className="rounded-2xl bg-basic-color-white shadow-[0px_0px_4px_rgba(0,_0,_0,_0.1)] [backdrop-filter:blur(30px)] w-[450px] overflow-hidden flex flex-col items-center justify-center p-6 box-border gap-[5px] min-w-[360px] max-w-full max-h-full text-center text-xl text-red-solid-red-400-d93b5c font-mulish-button-name-bold">
      <div className="self-stretch flex flex-col items-center justify-start gap-[4px] mb-3">
        <b className="self-stretch relative leading-[28px] mb-2">
          {LL0().dialogs.missingPayment.title()}
        </b>
        <div className="self-stretch relative text-base leading-[20px] font-medium text-blue-solid-blue-900-081a51 mt-2">
          {LL0().dialogs.missingPayment.notification()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[150px] text-sm text-blue-solid-blue-200-017efa mt-3">
        <button ref={makeRipple} onClick={onClose} className="flex-1 rounded-md [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 text-basic-color-white">
          <b className="relative">{LL0().ui.ok()}</b>
        </button>
      </div>
    </div>
  );
};

export default ChoosePaymentPopUp;