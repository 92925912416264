import { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { deleteMany } from "@/data/data-utils.ts";
import { type EodReportItem } from "@/pos/logic/ReportType.ts";
import { createCollection } from "@/data/utils/migration.ts";
import { Buffer } from "buffer";
import pako from "pako";

export const EOD_COLLECTION_NAME = 'eods'

export const VERSION = 4;

const eodSchema = {
  title: 'eod schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 10 },
    z: { type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ^ 5 },
    complete: {type: "boolean"},
    date: {type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ** 10},
    vDate: {type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ** 10},
    startOrderId: {type: 'number'},
    endOrderId: {type: 'number'},
    paymentSum: {type: 'number'},
    cashSum: {type: 'number'},
    // taxGroup: {type: 'array'},
    tseData: {type: 'object'},
    report: {type: 'string'}
    //TODO: gan report vào eod schema
  },
  indexes: ['z', 'date', 'vDate']
} as const;

export interface TseData {
  username?: string
  serialNumber?: string
  signatureAlgorithm?: string
  tseTimeFormat?:  string
  pdEncoding?: string
  tsePublicKey?: string
  certificates: string[]
}

export interface Eod {
  _id: string
  z: number,
  complete: boolean,
  date: number
  vDate?: number
  startOrderId?: number
  endOrderId?: number
  paymentSum?: number
  cashSum?: number
  // taxGroup?: string[]
  tseData: TseData
  report?: EodReportItem
}

export type EodDocument = RxDocument<Eod>;
export type EodCollection = RxCollection<Eod>;

export const EodCollection: EodCollection = {} as EodCollection;
export const Eod = EodCollection;

export const createEodCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Eod,
    collectionName: EOD_COLLECTION_NAME,
    version: VERSION,
    schema: eodSchema,
    extraStrategy: {
      3: doc => {
        if (doc.report) {
          doc.report = Buffer.from(pako.deflate(JSON.stringify(doc.report))).toString('base64')
        }
        return doc
      }
    }
  });
}

//clearEod
export const clearEod = async () => {
  //use deleteMany
  await deleteMany(Eod, {}, true);
}

// @ts-ignore
window.clearEod = clearEod;

// @ts-ignore
window.Eod = Eod;