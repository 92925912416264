import dayjs from 'dayjs'
import debug from 'debug'
import _ from 'lodash'
import type { MangoQuerySelector } from 'rxdb'
import uuid from 'time-uuid'

import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { SrmDocumentLog } from '@/data/SrmDocumentLog'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { loginUser } from '@/data/UserSignal.ts'
import { YearlyReports, type YearlyReportData } from '@/data/YearlyReports'
import { showTrainingMode } from '@/pos/trainingMode'
import { LL0 } from '@/react/core/I18nService'
import { printInvoiceFromRaster } from '@/react/Printer/print-invoice'
import { ensureExclusive, handleError, progressToast } from '@/shared/decorators'
import { printImageToConsole } from '@/shared/printImageToConsole'
import { printRaster } from '@/shared/printRaster'
import { downloadPrintedImage } from '@/shared/utils'

import { WebSrmDocumentError } from './errors'
import { generateUserReport } from './generateUserReport'
import { getSdk } from './getSdk'
import { getSignableSdkWithHeadersAndTaxNum } from './getSdkWithHeadersAndTaxNum'
import { handleSrmError } from './lib/decorators'
import { DocumentTypes, OperationModes, PrintModes, PrintOptions, TransactionTypes, UserReportTypes } from './lib/enum'
import { formatMoney } from './lib/formatters'
import { logAndNotifySrmError } from './lib/logAndNotifySrmError'
import { formatDateForJsonInQuebecTz } from './lib/timezone'
import type { DocumentData, SignedDocumentData } from './lib/types'
import { shouldDownloadReceipt } from './lib/utils'
import { recordDocument } from './recordDocument'
import { isDocumentResponse } from './response-validators'

const log = debug('data:srm-document')

interface Options {
  /** Change the user that making transaction. If not specified, default to current login user */
  impersonate?: string
}

class SrmReportLogic {
  constructor(public options: Options = {}) {}

  @ensureExclusive()
  private async _sendDocument(signed: SignedDocumentData) {
    const { sdk, headers } = getSignableSdkWithHeadersAndTaxNum()

    const userName = this.options?.impersonate ?? loginUser()?.name
    if (!userName) throw new Error(LL0().srm.errors.missingUserName())

    log('ℹ️ Sending document...', headers, signed)
    const record = await recordDocument(signed, userName)
    const response = await sdk.document(headers, signed)
    if (!isDocumentResponse(response)) throw new Error(LL0().srm.errors.invalidDocumentResponse())

    log('✅ Document sent', response)
    await record.incrementalUpdate({
      $set: {
        sent: true,
        response: response.retourDoc,
      },
    })

    if (response.retourDoc.listErr?.length) logAndNotifySrmError(new WebSrmDocumentError(response))

    return [response, record] as const
  }
  async #printReportDocument(data: SignedDocumentData) {
    if (data.type !== 'RUT') throw new Error(LL0().srm.errors.reportTypeUnsupported({ type: data.type }))
    const sdk = getSdk()
    log(`⚡️ Printing user report for ${data.UT}...`)
    const raster = await generateUserReport(data, {
      impersonate: this.options?.impersonate,
      qrcodeContent: await sdk.generateReportQRCode(data),
    })
    if (import.meta.env.DEV) printImageToConsole(`Report for ${data.UT}`, raster)
    // TODO: check if this is the correct function to use
    await printInvoiceFromRaster(raster, { skipOpenCashDrawer: true })

    const { testcaseNumber } = posSetting0()?.srm || {}
    if (shouldDownloadReceipt(testcaseNumber)) await downloadPrintedImage(raster, `[${testcaseNumber}] ${data.DR}.png`)
  }

  /** @deprecated Use `printYearlyUserReport` instead */
  @ensureExclusive()
  @progressToast(([from, to]) => LL0().srm.messages.producingUserReport({ from: dayjs(from).format('L'), to: dayjs(to).format('L'), duration: `${dayjs(to).diff(dayjs(from), 'day')} day(s)` }))
  @handleSrmError()
  @handleError()
  async printUserReport(range: [from: Date, to: Date], targetUser?: string) {
    const summary = await fetchReportData(range)
    const signed = await genReportDocumentRequest({
      summary,
      impersonate: this.options?.impersonate,
      targetUser,
    })
    await this._sendDocument(signed)
    await this.#printReportDocument(signed)
  }

  @ensureExclusive()
  @progressToast((year, targetUser) => (targetUser ? LL0().srm.messages.producingYearlyUserReportForOneUser({ year, name: targetUser }) : LL0().srm.messages.producingYearlyUserReport({ year })))
  @handleSrmError()
  @handleError()
  async printYearlyUserReport(year: number, targetUser?: string) {
    const summary = await getOrFetchYearlyReportData({ year, user: targetUser, training: showTrainingMode() })
    const signed = await genReportDocumentRequest({
      summary,
      impersonate: this.options?.impersonate,
      targetUser,
    })
    await this._sendDocument(signed)
    await this.#printReportDocument(signed)
  }
}
export const srmReportLogic = new SrmReportLogic()

/** @deprecated Use fetchReportDataByYear instead */
async function fetchReportData(range: [from: Date, to: Date]): Promise<YearlyReportData> {
  const trainingMode = deviceSetting0()?.srm?.trainingMode
  const dateQuery = { $gte: dayjs(range[0]).unix(), $lte: dayjs(range[1]).unix() }

  const totalRecords = await SrmTransactionLog.count({
    selector: { date: dateQuery },
    index: 'date',
  }).exec()
  log(`ℹ️ Found ${totalRecords} record(s) in this time range`)

  const latestRecord = await SrmTransactionLog.findOne({
    selector: {
      date: dateQuery,
      'data.formImpr': PrintOptions.paper,
    },
    sort: [{ date: 'desc' }],
    index: 'date',
  }).exec()

  if (totalRecords === 0 || !latestRecord) throw new Error(LL0().srm.errors.noDataAvailable())

  const paymentRecords = await SrmTransactionLog.find({
    selector: {
      // Made in Operation mode (excluding Training mode)
      'data.modTrans': trainingMode ? OperationModes.training : OperationModes.operating,
      // In the date range
      date: dateQuery,
      // Must be a closing receipt
      'data.typTrans': TransactionTypes.closingReceipt,
      // Printed to customer
      'data.modImpr': PrintModes.bill,
      // Amount not zero
      total: { $ne: 0 },
    },
    sort: [{ date: 'asc' }],
  })
    .exec()
    .then(a => a.map(b => b.toMutableJSON()))
  const negativeRecords = await SrmTransactionLog.find({
    selector: {
      // Made in Operation mode (excluding Training mode)
      'data.modTrans': trainingMode ? OperationModes.training : OperationModes.operating,
      // In the date range
      date: dateQuery,
      // Must be a closing receipt
      'data.typTrans': TransactionTypes.closingReceipt,
      // Not printed to customer
      'data.modImpr': { $ne: PrintModes.bill },
      // Is a negative order
      total: { $lt: 0 },
    },
    sort: [{ date: 'asc' }],
  })
    .exec()
    .then(a => a.map(b => b.toMutableJSON()))

  if (!paymentRecords.length) throw new Error(LL0().srm.errors.noPaymentDataAvailable())
  const records = [...paymentRecords, ...negativeRecords]

  log(`ℹ️ Payment records:`, paymentRecords)
  log(`ℹ️ Negative records:`, negativeRecords)
  log(`ℹ️ Generating report for ${records.length} record(s)`, records)

  const summary: YearlyReportData = {
    year: +latestRecord.data.datTrans.slice(0, 4),
    // payments: _.uniq(records.map(r => r.data.modPai).filter(a => a !== 'SOB' && a !== 'AUC')),
    net: _.sumBy(records, r => +r.data.mont.avantTax),
    gst: _.sumBy(records, r => +r.data.mont.TPS),
    qst: _.sumBy(records, r => +r.data.mont.TVQ),
    gross: _.sumBy(records, r => +r.data.mont.apresTax),
    adj: _.sumBy(records, r => +(r.data.mont.ajus ?? '0')),
    due: _.sumBy(records, r => +(r.data.mont.mtdu ?? r.data.mont.apresTax)),
    totalRecords: totalRecords,
    paymentRecords: paymentRecords.length,
    latestTrans: {
      apresTax: latestRecord.data.mont.apresTax,
      datTrans: latestRecord.data.datTrans,
      noTrans: latestRecord.data.noTrans,
      psiDatTrans: latestRecord.response?.psiDatTrans,
      psiNoTrans: latestRecord.response?.psiNoTrans,
    },
  }
  log(`ℹ️ Summary`, summary)
  return summary
}

interface FetchReportDataOptions {
  year: number
  user?: string
  training: boolean
}

/**
 * Retrieves or fetches yearly report data for a given year and user. If the report data
 * already exists in the database, it returns the existing data. Otherwise, it fetches
 * new report data, saves it (if targeting a specific user), and returns the newly fetched data.
 *
 * TODO: check if the existing report data is outdated and needs to be updated.
 */
async function getOrFetchYearlyReportData({ year, user, training }: FetchReportDataOptions): Promise<YearlyReportData> {
  console.groupCollapsed(`ℹ️ Fetching yearly report data of year ${year}${user ? ` for ${user}` : ''}${training ? ' in training mode' : ''}...`)
  try {
    const rows = await YearlyReports.find({
      selector: {
        year,
        training,
        ...(user ? { user } : {}),
      },
    }).exec()
    if (rows.length) log(`ℹ️ Found ${rows.length} existing report(s) for year ${year}`)
    const aggregated: YearlyReportData = rows.reduce(
      (acc, { reportData }) => {
        acc.net += reportData.net
        acc.gst += reportData.gst
        acc.qst += reportData.qst
        acc.gross += reportData.gross
        acc.adj += reportData.adj
        acc.due += reportData.due
        acc.totalRecords += reportData.totalRecords
        acc.paymentRecords += reportData.paymentRecords

        // If the latest transaction is more recent, update it
        if (reportData.latestTrans) {
          if (!acc.latestTrans || acc.latestTrans.datTrans < reportData.latestTrans.datTrans) {
            acc.latestTrans = reportData.latestTrans
          }
        }
        return acc
      },
      <YearlyReportData>{
        net: 0,
        gst: 0,
        qst: 0,
        gross: 0,
        adj: 0,
        due: 0,
        totalRecords: 0,
        paymentRecords: 0,
      }
    )

    const existingReport = rows.length ? aggregated : null
    log(`ℹ️ Existing report data:`, existingReport)
    const calculatedReport = await fetchReportDataByYear({ year, user: user, training: training })
    log(`ℹ️ Calculated report data:`, calculatedReport)

    if (!existingReport || calculatedReport.totalRecords > existingReport.totalRecords) {
      log(`ℹ️ Existing report is outdated, using calculated report...`)
      // Only save newly calculated report if targeting a specific user
      if (user) {
        log(`💾 Saving the calculated yearly report for user ${user} in year ${year}...`)
        await YearlyReports.insert({
          _id: uuid(),
          year,
          user,
          training,
          reportData: calculatedReport,
        })
      }
      return calculatedReport
    }

    log(`ℹ️ The existing report is valid, using it...`)
    return existingReport
  } finally {
    console.groupEnd()
  }
}

async function fetchReportDataByYear({ year, user, training }: FetchReportDataOptions): Promise<YearlyReportData> {
  console.groupCollapsed(`ℹ️ Calculating report data for year ${year}${user ? ` for ${user}` : ''}${training ? ' in training mode' : ''}...`)
  try {
    if (year < 2000 || year > dayjs().year()) throw new Error(LL0().srm.errors.invalidReportYear({ year }))

    const mainQuery: MangoQuerySelector<SrmTransactionLog> = {
      date: {
        $gte: dayjs(`${year}-01-01`).unix(),
        $lte: dayjs(`${year}-12-31`).unix(),
      },
      // Made in Operation mode (excluding Training mode)
      training: training ? true : { $ne: true }, // this will also include null/undefined values
      // Target user
      ...(user ? { user: user } : {}),
    }

    // TODO: Check why using the index for `count()` not working
    // const allRecords = await SrmTransactionLog.count({
    //   selector: mainQuery,
    //   index: user ? ['date', 'training', 'user'] : ['date', 'training'],
    // }).exec()
    const allRecords = await SrmTransactionLog.find({ selector: mainQuery })
      .exec()
      .then(rows => rows.length)
    log(`ℹ️ Found ${allRecords} record(s) in year ${year}`)

    const latestRecord = await SrmTransactionLog.findOne({
      selector: {
        ...mainQuery,
        'data.formImpr': PrintOptions.paper,
      },
      sort: [{ date: 'desc' }],
      index: 'date',
    }).exec()
    log(`ℹ️ Latest record:`, latestRecord)

    const paymentRecords = await SrmTransactionLog.find({
      selector: {
        ...mainQuery,
        // Must be a closing receipt
        'data.typTrans': TransactionTypes.closingReceipt,
        // Printed to customer
        'data.modImpr': PrintModes.bill,
        // Amount not zero
        total: { $ne: 0 },
      },
      sort: [{ date: 'asc' }],
    })
      .exec()
      .then(a => a.map(b => b.toMutableJSON()))
    log(`ℹ️ Payment records:`, paymentRecords)
    const negativeRecords = await SrmTransactionLog.find({
      selector: {
        ...mainQuery,
        // Must be a closing receipt
        'data.typTrans': TransactionTypes.closingReceipt,
        // Not printed to customer
        'data.modImpr': { $ne: PrintModes.bill },
        // Is a negative order
        total: { $lt: 0 },
      },
      sort: [{ date: 'asc' }],
    })
      .exec()
      .then(a => a.map(b => b.toMutableJSON()))
    log(`ℹ️ Negative records:`, negativeRecords)

    if (!paymentRecords.length) throw new Error(LL0().srm.errors.noPaymentDataAvailable())
    const records = [...paymentRecords, ...negativeRecords]

    if (allRecords === 0 || !latestRecord) throw new Error(LL0().srm.errors.noDataAvailable())

    log(`ℹ️ Generating report for ${allRecords} record(s)`, records)

    const summary: YearlyReportData = {
      year,
      // payments: _.uniq(records.map(r => r.data.modPai).filter(a => a !== 'SOB' && a !== 'AUC')),
      net: _.sumBy(records, r => +r.data.mont.avantTax),
      gst: _.sumBy(records, r => +r.data.mont.TPS),
      qst: _.sumBy(records, r => +r.data.mont.TVQ),
      gross: _.sumBy(records, r => +r.data.mont.apresTax),
      adj: _.sumBy(records, r => +(r.data.mont.ajus ?? '0')),
      due: _.sumBy(records, r => +(r.data.mont.mtdu ?? r.data.mont.apresTax)),
      totalRecords: allRecords,
      paymentRecords: paymentRecords.length,
      latestTrans: {
        apresTax: latestRecord.data.mont.apresTax,
        datTrans: latestRecord.data.datTrans,
        noTrans: latestRecord.data.noTrans,
        psiDatTrans: latestRecord.response?.psiDatTrans,
        psiNoTrans: latestRecord.response?.psiNoTrans,
      },
    }
    log(`ℹ️ Summary`, summary)
    return summary
  } finally {
    console.groupEnd()
  }
}

interface DocumentRequestOptions {
  summary: YearlyReportData
  impersonate?: string
  targetUser?: string
}

async function genReportDocumentRequest({ summary, impersonate, targetUser }: DocumentRequestOptions) {
  const { sdk, headers, gstNumber, qstNumber, deviceId } = getSignableSdkWithHeadersAndTaxNum()
  const { billingNumber } = posSetting0()?.srm ?? {}
  const { name: companyName, address, city, province, zipCode } = posSetting0()?.companyInfo ?? {}
  const userName = impersonate ?? loginUser()?.name

  if (!billingNumber) throw new Error(LL0().srm.errors.missingBillingNumber())
  if (!userName) throw new Error(LL0().srm.errors.missingUserName())
  if (!companyName) throw new Error(LL0().srm.errors.missingCompanyName())
  if (!address) throw new Error(LL0().srm.errors.missingCompanyAddress())

  const fullAddr = [address, city, province, zipCode].filter(a => !!a).join(', ')

  const now = new Date()
  const data: DocumentData = {
    type: DocumentTypes.userReport,
    RT: gstNumber,
    TQ: qstNumber,
    UT: userName,

    //====< Parameters related to the last document >====/
    NO: summary.latestTrans?.noTrans || '',
    MT: summary.latestTrans?.apresTax || '',
    DF: summary.latestTrans?.datTrans || '',

    //====< Parameters related to the sales summary >====/
    AN: `${summary.year}`,
    SN: `${summary.totalRecords}`,
    SV: `${summary.paymentRecords}`,
    SS: formatMoney(summary.net),
    SF: formatMoney(summary.gst),
    SP: formatMoney(summary.qst),
    ST: formatMoney(summary.gross),
    SA: formatMoney(summary.adj),
    SD: formatMoney(summary.due),
    TS: 'E',
    SR: billingNumber,
    CM: targetUser ? UserReportTypes.Unique : UserReportTypes.All,

    //====< Parameters relating to the device used to produce the report >====/
    IA: deviceId,
    IS: headers.IDSEV,
    VR: headers.VERSI,

    //====< Parameters relating to the dates required on the report >====/
    DC: formatDateForJsonInQuebecTz(dayjs(now).subtract(15, 'minutes').toDate()),
    DR: formatDateForJsonInQuebecTz(dayjs(now).subtract(5, 'seconds').toDate()),

    //====< Other parameters >====/
    AD: fullAddr,
    psiDatTrans: summary.latestTrans?.psiDatTrans,
    psiNoTrans: summary.latestTrans?.psiNoTrans,
  }
  return await sdk.signDocument(data)
}

// DEV Only
Object.assign(window, {
  async dev_printReport(id: string, download = false) {
    const sdk = getSdk()
    const { data } = await SrmDocumentLog.findOne({ selector: { _id: id } }).exec(true)

    if (data.type !== 'RUT') throw new Error(LL0().srm.errors.reportTypeUnsupported({ type: data.type }))

    log(`⚡️ Printing sale report...`)
    const raster = await generateUserReport(data, { qrcodeContent: await sdk.generateReportQRCode(data) })
    if (import.meta.env.DEV) printImageToConsole(`Report for ${data.UT}`, raster)
    await printRaster(raster)

    if (download) await downloadPrintedImage(raster, data.DR)
  },
  fetchReportData,
  fetchReportDataByYear,
  getOrFetchYearlyReportData,
})
