import { type FunctionComponent, useCallback } from "react";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import uuid from "time-uuid";
import { setInputControllers } from "@/react/OrderView/OrderView.tsx";
import {
  addNewMenu,
  closeEditMenuProviderMenuName,
  multipleMenus0
} from "@/react/EditMenuView/EditPricingLevel.logic.ts";

import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

export type EditMenuProviderMenuNameType = {
  className?: string;
  onClose?: () => void;
};

const EditMenuProviderMenuName: FunctionComponent<
  EditMenuProviderMenuNameType
> = ({ className = "", onClose }) => {

  const [newMenu, setNewMenu] = useSignal<string>('')

  const onComplete = useCallback(() => {
    addNewMenu(newMenu() as string)
    setNewMenu("");
  }, [newMenu()]);

  return (
    <div
      className={`w-full h-full relative rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-[34px] px-5 pb-2.5 box-border gap-[24px] min-w-[520px] max-w-full max-h-full text-center text-base text-black-solid-black-900-1e1e23 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-center gap-[6px] z-[0] text-left">
        <div className="relative font-semibold md:text-xs">{LL0().editMenu.menuName()}:</div>
        <Input
          className="self-stretch font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
          placeholder="Eg: Uber Eats"
          value={newMenu() || ''}
          refInputController={i => {
            i.setFocus(true)
            return setInputControllers?.([i]);
          }}
          onChange={(value) => {
            const menu = value.trim();
            if (menu !== '') {
              setNewMenu(menu);
            }
          }}
        />
      </div>
      {deviceSetting0()?.useVirtualKeyboard && (
      <div className="self-stretch flex flex-col items-center justify-center pt-0 px-0 pb-2.5 z-[1] text-sm text-white-solid-white-100-ffffff">
        <div className="rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-8 box-border height_414:h-[30px]"
             onPointerDown = {() => {
               addNewMenu(newMenu() as string);
             }}
        >
          <b className="relative">{LL0().ui.add()}</b>
        </div>
      </div>
      )}
      {!deviceSetting0()?.useVirtualKeyboard && (
        <TableManualKeyboard
          value = {''}
          inputController={inputController0}
          onEnter={onComplete}
        />
      )}
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[3]"
        alt=""
        src="/close-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default EditMenuProviderMenuName;
