import AmexUrl from '@/assets/payments/amex.png';
import ApplePayUrl from '@/assets/payments/apple_pay.png'
import BlackCardUrl from '@/assets/payments/black_card.png'
import CardUrl from '@/assets/payments/card.png'
import CashUrl from '@/assets/Cash.png'
import ChequeUrl from '@/assets/payments/cheque.png'
import DebitCardUrl from '@/assets/payments/debit_card.png'
import DoordashUrl from '@/assets/payments/doordash.png'
import GiftCardUrl from '@/assets/payments/gift_card.png'
import GooglePayUrl from '@/assets/payments/google_pay.png'
import InteracUrl from '@/assets/payments/interac.png'
import MastercardUrl from '@/assets/payments/mastercard.png'
import MenuOttawaUrl from '@/assets/payments/menu_ottawa.png'
import PaypalUrl from '@/assets/payments/paypal.png'
import SkipUrl from '@/assets/payments/skip.png'
import SodexoUrl from '@/assets/payments/sodexo.png'
import UberUrl from '@/assets/payments/uber.png'
import VisaUrl from '@/assets/payments/visa.png'
import DebitorUrl from '@/assets/payments/debitor.png'

interface PaymentMapProps {
  id: number;
  name?: string;
  icon?: string;
  type?: string;
  extraType?: string;
}

export const PaymentMap: PaymentMapProps[] = [
  {
    id: 0,
    name: "Amex",
    icon: AmexUrl,
    type: "none",
    extraType: 'amex',
  },
  {
    id: 1,
    name: "Apple Pay",
    icon: ApplePayUrl,
    type: "none",
    extraType: 'apple_pay',
  },
  {
    id: 2,
    name: "Black Card",
    icon: BlackCardUrl,
    type: "none",
    extraType: 'black_card',
  },
  {
    id: 3,
    name: "Card",
    icon: CardUrl,
    type: "none",
    extraType: 'card',
  },
  {
    id: 4,
    name: "Cash",
    icon: CashUrl,
    type: "cash",
    extraType: 'cash',
  },
  {
    id: 5,
    name: "Cheque",
    icon: ChequeUrl,
    type: "none",
    extraType: 'cheque',
  },
  {
    id: 6,
    name: "Debit Card",
    icon: DebitCardUrl,
    type: "none",
    extraType: 'debit_card',
  },
  {
    id: 7,
    name: "Door Dash",
    icon: DoordashUrl,
    type: "none",
    extraType: 'door_dash',
  },
  {
    id: 8,
    name: "Gift Card",
    icon: GiftCardUrl,
    type: "none",
    extraType: 'gift_card',
  },
  {
    id: 9,
    name: "Google Pay",
    icon: GooglePayUrl,
    type: "none",
    extraType: 'google_pay',
  },
  {
    id: 10,
    name: "Interac",
    icon: InteracUrl,
    type: "none",
    extraType: 'interac',
  },
  {
    id: 11,
    name: "Mastercard",
    icon: MastercardUrl,
    type: "none",
    extraType: 'mastercard',
  },
  {
    id: 12,
    name: "Menu Ottawa",
    icon: MenuOttawaUrl,
    type: "none",
    extraType: 'menu_ottawa',
  },
  {
    id: 13,
    name: "Paypal",
    icon: PaypalUrl,
    type: "none",
    extraType: 'paypal',
  },
  {
    id: 14,
    name: "Skip",
    icon: SkipUrl,
    type: "none",
    extraType: 'skip',
  },
  {
    id: 15,
    name: "Sodexo",
    icon: SodexoUrl,
    type: "none",
    extraType: 'sodexo',
  },
  {
    id: 16,
    name: "Uber",
    icon: UberUrl,
    type: "none",
    extraType: 'uber',
  },
  {
    id: 17,
    name: "Visa",
    icon: VisaUrl,
    type: "none",
    extraType: 'visa',
  },
  {
    id: 18,
    name: 'Debitor',
    icon: DebitorUrl,
    type: 'debitor',
    extraType: 'debitor',
  }
];


export const PaymentMap2 = {
  "Amex": AmexUrl,
  "Apple Pay": ApplePayUrl,
  "Black Card": BlackCardUrl,
  "Card": CardUrl,
  "Cash": CashUrl,
  "Cheque": ChequeUrl,
  "Debit Card": DebitCardUrl,
  "Door Dash": DoordashUrl,
  "Gift Card": GiftCardUrl,
  "Google Pay": GooglePayUrl,
  "Interac": InteracUrl,
  "Mastercard": MastercardUrl,
  "Menu Ottawa": MenuOttawaUrl,
  "Paypal": PaypalUrl,
  "Skip": SkipUrl,
  "Sodexo": SodexoUrl,
  "Uber": UberUrl,
  "Visa": VisaUrl,
  "Debitor": DebitorUrl,
}
