import debug from 'debug'
import { useEffect } from 'react'
import type { MangoQuery, RxCollection, RxDocument } from 'rxdb'
import { debounceTime, distinctUntilChanged, map, Subscription, tap } from 'rxjs'

import { convertDocuments, type DocDeepSignal } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils'
import { computed, effectOn, signal, type Accessor } from '@/react/core/reactive'

const log = debug(`dev:signal-data-access`)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParametersExceptFirst<F> = F extends (arg0: any, ...rest: infer R) => any ? R : never

interface Options<T, V> {
  auto?: boolean
  convertOpts?: {
    autoSave: boolean
    omitList?: string[]
  } & ParametersExceptFirst<typeof convertDocuments<T, V>>[2]
  onFirstDataAvailable?: (data: Array<RxDocument<T, V>>, converted: Array<DocDeepSignal<T, V>>) => void
  /** RxDb Query */
  rxQuery?: Accessor<MangoQuery<T>>
  /** Filter applied in the final result */
  outputFilter?: (value: RxDocument<T, V>, index: number) => boolean
}

interface AutomatedOptions<T, V> extends Options<T, V> {
  auto: true
}
export function generateSignalDataAccess<T, V>(collection: RxCollection<T, V>, opts: AutomatedOptions<T, V>): readonly [data: Accessor<DocDeepSignal<T, V>[]>]
export function generateSignalDataAccess<T, V>(collection: RxCollection<T, V>, opts?: Options<T, V>): readonly [data: Accessor<DocDeepSignal<T, V>[]>, makeDataAvailable: () => void]

/** Generate a Signal & a load-data-effect for loading data from a RxDb Collection */
export function generateSignalDataAccess<T, V>(collection: RxCollection<T, V>, opts: Options<T, V> = {}): readonly [data: Accessor<DocDeepSignal<T, V>[]>, makeDataAvailable?: () => void] {
  let firstTime = true
  const [data, setData] = signal<Array<DocDeepSignal<T, V>>>([])
  const query = computed(() => {
    const rxQuery = opts.rxQuery?.()
    if (!firstTime) log(collection.name, '🔗 New query:', rxQuery)
    return collection.find(rxQuery)
  })
  let subscription: Subscription | null = null

  const populateData = (val: Array<RxDocument<T, V>>) => {
    log(collection.name, '⚡️ Populating data...')
    const { autoSave = false, omitList, ...convertOpt } = opts.convertOpts ?? {}
    const converted = convertDocuments(val, autoSave, omitList, convertOpt)
    if (firstTime) {
      log(collection.name, '🚀 First data available', val)
      opts.onFirstDataAvailable?.(val, converted)
      firstTime = false
    }
    setData(converted)
  }
  const bindData = () => {
    if (firstTime) log('🔗 Binding data...')
    else log(collection.name, '🔗 New query detected, re-binding data...')
    subscription?.unsubscribe()
    subscription = query()
      .$.pipe(
        map(a => (opts?.outputFilter ? a.filter(opts.outputFilter) : a)),
        tap(populateData)
      )
      .subscribe()
  }

  if (opts?.auto) {
    dataLock.acquireAsync().then(() => effectOn([query], bindData))
    return [data]
  }

  const makeDataAvailable = () => {
    useEffect(() => {
      dataLock.acquireAsync().then(() => effectOn([query], bindData))
      return () => subscription?.unsubscribe()
    }, [])
  }

  return [data, makeDataAvailable]
}

interface DataCountOptions {
  debounce?: number
}

/**
 * Generate a Signal & a load-data-effect for counting total rows of data from a RxDb Collection
 *
 * Useful for displaying total data count
 *
 * @example const [count, makeCountAvailable] = generateSignalDataCount(collection)
 */
export function generateSignalDataCount(collection: RxCollection, opt: DataCountOptions = {}): readonly [count: Accessor<number>, makeCountAvailable: () => void] {
  const [count, setCount] = signal(0)
  let subscription: Subscription | null = null

  const makeCountAvailable = () => {
    useEffect(() => {
      dataLock.acquireAsync().then(() => {
        subscription = collection
          .count()
          .$.pipe(
            distinctUntilChanged(), // Ensure only unique values are passed
            debounceTime(opt.debounce ?? 0), // Debounce to prevent multiple updates
            tap(setCount)
          )
          .subscribe()
      })
      return () => subscription?.unsubscribe()
    }, [])
  }

  return [count, makeCountAvailable]
}
