import type { RxCollection, RxDatabase } from 'rxdb'

import { Product, PRODUCT_COLLECTION_NAME, productSchema } from './Product'
import { registerSyncHook } from './Product.sync-hook'
import { createCollection } from './utils/migration'

export async function createProductCollection<T>(database: RxDatabase<T>): Promise<RxCollection<Product>> {
  const collection = await createCollection({
    database: database,
    collection: Product,
    collectionName: PRODUCT_COLLECTION_NAME,
    version: productSchema.version,
    schema: productSchema,
  })

  registerSyncHook(collection)

  return collection
}
