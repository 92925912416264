import _ from "lodash";
import ripple from "ripple-effects";
import { useSwipeHandlers } from "@/react/core/handleTouch";
import { stop0 } from "@/shared/utils";
import {CSSProperties, HTMLAttributes, memo, useEffect, useMemo, useRef} from "react";
import { BoxProps } from "@/react/core/BoxProps";

export function cssUnit(prop: string, x?: string | number) {
	if (typeof x === "number") {
		return { [prop]: `${x}px` };
	} else if (typeof x === "string") {
		return { [prop]: x };
	}
	return {};
}

export function cssUnitArray(prop: string, vals?: Array<number>) {
	if (vals) {
		return { [prop]: `${vals[0]}px ${vals[1]}px ${vals[2]}px ${vals[3]}px` };
	}
	return {};
}

export interface SwipeableProps {
	onSwipeLeft?: () => void,
	onSwipeRight?: () => void,
	onTap?: () => void,
}

export const Box = memo((props: BoxProps & SwipeableProps & HTMLAttributes<any>) => {
	const style = useMemo<CSSProperties>(() => {
		let lineColor;
		if (props.lineColor) {
			lineColor = props.lineColor;
		} else if (props.selected) {
			lineColor = props.selectedColor || "#3487e7";
		} else if (props.debug) {
			lineColor = "#C9CBCC";
		}

		const style0 = {
			...lineColor && {
				border: `1px solid ${lineColor}`
			},
			"flexDirection": "column",
			...cssUnit("width", props.width),
			...cssUnit("height", props.height),
			...cssUnit("minHeight", props.minHeight),
			...cssUnit("top", props.y || props.top),
			...cssUnit("left", props.x || props.left),
			...cssUnit("bottom", props.bottom),
			...cssUnit("right", props.right),
			...cssUnitArray("padding", props.padding),
			...cssUnit("paddingLeft", props.paddingLeft),
			...cssUnit("paddingRight", props.paddingRight),
			...cssUnit("paddingTop", props.paddingTop),
			...cssUnit("paddingBottom", props.paddingBottom),
			...cssUnitArray("margin", props.margin),
			...cssUnit("marginLeft", props.marginLeft),
			...cssUnit("marginRight", props.marginRight),
			...cssUnit("marginTop", props.marginTop),
			...cssUnit("marginBottom", props.marginBottom),
			...cssUnitArray("border", props.border),
			...cssUnit("borderLeft", props.borderLeft),
			...cssUnit("borderRight", props.borderRight),
			...cssUnit("borderTop", props.borderTop),
			...cssUnit("borderBottom", props.borderBottom),
			...cssUnit("gap", props.gap || props.flexGap),
			...cssUnit("rowGap", props.gapRow),
			...cssUnit("columnGap", props.gapCol),
			...cssUnit("flexDirection", props.flexDirection),
			...cssUnit("flexWrap", props.flexWrap),
			// ...cssUnit('flex-grow', props.flexGrow),
			...props.flexGrow !== undefined && {
				"flexGrow": props.flexGrow
			},
			...cssUnit("flexShrink", props.flexShrink),
			...cssUnit("flexBasis", props.flexBasis),
			...cssUnit("justifyContent", props.justifyContent),
			...cssUnit("alignItems", props.alignItems),
			...props.center && {
				"justifyContent": "center",
				"alignItems": "center"
			},
			...cssUnit("position", props.position),
			...cssUnit("overflow", props.overflow),
			...props.display === false && {
				display: "none"
			},
			...cssUnit("lineWidth", props.lineWidth),
			...cssUnit("opacity", props.alpha),
			...props.visible === false && {
				display: "none"
			},
			...props.fill !== undefined && {
				background: props.fill
			},
			...props.alpha !== undefined && {
				opacity: props.alpha
			},
			...cssUnit("borderRadius", props.radius),
			...props.gradient !== undefined && {
				background: "linear-gradient(180deg, #FFFFFF 0%, #F8F7F7 100%)"
			}
		} as CSSProperties;

		return props.style ? _.merge({}, props.style, style0) : style0;
	}, []);
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (props.ripple && ref.current) {
			if (typeof props.ripple === "boolean") {
				ripple(ref.current!, {
					background: "#ff854f",
					opacity: 0.7
				});
			} else {
				ripple(ref.current!, {
					duration: props.ripple[0],
					background: props.ripple[1],
					opacity: 0.7
				});
			}
		}
	}, []);
	let events = {};
	if (props.onTap || props.onSwipeLeft || props.onSwipeRight) {
		events = useSwipeHandlers(props.onSwipeLeft, props.onSwipeRight, props.onTap);
	}

	return <div style={style} id={props.id} className={`flex ${props.className || ''}`}
		ref={ref}
		{...events}
		{...props.onClick && { onClick: stop0(props.onClick) }}
		onPointerDown={stop0(props.onPointerDown)}>{props.children}</div>;
});

export default Box;
export const Container = Box;
