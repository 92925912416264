import { FunctionComponent } from "react";
import { importProductLayouts } from '@/react/SyncMenuView/SyncMenuView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

const RightItemList: FunctionComponent = () => {
  return (
    <div className="self-stretch flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start text-left text-sm text-black-solid-black-880-1d1d26 font-mulish">
      { importProductLayouts().map(importLayout => {
          const {_id, id, name} = importLayout.product;

          return (<div className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-[13px] px-[9px] even:bg-gray-solid-gray-165-f8f8f8"
                     key={_id}
                  >
                    <div className="self-stretch flex-1 relative leading-[15px] font-semibold">
                      {id}. {name}
                    </div>
                  </div>)
        }
      )}
      { importProductLayouts().length === 0 &&
      <div className="!flex self-stretch hidden flex-row items-center justify-center pt-3.5 px-0 pb-0 text-center text-mini">
        <div className="flex-1 relative">{LL0().order.selectItemsFromTheLeftSide()}.</div>
      </div>
      }
    </div>
  );
};

export default RightItemList;
