import { convertDocument, convertDocuments } from "@/data/data-utils.ts";
import { OrderLayout } from "@/data/OrderLayout.ts";
import _ from "lodash";
import { ProductLayout } from "@/data/ProductLayout.ts";
import { Product } from "@/data/Product.ts";
import { products0 } from "@/data/ProductHub.ts";
import delay from "delay";
import { PaidOrder } from "@/data/Order.ts";
import { OrderStatus } from "@/pos/OrderType.ts";
import { Eod } from "@/data/Eod.ts";
import { clearEodCache } from "@/pos/logic/order-utils.ts";
import { posSetting0 } from "../PosSettingsSignal";


export function storeOrderData() {
  return {
    changeStatus,
    deleteEod,
    fixPaymentWebshop,
    changeEodStatus
  }

  async function deleteEod() {
    const eod =  await Eod.findOne({selector: {_id: '43m8zrdsj9gf'}}).exec();
    if (eod) await eod.remove();
    await clearEodCache()
  }


  async function changeEodStatus() {
    const _eod = await Eod.findOne({selector: {_id: '63mdiyg1v708'}}).exec();
    if (_eod) {
      const eod = convertDocument(_eod, true);
      eod.complete = false;
    }
  }


  async function fixPaymentWebshop() {
    const _order = await PaidOrder.findOne({selector: {_id: '33mdm3f1959q'}}).exec();
    if (_order) {
      const order = convertDocument(_order, true);
      order.payments[0].extraType = 'cash';
      order.payments[0].type = 'bar'
    }
    await clearEodCache();
  }

  async function changeStatus() {
    const _cancelOrders = await PaidOrder.find({selector: {status: OrderStatus.CANCELLATION_REFERENCE}}).exec();
    const cancelOrders = convertDocuments(_cancelOrders!, true);
    const mapIds = cancelOrders?.map(p => p.refOrder);

    const _originalOrders = await PaidOrder.find({
      selector: {
        _id: { $in: mapIds },
        status: { $nin: [OrderStatus.CANCELLED, OrderStatus.REFUNDED] }
      }
    }).exec()

    const originalOrders = convertDocuments(_originalOrders!, true);

    console.log(originalOrders.length)

    if (originalOrders.length > 0) {
      for (const order of originalOrders) {
        if (order.status === OrderStatus.PAID) {
          order.status = OrderStatus.CANCELLED;
        }
      }
    }
  }
}

export function fixTaxAndItem() {
  return {

  }
  async function findWrongOrder() {
    const _order = await PaidOrder.find(
      {
        selector:
          {
            vDate: {
              $gte: 1722470400
            }
          }
      }).exec();
  }
  if (_order) {
    console.log('order number', order?.length)
  }
}

async function fix5() {
  // let api = storeOrderData();
  // let api = storeOrderData();
  // // await api.changeStatus()
  // // await api.deleteEod();
  // await api.changeEodStatus();
  // await api.fixPaymentWebshop();
  // await api.fixRoomObjects();

  let api = fixTaxAndItem();
}

// @ts-ignore
window.fix5 = fix5;
