import type { RxCollection, RxDatabase } from 'rxdb'

import { CASHBOOK_HISTORIES_COLLECTION_NAME, CASHBOOK_TRANSACTIONS_COLLECTION_NAME, CashbookHistories, cashbookHistoriesSchema, CashbookTransactions, cashbookTransactionsSchema } from './Cashbook'
import { createCollection } from './utils/migration'

export const createCashbookHistoriesCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<CashbookHistories>> => {
  return await createCollection({
    database,
    collection: CashbookHistories,
    collectionName: CASHBOOK_HISTORIES_COLLECTION_NAME,
    version: cashbookHistoriesSchema.version,
    schema: cashbookHistoriesSchema,
  })
}

export const createCashbookTransactionsCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<CashbookTransactions>> => {
  return await createCollection({
    database,
    collection: CashbookTransactions,
    collectionName: CASHBOOK_TRANSACTIONS_COLLECTION_NAME,
    version: cashbookTransactionsSchema.version,
    schema: cashbookTransactionsSchema,
  })
}
