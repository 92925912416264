import EmailReportPlugin from "@email-report/EmailReportPlugin.tsx";
import {memo} from "react";

export type EmailReportHistoryProps = {
  time: string,
  email: string,
  reportType: string,
  reportTime: string,
  status: string,
  notes: string,
}

export const EmailHistoryData: EmailReportHistoryProps[] = [
  {
    time:'2023-10-20 11:38 PM',
    email:'example@gmail.com',
    reportType: 'Daily',
    reportTime:'2023-10-20',
    status: 'fail',
    notes: 'No recipient'
  },
  {
    time:'2023-10-21 16:38 PM',
    email:'user09213@gmail.com',
    reportType: 'Daily',
    reportTime:'2023-10-21',
    status: 'fail',
    notes: 'No recipient'
  },
  {
    time:'2023-10-22 13:38 PM',
    email:'example8812@gmail.com',
    reportType: 'Daily',
    reportTime:'2023-10-22',
    status: 'fail',
    notes: 'No recipient'
  },
]

const EmailReportView = () => {
  return (
    <EmailReportPlugin/>
  )
}

export default memo(EmailReportView)