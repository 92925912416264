import {type FunctionComponent, memo, useState} from "react";
import ProductEditingForm from "./ProductEditingView/ProductEditingForm";
import EditOnlineMenuTopNav from "./EditOnlineMenuTopNav";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {editingProduct, VIEW__PRODUCT} from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic.ts";
import PortalPopup from "@edit-online-menu/Popup/PortalPopup.tsx";
import PopupDeleteConfirmation from "@edit-online-menu/Popup/PopupDeleteConfirmation.tsx";

const ProductEditingView: FunctionComponent = () => {

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    <>
      <div
        className="edit-product-screen bg-gray-solid-gray-120-f8f9ff w-full overflow-hidden flex flex-col items-start justify-start h-full gap-[0px] text-center text-lg text-white-solid-white-100-ffffff font-mulish-button-name-bold hover:bg-gray-solid-gray-120-f8f9ff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start">
        <div
          className="self-stretch bg-blue-solid-blue-700-1b2b65 flex flex-row items-center justify-between py-1.5 px-5 md:pl-4 md:pr-4 md:box-border sm:pl-3.5 sm:pr-3.5 sm:box-border mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2.5 mq600:pr-2.5 mq600:box-border mq480:pl-2.5 mq480:pr-2.5 mq480:box-border height_414:pl-2.5 height_414:pr-2.5 height_414:box-border">
          <div
            className="h-11 flex flex-row items-center justify-start gap-[12px] lg:gap-[10px] md:h-10 md:gap-[8px] sm:h-9 sm:gap-[6px] mq768:h-8 mq768:gap-[4px] mq600:h-8 mq600:gap-[2px] mq480:h-8 mq480:gap-[2px]">
            <div
              className="self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-center py-0 px-5 lg:gap-[12px] md:h-10 md:gap-[6px] md:py-2 md:px-4 md:box-border sm:h-9 sm:gap-[4px] sm:py-1.5 sm:px-2.5 sm:box-border mq768:h-8 mq768:gap-[4px] mq768:rounded-3xs mq768:pt-1 mq768:pb-1 mq768:box-border mq600:h-8 mq480:h-8"
              ref={makeRipple}
              onClick={VIEW__PRODUCT.onCreateMore}
            >
              <b
                className="relative flex font-bold hover:font-bold hover:font-mulish-button-name-bold hover:text-lg hover:text-center hover:text-white-solid-white-100-ffffff hover:flex lg:text-base md:text-sm sm:text-smi mq768:text-xs mq600:text-2xs mq480:text-2xs">
                +{LL0().inventory.newComboItem()}
              </b>
            </div>
            <div
              className="self-stretch rounded-3xs [background:linear-gradient(rgba(253,_31,_155,_0.1),_rgba(253,_31,_155,_0.1)),_#fff] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-center py-0 px-5 gap-[12px] text-red-solid-red-400-d93b5c lg:gap-[12px] md:h-10 md:gap-[6px] md:py-2 md:px-4 md:box-border sm:h-9 sm:gap-[4px] sm:py-1.5 sm:px-2.5 sm:box-border mq768:h-8 mq768:gap-[4px] mq768:rounded-3xs mq768:pt-1 mq768:pb-1 mq768:box-border mq600:h-8 mq480:h-8"
              ref={makeRipple}
              onClick={() => setShowConfirmDelete(true)}
            >
              <img
                className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] mq768:w-5 mq768:h-5 mq600:w-5 mq600:h-5 mq480:w-5 mq480:h-5"
                alt=""
                src="/iconmenubin-icon@2x.png"
              />
              <b
                className="relative flex font-bold hover:font-bold hover:font-mulish-button-name-bold hover:text-lg hover:text-center hover:text-red-solid-red-400-d93b5c hover:flex lg:text-base md:text-sm sm:text-smi mq768:text-xs mq600:text-2xs mq480:text-2xs">
                {LL0().ui.delete()}
              </b>
            </div>
          </div>
          <div
            className="h-11 flex flex-row items-center justify-start lg:gap-[10px] md:h-10 md:gap-[8px] sm:h-9 sm:gap-[6px] mq768:h-8 mq768:gap-[4px] mq600:h-8 mq600:gap-[2px] mq480:h-8 mq480:gap-[2px]">
            <div
              className="self-stretch rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-center py-0 px-5 gap-[12px] lg:gap-[12px] md:h-10 md:gap-[6px] md:py-2 md:px-4 md:box-border sm:h-9 sm:gap-[4px] sm:py-1.5 sm:px-2.5 sm:box-border mq768:h-8 mq768:gap-[4px] mq768:rounded-3xs mq768:pt-1 mq768:pb-1 mq768:box-border mq600:h-8 mq480:h-8"
              ref={makeRipple}
              onClick={VIEW__PRODUCT.onEndEditing}
            >
              <img
                className="relative w-6 h-6 object-cover"
                alt=""
                src="/iconback-to-list-icon1@2x.png"
              />
              <b
                className="relative flex font-bold hover:font-bold hover:font-mulish-button-name-bold hover:text-lg hover:text-center hover:text-white-solid-white-100-ffffff hover:flex lg:text-base md:text-sm sm:text-smi mq768:text-xs mq600:text-2xs mq480:text-2xs">
                {LL0().article.save()}
              </b>
            </div>
          </div>
        </div>
        {/*<EditOnlineMenuTopNav />*/}
        <ProductEditingForm/>
      </div>
      {showConfirmDelete &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setShowConfirmDelete(false)}
        >
          <PopupDeleteConfirmation
            message={`${LL0().editOnlineMenu.doYouWantToDelete()} "${editingProduct()?.name}"?`}
            onClose={() => setShowConfirmDelete(false)}
            onConfirmed={() => (VIEW__PRODUCT.onDeleteCurrent(), setShowConfirmDelete(false))}
          />
        </PortalPopup>
      }
    </>
  );
};

export default memo(ProductEditingView);
