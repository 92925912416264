import debug from 'debug'
import IsomorphicWebSocket from 'isomorphic-ws'
import WebSocket from 'reconnecting-websocket'

import { Identify } from '@/react/websocket/ws-register.ts'
import { wsOpenLock } from '@/react/websocket/ws-state.ts'
import type { WsMessage } from '@/react/websocket/ws-types.ts'
import { getDeviceId } from '@/shared/getDeviceId'

import { mainDisplayIp } from '../Installation/SecondDisplayConfig.state'

const log = debug('ws')

// const urlParams = new URLSearchParams(window.location.search);
const ip = mainDisplayIp() || /*urlParams.get('ip') || */ 'localhost'
// let ip = '192.168.1.4';
export const ws = new WebSocket(`ws://${ip}:8080`, [], {
  WebSocket: IsomorphicWebSocket,
  ...(import.meta.env.MODE !== 'development' && {
    maxReconnectionDelay: 1000,
  }),
  // connectionTimeout: 1000,
  // maxRetries: 1000,
})

ws.addEventListener('open', function () {
  log('connected')
  console.log('⚡️ Socket connected, registering...')
  const msg: WsMessage = { ns: 'register', type: Identify.POS, deviceId: getDeviceId() }
  ws.send(JSON.stringify(msg))

  wsOpenLock.release().then()
})

ws.addEventListener('close', function () {
  log('disconnected')
})

ws.onerror = function (e) {
  log(`error + ${e.message}`)
}

// ws.onmessage = function incoming(data) {
//   log(`message: ${data.data}`);
// }

// ws.onmessage = function incoming(data) {
//   const str = '{' + JSON.parse(data.data as string).data.split('{')[1]
//   console.log(`message: ${str}`);
//
//   // setTimeout(function timeout() {
//   //   ws.send(Date.now());
//   // }, 500);
// };
