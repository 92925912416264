import type { RxConflictHandler, RxConflictHandlerInput, RxConflictHandlerOutput } from "rxdb/src/types";
import { stripAttachmentsDataFromDocument } from "rxdb";
import { deepEqual } from "rxdb/plugins/utils";
import _ from "lodash";

export const conflictHandler: RxConflictHandler<any> = function (
  i: RxConflictHandlerInput<any>,
  _context: string
): Promise<RxConflictHandlerOutput<any>> {
  const newDocumentState = stripAttachmentsDataFromDocument(i.newDocumentState);
  const realMasterState = stripAttachmentsDataFromDocument(i.realMasterState);

  // if (['couchdb-push-1', 'rxStorageInstanceToReplicationHandler-masterWrite'].includes(_context)) {
  //   if (deepEqual(
  //     newDocumentState,
  //     realMasterState
  //   )) {
  //     return Promise.resolve({
  //       isEqual: true
  //     });
  //   }
  //   return Promise.resolve({
  //     isEqual: false,
  //     documentData: i.realMasterState
  //   });
  // }

  if (_context === 'replication-resolve-conflict') {
    //merge realMasterState vs newDocumentState
    if (realMasterState._deleted) {
      return Promise.resolve({
        isEqual: false, documentData: i.realMasterState
      });
    } else if ((newDocumentState.updatedAt || 0) > (realMasterState.updatedAt || 0)) {
      return Promise.resolve({
        isEqual: false, documentData: i.realMasterState
      });
    } else {
      return Promise.resolve({
        isEqual: false, documentData: i.realMasterState
      });
    }
  }
  // neu updatedAt cua realMasterState < newDocumentState -> return true
  /**
   * If the documents are deep equal,
   * we have no conflict.
   * On your custom conflict handler you might only
   * check some properties, like the updatedAt time,
   * for better performance, because deepEqual is expensive.
   */
  if (deepEqual(
    newDocumentState,
    realMasterState
  )) {
    return Promise.resolve({
      isEqual: true
    });
  }

  // if (_context === 'downstream-check-if-equal-1') {
  //   if ((realMasterState.updatedAt || 0) >= (newDocumentState.updatedAt || 0)) {
  //     return Promise.resolve({
  //       isEqual: false,
  //       documentData: i.realMasterState
  //     });
  //   } else {
  //     return Promise.resolve({
  //       isEqual: true
  //     });
  //   }
  // }
  //
  // if (['couchdb-push-1', 'rxStorageInstanceToReplicationHandler-masterWrite'].includes(_context)) {
  //   if ((newDocumentState.updatedAt || 0) >= (realMasterState.updatedAt || 0)) {
  //     return Promise.resolve({
  //       isEqual: true
  //     });
  //   } else {
  //     return Promise.resolve({
  //       isEqual: false,
  //       documentData: i.realMasterState
  //     });
  //   }
  // }
  //
  // if (_context === 'upstream-check-if-equal') {
  //   if ((newDocumentState.updatedAt || 0) > (realMasterState.updatedAt || 0)) {
  //     return Promise.resolve({
  //       isEqual: false,
  //       documentData: i.realMasterState
  //     });
  //   } else {
  //     return Promise.resolve({
  //       isEqual: true
  //     });
  //   }
  // }

  /**
   * The default conflict handler will always
   * drop the fork state and use the master state instead.
   */
  return Promise.resolve({
    isEqual: false,
    documentData: i.realMasterState
  });
};