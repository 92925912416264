import Queue from 'queue';
import axios from "axios";
import { captureException } from "@sentry/react";

const queue = new Queue({autostart: true, concurrency: 1});

let inited = false;

let ip = import.meta.env.MODE === 'development' ? '192.168.1.6': 'localhost';
console.log('my mode: ',import.meta.env.MODE);

//for sunmi
export const printIntegrate = (task: { data: Buffer }) => {
  queue.push(async () => {
    let count = 0;
    while (count < 10) {
      try {
        const data = task.data.toString('base64');
        if (!inited) {
          await axios.post(`http://${ip}:5005/init-sunmi-printer`, {});
          inited = true;
        }
        await axios.post(`http://${ip}:5005/print-to-sunmi`, {data});
        break;
      } catch (e: any) {
        captureException(new Error(`print to integrated: error ${e.message}`), { tags: { type: 'print' } });
        console.error(`print to integrated: error`, e);
        //todo: emit to virtual printer
      } finally {
        count++;
      }
    }
  })
}
