import {missedCalls0} from '@/data/CallHub';
import {type FunctionComponent, useEffect, useState} from 'react';
import type {Customer} from '@/data/Customer.ts';
import type {Call} from '@/data/Call.ts';
import type {RxDocument} from 'rxdb';
import dayjs from 'dayjs';
import {onAcceptOrderCall} from '@/react/NewDeliveryView/NewDeliveryView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";
import { companyInfo0, generalSetting0 } from "@/data/PosSettingsSignal.ts";

export type MissedCallComponentProps = {
  missedCall: RxDocument<Call, {}>
  onClose ?: () => void
}

const MissedCallComponent = ({ missedCall, onClose }: MissedCallComponentProps) => {
  const [customer, setCustomer] = useState<RxDocument<Customer, {}> | undefined>();
  useEffect(() => {
    const fetchCustomer = async () => {
      setCustomer(await  missedCall.populate("customerId"))
    }
    fetchCustomer();
  }, []);

  return (
    <div className="self-stretch flex flex-row items-center justify-start gap-[5px]">
      <div className="hidden flex-row items-center justify-start gap-[6px]">
        <div className="w-[25px] h-[25px] overflow-hidden shrink-0 flex flex-row items-center justify-center">
          <img
            className="w-[20.3px] relative h-[25px] object-cover"
            alt=""
            src="/new-delivery-cancel-1@2x.png"
          />
        </div>
        <div className="w-0.5 relative box-border h-[34px] border-r-[2px] border-solid border-gray-200" />
      </div>
      <img
        className="w-8 relative h-8 object-cover"
        alt=""
        src="/iconbig-red-bin-icon@2x.png"
      />
      <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
        <b className="self-stretch relative">{customer?.name} - {customer?.phoneNumber}</b>
        <div className="relative text-xs text-gray-solid-gray-725-707070">
          {companyInfo0()?.backendLanguage === 'de' ? dayjs.unix(missedCall.date || 0).format("HH:mm") :  dayjs.unix(missedCall.date || 0).format("hh:mmA")}
        </div>
      </div>
      <div className="w-32 rounded-md box-border h-9 flex flex-row items-center justify-center p-2.5 text-center text-mini text-blue-solid-blue-500-0051c1 border-[1px] border-solid border-blue-solid-blue-420-2979ff"
           onClick={() => {
             onClose?.();
             onAcceptOrderCall(missedCall, customer)
           }}
      >
        <b className="relative">{LL0().delivery.order()}</b>
      </div>
    </div>
  )
}

type NewDeliveryMissedCallPopupType = {
  onClose?: () => void;
};

const NewDeliveryMissedCallPopup: FunctionComponent<
  NewDeliveryMissedCallPopupType
> = ({ onClose }) => {
  return (
    <div className="w-full relative rounded bg-white-solid-white-100-ffffff flex flex-col items-center justify-center pt-8 px-5 pb-4 box-border gap-[8px] min-w-[398px] max-w-full max-h-full overflow-auto text-left text-sm text-gray-solid-gray-725-707070 font-mulish">
      <i className="self-stretch relative [text-decoration:underline] font-medium z-[0]">
        {LL0().delivery.missed()}:
      </i>
      { missedCalls0().length ?
      <div className="self-stretch flex flex-col items-start justify-start gap-[13px] z-[1] text-black-solid-black-600-424242">
        { missedCalls0().map(missedCall => (
          <MissedCallComponent missedCall={missedCall}
                               key={missedCall._id}
                               onClose={onClose}
          />
        )) }
      </div>
     :
      <i className="self-stretch relative text-xs text-gray-solid-gray-460-9ca3af z-[3]">
        {LL0().delivery.noMissedCalls()}
      </i>
      }
      <img
        className="w-[22px] absolute !m-[0] top-[15px] right-[15px] h-[22px] object-cover cursor-pointer z-[2]"
        alt=""
        src="/icongeneralclose-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default NewDeliveryMissedCallPopup;
