import { Delete, Download } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import _ from 'lodash'
import React, { useMemo } from 'react'

import { usePemDownload } from '@/lib/utils'
import { LL0 } from '@/react/core/I18nService.tsx'
import { getCertificateInfo } from '@/srm/lib/utils.cert'

export default function CertificateInfoCell({ cert, download, onDelete }: { cert?: string; download: string; onDelete?: () => void }) {
  const info = useMemo(() => getCertificateInfo(cert), [cert])
  const link = usePemDownload(cert)

  return info ? (
    <div className="grid grid-cols-[1fr_2fr] gap-2 text-black">
      <p>{LL0().settings.userSettings.subject()}</p>
      <code className="grid grid-cols-[max-content_max-content_1fr] gap-2">
        {Object.entries(info?.subject).map(([k, v], index) => (
          <React.Fragment key={k}>
            <div className="text-stone-400">{k}</div>
            <div className="text-blue-300">=</div>
            <div data-testid={`installation.srm.certificate.${k}`}>{v}</div>
          </React.Fragment>
        ))}
        {/*<p className="break-all">{info.subject}</p>*/}
      </code>
      <p>{LL0().settings.userSettings.serialNumber()}</p>
      <code
        className="break-all"
        data-testid="installation.srm.certificate.serialNumber"
      >
        {info.serialNumber}
      </code>
      <p>{LL0().settings.userSettings.validUntil()}</p>
      <p>{info.notafter}</p>
      <p>{LL0().settings.userSettings.download()}</p>
      <div>
        <a
          href={link ?? '#'}
          download={download}
          className="text-blue-500"
        >
          <Download /> {download}
        </a>
      </div>
      {onDelete && (
        <>
          <p>{LL0().settings.userSettings.remove()}</p>
          <div>
            <IconButton
              color="error"
              onClick={onDelete}
              data-testid="installation.srm.deleteBtn"
            >
              <Delete />
            </IconButton>
          </div>
        </>
      )}
    </div>
  ) : (
    <p className="text-black italic">N/A - {LL0().settings.userSettings.pleaseEnrollFirst()}</p>
  )
}
