import TextField from "@/react/core/TextField.tsx";
import {setTerminal0, terminal0} from "@/react/PaymentSettingView/PaymentSettingView.tsx";

export default function CCVA920TerminalConfiguration() {
  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">IP</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata?.host || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.host = e.target.value;
            return prev;
          })}
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">Port</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata?.port || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            prev.metadata.port = e.target.value;
            return prev;
          })}
        />
      </div>
    </>
  )
}