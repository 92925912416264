import { FunctionComponent, useState, useCallback } from "react";
import NewDeliveryAddEditCustomer from "./NewDeliveryAddEditCustomer";
import PortalPopup from "./PortalPopup";
import {
  customer0,
  NewDeliveryScreenMode,
  onEnterNewDelivery,
  setNewDeliveryScreenMode
} from "@/react/NewDeliveryView/NewDeliveryView";
import {LL0} from "@/react/core/I18nService.tsx";

const NewDeliveryCustomerInfo: FunctionComponent = () => {
  const [isNewDeliveryAddEditCustomerOpen, setNewDeliveryAddEditCustomerOpen] =
    useState(false);

  const openNewDeliveryAddEditCustomer = useCallback(() => {
    setNewDeliveryScreenMode(NewDeliveryScreenMode.CUSTOMER);
    // setNewDeliveryAddEditCustomerOpen(true);
  }, []);

  const closeNewDeliveryAddEditCustomer = useCallback(() => {
    setNewDeliveryAddEditCustomerOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-row items-start justify-end pt-0 px-0 pb-2 gap-[0px_8px] text-left text-xs text-blue-solid-blue-370-0094ff font-mulish border-b-[1px] border-solid border-gray-solid-gray-495-c1c1c1">
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
          <div className="self-stretch flex flex-row items-start justify-start sm:flex-col sm:gap-[0px_4px]">
            <div className="flex-1 relative leading-[15px] sm:text-xs sm:flex-[unset] sm:self-stretch">
              <b>
                <span>{LL0().onlineOrder.customer()}:</span>
              </b>
              <span className="text-black-solid-black-880-1d1d26">
                <b>{` `}</b>
                <span>{customer0()?.name}, {customer0()?.phoneNumber}</span>
              </span>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start text-black-solid-black-880-1d1d26 sm:flex-col sm:gap-[0px_4px]">
            <div className="flex-1 relative leading-[14px] sm:text-xs sm:flex-[unset] sm:self-stretch">
              <p className="m-0">
                <b className="font-mulish">
                  <span className="text-blue-solid-blue-370-0094ff">
                    {LL0().onlineOrder.address()}:
                  </span>
                </b>
                <span>
                  <b className="font-mulish">{` `}</b>
                  <span>{customer0()?.addresses?.[customer0()?.defaultAddressIndex || 0]?.street}{" "}
                    {customer0()?.addresses?.[customer0()?.defaultAddressIndex || 0]?.home}</span>
                </span>
              </p>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start sm:flex-col sm:gap-[0px_4px]">
            <div className="flex-1 relative leading-[16px] sm:text-xs sm:flex-[unset] sm:self-stretch">
              <b>
                <span>{LL0().onlineOrder.note()}:</span>
              </b>
              <span className="text-black-solid-black-880-1d1d26">
                <b>{` `}</b>
                <span>{customer0()?.note}</span>
              </span>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-start justify-center pt-1.5 px-0 pb-0 cursor-pointer"
          onClick={openNewDeliveryAddEditCustomer}
        >
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/iconnew-deliveryedit-customer-icon@2x.png"
          />
        </div>
      </div>
      {isNewDeliveryAddEditCustomerOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewDeliveryAddEditCustomer}
        >
          <NewDeliveryAddEditCustomer
            onClose={closeNewDeliveryAddEditCustomer}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default NewDeliveryCustomerInfo;
