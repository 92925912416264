import type {FunctionComponent} from "react";
import {LL0} from "@/react/core/I18nService.tsx";

export type NewDeliveryCustomerInfoMType = {
  onClose?: () => void;
};

const NewDeliveryCustomerInfoM: FunctionComponent<
  NewDeliveryCustomerInfoMType
> = ({ onClose }) => {
  return (
    <div className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px_0px] min-w-[360px] h-full max-w-full max-h-full text-center text-[20px] text-red-solid-red-550-cf2222 font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <b className="self-stretch relative leading-[28px]">
          {LL0().delivery.missingPhoneNumber()}
        </b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-black-solid-black-880-1d1d26">
          {LL0().delivery.fillInValidPhone()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center max-w-[312px] text-sm text-white-solid-white-100-ffffff" onClick={onClose}>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8">
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default NewDeliveryCustomerInfoM;
