import { openobserveLogs } from '@openobserve/browser-logs'

import { getDeviceId } from '@/shared/getDeviceId'
import { posSync0 } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { servers } from '@/lib/servers.ts'
import { effectOn } from '@/react/core/reactive.ts'

effectOn([server], () => {
  if (server()) {
    const serverName = server()
    openobserveLogs.init({
      clientToken: servers[serverName!].o2.logToken,
      site: servers[serverName!].o2.logSite,
      organizationIdentifier: 'default',
      service: 'pos-frontend',
      env: 'production',
      version: '0.0.1',
      forwardErrorsToLogs: true,
      insecureHTTP: false,
      apiVersion: 'v1',
    })
    openobserveLogs.logger.info('loggerCreated', { server: serverName })
  }
})

effectOn([posSync0], () => {
  if (!!posSync0()) {
    openobserveLogs.addLoggerGlobalContext('storeId', posSync0()?.id)
    openobserveLogs.addLoggerGlobalContext('deviceId', getDeviceId())
    openobserveLogs.addLoggerGlobalContext('appVersion', import.meta.env.VITE_APP_VERSION)
    //@ts-ignore
    openobserveLogs.logger.info(
      `Heap limit: ${JSON.stringify({
        //@ts-ignore
        totalJsHeapSize: window.performance?.memory?.totalJSHeapSize,
        //@ts-ignore
        jsHeapSizeLimit: window.performance?.memory?.jsHeapSizeLimit,
      })}`,
      {
        storeId: posSync0()?.id,
        deviceId: getDeviceId(),
      }
    )
  }
})
