import { type FunctionComponent, useCallback, useContext } from 'react';
import OrderItem from './OrderItem';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import { order0 } from '@/react/OrderView/OrderViewShare';

type MoveTablePopupType = {
  showMoveTablePopup?: boolean;
};

const MoveTablePopup: FunctionComponent<MoveTablePopupType> = (
  {
    showMoveTablePopup,
  }) => {
  const {moveEnable} = useContext(ItemFactoryContext);

  if (moveEnable?.()) {
    showMoveTablePopup = true
  }
  const onMoveTableButtonClick = useCallback(async() => {
    if (order0().getMoveOrder!().items.length > 0) {
      router.screen = PosScreen.MOVE_ITEM_TABLE;
      console.log("Order: ", order0(), order0().getMoveOrder!())
    }
  }, []);

  return (
    showMoveTablePopup && (
      <div
        className="!pl-[5%] my-0 mx-[!important] absolute h-[calc(100%_+_1px)] w-full top-[0px] right-[0px] bottom-[-1px] left-[0px] bg-gray-opacity-gray-95-4d5663 flex flex-row items-center justify-end pt-4 pb-6 pr-2 pl-[228px] box-border gap-[12px] z-[2] text-center text-sm font-mulish lg:pl-[180px] lg:box-border md:pl-[180px] md:box-border sm:pl-[150px] sm:box-border Tablet_768:pl-12 Tablet_768:box-border Tablet_600:pl-6 Tablet_600:box-border Mobile_480:pl-10 Mobile_480:box-border mq1440:pl-[228px] mq1440:box-border">
        <div
          className="h-full self-stretch flex-1 rounded-md bg-gray-solid-gray-135-efefef box-border overflow-hidden flex flex-col items-start justify-between pt-3 px-0 pb-0 max-w-[350px] border-[3px] border-solid border-palette-blue-style lg:max-w-[400px] sm:max-w-[400px] mq1440:max-w-[450px]">
          <div className="h-full self-stretch flex-1 flex flex-col items-end justify-start pt-0 px-2 pb-2 gap-[8px]">
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-blue-solid-blue-300-3faaff h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-1 cursor-pointer Tablet_768:h-[30px]"
              onClick={onMoveTableButtonClick}
              ref={makeRipple}
            >
              <img
                className="w-6 relative h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"
                alt=""
                src="/iconmove-table-icon@2x.png"
              />
              <b className=" text-white-solid-white-100-ffffff relative leading-[17px] Tablet_768:text-smi">
                {LL0().order.moveTable()}
              </b>
            </div>
            {order0().getMoveOrder!()?.items.length > 0 ?
              <div
                className="overflow-y-auto no-scrollbar flex-1 h-full self-stretch rounded-md bg-white-solid-white-100-ffffff flex flex-col items-start justify-start p-px border-[1px] border-solid border-gray-solid-gray-400-c9cbcc">
                {order0().getMoveOrder!()?.items.map((item, index) =>
                  <OrderItem
                    orderItem={item}
                    key={index}
                    order={order0().getMoveOrder!()}
                    showAddMinusIcon={false}
                  />)}
              </div>
              :
              <div
                className="self-stretch flex-1 rounded-md bg-white-solid-white-100-ffffff flex flex-row items-start justify-start p-2 text-materialize-grey-darken-3 border-[1px] border-solid border-gray-solid-gray-370-d8d5d5">
                <b className="flex-1 relative">{LL0().order.selectItemsFromTheRight()}</b>
              </div>}
          </div>
        </div>
        <img
          className="w-16 relative h-16 object-cover Tablet_600:w-10 Tablet_600:h-10"
          alt=""
          src="/iconleft-arrow-icon-3@2x.png"
        />
      </div>
    )
  );
};

export default MoveTablePopup;