import { LL3 } from '@/react/core/I18nCurrency'
import { LL0 } from '@/react/core/I18nService'
import { FunctionComponent } from 'react'

type P = {
  totalCashlessSales?: number
  totalCashlessSalesExcludingTip?: number
  totalTip?: number
}

const StaffReportTotalCashlessSales: FunctionComponent<P> = ({ totalCashlessSales, totalCashlessSalesExcludingTip, totalTip }) => {
  const LL = LL0()
  return (
    <div className="self-stretch bg-white-solid-white-100-ffffff flex flex-col items-end justify-start py-2 px-6 gap-[8px] text-left text-sm text-gray font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
      <div className="self-stretch flex flex-row items-center justify-between text-mini text-royalblue-200">
        <b className="flex-1 relative Tablet_768:text-sm Tablet_600:text-smi">{LL.report.totalCashlessSales()}</b>
        <b className="relative text-base text-firebrick text-right Tablet_768:text-mini Tablet_600:text-sm">{totalCashlessSales ? LL3().format.currency(totalCashlessSales) : '-'}</b>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">{LL.report.totalCashlessSalesExcludingTip()}</div>
        <div className="relative text-base text-right Tablet_768:text-smi Tablet_600:text-xs">{totalCashlessSalesExcludingTip ? LL3().format.currency(totalCashlessSalesExcludingTip) : '-'}</div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-6">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">{LL.printing.totalTip()}</div>
        <div className="relative text-base text-right Tablet_768:text-smi Tablet_600:text-xs">{totalTip ? LL3().format.currency(totalTip) : '-'}</div>
      </div>
    </div>
  )
}

export default StaffReportTotalCashlessSales;