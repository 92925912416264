import { FunctionComponent, memo, useState } from 'react';
import SetMasterMachineItem from './SetMasterMachineItem';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import { isMasterDevice } from "@/lib/fetch-master.ts";
import { deviceSettings0 } from "@/data/DeviceSettingSignal.ts";
import { mainScreen } from "@/data/PosSettingsSignal.ts";

const SetMasterMachinePlugin: FunctionComponent = () => {
  const [isSetMasterMachineEditNameOpen, setSetMasterMachineEditNameOpen] =
    useState(false);
  const [isTaxAndPaymentDeleteConfiOpen, setTaxAndPaymentDeleteConfiOpen] =
    useState(false);
  const [isSetMasterMachineEditName1Open, setSetMasterMachineEditName1Open] =
    useState(false);
  const [isTaxAndPaymentDeleteConfi1Open, setTaxAndPaymentDeleteConfi1Open] =
    useState(false);
  const [isSetMasterMachineEditName2Open, setSetMasterMachineEditName2Open] =
    useState(false);
  const [isTaxAndPaymentDeleteConfi2Open, setTaxAndPaymentDeleteConfi2Open] =
    useState(false);
  return (
    <div
      className="master-machine-screen bg-white-solid-white-100-ffffff w-full h-full flex flex-col items-start justify-start gap-[8px] text-left text-mini text-gray-solid-gray-850-767676 font-mulish">
      <div
        className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[50px] flex flex-row items-center justify-between py-0 pr-2 pl-6 box-border sm:h-[42px] Tablet_768:pl-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:box-border height_414:h-[42px]">
        <b className="relative sm:text-sm height_414:text-sm">
          {LL0().masterMachine.setMasterMachine()}:
        </b>
        <div className="flex flex-row items-center justify-start gap-[8px] text-black-solid-black-1000-000000">
          <div
            className="rounded-[30px] bg-purple-solid-purple-100-f5e6fe shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-start py-0 px-2.5 box-border gap-[4px] sm:h-[30px] Tablet_600:h-[26px]">
            <div className="relative w-6 h-6 overflow-hidden shrink-0 sm:w-5 sm:h-5">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/iconreload-icon@2x.png"
              />
            </div>
            <div className="relative sm:text-sm Tablet_600:text-smi">
              {LL0().settings.deviceManager.reloadDevices()}
            </div>
          </div>
          <div
            className="rounded-[100px] bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-center text-sm text-white-solid-white-100-ffffff sm:h-[30px] sm:min-w-[68px] Tablet_600:h-[26px] Tablet_600:pl-2.5 Tablet_600:box-border Tablet_600:min-w-[64px]"
            ref={makeRipple}
            onClick={() => router.screen = mainScreen()}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="relative leading-[17px] hidden">Button</b>
          </div>
        </div>
      </div>
      <div
        className="self-stretch flex flex-col items-start justify-start py-0 px-6 text-base text-black-solid-black-880-1d1d26 sm:pl-6 sm:pr-6 sm:box-border Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
        <div className="relative font-extrabold sm:text-mini Tablet_600:text-mini">
          {LL0().settings.deviceManager.devices()}:
        </div>
      </div>
      <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start">
        {deviceSettings0().map((device, index) => {
          const num = deviceSettings0()
            .slice(0, index)
            .filter(prevDevice => prevDevice?.devicePos === undefined)
            .length;
          return (
            <SetMasterMachineItem
              device={device?.name}
              devicePos={device?.devicePos || num + 1}
              deviceSetting={device}
              isMasterMachine={isMasterDevice(device._id!)}
              isSetAsMasterDevice={!isMasterDevice(device._id!)}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(SetMasterMachinePlugin);
