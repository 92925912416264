import { FunctionComponent, useCallback, useMemo } from 'react'
import { InputKeyboardProps, ShiftState, SpecialKey } from '@/react/core/InputKeyboard.tsx'
import { Accessor, computed, useComputed, useSignal } from '@/react/core/reactive.ts'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import _ from 'lodash'
import { backendLanguage } from '@/data/language.ts'

enum Mode {
  CHAR,
  SYMBOL
}

interface TableManualKeyboardMode extends InputKeyboardProps {
  mode?:Mode
}
const TableManualKeyboard: FunctionComponent<TableManualKeyboardMode> = (props) => {
  let backspaceInterval: ReturnType<typeof setInterval>

  const [shiftState, setShiftState] = useSignal<ShiftState>(ShiftState.NONE)

  const [mode, setMode] = useSignal<Mode>(props.mode || Mode.CHAR)

  const [keyboardLanguage, setKeyboardLanguage] = useSignal<string>(backendLanguage() || 'en')

  const [pressedKey, setPressedKey] = useSignal<string | undefined>('')

  const selectedKeyboardLanguage = useCallback(() => {
    if (keyboardLanguage() === 'de') setKeyboardLanguage('en')
    else setKeyboardLanguage('de')
  }, [keyboardLanguage()])

  const selectedMode = useCallback(() => {
    if (mode() === Mode.CHAR) setMode(Mode.SYMBOL)
    else setMode(Mode.CHAR)
  }, [mode()])

  const convertChar = (char: string) => {
    if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
      return char.toUpperCase()
    }
    return char.toLowerCase()
  }

  const downShift = () => {
    if (shiftState() === ShiftState.SHIFT) {
      setShiftState(ShiftState.NONE)
      return
    }
  }

  const convertWithShift = (char: string) => {
    if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
      downShift()
      return char.toUpperCase()
    }
    return char.toLowerCase()
  }
  let shiftTimeout: ReturnType<typeof setTimeout>
  let charTimeout: ReturnType<typeof setTimeout>

  const specialChars: Array<SpecialKey> = [{
    id: 'shift',
    down() {
      console.log('shift')
      if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
        setShiftState(ShiftState.NONE)
      } else {
        setShiftState(ShiftState.SHIFT)
      }
      shiftTimeout = setTimeout(() => {
        setShiftState(ShiftState.CAPSLOCK)
      }, 500)
    },
    up() {
      console.log('up')
      clearTimeout(shiftTimeout)
    }
  }, {
    id: 'special'
  }, {
    id: 'EN'
  }, {
    id: 'space',
    down: () => {
      handleButton(' ')
    }
  }, {
    id: 'enter',
    down: () => {
      props.onEnter?.()
    }
  }, {
    id: 'backspace',
    down: () => {
      props.inputController?.()?.setFocus(true)
      props.inputController?.()?.setFocusLock(true)
      props.inputController?.()?.backspaceHandle()
      // backspaceInterval = setInterval(() => {
      //   props.inputController?.()?.setFocus(true)
      //   props.inputController?.()?.setFocusLock(true)
      //   props.inputController?.()?.backspaceHandle()
      //   if (props.inputController?.()?.keys().length === 0) {
      //     clearInterval(backspaceInterval);
      //   }
      // }, 200)
    },
    up() {
      clearInterval(backspaceInterval)
    }
  }, {
    id: ',',
    down: () => {
      handleButton(',')
    }
  }, {
    id: '.',
    down: () => {
      handleButton('.')
    }
  }]
  const handleButton = (char: string) => {
    setTimeout(() => {
      props.inputController?.()?.setFocus(true)
      props.inputController?.()?.setFocusLock(true)
      props.inputController?.()?.addKey(convertWithShift(char))
    }, 20)
  }
  const map = useMemo(() => new WeakMap<HTMLDivElement, boolean>(), [])
  const refHandler = useCallback((ref: HTMLDivElement, char?: string) => {
    if (ref && !map.has(ref)) {
      makeRipple(ref)
      if (!char) char = ref.children[0]?.getAttribute('data-id')!
      ref.addEventListener('pointerdown', (e) => {
        e.stopPropagation()
        if (char === 'shift') {
          _.find(specialChars, { id: 'shift' })?.down?.()
        } else if (char === 'backspace') {
          _.find(specialChars, { id: 'backspace' })?.down?.()
        } else if (char === 'space') {
          _.find(specialChars, { id: 'space' })?.down?.()
        } else if (char === 'enter') {
          _.find(specialChars, { id: 'enter' })?.down?.()
        } else {
          if (char) {
            clearTimeout(charTimeout)
            charTimeout = setTimeout(() => {
              setPressedKey(char)
            }, 500)
          }
        }
      }, { passive: true })
      ref.addEventListener('pointerup', (e) => {
        e.stopPropagation()
        if (char === 'shift') {
          _.find(specialChars, { id: 'shift' })?.up?.()
        } else if (char === 'backspace') {
          _.find(specialChars, { id: 'backspace' })?.up?.()
        } else if (char === 'space') {
          _.find(specialChars, { id: 'space' })?.up?.()
        } else if (char === 'enter') {
          _.find(specialChars, { id: 'enter' })?.up?.()
        } else {
          handleButton(char as string)
          setPressedKey('')
        }
        clearTimeout(charTimeout)
      })
      map.set(ref, true)
    }
  }, [])

  return (
    <div
      className="max-h-[300px] rounded-10xs bg-whitesmoke-500 flex flex-col items-start justify-start min-w-[524px] min-h-[200px] text-center text-3xl text-black font-mulish self-stretch flex-1">
      <div
        className="self-stretch flex-1 rounded bg-aliceblue [backdrop-filter:blur(30px)] flex flex-col items-center justify-center py-2 px-2.5 gap-[8px]">
        {mode() === Mode.CHAR &&
          <>
            <div
              className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="Q">{convertChar('Q')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="W">{convertChar('W')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="E">{convertChar('E')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="R">{convertChar('R')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="T">{convertChar('T')}</div>
              </div>
              {keyboardLanguage() !== 'de' && <div ref={refHandler}
                                                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="Y">{convertChar('Y')}</div>
              </div>}
              {keyboardLanguage() === 'de' && <div ref={refHandler}
                                                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="Z">{convertChar('Z')}</div>
              </div>}
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="U">{convertChar('U')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="I">{convertChar('I')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="O">{convertChar('O')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="P">{convertChar('P')}</div>
              </div>
              {keyboardLanguage() === 'de' && <div ref={refHandler}
                                                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="Ü">{convertChar('Ü')}</div>
              </div>}
            </div>
            <div
              className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-[33px] gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px] Mobile_480:pl-[30px] Mobile_480:pr-[30px] Mobile_480:box-border">
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="A">{convertChar('A')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative">
                {(keyboardLanguage() === 'de' && pressedKey() === 'S') && <div
                  className="!m-[0] absolute top-[-50px] left-[0px] rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_2px_4px_rgba(0,_0,_0,_0.22)] box-border h-12 flex flex-row items-center justify-center py-0 px-2 gap-[6px] z-[1] border-[0.3px] border-solid border-gray-solid-gray-450-b6b6b6">
                  <div ref={refHandler}
                       className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                    <div className="relative text-black-solid-black-1000-000000 z-[2]" data-id="S">
                      {convertChar('S')}
                    </div>
                  </div>
                  <div ref={refHandler}
                       className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                    <div className="relative text-black-solid-black-1000-000000 z-[2]" data-id="ß">
                      {convertChar('ß')}
                    </div>
                  </div>
                  <div ref={refHandler}
                       className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                    <div className="relative text-black-solid-black-1000-000000 z-[2]" data-id="Ś">
                      {convertChar('Ś')}
                    </div>
                  </div>
                  <div ref={refHandler}
                       className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                    <div className="relative text-black-solid-black-1000-000000 z-[2]" data-id="Š">
                      {convertChar('Š')}
                    </div>
                  </div>
                </div>}
                <div className="relative" data-id="S">{convertChar('S')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="D">{convertChar('D')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="F">{convertChar('F')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="G">{convertChar('G')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="H">{convertChar('H')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="J">{convertChar('J')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="K">{convertChar('K')}</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="L">{convertChar('L')}</div>
              </div>
              {keyboardLanguage() === 'de' &&
                <>
                  <div ref={refHandler}
                       className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                    <div className="relative" data-id="Ö">{convertChar('Ö')}</div>
                  </div>
                  <div ref={refHandler}
                       className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                    <div className="relative"  data-id="Ä">{convertChar('Ä')}</div>
                  </div>
                </>
              }
            </div>
            <div
              className="self-stretch flex-1 flex flex-row items-center justify-center gap-[22px] Tablet_768:gap-[16px] Tablet_600:gap-[12px] Mobile_480:gap-[4px]">
              <div ref={refHandler}
                   className="self-stretch rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7">
                <img
                  data-id="shift"
                  className="relative w-[18.9px] h-[16.5px]"
                  alt=""
                  src="/shift.svg"
                />
              </div>
              <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">
                {keyboardLanguage() !== 'de' && <div ref={refHandler}
                                                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="Z">{convertChar('Z')}</div>
                </div>}
                {keyboardLanguage() === 'de' && <div ref={refHandler}
                                                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative"  data-id="Y">{convertChar('Y')}</div>
                </div>}
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="X">{convertChar('X')}</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="C">{convertChar('C')}</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="V">{convertChar('V')}</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="B">{convertChar('B')}</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="N">{convertChar('N')}</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="M">{convertChar('M')}</div>
                </div>
              </div>
              <div ref={refHandler}
                   className="self-stretch rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7">
                <img
                  data-id="backspace"
                  className="relative w-[22.7px] h-[17px]"
                  alt=""
                  src="/delete-button.svg"
                />
              </div>
            </div>
          </>
        }

        {mode() === Mode.SYMBOL &&
          <>
            <div
              className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="1">1</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="2">2</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="3">3</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="4">4</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="5">5</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="6">6</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="7">7</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="8">8</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="9">9</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="0">0</div>
              </div>
            </div>
            <div
              className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[8px_6px] Tablet_600:gap-[8px_6px] Mobile_480:gap-[8px_4px]">
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="-">-</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="/">/</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id=":">:</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id=";">;</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="(">(</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id=")">)</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="$">$</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="€">€</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="£">£</div>
              </div>
              <div ref={refHandler}
                   className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="¥">¥</div>
              </div>
            </div>
            <div
              className="self-stretch flex-1 flex flex-row items-center justify-center gap-[32px] Tablet_768:gap-[32px_16px] Tablet_600:gap-[32px_12px] Mobile_480:gap-[32px_6px]">
              <div ref={refHandler}
                   className="self-stretch w-[78px] rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative" data-id="@">@</div>
              </div>
              <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="&">&</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="#">#</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="%">%</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="?">?</div>
                </div>
                <div ref={refHandler}
                     className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                  <div className="relative" data-id="!">!</div>
                </div>
              </div>
              <div ref={refHandler}
                   className="self-stretch rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7 Mobile_480:pl-5 Mobile_480:pr-5 Mobile_480:box-border Mobile_480:max-w-[52px]">
                <img
                  data-id="backspace"
                  className="w-[22.7px] relative h-[17px]"
                  alt=""
                  src="/delete-button.svg"
                />
              </div>
            </div>
          </>
        }

        <div
          className="self-stretch flex-1 gap-[8px] grid grid-cols-[33%_34%_32%] [grid-row-gap:0px] [grid-column-gap:8px] text-base">
          <div
            className="flex flex-row items-start justify-start py-0 pr-1 pl-0 box-border gap-[8px] min-w-[146px] relative Tablet_768:relative">
            <div ref={makeRipple} onPointerDown={selectedMode}
                 className="self-stretch flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative tracking-[-0.32px] leading-[21px]" data-id="mode">
                {mode() === Mode.CHAR ? '123' : 'ABC'}
              </div>
            </div>
            <div ref={refHandler}
                 className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative" data-id=",">,</div>
            </div>
            <div ref={makeRipple} onPointerDown={selectedKeyboardLanguage}
                 className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative" data-id="language">{keyboardLanguage() === 'de' ? 'DE' : 'EN'}</div>
            </div>
          </div>
          <div ref={refHandler}
               className="rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative">
            <div className="relative tracking-[-0.32px] leading-[21px]" data-id="space">
              {keyboardLanguage() === 'de' ? 'Leerzeichen' : 'space'}
            </div>
          </div>
          <div
            className="flex flex-row items-start justify-start py-0 pr-0 pl-1 box-border gap-[8px] min-w-[120px] relative">
            <div ref={refHandler}
                 className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[26px]">
              <div className="relative" data-id=".">.</div>
            </div>
            <div ref={refHandler}
                 className="self-stretch flex-1 rounded-8xs-6 bg-cornflowerblue shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[42px] text-base text-basic-color-white">
              <div className="relative" data-id="enter">{props.enterText || (keyboardLanguage() === 'de' ? 'Add' : 'Add')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableManualKeyboard
