import { FunctionComponent, useCallback, useContext, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import PortalPopup from "./PortalPopup";
import PaymentRedBillInfoPopup from "./PaymentRedBillInfoPopup";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import {
  blockMutateOrderButton,
  canShowQrCode,
  getLinkInvoice, isAddCustomer,
  paymentContext0,
  scopeOrder0, setIsAddCustomer
} from "@/react/PaymentView/PaymentView.tsx";
import { toast } from "react-toastify";
import DiscountPopupItem from "@payment/DiscountPopupItem.tsx";
import PaymentSelectTerminalPopu from "@payment/PaymentSelectTerminalPopu.tsx";
import QRCode from "qrcode.react";
import { getServer } from "@/shared/utils.ts";
import { signal } from "@/react/core/reactive.ts";
import CancelBillReasonConfirm from "./CancelBillReasonConfirm";
import VoucherPopup from "@/react/VoucherView/VoucherPopup.tsx";
import SplitBillEquallyPopup from "@order-view/SplitBillEquallyPopup.tsx";
import { isSplitBillEquallyPopupOpen, setSplitBillEquallyPopupOpen } from "@order-view/OrderBurgerMenuButton.tsx";
import { posSync0 } from "@/data/PosSyncState.ts";
import { AccessPort, getAccessibility } from "@/react/DecentralizationPort.ts";
import clsx from "clsx";
import { shouldWarnBecauseOffline } from "@/data/ReplicateEffect.ts";
import { printInvoice } from "@/react/Printer/print-invoice.ts";
import { InvoiceTypes } from "@/pos/OrderType.ts";
import _ from "lodash";
import { srmTransactionLogic } from "@/srm/transaction.logic.ts";
import { isFeatureEnabled, PosFeatures } from "@/srm/features.logic.ts";
import { dialogConfirmMergeSeat } from "@order-view/Popups/dialogConfirmMergeSeat.tsx";
import { onGroupBill, onMergeSeats } from "@/react/OrderView/OrderView.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { companyInfo0, generalSetting0, isQuebecSrmEnabled, printerGeneralSetting0 } from "@/data/PosSettingsSignal.ts";


export type PaymentScreenMoreOptionType = {
  className?: string;
  isDefault?: boolean;
};

export const [isPaymentRedBillInfoPopupOpen, setPaymentRedBillInfoPopupOpen] =
  signal<boolean>(false);

const PaymentScreenMoreOption: FunctionComponent<
  PaymentScreenMoreOptionType
> = ({ className = "", isDefault }) => {

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [isVoucherPopupOpen, setVoucherPopupOpen] = useState(false);
  const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(false);
  const [isQRCodePopupOpen, setQRCodePopupOpen] = useState(false);

  const [isPaymentSelectTerminalPopuOpen, setPaymentSelectTerminalPopuOpen] =
    useState(false);
  const [isCancelBillReasonConfirmOpen, setCancelBillReasonConfirmOpen] =
    useState(false);

  const { order0, seat, onSplitCancel, setSeat } = useContext(ItemFactoryContext)

  const openVoucherPopup = useCallback(() => {
    if (blockMutateOrderButton()) return toast("You have already printed the invoice!", {
      type: 'info',
      autoClose: 500
    });
    setVoucherPopupOpen(true);
    setIsExpanded(false);
  }, []);

  const closeVoucherPopup = useCallback(() => {
    setVoucherPopupOpen(false);
  }, []);

  const openDiscountPopup = useCallback(() => {
    if (blockMutateOrderButton()) return toast("You have already printed the invoice!", {
      type: 'info',
      autoClose: 500
    });
    setDiscountPopupOpen(true);
    setIsExpanded(false);
  }, []);

  const closeDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(false);
  }, []);

  const openPaymentSelectTerminalPopu = useCallback(() => {
    setPaymentSelectTerminalPopuOpen(true);
    setIsExpanded(false);
  }, []);

  const closePaymentSelectTerminalPopu = useCallback(() => {
    setPaymentSelectTerminalPopuOpen(false);
  }, []);


  const openPaymentRedBillInfoPopup = useCallback(() => {
    setPaymentRedBillInfoPopupOpen(true);
  }, []);

  const closePaymentRedBillInfoPopup = useCallback(() => {
    setPaymentRedBillInfoPopupOpen(false);
  }, []);

  const openCancelBillReasonConfirm = useCallback(() => {
    setCancelBillReasonConfirmOpen(true);
  }, []);

  const closeCancelBillReasonConfirm = useCallback(() => {
    setCancelBillReasonConfirmOpen(false);
  }, []);

  const openQrCodePopup = useCallback(() => {
    if (!posSync0()?.id) return toast.warning('You need connect to store', { autoClose: 500 })
    if (!order0?.() || !canShowQrCode(order0())) {
      return toast.warning('You need press print', { autoClose: 500 })
    }
    setQRCodePopupOpen(true);
  }, []);

  const closeQrCodePopup = useCallback(() => {
    setQRCodePopupOpen(false);
  }, []);


  return (
    <>
      <div
        className={`flex flex-col items-center justify-start relative gap-[12px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish ${className}`}
      >
        {isExpanded &&
          <div className="z-[1] w-screen h-screen fixed left-0 top-0" onClick={() => setIsExpanded(false)}/>
        }
        <div
          className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl [background:linear-gradient(180deg,_#2196f3,_#0a80de)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border z-[0] sm:pl-2 sm:pr-2 sm:box-border Tablet_768:pl-1 Tablet_768:pr-1 Tablet_768:box-border"
          ref={makeRipple}
          onClick={() => setIsExpanded(prev => !prev)}
        >
          <img
            className="w-[30px] relative h-[30px] object-cover sm:w-[26px] sm:h-[26px]"
            alt=""
            src="/payment-iconmenu-icon1@2x.png"
          />
        </div>

        {isExpanded && (
          <div
            className="!m-[0] absolute top-full left-[calc(50%_-_202px)] flex flex-col items-center justify-start z-[1]">
            <div className="self-stretch flex flex-col items-center justify-start">
              <img
                className="w-2.5 relative h-[9px] object-cover"
                alt=""
                src="/payment-icontooltip-arrow-up-icon@2x.png"
              />
            </div>
            <div
              className="w-[404px] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-blue-solid-blue-20-f3f9ff flex flex-row flex-wrap items-start justify-start p-2 box-border gap-[10px]">
              <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                onClick={openVoucherPopup}
                ref={makeRipple}
              >
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/payment-ordervoucher-icon@2x.png"
                />
                <b className="flex-1 relative leading-[17px] z-[2]">
                  {LL0().restaurant.redeemVoucher()}
                </b>
                <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                  {LL0().restaurant.redeemVoucher()}
                </b>
              </div>
              {getAccessibility(AccessPort.DISCOUNT) &&
                <div
                  className={clsx('h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff',
                    { 'opacity-70': blockMutateOrderButton() }
                  )}
                  ref={makeRipple}
                  onClick={openDiscountPopup}
                >
                  {!!paymentContext0.order0().discount && <div
                    className="!flex w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />}
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/payment-orderdiscount-icon@2x.png"
                  />
                  {!!paymentContext0.order0().discount ? <b
                      className="!flex flex-1 relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                      {LL0().payment.applyDiscount()}
                    </b>
                    :
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().payment.applyDiscount()}
                    </b>
                  }
                </div>}
              <div
                className={clsx('h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff',
                  { 'opacity-70': blockMutateOrderButton() }
                )}
                ref={makeRipple}
                onClick={async () => {
                  if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
                  if (blockMutateOrderButton()) return toast('You have already printed the invoice!', {
                    type: 'info',
                    autoClose: 500
                  })
                  setIsExpanded(false)
                  if (order0?.().seatMode) {
                    for (const _seat of order0().seatMap ?? []) {
                      const checkQuantity = _seat.items.filter(i => i.quantity > 0)
                      if (checkQuantity.length !== 0) {
                        await printInvoice(_seat, InvoiceTypes.GUEST_CHECK)
                      }
                    }
                  } else {
                    await printInvoice(scopeOrder0(), InvoiceTypes.GUEST_CHECK)
                  }
                }}
              >
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/payment-iconorderunpaid-bill-icon@2x.png"
                />
                <b className="flex-1 relative leading-[17px] z-[2]">
                  {LL0().order.invoiceTypes.guestCheck()}
                </b>
                <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                  {LL0().order.invoiceTypes.guestCheck()}
                </b>
              </div>
              {isFeatureEnabled(PosFeatures.RedBill) &&
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  ref={makeRipple}
                  onClick={async () => {
                    if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
                    setIsExpanded(false)
                    const provider = scopeOrder0().provider
                    const isOnlineOrder = !!provider
                    const isDeliveryOrder = scopeOrder0().type === 'delivery'
                    if (companyInfo0()?.country === 'de' && !isOnlineOrder && !isDeliveryOrder && !scopeOrder0().customerRaw) {
                      if (isAddCustomer()) {
                        setIsAddCustomer(false)
                      }
                      openPaymentRedBillInfoPopup()
                    } else {
                      await printInvoice(scopeOrder0(), InvoiceTypes.RED_INVOICE)
                    }
                  }}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/payment-iconpaymentscreenred-bill-icon@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.invoiceTypes.redBill()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                    {LL0().order.invoiceTypes.redBill()}
                  </b>
                </div>}
              <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                ref={makeRipple}
                onClick={openQrCodePopup}
              >
                <div
                  className="!hidden w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff z-[0]" />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/ordervoucher-icon@2x.png"
                />
                <b className="!flex flex-1 relative leading-[17px] hidden z-[2]">
                  {LL0().payment.showQrCode()}
                </b>
                <b className="!hidden flex-1 relative leading-[17px] text-white-solid-white-100-ffffff z-[3]">
                  {LL0().payment.showQrCode()}
                </b>
              </div>
              <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                ref={makeRipple}
                onClick={openPaymentSelectTerminalPopu}
              >
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/paymentterminal-setup-icon@2x.png"
                />
                <b className="flex-1 relative leading-[17px] z-[2]">
                  {LL0().dashboard.terminalSetup()}
                </b>
                <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                  {LL0().dashboard.terminalSetup()}
                </b>
              </div>
              {isFeatureEnabled(PosFeatures.SplitBill) &&
                <div
                  className='h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff'
                  ref={makeRipple}
                  onClick={() => {
                    setIsExpanded(false)
                    setSplitBillEquallyPopupOpen(true)
                  }}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/order-equally-split-icon@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.equalSplit()}
                  </b>
                  <b
                    className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                    {LL0().order.equalSplit()}
                  </b>
                </div>}
              {order0?.()?.seatMode &&
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  ref={makeRipple}
                  onClick={async () => {
                    await dialogConfirmMergeSeat(() => {
                      if (onSplitCancel && order0) {
                        onSplitCancel(false)
                        onMergeSeats(order0())
                        setIsExpanded(false)
                      }
                    })
                  }}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/icon-merge.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.mergeSeats()}
                  </b>
                </div>}
              {isFeatureEnabled(PosFeatures.RedBill) &&
              <div
                className={clsx('h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff', isQuebecSrmEnabled() ? 'opacity-40 pointer-events-none cursor-not-allowed bg-gray-opacity-gray-300 border-gray-400'
                  : 'bg-blue-opacity-blue-2196f3 border-blue-solid-blue-300-3faaff')}
                ref={makeRipple}
                onClick={() => {
                  setIsExpanded(false)
                  if (!isAddCustomer()) {
                    setIsAddCustomer(true)
                  }
                  openPaymentRedBillInfoPopup()
                }}
              >
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/add-customer-icon.png"
                />
                <b className="flex-1 relative leading-[17px] z-[2]">
                  {LL0().onlineOrder.addCustomer()}
                </b>
                <b
                  className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                  {LL0().onlineOrder.addCustomer()}
                </b>
              </div>
              }
              {isQuebecSrmEnabled() && <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                ref={makeRipple}
                onClick={() => (setIsExpanded(false), srmTransactionLogic.handleFailureToPay(scopeOrder0()))}
              >
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                <img
                  className="w-[24px] relative h-[24px] object-cover z-[1]"
                  alt=""
                  src="/fail-to-pay-icon.png"
                />
                <b className="flex-1 relative leading-[17px] z-[2]">
                  {LL0().order.receipt.failToPay()}
                </b>
                <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                  {LL0().order.receipt.failToPay()}
                </b>
              </div>}
              <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small"
                                     checked={deviceSetting0()?.autoOpenCashDrawer}
                                     onChange={(_e, checked) => _.assign(deviceSetting0(), { autoOpenCashDrawer: checked })}
                    />}
                    label='' />
                </div>
                <b className="flex-1 relative leading-[17px]">
                  {LL0().payment.autoOpenCashDrawer()}
                </b>
              </div>
              <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small"
                                     checked={!generalSetting0()?.cashTipEnable}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { cashTipEnable: !checked })}
                    />}
                    label='' />
                </div>
                <b className="flex-1 relative leading-[17px]">
                  {LL0().order.cashTip()}
                </b>
              </div>
              {(isQuebecSrmEnabled() && order0?.()?.seatMode) &&
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                  <div className="w-9 relative h-[18px]">
                    <FormControlLabel
                      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                      control={<Switch color="primary" size="small"
                                       checked={order0?.()?.isGroupBill || false}
                                       onChange={(e) => {
                                         if (order0)
                                           onGroupBill(order0(), e.target.checked)
                                       }} />}
                      label='' />
                  </div>
                  <b className="flex-1 relative leading-[17px]">
                    {LL0().order.groupBills()}
                  </b>
                </div>}
              <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small"
                                     checked={!!printerGeneralSetting0()?.autoPrintInvoiceBeforePaying}
                                     onChange={(_e, checked) => _.assign(printerGeneralSetting0(), { autoPrintInvoiceBeforePaying: checked })}
                    />}
                    label={''} />
                </div>
                <b className="flex-1 relative leading-[17px]">{LL0().payment.autoPrint()}</b>
              </div>
              <div
                className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small" />}
                    label={''} />
                </div>
                <b className="flex-1 relative leading-[17px]">
                  {LL0().payment.autoCloseAfterTerminalProcess()}
                </b>
              </div>
            </div>
          </div>
        )}
      </div>
      {isVoucherPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeVoucherPopup}
        >
          <VoucherPopup onClose={closeVoucherPopup} order={scopeOrder0()} />
        </PortalPopup>
      )}
      {isDiscountPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDiscountPopup}
        >
          <DiscountPopupItem onClose={closeDiscountPopup} />
        </PortalPopup>
      )}
      {isPaymentSelectTerminalPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentSelectTerminalPopu}
        >
          <PaymentSelectTerminalPopu onClose={closePaymentSelectTerminalPopu} />
        </PortalPopup>
      )}
      {isPaymentRedBillInfoPopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentRedBillInfoPopup}
        >
        <PaymentRedBillInfoPopup onClose={closePaymentRedBillInfoPopup}/>
        </PortalPopup>
      )}
      {isQRCodePopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeQrCodePopup}
        >
          <div
            className="w-full h-full relative rounded bg-white-solid-white-100-ffffff flex flex-col items-center justify-start p-12 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-900-1e1e23 font-mulish">
            <img
              className="w-8 absolute !m-[0] top-[12px] right-[12px] h-8 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
              alt=""
              src="/payment-button-close-icon2@2x.png"
              onClick={closeQrCodePopup}
            />
            <QRCode
              value={getLinkInvoice(order0?.()!, seat?.()) || `${getServer().manageServer.url}/invoice/${posSync0()?.id}/${order0?.()?._id}`}
              size={256}
              style={{ marginTop: '1rem' }}
            />
          </div>
        </PortalPopup>
      )}
      {isCancelBillReasonConfirmOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeCancelBillReasonConfirm}
        >
          <CancelBillReasonConfirm onClose={closeCancelBillReasonConfirm}/>
        </PortalPopup>
      )}
      {isSplitBillEquallyPopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setSplitBillEquallyPopupOpen(false)}
        >
          <SplitBillEquallyPopup onClose={() => setSplitBillEquallyPopupOpen(false)}/>
        </PortalPopup>
      )}
    </>
  );
};

export default PaymentScreenMoreOption;
