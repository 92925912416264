import type { Eod } from "@/data/Eod.ts";
import dayjs from "dayjs";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";



export function Stamm_AbschlussFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      Z_BUCHUNGSTAG: Z_BUCHUNGSTAG(),
      TAXONOMIE_VERSION: TAXONOMIE_VERSION(),
      Z_START_ID: Z_START_ID(),
      Z_ENDE_ID: Z_ENDE_ID(),
      NAME: NAME(),
      STRASSE: STRASSE(),
      PLZ: PLZ(),
      ORT: ORT(),
      LAND: LAND(),
      STNR: STNR(),
      USTID: USTID(),
      Z_SE_ZAHLUNGEN: Z_SE_ZAHLUNGEN(),
      Z_SE_BARZAHLUNGEN: Z_SE_BARZAHLUNGEN(),
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return eod.z;
  }

  function Z_BUCHUNGSTAG() {
    // hint: "Vom Erstellungsdatum abweichender Verbuchungstag",
    return dayjs.unix(eod.date).toISOString();
  }

  function TAXONOMIE_VERSION() {
    // hint: "Version der DFKA-Taxonomie-Kasse",
    return '2.1';
  }

  function Z_START_ID() {
    // luc minh in cai eod -> eod start order.id, end order.id
    // hint: "Erste BON_ID im Abschluss",
    // fixme: add this field to the model
    return eod.startOrderId;
  }

  function Z_ENDE_ID() {
    // hint: "Letzte BON_ID im Abschluss",
    // fixme: add this field to the model
    return eod.endOrderId;
  }

  function NAME() {
    // hint: "Name des Unternehmens",
    return companyInfo0().name;
  }

  function STRASSE() {
    // hint: "Straße",
    return companyInfo0().address;
  }

  function PLZ() {
    // hint: "Postleitzahl",
    return companyInfo0().zipCode;
  }

  function ORT() {
    // hint: "Ort",
    return companyInfo0().city;
  }

  function LAND() {
    // hint: "Land",
    return 'DEU';
  }

  function STNR() {
    // hint: "Steuernummer des Unternehmens",
    return companyInfo0().taxNumber;
  }

  function USTID() {
    // hint: "USTID",
    return companyInfo0().ustId;
  }

  function Z_SE_ZAHLUNGEN() {
    // hint: "Summe aller Zahlungen",
    return eod.paymentSum;
  }

  function Z_SE_BARZAHLUNGEN() {
    // hint: "Summe aller Barzahlungen",
    return eod.cashSum;
  }
}
