// import WebSocket from 'isomorphic-ws';

import debug from 'debug'
import WebSocket from 'reconnecting-websocket'

import { deviceSettingLock } from '@/data/DeviceSettingHub.ts'
import { signal } from '@/react/core/reactive.ts'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'

import { notificationToast } from '../FloorPlanView/Noti'
import { handleIncomingCall } from '../NewDeliveryView/NewDeliveryView'
import { isUsbDeviceStatusWsMessage, isUsbDeviceWsMessage, type UsbDevice } from './devices'
import { isArtechDataWsMessage } from './devices-artech'
import { getRingMsg, isUsroboticsDataWsMessage } from './devices-usrobotics'
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const log = debug('ws-java')

export const javaWs = new WebSocket(`ws://localhost:5005/live`, [], {
  connectionTimeout: 1000,
  maxRetries: 1,
})

export const javaWsLock = new MultiAwaitLock(true)

let opened = false

export const [usbDevices, setUsbDevices] = signal<UsbDevice[]>([])

export function getDeviceId(usbDevice?: UsbDevice) {
  if (!usbDevice) return ''
  return `${usbDevice.deviceVID.toString()}-${usbDevice.devicePID.toString()}`
}

javaWs.onopen = async function open() {
  log('JAVA WS connected')
  javaWsLock.release().then()
}

javaWs.onclose = function close() {
  log('disconnected')
}

javaWs.onmessage = async function incoming(e) {
  try {
    const data = JSON.parse(e.data)

    if (isUsbDeviceWsMessage(data)) {
      log(`🔌 ${data.data.deviceList.length} device(s) connected`, data.data.deviceList)
      setUsbDevices(data.data.deviceList)
      //fixme: condition to open devices
      if (!opened) {
        opened = true
        openDevice()
      }
      return
    }
    if (isUsroboticsDataWsMessage(data)) {
      const ringMsg = getRingMsg(data)
      log(`📞`, ringMsg)
      await handleIncomingCall(ringMsg.NMBR)
      return
    }
    if (isArtechDataWsMessage(data)) {
      log(`📞`, data.data)
      await handleIncomingCall(data.data)
      return
    }
    if (isUsbDeviceStatusWsMessage(data)) {
      if (data.data.status === 'Connecting...') notificationToast(`ℹ️ Connecting to [${data.data.deviceID}]...`, { autoClose: 1000 * 60 })
      else if (data.data.status === 'Connected') notificationToast(`✅ Device [${data.data.deviceID}] connected!`, { autoClose: 1000 * 60 })
      else notificationToast(`ℹ️ Device [${data.data.deviceID}] ${data.data.status}`, { autoClose: 1000 * 60 })
      log(`🔌 Done`)
      return
    }

    log(`🫧 Ws Message`, data)
  } catch (err) {
    log(`🛑 Failed to process ws message:`, e, err)
  }
}

//vid:2991, pid: 771
function openDevice() {
  log('openDevice usrobotics')
  if (deviceSetting0()?.callSystem?.mode === '56k-modem') {
    const cmd = {
      event: 'usrobotics-open-usb-device',
      //vid-pid
      payload: {
        deviceID: getDeviceId(deviceSetting0()?.callSystem?.usbDevice) /*'2991-771'*/,
      },
    }
    javaWs.send(JSON.stringify(cmd))
  } else if (deviceSetting0()?.callSystem?.mode === 'artech') {
    const cmd = {
      event: 'artech-open-usb-device',
      //vid-pid
      payload: {
        deviceID: '50635-2',
      },
    }
    javaWs.send(JSON.stringify(cmd))
  }
}

function listDevices() {
  const cmd = {
    event: 'usrobotics-list-usb-devices',
    payload: {},
  }
  javaWs.send(JSON.stringify(cmd))
}

export async function initCallSystem() {
  await deviceSettingLock.acquireAsync()
  await javaWsLock.acquireAsync()
  // await callSystemLock.acquireAsync();
  opened = false
  listDevices()
}

initCallSystem().then()

// @ts-ignore
window.listDevices = listDevices
// @ts-ignore
window.openDevice = openDevice

//list-usb-devices

const demoList = {
  event: 'usrobotics-list-usb-devices',
  data: {
    deviceList: [
      {
        devicePath: '/dev/bus/usb/002/003',
        deviceManufacturerName: 'USB Storage',
        deviceProductName: 'USB Storage',
        deviceVID: 1507,
        devicePID: 1873,
      },
      {
        devicePath: '/dev/bus/usb/002/006',
        deviceManufacturerName: 'U.S.Robotics',
        deviceProductName: 'USB Modem',
        deviceVID: 2991,
        devicePID: 771,
      },
      {
        devicePath: '/dev/bus/usb/002/004',
        deviceManufacturerName: 'ARTECH',
        deviceProductName: 'AD102',
        deviceVID: 50635,
        devicePID: 2,
      },
    ],
  },
}

//{message: {event: 'usrobotics-list-usb-devices', data: {
//  devicePath, deviceManufacturerName, deviceProductName, deviceVID, devicePID
//}}}

// ws.onmessage = function incoming(data) {
//   const str = '{' + JSON.parse(data.data as string).data.split('{')[1]
//   console.log(`message: ${str}`);
//
//   // setTimeout(function timeout() {
//   //   ws.send(Date.now());
//   // }, 500);
// };
