import { type FunctionComponent, useState } from "react";
import { DatePicker, LocalizationProvider, TimePicker, } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LL0 } from "@/react/core/I18nService.tsx";
import { ShiftStatus, TimeClock } from "@/data/TimeClock.ts";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { selectedUserId, updateStaffReport, updateTimeWithDate } from "@/react/StaffReportView/StaffReportView.tsx";
import uuid from "time-uuid";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { users0 } from "@/data/UserSignal.ts";

type AddNewTimeEntryPopupType = {
  onClose?: () => void;
};


const AddNewTimeEntryPopup: FunctionComponent<AddNewTimeEntryPopupType> = ({
  onClose,
}) => {
  const [datePickerValueFrom, setDatePickerValueFrom] = useState<any>(null);
  const [datePickerValueTo, setDatePickerValueTo] = useState<any>(null);
  const [clockInPickerValue, setClockInPickerValue] = useState<
    any
  >(null);
  const [clockOutPickerValue, setClockOutPickerValue] =
    useState<any>(null);

  const onConfirm = async() => {
    if (!datePickerValueFrom || !datePickerValueTo || !clockInPickerValue || !clockOutPickerValue) return toast.error('Please fill in all field')

    const clockInTime = dayjs(clockInPickerValue).unix();
    const clockOutTime = dayjs(clockOutPickerValue).unix();

    const isValidClock = clockOutTime >= clockInTime
    if (!isValidClock) return toast.error('Unable to initialize a shift with a negative duration')

    const username = users0().find(({ _id }) => _id === selectedUserId())?.name

    await TimeClock.insert({
      _id: uuid(),
      status: ShiftStatus.CLOCK_OUT,
      username: username,
      clockInTime: clockInTime,
      vDate: dayjs(datePickerValueFrom).unix(),
      clockOutTime: clockOutTime,
    })

    await updateStaffReport()

    onClose?.();
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[16px] min-w-[320] h-full text-left text-lg text-gray font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[16px] hover:items-start hover:justify-start hover:pt-8 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[320]">
        <div
          className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
          onClick={onClose}
        >
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/icongeneralclose-popup-icon--grey@2x.png"
          />
        </div>
        <b className="self-stretch relative z-[1]">{LL0().monthlyReport.newWorkTimeEntry()}</b>
        <div className="self-stretch flex flex-row items-start justify-start gap-[4px] z-[2] text-sm">
          <div className="relative font-extrabold">{LL0().timeClock.clockOutPassCodeDialogContent.staff()}:</div>
          <div className="relative text-right">{LL0().monthlyReport.manager()}</div>
        </div>
        <div className="self-stretch z-[2] flex flex-row items-start justify-center gap-[16px]">
          <div className="flex-1">
            <DatePicker
              format={LL2().dates.dateFormat()}
              label={LL0().report.from()}
              value={datePickerValueFrom}
              onChange={(newValue: any) => {
                setDatePickerValueFrom(newValue);
                setClockInPickerValue(updateTimeWithDate(newValue, clockInPickerValue));
              }}
              maxDate={dayjs()}
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  fullWidth: true,
                  color: "primary",
                },
              }}
            />
          </div>
          <div className="flex-1">
            <DatePicker
              format={LL2().dates.dateFormat()}
              label={LL0().report.to()}
              value={datePickerValueTo}
              onChange={(newValue: any) => {
                setDatePickerValueTo(newValue);
                setClockOutPickerValue(updateTimeWithDate(newValue, clockOutPickerValue));
              }}
              maxDate={dayjs()}
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  fullWidth: true,
                  color: "primary",
                },
              }}
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[4]">
          <div className="flex-1">
            <TimePicker
              label={LL0().timeClock.clockInOutDialog.clockedIn()}
              value={clockInPickerValue}
              onChange={(newValue: any) => {
                setClockInPickerValue(updateTimeWithDate(datePickerValueFrom, newValue));
              }}
              ampm={companyInfo0()?.country !== 'de'}
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  fullWidth: true,
                  color: "primary",
                },
              }}
            />
          </div>
          <div className="flex-1">
            <TimePicker
              label={LL0().timeClock.clockInOutDialog.clockedOut()}
              value={clockOutPickerValue}
              onChange={(newValue: any) => {
                setClockOutPickerValue(updateTimeWithDate(datePickerValueTo, newValue));
              }}
              ampm={companyInfo0()?.country !== 'de'}
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  color: "primary",
                },
              }}
            />
          </div>
        </div>
        <div
          className="self-stretch rounded bg-royalblue-100 flex flex-row items-center justify-center py-2 px-0 box-border min-w-[146px] z-[5] text-center text-white-solid-white-100-ffffff"
          onClick={onConfirm}>
          <div className="relative font-semibold">{LL0().ui.add()}</div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default AddNewTimeEntryPopup;