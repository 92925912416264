import type { FunctionComponent } from 'react'
import {addCustomDish, setInputControllers} from '@/react/OrderView/OrderView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";
import Input, {inputController0} from "@/react/core/Input.tsx";
import {useSignal} from "@/react/core/reactive.ts";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";

type OrderSreenCigarettePopuType = {
  onClose?: () => void;
};

const OrderScreenCigarettePopu: FunctionComponent<
  OrderSreenCigarettePopuType
> = ({onClose}) => {

  const [cigarette, setCigarette] = useSignal<string>("")

  return (
    <div
      className="w-full h-full relative rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start pt-8 px-0 pb-0 box-border gap-[8px] min-w-[438px] mq340:min-w-[290px] Mobile_480:min-w-0 max-w-full max-h-full text-left text-sm text-materialize-grey-darken-3 font-mulish">
      <div className="self-stretch w-full flex-1 flex flex-col items-center justify-start pt-2 px-4 pb-4 gap-[24px] mq432:p-0 z-[0]">
        <div className="self-stretch flex flex-row items-start justify-center mq432:p-2">
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
            <b className="relative">{LL0().article.price()}</b>
            <Input
              className={'w-full font-mulish italic text-smi text-gray-400'}
              refInputController={i => {
                i.setFocus(true)
                return setInputControllers?.([i]);
              }}
              value={cigarette()}
              onChange={value => setCigarette(value)}
            />
          </div>
        </div>
        <TableManualKeyboard
          value={''}
          inputController={inputController0}
          onEnter={() => {
            if (cigarette()) addCustomDish("Cigarette", cigarette());
            onClose?.();
          }}
        />
      </div>
      <img
        className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 object-cover cursor-pointer z-[1]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default OrderScreenCigarettePopu;
