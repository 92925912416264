import { formatDate } from "@/react/CashbookView/CashbookView";
import { type FunctionComponent } from "react";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";

type CashbookPriceBoxType = {
  icon?: string;
  title: string;
  cashNumber: number;
  dateCash?: number;
  isShowIcon?: boolean;
  isMismatch?: boolean;
  isDefault?: boolean;
  isMatching?: boolean;
  showDateCash?: boolean;
  widthStyle?: number;
};

const CashbookPriceBox: FunctionComponent<CashbookPriceBoxType> = ({
  icon,
  title,
  cashNumber,
  dateCash,
  isShowIcon,
  isMismatch,
  isDefault,
  isMatching,
  showDateCash,
  widthStyle
}) => {

  return (
    <div
      className={`flex flex-col items-start justify-start text-left text-lg text-white-solid-white-100-ffffff font-mulish ${widthStyle ? `w-[${widthStyle}px]` : 'flex-1'}`}>
      <div className="self-stretch flex flex-col items-start justify-start">
        <div
          className="self-stretch rounded-t-md rounded-b-none [backdrop-filter:blur(116px)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-0 relative gap-[8px] opacity-[0.9]">
          {isDefault && (
            <div
              className="w-[calc(100%_+_2px)] absolute !m-[0] h-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] [backdrop-filter:blur(116px)] [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.8),_rgba(38,_93,_219,_0.8))] border-blue-linear-blue-linear-2000 border-[1px] border-solid box-border opacity-[0.9] z-[1]"/>
          )}
          {isMatching && (
            <div
              className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(129,_234,_234),_rgba(54,_169,_194))] [backdrop-filter:blur(116px)] opacity-[0.9] z-[1]"/>
          )}
          {isMismatch && (
            <div
              className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(255,_158,_137,_0.9),_rgba(216,_39,_0,_0.9))] [backdrop-filter:blur(116px)] opacity-[0.9] z-[1]"/>
          )}
          {isShowIcon && (
            <img
              className="w-5 relative h-5 object-cover z-[3]"
              alt=""
              src={icon}
            />
          )}
          <b className="relative z-[4] sm:text-smi">{title}</b>
        </div>
        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)_inset] rounded-t-none rounded-b-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] overflow-hidden flex flex-row items-center justify-center py-[38px] px-0 text-center text-4xl"
        >
          <div
            className="flex-1 relative [text-shadow:0px_1.5px_3px_rgba(0,_0,_0,_0.2)] sm:text-lg"
          >
            {LL3().format.currency(cashNumber)}
          </div>
        </div>
      </div>
      {showDateCash && (
        <div className="self-stretch relative text-smi text-silver text-center">
          {dateCash && formatDate(dateCash, `${LL2().dates.dateFormat()} | ${LL2().dates.timeFormat()}`) || ""}
        </div>
      )}
    </div>
  );
};

export default CashbookPriceBox;