import Box from "@/react/core/Box";
import { BoxProps } from "@/react/core/BoxProps";
import { HTMLAttributes, PropsWithChildren } from "react";


const XBox = (props: BoxProps & HTMLAttributes<any> & PropsWithChildren) => {
	return <Box flexDirection="row" {...props} >
		{props.children}
	</Box>;
};

export default XBox;
export const XContainer = XBox;
