import { type FunctionComponent, memo, useEffect } from "react";
import OrderScreenLeft from "@order-view/OrderScreenLeft";
import OrderScreenRight from "@order-view/OrderScreenRight";
import OrderScreenBottomBar from "@order-view/OrderScreenBottomBar";
import {
  currentNonFixedProduct,
  isShowToolbar, keyboardMatchProducts,
  setCurrentNonFixedProduct, setIsShowToolbar, setKeyboardMatchProducts, setResultModifiers, setShowPopupModifier,
  showPopupModifier
} from '@/react/OrderView/OrderView.tsx'
import PortalPopup from "@order-view/PortalPopup.tsx";
import OrderScreenAdjustPricePopup from "@order-view/OrderScreenAdjustPricePopup.tsx";
import OrderGroupPopup from '@order-view/OrderGroupPopup.tsx';
import OrderChooseItemFromSameIDsPop from '@order-view/Popups/OrderChooseItemFromSameIDsPop.tsx';
import PortalPopup3 from "@order-view/PortalPopup3.tsx";
import { makeProductsAvailable } from "@/data/ProductHub.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const OrderScreenPlugin: FunctionComponent = () => {
  makeProductsAvailable();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  useEffect(() => {
    if (deviceSetting0()) {
      setIsShowToolbar(deviceSetting0()?.showToolbar)
    }
  }, [deviceSetting0()])

  return (
    <>
      <div
        className="!opacity-100 !rounded-none rounded-md w-full h-full overflow-hidden flex flex-col items-start justify-start bg-[url('/order-screen-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0]"
      >
        <div className="self-stretch flex-1 overflow-y-auto flex flex-row items-start justify-start">
          <OrderScreenLeft />
          <OrderScreenRight />
        </div>
        {isShowToolbar() &&
          <OrderScreenBottomBar />
        }
      </div>
      {
        !!currentNonFixedProduct() &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setCurrentNonFixedProduct(null)}
        >
          <OrderScreenAdjustPricePopup onClose={() => {
            setCurrentNonFixedProduct(null)
          }} />
        </PortalPopup>
      }
      <PortalPopup3
        overlayColor="rgba(0, 0, 0, 0.2)"
        placement="Centered"
        onOutsideClick={() => {
          setShowPopupModifier(false)
          setResultModifiers({})
        }}
        show={showPopupModifier()}
      >
        <OrderGroupPopup onClose={() => setShowPopupModifier(false)} onClearResult={() => setResultModifiers({})} />
      </PortalPopup3>
      {
        !!keyboardMatchProducts().length &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setKeyboardMatchProducts([])}
        >
          <OrderChooseItemFromSameIDsPop onClose={() => setKeyboardMatchProducts([])} />
        </PortalPopup>
      }
    </>
  );
};

export default memo(OrderScreenPlugin);