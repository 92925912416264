import { FunctionComponent } from "react";
import clsx from 'clsx';
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type EditRoomTableItemType = {
  price?: number;
  tableNumber?: string;
  remainingTime?: string;
  isSelected?: boolean;
  isMyTable?: boolean;
  isBooked?: boolean;
  className?: string;
};

const EditRoomTableItem: FunctionComponent<EditRoomTableItemType> = ({
  price = 0,
  tableNumber = "8",
  remainingTime = "23 mins",
  isSelected = true,
  isMyTable = true,
  isBooked = true,
  className
}) => {
  return (
    <div className={clsx("rounded w-full h-full flex flex-col items-center justify-center relative gap-[6px] text-center text-2xs text-gray-200 font-mulish", className)} >
      {isBooked && (
        <div className="absolute my-0 mx-[!important] top-[0px] left-[0px] bg-lightcoral w-full h-full" />
      )}
      {isSelected && (
        <div className="absolute my-0 mx-[!important] rounded box-border w-full h-full border-[2.5px] border-solid border-crimson-100" />
      )}
      {isBooked && (
        <b className="relative leading-[15px]">{LL3().format.currency(price)}</b>
      )}
      <b className="text-gray-900 relative text-xl leading-[16px]">{tableNumber}</b>
      {isBooked && (
        <div className="flex flex-row items-center justify-center gap-[3px] text-3xs">
          <img
            className="relative w-[9.8px] h-[9.1px]"
            alt=""
            src="/click-icon.svg"
          />
          <b className="relative tracking-[-0.2px]">{remainingTime}</b>
        </div>
      )}
      {isMyTable && (
        <img
          className="absolute my-0 mx-[!important] top-[0px] right-0 w-[21.5px] h-[21.5px"
          alt=""
          src="/base.svg"
        />
      )}

    </div>
  );
};

export default EditRoomTableItem;
