import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import dayjs from "dayjs";
import { getUST_SCHLUESSEL } from "@/tse/dsfinv/dsfinvModel2.ts";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";
import { getItemNet, getItemTax } from "@/pos/logic/order-reactive.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";

export function Bonpos_UStFactory(order: Order, item: OrderItem, isCancelled: boolean, eod: Eod) {
  return build();
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      POS_ZEILE: POS_ZEILE(),
      UST_SCHLUESSEL: UST_SCHLUESSEL(),
      POS_BRUTTO: POS_BRUTTO(),
      POS_NETTO: POS_NETTO(),
      POS_UST: POS_UST()
    }
  }

  function Z_KASSE_ID() {
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function POS_ZEILE() {
    if (isCancelled) {
      return order.items.indexOf(item) + 1;
    } else {
      return order.cancellationItems!.indexOf(item) + 1;
    }
  }

  function UST_SCHLUESSEL() {
    return getUST_SCHLUESSEL(item?.tax!);
  }

  function POS_BRUTTO() {
    return item?.vSum;
  }

  function POS_NETTO() {
    return getItemNet(item);
  }

  function POS_UST() {
    return getItemTax(item);
  }
}