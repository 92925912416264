import KitchenMonitorPlugin from "@kitchen-monitor/KitchenMonitorPlugin.tsx";
import {signal} from "@/react/core/reactive.ts";
import {memo} from "react";

export type OrderFoods = {
  name: string,
  modifiers: string,
  quantity: number
}

type Order = {
  orderStatus: "new" | "prepare" | "done";
  timeline: string;
  orderType: string;
  orderTime: string;
  orderId: string;
  orderStaff: string;
  orderFoods: OrderFoods[];
};

export const orderFoodList: Order[] = [
  {
    orderStatus: "new",
    timeline: "1 mins",
    orderType: "Table no. 3",
    orderTime: "3:00 PM",
    orderId: "#123",
    orderStaff: "Jennifer",
    orderFoods: [
      { name: "Chicken", modifiers: "khong hanh, them thit", quantity: 3 },
      { name: "Meat", modifiers: "khong hanh, them thit", quantity: 3 },
      { name: "Beef", modifiers: "khong hanh, them thit", quantity: 3 },
    ],
  },
  {
    orderStatus: "prepare",
    timeline: "2 mins",
    orderType: "Table no. 5",
    orderTime: "4:00 PM",
    orderId: "#124",
    orderStaff: "John",
    orderFoods: [
      { name: "Fish", modifiers: "khong hanh, them thit", quantity: 2 },
      { name: "Vegetables", modifiers: "khong hanh", quantity: 1 },
    ],
  },
  {
    orderStatus: "done",
    timeline: "3 mins",
    orderType: "Table no. 1",
    orderTime: "5:00 PM",
    orderId: "#125",
    orderStaff: "David",
    orderFoods: [
      { name: "Pasta", modifiers: "khong hanh, them thit", quantity: 2 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
      { name: "Salad", modifiers: "khong hanh", quantity: 1 },
    ],
  },
];

export const [heightContainer, setHeightContainer] = signal<number>(0)
export const [heightCard, setHeightCard] = signal<number>(0)

const KitchenMonitorView = () => {
  return (
    <KitchenMonitorPlugin/>
  )
}

export default memo(KitchenMonitorView)