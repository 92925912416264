import {FunctionComponent, memo} from "react";
import HappyHourLeftPath from "@happy-hour/HappyHourLeftPath";
import HappyHourRightPath from "@happy-hour/HappyHourRightPath";

const HappyHourPlugin: FunctionComponent = () => {
  return (
    <div className="happy-hour-screen bg-basic-color-white-solid-white-100-ffffff w-full h-full flex flex-row items-center justify-start">
      <HappyHourLeftPath />
      <HappyHourRightPath />
    </div>
  );
};

export default memo(HappyHourPlugin);
