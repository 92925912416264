import dialogService from "@/react/SystemService/dialogService.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

export const dialogShowId = async (id: string) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className='w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center box-border gap-[14px] min-w-[280px] pb-2 h-full max-w-full max-h-full text-center text-xl text-red-solid-red-550-cf2222 font-mulish'
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative text-xl leading-[28px]">
            {LL0().login.remoteControl()}
          </b>
          <b className="self-stretch relative text-md leading-[28px] text-black">
            {LL0().login.yourID()}: {id}
          </b>
        </div>
        <div
          className="w-full flex flex-row items-center justify-end gap-[6px] max-w-[312px] text-sm">
          <div
            className="flex-1 rounded-md bg-white-solid-white-100-ffffff  text-blue-solid-blue-420-2979ff box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-1 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={() => {
              onClose()
            }}
          >
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
          <div
            className="flex-1 rounded-md bg-blue-600 box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-1 max-w-[100px] cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(id)
              onClose()
            }}
          >
            <b className="relative text-white ">{LL0().ui.copy()}</b>
          </div>
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}
