import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

const Notice1: FunctionComponent = () => {
  return (
    <div className="rounded-sm bg-tomato-200 overflow-hidden flex flex-row items-center justify-start py-1 px-1.5 relative gap-[4px] z-[1] text-left text-smi text-white font-mulish">
      <div className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-chocolate-100 z-[0]" />
      <div className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-mediumseagreen hidden z-[1]" />
      <div className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-darkorange hidden z-[2]" />
      <div className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blueviolet hidden z-[3]" />
      <b className="relative leading-[117.4%] z-[4] Tablet_768:text-xs mq1440:text-sm">
        {LL0().pendingOrder.processing()}
      </b>
    </div>
  );
};

export default Notice1;
