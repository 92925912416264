import { demoDataImportMap } from "@/data/ImportData.ts";
import _ from "lodash";

import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { getUrlParam } from "@/shared/utils2.ts";

const countryCode = getUrlParam('cn')
export const addDemoCashbookData = async () => {
  const demoDataCashBook = await demoDataImportMap(countryCode || import.meta.env.VITE_COUNTRY)
  _.assign(posSetting0(), {
    cashbook: {
      incomingTypes: demoDataCashBook?.PosSetting?.[0].cashbook?.incomingTypes || [],
      outgoingTypes: demoDataCashBook?.PosSetting?.[0].cashbook?.outgoingTypes || []
    }
  })
}