import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb'
import { getCRDTSchemaPart } from 'rxdb/plugins/crdt'

import { type ItemCommit, type OrderStrip, type TOrder } from '@/pos/OrderType'

export const ORDER_COLLECTION_NAME = 'orders'
export const PAID_ORDER_COLLECTION_NAME = 'paid_orders'

export const VERSION = 26

export function createOrderSchema(title: string, useCRDT: boolean = false): RxJsonSchema<OrderStrip> {
  return {
    title,
    // keyCompression: true,
    version: VERSION,
    primaryKey: '_id',
    type: 'object',
    // @ts-expect-error We don't want to define all properties in the schema
    properties: {
      _id: { type: 'string', maxLength: 100 },
      ...(useCRDT && {
        crdts: getCRDTSchemaPart(),
      }),
      id: { type: 'integer', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
      ticketNumber: { type: 'integer', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
      externalStoreId: { type: 'string' },
      externalId: { type: 'string' },
      provider: { type: 'string' },
      protocol: { type: 'number' },
      type: { type: 'string' },
      vSum: { type: 'number' },
      tip: { type: 'number' },
      cashback: { type: 'number' },
      vDiscount: { type: 'number' },
      discount: { type: 'number' },
      discountDetails: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            name: { type: 'string' },
            value: { type: 'number' },
          },
        },
      },
      takeAway: { type: 'boolean' },
      manual: { type: 'boolean' },
      seatMode: { type: 'boolean' },
      status: { type: 'string', maxLength: 24 },
      z: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
      note: { type: 'string' },
      table: { type: 'string' },
      //fixme: delete this line
      count: { type: 'number' },
      date: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
      vDate: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
      items: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: { type: 'integer', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
            name: { type: 'string' },
            price: { type: 'number' },
            lastQuantity: { type: 'number' },
            quantity: { type: 'number' },
            course: { type: 'integer', multipleOf: 1, minimum: -2, maximum: 10 },
            commitRefs: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
            taxes: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: { type: 'number' },
                },
              },
            },
            groupPrinter: { type: 'string' },
            groupPrinter2: { type: 'string' },
            movedQuantity: { type: 'number' },
            vPrice: { type: 'number' },
            separate: { type: 'boolean' },
            vTakeAway: { type: 'boolean' },
            tax: { type: 'number' },
            vDiscount: { type: 'number' },
            vTaxSum: { type: 'object' },
            modifiers: {
              type: 'array',
              items: {
                type: 'object',
              },
            },
            discount: { type: 'string' },
            printed: { type: 'boolean' },
            ingredients: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
            taxComponents: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  value: { type: 'number' },
                  printLabel: { type: 'string' },
                },
              },
            },
            taxComponents2: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  value: { type: 'number' },
                  printLabel: { type: 'string' },
                },
              },
            },
            vTaxComponents: {
              type: 'object',
              properties: {},
            },
          },
        },
      },
      payments: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            value: { type: 'integer' },
            type: { type: 'string' },
            extraType: { type: 'string' },
          },
        },
      },
      users: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            name: { type: 'string' },
            date: { type: 'string' },
          },
        },
      },
      commits: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            action: { type: 'integer' },
            ref: { type: 'string' },
            _id: { type: 'string' },
            productRef: {
              type: 'object',
              properties: {
                name: { type: 'string' },
                price: { type: 'integer' },
              },
            },
            date: { type: 'number' },
            seat: { type: 'integer' },
          },
        },
      },
      shippingData: {
        type: 'object',
        properties: {
          fee: { type: 'number' },
          tax: { type: 'number' },
          vTax: { type: 'number' },
          vTaxSum: {
            type: 'object',
            properties: {},
          },
          estimatedDurationInMinutes: { type: 'number' },
          service: { type: 'string' },
          trackingUrl: { type: 'string' },
        },
      },
      createdDate: { type: 'string' }, // ISO the time the order has been created
      answerDate: { type: 'string' }, // ISO the time to answer the order: reject | accept
      pickupDate: { type: 'string' }, // ISO time ready for pickup the items (end-user + courier)
      dropOffDate: { type: 'string' }, // time to drop off the package (end-user receive the items)
      responseMessage: { type: 'string' },
      serviceFee: { type: 'number' },
      pickUpNumber: { type: 'number' },
      customer: { type: 'string' },
      customerRaw: {
        type: 'object',
        properties: {
          name: { type: 'string' },
          company: { type: 'string' },
          phone: { type: 'string' },
          address: { type: 'string' },
          zipCode: { type: 'string' },
          placeId: { type: 'string' },
          email: { type: 'string' },
          distance: { type: 'number' },
          lng: { type: 'number' },
          lat: { type: 'number' },
          city: { type: 'string' },
          country: { type: 'string' },
          ustId: { type: 'string' },
          type: { type: 'string' },
        },
      },
      tseMethod: { type: 'string' },
      qrCode: { type: 'string' },
      refOrder: { type: 'string' },
      originalOrder: { type: 'string' },
      printInvoice: { type: 'boolean' },
      needReassignQr: { type: 'boolean' },
      splitId: { type: 'string' },
      reactive: { type: 'boolean' },
      vTaxComponents: {
        type: 'object',
        properties: {},
      },
      refOrderId: { type: 'number' },
      refunded: { type: 'boolean' },
      trainingMode: { type: 'boolean' },
      refundReason: { type: 'string' },
      reason: { type: 'string' },
      manualTip: { type: 'number' },
      cashTip: { type: 'number' },
      appVersion: { type: 'string' },
      eod: { type: 'string' },
      masterHandlers: {
        type: 'object',
        properties: {
          cmd: { type: 'array', items: { type: 'string' } },
          cmdUuids: { type: 'array', items: { type: 'string' } },
          status: { type: 'string' },
        },
      },
      // FIXME: Redesign this schema, prefer moving it to `users`
      modifiedBy: { type: 'string' },
      multiplePriceMenu: { type: 'string' },
      directCancellationItems: { type: 'array', items: { type: 'object' } },
    },
    indexes: ['id', 'date', 'vDate', 'status', 'z'],
    ...(useCRDT && {
      crdt: {
        // CRDT options
        field: 'crdts',
      },
    }),
  }
}

export const orderSchema = createOrderSchema('order schema')

export type OrderDocMethods = { getItemsCommits: () => Promise<Array<ItemCommit> | undefined> }
export type OrderDocument = RxDocument<OrderStrip, OrderDocMethods>
export type Order = TOrder
export type PaidOrder = TOrder

export const Order = {} as RxCollection<OrderStrip, OrderDocMethods>
export const PaidOrder = {} as RxCollection<OrderStrip, OrderDocMethods>

// DEBUG: make collection global
Object.assign(window, {
  Order,
  PaidOrder,
  dev_printOrderData: async (paid = true) => {
    const data = await (paid ? PaidOrder : Order)
      .find({ sort: [{ date: 'desc' }] })
      .exec()
      .then(a =>
        a
          .map(b => b.toJSON())
          .map(b => ({
            ...b,
            Time: b.date && new Date(b.date * 1000).toTimeString().slice(0, 5),
            User: b.users?.join(','),
          }))
      )
    if (data.length) console.table(data, ['_id', 'id', 'Time', 'User', 'table', 'vSum', 'status'])
    else console.log(`» No data available in ${paid ? 'PaidOrder' : 'Order'}`)
  },
})
