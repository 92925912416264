import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

const KeyboardWrapper: FunctionComponent = () => {
  return (
    <div className="w-full !m-[0] absolute right-[0px] bottom-[0px] left-[0px] h-[258px] hidden flex-col items-start justify-start z-[4] text-center text-3xl text-black font-mulish">
      <div className="self-stretch flex-1 rounded-10xs bg-whitesmoke-400 flex flex-col items-start justify-start min-w-[524px] min-h-[200px]">
        <div className="self-stretch flex-1 rounded bg-aliceblue-200 [backdrop-filter:blur(30px)] flex flex-col items-center justify-center py-2 px-2.5 gap-[8px_0px]">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[0px_8px] Tablet_768:gap-[0px_8px] Tablet_600:gap-[0px_8px] Mobile_480:gap-[0px_8px]">
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">1</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">2</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">3</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">4</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">5</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">6</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">7</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">8</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">9</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">0</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[0px_8px] Tablet_768:gap-[0px_8px] Tablet_600:gap-[0px_8px] Mobile_480:gap-[0px_8px]">
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">Q</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">W</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">E</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">R</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">T</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">Y</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">U</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">I</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">O</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">P</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-[33px] gap-[0px_8px] Tablet_768:gap-[0px_8px] Tablet_600:gap-[0px_8px] Mobile_480:gap-[0px_8px] Mobile_480:pl-[30px] Mobile_480:pr-[30px] Mobile_480:box-border">
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">A</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">S</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">D</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">F</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">G</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">H</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">J</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">K</div>
            </div>
            <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
              <div className="relative">L</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[0px_22px] Tablet_768:gap-[0px_22px] Tablet_600:gap-[0px_22px] Mobile_480:gap-[0px_22px]">
            <div className="self-stretch rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7">
              <img
                className="w-[18.9px] relative h-[16.5px]"
                alt=""
                src="/shift.svg"
              />
            </div>
            <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[0px_6px]">
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative">Z</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative">X</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative">C</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative">V</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative">B</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative">N</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
                <div className="relative">M</div>
              </div>
            </div>
            <div className="self-stretch rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center py-0 px-7">
              <img
                className="w-[22.7px] relative h-[17px]"
                alt=""
                src="/delete-button.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 gap-[8px] grid grid-cols-[1fr_2fr_1fr] [grid-row-gap:0px] [grid-column-gap:8px] text-base Tablet_600:grid Tablet_600:grid-cols-[1fr_1fr_1fr] Tablet_600:[grid-row-gap:0px] Tablet_600:[grid-column-gap:8px]">
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[146px] relative col-[1/1] row-[1/1] Tablet_768:relative Tablet_768:col-[1/1] Tablet_768:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center">
                <div className="relative tracking-[-0.32px] leading-[21px]">
                  123
                </div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-3xl">
                <div className="relative">,</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-lg">
                <div className="relative">DE</div>
              </div>
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center relative col-[2/2] row-[1/1]">
              <div className="relative tracking-[-0.32px] leading-[21px]">
                Leerzeichen
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[120px] relative col-[3/3] row-[1/1] text-3xl Tablet_600:relative Tablet_600:col-[3/3] Tablet_600:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center min-w-[34px] Tablet_600:max-w-[52px]">
                <div className="relative">.</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-blue-solid-blue-350-2196f3 flex flex-col items-center justify-center min-w-[94px] text-base text-white-solid-white-100-ffffff Mobile_480:flex-1">
                <div className="relative">Hinzufügen</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[784px] flex-1 [backdrop-filter:blur(30px)] rounded bg-blue-opacity-blue-15-f3f9ff hidden flex-col items-center justify-center py-2 px-2.5 box-border gap-[8px]">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">1</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">2</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">3</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">4</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">5</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">6</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">7</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">8</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">9</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">0</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">-</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">/</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">:</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">;</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">(</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">)</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">$</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">€</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">£</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">¥</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[32px] Tablet_768:gap-[16px] Tablet_600:gap-[12px] Mobile_480:gap-[6px]">
            <div className="self-stretch w-[78px] shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">@</div>
            </div>
            <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">{`&`}</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">#</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">%</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">?</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">!</div>
              </div>
            </div>
            <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center py-0 px-7 Mobile_480:pl-5 Mobile_480:pr-5 Mobile_480:box-border Mobile_480:max-w-[52px]">
              <img
                className="w-[22.7px] relative h-[17px]"
                alt=""
                src="/delete-button1.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 gap-[8px] grid grid-cols-[1fr_2fr_1fr] [grid-row-gap:0px] [grid-column-gap:8px] text-base Tablet_600:grid Tablet_600:grid-cols-[1fr_1fr_1fr] Tablet_600:[grid-row-gap:0px] Tablet_600:[grid-column-gap:8px]">
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[146px] relative col-[1/1] row-[1/1] Tablet_768:relative Tablet_768:col-[1/1] Tablet_768:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center">
                <div className="relative tracking-[-0.32px] leading-[21px]">
                  ABC
                </div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center text-3xl">
                <div className="relative">,</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center text-lg">
                <div className="relative">EN</div>
              </div>
            </div>
            <div className="rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative">
              <div className="relative tracking-[-0.32px] leading-[21px]">
                space
              </div>
            </div>
            <div className="flex flex-row items-start justify-start py-0 pr-0 pl-1 box-border gap-[0px_8px] min-w-[120px] relative text-3xl">
              <div className="self-stretch flex-1 rounded-8xs-6 bg-[#fcfcfe] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[26px]">
                <div className="relative">.</div>
              </div>
              <div className="self-stretch flex-1 rounded-8xs-6 bg-cornflowerblue shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[42px] text-base text-basic-color-white">
                <div className="relative">{LL0().ui.add()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyboardWrapper;