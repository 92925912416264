import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";

type DeleteConfirmationPopupType = {
  onClose?: () => void;
  onClick?: () => void;
};

const DeleteConfirmationPopup: FunctionComponent<
  DeleteConfirmationPopupType
> = ({ onClose, onClick }) => {
  return (
    <div className="relative rounded bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px] min-w-[360px] h-full text-center text-[20px] text-firebrick font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[32px] hover:items-center hover:justify-center hover:rounded hover:pt-8 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[360]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <b className="self-stretch relative leading-[28px]">
          Delete Confirmation
        </b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-gray">
          {LL0().editOnlineMenu.doYouWantToDelete()}?
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
          ref={makeRipple}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
        ref={makeRipple}
             onClick={onClick}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
