import PureImage from "@gigasource/pureimage";
import {Writable} from "stream";
import { PNG } from "pngjs";

import { Buffer } from "buffer/";

export async function toBase64PngBuffer(width, height, imageContent) {
  const canvas = PureImage.make(width, height, {})
  canvas.data.fill(imageContent)
  const pngBuffer = await canvasToPngBuffer(canvas)
  const png = PNG.sync.read(pngBuffer)
  return PNG.sync.write(png).toString('base64')
}

async function canvasToPngBuffer(canvas, customHeight) {
  return new Promise(async resolve => {
    let canvasImageBuffer = Buffer.from([]);
    const currentCanvasHeight = canvas.height;
    if (!isNaN(customHeight)) canvas.height = customHeight;

    const writeStream = new Writable();
    writeStream._write = function (chunk, encoding, cb) {
      canvasImageBuffer = Buffer.concat([canvasImageBuffer, chunk]);
      cb();
    }
    writeStream.on('finish', async () => {
      canvas.height = currentCanvasHeight;
      resolve(canvasImageBuffer);
    });
    await PureImage.encodePNGToStream(canvas, writeStream);
  });
}
