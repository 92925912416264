import debug from 'debug'
import _ from 'lodash'
import uuid from 'time-uuid'

import type { DeviceSrmSetting } from '@/data/DeviceSetting'
import type { PosSrmSetting } from '@/data/PosSetting'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { User, UserRole } from '@/data/User'
import defaultTaxesByCity from '@/react/PaymentSettingView/defaultTaxesByCity.json'
import dialogService from '@/react/SystemService/dialogService'
import { CONSTANT_VALUES } from '@/srm/lib/constants'

import { srmUserLogic } from '../user.logic'
import { TESTCASE_VARS } from './constants'
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const log = debug('data:srm')

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

/** Save test summary to localStorage */
export const saveTestcaseSummary = (r: Record<string, boolean>) => localStorage.setItem('SRM_TESTCASE_SUMMARY', JSON.stringify(r))
/** Load test summary from localStorage */
export const loadTestcaseSummary = () => JSON.parse(localStorage.getItem('SRM_TESTCASE_SUMMARY') ?? '{}')

export const advanceToTestcase = async (testcaseNumber: string) => {
  await posSetting0()?.doc?.incrementalUpdate({
    $set: { 'srm.testcaseNumber': testcaseNumber },
  })
}
const colors = {
  blue: ['color:DodgerBlue', 'color:initial'],
  fuchsia: ['color:Fuchsia', 'color:initial'],
}

/**
 * Helper function to run testcase 🧪
 *
 * - Advance to selected test number and step
 * - Logging progress, result and errors
 * - When done, reset the testcase number
 */
export async function runTestcase(num: number, step: number, fn: () => Promise<void>): Promise<void> {
  const testcase = `${num.toString().padStart(3, '0')}.0${step.toString().padStart(2, '0')}`

  const closeDialog = dialogService.progress({ title: `Running ${testcase}...` })
  try {
    await advanceToTestcase(testcase)
    await sleep(200) // Take a breath
    console.groupCollapsed(`[SRM] 🧪 ${testcase}`)
    log(`ℹ️ [%c${testcase}%c] starting...`, ...colors.blue)
    await fn()
    saveLastTransactionSignature()
  } catch (e) {
    clearLastTransactionSignature()
    log(`🛑 [%c${testcase}%c] failed, reverting last transaction signature`, ...colors.blue, e)
    throw e
  } finally {
    console.groupEnd()
    await advanceToTestcase(CONSTANT_VALUES.CASESSAI_EMPTY)
    closeDialog()
  }
}

/** Save testcase results to localStorage */
export function saveTestCaseDevices(data: Record<string, DeviceSrmSetting>) {
  localStorage.setItem(`SRM_TESTCASE_DEVICES`, JSON.stringify(data))
}
/** Load testcase results from localStorage */
export function getTestcaseDevices() {
  const s = localStorage.getItem(`SRM_TESTCASE_DEVICES`)
  if (!s) return
  const devices = JSON.parse(s) as Record<string, DeviceSrmSetting>
  return devices
}

/** Setup company setting for tests */
export async function setupPosSettings(env?: PosSrmSetting['env'], trainingMode = false) {
  const posSetting = posSetting0()?.doc
  const deviceSetting = deviceSetting0()?.doc
  if (!posSetting) throw new Error('PosSetting not initialized!')
  if (!deviceSetting) throw new Error('DeviceSetting not initialized!')

  // Update setting for tests
  await posSetting.incrementalUpdate({
    $set: {
      'generalSetting.autoLogOutPeriod': 15 * 60 * 1000, // 15 min
      'generalSetting.autoLogOutAfterInactivity': true,
      'generalSetting.autoLogOutWhenCloseApp': true,
      'generalSetting.quebecSrmEnable': true,
      'generalSetting.trainingMode': trainingMode,

      'companyInfo.name': TESTCASE_VARS.name,
      'companyInfo.address': TESTCASE_VARS.street_address,
      'companyInfo.city': TESTCASE_VARS.city,
      'companyInfo.province': TESTCASE_VARS.province,
      'companyInfo.zipCode': TESTCASE_VARS.zipCode,

      'srm.qstNumber': TESTCASE_VARS.qstNumber,
      'srm.gstNumber': TESTCASE_VARS.gstNumber,
      'srm.authCode': TESTCASE_VARS.authCode,
      'srm.certificateCode': TESTCASE_VARS.certificateCode,
      'srm.identificationNumber': TESTCASE_VARS.identificationNumber,
      'srm.billingNumber': TESTCASE_VARS.billingNumber,
      'srm.timezone': TESTCASE_VARS.timezone,

      ...(env ? { 'srm.env': env } : {}),
    },
  })
}

export async function cleanDemoUsers() {
  // Delete all users, except admin
  await User.find({ selector: { name: { $ne: 'admin' } } }).remove()
}

export async function setupTestUsers() {
  const commonUserOpts: Partial<User> = {
    noTVQ: TESTCASE_VARS.qstNumber,
    noTPS: TESTCASE_VARS.gstNumber,
  }

  const users: User[] = [
    // Main user
    {
      _id: uuid(),
      avatar: '/src/assets/avatar/avatar_1.png',
      name: 'Michel Untel',
      passcode: '1111',
      role: UserRole.MANAGER,
      ...commonUserOpts,

      editHighSecuritySetting: true,
      moveItem: true,
      viewOrderDetail: true,
      viewOrderHistory: true,
      editMenuLayout: true,
      accessZReport: true,
      viewOtherStaffReport: true,
      discount: true,
      viewCustomerInfo: true,
      allowTableTakeOver: true,
      editTablePlan: true,
      viewOwnReport: true,
      viewMonthlyReport: true,
      manageInventory: true,
      deletePrintedItem: true,
      viewOnlineOrderDashboard: true,
      viewOnlineOrderMenu: true,
      viewOrder: true,
      viewReservation: true,
      cancelOrder: true,
      editThePriceOfAMenuItem: true,
    },
    // Sub user
    {
      _id: uuid(),
      avatar: '/src/assets/avatar/avatar_12.png',
      name: 'John Smith',
      passcode: '2222',
      role: UserRole.STAFF,
      ...commonUserOpts,
      viewOrderHistory: true,
      deletePrintedItem: true,
    },
  ]

  const { success, error } = await User.bulkInsert(users)

  if (success)
    for (const u of users) {
      if (!u.name) continue
      await srmUserLogic.createUser(u)
    }
  else console.log(error)
}

let currentDevice = ''

/** Setup new device setting for tests */
export async function setupNewDevice(name: string) {
  await deviceSetting0()?.doc?.incrementalUpdate({
    $unset: {
      'srm.deviceId': '',
      'srm.cert': '',
      'srm.certPSI': '',
      'srm.privateKey': '',
      'srm.publicKey': '',
      'srm.lastTransSig': '',
    },
  })
  currentDevice = name
}

/** Switch to specific device, by setting the deviceId and related key/cert */
export async function switchDevice(name: string, clearTransHistory = false) {
  const saved = getTestcaseDevices()
  if (!saved) throw new Error('Please run testcase 001 first!')
  const config = saved[name]
  if (!config) throw new Error('Device config not found!')

  // Save last transaction signature
  if (currentDevice) {
    saved[currentDevice].lastTransSig = deviceSetting0()?.srm?.lastTransSig
    saveTestCaseDevices(saved)
  }
  log(`⚡️ Switching to "${name}" device... (last device is "${currentDevice}")`)

  currentDevice = name
  if (clearTransHistory) {
    saved[currentDevice].lastTransSig = undefined
    saveTestCaseDevices(saved)
    if (config.deviceId) {
      log('⚠️ Clearing all transaction history...')
      await SrmTransactionLog.find({ selector: { deviceId: config.deviceId } }).remove()
    }
  }
  await deviceSetting0()?.doc?.incrementalUpdate({
    $set: {
      'srm.deviceId': config.deviceId,
      'srm.cert': config.cert,
      'srm.certPSI': config.certPSI,
      'srm.privateKey': config.privateKey,
      'srm.publicKey': config.publicKey,
      'srm.lastTransSig': config.lastTransSig,
    },
  })
  log(`✅ Switched to "${name}"`)
}

function saveLastTransactionSignature() {
  const saved = getTestcaseDevices() ?? {}
  const { lastTransSig } = deviceSetting0()?.srm ?? {}
  if (lastTransSig) {
    log(`💾 [Device ${currentDevice}] Saving last transaction signature...`, lastTransSig)
    saved[currentDevice].lastTransSig = lastTransSig
    saveTestCaseDevices(saved)
  } else {
    log('⚠️ No last transaction signature to save')
  }
}
function clearLastTransactionSignature() {
  const saved = getTestcaseDevices() ?? {}
  log(`💾 [Device ${currentDevice}] Clearing last transaction signature...`)
  saved[currentDevice].lastTransSig = CONSTANT_VALUES.emptySig
}

export function getCaTaxInfo() {
  const defaultTax = defaultTaxesByCity['Canada-Quebec'][0]
  const defaultTaxValue = defaultTax.components.reduce((acc, curr) => acc + curr.value, 0)
  return {
    tax: defaultTaxValue,
    tax2: defaultTaxValue,
    taxCategory: defaultTax.name,
    taxCategory2: defaultTax.name,
    taxComponents: defaultTax.components,
    taxComponents2: defaultTax.components,
  }
}
