import { FunctionComponent } from "react";
import MoveItemTableLeft from "./MoveItemTableLeft";
import {RoomComponent, rooms0} from "@/react/FloorPlanView/FloorPlanView.tsx";

const MoveItemTablePlugin: FunctionComponent = () => {
  return (
    <div className="w-full h-full bg-black-solid-black-700-364150 max-w-full flex flex-row items-center justify-start">
      <MoveItemTableLeft />
      <div className="relative self-stretch flex-1 flex flex-row items-center justify-center bg-[url('/move-item-table-right@3x.png')] bg-cover bg-no-repeat bg-[top] min-w-[750px]">
        {rooms0().map(room => <RoomComponent key={room._id} room={room} />)}
      </div>
    </div>
  );
};

export default MoveItemTablePlugin;
