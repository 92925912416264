import { FunctionComponent } from "react";
import { Switch, FormControlLabel, TextField } from "@mui/material";
import DefauleTimeToCompleteOder from "./DefauleTimeToCompleteOder";
import PlayNotificationSound from "./PlayNotificationSound";
import OrderSortingOption from "./OrderSortingOption";
import {LL0} from "@/react/core/I18nService.tsx";
import TextArea from "@/react/core/TextArea.tsx";

const OnlineOrderSettingContainer: FunctionComponent = () => {
  return (
    <div className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-6 pb-4 gap-[16px] text-left text-base text-black-solid-black-880-1d1d26 font-mulish sm:pl-6 sm:pr-6 sm:box-border Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
      <div className="self-stretch overflow-y-auto shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-4 gap-[8px] text-mini border-b-[1px] border-solid border-gainsboro">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
            <div className="relative sm:text-sm mq1440:text-base">{LL0().onlineOrder.settings.deviceName()}:</div>
            <div className="relative sm:text-sm mq1440:text-base">1</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
          <b className="relative sm:text-sm mq1440:text-base">{LL0().onlineOrder.status()}:</b>
          <i className="relative sm:text-sm mq1440:text-base">{LL0().onlineOrder.settings.notPaired()}</i>
        </div>
        <div className="flex flex-row items-start justify-start gap-[8px] text-center text-sm text-white-solid-white-100-ffffff">
          <div className="rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden flex flex-row items-center justify-center py-0 px-3 box-border height_414:h-[30px]">
            <b className="relative mq1440:text-mini">{LL0().onlineOrder.resetOnlineOrders()}</b>
          </div>
          <div className="rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden hidden flex-row items-center justify-center py-0 px-6 box-border height_414:h-[30px]">
            <b className="relative mq1440:text-mini">{LL0().onlineOrder.settings.pair()}</b>
          </div>
        </div>
      </div>
      <div className="self-stretch overflow-y-auto shrink-0 flex flex-col items-start justify-start gap-[8px]">
        <b className="relative sm:text-mini Tablet_600:text-mini">{LL0().onlineOrder.service()}:</b>
        <div className="self-stretch flex flex-row items-start justify-start gap-[32px]">
          <FormControlLabel
            label={LL0().printing.delivery()}
            control={<Switch color="primary" />}
          />
          <FormControlLabel
            label={LL0().printing.pickup()}
            control={<Switch color="primary" />}
          />
        </div>
      </div>
      <div className="self-stretch overflow-y-auto shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-4 gap-[8px] border-b-[1px] border-solid border-gainsboro">
        <b className="relative sm:text-mini Tablet_600:text-mini">
          {LL0().onlineOrder.noteToCustomer()}:
        </b>
        <TextArea
          className="self-stretch w-full"
        />
        <div className="rounded-md bg-blue-solid-blue-450-1271ff h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border text-center text-sm text-white-solid-white-100-ffffff height_414:h-[30px]">
          <b className="relative mq1440:text-mini">{LL0().onlineOrder.update()}</b>
        </div>
      </div>
      <b className="relative text-lg leading-[16px] text-blue-solid-blue-500-0051c1 sm:text-mid Tablet_600:text-base">
        {LL0().onlineOrder.customEffect()}:
      </b>
      <DefauleTimeToCompleteOder />
      <PlayNotificationSound />
      <OrderSortingOption />
    </div>
  );
};

export default OnlineOrderSettingContainer;
