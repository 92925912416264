import { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

type DeleteConfirmationPopupType = {
  onClose?: () => void;
  onComplete?: () => void;
};

const DeleteConfirmationPopup: FunctionComponent<
  DeleteConfirmationPopupType
> = ({ onClose, onComplete }) => {
  return (
    <div className="relative bg-basic-color-white w-full overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px] min-w-[360px] h-full max-w-full max-h-full text-center text-xl text-firebrick font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <b className="self-stretch relative leading-[28px]">
          {LL0().masterMachine.deleteConfirmation()}
        </b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-gray-200">
          {LL0().masterMachine.doYouWantToDelete()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-royalblue-100">
        <div
          className="flex-1 rounded-md bg-basic-color-white overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-royalblue-100"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-royalblue-100 overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 text-basic-color-white"
          onPointerDown={() => {
            onComplete && onComplete();
            onClose && onClose()
          }}
        >
          <b className="relative uppercase">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
