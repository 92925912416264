import { type FunctionComponent, useCallback, useContext } from 'react';
import { currentItem } from '@/react/OrderView/OrderView.tsx';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

type MoveSeatFormType = {
  showMoveSeatForm?: boolean;
};

const MoveSeatForm: FunctionComponent<MoveSeatFormType> = ({
  showMoveSeatForm,
}) => {
  const {order0, setSeat, seat} = useContext(ItemFactoryContext)

  const handleMoveSeat = useCallback(() => {
    console.log("Move order item to other seat")
  }, [])

  return (
    showMoveSeatForm && (
      <div className="w-[606px] flex-1 hidden flex-col items-end justify-between pt-4 pb-6 pr-2 pl-0 box-border z-[3] text-left text-smi text-black-solid-black-1000-000000 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-2 pb-6 gap-[20px]">
          <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
            <div className="relative">
              {LL0().order.chooseNumberOfItemToMove()}:
            </div>
            <div className="flex flex-row items-center justify-start gap-[3px] text-center text-base text-black-solid-black-880-1d1d26">
              <img
                className="w-[22px] relative h-[22px]"
                alt=""
                src="/minusvector3.svg"
              />
              <b className="w-[30px] relative inline-block shrink-0">{currentItem()?.quantity}</b>
              <img
                className="w-[22px] relative h-[22px]"
                alt=""
                src="/plusvector3.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-4">
            <div className="relative">
              {LL0().order.chooseTheSeatWantToMove()}:
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[20px] text-center text-sm text-black-solid-black-880-1d1d26">
              {order0?.()?.seatMap?.map((_seat, index) => (
                <div key={index} onClick={() => setSeat?.(index+1)}
                  className="rounded-10xs bg-gray-solid-gray-130-f4f4f4-200 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-[34px] flex flex-row items-center justify-center py-0 px-5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">
                  {seat?.() === index + 1 && <div className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff"/>}
                  <div className="relative z-[3]">Seat {index + 1}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div onClick={handleMoveSeat}
          className="w-[121px] rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 box-border text-center text-sm text-white-solid-white-100-ffffff">
          <b className="relative">{LL0().ui.save()}</b>
        </div>
      </div>
    )
  );
};

export default MoveSeatForm;