import SetMasterMachinePlugin from '@master-machine/SetMasterMachinePlugin.tsx'
import { memo } from 'react'
import { toast } from 'react-toastify'

import type { DocDeepSignal } from '@/data/data-utils.ts'
import { DeviceSetting } from '@/data/DeviceSetting.ts'
import SurrealClient from '@/shared/SurrealClient.ts'
import { upsertMaster } from "@/lib/fetch-master.ts";
import dayjs from "dayjs";
import { now } from "@/pos/logic/time-provider.ts";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { posSync0 } from "@/data/PosSyncState.ts";

export const editDeviceName = async (deviceSetting: DocDeepSignal<DeviceSetting>, name: string) => {
  try {
    if (deviceSetting?.doc) {
      await deviceSetting.doc.incrementalUpdate({ $set: { name: name } });
      const db = await SurrealClient.getSurrealClient('cloud')
      if (!db) return
      await db.query(`UPSERT Device:${deviceSetting._id} SET name = '${name}'`)
    }
    toast.success('Rename success!')
  } catch (error) {
    console.error("Error rename:", error);
    toast.error("Failed to rename");
  }
};

export const lastSeen = (lastOnline: number) => {
  const date = dayjs(now()).unix();
  const timeDiffSeconds = date - lastOnline;
  let stringDate = 'Last seen: '
  if (timeDiffSeconds < 300) {
    stringDate = `online`;
  } else if (timeDiffSeconds < 3600) {
    stringDate += `${Math.floor(timeDiffSeconds / 60)} minute ago`;
  } else if (timeDiffSeconds < 86400) {
    stringDate += `${Math.floor(timeDiffSeconds / 3600)} hour ago`;
  } else if (timeDiffSeconds < 2592000) {
    stringDate += `${Math.floor(timeDiffSeconds / 86400)} day ago`;
  } else if (timeDiffSeconds < 31536000) {
    stringDate += `${Math.floor(timeDiffSeconds / 2592000)} month ago`;
  } else {
    stringDate += `${Math.floor(timeDiffSeconds / 31536000)} year ago`;
  }
  return stringDate;
}

export async function removeDevice(deviceSetting: DocDeepSignal<DeviceSetting>) {
  try {
    if (deviceSetting?.doc) {
      deviceSetting.doc.incrementalUpdate({ $set: { show: false } });
    } else {
      return toast('Error when remove device!', { type: 'error', autoClose: 300 })
    }
    const db = await SurrealClient.getSurrealClient('cloud')
    if (!db) return
    await db.query(`UPSERT Device:${deviceSetting._id} SET show = false`)
    toast('Remove device successful!', { type: 'success', autoClose: 200 })
  } catch (e) {
    toast('An error has occurred while remove device: ' + e, { type: 'error' })
  }
}

export async function setDeviceAsMaster(deviceSetting: DocDeepSignal<DeviceSetting>) {
  try {
    if (posSetting0()) posSetting0()!.masterDevice = deviceSetting._id
    await upsertMaster(deviceSetting._id, deviceSetting.ip, true);
    if (deviceSetting.cloudRegister && posSync0()!.id) {
      const db = await SurrealClient.getSurrealClient('cloud')
      if (!db) return
      await db.query(`UPDATE PosUser:${posSync0()!.id} SET masterDevice = '${deviceSetting._id}';`)
    }
    toast('Set as master device successful!', { type: 'success' })
  } catch (e) {
    toast('An error has occurred while set as master device: ' + e, { type: 'error' })
  }
}

const MasterMachineView = () => {
  return <SetMasterMachinePlugin />
}

export default memo(MasterMachineView)
