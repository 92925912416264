import { FunctionComponent, memo } from "react";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type ExtraItemType = {
  modifierSName?: string;
  modifierSPrice?: number;
};

const ExtraItem: FunctionComponent<ExtraItemType> = memo(
  ({ modifierSName = "Extra cheese | ", modifierSPrice = 1 }) => {
    return (
      <div className="rounded-sm bg-grey-grey-lighten-2 flex flex-row items-center justify-start p-1 gap-[2px] text-left text-xs text-dimgray-100 font-mulish">
        <div className="relative">{modifierSName}</div>
        <div className="relative">{LL3().format.currency(modifierSPrice)}</div>
      </div>
    );
  }
);

export default ExtraItem;
