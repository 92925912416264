import type { RxCollection, RxDatabase } from 'rxdb'

import { Call, CALL_COLLECTION_NAME, callSchema, MISSED_CALL_COLLECTION_NAME, MissedCall } from './Call'
import { createCollection } from './utils/migration'

export const createCallCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<Call>> => {
  return await createCollection({
    database,
    collection: Call,
    collectionName: CALL_COLLECTION_NAME,
    version: callSchema.version,
    schema: callSchema,
  })
}

export const createMissedCallCollection = async <T>(database: RxDatabase<T>): Promise<RxCollection<Call>> => {
  return await createCollection({
    database,
    collection: MissedCall,
    collectionName: MISSED_CALL_COLLECTION_NAME,
    version: callSchema.version,
    schema: callSchema,
  })
}
