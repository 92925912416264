import {signal} from "@/react/core/reactive.ts";
import {Order} from "@/data/Order.ts";
import {createOrder} from "@/pos/logic/order-reactive.ts";

export const [order0, setOrder0] = signal<Order>(createOrder());
// @ts-ignore
window.order0 = order0;

export enum OrderMode {
  TABLE,
  FAST_CHECKOUT
}